import styles from './ChartTooltip.module.scss'

function customTooltips(context) {
    const tooltip = context.tooltip;
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.className = styles.tooltip;
        tooltipEl.innerHTML = '<table></table>';
        window.document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        tooltipEl.innerHTML = '<table></table>';

        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
        tooltipEl.classList.add(tooltip.xAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltip.body) {
        var titleLines = tooltip.title || [];

        var innerHtml = '<thead>';

        titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        tooltip.body.forEach((bi, i) => {
            if (bi.before.length > 0) {
                bi.before.forEach((body) => {
                    innerHtml += `<tr class="${styles.grouptitle}"><th>${body}</th></tr>`;
                });

                bi.lines.forEach((body) => {
                    innerHtml += `<tr class="${styles.grouptitlerow}"><td>${body}</td></tr>`;
                });
            } else {
                bi.lines.forEach((body) => {
                    var colors = tooltip.labelColors[i];
                    var style = `background: ${colors.backgroundColor}; border-color: ${colors.borderColor};`;
                    var span = `<span class="${styles.chartkey}" style="${style}"></span>`;
                    innerHtml += `<tr><td>${span}${body}</td></tr>`;
                });
            }
        });

        innerHtml += '</tbody>';

        var tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    var positionY = this._chart.canvas.getBoundingClientRect().top + window.scrollY;
    var positionX = this._chart.canvas.getBoundingClientRect().left;

    let top;

    if (tooltip.yAlign == 'above') {
        top = tooltip.y - tooltip.caretY;
    } else {
        top = tooltip.y + tooltip.caretY;
    }

    var bottom = tableRoot.clientHeight + top + positionY;

    var height = window.innerHeight;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = positionX + tooltip.x + 'px';
    if (bottom < height) {
        tooltipEl.style.top = positionY + top + 'px';
        tooltipEl.style.bottom = '';
    } else {
        tooltipEl.style.top = '';
        tooltipEl.style.bottom = '10px';
    }
    tooltipEl.style.xOffset = tooltip.xOffset;
    tooltipEl.style.fontFamily = "'Open Sans', sans-serif";
    tooltipEl.style.fontSize = '11px';
    tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
    tooltipEl.style.padding = '20px 20px';
    tooltipEl.style.backgroundColor = "rgba(0,0,0,0.8)";
};

export default customTooltips;