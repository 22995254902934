import React from 'react';
import TaimerComponent from "../TaimerComponent";
import { ArrowBack, KeyboardArrowRightRounded } from '@mui/icons-material';
import "./TopBar.css";

class TopBar extends TaimerComponent{
    constructor(props, context) {
        super(props, context, "bills/ReceivedInvoiceView");
    }

    createTopBarLinks = () => {
        const { backLink, links, info } = this.props;
        return (
            <>
                {backLink && this.createTopBarLink(<ArrowBack className="arrow-back" />, backLink.module, backLink.action, { ...backLink.other }, backLink.disabled)}

                <div className="top-bar-header">
                    {links.map(link => {
                        return (
                            <>
                                {this.createTopBarLink(link.header, link.module, link.action, { ...link.other }, link.disabled)}
                                <KeyboardArrowRightRounded className="arrow" />
                            </>
                        )
                    })}

                    <span>{info}</span>
                </div>
            </>
        )
    }

    createTopBarLink = (content, module, action, other = {}, disabled = false) => {
        let urlObject = { module, action, ...other };

        return (
            disabled ? <span className="header-link-disabled">{content}</span> : 
            <a 
                className="header-link" 
                href={this.context.functions.urlify(urlObject)} 
                onClick={(evt) => !evt.ctrlKey && this.openLink(evt, urlObject)} 
            >
                {content} 
            </a>
        );
    }

    openLink = (evt, urlObject) => {
        evt.preventDefault();
        this.context.functions.updateView(urlObject);
    }
  
    render() {
        const { extra, buttons } = this.props;

        return (
            <>
                <div className="bills-topbar-container">
                    <div className="top-bar-left">
                        {this.createTopBarLinks()}
                        {extra.map(e => {return e})}
                    </div>
                    <div className="btns">
                        {buttons.map(b => {return b})}
                    </div>
                </div>
            </>
        )
    }
}

export default TopBar;
