import React, { Component } from 'react';
import cn from 'classnames';
import './PopupList.css';

export class PopupListItem extends Component {
	constructor(props)
	{
		super(props);

		this.element = React.createRef();
	}

	onClick = () => {
		this.props.onClick(this.props.item);
	}

	componentDidUpdate = () => {
		if (this.props.selected && this.element.current.scrollIntoView)
			this.element.current.scrollIntoView({block: "center", inline: "center"});
	}

	render() {
		const { item } = this.props;

		return (<li ref={this.element} className={this.props.selected ? "selected" : undefined} onClick={this.onClick}>{item.name}</li>)
	}
}

export default class PopupList extends Component {
    static defaultProps = {
        keyfield: 'id',
        component: PopupListItem,
        items: [],
        selectedIndex: -1,
    }

	constructor(props) {
        super(props);

        this.state = {
        };
    }

	onMouseEnter = () => {
        if (this.props.onMouseEnter)
            this.props.onMouseEnter();
	}

	onMouseLeave = () => {
        if (this.props.onMouseLeave)
            this.props.onMouseLeave();
	}

	select = (item) => {
		this.props.onSelect(item);
    }
    
	render() {
        if (this.props.items.length === 0)
            return null;

        var Item = this.props.component;

        return <div className={cn("ListPopup", this.props.className)} style={this.props.style}>
        <ul onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
			{this.props.items.map((item, index) => <Item selected={index === this.props.selectedIndex} key={item[this.props.keyfield]} item={item} onClick={this.select} />)}
		</ul></div>
	}
}