import React, { Component } from 'react';
import OutlinedField from './OutlinedField';
import { MenuItem, ListItemIcon } from '@mui/material';
import { SettingsContext } from '../SettingsContext';
import BranchIndicator from "./BranchIndicator";
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';
import './TreeSelect.css';

const styles = {

}

function buildTree(options, value, itemLock, props)
{
    let data = [];

    buildTreeItemLevel(data, options, [], 0, value, itemLock, props);

    return data;
}

export function hasSubvalue(x, current)
{
    if (x.id == current)
        return true;

    if (x.children)
    {
        for (const child of x.children)
        {
            if (hasSubvalue(child, current))
                return true;
        }
    }

    return false;
}

function buildTreeItemLevel(data, options, indicators, level, current, itemLock, props = null)
{
    let opts = options.filter(x => x.deleted != 1 || hasSubvalue(x, current));
    if (props.hideOptions !== null && typeof hideOptions === "function")
        opts = props.hideOptions(opts, current)
    opts.map( (item, i) => {

        const isDisabled = typeof itemLock == "function" ? itemLock(item) : (Boolean(itemLock) !== false ? !item[itemLock] : !!item.disabled);
        const isLast = i === opts.length - 1;
        data.push(
            <MenuItem 
                className="treeSelectItem" 
                style={props.hideOptions != null && isDisabled ? {display: "none"} : {}} 
                value={item.value || item.id} 
                key={item.value || item.id} 
                disabled={isDisabled}
            >
                {level > 0 &&
                    <div className="BranchIndicator">
                        {indicators}
                        <BranchIndicator rowHeight={46} width={40} renderHor noBottom={isLast} />
                    </div>
                }
                {item.name}
            </MenuItem>);

        if (item.children && item.children.length > 0)
        {
            const subindicators = [...indicators]

            if (level > 0)
                subindicators.push(<BranchIndicator key={`si${level}`} rowHeight={46} width={40} renderNothing={isLast} />);


            buildTreeItemLevel(data, item.children, subindicators, level + 1, current, itemLock, props)
        }
    });
}

class TreeSelect extends Component {
    static contextType = SettingsContext;
    static defaultProps = {
        noOptionsMessage: "No options",
        customItemLock: false, 
    };

    render() {
        const NoOptionsMessage = this.props.noOptionsMessage;

		return (
            <OutlinedField InputProps={{classes: {selectMenu: "TreeSelect"}}} select {...this.props} className={cn('TreeSelect', this.props.className)}>
            
                           { (this.props.options == 0 ) ? 
                                <div className="options noOptionsMessage">
                                {
                                    typeof NoOptionsMessage === "string" ?
                                        NoOptionsMessage: undefined
                               
                                }
                                </div>
                           :
                           this.props.options && buildTree(this.props.options, this.props.value, this.props.customItemLock, this.props)
                            }
                
            </OutlinedField>
		);
	}
}

export default withStyles(styles)(TreeSelect);