import React, { ReactNode } from 'react';
import { Button, Drawer, Radio, InputAdornment, List, ListItem, IconButton, MenuItem, ListItemText } from '@mui/material';
import _ from "lodash";
import Checkbox from "./Checkbox";
import DatePicker from './react-date-range/src/components/DatePicker';
import DateRangePicker from './react-date-range/src/components/DateRangePicker';
import TimeRangeOutlinedField from "./TimeRangeOutlinedField";
import TimeOutlinedField from "./TimeOutlinedField";
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import OutlinedField from './OutlinedField';
import DataList from './DataList';
import TaimerComponent from '../TaimerComponent';
import TaimerAvatar from '../general/TaimerAvatar';
import styles from './ContentSlider.module.css';
import { ReactComponent as Clock } from '../general/icons/clock.svg';
import { DialogDropDownButton, DialogDropDownButtonItem } from "../dialogs/DialogDropDownButton";
import MultiSelect from './MultiSelect';
import { validEmail } from '../dialogs/Validate';
import RepeaterDialog from "../dialogs/RepeaterDialog";

import DeleteIcon from '@mui/icons-material/Delete';
import ContextMenu from './ContextMenu';

export interface EditableField {
    title: string;
    key: string;
    startKey?: string;
    endKey?: string;
    validation?: string[];
    type: string;
    disabled?: boolean;
    options?: any[];
    setOtherValuesWithSelection?: (item: any, selectedValue: any) => object;
    addNoneOption?: boolean;
    adornment?: ReactNode;
    adornmentPos?: string;
    isHidden?: (item: any) => boolean;
    defaultValue?: string;
    rows?: number;
    rowsMax?: number;
    class?: string;
    nested?: EditableField[];
    callback?: any;
    toggleDisableKeys?: string[];
    setOtherKeys?: SettableKeys[];
    preview: boolean,
    icon: any,
}

interface SettableKeys{ 
    key: string;
    value: any;
}

interface CustomBottomButton extends EditableField { }

interface Props {
    open: boolean;
    onClose: () => void;
    title: string;
    fields: EditableField[];
    item: any;
    canDeleteItem?: (item: any) => boolean;
    onDeleteItem?: (item: any) => void;
    onSave: (item: any) => void;
    toggleMe: () => void;
    custombuttonsbottom: CustomBottomButton[];
}

interface State {
    item: any;
    edit: boolean;
}

class ContentSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/ContentSlider');
        this.state = {
            item: cloneDeep(this.props.item),
            edit: false //this.props.item.edit
        };
    }

    

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.item, this.props.item)) {
            this.setState({
                item: cloneDeep(this.props.item),
                edit: this.props.item.edit
            });
        }
    };

    onFieldEdited = (e) => {
        if (!this.state.item) return;
        const value = e.target.value ?? e.target.checked; //This is used for checkbox
        const item = cloneDeep(this.state.item);

        this.setState({
            item: {
                ...item,
                [e.target.name]: value,
            },
        });
    };

    onFieldBatchEdited = (values) => {
        if (!this.state.item) return;
        const item = cloneDeep(this.state.item);
        this.setState({
            item: {
                ...item,
                ...values,
            },
        });
    };

    onMeetingLinkSelected = (e) => {
        if (!this.state.item) return;
        const value = e.target.value; 
        const item = cloneDeep(this.state.item);

        switch (value) {
            case 'personal':
                this.setState({
                    item: {
                        ...item,
                        onlinemeetingurl: '',
                        location: item.personal_meeting_link,
                        [e.target.name]: value,
                    },
                });
                break;
            case 'default':
                this.setState({
                    item: {
                        ...item,
                        [e.target.name]: value,
                    },
                });
                break;
            case 'none':
                this.setState({
                    item: {
                        ...item,
                        onlinemeetingurl: '',
                        [e.target.name]: value,
                    },
                });
                break;                
        }
    };

    onSelectChanged = (field: EditableField, value) => {
        if (field.setOtherValuesWithSelection) {
            const values = {
                [field.key]: value,
                ...field.setOtherValuesWithSelection(this.state.item, value),
            };
            this.onFieldBatchEdited(values);
        } else {
            this.onFieldEdited({ target: { name: field.key, value } });
        }
    };

    onRadioChanged = (e) => {
        this.onFieldEdited({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
    };

    onDateRangeChanged = (field: EditableField, event) => {
        const startDate = moment(event.selection.startDate).format('YYYY-MM-DD');
        const endDate = moment(event.selection.endDate).format('YYYY-MM-DD');
        const values = {
            [field.startKey || 'startDate']: startDate,
            [field.endKey || 'endDate']: endDate,
        };
        this.onFieldBatchEdited(values);
    };

    onDateRangeInputChanged = (field: EditableField, type, date) => {
        this.onFieldEdited({ target: { name: type == 'start' ? field.startKey || 'startDate' : field.endKey || 'endDate', value: moment(date).format('YYYY-MM-DD') } });
    };

    onDateChanged = (key, date) => {
        this.onFieldEdited({ target: { name: key, value: moment(date).format('YYYY-MM-DD') } });
    };

    onDateInputChanged = (key, date) => {
        this.onFieldEdited({ target: { name: key, value: moment(date).format('YYYY-MM-DD') } });
    };

    onTimeRangeChanged = (field: EditableField, value, start, end) => {

        const startTime = moment(start).format('HH:mm');
        const endTime = moment(end).format('HH:mm');

        const values = {
            [field.startKey || 'startTime']: startTime,
            [field.endKey || 'endTime']: endTime,
            [field.key || 'timeRange']: value,
        };
        this.onFieldBatchEdited(values);
    };

    onTimeChanged = (key, value) => {
        this.onFieldEdited({ target: { name: key, value: value } });
    };

    disableToggler = (fieldKeys) => {
        let victim = _.filter(this.props.fields, function (o) {
            if (fieldKeys.includes(o.key)) o.disabled = !o.disabled;
            if (typeof o.nested !== "undefined") {
                let nested = (o.nested || []).map((field) => {
                    if (fieldKeys.includes(field.key)) field.disabled = !field.disabled;
                    return null;
                });
                return null;
            }
        });
    }
    handleListItemClick = (event, value) => {
        console.log(value);
    }
    openNewWindow = (url) => {
        window.open(url,'_blank')?.focus();
    }

    addFreeContact = (event) => {
        if (event.key === 'Enter' && validEmail(event.target.value)) {
            //then add
            let contact = { email: event.target.value }
            this.addContact(contact);

            //then empty & unfocuse the input
            event.target.blur();

        }
    }

    addContact = (contact) => {
        if (!this.state.item) return;
        const item = cloneDeep(this.state.item);
        contact.added = 1;
        //contact.isDisabled = true; //Datalist does not update after selecetion, so setting these disabled will lead to a buggy usage
        contact.name = contact.label || contact.email;
        contact.value = contact.email;
        //const cons = item.attendees.concat([contact]);  // We have to join arrays with unique values only (below) since setting disabled is not ready yet
        const cons = _.unionBy(item.attendees, [contact], 'value');

        cons.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        this.setState({
            item: {
                ...item,
                attendees: cons,
            },
        });

    } 

    removeContact = (contact) => {
        if (!this.state.item) return;
        contact.isDisabled = false;
        const item = cloneDeep(this.state.item);
        item.attendees.splice(item.attendees.findIndex(({ value }) => value === contact.value), 1);
        this.setState({
            item: {
                ...item,
            },
        });
    }

    toggleSlider = () => {
        this.props.toggleMe();
    }

    renderField = (field: EditableField) => {
        const { item } = this.state;

        if (item && (field.isHidden && field.isHidden(item)) || !field.preview  ) {
            return null;
        }
        if (field.nested?.length) {
            return (
                <div className={styles.nestedRow}>
                    {(field.nested || []).map((field) => {
                        return this.renderField(field);
                    })}
                </div>
            );
        }
       switch (field.key) {

            case 'summary':
                if(item && item[field.key] && field.preview){
                    return (
                        <div className={styles.summaryPreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>{item[field.key]}</div>
                        </div>
                    );
                }

            case 'startdate':
                if(item && item[field.key] && item['timerange'] && field.preview){

                    //this goes agains the original idea, but let's do the string here until the date & time values are using the nested type
                    let timestring = moment.weekdays(moment(item[field.key]).day())+' '+moment(item[field.key]).format('D')+' '+moment.months(moment(item[field.key]).month())+' '+item['timerange'];
                    return (
                        <div className={styles.startdatePreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>{timestring}</div>
                        </div>
                    );
                }
            /*case 'timerange':
                if(item && item[field.key] && field.preview){
                    return (
                        <div className={field.key+" preview-field"}>
                            <div className="preview-icon">{field.icon}</div>
                            <div className="preview-content">{item[field.key]}</div>
                        </div>
                    );
                }*/
            case 'location':
                if(item && item[field.key] && field.preview){
                    return (
                        <div className={styles.locationPreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>{item[field.key]}</div>
                        </div>
                    );
                }
            case 'onlinemeetingurl':
                if(item && item[field.key] && field.preview){
                    return (
                        <div className={styles.onlinemeetingurlPreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>
                                <Button onClick={()=>{this.openNewWindow(item[field.key])}} size="large" color="primary">
                                    {this.tr('Join meeting')}
                                </Button>
                            </div>
                        </div>
                    );
                }
            case 'attendees':
                if(item && item[field.key]){
                    let attendees = item[field.key];
                    let attendeeText = attendees.length + ' '+( attendees.length === 1 ? this.tr("guest") : this.tr("guests"));
                    return (
                        <div className={styles.attendeesPreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>{attendeeText}
                                <List>
                                    {attendees.map(row => (
                                        <ListItem alignItems="flex-start" key={row.value} dense >
                                            <TaimerAvatar
                                                name={row.name}
                                                color={row.color || null}
                                                noavatar
                                            />
                                            <ListItemText className="person" primary={row.name} secondary={row.value && <div>{row.value}</div>} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </div>
                    );
                }

            case 'calendar':
                if(item && item[field.key] && field.preview){
                    const calendar = item[field.key];
                    return (
                        <div className={styles.calendarPreviewField}>
                            <div className={styles.previewIcon}>{field.icon}</div>
                            <div className={styles.previewContent}>{calendar.label}</div>
                        </div>
                    );
                }
            /*
            default:
                if(item && item[field.key] && field.preview){
                    return (
                        <div className={field.key+" default-field"}>{item[field.key]}</div>
                    );
                }
            */ 
        }
    }

    renderFieldEdit = (field: EditableField) => {
        const { item } = this.state;

        if (item && field.isHidden && field.isHidden(item)) {
            return null;
        }
        if (field.nested?.length) {
            return (
                <div className={styles.nestedRow}>
                    {(field.nested || []).map((field) => {
                        return this.renderFieldEdit(field);
                    })}
                </div>
            );
        }
        switch (field.type) {
            case 'radio':
                return (
                    <div className={styles.radioRow}>
                        <Radio color="primary" name={field.key} onChange={this.onRadioChanged} checked={item && Number(item[field.key]) > 0} />
                        <p>{field.title}</p>
                    </div>
                );
            case 'select':
                return (
                    <DataList
                        //@ts-ignore
                        addNoneOption={field.addNoneOption}
                        label={field.title}
                        options={field.options}
                        value={item && item[field.key]}
                        onChange={(value) => this.onSelectChanged(field, value)}
                    />
                );
            case 'data_select':
                let val = field?.options?.find(o => o.id == item?.[field.key]);
                if (val == undefined && field.defaultValue)
                    val = field?.options?.find(o => o.id == field.defaultValue);
                return (
                    <DataList
                        //@ts-ignore
                        addNoneOption={field.addNoneOption}
                        label={field.title}
                        options={field.options}
                        value={item && val}
                        onChange={(value) => this.onSelectChanged(field, value.id)}
                    />
                );
            case 'multi_select':
                return (
                    <MultiSelect
                        options={field.options}
                        skipInitialChange={true}
                        defaultValue={item && val}
                        className="multi-select"
                        label={field.title}
                        onChange={(value) => this.onSelectChanged(field, value.id)}
                    />
                );
            case 'context_menu':
                let selected = field?.options?.find(o => o.value == item?.[field.key]);

                return (
                    <ContextMenu
                        //@ts-ignore
                        buttonProps={{
                            className: styles.optionsMenuButton,
                            stickyIcon: true,
                            size: 'large',
                        }}
                        variant="outlined"
                        className={styles.optionsMenu}
                        label={selected && selected?.label || field.title}
                        size="medium"
                        placement={'bottom-end'}
                    >
                        {(field.options || []).map((option) => {
                            return (
                                <MenuItem onClick={event => {
                                        if(option.function) option.function(event,item);
                                        else this.onMeetingLinkSelected({target:{value:option.value,name:field.key}});
                                    }}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </ContextMenu>
                );
            case 'list':
                return (
                    <List dense={true}>
                        {(item && item[field.key] || []).map((listItem) => {
                            return (<ListItem
                                button
                                selected={true}
                                onClick={(event) => this.handleListItemClick(event, listItem.value)}
                            >
                                <ListItemText primary={listItem.name} />
                            </ListItem>);
                        })}
                    </List>
                );
            case "contact-list":
                return (
                    <div className="contact-list" key="people-contacts">
                        {field.options && <DataList
                            //@ts-ignore
                            label={field?.label}
                            options={field?.options}
                            name="contact"
                            updateData={this.addContact}
                            onChange={this.addContact}
                            shownCount={20}
                            onKeyDown={this.addFreeContact} />
                        }
                        <List>
                            {(item && item[field.key] || []).map(row => (
                                <ListItem alignItems="flex-start" key={row.value} dense >
                                    <TaimerAvatar
                                        name={row.name}
                                        color={row.color || null}
                                        noavatar
                                    />
                                    <ListItemText className="person" primary={row.name} secondary={row.value && <div>{row.value}</div>} />
                                    <IconButton
                                        aria-label={this.tr("Remove")}
                                        onClick={() => { this.removeContact(row) }}
                                        size="large">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                );

            case 'checkbox':
                return (
                    <Checkbox
                        name={field.key}
                        disabled={field.disabled}
                        showCheckedEvenWhenDisabled
                        checked={item[field.key]}
                        onChange={(event) => {
                            this.onFieldEdited(event);
                            if (typeof field.toggleDisableKeys !== "undefined") this.disableToggler(field.toggleDisableKeys);
                        }
                        }
                        label={field.title}
                    />
                );
            case 'daterange':
                return (
                    <DateRangePicker
                        className={field.class}
                        ranges={[
                            {
                                startDate: item && item[field.startKey || 'startDate'],
                                endDate: item && item[field.endKey || 'endDate'],
                                key: 'selection',
                            },
                        ]}
                        onChange={(event) => this.onDateRangeChanged(field, event)}
                        onInputChange={(type, date) => this.onDateRangeInputChanged(field, type, date)}
                        label={field.title}
                        dateFormat={this.context.userObject.dateFormat}
                    />
                );
            case 'date':
                return (
                    <DatePicker
                        className="date full"
                        disabled={field.disabled}
                        date={item && !!item[field.key] && item[field.key] != "0000-00-00" ? item[field.key] : undefined}
                        onChange={(date) => this.onDateChanged(field.key, date)}
                        onInputChange={(_, date) => this.onDateInputChanged(field.key, date)}
                        label={field.title}
                        dateFormat={this.context.userObject.dateFormat}
                    />
                );
            case 'timerange':
                return (
                    <TimeRangeOutlinedField
                        rootClass="time-range"
                        label={field.title}
                        value={item && item[field.key]}
                        onChange={(value, start, end) => this.onTimeRangeChanged(field, value, start, end)}
                    />
                );
            case 'time':
                return (
                    <TimeOutlinedField
                        value={item && item[field.key]}
                        onChange={(event, value) => this.onTimeChanged(field.key, event.target.value)}
                        label={field.title}
                        name={field.key}
                        type="time"
                        disabled={field.disabled}
                        endAdornment={(<InputAdornment position="end"><Clock className="clock-icon" /></InputAdornment>)}
                    />
                );
            case 'textarea':
                return (
                    <OutlinedField
                        adornmentPos={field.adornmentPos}
                        adornment={field.adornment}
                        disabled={field.disabled}
                        validation={field.validation}
                        onChange={this.onFieldEdited}
                        label={field.title}
                        name={field.key}
                        value={item && item[field.key]}
                        multiline
                        rows={field.rows}
                        rowsMax={field.rowsMax}
                    />
                );
            case 'link':
                if(item && item[field.key] && item[field.key] !== ''){
                    let maxLength=60;
                    let link = item[field.key].length > maxLength ? 
                    item[field.key].substring(0, maxLength - 3) + "..." : 
                        item[field.key];
                    return (
                        <a href={link} target="_blank" className="pseudoLink">{link}</a>
                    );
                }  else break; 
            case 'removable_link':
                if(item && item[field.key] && item[field.key] !== ''){
                    return (
                        <div><span className="pseudoLink">{item[field.key]}</span><DeleteIcon className="removeLink" onClick={() => this.onFieldEdited({target:{value:'',name:field.key}})} /></div>
                    );
                }  else break;  
            case 'dialogdropdown':
                return (
                    <DialogDropDownButton className="white" title={field.title}>
                        {(field.options || []).map((option) => {
                            return (
                                option.show && <DialogDropDownButtonItem onClick={event => option.function(event)}>
                                    {option.title}
                                </DialogDropDownButtonItem>
                            );
                        })}
                    </DialogDropDownButton>
                );
            case 'repeatrule':
                return(
                    <RepeaterDialog className={"repeat-button"} setRecurrence={(rule)=> this.onFieldEdited({target:{value:rule,name:field.key}})} />
                );                
            default:
                return (
                    <OutlinedField
                        adornmentPos={field.adornmentPos}
                        adornment={field.adornment}
                        disabled={field.disabled}
                        validation={field.validation}
                        onChange={this.onFieldEdited}
                        label={field.title}
                        name={field.key}
                        value={item && item[field.key]}
                    />
                );
        }
    };

    onDeleteItem = () => {
        this.props.onDeleteItem && this.props.onDeleteItem(this.state.item);
    };

    onSave = () => {
        const { item } = this.state;
        let saveItem = cloneDeep(item)

        const fieldsWithDefaults = this.props.fields.filter(f => f.defaultValue);
        if (fieldsWithDefaults.length > 0) {
            fieldsWithDefaults.map(fd => {
                saveItem[fd.key] = saveItem[fd.key] || fd.defaultValue;
            });
        }
        this.props.onSave(saveItem);
    };

    render() {
        const { open, onClose, title, fields, canDeleteItem, custombuttonsbottom } = this.props;
        const { item } = this.state;

        return (
            <Drawer open={open} anchor="right" onClose={onClose} className={styles.slider} disableEnforceFocus /* This ensures that Dialogs' inputs are working. Remove when dialogs are not used anymore */>
                <div className={styles.contentSlider}>
                    <div className={styles.top}>
                        <h1>{title}</h1>
                    </div>
                    <div className={styles.fields}>
                        {(fields || []).map((field) => {
                            if(this.state.edit) return this.renderFieldEdit(field);
                            else return this.renderField(field);
                        })}
                    </div>
                    <div className={styles.actions}>
                        <Button onClick={onClose} variant="text" size="large">
                            {this.tr('Cancel')}
                        </Button>
                        { !this.state.edit && canDeleteItem && 
                           <Button onClick={this.onDeleteItem} size="large" color="secondary">
                                {this.tr('Delete')}
                            </Button>
                        }
                        { !this.state.edit && <Button onClick={()=>{this.setState({edit:true});}} size="large" color="primary">
                            {this.tr('Edit')}
                        </Button> }
                        { this.state.edit && (custombuttonsbottom || []).map((button) => {
                            return this.renderFieldEdit(button);
                        })}
                        { this.state.edit && <Button onClick={this.onSave} size="large" color="primary">
                            {this.tr('Save')}
                        </Button>}
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default ContentSlider;
