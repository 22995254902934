import React from 'react';
import TaimerComponent from '../TaimerComponent';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

/* context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import ReportsTab, { SwitchesGroup } from './ReportsTab';
import ContextMenu from '../general/ContextMenu';
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';
import { returnCurrencyList } from "./../general/Currencies";

/* css */
import './ReportsView.css';

/*material ui */
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';

/* material icons */
import ChevronRight from '@mui/icons-material/ChevronRight';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { FontDownload, Print, Autorenew, Cached, EuroSymbol } from '@mui/icons-material';

import ReportsDefinitions from './ReportsDefinitions';
/* data backend */
import DataHandler from './../general/DataHandler';
import Utils from "./../general/Utils.js";

import FileSaver from 'file-saver';
import { format } from 'date-fns';
import _ from 'lodash';
import DropdownTabs from '../navigation/DropdownTabs';
import PageTopSection from '../general/PageTopSection';
import cloneDeep from "lodash/cloneDeep";
import { withSnackbar } from 'notistack';

import { removeDuplicates } from "../list/ListUtils";

const defineReports = ReportsDefinitions.getDefinitions();
const currentReports = (selected) =>
    Object.entries(defineReports.reports[selected]).map(([k, r]) => {
        return { ...r, id: k };
    });    

const styles = (theme) => ({
    container: {
        padding: '8px 20px 23px 20px !important',
    },
    buttonContainer: {
        display: 'flex',
        height: '47px',
        alignItems: 'center',
    },
    button: {
        textTransform: 'uppercase',
    },
    printButton: {
        height: '100%',
        minWidth: '160px',
    },
    exportButton: {
        cursor: 'pointer',
        color: '#6b7897',
        fontSize: '14px',
        minWidth: '160px',
        display: 'flex',
        alignItems: 'center',
        minWidth: '140px',
    },
    info: {
        fontSize: '12px',
        color: '#a2abc5',
        marginRight: '16px',
    },
    cloudDownload: {
        margin: '0 12px 0 22px',
    },
    tabsContainer: {
        marginTop: '15px',
        marginBottom: '10px',
    },
    tab: {
        display: 'inline-block',
    },
});

class ReportsView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'reports/ReportsView');

        const { privileges } = context;

        this.reportPDFSettings = {
            company_report: {
                title: this.tr("Accounts Hours Report"),
                filename: "accounts_hours_report",
                columns: [
                    {
                        key: "users",
                        title: this.tr("User"),
                    },
                    {
                        key: "hours",
                        title: this.tr("Hours"),
                        align: "right",
                        maxWidth: 60,
                        roundToDecimals: 2,
                    },
                ],
                groupBy: "customers_id",
                groupTitleKey: "customers",
                subGroupBy: "projects_id",
                subGroupTitleKey: "projects",
                footerItems: [
                    {
                        title: this.tr("Hours"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ],
                headerItems: [
                    {
                        title: this.tr("Account Total"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ],
                subHeaderItems: [
                    {
                        title: this.tr("Project Total"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ]
            },
            customers_daily: {
                title: this.tr("Accounts by Jobtype Hours Report"),
                filename: "accounts_by_jobtype_hours_report",
                columns: [
                    {
                        key: "date",
                        title: this.tr("Date"),
                        maxWidth: 60,
                        formatDate: true
                    },
                    {
                        key: "users",
                        title: this.tr("User"),
                        maxWidth: 100
                    },
                    {
                        key: "description",
                        title: this.tr("Description"),
                    },
                    {
                        key: "work_types",
                        title: this.tr("Job Type"),
                        maxWidth: 130
                    },
                    {
                        key: "hours",
                        title: this.tr("Hours"),
                        align: "right",
                        maxWidth: 50,
                        roundToDecimals: 2,
                    },
                ],
                groupBy: "customers_id",
                groupTitleKey: "customers",
                subGroupBy: "projects_id",
                subGroupTitleKey: "projects",
                footerItems: [
                    {
                        title: this.tr("Hours"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ],
                headerItems: [
                    {
                        title: this.tr("Account Total"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ],
                subHeaderItems: [
                    {
                        title: this.tr("Project Total"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ]
            },
            employees_report: {
                title: this.tr("Employees by Project Hours Report"),
                filename: "employees_by_project_hours_report",
                columns: [
                    {
                        key: "customers",
                        title: this.tr("Account"),
                        maxWidth: 120,
                    },
                    {
                        key: "projects",
                        title: this.tr("Project")
                    },
                    {   key: "work_types",
                        title: this.tr("Job Type"),
                        maxWidth: 130
                    },
                    {
                        key: "hours",
                        title: this.tr("Hours"),
                        align: "right",
                        maxWidth: 50,
                        roundToDecimals: 2,
                    },
                ],
                groupBy: "users_id",
                groupTitleKey: "users",
                footerItems: [
                    {
                        title: this.tr("Hours"),
                        key: "hours",
                        roundToDecimals: 2,
                    },
                ]
            },
            employees_daily: {
                title: this.tr("Employees Daily Hours Report"),
                filename: "employees_daily_hours_report",
                columns: [
                    {
                        key: "date",
                        title: this.tr("Date"),
                        maxWidth: 60,
                        formatDate: true
                    },
                    {
                        key: "customers",
                        title: this.tr("Account"),
                        maxWidth: 100,
                    },
                    {
                        key: "projects",
                        title: this.tr("Project"),
                        maxWidth: 120,
                    },
                    {
                        key: "description",
                        title: this.tr("Description")
                    },
                    {   key: "work_types",
                        title: this.tr("Job Type"),
                        maxWidth: 100
                    },
                    {
                        key: "hours",
                        title: this.tr("Hours"),
                        align: "right",
                        maxWidth: 50,
                        roundToDecimals: 2,
                    },
                ],
                groupBy: "users_id",
                groupTitleKey: "users",
                footerItems: [
                    {
                        title: this.tr("Days"),
                        key: "total_days",
                        roundToDecimals: 2,
                    },
                    {
                        title: this.tr("estimated days"),
                        key: "estimated_days",
                        roundToDecimals: 2,
                    },
                    {
                        title: this.tr("Overtime h"),
                        key: "overtime",
                        roundToDecimals: 2,
                    },
                    {
                        title: this.tr("Estimated h"),
                        key: "estimated_hours",
                        roundToDecimals: 2,
                    },
                    {
                        title: this.tr("Tracked h"),
                        key: "total_hours",
                        roundToDecimals: 2,
                    },
                ]
            },
            hour_balance: {
                title: this.tr("Hour balance report"),
                filename: "hour_balance_report",
                headerDateMode: "day",
                hideTotals: {
                    "current_balance": true
                },
                columns: [
                    {
                        key: "users",
                        title: this.tr("User"),
                        maxWidth: 120,
                    },
                    {
                        key: "team",
                        title: this.tr("Team")
                    },
                    {   key: "expected_hours",
                        title: this.tr("Expected hours"),
                        maxWidth: 90,
                        roundToDecimals: 2,
                    },
                    {
                        key: "monthly_hours",
                        title: this.tr("Hours This Month"),
                        align: "right",
                        maxWidth: 110,
                        roundToDecimals: 2,
                    },
                    {
                        key: "current_balance",
                        title: this.tr("Balance"),
                        align: "right",
                        maxWidth: 70,
                        roundToDecimals: 2,
                    },
                ],
            },
            invoice_rate: {
                title: this.tr("Invoice Rate Hours Report"),
                filename: "invoice_rate_hours_report",
                hideTotals: {
                    "hours": true,
                    "missed_hours": true,
                    "invoiceable_hours": true,
                    "non_invoiceable_hours": true,
                },
                columns: [
                    {
                        key: "users",
                        title: this.tr("User"),
                    },
                    {   key: "missed_hours",
                        title: this.tr("Absence"),
                        maxWidth: 60,
                        roundToDecimals: 2,
                    },
                    {
                        key: "invoiceable_hours",
                        title: this.tr("Billable"),
                        align: "right",
                        maxWidth: 60,
                        roundToDecimals: 2,
                    },
                    {
                        key: "non_invoiceable_hours",
                        title: this.tr("Non-Billable"),
                        align: "right",
                        maxWidth: 80,
                        roundToDecimals: 2,
                    },
                    {
                        key: "done_invoiceable",
                        title: this.tr("Billable rate"),
                        align: "right",
                        maxWidth: 60,
                        roundToDecimals: 2,
                        useAverage: true
                    },
                    {
                        key: "invoiceable_expected",
                        title: this.tr("Billable rate - absence"),
                        align: "right",
                        maxWidth: 120,
                        roundToDecimals: 2,
                        useAverage: true
                    },
                ],
            },
            overtime_report: {
                title: this.tr("Overtime Hours Report"),
                filename: "overtime_hours_report",
                columns: [
                    {
                        key: "date",
                        title: this.tr("Date"),
                        maxWidth: 60,
                        formatDate: true
                    },
                    {
                        key: "customers",
                        title: this.tr("Account"),
                        maxWidth: 150
                    },
                    {   key: "projects",
                        title: this.tr("Project"),
                    },
                    {
                        key: "expected_hours",
                        title: this.tr("Expected"),
                        align: "right",
                        maxWidth: 50,
                        roundToDecimals: 2,
                    },
                    {
                        key: "overtime_hours",
                        title: this.tr("overtime"),
                        align: "right",
                        maxWidth: 50,
                        roundToDecimals: 2,
                    },
                    {
                        key: "hours_multiplied",
                        title: this.tr("Hours multiplied"),
                        align: "right",
                        maxWidth: 90,
                        roundToDecimals: 2,
                    },
                ],
                groupBy: "users_id",
                groupTitleKey: "users",
                footerItems: [
                    {
                        title: this.tr("overtime"),
                        key: "overtime_hours",
                        roundToDecimals: 2,
                    },
                    {
                        title: this.tr("Hours multiplied"),
                        key: "hours_multiplied",
                        roundToDecimals: 2,
                    },
                ]
            },
        }
        
        this.tabs = [];
        if (privileges.reports) {
            this.tabs = this.tabs.concat(
                [
                    { id: 'hourReports', label: this.tr('Hours'), rightString: 'read+read_not_special+read_own_subjects+read_own_projects+ownCompany' },
                    { id: 'invoicing', label: this.tr('Invoicing'), rightString: 'invoicing' },
                    { id: 'sales', label: this.tr('Sales'), rightString: 'invoicing' },
                    { id: "billingReports", label: this.tr("Bills"), rightString: 'subcontracting' },
                    { id: 'productReports', label: this.tr('Products'), rightString: 'products' },
                    { id: "recognitionReports", label: this.tr("Recognition"), rightString: 'recognition' },
                    { id: "profitabilityReports", label: this.tr("Profitability"), rightString: 'profitability'},
                    { id: "forecastReports", label: this.tr("Forecast"), rightString: 'forecast'},
                    ...(this.context.addons.nav ? [{ id: 'wintime', label: this.tr('Wintime'), rightString: 'invoicing' }] : []),
                    { id: 'chiefReports', label: this.tr('Chief reports'), rightString: 'read_chief_reports+read_chief_subjects' },
                ].filter((t) => {
                    if (t.rightString === 'ownCompany') {
                        return true;
                    }
                    let ar = t.rightString.split('+');
                    for (let i = 0; i < ar.length; i++) {
                        if (privileges.reports[ar[i]]) {
                            return true;
                        }
                    }
                    return false;
                })
            );
        }

        if (!this.tabs.find((t) => t.id == 'hourReports')) this.tabs = this.tabs.concat([{ id: 'hourReports', label: this.tr('Hours'), rightString: 'ownCompany' }]);

        let selectedTab = this.props.selectedTab ? this.props.selectedTab : this.tabs[0].id;

        if (this.tabs.findIndex(t => t.id == selectedTab) == -1) {
            selectedTab = this.tabs[0].id;
        }

        this.exportNames = {
            company_report: this.tr("Account report"),
            customers_daily: this.tr("Accounts daily report"),
            employees_report: this.tr("Employees report"),
            employees_daily: this.tr("Employees daily report"),
            hour_balance: this.tr("Hour balance report"),
            invoice_rate: this.tr("Invoice rate report"),
            products_by_product: this.tr("Product report"),
            products_by_customer: this.tr("Products by customer report"),
            cpq_by_cpq: this.tr("CPQ report"),
            cpq_by_customer: this.tr("CPQ by customer report"),
            "invoicing_vs_subcontracting": this.tr("Costs period report"),
            "invoicing_vs_subcontracting_bills": this.tr("Invoicing period report"),
            "billing_rate_by_customers": this.tr("Invoice degree by customer report"),
            "billing_rate_by_users": this.tr("Invoice degree by user report"),
            "invoices_summary": this.tr("Invoices summary report"),
            "invoices_summary_with_rows": this.tr("Invoices summary with rows report"),
            "overtime_report": this.tr("Overtime report report"),
            "revenue_recognition_by_account": this.tr("Revenue recognition by account report"),
            "revenue_recognition_by_user": this.tr("Revenue recognition by user report"),
            "revenue_recognition_by_user_and_project": this.tr("Revenue recognition by user and project report"),
            "profitability_by_account": this.tr("Profitability by account report"),
            "profitability_by_project": this.tr("Profitability by project report"),
            "profitability_on_recognitions_by_account": this.tr("profitability_on_recognitions_by_account"),
            "profitability_on_recognitions_by_project" : this.tr("profitability_on_recognitions_by_project"),
            "scheduled_invoicing_by_account": this.tr("Scheduled invoicing by account report"),
            "scheduled_invoicing_by_project": this.tr("Scheduled invoicing by project report"),
            "monthly_invoicing_by_account": this.tr("Accounts monthly"),
            "monthly_invoicing_by_project": this.tr("Projects monthly"),
            "wintime_unfinished_stock": this.tr("Wintime unfinished stock"),
            "gross_profit_forecast": this.tr("Gross profit forecast report"),
            "gross_profit_forecast_by_account": this.tr("Gross profit forecast by account"),
            "gross_profit_forecast_by_project": this.tr("Gross profit forecast by project"),
            "gross_profit_forecast_on_recognitions": this.tr("gross_profit_forecast_on_recognitions"),
            "gross_profit_forecast_on_recognitions_by_account": this.tr("gross_profit_forecast_on_recognitions_by_account"),
            "gross_profit_forecast_on_recognitions_by_project": this.tr("gross_profit_forecast_on_recognitions_by_project"),
            projects_backlog: this.tr("Backlog"),
            bills_overview: this.tr("Bills overview"),
            bills_by_category: this.tr("Bills by category"),
            bills_by_supplier: this.tr("Bills by supplier"),
            "sales_overview": this.tr("Sales overview"),
        };

        this.exportTranslations = [
            {name: "approved", translation: this.tr("approved")},
            {name: "not approved", translation: this.tr("not approved")},
            {name: "rejected",  translation: this.tr("rejected")},
            {name: "gross profit forecast",  translation: this.tr("gross profit forecast")},
            {name: "cumulative forecast",  translation: this.tr("cumulative forecast")},
            {name: "invoicing total",  translation: this.tr("invoicing total")},
            {name: "invoicing forecast",  translation: this.tr("invoicing forecast")},
            {name: "invoicing actualized",  translation: this.tr("invoicing actualized")},
            {name: "revenue recognition gross profit forecast",  translation: this.tr("revenue recognition gross profit forecast")},
            {name: "revenue recognition cumulative forecast",  translation: this.tr("revenue recognition cumulative forecast")},
            {name: "recognition total",  translation: this.tr("recognition total")},
            {name: "recognition",  translation: this.tr("recognition")},
            {name: "costs total",  translation: this.tr("costs total")},
            {name: "costs forecast",  translation: this.tr("costs forecast")},
            {name: "costs actualized",  translation: this.tr("costs actualized")},
            {name: "subsidiary_subunit",  translation: this.tr("Subsidiary subunit")},
            {name: "parentaccount_subunit",  translation: this.tr("Parent account subunit")},
            {name: "parentaccount",  translation: this.tr("Parent account")},
            {name: "subsidiary",  translation: this.tr("Subsidiary")},
            {name: "main_project",  translation: this.tr("Main project")},
            {name: "project_level",  translation: this.tr("Subproject level")},
            {name: "locked",  translation: this.tr("Locked")},
            {name: "active",  translation: this.tr("Active")},
            {name: "won_deals",  translation: this.tr("Won Deals")},
            {name: "internal",  translation: this.tr("Internal")},
            {name: "Actual costs",  translation: this.tr("Actual costs")},
            {name: "Purchase orders",  translation: this.tr("Purchase orders")},
            {name: "Manually added cost rows",  translation: this.tr("Manually added cost rows")},
            {name: "lost deals",  translation: this.tr("Lost deals")},
            {name: "win rate",  translation: this.tr("Win rate")},
            {name: "added to pipeline total",  translation: this.tr("Added to pipeline total")},
            {name: "pipeline active total",  translation: this.tr("Pipeline active total")},
            {name: "pipeline active total by probability",  translation: this.tr("Pipeline active total by probability")},
            {name: "won deals",  translation: this.tr("Won Deals")},
        ];

        let currentReport = this.props.selectedReport || currentReports(selectedTab).find((f) => f.is_default > 0).id;
        if (currentReports(selectedTab).findIndex(t => t.id == currentReport) == -1) {
            currentReport = currentReports(selectedTab).find((f) => f.is_default > 0).id;
        }

        // let fetchButtonState = 0;
        // try {
        //    fetchButtonState = localStorage.getItem('reports_list_fetch_button_state') ?? 0;
        // } catch (e) {
        //     console.error(e);
        // }        

        this.currencySymbolsMap = returnCurrencyList().reduce((acc, cl) => ({...acc, [cl.id]: cl.symbol}), {});
        if (this.context.addons.invoice_currency)
            this.currencies = this.activeCurrencies = [];

        this.state = {
            tabs: [...this.tabs],
            selectedTab: selectedTab || '',
            pageTotal: 0,
            queryTotal: 0,
            reportCount: 0,
            currentReport,
            companies: [],
            company: props.company,
            companyArray: [],
            currency: context.taimerAccount.currency,
            currencySymbol: this.currencySymbolsMap[context.taimerAccount.currency],
            generatingPDF: false,
            multiCompany: true,
            fetchWithButton: true,
            /*fetchWithButton: fetchButtonState < 1 ? false : true,*/
            summaries: {},
            switches: {}
        };

        this.tab = React.createRef();
        this.updateComponentData = this.updateComponentData.bind(this);
       
    }

    setSelectedReport = (selectedReport, tabId) => {
        if (tabId != this.state.selectedTab) {
            this.toggleTab(tabId, selectedReport, true);
            return;
        }
        this.setState({ currentReport: selectedReport }, () => {
            this.tab.current.searchTerms = this.tab.current.getInitialSearchTerms();
            this.props.updateView({ selectedReport });
            this.tab.current && this.tab.current.changeReportType(selectedReport);
        });
    };

    getReportsForTabId = (tabId) => {
        const defineOptions = ReportsDefinitions.getDefinitions();
        const addons = this.context.addons;
        const useshoursExportAddons = Object.keys(addons).find((a) => ["mepco", "procountor"].indexOf(a) > -1) ? true : false;
        let reports = Object.entries(defineOptions.reports[tabId]).map(([k, r]) => {
            if(addons.hide_billing_rate_by_user_report && tabId == "invoicing" && k == "billing_rate_by_users") {
                return undefined;
            }
            if(r.addon_dependancies?.length > 0) {
                let check= r.addon_dependancies.some(d => addons[d]);
                if (!check)
                    return undefined;
            }            
            if(tabId == "hourReports" && k == "hours_export" && !useshoursExportAddons) {
                return undefined;
            }
            return { ...r, id: k, name: this.tr(r.name), url: { module:'reports', action: 'view', selectedTab: tabId, selectedReport: k }, action: () => this.setSelectedReport(k, tabId) };
        });
        reports = reports.filter(e => e !== undefined);
        return reports;
    };

    updateComponentData(pageTotal, queryTotal, currentReport, summaries, reportCount) {
        this.setState({
            pageTotal: pageTotal,
            queryTotal: queryTotal,
            currentReport: currentReport,
            summaries,
            reportCount
        });
    }
    componentDidMount() {
        DataHandler.get({url: 'subjects/companies'}).done(response => this.setState({multiCompany: response.length > 1}));

        if (this.context.addons.invoice_currency)
            this.getCurrencies();

        DataHandler.get({url: `subjects/companies/reports/${this.tabs.find(t => t.id == this.state.selectedTab).rightString}`, currency: 1}).done(
            companies => {
                const company = this.props.preset_company ? companies.find(c => c.id == this.props.preset_company) : companies.reduce((acc, c) => {if (c.id < acc.id) acc = c; return acc});
                this.setState({
                    companies: companies.map(c => ({...c, value: c.id, label: c.name})), company: company.id, currency: company.currency
                }, () => {
                    if (this.context.addons.invoice_currency)
                        this.activeCurrencies = this.currencies?.filter(cr => this.state.companies.map(c => c.id).includes(cr.companies_id));
                    this.tab.current.resetFilters(null, false);
                });
            }
        );

    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.selectedTab != this.props.selectedTab) {
            this.toggleTab(this.props.selectedTab);
        }
    }

    //print functions
    printReport = () => {};

    //export functions
    export = (target) => {
        const stateLoadingKey = `${target}Loading`;
        this.setState({ [stateLoadingKey]: true }, () => {
            const tab = this.tab.current;
            const selectedQuery = tab.state.selectedQuery;
            const selectedType = tab.state.selectedType;
            const currentReportOptions = defineReports.reports[this.state.selectedTab][selectedType];

            let columns = [];
            let accountColumnIndex = null;
            let accountCustomColumns = [];            
            // let storedOrder = cloneDeep(JSON.parse(localStorage.getItem(`taimer_list_settings_for_reports_${this.state.currentReport}`)));
            //jos on dynaamisia sarakeita yli yhden, niin pitää resettaa sarakejärjestys, muuten ei export välttämättä toimii
            if (Object.entries(currentReportOptions.dynamicColumnsDefinitions || {}).length > 1)
                columns = [...currentReportOptions.column_order];
            else {
                let columnOrder = tab.list.current?.columnOrder;
                if (columnOrder && columnOrder.length > 0) {
                    let firstOccurance = false;
                    columnOrder = columnOrder.filter((co, i) => {
                        //dynaamiset sarakkeet ensin pois
                        if (currentReportOptions.column_order.includes(co))
                            return true;
                        else {
                            firstOccurance = !firstOccurance ? i : firstOccurance;
                            return false;
                        }
                    });
                    //ja ekan dynaamisen tilalle sen placeholder
                    if (currentReportOptions.dynamicColumnsDefinitions)
                        columnOrder.splice(firstOccurance, 0, Object.entries(currentReportOptions.dynamicColumnsDefinitions)[0][0]);
                    columns = columnOrder;
                }
                else
                    columns = [...currentReportOptions?.column_order]; //fallback
            }           

            //removing what is not visible
            // if (storedOrder)
            //     columns = columns.filter(c => !storedOrder?.find(st => st.name == c) || storedOrder.find(st => st.name == c).visible);

            let exportHeaders = [];
            let filteredColumns = [];
            let params;

            _.forEach(columns, column => {
                _.forEach(defineReports.fields, (field, i) => {
                    if (column == field.name) {
                        const conditions = field.filterConditions?.filter(f =>  f.type == selectedType)?.reduce((acc, v) => {v.filters.forEach(e => acc[e.name] = e.value); return acc}, {});
                        if (Object.entries(conditions || {}).every(([name, value]) => Array.isArray(value) ? 
                            value.includes(tab.state[name]) || value.includes(tab.state.filterValues[name]) || value.includes(tab.state.switches[name])
                            :
                            tab.state[name] == value || tab.state.filterValues[name] == value || tab.state.switches[name] == value || tab.props[name] == (JSON.parse(value) || value))
                            ) {
                            exportHeaders.push(tab.tr(field.header) || ' ');
                            filteredColumns.push(column);
                        }
                    }
                });
            });

            const accountCustomFields = this.tab.current.state.companySettings?.account_custom_fields;
            filteredColumns.forEach((c, i) => {
                if (c === 'customers' && accountCustomFields?.length > 0) {
                    accountColumnIndex = i+1;
                    accountCustomColumns = accountCustomFields?.map(acf => ({
                        field: acf.name?.replaceAll(' ', '_').toLowerCase(),
                        header: this.state.companyArray?.length > 1 ? `${acf.name} (${this.state.companies.find(c => c.id == acf.companies_id)?.name})` : acf.name,                     
                    }));
                }
            });

            accountCustomColumns?.length > 0 && accountColumnIndex > 0 && accountCustomColumns.forEach((ac, i) => {
                filteredColumns.splice(accountColumnIndex, 0, ac.field);
                exportHeaders.splice(accountColumnIndex, 0, ac.header);
                accountColumnIndex ++;
            });

            // There's a bug in the above code block, where duplicate column names
            // sometimes end up in filteredColumns (same for exportHeaders). 
            // Rather than trying to understand what's going on there,
            // and potentially messing something up, I'll remove the duplicates like this.
            filteredColumns = removeDuplicates(filteredColumns);
            exportHeaders   = removeDuplicates(exportHeaders);

            let useFinancialYear = false;
    
            //mahdolliset dynaamiset sarakkeet
            if (currentReportOptions.dynamicColumnsDefinitions) {
                if (currentReportOptions.dynamicColumnsDefinitions.time_span == "financial_year") {
                    useFinancialYear = true;
                }
                Object.entries(currentReportOptions.dynamicColumnsDefinitions).forEach(([originalColumn, dataClass]) => {
                    const index = filteredColumns.findIndex(c => c == originalColumn);
                    if (index < 0)
                        return;

                    const baseColumn = cloneDeep(filteredColumns[index]);                    



                    let dc = tab.state.autoCompleteData[dataClass]?.length > 0 ? cloneDeep(tab.state.autoCompleteData[dataClass]) : undefined;
                    let insertColumns = undefined, headers = undefined;
                    let op;

                    if ((op = currentReportOptions.dynamicColumnHeaderOperators?.find(o => o.definition == originalColumn)) && dc) {
                        let reorderedDataClass = (op.reorderToTreeStructure ? Utils.treeFormatDataForList(dc).map(dc => dc.data) : dc).map(r => ({...r, name: `${r.name} (${r.id})`}));

                        if (op.filterByDataValues) {
                            const existingDataColumns = [...new Set(this.tab.current.state.data.reduce((acc, d) => [...acc, ...Object.keys(d)], []))];
                            reorderedDataClass = reorderedDataClass.filter(r => existingDataColumns.includes(r.name));
                        }

                        const {processedHeaders, reorderedIds} = op.operator(reorderedDataClass, this.tab.current.translations);
                        headers = processedHeaders?.reverse();
                        reorderedDataClass = reorderedIds.map(id => reorderedDataClass.find(dc => dc.id == id));
                        dc = reorderedDataClass;
                    }

                    if (typeof dc === 'object' && dc !== null)
                        insertColumns = dc?.map(c => c.name || c).reverse();
                    else
                        insertColumns = dc?.reverse();

                    insertColumns && insertColumns.forEach((nc, i) => {
                        filteredColumns.splice(index, (i==0 ? 1 : 0), nc);
                        exportHeaders.splice(index, (i==0 ? 1 : 0), headers?.[i] || this.tr(nc));
                    })
                })
            }
    
            const exportAdditionalHeaders = currentReportOptions.exportHeaders; 
    
            if (exportAdditionalHeaders?.length > 0) {
                filteredColumns = [...filteredColumns, ...exportAdditionalHeaders];
                exportHeaders = [...exportHeaders, ...exportAdditionalHeaders.map((e) => this.tr(e))];
            }

            if (selectedQuery) {
                const savedQuery = tab.state.savedQueries.find((item) => item.id == selectedQuery);
                params = JSON.parse(savedQuery.querystring);
                if (params.advanced_search_criteria)
                    params.advanced_search_criteria = JSON.stringify(params.advanced_search_criteria);
                const { dateRange: {startDate, endDate} } = tab.state;             
                params['startDate'] = format(startDate, 'YYYY-MM-DD');
                params['endDate'] = format(endDate, 'YYYY-MM-DD');
    
                //TAIM9-2218 asiakkaan report export hulabaloo purkkaa
                if (!params.company) params['company'] = JSON.parse(savedQuery.companies_id);
                if (!params.user) {
                    params['user'] = { usersId: this.context.userObject.usersId };
                }
            } else {

                params = tab.collectParams();
                params.company = this.state.companyArray.length > 0 ? this.state.companyArray : [this.state.company];

                currentReportOptions.switches && currentReportOptions.switches.forEach(sw => {
                    if (sw.notUsedInExport)
                        params[sw.id] = null;
                });
    
                params.user = this.context.userObject;
                params.currency = this.state.currency;
                params.export = 'export';
            }

            let dateRange = `${moment(params.startDate).format(this.context.userObject.dateFormat)} - ${moment(params.endDate).format(this.context.userObject.dateFormat)}`;
            let day = `${moment(tab.state.filterValues.day).format(this.context.userObject.dateFormat)}`;
            if (useFinancialYear && params.financialYearRange && params.financialYearRange.length == 2) {
                dateRange = `${moment(params.financialYearRange[0]).format(this.context.userObject.dateFormat)} - ${moment(params.financialYearRange[1]).format(this.context.userObject.dateFormat)}`;
            }
            params.day = day;
            let dateString = '';
            switch (this.state.currentReport) {
                case 'wintime_unfinished_stock':
                case 'hour_balance':
                    dateString = `${(this.exportNames[selectedType])}_${day}`;
                    break;
                default:
                    dateString = `${(this.exportNames[selectedType])}_${dateRange}`;
            }
            const filename = `${(this.exportNames[selectedType])}_${dateString}`.toLowerCase().replace(/ /g, "_").replace(/ä/g, "a").replace(/ö/g, "o").replace(/å/g, "a");
            DataHandler.postArrayBuffer({ url: `reports/${selectedType}_export` }, { ...params, filename, dateRange, reportTitle: this.exportNames[selectedType], order: filteredColumns, exportHeaders: exportHeaders, target: target, translations: this.exportTranslations }).done((response) => {
                let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
                if (target == 'csv') {
                    type = 'text/csv';
                }
                var blob = new Blob([response], {
                    type,
                });
    
                FileSaver.saveAs(blob, `${filename}.${target}`);
                this.setState({ [stateLoadingKey]: false });
            }).fail(() => {
                this.setState({ [stateLoadingKey]: false });
            });
        });
    };

    toggleTab = (tab, report = null, keepSelectedCompany = false) => {
        const tabName = (typeof tab == 'object') ? tab.id : tab;
        const reports = currentReports(tabName);
        const currentReport = report || (reports.find((r) => r.id == this.state.currentReport) || reports.find((f) => f.is_default > 0))?.id;
        this.tab.current.setState({ data: [], dataIsSet: false, selectedTab: tabName, currentReport, dataIsFetching: this.state.fetchWithButton ? this.tab.current.dataIsFetching : true, autoCompleteDataFilters: {} });

        this.setState({ selectedTab: tabName, currentReport: currentReport }, () => {
            DataHandler.get({ url: `subjects/companies/reports/${this.tabs.find((t) => t.id == tabName).rightString}`, currency: 1 }).done((response) => {
                const comp = response.map((r) => r.id).includes(this.state.company) ? this.state.company : response[0].id;
                let curr = response.find((r) => r.id == comp);
                if (curr == undefined) curr = response[0].currency;
                else curr = curr.currency;
                this.props.updateView({ selectedTab: tabName, selectedReport: currentReport });
                this.setState(
                    {
                	companies: response.map(c => ({...c, value: c.id, label: c.name})), 
                    company: keepSelectedCompany ? this.state.company : comp,
	                companyArray: keepSelectedCompany ? this.state.companyArray : [],
                    currency: curr,
                    currencySymbol: this.currencySymbolsMap[curr]
                    },
                    () => {
                        if (this.context.addons.invoice_currency)
                            this.activeCurrencies = this.currencies?.filter(cr => this.state.companies.map(c => c.id).includes(cr.companies_id));
                        this.tab.current.resetFilters(null, false);
                    }
                );
            });
        })
    };

    setCompany = (id, report) => {
        if (Array.isArray(id)) {
            if (this.state.companyArray == id) return false;
            this.setState({companyArray: id}, () => id.length == 0 ? null : this.toggleTab(this.state.selectedTab, report, true));
        }
        else {
            if (this.state.company == id) return false;
            this.setState({company: id}, () => this.toggleTab(this.state.selectedTab, report, true));
        }
        this.tab.current.loadSettingsForCompanies(id);
    }

    printCurrentHourReportPDF = () => {
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const snackbarKey = enqueueSnackbar(this.tr("Generating PDF..."), {
            variant: "info",
            persist: true
        });
        this.setState({generatingPDF: true}, () => {
            const { currentReport } = this.state;
            const params = this.tab.current.collectParams({}, false);
            params.user = this.context.userObject;
            const reportSettings = this.reportPDFSettings[currentReport] || {};
            reportSettings.translations = {
                grandTotalTitle: this.tr("Grand total"),
                totalTitle: this.tr("Total"),
            }
            params.reportSettings = reportSettings;
            params.debug = process.env.NODE_ENV !== 'production';
            const request = (process.env.NODE_ENV !== 'production') ? DataHandler.post({ url: `reports/pdf/${currentReport}` }, params) : DataHandler.postArrayBuffer({ url: `reports/pdf/${this.state.currentReport}` }, params);
            
            request.done((response) => {
                closeSnackbar(snackbarKey); 
                this.setState({ generatingPDF: false });
                if (process.env.NODE_ENV !== 'production') {
                    const printWindow = window.open("", "PRINT");
                    printWindow.document.body.innerHTML = response.html;
                    printWindow.focus();
                } else {
                    var blob = new Blob([response], {
                        type: 'application/pdf'
                    });
                    FileSaver.saveAs(blob, `${reportSettings.filename || reportSettings.title.toLowerCase().replace(/ /g, '_')}.pdf`);
                }
            }).fail(() => {
                this.setState({ generatingPDF: false });
                closeSnackbar(snackbarKey); 
                enqueueSnackbar(this.tr("Generating PDF failed!"), {
                    variant: "error"
                });
            });
        })
    }

    resetSwitches = () => {
        this.setState({switchesKey: this.state.switchesKey + Math.floor(Math.random() * 10) + 1 });
    }

    getCurrencies = () => {
        this.currencies  = [];
        DataHandler.get({url: `invoices/currency_rates`, company: 'all'}).done(currencies => {
            this.currencies = Object.entries(currencies)?.map(([id, cr]) => ({
                companies_id: id, rates: cr.filter(c => c.active_status == 'active').map(c => ({rateId: c.id > 0 ? c.id : undefined, label: c.currency_label, rate: c.currency_rate, symbol: this.currencySymbolsMap[c.currency_label], rate_reverse: c.currency_rate_reverse}))    
            }));
        }); 
    }

    changeActiveCurrency = (currency) => {
        this.setState({currency: currency, currencySymbol: this.currencySymbolsMap[currency]}, () => {
            this.tab.current.setState({data: []});
        });
    }

    render() {
        const { tr, context } = this;

        const { selectedTab, tabs, pageTotal, queryTotal, currentReport, companies, company, companyArray, multiCompany, currency, generatingPDF, summaries, reportCount, fetchWithButton } = this.state;
        const { updateView, classes } = this.props;
        const { taimerAccount } = this.context;

        const thisReport = defineReports.reports[selectedTab][currentReport];
        const totalsUnit = thisReport?.totalsunit || 'h';

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
        }).format;

        const tabProps = {
            ...this.props,
            currency: currency,
            currencies: this.context.addons.invoice_currency ? this.currencies : null,
            currencySymbol: this.state.currencySymbol, 
            tabContent: selectedTab,
            selectedReport: currentReport,
            perpage: 30,
            companies: companies,
            company: company,
            companyArray: companyArray,
            multiCompany: multiCompany,
            fetchButton: fetchWithButton,
            /*callbacks */
            valueChanged: this.accountDataChanged,
            onCompanyChange: this.setCompany,
            onSwitchChange: (switches) => this.setState({switches: switches})
        };
        const currentReportOptions = defineReports.reports[selectedTab][currentReport];

        const currentSwitches = this.tab.current && currentReportOptions.switches?.map((sw) => {
            let disabled = false;
            let removed = false;
            if (!sw.switchConditions)
                return { id: sw.id, name: this.tr(sw.name), disabled: disabled, indent: sw.indent };

            sw.switchConditions.forEach((swc, i) => {
                if (swc.conditionType == 'switch' && this.tab.current.state.switches[swc.name] !== swc.value)
                    disabled = true;
                if (swc.conditionType == 'addon' && !this.context.addons[swc.name])
                    removed = true
                if (swc.conditionType == 'state' && this.state[swc.name] !== swc.value)
                    removed = true;

            });
            if (removed)
                return false;
            return { id: sw.id, name: this.tr(sw.name), disabled: disabled, indent: sw.indent };
        }).filter(sw => sw);


        const currentCurrencies = () => {
            let rates = Object.entries(this.activeCurrencies)?.reduce((acc, [i,comp]) => {
                return [...acc, ...comp.rates.filter(rr => !acc.includes(rr.label)).map(r => r.label)];
            }, []);
            Object.values(this.activeCurrencies).forEach(ac => {
                rates.forEach((r, i) => {
                    if (!ac.rates.map(cr => cr.label).includes(r))
                        rates.splice(i, 1);
                });  
                
            });
            return rates;
        };
        
        const CurrencySymbolIcon = (props) => React.createElement('h1', {className: 'reports-view-currency-icon'}, props.content);

        const mainTabs = tabs.map((t) => ({ ...t, url: { module:'reports', action: 'view', selectedTab: t.id }, items: this.getReportsForTabId(t.id) }));
        const currentTabItems = (mainTabs.find(t => t.id == selectedTab) || {}).items || [];
        
        const summariesArr = currentReportOptions?.getSummaries !== undefined ? currentReportOptions.getSummaries(summaries).map(x => ({
            title: tr(x.title),
            value: 
                x.type === 'currency' ? currencyFormatter(x.value) : (
                x.type === 'date' ? format(x.value, this.context.userObject.dateFormat) : 
                x.value),
        })) : (!thisReport.noPagination && currentReport != 'hour_balance'
            ? [
                { title: tr('Page total'), value: totalsUnit === 'symbol' ? currencyFormatter(pageTotal || 0) : `${pageTotal || 0} ${totalsUnit}` },
                { title: tr('Grand total'), value: totalsUnit === 'symbol' ? currencyFormatter(queryTotal || 0) : `${queryTotal || 0} ${totalsUnit}` },
            ]
            : [])

        return (
            <div className={`${classNames(classes.container)} contentBlock`} id="reports-view">
                {false && (
                    <div className={classNames(classes.buttonContainer)}>
                        {(currentReport != 'hours_export' && selectedTab != 'chiefReports') && (
                            <span className={classNames(classes.exportButton, classes.button)} onClick={selectedTab != '' ? () => this.export('xlsx') : undefined}>
                                <CloudDownload className={classNames(classes.cloudDownload)} />
                                <span>{tr('EXPORT')}</span>
                            </span>
                        )}
                        {thisReport.noPagination ? undefined : currentReport !== 'hour_balance' ? (
                            <div className="total-wrapper">
                                {totalsUnit === 'symbol' ? (
                                    <div>
                                        <span className={classNames(classes.info)}>
                                            {tr('Page total')}: {currencyFormatter(pageTotal)}{' '}
                                        </span>
                                        <span className={classNames(classes.info)}>
                                            {tr('Grand total')}: {currencyFormatter(queryTotal)}{' '}
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        <span className={classNames(classes.info)}>
                                            {tr('Page total')}: {pageTotal} {totalsUnit}
                                        </span>
                                        <span className={classNames(classes.info)}>
                                            {tr('Grand total')}: {queryTotal} {totalsUnit}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : undefined}
                        {/*selectedTab != "mepcoReports" && <div className="total-wrapper">
                        <span className={classNames(classes.info)} >{currentReport !== "hour_balance" ? tr("Page total") : tr("Expected total")}: {pageTotal} {totalsUnit}</span>
                        <span className={classNames(classes.info)} >{currentReport !== "hour_balance" ? tr("Grand total") : tr("Month total")}: {queryTotal} {totalsUnit}</span>
                        </div> */}
                    </div>
                )}

                {/* <div className={classNames(classes.tabsContainer)} >
                    <Tabs color="primary" className="tab-container" value={selectedTab} onChange={(evt, selectedTab) => !evt.ctrlKey && this.toggleTab(selectedTab)} indicatorColor="primary" >
                        {tabs.map(e => !e.hidden && (
                            <Tab 
                                key={e.id} 
                                value={e.id} 
                                label={e.label} 
                                disabled={!e.id} 
                                className={`tab ${selectedTab != e.id ? "not-selected" : ""}`} 
                                href={this.context.functions.urlify({...this.props.viewProps, selectedTab: e.id})} 
                                onClick={(evt) => !evt.ctrlKey && evt.preventDefault()} 
                            />))}
                    </Tabs>
                </div> */}
                <DropdownTabs
                    containerPadding={20}
                    height={40}
                    parentTabStyling
                    onTabClick={this.toggleTab}
                    tabs={mainTabs}
                    selected={selectedTab}
                    selectedSubItem={currentReport}
                />
                <DropdownTabs
                    containerPadding={20}
                    subTabStyling
                    height={40}
                    onTabClick={(tab) => this.setSelectedReport(tab.id, selectedTab)}
                    tabs={currentTabItems.map(t => ({ ...t, url: { module: 'reports', action: 'view', selectedTab: selectedTab, currentReport: t.id }}))}
                    selected={currentReport}
                />
                <div>
                    <ReportsTab ref={this.tab} {...tabProps} renderSummarySection={() => (
                            <PageTopSection
                            /*mainButtons={[
                                    {
                                        title: tr("Search"),
                                        loading: this.tab.current?.state.dataIsFetching,
                                        action: () => !this.tab.current?.state.dataIsFetching && this.tab.current.setState({dataIsFetching: true}, () => this.tab.current?.fetchData({}, false, false, false, true)),
                                        isVisible: fetchWithButton
                                    },
                                ]
                            }*/
                            additionalButtons={
                                [/*{
                                    title: fetchWithButton ? this.tr('automatic queries off') : this.tr('automatic queries on'),
                                    icon: fetchWithButton ? <Autorenew /> : <Cached />,
                                    action: () => this.setState({fetchWithButton: !this.state.fetchWithButton}, 
                                            () => localStorage.setItem('reports_list_fetch_button_state', this.state.fetchWithButton ? 1 : 0)),                            
                                },*/ 
                                ...(selectedTab == "hourReports" && currentReport != 'hours_export' ? [
                                    {
                                        title: this.tr('Print PDF'),
                                        loading: generatingPDF,
                                        disabled: this.tab.current?.state.data?.length < 1,
                                        icon: <Print />,
                                        action: this.printCurrentHourReportPDF,
                                    }
                                ] : []), 
                                ...((currentReport != 'hours_export' && selectedTab != 'chiefReports') ? [
                                    {
                                        title: this.tr('EXPORT'),
                                        disabled: this.tab.current?.state.data?.length < 1,
                                        icon: <CloudDownload />,
                                        loading: this.state.xlsxLoading || this.state.csvLoading,
                                        popoverClass: "reports-view-export-dropdown",
                                        action: () => this.export('csv'),
                                        popoverComponent: (closeFunc) => (
                                            <div className={"export-dropdown"}>
                                                <button onClick={selectedTab != '' ? () => {
                                                    closeFunc();
                                                    this.export('xlsx');
                                                } : undefined}>{this.tr("Export .xlsx")}</button>
                                                <button onClick={selectedTab != '' ? () => {
                                                    closeFunc();
                                                    this.export('csv');
                                                } : undefined}>{this.tr("Export .csv")}</button>
                                            </div>
                                        )
                                    },
                                ] : []),
                                ...((this.context.addons.invoice_currency && (selectedTab !== "hourReports" && selectedTab !== "productReports") ) ? [
                                    {
                                        title: this.tr('Report currency'),
                                        icon: <CurrencySymbolIcon content={this.state.currencySymbol} />,
                                        popoverClass: "reports-view-export-dropdown",
                                        action: () => {},
                                        popoverComponent: (closeFunc) => (
                                            <div className={"export-dropdown"}>
                                                {currentCurrencies().map(curr => (
                                                    <button onClick={() => {this.changeActiveCurrency(curr);closeFunc();}} >
                                                        {curr}
                                                    </button>
                                                ))}
                                            </div>
                                        )
                                    },
                                ] : []),
                                ]
                            }
                            className={(!summariesArr || summariesArr.length == 0) && 'no-summaries'}
                            customViewButton={
                                currentSwitches?.length > 0 &&
                                this.tab.current && (
                                    <SwitchesGroup
                                        options={currentSwitches}
                                        values={this.state.switches}
                                        onChange={this.tab.current.onSwitchChange}
                                        noLabel showTooltip
                                        groupLabel={this.tr("Additional options")}
                                    />
                                )
                            }
                            summaries={summariesArr}
                        />
                    )} updateView={this.updateComponentData} />
                </div>
            </div>
        );
    }
}
ReportsView.propTypes = {};

export default withStyles(styles)(withSnackbar(ReportsView));
