import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import TextInputCell from '../../list/cells/TextInputCell';

class CorporationGroups extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/components/CorporationGroups");

        this.state = {
            groups: [],
            canEdit: false
        }
        this.newRowAdded = false;
        this.nameInput = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();

        this.refreshGroups();
    }

    componentDidUpdate = (prevProps) => {
        if (this.newRowAdded && this.nameInput.current) {
            this.nameInput.current.listCell.current.openEdit();
            this.newRowAdded = false;
        }
    }

    refreshGroups = () => {
        DataHandler.get({ url: `settings/corporation_groups`, members: 1 }).then(data => {
            this.setState({ groups: data.groups, canEdit:  data.can_edit});
        });
    }

    generateOptions = (row, all) => {
        const { users, groups } = this.props;

        if (!row.users)
            row.users = [];

        const filteredUsers = users.filter(e => row.users.find(ee => ee === e.id) === undefined);

        return filteredUsers.map(el => {
            el['label'] = el.label ? el.label : (el.name || "");
            return el;
        });
    }

    addUserToGroup(user, groupId) {
        DataHandler.request("PUT", { url: `users/${user}/permissionGroups` }, {groupId: groupId, is_corporation_group: 1}).done(response => {
            this.refreshGroups();
        });
    }

    removeUserFromGroup(user, groupId) {
        DataHandler.delete({ url: `users/${user}/permissionGroups` }, {groupId: groupId, is_corporation_group: 1}).done(response => {
            this.refreshGroups();
        });
    }

    removeGroup(title) {
        const { userObject: { companies_id } } = this.context;

        DataHandler.delete({url: `settings/corporation_groups/${title}`}).then( () => {
            this.refreshGroups();
        } );
    }

    updateGroup(title, data) {
        DataHandler.put({url: `settings/corporation_groups/${title}`}, data).then( () => {
            this.refreshGroups();
        } );
    }

    addGroup = () => {
        const { groups } = this.state;
        let newGroup = {
            name: this.tr("New Group"),
            id: groups.length * -1 - 1,
            users: []
        };
        this.newRowAdded = true;
        DataHandler.request("POST", { url: `settings/corporation_groups` }, newGroup).done(response => {
            newGroup.id = response.id;
            groups.push(newGroup);
            this.setState({ groups });
        });

    }

    render() {
        const { users } = this.props;
        const { groups } = this.state;
        const { tr } = this;

        const allUsers = groups.map(a => a.users).flat(1);

        return <div id="teamgroup-settings">
            <h3>{tr("Corporation Groups")}</h3>

            <Button className="green btn add" size="large" onClick={() => { this.addGroup() }} disabled={!this.state.canEdit}>{tr("Add corporation group")}</Button> 

            <div className="user-count">
                {groups.length} {this.tr('groups')}
            </div>

            <div id="teamgroup-wrapper">
                <div className="header">
                    <div className="column delete"></div>
                    <div className="column group">{tr("Title")}</div>
                    <div className="column users">{tr("Group members")}</div>
                </div>
                <div className="content">
                    {groups.map(row => (
                        <div className="row">
                            <div className="column delete">
                                {!row.dynamic && this.state.canEdit && <DeleteIcon
                                    fontSize="small"
                                    onClick={() => this.removeGroup(row.id)}
                                />}
                            </div>
                            <div className="column group">
                                <TextInputCell
                                    ref={this.nameInput}
                                    name="name"
                                    editable={this.state.canEdit}
                                    value={row.name}
                                    type={row.type}
                                    onEdited={(name, value) => {
                                        this.updateGroup(row.id, {name: value});
                                    }}
                                />
                            </div>
                            <div className="column users">
                                {row.users && row.users.map(u => {
                                    let found = users.find(elem => { return elem.id == u });
                                    if (found)
                                        return <Chip id={"g_" + found.id} key={"g_" + found.id} label={found.label} className="square" onDelete={this.state.canEdit ? () => this.removeUserFromGroup(found.id, row.id) : false} />
                                })}
                                {
                                    this.state.canEdit && (
                                        <Select
                                            onChange={(value) => {
                                                this.addUserToGroup(value.id, row.id);
                                            }}
                                            value={null}
                                            isSearchable={true}
                                            isMulti={false}
                                            disabled={!this.state.canEdit}
                                            placeholder={this.tr("Select...")}
                                            options={this.generateOptions(row)}
                                            className="drop"
                                        />
                                    )   
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    }
}

export default CorporationGroups;