import React from "react";
import List from "../../list/List";
import Button from "@mui/material/Button";
import DataHandler from "../../general/DataHandler";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import TalenomTaxRow from "./TalenomTaxRow";
import OutlinedField from "../../general/OutlinedField";
import TalenomSalesRow from "./TalenomSalesRow";
import TalenomProductRow from "./TalenomProductRow";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from "../../SettingsContext";
import TalenomCostPoolRow from "./TalenomCostPoolRow";
import MenuItem from '@mui/material/MenuItem';
import SettingRow from '../../list/rows/SettingRow';

/* css */
import "./Talenom.css";

class Talenom extends TaimerComponent {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "settings/api-settings/talenom");

		this.state = {
			username: "",
            password: "",
            username_bill: "",
            refresh_token_expires: false,
            sftpUsername: "",
            sftpPassword: "",
            sftpActivated: 0,
		};

        /* 
        0 (= Tulostus)
        1 (= Postituspalvelu verkkolasku)
        2 (= Postituspalvelu paperilasku)
        3 (= Tulostus, tilassa lähetetty)
        4 (= Sähköpostilähetys)
        */

        this.delivery_types = [{value: 1, label: this.tr("einvoicing")}, {value: 2, label: this.tr("paper")}, {value: 3, label: this.tr("print")}, {value: 4, label: this.tr("email")}];
		this.talenomTaxList = React.createRef();
        this.talenomSalesList = React.createRef();
        this.talenomCostPoolList = React.createRef();
        this.talenomProductList = React.createRef();
	}

	/**
	 * Talenom settings componentDidMount
	 *
	 */
	componentDidMount() {
		super.componentDidMount();
		this.updateComponentData();
	}

	/**
	 * Calls talenom get functions
	 *
	 */
	updateComponentData = (waitime = 0) => {
		setTimeout(() => {
            this.getAuth();
            this.getTaxRates();
            this.getSalesAccount();
            this.getSendingType();
            this.getCostPool();
            this.getProduct();
		}, waitime);
	};

	/**
	 * Saves talenom authentication information
	 *
	 */
	saveAuth = () => {
		const { username, password } = this.state;

		DataHandler.put(
			{ url: `settings/company/${this.props.company}/talenom` },
			{ username: username, password: password }
		).done(resp => this.updateComponentData());
	};

    saveSFTPAuth = () => {
		const { sftpUsername, sftpPassword } = this.state;

		DataHandler.put(
			{ url: `settings/company/${this.props.company}/talenom_sftp` },
			{ username: sftpUsername, password: sftpPassword }
		).done(resp => {
            this.props.enqueueSnackbar(this.tr("Credentials saved"), {
                variant: "success",
            });
            this.updateComponentData()
        });
	};

	/**
	 * Gets talenom authentication information
	 *
	 */
	getAuth = () => {
		const { company } = this.props;

        DataHandler.get({ url: `settings/company/${company}/talenom` })
        .done(response => {
			this.setState(response);
        })
        .fail(response => {});
    };
    
    /**
	 * Deactivate talenom authentication information
	 *
	 */
	deactivate = () => {
		const { company } = this.props;
        DataHandler.delete({ url: `settings/company/${company}/talenom` })
        .done(response => {
			this.back();
        })
        .fail(response => {});
	};

    deactivateSFTP = () => {
		const { company } = this.props;
        DataHandler.delete({ url: `settings/company/${company}/talenom_sftp` })
        .done(response => {
			this.props.enqueueSnackbar(this.tr("Talenom SFTP deactivated"), {
                variant: "success",
            });
            this.setState({sftpActivated: 0, sftpUsername: "", sftpPassword: ""});
        })
        .fail(response => {});
	};

	/**
	 * Gets new data
	 * Moves user back to integration page
	 *
	 */
	back = () => {
		const {
			functions: { updateView },
		} = this.context;
		this.props.fetchData();
		updateView({ module: "settings", action: "index", group: "integrations", page: "default", subpage: "" });
	};

	/**
	 * Create or update a talenom tax
	 *
	 */
	postTaxRate = data => {
		DataHandler.put(
			{ url: `settings/company/${this.props.company}/taxrate` },
			{ id: data.id, value: data.value, type: data.type, deleted: data.deleted }
		).done(resp => this.updateComponentData());
	};

	/**
	 * Gets companies talenom tax
	 *
	 */
	getTaxRates = () => {
		DataHandler.get({ url: `settings/company/${this.props.company}/taxrate/talenom` })
			.done(taxrates => {
                
                const taxratesautocomplete = [];

                taxrates.forEach(rate => {
                    taxratesautocomplete.push({id: rate.id, name: rate.value, value: rate.value});
                });

                this.setState({ taxrates, taxratesautocomplete });
			})
			.fail(response => {});
    };

    /**
	 * Get Talenom accounting
	 *
	 */
	getSalesAccount = () => {
        const company = this.props.company;

        DataHandler.get({ url: `accounting/accounts/${company}/talenom/1` })
        .done(response => {
            this.setState({salesAccounts: response.salesAccounts});
        })
        .fail(err => {});
    };
    
    /**
	 * Create Talenom sales accounting
	 *
	 */
	postSalesAccount = data => {
        const company = this.props.company;
        DataHandler.post({ url: `accounting/accounts/${company}/talenom/1` }, {data: JSON.stringify(data)})
        .done(resp => this.updateComponentData())
        .fail(err => {});
    };
    
    /**
	 * Update Talenom sales accounting
	 *
	 */
	putSalesAccount = data => {
        const company = this.props.company;
        DataHandler.put({ url: `accounting/accounts/${company}` }, {data: JSON.stringify(data)})
        .done(resp => this.updateComponentData())
        .fail(err => {});
    };
    
    /**
	 * Update Talenom sales accounting
	 *
	 */
	deleteSalesAccount = data => {
        const company = this.props.company;
        DataHandler.delete({ url: `accounting/accounts/${company}` }, {data: JSON.stringify(data)})
        .done(resp => this.updateComponentData())
        .fail(err => {});
    };

    /**
	 * Update Talenom sales accounting
	 *
	 */
	getSendingType = () => {
        const company = this.props.company;
        DataHandler.get({ url: `settings/company/${company}/invoicedeliverytype` },)
        .done(resp => {
            this.setState({delivery_type: resp.delivery_type});
        })
        .fail(err => {});;
    };
    
    /**
	 * Update Talenom sales accounting
	 *
	 */
	putSendingType = (type) => {
        const company = this.props.company;
        DataHandler.post(
			{ url: `settings/company/${company}/invoicedeliverytype` },
			{ default_invoice_delivery_type: type }
		).done(resp => this.updateComponentData());
    };
    
    /**
     * Update Talenom cost pool
     *
     */
    getCostPool = () => {
        const company = this.props.company;
        DataHandler.get(
            { url: `settings/company/${company}/talenomcostpool` },
        ).done(resp => {
            this.setState({
                dimensions: resp.dimensions
            });
        });
    }

    /**
     * Creates Talenom Cost pool
     * 
     */
    postCostPool = (data) => {
        const company = this.props.company;

        //Check dimension number
        if(isNaN(parseInt(data.dimension)) || parseInt(data.dimension) < 1){
            this.props.enqueueSnackbar(this.tr("dimension number cant be: " + data.dimension), {
                variant: "error",
            });
            
            this.updateComponentData();
            return;
        } 

        DataHandler.post(
            { url: `settings/company/${company}/talenomcostpool` },
            { dimension: data.dimension, name: data.name }
        ).done(resp => this.updateComponentData());
    }

    /**
     * Updates Talenom Cost pool
     *
     */
    putCostPool = (data) => {
        const company = this.props.company;

        //Check dimension number
        if(isNaN(parseInt(data.dimension)) || parseInt(data.dimension) < 1){
            this.props.enqueueSnackbar(this.tr("dimension number cant be: " + data.dimension), {
                variant: "error",
            });
            
            this.updateComponentData();
            return;
        } 

        DataHandler.put(
            { url: `settings/company/${company}/talenomcostpool` },
            { id: data.id, dimension: data.dimension, name: data.name }
        ).done(resp => this.updateComponentData());
        
    }

    /**
     * Deletes Talenom Cost pool
     *
     */
    deleteCostPool = (data) => {
        const company = this.props.company;
        DataHandler.delete(
            { url: `settings/company/${company}/talenomcostpool` },
            { id: data.id }
        ).done(resp => this.updateComponentData());
    }

    /**
     * Gets Talenom Products
     *
     */
    getProduct = () => {
        const company = this.props.company;
        DataHandler.get(
            { url: `settings/company/${company}/talenomproduct` }
        ).done(resp => {
            this.setState({
                products: resp.products
            });
        });
    }

    /**
     * Creates Talenom Products
     *
     */
    postProduct = (data) => {
        const company = this.props.company;

        //Check product_id number
        if(isNaN(parseInt(data.product_id)) || parseInt(data.product_id) < 1){
            this.props.enqueueSnackbar(this.tr("product id cant be: " + data.product_id), {
                variant: "error",
            });
            
            this.updateComponentData();
            return;
        } 

        DataHandler.post(
            { url: `settings/company/${company}/talenomproduct` },
            { id: data.id, name: data.name, product_id: data.product_id }
        ).done(resp => this.updateComponentData());
    }

    /**
     * Updates Talenom Product
     *
     */
    putProduct = (data) => {
        const company = this.props.company;

        //Check product_id number
        if(isNaN(parseInt(data.product_id)) || parseInt(data.product_id) < 1){
            this.props.enqueueSnackbar(this.tr("product id cant be: " + data.product_id), {
                variant: "error",
            });
            
            this.updateComponentData();
            return;
        } 

        DataHandler.put(
            { url: `settings/company/${company}/talenomproduct` },
            { id: data.id, product_id: data.product_id, name: data.name }
        ).done(resp => this.updateComponentData());
    }

    /**
     * Deletes Talenom Product
     *
     */
    deleteProduct = (data) => {
        const company = this.props.company;
        DataHandler.delete(
            { url: `settings/company/${company}/talenomproduct` },
            { id: data.id }
        ).done(resp => this.updateComponentData());
    }
    
    /**
     * Does bills authentication
     *
     */
    Authenticate = () => {
        const { username_bill } = this.state;
        const { company } = this.props;

        if(username_bill.length <= 0) {
            this.props.enqueueSnackbar(this.tr("login id must be defined"), {
                variant: "error",
            });

            return;
        }

        sessionStorage.setItem("previousUrl", window.location);
		sessionStorage.setItem("nextAction", "talenom-auth");

        DataHandler.post(
            { url: `talenom/company/${company}/auth` },
            { username: username_bill }
        )
        .done(resp => {
            if(resp.url) {
                sessionStorage.setItem("previousUrl", window.location);
			    sessionStorage.setItem("nextAction", "talenom-auth");
			    window.location = resp.url;
            }
        })
        .fail(resp => {
            
        });
    }

    /**
	 * Deactivate talenom bill authentication information
	 *
	 */
	deactivate_bill = () => {
		const { company } = this.props;
        DataHandler.delete({ url: `settings/company/${company}/talenombill` })
        .done(response => {
			this.updateComponentData();
        })
        .fail(response => this.updateComponentData());
	};

	/**
	 * Talenom settings renderer
	 *
	 */
	render() {
		const { tr } = this;
		const { username, username_bill, password, activated, activated_bill, refresh_token_expires } = this.state;
		const colConfigs = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

		return (
			<div id="integration-talenom" className="main-integration-wrapper">
				<div className="header">
					<Button className="return-button talenom-button" variant="text" size="large" onClick={this.back}>
						{<ChevronLeft />}
					</Button>
					<h3>{tr("talenom settings")}</h3>
				</div>
                <div className="settings">
                    <h4>{tr("activate talenom bills")}</h4>
                    <h5>
                        {
                            refresh_token_expires && activated_bill === "1" && (
                                new Date(refresh_token_expires) > new Date() ? tr("activation expires") + ": " + new Date(refresh_token_expires).toLocaleDateString() :
                                tr("expired") + ": " + new Date(refresh_token_expires).toLocaleDateString()
                            )
                        }
                    </h5>
					<OutlinedField
						value={username_bill}
						onChange={evt => this.setState({ username_bill: evt.target.value })}
						label={tr("login id")}
					/>
					<div className="settings-buttons">
                    {                           
                        activated_bill === "1" ?
                        <React.Fragment>
                            <Button className="settings-buttons-save talenom-button" color="primary" onClick={this.Authenticate} size="large">
                                {tr("activate")}
                            </Button>
                            <Button className="settings-buttons-activate talenom-button" color="secondary" onClick={this.deactivate_bill} size="large">
                                {tr("deactivate")}
                            </Button> 
                        </React.Fragment> :
                        <Button className="settings-buttons-save talenom-button" color="primary" onClick={this.Authenticate} size="large">
                            {tr("activate")}
                        </Button>
                    }
					</div>
				</div>
				<div className="settings">
					<h4>{tr("activate talenom invoicing")}</h4>
					<OutlinedField
						value={username}
						onChange={evt => this.setState({ username: evt.target.value })}
						label={tr("login id")}
					/>
					<OutlinedField
						value={password}
						onChange={evt => this.setState({ password: evt.target.value })}
						label={tr("password")}
                        autoComplete="new-password"
					/>
					<div className="settings-buttons">
						<Button className="settings-buttons-save talenom-button" color="primary" onClick={this.saveAuth} size="large">
							{tr("save")}
						</Button>
						{activated === "1" && (
							<Button
								className="settings-buttons-activate talenom-button"
								color={"secondary"}
								onClick={() => this.deactivate()}
								size="large"
							>
								{tr("deactivate")}
							</Button>
						)}
					</div>
				</div>
                <div className="settings">
					<h4>{tr("SFTP credentials")}</h4>
					<OutlinedField
						value={this.state.sftpUsername}
						onChange={evt => this.setState({ sftpUsername: evt.target.value })}
						label={tr("Username")}
					/>
					<OutlinedField
						value={this.state.sftpPassword}
						onChange={evt => this.setState({ sftpPassword: evt.target.value })}
						label={tr("password")}
					/>
					<div className="settings-buttons">
						<Button className="settings-buttons-save talenom-button" color="primary" onClick={this.saveSFTPAuth} size="large">
							{tr("save")}
						</Button>
						{this.state.sftpActivated == 1 && (
							<Button
								className="settings-buttons-activate talenom-button"
								color={"secondary"}
								onClick={() => this.deactivateSFTP()}
								size="large"
							>
								{tr("deactivate")}
							</Button>
						)}
					</div>
				</div>
                <div className="settings">
                    <h4>{tr("default sending type")}</h4>
                    <OutlinedField select disabled={false} label={"Default"} name={"default"} value={this.state.delivery_type == "0" ? 1 : this.state.delivery_type} fullWidth >
                        {this.delivery_types.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.putSendingType(value)}>{label}</MenuItem>)}
                    </OutlinedField>
                </div>
				<div className="settings">
					<h4>{tr("tax rates")}</h4>
					<Button
						className="green talenom-button"
						onMouseUp={() => this.talenomTaxList.current.addNewRow()}
						size="large"
					>
						{tr("add rate")}
					</Button>
					<br />
					<List
						ref={this.talenomTaxList}
						id="talenomTaxList"
						className="settingsList"
                        height="auto"
                        rowKey="id"
						rowHeight={73}
						newRow={{
							id: -1,
							value: 0,
							type: "talenom",
							deleted: 0,
						}}
						listRowType={TalenomTaxRow}
						columns={[
							{ width: 55, name: "delete", header: "", ...colConfigs },
							{ width: 350, name: "value", header: tr("rate"), ...colConfigs },
						]}
						rowProps={{
							onCreate: data => this.postTaxRate(data),
							onUpdate: data => this.postTaxRate(data),
							onDelete: data => {
								data.deleted = 1;
								this.postTaxRate(data);
							},
						}}
						data={this.state.taxrates}
					/>
				</div>
				<div className="settings">
					<h4>{tr("sales accounts")}</h4>
					<Button
						className="green talenom-button"
						onMouseUp={() => this.talenomSalesList.current.addNewRow()}
						size="large"
					>
						{tr("add account")}
					</Button>
					<br />
					<List
						ref={this.talenomSalesList}
						id="talenomSalesList"
						className="settingsList"
						height="auto"
                        rowHeight={73}
                        rowKey="id"
						newRow={{
							id: -1,
							name: "",
							account_number: 0,
							vat_id: 0,
							deleted: 0,
						}}
						listRowType={TalenomSalesRow}
						columns={[
							{ width: 55, name: "delete", header: "", ...colConfigs },
							{ width: 350, name: "name", header: tr("account name"), ...colConfigs },
							{ width: 150, name: "account_number", header: tr("account number"), ...colConfigs },
							{ width: 150, name: "vat_id", header: tr("rate"), ...colConfigs },
						]}
						rowProps={{
							onCreate: data => this.postSalesAccount(data),
							onUpdate: data => this.putSalesAccount(data),
							onDelete: data => {
								data.deleted = 1;
								this.deleteSalesAccount(data)
                            },
                            taxratesautocomplete: this.state.taxratesautocomplete
						}}
						data={this.state.salesAccounts}
					/>
				</div>
                <div className='settings'>
                    <h4>{tr('dimensions')}</h4>
                    <Button
                        className='green talenom-button'
                        onMouseUp={() => this.talenomCostPoolList.current.addNewRow()}
                        size='large'
                    >
                        {tr('add dimension')}
                    </Button>
                    <br />
                    <List
                        ref={this.talenomCostPoolList}
                        id='talenomCostPoolList'
                        className='settingsList'
                        height='auto'
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: '',
                            dimension: 1
                        }}
                        listRowType={TalenomCostPoolRow}
                        columns={[
                            { width: 55,    name: 'delete',     header: '',         ...colConfigs },
                            { width: 350,   name: 'name',       header: tr('name'), ...colConfigs },
                            { width: 200,   name: 'dimension',  header: tr('number'), ...colConfigs }
                        ]}
                        rowProps={{
                            onCreate: data => {
                                this.postCostPool(data);
                            },
                            onUpdate: data => {
                                this.putCostPool(data);
                            },
                            onDelete: data => {
                                this.deleteCostPool(data); 
                            },
                        }}
                        data={this.state.dimensions}
					/>
                </div>
                <div className='settings'>
                    <h4>{tr('products')}</h4>
                    <Button
                        className='green talenom-button'
                        onMouseUp={() => this.talenomProductList.current.addNewRow()}
                        size='large'
                    >
                        {tr('add product')}
                    </Button>
                    <br />
                    <List
                        ref={this.talenomProductList}
                        id='talenomProductList'
                        className='settingsList'
                        height='auto'
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: '',
                            product_id: 1
                        }}
                        listRowType={TalenomProductRow}
                        columns={[
                            { width: 55,    name: 'delete',     header: '',         ...colConfigs },
                            { width: 350,   name: 'name',       header: tr('name'), ...colConfigs },
                            { width: 200,   name: 'product_id',  header: tr('product id'), ...colConfigs }
                        ]}
                        rowProps={{
                            onCreate: data => this.postProduct(data),
                            onUpdate: data => this.putProduct(data),
                            onDelete: data => this.deleteProduct(data),
                        }}
                        data={this.state.products}
					/>
                </div>
			</div>
		);
	}
}

export default Talenom;
