import React from 'react';

import "./FlexUtils.css";

function FlexContainer(props) {
	const id        = props?.id || "";
	const className = props?.className || "";

	return <div ref={props.forwardedRef} id={id} className={`flex-container ${className}`} style={props.style !== undefined ? props.style : {}}>{props.children}</div>;
}

function FlexChild(props) {
    const className = props.className !== undefined ? ` ${props.className}` : "";
    const style     = { 
        flex: !props.flexConfig ? `${props.weight} 1 0px` : `${props.flexConfig}`,
        ...(props.style ? props.style : {})
    };

	return (
		<div ref={props.forwardedRef} onClick={e => props.onClick !== undefined ? props.onClick(e) : null} id={props.id} className={`flex-child${className}`} style={style}>
			{props.children}
		</div>
	);
}

export {
	FlexContainer,
	FlexChild
};
