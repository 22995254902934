import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MaskedInput from 'react-text-mask'

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM')

class TimeOutlinedField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            return {
                value: props.value
            };
        }
        return null;
    }

    render() {

        const { name, rootClass, disabled } = this.props;
        const { value } = this.state;

        return (
            <FormControl className={rootClass} variant="filled" fullWidth={this.props.fullWidth}>
                <InputLabel
                    shrink
                    ref={ref => {
                        this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor={`${name}-date`} >

                    {this.props.label}

                </InputLabel>

                <FilledInput
                    notched
                    inputComponent={MaskedInput}
                    inputProps={{
                        mask: [/\d/, /\d/, ':', /\d/, /\d/],
                        showMask: true,
                        placeholderChar: '-',
                        keepCharPositions: true,
                        pipe: autoCorrectedDatePipe
                    }}
                    type="text"                    
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={this.props.onChange}
                    labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0} />
            </FormControl>
        );
    }
}

TimeOutlinedField.defaultProps = {
    value: "07:00",
}

export default TimeOutlinedField;
