import React, { Component } from 'react';
import { Popper } from '@mui/material';
import TaimerAvatar from '../../../general/TaimerAvatar';
import TaimerComponent from "../../../TaimerComponent";
import { ReactComponent as CarIcon } from '../icons/travel_time.svg';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Tooltip from "@mui/material/Tooltip";
import ColorBall from '../ColorBall';
import cn from 'classnames';
import { getColorClass } from '../Colors.js';

// Event
class EventWrapper extends TaimerComponent {

	constructor(props, context) {
		super(props, context, "workhours/time-tracker/Wrappers/EventWrapper");
		this.state = {
			showAllUsers: false
		}
		this.ref = React.createRef();		
	}


	hideAllUsers = () => {
		this.setState({showAllUsers: false});
	}

	renderTooltipIcon = (Icon, text, className = "") => {		
		return (
			<Tooltip title={text}>
				<Icon className={className} />
			</Tooltip>
		);
	}

	render() {

		let {event} = this.props;

		let taskUsers = event.users_hours && event.users_hours.slice(0, 2);
		let additionalCount = event.users_hours && event.users_hours.length - 2;
		let statusText;
		switch(event.taskType) {
			case 'in-progress':
				statusText = this.tr('In progress');
				break;
			case 'overdue':
				statusText = this.tr('Overdue');
				break;
			case 'done':
				statusText = this.tr('Done');
				break;
			default:
				statusText = this.tr('Task');
				break
		}

		if(this.state.showAllUsers) {
			document.addEventListener("click", this.hideAllUsers);			
		} else {
			document.removeEventListener("click", this.hideAllUsers);
		}

		let container = document.body.querySelector(".TimeTracker .CalendarMain .rbc-calendar");
		const iconStatuses = ["-1", "1", "2"];
		const showStatusIcon = iconStatuses.includes(event.status) && event.approvable_hour;
		const showApprevedIcon = event.status == "1" && event.approvable_hour;
		const declineMessage = event.decline_message && event.decline_message.trim() != "" ? ": " + event.decline_message : "";

		return (
            <div className={event.saving ? 'saving' : ''}>
				{event.status == "1" && event.approvable_hour && this.renderTooltipIcon(ThumbUpIcon, this.tr("Approved"), `status-icon approved`) }
				{event.status == "-1" && event.approvable_hour && this.renderTooltipIcon(CancelRoundedIcon, this.tr("Declined") + declineMessage, `status-icon declined`) }
				{event.status == "2" && event.approvable_hour && this.renderTooltipIcon(HourglassFullIcon, this.tr("Submitted"), `status-icon`) }
				{event.isWorktripProject && this.renderTooltipIcon(CarIcon, this.tr("This is travel time"), `car-icon ${showStatusIcon ? "has-status-icon" : ""} ${showApprevedIcon ? "has-approved-icon" : ""}`) }
				{event.is_task > 0 && <div><div className={"task-status " + event.taskType}>{statusText}</div></div>}
				<div className="eventCustomerName">{event.unit?.name || event.customer?.name}</div>
				<div>{event.project?.name}</div>
				{/* {event.subproject.id > 0 && <div>{event.subproject.name}</div>} */}
				{event.worktask.id > 0 && <div>{event.worktask?.name}</div>}
				{event.saving && <img className="saving-indicator" src={require("../../../dashboard/insights/img/loading.svg").default} />}
				{event.is_task > 0 && <div className="task-description">{event.description}</div>}
				{event.is_task > 0 && 
					<div ref={this.ref} className="task-users" onClick={() => this.setState({showAllUsers: !this.state.showAllUsers})}>
					{taskUsers.map(el => (
						<TaimerAvatar
							id={(el.users_id + "").replace(/[^0-9]/g,"")}
            				name={el.name}
            				color={el.color}
						/>
					))}
					{ 
						additionalCount > 0 && (<div class="additional-count">+{additionalCount}</div>)
					}
					</div>					
				}
				{
					this.state.showAllUsers && (
						<Popper
							style={{zIndex: 1}}
							placement="bottom-start"
							anchorEl={this.ref.current}
							container={container}
							open={true}
						>
							<div className="event-all-users-popup">
								{ 
									event.users_hours.map(el => (
										<div  className={cn("user", getColorClass({id:el.users_id, color:el.color}, 'user'))}>
										   <div className="rbc-ball-cont">
                   								 <span className={"rbc-rec-list"}></span>
               								</div>
											<TaimerAvatar
												id={(el.users_id + "").replace(/[^0-9]/g,"")}
					            				name={el.name}
					            				color={el.color}
											/>
											<div className="name-container">
												<div className="name">{el.name}</div>
												<div className="title">{el.title}</div>
											</div>
										</div>				
									))
								}
							</div>
						</Popper>
					)
				}
			</div>
        );
	}
}

export default EventWrapper;
