import React, { Component } from 'react';

/* local components */
import Edit from './Edit';
import Delete from './Delete';
import Confirm from "./Confirm";
import CloseIcon from '@mui/icons-material/Close';

/* material ui */
import Dialog from '@mui/material/Dialog';

/* context */
import { SettingsContext } from './../../SettingsContext';

import "./CoreDialog.css";

class CoreDialog extends React.Component {
    static contextType  = SettingsContext;
    static defaultProps = {
        component: undefined
    };

    constructor(props, context) {
        super(props, context);

        this.typeMap = {
            edit: Edit,
            delete: Delete,
            confirm: Confirm
        };

        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.emptyValues      = this.emptyValues.bind(this);

        this.componentRef = React.createRef();
    }


    emptyValues(names) {
        this.componentRef.current && this.componentRef.current.emptyValues(names);
    }

    handleCloseClick(e) {
        const { onCloseClick } = this.props.dialogProps;

        if(typeof(onCloseClick) !== "function") {
            return;
        }

        onCloseClick(); 

        e.stopPropagation();
    }


    render() {
        const Component = this.props.dialogType && this.typeMap.hasOwnProperty(this.props.dialogType) ? this.typeMap[this.props.dialogType] : this.props.component;

        if(!Component) {
            throw new Error("CoreDialog: invalid dialogType and no 'component' prop passed to CoreDialog.");
        }

        const { onCloseClick, testid, ...dialogPropsRest } = this.props.dialogProps;

        return (
            <Dialog
                className="massOperationDialog core"
                PaperProps={{ className: "coreDialog", "data-testid": testid }}
                open>
                <CloseIcon className="closingX" onClick={this.handleCloseClick} />
                <Component ref={this.componentRef} {...dialogPropsRest} />
            </Dialog>
            
        );
    }
}

export default CoreDialog;
