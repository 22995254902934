import React from 'react';
import AddIcon from '@mui/icons-material/AddCircleOutline';

function SummaryActionButton(props) {
    const text    = props.text || "";
    const onClick = props.onClick !== undefined ? props.onClick : () => {};
    const style   = props.style ? props.style : {};

    return (
        <div
            style={{
                width: props.width + "px",
                lineHeight: props.height + "px", 
                cursor: "pointer", 
                color: "#2d9ff7",
                marginLeft: "25px",
                ...style
            }}
            onClick={onClick}>
            <span style={{ height: "100%" }} className="add-icon">
                <AddIcon style={{ fill: "#2d9ff7", width: "20px", marginTop: "-4px", marginRight: "5px" }} />
                {text}
            </span>
        </div>
    );
}

export default SummaryActionButton;
