import React, { useState } from "react";
import {
    Button,
  } from "@mui/material";
  
  import {
    ArrowDropDownRounded,
    Check,
} from "@mui/icons-material";

const MenuItem = props => {
const { item, isMultiSelect } = props;
return (
    <div onClick={item.onClick && item.onClick} className="menu-item">
    {isMultiSelect && (
        <div className="multi-select-box">
        {!!item.selected && <Check className="menu-item-icon" />}
        </div>
    )}
    {item.Icon && !isMultiSelect && (
        <item.Icon
        style={{ fill: item.color || "#b2b9c9" }}
        className="menu-item-icon"
        />
    )}
    <p>{item.text}</p>
    </div>
);
};

export default function HeaderButton(props) {
    const {
      text,
      icon,
      isDropdown,
      onClick,
      menuItems = [],
      isMultiSelect,
      popover
    } = props;
    const [showMenu, toggleMenu] = useState(false);
    return (
      <div
        onMouseLeave={() =>
          setTimeout(() => {
            toggleMenu(false);
          }, 200)
        }
        className="button-container"
      >
        <Button
          onMouseEnter={() => toggleMenu(true)}
          onClick={onClick}
          className="header-button"
        >
          {icon}
          <p>{text}</p>
          {isDropdown && <ArrowDropDownRounded className="dropdown-icon" />}
        </Button>
        {isDropdown && (
          <div
            style={{
              pointerEvents: showMenu ? "auto" : "none",
              opacity: showMenu ? 1 : 0,
              width: 'max-content',
            }}
            className="button-menu-container"
          >
            {popover || (
              <div className="grid-control-button-menu">
                {menuItems.map(item => {
                  return <MenuItem isMultiSelect={isMultiSelect} item={item} />;
                })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

