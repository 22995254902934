import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import CustomerList from '../../list/lists/CustomerList';
import ContactList from '../../list/lists/ContactList';
import UserList from '../../list/lists/UserList';

class Contacts extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Contacts');
        const {
            userObject,
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'accounts',
                label: this.tr('Accounts'),
                hidden: !(userObject.customer_read_companies.length > 0 || hasPrivilege('customers', 'read')),
            },
            {
                id: 'contacts',
                label: this.tr('Contact persons'),
                hidden: !hasPrivilege('persons', 'read'),
            },
            {
                id: 'users',
                label: this.tr('Staff'),
                hidden: !hasPrivilege('persons', 'userlist_read'),
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'accounts':
                            return <CustomerList {...this.props} />;
                        case 'contacts':
                            return <ContactList {...this.props} />;
                        case 'users':
                            return <UserList {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Contacts;
