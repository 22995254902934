import React from 'react';
import FileSaver from 'file-saver';
import { withSnackbar } from 'notistack';

/* mui */
import { Button, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from "@mui/icons-material/Warning";

/* mui icons */
import { ChevronLeft } from '@mui/icons-material';

/* local components */
import OutlinedField from '../../general/OutlinedField';
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";
import SettingsGrid from '../pages/SettingsGrid';

/* data backend */
import DataHandler from '../../general/DataHandler';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './Procountor.css';
import TaimerComponent from '../../TaimerComponent';

import { format } from "date-fns";
class VatCodeDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/Procountor");

        this.state = {
            currentStatus: 0
        }
    }

    componentDidMount = () => {
        this.setState({currentStatus: this.props.currentStatus})
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleSave = async () => {
        this.props.vatCodeSelected && this.props.vatCodeSelected(this.state.currentStatus);
        this.props.onClose && this.props.onClose();
    }

    render() {
        const { vatCodeList } = this.props;
        const { currentStatus } = this.state;

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="md"
                onClose={this.handleCancel}
                aria-labelledby="vatCodeList" >
                <div style={{backgroundColor: '#2D9FF7', height: '8px', borderRadius: '4px 4px 0 0', marginBottom: '10px'}}/> 
                <DialogContent className="invoiceDialogContent">
                    <div>{this.tr("Select Procountor VAT status for reverse VAT invoice")}</div>
                    <OutlinedField 
                        fullWidth
                        select 
                        label={this.tr("VAT statuses")}
                        className="listFilterOutlinedField" 
                        value={currentStatus}>
                        {
                            vatCodeList.map((opt) =>
                                <MenuItem value={opt.vatStatus} key={opt.vatStatus} onClick={() => this.setState({currentStatus: opt.vatStatus})}>
                                    {opt.description}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr('Cancel')}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.handleSave} color="primary">
                        {this.tr('Select VAT status')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
class Procountor extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/Procountor");

        this.state = {
            dialogOpen: false,
            vacation_project_id: 0,
            sick_leave_project_id: 0,
            child_sick_leave_project_id: 0,
            username: "",
            active: "-1",
            use_maventa_targeting: 0,
            dialog: undefined,
            dialogProps: undefined,
            reverse_vat_status: 0
        }

        this.invoiceTypes = [
            {value: 0, label: this.tr("No invoices")},
            {value: 1, label: this.tr("Verified invoices")},
            {value: 2, label: this.tr("Approved invoices")},
            {value: 3, label: this.tr("All invoices")},
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getProcountorStatus();
        this.getProjects();
    }

    getProcountorStatus = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/procountor/status`}).done(response => {
            this.setState({
                coaUpdatedDate: response.coaUpdatedDate,
                dimensionsUpdatedDate: response.dimensionsUpdatedDate,
                productsUpdatedDate: response.productsUpdatedDate,
                purchase_invoice_type: response.purchase_invoice_type,
                expenseProductsUpdatedDate: response.expenseProductsUpdatedDate,
                active: response.activated,
                username: response.username,
                reverse_vat_status: response.reverse_vat_status
            });
        });
    }

    dialogOpen = () => {
        const open = this.state.dialogOpen;
        this.setState({dialogOpen: !open});
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''})
    }

    procountorAuth = async () => {
        await DataHandler.get({url: `settings/company/${this.props.company}/procountor/link`}).done(data => {
            console.log(data.url);
            sessionStorage["nextAction"] = "procountor-redirect";
            window.location = data.url;
        });

    }

    deactivate = () => {
        DataHandler.post({url: `settings/integrations/procountor/${this.props.company}/deactivate`}).done(response => {
            if (response == "true"){
                this.props.fetchData();
            }
        }).done(() => {
            this.back();
        });
    }

    getDimensions = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor dimensions..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/dimensions`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Dimensions fetched successfully!"), {
                variant: "success",
            });    
            this.setState({dimensionsUpdatedDate: response.date});
        });
    }

    getCoA = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor Chart of Accounts..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/coa`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Chart of Accounts fetched successfully!"), {
                variant: "success",
            });
            this.setState({coaUpdatedDate: response.date});
        });
    }

    getProducts = (type) => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor products..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/products/${type}`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Products fetched successfully!"), {
                variant: "success",
            });

            if (type == 1)
                this.setState({productsUpdatedDate: response.date});
            else
                this.setState({expenseProductsUpdatedDate: response.date});
        });
    }

    getVatCodes = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor VAT statuses..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/vatcodes`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.lenght < 1) {
                this.props.enqueueSnackbar(this.tr("Unable to get list of VAT statuses from Procountor"), {
                    variant: "error",
                });	
            } else {
                this.openDialog("vatCodes", {
                    vatCodeList: response,
                    currentStatus: this.state.reverse_vat_status,
                    vatCodeSelected: (id) => this.saveVatCode(id),
                });
            }
        });
    }

    saveVatCode = (id) => {
        if (!id)
            return;

        DataHandler.put({url: `settings/company/${this.props.company}/procountor/reverse_vat_status`}, {reverse_vat_status: id}).done(() => {
            this.setState({reverse_vat_status: id});

            this.props.enqueueSnackbar(this.tr("VAT status for reverse VAT invoices saved"), {
                variant: "success",
            });
        });
    }

    openDialog = (dialog, props) => {
        this.setState({dialog, dialogProps: props});
    }

    closeDialog = () => {
        this.setState({dialog: undefined, dialogProps: undefined});
    }
    
    getUserIds = () => {
        const key = this.props.enqueueSnackbar("Generating Procountor ID list...", {
            variant: "info",
            persist: true
        });

        DataHandler.getArrayBuffer({url: `procountor/userIds/${this.props.company}`}).done((response) => {
            this.props.closeSnackbar(key);
            
            if (response) {
                const blob = new Blob([response], {
                    type: 'text/plain'
                });
                FileSaver.saveAs(blob, "procountor_ids.txt");
            }
        });
    }

    getProjects = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/procountor/projects`}).done(response => {
            this.setState(response);
        });
    }

    changeInvoiceType = (type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/procountor/purchase_invoice_type`}, {type: type}).done(() => {
            this.setState({purchase_invoice_type: type});
        });
    };

    changeProject = (target, update) => {
        if (update[target] == undefined)
            return;
        
        DataHandler.post({ url: `settings/company/${this.props.company}/procountor/projects` }, {update});
    }
    
    onCheckChange = (update, name, value) => {
        const { company } = this.props;
        
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${company}/procountor_settings`}, this.state));
    }

    authenticate = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/procountor/auth`}, this.state).done(resp => {
            if (resp.status == "ok") {
                this.props.enqueueSnackbar(this.tr("Taimer connected with Procountor"), {
                    variant: "success",
                });	
                this.getProcountorStatus();
            } else {
                this.props.enqueueSnackbar(this.tr("Unable to connect with Procountor. Check the API key and Procountor settings"), {
                    variant: "error",
                });	
            }
        });
    }

    render(){
        const {userObject: {dateFormat}} = this.context;
        
        const settings = { use_maventa_targeting: this.state.use_maventa_targeting };
        const dialogs = { vatCodes: VatCodeDialog }
        const CustomDialog = this.state.dialog && dialogs[this.state.dialog];

        return (
            <div id="integration-procountor" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr('Procountor Settings')}</h3>
                </div>
                {this.state.active == "-1" ?
                    <div className="settings">
                        <h4>{this.tr("API key")}</h4>
                        <p>{this.tr("Put your API key that you got from Procountor here and press 'Authenticate' to connect Taimer with Procountor")}</p>
                        <p>{this.tr("Taimer's client ID is ")}<b>taimer2Client</b></p>
                        <OutlinedField value={this.state.username} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("API key")} />
                        <br />
                        <Button color="primary" onClick={this.authenticate} size="large">{this.tr("Authenticate")}</Button>
                    </div>
                :
                    <div className="settings">
                        <h4>{this.tr("Deactivate Procountor")}</h4>
                        <Button className="deactivate" onClick={this.dialogOpen} size="large">{this.tr('Deactivate')}</Button>

                        <div className="settings">
                            <h4>{this.tr("Purchase invoice type")}</h4>
                            <p>{this.tr("Select the status of purchase invoices that are fetched daily from Procountor To Taimer")}</p>
                            <OutlinedField select disabled={false} label={this.tr("Invoice type")} name={"default"} value={this.state.purchase_invoice_type} fullWidth >
                                {this.invoiceTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeInvoiceType(value)}>{label}</MenuItem>)}
                            </OutlinedField>
                        </div><br/><br/>

                        <h4>{this.tr('Get Chart of Accounts from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={this.getCoA} size="large">{this.tr('Get CoA')}</Button>
                        {this.state.coaUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " +  format(this.state.coaUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4>{this.tr('Get Dimensions from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={this.getDimensions} size="large">{this.tr('Get Dimensions')}</Button>
                        {this.state.dimensionsUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " + format(this.state.dimensionsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4>{this.tr('Get Products from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={() => this.getProducts(1)} size="large">{this.tr('Get Products')}</Button>
                        {this.state.productsUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " + format(this.state.productsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4>{this.tr('Get Expense Products from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={() => this.getProducts(3)} size="large">{this.tr('Get Expense Products')}</Button>
                        {this.state.productsUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " + format(this.state.expenseProductsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4 className="dimensions">{this.tr('Get person IDs from Procountor')}</h4>
                        <Button color="primary" onClick={this.getUserIds} size="large">{this.tr('Get IDs')}</Button>

                        <h4>{this.tr('Set VAT status for reverse VAT invoices')}</h4>
                        <p>{this.tr("Select which Procountor VAT status is used for revere VAT invoices")}</p>
                        <Button color="primary" className="dimensions" onClick={() => this.getVatCodes()} size="large">{this.tr('Set VAT status')}</Button>

                        <div id="settings-maventa" className="main-integration-wrapper">
                            <h4>{this.tr("Contract billing")}</h4>
                            <p>{this.tr("Connect your Procountor bills to contract billing for your customers")}</p>
                            <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                                type: "check",
                                name: "use_maventa_targeting",
                                label: this.tr("Procountor contract billing"),
                                className: "respSetting third"
                            }]} />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Vacation project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: true, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.vacation_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        vacation_project_id: e.id
                                    }, () => {
                                        const update = {
                                            vacation_project_id: e.id
                                        };
                                        this.changeProject('vacation_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Sick leave project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: true, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.sick_leave_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        sick_leave_project_id: e.id
                                    }, () => {
                                        const update = {
                                            sick_leave_project_id: e.id
                                        };
                                        this.changeProject('sick_leave_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Childcare leave project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: true, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.child_sick_leave_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        child_sick_leave_project_id: e.id
                                    }, () => {
                                        const update = {
                                            child_sick_leave_project_id: e.id
                                        };
                                        this.changeProject('child_sick_leave_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        {CustomDialog && <CustomDialog {...this.state.dialogProps} onClose={this.closeDialog} />}

                        <Dialog
                            open={this.state.dialogOpen}
                            >
                            <DialogTitle><WarningIcon style={{ marginRight: 16 }} /> {this.tr('Deactivate Procountor?')}</DialogTitle>
                            <DialogContent>
                                    <DialogContentText>
                                       {this.tr(' Are you sure you want to deactivate Procountor?')}
                                    </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.dialogOpen}>
                                    {this.tr('Cancel')}
                                </Button>
                                <Button onClick={this.deactivate} style={{background: "#d0021b", color: "#ffffff"}}>
                                    {this.tr('Deactivate')}
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                }
            </div>
        )
    }
}

export default withSnackbar(Procountor);
