import React from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { cloneDeep, uniqBy, isEqual } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Button, MenuItem, Switch, Tab, Tabs, Tooltip } from '@mui/material';
import { Add, AddBox, AddCircleOutlined, Close, Delete, Edit, FileCopy, FormatListNumberedOutlined, MoreVert, Print, Search, TrackChangesOutlined } from '@mui/icons-material';
import { getAutocompleteDataForDialog } from '../resourcing/helpers';

import Quote from './Quote';
import List from '../list/List';
import QuoteRow from './QuoteRow';
import DataList from '../general/DataList';
import ContextMenu from '../general/ContextMenu';
import DataHandler from '../general/DataHandler';
import TaimerComponent from '../TaimerComponent';
import { validEmail } from '../dialogs/Validate';
import LoaderButton from '../general/LoaderButton';
import OutlinedField from '../general/OutlinedField';
import ResourceDialog from '../dialogs/ResourceDialog';
import ProposalEditor from './proposal/ProposalEditor';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
import ConfirmationDialog from '../settings/dialogs/ConfirmationDialog';
import { CopyQuoteDialog, CopySimpleQuoteDialog } from '../dialogs/CopyQuoteDialog';
import Utils from './../general/Utils.js';

import styles from './TabQuotes.module.scss';

export const quoteRowTypes = {
    headerRow: 0, // CPQ itemized initial row is just headerRow with cpqHeader: true
    quoteRow: 1,
    descriptionRow: 2,
    productRow: 3,
    cpqGroupedRow: 4,
    summaryRow: 99,
};

interface Props extends WithSnackbarProps {
    project: any;
    selectedQuoteId?: string;
    checkPrivilege: (module, permission) => boolean;
    canCreateInvoice: boolean;
    updateProjectData?: () => void;
    printMode?: boolean;
    showLockedUsersWithTag: boolean;
}

interface State {
    visitingAddresses: any[];
    receiverContacts: any[];
    companyContacts: any[];
    allUsers: any[];
    products: any[];
    jobtypes: any[];
    printLanguageOptions: any[];
    printDateOptions: any[];
    quotes: any[];
    quoteRows: any[];
    CPQParents: any[];
    defaultVAT: number;
    companyAddress: any;
    resourcingAutoCompleteData?: any;
    selectedQuote?: any;
    editMode: boolean;
    printLanguage: string;
    printDateFormat: string;
    currency: string;
    currentDialog?: string;
    dialogData?: any;
    columnSettingsAnchor?: any;
    targetingSectionOpen: boolean;
    detailsOpen: boolean;
    activeCell?: string;
    totals: any;
    /** For rendering a quote with print layout to html and sending that to print service */
    printQuote?: any;
    /** For rendering a quote with print layout to html and passing that to proposal editor */
    proposalQuote?: any;
    /** For old print method to tell puppeteer that the quote to print is now fully rendered (by adding an id to the div) */
    printQuoteReady?: boolean;
    deleteConfirmationQuote?: any;
    loadingQuotes: boolean;
    saving: boolean;
    selectedTargetingTab: string;
    selectedDetailsTab: string;
    grandTotalHidden: boolean;
    targetingSectionY: number;
}

class TabQuotes extends TaimerComponent<Props, State> {
    static defaultProps = { showLockedUsersWithTag: true };

    quantityTypes: any[];
    workTypes: any[];
    costTargetingColumns: any[];
    billTargetingColumns: any[];
    originalQuote: any;
    quote: any = React.createRef();
    quoteToPrint: any = React.createRef();
    quotePrintSnackbar: any;
    translations: any = {};
    detailsTabs = {
        quoteVersions: 'quoteVersions',
        printDetails: 'printDetails',
    };
    targetingTabs = {
        costTargeting: 'costTargeting',
        billTargeting: 'billTargeting',
    };
    dialogs = {
        confirmation: ConfirmationDialog,
        resourcing: ResourceDialog,
        copySimple: CopySimpleQuoteDialog,
        copyQuote: CopyQuoteDialog,
        cancel: ConfirmationDialog,
    };

    constructor(props, context) {
        super(props, context, 'projects/TabQuotes');

        this.quantityTypes = [
            { id: 1, value: 1, name: this.tr('qty'), label: this.tr('qty') },
            { id: 2, value: 2, name: this.tr('h'), label: this.tr('h') },
        ];

        this.workTypes = [
            { name: this.tr('Hours'), label: this.tr('Hours'), id: 1 },
            { name: this.tr('Sub-contracting'), label: this.tr('Sub-contracting'), id: 2 },
            { name: this.tr('Services'), label: this.tr('Services'), id: 3 },
            { name: this.tr('Products'), label: this.tr('Products'), id: 4 },
            { name: this.tr('Expenses'), label: this.tr('Expenses'), id: 5 },
        ];

        const commonColumnProps = {
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
        };

        this.costTargetingColumns = [
            {
                field: 'cost_total',
                name: 'cost_total',
                header: this.tr('Unit cost'),
                width: 35,
                alignRight: true,
                currency: true,
                disabled: true,
                ...commonColumnProps,
            },
            {
                field: 'targeted',
                name: 'targeted',
                header: this.tr('Targeted cost'),
                width: 40,
                alignRight: true,
                currency: true,
                ...commonColumnProps,
            },
        ];

        this.billTargetingColumns = [
            {
                field: 'quantity_sold',
                name: 'quantity_sold',
                header: this.tr('Sold'),
                width: 35,
                alignRight: true,
                disabled: true,
                ...commonColumnProps,
            },
            {
                field: 'quantity_delivered',
                name: 'quantity_delivered',
                header: this.tr('Delivered'),
                width: 40,
                alignRight: true,
                ...commonColumnProps,
            },
            {
                field: 'quantity_invoiced',
                name: 'quantity_invoiced',
                header: this.tr('Invoiced'),
                width: 35,
                alignRight: true,
                disabled: true,
                ...commonColumnProps,
            },
        ];

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };

        this.state = {
            quotes: [],
            quoteRows: [],
            totals: {},
            visitingAddresses: [],
            receiverContacts: [],
            companyContacts: [],
            allUsers: [],
            printLanguageOptions: [],
            printDateOptions: [],
            products: [],
            CPQParents: [],
            jobtypes: [],
            companyAddress: {},
            printLanguage: 'en',
            printDateFormat: this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            selectedQuote: undefined,
            editMode: false,
            targetingSectionOpen: false,
            detailsOpen: false,
            saving: false,
            currency: 'EUR',
            loadingQuotes: true,
            selectedTargetingTab: this.targetingTabs.costTargeting,
            selectedDetailsTab: this.detailsTabs.quoteVersions,
            defaultVAT: 0,
            grandTotalHidden: this.getGrandTotalHiddenFromLS(),
            targetingSectionY: 0,
        };
    }

    getGrandTotalHiddenFromLS = () => {
        let grandTotalHidden = false;
        try {
            const savedValue = localStorage.getItem('quote_grand_total_hidden') || '0';
            grandTotalHidden = savedValue == '1';
        } catch (e) {
            console.error(e);
        }
        return grandTotalHidden;
    };

    saveGrandTotalHiddenToLS = () => {
        try {
            localStorage.setItem('quote_grand_total_hidden', this.state.grandTotalHidden ? '1' : '0');
        } catch (e) {
            console.error(e);
        }
    };

    componentDidMount = () => {
        super.componentDidMount();
        this.getQuotes();
        this.getJobtypes();
        this.getAllUsers();
        this.getDefaultVAT();
        this.getCompanyData();
        this.getCompanyAddress();
        this.getCompanyContacts();
        this.getReceiverContacts();
        this.getVisitingAddresses();
        // for updating quotes after a task is saved (to show task indicator on row immediately)
        window.addEventListener('taskSaved', this.getQuotes);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('taskSaved', this.getQuotes);
    };

    shouldComponentUpdate = (_, oldState) => {
        // preventing a render loop when setting printQuoteReady to true (happens in render and would trigger an endless loop of re-renders)
        if (oldState.printQuoteReady && this.state.printQuoteReady) {
            return false;
        }
        return true;
    };

    componentDidUpdate = (_, oldState) => {
        // getting products only after moving to edit mode, it was done so in the old one as well
        if (!oldState.editMode && this.state.editMode) {
            this.getProducts();
        }
        if (!isEqual(oldState.selectedQuote?.headers, this.state.selectedQuote?.headers)) {
            const { quoteRows, totals } = this.getQuoteRows(this.state.selectedQuote);
            this.setState({ quoteRows, totals });
        }
    };

    getInitialRows = (type = '1') => {
        let id = 1;
        (this.state.quoteRows || []).forEach((quoteRow) => {
            if (Number(quoteRow.id) >= id) {
                id = Number(quoteRow.id) + 1;
            }
        });

        /* quote.type: 1 -> normal quote */
        /* quote.type: 2 -> refund quote */
        let typeSpecific;

        if (type === '1')
            typeSpecific = {
                quantity: 1,
            };
        else if (type === '2')
            typeSpecific = {
                quantity: -1,
            };

        const initialRows = [
            {
                type: quoteRowTypes.headerRow,
                isNew: true,
                id,
                noAutoFocus: true,
                rows: [
                    {
                        ...typeSpecific,
                        type: quoteRowTypes.quoteRow,
                        parentId: id,
                        isNew: true,
                        id: id + 1,
                        value: 0,
                        cost: 0,
                        discountPercentage: 0,
                        quantityType: this.quantityTypes[0].id,
                        vat: this.state.defaultVAT,
                        noAutoFocus: true,
                        workType: 1,
                    },
                ],
            },
        ];
        return initialRows;
    };

    getDefaultVAT = async () => {
        const {
            project: { companies_id },
        } = this.props;
        const response = await DataHandler.get({ url: `settings/company/${companies_id}/project/defaultQuoteVat` });
        this.setState({ defaultVAT: response.default_quote_vat || 0 });
    };

    getReceiverContacts = async () => {
        const { project } = this.props;
        let customerContacts = await DataHandler.get({ url: `subjects/contacts/${project.customers_id}` });
        let receiverContacts = [...(customerContacts || [])];
        if (project.contacts) {
            const projectContacts = [...project.contacts];
            const compare = (value) => String(value.id);
            receiverContacts = uniqBy(customerContacts.concat(projectContacts), compare);
        }
        this.setState({ receiverContacts });
    };

    getVisitingAddresses = async () => {
        const { project } = this.props;
        const visitingAddresses = await DataHandler.get({ url: `accounts/${project.account.id}/visiting_addresses/${project.companies_id}` });
        this.setState({ visitingAddresses: (visitingAddresses || []).map((a) => ({ ...a, label: a.address, value: a.id })) });
    };

    getProducts = async () => {
        const {
            project: { companies_id, account },
        } = this.props;
        const responses = await Promise.all([
            DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 0, account: account.id }),
            DataHandler.get({ url: `/cpq/parents/${companies_id}` }),
        ]);

        const products = responses[0] || {};
        const CPQParents = responses[1] || {};

        this.setState({
            products: (products.products || []).map((product) => ({ ...product, label: product.name })),
            CPQParents: (CPQParents.CPQParents || []).map((cpq) => ({ ...cpq, label: cpq.name })),
        });
    };

    getCompanyData = async () => {
        const {
            project: { companies_id },
        } = this.props;
        const companies = await DataHandler.get({
            url: `subjects/companies_with_project_right/read+project_cost_estimate_read`,
            currency: 1,
            date_format: 1,
            print_lang: 1,
            country_lang: 1,
            print_options: 1,
        });
        const currentCompany = companies.find((c) => c.id == companies_id);
        if (!currentCompany) return;
        const defaultPrintOptions = this.getDefaultPrintOptions(currentCompany);
        this.setState({
            ...defaultPrintOptions,
            currency: currentCompany.currency,
        });
    };

    getDefaultPrintOptions = (company) => {
        return {
            printLanguage: company.print_lang ? company.print_lang : company.country_lang,
            printLanguageOptions: company.print_languages || [],
            printDateFormat: company.date_format || this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printDateOptions: company.print_date_formats || [],
        };
    };

    convertDateFormat = (format, reverse = false) => {
        if (!format) return undefined;
        if (reverse) {
            return format.replace('DD', '%d').replace('MM', '%m').replace('YYYY', '%Y');
        }
        return format.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY');
    };

    getJobtypes = async () => {
        if (!this.context.addons.nav) return;
        const {
            project: { companies_id, account },
        } = this.props;
        const jobtypes = await DataHandler.get({ url: `accounts/quote_jobtypes/${account?.id}`, company: companies_id });
        this.setState({ jobtypes });
    };

    getAllUsers = async () => {
        const allUsers = await DataHandler.get({ url: `subjects/employees` });
        const userTagProps = {
            fields: { name: 'name' },
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
        };        
        allUsers.forEach((p, i) => {
            allUsers[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
        });

        this.setState({ allUsers });
    };

    getCompanyContacts = async () => {
        const {
            project,
            project: { companies_id },
        } = this.props;
        const companyContacts = await DataHandler.get({
            url: `subjects/employees/projects/project_cost_estimate_write/${companies_id}`,
            dontIgnoreCompany: project.limited_project_team == 1,
            showAuthUserFromWrongCompany: true,
        });
        const userTagProps = {
            fields: { label: 'label' },
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
        };        
        companyContacts.forEach((p, i) => {
            companyContacts[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
        });
        this.setState({ companyContacts });
    };

    getCompanyAddress = async () => {
        const {
            project: { companies_id },
        } = this.props;
        const companyAddress = await DataHandler.get({ url: `/settings/company/${companies_id}/address` });
        this.setState({
            companyAddress,
        });
    };

    getQuotes = async () => {
        const { project, selectedQuoteId } = this.props;
        const quotes = await DataHandler.get({ url: `projects/${project.id}/quotes` });
        // showing active ones first in the list
        quotes?.sort((a, b) => Number(b.active) - Number(a.active));
        const selectedQuote = quotes.find((q) => q.id == (this.state.selectedQuote?.id || selectedQuoteId)) || quotes[0];
        // originalQuote is what's shown after canceling edit
        this.originalQuote = selectedQuote;
        this.setState({
            loadingQuotes: false,
            quotes,
            selectedQuote,
        });
    };

    getCurrencyFormatter = () => Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.state.currency });

    onAddressEdited = (e, additionalValues) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        const { name, value } = e.target;
        selectedQuote = {
            ...selectedQuote,
            editedAddress: {
                ...selectedQuote.editedAddress,
                [name]: value,
                ...additionalValues,
            },
        };
        this.setState({ selectedQuote });
    };

    onAddressSelected = (address_id, data) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        selectedQuote = {
            ...selectedQuote,
            address_id,
            editedAddress: {
                ...selectedQuote.editedAddress,
                address: data?.address,
                name: data?.name,
                postalcode: data?.postalcode,
                city: data?.city,
                state: data?.state,
                country: data?.country,
                vatid: data?.vatid,
            },
        };
        this.setState({ selectedQuote });
    };

    onCompanyAddressEdited = (e) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        const { name, value } = e.target;
        selectedQuote = {
            ...selectedQuote,
            address: {
                ...selectedQuote.address,
                [name]: value,
            },
        };
        this.setState({ selectedQuote });
    };

    onQuoteEdited = (e) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        const { name, value } = e.target;
        selectedQuote = {
            ...selectedQuote,
            [name]: value,
        };
        this.setState({ selectedQuote }, () => {
            if (!this.state.editMode) {
                // if editing print visibility when not in edit mode, changes need to be saved immediately
                this.onSave();
            }
        });
    };

    // This is kind of horrifying. Parsing quote rows from headers & calculating totals
    getQuoteRows = (quote) => {
        let rows: any = [];
        let footerId = 1;

        // For grand totals
        const totals = {
            vatTotals: {},
            vatTotal: 0,
            subtotal: 0,
            discount: 0,
            total: 0,
            cost: 0,
            budgetedCost: 0,
            actualCost: 0,
            actualCostPercentage: 0,
            budgetedProfit: 0,
            actualProfit: 0,
            actualProfitPercentage: 0,
            budgetedMargin: 0,
            actualMargin: 0,
            actualMarginPercentage: 0,
        };

        if (!quote) return { quoteRows: rows, totals };

        // for cost targeting totals
        let targetCost = 0;
        let targetWithoutOwnWorkSum = 0;
        let targetMargin = 0;

        (quote.headers || []).forEach((header) => {
            rows.push(header);
            // for header totals
            const sectionTotals = {
                cost: 0,
                value: 0,
                discount: 0,
                vatTotal: 0,
                total: 0,
                margin: 0,
                targeted: Number(header.targeted || 0),
                quantity: 0,
                quantity_delivered: 0,
                quantity_invoiced: 0,
            };
            if (Number(header.has_automatic_targeting) > 0 || Number(header.targeted) > 0) {
                targetCost += Number(header.targeted || 0);
                targetWithoutOwnWorkSum += Number(header.targeted || 0) - Number(header.targeted_hours || 0);
            }
            (header.rows || []).forEach((row) => {
                if (row.type != 0 && row.type != 2 && row.deleted != 1) {
                    const discountValue = parseInt(row.discountPercentage) > 0 ? (parseInt(row.discountPercentage) / 100) * Number(row.value) : 0;
                    const calcValue = Number(row.value) - discountValue;
                    const sum = Number(row.quantity) * calcValue;
                    const sumWithoutDiscount = Number(row.value) * Number(row.quantity);
                    const value = Number(row.quantity) * Number(row.value);
                    const vatTotal = (Number(row.vat) / 100) * (calcValue * Number(row.quantity));
                    const margin = sum - Number(row.quantity) * Number(row.cost);
                    const cost = parseFloat(row.cost) * parseFloat(row.quantity);
                    const total = sum + vatTotal;
                    const rowVat = Number(row.vat || 0).toFixed(2);
                    const currentVatTotal = totals.vatTotals[rowVat] || 0;

                    totals.discount += discountValue * Number(row.quantity);
                    totals.vatTotal += vatTotal;
                    totals.cost += cost;
                    totals.vatTotals = {
                        ...totals.vatTotals,
                        [rowVat]: currentVatTotal + vatTotal,
                    };
                    totals.subtotal += sumWithoutDiscount || 0;
                    totals.total += total;

                    sectionTotals.cost += cost;
                    sectionTotals.vatTotal += vatTotal || 0;
                    sectionTotals.discount += discountValue * Number(row.quantity);
                    sectionTotals.value += value;
                    sectionTotals.total += sum || 0;
                    sectionTotals.margin += margin || 0;
                    sectionTotals.targeted += Number(row.targeted || 0);
                    sectionTotals.quantity += Number(row.quantity || 0);
                    sectionTotals.quantity_delivered += Number(row.quantity_delivered || 0);
                    sectionTotals.quantity_invoiced += Number(row.quantity_invoiced || 0);

                    targetMargin += sum - (row.workType != 1 ? cost : 0);
                    targetCost += Number(row.targeted || 0);
                    targetWithoutOwnWorkSum += row.workType != 1 ? Number(row.targeted || 0) : 0;
                }
                const quoteRow = cloneDeep(row);
                rows.push(quoteRow);
            });
            rows.forEach((row) => {
                if (row.type == quoteRowTypes.summaryRow && Number(row.id.replace('footer_', '') >= footerId)) {
                    footerId++;
                }
            });
            if (header.deleted != 1) {
                rows.push({
                    id: `footer_${footerId}`,
                    type: quoteRowTypes.summaryRow,
                    parentId: header.id,
                    ...sectionTotals,
                });
            }
        });

        totals.budgetedCost = totals.cost;
        totals.actualCost = targetCost;
        totals.actualCostPercentage = (totals.budgetedCost != 0 ? totals.actualCost / totals.budgetedCost : 0) * 100;

        totals.budgetedProfit = totals.subtotal - totals.discount - totals.cost;
        totals.actualProfit = totals.subtotal - totals.discount - targetCost;
        totals.actualProfitPercentage = (totals.budgetedProfit != 0 ? totals.actualProfit / totals.budgetedProfit : 0) * 100;

        totals.budgetedMargin = targetMargin;
        totals.actualMargin = totals.subtotal - totals.discount - targetWithoutOwnWorkSum;
        totals.actualMarginPercentage = (totals.budgetedMargin != 0 ? totals.actualMargin / totals.budgetedMargin : 0) * 100;

        if (rows.filter((r) => r.deleted != 1).length == 0 && this.state.editMode) {
            // triggers this function again but rows should then exist
            this.setState({
                selectedQuote: {
                    ...quote,
                    headers: [...(quote.headers || []), ...this.getInitialRows(quote.type)],
                },
            });
        }

        return { quoteRows: rows, totals };
    };

    onRowEdit = (data) => {
        this.editRow(data);
    };

    onAddRow = (row, addBelowRowId?) => {
        const { defaultVAT } = this.state;
        if (!this.state.selectedQuote) return;
        let id = 1;
        this.state.quoteRows.forEach((quoteRow) => {
            if (Number(quoteRow.id) >= id) {
                id = Number(quoteRow.id) + 1;
            }
        });
        const rowToAdd = { ...row, isNew: true, id };
        switch (row.type) {
            case 0:
            case 2:
                this.addRow(rowToAdd, addBelowRowId);
                break;
            default:
                this.addRow({ value: 0, cost: 0, discountPercentage: 0, quantity: 1, quantityType: this.quantityTypes[0].id, vat: defaultVAT, ...rowToAdd }, addBelowRowId);
        }
    };

    addRow = (row, addBelowRowId?) => {
        if (!this.state.selectedQuote) return;
        const selectedQuote = cloneDeep(this.state.selectedQuote);
        const headers = selectedQuote.headers || [];
        if (row.type == quoteRowTypes.headerRow) {
            headers.push(row);
        } else {
            const headerIndex = selectedQuote.headers.findIndex((h) => h.id == row.parentId);
            if (headerIndex != -1) {
                const rows = [...(headers[headerIndex]?.rows || [])];
                if (addBelowRowId) {
                    if (addBelowRowId == row.parentId) {
                        rows.unshift(row);
                    } else {
                        const index = rows.findIndex((r) => r.id == addBelowRowId);
                        if (index != -1) {
                            rows.splice(index + 1, 0, row);
                        }
                    }
                } else {
                    rows.push(row);
                }
                if (headers[headerIndex]) {
                    headers[headerIndex].rows = rows;
                }
            }
        }
        this.setState({
            selectedQuote: {
                ...selectedQuote,
                headers,
            },
        });
    };

    editRow = (newRow) => {
        if (!this.state.selectedQuote) return;
        const selectedQuote = cloneDeep(this.state.selectedQuote);
        const headers = selectedQuote.headers || [];
        const row = cloneDeep(newRow);
        if (row.type == quoteRowTypes.headerRow) {
            const index = headers.findIndex((r) => r.id == row.id);
            if (index != -1) {
                headers[index] = row;
                if (row.deleted == 1) {
                    (row.rows || []).forEach((r, i) => {
                        row.rows[i] = { ...r, deleted: 1 };
                    });
                }
            }
        } else {
            const headerIndex = selectedQuote.headers.findIndex((h) => h.id == row.parentId);
            if (headerIndex != -1) {
                const rows = [...(headers[headerIndex]?.rows || [])];
                const index = rows.findIndex((r) => r.id == row.id);
                if (index != -1) {
                    rows[index] = row;
                }
                if (headers[headerIndex]) {
                    headers[headerIndex].rows = rows;
                }
            }
        }
        this.setState(
            {
                selectedQuote: {
                    ...selectedQuote,
                    headers,
                },
            },
            () => {
                if (!this.state.editMode) {
                    // if editing print visibility when not in edit mode, changes need to be saved immediately
                    this.onSave();
                }
            }
        );
    };

    onContactSelected = (contact) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        selectedQuote = {
            ...selectedQuote,
            receiver_contact_id: contact.id,
            editedAddress: {
                ...selectedQuote.editedAddress,
                custom_contact: contact.label,
                email: contact.email,
                phone: contact.phone,
            },
        };
        this.setState({ selectedQuote });
    };

    onSenderContactSelected = (contact) => {
        if (!this.state.selectedQuote) return;
        let selectedQuote = cloneDeep(this.state.selectedQuote);
        selectedQuote = {
            ...selectedQuote,
            sender_contact_id: contact.id,
            company_phone: contact.phone,
            company_email: contact.email,
        };
        this.setState({ selectedQuote });
    };

    setSelectedQuote = (selectedQuote, setEditMode = false) => {
        // originalQuote is what's shown after canceling edit
        this.originalQuote = selectedQuote;
        this.setState({ selectedQuote }, () => {
            setEditMode && this.setState({ editMode: true });
        });
    };

    updateHeaders = (headers) => {
        this.setState({
            selectedQuote: {
                ...this.state.selectedQuote,
                headers,
            },
        });
    };

    renderContent = () => {
        const { selectedQuote, loadingQuotes } = this.state;
        return (
            <div className={styles.content}>
                <div className={styles.left}>
                    {!loadingQuotes && !selectedQuote ? (
                        <div className={styles.noQuoteView}>
                            <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                            <h3>{this.tr('No quote selected!')}</h3>
                            <Button onClick={this.onAddQuote} color="primary" size="large" data-testid="quote-button-add-new-quote">
                                {this.tr('Add new quote')}
                            </Button>
                            {this.context.addons.refund_material && (
                                <>
                                    <div>&nbsp;</div>
                                    <Button onClick={() => this.addQuote('2')} size="large" color="primary">
                                        {this.tr('Add new refund quote')}
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : (
                        this.renderQuote()
                    )}
                </div>
                <div className={styles.right}>
                    {this.renderDetails()}
                    {this.renderTargetingSection()}
                </div>
            </div>
        );
    };

    showProposal = () => {
        const { proposalQuote, printLanguage, printDateFormat } = this.state;
        this.context.functions.setOverlayComponent(
            <ProposalEditor
                printLanguage={printLanguage}
                printDateFormat={this.convertDateFormat(printDateFormat)}
                refreshQuoteData={this.getQuotes}
                quoteHTML={this.quoteToPrint.current && this.quoteToPrint.current.outerHTML}
                checkPrivilege={this.props.checkPrivilege}
                project={this.props.project}
                quote={proposalQuote}
            />
        );
        // this is used to render the print content for selected quote and passing it to proposal editor,
        // here it is no longer needed
        this.setState({ proposalQuote: undefined });
    };

    onToggleQuoteActive = (quote, checked) => {
        const quotes = cloneDeep(this.state.quotes);
        const index = quotes.findIndex((c) => c.id == quote.id);
        if (index != -1) {
            quotes[index].active = checked;
        }
        let selectedQuote;
        if (this.state.selectedQuote) {
            selectedQuote = cloneDeep(this.state.selectedQuote);
            if (quote.id == this.state.selectedQuote.id) {
                selectedQuote.active = checked;
            }
        }
        this.setState({ quotes, selectedQuote });
        DataHandler.post({ url: `projects/quotes/${quote.id}/status` }, { active: checked })
            .done()
            .fail((err) => {
                console.error(err);
                this.getQuotes();
            });
    };

    deleteQuote = (id) => {
        const quotes = cloneDeep(this.state.quotes);
        const quoteIndex = quotes.findIndex((q) => q.id == id);
        if (quoteIndex != -1) {
            quotes.splice(quoteIndex, 1);
        }
        let selectedQuote = this.state.selectedQuote;
        if (quotes.findIndex((q) => q.id == selectedQuote?.id) == -1) {
            selectedQuote = quotes[0];
        }

        if (quotes.length == 0) {
            this.setState({ quotes, selectedQuote: undefined });
        } else {
            this.setState({
                quotes,
                editMode: false,
                selectedQuote,
            });
        }

        if (Number(id > 0)) {
            DataHandler.delete({ url: `projects/quotes/${id}` })
                .done()
                .fail((err) => {
                    console.error(err);
                    this.props.enqueueSnackbar(this.tr('Deleting quote failed!'), {
                        variant: 'error',
                    });
                });
        }
    };

    onDeleteQuote = (deleteConfirmationQuote) => this.setState({ deleteConfirmationQuote });

    renderDetailsContent = () => {
        const { editMode, selectedDetailsTab, quotes, loadingQuotes } = this.state;
        const {
            checkPrivilege,
            project: { charge_costest, type },
            canCreateInvoice,
        } = this.props;
        const editable = checkPrivilege('projects', 'project_cost_estimate_write');
        switch (selectedDetailsTab) {
            case this.detailsTabs.quoteVersions:
                return (
                    <div className={styles.quoteVersions}>
                        {loadingQuotes || quotes.length == 0 ? (
                            <p>{loadingQuotes ? this.tr('Loading quotes...') : this.tr('No quotes.')}</p>
                        ) : (
                            <ul>
                                <li>
                                    <div>
                                        <h3>{this.tr('Active')}</h3>
                                    </div>
                                    <div>
                                        <h3>{this.tr('Quote name')}</h3>
                                    </div>
                                    {!editMode && (
                                        <Tooltip title={this.tr('Add quote')}>
                                            <button className={styles.addQuoteButton} onClick={this.onAddQuote} data-testid="quote-versions-button-add-new">
                                                <Add />
                                            </button>
                                        </Tooltip>
                                    )}
                                </li>
                                {this.state.quotes.map((quote) => {
                                    const showProposal =
                                        (!(!quote.proposals_id || quote.proposals_id == '-1') && checkPrivilege('projects', 'proposal_read')) || checkPrivilege('projects', 'proposal_write');
                                    return (
                                        <li
                                            key={quote.id}
                                            onClick={() => this.setSelectedQuote(quote)}
                                            className={`${this.state.selectedQuote?.id == quote.id ? styles.active : editMode ? styles.disabled : ''}`}
                                        >
                                            <div>
                                                <Switch color="primary" checked={quote.active == 1} onChange={(e) => this.onToggleQuoteActive(quote, e.target.checked)} />
                                            </div>
                                            <div className={styles.texts}>
                                                <h3>{quote.name == "New quote" ? this.tr("New quote header") : quote.name}</h3>
                                                {/* quote.type: 1 -> normal quote */}
                                                {/* quote.type: 2 -> refund quote */}
                                                {quote.type === '1' &&
                                                    (!editMode
                                                        ? showProposal && (
                                                              <button onClick={() => this.onShowProposal(quote)}>
                                                                  {!quote.proposals_id || quote.proposals_id == '-1' ? this.tr('Create Proposal') : this.tr('View Proposal')}
                                                              </button>
                                                          )
                                                        : quote.id == this.state.selectedQuote?.id && <p>{this.tr('Currently editing')}</p>)}
                                                {quote.type === '2' && <p>{this.tr('Refund quote')}</p>}
                                            </div>
                                            {editable && !editMode && (
                                                //@ts-ignore
                                                <ContextMenu customGrowId={styles.rowMenu} label={<MoreVert />} disablePortal={false} popperProps={{ placement: 'left-end' }} noExpandIcon>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setSelectedQuote(quote, true);
                                                        }}
                                                    >
                                                        <Edit />
                                                        {this.tr('Edit quote')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.onPrintQuote(quote);
                                                        }}
                                                    >
                                                        <Print />
                                                        {this.tr('Print quote')}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.openCopyDialog('copySimple', quote.id, quote.name)}>
                                                        <FileCopy />
                                                        {this.tr('Copy')}
                                                    </MenuItem>
                                                    {quote && type == '1' && charge_costest == '1' && canCreateInvoice && (
                                                        <Tooltip title={this.renderInvoicingDisabledMsg(quote)}>
                                                            <div>
                                                                <MenuItem
                                                                    disabled={this.allInvoiceableRowsAreHidden(quote) || quote.fully_invoiced === '1' || Number(quote.active) === 0}
                                                                    onClick={(e) => e.button !== 1 && this.onInvoiceQuote(e, quote)}
                                                                    onMouseUp={(e) => e.button === 1 && this.onInvoiceQuote(e, quote)}
                                                                >
                                                                    <AddBox />
                                                                    {this.tr('Add Invoice')}
                                                                </MenuItem>
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                    <MenuItem className={styles.delete} onClick={() => this.onDeleteQuote(quote)}>
                                                        <Delete />
                                                        {this.tr('Delete quote')}
                                                    </MenuItem>
                                                </ContextMenu>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                );
            case this.detailsTabs.printDetails:
                return this.renderPrintSettings();
        }
    };

    renderDetails = () => {
        const { detailsOpen, selectedDetailsTab } = this.state;
        return (
            <>
                <button className={styles.detailsToggle} onClick={this.toggleDetails}>
                    {detailsOpen ? <Close /> : <FormatListNumberedOutlined />}
                    <p>{detailsOpen ? this.tr('Close') : this.tr('Versions & details')}</p>
                </button>
                <div className={`${styles.details} ${detailsOpen ? styles.open : ''}`}>
                    <div className={styles.tabs}>
                        <Tabs color="primary" value={selectedDetailsTab} onChange={this.onSelectDetailsTab} className="details">
                            <Tab value={this.detailsTabs.quoteVersions} label={this.tr('Quote versions')} />
                            <Tab value={this.detailsTabs.printDetails} label={this.tr('Print details')} />
                        </Tabs>
                    </div>
                    {this.renderDetailsContent()}
                </div>
            </>
        );
    };

    toggleTargetingSection = () => this.setState({ targetingSectionOpen: !this.state.targetingSectionOpen });
    toggleDetails = () => this.setState({ detailsOpen: !this.state.detailsOpen });

    onSelectDetailsTab = (_, selectedDetailsTab) => this.setState({ selectedDetailsTab });
    onSelectTargetingTab = (_, selectedTargetingTab) => this.setState({ selectedTargetingTab });

    renderTargetingSection = () => {
        const { selectedQuote, editMode, totals, quoteRows, targetingSectionOpen, selectedTargetingTab, targetingSectionY } = this.state;
        const currencyFormatter = this.getCurrencyFormatter();
        const showBillTargeting = !!this.context.addons?.quoterow_partial_invoicing_for_products;
        if (!selectedQuote) return null;
        return (
            <>
                <button className={`${styles.targetingSectionToggle} ${editMode ? styles.editMode : ''}`} style={{ top: targetingSectionY }} onClick={this.toggleTargetingSection}>
                    {targetingSectionOpen ? <Close /> : <TrackChangesOutlined />}
                    <p>{targetingSectionOpen ? this.tr('Close') : this.tr('Cost targeting')}</p>
                </button>
                <div className={`${styles.targetingSection} ${editMode ? styles.editMode : ''} ${targetingSectionOpen ? styles.open : ''}`} style={{ top: targetingSectionY - 48 }}>
                    <div className={styles.titleContainer}>
                        {!showBillTargeting ? (
                            <h2>{this.tr('Cost targeting')}</h2>
                        ) : (
                            <Tabs color="primary" value={selectedTargetingTab} onChange={this.onSelectTargetingTab} className="details">
                                <Tab value={this.targetingTabs.costTargeting} label={this.tr('Cost targeting')} />
                                <Tab value={this.targetingTabs.billTargeting} label={this.tr('Bill targeting')} />
                            </Tabs>
                        )}
                    </div>

                    <div className={styles.listContainer}>
                        <List
                            className={`${styles.list} ${editMode ? styles.editMode : ''}`}
                            fluid
                            manualCreate
                            hideUndefinedCells
                            columns={selectedTargetingTab == this.targetingTabs.billTargeting ? this.billTargetingColumns : this.costTargetingColumns}
                            height="auto"
                            listRowType={QuoteRow}
                            noColorVariance
                            noStateData
                            ignoreRowPropsChange={false}
                            data={quoteRows}
                            onEdit={this.onRowEdit}
                            useLazyLoad
                            rowProps={{
                                editMode,
                                tr: this.tr,
                                currencyFormatter,
                                selectedTargetingTab,
                                formatNumberInput: this.formatNumberInput,
                            }}
                        />
                        {selectedTargetingTab == this.targetingTabs.costTargeting && (
                            <div className={styles.totalRow}>
                                <div className={styles.totalValues}>
                                    <div>
                                        <h3>{this.tr('Budgeted cost')}</h3>
                                        <p>{currencyFormatter.format(totals?.budgetedCost || 0)}</p>
                                    </div>
                                    <div>
                                        <h3>{this.tr('Actual cost')}</h3>
                                        <p>{currencyFormatter.format(totals?.actualCost || 0)}</p>
                                        <p className={Number(totals?.actualCostPercentage || 0) <= 100 ? styles.green : styles.red}>{`${(totals?.actualCostPercentage || 0).toFixed(2)} %`}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedTargetingTab == this.targetingTabs.costTargeting && (
                            <div className={styles.totalRow}>
                                <div className={styles.totalValues}>
                                    <div>
                                        <h3>{this.tr('Budgeted profit')}</h3>
                                        <p>{currencyFormatter.format(totals?.budgetedProfit || 0)}</p>
                                    </div>
                                    <div>
                                        <h3>{this.tr('Actual profit')}</h3>
                                        <p>{currencyFormatter.format(totals?.actualProfit || 0)}</p>
                                        <p className={Number(totals?.actualProfitPercentage || 0) >= 100 ? styles.green : styles.red}>{`${(totals?.actualProfitPercentage || 0).toFixed(2)} %`}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedTargetingTab == this.targetingTabs.costTargeting && (
                            <div className={styles.totalRow}>
                                <div className={styles.totalValues}>
                                    <div>
                                        <h3>{this.tr('Budgeted gross margin')}</h3>
                                        <p>{currencyFormatter.format(totals?.budgetedMargin || 0)}</p>
                                    </div>
                                    <div>
                                        <h3>{this.tr('Actual gross margin')}</h3>
                                        <p>{currencyFormatter.format(totals?.actualMargin || 0)}</p>
                                        <p className={Number(totals?.actualMarginPercentage || 0) >= 100 ? styles.green : styles.red}>{`${(totals?.actualMarginPercentage || 0).toFixed(2)} %`}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    onGrandTotalVisibilityChange = (grandTotalHidden) => {
        this.setState(
            {
                grandTotalHidden,
            },
            () => this.saveGrandTotalHiddenToLS()
        );
    };

    setTargetingSectionY = (targetingSectionY) => {
        this.setState({ targetingSectionY });
    };

    renderQuote = (selectedQuote = this.state.selectedQuote, options: { printMode?: boolean; onMount?: () => void } = {}) => {
        const { project, checkPrivilege } = this.props;
        const { printMode = false, onMount } = options;
        const {
            visitingAddresses,
            printDateFormat,
            companyAddress,
            companyContacts,
            receiverContacts,
            quoteRows,
            totals,
            jobtypes,
            editMode,
            products,
            CPQParents,
            loadingQuotes,
            printLanguage,
            allUsers,
            grandTotalHidden,
        } = this.state;
        const currencyFormatter = this.getCurrencyFormatter();
        let rows = quoteRows;
        let quoteTotals = totals;
        if (printMode) {
            const { quoteRows: newRows, totals: newQuoteTotals } = this.getQuoteRows(selectedQuote);
            rows = newRows;
            quoteTotals = newQuoteTotals;
        }
        return (
            <Quote
                ref={!printMode ? this.quote : undefined}
                innerRef={printMode ? this.quoteToPrint : undefined}
                onMount={onMount ? onMount : undefined}
                workTypes={this.workTypes}
                useLazyLoad={!printMode}
                printMode={printMode}
                quantityTypes={this.quantityTypes}
                printDateFormat={this.convertDateFormat(printDateFormat)}
                onCompanyAddressEdited={this.onCompanyAddressEdited}
                companyAddress={companyAddress}
                companyContacts={companyContacts}
                allUsers={allUsers}
                onAddressEdited={this.onAddressEdited}
                updateHeaders={this.updateHeaders}
                project={project}
                visitingAddresses={visitingAddresses}
                receiverContacts={receiverContacts}
                onQuoteEdited={this.onQuoteEdited}
                onAddressSelected={this.onAddressSelected}
                onContactSelected={this.onContactSelected}
                onSenderContactSelected={this.onSenderContactSelected}
                getVisitingAddresses={this.getVisitingAddresses}
                currencyFormatter={currencyFormatter}
                onRowEdit={this.onRowEdit}
                onAddRow={this.onAddRow}
                quote={selectedQuote}
                quoteRows={rows}
                totals={quoteTotals}
                jobtypes={jobtypes}
                editMode={editMode}
                products={products}
                CPQParents={CPQParents}
                loadingQuotes={loadingQuotes}
                checkPrivilege={checkPrivilege}
                openTaskDialog={this.openTaskDialog}
                getEditableFields={this.getEditableFields}
                renderField={this.renderField}
                printLanguage={printLanguage}
                formatNumberInput={this.formatNumberInput}
                canCreateInvoice={this.props.canCreateInvoice}
                onGrandTotalVisibilityChange={this.onGrandTotalVisibilityChange}
                grandTotalHidden={grandTotalHidden}
                setTargetingSectionY={this.setTargetingSectionY}
            />
        );
    };

    renderPrintSettings = () => {
        return (
            <div className={styles.printSettings}>
                {this.state.printLanguageOptions.length > 1 && (
                    <OutlinedField select label={this.tr('Print Language')} value={this.state.printLanguage}>
                        {this.state.printLanguageOptions.map((opt) => (
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printLanguage: opt.value })}>
                                {this.tr(opt.label)}
                            </MenuItem>
                        ))}
                    </OutlinedField>
                )}
                {this.state.printDateOptions.length > 1 && (
                    <OutlinedField select label={this.tr('Date Format')} value={this.state.printDateFormat}>
                        {this.state.printDateOptions.map((opt) => (
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </OutlinedField>
                )}
            </div>
        );
    };

    setEditModeOn = () => {
        this.setState({ editMode: true });
        this.context.functions.setDirty(true, false, {
            title: this.tr('Sales Quote is not saved!'),
            text: this.tr('Are you sure you want to leave? All unsaved changes will be lost.'),
        });
    };
    setEditModeOff = (additionalStateData = {}) => this.setState({ editMode: false, ...additionalStateData });

    cancelEditMode = () => {
        const isNewQuote = Number(this.state.selectedQuote?.id) < 0;
        this.setState({
            currentDialog: 'cancel',
            dialogData: {
                cancelText: this.tr('No'),
                okText: this.tr('Yes'),
                saveFunc: () => {
                    this.context.functions.setDirty(false);
                    let stateToSet: any = { selectedQuote: this.originalQuote };
                    if (isNewQuote) {
                        const quotes = cloneDeep(this.state.quotes);
                        const index = quotes.findIndex((q) => q.id == this.state.selectedQuote?.id);
                        if (index != -1) {
                            quotes.splice(index, 1);
                        }
                        if (quotes.length == 0) {
                            stateToSet = {
                                ...stateToSet,
                                selectedQuote: undefined,
                            };
                        }
                        stateToSet = { ...stateToSet, quotes };
                    }
                    this.setEditModeOff(stateToSet);
                },
                text: isNewQuote ? this.tr("You haven't saved this quote yet. Canceling now will delete the quote. Are you sure you want to cancel?") : this.tr('Are you sure you want to cancel?'),
            },
        });
    };

    onSave = () => {
        this.setState({ saving: true }, () => {
            const { project } = this.props;
            const { selectedQuote } = this.state;
            if (!selectedQuote) {
                this.setState({ saving: false });
                console.error('Quote not found.');
                return;
            }
            if (!validEmail((selectedQuote.company_email || '').trim(), true) || !validEmail((selectedQuote.editedAddress?.email || '').trim(), true)) {
                this.props.enqueueSnackbar(this.tr('You have invalid email addresses in your quote.'), {
                    variant: 'error',
                });
                this.setState({ saving: false });
                return;
            }

            const snackbarKey: any = this.props.enqueueSnackbar(this.tr('Saving quote'), {
                variant: 'info',
                persist: true,
            });

            const headers = {};

            let roworder = 0;
            let newId = -1;
            const quoteHeaders = cloneDeep(selectedQuote.headers);
            (quoteHeaders || []).forEach((header) => {
                let id = header.id;
                if (header.isNew) {
                    id = newId;
                    newId--;
                }
                header.roworder = roworder++;
                (header.rows || []).forEach((row) => {
                    row.roworder = roworder++;
                    if (row.isNew) {
                        row.id = newId;
                        newId--;
                    }
                });
                headers[id] = { ...header, id };
            });
            const data = {
                quotes: [
                    {
                        ...selectedQuote,
                        projectId: project.id,
                        project_name: selectedQuote.project_name || '',
                        deleted: 0,
                        headers,
                    },
                ],
                newAddressIds: [],
            };

            DataHandler.post({ url: 'projects/quotes' }, data)
                .done((res) => {
                    let selectedQuote = cloneDeep(this.state.selectedQuote);
                    if (Number(selectedQuote.id < 0) && (res.cost_est_ids || []).length > 0) {
                        selectedQuote.id = res.cost_est_ids[0].newId;
                    }
                    this.originalQuote = selectedQuote;
                    this.context.functions.setDirty(false);
                    this.props.closeSnackbar(snackbarKey);
                    this.setState({ saving: false, editMode: false, selectedQuote }, () => {
                        setTimeout(() => {
                            this.getQuotes();
                            this.props.updateProjectData && this.props.updateProjectData(); // updating project data to get updated values in revenue recognition
                        }, 1000);
                    });
                })
                .fail((err) => {
                    this.setState({ saving: false });
                    this.props.closeSnackbar(snackbarKey);
                    this.props.enqueueSnackbar(this.tr('Saving quote failed!'), {
                        variant: 'error',
                    });
                    console.error(err);
                });
        });
    };

    onAddQuote = () => {
        this.addQuote('1');
    };
    addQuote = (type = '1') => {
        const { project } = this.props;
        const { userObject } = this.context;
        const quotes = cloneDeep(this.state.quotes);
        const id = quotes.length * -1 - 1;
        const address = (this.state.visitingAddresses || []).find((a) => a.is_customer_default == 1) || this.state.visitingAddresses[0];
        const defaultUser = this.state.companyContacts.find((c) => c.id == this.context.userObject.usersId);

        /* quote.type: 1 -> normal quote */
        /* quote.type: 2 -> refund quote */
        let typeSpecific;

        if (type === '1')
            typeSpecific = {
                name: this.tr('New quote'),
            };
        else if (type === '2')
            typeSpecific = {
                name: this.tr('New refund quote'),
            };

        const newQuote = {
            ...typeSpecific,
            active: 1,
            created: moment().format('YYYY-MM-DD'),
            deleted: 0,
            id,
            projectId: project.id,
            project_name: project.name,
            userId: userObject.usersId,
            address_id: address?.id,
            receiver_contact_id: this.state.receiverContacts.length > 0 ? this.state.receiverContacts[0].id : undefined,
            sender_contact_id: userObject.usersId,
            editedAddress: {
                address: address?.address,
                name: address?.name,
                postalcode: address?.postalcode,
                city: address?.city,
                state: address?.state,
                country: address?.country,
                vatid: address?.vatid,
                phone: this.state.receiverContacts[0]?.phone,
                email: this.state.receiverContacts[0]?.email,
            },
            company_phone: defaultUser?.phone,
            company_email: defaultUser?.email,
            validEmail: true,
            validCompanyEmail: true,
            print_exceptions: ['cost', 'discountPercentage', 'margin'],
            type,
            headers: this.getInitialRows(type),
        };
        quotes.unshift(newQuote);
        this.setState({ quotes, selectedQuote: newQuote }, () => {
            // Edit mode set afterwards because of how the listColumns are updated inside Quote.tsx,
            // check componentDidUpdate there.
            this.setState({ editMode: true });
        });
    };

    renderInvoicingDisabledMsg = (quote) => {
        if (Number(quote.active) === 0) return this.tr('This quote is not active');
        if (quote.fully_invoiced === '1') return this.tr('This quote is fully invoiced');
        if (this.allInvoiceableRowsAreHidden(quote)) return this.tr('All invoiceable quote rows are hidden');
        return '';
    };

    onInvoiceQuote = (e, selectedQuote) => {
        const {
            project: {
                id: projects_id,
                companies_id,
                customer_reference,
                account: { id: customers_id },
            },
        } = this.props;
        const {
            functions: { updateView },
        } = this.context;
        updateView(
            {
                module: 'invoices',
                action: 'view',
                companies_id,
                projects_id,
                customers_id,
                /* invoiceType: 2 -> material invoice, invoiceType: 3 -> refund invoice */
                invoiceType: selectedQuote.type === '1' ? '2' : '3',
                start: selectedQuote.created,
                end: selectedQuote.created,
                preselect: 'quote',
                reference: customer_reference,
                quote: selectedQuote.id,
            },
            e.ctrlKey || e.metaKey || e.button === 1
        );
    };

    printQuote = () => {
        const quote = this.state.printQuote;
        if (!quote) return;
        const { project } = this.props;
        let styleTags = document.head.getElementsByTagName('style');
        let styleString = '';
        for (let style in styleTags) {
            if (styleTags[style].innerText != '' && styleTags[style].innerText != undefined && styleTags[style].innerText != 'undefined')
                if (!styleTags[style].innerText.startsWith('iframe')) styleString += styleTags[style].innerText;
        }

        let linkTags = document.head.getElementsByTagName('link');
        let links: any = [];
        for (let link in linkTags) {
            if (linkTags[link].rel == 'stylesheet' && (linkTags[link].href.indexOf('taimer.com') > 0 || linkTags[link].href.indexOf('taimer-stack:1025') > 0)) {
                links.push(linkTags[link].href);
            }
        }

        const pageStyleString = `@page {
            size: 8.5in 12in;
            margin: 0 !important;
        }`;

        let html = '<html><head><meta charset="UTF-8"><style>' + pageStyleString + '</style><style>' + styleString + '</style>';
        for (let link in links) {
            html += "<link rel='stylesheet' href='" + links[link] + "'>";
        }
        html += '<link rel="preconnect" href="https://fonts.googleapis.com">';
        html += '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>';
        html += '<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet">';
        html += `</head><body style="margin:0px"><div class="${styles.printContainer}">`;
        let quoteHtml = this.quoteToPrint.current?.outerHTML;
        html += quoteHtml + '</div></body></html>';

        // For dev - open the page in new window to inspect styles
        if (process.env.NODE_ENV !== 'production' && process.env?.REACT_APP_QUOTE_PRINTING !== '1') {
            this.quotePrintSnackbar && this.props.closeSnackbar(this.quotePrintSnackbar);
            const printWindow = window.open('', 'PRINT');
            if (printWindow && quoteHtml) printWindow.document.body.innerHTML = html;
            printWindow?.focus();
        } else {
            const params = {
                quoteId: quote.id,
                projectId: project.id,
                html: html,
                styles: styleString,
                links: links,
            };

            DataHandler.post({ url: 'print/quote' }, params).done((response) => {
                this.quotePrintSnackbar && this.props.closeSnackbar(this.quotePrintSnackbar);
                if (!response.error) {
                    DataHandler.getArrayBuffer({ url: 'file', filename: response.filename }).done((response) => {
                        if (response != false) {
                            const blob = new Blob([response], {
                                type: 'application/pdf',
                            });
                            FileSaver.saveAs(blob, moment().format('YYYY-MM-DD') + '_' + quote.name + '_' + project.name + '.pdf');
                        } else {
                            this.props.enqueueSnackbar(this.tr('Failed to generate PDF-file.'), {
                                variant: 'error',
                            });
                        }
                    });
                } else {
                    this.props.enqueueSnackbar(this.tr('Failed to generate PDF-file.'), {
                        variant: 'error',
                    });
                }
            });
        }
        this.setState({ printQuote: undefined });
    };

    onPrintQuote = (quote) => {
        this.quotePrintSnackbar = this.props.enqueueSnackbar(this.tr('Generating quote PDF-file...'), {
            variant: 'info',
            persist: true,
        });
        this.setState({ printQuote: cloneDeep(quote) });
    };

    onShowProposal = (quote) => {
        this.setState({ proposalQuote: cloneDeep(quote) });
    };

    copy = (id, active, targeting, name) => {
        DataHandler.post({ url: `projects/quotes/${id}/copy`, project: this.props.project.id, active, targeting, name, tr: this.tr('copystring') }).done((response) => {
            const quoteId = response;
            setTimeout(() => {
                DataHandler.get({ url: `projects/${this.props.project.id}/quotes` }).done((quotes) => {
                    const selectedQuote = quotes.find((q) => q.id == quoteId) || this.state.selectedQuote;
                    this.setState({ quotes, selectedQuote, editMode: true });
                });
            }, 1000);
        });
    };

    openCopyDialog = (currentDialog, id, name) => {
        const dialogData = {
            quote: id,
            quoteName: name,
            company: this.props.project.companies_id,
            project: this.props.project.id,
            handleCopyResult: this.copy,
        };

        this.setState({
            dialogData,
            currentDialog,
        });
    };

    allInvoiceableRowsAreHidden = (quote) => {
        let allInvoiceableRowsAreHidden = true;
        (quote.headers || []).forEach((header) => {
            const notHiddenInvoiceableRows = (header.rows || []).filter(
                (r) => (r.type == quoteRowTypes.quoteRow || r.type == quoteRowTypes.productRow || r.type == quoteRowTypes.cpqGroupedRow) && (Number(r.bills_id || 0) == 0 || (this.context.addons.quoterow_partial_invoicing_for_products && r.type == quoteRowTypes.productRow && Number(r.quantity) > Number(r.quantity_invoiced))) && r.hidden_for_print != 1
            );
            if (notHiddenInvoiceableRows.length > 0) allInvoiceableRowsAreHidden = false;
        });
        return allInvoiceableRowsAreHidden;
    };

    renderOptions = () => {
        const { editMode, saving, selectedQuote } = this.state;
        const {
            checkPrivilege,
            project: { charge_costest, type },
            canCreateInvoice,
        } = this.props;
        const editable = checkPrivilege('projects', 'project_cost_estimate_write');
        if (!selectedQuote) return null;
        const showProposal = (!(!selectedQuote.proposals_id || selectedQuote.proposals_id == '-1') && checkPrivilege('projects', 'proposal_read')) || checkPrivilege('projects', 'proposal_write');
        return (
            <div className={styles.options}>
                <div className={styles.optionsContainer}>
                    <div>
                        {!editMode && (
                            <ContextMenu
                                //@ts-ignore
                                className={styles.optionsMenu}
                                buttonProps={{
                                    stickyIcon: true,
                                    size: 'large',
                                }}
                                variant="outlined"
                                label={this.tr('Options')}
                                size="medium"
                                placement={'bottom-start'}
                                data-testid="quote-button-options"
                            >
                                <MenuItem onClick={() => this.onPrintQuote(selectedQuote)}>
                                    <Print />
                                    {this.tr('Print')}
                                </MenuItem>
                                {editable && showProposal && (
                                    <MenuItem onClick={() => this.onShowProposal(selectedQuote)}>
                                        <Add />
                                        {!selectedQuote?.proposals_id || selectedQuote?.proposals_id == '-1' ? this.tr('Create Proposal') : this.tr('View Proposal')}
                                    </MenuItem>
                                )}
                                {editable && (
                                    <MenuItem onClick={this.onAddQuote} data-testid="quote-button-options-add-new-quote">
                                        <AddCircleOutlined />
                                        {this.tr('New quote')}
                                    </MenuItem>
                                )}
                                {editable && this.context.addons.refund_material && (
                                    <MenuItem onClick={() => this.addQuote('2')}>
                                        <AddCircleOutlined />
                                        {this.tr('Add new refund quote')}
                                    </MenuItem>
                                )}
                                {editable && (
                                    <MenuItem onClick={() => this.openCopyDialog('copySimple', selectedQuote.id, selectedQuote.name)}>
                                        <FileCopy />
                                        {this.tr('Copy')}
                                    </MenuItem>
                                )}
                                {editable && (
                                    <MenuItem onClick={() => this.openCopyDialog('copyQuote', false, selectedQuote.name)}>
                                        <Search />
                                        {this.tr('Copy from another project')}
                                    </MenuItem>
                                )}
                                {selectedQuote && type == '1' && charge_costest == '1' && canCreateInvoice && (
                                    <Tooltip title={this.renderInvoicingDisabledMsg(selectedQuote)}>
                                        <div>
                                            <MenuItem
                                                disabled={this.allInvoiceableRowsAreHidden(selectedQuote) || selectedQuote.fully_invoiced === '1' || Number(selectedQuote.active) === 0}
                                                onClick={(e) => e.button !== 1 && this.onInvoiceQuote(e, selectedQuote)}
                                                onMouseUp={(e) => e.button === 1 && this.onInvoiceQuote(e, selectedQuote)}
                                            >
                                                <AddBox />
                                                {this.tr('Add Invoice')}
                                            </MenuItem>
                                        </div>
                                    </Tooltip>
                                )}
                            </ContextMenu>
                        )}
                        {editMode && (
                            <Button color="info" className={styles.cancel} onClick={this.cancelEditMode} size="large">
                                {this.tr('Cancel')}
                            </Button>
                        )}
                        {editable &&
                            (editMode == true ? (
                                <LoaderButton color="primary" loading={saving} onClick={this.onSave} size="large" text={this.tr('Save')} data-testid={saving ? "quote-button-save-saving" : "quote-button-save"} />
                            ) : (
                                <Button color="primary" onClick={this.setEditModeOn} size="large" data-testid="quote-button-edit">
                                    {this.tr('Edit')}
                                </Button>
                            ))}
                    </div>
                </div>
            </div>
        );
    };

    openTaskDialog = async (quoteRowId, quantity, name) => {
        const project = cloneDeep(this.props.project);
        project.label = project.name;
        const enddate = new Date();
        enddate.setDate(enddate.getDate() + 7);

        const data = {
            quote_rows_id: quoteRowId,
            start_date: new Date(),
            end_date: enddate,
            projects_id: project.id,
            type: 'task',
            hours: quantity,
            project_disabled: 1,
            description: name,
        };
        this.context.functions.addResource(data);
    };

    closeDialog = () => this.setState({ currentDialog: undefined, dialogData: undefined });
    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    // for getting the fields for the edit popup
    getEditableFields = (item) => {
        let firstItem;
        switch (item?.type) {
            case 3:
                firstItem = {
                    key: 'product_id',
                    autoFocus: true,
                    title: this.tr('Product'),
                    type: 'data_select',
                    options: this.state.products,
                    setOtherValuesWithSelection: (_, value) => {
                        const product = this.state.products.find((p) => p.id == value);
                        return {
                            product_name: product.name,
                            cost: product.cost_price,
                            value: product.income_price,
                            discountPercentage: product.discount_percent,
                        };
                    },
                };
                break;
            case 4:
                firstItem = {
                    key: 'product_id',
                    autoFocus: true,
                    title: this.tr('CPQ'),
                    type: 'data_select',
                    options: this.state.CPQParents,
                    setOtherValuesWithSelectionPromise: async (_, value) => {
                        const cpq = this.state.CPQParents.find((cpq) => cpq.id == value);
                        const sums = { cost: 0, value: 0 };
                        let vat = 0;
                        try {
                            const { cpqs } = await DataHandler.get({ url: `cpq/childrens`, parentId: cpq.id });
                            cpqs.forEach((row) => {
                                sums.cost += Number(row.quantity) * Number(row.unit_cost);
                                sums.value += Number(row.quantity) * Number(row.selling_price);
                                vat = Number(row.vat);
                            });
                        } catch (e) {
                            console.error(e);
                        }
                        return {
                            product_name: cpq.name,
                            vat,
                            ...sums,
                        };
                    },
                };
                break;
            default:
                firstItem = {
                    key: 'name',
                    autoFocus: true,
                    title: this.tr('Description'),
                    type: 'text',
                };
        }
        // has all of the fields, just for the future if we want to do edits in a slider etc.
        // for now only the fields with showInEditPopup are used
        const fields = [
            firstItem,
            {
                key: 'quantity',
                title: this.tr('Quantity'),
                type: 'text',
                showInEditPopup: ['quantity'],
                validation: ['numeric'],
                autoFocus: true,
            },
            {
                key: 'quantityType',
                title: this.tr('Quantity type'),
                type: 'data_select',
                options: this.quantityTypes,
                defaultValue: this.quantityTypes[0].id,
                addNoneOption: false,
                useStringValueIfNan: true,
                showInEditPopup: ['quantity'],
            },
            {
                key: 'cost',
                title: this.tr('Unit cost'),
                type: 'text',
                validation: ['numeric'],
            },
            {
                key: 'targeted',
                title: this.tr('Targeted cost'),
                type: 'text',
                validation: ['numeric'],
            },
            {
                key: 'value',
                title: this.tr('Selling price'),
                type: 'text',
                showInEditPopup: ['value'],
                autoFocus: true,
                validation: ['numeric'],
            },
            {
                key: 'discountPercentage',
                title: this.tr('Discount %'),
                type: 'text',
                showInEditPopup: ['value'],
                validation: ['numeric'],
            },
            {
                key: 'vat',
                title: this.tr('VAT %'),
                type: 'text',
                showInEditPopup: ['vat'],
                autoFocus: true,
                validation: ['numeric'],
            },
            {
                key: 'margin',
                title: this.tr('Margin'),
                type: 'text',
                showInEditPopup: ['margin'],
                autoFocus: true,
                alwaysUseDefaultValue: true,
                validation: ['numeric'],
                getDefaultValue: (data) => {
                    const calcValue = parseInt(data.discountPercentage) > 0 ? data.value - (parseInt(data.discountPercentage) / 100) * data.value : data.value;
                    const margin = (Number(calcValue) - Number(data.cost)) * Number(data.quantity);
                    return margin.toFixed(2);
                },
                setOtherValuesWithSelection: (data, value) => {
                    const price = Number(data.value || 0);
                    const cost = Number(data.cost || 0);
                    if (isNaN(value) || (!price && !cost)) return {};
                    const margin = Number(value || 0);
                    if (price && !cost) {
                        if (margin > price) {
                            return {
                                value: margin.toFixed(2),
                            };
                        }
                        const calcValue = parseInt(data.discountPercentage) > 0 ? data.value - (parseInt(data.discountPercentage) / 100) * data.value : data.value;
                        const cost = calcValue - Number(value) / Number(data.quantity);
                        return {
                            cost,
                            margin: undefined,
                        };
                    } else {
                        let price = Number(data.cost) + Number(value) / Number(data.quantity);
                        if (parseInt(data.discountPercentage) > 0) {
                            price = price / (1 - parseInt(data.discountPercentage) / 100);
                        }
                        return {
                            value: price,
                            margin: undefined,
                        };
                    }
                },
            },
            {
                key: 'marginPercentage',
                title: this.tr('Margin %'),
                type: 'text',
                showInEditPopup: ['margin'],
                validation: ['numeric'],
                alwaysUseDefaultValue: true,
                getDefaultValue: (data) => {
                    const calcValue = parseInt(data.discountPercentage) > 0 ? data.value - (parseInt(data.discountPercentage) / 100) * data.value : data.value;
                    const margin = Number(calcValue) - Number(data.cost);
                    const marginPercentage = Math.round(100 * (!calcValue ? 0 : margin / calcValue)).toFixed(2);
                    return marginPercentage;
                },
                setOtherValuesWithSelection: (data, value) => {
                    if (isNaN(value)) return {};
                    if (Number(data.value || 0) && !Number(data.cost || 0)) {
                        const calcValue = parseInt(data.discountPercentage) > 0 ? data.value - (parseInt(data.discountPercentage) / 100) * data.value : data.value;
                        const percentage = Number(value) / 100;
                        const cost = (calcValue - percentage * calcValue).toFixed(2);
                        return {
                            cost,
                            marginPercentage: undefined,
                        };
                    } else {
                        if (value == 100) {
                            return {
                                cost: (0).toFixed(2),
                                marginPercentage: undefined,
                            };
                        }
                        let price = Number(data.cost) / (1 - Number(value) / 100);
                        if (parseInt(data.discountPercentage) > 0) {
                            price = price / (1 - parseInt(data.discountPercentage) / 100);
                        }
                        return {
                            value: price.toFixed(2),
                            marginPercentage: undefined,
                        };
                    }
                },
            },
            {
                key: 'workType',
                title: this.tr('Type of work'),
                type: 'data_select',
                options: this.workTypes,
                addNoneOption: false,
            },
            {
                key: 'jobtypes_id',
                title: this.tr('Jobtype'),
                type: 'data_select',
                options: this.state.jobtypes,
                addNoneOption: false,
            },
        ];
        return fields;
    };

    onEditFieldKeyDown = (e) => {
        if (e.key == 'Enter' || e.key == 'Escape') {
            this.quote.current && this.quote.current.setActiveCell(undefined);
        }
    };

    formatNumberInput = (val) => {
        let value = val.replace(',', '.');
        const endsWithDot = value.endsWith('.');
        if (!endsWithDot && !Number.isFinite(Number(value || 0))) {
            return undefined;
        }
        const formattedValue = endsWithDot ? value : Number(value || 0);
        return formattedValue;
    };

    // for rendering fields inside the edit popup
    renderField = (item, field) => {
        switch (field.type) {
            case 'select':
                return (
                    <DataList
                        key={field.key}
                        //@ts-ignore
                        addNoneOption={field.addNoneOption}
                        autoFocus={field.autoFocus}
                        openMenuOnFocus
                        tabSelectsValue={false}
                        label={field.title}
                        options={field.options}
                        value={item && item[field.key]}
                        shownCount={20}
                        onChange={(value) => this.onRowEdit({ ...item, ...(field.setOtherValuesWithSelection ? field.setOtherValuesWithSelection(item, value) : {}), [field.key]: value })}
                    />
                );
            case 'data_select':
                const itemValue = item?.[field.key];
                let val = field?.options?.find((o) => o.id == itemValue);
                if (field.useStringValueIfNan && isNaN(itemValue)) {
                    val = !itemValue ? undefined : { id: 0, label: itemValue };
                }
                if (val == undefined && field.defaultValue) val = field?.options?.find((o) => o.id == field.defaultValue);
                return (
                    <DataList
                        key={field.key}
                        //@ts-ignore
                        addNoneOption={field.addNoneOption}
                        autoFocus={field.autoFocus}
                        openMenuOnFocus
                        tabSelectsValue={false}
                        label={field.title}
                        options={field.options}
                        value={item && val}
                        shownCount={20}
                        onChange={(value) => this.onRowEdit({ ...item, ...(field.setOtherValuesWithSelection ? field.setOtherValuesWithSelection(item, value) : {}), [field.key]: value.id })}
                    />
                );
            default: {
                let value = item && (item.alwaysUseDefaultValue ? field.getDefaultValue && field.getDefaultValue(item) : item[field.key] || (field.getDefaultValue && field.getDefaultValue(item)));
                if (typeof value == 'number') {
                    value = value.toFixed(2);
                }
                return (
                    <OutlinedField
                        key={field.key}
                        adornmentPos={field.adornmentPos}
                        adornment={field.adornment}
                        autoFocus={field.autoFocus}
                        selectOnFocus={field.autoFocus}
                        disabled={field.disabled}
                        validation={field.validation}
                        onKeyDown={this.onEditFieldKeyDown}
                        onChangeOnEnter
                        usePropValue
                        onChange={(e) => {
                            let value = this.formatNumberInput(e.target.value);
                            if (value == undefined) return;
                            if (field.key == 'marginPercentage') {
                                if (Number(value) >= 100) {
                                    value = 100;
                                } else if (Number(value) < 0) {
                                    value = 0;
                                }
                            }
                            this.onRowEdit({
                                ...item,
                                [field.key]: value,
                                ...(field.setOtherValuesWithSelection ? field.setOtherValuesWithSelection(item, value) : {}),
                            });
                        }}
                        label={field.title}
                        name={field.key}
                        value={value}
                    />
                );
            }
        }
    };

    closeDeleteConfirmationDialog = () => this.setState({ deleteConfirmationQuote: undefined });
    setPrintQuoteReady = () => this.setState({ printQuoteReady: true });

    render() {
        const { selectedQuote, printQuote, printQuoteReady, proposalQuote, deleteConfirmationQuote, loadingQuotes } = this.state;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        // this is just for the old print method that's still used in mobile
        if (this.props.printMode) {
            if (loadingQuotes || !selectedQuote) return null;
            return (
                <div id={printQuoteReady ? 'quote-list-wrapper' : ''} className={styles.printContainer}>
                    {this.renderQuote(this.state.selectedQuote, { printMode: true, onMount: this.setPrintQuoteReady })}
                </div>
            );
        }

        return (
            <div id={styles.tabQuotes}>
                <div className={styles.mainContent}>
                    <div className={`${styles.content} ${styles.sticky}`}>
                        <div className={styles.left}>{this.renderOptions()}</div>
                        <div className={styles.right}></div>
                    </div>
                    {this.renderContent()}
                </div>
                {(printQuote || proposalQuote) && (
                    <div className={styles.noDisplay}>{this.renderQuote(printQuote || proposalQuote, { printMode: true, onMount: !!proposalQuote ? this.showProposal : this.printQuote })}</div>
                )}
                {Dialog && (
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onClose={this.closeDialog}
                        checkPrivilege={this.props.checkPrivilege}
                        onDialogSave={Dialog == ResourceDialog ? this.closeDialog : this.confirmDialog}
                        data={this.state.dialogData}
                        autoCompleteData={Dialog == ResourceDialog ? this.state.resourcingAutoCompleteData : {}}
                    />
                )}
                {deleteConfirmationQuote && (
                    <CoreDialog
                        dialogType="delete"
                        dialogProps={{
                            onCloseClick: this.closeDeleteConfirmationDialog,
                            close: this.closeDeleteConfirmationDialog,
                            onConfirm: () => {
                                this.closeDeleteConfirmationDialog();
                                this.deleteQuote(deleteConfirmationQuote.id);
                            },
                            onCancel: this.closeDeleteConfirmationDialog,
                            header: this.tr('Delete quote'),
                            translatedConfirmButtonText: this.tr('Delete'),
                            cancelButtonText: this.tr('Cancel'),
                            warning: () => this.tr('Are you sure you want to delete quote ${quote}?', { quote: deleteConfirmationQuote.name }),
                        }}
                    />
                )}
            </div>
        );
    }
}

export default withSnackbar(TabQuotes);
