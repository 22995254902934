import React from 'react';
import './TeamChat.css';
import TaimerComponent from '../TaimerComponent';
import DataHandler from './../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import cn from 'classnames';
import { Tabs, Tab, Badge } from '@mui/material';
import { Portal } from '@mui/base';
import TabGroups from './TabGroups';
import TabProjects from './TabProjects';
import TabNewsFeed from './TabNewsFeed';
import { withStyles } from '@mui/styles';
import $ from 'jquery';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    padding: {
        padding: `0 ${theme.spacing(2)}`,
    },
    primary: {
        color: '#6b7897',
    },
    icon: {
        fill: '#B2B9C9',
        margin: 0,
    },
    button: {
        '&:hover': {
            background: 'rgba(44, 159, 247, 0.08)',
        }
    },
});

// Team Chat
class TeamChat extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        updateFrequency: 6000,
    }

    constructor(props, context) {
        super(props, context, "team-chat/TeamChat");

        window.TeamChat = this;

        this.state = {
            open: this.props.full,
            totalUnread: -1,
            selectedTab: 'projects',
            selectedId: 0,
            selectedExtra: {},
            online: false,

            start: 0,
            unreadData: {
                news: {
                    count: 0,
                },
                projects: {
                    count: 0,
                },
                groups: {
                    count: 0,
                },
                ids: {},
            },
            viewData: {
                messages: [],
                hasOlder: 0,
                hasNewer: 0,
            },
            viewParams: {},
            groupsMembers: [],
            addableMembers: [],
            editable: false,
            canAdd: false,
            owned: false,
            currentTabLoaded: false,
            currrentTabError: false,
        };

        this.refMenuBtn = React.createRef();
        this.refUserList = React.createRef();
        this.refCurrentTab = React.createRef();
    }

    interval = false;
    isRefreshing = false;
    isPaused = false;
    currentPopup = false;
    unreadNotificationShown = 0;

    inPopup = () => this.props.full;

    inPopupMode = () => !!this.currentPopup;

    componentDidMount = () => {
        super.componentDidMount();
        if (this.inPopup()) {
            $('body').addClass('teamchat-popup');
            this.refresh();
        }

        this.interval = setInterval(this.refresh, this.props.updateFrequency);

        window.addEventListener("beforeunload", this.handleClose);
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
        clearInterval(this.interval);
        window.removeEventListener("beforeunload", this.handleClose);
    }

    handleClose = () => {
        if (this.inPopup) {
            sessionStorage.setItem("TeamChatPopup", false);
            if (window.opener) {
                window.opener.TeamChat.onPopupCloses();
            }
        }
    }

    setUnreadCount = (unreadData, totalUnread, popup = false) => {
        const { totalUnread: lastUnread, open } = this.state;
        const { functions: { showNotification } } = this.context;
        const { tr } = this;

        this.setState({ unreadData, totalUnread });

        this.props.onUnreadCount && this.props.onUnreadCount(totalUnread);

        if (popup)
            this.currentPopup = popup;

        if (totalUnread > this.unreadNotificationShown && (!open || document.hidden)) {
            this.unreadNotificationShown = totalUnread;

            showNotification(
                tr("Taimer Team Chat"),
                tr("You have received new message in Taimer!"),
                () => this.setState({ open: true }),
                { tag: 'teamchat-notification' }
            );
        }

        // Relay to page if in popup
        if (window.opener !== window)
            window.opener && window.opener.TeamChat && window.opener.TeamChat.setUnreadCount(unreadData, totalUnread, true);
    }

    refresh = async (refreshMembers = false, forceRefresh = false) => {
        const { full } = this.props;
        const { open, selectedTab, currrentTabError, online: lastOnline } = this.state;

        let needsUpdate = false;
        let isOnline = true;

        try {
            if (!forceRefresh) {
                let totalUnread = 0;

                const unreadData = await DataHandler.get({ url: 'teamchat/unread' });
                if (unreadData && unreadData.news && unreadData.projects && unreadData.groups) {
                    totalUnread = unreadData.news.count + unreadData.projects.count + unreadData.groups.count;

                    needsUpdate = totalUnread > 0;

                    if (!needsUpdate && unreadData.ids && !_.isEqual(unreadData.ids, this.state.unreadData.ids)) {
                        needsUpdate = true;
                    }

                    this.setUnreadCount(unreadData, totalUnread);
                }

                if (totalUnread === 0)
                    this.unreadNotificationShown = 0;
            }
        } catch (error) {
            isOnline = false;
            console.error("teamchat error: ", error);
        }

        if (lastOnline !== isOnline) {
            needsUpdate = true;
            this.setState({online: isOnline});
        }

        if (!isOnline)
            return;

        try {
            if (this.refCurrentTab.current && this.refCurrentTab.current.refresh && (currrentTabError || forceRefresh || (needsUpdate && (full || open)))) {
                const data = await this.refCurrentTab.current.refresh();

                if (data === false) {
                    this.setState({
                        currrentTabError: true,
                    });
                } else {
                    this.setState({
                        currrentTabError: false,
                    });
                }
            }
        } catch (error) {
            console.log("teamchat error: ", error);
            this.setState({ currrentTabError: true });
        }
    }

    createPopup = (focus = true) => {
        if (this.currentPopup) {
            if (focus && this.currentPopup.focus)
                this.currentPopup.focus();

            return true;
        }

        this.currentPopup = window.open('', "Taimer_TeamChat", "width=1280, height=800, titlebar=0, status=0, menubar=0, toolbar=0, location=0, scrollbars=0", false)

        if (!this.currentPopup) {
            sessionStorage.setItem("TeamChatPopup", false);
            return false;
        }

        if (this.currentPopup.location.href === "about:blank") {
            const url = "index.php?module=teamchat&action=popup"

            this.currentPopup.location = url;
        }

        sessionStorage.setItem("TeamChatPopup", true);

        this.close();

        return true;
    }

    onPopupCloses = () => {
        this.currentPopup = false;
    }

    onClickPopUp = () => {
        const { full = false } = this.props;

        if (full) {

        }
        // If popup is not open
        else if (!this.currentPopup) {
            this.createPopup();
            this.close();
        }
    }

    open = (currentState) => {
        this.unreadNotificationShown = 0;

        if (!this.state.open) {
            $('body').addClass('teamchat-open');
            this.setState({ open: true });
        }

        if (currentState) {
            // If in Project, jump to it's chat
            if (currentState.module === "projects" && currentState.action === "view" && currentState.id > 0) {
                this.setPage("projects", currentState.id);
            } else if (currentState.module === "notifications" && currentState.action === "open-chat" && currentState.id > 0) {
                this.setPage("groups", -1, { user: currentState.id, chatName: currentState.target_name });
            }
        }
    }

    setPage = (tab, id = 0, extra = undefined, callback = undefined) => {
        this.setState({
            selectedTab: tab,
            selectedId: id,
            selectedExtra: extra,
        });
    }

    close = () => {
        const { full = false } = this.props;

        // This is popup
        if (full) {
            window.close();
        }
        else {
            $('body').removeClass('teamchat-open');
            this.setState({ open: false });
        }
    }

    switchTab = (e, selectedTab) => {
        if (this.state.selectedTab === selectedTab)
            return;

        this.setPage(selectedTab);
    }

    onSelect = (item, newTab) => {
        const { selectedTab, selectedId } = this.state;

        if (!newTab)
            newTab = selectedTab;

        if (newTab === selectedTab && item && selectedId === item.id)
            return;

        this.setState({
            selectedTab: newTab,
            selectedName: item ? (item.name || item.project) : '',
            selectedSubtitle: item && item.customer ? item.customer : '',
            selectedId: item ? item.id : 0,
            currentTabLoaded: false,
            groupsMembers: [],
            addableMembers: [],
            viewData: {
                messages: [],
                hasOlder: 0,
                hasNewer: 0,
            },
            viewParams: {},
            editable: false,
            canAdd: false,
            owned: false,
        }, () => {
            this.refresh(true, true);

            if (item && item.new) {
                this.refUserList.current.showPopup();
            }
        })
    }

    onNavigatePage = async (viewParams) => {
        this.setState({ viewParams }, () => this.refresh(false, true));
    }

    render() {
        const { classes, full = false, updateFrequency, enqueueSnackbar } = this.props;
        const {
            selectedTab, selectedId, selectedExtra, open = false,
            unreadData: { projects: unreadProjects, groups: unreadGroup, news: unreadNews },
            online, currrentTabError,
        } = this.state;

        if (!full && !open)
            return null;

        const tabProps = {
            classes,
            selectedId,
            selectedExtra,
            setPage: this.setPage,
            updateFrequency,
            inPopup: this.inPopup(),
            openPopup: this.onClickPopUp,
            onClose: this.close,
            tr: this.tr,
            enqueueSnackbar,
        }

        let Root = Portal;

        if (this.props.full)
            Root = React.Fragment;

        let currentTab = undefined;

        if (open) {
            switch (selectedTab) {
                case 'news':
                    currentTab = <TabNewsFeed ref={this.refCurrentTab} {...tabProps} />;
                    break;
                case 'projects':
                    currentTab = <TabProjects ref={this.refCurrentTab} {...tabProps} />;
                    break;
                case 'groups':
                    currentTab = <TabGroups ref={this.refCurrentTab} {...tabProps} />;
                    break;
            }
        }

        return (<Root>
            {!full && open && <div className="TeamChatBackground" onClick={this.close} />}
            <div className={cn("TeamChat", open && "open", full ? "TeamChatFull" : "TeamChatSidebar")}>
                <div className="TeamChatGrid">
                    <div className="TeamChatGridTop TeamChatGridTabs">
                        <div className={`TeamChatToolbarTabs ${this.context.functions.checkPrivilege("newsfeed", "newsfeed") ? '' : 'news-not-visible'}`}>
                            <Tabs centered color="primary" value={selectedTab} onChange={this.switchTab} indicatorColor="primary" >
                                { this.context.functions.checkPrivilege("newsfeed", "newsfeed") && <Tab value="news" label={<Badge className={classes.padding} color="secondary" invisible={unreadNews.count === 0} badgeContent={unreadNews.count}>{this.tr("News")}</Badge>} /> }
                                <Tab value="projects" label={<Badge className={classes.padding} color="secondary" invisible={unreadProjects.count === 0} badgeContent={unreadProjects.count}>{this.tr("Projects")}</Badge>} />
                                <Tab value="groups" label={<Badge className={classes.padding} color="secondary" invisible={unreadGroup.count === 0} badgeContent={unreadGroup.count}>{this.tr("Chat")}</Badge>} />
                            </Tabs>
                        </div>
                    </div>

                    {currentTab && currentTab}

                    {(!online || currrentTabError) && <div className="TeamChatOffline">{this.tr("Team Chat is offline. New mesasges won't appear.")}</div>}
                </div>
            </div>
        </Root>);
    }
}

export default withSnackbar(withStyles(styles)(TeamChat));
