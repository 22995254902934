import React, { Component } from 'react'
import TaimerComponent from '../TaimerComponent';
import NotificationsDefinitions from './NotificationsDefinitions';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment'
import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import { formatInputNumber } from '../helpers';
import { format } from 'date-fns';

import './Notification.css';


export class Notification extends TaimerComponent {
    static contextType = SettingsContext;
    
    static defaultProps = {
        data: {},
        companies: undefined
    }
    
    constructor(props, context) {
        super(props, context, "notifications/Notification");
    }

    removeNotification = () => {
        const { data, refresh } = this.props;
        DataHandler.put({url: `notifications/remove/${data.id}`})
        .done(response => {
            refresh(true, true);
        })
        .fail(err => {
            refresh(true, true);
        })  
    }

    markAsSeen = () => {
        const { data, refresh } = this.props;
        DataHandler.put({url: `notifications/seen/${data.id}`});
        refresh(true, true);
    }

    openChat = () => {
        const { data, openTeamChat } = this.props;
        openTeamChat({module: 'notifications', action: 'open-chat', id: data.target_id, target_name: data.user});
        this.props.close();
    }

    viewInvoice = () => {
        const { data } = this.props;
        const { functions: { updateView }} = this.context;
        const values = JSON.parse(data.value);
        updateView({ module: "invoices", action: "view", id: data.target_id, billid: values.invoice_nr }, false);
        this.props.close();
    }

    listInvoices = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: {companies_id}} = this.context;
        const values = JSON.parse(data.value);
        updateView({ module: "invoices", action: "main", selectedTab: 'invoices', company: data.companies_id-0 || companies_id, bill_ids: values.invoice_ids} , false);
        this.props.close();
    }

    viewProject = () => {
        const { data } = this.props;
        const { functions: { updateView }} = this.context;
        const values = JSON.parse(data.value);
        updateView({ module: "projects", action: "view", id: data.target_id }, false);
        this.props.close();
    }

    viewApprovableOvertimes = () => {
        const { functions: { updateView }, userObject: {companies_id}} = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "approvals", type_filter_value: 2, status_filter_value: 2}, false);
        this.props.close();
    }

    viewWorkhours = () => {
        const { data } = this.props;
        const values = JSON.parse(data.value);
        const ids = values.ids?.join();
        // For some reason values for workhour statuses in workhour list are 1 for declined and 3 for approved although in database they are -1 and 1.
        const status_filter_value = values.approval_type == 'declined' ? 1 : 3

        const { functions: { updateView }, userObject: {companies_id}} = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "time-tracker", selectedSubTab: "myHours", nonUrlParams: {ids, status_filter_value}}, false);
        this.props.close();
    }

    viewApprovalHours = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: {companies_id}} = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "approvals", status_filter_value: 4, user: data.target_id}, false);
        this.props.close();
    }

    viewPurchaseOrder = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: {companies_id}, privileges} = this.context;
        const values = JSON.parse(data.value);
        if (values.po_ids && privileges.purchaseorders.read) {
            updateView({ module: "costs", action: "main", selectedTab: 'purchase-orders'}, false);
        }
        else if (values.po_id) {
            updateView({ module: "purchaseorder", action: "view", id: values.po_id}, false);
        }
        this.props.close();
    }

    render() {
        const definedNotifications = NotificationsDefinitions.getDefinitions();
        
        const { data, type, companies } = this.props;
        const { userObject: { dateFormat }, taimerAccount } = this.context;
        let values = null;
        const approval_done_subtypes = ['22', '23', '30'];
        const overtime_approval_subtypes = ['6', '7'];
        const message_sub_types = ['7', '23', '30'];
        const po_synced_subtypes = ['27', '28'];

        if (type == 2 || type == 3 || type == 4 || (data.type == 1 && (overtime_approval_subtypes.includes(data.sub_type) || approval_done_subtypes.includes(data.sub_type)))) {
            try {
                values = JSON.parse(data.value);
            } catch (e) {}
        }

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency,
        }).format;

        const functions = {
            markasseen: this.markAsSeen,
            openchat: this.context.functions.checkPrivilege("newsfeed", "newsfeed") && this.openChat,
            view_invoice: this.viewInvoice,
            list_invoices: this.listInvoices,
            view_project: this.viewProject,
            view_overtime: this.viewApprovableOvertimes,
            view_workhours: this.viewWorkhours,
            view_approval_hours: this.viewApprovalHours,
            view_approval_hours_project_manager: this.viewApprovalHoursProjectManager, 
            view_po: this.viewPurchaseOrder,
        }

        let date = false;
        if (overtime_approval_subtypes.includes(data.sub_type)) {
            date = values && values.date ? values.date : data.value;
            date = format(date, this.context.userObject.dateFormat.toUpperCase());
        }

        const replaces = {
            '${user}': type == 3 ? values.user : data.user,
            '${hours}': formatInputNumber(data.value, "hours"),
            '${hour_entries}': (data.type == 1 && approval_done_subtypes.includes(data.sub_type)) ? values.entries : undefined,
            '${date}': date ? date : data.value,
            '${roles}': type == 2 ? values.roles : undefined,
            '${invoice_nr}': type == 2 ? values.invoice_nr : undefined,
            '${invoice_total}': type == 2 ? currencyFormatter(String(values.invoice_total).replace(",",".")) : undefined,
            '${account}': type == 2 || type == 3 ? values.account : undefined,
            '${projects}': type == 2 ? values.projects : undefined,
            '${invoice_count}': type == 2 ? values.invoice_count : undefined,
            '${account_count}': type == 2 ? values.account_count : undefined,
            '${project_count}': type == 2 ? values.project_count : undefined,
            '${project}': type == 3 || type == 4 ? values.project : undefined,
            '${message}': (data.type == 1 && message_sub_types.includes(data.sub_type) && values && values.message) ? this.tr("Message") + ": " + values.message : "",
            '${po_id}': type == 4 && po_synced_subtypes.includes(data.sub_type) ? values.po_id : undefined,
            '${bill_nr}': type == 4 && po_synced_subtypes.includes(data.sub_type) ? values.bill_nr : undefined,
            '${po_ids}': type == 4 && data.sub_type == 29 ? values.po_ids : undefined,
        }       
        
        return (
            _.map(definedNotifications[type].notifications, notification => {
                if(notification.sub_type == data.sub_type) {
                    let msg = notification.msg;
                    _.forEach(replaces, (a, b) => msg = msg.replace(b,a) );                    

                    let mainHeader = null;
                    mainHeader = notification.header;
                    _.forEach(replaces, (a, b) => mainHeader = mainHeader.replace(b,a) );

                    let subHeader = null;
                    if (notification.sub_header) {
                        subHeader = notification.sub_header;
                        _.forEach(replaces, (a, b) => subHeader = subHeader.replace(b,a) );
                    }

                    const time = moment.utc(data.creation_date).local();
                    const today = moment();
                    const yesterday = moment().subtract(1, 'days');
                    let date = time.format(dateFormat);
                    if (today.isSame(time, "day"))
                        date = time.format('LT');
                    else if (yesterday.isSame(time, "day"))
                        date = this.tr("Yesterday");
                    

                    return (
                        <div className={`notification ${data.seen == 0 ? "unseen" : "seen"}`}>
                            <div className="left">
                                <div className={`iconContainer notification${data.sub_type}`}>
                                    {notification.icon}
                                </div>
                            </div>
                            <div className="right">
                                <div className="top">
                                    <div className="main-header">
                                        <span className="header">
                                            {mainHeader}
                                        </span>
                                        <span className="time">
                                            {date}
                                        </span>
                                    </div>
                                    {notification.sub_header ? 
                                        <div className="sub-header">
                                            <span>
                                                {subHeader}
                                            </span>
                                        </div>
                                        : undefined}
                                </div>
                                <div className="bottom">
                                    <span className="message">
                                        {msg}
                                    </span>
                                    <div className="actions">
                                        {_.map(notification.actions, (action, i) => {
                                            if (i === "markasseen" && data.seen == 1) {
                                                return null;
                                            } else if (functions[i]) {
                                                return (
                                                    <span className="action" onClick={functions[i]}>
                                                        {action}
                                                    </span>
                                                );
                                            }   
                                        })}
                                        <span className="action" onClick={this.removeNotification}>
                                            {this.props.tr('remove')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        )
    }
}

export default Notification
