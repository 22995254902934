/* css */
import './ProductDetailsInfo.css';

/* others */
import React from 'react';

import CreatableSelect from "react-select/creatable";
import LabelFieldGroup from "./../../general/LabelFieldGroup";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';
import Select from "react-select";
import { formatInputNumber } from '../../helpers';
import DataHandler from "./../../general/DataHandler";
import TreeOption from "./../TreeOption";
import AutoCompleteCell from "../cells/AutoCompleteCell";
import { withSnackbar } from 'notistack';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import { cloneDeep } from 'lodash';

class ProductDetailsInfo extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ProductDetailsInfo");
        this.state = {
           product: props.product,
           invalidFields: [],
           catalogOptions: [],
           catalogs: props.product.catalogs ? props.product.catalogs.map(item => ({ ...item, label: item.name, value: item.id })) : [],
           deleteCatalogDialogOpen: false,
           loadingCatalogs: false
        };

        this.writePrivileges = context.functions.checkPrivilege("products", "write", props.product.companies_id);
        this.onCatalogChange = this.onCatalogChange.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        DataHandler.post({
            url: `products/company/${this.state.product.companies_id}/catalog`,
        }, { all: true })
            .done((res) => {
                this.setState({ catalogOptions: res.catalogs.map(e => { return { ...e, value: e.id } }) || [] });
            })
            .fail((error) => {
                console.log(error);
            });
    }

    closeDeleteDialog = () => {
        this.setState({ deleteCatalogDialogOpen: false });
    }

    async editField(e, validation = "") {
        let name = e.target.name;
        let value = e.target.value;
        if (validation === "numeric" || validation === "percent")
            value = value.replace(",", ".");

        if (!this.validateField(name, value, validation))
            return;

        if (name === "percentage_increase") {
            name = "income_price";
            value = parseFloat(this.state.product.cost_price) + (value * this.state.product.cost_price / 100);
        }

        if (name === "vat" || name === "balance")
            value = Math.round(value);

        let product = { ...this.state.product, [name]: value }

        if (name === "balance") {
            await DataHandler.get({url: `products/${this.props.product.id}/free_balance`, balance: value}).done(response => {
                product.freeBalance = response.freeBalance;
            });   
        }

        this.props.saveProduct(product, "updateListFromSlider");

        this.setState(state => {
            state.product = product;
            return state;
        });
    }

    validateField(name, value, validation) {
        let data = {...this.state.invalidFields}; 

        if (name === "code" && this.codeIsNotUnique(value)) {
            this.showFieldError(name, 'Product code must be unique.', data);
			return false;
        }

		if (validation === "empty" && (!value || value.trim() === "")) {
			this.showFieldError(name, name.charAt(0).toUpperCase() + name.slice(1) + " cannot be empty", data)
			return false;
		}

		if ((validation === "numeric" || validation === "percent") && !Number(value) && Number(value) !== 0) {
            this.showFieldError(name, 'Invalid number', data);
			return false;
		}

		if (validation === "percent" && (Number(value) < 0 || Number(value) > 100)) {
			this.showFieldError(name, 'Invalid percent', data);
			return false;
        }
        
        data[name] = false; 
		this.setState({ invalidFields: data });
        return true;
    }

    showFieldError(name, message, data) {
		this.props.enqueueSnackbar(this.tr(message), {
				variant: 'error'
        });
        
		data[name] = true; 
		this.setState({ invalidFields: data });
	}

    
    codeIsNotUnique = (data) => {
		if(this.props.productCodes.indexOf(data) > -1 && data !== "" && data != this.props.product.code) {
            return true;
		}
		return false;
	}

    selectionChanged = (e, field) => {
        let product = { ...this.state.product, [field]: e.id }

        if (field = "unit_id") 
            product.unit = e.name

        this.props.saveProduct(product, "updateListFromSlider")

        this.setState(state => {
            state.product = product;
            return state;
        });
    }

    groupField = (options) => {
        return {
            label: this.tr("Group"), editable: true, className: "groupfilter", name: "product_types_id", value: -1, editorType: AutoCompleteCell, autoCompleteData: options,
            rowHeight: 20,
            editable: this.writePrivileges,
            listCellProps: {
                showTooltipForOverflownText: true,
                inEditMode: true,
                width: "100%",
                className: "product_types_id"
            },
            components: {
                Option: props => {
                    const passProps = {
                        isDisabled: props.isDisabled,
                        isFocused: props.isFocused,
                        isMulti: props.isMulti,
                        isSelected: props.isSelected
                    };

                    return <TreeOption {...props.data} {...passProps} onSelect={e => {
                        if (e) {
                            props.setValue(e.id);
                            this.selectionChanged(e, "product_types_id")
                        }
                        else {
                            props.setValue("");
                            this.selectionChanged({ id: "" }, "product_types_id")
                        }
                    }} />;
                }
            }
        }
    }

    createCatalog = (name) => {
        this.setState({ loadingCatalogs: true });
        const { catalogs } = this.state;

        DataHandler.put({url: `products/company/${this.state.product.companies_id}/catalog`},{ catalogs: [{ name }] }).done((res) => {
            const id = (res.catalog_ids || [])[0];
            
            DataHandler.put({
                url: `products/company/${this.state.product.companies_id}/catalog/products`,
            }, { catalogs: [id], products: [this.state.product.id]})       
            .done(() => {
                setTimeout(() => {
                    this.props.getProductData();
                    this.setState({ catalogs: cloneDeep(catalogs).concat({id, label: name, value: id}), loadingCatalogs: false });
                }, 1000);
            })
        })
    }

    onCatalogChange(value, { action, removedValue}) {
        switch (action) {
            case 'remove-value':
                this.setState({ deleteCatalogDialogOpen: true, deleteCatalogData: {...removedValue, catalogs: value} })
                break;
            case 'select-option':
                this.setState({ loadingCatalogs: true });
                DataHandler.put({
                    url: `products/company/${this.state.product.companies_id}/catalog/products`,
                }, { catalogs: [value[value.length - 1].id], products: [this.state.product.id] }).done(() => {
                    setTimeout(() => {
                        this.props.getProductData();
                        this.setState({ catalogs: value, loadingCatalogs: false });
                    }, 1000);
                })
        }
    }

    deleteCatalog() {
        this.setState({ loadingCatalogs: true });
        DataHandler.put({ url: `products/company/${this.state.product.companies_id}/catalog/products`, },
            { catalogs: [this.state.deleteCatalogData.id], products: [this.state.product.id], delete: 1 }).done(() => {
                setTimeout(() => {
                    this.props.getProductData();
                    this.setState({ catalogs: this.state.deleteCatalogData.catalogs, loadingCatalogs: false });
                }, 1000);
            })
            .fail((error) => {
                console.log(error);
            });

            this.closeDeleteDialog();
    }
    

    render() {
        const  { product, catalogs, catalogOptions } = this.state;
        let {autoCompleteData } = this.props;
        let  groupOptions = autoCompleteData.bobOptions || [];
        const units = autoCompleteData.units.map(e => {
            return {...e, value: e.id}
        });
        const isCatalogProduct = Number(product.parent_id) > 0;
        let bob_id = product['product_types_id'];
		let group_string = "";
		let cont = true;

        while(cont) {
			cont = false;
			autoCompleteData.flatgroups.forEach((bob) => {
				if(bob.id == bob_id) {
					if(group_string == "") group_string = bob.name;
					else group_string = bob.name + " > " + group_string;
					bob_id = bob.parent_id;
					cont = true;
				}
			});
        }

        let percentageIncrease = product.cost_price > 0 ? Math.round((product.income_price - product.cost_price) / product.cost_price * 100) : 0;

        if (groupOptions[0] && groupOptions[0].id !== -1)
            groupOptions.unshift({id: -1, value: -1, name: group_string, label: group_string});
        else
            groupOptions[0] = {id: -1, value: -1, name: group_string, label: group_string};            

        return (
            <div className="product-details">
                <LabelFieldGroup
                    editMode={true}
                    fields={[
                        { label: this.tr("Product Name"), editable: this.writePrivileges && !isCatalogProduct, className: this.state.invalidFields["name"] ? "product-details-error-field" : "", name: "name", value: product.name, onChange: e => this.editField(e, "empty") },
                        { label: this.tr("Product Code"), editable: this.writePrivileges && !isCatalogProduct, className: this.state.invalidFields["code"] ? "product-details-error-field" : "", name: "code", value: product.code, onChange: e => this.editField(e) },
                        // { label: this.tr("Storage"), name: "storage", value: {}, editorType: Select, options: undefined },
                        
                        this.groupField(groupOptions),

                        !isCatalogProduct && { label: this.tr("Catalogues"), className: `catalog-select ${this.state.loadingCatalogs && "loading"}`, name: "catalogs", editable: true, editorType: CreatableSelect, 
                            options: catalogOptions.filter(e => !catalogs.find(c => c.id === e.id)),
                            value: catalogs,
                            disabled: !this.writePrivileges || this.state.loadingCatalogs,
                            isMulti: true,
                            onCreateOption: this.createCatalog,
                            onChange: this.onCatalogChange
                        },
                        // { label: this.tr("Category"), name: "category",value: {}, editorType: Select, options: undefined },
                        { label: this.tr("Unit"), name: "unit_id", editable: this.writePrivileges, value: product.unit_id, editorType: Select, options: units.filter(x => x.deleted == 0 || x.id == product.unit_id), onChange: async e => this.selectionChanged(e, "unit_id") },
                        { label: this.tr("Unit Price"), editable: this.writePrivileges, className: this.state.invalidFields["cost_price"] ? "product-details-error-field" : "", name: "cost_price", value: formatInputNumber(product.cost_price), onChange: e => this.editField(e, "numeric") },
                        { label: this.tr("Selling Price"), editable: this.writePrivileges, className: this.state.invalidFields["income_price"] ? "product-details-error-field" : "", name: "income_price", value: formatInputNumber(product.income_price), onChange: e => this.editField(e, "numeric") },
                        ... (this.context.taimerAccount.use_maventa_targeting == 1 ? [{ label: this.tr("% Increase"), editable: this.writePrivileges && product.cost_price > 0, className: this.state.invalidFields["percentage_increase"] ? "product-details-error-field" : "", name: "percentage_increase", value: product.cost_price == 0 ? "" : percentageIncrease, onChange: e => this.editField(e, "numeric") }] : []),
                        { label: this.tr("VAT%"), editable: this.writePrivileges, className: this.state.invalidFields["vat"] ? "product-details-error-field" : "", name: "vat", value: product.vat, onChange: e => this.editField(e, "percent") },
                        { label: this.tr("Discount%"), editable: this.writePrivileges, className: this.state.invalidFields["discount_percent"] ? "product-details-error-field" : "", name: "discount_percent", value: formatInputNumber(product.discount_percent), onChange: e => this.editField(e, "percent") },
                        { label: this.tr("In Stock"), className: this.state.invalidFields["balance"] ? "product-details-error-field" : "", name: "balance", value: product.balance, editable: false, onChange: e => this.editField(e, "numeric") },
                        { label: this.tr("Free Balance"), name: "freeBalance", value: product.freeBalance, editable: false, onChange: e => this.editField(e) },
                        // { label: this.tr("Ordered Product"), name: "ordered_product", value: "150", editable:false  }
                    ]}
                />

                {this.state.deleteCatalogDialogOpen &&
                    <MassDialog
                        onDialogClose={this.closeDeleteDialog}
                        onDialogSave={this.deleteCatalog}
                        dialogType={"delete"}
                        dialogProps={{
                            wider: true,
                            onCloseClick: () => this.closeDeleteDialog(),
                            open: this.state.deleteCatalogDialogOpen,
                            close: () => this.closeDeleteDialog(),
                            header: this.tr("Remove product from catalog?"),
                            warning: () => {
                                return (
                                    <p id="product-delete-dialog-warning">
                                        {this.tr("Are you sure you want to remove product from catalog \"${catalog}\"?", { catalog: this.state.deleteCatalogData.label })}
                                    </p>

                                )
                            },
                            onConfirm: () => {
                                this.deleteCatalog();
                            }
                        }}
                    />
                }
            </div>
        );
    }
}

export default withSnackbar(ProductDetailsInfo);
