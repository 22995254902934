import React from "react";

import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";
import { SettingsContext } from "../SettingsContext";
import Button from '@mui/material/Button';
import List from "../list/List";
import TreeListRow from "../list/TreeListRow";
import { gatherIdsRecursively, treeFormatDataForList, findRecursively } from "./../list/ListUtils";
import './TabReportingGroup.css';
import ConfirmationDialog from "./../list/dialogs/ConfirmationDialog";
import PageTopSection from "../general/PageTopSection";


class TabReportingGroup extends TaimerComponent {
  static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "accounts/TabReportingGroup");

        this.accountReportGroupList = React.createRef();

        this.state = {
            data: {
                accountReportingGroups: []
            },
            dialogData: undefined
        };
        this.dialogs = {
            confirmation: ConfirmationDialog
        };
        this.fetchReportingGroups = this.fetchReportingGroups.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.fetchReportingGroups();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
    };

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    setStateData(key, data) {
        let stateData = this.state.data;
        stateData[key] = data;
        this.setState({ data: stateData });
    }

    fetchReportingGroups(callback = () => { }) {
        const { company ,id} = this.props;

        return  DataHandler.get({url: `accounts/${id}/reporting_groups/${company}`}).done(data => {
            let arg =     data.reporting_group.map(r_group => {
                r_group['radiobutton'] = r_group.is_default;
                return r_group;
            });
            this.setStateData("accountReportingGroups",   arg && arg.sort((a, b) => { return b.id - a.id; }));
            callback();
        });
    }


    render() {
        const { tr } = this;
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { company ,id, editable} = this.props;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        return (
            <div id="accounts-reportingGroup">
                    <PageTopSection mainButtons={[
                        {
                            title: tr("ADD REPORTING GROUP"),
                            action: () => this.accountReportGroupList.current.addNewRow(),
                            isVisible: editable
                        }
                    ]} header={this.props.header || tr("Reporting group")} subheaders={this.props.subheaders} subtitle={tr("Description of reporting group")} />
                    <List
                        id="accountReportGroupList"
                        className="settingsList"
                        height="auto"
                        manualListWidth={1000}
                        rowHeight={44}
                        listRowType={TreeListRow}
                        ref={this.accountReportGroupList}
                        emptyNewDataOnUpdate={false}
                        showPageSelector={false}
                        treeData={true}
                        noStateData={true}
                        idType={"string"}
                        rowProps={{
                            rowClassName: "projectCategoryRow",
                            rowHeight: 44,
                            addChildLabel: tr("Add sub-row"),
                            deleteLabel: tr("Delete"),
                            indentWidth: 24,
                            onCreate: data => {
                                DataHandler
                                    .post({url: `accounts/${id}/reporting_groups/${company}`},{ data: JSON.stringify(data)})
                                    .done(response => {
                                        this.accountReportGroupList.current.editData({ id: String(response.id), radiobutton: response.is_default }, data.id);

                                        // this.fetchReportingGroups()
                                    });
                            },
                            onUpdate: (data, _, list) => {
                                let newData = list.getData().map(d => d.id == data.id ? data : d);
                                
                                if(parseInt(data.radiobutton) === 1 || data.radiobutton === true) {
                                    newData = newData.map(d => {
                                        d.radiobutton = d.id == data.id; 

                                        return d;
                                    });
                                }

                                list.setData(newData);

                                if(data.radiobutton == true) {
                                    data['is_default'] = 1;
                                } else {
                                    data['is_default'] = 0;
                                }

                                DataHandler.post({url: `accounts/${id}/reporting_groups/${company}`},{ data: JSON.stringify(data)})

                                    .done(() => {

                                        // this.fetchReportingGroups();
                                    });
                            },
                            onDelete: (data, _, list) => {
                                this.setState({
                                    dialogData: {
                                        id: data.id,
                                        saveFunc: (id) => {
                                            if(id < 0) {
                                                this.accountReportGroupList.current.removeRow(id);
                                            } else {
                                                DataHandler.delete({ url: `accounts/${id}/reporting_groups/${company}`},{ data: JSON.stringify(data)}).then(response => {
                                                    if(response.new_default) {
                                                        list.editData({ is_default: 1, radiobutton: 1 }, response.new_default);
                                                    }

                                                    const treeData = treeFormatDataForList(list.getData(), "parent_id");
                                                    const parent   = findRecursively(treeData, d => d.data.id == id);
                                                    const treeIds  = gatherIdsRecursively(parent);

                                                    this.accountReportGroupList.current.removeRow(treeIds); 
                                                });
                                            }
                                        },
                                        text: tr("Do you want to delete reporting group") + ": " + data.name + "?"
                                    }
                                });

                                this.openDialog('confirmation');
                            },
                            addChild: data => {
                                if(data.id < 0) {
                                    DataHandler
                                        .post({url: `accounts/${id}/reporting_groups/${company}`},{ data: JSON.stringify(data)})
                                        .done(resp => {
                                            this.accountReportGroupList.current.editData({ id: resp.id }, data.id);
                                            this.accountReportGroupList.current.addNewRow({ parent_id: resp.id });
                                        });
                                    } else {
                                        this.accountReportGroupList.current.addNewRow({ parent_id: data.id });
                                    }
                                },
                            }}
                            parentKey="parent_id"
                            newRow={{
                                name: tr("New reporting group..."),
                                radiobutton: 0
                            }}
                            columns={[
                                { width: 24, name: "contextMenu", header: "", ...dColConf },
                                { width: 350, name: "name", header: tr("Reporting group"), ...dColConf },
                                { width: 150, name: "radiobutton", header: tr("Default group type"), ...dColConf }
                            ]}

                            data={this.state.data.accountReportingGroups}

                    />

                     {Dialog && <Dialog
                        open
                        company={company}
                        onDialogClose={this.closeDialog}
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}

                    />}
            </div>
        );
  }
}

export default TabReportingGroup;
