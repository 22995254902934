import React, { Component } from 'react';
import { SettingsContext } from '../../../SettingsContext';
import OutlinedField from '../../../general/OutlinedField';
import FilterDropdown from '../../../general/FilterDropdown';
import TaimerComponent from "../../../TaimerComponent";

export default class InvoiceRowMileageAdditional extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
	}

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRowMileageAdditional");

		this.state = {
			valid: false,
			validation: this.validate(this.props.item),
		};
	}

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	validate = (row) => {
		var result = {
			valid: true,
		};

		if (isNaN(row.amount) || row.amount <= 0) {
			result.valid = false;
			result.amount = true;
		}

		return result;
	}

	onChange = (e) => {
		const { name, value } = e.target;

		var item = {
			...this.props.item,
			[name]: value,
		};

		if (name === "subtype")
			item.unit_price = value.rate;

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}

	render() {
		const { item, showValidationResult, disabled: propDisabled } = this.props;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment key={item.invoiceRowIndex}>
			<div className="third colspan">
				<div className="sublevelindicator" />
				<div className="fullwidth">
					<FilterDropdown
						label={this.tr("Type")}
						name="subtype"
						items={this.props.mileageAdditional}
						onChange={this.onChange}
						value={item.subtype}
						error={validation.subtype}
						disabled={disabled} />
				</div>
			</div>

			<OutlinedField name="amount"
				label={this.tr("Amount")}
				value={item.amount}
				onChange={this.onChange}
				adornment={taimerAccount.distanceUnit}
				fullWidth
				error={validation.amount}
				disabled={disabled} />

			<OutlinedField name="unit_price"
				label={this.tr("Unit Price")}
				value={item.unit_price}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				currencyPostfix={`/${taimerAccount.distanceUnit}`}
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				error={validation.unit_price}
				disabled={disabled} />

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				error={validation.total}
				disabled={disabled} />

			<div className="invoiceRowDelete">{!disabled && <button onClick={this.delete}>X</button>}</div>
		</React.Fragment>)
	}
}