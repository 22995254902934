import React from 'react';

/* css */
import './ExpenseView.css';

/* material-ui */
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import InputLabel from '@mui/material/InputLabel';
import { Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RightArrow from '@mui/icons-material/ChevronRight';
import FormControl from '@mui/material/FormControl';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'

/* others */
import $ from 'jquery';
import List from "../list/List";
import moment from 'moment';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import DataList from './../general/DataList';
import DateCell from '../list/cells/DateCell';
import ListCell from '../list/ListCell';
import ReactDOM from 'react-dom';
import { parse, format } from 'date-fns';
import TreeSelect from '../general/TreeSelect';
import DataHandler from '../general/DataHandler';
import TaimerAvatar from '../general/TaimerAvatar';
import ContextMenu from '../general/ContextMenu';
import OutlinedField from "./../general/OutlinedField";
import TextInputCell from "../list/cells/TextInputCell";
import { DatePicker } from '../general/react-date-range/src';
import TaimerComponent from '../TaimerComponent';
import CurrencyListCell from '../list/CurrencyListCell';
import AutoCompleteCell from "../list/cells/AutoCompleteCell";
import ExpenseViewOverlay from './ExpenseViewOverlay';
import { SettingsContext } from './../SettingsContext';
import PlaceholderListCell from '../list/PlaceholderListCell';
import { DateRangePicker } from '../general/react-date-range/src';
import { AddProject } from '../general/no-options/AddItemComponents';
import ProjectTreeDropdown from "../projects/ProjectTreeDropdown";
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';
import CurrencyRateListCell from '../list/CurrencyRateListCell';
import { formatInputNumber } from '../helpers';
import LoaderButton from "../general/LoaderButton";
import { getWorktypesForProject } from '../Data';
import PurchaseExpenseTranslations from '../general/backendTranslations/PurchaseExpenseTranslations';
import TravelExpenseTranslations from '../general/backendTranslations/TravelExpenseTranslations';
import MassDialog from '../dialogs/mass_operations/CoreDialog';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import { ReactComponent as AddAdditionalAllowanceIcon } from '../general/icons/Allowance.svg';
import { withSnackbar } from 'notistack';
import StatusTag from '../general/StatusTag';
import { forEach } from 'lodash';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import FileSaver from 'file-saver';
import colors from '../colors';

function calcTotalValue(sumData, rateData, vatData, kaanteis = false) {
    let value = 0;
    let vat = vatData;

    //Calculate sum
    let sum = calcRateValue(sumData, rateData, kaanteis, true);

    //Calculate total with sum and vat
    value = sum * (vat / 100 + 1);

    return value;
}

function calcRateValue(sumData, rateData, kaanteis = false, returnNumber = false) {
    let value = 0;
    if(!rateData) {        
        sumData = Number(sumData);
        if(isNaN(sumData)) {
            return 0;
        }
        return sumData;
    }
    //Change values way they can be calculated
    let sum = prepCalcValue(sumData);
    let rate = prepCalcValue(rateData);
    if(rate == 0) {
        rate = 1;
    }
    if(kaanteis) rate = 1 / rate;
    
    value = sum * (1 / rate);

    if(!returnNumber) {
        value = value + "";
        value = String(value).replace('.', ',');
    }
    return value;
}

function prepCalcValue(val) {

    let value = val;
    value = value + "";
    value = String(value).replace(',','.');
    value = parseFloat(value);

    return value;
}

class TimeRangePicker extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "expenses/ExpenseView");
    }

    onChange = (value, name) => {
        this.props.onChange(value, name);
    }

    render() {
        const { tr } = this;
        const { name, rootClass, start, end, label = this.tr("Time range"), editable } = this.props;
        const starttime = moment(start);
        const endtime = moment(end);

        return (
            <FormControl className={rootClass} variant="filled">
                <InputLabel
                    shrink
                    ref={ref => {
                        this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor={`${name}-date`} >

                    {label}
                </InputLabel>

                <div className="time-border">
                    <div className="time-values">
                        <TextInputCell
                            name="starttime"
                            inputType="time"
                            value={starttime.format('LT')}
                            editValue={starttime.format('HH:mm')}
                            onEdited={(value) => this.onChange(value, 'starttime')}
                            editable={editable}
                            listCellProps={{
                                'data-testid': 'start_time',
                            }}
                        />
                        <span className="separator">:</span>
                        <TextInputCell
                            name="endtime"
                            inputType="time"
                            value={endtime.format('LT')}
                            editValue={endtime.format('HH:mm')}
                            onEdited={(value) => this.onChange(value, 'endtime')}
                            editable={editable}
                            listCellProps={{
                                'data-testid': 'end_time',
                            }}
                        />
                    </div>
                </div>
            </FormControl>
        );
    }
}
class ExpenseRow extends ListRow {
    static contextType = SettingsContext;

    constructor(props) {
        super(props, undefined, undefined, 'expenses/ExpenseView');

        this.initialFocusDone = false;
        this.descriptionCell = React.createRef();
        this.cellEdited = this.cellEdited.bind(this);
    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true, but has since been revised
    // to check whether any props or state has changed.
    // This change broke these rows.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(prevProps, prevState) {
        return true;
    }

    cellEdited = (name, value, totalEdited = false) => {
        const { rowProps } = this.props;

        const { userObject } = this.context;

        if (name == "sum" && this.props.rowProps.currency_rate){
            if(value === NaN || value === "") value = 0;
            value = calcRateValue(value, this.props.rowProps.currency_rate, true, true);
        }

        let data = cloneDeep(this.props.data);
        data[name] = value;

        const expense_vat = data.type > 0 && this.props.sharedData.types ? this.props.sharedData.types.find(vt => vt.id == data.type) : {};
       
        if (name == "type")  {
            data.vat = expense_vat.vat; 
            this.setState({_invalidField_vat: false})
        }

        if (name == "sum" && totalEdited) {
            data.last_edited_sum = "total";
        } else if (name == "sum") {
            data.last_edited_sum = "sum";
        }

        let action = "";
        if (rowProps.expenseType == 1) {
            action = "set_purchase_expense_row";
        } else {
            action = "set_other_allowance_row";
        }

        let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: action };

        if (rowProps.expenseId) {
            DataHandler.post(params, data).done(response => {
                data['id'] = response.data['id'];
                data['old_id'] = response.data['old_id'] || 0;
                data.cost = data.sum;
                rowProps.rowChanged(data, false, "expense")
            });
        } else {
            rowProps.rowChanged(data, true, "expense");
        }
    }

    vatEdited = (value, data, total) => {
        const { sharedData: { enqueueSnackbar } } = this.props;
        const inputValue = value;
        value = Number(value.replace(",", "."));
        
        if (!value && value != "0" && value != null) {
            enqueueSnackbar((this.tr("Invalid number") + "."), {
                variant: "error",
            });
            this.setState({_invalidField_vat: inputValue})
            return;
        }
        else if(value > 100){
            enqueueSnackbar((this.tr("The entered vat % is more then 100") + "."), {
                variant: "error",
            });
            this.setState({_invalidField_vat: inputValue})
            return;
        } 
        else if(value < 0){
            enqueueSnackbar((this.tr("The entered vat % is less than 0") + "."), {
                variant: "error",
            });
            this.setState({_invalidField_vat: inputValue})
            return;
        } 

        this.setState({_invalidField_vat: false})
        if (data.last_edited_sum == "total") {
            this.calcTaxlessPrice(total, value, true)
        }else
            this.cellEdited("vat", value);
    }

    handleDelete = event => {
        const { rowProps, data } = this.props;
        const { userObject } = this.context;

        let action = "";
        if (rowProps.expenseType == 1) {
            action = "delete_purchase_expense_row";
        } else {
            action = "delete_other_allowance_row";
        }

        let params = { module: "purchase_expenses", id: data['id'], data: data, companies_id: userObject.companies_id, action: action };
        if (data.isDraft == true) {
            rowProps.handleDelete(data, "expense");
            this.props.rowProps.afterDelete();
        } else if (data.id < 0) {
            rowProps.handleDelete(data, "expense");
            this.props.rowProps.afterDelete();
        } else {
            DataHandler.post(params, data).done(response => {
                rowProps.handleDelete(data, "expense");
                this.props.rowProps.afterDelete();
            });
        }        
    };

    calcTaxlessPrice = (total, vat, updateVat = false) => {
        if (isNaN(total))
            total = parseFloat(total.replace(",", "."));
        
        if (isNaN(vat))
            vat = parseFloat(vat.replace(",", "."));

        this.cellEdited("sum", total / (vat / 100 + 1), true);

        if (updateVat) {
            this.setData("vat", vat);
            setTimeout(() => {
                this.cellEdited("vat", vat);
            }, 500);
        }
    }

    componentDidMount() {
        if (!this.initialFocusDone && this.props.data['id'] < 0 && this.props.rowProps.expenseType == 1) {
            this.descriptionCell.current.listCell.current.openEdit();
            this.initialFocusDone = true;
        }
    } 

    render() {
        const { data } = this.props;
        const { taimerAccount, functions } = this.context; 

        const className = ["expenseListRow listElement row", this.props.hidden ? "hidden" : "", this.state.data['id'] < 0 ? "new" : ""].join(" ");

        let deletedTypes = [],
            validTypes = [];

        this.props.sharedData.types.map(type => {
            if (type.deleted > 0) {
                type.isDisabled = true;
                deletedTypes.push(type);
            } else {
                validTypes.push(type);
            }
        });

        this.props.sharedData.attachments.map(a => {
            a.name = a.filename;
            a.id = a.attachments_id;
            return a;
        });

        let attachmentList = [...this.props.sharedData.attachments];
        attachmentList.length > 0 && attachmentList.unshift({id: 0, name: '-', deleted: 0}); 

        const types = [...validTypes, ...deletedTypes]; 
        const type = types.find(t => t.id == data.type);
        if (isNaN(data.sum))
            data.sum = 0;
         if (isNaN(data.vat))
            data.vat = 0;
 
        const jobtype = data.jobtypes_id > 0 && this.props.sharedData.jobtypes ? this.props.sharedData.jobtypes.find(t => t.id == data.jobtypes_id) : {};
        const ratio = data.ratio_id > 0 && this.props.sharedData.ratios ? this.props.sharedData.ratios.find(t => t.id == data.ratio_id) /*: this.props.sharedData.ratios ? this.props.sharedData.ratios[0]*/ : {};
        const attachment = this.props.sharedData.attachments.find(t => t.id == data.attachments_id);
        const product = data.accounting_products_id > 0 && this.props.sharedData.products ? this.props.sharedData.products.find(t => t.id == data.accounting_products_id) : {}
        const account = data.accounting_accounts_id > 0 && this.props.sharedData.accounts ? this.props.sharedData.accounts.find(t => t.id == data.accounting_accounts_id) : {}
        const expense_vat = data.type > 0 && this.props.sharedData.types ? this.props.sharedData.types.find(vt => vt.id == data.type) : {};
        let vat = "";
      /*   if (data.vat)  
            vat = formatInputNumber(data.vat);
 */
        const sum = calcRateValue(data.sum, this.props.rowProps.currency_rate, false, true).toFixed(2);
        let total = calcTotalValue(data.sum, this.props.rowProps.currency_rate, data.vat);
       

        const cells = {
            delete:
                <ListCell width={this.props.columnWidthMap['delete']} onlyDisplay={true} zeroBasis={true}>
                    <div className="cell" style={{ width: "100%" }} data-testid={"expense-row-delete-button-" + this.props.rowIndex}>
                        {this.props.rowProps.editable && <DeleteIcon
                            fontSize="small"
                            onClick={this.handleDelete}
                        />}
                    </div>
                </ListCell>,
            description:
                <TextInputCell
                    listCellType={PlaceholderListCell}
                    ref={this.descriptionCell}
                    listCellProps={{
                        placeholder: this.tr("Write description here"),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true,
                        'data-testid': 'description-' + this.props.rowIndex,
                    }}
                    style={{ width: this.props.columnWidthMap['description'] + 'px' }}
                    width={this.props.columnWidthMap['description']}
                    name="description"
                    value={data.description}
                    onEdited={this.cellEdited}
                    placeholder={this.tr("Write description here")}
                    editable={this.props.rowProps.editable}
                />,
            sum:
                <TextInputCell
                    listCellType={CurrencyRateListCell}
                    style={{ width: this.props.columnWidthMap['sum'] + 'px' }}
                    width={this.props.columnWidthMap['sum']}
                    name="sum"
                    listCellProps={{
                        zeroBasis: true,
                        'data-testid': 'sum-' + this.props.rowIndex,
                    }}
                    value={sum ? Number(sum).toFixed(2) : ""}
                    currency={this.props.rowProps.currency_label}
                    onEdited={this.cellEdited}
                    editable={this.props.rowProps.editable}
                    validation={["numeric"]}
                />,
            vat:
                <TextInputCell
                    style={{ width: this.props.columnWidthMap['vat'] + 'px' }}
                    width={this.props.columnWidthMap['vat']}
                    name="vat"
                    listCellProps={{
                        zeroBasis: true,
                        showErrorBorder: this.state._invalidField_vat,
                        'data-testid': 'vat-' + this.props.rowIndex,
                    }}
                    value={this.state._invalidField_vat ? this.state._invalidField_vat : (data.vat == "0" ? "0,00" : (data.vat ? formatInputNumber(data.vat) : ""))}
                    onEdited={(value) => {
                        this.vatEdited(value, data, total)
                    }}
                    editable={this.props.rowProps.editable && (expense_vat && expense_vat.vat == null)}
                />,
            total:
                <TextInputCell
                    listCellType={CurrencyRateListCell}
                    style={{ width: this.props.columnWidthMap['total'] + 'px' }}
                    width={this.props.columnWidthMap['total']}
                    name="total"
                    listCellProps={{
                        zeroBasis: true
                    }}
                    value={total ? Number(total).toFixed(2) : ""}
                    currency={this.props.rowProps.currency_label}
                    editable={this.props.rowProps.editable}
                    onEdited={(value) => this.calcTaxlessPrice(value, data.vat)}
                />,
            type:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['type'] + 'px' }}
                    width={this.props.columnWidthMap['type']}
                    name="type"
                    value={type}
                    listCellProps={{
                        zeroBasis: true,
                        'data-testid': 'type-' + this.props.rowIndex,
                    }}
                    editable={type && type.deleted > 0 && data.id > 0 && this.props.rowProps.editable ? false : true}
                    autoCompleteData={types}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("type", value.id)
                    }}
                />,
            ratio_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['ratio_id'] + 'px' }}
                    width={this.props.columnWidthMap['ratio_id']}
                    name="ratio_id"
                    value={ratio}
                    editable={ratio && ratio.deleted > 0 && data.id > 0 && this.props.rowProps.editable ? false : true}
                    autoCompleteData={this.props.sharedData.ratios}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("ratio_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            jobtypes_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['jobtypes_id'] + 'px' }}
                    width={this.props.columnWidthMap['jobtypes_id']}
                    name="jobtypes_id"
                    value={jobtype}
                    editable={this.props.rowProps.editable ? false : true}
                    autoCompleteData={this.props.sharedData.jobtypes}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("jobtypes_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            attachments_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['attachments_id'] + 'px' }}
                    width={this.props.columnWidthMap['attachments_id']}
                    name="attachments_id"
                    value={attachment}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={attachmentList}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("attachments_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_products_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_products_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_products_id']}
                    name="accounting_products_id"
                    value={product}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.products}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_products_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_accounts_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_accounts_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_accounts_id']}
                    name="accounting_accounts_id"
                    value={account}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.accounts}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_accounts_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />
        };

        return (
            <div data-testid={"expense-row" + this.props.rowIndex} className={className} style={{ display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}>
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class MileageAllowanceRow extends ListRow {
    static contextType = SettingsContext;

    constructor(props) {
        super(props, undefined, undefined, 'expenses/ExpenseView');
        this.initialFocusDone = false;
        this.cellEdited = this.cellEdited.bind(this);
    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true, but has since been revised
    // to check whether any props or state has changed.
    // This change broke these rows.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(prevProps, prevState) {
        return true;
    }

    componentDidMount() {
        let { data } = this.props;
        if (data) {
            let allowance, product = undefined;
            if (data.is_additional_row == 1) {
                allowance = this.props.sharedData.additionalRates.find(t => t.id == data.target_id);
            } else {
                allowance = this.props.sharedData.mileageRates.find(t => t.id == data.target_id);
            }
            if (allowance)
                product = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product);
            
            if (product)
                data.accounting_products_id = product.id;
            
            this.setState({data: data});
        }
    }

    cellEdited = (name, value) => {
        const { rowProps } = this.props;
        const { userObject } = this.context;

        if (name == "mileage" && typeof name == "string") {
            value = value && String(value).replace(',', '.');
            value = value && Math.round(value * 2) / 2;
        }

        let data = cloneDeep(this.props.data);
        if (name == "target_id") {
            const rateEnddate = this.props.sharedData[(data.is_additional_row == 1 ? 'additionalRates' : 'mileageRates')].find(mr => mr.id == value).enddate;
            if (!rowProps.enddateCheck(rateEnddate)) {
                rowProps.enqueueSnackbar(this.tr('The final date for this allowance exceeds selected end date.'), {
                    variant: "error"
                });
                return;
            }
        }

        data[name] = value;
        if (name === "target_id") {
            let allowance, product = undefined;
            if (data.is_additional_row == 1) {
                allowance = this.props.sharedData.additionalRates.find(t => t.id == data.target_id);
            } else {
                allowance = this.props.sharedData.mileageRates.find(t => t.id == data.target_id);
            }
            product = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product);
            if (product)
                data.accounting_products_id = product.id;
        }
        this.setState({ data: data }, () => {
            let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: "set_mileage_allowance_row" };
            let target = "mileage";

            if (data.is_additional_row == 1) {
                target = "additional";
            }

            if (rowProps.expenseId) {
                DataHandler.post(params, data).done(response => {
                    data['id'] = response.data['id'];
                    data['old_id'] = response.data['old_id'] || 0;
                    rowProps.rowChanged(data, false, target)
                });
            } else {
                rowProps.rowChanged(data, true, target)
            }        
        });
    }

    handleDelete = event => {
        const { rowProps, data } = this.props;
        const { userObject } = this.context;

        let target = "mileage";
        let action = "delete_mileage_allowance_row";

        if (data.is_additional_row == 1) {
            action = "delete_additional_allowance_row";
        }

        let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: action };
        if (data.isDraft == true) {
            rowProps.handleDelete(data, target);
        } else if (data.id < 0) {
            rowProps.handleDelete(data, target);
        } else {
            DataHandler.post(params, data).done(response => {
                rowProps.handleDelete(data, target);
            });
        }     
        this.props.rowProps.afterDelete();           
    };

    render() {
        const { data } = this.props;

        const className = ["expenseListRow listElement row", this.props.hidden ? "hidden" : "", data['id'] < 0 ? "new" : ""].join(" ");

        const allowanceList = data.is_additional_row == 1 ? this.props.sharedData.additionalRates : this.props.sharedData.mileageRates;
        
        let allowance = undefined;
        let extraPassangerRate = 0;

        if (data.is_additional_row == 1) {
            allowance = this.props.sharedData.additionalRates.find(t => t.id == data.target_id);
            if (!allowance) {
                allowance = this.props.sharedData.additionalRates.filter(e => Number(e.deleted) < 1)[0] || {id: 0};
                this.props.data['target_id'] = allowance.id;
                let accountProduct = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product)
                if (accountProduct)
                    this.props.data['accounting_products_id'] = accountProduct.id;
            }
        } else {
            allowance = this.props.sharedData.mileageRates.find(t => t.id == data.target_id);
            if (!allowance) {
                allowance = this.props.sharedData.mileageRates.filter(e => Number(e.deleted) < 1)[0] || {id: 0};
                this.props.data['target_id'] = allowance.id;
                let accountProduct = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product)
                if (accountProduct)
                    this.props.data['accounting_products_id'] = accountProduct.id;
            }
        }

        if (this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(this.props.sharedData.companyId) > -1) {
            const xtraRate = this.props.sharedData.additionalRates.find(e => e.type == 1 && e.deleted != 1);
            extraPassangerRate = xtraRate ? xtraRate.rate : 0;
        }

        let rate = allowance.rate-0
        if (this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(this.props.sharedData.companyId) > -1) {
            rate = data.add_passenger_count > 0 ? parseFloat(allowance.rate) + parseFloat(extraPassangerRate) * parseFloat(data.add_passenger_count) : allowance.rate-0;
        }

        const jobtype = data.jobtypes_id > 0 && this.props.sharedData.jobtypes ? this.props.sharedData.jobtypes.find(t => t.id == data.jobtypes_id) : {}; 
        const product = data.accounting_products_id > 0 && this.props.sharedData.products ? this.props.sharedData.products.find(t => t.id == data.accounting_products_id) : {}
        const account = data.accounting_accounts_id > 0 && this.props.sharedData.accounts ? this.props.sharedData.accounts.find(t => t.id == data.accounting_accounts_id) : {}
        let total = rate * data.mileage;

        if (isNaN(total))
            total = 0;

        let mileage = "";
        if (data.mileage)   
            mileage = String(data.mileage).replace(".",",");

        const cells = {
            mileage_context:
                this.props.rowProps.editable == true ?
                    <ListCell width={this.props.columnWidthMap['mileage_context']} onlyDisplay={true} zeroBasis={true}>
                        <ContextMenu label={<ThreeDotsIcon />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" width={this.props.columnWidthMap['mileage_context']} style={{ width: "100%" }} noExpandIcon popperProps={{ disablePortal: false }}>
                            {!data.is_additional_row == 1 && (!this.context.addons.netvisor || (this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(this.props.sharedData.companyId) == -1)) && <MenuItem className="menuItem additional" onClick={() => this.props.rowProps.addAdditionalAllowance(data.id)}><AddAdditionalAllowanceIcon />{this.tr("Add additional allowance")}</MenuItem>}
                            <MenuItem className="menuItem delete" onClick={() => this.handleDelete()}><RemoveIcon className="Delete"/>{this.tr("Delete")}</MenuItem>
                        </ContextMenu>
                    </ListCell>
                    : <ListCell editable={false} width={this.props.columnWidthMap['mileage_context']} />,
            mileage_allowance:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['mileage_allowance'] + 'px' }}
                    width={this.props.columnWidthMap['mileage_allowance']}
                    name="mileage_allowance"
                    value={allowance}
                    editable={this.props.rowProps.editable}
                    autoCompleteData={allowanceList}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    listCellProps={{
                        zeroBasis: true
                    }}
                    onEdited={value => {
                        this.cellEdited("target_id", value.id)
                    }}
                />,
            mileage_description:
                <TextInputCell
                    listCellType={PlaceholderListCell}
                    ref={this.descriptionCell}
                    listCellProps={{
                        placeholder: this.tr("Write mileage description here"),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true,
                        'data-testid': 'mileage_description-' + this.props.rowIndex,
                    }}
                    style={{ width: this.props.columnWidthMap['mileage_description'] + 'px' }}
                    width={this.props.columnWidthMap['mileage_description']}
                    name="mileage_description"
                    value={data.mileage_description}
                    onEdited={this.cellEdited}
                    placeholder={this.tr("Write description here")}
                    editable={this.props.rowProps.editable}
                />,
            mileage:
                <TextInputCell
                    listCellType={PlaceholderListCell}
                    listCellProps={{
                        placeholder: this.tr("Add mileage distance"),
                        style: {
                            fontStyle: "normal"
                        }
                    }}
                    style={{ width: this.props.columnWidthMap['mileage'] + 'px' }}
                    width={this.props.columnWidthMap['mileage']}
                    name="mileage"
                    listCellType={PlaceholderListCell}
                    listCellProps={{
                        placeholder: this.tr("Add mileage"),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true,
                        'data-testid': 'mileage_amount-' + this.props.rowIndex,
                    }}
                    value={mileage}
                    onEdited={this.cellEdited}
                    placeholder={this.tr("Add mileage distance")}
                    editable={this.props.rowProps.editable}
                />,
            add_passenger_count:
                <TextInputCell
                    style={{width: this.props.columnWidthMap['add_passenger_count'] + 'px'}}
                    width={this.props.columnWidthMap['add_passenger_count']}
                    name="add_passenger_count"
                    listCellType={PlaceholderListCell}
                    listCellProps={{
                        placeholder: this.tr("Additional passengers"),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true
                    }}
                    value={data.add_passenger_count}
                    onEdited={this.cellEdited}
                    editable={this.props.rowProps.editable}
                />,
            price_per_distance:
                <TextInputCell
                    listCellType={CurrencyRateListCell}
                    style={{ width: this.props.columnWidthMap['price_per_distance'] + 'px' }}
                    width={this.props.columnWidthMap['price_per_distance']}
                    name="price_per_distance"
                    value={rate || 0}
                    editable={false}
                    listCellProps={{
                        zeroBasis: true
                    }}
                    currency={this.props.rowProps.currency_label}
                />,
            mileage_total:
                <TextInputCell
                    listCellType={CurrencyRateListCell}
                    style={{ width: this.props.columnWidthMap['mileage_total'] + 'px' }}
                    width={this.props.columnWidthMap['mileage_total']}
                    name="mileage_total"
                    value={total}
                    editable={false}
                    listCellProps={{
                        zeroBasis: true,
                        'data-testid': 'mileage_row_total-' + this.props.rowIndex,
                    }}
                    currency={this.props.rowProps.currency_label}
                />,
            jobtypes_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['jobtypes_id'] + 'px' }}
                    width={this.props.columnWidthMap['jobtypes_id']}
                    name="jobtypes_id"
                    value={jobtype}
                    editable={this.props.rowProps.editable ? false : true}
                    autoCompleteData={this.props.sharedData.jobtypes}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("jobtypes_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_products_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_products_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_products_id']}
                    name="accounting_products_id"
                    value={product}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.products}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_products_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_accounts_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_accounts_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_accounts_id']}
                    name="accounting_accounts_id"
                    value={account}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.accounts}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_accounts_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />
        };

        return (
            <div className={className} style={{ display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}>
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class DailyAllowanceRow extends ListRow {
    static contextType = SettingsContext;

    constructor(props) {
        super(props);
        this.initialFocusDone = false;
        this.cellEdited = this.cellEdited.bind(this);
    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true, but has since been revised
    // to check whether any props or state has changed.
    // This change broke these rows.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(prevProps, prevState) {
        return true;
    }

    componentDidMount() {
        let { data } = this.props;
        let allowance, product = undefined;
        allowance = this.props.sharedData.dailyRates.find(t => t.id == data.daily_allowances_id);
        product = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product);                
        if (product)
            data.accounting_products_id = product.id;
        this.setState({data: data});
    }

    cellEdited = (name, value) => {
        const { rowProps } = this.props;
        const { userObject, taimerAccount: { countryCode } } = this.context;

        let updateDays = false;

        if (name == "days" && typeof value === "string") {
            let days = String(value).replace(',', '.');
            value = Math.round(days * 2) / 2;
            if(isNaN(value) || value === "") value = 0;
        }        
        if (name == "startdate") {
            name = 'start_time';
            updateDays = true;

            let date = new Date(value);
            date = moment(date);

            let currentDate = this.props.data[name]
            currentDate = moment(currentDate);

            value = date.format("YYYY-MM-DD") + " " + currentDate.format("HH:mm:ss")
        } else if (name == "enddate") {
            name = 'end_time';
            updateDays = true;

            let date = new Date(value)
            date = moment(date);

            let currentDate = this.props.data[name]
            currentDate = moment(currentDate);

            value = date.format("YYYY-MM-DD") + " " + currentDate.format("HH:mm:ss")
        } else if (name == "start_time") {
            let currentDate = this.props.data[name]
            currentDate = moment(currentDate);

            updateDays = true;

            if (value == "")
                value = "00:00"

            let newValue = currentDate.format("YYYY-MM-DD") + " " + value + ":00";
            value = newValue;
        } else if (name == "end_time") {
            let currentDate = this.props.data[name]
            currentDate = moment(currentDate);

            updateDays = true;

            if (value == "")
                value = "00:00"

            let newValue = currentDate.format("YYYY-MM-DD") + " " + value + ":00";
            value = newValue;
        }

        let data = cloneDeep(this.props.data);

        if (name == "target_id") {
            const rateEnddate = this.props.sharedData.dailyRates.find(mr => mr.id == value).enddate;
            if (!rowProps.enddateCheck(rateEnddate)) {
                rowProps.enqueueSnackbar(this.tr('The final date for this allowance exceeds selected end date.'), {
                    variant: "error"
                });
                return;
            }
        }

        data[name] = value;
        if (name === "daily_allowances_id") {
            let allowance, product = undefined;
            allowance = this.props.sharedData.dailyRates.find(t => t.id == data.daily_allowances_id);
            product = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product);
            if(typeof product !== "undefined" ) data.accounting_products_id = product.id;
        }
        this.setState({ data: data }, () => {
            if (updateDays && countryCode === "FI") {
                data['days'] = this.calculateDays(data);
            }

            let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: "set_daily_allowance_row" };

            if (rowProps.expenseId) {
                DataHandler.post(params, data).done(response => {
                    data['id'] = response.data['id'];
                    data['old_id'] = response.data['old_id'] || 0;
                    rowProps.rowChanged(data, false, "daily")
                });
            } else {
                rowProps.rowChanged(data, true, "daily")
            }
        });
    }

    calculateDays = (data) => {
        const start = moment(data.start_time).format('x');
        const end = moment(data.end_time).millisecond('x');
        const hours = (end - start) / 3600000;
        const days = Math.floor(hours / 24);
        const remainingHours = hours - (days * 24);
        let total = days;

        if (days > 0) {
            if (remainingHours > 6)
                total++;
            else if (remainingHours > 2)
                total += 0.5;
        }
        else {
            if (remainingHours > 10)
                total++;
            else if (remainingHours > 6)
                total += 0.5;
        }

        return total;
    }

    handleDelete = event => {
        const { rowProps, data } = this.props;
        const { userObject } = this.context;

        let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: "delete_daily_allowance_row" };
        if (data.isDraft == true) {
            rowProps.handleDelete(data, "daily");
            this.props.rowProps.afterDelete();
        } else if (data.id < 0) {
            rowProps.handleDelete(data, "daily");
            this.props.rowProps.afterDelete();
        } else {
            DataHandler.post(params, data).done(response => {
                rowProps.handleDelete(data, "daily");
                this.props.rowProps.afterDelete();
            });
        }
    };

    render() {
        const { data } = this.props;

        const className = ["expenseListRow listElement row", this.props.hidden ? "hidden" : "", this.state.data['id'] < 0 ? "new" : ""].join(" ");
        const { taimerAccount } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;

        let allowance = undefined;

        allowance = this.props.sharedData.dailyRates.find(t => t.id == data.daily_allowances_id);
        if (!allowance) {
            allowance = this.props.sharedData.dailyRates[0];
            this.props.data['daily_allowances_id'] = allowance.id
            let accountProduct = allowance.id > 0 && this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product)
            if (accountProduct)
                this.props.data['accounting_products_id'] = accountProduct.id;
        }

        if (allowance && allowance.integration_product > 0 && !data.accounting_products_id) {
            let productObject = this.props.sharedData.products && this.props.sharedData.products.find(t => t.id == allowance.integration_product);
            if (productObject)
                data.accounting_products_id = productObject.id
        }

        const jobtype = data.jobtypes_id > 0 && this.props.sharedData.jobtypes ? this.props.sharedData.jobtypes.find(t => t.id == data.jobtypes_id) : {};

        if (!this.props.data.start_time)
            this.props.data.start_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (!this.props.data.end_time)
            this.props.data.end_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        const start = moment(this.props.data.start_time);
        const end = moment(this.props.data.end_time);

        const product = data.accounting_products_id > 0 && this.props.sharedData.products ? this.props.sharedData.products.find(t => t.id == data.accounting_products_id) : {}
        const account = data.accounting_accounts_id > 0 && this.props.sharedData.accounts ? this.props.sharedData.accounts.find(t => t.id == data.accounting_accounts_id) : {}

        let total = (((data.days - data.days % 1) * allowance.rate) + (data.days % 1 * 2 * allowance.part_rate)).toFixed(2);
        if (isNaN(total))
            total = 0;

        let days = "";
        if (data.days)  
            days = String(data.days).replace(".",",");

        const cells = {
            daily_delete:
                <ListCell width={this.props.columnWidthMap['daily_delete']} onlyDisplay={true} zeroBasis={true}>
                    <div className="cell" style={{ width: "100%" }}>
                        {this.props.rowProps.editable && <DeleteIcon
                            style={{ width: "100%" }}
                            fontSize="small"
                            onClick={this.handleDelete}
                        />}
                    </div>
                </ListCell>,
            daily_allowance:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['daily_allowance'] + 'px' }}
                    width={this.props.columnWidthMap['daily_allowance']}
                    name="daily_allowance"
                    value={allowance}
                    editable={this.props.rowProps.editable}
                    autoCompleteData={this.props.sharedData.dailyRates}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    listCellProps={{
                        zeroBasis: true
                    }}
                    onEdited={value => {
                        this.cellEdited("daily_allowances_id", value.id)
                    }}
                />,
            start_date:
                <DateCell
                    usePopper={true}
                    width={this.props.columnWidthMap['start_date']}
                    name="start_date"
                    offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    editable={this.props.rowProps.editable}
                    value={start.format('YYYY-MM-DD')}
                    listCellProps={{
                        zeroBasis: true
                    }}
                    onEdited={date => {
                        this.cellEdited("startdate", date);
                    }} />,
            start_time:
                <TextInputCell
                    width={this.props.columnWidthMap['start_time']}
                    name="start_time"
                    inputType="time"
                    value={start.format('LT')}
                    editValue={start.format('HH:mm')}
                    onEdited={this.cellEdited}
                    editable={this.props.rowProps.editable}
                    listCellProps={{
                        zeroBasis: true
                    }}
                />,
            end_date:
                <DateCell
                    usePopper={true}
                    width={this.props.columnWidthMap['end_date']}
                    name="end_date"
                    offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    editable={this.props.rowProps.editable}
                    value={end.format('YYYY-MM-DD')}
                    listCellProps={{
                        zeroBasis: true
                    }}
                    onEdited={date => {
                        this.cellEdited("enddate", date);
                    }} />,
            end_time:
                <TextInputCell
                    width={this.props.columnWidthMap['end_time']}
                    name="end_time"
                    inputType="time"
                    value={end.format('LT')}
                    editValue={end.format('HH:mm')}
                    onEdited={this.cellEdited}
                    editable={this.props.rowProps.editable}
                    listCellProps={{
                        zeroBasis: true
                    }}
                />,
            days:
                <TextInputCell
                    style={{ width: this.props.columnWidthMap['days'] + 'px' }}
                    width={this.props.columnWidthMap['days']}
                    name="days"
                    listCellType={PlaceholderListCell}
                    listCellProps={{
                        placeholder: this.tr("Days"),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true
                    }}
                    value={days}
                    onEdited={this.cellEdited}
                    editable={this.props.rowProps.editable}
                />,
            daily_summary:
                <TextInputCell
                    width={this.props.columnWidthMap['daily_summary']}
                    name="mileage_description"
                    value={currencyFormatter(allowance.rate) + " / " + currencyFormatter(allowance.part_rate)} //will probably break if is editable
                    onEdited={this.cellEdited}
                    editable={false}
                    listCellProps={{
                        zeroBasis: true
                    }}
                />,
            daily_total:
                <CurrencyListCell
                    width={this.props.columnWidthMap['daily_total']}
                    name="mileage_description"
                    value={total}
                    onEdited={this.cellEdited}
                    editable={false}
                    zeroBasis={true}
                    data-testid={'allowance_row_total-' + this.props.rowIndex}
                />,
            jobtypes_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['jobtypes_id'] + 'px' }}
                    width={this.props.columnWidthMap['jobtypes_id']}
                    name="jobtypes_id"
                    value={jobtype}
                    editable={this.props.rowProps.editable ? false : true}
                    autoCompleteData={this.props.sharedData.jobtypes}
                    searchable={false}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("jobtypes_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_products_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_products_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_products_id']}
                    name="accounting_products_id"
                    value={product}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.products}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_products_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />,
            accounting_accounts_id:
                <AutoCompleteCell
                    style={{ width: this.props.columnWidthMap['accounting_accounts_id'] + 'px' }}
                    width={this.props.columnWidthMap['accounting_accounts_id']}
                    name="accounting_accounts_id"
                    value={account}
                    editable={this.props.rowProps.editable ? true : false}
                    autoCompleteData={this.props.sharedData.accounts}
                    searchable={true}
                    menuPortalTarget={this.props.rowProps.listContainer}
                    onEdited={value => {
                        this.cellEdited("accounting_accounts_id", value.id)
                    }}
                    listCellProps={{
                        editable: this.props.rowProps.editable,
                        inEditMode: this.props.rowProps.editable,
                        zeroBasis: true
                    }}
                />
        };

        return (
            <div className={className} style={{ display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}>
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class ExpenseView extends TaimerComponent {
    static contextType = SettingsContext;

    headerTitle = this.tr("Expense");

    constructor(props, context) {
        super(props, context, "expenses/ExpenseView");

        this.state = {
            expense: {
                avatar: context.userObject.avatar,
                state: 0,
                costestimate_id: 0,
                costestimate_row_id: 0,
                users_id: context.userObject.usersId,
                fullname: context.userObject.fullname,
                user_role: context.userObject.role,
                color: context.userObject.color,
                abbreviation: context.userObject.abbreviation,
                expense_date: format(new Date(), "YYYY-MM-DD"),
                startdate: format(new Date(), "YYYY-MM-DD"),
                enddate: format(new Date(), "YYYY-MM-DD"),
                starttime: moment(new Date).format('HH:mm'),
                endtime: moment(new Date).format('HH:mm'),
                billing: 1,
                currency_rate: '1,000000',
                currency_label: context.taimerAccount.currency,
                projects_id: this.props.projects_id
            },
            project: {},
            totals: {
                subtotal: 0,
                vat: 0,
                total: 0
            },
            data: {
                expense_rows: [],
                mileage_rows: [],
                daily_rows: []
            },
            payment_types: [],
            sharedData: {
                types: [],
                mileageRates: [],
                dailyRates: [],
                additionalRates: []
            },
            date_range: {
                startDate: new Date,
                endDate: new Date,
                key: "selection"
            },
            entity_attachments: [],
            rowsNeedSaving: false,
            selectionErrors: {
                "customers_id": false,
                "projects_id": false,
                "time": false,
            },
            canCreateProject: true,
            autoCompleteData: {
                projects: [],
                quotes: [],
            },
            dataFetched: false,
            forceFirstQuoteRow: true,
            products: [],
            accounts: [],
            printLanguageOptions: [],
            printDateOptions: [],
            printLanguage: "en",
            printDateFormat: this.convertDateFormat(this.context.taimerAccount.companyDateFormat),
            defaultAccountingProducts: {
                default_mileage_allowance_product: {},
                default_daily_allowance_product: {},
                default_additional_allowance_product: {},
            },
            canAddMileage: false,
            saving: false,
            googleDrive: (this.context.addons.googledrive && this.context.addons?.googledrive?.used_by_companies.indexOf(this.context.userObject.companies_id) > -1),
            googleDriveAuthorized: false,
        };

        this.sharedData = {
            types: []
        };

        this.states = [];
        this.states[0] = { name: this.tr("Draft"), color: "#bbbbbb" };
        this.states[1] = { name: this.tr("Waiting"), color: "#ffb822" };
        this.states[2] = { name: this.tr("Approved"), color: colors.greenish_cyan };
        this.states[3] = { name: this.tr("Rejected"), color: "#f7548f" };
        this.states[4] = { name: this.tr("Archived"), color: "#bbbbbb" };

        this.inputProps = {
            fullWidth: true,
            onChange: this.onChange
        }

        this.inputPropsDataList = {
            fullWidth: true,
            onChange: this.onChangeDataList
        }

        this.targetingMap = [];

        this.expenseList = React.createRef();
        this.mileageList = React.createRef();
        this.allowanceList = React.createRef();
        this.upload = React.createRef();

        this.addRow = this.addRow.bind(this);
        this.calculateSums = this.calculateSums.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateComponentData = this.updateComponentData.bind(this);
        this.saveAsNew = this.saveAsNew.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);

        this.nextNewRowId = -1;

        try {
            const key = props.expenseType == 1 ? 'expense_ids' : 'travel_expense_ids'
            this.allIds = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
        } catch (ex) {
            this.allIds = [];
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(prevState.data, this.state.data) || !isEqual(prevState.sharedData, this.state.sharedData)) {
            this.setState({
                totals: this.calculateSums()
            });
        }
    }

    getExpenseFields = (companyId) => {
        let fields = [
            { field: "delete", name: "delete", header: "", width: 50, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "description", name: "description", header: this.tr("Description"), width: 250, resizeable: false, showMenu: false },
            { field: "type", name: "type", header: this.tr("Expense type"), width: 165, resizeable: false, showMenu: false },
            { field: "sum", name: "sum", header: this.tr("Sum"), width: 100, resizeable: false, showMenu: false },
            { field: "vat", name: "vat", header: this.tr("Vat %"), width: 100, resizeable: false, showMenu: false },
            { field: "total", name: "total", header: this.tr("Total"), width: 100, resizeable: false, showMenu: false },
        ]   

        if (this.context.addons && this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "ratio_id", name: "ratio_id", header: this.tr("Netvisor ratio"), width: 165, resizeable: false, showMenu: false });
            fields.push({ field: "attachments_id", name: "attachments_id", header: this.tr("Attachment"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.context.addons && this.context.addons.nav) {
            fields.push({ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.props.expenseType == 2 && this.context.addons && this.context.addons.procountor && this.context.addons.procountor.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_products_id", name: "accounting_products_id", header: this.tr("Product"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.context.addons && this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_accounts_id", name: "accounting_accounts_id", header: this.tr("Account"), width: 165, resizeable: false, showMenu: false });
        }

        return fields;
    }

    getMileageFields = (companyId) => {
        let fields = [
            { field: "mileage_context", name: "mileage_context", header: "", width: 50, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "mileage_allowance", name: "mileage_allowance", header: this.tr("Allowance"), width: 100, resizeable: false, showMenu: false },
            { field: "mileage_description", name: "mileage_description", header: this.tr("Description"), width: 100, resizeable: false, showMenu: false },
            { field: "Distance", name: "mileage", header: this.tr("Mileage"), width: 100, resizeable: false, showMenu: false },
            ... (this.context.addons && this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(companyId) > -1 ? [{field: "add_passenger_count", name: "add_passenger_count", header: this.tr("Additional passengers"), width: 100, resizeable: false, showMenu: false}] : []),
            
            { field: "price_per_distance", name: "price_per_distance", header: this.tr("Price per distance"), width: 100, resizeable: false, showMenu: false },
            { field: "mileage_total", name: "mileage_total", header: this.tr("Total"), width: 100, resizeable: false, showMenu: false },
        ];

        if (this.context.addons && this.context.addons.nav) {
            fields.push({ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.props.expenseType == 2 && this.context.addons && this.context.addons.procountor && this.context.addons.procountor.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_products_id", name: "accounting_products_id", header: this.tr("Product"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.context.addons && this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_accounts_id", name: "accounting_accounts_id", header: this.tr("Account"), width: 165, resizeable: false, showMenu: false });
        }

        return fields;
    }

    getDailyFields = (companyId) => {
        let fields = [
            { field: "daily_delete", name: "daily_delete", header: "", width: 50, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "daily_allowance", name: "daily_allowance", header: this.tr("Allowance"), width: 100, resizeable: false, showMenu: false },
            { field: "start_date", name: "start_date", header: this.tr("Start date"), width: 125, resizeable: false, showMenu: false },
            { field: "start_time", name: "start_time", header: this.tr("Start time"), width: 125, resizeable: false, showMenu: false },
            { field: "end_date", name: "end_date", header: this.tr("End date"), width: 125, resizeable: false, showMenu: false },
            { field: "end_time", name: "end_time", header: this.tr("End time"), width: 125, resizeable: false, showMenu: false },
            { field: "days", name: "days", header: this.tr("Days"), width: 75, resizeable: false, showMenu: false },
            { field: "daily_summary", name: "daily_summary", header: this.tr("Daily / partial"), width: 100, resizeable: false, showMenu: false },
            { field: "daily_total", name: "daily_total", header: this.tr("Total"), width: 100, resizeable: false, showMenu: false },
        ];

        if (this.context.addons && this.context.addons.nav) {
            fields.push({ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.props.expenseType == 2 && this.context.addons && this.context.addons.procountor && this.context.addons.procountor.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_products_id", name: "accounting_products_id", header: this.tr("Product"), width: 165, resizeable: false, showMenu: false });
        }

        if (this.context.addons && this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(companyId) > -1) {
            fields.push({ field: "accounting_accounts_id", name: "accounting_accounts_id", header: this.tr("Account"), width: 165, resizeable: false, showMenu: false });
        }

        return fields;
    }

    getDefaultPrintOptions = async (currentCompany) => {
        let options = {};
        options.printDateFormat = this.convertDateFormat(this.context.taimerAccount.companyDateFormat);

        try {
            const companies = await DataHandler.get({ url: `subjects/companies/worktrips/write+approve+approve_superior+approve_projectmanager+modify_all`, print_lang: 1, date_format: 1, country_lang: 1, print_options: 1 });
            const c = companies.find(company => company.id == currentCompany)
            options.printLanguage = c.print_lang ? c.print_lang : c.country_lang;
            options.printDateFormat = c.date_format || this.convertDateFormat(this.context.taimerAccount.companyDateFormat);
            options.printLanguageOptions = c.print_languages;
            options.printDateOptions = c.print_date_formats;
        } catch(err) {
            options.printLanguage = this.state.printLanguage;
            options.printLanguageOptions = this.state.printLanguageOptions;
            options.printDateOptions = this.state.printDateOptions;
        }

        return options;
    }

    async updateComponentData() {
        const { userObject, addons: { procountor } } = this.context;
        let { id, draftId } = this.props;

        if (draftId > 0 && (!id || id < 1))
            id = draftId;

        let params = { module: "purchase_expenses", id, companies_id: userObject.companies_id };
        let handler = data => {
            this.setState(data);
        };

        const customFields = await DataHandler.get({ url: `settings/company/${userObject.companies_id}/project/customfields` });
        const canCreateProject = customFields.find(x => x.required);
        this.setState({ canCreateProject });

        DataHandler.get({ url: `settings/company/cost_targeting_companies` }).done(data => {
            this.targetingMap = data;
        });        

        let currency_data = [];
        let currencies = [{label: this.context.taimerAccount.currency, value: "1,000000"}];

        if (this.props.expenseType == 1) {
            params.action = "get_purchase_expense";

        } else {
            params.action = "get_traveling_expense";
        }
        if(!id) {
            currency_data = await DataHandler.get({url: `settings/expenses/currencies/${userObject.companies_id}/${this.context.taimerAccount.currency}`}).then(response => response.currency_data);
            currency_data.forEach((data) => {
                currencies.push({label: data.name, value: data.rate});
            });
            this.setState({currencies});
        }
        
        DataHandler.get(params).done(async data => {
            if (data.error) {
                this.setState({ error: data.error });
                return;
            }

            if (draftId > 0) {
                data.expense.id = undefined;
                data.expense.state = 0;
                data.expense.avatar = this.context.userObject.avatar;
                data.expense.users_id = this.context.userObject.usersId;
                data.expense.fullname = this.context.userObject.fullname;
                data.expense.user_role = this.context.userObject.role;
                data.expense.color = this.context.userObject.color;
                data.expense.currency_rate = '1,000000';
                data.expense.currency_label = this.context.taimerAccount.currency;
                
                if (this.props.expenseType == 1) {
                    data.data.expense_rows.forEach((r, i) => {
                        data.data.expense_rows[i].purchase_expenses_id = -1;
                        data.data.expense_rows[i].isDraft = true;
                    });
                } else if (this.props.expenseType == 2) {
                    data.data.daily_rows.forEach((r, i) => {
                        data.data.daily_rows[i].traveling_expenses_id = -1;
                        data.data.daily_rows[i].isDraft = true;
                    });
                    data.data.expense_rows.forEach((r, i) => {
                        data.data.expense_rows[i].traveling_expenses_id = -1;
                        data.data.expense_rows[i].isDraft = true;
                        
                    });
                    data.data.mileage_rows.forEach((r, i) => {
                        data.data.mileage_rows[i].traveling_expenses_id = -1;
                        data.data.mileage_rows[i].isDraft = true;
                    });
                    data.data.additional_rows.forEach((r, i) => {
                        data.data.additional_rows[i].traveling_expenses_id = -1;
                        data.data.additional_rows[i].isDraft = true;
                    });
                }
            }

            DataHandler.get({url: `settings/expenses/currencies/${data.expense.companies_id}/${this.context.taimerAccount.currency}`}).done(response => {
                response.currency_data.forEach((data) => {
                    currencies.push({label: data.name, value: data.rate});
                });
                this.setState({currencies});
            });
           
            const printOptions = await this.getDefaultPrintOptions(data.expense.companies_id);

            const startDate = data.expense.startdate ? data.expense.startdate : new Date;

            this.setState({
                data: data.data,
                expense: data.expense,
                date_range: {
                    startDate,
                    endDate: data.expense.enddate ? data.expense.enddate : new Date,
                    key: "selection"
                },
                rowsNeedSaving: draftId > 0 ? true : false,
                printLanguage: printOptions.printLanguage,
                printDateFormat: printOptions.printDateFormat,
                printLanguageOptions: printOptions.printLanguageOptions,
                printDateOptions: printOptions.printDateOptions
            });

            this.getRates(startDate);
            this.getProducts();
            this.getAccounts();
        });

        if (!id) {
            this.getRates(new Date());
            this.getProducts();
            this.getAccounts();
        }

        const payment = await DataHandler.get({ url: 'expenses/payment_types', only_own_types: true, expenses_id: id });
        const types = await DataHandler.get({ url: 'expenses/custom_types', show_deleted: 'true', expenses_id: id });

        this.setState(state => ({
            payment_types: payment.payment_types,
            sharedData: {
                ...state.sharedData,
                // mileageRates: rates.mileage_rates,
                // additionalRates: rates.additional_rates,
                // dailyRates: rates.daily_rates,
                // ratios: rates.ratios,
                types: types.custom_types,
            },
            //dataFetched: true  //data will not be fetched and state.sharedData updated here yet
        }));

        this.getAttachments();

        if (!this.props.id && this.props.expenseType == 1 && !draftId)
            this.addNewRow("expense");

        DataHandler.get({ url: `expenses/autoCompleteData` }).done(
            autoCompleteData => {
                this.setState({ autoCompleteData });
            }
        );

        if (this.state.expense && this.state.expense.companies_id > 0) {
            this.getJobtypes(this.state.expense.companies_id);
        }
        else
            this.getJobtypes(this.context.userObject.companies_id);
    }

    getRates = async (date) => {
        
        if (typeof date === "string") {
            date = parse(date, 'YYYY-MM-DD', new Date);
        }
        const companyId = this.props.id > 0 ? this.state.expense.companies_id : this.context.userObject.companies_id;
        const rates = await DataHandler.get({ url: 'expenses/rates/'+companyId , show_deleted: 'true', year: format(date, 'YYYY')});

        rates.ratios.unshift({id: 0, deleted: 0, name: "-"});
       
        //We want to get the mileage rates that are not deleted and if such is found then allow adding mileage expense rows
        const active_mileage_rates = rates.mileage_rates.filter((rate) => {
            return rate.deleted === "0"; 
        });

        this.setState(state => ({
            sharedData: {
                ...state.sharedData,
                mileageRates: rates.mileage_rates?.filter(t => moment(t.enddate) >= moment(this.state.date_range.endDate)).filter(t => moment(t.startdate) <= moment(this.state.date_range.startDate)),
                additionalRates: rates.additional_rates?.filter(t => moment(t.startdate) <= moment(this.state.date_range.startDate)),
                // additionalRates: rates.additional_rates?.filter(t => moment(t.enddate) >= moment(this.state.date_range.endDate)).filter(t => moment(t.startdate) <= moment(this.state.date_range.startDate)),
                dailyRates: rates.daily_rates?.filter(t => moment(t.enddate) >= moment(this.state.date_range.endDate)).filter(t => moment(t.startdate) <= moment(this.state.date_range.startDate)),
                ratios: rates.ratios,
            },
            canAddMileage: active_mileage_rates.length > 0 ? true : false,
            dataFetched: true
        }));
        return rates;
    }

    getProducts = (newYear) => {
        if (newYear == "Invalid Date")
            newYear = format(new Date, "YYYY");

        const year = newYear ? newYear : (this.state.expense.startdate ? format(this.state.expense.startdate, "YYYY") : format(new Date, "YYYY"));
        const companyId = this.props.id > 0 ? this.state.expense.companies_id : this.context.userObject.companies_id;

        if (!companyId > 0)
            return;

        DataHandler.get({ url: `expenses/products/${companyId}/${year}` }).done(response => {
            this.setState({ products: response });
        });
        
    }

    getAccounts = () => {
        const companyId = this.props.id > 0 ? this.state.expense.companies_id : this.context.userObject.companies_id;

        if (!companyId > 0)
            return;

        DataHandler.get({ url: `expenses/accounts/${companyId}` }).done(response => {
            this.setState({ accounts: response });
        });
        
    }

    getAttachments() {
        const { id } = this.props;

        if (!id) return;

        const url = this.props.expenseType == 1 ? 'expenses/' + id + '/attachments' : 'expenses/' + id + '/attachments/traveling';

        DataHandler.request("GET", { url: url }).done(response => {
            response.forEach(e => {
                e.fileId = e.id
            });
            const filteredData = response.filter(file => {
                if (file.filesize == null || file.id == null || file.filesize == "0")
                    return false;
                else
                    return true;
            });
            this.setState({ entity_attachments: filteredData }, () => {
                if(this.state.googleDrive) this.checkGoogleDriveAttachments();
            });
        });
    }
    getJobtypes = async (company) => {
        const jobtypes = await DataHandler.get(
            {url: 'subjects/nav/worktypes/' + company},
        );
        this.setState({
            jobtypes: jobtypes,
        });
    }

    /* Google Drive related functionality - would be better if this would use TabAttachments also */

    checkGoogleDriveAttachments = () => {
        //First we check if user is authorized
        DataHandler.post({ url: `drive/google/connect` }, { company: this.props.company })
            .done(response => {
                if (response.authenticated){
                    if(this.props.expenseType == 1) this.checkIfHasAttachments('expenses');
                    else this.checkIfHasAttachments('travel-expenses');
                    this.setState({googleDriveAuthorized: true});
                } 
            })
            .fail(response => {
                console.log(response);
            });
    }

    checkIfHasAttachments = (type) => {
        
        const { id } = this.props;

        if (!id) return;

        DataHandler.post({ url: 'drive/google/getattachmentsforexpenses' }, { id: id, type: type })
            .done(response => {
                if (response.status !== 'no-attachments' && Object.keys(response.files).length > 0) {
                        this.setState({ entity_attachments: [...this.state.entity_attachments, ...response.files] });
                }
            })
            .fail(err => {
                console.log(err);
            });
    }

    deleteDriveFile = (fileId) => {
        const { enqueueSnackbar } = this.props;
        DataHandler.post({ url: 'drive/google/deletefile' }, { file_id: fileId })
            .done(response => {
                if (!response.error) {

                    enqueueSnackbar(this.tr('Attachment removed succesfully!'), {
                        variant: "success"
                    });

                } else {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }

    async expenseDataChanged(update, timeChanged = false) {
        const { id, enqueueSnackbar } = this.props;
        const { selectionErrors, expense } = this.state;
        
        if (update && update.startdate) {
            const rates = await this.getRates(update.startdate);
            const ratesFound = rates?.mileage_rates && rates?.mileage_rates?.length > 0;
            if (!ratesFound) {
                enqueueSnackbar(this.tr('Mileage and/or daily allowance rates not found for selected year'), {
                    variant: "error"
                });
                this.setState({
                    date_range: {
                        startDate: expense.startdate,
                        endDate: expense.enddate,
                        key: "selection"
                    }
                })
                return;
            }
            
            let newSharedData = cloneDeep(this.state.sharedData);
            ['additionalRates', 'dailyRates', 'mileageRates'].forEach(type => {
                newSharedData[type] = newSharedData[type].filter(t => moment(t.startdate) <= moment(update.startdate));
            });
            this.setState({sharedData: newSharedData});  

            this.getProducts(format(update.startdate, "YYYY"));
        }

        if (update && update.enddate) {
            let newSharedData = cloneDeep(this.state.sharedData);
            ['additionalRates', 'dailyRates', 'mileageRates'].forEach(type => {
                newSharedData[type] = newSharedData[type].filter(t => moment(t.enddate) >= moment(update.enddate));
            });
            this.setState({sharedData: newSharedData});
        }

        const data = { ...this.state.expense, ...update };
        this.saveChanges({ id, data });
        this.setState({ expense: data, selectionErrors: selectionErrors });

        if (!id && Object.keys(update).length > 0) {
            this.context.functions.setDirty(true);
        }
    }

    saveChanges(update) {
        let params;
        if (this.props.expenseType == 1)
            params = { module: "purchase_expenses", action: "set_purchase_expense" };
        else
            params = { module: "purchase_expenses", action: "set_traveling_expense" };


        const totals = this.calculateSums();
        update.data.subtotal = totals.subtotal;
        update.data.total = totals.total;

        update.id && DataHandler.post(params, update).then( () => {
            DataHandler.post({url: `projects/${update.data.projects_id}/updateValues`});
        } );
    }

    saveAsNew(state) { 
        const { id, updateView } = this.props;
        const { expense, rowsNeedSaving, selectionErrors, selectedCurrency } = this.state;

        // TR-550 Do we really need this?
        // if (!expense.customers_id) {
        //     selectionErrors.customers_id = true;
        //     this.setState({ selectionErrors });
        //     return;
        // }
        if (!expense.projects_id) {
            selectionErrors.projects_id = true;
            this.setState({ selectionErrors });
            return;
        }
        let params;
        if (this.props.expenseType == 1)
            params = { module: "purchase_expenses", action: "set_purchase_expense" };
        else
            params = { module: "purchase_expenses", action: "set_traveling_expense" };

        expense.state = state;

        const data = { ...expense }; 
        
        if (id)
            return;
        
        this.setState({saving: true},() => DataHandler.post(params, data).done(async response => {
            //this.context.mixpanel.track('Create Expense', {'Expense type': this.props.expenseType == 1 ? 'Purchase' : 'Travel'});
            //this.context.mixpanel.people.increment('# of expenses created');

            if (this.props.expenseType == 1) {
                if (rowsNeedSaving) {
                    params = {
                        module: "purchase_expenses",
                        action: "set_new_purchase_expense_rows",
                    };

                    let data = {
                        rows: this.expenseList.current.getVisibleRows().map(row => row.getData()),
                        id: response.data['id']
                    }

                    await DataHandler.post(params, data);
                }
                this.context.functions.setDirty(false);
                updateView({
                    "module": "worktrips",
                    "action": "modify",
                    "id": response.data['id'],
                    "expenseType": 1
                });

                DataHandler.post({url: `projects/${data.projects_id}/updateValues`});
            }
            else {
                if (rowsNeedSaving) {
                    params = { module: "purchase_expenses", action: "set_new_other_allowance_rows", };
                    const data = { rows: this.expenseList.current.getVisibleRows().map(row => row.getData()), id: response.data['id'] }
                    await DataHandler.post(params, data);

                    params = { module: "purchase_expenses", action: "set_new_mileage_allowance_rows", };
                    const mileageData = { rows: this.mileageList.current.getVisibleRows().map(row => row.getData()), id: response.data['id'] }
                    await DataHandler.post(params, mileageData);

                    params = { module: "purchase_expenses", action: "set_new_daily_allowance_rows", };
                    const dailyData = { rows: this.allowanceList.current.getVisibleRows().map(row => row.getData()), id: response.data['id'] }
                    await DataHandler.post(params, dailyData);
                }

                this.context.functions.setDirty(false);
                updateView({
                    "module": "worktrips",
                    "action": "modify",
                    "id": response.data['id'],
                    "expenseType": 2
                });

                DataHandler.post({url: `projects/${data.projects_id}/updateValues`});
            }
            this.setState({saving: false});
        }));
    }

    onChangeDataList = (e) => {
        const { selectionErrors, projects, expense } = this.state;

        let { label, id, type } = e;
        let update = { [type]: id };
        if (type === "customers_id") {
            update.projects_id = 0;
            update.subprojects_id = 0;

            selectionErrors.customers_id = false;
            this.setState({ selectionErrors });
        }

        if (type === "projects_id") {
            selectionErrors.projects_id = false;

            let project = projects.find(el => el.id == id);
            if (project) {
                update.billing = project.charge_traveling == 1 ? "1" : "-1";
            }

            this.setState({ selectionErrors });
        }

        this.expenseDataChanged(update);
    }

    onChange = (e) => {
        const { selectionErrors } = this.state;   
        if(e && !e.target){
            const { expense } = this.state;
            this.setState({
                project: e, expense: {
                    ...expense,
                    customers_id: e.customer,
                    projects_id: e.id,
                }
            });
        } else {
            let { name, value, checked } = e.target;
            if (name == "billing") {
                value = checked ? "1" : "-1";
            }
            
            let update = { [name]: value };
            
            if (name === "costestimate_id") {
                update.costestimate_row_id = 0;
            }
            
            this.expenseDataChanged(update);
        }  
    }

    handleSelect = (date) => {
        this.expenseDataChanged({ expense_date: format(date, "YYYY-MM-DD") });
    }

    handleInputChange = (dateType, date) => {
        if (dateType == "start") {
            this.expenseDataChanged({ expense_date: format(date, "YYYY-MM-DD") });
        } else {
            this.setState({
                date_range: {
                    startDate: this.state.date_range.startDate,
                    endDate: date,
                    key: "selection"
                }
            });
        }

        return this.state.date_range;
    }

    handleRangeSelect = (event) => {
        const rangeSelected = typeof event.selection.startDate === 'string' || typeof event.selection.endDate === 'string';
        this.setState({
            date_range: {
                startDate: format(event.selection.startDate, "YYYY-MM-DD"),
                endDate: format(event.selection.endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => {
            rangeSelected && this.expenseDataChanged({
                startdate: format(event.selection.startDate, "YYYY-MM-DD"),
                enddate: format(event.selection.endDate, "YYYY-MM-DD"),
            });
        });
    }

    handleRangeInputChange = (dateType, date) => {
        const startDate = dateType == "start" ? format(date, "YYYY-MM-DD") : this.state.date_range.startDate;
        const endDate = dateType == "start" ? this.state.date_range.endDate : format(date, "YYYY-MM-DD");
        this.setState({
            date_range: {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }
        }, () => {
            this.expenseDataChanged({
                startdate: startDate,
                enddate: endDate,
            });
        });
        return this.state.date_range;
    }

    addRow(target) {
        this.addNewRow(target);
    }

    addNewRow = (target) => {
        let arrayName = "";
        let newRow = {};
        const { addons: { procountor }, userObject } = this.context
        //const { defaultAccountingProducts: { default_daily_allowance_product, default_mileage_allowance_product, default_additional_allowance_product } } = this.state;
        let data = JSON.parse(JSON.stringify(this.state.data));
        const types = this.state.sharedData.types.filter(type => type.deleted < 1);

        if (target == "expense") {
            arrayName = "expense_rows";

            newRow = {
                id: String(this.nextNewRowId--),
                description: "",
                sum: 0,
               // vat: this.context.taimerAccount.defaultVat || 0,
                vat: types.length > 0 ? types[0].vat : 0,
                type: types.length > 0 ? types[0].id : 0,
                expenses_id: this.props.id,
                content_type: 3,
                last_edited_sum: "total"
            };
        } else if (target == "mileage" || target == "additional") {
            arrayName = "mileage_rows";
            newRow = {
                id: String(this.nextNewRowId--),
                expenses_id: this.props.id,
                content_type: 2
            }
        } else if (target == "allowance") {
            arrayName = "daily_rows";

            newRow = {
                id: String(this.nextNewRowId--),
                start_time: this.state.expense.startdate + " " + this.state.expense.starttime,
                end_time: this.state.expense.enddate + " " + this.state.expense.endtime,
                days: this.context.taimerAccount.countryCode === "FI" ? this.calculateDays() : '1',
                daily_allowances_id: Array.isArray(this.state.sharedData.dailyRates) && this.state.sharedData.dailyRates.length > 0 ? this.state.sharedData.dailyRates[0].id : undefined,
                expenses_id: this.props.id,
                content_type: 1,
                newRow: true
            }
        }

        data[arrayName].unshift(newRow);
        data[arrayName] = [...data[arrayName]];
        this.setState({ data });
    }

    addAdditionalAllowance = (parentId) => {
        const { addons: { procountor }, userObject } = this.context
        let data = JSON.parse(JSON.stringify(this.state.data));

        let newRow = {
            id: String(this.nextNewRowId--),
            is_additional_row: 1,
            mileage_allowance_id: parentId,
            expenses_id: this.props.id,
            content_type: 2
        };
        data['mileage_rows'].unshift(newRow);
        data['mileage_rows'] = [...data['mileage_rows']];
        this.setState({ data });
    }

    afterDelete = () => {
        setTimeout(() => {
            const totals = this.calculateSums();
            this.setState({totals: totals});            
        }, 40);
    }

    calculateSums() {
        const stateData   = cloneDeep(this.state.data);
        const expenseRows = Object.keys(stateData)
            .map(k => {
                if (k == 'additional_rows') {
                    const r = stateData[k].map(s => s.id);
                    if (stateData.mileage_rows.find(mr => r.includes(mr.id)))
                        return undefined;                    
                }
                return stateData[k];
            })
            .filter(f => f)
            .reduce((acc, cur) => [...acc, ...cur], []);

        if (!expenseRows.length) {
            return {
                subtotal: 0,
                vat: 0,
                total: 0
            };
        }

        const companyId = this.props.id > 0 ? this.state.expense.companies_id : this.context.userObject.companies_id;

        let totals = expenseRows.map(data => {
            if (!Number(data.vat))
                data.vat = 0;

            if (this.props.expenseType == 1) {
                return {
                    subtotal: (data.sum - 0),
                    vat: (data.vat / 100) * (data.sum - 0),
                    total: ((data.vat / 100) * data.sum - 0) + (data.sum - 0)
                };
            } else {
                let sum = 0;
                let vat = 0;
                let total = 0;
                if (data.content_type == 1) {
                    const dailyAllowance = this.state.sharedData.dailyRates.find(t => t.id == data.daily_allowances_id);
                    if (dailyAllowance)
                    {
                        sum = ((data.days - data.days % 1) * dailyAllowance.rate) + (data.days % 1 * 2 * dailyAllowance.part_rate);
                        total = sum;
                    }
                } else if (data.content_type == 2) {
                    let mileageAllowance = undefined
                    if (data.is_additional_row == 1) {
                        mileageAllowance = this.state.sharedData.additionalRates.find(t => t.id == data.target_id);
                    }
                    else {
                        mileageAllowance = this.state.sharedData.mileageRates.find(t => t.id == data.target_id);
                    }
                    let extraPassangerRate = 0;
                    if (this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(companyId) > -1) {
                        const xtraRate = this.state.sharedData.additionalRates.find(e => e.type == 1 && e.deleted != 1);
                        extraPassangerRate = xtraRate ? xtraRate.rate-0 : 0;               
                    }

                    let rate = 0;
                    if (mileageAllowance) {
                        rate = mileageAllowance.rate;
                        if (this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(companyId) > -1)
                            rate = data.add_passenger_count > 0 ? mileageAllowance.rate - 0 + (extraPassangerRate - 0) * (data.add_passenger_count - 0) : mileageAllowance.rate - 0;
                    }
                    let mileage = data.mileage;
                    if(!mileage) {
                        mileage = 0;
                    }
                    sum = rate * mileage;
                    total = sum;
                } else {
                    sum = data.sum - 0;
                    vat = (data.vat / 100) * (sum);
                    total = vat + sum;
                }
                return {
                    subtotal: sum,
                    vat: vat,
                    total: total
                };
            }
        }).reduce((acc, curr) => {
            return {
                subtotal: acc.subtotal + curr.subtotal,
                vat: acc.vat + curr.vat,
                total: acc.total + curr.total,
            };
        });

        return totals;
    }

    rowChanged = (row, notSaved, type) => {
        const { id } = this.props;
        const { dataFetched } = this.state;
        let data = cloneDeep(this.state.data);

        let arrayName = ""
        if (type == "expense")
            arrayName = "expense_rows";
        else if (type == "mileage" || type == "additional")
            arrayName = "mileage_rows";
        else if (type == "daily")
            arrayName = "daily_rows";

        const targetId = notSaved ? row.id : row.old_id;

        if (notSaved) {
            let index = data[arrayName].findIndex(e => e.id == targetId);

            if (index < 0) {
                data[arrayName].unshift(row);
            } else {
                data[arrayName][index] = row;
            }
            
            data[arrayName] = [...data[arrayName]];
            this.setState({ data });

            this.state.rowsNeedSaving = true;
        } else {
            let index = data[arrayName].findIndex(e => e.id == targetId);

            if (index < 0) {
                data[arrayName].unshift(row);
            } else {
                data[arrayName][index] = row;
            }

            data[arrayName] = [...data[arrayName]];

            this.expenseList.current.emptyNewData();

            if (this.props.expenseType == 2) {
                this.mileageList.current.emptyNewData();
                this.allowanceList.current.emptyNewData();
            }

            this.setState({ data });
        }

        if (!id) {
            const hasData = !!(row.description || row.sum > 0);

            if (hasData)
                this.context.functions.setDirty(true);
        }

        this.expenseDataChanged({});
    }

    deleteRow = (row, type) => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        if (type == "expense")
            data.expense_rows.splice(data.expense_rows.findIndex(e => e.id == row.id), 1);
        else if (type == "mileage")
            data.mileage_rows.splice(data.mileage_rows.findIndex(e => e.id == row.id), 1);
        else if (type == "daily")
            data.daily_rows.splice(data.daily_rows.findIndex(e => e.id == row.id), 1);

        this.setState({ data });
        this.afterDelete();
    };    

    updateExpenseState = state => {
        this.expenseDataChanged({ "state": state });
    }

    openExpense = id => {
        if (!id || id == 0) return;

        this.props.updateView({
            'module': 'worktrips',
            'action': 'modify',
            'expenseType': this.props.expenseType,
            'id': id
        })
    }

    onDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    }

    uploadFiles = (evt) => {
        const { id, enqueueSnackbar } = this.props;
        const { taimerAccount: { attachmentMaxSize } } = this.context;

        evt.stopPropagation();
        evt.preventDefault();

        const url = this.props.expenseType == 1 ? 'expenses/' + id + '/attachments' : 'expenses/' + id + '/attachments/traveling';

        const files = Array.from(evt.target.files || evt.dataTransfer.files);

        let totalSize = 0;

        files.forEach(function(file){
            totalSize += file.size;
        });

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        files.forEach(e => {
            DataHandler.file({ url: url }, e, undefined, e => {
                if (e.responseJSON === false) {
                    enqueueSnackbar(this.tr('Uploading file failed'), {
                        variant: "error"
                    });
                    return;
                }
                this.getAttachments();
            });
        });
    }

    openAttachment = (attachment) => {
        if(attachment?.locationtype === 'googledrive'){
            window.open(attachment?.file_url, "_blank");
        } else {
            DataHandler.getArrayBuffer({ url: `attachment/${attachment.fileId}`}).done((response, status, xhr) => {
                const type = xhr.getResponseHeader("content-type") || "";
                const filename = xhr.getResponseHeader("Content-Disposition").match(/filename=(.*)/)[1] || "file";
                const blob = new Blob([response], { type });
                FileSaver.saveAs(blob, filename);
            });
        }
    }

    openAttachmentDeleteDialog = (attachment) => {
        const data = {
            confirmFunc: () => {
                if(attachment?.locationtype === 'googledrive' && this.state.googleDriveAuthorized){
                    const { entity_attachments } = this.state;
                    this.deleteDriveFile(attachment.fileId);
                    entity_attachments.splice(entity_attachments.findIndex(e => e.fileId === attachment.fileId), 1);
                    this.setState({ entity_attachments });
                } 
                else this.deleteAttachment(attachment.fileId);

                this.closeDeleteDialog();
            },
            header: this.tr("Delete attachment?"),
            warning: this.tr("Are you sure you want to delete attachment: ${name}?", {name: attachment.filename})
        }

        this.setState({ deleteDialogOpen: true, deleteDialogData: data });
    }

    closeDeleteDialog = () => {
        this.setState({ deleteDialogOpen: false });
    }

    deleteAttachment = (id) => {
        const { entity_attachments } = this.state;

        DataHandler.delete({ url: 'attachments/' + id }).done(response => {
            entity_attachments.splice(entity_attachments.findIndex(e => e.fileId === id), 1);
            this.setState({ entity_attachments });
        });

        this.closeDeleteDialog();
    };

    print = async () => {
        let { expense } = this.state;
        let ids = expense.id;

		const key = this.props.enqueueSnackbar(this.tr("Generating expenses PDF-file..."), {
			variant: "info",
			persist: true
		});

		//let { checkedRows } = this.state;
		//let ids = checkedRows.map(el => el.id);
		const transl = new PurchaseExpenseTranslations().returnTranslations(['en']);
        const travelTransl = new TravelExpenseTranslations().returnTranslations(['en']);
        
        let date = this.state.date_range?.startDate || new Date();
        if (typeof date === "string") {
            date = parse(date, 'YYYY-MM-DD', new Date);
        }
        const year = format(date, 'YYYY');

		const parameters = {
			use_netvisor: this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(expense.companies_id) > -1 ? 1 : 0,
			module: this.props.expenseType == 1 ? "expenses" : "travel_expenses",
			purchase_expenses_id: ids,
			traveling_expenses_id: ids,
			showattachments: 1,
			company_id: expense.companies_id,
			pdf: 1,
			rtlang: this.state.printLanguage,
			auth: this.context.functions.getStorage().taimerToken,
            dateFormat: this.state.printDateFormat,
            year
		};

		DataHandler.postArrayBuffer({ url: 'expenses/print_pdf', ...parameters })
			.done((response) => {
				this.props.closeSnackbar(key);
				
				if (!response.error) {
					var blob = new Blob([response], {
						type: 'application/pdf'
					});
					FileSaver.saveAs(blob, parameters.module + '_' + format(new Date(), "YYYY-MM-DD") + '.pdf');
				}
				else {
					this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
						variant: "error",
					});
				}
			})
			.fail(err => {
				this.props.closeSnackbar(key);

				this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
					variant: "error",
				});
			})
    }
    
    convertDateFormat(format) {
        return format.replace('DD','%d')
            .replace('MM', '%m')
            .replace('YYYY', '%Y')
    }

    changeTime = (value, type) => {
        const { expense } = this.state;
        expense[type] = value;
        this.setState({ expense });
        this.expenseDataChanged(expense);
    }

    calculateDays = () => {
        const start = moment(this.state.expense.startdate + " " + this.state.expense.starttime).format('x');
        const end = moment(this.state.expense.enddate + " " + this.state.expense.endtime).millisecond('x');
        const hours = (end - start) / 3600000;
        const days = Math.floor(hours / 24);
        const remainingHours = hours - (days * 24);
        let total = days;

        if (days > 0) {
            if (remainingHours > 6)
                total++;
            else if (remainingHours > 2)
                total += 0.5;
        }
        else {
            if (remainingHours > 10)
                total++;
            else if (remainingHours > 6)
                total += 0.5;
        }

        return total;
    }

    /* changeRowsRate = (rate) => {
        const data = this.state;
        if(this.state.expense.id) {
            if(this.state.data.expense_rows && this.state.data.expense_rows.length > 0) {
                this.state.data.expense_rows.forEach((row, i) => {

                    //value, name
                    const { rowProps } = this.props;
                    const { userObject } = this.context;
                    let value = row.sum;
                    rate = (rate + "").replace(',', '.');
                    value = parseFloat(value) * (1 / parseFloat(rate));
                    row.sum = value;

                    let action = "";
                    if (row.type == 1) {
                        action = "set_purchase_expense_row";
                    } else {
                        action = "set_other_allowance_row";
                    }

                    let params = { module: "purchase_expenses", id: row.id, companies_id: userObject.companies_id, action: action };

                });
            }
        }
    } */

    forceSetFirstQuoteRow = (projectId) => {

        if (!projectId)
            return;

        const firstQuote = this.state.autoCompleteData.quotes.find(el => el.projects_id == projectId);
        const firstRow = (firstQuote && firstQuote.children.length > 0 && firstQuote.children[0].id) || 0;

        this.setState(state => {
            state.expense.costestimate_row_id = firstRow;
            return state;
        });
    }    

    rowDataChanged = (data) => {
        if (data.origin !== "propDataChange") return;

        const newData = data.data.find(e => Number(e.id) < 0);
       
        if (newData) {
            let target = "";
            let action = "";

            switch (newData.content_type) {
                case 1:
                    target = "daily";
                    action = "set_daily_allowance_row";
                    break;
                case 2:
                    target = "mileage";
                    action = "set_mileage_allowance_row";
                    break;
                default:
                    target = "expense";
                    action = this.props.expenseType == 1 ? "set_purchase_expense_row" : "set_other_allowance_row";
            }

            this.saveNewExpenseRow(newData, target, action);
        }
    }

    saveNewExpenseRow = (data, target, action) => {
        const { userObject } = this.context;

        let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: action };

        if (data.is_additional_row == 1) {
            target = "additional";
        }

        if (this.state.expense.id) {
            DataHandler.post(params, data).done(response => {
                data['id'] = response.data['id'];
                data['old_id'] = response.data['old_id'] || 0;

                if (data.target === "expense") data.cost = data.sum;

                this.rowChanged(data, false, target)
            });
        }
        else {
            this.rowChanged(data, true, target)
        }
    }

    renderPrintOptions = () => {
        return (
        <>
            <OutlinedField
                select
                label={this.tr("Print Language")}
                className="listFilterOutlinedField expense-print-options"
                value={this.state.printLanguage}>
                {
                    this.state.printLanguageOptions.map((opt) =>
                        <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printLanguage: opt.value })}>
                            {this.tr(opt.label)}
                        </MenuItem>
                    )
                }
            </OutlinedField>
            <OutlinedField
                select
                label={this.tr("Date Format")}
                className="listFilterOutlinedField expense-print-options"
                value={this.state.printDateFormat}>
                {
                    this.state.printDateOptions.map((opt) =>
                        <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                            {opt.label}
                        </MenuItem>
                    )
                }
            </OutlinedField>
        </>)
    }

    renderDeleteDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeDeleteDialog}
                onDialogSave={this.state.deleteDialogData.confirmFunc}
                dialogType={"delete"}
                dialogProps={{
                    confirmButtonText: this.state.deleteDialogData.confirmButtonText,
                    onCloseClick: this.closeDeleteDialog,
                    open: this.state.deleteDialogOpen,
                    close: this.closeDeleteDialog,
                    header: this.state.deleteDialogData.header,
                    warning: () => {
                        return this.state.deleteDialogData.warning;
                    },
                    onConfirm: () => {
                       this.state.deleteDialogData.confirmFunc();
                    }
                }}
            />
        )
    }

    _checkEnddate = (date) => {
        return moment(date) >= moment(this.state.date_range.endDate);
    }

    render() {
        if (this.state.error) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay}/>}</div>;
        }

        if (this.props.expenseType == 2 && (this.state.sharedData.mileageRates.length == 0 && this.state.sharedData.dailyRates.length == 0))
            if (this.state.dataFetched === true)
                return <ExpenseViewOverlay />
            else
                return <div><img className='main-page-loading-indicator' src={require('../dashboard/insights/img/loading.svg').default} /></div>

        const { expense, data, project, payment_types, entity_attachments, selectionErrors, sharedData, billing_type, autoCompleteData: { quotes }, currencies } = this.state;
        const { enqueueSnackbar} = this.props;
        const { taimerAccount, userObject, privileges, functions: { checkPrivilege, presentCurrency }, addons: { procountor } } = this.context;

        const modifyAll = checkPrivilege("worktrips", "modify_all");

        const rowProps = {
            listContainer: this.listContainer,
            handleDelete: this.deleteRow,
            listRef: this.expenseList,
            mileageListRef: this.mileageList,
            dailyListRef: this.allowanceList,
            rowChanged: this.rowChanged,
            expenseId: expense.id > 0 ? expense.id : this.props.id,
            editable: (expense.state < 2 && ((expense.users_id == userObject.usersId) || modifyAll)) || (modifyAll && expense.state == 2 && expense.bills_id == 0),
            expenseType: this.props.expenseType,
            addAdditionalAllowance: this.addAdditionalAllowance,
            afterDelete: this.afterDelete,
            currency_rate: this.state.expense.currency_rate,
            currency_label: this.state.expense.currency_label,
            enddateCheck: this._checkEnddate,
            enqueueSnackbar: this.props.enqueueSnackbar
        }

        const quotesFiltered = quotes.filter(row => row.projects_id == expense.projects_id);
        const quoteIds = quotesFiltered.map(el => el.id);

        const quote = quotes.find(x => x.id == expense.costestimate_id && quoteIds.indexOf(x.id) > -1);
        const quoteRowsFiltered = quote ? quote.children : [];
        
        let billableProject = false;

        if (project) {
            billableProject = project.charge_traveling == 1;
        }
        const types = sharedData.types.filter(type => type.deleted < 1);
        const newRow = {
            description: "",
            sum: 0,
            vat: taimerAccount.defaultVat || 0,
            type: types.length > 0 ? types[0].id : 0,
            expenses_id: this.props.id,
            content_type: 3
        };

        let newDailyRow = {
            start_time: this.state.expense.startdate + " " + this.state.expense.starttime,
            end_time: this.state.expense.enddate + " " + this.state.expense.endtime,
            days: taimerAccount.countryCode === "FI" ? this.calculateDays() : '1',
            expenses_id: this.props.id,
            content_type: 1,
            newRow: true,
        }

        let newMileageRow = {
            expenses_id: this.props.id,
            content_type: 2,
        }
        const currentIndex = this.allIds.indexOf(expense.id);
        const prev_id = this.allIds[currentIndex - 1] || 0
        const next_id = this.allIds[currentIndex + 1] || 0
        const { totals } = this.state;
        const start = moment(expense.startdate + " " + expense.starttime);
        const end = moment(expense.enddate + " " + expense.endtime);
        const showQuoteTargeting = this.targetingMap[userObject.companies_id] > 0;

        const companyId = this.props.id > 0 ? this.state.expense.companies_id : this.context.userObject.companies_id;

        return (
            <div className="taimer-view" id='expense-view'>
                <div id="top-bar">
                    <div id="button-container">
                        <div className="more-button" data-testid="expense-view-back-button">
                            <LeftArrow className="backButton" onClick={() => this.props.updateView(this.props.expenseType == 2 ? { module: 'costs', action: 'main', selectedTab: 'travel-expenses'} : { module: 'costs', action: 'main', selectedTab: 'expenses' })} />
                        </div>
                        {this.state.deleteDialogOpen && this.renderDeleteDialog()}

                        {this.props.id ? (
                            <React.Fragment>
                                {(
                                    (checkPrivilege("worktrips", "approve", expense.companies_id))
                                    || (expense.users_id == this.context.userObject.usersId)) && expense.state == 0 && <Button className="btn save" size="large" onClick={() => { this.updateExpenseState(1) }}>{this.tr("Save")}</Button>}
                                {(checkPrivilege("worktrips", "approve", expense.companies_id) || expense.is_subject) && (expense.state == 1 || expense.state == 3) && <Button className="btn approve" size="large"  data-testid="approve_expense_button" onClick={() => { this.updateExpenseState(2) }}>{this.tr("Approve")}</Button>}
                                {(checkPrivilege("worktrips", "approve", expense.companies_id) || expense.is_subject) && expense.state < 2 && <Button className="btn reject" size="large" onClick={() => { this.updateExpenseState(3) }}>{this.tr("Reject")}</Button>}
                                {(checkPrivilege("worktrips", "approve", expense.companies_id) || expense.is_subject) && expense.state != 4 && expense.state != 3 && <Button className="btn archive" size="large" onClick={() => { this.updateExpenseState(4) }}>{this.tr("Archive")}</Button>}
                                <Button className="btn print" size="large" onClick={() => { this.print() }}>{this.tr("Print")}</Button>
                                
                                {this.state.printLanguageOptions.length > 1 ? this.renderPrintOptions() : <OutlinedField
                                    select
                                    label={this.tr("Date Format")}
                                    className="listFilterOutlinedField expense-print-options"
                                    value={this.state.printDateFormat}>
                                    {
                                        this.state.printDateOptions.map((opt) =>
                                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                                {opt.label}
                                            </MenuItem>
                                        )
                                    }
                                </OutlinedField>}
                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <LoaderButton className="btn save" size="large" text={this.tr("Save Draft")} loading={this.state.saving} onClick={() => { this.saveAsNew(0) }}></LoaderButton>
                                    <LoaderButton className="btn save blue" size="large" text={this.tr("Save")} data-testid="save_expense_button" loading={this.state.saving} onClick={() => { this.saveAsNew(1) }}></LoaderButton>
                                </React.Fragment>
                            )}
                    </div>
                    {expense.id && <div id="state-bar">
                        <div className={(!prev_id > 0) ? "arrow prev disabled" : "arrow prev"}><LeftArrow fontSize="small" onClick={() => { this.openExpense(prev_id) }} /></div>
                        <StatusTag text={this.states[expense.state].name} color={this.states[expense.state].color} />
                        <div className="expense-id">
                            {this.props.expenseType == 1 && this.tr('Expense')} 
                            {this.props.expenseType == 2 && this.tr('Travel expense')} {expense.id}
                        </div>
                        <div className={(!next_id > 0) ? "arrow next disabled" : "arrow next"}><RightArrow fontSize="small" onClick={() => { this.openExpense(next_id) }} /></div>
                    </div>}
                </div>

                <div className="area left">
                    <div className="header">
                        {this.props.expenseType == 2 ? 
                            <h1 className="header">{this.tr("Travel expense details")}</h1> 
                            :
                            <h1 className="header">{this.tr("Expense details")}</h1>
                        }
                    </div>

                    <div className="user-info-container">
                        <TaimerAvatar
                            id={expense.users_id}
                            name={expense.fullname}
                            color={expense.color}
                            size="small"
                        />
                        <div className="user-info">
                            <div>{expense.fullname}</div>
                            <div>{expense.user_role}</div>
                        </div>
                    </div>

                    <ProjectTreeDropdown
                        name="project" // TODO: Onko tarpeellinen?
                        label={this.tr("Project")}
                        treeDropdownProps={{ growOptionContainer: true, showTooltip: true, resetOnClickaway: true, emptyValueOnClick: false }}
                        value={expense.projects_id}
                        disabled={!rowProps.editable}
                        disableAutoSelect
                        queryParameters={{right: 'read'}}
                        error={selectionErrors.customers_id || selectionErrors.projects_id}
                        noOptionsMessage={AddProject}
                        noOptionsMessageProps={{
                            selectProps: {
                                onItemCreated: this.onChange,
                            }
                        }}
                        onSelect={e => {
                            this.setState({
                                project: e, expense: {
                                    ...expense,
                                    customers_id: e.customer,
                                    projects_id: e.id,
                                }
                            }, () => {
                                const update = {
                                    customers_id: e.customer,  
                                    projects_id: e.id,
                                    billing:e.charge_traveling,
                                };
                                this.expenseDataChanged(update);
                                this.props.forceFirstQuoteRow && data && this.forceSetFirstQuoteRow(e.id);                                
                            });
                        }}
                    />

                    {showQuoteTargeting && quotesFiltered.length > 0 && <DataList
                        name="costestimate_id"
                        value={quote}
                        label={this.tr("Quotation")}
                        options={quotesFiltered.map(e => ({ id: e.id, label: e.name, type: 'costestimate_id' }))}
                        isDisabled={!rowProps.editable}
                        {...this.inputPropsDataList} />}

                    {showQuoteTargeting && quoteRowsFiltered.length > 0 && <TreeSelect
                        name="costestimate_row_id"
                        value={expense.costestimate_row_id}
                        label={this.tr("Quotation Row")}
                        onChange={this.onChange}
                        options={quoteRowsFiltered}
                        disabled={!rowProps.editable} />}

                    {this.props.expenseType == 1 &&
                        <OutlinedField className="basic-info" label={this.tr("Payment type")} name="payment_type_id" value={expense.payment_type_id} select disabled={!rowProps.editable} {...this.inputProps} >
                            {payment_types.map(row => (
                                <MenuItem data-testid={row.name} key={row.id} value={row.id}>{row.name}</MenuItem>
                            ))}
                        </OutlinedField>
                    }

                    <OutlinedField className="basic-info description" label={this.tr("Purpose of expense")} name="description" value={expense.description} multiline disabled={!rowProps.editable} inputProps={{ readOnly: !rowProps.editable }} {...this.inputProps} />

                    {/* Kululasku */}
                    {this.props.expenseType == 1 &&
                        <React.Fragment>
                            <DatePicker
                                className="basic-info date"
                                date={expense.expense_date}
                                onChange={this.handleSelect}
                                onInputChange={this.handleInputChange}
                                label={this.tr("Date")}
                                dateFormat={userObject.dateFormat}
                                disabled={!rowProps.editable}
                            />
                            {
                            this.state.currencies && this.state.currencies.length > 1 &&
                            <React.Fragment>
                                <DataList
                                    label={this.tr('Expenses currency')}
                                    className="basic-info expense-currency"
                                    options={this.state.currencies}
                                    value={{label: this.state.expense.currency_label, value: (this.state.expense.currency_value + "").replace(',', '.')}}
                                    onChange={(data) => {

                                        let expense = this.state.expense;
                                        let originalrate = this.state.expense.currency_rate;
                                        expense.currency_rate = data.value;
                                        expense.currency_label = data.label;

                                        let statedata = this.state.data;
                                        let params = { module: "purchase_expenses", id: data['id'], companies_id: userObject.companies_id, action: 'set_purchase_expense_row' };

                                        if(statedata.expense_rows.length > 0) {
                                            statedata.expense_rows.forEach((rowdata, i) => {

                                                rowdata.sum = (prepCalcValue(rowdata.sum) * (prepCalcValue(data.value) / prepCalcValue(originalrate))).toFixed(2);

                                                if(expense.id > 0) {
                                                    DataHandler.post(params, rowdata).done(response => {
                                                        data = response.data;
                                                        this.rowChanged(data, false, "expense")
                                                    });
                                                } else {
                                                    this.rowChanged(rowdata, true, "expense");
                                                }
                                            })
                                        }

                                        this.setState({expense});
                                        this.expenseDataChanged(expense);
                                    }}
                                />
                                <OutlinedField 
                                    className="basic-info expense-rate"
                                    label={this.tr("Rate")}
                                    name="rate"
                                    value={String(this.state.expense.currency_rate).replace(".",",") + " " + this.context.taimerAccount.currency}
                                    multiline
                                    disabled={false}
                                    inputProps={{ readOnly: true }} />
                            </React.Fragment>
                            }
                        </React.Fragment>
                    }

                    {/* Matkalasku */}
                    {this.props.expenseType == 2 &&
                        <React.Fragment>
                            <OutlinedField className="basic-info route" label={this.tr("Route")} name="route" value={expense.route} multiline disabled={!rowProps.editable} inputProps={{ readOnly: !rowProps.editable }} {...this.inputProps} />

                            <DateRangePicker
                                className="basic-info daterange"
                                ranges={[this.state.date_range]}
                                onChange={this.handleRangeSelect}
                                onInputChange={this.handleRangeInputChange}
                                label={this.tr("Date range")}
                                dateFormat={userObject.dateFormat}
                                maxDate={new Date(moment(this.state.date_range.startdate).endOf('year'))}
                                disabled={!rowProps.editable} />

                            <TimeRangePicker
                                end={end}
                                rootClass="time-range basic-info"
                                start={start}
                                onChange={this.changeTime}
                                editable={rowProps.editable} />

                        </React.Fragment>
                    }

                    <div className="invoicing-option">
                        {this.tr('Invoice customer')}
                        <Switch name="billing" color="primary" onChange={this.onChange} checked={expense.billing == 1} />
                    </div>

                    {this.props.id && (
                        <div className="attachments">
                            {rowProps.editable && <div className="add-file" onDragOver={this.onDragOver} onDrop={this.uploadFiles}>
                                <div>
                                    <CloudDownloadOutlined /><br />
                                    <span onClick={() => this.upload.current.click()}>{this.tr("Choose a file")}</span> {this.tr("or drag it here")}
                                    <input ref={this.upload} onChange={this.uploadFiles} type="file" multiple />
                                </div>
                            </div>}

                            <div className="attachmet-list">
                                {entity_attachments.map(row => (
                                    <div className="row" key={row.id}>
                                        <div className="name-cell" onClick={() => this.openAttachment(row)}>{row.filename}</div>
                                        {/*rowProps.editable*/ true && <div className="delete-cell"><DeleteIcon fontSize="small" onClick={() => this.openAttachmentDeleteDialog(row)} /></div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="area right" ref={cont => this.listContainer = cont} >
                    {this.props.expenseType == 2 &&
                        <div>
                            <div className="header">
                                <h1>{this.tr("Mileage allowances")}</h1>
                                {rowProps.editable && <Button
                                    data-testid="new_mileage_row_button"
                                    className="btn add-row"
                                    size="large"
                                    disabled={!this.state.canAddMileage}
                                    onClick={() => {
                                        if (this.state.sharedData.mileageRates.filter(r => r.deleted < 1).length < 1) {
                                            enqueueSnackbar(this.tr("Please add mileage allowance rate."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        this.addRow("mileage") 
                                    }}>
                                    {this.tr("Add mileage")}
                                </Button>}
                            </div>

                            <List
                                ref={this.mileageList}
                                sharedData={{...sharedData, jobtypes: this.state.jobtypes, products: this.state.products, accounts: this.state.accounts, companyId: companyId}}
                                fluid
                                className="mileageList"
                                height="285"
                                data={data.mileage_rows}
                                columns={this.getMileageFields(companyId)}
                                listRowType={MileageAllowanceRow}
                                rowProps={rowProps}
                                newRow={newMileageRow}
                                onDataChange={(data) => this.rowDataChanged(data)}
                                emptyNewDataOnUpdate={false}
                                //noStateData={true}
                            />

                            <div className="header">
                                <h1>{this.tr("Daily allowances")}</h1>
                                {rowProps.editable && <Button
                                    data-testid="new_allowance_row_button"
                                    className="btn add-row"
                                    size="large"
                                    onClick={() => { 
                                        if (this.state.sharedData.dailyRates.filter(r => r.deleted < 1).length < 1) {
                                            enqueueSnackbar(this.tr("Please add daily allowance rate."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        this.addRow("allowance") }}>
                                    {this.tr("Add allowance")}
                                </Button>}
                            </div>

                            <List
                                ref={this.allowanceList}
                                sharedData={{...sharedData, jobtypes: this.state.jobtypes, products: this.state.products, accounts: this.state.accounts, companyId: companyId}}
                                className="allowanceList"
                                fluid
                                height="285"
                                data={data.daily_rows}
                                columns={this.getDailyFields(companyId)}
                                listRowType={DailyAllowanceRow}
                                rowProps={rowProps}
                                newRow={newDailyRow}
                                onDataChange={(data) => this.rowDataChanged(data)}
                                emptyNewDataOnUpdate={false}
                            />
                        </div>
                    }

                    <div className="header">
                        <h1>{this.tr("Expenses")}</h1>
                        {
                            rowProps.editable && userObject.usersId ?
                                <LoaderButton
                                    className="btn add-row"
                                    size="large"
                                    text={this.tr("New row")}
                                    loading={!this.state.dataFetched}
                                    onClick={() => {
                                        if (!rowProps.editable) return;
                                        this.addRow("expense")
                                    }}
                                    data-testid={"new_expense_row_button"}
                                /> : undefined
                        }
                    </div>

                    <List
                        fluid
                        height="285"
                        ref={this.expenseList}
                        data={data.expense_rows}
                        columns={this.getExpenseFields(companyId)}
                        sharedData={{...sharedData, enqueueSnackbar, attachments: this.state.entity_attachments, jobtypes: this.state.jobtypes, products: this.state.products, accounts: this.state.accounts, companyId: companyId}}
                        className="expenseList"
                        listRowType={ExpenseRow}
                        rowProps={rowProps}
                        newRow={newRow}
                        onDataChange={(data) => this.rowDataChanged(data)}
                        emptyNewDataOnUpdate={false}
                    />

                    <div className="footer">
                        <div id="comments">

                        </div>
                        <div id="sum-info">
                            <div className="row subtotal">
                                <div className="row-name">
                                    {this.tr("Subtotal")}
                                </div>
                                {   !(!this.state.expense.currency_label || taimerAccount.currency === this.state.expense.currency_label) &&
                                    <div className="row-sum">
                                        {presentCurrency(totals && totals.subtotal != undefined ? calcRateValue(totals.subtotal, this.state.expense.currency_rate, false, true) : 0, this.state.expense.currency_label ? this.state.expense.currency_label: taimerAccount.currency)}
                                    </div>
                                }
                                <div className="row-sum">
                                    {presentCurrency(totals && (totals.subtotal != undefined && !isNaN(totals.subtotal)) ? totals.subtotal : 0, taimerAccount.currency)}
                                </div>
                            </div>
                            <div className="row vat">
                                <div className="row-name">
                                    {this.tr("VAT")}
                                </div>
                                {   !(!this.state.expense.currency_label || taimerAccount.currency === this.state.expense.currency_label) &&
                                    <div className="row-sum">
                                        {presentCurrency(totals && totals.vat != undefined ? calcRateValue(totals.vat, this.state.expense.currency_rate, false, true) : 0, this.state.expense.currency_label ? this.state.expense.currency_label : taimerAccount.currency)}
                                    </div>
                                }
                                <div className="row-sum">
                                    {presentCurrency(totals && (totals.vat != undefined && !isNaN(totals.vat)) ? totals.vat : 0, taimerAccount.currency)}
                                </div>
                            </div>
                            <div className="row total">
                                <div className="row-name">
                                    {this.tr("Total")}
                                </div>
                                {   !(!this.state.expense.currency_label || taimerAccount.currency === this.state.expense.currency_label) &&
                                    <div className="row-sum">
                                        {presentCurrency(totals && totals.total != undefined ? calcRateValue(totals.total, this.state.expense.currency_rate, false, true) : 0, this.state.expense.currency_label ? this.state.expense.currency_label : taimerAccount.currency)}
                                    </div>
                                }
                                <div className="row-sum" data-testid="expense-total">
                                    {presentCurrency(totals && (totals.total != undefined && !isNaN(totals.total)) ? totals.total : 0, taimerAccount.currency)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ExpenseView.defaultProps = {
    expenseType: 1, // 1 = purchase, 2 = travel
    sharedData: {
        mileageRates: [],
        additionalRates: [],
        dailyRates: []
    },
    forceFirstQuoteRow: true
};

export default withSnackbar(ExpenseView);
