import React from 'react';
import ReactDOM from 'react-dom';
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import List from '../list/List';
import ListRow from '../list/ListRow';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import './AccountingInfo.css';

class AccountingInfoRow extends ListRow {
    constructor(props) {
        super(props, {}, {}, "invoices/AccountingInfoRow");
    }

    render() {
        const className = "accountListRow listElement row";

        /*let purchaseAccount = -1;
        if (this.props.sharedData.purchaseAccounts && this.props.sharedData.purchaseAccounts.length > 1) {
            purchaseAccount = this.state.data.purchase_account > -1 ? this.props.sharedData.purchaseAccounts.find(e => e.id == this.state.data.purchase_account) : this.props.sharedData.purchaseAccounts[1];
        }*/

        const cells = {
            efina_product: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['efina_product'] + 'px'}}
                width={this.props.columnWidthMap['efina_product']}
                name="efina_product"
                //value={this.props.data.efina_product}
                value={{label: this.props.data.efina_product_label}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.efinaProducts}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.editRow("efina_product", value, this.props.data.id);
                }}
            />,
            efina_cost_pool: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['efina_cost_pool'] + 'px'}}
                width={this.props.columnWidthMap['efina_cost_pool']}
                name="efina_cost_pool"
                value={{label: this.props.data.efina_costpool_label}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.efinaCostPools}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.editRow("efina_cost_pool", value, this.props.data.id)
                }}
            />,
            accounting_product: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['accounting_product'] + 'px'}}
                width={this.props.columnWidthMap['accounting_product']}
                name="accounting_product"
                value={this.props.sharedData.accountingProducts ? this.props.sharedData.accountingProducts.find(e => e.id == this.state.data.accounting_product) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem accounting-product"
                autoCompleteData={this.props.sharedData.accountingProducts}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.maintainLocalState && this.setState({data: {...this.state.data, accounting_product: value.id}});
                    this.props.rowProps.editRow("accounting_product", value.id, this.props.data.id);
                }}
            />,
            product_register_product: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['product_register_product'] + 'px'}}
                width={this.props.columnWidthMap['product_register_product']}
                name="product_register_product"
                value={this.props.sharedData.productRegisterProducts ? this.props.sharedData.productRegisterProducts.find(e => e.id == this.state.data.product_register_product) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem product_register_product"
                autoCompleteData={this.props.sharedData.productRegisterProducts}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.maintainLocalState && this.setState({data: {...this.state.data, product_register_product: value.id}});
                    this.props.rowProps.editRow("product_register_product", value.id, this.props.data.id);
                }}
            />,
            dimension_item: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['dimension_item'] + 'px'}}
                width={this.props.columnWidthMap['dimension_item']}
                name="dimension_item"
                value={this.props.sharedData.dimensions ? this.props.sharedData.dimensions.find(e => e.id == this.state.data.dimension_item) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.dimensions}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.maintainLocalState && this.setState({data: {...this.state.data, dimension_item: value.id}});                     
                    this.props.rowProps.editRow("dimension_item", value.id, this.props.data.id)
                }}
            />,
            sales_account: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['sales_account'] + 'px'}}
                width={this.props.columnWidthMap['sales_account']}
                name="sales_account"
                value={this.props.sharedData.salesAccounts ? this.props.sharedData.salesAccounts.find(e => e.id == this.state.data.sales_account) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: true
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.salesAccounts}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {                   
                    if(value.integration && value.integration === "talenom") {
                        this.props.rowProps.maintainLocalState && this.setState({data: {...this.state.data, talenom_account: value.id}});                        
                        this.props.rowProps.editRow("talenom_account", {id: value.id, vat_value: value.vat_value}, this.props.data.id)
                    } else {
                        this.props.rowProps.maintainLocalState && this.setState({data: {...this.state.data, sales_account: value.id}});                       
                        this.props.rowProps.editRow("sales_account", value.id, this.props.data.id);
                    }
                }}
            />,
            purchase_account: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['purchase_account'] + 'px'}}
                width={this.props.columnWidthMap['purchase_account']}
                name="purchase_account"
                value={this.props.sharedData.purchaseAccounts ? this.props.sharedData.purchaseAccounts.find(e => e.id == this.state.data.purchase_account) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: this.props.rowProps.editable
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.purchaseAccounts}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.editRow("purchase_account", value.id, this.props.data.id)
                }}
            />,
            purchase_vatcode: <AutoCompleteCell
                style={{width: this.props.columnWidthMap['purchase_vatcode'] + 'px'}}
                width={this.props.columnWidthMap['purchase_vatcode']}
                name="purchase_vatcode"
                value={this.props.sharedData.purchaseVatCodes ? this.props.sharedData.purchaseVatCodes.find(e => e.id == this.state.data.purchase_vatcode) : {}}
                editable={this.props.rowProps.editable}
                listCellProps={{
                    editable: this.props.rowProps.editable,
                    inEditMode: this.props.rowProps.editable
                }}
                className="accountingItem"
                autoCompleteData={this.props.sharedData.purchaseVatCodes}
                searchable={true}
                menuPortalTarget={this.props.rowProps.container}
                addScrollX
                menuContainerStyle={{zIndex: 9000}}
                onEdited={value => {
                    this.props.rowProps.editRow("purchase_vatcode", value.id, this.props.data.id)
                }}
            />,
        }

        return (
            (this.props.data.deleted && this.props.data.deleted == 1) ? null
                : ((this.props.sharedData.type == "sales_invoices" && this.props.data.row_category == 1 || this.props.data.row_category == 3 || this.props.data.row_category == 4) || this.props.sharedData.type == "purchase_invoices") ? (
                    <React.Fragment>
                        <div className={this.props.data.total_marker ? className + " last-row" : className} style={{display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}>
                            {this.props.columnOrder.map(columnName => cells[columnName])}
                        </div>

                        {this.props.sharedData.descriptionRows.map(d => {
                            if (d.billentries_id == this.props.data.id && !d.deleted) {
                                return <div className={className} style={{display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}></div>
                            }
                        })}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={className + " header" + (this.props.data.isLast ? ' last-row' : '')} style={{display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}></div>
                        
                        {this.props.sharedData.descriptionRows.map(d => {
                            if (d.billentries_id == this.props.data.id && !d.deleted) {
                                return <div className={className} style={{display: "flex", height: !this.props.hidden ? "26px" : "0px", lineHeight: "26px" }}></div>
                            }
                        })}
                    </React.Fragment>
                ) 
		);
    }
}

class AccountingInfo extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/AccountingInfo");

        this.columnSettings = {showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false};

        this.columns = [
            { field: "efina_product", name: "efina_product", type: "sales_invoices", header: this.tr("eFina Product"), width: 150, usedIn: ['efina'], ...this.columnSettings },
            { field: "efina_cost_pool", name: "efina_cost_pool", type: "sales_invoices", header: this.tr("eFina Dimension"), width: 150, usedIn: ['efina'], ...this.columnSettings },
            { field: "accounting_product", name: "accounting_product", type: "sales_invoices", header: this.tr("Product"), width: 150, usedIn: ['netvisor', 'procountor', 'talenom', 'fennoa', 'fivaldi', 'venda', 'meritaktiva', "xero",], ...this.columnSettings },
            { field: "product_register_product", name: "product_register_product", type: "sales_invoices", header: this.tr("Product"), width: 150, usedIn: ['heeros',], ...this.columnSettings },
            { field: "dimension_item", name: "dimension_item", type: "sales_invoices", header: this.tr("Dimension"), width: 150, usedIn: ['netvisor', 'procountor', 'talenom', 'fennoa', 'wintime', 'emce_invoicing', 'heeros'], ...this.columnSettings },
            { field: "sales_account", name: "sales_account", type: "sales_invoices", header: this.tr("Account"), width: 150, usedIn: ['netvisor', 'procountor', 'emce', 'talenom', 'fennoa', 'wintime', "xero", 'heeros'], ...this.columnSettings },
            { field: "purchase_account", name: "purchase_account", type: "purchase_invoices", header: this.tr("Purchase account"), width: 200, usedIn: ['netvisor', 'heeros'], ...this.columnSettings },
            { field: "dimension_item", name: "dimension_item", type: "purchase_invoices", header: this.tr("Dimension"), width: 150, usedIn: ['netvisor', 'heeros'], ...this.columnSettings },
            { field: "purchase_vatcode", name: "purchase_vatcode", type: "purchase_invoices", header: this.tr("VAT Code"), width: 150, usedIn: ['heeros'], ...this.columnSettings },
        ];

        this.listColumns = [];
        Object.keys(this.context.addons).map((addon) => {
            this.columns.find(c => {
                if(c.usedIn.indexOf(addon) > -1 && !this.listColumns.includes(c) && c.type == this.props.type)
                    this.listColumns.push(c);
            })
        });
    }

    componentDidMount() {
        super.componentDidMount();
        this.selfNode = ReactDOM.findDOMNode(this);
    }

    render() {
        let invoiceRows = this.props.invoiceRows && this.props.invoiceRows.slice ? this.props.invoiceRows.slice() : [];
        let lastWasHeader = false;
        for(let i = 0; i < invoiceRows.length; i++) {
            invoiceRows[i].isLast = false;
            if(invoiceRows[i].row_category == 1 || invoiceRows[i].row_category == 3 || invoiceRows[i].row_category == 4) {
                lastWasHeader = false;
            } else {
                if(invoiceRows[i - 1]) {
                    invoiceRows[i - 1].isLast = lastWasHeader;
                }
                if(invoiceRows[i].total_marker > 0) {
                    lastWasHeader = true;
                }
            }
        }
        return (
            this.props.visible == 1 && (
                <div className="accountingInfo">
                    <List
                        fluid
                        height="auto"
                        rowHeight={this.props.rowHeight ? this.props.rowHeight : 26}
                        listRowType={AccountingInfoRow}
                        data={invoiceRows}
                        sharedData={{
                            type: this.props.type,
                            descriptionRows: this.props.descriptionRows,
                            ...this.props.accountingData
                        }}
                        rowProps={{
                            container: this.props.container == "self" ? this.selfNode : this.props.container,
                            editRow: this.props.editRow,
                            rowHeight: this.props.rowHeight ? this.props.rowHeight : 26,
                            editable: this.props.editable,
                            maintainLocalState: this.props.maintainLocalState
                        }}
                        columns={this.listColumns} 
                    />
                </div>
            )
        );
    }
}

AccountingInfo.defaultProps = {
    type: "sales_invoices",
    invoiceRows: [],
    descriptionRows: [],
    visible: 0
};

export default AccountingInfo;
