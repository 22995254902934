/* react */
import React from 'react';

/* css */
import './Efina.css';
import './Maventa.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import { MenuItem } from '@mui/material';

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class Fortnox extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Fortnox");

        this.state = {
            activated: 0,
            apikey: "",
            dialogData: undefined,
            purchaseInvoiceType: 0,
            creditCardPaymentType: 0,
            ownMoneyPaymentType: 0,
            paymentTypes: [],
            oldAuth: 0
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.invoiceTypes = [
            {value: 0, label: this.tr("No invoices")},
            {value: 1, label: this.tr("Fully paid")},
            {value: 2, label: this.tr("Unpaid")},
            {value: 3, label: this.tr("Unpaid and overdue")},
            {value: 4, label: this.tr("Unbooked")},
            {value: 5, label: this.tr("Payment pending")},
            {value: 6, label: this.tr("Waiting authorization pending")},
            {value: 7, label: this.tr("All invoices")},
        ];

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getPaymentTypes();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/fortnox`}).done(response => {
            this.setState(response);
        });
    }

    getPaymentTypes = () => {
        DataHandler.get({ url: 'expenses/payment_types', only_regular_types: true }).done(response => {
            this.setState({paymentTypes: response.payment_types});
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `settings/company/${this.props.company}/fortnox`}).done(this.back),
                text: this.tr("Do you want to deactivate Fortnox integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    authenticate = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/fortnox`}, this.state).done(resp => {
            if (resp.status == "ok") {
                this.props.enqueueSnackbar(this.tr("Taimer connected to Fortnox"), {
                    variant: "success",
                });	
                this.getAuthData();
            } else {
                this.props.enqueueSnackbar(this.tr("Unable to connect to Fortnox. Check the API key"), {
                    variant: "error",
                });	
            }
        });
    }

    authenticateOAuth = () => {
        if (this.state.url != "") 
            window.location = this.state.url;
    }

    getCustomers = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting customer numbers from Fortnox..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `fortnox/${this.props.company}/customers`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Customer numbers updated."), {
                variant: "success",
            });
        });
    }

    getAccounts = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting account plan from Fortnox..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `fortnox/${this.props.company}/accounts`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Account plan fetched"), {
                variant: "success",
            });
        });
    }

    changeInvoiceType = (type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/fortnox/purchase_invoice_type`}, {type: type}).done(() => {
            this.setState({purchaseInvoiceType: type});
        });
    };


    changePaymentType = (id, type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/fortnox/expense_payment_type`}, {id: id, type: type}).done(() => {
            if (type == 1) {
                this.setState({creditCardPaymentType: id});
            } else {
                this.setState({ownMoneyPaymentType: id});
            }
        });
    };
    
    render(){
        const { activated, username} = this.state;        
        const { tr } = this;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Fortnox Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                {this.state.oldAuth == 1 && (
                    <React.Fragment>
                    <div className="settings">
                        <h4>{tr("API key")}</h4>
                        <p>{tr("Put your API key that you can get from Fortnox here and press 'Authenticate' to connect Taimer to Fortnox")}</p>
                        <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("API key")} />
                    </div>

                    {/*<div className="settings">
                        <Button color="primary" onClick={this.authenticate} size="large">{tr("Authenticate")}</Button>
                    </div>*/}
                    </React.Fragment>
                )}

                {(activated != 1 && this.state.oldAuth == 0) && (
                    <div className="settings">
                        <Button color="primary" onClick={this.authenticateOAuth} size="large">{tr("Authenticate Oauth2")}</Button>
                    </div>
                )}

                {activated == 1 && (
                    <React.Fragment>
                        <div className="settings">
                            <h4>{tr("Purchase invoice type")}</h4>
                            <p>{tr("Select the status of purchase invoices that are fetched daily from Fortnox To Taimer")}</p>
                            <OutlinedField select disabled={false} label={this.tr("Invoice type")} name={"default"} value={this.state.purchaseInvoiceType} fullWidth >
                                {this.invoiceTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeInvoiceType(value)}>{label}</MenuItem>)}
                            </OutlinedField>
                        </div>

                        <div className="settings">
                            <h4>{tr("Update customer numbers")}</h4>
                            <p>{tr("Update customer numbers from Fortnox to Taimer")}</p>
                            <Button color="primary" onClick={this.getCustomers} size="large">{tr("Update")}</Button>
                        </div>

                        <div className="settings">
                            <h4>{tr("Get account plan")}</h4>
                            <p>{tr("Get account plan register from Fortnox to use it with expense row accounting")}</p>
                            <Button color="primary" onClick={this.getAccounts} size="large">{tr("Get account plan")}</Button>
                        </div>

                        <div className="settings">
                            <h4>{tr("Expense peyment types")}</h4>
                            <p>{tr("Select which expense payment type is for company credit card payments and which is for own money payments so Taimer can set right account numbers to the expenses when they are sent to Fortnox.")}</p>
                            
                            <h4>{this.tr("Company credit card")}</h4>
                            <OutlinedField select disabled={false} label={this.tr("Payment type")} name={"creditCardPaymentType"} value={this.state.creditCardPaymentType} fullWidth >
                                {this.state.paymentTypes.map(({id, name}) => <MenuItem key={id} value={id} onClick={() => this.changePaymentType(id, 1)}>{name}</MenuItem>)}
                            </OutlinedField>
                            
                            <h4>{this.tr("Own money")}</h4>
                            <OutlinedField select disabled={false} label={this.tr("Payment type")} name={"ownMoneyPaymentType"} value={this.state.ownMoneyPaymentType} fullWidth >
                                {this.state.paymentTypes.map(({id, name}) => <MenuItem key={id} value={id} onClick={() => this.changePaymentType(id, 2)}>{name}</MenuItem>)}
                            </OutlinedField>
                        </div>
                    </React.Fragment>
                )}

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
  
            </div>
        )
    }
}

export default Fortnox;