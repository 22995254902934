import React, { Component } from 'react'
import TaimerComponent from '../TaimerComponent';

import { SettingsContext } from '../SettingsContext';

import Notification from './Notification';
import DataHandler from '../general/DataHandler';

import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';

import _ from 'lodash';
import './Category.css';
import { active } from 'd3';

export class Category extends TaimerComponent {
    static defaultProps = {
        icon: "",
        header: "",
        type: "",
        data: [],
    }

    constructor(props, context) {
        super(props, context, "notifications/Category");
         
        this.state = {
            open: false,
        }

        this.localStorageOpen = {};
    }

    componentDidMount() {
        this.localStorageOpen = JSON.parse(localStorage.getItem("openNotifications"));
        const open = this.localStorageOpen[this.props.type] === "open" ? true : false;
        this.setState({open: open});
    }

    open = () => {
        this.setState({open: true});
        this.localStorageOpen[this.props.type] = "open";
        localStorage.setItem("openNotifications", JSON.stringify(this.localStorageOpen))
    }
    
    close = () => {
        this.setState({open: false});
        this.localStorageOpen[this.props.type] = "closed";
        localStorage.setItem("openNotifications", JSON.stringify(this.localStorageOpen))
    }

    clear = (seen = null) => {
        const { type } = this.props;
        const { userObject: { usersId }} = this.context;
        
        const params = {seen: seen, type: type, user: usersId };

        DataHandler.post({url: `notifications/clear`, ...params}).done(() => {
            this.props.notificationProps.refresh(true);
        });
    }

    render() {
        const { tr } = this;
        const { icon, header, data, type, notificationProps } = this.props;
        const { open } = this.state;
        let newCount = 0, seenCount = 0;
        this.props.data.forEach(notification => {
            if (notification.seen == 0)
                newCount++;
            else 
                seenCount++;
        });

        return (
            <div className="categoryMain">
                <div onClick={open ? this.close : this.open} className="category">
                    <div onClick={open ? this.close : this.open} className="categoryTitle">
                        <div onClick={open ? this.close : this.open} className={`iconContainer type${type}`}>
                            {icon}
                        </div>
                        <span onClick={open ? this.close : this.open} className="title">{header}</span>
                    </div>
                    <div className="categoryActions">
                        <div onClick={open ? this.close : this.open} className={`notificationCount ${newCount > 0 ? "active" : "inactive"}`}>
                            <span>{newCount > 0 ? newCount : seenCount}</span>
                        </div>
                        {open ? <UpIcon onClick={this.close} /> : <DownIcon onClick={this.open} />}
                    </div>
                </div>
                {open ? 
                    <div className="categoryFunctions">
                        <span onClick={() => {this.clear(1)}}>{tr("Clear read")}</span>
                        <span onClick={() => {this.clear()}}>{tr("Clear all")}</span>
                    </div> : undefined
                }
                {open ? 
                    _.map(data, notification => {
                        return (
                            <Notification
                                type={type} 
                                data={notification}
                                {...notificationProps}
                            />
                        )
                    })
                : undefined}
                <div className={`divider ${open ? "buffer" : ""}`} />
            </div>
        )
    }
}

export default Category
