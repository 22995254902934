import React from 'react';
import { SettingsContext } from './../SettingsContext';
import TaimerComponent from '../TaimerComponent';
import TaimerAvatar from "../general/TaimerAvatar";
import StatusTag from '../general/StatusTag';
import TextInputWithIcon from "../general/TextInputWithIcon";
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { format } from "date-fns";
import "./Log.css";

class Log extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "bills/log");

        this.refList = React.createRef();

        this.state = {
            message: this.props.message || ""
        }
    }

    sendMessage = () => {
        this.props.sendMessage && this.props.sendMessage(this.state.message);
        this.setState({ message: "" });
    }

    render() {
        const { users, statusLog, statusMap, id, className } = this.props;
        const { message } = this.state;
      
        return (
            <>
            <div id="log" className={className}>
            <div className="entryContainer" ref={this.logEntryContainer}>
                {users && users.length > 0 && statusLog && statusLog.map((logEntry, index) => {
                    let user = users.find(u => u.id == logEntry.users_id);
                    let name = user !== undefined ? `${user.lastname} ${user.firstname}` : "";
                    let targeted_user = null;
                    let targeted_name = "";

                    if (logEntry.target_id) {
                        targeted_user = users.find(v => v.id == logEntry.target_id);
                        targeted_name = targeted_user !== undefined ? `${targeted_user.lastname} ${targeted_user.firstname}` : "";
                    }

                    return (
                            <div className="logEntry">
                                <div className="row">
                                    <TaimerAvatar className="avatarBall" id={logEntry.users_id} name={name} noavatar color={user !== undefined ? user.color : "#FFFFFF"} />
                                    <div>
                                        <div className="top names"  style={{ float: "left" }}>
                                            <div className="bold targeted-name">{name} <ArrowRightAlt className={targeted_user ? "" : "not-visible"} />
                                            {targeted_user && 
                                                <div className="bold targeted-name">{targeted_name}</div>
                                            } 
                                            </div>
                                        </div>
                                        <div className="half top status-date" style={{ float: "right", textAlign: "right" }}>
                                            <StatusTag text={statusMap[logEntry.event].name} color={statusMap[logEntry.event].color} />
                                            <span>{format(logEntry.event_time, this.context.userObject.dateFormat)}</span>
                                        </div>
                                        <div className="half bottom message" style={{ float: "left" }}>
                                            <span>{logEntry.message}</span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                    );
                })}
            </div>
            {!this.props.disableMessage &&
                <div className="inputContainer">
                    <TextInputWithIcon
                        className="textInput"
                        value={message}
                        onChange={value => this.setState({ message: value })}
                        onEnter={() => this.sendMessage()}
                        onClick={() => this.sendMessage()}
                        placeholder={this.tr("Write a comment..")}>
                    </TextInputWithIcon>

                    {id === undefined || id < 0 ? <div id="overlay-messages"></div> : undefined}
                </div>
            }
            </div>
            </>
        )
    }
}

export default Log;
