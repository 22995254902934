import React from 'react';
import ReactSelect from 'react-select';
import _ from 'lodash';

import DataHandler from './../general/DataHandler';
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import OutlinedField from '../general/OutlinedField';
import TaimerComponent from '../TaimerComponent';
import SettingsGrid from '../settings/pages/SettingsGrid';
import ActivitySettingsTypeSelect from './elements/ActivitySettingsTypeSelect';

import { SettingsContext } from '../SettingsContext';
import Utils from "../general/Utils";

import { Switch } from "@mui/material";

//Activity icons 
import Email from '../settings/img/Activity_icons/Email.svg'
import Call from '../settings/img/Activity_icons/Phone.svg'
import Note from '../settings/img/Activity_icons/Note.svg'
import Meeting from '../settings/img/Activity_icons/Meeting3.svg'
import Online_meeting from '../settings/img/Activity_icons/Online_meeting.svg'
import Briefcase from '../settings/img/Activity_icons/Briefcase.svg'
import Contract from '../settings/img/Activity_icons/Contract.svg'
import CreditCard from '../settings/img/Activity_icons/CreditCard.svg'
import DocumentSend from '../settings/img/Activity_icons/DocumentSend.svg'
import Email2 from '../settings/img/Activity_icons/Email2.svg'
import HandShake from '../settings/img/Activity_icons/HandShake.svg'
import Invoice from '../settings/img/Activity_icons/Invoice.svg'
import Location from '../settings/img/Activity_icons/Location.svg'
import Meeting1 from '../settings/img/Activity_icons/Meeting1.svg'
import Meeting2 from '../settings/img/Activity_icons/Meeting2.svg'
import Office from '../settings/img/Activity_icons/Office.svg'
import Quote from '../settings/img/Activity_icons/Quote.svg'
import Star from '../settings/img/Activity_icons/Star.svg'
import ToDoList from '../settings/img/Activity_icons/ToDoList.svg'
import Travel from '../settings/img/Activity_icons/Travel.svg'
import VideoCall2 from '../settings/img/Activity_icons/VideoCall2.svg'

/* css */
import './ActivitySettingsDialog.css';

const iconItems = [
	{
		id: 'Meeting3.svg',
		name_fi: 'Kokous',
		name_se: 'Möte',
		name_en: 'Meeting',
		name_en_us: 'Meeting',		
		icon: Meeting,
		color:"#0f3c65"
	},
	{
		id: 'Online_meeting.svg',
		name_fi: 'Verkkopalaveri',
		name_se: 'Online-möte',
		name_en: 'Online_meeting',
		name_en_us: 'Online_meeting',		
		icon: Online_meeting,
		color:"#4c4ba8"
	},
	{
		id: 'Phone.svg',
		name_fi: 'Puhelu',
		name_se: 'Telefonsamtal',
		name_en: 'Call',
		name_en_us: 'Call',		
		icon: Call,
		color:"#335056"
	},	
	{
		id: 'Email.svg',
		name: 'Sähköposti',
		name_se: 'E-post',
		name_en: 'Email',
		name_en_us: 'email',
		icon: Email,
		color:"#42b677"
	},
	{
		id: 'Note.svg',
		name_fi: 'Huomio',
		name_se: 'Anteckningar',
		name_en: 'Note',
		name_en_us: 'Note',		
		icon: Note,
		color:"#fa7a1f"
	},
	{
		id: 'Briefcase.svg',
		// name: 'Salkku',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Briefcase,
		color:"#5584a9"
	},
	{
		id: 'Contract.svg',
		// name: 'Sopimus',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Contract,
		color:"#c36d7a"
	},
	{
		id: 'CreditCard.svg',
		// name: 'Luotokortti',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: CreditCard,
		color:"#9ec9c6"
	},
	{
		id: 'DocumentSend.svg',
		// name: 'Asiakirja lähetetty',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: DocumentSend,
		color:"#f7548f"
	},
	{
		id: 'Email2.svg',
		// name: 'Sähköposti2',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Email2,
		color:"#2589d7"
	},
	{
		id: 'HandShake.svg',
		// name: 'Kättely',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: HandShake,
		color:"#e181a5"
	},
	{
		id: 'Invoice.svg',
		// name: 'Lasku',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Invoice,
		color:"#127658"
	},
	{
		id: 'Location.svg',
		// name: 'Sijainti',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Location,
		color:"#ff5722"
	},
	{
		id: 'Meeting1.svg',
		// name: 'Kokous2',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Meeting1,
		color:"#2d9ff7"
	},
	{
		id: 'Meeting2.svg',
		// name: 'Kokous3',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',	
		icon: Meeting2,
		color:"#9a7bb1"
	},
	{
		id: 'Office.svg',
		// name: 'Toimisto',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Office,
		color:"#7ba8f9"
	},
	{
		id: 'Quote.svg',
		// name: 'Lainaus',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Quote,
		color:"#4b658f"
	},
	{
		id: 'Star.svg',
		// name: 'Tähti',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Star,
		color:"#f5a623"
	},
	{
		id: 'ToDoList.svg',
		// name: 'Tehtävälistä',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: ToDoList,
		color:"#d4890d"
	},
	{
		id: 'Travel.svg',
		// name: 'Matka',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: Travel,
		color:"#f7548f"
	},
	{
		id: 'VideoCall2.svg',
		// name: 'Videopuhelu',
		// name_se: '',
		// name_en: '',
		// name_en_us: '',		
		icon: VideoCall2,
		color:"#516ebd"
	}
];


export default class ActivitySettingsDialog extends TaimerComponent {
	
	static contextType = SettingsContext;

	constructor(props, context)
	{
		super(props, context, "dialogs/ActivitySettingsDialog");
		
		const { userObject } = context;
		this.defaultNameField = Utils.getNameFieldByUserLang(userObject.language, 'name');

		const {dialogData} = props;

		this.state = {
			id: dialogData.id || -1,
			names: {
				name: dialogData.name || '',
				name_fi: dialogData.name_fi || '',
				name_se: dialogData.name_se || '',
				name_en: dialogData.name_en || '',
				name_en_us: dialogData.name_en_us || '',				
			},
			is_default: dialogData.is_default || 0,
			icon: dialogData.icon || undefined,
			nameEdited: false,
		}
	}

	onIconChange = e => {
		const id = e.target.value;
		const item = iconItems.find(i => i.id == id);
		this.setState(state => {
			state.old_icon_set = 0;
			state.icon = id;
			// if (state.id < 0 && !this.state.nameEdited)
			// 	Object.keys(state.names).forEach(k => {
			// 		state.names[k] = item[k] || "";
			// 	});
			return state;
		});
	}

	onNameChange = (e) => {
		this.setState({nameEdited: true, names: {...this.state.names, name: e.target.value}});
	}	

	render() {
		const { userObject: { usersId }, functions: { checkPrivilege } } = this.context;
		const { onClose, onSave } = this.props;
		const { id, is_default, names, icon } = this.state;

		const saveObject = {..._.omit(this.state,'names'), ...names};

		if (saveObject.old_icon_set)
			delete saveObject.icon;

		return (
			<DialogBorder
				id="ActivitySettingsDialog"
				title={this.tr("Add activity type")}
				onClose={onClose}
				onSave={() => onSave(saveObject)}
				disableSave={!icon || !names.name}
				className="TMRDialogGrid TMRDialogInputs" >
				<DialogSection>
					<OutlinedField
						className="full"
						label={this.tr("Activity type")}
						name="name"
						value={names.name}
						onChange={this.onNameChange}
					/>
					<h3>{this.tr('Choose Icon')}</h3>
					<ActivitySettingsTypeSelect
						className="full"
						name="iconItems"
						items={iconItems}
						value={icon} 
						onChange={this.onIconChange} />

					<div className="setAsDefault">
                        <span>{this.tr('Set as default')}</span>
                    <div>
					</div>  
						<Switch
							name="is_default"
							color="primary"
							className="Default"
							disableRipple={true}
							onChange={() => {
							    this.setState({is_default: !is_default});
							}}
							checked={is_default > 0} />
                    </div>

				</DialogSection>
			</DialogBorder>
		)
	}
}
