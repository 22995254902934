import React from 'react';
import $ from 'jquery';
import _, { debounce, throttle, cloneDeep, isEqual } from 'lodash';

import TaimerComponent from './TaimerComponent';

import { SnackbarProvider, useSnackbar } from 'notistack';

/* local components */
import TaimerNavi from './navigation/TaimerNavi';
import Login from './login/Login';
import ProjectView from './projects/ProjectView';
import ProjectKanban from './projects/ProjectKanban';
import ProjectList from './list/lists/ProjectList';
import ContactView from './contacts/ContactView';
import AccountView from './accounts/AccountView';
import MailLogin from './mail/MailLogin';
import OnedriveLogin from './onedrive/OnedriveLogin';
import ExpenseView from './expenses/ExpenseView';
import TeamChat from './team-chat/TeamChat';
import Notifications from './notifications/Notifications';
import Settings from './settings/Settings';
import ReportsView from './reports/ReportsView';
import UserView from './users/UserView';
import InvoiceView from './invoices/InvoiceView';
import CollaborateView from './collaborate/CollaborateView';
import BuyTaimer from './general/BuyTaimer';
import OrderView from './onboarding/OrderView';
import PurchaseOrderView from './bills/PurchaseOrderView';
import ReceivedInvoiceView from './bills/ReceivedInvoiceView';
import QuotePrint from './general/QuotePrint';
import ActivitiesInsight from './dashboard/insights/activities/ActivitiesInsight';
import TaimerCalendarLogin from './taimercalendar/TaimerCalendarLogin';
import ErrorBoundary from './error/ErrorBoundary';
import OnboardingView from './onboarding/OnboardingView';
import PersonalNote from './general/PersonalNote';
import InsightsView from './dashboard/insights/InsightsView';

import notificationIcon from './notification-icon.png';

/* context */
import { SettingsContext } from './SettingsContext';

import 'moment/min/locales.min'
import moment from "moment";

// Date-fns loacles
import { enUS, enGB, sv, es, pt, it, lt, nn } from 'date-fns/locale';
//import da from 'date-fns/locale/da';
import fi from 'date-fns/locale/fi';

import { getMonth, addYears, startOfYear, addMonths, endOfMonth, format, parse } from 'date-fns';

/* mui theme */
import { TaimerTheme } from './TaimerTheme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import './index.css';
import DataHandler from './general/DataHandler';
import Cacher from "./general/Cacher";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from "@mui/icons-material/Warning";
import Button from '@mui/material/Button';

/* Stripe */
import { Elements, StripeProvider } from 'react-stripe-elements';

/* Mixpanel */
//import mixpanel from 'mixpanel-browser';

import ProfitLoss from './charts/ProfitLoss';
import InvoicingInsight from './dashboard/insights/invoicing/InvoicingInsight';
import HoursInsight from './dashboard/insights/hours/HoursInsight';
import SalesInsight from './dashboard/insights/sales/SalesInsight';
import GoalsInsight from './dashboard/insights/goals/GoalsInsight';
import Contacts from './navigation/pages/Contacts';
import TimeManagement from './navigation/pages/TimeManagement';
import Invoices from './navigation/pages/Invoices';
import Costs from './navigation/pages/Costs';
import Products from './navigation/pages/Products';
import EmptyInvoiceConfirmationDialog from "./invoices/dialogs/EmptyInvoiceConfirmationDialog";
import { AnimatedSlider } from './general/Slider';
import AddAccountSlider from './general/AddAccountSlider';
import AddProjectSlider from './general/AddProjectSlider';
import AddContactSlider from './general/AddContactSlider';
import AddUserSlider from './general/AddUserSlider';
import AddActivitySlider from './general/AddActivitySlider';
import { Close, ThumbUp } from '@mui/icons-material';
import AddHoursSlider from './general/AddHoursSlider';
import AddResourceSlider from './general/AddResourceSlider';
import PageNotFound from './general/PageNotFound';
import ResourcePlanning from './navigation/pages/ResourcePlanning';
import Dashboard from './navigation/pages/Dashboard';
import CoreDialog from './dialogs/mass_operations/CoreDialog';


const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC;

let globalContext = {};

const CloseSnackbarButton = (props) => {
    const { closeSnackbar } = useSnackbar();
    const { key } = props;
    return <Button className="close-snackbar-button" onClick={() => closeSnackbar(key)}><Close /></Button>;
}

export function exportContext() {
    return globalContext;
};
class Taimer extends TaimerComponent {
    static contextType = SettingsContext;
    settings = {
        showMenuSwitcher: false,
        build: "",
        userObject: {
            language: 'en-GB',
            calendarLocale: 'en-US',
            dateFormat: "YYYY-MM-DD",
            timeFormat: "LT",
            projects: 0,
            accounts: 0,
            sidebarStyle: 0,
            accountlistDefaultUnitFilter: "-100",
            disable_user_balance: false,
        },
        taimerAccount: {
            numberFormat: 'fi-FI',
            currency: 'EUR',
            symbolPosition: 'end', //'start'|'end'
            symbol: '€',
            distanceUnit: 'km',
            financialYearStart: 1,
            ourCompanyLang: 'en', //selected company language
            companyPrintLang: 'en',
            companyDateFormat: "DD.MM.YYYY",
            origin: 'onboarding',
            defaultVat: 24,
            projectInvoiceSettings: [],
            defaulttermsofpayment: 0,
            defaultannotation: 0,
            defaultpenaltyinterest: 0,
            attachmentMaxSize: 1,
        },
        calendar: {
            clock: 0, // 0 = 12hr, 1 = 24hr clock
            startOfWeek: 0, // 0 sunday, 1 monday
            locale: enUS, // locale for date-fns
            localeName: 'en-US'
        },
        timeTracker: {
            workhour_accuracy: 15,
            bulkentry_interval: 15,
            slots: 4,
            startTime: [4, 0, 0],
            endTime: [22, 0, 0],
            resourcingEnabled: true,
        },
        privileges: {},
        fakePrivileges: false,
        taimerVersion: "free",
        versionId: "1",
        addons: {},
        allAddons: {},
        functions: {
        }
    };

    state = {
        buyTaimerOpen: false,
    }
    
    lastCompany = false;
    isDirty = false;
    dirtyHandler = false;
    unsavedDialogData = {};
    previousView = undefined;

    constructor(props, context) {
        super(props, context,  "Taimer");
        
        this.cacher = new Cacher();
        this.onUnloadListeners = [];

        this.updateView = this.updateView.bind(this);
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.parseLocation = this.parseLocation.bind(this);
        ["whoami", "urlify", "setTitle", "setTokenExpiration", "sendAnalytics"].forEach(e => this[e] = this[e].bind(this));

        this.settings.functions.setOverrideHeaderTitles = this.setOverrideHeaderTitles;
        this.settings.functions.updateView = this.updateView;
        this.settings.functions.setOverlayComponent = this.setOverlayComponent;
        this.settings.functions.setAdditionalHeaders = this.setAdditionalHeaders;
        this.settings.functions.openActivitySlider = this.openActivitySlider;
        this.settings.functions.addAccount = this.addAccount;
        this.settings.functions.setContextItem = this.setContextItem;
        this.settings.functions.addProject = this.addProject;
        this.settings.functions.addContact = this.addContact;
        this.settings.functions.addUser = this.addUser;
        this.settings.functions.addInvoice = this.addInvoice;
        this.settings.functions.addHours = this.addHours;
        this.settings.functions.addResource = this.addResource;
        this.settings.functions.showDialogContent = this.showDialogContent;
        this.settings.functions.showDialog = this.showDialog;
        this.settings.functions.showConfirmationDialog = this.showConfirmationDialog;
        this.settings.functions.closeDialog = this.closeDialog;
        this.settings.functions.showSliderContent = this.showSliderContent;
        this.settings.functions.showSlider = this.showSlider;
        this.settings.functions.closeSlider = this.closeSlider;
        this.settings.functions.checkPrivilege = this.checkPrivilege;
        this.settings.functions.checkPrivilegeAny = this.checkPrivilegeAny;
        this.settings.functions.hasPrivilege = this.hasPrivilege;
        this.settings.functions.getCompany = this.getCompany;
        this.settings.functions.getPreferedCompany = this.getPreferedCompany;
        this.settings.functions.getViewProps = this.getViewProps;
        this.settings.functions.setDirty = this.setDirty;
        this.settings.functions.openWorkhourDialog = this.openWorkhourDialog;
        this.settings.functions.whoami = this.whoami;
        this.settings.functions.getFinancialYear = this.getFinancialYear;
        this.settings.functions.toggleBuyDialog = this.toggleBuyDialog;
        this.settings.functions.presentCurrency = this.presentCurrency;
        this.settings.functions.showNotification = this.showNotification;
        this.settings.functions.openTeamChat = this.openTeamChat;
        this.settings.functions.startWorkhourTimer = this.startWorkhourTimer;
        this.settings.functions.stopWorkhourTimer = this.stopWorkhourTimer;
        this.settings.functions.getWorkhourTimers = this.getWorkhourTimers;
        this.settings.functions.getWorkhourTimer = this.getWorkhourTimer;
        this.settings.functions.urlify = this.urlify;
        this.settings.functions.setTitle = this.setTitle;
        this.settings.functions.getStorage = this.getStorage;
        this.settings.functions.setTokenExpiration = this.setTokenExpiration;
        this.settings.functions.setSidebarStyle = this.setSidebarStyle;
        this.settings.functions.setLastCompany = this.setLastCompany;
        this.settings.functions.getTimeTrackerSettings = this.getTimeTrackerSettings;
        this.settings.functions.refreshTimeTrackerSettings = this.refreshTimeTrackerSettings;
        this.settings.functions.getDownloadPath = this.getDownloadPath;
        this.settings.functions.sendAnalytics = this.sendAnalytics;
        this.settings.functions.getFnsLocales = this.getFnsLocales;
        this.settings.functions.companyUsesAddOn = this.companyUsesAddOn;
        this.settings.functions.unregisterOnUnloadListeners = this.unregisterOnUnloadListeners;
        this.settings.functions.registerOnUnloadListener = this.registerOnUnloadListener;
        this.settings.functions.emptyCacheOnUnload = this.emptyCacheOnUnload;

        this.settings.cacher = this.cacher;

        let parsedLocation = this.parseLocation();
        
        this.state = {
            view : {
                module: "login",
                action: "login",
                tabletMode: 'Off',
                ...parsedLocation,
                selectedPage: parsedLocation && parsedLocation.module && parsedLocation.action ? `${parsedLocation['module']}-${parsedLocation['action']}` : "login-login",
            },
            useAppcues: true,
            initial: true,
            settingsLoaded: false,
            unsavedWarningOpen: false,
            workhourTimers: [],
            additionalHeaders: [],
        };

        this.refTaimerNavi = React.createRef();
        this.refTeamChat = React.createRef();
        this.refCurrentPage = React.createRef();
        this.refNotifications = React.createRef();
        this.refPersonalNote = React.createRef();
        this.refErrorBoundary = React.createRef();
        this.currentViewRef = React.createRef();

        this.planNames = [
            "Free",
            "CRM",
            "PRM",
            "ERP"
        ];
 
        const isOdin = this.settings.isOdin = window.location.href.indexOf(':8080') > -1;
        const tokenData = this.parseTokenData();
        const currentFolder = window.location.href.split("/")[3];
     
        if (tokenData && this.state.view.selectedPage !== 'onboarding-view') {
            if (this.state.view.selectedPage === 'login-login' && !currentFolder && Number(tokenData.exp) > moment().unix()) {
                //user doesn't have a correct url and session is still valid

                this.state.view.module = 'dashboard';
                this.state.view.action = 'main';
                this.state.view.selectedTab = 'my-day';
                this.state.view.selectedPage = 'dashboard-main';

                window.history.pushState({}, "Taimer", `${isOdin ? '' : `/${tokenData.folder}/`}index.php?module=dashboard&action=main&selectedTab=my-day`);
            }
            else if (!isOdin && currentFolder !== tokenData.folder && !this._isPrintService()) {
                //user has changed the folder manually in the address bar
                localStorage.logout = "folder has changed, logout \n";
                this.forceLogOut();
            }
        }
    }
    
    getDownloadPath = () => { return window.location.href.indexOf(":8080") > -1 ? "react/api/attachment?" : "/react/api/attachment?" }

    parseTokenData = () => {
        const token = this.getStorage().taimerToken;
        
        if (token && token.length > 50) {
            //user has already logged in
            try {
                const jwt = this.parseJwt();

                return jwt;
            }catch (e) {
                console.log(e, "PARSE TOKEN DATA ERROR: ", e);
                return {}
            }
        }
        
        return {};
    }

    forceLogOut = () => {
        sessionStorage.clear();
        localStorage.setItem('taimerToken', "");
        localStorage.setItem('taimerPrivileges', "");
        localStorage.setItem('taimerVersion', "");
        localStorage.setItem('taimerVersionId', "");
        localStorage.setItem('taimerAddons', "");
        localStorage.setItem('onboardingEnded', "");
        localStorage.setItem('lastView', "");
        localStorage.setItem('taimerTokenIssued', "");
        localStorage.setItem('taimerTokenExpires', "");
        localStorage.setItem('taimerTokenRefresh', "");
        localStorage.setItem('lastCompany', "0");
        clearTimeout(this.activityTimer);
        
        //this.context.mixpanel.reset();
        DataHandler.post({url: "auth/logout_saml"})
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
        
        if (this.state.view.selectedPage === 'login-login')
            return;
        
        const currentFolder = window.location.href.split("/")[3];
        window.location = `${!currentFolder || this.settings.isOdin ? '' : `/${currentFolder}/`}index.php?module=login&action=login`;
    }
    
    setTokenExpiration() {
        const tokenData = this.parseTokenData();
        const storage = this.getStorage();
        const time = moment().unix();
        const expireTime = tokenData.exp - tokenData.iat;
        storage.setItem('taimerTokenIssued', time);
        storage.setItem('taimerTokenExpires', time + expireTime);
        storage.setItem('taimerTokenRefresh', time + (expireTime / 2));
        storage.setItem("lastActivityTime", moment().unix());
    }

    refreshToken = async () => {
        const response = await DataHandler.get({url: 'auth/refresh_token'});
 
        this.getStorage().setItem('taimerToken', response.token);
        this.setTokenExpiration();
    }

    //1000ms debounce, so that backend doesn't get bombarded with a billion requests
    handleActivityEvent = debounce((evt) => {
        let storage = this.getStorage();
        storage.setItem("lastActivityTime", moment().unix());
    }, 1000);

    loopActivityTime = () => {
        const time = moment().unix();
        const sessionTimeout = this.settings && this.settings.taimerAccount && this.settings.taimerAccount.sessionTimeOut;
        const { taimerTokenIssued, taimerTokenExpires, taimerTokenRefresh, lastActivityTime } = this.getStorage();
        
        if (this.activityTimer)
            clearTimeout(this.activityTimer);
        
        if (!taimerTokenIssued) {
            localStorage.logout = "no token data, logout \n";
            return this.forceLogOut();
        }   
        if (time > taimerTokenExpires) {
            localStorage.logout = `token expired, current ${time}, exp ${taimerTokenExpires}, logout \n`;
            return this.forceLogOut();
        }
        if (time - lastActivityTime > sessionTimeout) {
            localStorage.logout = `no activity, last ${lastActivityTime}, sessionTimeout ${sessionTimeout} logout \n`;
            return this.forceLogOut();
        }
        if (moment().unix() > taimerTokenRefresh) {
            this.refreshToken();
        }
        
        this.activityTimer = setTimeout(() => {
            this.loopActivityTime();
        }, 5000);
        
    }

    getWorkhourTimers = () => this.state.workhourTimers || [];

    loadWorkhourTimers = async () => {
        const data = await DataHandler.get({url: 'timetracker/timers'});

        const workhourTimers = [];

        data.forEach( timer => {
            const start = moment
              .utc(timer.start, "YYYY-MM-DD HH:mm:ss")
              .local()
              .toDate();
            
            workhourTimers.push({
                ...timer,
                start,
            });

        });

        this.setState({workhourTimers});
    }

    isStartingTimer = false;

    timetrackerSettings = null;

    refreshTimeTrackerSettings = async () => {
        const timetrackerSettings = await DataHandler.get({ url: `timetracker/settings`});

        _.each(timetrackerSettings, (company) => {
            company.allow_add_after = new Date(company.allow_add_after * 1000);
            company.allow_modify_after = new Date(company.allow_modify_after * 1000);
        })

        this.timetrackerSettings = timetrackerSettings;
    }

    getTimeTrackerSettings = (projectCompany = null) => {
        let userCompany = Number(this.settings.userObject.companies_id);

        let actualCompany = userCompany > 0 ? userCompany : (projectCompany || 0);

        if (!this.timetrackerSettings[actualCompany]) {
            console.warn(`Missing timetracker settings for ${actualCompany}, using defaults`);
            actualCompany = 0;
        }

        return this.timetrackerSettings[actualCompany] ?? {
            // Sane defaults to prevent crashes before data is loaded
            id: 0,
            defaultVat: 0,
            dailymaxworkinghours: 7.5,
            balancemode: 0,
            workhour_accuracy: 15,
            bulkentry_interval: 15,
            bulkentry_daystart: '07:00',
            notify_hour_balance_max: 0,
            notify_hour_balance_min: 0,
            prevent_adding_old_workhours: false,
            lock_workhours_slide: 0,
            prevent_multicompany_hourentries: false,
            prevent_main_project_hours: false,
            hour_entry_description: false,
            allow_modify_after: 0,
            allow_add_after: 0,
            enable_approval_submitting: false
        };
    }

    startWorkhourTimer = throttle(async (data) => {
        if (this.isStartingTimer)
            return;

        try {
            const res = await DataHandler.post({url: 'timetracker/timers/start', ...data});

            if (!res || !res.id) {
                return;
            }
    
            const workhourTimers = [...(this.state.workhourTimers||[])];

            workhourTimers.push({
                id: res.id,
                start: new Date(),
                ...data,
            });
    
            this.setState({workhourTimers});
            return res;
        } catch (error) {
            // Probably timer is already running
            this.loadWorkhourTimers();
        }

        this.isStartingTimer = false;
    }, 500);

    getWorkhourTimer = () => {
        const { workhourTimers } = this.state;

        if (workhourTimers.length === 0)
            return false;

        const current = workhourTimers[0];

        return current;
    }

    stopWorkhourTimer = async (timer) => {
        const { workhourTimers } = this.state;

        this.setState({workhourTimers: workhourTimers.filter(x => x.id !== timer?.id)});

        if (!timer?.id) {
            return;
        }
        
        try {
            const res = await DataHandler.post({url: 'timetracker/timers/stop', id: timer.id});

            window.dispatchEvent(new Event('timersChanged'));

            return  true;
        } catch (error) {
            this.loadWorkhourTimers();

            return false;
        }
    }

    openWorkhourDialog = (data) => {
        this.addHours(data);
    }

    setDirty = (value, handler = false, data = {}) => {
        if (this.isDirty === value && this.dirtyHandler === handler)
            return;

        this.isDirty = value;
        this.dirtyHandler = handler;
        this.unsavedDialogData = data;
    }

    checkPrivilege = (group, perm, company) => {
        if (!this.settings.userObject.usersId)
            return false;

        if (!company) {
            let noInitialCompany;
            company = this.settings.userObject.companies_id;
        }

        company = parseInt(company, 10);

        const g = this.settings.privileges[group];
        const p = g && g[perm];

        const cClause = (company == 0 && typeof noInitialCompany === 'undefined' ? true : (p && p.indexOf(company) > -1));

        return (g && p && cClause) || false;
    }
    hasPrivilege = (group, perm = undefined) => {
        const g = this.settings.privileges[group];
        
        if (g && !perm)
            return true;
        if (!g)
            return false;
        
        let p;
        if (Array.isArray(perm))
            for (let i in perm) {
                p = g[perm[i]];
                if (p && p.length > 0)
                    return true;
            }
        else {
            p = g[perm];
            return p && p.length > 0;
        }
        
        return false;
    }

    getPreferedCompany = (companies) => {
        if (!companies) 
            return false;

        companies = companies.map(x => Number(x)).filter(x => x);

        if (companies.length === 0)
            return false;

        let usedCompany = companies[0];

        if (companies.indexOf(this.lastCompany) > -1)
            usedCompany = this.lastCompany;
        else if (companies.indexOf(this.settings.userObject.companies_id -0) > - 1)
            usedCompany = Number(this.settings.userObject.companies_id);

        return usedCompany;
    }

    getViewProps = () => {
        return this.state.view;
    }

    getCompany = (group, perm, preferCompany, useLastCompany = false) => {
        preferCompany = preferCompany || this.settings.userObject.companies_id;
        
        const g = this.settings.privileges[group];
        const p = g && g[perm];
        
        if (!p || p.length === 0)
            return false;

        let usedCompany = p[0];

        if (useLastCompany !== false && p.indexOf(this.lastCompany) > -1)
            usedCompany = this.lastCompany;
        else if (preferCompany !== false && p.indexOf(preferCompany-0) > -1)
            usedCompany = preferCompany;
        
        return usedCompany.toString();
    }

    checkPrivilegeAny = (group, perms = undefined, company = undefined) => {
        if (!this.settings.userObject.usersId)
            return false;

        if (!perms)
            perms = this.settings.privileges[group] ? Object.keys(this.settings.privileges[group]) : [];

        for (let perm of perms) {
            if (this.checkPrivilege(group, perm, company))
                return true;
        }

        return false;
    }

    parseLocation() {
        let newData = {};
        let location = window.location.href.split('?');

        if (!location[1])
            return;

        location[1].split("&").forEach(e => {
            let split = e.split("=");

            if (split[1] === "false")
                split[1] = false;
            else if (split[1] === "true")
                split[1] = true;
            else if (split[1] === "undefined" || split[1] == "")
                split[1] = undefined;
                
            newData[split[0]] = typeof split[1] === "string" ? split[1].replace("#", "") : split[1];
        });

        return newData;
    }

    onBeforeUnload = (e) => {
        if (this.isDirty) {
            e.preventDefault();
            e.returnValue = '';

            //this.dirtyHandler && this.dirtyHandler(true);
        }
    }

    dirtyLeaveTarget = {};

    onPopState = (e) => {
        const storage = this.getStorage();
        const token = storage.getItem('taimerToken');
        if (token === "" || !token || token === null) {
            e.preventDefault();
            this.updateView({action: 'login', 'module': 'login'}, false, false, false, true /* no whoami */);
        }
        let update = this.parseLocation();

        const isViewChanging = (update.module && update.module !== this.state.module) || (update.action && update.action !== this.state.action);

        if (this.isDirty && isViewChanging) {
            this.dirtyLeaveTarget = update;
            this.dirtyHandler && this.dirtyHandler();

            const url = `index.php?${$.param(this.state.view)}`;
            window.history.pushState({}, "Taimer", url);

            this.setState({unsavedWarningOpen: true});

            return;
        }
        this.updateView(update, false, true, false, true, "", false);
    }
    
    setTitle(postfix = "") {
        const prefix = this.getStorage() === sessionStorage ? "(A) " : "";
        document.title = `${prefix} ${postfix.length > 0 ? postfix + " - " : ""}Taimer`;
    }

    convertDateFormat(format) {
        return format.replace('%d', 'DD')
            .replace('%m', 'MM')
            .replace('%Y', 'YYYY')
    }
    
    getStorage () {
        return sessionStorage.taimerToken ? sessionStorage : localStorage;
    }
    parseJwt () {
        const token = this.getStorage().taimerToken;
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        
        let data = JSON.parse(jsonPayload);
        data.folder = data.db.substr(2);

        return data;
    };

    sendAnalytics = _.throttle((calledFunction = null, data = null) => {  
            /* CHECK OUT GTM AND GA4 CONFIGURATIONS AND READ GOOGLE'S DOCUMENTATION BEFORE USING! */
            /* When in doubt don't hesitate to ask for help. */
            /* If sending success and fail data consecutively please use 600ms setTimeout for the fail data so the throttle isn't triggered as it's mainly there to prevent tracking user spam. */
            /* EXAMPLE USAGE: 
                callType = "event"
                calledFunction = "invoice_created", <-- This is defined in GTM Triggers which is linked to a corresponding GTM Tag.
                data = {
                    taimer_version: 4,
                    invoice_type: Mass Invoice,
                    invoices_crated: 15
                }
            */
            window.dataLayer && window.dataLayer.push({"event": calledFunction, ...data});

            //Amplitude should just work with the same data as GA4 in a more simplified manner
            window.amplitude && window.amplitude.getInstance().logEvent(calledFunction, data);
    }, 500);
    

    componentWillMount() {        
        if (this.state.view.token) {
            //adminpanel login
            if (this.state.view.local_login == '1')
                localStorage.setItem('taimerToken', this.state.view.token);
            else
                sessionStorage.setItem('taimerToken', this.state.view.token);
        
            this.setTokenExpiration();
            window.history.pushState({}, "Taimer", this.urlify({token: ""}));
            window.location.reload();
        }
       
        this.setTitle();
        const storage = this.getStorage();

        if (storage.taimerPrivileges) {
            try {
                this.settings.privileges = JSON.parse(storage.taimerPrivileges);
                this.settings.taimerVersion = storage.taimerVersion;
                this.settings.versionId = storage.taimerVersionId;
                this.settings.addons = JSON.parse(storage.taimerAddons);
            } catch (e) { console.log("invalid session") }
        }
        else if (process.env.NODE_ENV !== 'production')
            $.get("json/privileges.json").done(e => this.settings.privileges = e);

        if ("nextAction" in sessionStorage) {
            const { state, view } = this.state;
            const nextAction = sessionStorage["nextAction"];

            if (view && view.code) {
                const code = view.code;
                const realmId = view.realmId
                if (nextAction === "mail-office-login")
                {
                    DataHandler.post({url: 'mails/login'}, {
                        code
                        
                    }).then(() =>  {
                        let url = sessionStorage["previousUrl"];

                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else if (nextAction === "settings-accounting") {
                    DataHandler.post({url:
                        `settings/company/${this.settings.userObject.companies_id}/quickbooks/link`
                    }, {
                        code, state, realmId
                    }).done(() => {
                        this.updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default'})
                    }).fail(e => {
                        this.updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', error: e.responseJSON})
                    });

                } else if (sessionStorage["nextAction"] === "onedrive-office-login") {

                    sessionStorage.removeItem("nextAction");
                    DataHandler.post({ url: 'onedrive/login' }, {
                        code
                    }).then(() => {
                        let url = sessionStorage["previousUrl"];
                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else if (nextAction === "calendar-office-login") {

                    sessionStorage.removeItem("nextAction");
                    DataHandler.post({ url: 'calendar/office/login' }, {
                        code
                    }).then(() => {
                        let url = sessionStorage["previousUrl"];
                        sessionStorage.removeItem("previousUrl");

                        window.location = url;
                    });
                } else {
                    console.log(nextAction, "invalid")
                }

                sessionStorage.removeItem("nextAction");
            }
        }
        !['login-login', 'onboarding-view'].includes(this.state.view.selectedPage) && this.whoami();

        window.addEventListener("beforeunload", this.onBeforeUnload);
        window.addEventListener("popstate", this.onPopState);
        document.addEventListener('keypress', this.handleActivityEvent);
        document.addEventListener('click', this.handleActivityEvent);

    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("beforeunload", this.onBeforeUnload);
        window.removeEventListener("popstate", this.onPopState);
        document.removeEventListener('keypress', this.handleActivityEvent);
        document.removeEventListener('click', this.handleActivityEvent);
    }

    updateTimeFormat(format, is12hr) {
        const is12hrf = format.indexOf('h') > -1;

        if (is12hrf == is12hr)
            return format;

        if (is12hr)
            return format.replace(/H/g, 'h') + " A";
        else
            return format.replace(/h/g, 'H').replace(" A", "");
    }

    getFnsLocales = () => {
        const use12hr = this.settings.calendar.clock === 0;
        const fnsLocales = {
            'en': use12hr ? enUS : enGB,
            'fi': fi,
            'se': sv,
            'lt': lt,
            'nn': nn,
            'es': es,
            'pt': pt,
            'it': it,
        }
        return fnsLocales;
    }
   
    companyUsesAddOn = (addOn, companyId) => {
        if(!addOn || !companyId) {
            return false;
        }

        const { addons } = this.settings;

        return addons[addOn] 
            && addons[addOn]?.used_by_companies?.indexOf(companyId) > -1;
    }

    getMomentLocale(data) {
        this.settings.calendar.clock = Number(data.settings.clock_format);
        this.settings.calendar.startOfWeek = Number(data.settings.week_start);

        const use12hr = this.settings.calendar.clock === 0;
        const lang = this.lang.split('-')[0];

        const fnsLocales = this.getFnsLocales();

        const fnsLocaleNames = {
            'en': use12hr ? 'en-US' : 'en-GB',
            'fi': 'fi',
            'se': 'se',
            'lt': 'lt',
            'nn': 'nn',
            'es': 'es',
            'pt': 'pt',
            'it': 'it',
        }

        this.settings.calendar.locale = fnsLocales[lang] || fnsLocales.en;
        this.settings.calendar.localeName = fnsLocaleNames[lang] || fnsLocaleNames.en;

        const langToMomentLocale = {
            'en': use12hr ? 'en' : 'en-gb',
            'fi': 'fi',
            'se': 'sv',
            'lt': 'lt',
            'nn': 'nn',
            'da': 'da',
            'es': 'es',
            'pt': 'pt',
            'it': 'it',
        };

        const baseLocale = langToMomentLocale[lang] || langToMomentLocale.en;
        const taimerLocale = `taimer-${baseLocale}`;

        const localeData = moment.localeData(baseLocale);
        const longDateFormat = {};

        for (const iterator of ['LT', 'LTS', 'LLL', 'LLLL']) {
            longDateFormat[iterator] = this.updateTimeFormat(localeData.longDateFormat(iterator), use12hr);
        }

        moment.defineLocale(taimerLocale, {
            parentLocale: baseLocale,
            week: {
                dow: this.settings.calendar.startOfWeek,
                doy: this.settings.taimerAccount.countryCode === "US" ? 6 : 4 // https://momentjs.com/docs/#/customization/dow-doy/
            },
            longDateFormat,
        });

        return taimerLocale;
    }

    _isPrintService = () => {
        return this.state.view.module == "print";
    }

    getHourApprovalPrivileges = async () => {
        const response = await DataHandler.get({ url: 'timetracker/workhours/approval/settings' });
        return response.show_approval && response.show_approval === '1';
    }

    async whoami() {
        // Load Users Settings
        let data = {}
        
        const storage = this.getStorage();

        try {
            const responses = await Promise.all([DataHandler.get({ url: 'whoami' }), this.getHourApprovalPrivileges()])
            data = responses[0] || {};
            data = {
                ...data,
                showApprovals: responses[1]
            }
            if(!this.cacher.isInitialized()) {
                this.cacher.initialize([
                   [{ url: `projects/autoCompleteData/${data.company}` }, 300],
                   [{ url: `subjects/dimensions/teams/${data.company}` }, 300],
                   [{ url: `projects/needed_rights` }, 300],
                   [{ url: `settings/company/${data.company}/defaultPipeline` }, 300],
                   [{ url: `settings/company/${data.company}/tagSettings` }, 300],
                   [{ url: `settings/company/${data.company}/project/oneprojecttype` }, 300],
                   [{ url: `settings/company/${data.company}/default_project_category` }, 300],
                   [{ url: `subjects/companies/projects/read/`, currency: 1 }, 300],
                ]);
            }

        } catch (error) {
            console.log(error)
            if (this.state.view.selectedPage !== "login-login") {
                storage["loginRedirect"] = JSON.stringify(this.state.view);
                this.updateView({action: 'login', 'module': 'login'}, false, false, false, true /* no whoami */);
            }

            return;
        }

        const params = {
            id: data.user,
            attachment: "user_profile",
            auth: storage.taimerToken,
        };
        
        if (this.settings.build.length && this.settings.build !== data.build)
            window.location.reload();
        else
            this.settings.build = data.build;
        
        this.settings.showMenuSwitcher = data.addons.show_sidebar_switcher !== undefined;
        this.settings.userObject.fullname = `${data.settings.lastname} ${data.settings.firstname}`;
        this.settings.userObject.abbreviation = `${data.settings.lastname.slice(0, 1)}${data.settings.firstname.slice(0, 1)}`
        this.settings.userObject.usersId = Number(data.user);
        this.settings.userObject.companies_id = data.company;
        this.settings.userObject.title = data.title;
        this.settings.userObject.avatar = `download.php?` + $.param(params);
        this.settings.userObject.avatarId = data.settings.profile_img_id;
        this.settings.userObject.accountlistDefaultUnitFilter = data.settings.accountlist_default_unit_filter;
        if(data.settings.date_format == '') {
            data.settings.date_format = "%d.%m.%Y";
        }
        this.settings.userObject.dateFormat = this.convertDateFormat(data.settings.date_format);
        this.settings.taimerAccount.companyDateFormat = this.convertDateFormat(data.companySettings.date_format);
        switch (data.settings.date_format) {
            case '%d.%m.%Y':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%d.%m.');
                break;

            case '%m/%d/%Y':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%m/%d');
                break;

            case '%Y-%m-%d':
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%m-%d');
                break;
            default:
                this.settings.userObject.dateFormatShort = this.convertDateFormat('%d.%m.');
                break;
        }
        this.settings.userObject.personal_meeting_link = data.settings.personal_meeting_link;
        this.settings.userObject.datetimeFormat = `${this.settings.userObject.dateFormat} ${this.settings.userObject.timeFormat}`;
        this.settings.userObject.color = data.settings.color;
        this.settings.userObject.email = data.settings.email;
        this.settings.userObject.language = data.settings.lang || 'en';
        this.settings.userObject.project_read_companies = data.project_read_companies;
        this.settings.userObject.customer_read_companies = data.customer_read_companies;
        this.settings.userObject.mass_invoicing_companies = data.mass_invoicing_companies;
        this.settings.userObject.hasAccountWritePermission = data.has_account_write_permission;
        this.settings.userObject.hasProjectWritePermission = data.has_project_write_permission;
        this.settings.userObject.hasCrmWritePermission = data.has_crm_write_permission;
        this.settings.userObject.sidebarStyle = data.settings.sidebar_style;
        this.settings.userObject.creationDate = data.settings.creationdate;
        this.settings.userObject.show_email = data.settings.show_email;
        this.settings.userObject.show_onedrive = data.settings.show_onedrive;
        this.settings.userObject.show_calendar = data.settings.show_calendar;
        this.settings.userObject.timetracker_old_drop = data.settings.timetracker_old_drop;
        this.settings.userObject.disable_user_balance = data.settings.disable_user_balance;
        this.settings.userObject.hubSpotChatToken = data.hubspot_chat_token;

        this.settings.folder = data.folder;
        this.settings.timeTracker.workhour_accuracy = Number(data.globalSettings.workhour_accuracy);
        this.settings.timeTracker.bulkentry_interval = Number(data.globalSettings.bulkentry_interval);
        this.settings.timeTracker.saturday_is_workday = data.globalSettings.resourcing_count_saturday;
        this.settings.timeTracker.sunday_is_workday = data.globalSettings.resourcing_count_sunday;
        this.settings.timeTracker.workday_length = Number(data.settings.workday_length === null ? data.globalSettings.dailyworkinghours : data.settings.workday_length);
        this.settings.timeTracker.show_approval = data.showApprovals;

        if (this.settings.timeTracker.bulkentry_interval < 1) {
            this.settings.timeTracker.bulkentry_interval = this.settings.timeTracker.workhour_accuracy;
        }

        /* dev override */
        if (localStorage.devLang)
            this.settings.userObject.language = localStorage.devLang;
        this.settings.taimerAccount.currency = data.companySettings.currency.toUpperCase(); /* DEPRECATED */
        this.settings.taimerAccount.defaultVat = parseFloat(data.settings.defaultvat);
        this.settings.taimerAccount.name = data.companySettings.name; /* DEPRECATED */
        this.settings.taimerAccount.ourCompanyLang = data.companySettings.country_lang;  
        this.settings.taimerAccount.companyPrintLang = data.companySettings.print_lang;  
        this.settings.taimerAccount.financialYearStart = Number(data.companySettings.financial_year_start); /* DEPRECATED */
        this.settings.taimerAccount.userlimit = data.globalSettings.userlimit;
        this.settings.taimerAccount.trialStatus = data.companySettings.trialStatus;
        this.settings.taimerAccount.trialEnds = data.companySettings.trialEnds;
        this.settings.taimerAccount.hasMaventa = data.companySettings.has_maventa;
        this.settings.taimerAccount.logo = data.companySettings.logo;
        this.settings.taimerAccount.showLogo = data.companySettings.showlogo;
        this.settings.taimerAccount.countryCode = data.companySettings.country_code.toUpperCase();
        this.settings.taimerAccount.hasEnterpriseGroups = Boolean(Number(data.companySettings.has_enterprise_groups));
        this.settings.taimerAccount.use_maventa_targeting = data.companySettings.use_maventa_targeting;
        this.settings.taimerAccount.force_teams_branchofbusiness_for_projects = data.companySettings.force_teams_branchofbusiness_for_projects == 1;
        this.settings.taimerAccount.mandatory_team_selection_for_projects = data.companySettings.mandatory_team_selection_for_projects == 1;
        this.settings.taimerAccount.showState = this.settings.taimerAccount.countryCode === "US";
        this.settings.taimerAccount.origin = data.globalSettings.origin;
        this.settings.taimerAccount.defaulttermsofpayment = data.globalSettings.defaulttermsofpayment;
        this.settings.taimerAccount.defaultannotation = data.globalSettings.defaultannotation;
        this.settings.taimerAccount.defaultpenaltyinterest = data.globalSettings.defaultpenaltyinterest;
        this.settings.taimerAccount.stripeCustomerId = data.globalSettings.stripe_customer_id;
        this.settings.taimerAccount.attachmentMaxSize = data.attachmentMaxSize;
        this.settings.taimerAccount.isMulticompany = Boolean(data.is_multicompany);
        this.settings.taimerAccount.hasS3SweFinance = data.has_s3_swe_finance;
        this.settings.taimerAccount.useSubresources = data.globalSettings.use_subresources > 0;
        this.settings.taimerAccount.useExtraProjectHours = data.globalSettings.use_extra_project_hours > 0;
        this.settings.taimerAccount.allow_resourcing_to_project = data.globalSettings.allow_resourcing_to_project > 0;
        this.settings.taimerAccount.sessionTimeOut = data.globalSettings.session_timeout;
        this.settings.taimerAccount.hasExtranet = data.globalSettings.has_extranet > 0;
        this.settings.taimerAccount.hideOtherCompanyTasks = data.globalSettings.hide_other_company_tasks > 0;
        this.settings.taimerAccount.subContractorType = data.globalSettings.customers_types_id;
        this.settings.taimerAccount.shareCustomerBasicForAll = data.globalSettings.share_customer_basic_for_all > 0;
        this.settings.taimerAccount.preventCompanySpecificAutocompleteData = data.globalSettings.prevent_company_specific_autocomplete_data > 0;
        this.settings.taimerAccount.allowAccountManagerAndResponsibleFromAllCompanies = data.globalSettings.allow_account_manager_and_responsible_from_all_companies > 0;
        this.settings.taimerAccount.forceCustomerShipGroup = data.globalSettings.force_customership_group > 0;
        this.settings.taimerAccount.useHubSpot = data.use_hubspot;

        this.settings.allAddons = data.allAddons;

        // Moment Calendar Locale (usses settings)
        moment.locale(this.getMomentLocale(data));
        window.moment = moment;        

        /* dev override */
        if (localStorage.devOrigin)
            this.settings.taimerAccount.origin = localStorage.devOrigin;

        this.settings.taimerAccount.numberFormat = data.companySettings.country_lang.toLowerCase() + "-" + data.companySettings.country_code.toUpperCase(); 

        if (this.settings.taimerAccount.origin === "ingram") {
            data.privileges.receivedinvoices = [];
        }

        if (!this.settings.fakePrivileges && !_.isEqual(data.privileges, this.settings.privileges)) {
            storage.setItem('taimerPrivileges', JSON.stringify(data.privileges));
            this.settings.privileges = data.privileges;
            this.setState({});
        }
        if (!_.isEqual(data.addons, this.settings.addons)) {
            storage.setItem('taimerAddons', JSON.stringify(data.addons));
            this.settings.addons = data.addons;
            this.setState({});
        }
        if (data.appVersion !== this.settings.versionId) {
            this.settings.versionId = data.appVersion;
            storage.setItem('taimerVersionId', data.appVersion);
            this.setState({});
        }
            
        // Restore page before login
        if (storage["loginRedirect"]) {
            const view = JSON.parse(storage["loginRedirect"]);
            delete view["settingsLoaded"];
            delete view["tabletMode"];

            storage.removeItem("loginRedirect");

            this.updateView(view);
        }
        
        if (storage.taimerLang !== this.settings.userObject.language) {
            storage.taimerLang = this.settings.userObject.language;
            window.location.reload();
        }
        
        if (localStorage.testUS === "1") {
            this.settings.taimerAccount.showState = true;
            this.settings.taimerAccount.origin = 'ingram';
        }

        // Intercom
        if (data.use_hubspot == 0) {
            window.intercomSettings = {
                app_id: "t2aiyxkp",
                user_id: data.user + "/" + data.adminCompanyId,
                user_hash: data.intercomHash,
                name: data.settings.firstname + " " + data.settings.lastname,
                email: data.settings.email,
                phone: data.settings.phone,
                created_at: data.settings.creationdate == "0000-00-00" ? Math.round(new Date("2015-01-01").getTime()/1000) : Math.round(new Date(data.settings.creationdate).getTime()/1000),
                folder: data.folder,
                taimer_lang: data.settings.lang,
                admin: data.privileges.admin ? 1 : 0,
                taimer_version: this.planNames[data.appVersion-1],
                taimer_software: 9,
                taimer_origin: this.settings.taimerAccount.origin == "ingram" ? "T-Mobile" : "Taimer",
                'Company industry': data.globalSettings.marketing_industry,
                'Company size': data.globalSettings.marketing_size,
                'Company Domain': data.companySettings.domain,
                plan: data.companySettings.defaultreference == "onboarding" ? 'Free trial' : 'Customer',
                company: {
                    name: data.companySettings.name,
                    id: data.adminCompanyId,
                    folder: data.folder,
                    country_code: data.companySettings.country_code,
                    taimer_version: this.planNames[data.appVersion-1],
                    taimer_software: 9,
                    currency: data.companySettings.currency,
                    plan: data.companySettings.defaultreference == "onboarding" ? 'Free trial' : 'Customer',
                    taimer_origin: this.settings.taimerAccount.origin == "ingram" ? "T-Mobile" : "Taimer",
                    industry: data.globalSettings.marketing_industry,
                    'Company size Text': data.globalSettings.marketing_size,
                    website: data.companySettings.domain
                },
                language_override: data.settings.lang
            }
        } else {
            window.Intercom = undefined;
        }

        this.initAppcues();

        globalContext = this.settings;
        // Maybe someday...
        /*mixpanel.init("95da49d687b324c6135dcacbfb642a24"); //Test project token
        mixpanel.identify(data.folder + "_" + data.user);
        mixpanel.people.set({
            "$email":  data.settings.email,
            "$first_name": data.settings.firstname,
            "$last_name": data.settings.lastname,
            "$created": data.settings.creationdate == "0000-00-00" ? new Date("2019-01-01").toISOString() : new Date(data.settings.creationdate).toISOString(),
            "$last_login": new Date(),
            "$ip": data.ipAddress,
            "$phone": data.settings.phone,
            "Name": data.settings.firstname + " " + data.settings.lastname, 
            "User ID": data.user,
            "Folder": data.folder,
            "Language": data.settings.lang,
            "Title": data.settings.title,
            "Taimer version": 9,
            "Plan": this.planNames[data.appVersion-1],
            "Company": data.companySettings.name,
            "Currency": data.companySettings.currency,
            "Trial": data.companySettings.trialStatus,
            "Company ID": data.company
        });

        this.settings.mixpanel = mixpanel;*/

        if (data.use_hubspot == 1 && data.hubspot_chat_token && data.hubspot_chat_token != "") {
            window.hsConversationsSettings = {
                identificationEmail: data.settings.email,
                identificationToken: data.hubspot_chat_token,
            };

            window.HubSpotConversations && window.HubSpotConversations.widget.load();
        }

        if (!this.state.settingsLoaded) {
            this.loadWorkhourTimers();
        }

        if (!this.timetrackerSettings) {
            await this.refreshTimeTrackerSettings();
        }

        if (this.lastCompany === false) {
            this.lastCompany = Number(data.company);

            if (localStorage.lastCompany) {
                const l = Number(localStorage.lastCompany);

                if (l && l > 0) {
                    this.lastCompany = l;
                }
            }
        }

        if (!this._isPrintService()) {
            //session timout management
            this.getStorage().setItem("lastActivityTime", moment().unix());
            this.loopActivityTime();
        }

        let lang = data.settings.lang;
        if (lang == "se")
            lang = "sv";
        if (lang == "en_us")
            lang = "en-us";

        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
       
        this.setState({ settingsLoaded: true });
    }

    initAppcues = () => {
        const { userObject, folder, versionId } = this.settings;
        try {
            window.Appcues.identify(folder + userObject.usersId, {
                email: userObject.email,
                displayName: userObject.fullname,
                version: versionId,
                language: userObject.language,
                created_at: userObject.creationDate,
                folder
            });
            window.AppcueWidget = window.AppcuesWidget(window.Appcues.user());
        } catch (e) {
            console.error(e);
        }
    }

    showNotification = (title, body = undefined, callback = undefined, extra = {}) => {
        if (!("Notification" in window))
            return;

        if (Notification.permission === "denied")
            return;

        if (Notification.permission !== "granted") {
            Notification.requestPermission().then( (perm) => {
                if (perm === "granted")
                    this.showNotification(title, body);
            } )

            return;
        }

        const notification = new Notification(title, {
            body,
            icon: notificationIcon,
            ...extra,
        });

        notification.onclick = callback;
    }

    getFinancialYear = (date = null, financialYearStart = null) => {

        if (!financialYearStart) {
            financialYearStart =  this.settings.taimerAccount.financialYearStart;
        }

        let year = null;

        if (!date) {
            year = new Date();

            if ((getMonth(year) + 1) < financialYearStart)
                year = addYears(year, -1);
        }
        else if (typeof date === 'number') {
            year = parse(`${date}-01-01`, "YYYY-MM-DD", new Date());
        } 
        else {            
            if ((getMonth(date) + 1) < financialYearStart)
                year = addYears(date, -1);
            else
                year = (date);
        }

        year = startOfYear(year);

        const start = addMonths(year, financialYearStart - 1);
        const end = endOfMonth( addMonths(year, Number(financialYearStart) + 10) );

        return {
            label: `${format(start, "MM/YYYY")} - ${format(end, "MM/YYYY")}`,
            start,
            end,
        }
    }


    presentCurrency = (value, currency) => {
        if(!currency) currency = this.settings.taimerAccount.currency;
    
        const response = new Intl.NumberFormat(this.settings.taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'symbol'
        }).format(value);
 
        return response;
    }

    updateUrl(url) {
        if(typeof url === "string") 
            window.history.pushState({}, "", url);
        else if(typeof url === "object") {
            let parts      = window.location.search.split("&");
            let currentUrl = {};
            let urlParts = [];

            parts[0] = parts[0].charAt(0) === "?" ? parts[0].substr(1) : parts[0];

            parts.forEach(part => {
                let split = part.split("=");

                if(split.length > 1)
                    currentUrl[split.shift()] = split.join("=");
                else
                    currentUrl[split[0]] = "";
            });

            for(let key in url)
                currentUrl[key] = url[key];

            for(let i in currentUrl)
                urlParts.push(`${i}=${currentUrl[i]}`);

            window.history.pushState({}, "", window.location.pathname + "?" + urlParts.join("&"));
        }
    }
    
    getUrlParams(update) {
        update = update ? _.cloneDeep(update) : {};
        
        const { view } = this.state;
        const sticky = ['tabletMode'];
        const updateKeys = Object.keys(update);

        if (update.selectedPage) {
            const split = update.selectedPage.split("-");
            update.module = split[0];
            update.action = split[1];
        }
        
        if (update.module || update.action)
            update.selectedPage = update.module + "-" + update.action;
        
        if (!sticky.some(e => updateKeys.indexOf(e) === -1) && updateKeys.length === 1) {
            /* only sticky params are updated, add saved params to state change */
            
            update = {...view, ...update};
        }
        else if(!update.module && !update.action && !update.selectedPage) {
            /* view is not changing, add saved params to state change */
            
            update = {...view, ...update};

            Object.keys(update).forEach(key => {
                if (update[key] == undefined) {
                    delete update[key];
                }
            });
        }
        else {
            /* view is changing, add sticky params to state change */
            
            sticky.forEach(e => view[e] !== undefined && (update[e] = view[e]));
        }
        
        return update;
    }
    urlify(update) {
        update = this.getUrlParams(update);
        return `index.php?${$.param(update)}`;
    }

    updateView(update, newWindow, stateRefresh = true /* DEPRECATED */, rememberPage = false, noWhoami = false, windowTitle = null, pushHistory = true) {
        windowTitle !== null && this.setTitle(windowTitle);
        
        this.refErrorBoundary && this.refErrorBoundary.current && this.refErrorBoundary.current.clearError();
        let nonUrlParams = {};
        if (update.nonUrlParams) {
            nonUrlParams = update.nonUrlParams;
            delete update.nonUrlParams;
        }

        update = this.getUrlParams(update);

        if ((this.state.view.selectedPage !== update.selectedPage || this.state.view.selectedTab !== update.selectedTab) && this.isDirty && !newWindow) {
            this.dirtyLeaveTarget = update;
            this.dirtyHandler && this.dirtyHandler();

            this.setState({unsavedWarningOpen: true});

            return;
        }
        
        if (rememberPage && update.selectedPage !== 'login-login') {
            localStorage.lastView = JSON.stringify({...update});
        }

        // Remove elements that might get "stuck" in the DOM for one reason or another.
        const chartTooltip = document.getElementById("chartjs-tooltip");
        if (chartTooltip) {
            chartTooltip.parentNode.removeChild(chartTooltip);
        }
        const shouldReplace = update?.replace == true;
        if (shouldReplace) {
            delete update.replace;
        }
        const url = `index.php?${$.param(update)}`;
        
        if (newWindow) {
            const win = window.open(url, '_blank');
            if (typeof newWindow === "function")
                win.onload = function () { win.taimerCallback = newWindow; };
        }
        else { /* single page app */
            if (shouldReplace) {
                window.history.replaceState({}, "Taimer", url);
            } else {
                pushHistory && window.history.pushState({}, "Taimer", url);
            }
            update = {...update, ...nonUrlParams};
            if (update.selectedPage != this.state.view?.selectedPage) {
                this.setAdditionalHeaders([]);
                this.setOverrideHeaderTitles(undefined);
                this.previousView = cloneDeep(this.state.view);
            }
            this.setState({view: update}, () => !noWhoami && this.whoami());
            if(window.Appcues){
                window.Appcues.page(); 
            }
            if (window.Intercom) {
                window.Intercom('update', {last_request_at: parseInt((new Date()).getTime()/1000)});
            }
        }
    }

    goToPreviousView = (fallbackLocation = undefined) => {
        if (!this.previousView && !fallbackLocation) return;
        this.updateView(this.previousView || fallbackLocation);
    }

    onUnreadCount = (unreadCount) => {
        this.refTaimerNavi.current && this.refTaimerNavi.current.setUnreadCount(unreadCount);
    }

    onUnseenCount = (unseenCount) => {
        this.refTaimerNavi.current && this.refTaimerNavi.current.setUnseenCount(unseenCount);
    }

    openTeamChat = (location = {}) => {
        this.refTeamChat.current.open(location)
    }

    openNotifications = (location = {}) => {
        this.refNotifications.current.open(location)
    }

    toggleBuyDialog = (addon = "") => {
        const storage = this.getStorage();
        
        if (storage.onboardingEnded == "true")
            return;

        //addon != "" && (this.state.buyTaimerOpen == false || this.state.buyTaimerOpen == undefined) && mixpanel.track('Open Add-on Dialog', {'Clicked add-on': addon, 'Trial dialog': false});

        this.setState({ buyTaimerOpen: !this.state.buyTaimerOpen, buyTaimerAddon: addon });
    }

    setPerms = (perm) => {
        if (!perm) {
            this.settings.fakePrivileges = false;
            this.whoami();
            return;
        }

        this.settings.fakePrivileges = true;
        this.settings.privileges = perm;
        this.forceUpdate();
    }

    dirtyCancel = () => {
        const { unsavedDialogData } = this;
        this.dirtyLeaveTarget = {};

        unsavedDialogData && unsavedDialogData.onCancel && unsavedDialogData.onCancel();
        // this.unsavedDialogData = false;
        this.setState({ unsavedWarningOpen: false });
    }

    dirtyAccept = () => {
        this.dirtyHandler && this.dirtyHandler(true);
        this.setDirty(false);

        this.state.overlayComponent ? this.setOverlayComponent(this.dirtyLeaveTarget) : this.updateView(this.dirtyLeaveTarget);
        this.setState({ unsavedWarningOpen: false });
    }

    componentDidMount() {
        const { action, module, tab , tabletMode } = this.state;
        $('body').attr('action', action).attr('module', module).attr('tab', tab).attr('tabletMode', tabletMode);
        if ("Notification" in window) {
            Notification.requestPermission();
        }
        this.getStorage().setItem("lastActivityTime", moment().unix());
        setTimeout(() => {
            this.refNotifications.current && this.refNotifications.current.refresh(true);
        }, 500);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.view.selectedPage !== nextState.view.selectedPage) {
            this.viewWillChange(this.state.view, nextState.view);
        }

        return true;
    }

    componentDidUpdate() {
        const { action, module, tab } = this.state;

        $('body').attr('action', action).attr('module', module).attr('tab', tab);    
    }

    viewWillChange(currentView, nextView) {
        this.unregisterOnUnloadListeners();
    }

    unregisterOnUnloadListeners = () => {
        this.onUnloadListeners.forEach(l => {
            window.removeEventListener("unload", l);
        });

        this.onUnloadListeners = [];
    }

    registerOnUnloadListener = (fn) => {
        window.addEventListener("unload", fn);

        this.onUnloadListeners.push(fn);
    };

    emptyCacheOnUnload = (viewName) => {
        this.registerOnUnloadListener(() => {
            const storage = sessionStorage.taimerToken 
                ? sessionStorage 
                : localStorage;

            const token   = `Bearer ${storage.taimerToken}`;
            const headers = new Headers();

            headers.append('Authorization-taimer', token);

            const prefix = window.location.href.indexOf(":8080") > -1 
                ? "react/api/" 
                : "/react/api/" ;

            fetch(`${prefix}/cache/handle_view_refresh/${viewName}`, {
                method: "POST", 
                body: null,            
                headers: headers,       
                credentials: 'include',
                keepalive: true
            });
        });
    };

    toggleNewMenu = () => {
        this.settings.userObject.sidebarStyle = !this.settings.userObject.sidebarStyle;

        this.forceUpdate();
    }

    setSidebarStyle = (sidebarStyle) => {
        if (sidebarStyle != 0 && sidebarStyle != 1) return;
        this.settings.userObject.sidebarStyle = sidebarStyle;
        this.forceUpdate();
    }

    setLastCompany = (company) => {
        company = Number(company);

        if (!company) return;

        this.lastCompany = company;
        localStorage.lastCompany = company;
    }

    setOverlayComponent = (overlayComponent) => {
        if (!overlayComponent && this.isDirty) {
            this.dirtyLeaveTarget = overlayComponent;
            this.dirtyHandler && this.dirtyHandler();
            this.setState({ unsavedWarningOpen: true });
            return;
        }
        this.setState({ overlayComponent });
    }

    showDialogContent = (dialogContent, dialogProps) => {
        const dialog = (
            <Dialog open={true} onClose={this.closeDialog} PaperProps={{ className: "taimer-dialog-container" }} {...dialogProps}>
                {dialogContent}
            </Dialog>  
        );
        this.showDialog(dialog);
    }
    
    showConfirmationDialog = ({header, warning, confirmText, cancelText = this.tr("Cancel"), onConfirm, confirmButtonClass = undefined, hideWarningIcon = false}) => {
        this.showDialog( <CoreDialog
            dialogType="delete"
            dialogProps={{
                onCloseClick: this.closeDialog,
                close: this.closeDialog,
                onConfirm: () => {
                    this.closeDialog();
                    onConfirm && onConfirm();
                },
                onCancel: this.closeDialog,
                header,
                translatedConfirmButtonText: confirmText,
                cancelButtonText: cancelText,
                warning: () => warning,
                confirmButtonClass,
                hideWarningIcon
            }}
        />);
    }

    showDialog = (dialog) => {
        this.setState({ dialog });
    }

    showSlider = (slider) => {
        const sliders = [...this.state.sliders || []];
        if (sliders.length > 0) {
            slider = React.cloneElement(slider, { key: sliders.length, hideBackdrop: true });
        }
        sliders.push(slider);
        this.setState({ sliders });
    }

    showSliderContent = (title, content) => {
        const slider = (
            <AnimatedSlider open={true} title={title} onClose={this.closeSlider}>
                {content}
            </AnimatedSlider>
        );
        this.showSlider(slider);
    }

    closeDialog = (callback) => {
        this.setState({ dialog: undefined }, () => {
            callback && callback();
        });
    }

    closeSlider = (callback) => {
        let sliders = [...this.state.sliders || []];
        sliders.pop();
        if (sliders.length == 0) sliders = undefined;
        this.setState({ sliders }, () => {
            callback && typeof callback == 'function' && callback();
        });
    }

    setAdditionalHeaders = (additionalHeaders) => {
        if (!isEqual(additionalHeaders, this.state.additionalHeaders)) {
            this.setState({ additionalHeaders });
        }
    }

    setOverrideHeaderTitles = (titles) => {
        this.refTaimerNavi.current?.setOverrideHeaderTitles && this.refTaimerNavi.current.setOverrideHeaderTitles(titles);
    }

    addAccount = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddAccountSlider key="addAccount" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addContact = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddContactSlider key="addContact" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    openActivitySlider = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddActivitySlider key="addActivity" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addUser = (initialSelectionProps) => {
        const { taimerAccount: { userlimit }, versionId } = this.settings;
        if (userlimit.used >= userlimit.limit) {
            if (versionId == 1) {
                DataHandler.get({ url: 'settings/subscription/getFreeId' }).done(response => {
                    let id = response.id
                    if (id) {
                        this.updateView({
                            module: 'onboarding',
                            action: 'order',
                            subId: id
                        });
                    }
                });
            } else {
                this.updateView({
                    module: 'onboarding',
                    action: 'order',
                    subId: false
                });
            }
            return;
        }
        this.showSlider(<AddUserSlider key="addUser" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} />)
    }

    addHours = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddHoursSlider key="addHours" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addResource = (initialSelectionProps, otherProps) => {
        this.showSlider(<AddResourceSlider key="addResource" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addProject = (initialSelectionProps, otherProps) => {
        const { addons } = this.settings;
        if (addons.projects && addons.projects.limit && addons.projects.used >= addons.projects.limit) {
            this.toggleBuyDialog('projects');
            return;
        }
        this.showSlider(<AddProjectSlider key="addProject" open={true} onClose={this.closeSlider} initialSelectionProps={initialSelectionProps} {...otherProps} />)
    }

    addInvoice = async (props, newTab) => {
        const { addons } = this.settings;
        const { project, account, company } = props;

		if (addons.invoicing && addons.invoicing.limit && addons.invoicing.used >= addons.invoicing.limit) {
			this.toggleBuyDialog("invoicing");
            return;
		}
        const params = { module: 'invoices', action: 'view', editMode: 1 };
        if (project) {
            params.projects_id = project.id;
            params.customers_id = project.account.id;
            params.companies_id = project.companies_id;
            params.preselect = 'projectInvoice';

            const invoiceableProjects = await DataHandler.get({ url: `invoices/invoiceable_projects/${company}`, invoiceType: 'all'});
            let isInvoiceable = invoiceableProjects.projects.find(e => e.id === project.id);
            
            if (isInvoiceable && isInvoiceable.invoiceable) {
                params.template = 'material';
                this.updateView(params, newTab);
            } 
            else if (isInvoiceable && isInvoiceable.refundable) {
                params.invoiceType = 3;
                this.updateView(params, newTab);
            } else {
                const companies = await DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, invoice_only_material: 1 });
                const foundCompany = companies.find(c => c.id == (project?.companies_id || company));
                if (foundCompany?.invoice_only_material != 1) {
                    params.template = 'blank';
                    this.showDialog(
                        <EmptyInvoiceConfirmationDialog 
                            open
                            onDialogClose={this.closeDialog}
                            data={{
                                text: this.tr("No uninvoiced material for project. Do you want to create an empty invoice?"),
                                params: params,
                                updateView: (params) => {
                                    this.closeDialog();
                                    this.updateView(params);
                                }}} 
                            />
                    );
                } else {
                    this.currentViewRef.current && this.currentViewRef.current.props.enqueueSnackbar && this.currentViewRef.current.props.enqueueSnackbar(this.tr("No uninvoiced material for project."), {
                        variant: "warning",
                    });
                }
            }
        } else if (account) {
            params.customers_id = account.id;
            params.companies_id = company;
            params.preselect = 'account';
            this.updateView(params, newTab);
        } else {
            params.companies_id = company;
            this.updateView(params, newTab);
        }
	}

    render() {
        const { settingsLoaded, buyTaimerOpen, unreadCount, view, workhourTimers, overlayComponent, additionalHeaders, dialog, sliders } = this.state;
        const { navigationOnly, enqueueSnackbar, closeSnackbar } = this.props;
        const { addons } = this.settings;

        // Time Tracker doesn't support runtime locale changes
        if (!settingsLoaded && !['login', 'onboarding'].includes(view.module))
            return <div><img className='main-page-loading-indicator' style={{ marginTop: -35 }} src={require('./dashboard/insights/img/loading.svg').default} /></div>

        const storage = this.getStorage();
        
        const { state, updateView, updateUrl, openTeamChat, toggleBuyDialog, whoami, unsavedDialogData, openNotifications } = this;
        const props = { ...state.view, updateView, updateUrl, openTeamChat, toggleBuyDialog, whoami, enqueueSnackbar, closeSnackbar };

        const views = {
            // new main views with tabs
            'dashboard-main': (<Dashboard {...props} />),
            'contacts-main': <Contacts {...props} />,
            'timemanagement-main': <TimeManagement {...props} />,
            'resourceplanning-main': <ResourcePlanning {...props} />,
            'invoices-main': <Invoices {...props} />,
            'costs-main': <Costs {...props} />,
            'products-main': <Products {...props} />,
            // old views
            'dashboard-overview': (<Dashboard {...props} />),
            'dashboard-myday': (<Dashboard {...props} />),
            'dashboard-my_day': (<Dashboard {...props} />),
            'dashboard-profitloss': (<ProfitLoss {...props} />),
            'dashboard-invoicing': <InvoicingInsight {...props} />,
            'dashboard-hours': <HoursInsight {...props} />,
            'dashboard-sales': <SalesInsight viewProps={state.view} {...props} />,
            'dashboard-goals': <GoalsInsight {...props} />,
            'customers-view': (<AccountView key={props.id} viewProps={state.view} {...props} />),
            'mails-login': (<MailLogin className="main" {...props} />),
            'onedrive-login': (<OnedriveLogin className="main" {...props} />),
            'products-view': (<Products {...props} />),
            'cpq-view': (<Products {...props} selectedTab="cpq" />),
            'catalog-list': (<Products {...props} selectedTab="catalogs" />),
            'projects-view': (<ProjectView key={props.id} viewProps={state.view} {...props} />),
            'projects-list': (<ProjectList viewProps={state.view} {...props} />),
            'contact-view': (<ContactView {...props} />),
            'contacts-list': (<Contacts {...props} selectedTab="contacts" />),
            'users-list': (<Contacts {...props} selectedTab="users" />),
            'workhours-calendar': (<TimeManagement {...props} selectedTab="time-tracker" selectedSubTab="calendarView" />),
            'workhours-list': (<TimeManagement  {...props} selectedTab="time-tracker" selectedSubTab="myHours" />),
            'workhours-modified': (<TimeManagement {...props} selectedTab="time-tracker" selectedSubTab="myHours-modified" />),
            'invoices-list': (<Invoices {...props} selectedTab="invoices" />),
            'invoices-mass': (<Invoices {...props} selectedTab="mass-invoicing" />),
            'invoices-log': (<Invoices {...props} selectedTab="invoices-log" />),
            'worktrips-modify': (<ExpenseView key={props.id} {...props} />),
            'worktrips-list': (<Costs {...props} selectedTab="expenses" />),
            'travelexpenses-list': (<Costs {...props} selectedTab="travel-expenses" />),
            'customers-list': (<Contacts {...props} selectedTab="accounts" />),
            'projects-kanban': (<ProjectKanban {...props} />),
            'login-login': (<Login {...props} />),
            'settings-index': (<Settings viewProps={state.view} {...props} />),
            'reports-view': (<ReportsView viewProps={state.view} {...props} />),
            'users-view': (<UserView {...props} />),
            'teamchat-popup': (<TeamChat full />),
            'resourcing-view': (<ResourcePlanning {...props} />),
            'invoices-view': (<InvoiceView {...props} />),
            'collaborate-view': (<CollaborateView {...props} />),
            'onboarding-order': (<OrderView {...props} />),
            'purchaseorder-view': <PurchaseOrderView {...props} />,
            'bills-list': <Costs {...props} selectedTab="bills" />,
            'print-quote': <QuotePrint {...props} />,
            'receivedinvoice-view': <ReceivedInvoiceView {...props} />,
            'activities-insight': (<ActivitiesInsight {...props} />),
            'hours-insight': (<HoursInsight {...props} />),
            'sales-insight': <SalesInsight {...props} />,
            'goals-insight': <GoalsInsight {...props} />,
            'calendar-login': (<TaimerCalendarLogin className="main" {...props} />),
            'insights-view': (<InsightsView {...props} />),
        };
        
        let selectedPage = view.selectedPage; 
        
        if (!storage.taimerToken) {
            selectedPage = "login-login";
        }
        if (view.module == 'onboarding' && view.action == 'view') {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            <SettingsContext.Provider value={this.settings}>
                                <OnboardingView />
                            </SettingsContext.Provider>
                        </SnackbarProvider>
                    </ThemeProvider >
                </StyledEngineProvider>
            );            
        }

        const isPopup = ["teamchat", "login", "print", "proposal"].indexOf(view.module) > -1 || selectedPage === 'login-login';

        if (isPopup) {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <SettingsContext.Provider value={this.settings}>
                            <div id="react-content">
                                {views[view.selectedPage]}
                            </div>
                        </SettingsContext.Provider>
                        </SnackbarProvider>
                    </ThemeProvider >
                </StyledEngineProvider>
            );
        }
 
        switch(selectedPage) {
            case 'dashboard-profitloss':
                if(!this.hasPrivilege('dashboard', 'profit_loss_read')) {
                    selectedPage = 'dashboard-main';
                    break;
                }
        }
        
        if (storage.onboardingEnded == "true") {
            selectedPage = "onboarding-order";
        }

        return (

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={TaimerTheme}>
                <SettingsContext.Provider value={this.settings}>
                    <SnackbarProvider 
                        action={(key) => (
                            <CloseSnackbarButton key={key} />
                        )} 
                        iconVariant={{ default: <ThumbUp /> }} 
                        classes={{
                            containerAnchorOriginTopRight: 'snackbarZindex',
                            containerAnchorOriginTopLeft: 'snackbarZindex',
                            root: 'snackbarRoot',
                        }} 
                        maxSnack={3} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <div id="react-content" className={`${view.tabletMode === 'On' ? "tablet-mode" : ""} ${!!overlayComponent ? "no-display" : ""}`}>
                            <TaimerNavi
                                key={(this.settings.userObject.sidebarStyle === '0' ? "navi-new" : "navi") 
                                        + (this.settings.userObject.show_email == '0' ? "no_email" : "show_email") 
                                        + (this.settings.userObject.show_onedrive == '0' ? "no_onedrive" : "show_onedrive")
                                        + (this.settings.userObject.show_calendar == '0' ? "no_calendar" : "show_calendar")}
                                toggleNewMenu={this.toggleNewMenu}
                                goToPreviousView={this.goToPreviousView}
                                ref={this.refTaimerNavi}
                                url={{ ...state.view, tabletMode: undefined }}
                                toggleBuyDialog={toggleBuyDialog}
                                updateView={this.updateView}
                                tabletMode={view.tabletMode}
                                chatUnread={unreadCount}
                                additionalHeaders={additionalHeaders}
                                currentViewRef={this.currentViewRef}
                                chatClicked={() => this.refTeamChat.current.open(this.state)}
                                notificationsClicked={() => this.refNotifications.current.open(this.state)}
                                personalNoteClicked={() => this.refPersonalNote.current?.open && this.refPersonalNote.current.open()}
                                changeDevPerms={this.setPerms}
                                changeDevLang={this.changeDevLang}
                                useAppcues={this.state.useAppcues}
                                workhourTimers={workhourTimers} />
                            {!navigationOnly && <ErrorBoundary ref={this.refErrorBoundary} updateView={this.updateView}>{React.cloneElement(views[selectedPage] || <PageNotFound />, { ref: this.currentViewRef })}</ErrorBoundary>}
                            {this.checkPrivilege("newsfeed", "newsfeed", "0") && <TeamChat ref={this.refTeamChat} onUnreadCount={this.onUnreadCount} />}
                            {<Notifications ref={this.refNotifications} onUnseenCount={this.onUnseenCount} {...props} />}
                            {<PersonalNote ref={this.refPersonalNote} {...props} />}
                        </div>
                        {buyTaimerOpen && (
                            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                                <Elements locale={this.settings.userObject.language}>
                                    <BuyTaimer {...props} addon={this.state.buyTaimerAddon} />
                                </Elements>
                            </StripeProvider>
                        )}
                        <Dialog
                            data-testid="unsaved-warning-dialog"
                            open={this.state.unsavedWarningOpen}
                            onClose={this.dirtyCancel}>
                            <DialogTitle><WarningIcon style={{ marginRight: 16, color: '#ffcf5c' }} /> {unsavedDialogData.title || this.tr("Are you sure you want to exit?")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {unsavedDialogData.text || this.tr("Your changes won't be saved unless all mandatory fields are filled.")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.dirtyCancel} color="primary">
                                    {unsavedDialogData.cancelText || this.tr("Cancel")}
                                </Button>
                                <Button onClick={this.dirtyAccept} color="primary">
                                    {this.tr('Leave')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {sliders?.length > 0 && sliders}
                        {dialog && dialog}
                        {overlayComponent && 
                        <div className="taimer-overlay">{overlayComponent}</div>
                        }
                        </SnackbarProvider>
                    </SettingsContext.Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default Taimer;
