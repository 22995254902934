import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import MyDay from '../../dashboard/my_day/MyDay';
import ProfitLoss from '../../charts/ProfitLoss';
import HoursInsight from '../../dashboard/insights/hours/HoursInsight';
import InvoicingInsight from '../../dashboard/insights/invoicing/InvoicingInsight';
import SalesInsight from '../../dashboard/insights/sales/SalesInsight';
import ActivitiesInsight from '../../dashboard/insights/activities/ActivitiesInsight';
import GoalsInsight from '../../dashboard/insights/goals/GoalsInsight';

class Dashboard extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Dashboard');
        const {
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'my-day',
                label: this.tr('My Day'),
            },
            {
                id: 'profit-loss',
                label: this.tr('Profit & Loss'),
                hidden: () => !hasPrivilege('dashboard', ['profit_loss_read']),
            },
            {
                id: 'hours-overview',
                label: this.tr('Hours'),
                hidden: () => !hasPrivilege('dashboard', ['hours_read']),
            },
            {
                id: 'invoicing-overview',
                label: this.tr('Invoicing'),
                hidden: () => !hasPrivilege('dashboard', ['invoicing_read']),
            },
            {
                id: 'leads-overview',
                label: this.tr('Leads'),
                hidden: () => !hasPrivilege('dashboard', ['sales_read']),
            },
            {
                id: 'deals-overview',
                label: this.tr('Deals'),
                hidden: () => !hasPrivilege('dashboard', ['sales_read']),
            },
            {
                id: 'activities-overview',
                label: this.tr('Activities'),
                hidden: () => !hasPrivilege('dashboard', ['activities_read']),
            },
            {
                id: 'goals-overview',
                label: this.tr('Goals'),
                hidden: () => !hasPrivilege('dashboard', ['goals_read']),
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} selectedSubTab={this.props.selectedSubTab} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'my-day':
                            return <MyDay {...this.props} />;
                        case 'profit-loss':
                            return <ProfitLoss {...this.props} />;
                        case 'hours-overview':
                            return <HoursInsight {...this.props} />;
                        case 'invoicing-overview':
                            return <InvoicingInsight {...this.props} />;
                        case 'leads-overview':
                            return <SalesInsight {...this.props} mode="leads" />;
                        case 'deals-overview':
                            return <SalesInsight {...this.props} mode="deals" />;
                        case 'activities-overview':
                            return <ActivitiesInsight {...this.props} />;
                        case 'goals-overview':
                            return <GoalsInsight {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Dashboard;
