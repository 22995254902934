import React from 'react';
import TaimerComponent from "../TaimerComponent";

import DataHandler from './../general/DataHandler';
import { Tab, Tabs, Button } from '@mui/material';

import CustomFields from './customfields/CustomFields';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import withStyles from '@mui/styles/withStyles';



/* context */
import { SettingsContext } from '../SettingsContext';

import './TabForms.css';

import { ReactComponent as FormsOverlay } from './FormOverlay.svg';
import Link from '../general/Link';

const styles = theme => ({
	tab: {
		whiteSpace: 'nowrap',
    },
    scrollable: {
        overflowX: 'hidden',
    }
});

class TabForms extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabForms");
        this.state = {
            forms: [],
            pipeline: 0,
            status: 0,
            loaded: false,
        };
    }

    changeForm = (e, tab) => {
        const { id } = this.props;
        const { functions: { updateView } } = this.context;

        updateView({ id, selectedTab: "forms", form: tab });
    }

    componentDidMount = () => {
        this.updateForms();
    }

    componentDidUpdate(prevProps, prevState) {
        const { project: { projects_pipelines_id: nextPipeline, status: nextStatus } } = this.props;
        const { pipeline, status } = this.state;

        if (Number(pipeline) !== Number(nextPipeline) || Number(status) !== Number(nextStatus)) {
            this.updateForms(true);
        }
    }

    updating = false;

    updateForms = (refresh = false) => {
        if (this.updating) 
            return;
        
        this.updating = true;

        const { id, project: { projects_pipelines_id, status } } = this.props;
        const { functions: { updateView } } = this.context;

        DataHandler.get({ url: `projects/${id}/forms`, refresh: refresh ? 1 : 0 }).done((forms) => {
            this.setState({ forms, pipeline: projects_pipelines_id, status, loaded: true });

            if (forms.length > 0 && (!this.props.form || forms.findIndex(x => x.id == this.props.form) === -1))
                updateView({ id, selectedTab: "forms", form: forms[0].id });
        }).always(() => this.updating = false);
    }

    customFieldEdited = (name, value) => {
        if (name.startsWith("custom_"))
            name = name.substring(7);

        this.props.valueChanged({custom: { [name]: value }});
    }

    onChangeConfirm = async (e) => {
        const { id, form, enqueueSnackbar } = this.props;
        const { target: { checked }} = e;

        const data = await DataHandler.put({url: `projects/${id}/forms/${form}`}, {
            confirmed: checked ? 1 : 0
        });

        if (data.error) {
            enqueueSnackbar(`Cannot confirm form due to missing values:\r\n ${data.missing.join("\r\n")}`, {
                variant: "error",
            });
        }

        const { forms: oldForms } = this.state;

        const index = oldForms.findIndex(x => x.id == form);
        const currentForm = oldForms[index];

        const forms = [
            ...oldForms,
        ];

        forms[index] = {...currentForm, ...data};

        this.setState({forms});
    }

    render() {
        const { userObject: { dateFormat }, functions: { updateView } } = this.context;
        const { form, project, customFields, formErrors, projects_sales_states, classes, editable } = this.props;
        const { forms, loaded } = this.state;
        const { tr } = this;

        const currentForm = forms.find(x => x.id == form);

        if (forms.length === 0 && loaded) {
            return (
                <div id="projects-forms" className="column c1"><div><div className="projects-forms-overlay">
                    <FormsOverlay />
                    <div className="text">
                        {this.tr('Create a form and start collecting customised project data')}.
                        <Link url={this.context.userObject.language == "fi" ? "https://support.taimer.com/fi/luo-lomakkeita-ker%C3%A4t%C3%A4ksesi-olennaista-tietoa-projekteille" :"https://support.taimer.com/build-forms-to-collect-project-data"} target="_blank">{this.tr(' See how it works')}</Link>.<br/>
                        {this.tr(' Get started ')} 
                        <Link url={{module: 'settings', action: 'index', group: 'features', page: 'pipeline'}} openInNewTab>{this.tr('here')}</Link>!
                    </div>
                 </div>
                 </div></div>);
        }

        return (
            <div id="projects-forms" className="column c1">
                <div className="tabsarea">
                    <Tabs classes={{scrollable: classes.scrollable}} color="primary" value={String(form)} onChange={this.changeForm} indicatorColor="primary" variant="scrollable">
                        {forms.map(x => <Tab classes={{root: classes.tab}} key={x.id} value={x.id} label={x.name} />)}
                    </Tabs>
                    {
                        (!(null == this.context.privileges.admin)) ?
                        <span className={"settingsButton"}>
                            <IconButton
                                href= {this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'pipeline' })}
                                aria-label={this.tr("Settings")}
                                size="large">
                                <SettingsIcon />
                            </IconButton>
                        </span> : undefined
                    }
                </div>
                <div className="formarea">
                {
                    currentForm && <CustomFields
                        ref={this.refCustomFields}
                        fields={customFields}
                        disabled={!editable}
                        form={form}
                        project={project}
                        values={project.custom || {}}
                        disableOptional={!project.account.id}
                        onChange={this.customFieldEdited}
                        projects_sales_states={projects_sales_states}
                        errors={formErrors}
                    />
                }
                </div>
            </div>
        );
    }
};

export default withStyles(styles)(TabForms);
