import React from 'react';
import './Integration.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import AccountingAccounts from '../components/AccountingAccounts';
import AccountingVatCodes from '../components/AccountingVatCodes';
//import AccountingTermsOfPayments from '../components/AccountingTermsOfPayments';
import AccountingDimensions from '../components/AccountingDimensions';
import { SettingsContext } from '../../SettingsContext';
import AccountingWorkflows from '../components/AccountingWorkflows';
import SettingsGrid from '../pages/SettingsGrid';

class Heeros extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Heeros");

        this.state = {
            activated: 0,
            username: "",
            password: "",
            accounting_company_id: "",
            end_customer_id: "",
            dialogData: undefined,
            currentDialog: false,
            settings: {
                "approverName" : "",
                "approveWorkflow" : "",
                "approveStatus" : "",
            },
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.integration = "heeros";

        this.billSettings = [
            {
				type: 'text',
				name: 'approverName',
				label: this.tr('Approver name'),
				className: 'respSetting third marginTop',
			},
			{
				type: 'text',
				name: 'approveWorkflow',
				label: this.tr('Approval workflow'),
				className: 'respSetting third',
			},
            {
				type: 'text',
				name: 'approveStatus',
				label: this.tr('Approval status number'),
				className: 'respSetting third',
			},
        ]
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.setState(response);
        });
    }

    saveAuth = () => {
        const data = {
            username: this.state.username,
            password: this.state.password,
            realm: this.state.accounting_company_id + "/" + this.state.end_customer_id,
            company_id: this.props.company,
            integration: this.integration,
        }

        DataHandler.put({url: `integrations`}, data).done(response => {
            this.props.enqueueSnackbar(this.tr("Heeros authentication information saved"), {
                variant: "success",
            });	
            this.setState({activated: 1});
        });
    }

    back = () => {
        const {functions: {updateView}} = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: 'heeros'}).done(() => {
                    this.props.enqueueSnackbar(this.tr("Heeros authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate Heeros integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    fetchAccounts = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros accounts..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/accounts`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Accounts fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    fetchDimensions = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros dimensions..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/dimensions`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Dimensions fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    fetchVatCodes = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros VAT codes..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/vatcodes`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("VAT codes fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    onChangeBillSettings = (settings) => {
		DataHandler.post({url: `integrations/custom_settings`}, {integration: this.integration, settings: settings, company_id: this.props.company})
			.done(response => {
				this.props.enqueueSnackbar(this.tr('Bill approval settings saved'), {
					variant: 'success',
					preventDublicate: true,
				});
                this.setState({settings: settings});
			})
			.fail(response => {
				this.props.enqueueSnackbar(this.tr('Something went wrong while saving your settings'), {
					variant: 'error',
					preventDublicate: true,
				});
			});
	}

    render(){
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="integration-wrapper" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr("Heeros settings")}</h3>
                    {this.state.activated == 1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{this.tr("Deactivate")}</Button>}
                </div>

                <div className="settings smaller-margin">
                    <h4>{this.tr("Authentication information")}</h4>
                    <OutlinedField value={this.state.username} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("Username")} disabled={this.state.activated == 1} />
                    <OutlinedField value={this.state.password} onChange={(evt) => this.setState({password: evt.target.value})} label={this.tr("Password")} type="password" autoComplete="new-password" disabled={this.state.activated == 1} />
                    <OutlinedField value={this.state.accounting_company_id} onChange={(evt) => this.setState({accounting_company_id: evt.target.value})} label={this.tr("Accounting company ID")} />
                    <OutlinedField value={this.state.end_customer_id} onChange={(evt) => this.setState({end_customer_id: evt.target.value})} label={this.tr("End customer ID")} />

                    <Button className="auth-button" color="primary" onClick={this.saveAuth} size="large">{this.tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{this.tr("Sales accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={1}
                        addButtonText={this.tr("Add sales account")}
                        showDefaultOption={true}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr("VAT codes")}</h4>
                    <AccountingVatCodes
                        integration={this.integration}
                        company={this.props.company}
                        showVatAccount={false}
                    />
                </div>

                {/*<div className="settings">
                    <h4>{this.tr("Payment terms")}</h4>
                    <AccountingTermsOfPayments 
                        integration={this.integration}
                        company={this.props.company}
                    />
                </div>*/}

                <div className="settings">
                    <h4>{this.tr("Dimensions")}</h4>
                    <AccountingDimensions 
                        integration={this.integration}
                        company={this.props.company}
                        showDefaultOption={true}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr("Bill workflows")}</h4>
                    <AccountingWorkflows
                        integration={this.integration}
                        company={this.props.company}
                        showDefaultOption={true}
                    />
                </div>

                <div className="settings">
                <h4>{this.tr("Bill accounts")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchAccounts()} size="large">{this.tr("Get accounts")}</Button>
                </div>

                <div className="settings">
                <h4>{this.tr("Bill dimensions")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchDimensions()} size="large">{this.tr("Get dimensions")}</Button>
                </div>

                <div className="settings">
                <h4>{this.tr("Bill VAT codes")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchVatCodes()} size="large">{this.tr("Get VAT codes")}</Button>
                </div>

                <div className="settings">
                    <h3>{this.tr('Approval information')}</h3>
                    <p className='subtitle'>
					    {this.tr('Define the approver and workflow status for invoices that are sent to Heeros.')}
					</p>
                    <SettingsGrid 
                        settings={this.billSettings} 
                        item={this.state.settings} 
                        onChange={this.onChangeBillSettings}
                    />
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default Heeros;