import React, { Component } from 'react';
import cn from 'classnames';
import './ActivitySettingsTypeSelect.css';
import { getActivityTypes } from '../../Data';
import TaimerComponent from "../../TaimerComponent";

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
class ActivitySettingsType extends TaimerComponent {
	static defaultProps = {
        onSelect: () => {},
    }

    constructor(props, context) {
        super(props, context, "dialogs/elements/ActivitySettingsTypeSelect");

		this.element = React.createRef();
    }

    componentDidMount = () => {
        super.componentDidMount();
        const { selected } = this.props;
	}

    onClick = () => {
       // const { items } = this.props.items;
       const { item: { icon, id, name }, className } = this.props;

       this.props.onSelect(id,name);
    }
    
	render() {
        const { item: { icon }, className } = this.props;
        const iconColor =  this.props.item.color;
        let r = parseInt(iconColor.slice(1, 3), 16);
        let g = parseInt(iconColor.slice(3, 5), 16);
        let b = parseInt(iconColor.slice(5, 7), 16);
 
        const  C_color = "rgba(" + r + ", " + g + ", " + b + ", " + 0.1 + ")" ;
        const  C_color1 = {'background-color':C_color,'color': iconColor };
    
		return (<div ref={this.element}  className={cn("TMRActivityType", this.props.className) } style={C_color1}  onClick={this.onClick}>
            <img alt="" src={icon} />
          {  <CircleCheckedFilled
               color="primary"
               className={"greeCheck"}
               checked={true}
                                   
            />}
        </div>)
	}
}

export default class ActivitySettingsTypeSelect extends Component {

    onSelect = (id,name) => {
      //  const { name } = this.props.items;
        const e = {
            target: {
                name,
                value: id ? id: undefined
            }
        }
    this.props.onChange(e);
    }

    componentDidMount() {
		this.refreshActivityIcons(this.props);
    }
    
    componentDidUpdate(prevProps, prevState) {
		if (this.props.company !== prevProps.company)
			this.refreshActivityIcons(this.props);
	}

    async refreshActivityIcons(props)
	{
        if (this.props.items) return;
        const items = await getActivityTypes(props.company);
        
        this.setState({items});
        if(!this.props.value && items) this.onSelect(items[0]);
	}

    constructor(props) {
        super(props);

		this.refActivity = React.createRef();
    }

   

	render() {
        const { className} = this.props;
        let { value } = this.props;
        const items = this.props.items;
    
		return (<div className={cn("TMRActivityTypeSelect", className)}>
            <div className="ActivityIconItemsWrapper" ref={this.refActivity}>
                <div className="ActivityIconItems">
                    {
                        value ? items.map((v) => (<ActivitySettingsType className={value === v.id && "selected"} onSelect={this.onSelect} key={v.id} item={v} />))
                        : items.map((v, i) => (<ActivitySettingsType /*className={i === 0 && "selected"}*/ onSelect={this.onSelect} key={v.id} item={v} />))
                    }
                </div>
            </div>
        </div>)
	}
}