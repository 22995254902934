import React from "react";
import SettingRow from "../../list/rows/SettingRow";
import ListCell from "../../list/ListCell";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";

/**
 * Efina settings - TalenomSalesRow
 *
 */
export default class TalenomSalesRow extends SettingRow {
	/**
	 * TalenomSalesRow renderer
	 *
	 */
	render() {
		const cells = {
			delete: (
				<ListCell
					className="noBg pointer"
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap["delete"]}
				>
					<TrashCanIcon />
				</ListCell>
			),
			name: (
				<TextInputCell
					width={this.props.columnWidthMap["name"]}
					name="name"
					value={this.state.data["name"]}
					onEdited={this.editAndSave}
				/>
			),
			account_number: (
				<TextInputCell
					width={this.props.columnWidthMap["account_number"]}
					name="account_number"
					value={this.state.data["account_number"]}
					onEdited={this.editAndSave}
				/>
			),
			vat_id: (
				<AutoCompleteCell
					listCellProps={{
						className: "vat_id",
					}}
					editable={true}
					autoCompleteData={this.props.rowProps.taxratesautocomplete}
					name="vat_id"
					width={this.props.columnWidthMap["vat_id"]}
					value={{label: this.state.data["vat_value"], value: this.state.data["vat_id"]}}
					onEdited={data => {
                        const newdata = this.state.data;
                        newdata.vat_id = data.id;

                        this.editAndSave(newdata);
                    }}
				/>
			),
		};

		return (
			<div
				className="listElement row flex"
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}
