import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from  '../../TaimerComponent';
import { ReactComponent as FileIcon } from '../icons/upload csv.svg';

/* material ui */
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';

/* backend */
import DataHandler from '../../general/DataHandler';

import './FileUpload.css';

class FileUpload extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {   
        super(props, context, "dialogs/imports/FileUpload");

        this.state = {
            showFile: false,
            fileName: "",
            fileSize: "",
        }
        this.upload = React.createRef();
    }

    onDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    };

    uploadFiles = (file) => { 
        const e = {target: {name: "upload"}};
        this.props.startLoadingAnimation(e);
        file.forEach(e => {
            DataHandler.file({ url: `imports/parse/file`, details: { companies_id: this.props.company }, ...this.props.extra }, e, undefined, e => {
                this.props.setFileData(e.responseJSON);
            });
        });
    }

    showFile = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        let file = Array.from(evt.target.files || evt.dataTransfer.files)
        if (file.length > 0)            
            this.setState({showFile: true, fileName: file[0].name, fileSize: file[0].size}, () => this.props.stageFile(file));
    }

    humanFileSize = (bytes, si) => {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    }
 
    render() {
        const { tr } = this;
        const { fileName, fileSize } = this.state;
        if (!this.state.showFile){
            return (
                <div className="add-file" onDragOver={this.onDragOver} onDrop={this.showFile} onClick={() => this.upload.current.click()}>
                    <div>
                        <CloudDownloadOutlined /><br />
                        <span>{this.props.uploadHeader ? this.props.uploadHeader : tr("Import by dragging a file here or by")}</span>
                        <div className="link-container">
                            <span className="upload-link">{tr("clicking here")}</span>
                            <span>.</span> 
                        </div>
                        <span className="recommended">{this.props.suggestedFilesize}.</span>
                        <br />
                        <input ref={this.upload} onChange={this.showFile} type="file" multiple />
                    </div>
                </div>        
            );  
        } else {
            return (
                <div className="check-file" onDragOver={this.onDragOver} onDrop={this.showFile}>
                    <div className={fileSize > this.props.fileSizeWarningThreshold ? "warning" : ""}>
                        <FileIcon />
                        <span>{tr("File Name: ") + " " + fileName}</span>
                        <span>{tr("File Size: ") + " " + this.humanFileSize(fileSize)}</span>
                        {fileSize > this.props.fileSizeWarningThreshold ? <span className="size-warning">{tr("File is too large")}.</span> : undefined}
                    </div>
                </div>
            )
        }
    }
}

export default FileUpload;