import React from 'react';
import TaimerComponent from "../TaimerComponent";
import DataHandler from './../general/DataHandler';
import { SettingsContext } from './../SettingsContext';
import OutlinedField from "./../general/OutlinedField";
import ToggleableOutlinedField from "./../general/ToggleableOutlinedField";
import List from "../list/List";
import ListCell from "../list/ListCell";
import TextInputCell from "../list/cells/TextInputCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ConfirmationDialog from "./../list/dialogs/ConfirmationDialog";

import Button from '@mui/material/Button';
import TrashCanIcon from "@mui/icons-material/Delete";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';


import "./TabMediapro.css";
import PageTopSection from '../general/PageTopSection';


class MediaproRow extends PropsOnlyListRow {
    defineCells() {
        const { data }     = this.props;
        const { canWrite } = this.props.sharedData;

        return {
            delete: data.id > 0 
                ? 
                <ListCell onlyDisplay={true}>
                { 
                    canWrite && (<TrashCanIcon onClick={() => this.delete()} />)
                }
                </ListCell>
                :
                <ListCell onlyDisplay={true}>
                    <Cancel onClick={() => this.props.listRef.removeRow(data.id)} />
                    <CheckCircle onClick={() => this.create()} />
                </ListCell>,
            number: <TextInputCell value={data.number} editable={canWrite} inEditMode={data.id < 0} onEdit={value => this.setDataAndUpdate({ number: value })} />,
            text: <TextInputCell value={data.text} editable={canWrite} inEditMode={data.id < 0} onEdit={value => this.setDataAndUpdate({ text: value })} />
        };
    }
}


class TabMediapro extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "accounts/TabMediapro");

        this.context      = context;
        this.productList  = React.createRef();
        this.contractList = React.createRef();

        this.urlBase = `accounts/${this.props.id}/company/${this.props.company}`;

        this.state = {
            details: { mediapro_id: "-" },
            products: [],
            contracts: [],
            mediaproIdEdit: false
        };

        this.initialize             = this.initialize.bind(this);
        this.fetchMediaproDetails   = this.fetchMediaproDetails.bind(this);
        this.fetchMediaproProducts  = this.fetchMediaproProducts.bind(this);
        this.fetchMediaproContracts = this.fetchMediaproContracts.bind(this);
        this.handleMediaproIdClick  = this.handleMediaproIdClick.bind(this);

        this.initialize();
    }


    async initialize() {
        this.setState({
            details: await this.fetchMediaproDetails(),
            products: await this.fetchMediaproProducts(),
            contracts: await this.fetchMediaproContracts(),
        });
    }


    fetchMediaproDetails() {
        return DataHandler.get({ url: `${this.urlBase}/mediapro` });
    }


    fetchMediaproProducts() {
        return DataHandler.get({ url: `${this.urlBase}/mediapro_products` });
    }


    fetchMediaproContracts() {
        return DataHandler.get({ url: `${this.urlBase}/mediapro_contracts` });
    }


    handleMediaproIdClick(eventType, value) {
        ({
            save:   async () => {
                let resetValue     = false;
                const currentValue = this.state.details.mediapro_id;

                try {
                    await DataHandler.put({ url: `${this.urlBase}/mediapro` }, { mediaproId: value });

                    this.setState({ details: { mediapro_id: value } });
                } catch(ex) {
                    resetValue = true;

                    console.warn("Could not update Mediapro details.");
                } finally {
                    const state = { 
                        mediaproIdEdit: false 
                    };

                    if(resetValue) {
                        state.details = { mediapro_id: currentValue };
                    }

                    this.setState(state);
                }
            },
            cancel: () => this.setState({ mediaproIdEdit: false }),
            edit:   () => {
                if(!this.context.functions.checkPrivilege("customers", "mediapro_write", this.props.company)) {
                    this.props.enqueueSnackbar(this.tr("You do not have the required privileges to perform this action"), {
                        variant: "error"
                    });

                    return;
                }

                this.setState({ mediaproIdEdit: true });
            }
        })[eventType]();
    }


    render() {
        const { checkPrivilege } = this.context.functions;

        const canWrite = checkPrivilege("customers", "mediapro_write", this.props.company);
        const isAdmin  = checkPrivilege("admin", "admin", this.props.company);

        return (
            <div id="accounts-mediapro">
                <PageTopSection header={this.props.header || this.tr("Mediapro")} subheaders={this.props.subheaders} subtitle={this.tr("Accounts's Mediapro Details")} />
                <ToggleableOutlinedField 
                    editable={isAdmin}
                    value={this.state.details.mediapro_id}
                    onSave={(value) => this.handleMediaproIdClick("save", value)}
                    onCancel={() => this.handleMediaproIdClick("cancel")}
                    onEditClick={() => this.handleMediaproIdClick("edit")}
                    editMode={this.state.mediaproIdEdit}
                    style={{ width: "280px" }}
                    validation={["empty"]}
                    outlinedFieldProps={{
                        label: this.tr("Mediapro customer number"),
                        name: "mediapro_id",
                        fullWidth: true
                    }} />


                <div className="container">
                    <div className="flexColumn">
                        <h3>{this.tr("Mediapro Products")}</h3>
                        <Button className="green" disabled={!canWrite} onClick={() => this.productList.current.addNewRow()} size="large">{this.tr("Add a product")}</Button>
                    </div>
                    <div className="flexColumn">
                        <h3>{this.tr("Mediapro Contracts")}</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="flexColumn">
                        <List 
                            ref={this.productList}
                            fluid
                            defaultColumnHeaderConfig={{ resizeable: false, moveable: false, showMenu: false, showResizeMarker: false }}
                            listRowType={MediaproRow}
                            showPageSelector={false}
                            noStateData={true}
                            height="auto"
                            manualCreate={true}
                            sharedData={{
                                canWrite: canWrite
                            }}
                            columns={[
                                { name: "delete", width: 1 },
                                { name: "number", width: 7, header: this.tr("Product Number") },
                                { name: "text", width: 6, header: this.tr("Product Name") },
                            ]}
                            newRow={{
                                number: "",
                                text: ""
                            }}
                            data={this.state.products.map(d => {
                                d.number = d.mediapro_id;
                                d.text   = d.name;

                                return d;
                            })}
                            rowCallbacks={{
                                onCreate: async (data, row) => {
                                    const id = await DataHandler.post({ url: `${this.urlBase}/mediapro_products` }, { number: data.number, text: data.text });

                                    row.setData({ id: id });
                                },
                                onUpdate: async (data) => {
                                    await DataHandler.put({ url: `${this.urlBase}/mediapro_products/${data.id}` }, { number: data.number, text: data.text });
                                },
                                onDelete: async (data) => {
                                    await DataHandler.delete({ url: `${this.urlBase}/mediapro_products/${data.id}` }).done(() => this.productList.current.removeRow(data.id));
                                }
                            }}
                        />
                    </div>
                    <div className="flexColumn">
                        <List
                            ref={this.contractList}
                            fluid
                            defaultColumnHeaderConfig={{ resizeable: false, moveable: false, showMenu: false, showResizeMarker: false }}
                            listRowType={MediaproRow}
                            showPageSelector={false}
                            noStateData={true}
                            height="auto"
                            manualCreate={true}
                            sharedData={{
                                canWrite: canWrite
                            }}
                            columns={[
                                { name: "delete", width: 1 },
                                { name: "number", width: 7, header: this.tr("Contract Number") },
                                { name: "text", width: 6, header: this.tr("Contract Description") },
                            ]}
                            data={this.state.contracts.map(d => {
                                d.number = d.mediapro_id;
                                d.text   = d.name;

                                return d;
                            })}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

TabMediapro.contextType = SettingsContext;

export default TabMediapro;
