import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import _ from 'lodash';

/* material ui */
import { MenuItem, Button, Tooltip, Tabs, Tab, Switch } from '@mui/material';
import { Portal } from '@mui/base';
import { Doughnut, Bar } from 'react-chartjs-2';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

/* local components */
import TaimerComponent from "../TaimerComponent";
import List from './../list/List';
import PropsOnlyListRow from "./../list/PropsOnlyListRow";
import ListRow from "./../list/ListRow";
import TreeStructureIndicatorCell from "./../list/cells/TreeStructureIndicatorCell";
import ListCell from './../list/ListCell';
import LinkListCell from "./../list/LinkListCell";
import TextInputCell from "./../list/cells/TextInputCell";
import StatusCell from "./../list/cells/StatusCell";    
import CheckboxCell from "./../list/cells/CheckboxCell";
import CurrencyListCell from "./../list/CurrencyListCell";
import ContextMenu from './../general/ContextMenu';
import OutlinedField from "./../general/OutlinedField";

import InsightBlock from "./../dashboard/insights/InsightBlock";
import InsightDropDown from "./../dashboard/insights/InsightDropDown";
import InsightTable, { InsightTableListRow } from "./../dashboard/insights/InsightTable";
import EmptyInvoiceConfirmationDialog from "./../invoices/dialogs/EmptyInvoiceConfirmationDialog";

/* data backend */
import DataHandler from './../general/DataHandler';

/* css */
import './TabFinancialOverview.css';

/* context */
import { SettingsContext } from './../SettingsContext';

/*icons*/
import { ReactComponent as InvoiceProjectcard } from './../general/icons/InvoiceProjectcard.svg';
import { ReactComponent as InvoiceMaterial } from './../general/icons/InvoiceMaterial.svg';
import { ReactComponent as SubtotalInvoice } from './../general/icons/SubtotalInvoice.svg';
import { ReactComponent as Costs } from './../general/icons/Costs.svg';
import { ReactComponent as Income } from './../general/icons/Income.svg';
import { ReactComponent as ProjectMargin } from './../general/icons/ProjectMargin.svg';
import { ReactComponent as ProjectRevenue } from './../general/icons/ProjectRevenue.svg';
import { ReactComponent as TotalCost } from './../general/icons/TotalCost.svg';
import { ReactComponent as Backlog } from './../general/icons/Backlog.svg';

/* material icons */
import { MoreHoriz, CheckCircle, Cancel, ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { CheckBox } from "@mui/icons-material";

/* other */
import { format, isValid } from "date-fns";
import colors from '../colors';

const BlockWithIcon = props => {
  const { tooltip, icon, header, value, onClick, background } = props;
  return (
    <div onClick={onClick} className="finances-overview-block highlightable">
      <div className="square-icon-container">
        <div className="square-icon" style={{backgroundColor: background}} >{icon}</div>
      </div>
      <Tooltip title={tooltip ?? ""} classes={{ tooltip: 'darkblue-tooltip' }} placement="top" >
        <div className="text-container">
            <p>{header}</p>
            <h1>{value}</h1>
        </div>
      </Tooltip>
    </div>
  );
};

class OverviewRow extends PropsOnlyListRow {
    static rowDimensions = {
        height: "44px",
        lineHeight: "44px"
    };

    constructor(props) {
        super(props, {}, { childRowType: OverviewRow }, "projects/TabFinancialOverview");

        this.popperRoot = React.createRef();

    }

    defineClassName = () => {
        const childClass       = this.props.isChild ? "child" : "";
        let matchedSearchClass = this.props.rowProps.showTreeStructures && this.props.rowProps.showWholeTrees && this.props.data.matched_search ? "matchedSearch" : "";

        return `overviewRow ${childClass} ${matchedSearchClass}`;
    }


    defineCells() {

        const { data, rowProps, columnWidthMap } = this.props;

        const staticColumnProps = { showMenu: false, showMenuContainer: true, resizeable: false, showResizeMarker: false, moveable: false, hideable: false };

        const commonProps = { editable: false, openEditOnFocus: false };

        const joinedCellWidth = columnWidthMap.name*1 + columnWidthMap.customer*1 + columnWidthMap.status*1; 

        let cells = {
            checked:
                <CheckboxCell 
                    checked={this.props.checked}
                    listCellProps={data.id < 0 && {style: {width: `${columnWidthMap.checked}px`, flexGrow: columnWidthMap.checked, flexShrink:1, flexBasis: '0px'}}}  
                    onClick={() => {
                        this.props.listRef.check(this.props.data.id);
                        rowProps.onCheck(this.props.listRef.getCheckedRows());
                    }} />,
            project_id: 
                (data.id > 0 ? <LinkListCell
                    {...commonProps}
                    urlHandler={value => `index.php?module=projects&action=view&id=${data.id}`}
                    value={data.project_id}
                    noTab={true} 
                    useIndenting={false}
                    indent={this.props.recursionLevel} />
                    : <div className="disabled-div" style={{width: `${columnWidthMap.project_id}px`, flexGrow: columnWidthMap.project_id, flexShrink:1, flexBasis: '0px'}} />)
                ,                
            name:
                (data.id > 0 ? <TreeStructureIndicatorCell
                    name="name"
                    childrenVisible={this.props.childrenVisible}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    rowData={this.props}
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
                    value={data.name}>
                    <TextInputCell
                        {...commonProps}
                        listCellType={LinkListCell} 
                        listCellProps={{ 
                            urlHandler: value => `index.php?module=projects&action=view&id=${data.id}`,
                            noTab: true,
                            inEditMode: false, 
                            editable: false, 
                            style: { height: "44px" }, 
                            width: columnWidthMap['name'] - ((this.props.children.length > 0 ? 1 : 0) + this.props.recursionLevel) * 25 
                        }} 
                        name="project"
                        value={data.name} />
                </TreeStructureIndicatorCell> : 
                <div className="disabled-div enchanced" style={{width: `${joinedCellWidth}px`, flexGrow: joinedCellWidth, flexShrink:1, flexBasis: '0px'}} >
                    {this.tr("All invoices that are created without project for the customer")} 
                </div> ),
            customer:
                data.id > 0 ? <LinkListCell
                    {...commonProps}
                    asText={this.props.data.can_see_account == "0"}
                    value={data.customer}
                    urlHandler={() => `index.php?module=customers&action=view&id=${data.customers_id}`}
                    noTab={true} /> 
                    : 
                    <div className="disabled-div" />,
            status: 
                data.id > 0 ? <StatusCell 
                    listCellProps={{style: {width: `${columnWidthMap.status}px`, flexGrow: columnWidthMap.status, flexShrink:1}}} 
                    displayData={rowProps.statuses.find(s => s.id == data.status) || rowProps.statuses.find(s => s.id == -1)} 
                    value={data.status} /> : <div className="disabled-div" />,
            invoiced:
                <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    style={data.id < 0 && {flexBasis: 0}}
                    className={data.id < 0 ? 'enchanced' : ''}
                    value={data.invoiced}
                    textAlign="right" />,
            budgeted_sales:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_sales}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            sale_difference:
                (data.id > 0 ? <CurrencyListCell
                    className={data.invoiced - data.budgeted_sales < 0 ? 'negative' : ''}
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.invoiced - data.budgeted_sales}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            budgeted_costs:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            actual_costs:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.actual_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            cost_difference:
                (data.id > 0 ? <CurrencyListCell
                    className={data.budgeted_costs - data.actual_costs < 0 ? 'negative' : ''}
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_costs - data.actual_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
        };

        return cells;
    }
}

class InvoicedVSSalesRow extends InsightTableListRow {
  defineCells() {
    const { data, sharedData: { currency } } = this.props;
    const { taimerAccount, userObject } = this.context;

    const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
        style: 'currency',
        currency,
    }).format;

    let cells = {};

    Object.keys(data).forEach(key => {
        switch (key) {
            case "type_name":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container">
                            <p>{this.tr(data[key])}</p>
                        </div>
                    </ListCell>
                );
                break;
            case "invoiced":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container">
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        </div>
                    </ListCell>
                );
                break;
            case "budgeted_price":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container"> {
                            ['scheduled', 'automatic'].includes(data.type_name) ? 
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        }
                        </div>
                    </ListCell>
                );
                break;
            case "selling_price":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container"> {
                            ['products', 'services'].includes(data.type_name) ? 
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        }
                        </div>
                    </ListCell>
                );
                break;
            case "own_cost":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container"> {
                            ['products', 'services', 'scheduled', 'automatic'].includes(data.type_name) ? 
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        }
                        </div>
                    </ListCell>
                );
                break;                                            
            case "budgeted_vs_selling":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className={`list-cell-container ${Number(data[key]) < 0 ? 'negative-cellvalue' : ''}`} > {
                            ['products', 'services', 'scheduled', 'automatic'].includes(data.type_name) ? 
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        }
                        </div>
                    </ListCell>
                );
                break;
            case "to_be_invoiced":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container"> {
                            ['scheduled', 'automatic'].includes(data.type_name) ? 
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        }
                        </div>
                    </ListCell>
                );
                break;                            
        }
    });

    cells = {
      ...super.defineCells(),
      ...cells
    };

    return cells;
  }
}

class SummaryRow extends ListRow {
  constructor(props, context) {
    super(props, {}, {}, "projects/TabFinancialOverview");
  }

  defineClassName() {
    return "overview-summary-row listElement row flex";
  }

  defineCells() {

    const {rows, sharedData: { currency }} = this.props;
    const { taimerAccount, functions: { presentCurrency }} = this.context;

    let values = {};
    rows.map((row) => {
      Object.keys(row.data || {}).forEach((key) => {
        values[key]
          ? (values[key] += Number(row.data[key] || 0))
          : (values[key] = Number(row.data[key] || 0));
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className={`totalcell ${Number(values[key]) < 0 ? 'negative-cellvalue' : ''}`}
          name="user"
          onlyDisplay
          editable={false}
          alignCell >
              {Number(values[key]) != '0' && values[key] ? presentCurrency(values[key].toFixed(2)) : '-'}
        </ListCell>
      );
    });

    const totalColumns = [
      "type_name",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    return (
      <div className={this.defineClassName()} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}

class BudgetedVsActualCostRow extends InsightTableListRow {
  defineCells() {
    const { data, sharedData: { currency } } = this.props;
    const { taimerAccount, userObject } = this.context;

    const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {   
        style: 'currency',
        currency,
    }).format;

    let cells = {};

    Object.keys(data).forEach(key => {
        switch (key) {
            case "type_name":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container">
                            <p>{this.tr(data[key])}</p>
                        </div>
                    </ListCell>
                );
                break;
            case "budgeted_cost":
            case "actual_cost":
            case "remaining_cost":        
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className="list-cell-container">
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        </div>
                    </ListCell>
                );
                break;
            case "variance":
                cells[key] = (
                    <ListCell onlyDisplay={true}>
                        <div className={`list-cell-container ${Number(data[key]) < 0 ? 'negative-cellvalue' : ''}`} >
                            <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                        </div>
                    </ListCell>
                );
                break;            
        }
    });

    cells = {
      ...super.defineCells(),
      ...cells
    };

    return cells;
  }
}


class TabFinancialOverview extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabFinancialOverview");

        this.state = {
            projectsListData: [],
            invoicedBarData: {},
            profitsData: {income: 0, hourly: 0, expenses: 0, bills: 0},
            invoiceMaterial: {},
            statistics: { 
                revenue: 0, 
                actualProjectMargin: 0,
                budgetedProjectMargin: 0, 
                actualGrossMargin: 0,
                budgetedGrossMargin: 0
            },
            projectListOpen: true,
            emptyInvoiceConfirmationDialogOpen: false,
            dialogData: {},
            selectedProjectFilters: ['active'],
            currency: "EUR",
        }

        this.projectStatuses = [
            {key: "active", id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan},
            {key: "closed", id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b"},
            {key: "onHold", id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f"}
        ];

        this.subprojectsList = React.createRef();
        this.chart = React.createRef();

        this.chartColors = {
            incomeColor: "#2c9ff7",
            costColor: "#f57eab"
        }

        this.profitDataChartOptions = (profitsData) => {return {
                responsive: true,
                cutoutPercentage: 75,
                maintainAspectRatio: false,
                aspectRatio: 1,
                title:{
                    display: false,
                },
                layout: {padding: {right: 50}},
                legend: {
                    display: false
                },           
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (tooltipItem, data) =>
                            ` ${data.labels[tooltipItem.index]} : ${context.functions.presentCurrency(data.datasets[0].data[tooltipItem.index], context.taimerAccount.currency)}`,
                        afterLabel: (tooltipItem, data) => 
                            Object.entries(profitsData).filter((v) => ((!data.type && v[0] != 'income') || (data.type == 'gross' && !['hourly', 'income'].includes(v[0]))))
                                .map(w => `${this.tr(w[0])} : ${context.functions.presentCurrency(w[1], context.taimerAccount.currency)}`)
                    }                
                }                   
            }};

        this.invoiceMaterialChartOptions = this.createInvoiceMaterialChartOptions();

        this.columns = [
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "project_id", name: "project_id", header: this.tr("Nr."), width: 90, resizeable: true, moveable: false },
            { field: "name", name: "name", header: this.tr("Project"), width: 200, showMenu: true, resizeable: true, showResizeMarker: true, moveable: false, hideable: false },
            { field: "customer", name: "customer", header: this.tr("Account"), width: 200 },
            { field: "status", name: "status", header: this.tr("Status"), width: 100 },
            { field: "invoiced", name: "invoiced", header: this.tr("Invoiced"), width: 120, type: "currency" },
            { field: "budgeted_sales", name: "budgeted_sales", header: this.tr("Budgeted sales"), width: 120, type: "currency" },
            { field: "sale_difference", name: "sale_difference", header: this.tr("Difference"), width: 120, type: "currency" },
            { field: "budgeted_costs", name: "budgeted_costs", header: this.tr("Budgeted costs"), width: 120, type: "currency" },
            { field: "actual_costs", name: "actual_costs", header: this.tr("Actual costs"), width: 120, type: "currency" },
            { field: "cost_difference", name: "cost_difference", header: this.tr("Difference"), width: 120, type: "currency"}
        ];

        const staticColumnCommons = {resizeable: false, moveable: false, hideable: false, showMenu: false};

        this.invoicedVsSalesColumns = [
            { field: "type_name", name: "type_name", header: this.tr("Type"), width: 300, ...staticColumnCommons },
            { field: "budgeted_price", name: "budgeted_price", header: this.tr("Budgeted selling_price"), width: 200, ...staticColumnCommons },
            { field: "selling_price", name: "selling_price", header: this.tr("Selling_price"), width: 200, ...staticColumnCommons },
            { field: "own_cost", name: "own_cost", header: this.tr("Own cost"), width: 200, ...staticColumnCommons },
            { field: "budgeted_vs_selling", name: "budgeted_vs_selling", header: this.tr("Budgeted vs selling"), width: 200, ...staticColumnCommons },
            { field: "invoiced", name: "invoiced", header: this.tr("Invoiced"), width: 200, ...staticColumnCommons },
            { field: "to_be_invoiced", name: "to_be_invoiced", header: this.tr("To be invoiced"), width: 200, ...staticColumnCommons },
        ];

        this.BudgetedVsActualCostColumns = [
            { field: "type_name", name: "type_name", header: this.tr("Type"), width: 300, ...staticColumnCommons },
            { field: "budgeted_cost", name: "budgeted_cost", header: this.tr("Budgeted cost"), width: 200, ...staticColumnCommons },
            { field: "actual_cost", name: "actual_cost", header: this.tr("Actual cost"), width: 200, ...staticColumnCommons },
            { field: "variance", name: "variance", header: this.tr("Variance"), width: 200, ...staticColumnCommons },
            { field: "remaining_cost", name: "remaining_cost", header: this.tr("Remaining cost"), width: 200, ...staticColumnCommons }
        ];        

        this.allProjects = [];
        this.projectsFiltered = false;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getInitialData();
    }

    createInvoiceMaterialChartOptions = () => {
        return {
            maintainAspectRatio: false,
            aspectRatio: 2,
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: (tooltipItem) =>
                            ` ${this.context.functions.presentCurrency(tooltipItem.raw, this.props.currency || this.context.taimerAccount.currency || 'EUR')}`,
                    }
                },
                legend: { display: false },
            },
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    stacked: true,
                    position: 'left',
                    beginAtZero: true,
                    ticks: {
                        callback: (value, index, values) => this.context.functions.presentCurrency(value, this.props.currency || this.context.taimerAccount.currency || 'EUR')
                    }
                },
                x: {
                    stacked: true,
                    grid: { display: false }
                }
            },
        };

    }

    getInitialData() {
        const { projects_id, projects_ids, entity } = this.props;

        DataHandler.post({url: `projects/financial_overview`},{ 
            onlyProjectData: true,
            project: projects_id || projects_ids, 
            account: entity == "account" ? this.props.id : null, 
            company: this.props.company, 
            accountHeader: entity == "account" ? this.tr("account") : null,
            company_currency: this.context.taimerAccount.currency
        }).done(data => {
            this.allProjects = data.projects;
            this.updateComponentData(this.getFilteredProjects().map(c => c.id));  
        });      
    }

    updateComponentData = (projects) => {
        const { projects_id, projects_ids, entity } = this.props;

        DataHandler.post({url: `projects/financial_overview`},{ 
            project: projects_id || projects_ids, 
            projects: projects, 
            account: entity == "account" ? this.props.id : null, 
            company: this.props.company, 
            accountHeader: entity == "account" ? this.tr("account") : null,
            company_currency: this.context.taimerAccount.currency
        }).done(
            data => {
                // Update allProjects data
                this.allProjects.forEach((a, index) => {
                    let project = data.projectsListData.find(e => a.id === e.id);
                    if (project)
                        this.allProjects[index] = project;
                })

                data.projectsListData = this.getFilteredProjects();

                this.setState(data);
            }
        );      
    }

    onCheck = (checked) => {
        // After projects are filtered all projects are checked and then we don't want to update component.
        if(this.projectsFiltered) {
            this.projectsFiltered = false;
            return;
        }

        let projects = [];

        if (Array.isArray(checked))
            projects = this.getFilteredProjects(checked).map(c => c.id);
        else
            projects = checked ? this.getFilteredProjects().map(c => c.id) : [];
        
        if (projects.length === 0)
            projects = [""];
        this.updateComponentData(projects);
    }

    filterProjects = () => {     
        if (this.subprojectsList.current) {
            this.projectsFiltered = true;
            this.subprojectsList.current.checkAll(true);
        }
       
        this.updateComponentData(this.getFilteredProjects().map(c => c.id));
    }

    getFilteredProjects(checkedProjects) {
        let projects = _.cloneDeep(this.allProjects);

        const selectedFilters = this.projectStatuses.filter(e => this.state.selectedProjectFilters.includes(e.key)).map(e => e.value);

        if (selectedFilters.length > 0)
            projects = projects.filter(c => selectedFilters.includes(c.locked) || c.id === -1 );

        if (checkedProjects) {
            projects = projects.filter(function (item) {
                return checkedProjects.includes(String(item.id));
            })
        } 

        return projects;
    }

    addInvoice = (evt) => {

        const { addons } = this.context;
        let companies_id, customers_id, startdate, customer_reference, customer_address_id;

        if (this.props.entity == "project") {
            const { project } = this.props;
            this.context.functions.addInvoice({ project, company: project.companies_id }, evt?.ctrlKey || evt?.metaKey || evt?.button === 1);
            return;
        }
        else if (this.props.entity == "account") {
            customer_address_id = this.props.account.invoicing_addresses[0].id;
            companies_id = this.props.company;
            customers_id = this.props.account.id;
            startdate = this.state.projectsListData.reduce((a, v, i) => new Date(v.startdate) < new Date(a.startdate) && i ? v : a).startdate;
        }

        if (addons.invoicing && addons.invoicing.limit && addons.invoicing.used >= addons.invoicing.limit) {
            this.props.toggleBuyDialog("invoicing");
        }
        else {
            const invoiceObject = {
                module: 'invoices', 
                action: 'view', 
                editMode: 1,
                companies_id, 
                projects_ids: this.subprojectsList.current.getCheckedRows().join() || this.props.id, 
                customers_id,
                invoiceType: 2,
                template: 'material',
                start: startdate, 
                end: format(new Date(), 'YYYY-MM-DD'),
                reference: customer_reference,
                customer_address_id,
                preselect: 'financeOverview'
            };
            this.props.updateView(invoiceObject,  evt.button === 1);
        }
    }        

    getProjectFilterTitle() {
        const selectedProjectFilters = this.state.selectedProjectFilters;
        let filterTitle = "";

        if (selectedProjectFilters.length === 1)
            filterTitle = this.projectStatuses.find(e => e.key === selectedProjectFilters[0]).label;
        else if (selectedProjectFilters.length === 0 || selectedProjectFilters.length === this.projectStatuses.length)
            filterTitle = this.tr("All");
        else
            filterTitle = this.projectStatuses.find(e => e.key === selectedProjectFilters[0]).label + ", " + this.projectStatuses.find(e => e.key === selectedProjectFilters[1]).label;

        return filterTitle;
    }

    render () {
        const { projects_id, editable } = this.props;
        const { statistics, projectsListData, invoicedBarData, profitsData, invoiceMaterial, projectListOpen, salesByType, costsByType } = this.state;
        const { taimerAccount, versionId, addons, userObject, functions: { presentCurrency } } = this.context;
        const currency = this.props.currency || taimerAccount.currency || 'EUR';

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        function createDatasets() {

            const netProfitData = [profitsData.income.toFixed(2), (profitsData.hourly + profitsData.bills + profitsData.expenses).toFixed(2)];
            const grossProfitData = [profitsData.income.toFixed(2), (profitsData.bills + profitsData.expenses).toFixed(2)];

            const profitData = {
                net: {datasets: [{
                    data: netProfitData,
                    backgroundColor: [ this.chartColors.incomeColor, this.chartColors.costColor ]
                }],
                labels: [
                    this.tr("Income"),
                    this.tr("Costs")
                ],
                icons: [
                    <div className="legend-icon-wrapper" style={{backgroundColor: '#dceffe'}}><Income style={{color: this.chartColors.incomeColor}}/></div>,
                    <div className="legend-icon-wrapper" style={{backgroundColor: '#fbd5e4'}}><Costs style={{color: this.chartColors.costColor}}/></div>
                ]},

                gross: {datasets: [{
                    data: grossProfitData,
                    backgroundColor: [ this.chartColors.incomeColor, this.chartColors.costColor ]
                }],
                labels: [
                    this.tr("Income"),
                    this.tr("Costs")
                ],
                icons: [
                    <div className="legend-icon-wrapper" style={{backgroundColor: '#dceffe'}}><Income style={{color: this.chartColors.incomeColor}}/></div>,
                    <div className="legend-icon-wrapper" style={{backgroundColor: '#fbd5e4'}}><Costs style={{color: this.chartColors.costColor}}/></div>
                ],
                type: 'gross'
                }
            };

            const profitDataLegend = (type) => 
                <div className="overview-custom-legend">
                    {profitData[type].labels.map((l, i) => {
                        return (
                            <div className="legend-text">
                                {profitData[type].icons[i]}
                                {l}
                                <p>{currencyFormatter(profitData[type].datasets[0].data[i])}</p>
                            </div>
                        );
                    })}
                
                </div>;

  
            const invoiceMaterialData = {
                datasets: [{
                    data: Object.values(invoiceMaterial).map(i => i.toFixed(2)),
                    backgroundColor: [ '#2d9ff7', '#ffa082', '#f7bc59', colors.greenish_cyan, '#4c4ba8', '#f7548f', "#f5a623" ]
                }],
                labels: [
                    this.tr("Hours"),
                    this.tr("Bills"),
                    this.tr("Expenses"),
                    this.tr("Traveling expenses"),
                    this.tr("Scheduled invoices"),
                    this.tr("Automatic invoices"),
                    this.tr("Quotes")
                ]
            }            

            return {
                netProfit: {
                    data:profitData.net,
                    legend: profitDataLegend('net'), 
                    result: netProfitData.reduce((acc, v) => (acc - v))
                },
                grossProfit: {
                    data: profitData.gross,
                    legend: profitDataLegend('gross'), 
                    result: grossProfitData.reduce((acc, v) => (acc - v))
                },
                invoiceMaterial: invoiceMaterialData,
            };
        }

        const allDatasets = createDatasets.bind(this)();

        const calcPercentage = (setName, index) => {
            const rowValue = allDatasets[setName].datasets[0].data[index];
            const totalValue = allDatasets[setName].datasets[0].data.reduce((acc, x) => acc*1 + x*1);

            if (rowValue <= 0 || totalValue <= 0)
                return 0;
            else
                return((rowValue * 100) / totalValue).toFixed(2);
        }

        const showPercentage = (actual, budgeted) => {
            if (statistics.budgetedProjectMargin == 0)
                return '-';
            if (statistics.actualProjectMargin == 0)
                return '0 %';
            return `${(statistics.actualProjectMargin/statistics.budgetedProjectMargin*100).toFixed(0)} %`;
        }
        
        const projectFilterTitle = this.getProjectFilterTitle();

        return (
            <div id="finance-overview">

                <div className="project-list-filters">
                    <div className="column project-list" onClick={() => this.setState({ projectListOpen: !this.state.projectListOpen })}>
                        <div className="header-container">{this.tr('Select project to view data')}</div>
                        {!projectListOpen ? <ExpandMore /> : <ExpandLess />}
                    </div>
                    <div className='right'>
                        {this.props.renderNoteToInvoiceCreator && this.props.renderNoteToInvoiceCreator()}       
                        <InsightDropDown
                                title={this.tr("Project status")}
                                titleLabel={projectFilterTitle}
                                color="#e3f4ff"
                                titleColor="#2d9ff7"
                                tabs={this.projectStatuses}
                                customComponent={(this.projectStatuses || []).map((o) => {
                                    return (
                                        <div
                                            key={o.key}
                                            onClick={(e) => 
                                                this.setState(state => {
                                                    if (state.selectedProjectFilters.includes(o.key)) { 
                                                        state.selectedProjectFilters = state.selectedProjectFilters.filter(ts => ts != o.key);
                                                    }
                                                    else
                                                        state.selectedProjectFilters.push(o.key);

                                                    return state;
                                                }, () => this.filterProjects())
                                            }
                                            className="column-options-dropdown-item">
                                            <div className="check-box">
                                                {(this.state.selectedProjectFilters.includes(o.key)) && (
                                                    <CheckBox className="icon" />
                                                )}
                                            </div>
                                        {o.name}
                                        </div>
                                    );
                                })}
                            />
                    </div>

                </div>
                {projectListOpen && <List
                    ref={this.subprojectsList}
                    className="projects-list"
                    allChecked
                    fluid
                    data={projectsListData}
                    columns={this.columns}
                    height="fitRemaining"
                    parentKey="parentId"
                    listRowType={OverviewRow}
                    treeData={true}
                    parentsExpandedOnInit={true}
                    noStateData={true}
                    onCheckAll={() => this.onCheck(true)}
                    onUncheckAll={() => this.onCheck(false)}
                    rowProps={{
                        onCheck: (x) => this.onCheck(x),
                        showWholeTrees: this.state.showWholeTrees,
                        showTreeStructures: this.state.showTreeStructures,
                        statuses: this.projectStatuses,
                        currency: currency
                    }}
                />}

                <div className="full-width overview-container">

                    <InsightBlock noPadding={true} noHeader={true} width={6}>
                        <div className="invoiced-bar">
                          <BlockWithIcon
                            header={this.tr("Invoiced")}
                            value={currencyFormatter(invoicedBarData.invoiced || "0")}
                            background="#edfcf9"
                            icon={
                              <InvoiceProjectcard style={{ color: "white", height: 45, width: 45 }} />
                            }
                          />
                          <BlockWithIcon
                            header={this.tr("Invoice material")}
                            value={currencyFormatter(invoicedBarData.material || "0")}
                            background="#fef6e9"
                            icon={
                              <InvoiceMaterial style={{ color: "white", height: 45, width: 45 }} />           }
                          />                      
                          <BlockWithIcon
                            header={this.tr("Subtotal - Invoiced")}
                            value={currencyFormatter(invoicedBarData.result || "0")}
                            background="#dceffe"
                            icon={<SubtotalInvoice style={{ color: "white", height: 45, width: 45 }} />}
                          />
                          <BlockWithIcon
                            header={this.tr("Total costs")}
                            value={currencyFormatter(invoicedBarData.total_costs || "0")}
                            background="#fdeaf1"
                            icon={<TotalCost style={{ color: "white", height: 45, width: 45 }} />}
                          />
                        <BlockWithIcon
                            tooltip={this.tr("Backlog: Project Value - (Billed hours + Unbilled Hours + Expenses + Purchase Orders)")}
                            header={this.tr("Backlog")}
                            value={currencyFormatter(invoicedBarData.backlog || "0")}
                            background="#fff0eb"
                            icon={<Backlog style={{ color: "#FFA082", height: 45, width: 45 }} />}
                        />

                        </div>
                    </InsightBlock>

                    <div className="overview-row">
                        <InsightBlock rootClassName="finance-overview-double" title={this.tr("Net profit")} width={3} canCollapse={false}>

                            <div className="overview-nutchart-wrapper" >
                                <div className={`overview-nutchart-result ${allDatasets.netProfit.result < 0 ? 'negative' : ''}`}>
                                    {currencyFormatter(allDatasets.netProfit.result) || "0"} 
                                    <p >{this.tr("Income - (Hourly cost + bills + expenses)")}</p>
                                </div>
                                <div className="overview-nutchart-graph" >
                                    <Doughnut
                                        ref={this.chart}
                                        data={allDatasets.netProfit.data}
                                        options={this.profitDataChartOptions(profitsData)}
                                    />                            
                                </div>
                                {allDatasets.netProfit.legend}                  
                            </div>

                        </InsightBlock>

                        <InsightBlock rootClassName="finance-overview-double project-revenue" title={this.tr("Project revenue")} width={3} canCollapse={false}>
                            <ProjectRevenue className="background-icon" />
                            <div className="overview-subheader" > {this.tr("Sales quote total value")} </div>
                            <div className="overview-result-left">{currencyFormatter(statistics.revenue || "0")}</div>
                            <div className="overview-result-sub">{this.tr("Subtotal")}</div>
                        </InsightBlock>
                    </div>

                    <div className="overview-row">
                        <InsightBlock rootClassName="finance-overview-double" title={this.tr("Gross profit")} width={3} canCollapse={false}>
                            
                            <div className="overview-nutchart-wrapper" >
                                <div className={`overview-nutchart-result ${allDatasets.grossProfit.result < 0 ? 'negative' : ''}`}>
                                    {currencyFormatter(allDatasets.grossProfit.result) || "0"} 
                                    <p >{this.tr("Income - (bills + expenses)")}</p>
                                </div>
                                <div className="overview-nutchart-graph" >
                                    <Doughnut
                                        data={allDatasets.grossProfit.data}
                                        options={this.profitDataChartOptions(profitsData)}
                                    />                                
                                </div>
                                {allDatasets.grossProfit.legend}                             
                            </div>

                        </InsightBlock>

                        <InsightBlock rootClassName="finance-overview-double project-margin" title={this.tr("Project margin")} width={3} canCollapse={false}>
                            <ProjectMargin className="background-icon" />
                            <div className="overview-subheader" > {this.tr("Sales quote budgeted costs vs. actual costs")} </div>
                            <div className="overview-rowwrapper">
                                <div className="overview-subrow">
                                    <div className="subrow-header">{this.tr("Actual project margin")}
                                        <p>{this.tr("Budgeted project margin")}</p>
                                    </div>
                                    <div className="subrow-result" data-after={showPercentage(statistics.actualProjectMargin, statistics.budgetedProjectMargin)} >
                                        {currencyFormatter(statistics.actualProjectMargin || "0")}
                                        <p>{currencyFormatter(statistics.budgetedProjectMargin || "0")}</p>
                                    </div>
                                </div>

                                <div className="overview-subrow">
                                    <div className="subrow-header">{this.tr("Actual grossmargin")}
                                        <p>{this.tr("Budgeted costs - (approved bills + expences)")}</p>
                                    </div>
                                    <div className="subrow-result" data-after={showPercentage(statistics.actualGrossMargin, statistics.budgetedGrossMargin)} >
                                        {currencyFormatter(statistics.actualGrossMargin || "0")}
                                        <p>{currencyFormatter(statistics.budgetedGrossMargin || "0")}</p>
                                    </div>
                                </div>
                            </div>                            
                        </InsightBlock>

                    </div>

                    <InsightBlock 
                        rootClassName="finance-overview-single" 
                        title={this.tr("Invoice material")}
                        filterComponents={this.props.canCreateInvoice && this.state.allowInvoicing == 1 && <Button className={`green`} size="large" onMouseUp={this.addInvoice}>{this.tr("ADD INVOICE")}</Button>} >
                        <div className="overview-barchart-wrapper">                           
                            <Bar
                                data={allDatasets.invoiceMaterial}
                                options={this.invoiceMaterialChartOptions}
                            />
                        </div>
                        <div className="overview-barchart-legend">
                            <div className="overview-barlegend-table">
                                <div className="table-area">
                                    {Object.values(invoiceMaterial).length > 0 && <Table className="summary">
                                        <TableHead className="grey">
                                            <TableRow>
                                                <TableCell className="title">{this.tr("Type")}</TableCell>
                                                <TableCell className="title">{this.tr("Value")}</TableCell>
                                                <TableCell align="right" className="title">{this.tr("% of tot")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allDatasets.invoiceMaterial.labels.map((x, i) => 

                                            <TableRow className="row" >
                                                <TableCell className="label">
                                                    <div className="ball-indicator" style={{ background: allDatasets.invoiceMaterial.datasets[0].backgroundColor[i] }}></div>
                                                    <div className="label-text">{x}</div>
                                                </TableCell>
                                                <TableCell className="number-value" >
                                                    {presentCurrency(allDatasets.invoiceMaterial.datasets[0].data[i], currency)}
                                                </TableCell>
                                                <TableCell className="number-value" align="right">
                                                    {`${ calcPercentage('invoiceMaterial', i)} %`}
                                                </TableCell>                                                
                                            </TableRow>)}
                                        </TableBody>
                                        <TableFooter className="grey">
                                            <TableRow>
                                                <TableCell className="title">{this.tr("Total")}</TableCell>
                                                <TableCell className="title">{presentCurrency(allDatasets.invoiceMaterial.datasets[0].data.reduce((acc, x) => acc*1 + x*1), currency)}</TableCell>
                                                <TableCell align="right" className="title">100 %</TableCell>
                                            </TableRow>
                                        </TableFooter>                                        
                                    </Table>}
                                </div>
                            </div>                        
                        </div> 
                    </InsightBlock>

                    <InsightTable
                        loaded
                        noSlider
                        height={400}
                        hideDefaults={true}
                        blockProps={{
                            largeTitle: true,
                            rootClassName: 'block',
                            wrapperClass: "block-wrapper",
                            headerClass: 'block-header'
                        }}
                        width={6}
                        height="300"
                        title={this.tr("Invoiced vs Sales Price")}
                        rowType={InvoicedVSSalesRow}
                        summaryRowType={SummaryRow}
                        data={salesByType}
                        columns={this.invoicedVsSalesColumns}
                        currency={currency} />

                    <InsightTable
                        loaded
                        noSlider
                        height={400}
                        hideDefaults={true}
                        blockProps={{
                            largeTitle: true,
                            rootClassName: 'block',
                            wrapperClass: "block-wrapper",
                            headerClass: 'block-header'
                        }}
                        width={6}
                        title={this.tr("Budgeted Cost vs. Actual Cost")}
                        rowType={BudgetedVsActualCostRow}
                        summaryRowType={SummaryRow}
                        data={costsByType}
                        columns={this.BudgetedVsActualCostColumns}
                        currency={currency} />

                  {this.state.emptyInvoiceConfirmationDialogOpen && <EmptyInvoiceConfirmationDialog
                    open
                    onDialogClose={() => {
                      this.setState({ emptyInvoiceConfirmationDialogOpen: false });
                    }}
                    data={this.state.dialogData} 
                  />}
                </div>                                
            </div>
        );
    }
};
TabFinancialOverview.propTypes = {
    id: PropTypes.string.isRequired,
};

export default TabFinancialOverview;
