import React from "react";
import Button from "@mui/material/Button";
import mainLogo from "./../images/google-calendar.png";
import LinearProgress from "@mui/material/LinearProgress";
import { SettingsContext } from "../../SettingsContext";
import withStyles from '@mui/styles/withStyles';
import TaimerComponent from "../../TaimerComponent";
import OutlinedField from "../../general/OutlinedField";
import DataHandler from "./../../general/DataHandler";

const styles = theme => ({
	groupprogress: {
		width: "100%",
	},
});

class CalendarLoginView extends TaimerComponent {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "taimercalendar/CalendarComponents/CalendarLoginView");
		this.state = {
			authcode: "",
			getauth: true,
		};
	}

	componentDidMount() {
		super.componentDidMount();
	}

	connectGoogle = () => {
		DataHandler.get({ url: `calendar/google/connect` })
			.done(response => {
				if (response.url) {
					sessionStorage.setItem("previousUrl", window.location);
					sessionStorage.setItem("nextAction", "google-login");
					window.open(response.url, "_blank");
                }
                this.setState({ getauth: false });
			})
			.fail(err => {
				console.log("failed");
			});
	};

	authenticateGoogle = () => {
		DataHandler.post({ url: "calendar/google/authenticate" }, { authcode: this.state.authcode })
			.done(response => {
				if (!response.error) {
                    setTimeout(() => {
                        this.props.changeGoogleAuthStatus();
                    }, 1000);
				} else {
					console.log(response.error); //there should be a prompt for the user too
				}
			})
			.fail(err => {
				console.log(err);
			});
	};

	connectOutlook = () => {

		DataHandler.get({url: `calendar/office/login`}).done(response => {
			if (response.url){
				sessionStorage.setItem("previousUrl", window.location);
				sessionStorage.setItem("nextAction", "calendar-office-login");
				window.location = response.url;
			}
		}).fail(err => {
			console.log(err);
		});
	};

	/**
	 * CalendarLoginView renderer
	 *
	 */
	render() {
		const { tr } = this;
		const { classes, loading } = this.props;
		const { getauth } = this.state;

		return (
			<div id="calendar-signin">
				<div className="left">
					<div className="left-container">
						<h1>
							<b>{this.tr("Activate your Calendar")}</b>
						</h1>
						<div className="info">
							<div className="infotext">
								{this.tr("activate your google or Office365 calendar in taimer to view your calendar entries")} 
								<br />
								<br />
							</div>
						</div>

							<div className="buttons google">
								{getauth ? (
									<Button className="buttons-connect" onClick={() => this.connectGoogle()} disabled={loading}>
										{tr("Activate Google Calendar")}
									</Button>
									
								) : (
									<div className="buttons-authentication">
                                        <OutlinedField
											className="buttons-authentication-textfield"
											value={""}
											onChange={evt => this.setState({ authcode: evt.target.value })}
											label={tr("google code")}
										/>
										<Button
											className="buttons-authentication-button"
											onClick={() => this.authenticateGoogle()}
										>
											{tr("authenticate")}
										</Button>
									</div>
								)}
							</div>

							<div className="buttons office">
								{getauth ? (
									<Button className="buttons-connect" onClick={() => this.connectOutlook()} disabled={loading}>
										{tr("Activate O365")}
									</Button>
									
								) : '' }
							</div>							
						
					</div>
				</div>
				<div className="right">
					<img src={mainLogo} />
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(CalendarLoginView);
