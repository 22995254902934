import React from 'react';

import OutlinedField from "./OutlinedField";
import { validators } from "./OutlinedField";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import "./ToggleableOutlinedField.css";


class ToggleableOutlinedField extends React.Component {
    static defaultProps = {
        outlinedFieldProps: {},
        editMode: false,
        editable: true,
        onEditClicked: () => {}, // Why did I 
        onEditClick: undefined,
        onSave: () => {},
        onSaveError: () => {},
        onCancel: () => {},
        style: {},
        validation: [],
        value: undefined
    };
    
   
    constructor(props) {
        super(props); 

        this.outlinedField = React.createRef();

        this.state = {
            value: props.value,
            error: false
        };

        this.originalValue = props.value;

        this.checkForInitialValues = this.checkForInitialValues.bind(this);
        this.validateValue         = this.validateValue.bind(this);
        this.handleEditClick       = this.handleEditClick.bind(this);
        this.handleSave            = this.handleSave.bind(this);
        this.handleCancel          = this.handleCancel.bind(this);
        this.handleOnChange        = this.handleOnChange.bind(this);
        this.handleOnKeyUp         = this.handleOnKeyUp.bind(this);
        this.setOriginalValue      = this.setOriginalValue.bind(this);
        this.setOutlinedFieldValue = this.setOutlinedFieldValue.bind(this);
    }


    componentDidUpdate(prevProps) {
        if(this.props.value !== prevProps.value) {
            this.originalValue = this.props.value;

            this.setState({ value: this.props.value });
        }

        this.checkForInitialValues();
    }


    checkForInitialValues() {
        if(this.props.value !== undefined && this.state.value === undefined && this.originalValue === undefined) {
            this.setOriginalValue(this.props.value);
        }
    }


    validateValue(value) {
        const validationFunctions = this.props.validation.map(v => validators[v]);

        if(validationFunctions.length === 0) {
            return true;
        }

        return validationFunctions.map(f => f(value)).filter(v => !v).length === 0;
    }


    handleEditClick() {
        this.checkForInitialValues();

        const fn = typeof(this.props.onEditClick) === "function" ? this.props.onEditClick : this.props.onEditClicked;

        fn();
    }


    handleSave() {
        if(this.state.error) {
            this.props.onSaveError(this.state.value);

            return;
        }

        this.props.onSave(this.state.value);
    }


    handleCancel() {
        this.setState({ 
            value: this.originalValue,
            error: false
        }, () => {
            this.setOutlinedFieldValue(this.state.value);
            this.props.onCancel(this.state.value);
        });
    }


    handleOnChange(e) {
        const value = e.target.value;
        const error = !this.validateValue(value);

        this.setState({
            value: value,
            error: error
        });
    }


    handleOnKeyUp(e) {
        if(e.key === "Enter") {
            this.handleSave();
        }

        if(e.key === "Escape") {
            this.handleCancel(); 
        }
    }


    setOriginalValue(value) {
        this.setState({
            value: value
        });
        
        this.originalValue = value; 
    }


    setOutlinedFieldValue(value) {
        this.outlinedField.current.setValue(value);
    }
    
   
    render() {
        const { editable, editMode } = this.props;
        const outlinedFieldProps     = {
            ...this.props.outlinedFieldProps,
            disabled: !this.props.editMode,
            onChange: this.handleOnChange,
            useOnChange: true,
            onKeyUp: this.handleOnKeyUp,
            value: this.state.value,
            error: this.state.error
        };

        if(outlinedFieldProps.hasOwnProperty("validation")) {
            delete outlinedFieldProps.validation;
        }

        const className = `toggleableOutlinedFieldContainer 
            ${outlinedFieldProps.hasOwnProperty("fullWidth") && outlinedFieldProps.fullWidth && !this.props.style.hasOwnProperty("width")
                ? 
                "fullWidth" 
                : 
                ""
            }

            ${editMode ? "editMode" : ""}`;

        return (
            <div className={className} style={this.props.style}>
                <div className="buttonsContainer">
                    {(!editMode && editable) && <EditIcon className="editIcon" onClick={this.handleEditClick} />}
                    {editMode && <CheckIcon className="saveIcon" onClick={this.handleSave} />}
                    {editMode && <CloseIcon className="cancelIcon" onClick={this.handleCancel} />}
                </div>
                <OutlinedField {...outlinedFieldProps} ref={this.outlinedField} />
            </div>
        );
    }
}

export default ToggleableOutlinedField;
