import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from "./../../general/DataHandler";
import { SettingsContext } from '../../SettingsContext';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import CheckboxCell from "../../list/cells/CheckboxCell";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import ConfirmationDialog from "./../../list/dialogs/ConfirmationDialog";

import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TrashCanIcon from "@mui/icons-material/Delete";

// import "./Efina.css";
import "./Mediapro.css";
// import "./../pages/Project.css";

import cloneDeep from "lodash/cloneDeep";


class SimpleCheckRow extends PropsOnlyListRow {
    defineCells() {
        const { data, checked } = this.props;

        return {
            name: 
                <ListCell editable={false} value={data.name} />,
            checked:
                <CheckboxCell checked={checked} onEdited={() => this.props.listRef.check(this.props.data.id)} />
        };
    }
}

class SimpleNameRow extends PropsOnlyListRow {
    defineCells() {
        return {
            delete: <ListCell className="noBg pointer" permanentEditMode={true}><TrashCanIcon onClick={() => this.delete()} /></ListCell>,
            name: <TextInputCell value={this.props.data.name} onEdit={value => this.setDataAndUpdate({ name: value })} />
        };
    }
}


class Mediapro extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/api-settings/Mediapro");


        this.pipelineList       = React.createRef();
        this.accountTypeList    = React.createRef();
        this.serviceProductList = React.createRef();

        this.state = {
            pipelines: [],
            accountTypes: [],
            settings: null,
            confirmationDialog: false,
            confirmationDialogProps: {},
            initialized: false
        };

        this.initializeData                = this.initializeData.bind(this);
        this.onEdit                        = this.onEdit.bind(this);
        this.fetchCompanysPipelines        = this.fetchCompanysPipelines.bind(this);
        this.fetchAccountTypes             = this.fetchAccountTypes.bind(this);
        this.fetchCompanysMediaproSettings = this.fetchCompanysMediaproSettings.bind(this);
        this.back                          = this.back.bind(this);
        this.deactivate                    = this.deactivate.bind(this);
    }


    componentDidMount() {
        this.initializeData();
    }


    async initializeData() {
        this.setState({ 
            accountTypes: await this.fetchAccountTypes(),
            pipelines: await this.fetchCompanysPipelines(), 
            serviceProducts: await this.fetchCompanysServiceProducts(),
            settings: await this.fetchCompanysMediaproSettings(), 
            initialized: true 
        });
    }


    async refreshServiceProducts() {
        this.setState({
            serviceProducts: await this.fetchCompanysServiceProducts()
        });
    }


    async onEdit() {
        if(this.state.settings == null) {
            return;
        }

        const pipelines    = this.pipelineList.current.getData().filter(d => d._checked).map(d => d.id);
        const accountTypes = this.accountTypeList.current.getData().filter(d => d._checked).map(d => d.id);

        const url      = `settings/integrations/${this.props.company}/${this.state.settings.id}`;
        const settings = cloneDeep(this.state.settings);

        await DataHandler.put({ url: url }, { value: JSON.stringify({ send_projects_in_pipelines: pipelines, send_units_of_types: accountTypes })});

        settings.value.send_projects_in_pipelines = pipelines;
        settings.value.send_units_of_types        = accountTypes;

        this.setState({ settings: settings });
    }


    fetchCompanysPipelines() {
        return DataHandler.get({ url: `subjects/pipelines/${this.props.company}` }).then(response => response.map(pl => ({ ...pl, value: pl.id })));
    }


    fetchAccountTypes() {
        return DataHandler.get({ module: 'settings', action: 'get_account_types' }).then(data => data.account_types.sort((a, b) => {
            if(a.en < b.en) {
                return -1;
            } else if(a.en > b.en) {
                return 1;            
            }

            return 0;
        }));
    }


    fetchCompanysServiceProducts() {
        return DataHandler.get({ url: `settings/integrations/${this.props.company}/mediapro/service_products` });
    }


    async fetchCompanysMediaproSettings() {
        const url             = `settings/integrations/${this.props.company}/mediapro/all_settings`;
        const defaultSettings = JSON.stringify({ send_projects_in_pipelines: [], send_units_of_types: [] });
        let settings;

        try {
            settings = await DataHandler.get({ url: url }); 
        } catch(ex) {
            const id = await DataHandler.post({ url: url }, { value: defaultSettings });
            settings = { id: id, integration: "mediapro", name: "all_settings", value: defaultSettings };
        }

        settings.value = JSON.parse(settings.value);

        return settings;
    }


    back() {
        this.context.functions.updateView({ module: "settings", action: "index", group: "integrations", page: "default", subpage: "" });
    }


    deactivate() {
        DataHandler.delete({url: `settings/company/${this.props.company}/mediapro`}).done(this.back);
    }


    render() {
        const { settings }              = this.state;
        const activePipelines           = settings !== null && settings.value && settings.value.send_projects_in_pipelines ? settings.value.send_projects_in_pipelines : [];
        const activeAccountTypes        = settings !== null && settings.value && settings.value.send_units_of_types ? settings.value.send_units_of_types : [];

        const dColConf                  = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const activePipelinesMap        = {};
        const activeAccountTypesMap     = {};

        let { pipelines, accountTypes, serviceProducts } = cloneDeep(this.state);

        pipelines.forEach(pipeline => activePipelinesMap[pipeline.id] = false);
        activePipelines.forEach(pipelineId => activePipelinesMap[pipelineId] = true);

        accountTypes.forEach(type => activeAccountTypesMap[type.id] = false);
        activeAccountTypes.forEach(typeId => activeAccountTypesMap[typeId] = true);

        pipelines = cloneDeep(pipelines).map(pipeline => {
            pipeline.checked = activePipelinesMap[pipeline.id];

            return pipeline;
        });

        accountTypes = cloneDeep(accountTypes).map(type => {
            type.checked = activeAccountTypesMap[type.id];
            type.name    = type.en;

            return type;
        });

        return (
            <div id="integration-mediapro" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>

                    <h3>{this.tr("Mediapro settings")}</h3>

                    {<Button className="red deactivate" size="large" onClick={this.deactivate}>{this.tr("Deactivate")}</Button>}
                </div>
                <div className="settings">
                    <div className="container">
                        <div className="flexColumn">
                            <h4>{this.tr("Mediapro Pipelines")}</h4>
                            <p>{this.tr("Choose which pipelines' projects should be sent to Mediapro.")}</p> 
                            <p>{this.tr("A project will be sent to Mediapro when it's moved to one of the selected pipelines and when its edited while being in any of the selected pipelines. Projects in the Won Deals pipeline will be sent by default.")}</p>
                        </div>
                        <div className="flexColumn">
                            <h4>{this.tr("Mediapro Account Types")}</h4>
                            <p>{this.tr("Choose which types of units will be sent to Mediapro.")}</p>
                            <p>{this.tr("A unit will be sent to Mediapro when it's edited, given that it has one the selected account types.")}</p>
                        </div>
                    </div>

                    <div className="container">
                        <div className="flexColumn">
                            <List
                                ref={this.pipelineList}
                                className="settingsList"
                                checkedRows={pipelines.filter(d => activePipelinesMap[d.id]).map(d => d.id)}
                                height="auto"
                                fluid
                                rowHeight={44}
                                listRowType={SimpleCheckRow}
                                showPageSelector={false}
                                noStateData={true}
                                columns={[
                                    { width: 85, name: "name", header: this.tr("Pipeline"), ...dColConf },
                                    { width: 25, name: "checked", header: this.tr("Send to Mediapro"), ...dColConf}
                                ]}
                                onCheck={this.onEdit}
                                data={pipelines}
                            />
                        </div>
                        <div className="flexColumn">
                            <List
                                ref={this.accountTypeList}
                                className="settingsList"
                                checkedRows={accountTypes.filter(d => activeAccountTypesMap[d.id]).map(d => d.id)}
                                height="auto"
                                fluid
                                rowHeight={44}
                                listRowType={SimpleCheckRow}
                                showPageSelector={false}
                                noStateData={true}
                                columns={[
                                    { width: 85, name: "name", header: this.tr("Account type"), ...dColConf },
                                    { width: 25, name: "checked", header: this.tr("Send to Mediapro"), ...dColConf}
                                ]}
                                onCheck={this.onEdit}
                                data={accountTypes}
                            />
                        </div>
                    </div>

                    {false && <div className="container">
                        <div className="flexColumn">
                            <h4>{this.tr("Mediapro Service Products")}</h4>
                        </div>
                        <div className="flexColumn"></div>
                    </div>}

                    {false && <div className="container">
                        <div className="flexColumn">
                            <Button className="green" onClick={() => this.serviceProductList.current.addNewRow({ name: "" })} size="large">{this.tr("Add new service product")}</Button>
                            <List
                                ref={this.serviceProductList}
                                className="settingsList"
                                height="auto"
                                fluid
                                rowHeight={44}
                                listRowType={SimpleNameRow}
                                rowProps={{
                                    onCreate: async (data, row) => {
                                        const resp = await DataHandler.post({ url: `settings/integrations/${this.props.company}/mediapro/service_products` }, data);

                                        row.setData("id", resp);
                                    },
                                    onUpdate: async (data) => {
                                        await DataHandler.put({ url: `settings/integrations/${this.props.company}/mediapro/service_products/${data.id}` }, data);
                                    },
                                    onDelete: (data) => {
                                        const closeDialog = () => {
                                            this.setState({
                                                confirmationDialog: false,
                                                confirmationDialogProps: {}
                                            });
                                        };

                                        this.setState({
                                            confirmationDialog: true,
                                            confirmationDialogProps: {
                                                data: { text: this.tr(`Do you really want to permanently delete "${data.name}"? Please be aware that this action can not be undone.`) },
                                                onDialogClose: closeDialog,
                                                onDialogSave: async () => {
                                                    try {
                                                        await DataHandler.delete({ url: `settings/integrations/${this.props.company}/mediapro/service_products/${data.id}` });

                                                        this.setState({ serviceProducts: this.state.serviceProducts.filter(d => d.id != data.id) });
                                                    } catch(ex) {
                                                        console.warn(ex);
                                                    } finally {
                                                        closeDialog();
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }}
                                showPageSelector={false}
                                noStateData={true}
                                columns={[
                                    { width: 10, name: "delete", ...dColConf },
                                    { width: 85, name: "name", header: this.tr("Service product"), ...dColConf },
                                ]}
                                onCheck={this.onEdit}
                                data={serviceProducts}
                            />
                        </div>
                        <div className="flexColumn"></div>
                    </div>}
                </div>
                {this.state.confirmationDialog && <ConfirmationDialog {...this.state.confirmationDialogProps} />}
            </div>
        );
    }
}

Mediapro.contextType = SettingsContext;

export default Mediapro;
