import React from 'react';

import _ from 'lodash';
import isEqual from "lodash/isEqual"; 
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import FileSaver from 'file-saver';

import { withSnackbar } from 'notistack';

import { intersection } from "../../general/Set";

import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import ProjectKanban from "../../projects/ProjectKanban";
import { treeFormatDataForList, makeMap, makeMapOfPrimitives } from "../ListUtils";
import Utils from "./../../general/Utils.js";
import List from "./../List";
import ProjectListRow from "./../rows/ProjectListRow";
import PageSelector from "./../PageSelector";
import DataHandler from "./../../general/DataHandler";
import SettingsSlider from '../../settings/SettingsSlider';
import { default as ProjectSettings } from '../../settings/pages/Project';
import AdvancedSearch from "../../search/AdvancedSearch";
import { DateRangePicker } from './../../general/react-date-range/src';
import TaimerComponent from "../../TaimerComponent";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import ImportDialog from "../../dialogs/imports/ImportAccountDialog";
import Checkbox from "../../general/Checkbox";
import OutlinedField from "./../../general/OutlinedField";
import ProgressNotification from "./../../general/ProgressNotification";
import ErrorNotification from "./../../general/ErrorNotification";
import NotificationContent from "./../../general/NotificationContent";
import TreeViewSelection from "./../../general/TreeViewSelection";
import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions
} from "../../dimensions/helpers"

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import "./../../general/MuiTabs.css";
import CopyProjectDialog from "../../dialogs/CopyProjectDialog";
import SetAsSubProjectDialog from '../../dialogs/SetAsSubProjectDialog';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Switch from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import CloseDialogContent from "../../projects/CloseDialogContent";

import { format, addDays, addMonths, endOfMonth, startOfMonth, isValid } from "date-fns";

import SpecialPermissionSlider from '../../general/SpecialPermissionSlider';
import ProjectStatusReasonDialog from '../../dialogs/ProjectStatusReasonDialog';
import { getAutocompleteDataForDialog } from '../../resourcing/helpers';

/* context */
import { SettingsContext } from './../../SettingsContext';


import "./ProjectList.css";
import "./../../general/MuiTabs.css";

/* overlay */
import ProjectListOverlay from '../overlays/ProjectListOverlay';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import PageTopSection from '../../general/PageTopSection';
import { FormatListBulleted, TableChart, ViewColumnRounded } from '@mui/icons-material';
import HoursReportSlider from '../../projects/HoursReportSlider';
import WithTabs from '../../navigation/WithTabs';
import InsightDropDown from '../../dashboard/insights/InsightDropDown';
import colors from '../../colors';

// TODO: There's an abstract version of this function in ListUtils; modify this file to use that 
// function and get rid of this one.
// options = hierarchical data
function createBobOptions(options, level = 0, parent = undefined) {
    let finalOptions = [];

    for(let i = 0; i < options.length; ++i) {
        let tempOpt = options[i];
        let opt = {
            value: tempOpt.data.id,
            name: tempOpt.data.name,
            id: tempOpt.data.id,
            label: tempOpt.data.label,
            data: tempOpt.data,
            deleted: tempOpt.data.deleted,
            children: tempOpt.children,
            parentProps: { data: parent !== undefined ? parent.data : {}, children: parent !== undefined ? parent.children : [], parentProps: parent !== undefined ? parent.parentProps : {} },
            level: level,
            isChild: level > 0,
            isLastChild: level > 0 && i === options.length - 1
        };

        finalOptions.push(opt);

        if(tempOpt.children && tempOpt.children.length > 0)
            finalOptions = [...finalOptions, ...createBobOptions(tempOpt.children, level + 1, opt)];
    }

    return finalOptions;
}

class ProjectList extends TaimerComponent {
    static contextType  = SettingsContext;
    static defaultProps = {
        perpage: 30,
        customerId: false,
        projectDefaults: {},
        view: 'list',
        stickySearchKey: "project_list",
        pipeline_id: 0,
        useStickySearch: true,
        allowProjectCreation: null,
        showLockedUsersWithTag: true,
    };

    pipelineSetManually = false;

    constructor(props, context) {
        super(props, context, "list/lists/ProjectList");

        context.functions.emptyCacheOnUnload("project_list");

        this.cacher                       = context.cacher;
        this.initialFetchDone             = false;
        this.forceOverrideComponentUpdate = false;
        this.savedParams                  = {};

        const date = this.props.date && new Date(this.props.date);
        const thisMonth = this.props.date ? { start: startOfMonth(date), end: endOfMonth(date) } : { start: '', end: '' };

		let preferedCompany = context.functions.getPreferedCompany(context.userObject.project_read_companies.map(x => x.id));

		let initalCompany = context.userObject.project_read_companies.find(el => el.id == preferedCompany) ?? {};
        if(!initalCompany && context.userObject.project_read_companies.length > 0) {
            initalCompany = context.userObject.project_read_companies[0];
		}

        if (props.companies_id) {
            initalCompany = {id: props.companies_id};
        }

        this.filtersInitialValues = {
            status: 0,
            expectedClosingDate: 0,
            salesAgent: 0,
            projectStatus: -1,
            projects_pipelines_id: this.props.pipeline_id || 0,
            company: props.onlyThisCompany ? props.onlyThisCompany : (initalCompany ? initalCompany.id : false),
            freetext: ""
        };

        this.initialDate = {
            startDate: isValid(thisMonth.start) ? format(thisMonth.start, "YYYY-MM-DD") : '',
            endDate: isValid(thisMonth.end) ? format(thisMonth.end, "YYYY-MM-DD") : '',
            key: "selection"
        }
        this.initialExpectDate = {
            startDate:  '',
            endDate:  '',
            key: "selection"
        }

        this.userTypeAutocompleteClasses = [
            'all_users',
            'dynamic_right_employees',
            'employees',
            'modified_by',
            'privileged_employees',
            'project_team_member_pool',
            'users',
            'users_for_filters'
        ];

        this.userTypeDataHeaders = {project_manager_user_id: 'project_manager_fullname'};

        const treeViewSettings = JSON.parse(localStorage.getItem("projectlist_treeview_settings")) || {  
            showTreeStructures: true, 
            showWholeTrees: true,
            showMatchesAndChildren: false
        };

        this.state = {
            filters: { ...this.filtersInitialValues, company: this.props.companies_id ? this.props.companies_id : this.filtersInitialValues.company },
            dateRange: { ...this.initialDate },
            expectedDateRange: { ...this.initialExpectDate },
            funnelRange: { ...this.initialExpectDate },
            eventDateRange: { ...this.initialExpectDate },
            data: [],
            dimensions: [],
            dimensionItems: {},
            dimensionAutoCompleteData: {},
            dimensionAutoCompleteDataFilters: {},
            page: 1,
            setDefaultProjectCategory:false,
            pageCount: 1,
            projectCount: 0,
            perpage: this.props.perpage,
            hoursReportProject: null,
            stickySearchInitialized: !this.props.useStickySearch,
            totals: {
                value: 0,
                margin: 0,
                tracked: 0
            },
            hasProjects: true,
            filtersChanged: !this.props.useStickySearch,
            searchTerms: this.getInitialSearchTerms(),
            stickyRun: false,
            companies: context.userObject.project_read_companies,
            companyCurrency: "EUR",
			viewMenuOpen: false,
            viewMenuAnchor: undefined,
            ...treeViewSettings,
            currentDialogProps: undefined,
            defaultPipeline: 0,
            tagPoolSettingsData: {
                "use_separate_tag_pools": 0,
                "create_tags_only_from_settings": 1
            },
            use_events: 0,
        };

        this.fieldMap = {
            project: "name",
            branchofbusiness_id: "category",
            product_structures_id:"reporting_group",
            project_team: "team_members",
            team_groups: "team_groups",
            // revenue: "costest_sum",
            // salesmargin: "costest_margin",
            type: "projects_pipelines_id",
            project_manager_user_id: "users_id",
            projects_sales_states_id: "projects_sales_states_id"
        };

       /*  this.expectedClosingDates = [
            { value: 0, label: this.tr("All") },
            { value: "today", label: this.tr("Today") },
            { value: "week", label: this.tr("This week") },
            { value: "month", label: this.tr("This month") },
            { value: "next_month", label: this.tr("Next month") },
            { value: "year", label: this.tr("This year") }
        ];  */       

        this.previousStateFilters = { ...this.state.filters };
        this.sortTerms            = undefined;
        this.autoCompleteData     = false;
        this.autoCompleteDataMap  = {}
        this.fields               = [];
        this.oneprojecttype       = false;

        this.list            = React.createRef();
        this.advancedSearch  = React.createRef();

        this.promptBatchUpdate                       = this.promptBatchUpdate.bind(this); 
        this._promptBatchUpdate                      = this._promptBatchUpdate.bind(this); 
        this.batchUpdate                             = this.batchUpdate.bind(this);
        this._batchUpdate                            = this._batchUpdate.bind(this);
        this.showProgressNotification                = this.showProgressNotification.bind(this);
        this.showErrorNotification                   = this.showErrorNotification.bind(this);
        this.showChangesAppliedNotification          = this.showChangesAppliedNotification.bind(this);
        this.showChangesAppliedPartiallyNotification = this.showChangesAppliedPartiallyNotification.bind(this);
        this.closeDialogAndReset                     = this.closeDialogAndReset.bind(this);
        this.toggleViewState                         = this.toggleViewState.bind(this);
        this.getCachedAutoCompleteData               = this.getCachedAutoCompleteData.bind(this);
        this.getAutoCompleteData                     = this.getAutoCompleteData.bind(this);
        this.updateComponentData                     = this.updateComponentData.bind(this);
        this.setFilters                              = this.setFilters.bind(this);
        this.filtersAreInInitialState                = this.filtersAreInInitialState.bind(this);
        this.fetchData                               = this.fetchData.bind(this);
        this.handleCounts                            = this.handleCounts.bind(this);
        this.setData                                 = this.setData.bind(this);
        this.onEdit                                  = this.onEdit.bind(this);
        this.addRow                                  = this.addRow.bind(this);
        this.addProject                              = this.addProject.bind(this);
        this.export                                  = this.export.bind(this);
        this.openDialog                              = this.openDialog.bind(this);
        this.confirmDialog                           = this.confirmDialog.bind(this);
        this.closeDialog                             = this.closeDialog.bind(this);
        this.onToolbarExportClick                    = this.onToolbarExportClick.bind(this);
        this.onToolbarEditClick                      = this.onToolbarEditClick.bind(this);
        this.onToolbarDeleteClick                    = this.onToolbarDeleteClick.bind(this);
        this.beforeNew                               = this.beforeNew.bind(this);
        this.onPerPageChange                         = this.onPerPageChange.bind(this);
        this.onPageChange                            = this.onPageChange.bind(this);
        this.onSortRows                              = this.onSortRows.bind(this);
        this.addToAutoCompleteData                   = this.addToAutoCompleteData.bind(this);
        this.initializeStickySearch                  = this.initializeStickySearch.bind(this);
        this.saveStickySearch                        = this.saveStickySearch.bind(this);
        this.initDimensions                          = this.initDimensions.bind(this);

        this.translations = {
            edit: this.tr("Edit"),
            rowsSelected: this.tr("row(s) selected"),
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };

        this.statuses = [
            {id: 0, name: this.tr("All")},
            {id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan},
            {id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b"},
            {id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f"},
            {id: '3', name: this.tr('Lost'), label: this.tr('Lost'), value: '3', color: "#f52b2b", hideInSearch: true},
            
        ];

        this.defaultPipelines = [
            {id: "-1", name: this.tr("Won deals"), value: "-1", label: this.tr("Won deals")},
            {id: "-5", name: this.tr("Internal projects"), value: "-5", label: this.tr("Internal projects")}
        ];

        // Dialogs -->
        this.dialogs = {
            deleteConfirmation: ConfirmationDialog,
            import: ImportDialog,
            promptBatchUpdate: MassDialog,
            delete: MassDialog,
            editSelections: MassDialog,
            propertyEditor: MassDialog,
            CopyProjectDialog: CopyProjectDialog,
            /* archiveProjectDialog: ConfirmationDialog, */
            archiveProjectDialog: MassDialog,
            setAsSubProjectDialog: SetAsSubProjectDialog,
        };


        this.basicDialogControls = () => ({
            cancelText: this.tr("Cancel"),
            confirmText: this.tr("Confirm"),
            onCancel: this.closeDialog,
            onCloseClick: this.closeDialog
        });


        // Will be included in all the other mass editing dialogs.
        this.propertyPickerFields = (value = undefined) => ({
            propertyToEdit: {
                _type: "drop",
                label: this.tr("Choose a property to edit"),
                onChange: async (propertyToEdit) => {
                    // For now, ResourceDialog is included independently
                    // without including it into CoreDialog. -->
                    if(propertyToEdit === "task" || propertyToEdit === "milestone") {
                        this.closeDialog(() => this.openResourceDialog(propertyToEdit))
                        
                        return;
                    }
                    // <-- Resource dialog.

                    let dialogProps = this.propertyEditorDialogFields[propertyToEdit];

                    if(dialogProps.hasOwnProperty("sharedData") && typeof(dialogProps.sharedData) === "function") {
                        dialogProps.sharedData = await dialogProps.sharedData();
                    }

                    this.dialogProps.propertyEditor.dialogProps = {
                        ...this.basicDialogControls(),
                        ...dialogProps
                    };

                    this.openDialog("propertyEditor", {
                        title: `${this.translations.edit}`,
                        subtitle: `(${this.list.current ? (this.list.current.getAllChecked() ? this.state.projectCount : this.list.current.getCheckedRows().length) : 0} ${this.translations.rowsSelected})`
                    });
                },
                value: value,
                options: [
                    { value: "task", label: this.tr("Task") },
                    { value: "milestone", label: this.tr("Milestone") },
                    { value: "pipeline", label: this.tr("Pipeline & Stage") },
                    { value: "typesAdd", label: this.tr("Add project types") },
                    { value: "typesRemove", label: this.tr("Remove project types") },
                    { value: "tagsAdd", label: this.tr("Add tags") },
                    { value: "tagsRemove", label: this.tr("Remove tags") },
                    { value: "status", label: this.tr("Status") },
                    { value: "category", label: this.tr("Category") },
                    this.props.entity == "account" && { value: "reporting_group", label: this.tr("Reporting group") },
                    { value: "project_manager", label: this.tr("Project manager") },
                    { value: "sales_agent", label: this.tr("Sales agent") },
                    { value: "startdate", label: this.tr("Start date") },
                    { value: "enddate", label: this.tr("End date") }
                ].filter(x => x)
            }
        });

        // One key in this.dialogProps corresponds to one dialog.
        // the dialogProps property of each dialog object will be passed to MassDialog/CoreDialog.
        // This happens by setting said dialogProps object as this.state.currentDialogProps.
        //
        // The dialogs are defined like this because we need to be able to easily
        // reset these definitions; they may be mutated based on the needs of each dialog.
        this.originalDialogProps = () => ({
            deleteConfirmation: {
            },
            delete: {
                dialogType: "delete",
                open: this.state.currentDialog === "delete",
                onDialogClose: this.closeDialog,
                dialogProps: {
                    header: this.tr("Delete Project(s)") + "?",
                    warning: () => {
                        if (this.state.currentDialogProps.process === "close_one")
                            return <CloseDialogContent data={this.state.dialogData} />;

                        let massDelete = true;
                        if (this.state.currentDialogProps.process === "delete_one")
                            massDelete = false;

                        return <DeleteDialogContent massDelete={massDelete} data={this.state.dialogData} />;
                    },
                    close: this.closeDialog,
                    onCloseClick: this.closeDialog,
                    onConfirm: () => {
                        if (this.state.currentDialogProps.process === "close_one") {
                            this.closeProject(this.state.currentDialogProps);
                            return;
                        }

                        if (this.state.currentDialogProps.process === "delete_one") {
                            this.deleteProject(this.state.currentDialogProps);
                            return;
                        }

                        this.deleteProjectsMass();
                    }
                }
            },
            // NOTE:
            // Not in use yet.
            promptBatchUpdate: {
                dialogType: "confirm",
                open: this.state.currentDialog === "promptBatchUpdate",
                dialogProps: {
                    ...this.basicDialogControls(),
                    title: this.tr("Edit"),
                    confirmText: this.tr("Confirm"),
                    cancelText: this.tr("Cancel")
                },
            },
            // The dialog where nothing but a dropdown for editable properties is shown.
            editSelections: {
                dialogType: "edit",
                dialogProps: {
                    ...this.basicDialogControls(),
                    fields: this.propertyPickerFields(),
                    disableConfirm: true
                }
            },
            // The actual property editor dialog.
            propertyEditor: {
                dialogType: "edit",
                dialogProps: {
                    // The rest of this will be filled in from 
                    // this.propertyEditorDialogFields according to which 
                    // property the user wants to edit.
                }
            },
            CopyProjectDialog: {},
            archiveProjectDialog: {
                dialogType: "delete",
                open: this.state.currentDialog === "delete",
                onDialogClose: this.closeDialog
            },
            setAsSubProjectDialog: {
                onClose: this.closeDialog
            }
        });

        this.dialogProps = this.originalDialogProps();

        // EDITOR DIALOG CONFIGS:
        // Each key corresponds to a selection in the property selection dropdown.
        // Each entry defines the content's of a dialog, except for its title and its buttons (basicDialogControls),
        // which are included into the configuration when creating the property editor dialog.
        this.propertyEditorDialogFields = {
            pipeline: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("pipeline"),
                    pipeline: {
                        _type: "drop",
                        label: this.tr("Choose a pipeline"),
                        options: sharedData.pipelines
                    },
                    stage: {
                        _type: "drop",
                        label: dialogData.pipeline ? this.tr("Choose stage") : this.tr("Please choose a pipeline first"),
                        options: sharedData.sales_states.filter(ss => {
                            return (Number(dialogData.pipeline) < 0 && ss.project_type == Math.abs(dialogData.pipeline)) || ss.projects_pipelines_id == dialogData.pipeline;
                        })
                    }
                }),
                onConfirm: (data) => {
                    const { pipeline, stage } = data;
                    const newPipeline         = parseInt(pipeline);

                    this.promptBatchUpdate({
                        confirmationSummary: [
                            { name: this.tr("Pipeline"), value: this.autoCompleteDataMap.pipelines[pipeline].name }, 
                            { name: this.tr("Stage"), value: this.autoCompleteDataMap.sales_states[stage].name } 
                        ],
                        onConfirm: async () => {
                            const showWonReasonDialog = newPipeline == '-1' && (this.list.current.getAllChecked() || (this.list.current.getCheckedData() || []).findIndex(p => p.status == '0') != -1);
                            if (showWonReasonDialog && this.projectWonReasonIsInUse()) {
                                this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="won" project={{ companies_id: this.state.filters.company, multiple: true }} onSave={async (reason, status_reason_comment) => {
                                    const errorsIn = await this.batchUpdate({ data: {
                                        projects_pipelines_id: newPipeline > 0 ? pipeline : "0",
                                        status: newPipeline > 0 ? "0" : String(newPipeline * -1),
                                        status_reason_id: reason.id,
                                        status_reason_comment
                                    } });
        
                                    this.batchUpdate({
                                        data: { projects_sales_states_id: stage },
                                        showNotification: false,
                                        uncheckAfter: false,
                                        excludeIds: errorsIn
                                    });
                                }} />);
                            } else {
                                const errorsIn = await this.batchUpdate({ data: {
                                    projects_pipelines_id: newPipeline > 0 ? pipeline : "0",
                                    status: newPipeline > 0 ? "0" : String(newPipeline * -1)
                                } });
    
                                this.batchUpdate({
                                    data: { projects_sales_states_id: stage },
                                    showNotification: false,
                                    uncheckAfter: false,
                                    excludeIds: errorsIn
                                });
                            }
                        }
                    })
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            typesAdd: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("typesAdd"),
                    types: {
                        _type: "dropMultiple",
                        label: this.tr("Add types"),
                        options: sharedData.types.filter(el => el.deleted < 1)
                    }
                }),
                onConfirm: (data) => {
                    const names = [], ids = [];

                    data.types.forEach(t => {
                        ids.push(t.id);
                        names.push(t.name);
                    });

                    this.promptBatchUpdate({ name: ids.length > 1 ? this.tr("Add types") : this.tr("Add type"), value: names.join(", ") }, { project_types: ids.join(",") });
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            typesRemove: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("typesRemove"),
                    types: {
                        _type: "dropMultiple",
                        label: this.tr("Remove types"),
                        options: sharedData.types
                    }
                }),
                onConfirm: (data) => {
                    const names = [], ids = [];

                    data.types.forEach(t => {
                        ids.push(t.id);
                        names.push(t.name);
                    });

                    this.promptBatchUpdate({ name: ids.length > 1 ? this.tr("Remove types") : this.tr("Remove type"), value: names.join(", ") }, { project_types: ids.map(t => t.id).join(","), delete: true }) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            status: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("status"),
                    status: {
                        _type: "drop",
                        label: this.tr("Choose a status"),
                        options: sharedData.statuses
                    }
                }),
                onConfirm: (data) => {
                    // Lost status gives a locked value of 3, is not correct, should be 1. Not even sure if the lost status should be here because
                    // a lost project is just a project closed from the pipeline.
                    if (data.status == '3') {
                        data.status = '1';
                    }
                    // True passed as the last argument, which means the list's checkboxes will all be unchecked. 
                    // This is because the projects whose statuses we've changed, might not be in the current set anymore.
                    this.promptBatchUpdate({ name: this.tr("Status"), value: this.autoCompleteDataMap.statuses[data.status].name }, { locked: data.status }, true) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            category: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("category"),
                    category: {
                        _type: "treeDrop",
                        label: this.tr("Choose a category"),
                        options: sharedData.bobOptions.map(o => o.data),
                        parentKey: "parent_id"
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Category"), value: this.autoCompleteDataMap.branch_of_business[data.category].name }, { branchofbusiness_id: data.category }) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
        
            reporting_group: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("reporting_group"),
                    reporting_group: {
                        _type: "treeDrop",
                        label: this.tr("Choose a reporting group"),
                        options:  sharedData.reportingOptions.map(o => o.data),
                        parentKey: "parent_id"
                    }
                }),
                onConfirm: (data) => {
                    
                    this.promptBatchUpdate({ name: this.tr("Reporting group"), value: this.autoCompleteDataMap.reporting_groups[data.reporting_group].name }, { product_structures_id: data.reporting_group }) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            
            project_manager: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("project_manager"),
                    project_manager: {
                        _type: "drop",
                        label: this.tr("Choose a project manager"),
                        options: sharedData.employees.map(u => {
                            u.label = `${u.name}`;

                            return u;
                        }).sort((a, b) => {
                            return a.label - b.label; 
                        })
                    }
                }),
                onConfirm: (data) => {
                    const user = this.autoCompleteDataMap.employees[data.project_manager];
                    const name = `${user.name}`;

                    this.promptBatchUpdate({ name: this.tr("Project manager"), value: name }, { users_id: data.project_manager }) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            sales_agent: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("sales_agent"),
                    sales_agent: {
                        _type: "drop",
                        label: this.tr("Choose a sales agent"),
                        options: sharedData.privileged_employees.map(u => {
                            u.label = `${u.name}`; 

                            return u;
                        }).sort((a, b) => {
                            return a.label - b.label; 
                        })
                    }
                }),
                onConfirm: (data) => {
                    const user = this.autoCompleteDataMap.privileged_employees[data.sales_agent];
                    const name = `${user.name}`;

                    this.promptBatchUpdate({ name: this.tr("Sales agent"), value: name }, { status_users_id: data.sales_agent }) 
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            startdate: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("startdate"),
                    startdate: {
                        _type: "date",
                        label: this.tr("Choose a date")
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Start date"), value: format(data.startdate, this.context.userObject.dateFormat) }, { startdate: format(data.startdate, "YYYY-MM-DD") });
                }
            },
            enddate: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("enddate"),
                    enddate: {
                        _type: "date",
                        label: this.tr("Choose a date")
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("End date"), value: format(data.enddate, this.context.userObject.dateFormat) }, { enddate: format(data.enddate, "YYYY-MM-DD") });
                }
            },
            tagsAdd: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("tagsAdd"),
                    tags: {
                        _type: "dropMultiple",
                        allowCreate: true,
                        label: this.tr("Add tags"),
                        options: sharedData.tags
                    } 
                }),
                onConfirm: (data) => {
                    const newTags = data.tags.filter(tag => {
                        return tag.__isNew__; 
                    }).map(tag => {
                        delete tag.__isNew__;

                        tag.value = tag.label;
                        tag.name  = tag.label;
                        tag.id    = tag.label;

                        return tag;
                    });

                    if(newTags.length > 0) {
                        this.addToAutoCompleteData("tags", newTags, false); 
                        this.addToAutoCompleteData("project_tags", newTags, false); 
                    }

                    const tagLabels = data.tags.map(d => d.label);

                    this.promptBatchUpdate({ name: this.tr("Add tags"), value: tagLabels.join(", ") }, { tags: tagLabels })
                },
                sharedData: () => this.getCachedAutoCompleteData()
            },
            tagsRemove: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("tagsRemove"),
                    tags: {
                        _type: "dropMultiple",
                        label: this.tr("Enter the tags you want to remove"),
                        options: sharedData.tags
                    } 
                }),
                onConfirm: (data) => {
                    const tagLabels = data.tags.map(d => d.label);
                    
                    this.promptBatchUpdate({ name: this.tr("Remove tags"), value: tagLabels.join(", ") }, { tags: tagLabels, delete: true });
                },
                sharedData: () => this.getCachedAutoCompleteData()
            }
        };
        // <-- Dialogs.
        
        this.newRow = {
            project_id: this.context.addons.custom_project_id ? "" : -1,
            customers_id: this.props.customerId ? this.props.customerId : undefined,
            customer: undefined,
            project: "",
            locked: "-1", // previously status
            log_created: format(new Date(), "YYYY-MM-DD"),
	        closing_date:  format(addMonths(new Date(), 1), "YYYY-MM-DD"),
            startdate: format(new Date(), "YYYY-MM-DD"),
            enddate: format(addMonths(new Date(), 1), "YYYY-MM-DD"),
            status_changed: "0000-00-00",
            sales_state_changed: "0000-00-00",
            probability_percent: 0,
            project_manager_user_id: this.context.userObject.usersId,
            status_users_id: this.context.userObject.usersId,
            project_team: '',
            team_groups: [],
            revenue_text: "",
            salesmargin_text: "",
            hours_text: "",
            project_type: 0,
            parentId: null,
            ...this.autoCompleteData.projectDefaults,
            ...this.props.projectDefaults, // Defaults from list,
            companies_id: this.state.filters.company,
            dimension_teams_id: undefined,
            quotes: false,
            _quotePopUpOpen: false,
            _projectIdIsDuplicate: false,
            _projectIdFieldIsEmpty: false,
            _isCreatingNew: false
        };



        this.rowProps = {
            addons: this.context.addons,
            showHoursReport: (hoursReportProject) => {
                this.setState({ hoursReportProject });
            },
            projectWonReasonIsInUse: this.projectWonReasonIsInUse,
            projectLostReasonIsInUse: this.projectLostReasonIsInUse,
            archiveProject: async (projectData, onConfirm = () => {}) => {
                try {
                    const data = await DataHandler.get({ url: `projects/${projectData.id}/archivecheck/${projectData.companies_id}` });

                    if(data['sub_project_data']['sub_projects'].length > 0) {
                        this.openCloseDialog(data['sub_project_data']);
                        return;
                    } 

                    const hasUninvoiced = data['archiveProject'] > 0;
                    this.openDialog('archiveProjectDialog', {
                        deleteDisabled: false,
                        close: this.closeDialog,
                        confirmButtonText: this.tr("Archive").toUpperCase(),
                        header: this.tr("Archive project") + "?",
                        warning: () => {
                            return <p id="project-delete-dialog-warning">{this.tr("Are you sure you want to archive project") + ": " + projectData.name + " (" + projectData.project_id + ")" + (hasUninvoiced ? ", " + this.tr("because it has uninvoiced rows?") : "?")}</p>;
                        },
                        onConfirm: () => {
                            onConfirm();

                            this.closeDialog();
                        }
                    });

                    return;

                } catch(e) {
                    this.props.enqueueSnackbar(this.tr("An error occured while performing the action you requested. You can try again, but if the error persists, please contact support."), {
                        variant: "error",
                    });
                }
            },
            onCreate: async (data) => {
                for(let i in this.fieldMap)
                    data[this.fieldMap[i]] = data[i];

                data.custom = {};

                for(let i in data) {
                    if(i.substr(0, 7) !== "custom_")
                        continue;

                    let id    = i.split("_")[1];
                    let value = data[i];

                    data.custom[id] = value && typeof value === "object" && value.hasOwnProperty("id") ? value.id : value
                }

                if(data.projects_pipelines_id < 0) {
                    data.status = Math.abs(data.projects_pipelines_id);
                    data.projects_pipelines_id = 0;
                }

                if (!data.team_members) {
                    data.team_members = await DataHandler.get({url: `projects/account_team/${data.customers_id}/${data.companies_id}/-1`})
                    if(!data.team_members) {
                        data.team_members = [];
                    }
                }
                if (!data.team_members.find(t => t === data.status_users_id)) {
                    data.team_members.push(data.status_users_id);
                }
                if (!data.team_members.find(t => t === data.project_manager_user_id)) {
                    data.team_members.push(data.project_manager_user_id);
                }
                data.deletable = true;
                return DataHandler.post({ url: "projects" }, data).done(() => this.updateLimitInfo());
            },
            onUpdate: (data) => {
                const { enqueueSnackbar } = this.props;
                for(let i in this.fieldMap)
                    data[this.fieldMap[i]] = data[i];
                delete data.revenue;
                delete data.salesmargin;

                if(!data.team_members) 
                    data.team_members = [];
                else 
                    data.team_members = data.team_members.split(',');

                if (!data.team_members.find(t => t === data.status_users_id)) {
                    data.team_members.push(data.status_users_id);
                }

                if (!data.team_members.find(t => t === data.project_manager_user_id)) {
                    data.team_members.push(data.project_manager_user_id);
                }

                if (Number(data.type) < 0) data.status = String(Number(data.type) * -1);
                if (Number(data.type) > 0) data.status = "0";

                // apparently the value 3 is for lost projects (only to show "lost" in the project status column) -> not meant to be saved to database 
                // locked value of 1 is transformed to 3 when getting project list rows in Project DAO.
                if (data.locked == '3') {
                    data.locked = '1';
                }

                DataHandler.put({ url: `projects/${data.id}` }, data)
                    .done(r => {
                        this.fetchData({ page: this.state.page });
                    })
                    .fail(e => {
                        const resp = e.responseJSON;

                        if(resp && resp.error === "invalid_state") {
                            enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                                variant: "error",
                            });
                        }
                        else if (resp && resp.error === "PARENT_LOCKED") {
                            enqueueSnackbar(this.tr(`Cannot change status: Parent is locked`), {
                                variant: "error",
                            });
                            return;
                        }
                        else if (resp && resp.error === "CUSTOMER_NOT_ALLOWED") {
                            enqueueSnackbar(this.tr(`Cannot create project for this customer`), {
                                variant: "error",
                            });
                            return;
                        }

                        this.fetchData({ page: this.state.page });
                    });
            },
            onDelete: (data) => {
                if(data.id < 0) {
                    this.list.current.removeRow(data.id);
                } else {
                    this.openDialog("delete", {...data, process: "delete_one",  confirmDisabled: !data.deletable, wider: true});
                }
            },
            company: this.state.filters.company,
            refreshList: this.fetchData,
            updateLimitInfo: this.updateLimitInfo,
            addToAutoCompleteData: this.addToAutoCompleteData,
            enqueueSnackbar: this.props.enqueueSnackbar,
            updateView: this.props.updateView,
            showWholeTrees: this.state.showWholeTrees,
            showTreeStructures: this.state.showTreeStructures,
            openDialog: this.openDialog,
            openCloseDialog: this.openCloseDialog,
            closeDialog: this.closeDialog,
            getOneProjectTypeSetting: this.getOneProjectTypeSetting,
            isProjectLimitReached: this.isProjectLimitReached,
            toggleBuyDialog: this.props.toggleBuyDialog
        }
    }

    getInitialSearchTerms = () => {
        if (this.props.customerId) {
            return {
                advanced_search_criteria: {
                    filters: {
                        customer: [
                            {
                                operator: 'eq',
                                type: 'entity',
                                value: this.props.customerId
                            }
                        ]
                    }
                },
                currentFilters: [
                    {
                        bundledElements: [],
                        entityMode: true,
                        identifier: this.props.customerId,
                        isBundle: false,
                        name: 'customer',
                        nameTransl: this.tr("Account"),
                        operator: 'eq',
                        operatorTransl: this.tr('is'),
                        value: this.props.customerName
                    }
                ],
                mode: 'advanced'
            }
        }

        return undefined
    }
    
    updateLimitInfo = () => {
        if (this.context.addons && this.context.addons.projects && this.context.addons.projects.limit) {
            this.context.functions.whoami();
        }
    }

    async initDimensions(company) {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
            this.setState({ 
                dimensions: [],
                dimensionItems: {},
                dimensionAutoCompleteData: {},
                dimensionAutoCompleteDataFilters: {},
            });

            return;
        }

        const data = await getDimensionAutoCompleteData(company);

        this.setState({ ...data }); 
    }

    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.props.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                return;            
            }

            if (this.props.companies_id) {
                response.filters.company = this.props.companies_id;
            }

            if (!this.context.userObject.project_read_companies.find(el => el.id == response.filters.company)) {
				response.filters.company = this.filtersInitialValues.company;
			}

            if(this.props.pipeline_id !== 0) {
                response.filters.projects_pipelines_id = this.props.pipeline_id;
            }

            const { functions: { updateView }, userObject: { sidebarStyle } } = this.context;
            if (this.props.mode =='pipeline' || this.context.userObject.sidebarStyle == '1') {
                updateView({ pipeline_id: response.filters.projects_pipelines_id, replace: true }, false, undefined, undefined, true);
            } else {
                response.filters.projects_pipelines_id = this.state.filters?.projects_pipelines_id;
            }
            this.setState({...response, defaultPipeline: this.state.defaultPipeline });
        }).fail(response => {

        }).always((response, _, request) => {
            this.setState({ 
                stickySearchInitialized: true, 
                filtersChanged: true, 
                stickyRun: request.status === 204 ? undefined : true
            }, () => this.updateComponentData(false, true, true, true));

        });
    }


    saveStickySearch(additional = {}) {
        if (!this.state.stickySearchInitialized)
            return;

        let stateToSave = {};
        
        [
            "filters",
            "dateRange",
            "expectedDateRange",
            "funnelRange",
            "eventDateRange",
            "page",
            "perpage",
            "searchTerms",
            "showTreeStructures",
            "showWholeTrees",
            "showMatchesAndChildren",
            "defaultPipeline"
        ].forEach(f => stateToSave[f] = this.state[f]);

        stateToSave = { ...stateToSave, ...additional };

        DataHandler.post({ url: `saved_search/sticky/${this.props.stickySearchKey}`, }, { search: stateToSave });
    }


    validateView = () => {
        if (this.props.view !== "list" && this.props.view !== "kanban") {
            this.toggleViewState();
        }
    }


    componentDidMount() {
        super.componentDidMount(); 
        if(this.props.useStickySearch) {
            this.initializeStickySearch();
        }
        else {
            this.updateComponentData(false, true, true);
        }
        this.listenReset();

        this.initDimensions(this.state.filters.company);
        window.addEventListener("projectCreated", this.updateAfterCreation);
    }

    updateAfterCreation = () => {
        this.fetchData();
    }

    promptBatchUpdate(config = {}) {
        if(arguments.length > 1) {
            this._promptBatchUpdate(...arguments);
            return;
        }

        const defConf = {
            confirmationSummary: [],
            data: {},
            uncheckAfter: false,
            onConfirm: undefined
        };

        const fConfig = { ...defConf, ...config };

        this._promptBatchUpdate(...Object.keys(defConf).map(f => fConfig[f])); 
    }


    async _promptBatchUpdate(confirmationSummary = [], data, uncheckAfter = false, onConfirm = undefined) {
        const length = this.list.current ? (this.list.current.getAllChecked() ? this.state.projectCount : this.list.current.getCheckedRows().length) : 0;
        let ids      = [];
        let projects = [];
        let hasSubProjects = false;
        let activateProjects = false;

        // If changing status to "Locked" also lock all subprojects. Shows warning if any subprojects.
        if (Number(data.locked) === 1) {
            ids = (this.list.current.getAllChecked()) ? await this.fetchData({ onlyIds: true }) : this.list.current.getCheckedRows();
            projects = await DataHandler.post({ url: `projects/getSubProjects/${this.state.filters.company}` }, {projects: ids})
            if (projects.length > ids.length)
                hasSubProjects = true;

            data.ids = projects ? [...projects] : ids;
        }

        // If changing status to "Active" or "On hold", allow changing only for projects whose parent is not locked (or parent is in selected projects).
        else if (Number(data.locked) === -1 || Number(data.locked) === 2) {
            ids = (this.list.current.getAllChecked()) ? await this.fetchData({ onlyIds: true }) : this.list.current.getCheckedRows();
            projects = await DataHandler.post({ url: `projects/checkParentStatuses/${this.state.filters.company}` }, {projects: ids})
            activateProjects = projects;
            data.ids = activateProjects.allowed_projects;
        }

        this.openDialog("promptBatchUpdate", {
            title: `${this.translations.edit}`,
            subtitle: `(${length} ${this.translations.rowsSelected})`,
            text: (hasSubProjects || Number(data.locked) === -1 || Number(data.locked) === 2) ? this.lockMassConfirmationText(activateProjects, data.locked) : this.tr("Are you sure you want to apply the changes to the selected projects? Once applied, the changes can not be canceled or undone."),
            onConfirm: onConfirm === undefined ? () => {
                const showLostReasonDialog = data.locked == '1' && (this.list.current.getAllChecked() || (this.list.current.getCheckedData() || []).findIndex(p => p.status == '0') != -1);
                if (showLostReasonDialog && this.projectLostReasonIsInUse()) {
                    this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="lost" project={{ companies_id: this.state.filters.company, multiple: true }} onSave={(reason, status_reason_comment) => {
                        data.status_reason_id = reason.id;
                        data.status_reason_comment = status_reason_comment;
                        this.batchUpdate(data, uncheckAfter);
                    }} />);
                } else {
                    this.batchUpdate(data, uncheckAfter);
                }
            } : onConfirm,
            disableConfirm: activateProjects && activateProjects.allowed_projects.length === 0,
            confirmationSummary: Array.isArray(confirmationSummary) ? confirmationSummary : [confirmationSummary]
        });
    }

    lockMassConfirmationText(activateProjects, locked) {
        const status = Number(locked) === -1 ? "activated" : "put on hold";
        return (
            <>
                {!activateProjects && (
                    <>
                        <p>{this.tr("Are you sure you want to apply the changes to the selected projects? Once applied, the changes can not be canceled or undone.")}</p>
                        <p>{this.tr("This will also lock all sub projects!")}</p>
                    </>
                )}
                 {activateProjects && (
                    <>
                        {activateProjects.allowed_projects.length === 0 &&
                            <p>{this.tr(`None of the selected projects can be ${status}!`)}</p>
                        }
                        {activateProjects.allowed_projects.length > 0 &&
                            <p>{this.tr("Are you sure you want to apply the changes to ${amount} projects? Once applied, the changes can not be canceled or undone.", {amount: activateProjects.allowed_projects.length})}</p>
                        }
                        {activateProjects.non_allowed_projects.length > 0 &&
                            <p>{activateProjects.non_allowed_projects.length + " " + this.tr(`projects cannot be ${status} because parent is locked!`)}</p>
                        }
                    </>
                )}
            </>
        )
    }

    batchUpdate(config = {}) {
        if(arguments.length > 1)
            return this._batchUpdate(...arguments);
         
        const defConf = {
            data: {},
            uncheckAfter: false,
            showNotification: true,
            excludeIds: []
        };

        const fConfig = { ...defConf, ...config };

        return this._batchUpdate(...Object.keys(defConf).map(f => fConfig[f]));
    }


    // uncheckAfter === true will uncheck all rows on the list after changes have been applied.
    // Used when an update leads to the selected rows no longer being visible in the current set.
    async _batchUpdate(data, uncheckAfter = false, showNotification = true, excludeIds = []) {
        // https://i.imgflip.com/3ncsld.jpg
        const ids          = data.locked ? data.ids : (this.list.current.getAllChecked()) ? await this.fetchData({ onlyIds: true }) : this.list.current.getCheckedRows();
        const listProjects = makeMap(this.list.current.getData(), "id");
        const allMap       = makeMapOfPrimitives(ids);

        if (!ids || ids.length === 0) {
            this.closeDialog();

            this.showErrorNotification({
                message: this.tr("None of the projects can be updated!"),
                buttonText: this.tr("Dismiss"),
                key: "batchUpdateFieldError",
                preventDuplicate: true
            });

            return;
        }

        excludeIds.forEach(id => allMap[id] = false);

        const projects = ids.filter(id => allMap[id]).map(id => ({ ...data, id: id }));

        let notificationKey = showNotification ? this.showProgressNotification() : undefined;

        this.closeDialog();

        try {
            const response = await DataHandler.put({ url: `projects/mass` }, { projects });
            if(uncheckAfter) {
                this.list.current.uncheckAll();
            }
            return this.fetchData().then(() => {
                const errorsIn = [];

                for(let id in response) {
                    if(response[id].hasOwnProperty("original") && response[id].original.hasOwnProperty("error")) {
                        errorsIn.push(id);
                    }
                }

                if(errorsIn.length > 0) {
                    this.list.current.check(errorsIn, true);

                    this.showErrorNotification({
                        message: this.tr("An error occured while applying changes to some projects. Please make sure that the projects checked in the list have their compulsory fields filled, then reattempt the edit."),
                        buttonText: this.tr("Dismiss"),
                        key: "batchUpdateFieldError",
                        preventDuplicate: true
                    }); 
                }

                // If there were no errors, changes were applied succesfully,
                // if the quantity of sent projects is greater than of those which had errors, changes were applied partially,
                // otherwise no project was edited without errors, and showErrorNotification is the only relevant notification
                // to show.
                if(showNotification) {
                    if(errorsIn.length === 0)
                        this.showChangesAppliedNotification();
                    else if(projects.length > errorsIn.length)
                       this.showChangesAppliedPartiallyNotification();
                }

                return errorsIn;
            });

        } catch(e) {
            this.showErrorNotification({
                message: this.tr("Something went wrong while applying the changes"), 
                buttonText: this.tr("Dismiss"),
                key: "batchUpdateError"
            });
        } finally {
            setTimeout(() => {
                if(!showNotification)
                    return;

                this.props.closeSnackbar(notificationKey); 
            }, 1000);
        }
         


        return DataHandler.put({ url: `projects/mass` }, { projects }).done(response => {
            if (uncheckAfter) {
                this.list.current.uncheckAll();
            }
            return this.fetchData().then(() => {
                const errorsIn = [];

                for(let id in response) {
                    if(response[id].hasOwnProperty("original") && response[id].original.hasOwnProperty("error")) {
                        errorsIn.push(id);
                    }
                }

                if(errorsIn.length > 0) {
                    this.list.current.check(errorsIn, true);

                    this.showErrorNotification({
                        message: this.tr("An error occured while applying changes to some projects. Please make sure that the projects checked in the list have their compulsory fields filled, then reattempt the edit."),
                        buttonText: this.tr("Dismiss"),
                        key: "batchUpdateFieldError"
                    }); 
                }

                if(showNotification) {
                    if(errorsIn.length === 0)
                        this.showChangesAppliedNotification();
                    else
                       this.showChangesAppliedPartiallyNotification();
                }

                return errorsIn;
            });
        }).fail(() => {
            this.showErrorNotification({
                message: this.tr("Something went wrong while applying the changes"), 
                buttonText: this.tr("Dismiss"),
                key: "batchUpdateError"
            });
        }).always(() => {
            setTimeout(() => {
                this.props.closeSnackbar(notificationKey); 
            }, 1000);
        });
    }


    // Pass a ref to control the notification's progress indicator.
    // Since it's a persistant notification, we return the key of the
    // notification in order to be able to clear it later using clearSnackbar(key);
    showProgressNotification() {
        return this.props.enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: key => {
                return (
                    <ProgressNotification
                        message={this.tr("Applying changes")} />
                );
            }
        });
    }


    showErrorNotification({ message, buttonText, onClick, key, preventDuplicate }) {
        const { closeSnackbar } = this.props;

        this.props.enqueueSnackbar("", {
            persist: true,
            key: key || "errorNotification",
            preventDuplicate: preventDuplicate || false,
            content: () => {
                return (
                    <ErrorNotification message={message} closeSnackbar={closeSnackbar} key={key} onClick={onClick} buttonText={buttonText} />
                ); 
            }
        });
    }


    showChangesAppliedNotification(msg = undefined) {
        this.props.enqueueSnackbar(msg === undefined ? this.tr("Changes applied") : msg, {
            persist: false,
            variant: "success",
            preventDuplicate: true
        });
    }


    showChangesAppliedPartiallyNotification(msg = undefined) {
        this.props.enqueueSnackbar(msg === undefined ? this.tr("Changes applied partially") : msg, {
            persist: false,
            variant: "warning",
            preventDuplicate: true
        });
    }


    closeDialogAndReset() {
        this.closeDialog();

        setTimeout(() => {
            this.list.current.uncheckAll(false);
        });
    }


    getCachedAutoCompleteData() {
        if(this.autoCompleteData !== false)
            return this.autoCompleteData;

        return this.getAutoCompleteData().done(data => data);
    } 

    async getAutoCompleteData() {
        const { company } = this.state.filters;
        return Promise.all([
            this.cacher.get({ url: `projects/autoCompleteData/${company}` }),
            this.cacher.get({ url: `subjects/dimensions/teams/${company}` }),
            this.cacher.get({ url: `projects/needed_rights` }),
        ]).then(([data, dimensionTeams, rights]) => {
            data.rights                  = rights;
            data.dimension_teams         = dimensionTeams;
            data.bob_leveled             = Utils.treeFormatDataForList(data.branch_of_business, "parent_id");
            data.reportingGroups_leveled = Utils.treeFormatDataForList(data.reporting_groups, "parent_id");

            data.users_for_filters = data.privileged_employees.concat(data.dynamic_right_employees).sort((a, b) => {
                return a.name.localeCompare(b.name, "fi");
            });

            data.users_for_filters = _.uniqBy(data.users_for_filters, 'id'); 

            if(!data.bob_leveled) {
                data.bob_leveled = [];
            }

            if(!data.reportingGroups_leveled) {
                data.reportingGroups_leveled = [];
            }

            data.type_map         = makeMap(data.types);
            data.customerType_map = makeMap(data.customer_types);
            data.bobOptions       = createBobOptions(data.bob_leveled);

            if(!this.showLostOnPipelineId) {
                this.showLostOnPipelineId = {};
            }

            data.pipelines.forEach(e => {
                if(e.hide_sales_insight_loss == 0) {
                    this.showLostOnPipelineId[e.id] = true;
                }
            });

            return data;
        });
    }

    projectWonReasonIsInUse = (company = this.state.filters.company) => {
        return this.context.userObject.project_read_companies.findIndex(c => c.id == company && c.use_project_won_reason == 1) != -1
    }

    projectLostReasonIsInUse = (company = this.state.filters.company) => {
        return this.context.userObject.project_read_companies.findIndex(c => c.id == company && c.use_project_lost_reason == 1) != -1
    }
    
    updateComponentData(setKanbanDefaultPipeline = true, fetchData = false, initialUpdate = false, stickySearch = false) {
        this.cacher.get({url: `subjects/companies_with_project_right/read/`, currency: 1}).then(companies => {
            let c = false;
            companies.forEach(company => {
                if(company.id == this.state.filters.company) {
                    this.setState({companyCurrency: company.currency});
                    c = true;
                }
            })
            if (!c && companies?.[0]) {
                this.setState({companyCurrency: companies[0].currency})
            }
        }); 

        this.getAutoCompleteData().then(async (data) => {
            //lisätään hardkoodatu, frondissa koska käännökset
            data = {...data, statuses: this.statuses};
            data.pipelines = [...data.pipelines, ...this.defaultPipelines];
            data.project_types = [...data.project_types, ...this.defaultPipelines]; //tarvitaanko tätä enaa mihinkään?
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };
            Object.keys(data).forEach(k => {
                if (this.userTypeAutocompleteClasses.includes(k)) {
                    data[k] = data[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                }
            })

            this.autoCompleteData = data;
            this.cacher.get({ url: `settings/company/${data.company}/default_project_category` }).then(res => {
                if(res.project_category_settings == 1){
                    this.setState({setDefaultProjectCategory: true});
                }
                const defaultBob = this.state.setDefaultProjectCategory && this.autoCompleteData.bobOptions.find(x => x.data.is_default == 1 && x.data.deleted != 1 && x.data.locked != 1);
                if((!defaultBob || typeof(defaultBob) !== "object" || !defaultBob.hasOwnProperty("id") ) && this.state.setDefaultProjectCategory ) {
                    this.newRow.branchofbusiness_id = Array.isArray(this.autoCompleteData.bobOptions) && this.autoCompleteData.bobOptions.length > 0 && this.autoCompleteData.bobOptions[0].hasOwnProperty("id") ? this.autoCompleteData.bobOptions[0].id : null;
                } else {
                    this.newRow.branchofbusiness_id = defaultBob.id;
                }
            }); 

            this.autoCompleteData.reportingGroupOptions = createBobOptions(this.autoCompleteData.reportingGroups_leveled);
            this.autoCompleteData.sales_states = this.autoCompleteData.sales_states.filter(x => x.companies_id == this.state.filters.company);
            this.autoCompleteData.reportingOptions = [];
            let cusId =  this.props.customerId;
            this.autoCompleteData.reportingGroupOptions.map(rgso => {
                if(rgso.data.customers_id == cusId){
                    this.autoCompleteData.reportingOptions.push(rgso);
                }});

            let ref;

            for(let key in data) {
                if (key === "all_users") 
                    continue;

                ref = data[key];

                if(!Array.isArray(ref) || ref.length === 0 || !ref[0].hasOwnProperty("id"))
                    continue;

                this.autoCompleteDataMap[key] = Utils.makeMap(ref, "id"); 
            }

            Promise.all([
                this.cacher.get({ url: `settings/company/${this.state.filters.company}/defaultPipeline` }),
                this.cacher.get({ url: `settings/company/${this.state.filters.company}/tagSettings` }),
                this.cacher.get({ url: `settings/company/${this.state.filters.company}/project/oneprojecttype` }),
                this.cacher.get({ url: `settings/company/${this.state.filters.company}/use_events` })  
            ]).then(([defaultPipelineData, tagSettingData, oneProjectTypeData, companyEventSettings]) => {
                this.oneprojecttype       = oneProjectTypeData.oneprojecttype;
                const tagPoolSettingsData = tagSettingData.tagPoolSettingsData;
                if (this.props.view === "kanban" && setKanbanDefaultPipeline) {
                    const filters = { ...this.state.filters, projects_pipelines_id: defaultPipelineData.default_projects_pipelines_id }
                    this.setState({ filters, defaultPipeline: defaultPipelineData.default_projects_pipelines_id, tagPoolSettingsData: tagPoolSettingsData });
                    this.context.functions.updateView({ pipeline_id: defaultPipelineData.default_projects_pipelines_id, replace: true }, false, undefined, undefined, true);
                    this.fetchData(filters);
                }
                else {
                    this.setState({ defaultPipeline: defaultPipelineData.default_projects_pipelines_id, tagPoolSettingsData: tagPoolSettingsData, use_events: companyEventSettings.use_events });
                }
            });
        }).then(() => {
            // if(this.state.stickySearchInitialized) {
                // return;
            // }

            // this.initializeStickySearch();
        }).then(() => {
            if(!this.list.current) {
                return;
            }

            this.list.current.flipAllRows();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("projectCreated", this.updateAfterCreation);
        this.unListenReset();
    }

    getOneProjectTypeSetting = () => {
        return this.oneprojecttype;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.forceOverrideComponentUpdate) {
            this.forceOverrideComponentUpdate = false;

            return true;
        }

        if(isEqual(this.props, nextProps) && isEqual(this.state, nextState)) {
            return false; 
        }

        if(this.props.view === "kanban" && nextProps.view === "list") {
            // To prevent the List component from completely freezing, 
            // as the kanban might have displayed thousands of projects, 
            // while List can't do that yet.

            if(this.props.pipeline_id !== nextProps.pipeline_id) {
                const curFilters          = { ...this.state.filters, projects_pipelines_id: nextProps.pipeline_id };
                this.previousStateFilters = { ...curFilters };
    
                this.setState({ data: [], filters: curFilters }, () => {
                    this.fetchData({ ...curFilters });
                });
            } else {
                this.setData({ projects_new: [], has_projects: true }, () => this.fetchData());
            }

            return false;
        }

        return true;
    }


    toggleViewState() {
        const nextView = this.props.view === "list" ? "kanban" : "list";

        this.fetchData({ page: 1, view: nextView });
        this.context.functions.updateView({view: nextView});
    }


    // TODO: Merge dialogData and dialogProps as they seem to have 90% same functionality.
    // Also a generic way to construct dialogs would be good.
    async openDialog(name, data = undefined) {
        if (name === "CopyProjectDialog") {
            const limitReached = await this.isProjectLimitReached(true);
            if (limitReached) {
                this.props.toggleBuyDialog("projects");
                return;
            }
        }

        this.setState({ 
            // These "currentDialogProps" will be passed to the mass edit dialog in this component's render function.
            currentDialogProps: { 
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}), 
                ...(typeof(data) === "object" ? data : {}) 
            }, 
            currentDialog: name,
            dialogData: data
        });
    }


    closeDialog(cb = () => {}) {
        this.setState({
            currentDialog: false,
            currentDialogProps: undefined,
            dialogData: undefined,
        }, () => {
            this.dialogProps = this.originalDialogProps(); // "Reset" the dialogs.

            if({}.toString.call(cb) === '[object Function]') {
                cb();
            }
        });
    }


    confirmDialog(saveFunc, id) {
        saveFunc(id);
        this.closeDialog();
    }

    deleteProjectsMass = () => {
        let notificationKey = this.showProgressNotification();

        DataHandler.delete({ url: `projects/${this.state.currentDialogProps.deletable_ids}` }).done((response) => {
            this.props.closeSnackbar(notificationKey);
            if (Number(response.deleted) > 0) {
                this.showChangesAppliedNotification(response.deleted + " " + this.tr("projects deleted."));
                this.updateLimitInfo();
                this.list.current.uncheckAll();
            }
            if (Number(response.not_allowed) > 0) {
                this.showErrorNotification({
                    message: response.not_allowed + " " + this.tr("projects could not be deleted:") + " " + this.tr("No permission"),
                    buttonText: this.tr("Dismiss"),
                    key: "batchUpdateError"
                });
            }
            if (Number(response.non_deletable) > 0) {
                this.showErrorNotification({
                    message: response.non_deletable + " " + this.tr("projects could not be deleted:") + " " + this.tr("Projects have data"),
                    buttonText: this.tr("Dismiss"),
                    key: "batchUpdateError"
                });
            }
            this.fetchData();
        }).fail(() => {
            this.props.closeSnackbar(notificationKey);

            this.showErrorNotification({
                message: this.tr("Something went wrong while applying the changes"),
                buttonText: this.tr("Dismiss"),
                key: "batchUpdateError"
            });
        });

        this.closeDialog();
    }

    deleteProject(data) {
        if (data.deletable === false) {
            this.props.enqueueSnackbar(this.tr("Cannot delete project") + ": " + this.tr(data.delete_msg), {
                variant: "error",
            });
        }
        else {
            DataHandler.post({ url: 'projects/' + data.id + '/delete' }).done((response) => {
                this.props.enqueueSnackbar(this.tr("Project deleted successfully") + "!", {
                    variant: "success",
                });
                this.list.current.uncheckAll();
                this.fetchData();
                this.updateLimitInfo();
            }).fail((err) => {
                let msg = this.tr("Error in deleting project");
                if (err?.responseJSON?.error) {
                    msg += ": " + this.tr(err.responseJSON.error);
                }
                this.props.enqueueSnackbar(msg, {
                    variant: "error",
                });
            })
        }

        this.closeDialog();
    }

    closeProject = () => {
        let projects = [...this.state.currentDialogProps.all_projects];
        let shouldShowReasonDialog = false;
        projects.forEach(p => {
            if (p.status == 0) shouldShowReasonDialog = true;
            p.locked = 1;
            p.status_date = new Date();
        });

        this.closeDialog();

        if (shouldShowReasonDialog && this.projectLostReasonIsInUse()) {
            this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="lost" project={projects[0]} onSave={(reason, status_reason_comment) => {
                    projects.forEach(p => {
                        if (p.status == 0) {
                            p.status_reason_id = reason.id;
                            p.status_reason_comment = status_reason_comment;
                        };
                    });
                    this.handleCloseProjects(projects);
                }} />
            );
        } else {
            this.handleCloseProjects(projects);
        }
	}

    handleCloseProjects = (projects) => {
        let notificationKey = this.showProgressNotification();

        DataHandler.put({ url: `projects/mass` }, { projects }).done(response => {
            return this.fetchData().then(() => {
                const errorsIn = [];

                for(let id in response) {
                    if(response[id].hasOwnProperty("original") && response[id].original.hasOwnProperty("error")) {
                        errorsIn.push(id);
                    }
                }
    
                if(errorsIn.length > 0) {
                    this.list.current.check(errorsIn, true);

                    this.showErrorNotification({
                        message: this.tr("An error occured while closing some projects."),
                        buttonText: this.tr("Dismiss"),
                        key: "batchUpdateFieldError"
                    }); 
                }

                if (errorsIn.length === 0)
                    this.showChangesAppliedNotification();
                else if (projects.length > errorsIn.length)
                    this.showChangesAppliedPartiallyNotification();

                this.props.closeSnackbar(notificationKey);
                return errorsIn;
            });
        }).fail(() => {
            this.showErrorNotification({
                message: this.tr("Something went wrong while applying the changes"), 
                buttonText: this.tr("Dismiss"),
                key: "batchUpdateError"
            });
            this.props.closeSnackbar(notificationKey);
        })
    }

    onToolbarExportClick() {
        this.export("xlsx", this.fields);

        this.closeDialog();
    }


    onToolbarEditClick() {
        this.openDialog("editSelections", {
            title: `${this.translations.edit}`,
            subtitle: `(${this.list.current ? (this.list.current.getAllChecked() ? this.state.projectCount : this.list.current.getCheckedRows().length) : 0} ${this.translations.rowsSelected})`
        });
    }


    async onToolbarDeleteClick() {
        const checkedRows   = await this.getSelectedProjects(); 

        const string        = checkedRows.join(",");
        DataHandler.post({ url: `projects/check_deletable` }, {ids: string}).done((response) => {
            this.openDialog("delete", {...response, confirmDisabled: response.deletable_count === 0});
        })
    }


    async beforeNew() {
        const limitReached = await this.isProjectLimitReached(true);
        if (limitReached)
            this.props.toggleBuyDialog("projects");
        else
            this.list.current.addNewRow();
    }

    onPerPageChange(perpage) {
        this.setState({ perpage: perpage }, () => this.fetchData({ perpage: perpage }));
    }


    onPageChange(page) {
        this.list.current.startPageChangeAnimation();

        this.setState({ page: page })
        this.fetchData({ page: page })
    }


    onSortRows(colName, asc) {
        this.sortTerms = { name: colName, asc: asc };

        this.setState({page: 1}, () => this.fetchData().then(this.list.current.flipAllRows));
    }


    addToAutoCompleteData(key, data, forceUpdate = true) {
        if(Array.isArray(data)) {
            this.autoCompleteData[key] = this.autoCompleteData[key].concat(data) 
        } else {
            this.autoCompleteData[key].unshift(data);
        }

        if(!forceUpdate)
            return;

        this.forceUpdate();
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevProps.customerName != this.props.customerName) {
            this.setState({
                searchTerms: this.getInitialSearchTerms()
            })
        }

        if (prevProps.mode != 'pipeline' && this.props.mode == 'pipeline') {
            let pipeline_id = this.state.defaultPipeline;
            if ((!this.props.pipeline_id || Number(this.props.pipeline_id) > 0) && Number(pipeline_id) < 0) {
                pipeline_id = this.autoCompleteData?.pipelines[0]?.id || pipeline_id;
            }
            this.context.functions.updateView({ pipeline_id, replace: true }, false, undefined, undefined, true);
        }

	    const { functions: { updateView } } = this.context;
        let nextFilters = { ...this.state.filters };
        let updateFilters = false;

        this.validateView();

        if(this.props.onlyThisCompany != prevProps.onlyThisCompany) {
            if (this.props.onlyThisCompany)
                nextFilters.company = this.props.onlyThisCompany;

            updateFilters = true;
            // let filters = {...this.state.filters};
            // filters.company = this.props.onlyThisCompany;
            // this.setState({filters});
            // return;
        } else if (this.props.companies_id && this.props.companies_id !== prevProps.companies_id) {
            nextFilters.company = this.props.companies_id;
            updateFilters = true;
        }

        if(prevState.showTreeStructures !== this.state.showTreeStructures || prevState.showWholeTrees !== this.state.showWholeTrees || prevState.showMatchesAndChildren !== this.state.showMatchesAndChildren) {
            localStorage.setItem("projectlist_treeview_settings", JSON.stringify({
                showTreeStructures: this.state.showTreeStructures,
                showWholeTrees: this.state.showWholeTrees,
                showMatchesAndChildren: this.state.showMatchesAndChildren
            }));
        }

        if(this.props.pipeline_id !== prevProps.pipeline_id) {
            nextFilters.projects_pipelines_id = this.props.pipeline_id;
            updateFilters = true;
        }

        if (updateFilters) {
            this.previousStateFilters = { ...nextFilters };

            if (nextFilters.company !== this.state.filters.company) {
                if (nextFilters.projects_pipelines_id > 0) {
                    nextFilters.projects_pipelines_id = 0;
                }

                this.setState({ filters: nextFilters }, () => {
                    this.updateComponentData(false);
                });
            } else {
                this.setState({ filters: nextFilters }, () => {
                    this.fetchData({ ...nextFilters });
                });
            }
            return;
        }

        // If filters have changed, we need to trigger the search.
        if((this.props.view !== prevProps.view || this.state.filtersChanged) && this.props.pipeline_id === prevProps.pipeline_id) {
            let filters      = {};
            let filterAmount = 0;

            for(let i in this.state.filters) {
                if(this.state.filters[i] === undefined && this.previousStateFilters[i] === undefined) {
                    continue;
                }

                filters[i] = this.state.filters[i];

                ++filterAmount;
            }

            if(filterAmount > 0 || this.props.view !== prevProps.view) {
                this.fetchData({ ...filters });
            }

            this.previousStateFilters = { ...filters };
        }

        if(this.state.filtersChanged) {
            if(this.list.current) {
                this.list.current.resetCheckedRows();
            }

            this.setState({ filtersChanged: false });
        }

        // When switching to kanban, choose the company's default pipeline in the pipeline dropdown if one isn't chosen already.
        if(this.autoCompleteData && this.autoCompleteData.company == this.state.filters.company && this.autoCompleteData.pipelines && this.autoCompleteData.pipelines.length > 0 && this.props.view === "kanban" && this.state.filters.projects_pipelines_id == 0) {
            // let filters = this.state.filters;
            // filters.projects_pipelines_id = this.autoCompleteData.pipelines[0].id;

            // this.setState({ filters: filters });
            const { userObject: { sidebarStyle } } = this.context;
            let pipeline_id = this.state.defaultPipeline;
            if (sidebarStyle != "1" && (!this.props.pipeline_id || Number(this.props.pipeline_id) > 0) && Number(pipeline_id) < 0) {
                pipeline_id = this.autoCompleteData.pipelines[0].id || pipeline_id;
            }
            updateView({ pipeline_id, replace: true }, false, undefined, undefined, true);
        }

        if(this.state.filters.company !== prevState.filters.company) {
            this.updateComponentData();
            this.initDimensions(this.state.filters.company);
        }
    }


    setFilters(filters, callback = () => {}) {
        if(isEqual(this.state.filters, filters))
            return;

        this.setState({ filters: filters, filtersChanged: true }, () => {
            if(typeof(callback) !== "function")
                return;
        
            callback();
        });
    }


    setFilter(kvPairs, callback = () => {}) {
        let filters = clone(this.state.filters);

        for(let i in kvPairs)
            filters[i] = kvPairs[i];

        this.setFilters(filters, callback);
    }


    filtersAreInInitialState() {
        const initial = cloneDeep(this.filtersInitialValues);
        const filters = cloneDeep(this.state.filters);

        // In the kanban view we don't want the pipeline selection to 
        // count as a filter having been edited.
        if(this.props.view === "kanban" ||!this.pipelineSetManually) {
            delete initial.projects_pipelines_id;
            delete filters.projects_pipelines_id;
        } 

        for(let key in initial) {
            initial[key] = String(initial[key]);
            filters[key] = String(filters[key]);
        }
        const freetext = this.state.searchTerms ? this.state.searchTerms.freetextSearchTerm : "";

        return !freetext && isEqual(initial, filters) && isEqual(this.state.dateRange, this.initialDate) && isEqual(this.state.expectedDateRange, this.initialExpectDate) && isEqual(this.state.funnelRange, this.initialExpectDate) && isEqual(this.state.eventDateRange, this.initialExpectDate);
    }


    switchCompany = (companies_id) => {
        const filters = {
            company: companies_id
        };

        const url = {
            companies_id: companies_id  
        };

        this.setFilter(filters, () => {
            this.props.updateUrl(url);
            this.updateComponentData(false, true);
        });

        this.context.functions.setLastCompany(companies_id);
    }


    fetchData(override = {}, force = undefined) {
        this.list.current && this.list.current.setState({ isLoading: true});
        if (this.dataRequest) this.dataRequest.abort();
        
        if(!this.initialFetchDone) {
            try {
                this.list.current.startPageChangeAnimation();
            } catch(e) {}
        }

        // It just had to come to this, didn't it.
        this.forceOverrideComponentUpdate = force === undefined ? this.forceOverrideComponentUpdate : force;

        let parameters = { perpage: this.state.perpage, ...this.state.filters, view: this.props.view, sticky_search_key_name: this.props.stickySearchKey};

        if(this.state.filtersChanged && !this.state.stickyRun) {
            parameters.page = 1; 
        } else {
            parameters.page = this.state.page; 
        }

        const selection = this.state.dateRange;
        const expectedSelection = this.state.expectedDateRange;
        const funnelSelection = this.state.funnelRange;
        const eventDateSelection = this.state.eventDateRange; 

        for(let oi in override)
            parameters[oi] = override[oi];

        const addedGetParams = {
            ...parameters,
            customers_id: this.props.customerId !== false ? this.props.customerId : undefined,
            sort: this.sortTerms !== undefined ? { name: this.sortTerms.name, asc: this.sortTerms.asc } : undefined,
        };

        addedGetParams.start = selection.startDate != '' ? format(selection.startDate, 'YYYY-MM-DD') : '';
        addedGetParams.end = selection.startDate != '' ? format(selection.endDate, 'YYYY-MM-DD') : '';
        addedGetParams.expectStart = expectedSelection.startDate != '' ? format(expectedSelection.startDate, 'YYYY-MM-DD') : '';
        addedGetParams.expectEnd = expectedSelection.startDate != '' ? format(expectedSelection.endDate, 'YYYY-MM-DD') : '';
        addedGetParams.funnelStart = funnelSelection.startDate != '' ? format(funnelSelection.startDate, 'YYYY-MM-DD') : '';
        addedGetParams.funnelEnd = funnelSelection.startDate != '' ? format(funnelSelection.endDate, 'YYYY-MM-DD') : '';
        if  (this.state.use_events > 0) {
            addedGetParams.eventStart = eventDateSelection.startDate != '' ? format(eventDateSelection.startDate, 'YYYY-MM-DD') : '';
            addedGetParams.eventEnd = eventDateSelection.startDate != '' ? format(eventDateSelection.endDate, 'YYYY-MM-DD') : '';
        }

        let defaultFilters = {
            expectedClosingDate: 0,
            projectStatus: -1,
            projects_pipelines_id: 0,
            salesAgent: 0,
            status: 0
        };

        const relevantFilters     = {};
        const relevantFilterNames = Object.keys(defaultFilters);

        Object.keys(this.state.filters).filter(f => relevantFilterNames.indexOf(f) > -1).forEach(fKey => relevantFilters[fKey] = this.state.filters[fKey]);

        for(let cf in relevantFilters) {
            relevantFilters[cf] = Number(relevantFilters[cf]);
        }

        let setDataAnon = (data) => {
            if(addedGetParams.onlyIds)
                return data;

            this.list.current && this.list.current.setState({ isLoading: false});
            this.setData(data, () => this.initialFetchDone = true);
        };

        if (this.props.view === "kanban") 
            addedGetParams.getActivities = true;

        const url = { 
            url: "projects/list_new?\\", 
            ...addedGetParams, 
			showTreeStructures: Boolean(this.state.showTreeStructures),
            wholeTrees: this.state.showTreeStructures && this.state.showWholeTrees,
            matchesAndChildren: this.state.showTreeStructures && this.state.showMatchesAndChildren,
			markMatches: this.state.showTreeStructures && this.state.showWholeTrees && (!isEqual(defaultFilters, relevantFilters) || !(this.state.searchTerms === undefined || (this.state.searchTerms.freetextSearchTerm && this.state.searchTerms.freetextSearchTerm.trim() == "") || !this.state.searchTerms.freetextSearchTerm)) 
        };

        if(this.state.searchTerms !== undefined && this.state.searchTerms.mode === "advanced") {
            this.savedParams = { advanced_search_criteria: JSON.stringify(this.state.searchTerms.advanced_search_criteria) };
            this.getParams = { ...url, mode: 'advanced' };
        } else if(this.state.searchTerms !== undefined && this.state.searchTerms.mode === "freetext") {
            this.savedParams = { freetext: this.state.searchTerms.freetextSearchTerm, advanced_search_criteria: JSON.stringify(this.state.searchTerms.advanced_search_criteria) };
            this.getParams = { ...url, mode: 'freetext' };
        } else {
            this.getParams = { ...url };
        }

        if(parameters.page !== this.state.page) {
            this.setState({ page: parameters.page });
        }

        if(this.state.stickyRun) {
            this.setState({ stickyRun: false }); 
        }

        if(this.props.useStickySearch) {
            this.saveStickySearch({ page: parameters.page });
        }

        const request = DataHandler.post(this.getParams, this.savedParams).done(setDataAnon);
        // const request = DataHandler.get(this.getParams, this.savedParams).done(setDataAnon); 
        
        this.dataRequest = request;

        return request;
    }

    async getSelectedProjects() {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();

        let selected;

        if(allCheckedExcept) {
            selected = await this.fetchData({ onlyIds: true });
            selected = selected.filter(id => {
                return !allCheckedExcept[id]; 
            });
        } else {
            selected = this.list.current.getCheckedRows();
        }

        return selected;
    }


    async export(target, fields) {
        if(!this.state.data || this.state.data.length < 1 || !this.list.current) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        const columns          = this.list && this.list.current ? this.list.current.visibleColumnsOrder : this.state.listColumns;
        const exportHeaders    = [];        
        let params             = {};

        let selected = await this.getSelectedProjects(); 
        if (selected.length === 0)
            selected = await this.fetchData({ onlyIds: true });

        _.forEach(columns, column => {
            _.forEach(fields, (field, i) => {
                if(column == field.name && column != "expand" && column != "checked" && column != "hour_report" && column != "quotes"){
                    exportHeaders.push(field.header);
                    if (column === "project_id")
                    exportHeaders.push(this.tr("Path"));
                }
            })
        })

        // if(selected.length > 0)
            // params.ids = JSON.stringify(selected);
        // else
            // params = { ...params, ...this.savedParams };

        params.order = columns;
        params.columnNames = exportHeaders;
        params.company = this.state.filters.company;
        params.currency = this.state.companyCurrency;
        params.statuses = this.statuses;
        params.sort = this.sortTerms !== undefined ? { name: this.sortTerms.name, asc: this.sortTerms.asc } : undefined;
        params.file_name = this.tr("projects_list_export");
        //["expand", "checked", "project_id", "customer", "project", "project_type", "sales_state", "sales_state_changed", "status", "statusdate", "log_created", "closing_date", "branchofbusiness", "startdate", "enddate", "project_manager", "seller", "project_team", "revenue", "salesmargin", "hours_done", "maxhours"];

        DataHandler.postArrayBuffer({ url: "projects/list_export" }, { ids: selected, export: target, ...params }, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
            this.list.current && this.list.current.setState({ isLoading: false});
        });
    }


    handleCounts(data) {
        this.setState({
            pageCount: data.page_count !== undefined ? data.page_count : 1,
            projectCount: data.count !== undefined ? data.count : 0
        });
    }


    setData(data, callback = () => {}) {
        let projects = data.projects_new;
        const totals = data.totals !== undefined ? data.totals : { margin: 0, value: 0, tracked: 0 };

        if (this.props.view === "kanban") {
            let activities = [];
            projects.forEach((p) => {
                activities = (data.activities || []).filter(a => a.project === p.id);
                p.activitiesDue = activities.find(pa => pa.status === "2") ? true : false;
                p.activitiesOverdue = activities.find(pa => pa.status === "3") ? true : false;
            })
        }
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.autoCompleteData.users
        };
        projects.forEach((p, i) => {
            if (Object.keys(p).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {          
                projects[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            }
        })


        this.setState({
            data: projects !== undefined && projects.length > 0 ? projects : [],
            loading: false,
            totals,
            hasProjects: Boolean(data.has_projects),
            pageCount: data.page_count !== undefined ? data.page_count : this.state.pageCount,
            projectCount: data.count !== undefined ? data.count : this.state.projectCount,
        }, callback);

        try {
            this.list.current.endPageChangeAnimation();
        } catch(e) {
        
        }
    }


    onEdit(editedData, id) {
        if(id < 0) {
            this.list.current.editData(editedData, id);
            return;
        }

        this.setState({ 
            data: cloneDeep(this.state.data).map(p => {
                if(Number(p.id) !== id) {
                    return p; 
                } 

                return editedData;
            }) 
        });
    }


    addRow() {
        this.list.current.addNewRow();
    }

    addProject = async (evt) => {
        let pipeline_id = 0;
        if (Number(this.state.filters.projects_pipelines_id) < 0) pipeline_id = this.state.filters.projects_pipelines_id;
        this.context.functions.addProject({ pipeline_id, customers_id: this.props.customerId, companies_id: this.state.filters.company });
    }

    isProjectLimitReached = async (countNewRows = false) => {
        if (this.context.addons && this.context.addons.projects && this.context.addons.projects.limit) {
            const limitInfo = await DataHandler.get({ url: `settings/limits/projects` })
            let projectsAdded = limitInfo.used;

            if (countNewRows && this.list.current && this.list.current.dataMap) 
                projectsAdded += Object.values(this.list.current.dataMap).filter(e => Number(e.id) < 0).length;

            if (limitInfo.limit && projectsAdded >= limitInfo.limit)
                return true
        }

        return false;
    }

    onDateChange = (event) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.fetchData());
    }

    onDateInputChange = (dateType, date) => {

        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.fetchData());
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.fetchData());
        }
    }
    onExpectDateChange = (event, daterangetype) => {

        const { startDate, endDate } = event.selection ;

        this.setState({
            [daterangetype]: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.fetchData());
    }
    onExpectDateInputChange = (dateType, date, daterangetype) => {

        const { endDate, startDate } = this.state[daterangetype];
        date = format(date, "YYYY-MM-DD");

        if (dateType == "expectStart") {
            this.setState({
                [daterangetype]: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.fetchData());
        } else {
            this.setState({
                [daterangetype]: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.fetchData());
        }
    }
    listenReset = () => {
        document.body.addEventListener("keyup", this.resetFilters);
    }


    unListenReset = () => {
        document.body.removeEventListener("keyup", this.resetFilters);
    }


    resetFilters = (evt) => {
        if(evt.keyCode == 27) {
            this._resetFilters();
        }
    }

    _resetFilters = () => {
        this.sortTerms = undefined;

        this.advancedSearch.current.clearSearch(undefined, true);
        this.advancedSearch.current.clearSearchTextInput();

        this.setState({
            filters: { 
                ...this.filtersInitialValues, 
                projects_pipelines_id: this.props.view === "kanban" ? this.state.defaultPipeline : this.filtersInitialValues.projects_pipelines_id
            },
            searchTerms: undefined,
            dateRange: this.initialDate,
            expectedDateRange: this.initialExpectDate,
            funnelRange: this.initialExpectDate,
            eventDateRange: this.initialExpectDate,            
            filtersChanged: true
        });
    }

    openResourceDialog = (taskOrMilestone = "task") => {
        this.context.functions.addResource({ companies_id: this.state.filters.companies }, { massEditMode: true, massEditProjectIds: this.list.current.getCheckedRows(), mode: taskOrMilestone, afterSaveCallback: () => {
            this.list.current.uncheckAll();
        }});
    }

    selectViewMode = (view) => {
        if (view == this.props.view) return;
        let { pipeline_id } = this.props;
        let pipelineChanged = false;
        if (view === "kanban") {
            if (pipeline_id == 0) {
                if (this.state.defaultPipeline != 0) {
                    pipeline_id = this.state.defaultPipeline;
                    pipelineChanged = true;
                }
                this.updateComponentData(true);
            }
            if (this.list.current) 
                this.setState({listColumns: this.list.current.columnOrder});
        }

        this.setState({ data: [] }, () => {
            const update = pipelineChanged ? { view, pipeline_id } : { view }
            this.context.functions.updateView(update, false, undefined, undefined, true);
        });
    }

    openCloseDialog = (data) => {
        this.openDialog("delete", {...data, process: "close_one", wider: true, header: this.tr("Close Project") + "?", confirmButtonText: this.tr("Archive").toUpperCase()});
    }
    
    getSubheaders = () => {
        const { pipeline_id } = this.props;
        const { userObject: { sidebarStyle } } = this.context;
        const subheaders = [];
        switch (Number(pipeline_id)) {
            case -1:
                break;
            case -5:
                break;
            default: 
                const pipeline = (this.autoCompleteData.pipelines || []).find(p => p.id == pipeline_id);
                if (pipeline) {
                    subheaders.push(pipeline.name); 
                }
                break;
        }
        return subheaders;
    }

    renderSummarySection = () => {
        const { allowProjectCreation } = this.props;
        const { taimerAccount } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, { style: "currency", minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.state.companyCurrency }).format;

        return (
            <PageTopSection mainButtons={[
                {
                isVisible: allowProjectCreation != null ? allowProjectCreation == 1 : true,
                action: this.addProject,
                title: this.tr("ADD PROJECT"),
                "data-testid": 'add-project-button'
            }
        ]} additionalButtons={this.props.view === "kanban" ? [] : [
                {
                    title: this.tr("EXPORT"),
                    icon: <CloudDownload />,
                    action: () => this.export("xlsx", this.fields)
                },
            ]} customViewButton={
                <TreeViewSelection
                            showTreeStructures={this.state.showTreeStructures}
                            showWholeTrees={this.state.showWholeTrees}
                            showMatchesAndChildren={this.state.showMatchesAndChildren}
                            onChange={(name, value) => {
                                this.setState({ [name]: value }, () => {
                                    this.fetchData();
                                });
                            }}
                         />
            } 
            viewModes={!this.props.customerId && [
                { key: 'list', label: this.tr('LIST'), action: () => this.selectViewMode('list')}, 
                { key: 'kanban', label: this.tr('KANBAN'), action: () => this.selectViewMode('kanban')}
            ]} selectedViewMode={this.props.view} viewModesTitle={this.tr("View")} summaries={[
                {
                    title: this.tr("Projects value"),
                    value: currencyFormatter(Math.round(this.state.totals?.value || 0))
                },
                {
                    title: this.tr("Projects margin"),
                    value: currencyFormatter(Math.round(this.state.totals?.margin || 0))
                },
                {
                    title: this.tr("Tracked hours"),
                    value: Math.round(this.state.totals?.tracked || 0) + " h"
                },
            ]} settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
                title: this.tr("Settings"),
                href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'projects' }),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'projects' }, false)
			}} specialPermissionsButton={{
				isVisible: !(null == this.context.privileges.admin),
                title: this.tr("Special Permission"),
			}} />
        );
    }

    closeHoursReportSlider = () => {
        this.setState({ hoursReportProject: null });
    }

    onTabClick = (tab) => {
        if (this.props.view == 'kanban' && tab.id == '0') {
            this.selectViewMode('list');
        }
    }

    render() {
        if(!this.state.stickySearchInitialized) {
            return null;
        }

        const { addons, userObject, userObject: { sidebarStyle }, functions: { checkPrivilege, checkPrivilegeAny, hasPrivilege }, taimerAccount: { hasEnterpriseGroups } } = this.context;
        const { dateRange, companies, expectedDateRange, eventDateRange, funnelRange, companyCurrency } = this.state;
        const { customerId, allowProjectCreation, entity } = this.props;

        if (userObject.project_read_companies < 1 && !hasPrivilege("projects", ["read", "write"])) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay}/>}</div>
        }

        if(!this.autoCompleteData) {
            return null;
        }
        
        let Dialog = null;

        // Is the this.dialogProps.hasOwnProperty(this.state.currentDialog) check necessary? It isn't used here at least.
        if(this.state.currentDialog && this.dialogProps.hasOwnProperty(this.state.currentDialog)) {
            Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        }

        const hasHourReportRight         = checkPrivilege('projects', 'hours_report_read', this.state.filters.company);
        const hasGlobalCostestimateRight = checkPrivilege('projects','project_cost_estimate_read',this.state.filters.company);
        const hasGlobalBillsRight        = checkPrivilege('receivedinvoices','approve', this.state.filters.company);
        const hasGlobalInvoiceRight      = checkPrivilege('invoices','write_full', this.state.filters.company);
        const partialInvoicingOn         = addons.quoterow_partial_invoicing_for_products;
        const customProjectIdOn          = addons.custom_project_id;

        const commonConf = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };
        let fields = [
            { field: "expand", staticIndex: 0, name: "expand", header: "", columnHeaderType: checkPrivilege("projects", "write", this.state.filters.company) && allowProjectCreation != null ? allowProjectCreation == 1 ? "roundButton" : "" : "roundButton", width: 50, ...commonConf },
            { field: "checked", staticIndex: 1, name: "checked", header: "", columnHeaderType: "checkbox", width: 50, ...commonConf },
            { field: "hour_report", staticIndex: 2, name: "hour_report", header: "", width: 50, ...commonConf },
            // If the custom project id addon is active, make the project_id column a bit wider so it's easier to type in it.
            { field: "project_id", staticIndex: hasHourReportRight ? 3 : 2, name: "project_id", header: this.tr("Nr."), width: customProjectIdOn ? 90 : 61, resizeable: true, moveable: false },
            { field: "name", name: "name", header: this.tr("Project"), width: 200, showMenu: true, resizeable: true, showResizeMarker: true, moveable: true, hideable: false, visualizationType: "tree" },
            { field: "customer", name: "customer_id", header: this.tr("Account"), width: 200, visualizationType: "tree" },
            addons?.dimensions?.used_by_companies?.indexOf(this.state.filters.company) > -1 ? { field: "dimension_team", name: "dimension_team", header: this.tr("Team"), width: 200 } : null,
            { field: "customer_type", name: "customer_type", header: this.tr("Account Type"), width: 200 },
            { field: "customership_group", name: "customership_group", header: this.tr("Account Group"), width: 200 },
            { field: "enterprise_groups", name: "enterprise_groups", header: this.tr("Enterprise group"), width: 150 },
            { field: "type", name: "type", header: this.tr("Type"), width: 175 },
            { field: "project_type", name: "project_type", header: this.tr("Funnel"), width: 175 },
            { field: "projects_pipelines_date", name: "projects_pipelines_date", header: this.tr("Funnel changed"), width: 140 },
            { field: "sales_state", name: "sales_state", header: this.tr("Stage"), width: 140 },
            { field: "sales_state_changed", name: "sales_state_changed", header: this.tr("Stage changed"), width: 140, type: "date" },
            { field: "status", name: "status", header: this.tr("Status"), width: 140, resizeable: false },
            { field: "statusdate", name: "statusdate", header: this.tr("Status changed"), width: 140, type: "date" },
            { field: "reporting_group", name: "reporting_group", header: this.tr("Reporting group"), width: 175, visualizationType: "tree" },
            { field: "tags", name: "tags", header: this.tr("Tags"), width: 180 },
            { field: "log_created", name: "log_created", header: this.tr("Created"), width: 140, type: "date" },
            { field: "closing_date", name: "closing_date", header: this.tr("Expected close date"), width: 140 , type: "date"},
            { field: "branchofbusiness", name: "branchofbusiness", header: this.tr("Category"), width: 140, type: "text", visualizationType: "tree", excludedOperators: ["contains", "doesnotcontain"] },
            { field: "startdate", name: "startdate", header: this.tr("Start date"), width: 140, type: "date" },
            { field: "enddate", name: "enddate", header: this.tr("End date"), width: 140, type: "date" },
            this.state.use_events > 0 ? { field: "event_startdate", name: "event_startdate", header: this.tr("Event start date"), width: 140, type: "date" } : null,
            this.state.use_events > 0 ? { field: "event_enddate", name: "event_enddate", header: this.tr("Event end date"), width: 140, type: "date" } : null,            
            { field: "project_manager", name: "project_manager", header: this.tr("Project manager"), width: 150 },
            { field: "seller", name: "seller", header: this.tr("Sales agent"), width: 150 },
            { field: "invoicing_type", name: "invoicing_type", header: this.tr("Invoicing type"), width: 150, excludedOperators: ["contains", "doesnotcontain"] },
            { field: "quotes", name: "quotes", header: this.tr("Quotes"), width: 100, resizeable: true, moveable: true, sortable: false },
            { field: "revenue", name: "revenue", header: this.tr("Deal Revenue"), width: 140, showMenu: hasGlobalCostestimateRight, type:"number" },
            { field: "project_invoices_sum", name: "project_invoices_sum", header: this.tr("Bills Total"), width: 140, type:"number" },
            { field: "other_entries_sum", name: "other_entries_sum", header: this.tr("Scheduled Invoices Total"), width: 140, type:"number" },
            { field: "automatic_invoicing_sum", name: "automatic_invoicing_sum", header: this.tr("Automatic Invoices Total"), width: 140, type:"number", sortable: hasGlobalInvoiceRight },
            { field: "net_profit", name: "net_profit", header: this.tr("Net Profit"), width: 140, type:"number", sortable: hasGlobalInvoiceRight },
            { field: "gross_profit", name: "gross_profit", header: this.tr("Gross Profit"), width: 140, type:"number", sortable: hasGlobalInvoiceRight },
            { field: "actual_grossmargin", name: "actual_grossmargin", header: this.tr("Actual grossmarging"), sortable: hasGlobalInvoiceRight, width: 140, type:"number" },
            { field: "invoiced", name: "invoiced", header: this.tr("Invoiced"), width: 140, type:"number", sortable: hasGlobalInvoiceRight },
            { field: "invoiceable_left", name: "invoiceable_left", header: this.tr("Left to be invoiced"), width: 140, type:"number",  },
            { field: "salesmargin", name: "salesmargin", header: this.tr("Deal Margin"), width: 140, showMenu: hasGlobalCostestimateRight, type:"number" },
            { field: "backlog", name: "backlog", header: this.tr("Backlog"), tooltipContent: this.tr("Project Value - (Invoivced h + unbilled h + expenses + bills)"), width: 140, type:"number",  },
            { field: "hours_done", name: "hours_done", header: this.tr("Tracked hours"), width: 140 },
            { field: "unbilled_hours", name: "unbilled_hours", header: this.tr("Unbilled hours"), width: 140 },
            { field: "extra_maxhours", name: "extra_maxhours", header: this.tr("Budgeted hours"), width: 140,  type:"number" },
            { field: "maxhours", name: "maxhours", header: this.tr("Allocated hours"), width: 140,  type:"number" },
            { field: "team_groups", name: "team_groups", header: this.tr("Team groups"), width: 150 },
            { field: "team_groups", name: "team_groups", header: this.tr("Team groups"), width: 150 },
            ...((this.projectLostReasonIsInUse() || this.projectWonReasonIsInUse()) ? [{ field: "status_reason", name: "status_reason", header: this.tr("Won & Lost Reason"), width: 150, excludedOperators: ["contains", "doesnotcontain"] },
            { field: "status_reason_comment", name: "status_reason_comment", header: this.tr("Won & Lost Comment"), width: 200 }] : []),
            { field: "misc_info", name: "misc_info", header: this.tr("Additional info"), width: 200 },
        ].filter(f => f);

        if(!hasGlobalBillsRight) {
            fields = fields.filter(field => {
                return ["project_invoices_sum"].indexOf(field.name) === -1; 
            });
        }

        if(!this.context.taimerAccount.useExtraProjectHours) {
            fields = fields.filter(field => {
                return ["extra_maxhours"].indexOf(field.name) === -1; 
            });
        }

        if(!partialInvoicingOn) {
            fields = fields.filter(field => {
                return ["invoiceable_left", "quotes"].indexOf(field.name) === -1; 
            });
        }

        if (!hasEnterpriseGroups) {
            fields = fields.filter(field => {
                return ["enterprise_groups"].indexOf(field.name) === -1;
            })
        }

        this.autoCompleteData.custom_fields.forEach((v) => {
            if (v.show_in_details) {
                fields.push({
                    field: `custom_${v.id}`,
                    name: `custom_${v.id}`,
                    // sortable: false,
                    header: v.name,
                    transl: v.name,
                    tagTransl: v.name,
                    width: 140,
                    type: v.type == 'date' ? 'date' : 'text',
                });
            }
        })

        this.fields = fields;

        const SelectProps = {
            MenuProps: {
                onEntering: () => this.unListenReset(),
                onExited: () => this.listenReset()
            }
        };

        const excludeFromAdvancedSearchFields = [
            "expand", 
            "hour_report",
            "checked", 
            "hours_done", 
            "projects_pipelines_date",
            "unbilled_hours", 
            "project_type", 
            "status", 
            "seller", 
            "startdate", 
            "enddate", 
            "statusdate", 
            "closing_date",
            "invoiced",
            "extra_maxhours",
            "project_invoices_sum",
            "other_entries_sum",
            "automatic_invoicing_sum",
            "status_reason_comment"
        ];
        
        let advancedSearchFields = fields.filter(f => excludeFromAdvancedSearchFields.indexOf(f.field) === -1).map(f => { 
            return { 
                field: f.field, 
                transl: f.header, 
                tagTransl: f.tagTransl,
                type: f.type, 
                visualizationType: f.visualizationType ? f.visualizationType : "list", 
                excludedOperators: f.excludedOperators 
            };
        });

        advancedSearchFields.splice(_.findIndex(advancedSearchFields, {field: 'team_groups'}) + 1, 0, { field: "project_team", transl: this.tr("Project team") });

        if(!hasGlobalCostestimateRight) {
            advancedSearchFields = advancedSearchFields.filter(el => el.field != 'revenue' && el.field != 'salesmargin');
        }

        const entityModeForAdvancedSearchFields = {
            customer: true,
            customer_type: true,
            name: true,
            branchofbusiness: true,
            reporting_group: true,
            project_manager: true,
            invoicing_type: true,
            type: true,
            sales_state: true,
            seller: true
        };

        advancedSearchFields = advancedSearchFields.map(f => {
            f.entityMode = Boolean(entityModeForAdvancedSearchFields[f.field]);

            return f;
        });

        return (
            <div className="contentBlock" id="projectList">
                <WithTabs hideTabs={!!this.props.customerId} offsetTop={10} onTabClick={this.onTabClick} loading={this.state.loading} hideTabs={this.props.noTabs} header={this.props.header} subheaders={this.props.subheaders} tabs={[
                {
                    id: '0',
                    label: this.tr("All"),
                },
                {
                    id: 'pipeline',
                    label: this.tr("Pipelines"),
                    items: (this.autoCompleteData.pipelines || []).map(pl => ({ ...pl, label: pl.name })).filter(pipeline => Number(pipeline.id) > 0)
                },
                {
                    id: '-1',
                    label: this.tr("Won deals"),
                },
                {
                    id: '-5',
                    label: this.tr("Internal projects"),
                },
            ]} selectedTab={this.props.pipeline_id} selectedSubTab={this.props.pipeline_id} selectedTabKey='pipeline_id' selectedSubTabKey='pipeline_id'>
                {() => {
                    return (
                        <>
                            <div className="listControlsContainer clearfix">
                                {this.state.showSettings && <SettingsSlider onClose={() => this.setState({ showSettings: false })}><ProjectSettings /></SettingsSlider>}
                                <div className="list-filter-section">
                                <div className="header-container primary">
                                    {!this.props.onlyThisCompany && companies.length > 1 && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Company")} value={this.state.filters.company} select onChange={e => this.switchCompany(e.target.value)}>
                                        { companies.map(row => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </OutlinedField>}
                                    
                                    {/* <OutlinedField className="listFilterOutlinedField" select name="pipeline_id" SelectProps={SelectProps} label={this.tr("Funnel")} value={this.state.filters.projects_pipelines_id} onChange={e => {
                                        this.pipelineSetManually = true;
                                        this.setState({ page: 1 }, () => this.context.functions.updateView({id: customerId,view: this.props.view, pipeline_id: e.target.value}));
                                    }}>
                                        {[this.props.view === "list" ? { id: 0, name: this.tr("All") } : null, ...(this.autoCompleteData.pipelines !== undefined ? this.autoCompleteData.pipelines : [])]
                                            .filter(p => p !== null)
                                            .map(pt => {
                                                if(pt.id >= 0) {
                                                    return <MenuItem value={pt.id}>{pt.name}</MenuItem>
                                                } else if(pt.id == -1) {
                                                    return <MenuItem key="-1" value={-1}>{this.tr('Won Deal')}</MenuItem>
                                                } else if(pt.id == -5) {
                                                    return <MenuItem key="-5" value={-5}>{this.tr('Internal Project')}</MenuItem>
                                                }
                                            })} 
                                    </OutlinedField> */}
                                    <OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Status")} value={this.state.filters.projectStatus} onChange={e => {
                                        this.setFilter({ projectStatus: e.target.value });
                                    }}>
                                        {this.statuses && this.statuses.filter(e => !e.hideInSearch).map(status => {
                                            return <MenuItem value={status.id}>{this.showLostOnPipelineId[this.state.filters.projects_pipelines_id] && status.id == 1 ? this.tr("Lost") : status.name}</MenuItem>
                                        })}
                                    </OutlinedField>
                                    {/* <OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Expected close date")} value={this.state.filters.expectedClosingDate} onChange={e => {
                                        this.setFilter({ expectedClosingDate: e.target.value });
                                    }}>
                                        {this.expectedClosingDates.map(el => {
                                            return <MenuItem value={el.value}>{el.label}</MenuItem>
                                        })}
                                    </OutlinedField> */}
                                    
                                    <OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Sales agent")} value={this.state.filters.salesAgent} onChange={e => {
                                        this.setFilter({ salesAgent: e.target.value });
                                    }}>
                                        {[{ id: 0, name: this.tr("All") }, ...(this.autoCompleteData.users_for_filters !== undefined ? this.autoCompleteData.users_for_filters : [])].map(sp => {
                                            return <MenuItem value={sp.id}>{sp.name}</MenuItem>
                                        })}
                                    </OutlinedField>

                                    <DateRangePicker
                                            className="daterange"
                                            ranges={[funnelRange]}
                                            onChange={(e) => this.onExpectDateChange(e, 'funnelRange')}
                                            onInputChange={(dateType, date) => this.onExpectDateInputChange(dateType, date, 'funnelRange')}
                                            label={this.tr("Funnel change range")}
                                            dateFormat={userObject.dateFormat} />

                                    <DateRangePicker
                                            className="daterange"
                                            ranges={[expectedDateRange]}
                                            onChange={(e) => this.onExpectDateChange(e, 'expectedDateRange')}
                                            onInputChange={(dateType, date) => this.onExpectDateInputChange(dateType, date, 'expectedDateRange')}
                                            label={this.tr("Expected close date")}
                                            dateFormat={userObject.dateFormat} />

                    {this.state.use_events > 0 && <DateRangePicker
                            className="daterange"
                            ranges={[eventDateRange]}
                            onChange={(e) => this.onExpectDateChange(e, 'eventDateRange')}
                            onInputChange={(dateType, date) => this.onExpectDateInputChange(dateType, date, 'eventDateRange')}
                            label={this.tr("Event date")}
                            dateFormat={userObject.dateFormat} />}

                                    <DateRangePicker
                                            className="daterange"
                                            ranges={[dateRange]}
                                            onChange={this.onDateChange}
                                            onInputChange={this.onDateInputChange}
                                            label={this.tr("Status span")}
                                            dateFormat={userObject.dateFormat} />

                                        <AdvancedSearch
                                            ref={this.advancedSearch}
                                            fields={[
                                                ...advancedSearchFields,
                                                ...createAdvancedSearchFieldsForDimensions(this.state.dimensions)
                                            ]}
                                            mode={this.state.searchTerms && this.state.searchTerms.mode ? this.state.searchTerms.mode : undefined}
                                            initialFilters={this.state.searchTerms && this.state.searchTerms.currentFilters ? this.state.searchTerms.currentFilters : undefined}
                                            mainConfig={this.state.searchTerms && this.state.searchTerms.advanced_search_criteria ? { operator: this.state.searchTerms.advanced_search_criteria.operator } : undefined} 
                                            noRequests={true}
                                            freetextLabel={this.state.searchTerms ? this.state.searchTerms.freetextSearchTerm : ""}
                                            alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                            onClearSearch={this._resetFilters}
                                            onSearchTrigger={(searchTerms) => {
                                                if(isEqual(searchTerms, this.state.searchTerms)) {
                                                    return;
                                                }

                                                if(this.list.current) {
                                                    this.list.current.resetCheckedRows();
                                                    this.list.current.startDataChangeAnimation();
                                                }

                                                this.setState({ page: 1, searchTerms: searchTerms, filtersChanged: true });
                                            }}
                                            autoCompleteData={{
                                                customer:         [this.autoCompleteData.customers, "parent_id"],
                                                name:             [this.autoCompleteData.projects, "parent_id"],
                                                sales_state:      this.autoCompleteData.sales_states.filter(ss => {
                                                    if(this.props.pipeline_id == 0) {
                                                        return true;
                                                    }

                                                    return this.props.pipeline_id < 0 
                                                        ? 
                                                        parseInt(ss.project_type) === Math.abs(this.props.pipeline_id) 
                                                        : 
                                                        ss.projects_pipelines_id == this.props.pipeline_id;
                                                }),
                                                seller:           this.autoCompleteData.users_for_filters,
                                                project_team:     this.autoCompleteData.users_for_filters,
                                                team_groups:      this.autoCompleteData.team_groups,
                                                project_manager:  this.autoCompleteData.users_for_filters,
                                                customer_type:    this.autoCompleteData.customer_types,
                                                invoicing_type:   [
                                                    { id: 1, name: this.tr("Billable") },
                                                    { id: 2, name: this.tr("Non-billable") },
                                                    { id: 3, name: this.tr("Vacation / Leave") },
                                                ],
                                                branchofbusiness: [this.autoCompleteData.branch_of_business ? this.autoCompleteData.branch_of_business.filter(bob => (bob.deleted < 1 && bob.locked < 1)) : this.autoCompleteData.branch_of_business, "parent_id"],
                                                reporting_group:  [this.autoCompleteData.reporting_groups, "parent_id"],
                                                tags:             this.autoCompleteData.project_tags,
                                                enterprise_groups: this.autoCompleteData.enterprise_groups,
                                                type:             this.autoCompleteData.types.filter(el => el.deleted < 1),
                                                status_reason:    this.autoCompleteData.status_reasons,
                                                ...this.state.dimensionAutoCompleteData
                                            }}
                                            autoCompleteDataFilters={{
                                                name: {
                                                    type: (projects, project_types) => {
                                                        const projectTypes = project_types
                                                            .filter(g => typeof(g) === "object" && g.hasOwnProperty("data"))
                                                            .map(g => g.data.value);

                                                        return projects.filter(p => intersection(p.project_types, projectTypes).length > 0);
                                                    },
                                                    customership_group: "customership_groups_id|customership_groups.id",
                                                    reporting_group:    "product_structures_id|reporting_group.id",
                                                    branchofbusiness: "branchofbusiness_id|branchofbusiness.id",
                                                    customer: "customers_id|customer.id",
                                                },
                                                reporting_group: "customers_id|customer.id",
                                                ...this.state.dimensionAutoCompleteDataFilters
                                            }}
                                            perpage={this.props.perpage} />
                                    </div>
                                </div>
                                {this.renderSummarySection()}
                            </div>
                {this.props.view === "list" && (<div>
                    <List
                        virtualized={true}
                        virtualization__useHorizontalVirtualization={true}
                        rowHeight={44}
                        ref={this.list}
                        data={this.state.data.map(p => {
                            p['_quotePopUpOpen']        = false;
                            p['quotes']                 = false;
                            p['_projectIdIsDuplicate']  = false;
                            p['_projectIdFieldIsEmpty'] = false;

                            return p;
                        })}
                        // Alternative way to handle adding a new row when virtualized.
                        // overrideAddNewRow={({ row, data, id, list }) => {
                            // this.setState({
                                // data: [
                                    // row,
                                 // ...data
                                // ]
                            // });
                        // }}
                        columns={fields.filter(el => el.field != 'team_groups')}
                        sharedData={{ 
                            ...this.autoCompleteData,
                            autoCompleteDataMap: this.autoCompleteDataMap,
                            tagPoolSettingsData: this.state.tagPoolSettingsData
                        }}
                        height="fitRemaining"
                        parentKey="parentId"
                        enableToolbar={true}
                        minHeight={448}
                        trimHeight={-10}
                        className="projectList"
                        listRowType={ProjectListRow}
                        treeData={true}
                        parentsExpandedOnInit={true}
                        noStateData={true}
                        manualCreate={true}
                        showNoResultsMessage={this.initialFetchDone}
                        showOverlay={!this.state.hasProjects}
                        overlayComponent={ProjectListOverlay}
                        overlayProps={{addProject: this.addProject}}
                        onEdit={this.onEdit}
                        onToolbarExportClick={this.onToolbarExportClick}
                        onToolbarEditClick={this.onToolbarEditClick}
                        onToolbarDeleteClick={this.onToolbarDeleteClick}
                        roundbutton={this.beforeNew}
                        newRow={this.newRow}
                        rowProps={{ ...this.rowProps, currency: companyCurrency }}
                        saveColumnConfig={true}
                        userListSettingsKey="project_list"
                        useAllCheckedExcept={true}
                        showPageSelector={true}
                        controlPage={true}
                        pageCount={this.state.pageCount}
                        page={this.state.page}
                        totalCount={this.state.projectCount}
                        perpage={this.state.perpage}
                        onPerPageChange={this.onPerPageChange}
                        onPageChange={this.onPageChange}
                        onSortRows={this.onSortRows}
                        useHSRightPadding
                    />
                </div>)}
                {this.props.view === "kanban" && (
                    <ProjectKanban
                        company={this.state.filters.company}
                        projectWonReasonIsInUse={this.projectWonReasonIsInUse}
                        projectLostReasonIsInUse={this.projectLostReasonIsInUse}
                        data={this.state.data}
                        pipelines={this.autoCompleteData.pipelines}
                        projects_pipelines_id={this.state.filters.projects_pipelines_id}
                        status={[-1, -5].indexOf(parseInt(this.state.filters.projects_pipelines_id)) > -1 ? Math.abs(parseInt(this.state.filters.projects_pipelines_id)) : 0}
                        updateView={this.props.updateView}
                        openTeamChat={this.props.openTeamChat}
                        fetchData={this.fetchData}
                        archiveProject={this.rowProps.archiveProject}
                        onMove={this.fetchData} 
                        onWon={this.fetchData}
                        isProjectLimitReached={this.isProjectLimitReached}
                        openDialog={this.openDialog}
                        closeDialog={this.closeDialog}
                        onDialogSave={this.rowProps.onUpdate}
                        toggleBuyDialog={this.props.toggleBuyDialog}
                        />)}
                        </>
                    );
                }}
                </WithTabs>
                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                    getAccountTypes={this.fetchAccountTypes}
                    dialogType={this.dialogProps[this.state.currentDialog].dialogType}
                    refreshList={this.fetchData}
                    updateLimitInfo={this.updateLimitInfo}
                    // dialogProps={this.dialogProps[this.state.currentDialog].dialogProps}
                    dialogProps={{
                        ...this.state.currentDialogProps
                    }}
                />}
                <HoursReportSlider open={!!this.state.hoursReportProject} onClose={this.closeHoursReportSlider} project={this.state.hoursReportProject} />
            </div>
        );
    }
}

export default withSnackbar(ProjectList);


class DeleteDialogContent extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, null, "list/lists/ProjectList/DeleteDialogContent");
    }

    showProjectList(projects, header) {
        return (
            <>
                <span>{header}</span>
                <div class="non-deletable-list">
                    <ul>
                        {projects.map(p => (
                            <li>{p.name} ({p.project_id})</li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }

    render() {
        const data = this.props.data;

        return (
            <>
                {this.props.massDelete && (
                    <>
                        <DialogContentText id="project-delete-dialog-warning">
                            <div className="mass-delete">
                                {data.deletable_count === 0 && this.tr("No deletable projects!")}
                                {data.deletable_count > 0 && this.tr("You are about to permanently delete {{%placeholder-do-not-translate%}} project(s). The deletion of a project can not be canceled or undone. Are you sure you want to permanently delete the selected projects?").replace("{{%placeholder-do-not-translate%}}", `${data.deletable_count}`)}
                            </div>
                        </DialogContentText>
                        <DialogContentText id="project-delete-dialog-content">
                            {data.non_deletable_projects.length > 0 && this.showProjectList(data.non_deletable_projects, this.tr("Following ${amount} project(s) cannot be deleted because they have data", { amount: data.non_deletable_projects.length }) + ":")}
                            {data.non_allowed_projects.length > 0 && this.showProjectList(data.non_allowed_projects, this.tr("You are not allowed to delete following ${amount} project(s)", { amount: data.non_allowed_projects.length }) + ":")}
                        </DialogContentText>
                    </>
                )}
                {!this.props.massDelete && (
                    <>
                        <DialogContentText id="project-delete-dialog-warning">
                            {!data.deletable && this.tr("Cannot delete project: ${project}", {project: data.name + " (" + data.project_id + ")"}) + ". " + this.tr(data.delete_msg) + "!"}
                            {data.deletable && this.tr("Are you sure you want to delete project: ${project}?", {project: data.name + " (" + data.project_id + ")"})}
                        </DialogContentText>
                    </>
                )}
            </>
        )
    }
}
