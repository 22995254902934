import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import ExpenseList from '../../expenses/ExpenseList';
import BillsPoList from '../../bills/BillsPoList';

class Costs extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Costs');
        const {
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'expenses',
                label: this.tr('Expenses'),
                hidden: !hasPrivilege("worktrips"),
            },
            {
                id: 'travel-expenses',
                label: this.tr('Travel Expenses'),
                hidden: !hasPrivilege("worktrips"),
            },
            {
                id: 'bills',
                label: this.tr('Bills'),
                hidden: !hasPrivilege("receivedinvoices", ["pre_approve", "approve"])
            },
            {
                id: 'purchase-orders',
                label: this.tr('Purchase Orders'),
                hidden: !hasPrivilege("purchaseorders", ["read"]), 
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'expenses':
                        case 'travel-expenses':
                            //@ts-ignore
                            return <ExpenseList {...this.props} listType={selectedTab == 'travel-expenses' ? 'travelExpenses' : 'expenses'} />;
                        case 'bills':
                        case 'purchase-orders':
                            return <BillsPoList {...this.props} selectedTab={selectedTab == 'purchase-orders' ? 'po' : 'bills'} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Costs;
