import React from 'react';
import TaimerComponent from "../TaimerComponent";

import { makeMap } from "./ListUtils";
import { intersection, difference } from "./../general/Set";
import { getTextWidthInElement } from './../helpers';

import CreateIcon from '@mui/icons-material/Create';
import CloudDownload from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import tripleDotIconGrey from './icons/tripledot_grey.svg';
import tripleDotIconBlue from './icons/tripledot_blue.svg';

import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual"; 
import { ResizeSensor } from "css-element-queries";

import { Popper, Paper, Tooltip, CircularProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIListItemText from '@mui/material/ListItemText';
import MUIArrowDownward from "@mui/icons-material/ArrowDownward";
import MUIArrowUpward from "@mui/icons-material/ArrowUpward";
import MUIVisibilityOff from "@mui/icons-material/VisibilityOff";
import MUIViewColumn from "@mui/icons-material/ViewColumn";
import MUIExpandLess from '@mui/icons-material/ExpandLess';
import MUIExpandMore from '@mui/icons-material/ExpandMore';
import MUICheckBox from '@mui/icons-material/CheckBox';
import MUICheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import MUIInfoOutlined from '@mui/icons-material/InfoOutlined';
import { withSnackbar } from 'notistack';
import { PrintDisabled } from '@mui/icons-material';
import colors from '../colors';


function ColumnHeaderCheckbox(props = { checked: false, onClick: () => {} }) {
	return (
		<div className="checkbox">
			<div className={props.checked ? "checkmarkContainer checked deselector" : "checkmarkContainer"} onClick={props.onClick}></div>
		</div>
	);
}

function ColumnHeaderRoundButton(props = { symbol: "+", onClick: () => {} }) {
	return (
		<div data-testid="list-round-button" style={{ position: "relative", height: "100%" }}>
			<div onClick={!props.loading ? props.onClick : undefined} style={{cursor: "pointer", width: "24px", height: "24px", borderRadius: "12px", background: colors.greenish_cyan, textAlign: "center", lineHeight: "23px", fontSize: "20px", color: "#fff", position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px" }}>{props.loading ? <div className='list-round-button-loading-indicator'><CircularProgress size={12} color='white' /></div> : props.symbol}</div>
		</div>
	);
}

export function ColumnHeaderButton(props) {
    const className = `toolbarButton ${props.color}`;
    const Icon      = props.icon;
    const color     = props.color;

	return (
        <div className={className}>
            <div onClick={props.onClick}>
                {props.icon && <Icon className="columnHeaderButtonIcon" />}
                {props.title}
            </div>
		</div>
	);
}

function ColumnHeaderText(props) {
	return (
		<div className={"columnHeaderText"}>
            <div>{props.text}</div>
		</div>
	);
}


// This thing is a fucking mess, 
// but at least opening the column 
// menu doesn't trigger a rerender 
// of the whole List component anymore.

// Only relevant when enforceMinimumWidths is false.
const LIST_COLUMN_MIN_WIDTH              = 42;
const LIST_COLUMN_MIN_WIDTH_COMPENSATION = 55;
const LIST_WHAT_CHANGED_TEMPLATE         = {
    width: false,
    order: false,
    visibility: false,
    columnConfig: false,
    minimumWidths: false
};

class ListHeader extends TaimerComponent {
    static defaultProps = {
        // headerRef: undefined, // Ref from List.
        fluidFix: undefined, // Ref from List.
        allChecked: false,
        checkedRowsAmount: 0,
        className: "",
        columnsAmount: undefined,
        columnWidths: undefined,
        enforceMinimumWidths: true,
        fluid: false,
        fluidUsingPercentages: false,
        listWidth: undefined,
        listRef: undefined,
        maxColumnMenuHeight: 300,
        onSetColumnOrder: () => {},
        onColumnResize: () => {},
        onSortRows: () => {},
        onColumnConfigChange: () => {},
        toolbarMode: false
    };


    static alignClassNames = {
        left: "",
        center: "alignCenter",
        right: "alignRight"
    };
    
   
    constructor(props, context) {
        super(props, context, "list/ListHeader"); 

        this.state = {
            columns: cloneDeep(this.props.columns)
        };

        // TODO: Refs. Sort these out when you have the chance..
        this.columnDragger = React.createRef();
        this.header        = React.createRef();
        this.mainWrapper   = this.props.mainWrapper;
        this.resizeMarker  = this.props.resizeMarker;
        this.columnRefs    = {};
        this.columnOrder   = this.props.columns.map(c => c.name);

        this.minimumColumnWidthsDeterminedOnce = false;
        this.cachedMinimumWidths               = {};

		// Column resizing state.
		this.dragging                      = false;
		this.columnToResize                = undefined;
		this.dragStartX                    = undefined;
		this.currentColumnWidth            = undefined;
		this.resizeMarkerX                 = undefined;

		// Column reordering state.
		this.reordering                    = false;
		this.columnWasMoved                = false;
		this.columnToMove                  = undefined;
		this.replacee                      = undefined;
		this.startReorderingTimeout        = undefined;
		this.relativeColumnGrabX           = undefined;
		this.dropBeforeColumnName          = undefined;

		// Scrolling on x and y axes by "grabbing" states.
		this.scrollingByGrab               = false;
		this.startGrabScrollTimeout        = undefined;
		this.scrollingStartedAtCoordinates = undefined;
		this.scrollPositionsWhenStarted    = undefined;

        this.determineAndSetMinimumColumnWidths = this.determineAndSetMinimumColumnWidths.bind(this);
        this.handleColumnReferences             = this.handleColumnReferences.bind(this);
        this.startColumnResize                  = this.startColumnResize.bind(this);
        this.dragColumnResizer                  = this.dragColumnResizer.bind(this);
        this.endColumnResize                    = this.endColumnResize.bind(this);
        this.startColumnMoveCounter             = this.startColumnMoveCounter.bind(this);
        this.stopColumnMoveCounter              = this.stopColumnMoveCounter.bind(this);
        this.startColumnReorder                 = this.startColumnReorder.bind(this);
        this.moveColumn                         = this.moveColumn.bind(this);
        this.dropColumn                         = this.dropColumn.bind(this);
        this.getHeaderWidth                     = this.getHeaderWidth.bind(this);
        this.resizeColumn                       = this.resizeColumn.bind(this);
        this._resizeColumn                      = this._resizeColumn.bind(this);
        this._resizeColumnFluid                 = this._resizeColumnFluid.bind(this);

		// Wrapped listeners, that will get attached to document.body, and which we will have to be able to remove by their name.
		this.bodyMouseMoveListenerForColumn   	= event => this.moveColumn(event);
		this.bodyMouseUpListenerForColumn  	  	= event => this.dropColumn(event);
		this.bodyMouseUpStopColumnMoveCounter 	= event => this.stopColumnMoveCounter();
		this.bodyMouseMoveListenerForResizer  	= event => this.dragColumnResizer(event);
		this.bodyMouseUpListenerForResizer    	= event => this.endColumnResize(event);
		this.closeColumnMenuWindowListener 		= () => {
			window.removeEventListener("click", this.closeColumnMenuWindowListener);

			this.setState({
				columns: this.state.columns.map(c => {
					c.menuOpen = false;
					return c;
				})
			});
		};
    }


    componentDidMount() {
        super.componentDidMount();

        this.handleColumnReferences();

        if(this.props.enforceMinimumWidths && !this.props.fluid) {
            this.determineAndSetMinimumColumnWidths();
        }
    } 


    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(prevProps.columns, this.props.columns)) {
            this.handleColumnReferences();
            
            this.setState({
                columns: this.props.columns 
            }, () => {
                if(!this.props.enforceMinimumWidths || !this.minimumColumnWidthsDeterminedOnce || this.props.fluid) {
                    return;
                }

                this.determineAndSetMinimumColumnWidths();
            }); 
        }

        if(!isEqual(prevState.columns, this.state.columns)) {
            const extractRelevantColumns = c => {
                return {
                    name: c.name,
                    width: c.width,
                    visible: c.visible
                };
            };

            const prevColumns = cloneDeep(prevState.columns).map(extractRelevantColumns);
            const curColumns  = cloneDeep(this.state.columns).map(extractRelevantColumns);

            if(!isEqual(prevColumns, curColumns)) {
                const whatChanged = clone(LIST_WHAT_CHANGED_TEMPLATE);
                const prevMap     = makeMap(prevColumns, "name");

                curColumns.forEach(c => {
                    let prev = prevMap[c.name];

                    if(!prev) {
                        whatChanged.columnConfig = true;

                        return;
                    }

                    if(prev.width !== c.width) {
                        whatChanged.width = true;    
                    }

                    if(prev.visible !== c.visible) {
                        whatChanged.visibility = true; 
                    }
                });

                if(!isEqual(prevColumns.map(c => c.name), curColumns.map(c => c.name))) {
                    whatChanged.order = true; 
                }

                this.props.onColumnConfigChange(this.state.columns, whatChanged);
            }

        }

        if(!isEqual(this.props.columnOrder, this.columnOrder)) {
            this.columnOrder = this.props.columns.map(c => c.name);
        }

        if(this.props.enforceMinimumWidths && !this.minimumColumnWidthsDeterminedOnce && !this.props.fluid) {
            this.determineAndSetMinimumColumnWidths();
        }
    }


    determineAndSetMinimumColumnWidths() {
        const columnNames = Object.keys(this.columnRefs);
        const nonNullRefs = columnNames.filter(name => this.columnRefs[name].current !== null);

        if(columnNames.length === 0 || nonNullRefs.length === 0) {
            return;
        }

        const currentColumnsMap = makeMap(this.state.columns, "name");
        const minWidthMap       = {};

        nonNullRefs.forEach(name => {
            const titleSpans = Array.from(this.columnRefs[name].current.getElementsByClassName("titleSpan"));

            if(titleSpans.length === 0 || currentColumnsMap[name]?.noMinWidth) {
                minWidthMap[name] = currentColumnsMap[name].width;
            } else {
                const span        = titleSpans[0];
                const minWidth    = Math.round(getTextWidthInElement(span.innerText, span));

                minWidthMap[name] = minWidth + (LIST_COLUMN_MIN_WIDTH_COMPENSATION);
            }
        });

        const columns = cloneDeep(this.state.columns).map(column => {
            // If the column is already wider than
            // its minimum width, use its current width.
            column.width = (minWidthMap[column.name] > currentColumnsMap[column.name].width) 
                ? minWidthMap[column.name]  
                : currentColumnsMap[column.name].width;

            return column;
        });

        this.minimumColumnWidthsDeterminedOnce = true;
        this.cachedMinimumWidths               = minWidthMap;

        this.setState({ columns: columns });
    }


    handleColumnReferences() {
        const prev  = Object.keys(this.columnRefs);
        const curr  = this.props.columns.map(c => c.name);
        const refs  = {};
        const inter = intersection(prev, curr);

        if(inter.length === 0) {
            curr.forEach(name => refs[name] = React.createRef());
        } else {
            inter.forEach(name => refs[name] = this.columnRefs[name]);
            difference(curr, prev).forEach(name => refs[name] = React.createRef());
        }

        this.columnRefs = refs;
    }


	resizeColumn(columnName, width) {
        if(this.props.enforceMinimumWidths 
            && this.cachedMinimumWidths.hasOwnProperty(columnName) 
            && width < this.cachedMinimumWidths[columnName]) {

            width = this.cachedMinimumWidths[columnName];
        } else if(width < LIST_COLUMN_MIN_WIDTH) {
			width = LIST_COLUMN_MIN_WIDTH;
		}

        this.props.fluid ? this._resizeColumnFluid(columnName, width) : this._resizeColumn(columnName, width);
	}


    _resizeColumn(columnName, width) {
        this.setState({
            columns: cloneDeep(this.state.columns).map(c => {
                if(c.name === columnName) {
                    c.width = width;
                }

                return c;
            })
        });
    } 


    _resizeColumnFluid(columnName, width) {
        const currentWidth   = this.state.columns.find(c => c.name === columnName).width;
        const columnIndex    = this.state.columns.findIndex(c => c.name === columnName);
        const diff           = (width - currentWidth) * -1;
        const spreadOverN    = this.state.columns.length - (columnIndex + 1);
        const increment      = diff / spreadOverN;

        this.setState({
            columns: cloneDeep(this.state.columns).map((c, i) => {
                if(c.name === columnName) {
                    c.width = width;
                } else if(i > columnIndex) {
                    c.width += increment;
                }

                return c;
            })
        });
    }


	startColumnResize(event, column) {
		this.columnToResize = column;
		this.dragStartX 	= event.clientX;
		this.dragging 		= true;

		// TODO: Would be nice if the currently resized column would highlight somehow.
		// this.columnToResize.current.classList.add("resizingActive");

		this.dragColumnResizer(event); // To make the resizer visible even if the cursor hasn't been moved to change the size yet.

		document.body.addEventListener("mousemove", this.bodyMouseMoveListenerForResizer);
		document.body.addEventListener("mouseup", this.bodyMouseUpListenerForResizer);

		return true;
	}


	dragColumnResizer(event) {
		if(!this.dragging || event.clientX === 0)
			return;

		this.resizeMarkerX 		= event.clientX - this.mainWrapper.current.getBoundingClientRect().left + this.mainWrapper.current.scrollLeft;
		this.currentColumnWidth = this.props.columnWidths.find(w => w.name === this.columnToResize.name).width + (event.clientX - this.dragStartX);

		this.resizeMarker.current.classList.add("visible");
		this.resizeMarker.current.style.left = this.resizeMarkerX + "px";
	}


	endColumnResize(event) {
		if(!this.dragging)
			return;

		this.resizeMarker.current.classList.remove("visible");

		this.resizeColumn(this.columnToResize.name, this.currentColumnWidth);

		this.dragging 			= false;
		this.columnToResize 	= undefined;
		this.dragStartX 		= undefined;
		this.currentColumnWidth = undefined;
		this.resizeMarkerX	 	= undefined;

		document.body.removeEventListener("mousemove", this.bodyMouseMoveListenerForResizer);
		document.body.removeEventListener("mouseup", this.bodyMouseUpListenerForResizer);
	}


	startColumnMoveCounter(event, column) {
		if(this.dragging || event.button !== 0 || event.target.nodeName.toLowerCase() === "img" || column.menuOpen) // Pressing the triple dot menu icon seems to trigger this function, so prevent proceeding if that's the case.
			return;

		event.persist();

		document.body.addEventListener("mouseup", this.bodyMouseUpStopColumnMoveCounter);

		this.startReorderingTimeout = setTimeout(() => {
			this.startColumnReorder(event, column);
		}, 0);
	}


	stopColumnMoveCounter() {
		clearTimeout(this.startReorderingTimeout);

		this.startReorderingTimeout = undefined;

		document.body.removeEventListener("mouseup", this.bodyMouseUpStopColumnMoveCounter);
	}


	startColumnReorder(event, column) {
		this.reordering = true;

		// If the user grabbed the span, use event.target's parent, which is the actual div we want to move.
		this.columnToMove = event.target.tagName.toLowerCase() !== "span" ? event.target : event.target.parentNode;

		// TODO: Check that columnToMove isn't the last one in the header.
		if(this.columnToMove.nextElementSibling !== null && this.columnToMove.nextElementSibling !== undefined)
			this.columnToMove.nextElementSibling.style.marginLeft = this.columnToMove.style.width;

		// This value is calculated so we can hold onto the column-to-be-moved from the part where the user "grabbed".
		this.relativeColumnGrabX = event.clientX - this.columnToMove.getBoundingClientRect().left;

		// Setting .inReorderingState for this.columnToMove changes the value of evennt.target.offsetLeft, so this must be done like this.
		const tempOffsetLeft = this.columnToMove.offsetLeft;

		this.columnToMove.style.left = tempOffsetLeft + "px";
		this.columnToMove.classList.add("inReorderingState");

		// Added after setting the margin, so the animation doesn't happen yet.
		this.header.current.classList.add("reordering");

		let children = Array.from(this.header.current.children);

		for(let i in children) {
			if(i > 0 && children[i - 1] === this.columnToMove) {
				this.replacee = children[i];
            }
        }

		// Ögögög, ahaha.
		document.body.addEventListener("mousemove", this.bodyMouseMoveListenerForColumn);
		document.body.addEventListener("mouseup", this.bodyMouseUpListenerForColumn);
	}


	moveColumn(event) {
		if(!this.reordering)
			return;

		this.columnWasMoved = true;

		const newX 			   = event.clientX - this.header.current.getBoundingClientRect().left - this.relativeColumnGrabX;
        const colToMoveCenterX = newX + this.columnToMove.offsetWidth / 2;

		this.columnToMove.style.left = `${newX}px`;

		let children = Array.from(this.header.current.children).reverse();
		let replacee = this.replacee; // Ensures that if the loop below doesn't find a replacee, we have one set already.

		for(let child of children) {
			if(child === this.columnToMove || colToMoveCenterX >= child.offsetLeft + child.offsetWidth / 2)
				continue;

			replacee = child;
		}

		// Achhhhhh, hulabalooooo.
		if(colToMoveCenterX >= this.header.current.getBoundingClientRect().width - children[0].getBoundingClientRect().width / 2) {
			for(let child of children)
				child.style.marginLeft = "0px";

			this.dropBeforeColumnName = undefined;
			this.replacee 		  = undefined;

			return;
		}

		if(replacee === undefined)
			return;

		const replaceeName = replacee.dataset.name;
		const replaceeConf = this.state.columns.find(c => c.name === replaceeName);

		if(replaceeConf === undefined || !replaceeConf.moveable)
			return;

		for(let child of children)
			child.style.marginLeft = 0;

		replacee.style.marginLeft = this.columnToMove.style.width;
		this.dropBeforeColumnName = replacee.dataset.name;
		this.replacee 			  = replacee;
	}


	dropColumn(event) {
		if(!this.reordering)
			return;

		// This isn't 100% necessarily needed, but good housekeeping at least.
		document.body.removeEventListener("mousemove", this.bodyMouseMoveListenerForColumn);
		document.body.removeEventListener("mouseup", this.bodyMouseUpListenerForColumn);

		this.header.current.classList.remove("reordering");

		this.reordering = false;

		let slicePlace = this.dropBeforeColumnName !== undefined ? this.columnOrder.findIndex(c => c === this.dropBeforeColumnName) : this.columnOrder.length - 1;
		let toMove 	   = this.columnOrder.find(c => c === this.columnToMove.dataset.name);

		// Reset state.
		this.columnToMove.classList.remove("inReorderingState");

		// TODO: This could be a little more sophisticated somehow. This usually happens if the user just drops the column back where it was picked up without ever moving it.
		if(this.replacee !== undefined)
			this.replacee.style.marginLeft = 0;

        // The list was crashing when dragging the column from the right side (from the 3–dot-button's container I believe). The columnToMove's dataset was empty then so everything below this failed.
        // This seems to fix the issue completely.
        if (!toMove) {
            return;
        }

		let moveAsLast 				= this.dropBeforeColumnName === undefined;
		let columnWasMoved 			= this.columnWasMoved;

		this.reordering 			= false;
		this.columnWasMoved 		= false;
		this.columnToMove 			= undefined;
		this.replacee 				= undefined;
		this.startReorderingTimeout = undefined;
		this.relativeColumnGrabX 	= undefined;
		this.dropBeforeColumnName 	= undefined;

		if(slicePlace < 0 || !columnWasMoved)
			return;

		const start       = this.columnOrder.slice(0, slicePlace).filter(c => c !== toMove);
		const toMovePart  = [toMove];
		const rest        = this.columnOrder.slice(slicePlace).filter(c => c !== toMove);
        const columnOrder = start.concat(moveAsLast ? rest : toMovePart, moveAsLast ? toMovePart : rest);
		const map         = makeMap(cloneDeep(this.state.columns), "name");

        this.setState({ columns: columnOrder.map(c => map[c]) });
	}


    getHeaderWidth() {
        return (this.header.current === null) ? 0 : this.header.current.clientWidth;
    }


    render() {
        if(!this.props.visible)
            return null;

        const headerStyle = {};
        const commonConf  = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true,
            toolbarColumn: true 
        };

        const toolbarColumns = [
            ...(this.props.additionalToolbarColumns || []).map(col => ({ ...col, ...commonConf })),
            { name: "export", header: "", columnHeaderType: "exportButton", width: 100, ...commonConf, visibleInToolbar: (this.props.hiddenToolbarColumns || []).indexOf("export") == -1 },
            { name: "edit", header: "", columnHeaderType: "editButton", width: 100, ...commonConf, visibleInToolbar: (this.props.hiddenToolbarColumns || []).indexOf("edit") == -1 },
            { name: "delete", header: "", columnHeaderType: "deleteButton", width: 100, ...commonConf, visibleInToolbar: (this.props.hiddenToolbarColumns || []).indexOf("delete") == -1 },
            { name: "selected_quantity", header: "", columnHeaderType: "headerText", width: 260, ...commonConf, visibleInToolbar: (this.props.hiddenToolbarColumns || []).indexOf("selected_quantity") == -1 },
        ];

		if(this.props.fluid) {
            headerStyle['display']  = "flex";
            headerStyle['width']    = "100%";
		    headerStyle['minWidth'] = this.props.minWidth;
        } else {
            headerStyle['width']    = this.props.listWidth + "px";
        }

        return (
            <div className={`header ${this.props.className} ${this.props.toolbarMode? "toolbarVisible" : ""}`} ref={this.header} style={headerStyle}>
                <div className={`columnDragger ${this.props.className}`} ref={this.columnDragger}></div>
                {/* columns here */}
                {this.state.columns.concat(this.props.toolbarMode ? toolbarColumns : []).filter(c => {
                    return (!this.props.toolbarMode && c.visible) || c.visibleInToolbar;
                })
                .filter(column => !column.hidden)
                .map((column, index) => {
                    const e                      = this.props.columnWidths.find(w => w.name === column.name); // TODO: Make a map of the column widths.
                    const width                  = e !== undefined && e !== null ? e.width : column.width || this.props.columnDefaultWidth;
                    // ADHFIADOHTWREIGKSMNDfgblsdfhnkoiöj
                    const colStyle               = !column.toolbarColumn ? { width: width + "px", flex: column.noFlex ? 'none' : `${width} 1 0` } : {};
                    const resizeableClassName    = column.resizeable ? "resizeable" : "";
                    const moveableClassName      = column.moveable ? "moveable" : "";
                    const columnClassName	     = column.className ? column.className : "";
                    const toolbarColumnClassName = column.toolbarColumn ? "toolbarColumn" : "";
                    const alignClassName         = ListHeader.alignClassNames[column.alignRight ? "right" : column.alignment];

                    return (<Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={column.tooltipContent ?? ""} placement="top">
                        <div
                            key={index}
                            ref={this.columnRefs[column.name]}
                            className={`column ${moveableClassName} ${this.props.className} ${columnClassName} ${toolbarColumnClassName}`}
                            data-name={column.name}
                            title={column.header}
                            onMouseDown={column.moveable ? e => this.startColumnMoveCounter(e, column) : () => {}}
                            onMouseUp={column.moveable ? e => this.stopColumnMoveCounter() : () => {}}
                            title={column.showTitle ? (column.title || column.header) : null}
                            style={colStyle}>
                                {column.hasOwnProperty("content") && column.content}
                                {!this.props.hideHeaderButtons && !column.hasOwnProperty("content") && column.hasOwnProperty("columnHeaderType") && ({
                                    checkbox: <ColumnHeaderCheckbox checked={this.props.allChecked} onClick={() => this.props.listRef.checkAll(!this.props.allChecked)} />,
                                    roundButton: <ColumnHeaderRoundButton loading={this.props.listRef.props.roundButtonLoading} symbol="+" onClick={(e) => {
                                        if(this.props.listRef.props.roundbutton) {
                                            this.props.listRef.props.roundbutton(e);
                                        } else {
                                            if(typeof(this.props.listRef.props.beforeNew) === "function" && !this.props.listRef.props.beforeNew())
                                                return;

                                            this.props.listRef.addNewRow();
                                            this.props.listRef.props.onPressPlus();
                                        }
                                    }} />,
                                    exportButton: <ColumnHeaderButton title={this.tr("Export")} onClick={this.props.listRef.props.onToolbarExportClick} icon={CloudDownload} />,
                                    editButton: <ColumnHeaderButton title={this.tr("Edit")} onClick={this.props.listRef.props.onToolbarEditClick} icon={CreateIcon} />,
                                    deleteButton: <ColumnHeaderButton title={this.tr("Delete")} color="red" onClick={this.props.listRef.props.onToolbarDeleteClick} icon={DeleteIcon} />,
                                    headerText: <ColumnHeaderText text={(this.props.checkedRowsAmount) + " " + this.tr("rows selected")} />,
                                    ...this.props.additionalToolbarButtons
                                })[column.columnHeaderType]}
                                <div className={`columnHeaderContent ${alignClassName}`} style={{ float: column.alignRight ? "right" : undefined }}>
                                {!column.hasOwnProperty("columnHeaderType") && <span
                                    className="titleSpan">
                                    {this.props.showHiddenFromPrintIndicator && column.hiddenFromPrint && <Tooltip title={this.tr("Hidden from print")}><PrintDisabled /></Tooltip>}
                                    {column.header}
                                </span>}
                            <div className="rightSide">
                                {column.tooltipContent && <div className='columninfoIcon'><MUIInfoOutlined fontSize='small' /></div>}
                                {column.hasOwnProperty("additionalComponent") && column.additionalComponent()}
                                {!column.showMenu && column.showMenuContainer && <div className="menuContainer" draggable="false"></div>}
                                {column.showMenu && <div className="menuContainer" draggable="false">
                                    <img alt="" draggable="false" src={!column.menuOpen ? tripleDotIconGrey : tripleDotIconBlue} onClick={(event) => {
                                        const clickedColumn = this.state.columns.find(c => c.name === column.name);
                                        const newOpenState  = !clickedColumn.menuOpen;
                                        const columns       = cloneDeep(this.state.columns);

                                        this.setState({
                                            anchorEl: event.currentTarget, 
                                            columns: columns.map(c => {
                                                if(c.name === clickedColumn.name) {
                                                    c.menuOpen = newOpenState;
                                                } else if(newOpenState) {
                                                    c.menuOpen = false;
                                                }

                                                return c;
                                            })
                                        });
                                    }} onMouseOver={e => e.target.setAttribute("src", tripleDotIconBlue)} onMouseOut={e => !column.menuOpen && (e.target.setAttribute("src", tripleDotIconGrey))} className="tripledot" />
                                    {column.menuOpen && <Popper open={column.menuOpen} anchorEl={this.state.anchorEl} placement='bottom-start'>
                                        <ClickAwayListener onClickAway={(e) => {
                                        if(!e.target.classList.contains("tripledot")) {
                                            this.setState({
                                                columns: cloneDeep(this.state.columns).map(c => {
                                                    c.menuOpen = false;
                                                    return c;
                                                })
                                            });
                                        }
                                    }}>
                                        <MUIList
                                            style={{ maxHeight: this.props.maxColumnMenuHeight, overflow: "auto", boxSizing: "border-box" }}
                                            className="muiColumnMenuListPopper"
                                            component="div"
                                            onClick={e => e.stopPropagation()}>
                                            {column.sortable && <MUIListItem button className="muiListItem" onClick={() => this.props.onSortRows(column.name, true)}>
                                                <MUIListItemIcon>
                                                    <MUIArrowUpward />
                                                </MUIListItemIcon>
                                                <MUIListItemText inset primary={this.tr("Sort ascending")} />
                                            </MUIListItem>}
                                            {column.sortable && <MUIListItem button className="muiListItem" onClick={() => this.props.onSortRows(column.name, false)}>
                                                <MUIListItemIcon>
                                                    <MUIArrowDownward />
                                                </MUIListItemIcon>
                                                <MUIListItemText inset primary={this.tr("Sort descending")} />
                                            </MUIListItem>}
                                            {column.hideable && <MUIListItem button className="muiListItem" onClick={() => {
                                                const newColumns = cloneDeep(this.state.columns).map(c => {
                                                    if(c.name === column.name) {
                                                        c.visible  = false;
                                                        c.menuOpen = false;
                                                    }

                                                    return c;
                                                });

                                                // this.props.onColumnVisibilityChange(newColumns);
                                                if (newColumns.filter(col => col.showMenu && col.visible).length == 0) {
                                                    this.props.enqueueSnackbar(this.tr("You have to have at least one column with a menu visible."), {
                                                        variant: 'error'
                                                    });
                                                    return;
                                                }

                                                this.setState({
                                                    columns: newColumns
                                                });

                                                setTimeout(() => { window.removeEventListener("click", this.closeColumnMenuWindowListener) }, 0);
                                            }}>
                                                <MUIListItemIcon>
                                                    <MUIVisibilityOff />
                                                </MUIListItemIcon>
                                                <MUIListItemText inset primary={this.tr("Hide this column")} />
                                            </MUIListItem>}
                                            {!column.hideColumnsFromMenu && <MUIListItem button className="muiListItem" onClick={() => {
                                                this.setState({ columns: cloneDeep(this.state.columns).map(c => {
                                                    if(c.name === column.name)
                                                        c.columnVisibilityMenuOpen = !c.columnVisibilityMenuOpen;

                                                    return c;
                                                })})
                                            }}>
                                                <MUIListItemIcon>
                                                    <MUIViewColumn />
                                                </MUIListItemIcon>
                                                <MUIListItemText inset primary={this.tr("Columns")} />
                                                {column.columnVisibilityMenuOpen ? <MUIExpandMore /> : <MUIExpandLess />}
                                            </MUIListItem>}
                                            <Collapse in={column.columnVisibilityMenuOpen}>
                                                <MUIList component="div">
                                                    {this.state.columns.filter(c => c.hideable && !c.hidden).map(colVisColumn => {
                                                        return (<MUIListItem button style={{ paddingLeft: "30px" }} className="muiListItem" onClick={() => {
                                                            const newColumns = cloneDeep(this.state.columns).map(c => {
                                                                if(c.name === colVisColumn.name) {
                                                                    c.visible = !c.visible;
                                                                }

                                                                if(!c.visible && colVisColumn.name === column.name)
                                                                    c.menuOpen = false;

                                                                return c;
                                                            });

                                                            if (newColumns.filter(col => col.showMenu && col.visible).length == 0) {
                                                                this.props.enqueueSnackbar(this.tr("You have to have at least one column with a menu visible."), {
                                                                    variant: 'error'
                                                                });
                                                                return;
                                                            }

                                                            // this.props.onColumnVisibilityChange(newColumns);

                                                            this.setState({
                                                                columns: newColumns
                                                            });
                                                        }}>
                                                            <MUIListItemIcon>
                                                                {colVisColumn.visible ? <MUICheckBox /> : <MUICheckBoxOutlineBlank />}
                                                            </MUIListItemIcon>
                                                            <MUIListItemText inset primary={colVisColumn.header} />
                                                        </MUIListItem>);
                                                    })}
                                                </MUIList>
                                            </Collapse>
                                        </MUIList>
                                    </ClickAwayListener>
                                    </Popper>}
                                </div>}
                                {index < (this.props.columnAmount - 1) && column.showResizeMarker && <div className={`resizer ${resizeableClassName} ${this.props.className}`} onMouseDown={column.resizeable ? e => this.startColumnResize(e, column) : () => {}}><div className={`marker ${this.props.className}`}></div></div>}
                                </div>
                            </div>
                        </div>
                    </Tooltip>);
                })}
                {this.props.fluid && !this.props.hideFluidFix && <div className="column" ref={this.props.fluidFix}></div>}
            </div>
        );
    }
}

export default withSnackbar(ListHeader);
