import React, { Component } from 'react'
import DataHandler from '../../general/DataHandler';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import List from '../../list/List';
import ContextMenu from '../../general/ContextMenu';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import FileIcon from '@mui/icons-material/NoteOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { SettingsContext } from '../../SettingsContext';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TaimerComponent from "../../TaimerComponent";
import CircularProgress from '@mui/material/CircularProgress';
import $ from 'jquery';


import EditIcon from "@mui/icons-material/Edit";
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';

//Place to define 'special' material ui css changes
const styles = theme => ({
    groupprogress: {
        width: "100%",
    }
});

class OnedriveRow extends TaimerComponent {

    constructor(props) {
        super(props, null, "onedrive/OnedriveComponents/OnedriveView");
    }

    //Delete buttons function at row menu
    handleDelete = event => {
        const { rowProps, data } = this.props;
        rowProps.handleDelete(data.id);
    };

    //Rename buttons function at row menu
    handleRename = event => {
        const { rowProps, data } = this.props;
        rowProps.handleRename(data.id, data.name);
    };

    //Function to open clicked item at list (Folder or File)
    handleItem(event) {
        const { rowProps, data } = this.props;
        rowProps.handleItem(data.id, data.name);
    }

    //Changes data size of file from bytes to GB, Mb and kB (to not show 10251876 bytes)
    humanFileSize = (bytes, si) => {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    }

    //Defines row of list that List element uses
    render() {
        const { columnOrder, columnWidthMap, data } = this.props;
        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" }} } 
        });

        const cells = {
            "context": (
                <div {...props.context}>
                    <ContextMenu label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" noExpandIcon>
                        <MenuItem className="delete" onClick={()=>this.handleDelete(data.id)}><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem>
                        <MenuItem onClick={()=>this.handleRename(data.id, data.name)}><EditIcon />{this.tr("Rename")}</MenuItem>
                    </ContextMenu>
                </div>
            ),
            "filename": (
                <div {...props.filename}>{!data["folder"] ? 
                <a href={data["webUrl"]} target="_blank"><FileIcon className={"listicon"} />{data.name}</a> :
                <a onClick={()=>this.handleItem(data.id, data.name)}><FolderIcon className={"listicon"} />{data.name}</a>}</div>
            ),
            "size": (
                <div {...props.size}>{data.size > 0 ? this.humanFileSize(data.size, true) : undefined}</div>
            ),
        };

        return (
            <div className="list-element">
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }     
}

class OnedriveView extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props) {
        super(props, null, "onedrive/OnedriveComponents/OnedriveView");
        this.state = {
            children: [],
            folderid: "root",
            anchorEl: null,
            anchorElDrive: null,
            anchorElLogout: null,
            completed: 0,
            loading: false,
            renameName: "",
            renameId: "",
            newname: "",
            folderstate: [],
            groups: [],
            currentdrive: "me",
            drivename: "MyDrive",
            rootname: "Files",
            grouploading: true,
            dialog: ""
        }
    }

    //Runs when component is rendered
    componentDidMount() {
        this.getRoot();
        this.getGroups();
    }

    //Opens dialog to create new folder where to define its name
    newfolderOpen = () => {
        this.setState({ dialog: "New folder" });
        this.setState({ open: true });
    }

    //Opens dialog to rename folder or file
    renameOpen = (id, name) => {
        this.setState({ dialog: "Rename", renameId: id, renameName: name });
        this.setState({ open: true });
    }

    //When rename dialogs rename button is selected
    renameRename = () => {
        if(this.state.newname == "") {
            this.setState({ open: false, renameId: "", renameName: "" });
            return;
        }

        this.setState({loading: true});
        this.setState({ open: false });
        const driveid = this.state.currentdrive;

        DataHandler.post({url: `onedrive/rename`},{driveid: driveid, itemid: this.state.renameId, newname: this.state.newname}).done(response => {
            this.getFolder(this.state.folderid.id, this.state.folderid.name);
        }).fail(response => {
            this.setState({loading: false});
        });

        this.setState({ renameId: "", renameName: "" });
    }

    //When 'cancel' is selected in any dialog at component
    dialogCloseCancel = () => {
        this.setState({ open: false, renameId: "", renameName: "", newname: "" });
    }

    //When delete is selected to delete folder or a file
    deleteItem = (id) => {
        this.setState({loading: true});
        const driveid = this.state.currentdrive;

        DataHandler.delete({url: `onedrive/delete`},{driveid: driveid, itemid: id}).done(response => {
            this.getFolder(this.state.folderid.id, this.state.folderid.name);
        }).fail(response => {
            this.setState({loading: false});
        });
    };

    //When folder is selected from list or top of the page
    getFolder = (id, name = undefined, driveid = undefined) => {

        this.setState({loading: true});

        if(!driveid){
            driveid = this.state.currentdrive;
        }

        DataHandler.post({url: `onedrive/children`},{driveid: driveid, itemid: id}).done(response => {
            this.setState(response);

            //Changes folderstate to match selected folder (foldertree at top of the page)  
            if(name) {
                if(this.state.folderstate.length > 0) {
                    let newarray = [];
                    let found = false;;
        
                    this.state.folderstate.forEach(function(folder) {
                        if(folder.id !== id && !found) {
                            newarray = [...newarray, folder];
                        } else {
                            found = true;
                        }
                    })
        
                    newarray = [...newarray, {id: id, name: name}];
                    this.setState({folderstate: newarray });
                } else {
                    this.setState({folderstate: [...this.state.folderstate, {id: id, name: name}] });
                }
            }

            this.setState({folderid: {id: id, name: name}, loading: false});       
        }).fail(response => {
            this.setState({loading: false});
        });

    };

    //When new drive or root folder of drive is selected 
    getRoot = () => {
        this.setState({loading: true});
        const driveid = this.state.currentdrive;

        DataHandler.post({url: `onedrive/children`},{driveid: driveid, itemid: "root"}).done(response => {
            this.setState(response);
            this.setState({folderid: {id: "root", name: "root"}, loading: false, folderstate: []});
        }).fail(response => {
            this.setState({loading: false});
        });
    };

    //When drive is selected from 'MyDrive' menu
    getDrive = (driveid, itemid, drivename, rootname) => {
        this.setState({currentdrive: driveid, folderstate: [], drivename: drivename, rootname: rootname});
        this.getFolder(itemid, undefined, driveid);
        this.handleClose();
    };

    //Gets groups drives those are rendered under 'MyDrive' menu
    getGroups = () => {
        this.setState({loading: true});

        DataHandler.get({url: `onedrive/groups`}).done(response => {
            if(response.groups !== null) this.setState(response);
            this.setState({loading: false, grouploading: false});
        }).fail(response => {
            this.setState({loading: false, grouploading: false});
        });
    };

    //Creates new folder when 'Create folder' is selected from dialog 
    createFolder = () => {
        const id = this.state.folderid.id;
        const driveid = this.state.currentdrive;
        this.setState({ open: false, loading: true });

        if(this.state.newname == "") {
            DataHandler.post({url: `onedrive/folder`},{itemid: id, name: "NewFolder", driveid: driveid}).done(response => {
                this.getFolder(id, this.state.folderid.name);
                
            }).fail(response => {
                this.setState({loading: false});
            });
        } else {
            DataHandler.post({url: `onedrive/folder`},{itemid: id, name: this.state.newname, driveid: driveid}).done(response => {
                this.getFolder(id, this.state.folderid.name);
            }).fail(response => {
                this.setState({loading: false});
            });
        }

        this.handleClose();
    }

    //Creates new Word file to folder
    createWord = () => {
        const id = this.state.folderid.id;
        const driveid = this.state.currentdrive;
        this.setState({loading: true});

        DataHandler.post({url: `onedrive/wordfile`},{itemid: id, driveid: driveid}).done(response => {
            this.getFolder(this.state.folderid.id, this.state.folderid.name);
            /* window.open(response.file.webUrl); */
            
        }).fail(response => {
            this.setState({loading: false});
        });
        //Closes menu
        this.handleClose();
    }

    //Creates new Excel file to folder
    createExcel = () => {
        const id = this.state.folderid.id;
        const driveid = this.state.currentdrive;
        this.setState({loading: true});

        DataHandler.post({url: `onedrive/excelfile`},{itemid: id, driveid: driveid}).done(response => {  
            this.getFolder(this.state.folderid.id, this.state.folderid.name);
            /* window.open(response.file.webUrl); */
            
        }).fail(response => {
            this.setState({loading: false});
        });
        //Closes menu
        this.handleClose();

    }

    //Creates new PowerPoint file to folder
    createPresentation = () => {
        const id = this.state.folderid.id;
        const driveid = this.state.currentdrive;
        this.setState({loading: true});

        DataHandler.post({url: `onedrive/presentationfile`},{itemid: id, driveid: driveid}).done(response => {
            this.getFolder(this.state.folderid.id, this.state.folderid.name);
            /* window.open(response.file.webUrl); */
            
        }).fail(response => {
            this.setState({loading: false});
        });
        //Closes menu
        this.handleClose();
    }

    //Uploads selected file to folder
    uploadFiles = (evt) => {

        evt.stopPropagation();
        evt.preventDefault();
        const id = this.state.folderid.id;
        const drive = this.state.currentdrive;
        this.setState({loading: true});

        Array.from(evt.target.files || evt.dataTransfer.files).forEach(e => {
            DataHandler.get({url: `onedrive/uploadsession/${id}/${e.name}/${drive}`}).done(response => {
                $.ajax({
                    type: "PUT",
                    headers: {
                      "Content-Range": "bytes 0-" + (e.size - 1)  + '/' + e.size,
                    },
                    url: response.uploadsession.uploadUrl,
                    xhr: () => {
                        var xhr = $.ajaxSettings.xhr();
                        xhr.upload.onprogress = (e) => {
                            if (e.lengthComputable) {
                                if((e.loaded / e.total) >= 1) {
                                    this.setState({ completed: 0 });
                                } else {
                                    let currentprogression = ((e.loaded / e.total) * 100);
                                    this.setState({ completed: currentprogression });
                                }
                            }
                        };
                        return xhr;
                    },
                    data: e,
                    processData: false,
                    contentType: false,
                }).done(response => {
                    this.getFolder(this.state.folderid.id, this.state.folderid.name);
                }).fail(response => {
                    this.setState({loading: false});
                });;
                
            }).fail(response => {
                this.setState({loading: false});
            });;
        });
    }

    //Funtion to achor logout menu
    handleClickLogoutMenu = event => {
        this.setState({ anchorElLogout: event.currentTarget });
    };

    //Funtion to achor drive menu
    handleClickDrive = event => {
        this.setState({ anchorElDrive: event.currentTarget });
    };

    //Funtion to achor new menu
    handleClickNew = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    //Function to close all menus
    handleClose = () => {
        this.setState({ anchorEl: null, anchorElDrive: null, anchorElLogout: null});
    };

    //Logout function
    logout = () => {
        DataHandler.get({url: `onedrive/logout`}).done(response => {  
            window.location.href = `index.php?module=onedrive&action=login`;
        })
    }

    //Sorts row to ascending or descending
    sortRows = (colName, asc) => {
        let sortedArray;
        if(colName == "size") {
            if(asc){
                sortedArray = this.state.children.sort(function (x, y) {
                    return x.size - y.size;
                });
            }else {
                sortedArray = this.state.children.sort(function (x, y) {
                    return y.size - x.size;
                });
            }   
        }
        if(colName == "filename") {
            if(asc){
                sortedArray = this.state.children.sort(function (x, y) {
                    var a = x.name.toUpperCase(),
                        b = y.name.toUpperCase();
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                sortedArray = this.state.children.sort(function (x, y) {
                    var a = x.name.toUpperCase(),
                        b = y.name.toUpperCase();
                    if (a < b) {
                        return 1;
                    }
                    if (a > b) {
                        return -1;
                    }
                    return 0;
                });
            }
        }
        this.setState({sortedArray});
    }
    
    render() {
        const { classes } = this.props;
        const { anchorEl, anchorElDrive, anchorElLogout} = this.state;

        //Functions for rows
        const rowProps = {
            handleItem: this.getFolder,
            handleDelete: this.deleteItem,
            handleRename: this.renameOpen,
        };

        //Rows columns settings
        const columns = [
            { name: "context", header: "",  width: 20, showMenu: false, resizeable: true, showResizeMarker: true, moveable: false, hideable: false },
            { name: "filename", header: "Filename", width: 500 },
            { name: "size", header: "Size", width: 100 },
        ];

        return (
            <div id="onedrive-view">
                <div className="top-first-div">
                    <Button aria-owns={anchorElDrive ? 'drive-menu' : undefined} aria-haspopup="true" onClick={this.handleClickDrive} variant="text" className="top-button drive-button" >
                        {this.state.drivename}
                    </Button>
                    <Menu id="drive-menu" anchorEl={anchorElDrive} open={Boolean(anchorElDrive)} onClose={this.handleClose} >           
                        <MenuItem onClick={()=>this.getDrive("me", "root", "MyDrive", "Files")} >MyDrive</MenuItem>
                        { this.state.grouploading ? <MenuItem><LinearProgress className={classes.groupprogress} /></MenuItem> : undefined }
                        {
                            this.state.groups.map(group => {
                                return (<MenuItem onClick={()=>this.getDrive(group.driveid, "root", group.displayName, group.rootname)}>{group.displayName}</MenuItem>);
                            })
                        }
                    </Menu>
                    <Button className="top-button folder-button" onClick={()=>this.getRoot()} variant="text"><ArrowRightIcon />{this.state.rootname}</Button>
                    {
                        this.state.folderstate.map(folder => {
                            return (<Button className="top-button folder-button" onClick={()=>this.getFolder(folder.id, folder.name)} variant="text"><ArrowRightIcon />{folder.name}</Button>);
                        })
                    }
                    <IconButton
                        disableRipple={true}
                        className="topbutton logout-button"
                        aria-label="More"
                        aria-owns={Boolean(anchorElLogout) ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickLogoutMenu}
                        size="large">
                        <MoreIcon />
                    </IconButton>
                    <Menu id="simple-menu" anchorEl={anchorElLogout} open={Boolean(anchorElLogout)} onClose={this.handleClose}>
                        <MenuItem onClick={this.logout}>{this.tr("Logout")}</MenuItem>
                    </Menu>
                </div>
                <div className="top-second-div">
                    <Button aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={this.handleClickNew} variant="text" className="top-button new-button" >
                        <AddIcon /> 
                        {this.tr("New")}
                    </Button>
                    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose} >
                        <MenuItem onClick={this.newfolderOpen}>{this.tr("Folder")}</MenuItem>
                        <MenuItem onClick={this.createWord}>Word</MenuItem>
                        <MenuItem onClick={this.createExcel}>Excel</MenuItem>
                        <MenuItem onClick={this.createPresentation}>PowerPoint</MenuItem>
                    </Menu>
                    <Button raised component="label" className="top-button" variant="text" >
                        {this.tr("Upload")}
                        <input onChange={this.uploadFiles} type="file" style={{ display: 'none' }} />
                    </Button>
                    {this.state.completed > 0 ? <LinearProgress variant="determinate" value={this.state.completed} /> : undefined} 
                    {this.state.loading == true && this.state.completed <= 0 ? <LinearProgress /> : undefined}
                </div>
                <div className="middle-div">
                    { this.state.children.length > 0 ? <List
                        fluid={true}
                        columns={columns}  
                        height="fitRemaining" 
                        data={this.state.children} 
                        listRowType={OnedriveRow} 
                        rowProps={rowProps}
/*                         saveColumnConfig={true} */
                        showPageSelector={false} 
                        onSortRows={this.sortRows} /> : undefined }
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        >
                        <DialogTitle id="form-dialog-title">{this.state.dialog}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                defaultValue={this.state.dialog == this.tr("Rename") ? this.state.renameName : "NewFolder"}
                                onChange={e => this.setState({ newname: e.target.value })}
                                type="text"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogCloseCancel} color="primary">
                                {this.tr("Cancel")}
                            </Button>
                            <Button onClick={this.state.dialog == "Rename" ? this.renameRename : this.createFolder} color="primary">
                                {this.state.dialog == "Rename" ? this.tr("Rename") : this.tr("Create folder")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(OnedriveView);
