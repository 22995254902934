import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { AccessTime, Receipt } from "@mui/icons-material";
import PropTypes from "prop-types";
import "./HoursOverview.css";
import InsightBlock from "../../InsightBlock";
import InsightSlider from "../../InsightSlider";
import _ from 'lodash';

const pieColors = ["#f5a623", "#2d9ff7", "#f97fab"];

const BlockWithIcon = props => {
  const { icon, header, value, subText, onClick } = props;
  return (
    <div data-testid={props['data-testid']} onClick={onClick} className="hours-overview-block highlightable">
      <div className="square-icon-container">
        <div className="square-icon">{icon}</div>
      </div>
      <div className="text-container">
        <p>{header}</p>
        <h1 data-testid={`${props['data-testid']}-value`} >{value}</h1>
        {subText && <div className="sub-text-container">{subText}</div>}
      </div>
    </div>
  );
};

const sliderColumnsForIndex = index => {
  switch (index) {
    default:
      return ["account", "project", "tracked", "split"];
  }
};

const _parsePieData = data => {
  return {
    labels: _.map(data, obj => obj.name),
    datasets: [
      {
        data: _.map(data, obj => obj.value),
        backgroundColor: pieColors,
        hoverBackgroundColor: pieColors
      }
    ]
  };
};

const BlockWithGraph = props => {
  const { header, data, onClick, numberFormatter, tr } = props;
  const pieData = _parsePieData(data);
  const labels = pieData.labels.map((label, index) => {
    return (
      <div
        onClick={() =>
          onClick({
            type: data[index].type,
            columns: sliderColumnsForIndex(index),
            data: data[index].data,
            label
          })
        }
        className="graph-label-container highlightable-text"
        key={index}
      >
        <div
          className="graph-label-color-indicator"
          style={{
            backgroundColor: pieData.datasets[0].backgroundColor[index]
          }}
        />
        <p className="graph-label-value-text">{Number(data[index].value || 0).toFixed(2)} h</p>
        <p className="graph-label-text">
          {label}

          {data[index].value_non_approved > 0 && <span className="graph-label-approval">
            , {tr("of which")} {Number(data[index].value_non_approved || 0).toFixed(2)}<span className="post-value"> h {tr("in approval")}</span>
          </span>}
        </p>
      </div>
    );
  });
  return (
    <div className="hours-overview-block">
      <div className="square-icon-container">
        <div className="graph">
          <Doughnut
            data={pieData}
            width={80}
            height={80}
            options={{ maintainAspectRatio: true, cutout: '75%' }}
          />
        </div>
      </div>
      <div className="text-container">
        <p>{header}</p>
        <div className="graph-labels">{labels}</div>
      </div>
    </div>
  );
};

BlockWithIcon.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element
};

const HoursOverview = props => {
  const { tr, currencyFormatter, numberFormatter, currency, data, showSlider } = props;
  const totalColumns = ["user", "tracked", "split"];
  const billableColumns = [
    "account",
    "project",
    "tracked",
    "total_billable",
    "split"
  ];

  return (
    <div className="full-width">
      <InsightBlock noPadding={true} noHeader={true} width={6}>
        <div className="hours-overview">
          <BlockWithIcon
            onClick={() =>
              showSlider({
                columns: totalColumns,
                label: tr("Hours Insight") + " - " + tr("Total Hours"),
                type: 'total',
              })
            }
            header={tr("Total Hours")}
            data-testid="total-hours"
            value={Number(data.total.value || "0").toFixed(2) + " h"}
            subText={data.total.value_non_approved > 0 && <span>
              {tr("of which")}{" "}{Number(data.total.value_non_approved || "0").toFixed(2)}
              <span className="post-value"> h {tr("in approval")}</span>
            </span>}
            icon={
              <AccessTime style={{ color: "white", height: 45, width: 45 }} />
            }
          />
          <BlockWithGraph
            tr={tr}
            onClick={data => showSlider({...data, label: tr("Hours Insight") + " - " + data.label})}
            header={tr("Billable Hours")}
            data-testid="billable-hours"
            numberFormatter={numberFormatter}
            data={data.pie_data || []}
          />
          <BlockWithIcon
            onClick={() =>
              showSlider({
                columns: billableColumns,
                type: 'billable_amount',
                label: tr("Hours Insight") + " - " + tr("Billable Amount")
              })
            }
            data-testid="billable-amount"
            header={tr("Billable Amount")}
            value={currencyFormatter(data.billable_amount.value || "0")}
            subText={data.billable_amount.value_non_approved > 0 && <span>
                {tr("of which")}{" "}{currencyFormatter(data.billable_amount.value_non_approved || "0")}
                <span className="post-value"> {tr("in approval")}</span>
              </span>}
            icon={<Receipt style={{ color: "white", height: 45, width: 45 }} />}
          />
        </div>
      </InsightBlock>
    </div>
  );
};

export default HoursOverview;
