import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';
import './IntegrationContainer.css';
import colors from '../../colors';

class IntegrationContainer extends TaimerComponent{

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/components/IntegrationContainer");
        
        this.handleClick = this.handleClick.bind(this);
        //this.editClick = this.editClick.bind(this);


    }

    handleClick(key, active){
        const {functions: { updateView } } = this.context;

        if (this.props.activateInstantly) {
            DataHandler.put({url: `settings/company/${this.props.company}/${this.props.name}`});
        }

        updateView({subpage: key});    
    }

    /*editClick(key, active){
        const {functions: { updateView } } = this.context;
        updateView({subpage: key, active: active});
    }*/
//
    render(){
        const {active, className, label, sublabel, img, imgText, name, soon} = this.props;
        const { tr } = this;
        const StyledChip = withStyles({
			root: {
                float: "right",
                marginTop: 16,
                marginRight: 16,
				backgroundColor: colors.greenish_cyan,
				width: '77px',
				height: '23px'
			},
			label: {
				color: 'white !important',
				fontWeight: '600',
				fontSize: "12px"
			},
        })(Chip);
        
        return(
            <div className={"main-container-wrapper " + className}>
                <div className={"container"}>
                    <div className="container-content-top">
                        <div className="chip-container"> 
                            {active ? <StyledChip label={tr("Activated")}/> : undefined}
                        </div>
                        <div className="img-container"> 
                            {img && <img src={img} />}
                            {imgText && imgText}
                        </div>
                    </div>
                    <div className="container-content-middle">
                        <div className="text-container">
                            <h4>{label}</h4>
                            <p>{sublabel}</p>
                        </div>
                    </div>
                    <div className="container-content-bottom">
                    {active == true ?
                        <Button 
                        className="edit button" 
                        size="large" 
                        onClick={() => this.handleClick(name, active)} >{tr("EDIT")}</Button>
                        :
                        <Button 
                        className="activate button" 
                        size="large" 
                        color="primary" 
                        onClick={() => this.handleClick(name, active)} >{tr("ACTIVATE")}</Button>    
                    }
                    </div>
                </div>
                {soon ? <div className="overlay"><h2>{tr("Coming Soon.")}</h2></div> : undefined}
            </div>
       )
    }
}

IntegrationContainer.defaultProps = {
    activateInstantly: false,
    className: ""
}

export default IntegrationContainer;
