import React from 'react';
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import CurrencyListCell from "../CurrencyListCell";
import LinkListCell from "../LinkListCell";
import MultiLinkListCell from "../cells/MultiLinkListCell";
import { format } from "date-fns";
import ContextMenu, { ContextSubMenu } from '../../general/ContextMenu';
import MenuItem from '@mui/material/MenuItem';
import CheckboxCell from "../cells/CheckboxCell";
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import PrintIcon from '@mui/icons-material/Print';

/* context */
import { SettingsContext } from './../../SettingsContext';

export default class PurchaseOrderListRow extends PropsOnlyListRow {
    static contextType = SettingsContext;

    constructor(props, context) {
		super(props, null, undefined, "list/rows/PurchaseOrderListRow");

        this.context = context;
    }

    defineClassName() {
        return "purchaseOrderListRow";
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            super.shouldComponentUpdate(nextProps, nextState) ||
            this.props.sharedData.customers != nextProps.sharedData.customers
        );
    }

    defineCells() {
        const { data, checked } = this.props;
        const printLanguageOptions = this.props.rowProps.printLanguageOptions;
        const commonProps = {
            editable: false
        };

        return {
            expand:
                <ContextMenu data-testid={`purchaseorder-list-row-context-menu-${data.id}`} label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: this.props.columnWidthMap['expand'] + 'px', flex: this.props.columnWidthMap['expand'] + " 1 0px" }} noExpandIcon>
                    <MenuItem data-testid={`purchaseorder-list-row-context-menu-view-${data.id}`} onClick={() => this.context.functions.updateView({ module: "purchaseorder", action: "view", id: data.id, companies_id: this.props.rowProps.companies_id })}><ViewIcon />{this.tr("View")}</MenuItem>
                    { printLanguageOptions.length > 1 ?
                        <ContextSubMenu title={this.tr('Print')} icon={<PrintIcon title="" />} >
                            {printLanguageOptions.map(pl =>
                                <MenuItem onClick={() => this.props.rowProps.print(pl.value, this.props.data.id)}>{this.tr(pl.label)}</MenuItem>
                            )}
                        </ContextSubMenu>
                        : 
                        <MenuItem onClick={() => this.props.rowProps.print(printLanguageOptions[0]?.value, this.props.data.id)}><PrintIcon title="" /> {this.tr('Print')}</MenuItem>
                    }
                    {/*<MenuItem onClick={() => window.location = `index.php?module=purchaseorder&action=view&id=${this.props.data.id}`}>View</MenuItem>*/}
                    {/* <MenuItem onClick={() => {}}>Edit</MenuItem> */}
                    {/*<MenuItem onClick={() => {this.delete()}}>Delete</MenuItem>*/}
                </ContextMenu>,
            checked:
                <CheckboxCell checked={checked} onClick={() => this.props.listRef.check(data.id)} />,
            state:
                <EditableStatusCell
                    {...commonProps}
                    options={this.props.sharedData.subcontract_statuses}
                    value={data.state} />,
            id:
                <LinkListCell
                    {...commonProps}
                    value={data.id}
                    urlHandler={value => `index.php?module=purchaseorder&action=view&id=${data.id}&companies_id=${this.props.rowProps.companies_id}&projects_id=${this.props.rowProps.projectsId}`}
                    noTab={true} />,
            supplier:
                <LinkListCell
                    {...commonProps}
                    value={data.customers_name}
                    asText={data.can_see_supplier == 0}
                    urlHandler={value => `index.php?module=customers&action=view&id=${data.customers_id}&company=${this.props.rowProps.companies_id}`}
                    noTab={true} 
                />,
            customer:
                <LinkListCell
                    {...commonProps}
                    value={(this.props.sharedData.customers.find(c => c.id === data.targeted_customers_id) || { name: "" }).name}
                    asText={data.can_see_customer == 0}
                    urlHandler={value => `index.php?module=customers&action=view&id=${data.targeted_customers_id}&company=${this.props.rowProps.companies_id}`} 
                />,
            project:
                <LinkListCell
                    {...commonProps}
                    value={data.project} 
                    asText={data.can_see_project == 0}
                    urlHandler={value => `index.php?module=projects&action=view&id=${data.projects_id}`}
                />,
            subproject:
                <LinkListCell
                    {...commonProps}
                    value={"Subproject"} />,
            creation_date:
                <ListCell
                    {...commonProps}
                    value={format(data.creation_date, this.context.userObject.dateFormat)} />,
            duedate:
                <ListCell
                    {...commonProps}
                    value={data.duedate == "0000-00-00" ? "" : format(data.duedate, this.context.userObject.dateFormat)} />,

            state_date:
                <ListCell
                    {...commonProps}
                    value={format(data.state_date, this.context.userObject.dateFormat)} />,
            delivery_date:
                <ListCell
                    {...commonProps}
                    value={data.delivery_date == "0000-00-00" ? "" : format(data.delivery_date, this.context.userObject.dateFormat)} />,
            netsum:
                <CurrencyListCell
                    {...commonProps}
                    textAlign="right"
                    currency={this.props.rowProps.currency}
                    value={data.netsum} />,
            grosssum:
                <CurrencyListCell
                    {...commonProps}
                    textAlign="right"
                    currency={this.props.rowProps.currency}
                    value={data.grosssum} />,
            user:
                <ListCell
                    {...commonProps}
                    value={data.user} />,
            targeted_user:
                <ListCell
                    {...commonProps}
                     value={data.targeted_user} />,
            projects_id:
                <ListCell
                    {...commonProps}
                    value={data.projects_id} />,
            synced_to_bill:
                    data.can_see_bill ? 
                        <MultiLinkListCell
                            {...commonProps}
                            value={data.bill_nrs} 
                            urlHandler={value => {
                                let urls = []
                                data.bill_ids.split(",").forEach(e => {
                                urls.push(`index.php?module=receivedinvoice&action=view&id=${e}`);
                                });
                                return urls;
                            }}             
                        />
                    :   
                    <ListCell
                        {...commonProps}
                        value={data.bill_nrs} />,
        };
    }
}
