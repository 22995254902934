import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";

import TaimerComponent from "../../../../TaimerComponent";
import InsightBlock from "../../InsightBlock";
import InsightChartOptions from "../../InsightChartOptions";
import colors from "../../../../colors";
import "./MonthlyOverview.css";

export default class MonthlyOverview extends TaimerComponent {
  constructor(props, context) {
    super(
      props,
      context,
      "dashboard/insights/goals/components/MonthlyOverview"
    );
    const selectedGoalTypeName = this.props.selectedGoalType
      ? this.props.selectedGoalType.name
      : "";
    const selectedGoalName = this.props.selectedGoal
      ? this.props.selectedGoal.name
      : "";

    this._monthKeyFormat = "YYYY-MM";
    this.rightColumns = [
      {
        label: this.tr("Total"),
      },
      {
        label: this.tr("M-to-D"),
        month: moment(),
      },
    ];

    this.state = {
      rows: [
        {
          label: selectedGoalName,
          key: "goal_type",
        },
        {
          label: selectedGoalTypeName,
          key: "type",
        },
        {
          label: this.tr("Cumulative Difference"),
          cumulative: true,
        },
      ],
    };
  }

  componentDidUpdate = (oldProps) => {
    if (
      this.props.selectedGoal != oldProps.selectedGoal ||
      this.props.selectedGoalType != oldProps.selectedGoalType
    ) {
      const selectedGoalTypeName = this.props.selectedGoalType
        ? this.props.selectedGoalType.name
        : "";
      const selectedGoalName = this.props.selectedGoal
        ? this.props.selectedGoal.name
        : "";
      this.setState({
        rows: [
          {
            label: selectedGoalName,
            key: "goal_type",
          },
          {
            label: selectedGoalTypeName,
            key: "type",
          },
          {
            label: this.tr("Cumulative Difference"),
            cumulative: true,
          },
        ],
      });
    }
  };

  _parseGraphData = (data) => {
    if (data) {
      return this.props.months.map(
        (m) => data[m.format(this._monthKeyFormat)] || 0
      );
    }
  };

  _renderChart = () => {
    const {
      functions: { presentCurrency },
    } = this.context;
    const { data, selectedGoal, selectedGoalType } = this.props;
    const options = InsightChartOptions;
    const selectedGoalTypeName = selectedGoalType ? selectedGoalType.name : "";
    const selectedGoalName = selectedGoal ? selectedGoal.name : "";
    const chartData = {
      datasets: [
        {
          data: this._parseGraphData(data.goal_type),
          label: selectedGoalName,
          borderColor: "#716aca",
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          borderDash: [10],
          type: "line",
          lineTension: 0,
          pointRadius: 0,
        },
        {
          data: this._parseGraphData(data.type),
          label: selectedGoalTypeName,
          borderColor: colors.dark_sky_blue,
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          lineTension: 0,
          pointRadius: 5,
        },
      ],
      labels: this.props.months.map(
        (m) => this.props.monthTitles[m.month() + 1]
      ),
    };
    return (
      <div className="chart">
        <Line
          data={chartData}
          width={"100%"}
          options={{
            ...options,
            barValueSpacing: 20,
            plugins: {
              tooltip: {
                ...options.plugins.tooltip,
                callbacks: {
                  labelColor: (tooltipItem) => {
                    return {
                      backgroundColor:
                        chartData.datasets[tooltipItem.datasetIndex].borderColor,
                    };
                  },
                  label: (tooltipItem) => {
                    const dataset = tooltipItem.dataset;
                    var label =
                      " " + dataset.label || "";
  
                    if (label) {
                      label += ": ";
                    }
                    label += presentCurrency(tooltipItem.raw);
                    return label;
                  },
                },
              },
              legend: {
                ...options.plugins.legend,
                labels: {
                  ...options.plugins.legend.labels,
                  generateLabels: () => {
                    return chartData.datasets.map((obj, i) => {
                      return {
                        text: obj.label,
                        datasetIndex: i,
                        pointStyle: obj.type != "line" ? "rectRounded" : "line",
                        fillStyle: obj.borderColor,
                        lineCap: "round",
                        lineJoin: "round",
                        strokeStyle:
                          obj.type != "line" ? "#FFF" : obj.borderColor,
                        lineWidth: obj.type != "line" ? 5 : 2,
                        lineDashOffset: 0,
                        lineDash: [3],
                      };
                    });
                  },
                },
              },
            },
            scales: {
              ...options.scales,
              y: {
                  ...options.scales.y,
                  type: "linear",
                  position: "left",
                  beginAtZero: true,
                  ticks: {
                    ...options.scales.y?.ticks,
                    callback: (value) => {
                      return presentCurrency(value);
                    },
                  },
                },
            },
          }}
        />
      </div>
    );
  };

  _getCellValue = (row, month) => {
    const { months, data } = this.props;
    const monthKey = month.format(this._monthKeyFormat);
    if (data && data.goal_type && data.type) {
      if (row.cumulative) {
        let cumulativeDifference = 0;
        months.forEach((m) => {
          if (m.isSameOrBefore(month, "month")) {
            const currentMonthKey = m.format(this._monthKeyFormat);
            const actual = data.goal_type[currentMonthKey] || 0;
            const target = data.type[currentMonthKey] || 0;
            const difference = actual - target;
            cumulativeDifference += difference;
          }
        });
        return cumulativeDifference;
      }
      if (!data[row.key]) {
        return 0;
      }
      return data[row.key][monthKey] || 0;
    }
    return 0;
  };

  _getTotalValue = (row, month = null) => {
    const { months, data } = this.props;
    if (
      !data ||
      !data.goal_type ||
      !data.type ||
      (!data[row.key] && !row.cumulative)
    ) {
      return 0;
    }
    if (!month) month = months[months.length - 1];
    let total = 0;
    months.forEach((m) => {
      if (m.isSameOrBefore(month, "month")) {
        const currentMonthKey = m.format(this._monthKeyFormat);
        let value;
        if (row.cumulative) {
          const actual = data.goal_type[currentMonthKey] || 0;
          const target = data.type[currentMonthKey] || 0;
          value = actual - target;
        } else {
          value = data[row.key][currentMonthKey] || 0;
        }
        total += value;
      }
    });
    return total;
  };

  _renderTable = () => {
    const {
      functions: { presentCurrency },
    } = this.context;
    const currentDate = moment();
    return (
      <div className="table">
        <div className="left">
          {this.state.rows.map((r) => (
            <div className="title row">{r.label}</div>
          ))}
        </div>
        <div className="content">
          <div className="header">
            {this.props.months.map((m) => (
              <div
                className={`title cell ${
                  m.isSame(currentDate, "month") && "current"
                }`}
              >
                {this.props.monthTitles[m.month() + 1]}
              </div>
            ))}
          </div>
          {this.state.rows.map((r) => (
            <div className="row">
              {this.props.months.map((m) => {
                const value = this._getCellValue(r, m);
                return (
                  <div
                    className={`cell ${
                      r.cumulative ? (value > 0 ? "green" : "red") : ""
                    } ${m.isSame(currentDate, "month") && "current"} `}
                  >
                    {presentCurrency(value)}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="right">
          <div className="header">
            {this.rightColumns.map((c) => (
              <div className="title cell">{c.label}</div>
            ))}
          </div>
          {this.state.rows.map((r) => (
            <div className="row">
              {this.rightColumns.map((c) => {
                const value = this._getTotalValue(r, c.month);
                return (
                  <div
                    className={`cell ${
                      r.cumulative ? (value > 0 ? "green" : "red") : ""
                    }`}
                  >
                    {presentCurrency(value)}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <InsightBlock
        {...this.props}
        className="monthly-overview"
        title={this.tr("Monthly Overview")}
      >
        {this._renderChart()}
        {this._renderTable()}
      </InsightBlock>
    );
  }
}
