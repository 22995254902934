import React from 'react';
import PropTypes from 'prop-types';

import List from "../List";
import ModifiedHoursListRow from "../rows/ModifiedHoursListRow";
import PageSelector from "../PageSelector";
import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from '../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import InsightDropDown from "../../dashboard/insights/InsightDropDown";
import _ from 'lodash';

class ModifiedHoursList extends TaimerComponent {
	static contextType = SettingsContext;
	constructor(props, context) {
		super(props, context, "list/lists/ModifiedHoursList");

		this.state = {
			data: [],
			page: 1,
			pageCount: 1,
			entryCount: 0,
			perpage: this.props.perpage,
		};

		this.autoCompleteData = props.autoCompleteData;
		this.stickySearchKey = "modified_hours_list";
        
        this.filtersInitialValues = {
            perpage: this.props.perpage,
			sort: undefined,
		};
		
		this.listModes = [
            {
              key: "hoursListed",
              label: this.tr("Hours listed"),
              action: () => this.props.navigate('myHours'),
              icon: require("../../dashboard/my_day/assets/list.svg").default,
            },
            {
              key: "modifiedEntries",
              label: this.tr("Modified entries"),
              action: () => {},
              icon: require("../../dashboard/my_day/assets/bulk.svg").default,
            }
        ];     

		this.fields = [
			{ field: "change_time", name: "change_time", header: this.tr("Changed"), width: 210, type: "date" },
			{ field: "user", name: "user", header: this.tr("User"), width: 180 },
			{ field: "project_old", name: "project_old", header: this.tr("Old Project"), width: 160 },
			{ field: "project_new", name: "project_new", header: this.tr("New Project"), width: 160 },
			{ field: "date_old", name: "date_old", header: this.tr("Old Date"), width: 110, type: "date" },
			{ field: "date_new", name: "date_new", header: this.tr("New Date"), width: 110, type: "date" },
			{ field: "time_old", name: "time_old", header: this.tr("Old Time"), width: 180, type: "number" },
			{ field: "time_new", name: "time_new", header: this.tr("New Time"), width: 180, type: "number" },
			{ field: "description_old", name: "description_old", header: this.tr("Old description"), width: 180 },
			{ field: "description_new", name: "description_new", header: this.tr("New description"), width: 180 },
			{ field: "type", name: "type", header: this.tr("Type"), width: 180 },
			{ field: "delete_message", name: "delete_message", header: this.tr("Delete message"), width: 180 },
			{ field: "edit_user", name: "edit_user", header: this.tr("Modifier"), width: 270 },
		];

        this.allFields 			  = this.fields.map(f => f.field);
        this.advancedSearchFields = this.fields.filter(f => f.field !== "expand" && f.field !== "checked" && f.field !== "type").map(f => {
        	let field = { field: f.field,  transl: f.header, type: f.type };

        	if(f.hasOwnProperty("autoCompleteData"))
        		field.autoCompleteData = f.autoCompleteData;

        	return field;
        });

		this.list 	  		= React.createRef();
		this.advancedSearch = React.createRef();
		this.pageChanged 	= this.pageChanged.bind(this);
        this.fetchData 		= this.fetchData.bind(this);
        this.setData 		= this.setData.bind(this);
		this.addRow 		= this.addRow.bind(this);
		this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
		this.saveStickySearch         = this.saveStickySearch.bind(this);
		this.searchTerms = undefined;
	}


	componentDidMount() {
		super.componentDidMount();
		this.initializeStickySearch();
	}

	initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                this.fetchData();
                return;            
            }

			this.searchTerms = response.searchTerms;

            this.setState({...response}, () => this.fetchData({page: response.page || 1}, true, false, true));
        }).fail(response => {
            this.fetchData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(page) {
        let filters = {};
        for(let key in this.filtersInitialValues) {
            filters[key] = this.state[key];
		}
		
		filters.page = page;
		filters.searchTerms = this.searchTerms;
		
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: filters });
    }

    filtersAreInInitialState() {
        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : ""

        return freetext === "";
    }

    _resetFilters = () => {
        this.advancedSearch.current.clearSearch(undefined, true);
        this.advancedSearch.current.clearSearchTextInput();

        this.searchTerms = undefined;

        this.setState({
            ...this.filtersInitialValues, 
        }, () => this.fetchData({}, true, true));
    }

	pageChanged(page) {
		const pageConf = { page: page };
        this.list.current && this.list.current.startPageChangeAnimation();
		this.fetchData(pageConf, false);
	}


	// When the list is initialized, we use this method to fetch the initial data.
	async fetchData(override = {}, updatePageCount = true, resetPage = false, initialFetch = false) {
		const { sort } = this.state;

		let parameters = { 
			page: 1,
			perpage: this.state.perpage, 
			sort
		}
		let postParams = {};

		if (this.searchTerms !== undefined) {
			parameters.mode = this.searchTerms.mode;
			if (this.searchTerms.mode == 'advanced') {
				postParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
			} else {
				parameters.freetext = this.searchTerms.freetextSearchTerm;
			}
		}

		for (let oi in override)
			parameters[oi] = override[oi];

		if (!initialFetch)
			this.saveStickySearch(parameters.page);
			
		const data = await DataHandler.post({url: 'timetracker/workhours/edited', ...parameters}, postParams);
		
		this.setData(data, parameters.page);

		if (resetPage)
			this.list.current.setPage(1);

		if (updatePageCount)
		{
			const pages = await DataHandler.post({url: 'timetracker/workhours/edited', ...parameters, getcount: 1}, postParams);
			
			this.setState({
				entryCount: pages.count,
				pageCount: pages.page_count
			}, () => initialFetch && this.list.current && this.list.current.setPage(parameters.page));
		}
	}


	setData(data, page) {
		this.setState({page: page, data: (data !== undefined || data.length > 0) ? data : [] }, this.list.current?.endPageChangeAnimation);
	}


	addRow() {
		this.list.current.addNewRow();
	}


	render() {
		if(!this.state.stickySearchInitialized) {
            return null;
        }
		const { tr } = this;
		return (
			<div className="contentBlock contentBlockMyHours">
				<div className="listControlsContainer">
					<div className="actionContainer clearfix">
						<div className="workhoursLeft">
							<AdvancedSearch
								ref={this.advancedSearch}
								mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                                initialFilters={this.searchTerms && this.searchTerms.currentFilters ? this.searchTerms.currentFilters : undefined}
                                mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
                                freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
                                alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                onClearSearch={this._resetFilters}
								fields={this.advancedSearchFields}
								onSearchResult={this.setData}
								noRequests={true}
								onSearchTrigger={(searchTerms) => {
									if(_.isEqual(searchTerms, this.searchTerms))
										return;
									this.searchTerms = searchTerms;
									this.fetchData({}, true, true);
								}}
								autoCompleteData={this.props.autoCompleteData ? {
									project_old: this.props.autoCompleteData['project_drop'],
									project_new: this.props.autoCompleteData['project_drop'],
								} : {}}
								perpage={this.state.perpage} />
						</div>
						<div className="workhoursRight">
							<div>
                                <InsightDropDown
									title={this.tr("Show")}
                                    tabs={this.listModes}
                                    selected={"modifiedEntries"}
                                />
                            </div>
						</div>
					</div>
				</div>
				<List
					ref={this.list}
					data={this.state.data}
					columns={this.fields}
					sharedData={this.autoCompleteData}
					rowHeight={56}
					height="fitRemaining"
					className="myhoursList"
					listRowType={ModifiedHoursListRow}
					rowProps={{list: this, tr: this.tr}}
					userListSettingsKey="timetracker_modifiedhours" 
					saveColumnConfig={true}
					pageCount={this.state.pageCount}
					totalCount={this.state.entryCount}
					perpage={this.state.perpage}
					page={this.state.page}
					controlPage={true}
					showPageSelector={true}
					onPerPageChange={perpage => {
						this.setState({ perpage: perpage });
						this.fetchData({ perpage: perpage }, true, true);
					}}
					onPageChange={page => this.pageChanged(page)}
					onSortRows={(name, asc) => {
						this.setState({sort: {
							name: name,
							asc: asc
						}});

						this.fetchData({sort: {
							name: name,
							asc: asc
						}}, true, true);
					}} />
			</div>
		);
	}
}
    
ModifiedHoursList.propTypes = {
    updateView: PropTypes.func.isRequired,
    perpage: PropTypes.number.isRequired,
    //autoCompleteData: PropTypes.object.isRequired,
};
ModifiedHoursList.defaultProps = {
	perpage: 30
};

export default ModifiedHoursList;