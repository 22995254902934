import React from 'react';
import PropTypes from 'prop-types';
import TaimerComponent from "../TaimerComponent";

/* material ui */
import { Checkbox, Button, CircularProgress } from '@mui/material';

/* icons */
import { Clear } from '@mui/icons-material';

/* context */
import { SettingsContext } from './../SettingsContext';

/*data */
import DataHandler from "./DataHandler";

/* css */
import './BuyTaimer.css';

/* other */
import {CardElement, injectStripe} from 'react-stripe-elements';
import { withSnackbar } from 'notistack';

class BuyTaimer extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "general/BuyTaimer");

        this.state = {
            creditCardInfo: false,
            addons: {},
            showProgress: false,
            sum: 0,
            trials: {},
        };
        const { tr } = this;

        this.translations = {
            "projects": tr("Leads & deals"),
            "invoicing": tr("Invoicing"),
            "expenses": tr("Expenses"),
            //"travelexpenses": tr("Expenses"),
            "timetracker": tr("Time tracker"),
            "resourcing": tr("Resourcing"),
            "collaborate": tr("Boards & cards"),
            "sales": tr("Sales goals"),
            "bills": tr("Bills")
        };

        ["renderLimited", "onChange", "save", "paymentCardCheck"].forEach(e => this[e] = this[e].bind(this));
    }

    unlimitedTypes = ["timetracker", "bills", "resourcing"/*, "sales"*/];

    componentDidMount() {
        DataHandler.get({url: 'settings/subscription/creditcard'}).done(response => {
            if (response == false) {
                DataHandler.get({url: 'settings/subscription/getSetupIntent'}).done(response => {
                    this.setState({
                        setupIntent: response.intent.client_secret,
                        creditCardInfo: false
                    });
                });
            } else {
                this.setState({creditCardInfo: response});
            }
            
        });

        this.props.addon && this.setState({[this.props.addon]: true});

        //DataHandler.get({url: 'settings/trials'}).done(trials => this.setState({trials}));
    }

    onChange = (evt) => {
        const state = this.state.addons || {};
        const name = evt.currentTarget.name;
        state[name] = !state[name];

        if (state[name]) {
            if(name == "resourcing")
                this.setState({sum: this.state.sum + 7.4});
            else
                this.setState({sum: this.state.sum + 3.8});
        } else {
            if(name == "resourcing")
                this.setState({sum: this.state.sum - 7.4});
            else
                this.setState({sum: this.state.sum - 3.8});
        }

        this.setState(state);

        if(state[name]) {
            let data = {
                tags: [name],
            };
            let allSelected = true;
            for(var i in state) {
                if(!state[i]) {
                    allSelected = false;
                    break;
                }
            }
            if(allSelected) {
                data.tags.push("all");
            }
            DataHandler.post({url: 'active_campaign/tags'}, data);
        }
    }

    renderLimited() {
        const { addons } = this.context;
        const { trials } = this.state;
        const state = this.state || {};
        const options = [];
        const { tr } = this;


        Object.keys(addons).length && Object.keys(addons).forEach(e => {
            const trial = trials[e];

            (addons[e].limit > 0 || (addons[e].trial && !this.unlimitedTypes.includes(e))) && options.push(<div className="option">
                <Checkbox name={e} onChange={this.onChange} checked={state[e] === true} />
                <span style={{fontWeight: 'bold'}}>{this.translations[e]}</span><span> - 3.80 € / {tr("user")} / {tr("month")}</span>
 
            </div>);
        });

        return (
            <React.Fragment>{options}</React.Fragment>
        );
    }
    renderAddons() {
        const state = this.state || {};
        const { addons } = this.context;
        const options = [];
 
        const { tr } = this;
 
        this.unlimitedTypes.forEach(e => {
            if(Object.keys(addons).indexOf(e) === -1 || addons[e].limit > 0 || addons[e].trial)
                options.push(<div className="option">
 
                    {e == "resourcing" ?
                        <React.Fragment><Checkbox name={e} onChange={this.onChange} checked={state[e] === true} /><span style={{fontWeight: 'bold'}}>{this.translations[e]}</span><span> - 7.40 € / {tr("user")} / {tr("month")}</span></React.Fragment>
                    :
                        <React.Fragment><Checkbox name={e} onChange={this.onChange} checked={state[e] === true} /><span style={{fontWeight: 'bold'}}>{this.translations[e]}</span><span> - 3.80 € / {tr("user")} / {tr("month")}</span></React.Fragment>
                    }
                </div>);
        });

        return (
            <React.Fragment>{options}</React.Fragment>
        );
    }
    renderTrials = () => {
        const { trials } = this.state;
        const options = [];
        const { tr } = this;

        Object.keys(trials).length && Object.keys(trials).forEach(e => {
            const trial = trials[e];

            options.push(<div className="option">
                <span style={{fontWeight: 'bold'}}>{this.translations[e]}</span>
                <span> - <a href="#" onClick={this.activateTrial.bind(this, e)}>{tr("Activate")} {trial.trial_days} {tr("days free trial")}</a></span>
            </div>);
        });

        return (
            <React.Fragment>{options}</React.Fragment>
        );
    }
    save (token) {
        const state = this.state.addons || {};
        const addons = [];
        const { enqueueSnackbar } = this.props;

        Object.keys(state).forEach(e => {
            state[e] === true && addons.push(e);
        });

        const params = {};
        params.addons = addons;

        if (token)
            params.token = token;

        //this.context.mixpanel.track("Purchase Add-ons", {"Add-on names": addons});
        
        if (this.context.taimerAccount.stripeCustomerId) {
            addons.length && DataHandler.put({url: 'settings/addons'}, params).done((response) => {
                if (response.success) {
                    this.props.whoami();
                    this.props.toggleBuyDialog();
                    this.setState({hideButtons: false, showProgress: false});
                } else {
                    this.setState({errorMessage: this.tr("Something went wrong. Please try again."), hideButtons: false, showProgress: false});
                }
            });
        }
        else {
            addons.length && DataHandler.put({url: 'settings/invoiced_subscription/addons'}, params).done((response) => {
                response.mail_sent && enqueueSnackbar(this.tr(`Request sent to customer service`), {  variant: "info" });
            });
            this.props.toggleBuyDialog();
        }

        this.setState({hideButtons: false});
    }

    async paymentCardCheck() {
        this.setState({hideButtons: true, errorMessage: '', showProgress: true});

        if (!this.state.creditCardInfo) {
            let {setupIntent, error} = await this.props.stripe.handleCardSetup(this.state.setupIntent);

            if (error) {
                this.setState({errorMessage: error.message + " (" + error.code + ")", hideButtons: false, showProgress: false});
            } else {
                this.save(setupIntent.payment_method);
            }
        } else {
            this.save();
        }
    }

    render () {
        const { taimerAccount: { origin, stripeCustomerId}, functions: { checkPrivilege } } = this.context;
        const { trials } = this.state;
        const { tr } = this;

        // TMobile
        if (origin === "ingram" || !checkPrivilege("admin", "admin")) {
            return (
                <div id="buy-taimer">
                    <div className="dialog">
                        <h1>{tr("Limit reached")}</h1>
                        <p>{tr("You have reached limit of your plan.")}</p>
                        <p>{tr("Please contact your administrator.")}</p>
                        <Button className="close-button" onClick={this.props.toggleBuyDialog}><Clear /></Button>
                    </div>
                </div>
            );
        }
        
        return (
            <div id="buy-taimer">
                <div className="dialog">
                    <h1>{tr("Simplify work")}</h1>
                    <p>{tr("A family of add-ons to customize Taimer to your needs")}</p>
                    <Button className="close-button" onClick={this.props.toggleBuyDialog}><Clear /></Button>
                    <div className="column">
                        <h2>{tr("Go Unlimited")}</h2>
                        {this.renderLimited()}
                        <h2>{tr("Addons")}</h2>
                        {this.renderAddons()}
                        {false && Object.keys(trials).length > 0 && <h2>{tr("Try for free")}</h2>}
                        {false && this.renderTrials()}
                    </div>
                    <div className="column right">
                        <div className="total-sum">
                            <h3 className="total-header">{tr("Total")}<span className="vat-info">{tr("(excl. VAT)")}</span></h3>
                            <div className="total-sum">{this.state.sum.toFixed(2)} € / {tr("user")} / {tr("month")}</div>
                        </div>
                        {stripeCustomerId && (this.state.creditCardInfo ? (
                           <React.Fragment>
                                <div className="card-data-wrapper">
                                    <h3>{tr("Your payment card")}</h3>
                                    <div className="card-data">
                                        {this.state.creditCardInfo.brand} / **** **** **** {this.state.creditCardInfo.last4}
                                    </div>
                                    <div className="card-data">
                                        {tr("Expires")}: {this.state.creditCardInfo.exp_month} / {this.state.creditCardInfo.exp_year}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="card-data-wrapper">
                                <h3>{tr("Add your payment card information")}</h3>
                                <CardElement />
                            </div>
                        ))}
                        {!this.state.hideButtons && <Button color="primary" size="large" onClick={stripeCustomerId ? this.paymentCardCheck : () => this.save()}>{tr("CONFIRM CHANGES")}</Button>}
                        {this.state.showProgress && <div class="progress-wrapper"><CircularProgress /></div>}
                        <div className="error-message">{this.state.errorMessage}</div>
                    </div>
                </div>
            </div>
        );

    }

}

export default withSnackbar(injectStripe(BuyTaimer));