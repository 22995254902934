import React from 'react';

import TaimerComponent from "../TaimerComponent";

/* local components */
import OutlinedField from "./../general/OutlinedField";
import DataHandler from './../general/DataHandler';

/*material ui */
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '../general/Link';

class ForgotPasswordView extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "login/ForgotPasswordView");

        this.state = {
            email: "",
            folder: "",
            error: false,
            emailEmpty: false,
            loading: false
        };

        this.validation = {
            email: React.createRef()
        }
    }; 

    componentDidMount() {
        super.componentDidMount();
        this.setCustomerFolder();
    }

    sendPasswordRequest = (e) => {
        this.setState({ loading: true });

        if (!this.validation["email"].current.isValid()) {
            this.setState({ error: this.state.email ? true : false, loading: false });
            return;
        }

        DataHandler.post({ url: `onboarding/retrieve/` }, { email: this.state.email, folder: this.state.folder }).done(e => {
            this.setState({ error: false });
            this.props.changePage("passwordRequested", this.state.email);
        })
        .fail(err => {
            this.setState({ error: "requestError", loading: false });
        });
    };

    changePage = (e, page) => {
        e.preventDefault();
        this.props.changePage(page);
    };

    setCustomerFolder = (folder = window.location.href.split("/")[3]) => {
        this.setState({folder: folder});    
    }

    render() {
        const { state, tr } = this;
        const { email, folder, error, loading } = state;
        const isOdin = window.location.href.indexOf(':8080') > -1;
        const currentFolder = window.location.href.split("/")[3];
        const errorMessage = error === "requestError" ? tr("Error in requesting password") : tr("Invalid email address")

        

        return (
            <React.Fragment>
                <h1>{tr("Forgot password?")}</h1>
                <p className={"forgot-password-text"}>{tr("Enter the email address associated with your account.")}</p>
                {error &&
                    <div className="error-alert">
                        <p>{errorMessage}</p>
                    </div>
                }
                <OutlinedField noOnchangeValidation={true} className={"login-field email-field"} value={email} onKeyUp={(e) => this.setState({ email: e.target.value })} ref={this.validation.email} validation={["email", "empty"]} inputRef={el => this.email = el} label={tr("Email")} autoFocus={true} />
                {(currentFolder === "" || isOdin) && <OutlinedField className={"login-field clientID-field"} value={folder} onKeyUp={(e) => this.setCustomerFolder(e.target.value)} label={tr("Client ID (Optional)")} />}
                {!loading &&
                    <Button className={"login-button"} color="primary" onClick={(e) => this.sendPasswordRequest(e)} size="large">{tr("Submit").toUpperCase()}</Button>
                }
                {loading &&
                    <Button className={"login-button"} color="primary" size="large" disabled><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>
                }
                <p className={"link-text"}>{tr("Go back to")} <Link onClick={(e) => { this.changePage(e, "login") }}>{tr("login page")}</Link></p>
            </React.Fragment>
        );
    }
}

export default ForgotPasswordView;