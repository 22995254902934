import React from "react";
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import LinkListCell from "../LinkListCell";
import { Switch, MenuItem } from "@mui/material";
import ContextMenu from "../../general/ContextMenu";
import { MoreHoriz, Edit, RemoveRedEye, LinkOff } from "@mui/icons-material";
import DateCell from "../cells/DateCell";

export default class CatalogAccountListRow extends PropsOnlyListRow {
  constructor(props) {
    super(props, null, {}, "list/rows/CatalogAccountListRow");
  }

  defineClassName() {
    return "catalogAccountListRow";
  }

  defineCells() {
    const {
      functions: { updateView, checkPrivilege },
    } = this.context;
    const {
      data,
      columnWidthMap,
      rowProps: { company },
    } = this.props;

    let cells = {};

    Object.keys(data).forEach((key) => {
      cells[key] = (
        <ListCell
          width={columnWidthMap[key]}
          value={data[key] || "-"}
          textAlign="left"
          editable={false}
        />
      );
    });
    cells = {
      ...cells,
      context: (
        <ContextMenu
          label={<MoreHoriz />}
          buttonProps={{ className: "action-menu" }}
          className="cell row-menu"
          style={{
            width: this.props.columnWidthMap["context"] + "px",
            flex: this.props.columnWidthMap["context"] + " 1 0px",
          }}
          noExpandIcon
        >
          {checkPrivilege("customers", "read", company) && (
            <MenuItem
              onClick={() =>
                updateView(
                  {
                    module: "customers",
                    action: "view",
                    id: data.customer_id,
                  },
                  false
                )
              }
            >
              <RemoveRedEye />
              {this.tr("View Account")}
            </MenuItem>
          )}
          {checkPrivilege("customers", "write", company) && (
            <MenuItem
              className="delete"
              onClick={() => this.props.rowProps.unlinkFromAccount(data)}
            >
              <LinkOff />
              {this.tr("Unlink from account")}
            </MenuItem>
          )}
        </ContextMenu>
      ),
      customer_id: (
        <LinkListCell
          width={columnWidthMap.customer_id}
          urlHandler= { value => checkPrivilege("customers", "read", company) && `index.php?module=customers&action=view&id=${ data.customer_id}`}
          value={data.customer_id}
          noTab={true}
          editable={false}
        />
      ),
      customer_name: (
        <LinkListCell
          width={columnWidthMap.customer_name}
          urlHandler= { value => checkPrivilege("customers", "read", company) && `index.php?module=customers&action=view&id=${ data.customer_id}`}
          value={data.customer_name}
          noTab={true}
          editable={false}
        />
      ),
      catalog_products_only: (
        <ListCell
          width={columnWidthMap.catalog_products_only}
          editable={false}
          onlyDisplay
        >
          <div className="catalog-switch-container">
            <Switch
              color="primary"
              checked={data.catalog_only == "1"}
              disabled={!checkPrivilege("customers", "write", company)}
              onChange={(e) => {
                this.setData("catalog_only", e.target.checked ? "1" : "0");
                this.props.rowProps.setCatalogAccountSettings(e, data);
              }}
            />
          </div>
        </ListCell>
      ),
      edited_date: <DateCell editable={false} value={data.edited_date} />,
    };

    return cells;
  }
}
