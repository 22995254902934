import React from 'react';

/* css */
import './InsightFilter.css';

/* others */
import _ from 'lodash';
import cn from 'classnames';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import { DateRangePicker } from './../../general/react-date-range/src';
import OutlinedField from "./../../general/OutlinedField";
import MenuItem from '@mui/material/MenuItem';
import MultiSelect from '../../general/MultiSelect';
import AdvancedSearch from '../../search/AdvancedSearch';

import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions,
	getInitialDimensionState
} from "../../dimensions/helpers"

import ListItemIcon from '@mui/material/ListItemIcon';

import DataList from '../../general/DataList';

import Save from '@mui/icons-material/Save';
import Clear from '@mui/icons-material/Clear';

/*dialogs*/
import SaveSearchQuery from "../../reports/dialogs/SaveSearchQuery";
import DeleteSearchQuery from "../../reports/dialogs/DeleteSearchQuery";
import { getYear } from 'date-fns';


import InsightMultiselect from './filters/InsightMultiselect';
import InsightSelect from './filters/InsightSelect';
import InsightDateRange from './filters/InsightDateRange';

import { intersection } from "../../general/Set";
import colors from '../../colors';

const knownFilters = {
    multiselect: InsightMultiselect,
    select: InsightSelect,
    daterange: InsightDateRange,
};

class InsightFilter extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        allowEmptyDateRange: false,
        showPeriodSelector: true,
        customFilters: [],
        dropFilters: [
            "customers",
        ],
        advancedFilters: [
            "tags", "customership_groups", "projects", "project_type", "project_categories", "reporting_group",
            "account_manager", "sales_agent", "project_team", "project_manager", "team",
        ],
        showDefaultPipelines: false,
        filterProjectsByPipeline: false,
        allowMultipleCompanies: false,
        disableAdvancedSearchDimensions: false,
        outsideFiltersState: {},
        pipelines: [],
        pipelinesStages: {},
        advancedSearchProps: {},
    }

    basekey = 1;

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightFilter");

        this.state = {
            selectedQuery: 0,
            dialogData: undefined,
            pendingDate: null,
            resetCustomers: false,
            resetPipelines: false,
            resetUsers: false,
            showFiscalSelector: true,
            ...getInitialDimensionState()
        }

        const possibleFields = {
            tags: {
                name: "tags",
                transl: this.tr("Tags"),
                fieldType: "multitext"
            },
            customership_groups: {
                field: "customership_groups",
                name: "customership_groups",
                transl: this.tr("Account Group"),
                fieldType: "multitext",
                entityMode: true
            },
            customers_types: {
                field: "customers_types",
                name: "customers_types",
                transl: this.tr("Account Type"),
                fieldType: "multitext",
                entityMode: true
            },
            customers: {
                transl: this.tr("Account"),
                fieldType: "multitext",
                visualizationType: "tree",
                entityMode: true
            },
            projects: {
                transl: this.tr("Project"),
                fieldType: "multitext",
                visualizationType: "tree",
                entityMode: true
            },
            project_type: {
                transl: this.tr("Project Type"),
                fieldType: "multitext",
                entityMode: true
            },
            project_categories: {
                transl: this.tr("Project Category"),
                fieldType: "multitext",
                visualizationType: "tree",
                entityMode: true
            },
            reporting_group: {
                field: "reporting_group",
                name: "reporting_group",
                transl: this.tr("Reporting Group"),
                fieldType: "multitext",
                visualizationType: "tree",
                entityMode: true
            },
            account_manager: {
                transl: this.tr("Account Manager"),
                fieldType: "multitext",
                entityMode: true
            },
            sales_agent: {
                transl: this.tr("Sales Agent"),
                fieldType: "multitext"
            },
            project_team: {
                transl: this.tr("Project Team"),
                fieldType: "multitext",
                entityMode: true
            },
            project_manager: {
                transl: this.tr("Project Manager"),
                fieldType: "multitext"
            },
            team: {
                transl: this.tr("Team"),
                fieldType: "multitext"
            },
            enterprise_groups: {
                transl: this.tr("Enterprise group"),
                fieldType: "multitext"
            },
        };

        this.statuses = [
            { id: 0, name: this.tr("All") },
            { id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan },
            { id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b" },
            { id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f" }
        ];

        this.defaultPipelines = [
            { id: -1, name: this.tr("Won deals"), value: "-1", label: this.tr("Won deals") },
            { id: -5, name: this.tr("Internal projects"), value: "-5", label: this.tr("Internal projects") }
        ];

        this.outlinedFieldSelectProps = {
            MenuProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }
        };

        this.fields = [];

        for (const key of this.props.advancedFilters) {
            if (!key) continue;
            
            this.fields.push({
                field: key,
                name: key,
                ...possibleFields[key],
            });
        }

        this.dialogs = {
            saveSearch: SaveSearchQuery,
            deleteSearch: DeleteSearchQuery
        };
        this.dialogTexts = {
            saveSearch: this.tr("Save current search"),
            deleteSearch: this.tr("Are you sure you want to delete this saved search?")
        }

        this.pendingDate = false;

        this.refAdvancedSearch = React.createRef();

        this.props.dropFilters.length > 0 && console.info("InsightFilter Todo: Update Dropfilters to Custom Filters");
    }

    componentDidMount() {
        super.componentDidMount();

        this.initDimensions(this.props.filters.company);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters && prevProps.filters.basekey !== this.props.filters.basekey) {
            this.reset();
        }

        else if (this.props.autoCompleteData != prevProps.autoCompleteData) {
            this.reset();
        }

        else if (prevProps.filters && this.props.filters) {
            const old = prevProps.filters;
            const current = this.props.filters;

            if (!_.isEqual(old.customers_ids, current.customers_ids)) {
                this.reset();
            }
        }

        if(this.props.filters.company !== prevProps.filters.company) {
            this.initDimensions(this.props.filters.company);
        }
    }

    initDimensions = async (company) => {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
			this.setState(getInitialDimensionState());
            return;
        }

        this.setState({ ...(await getDimensionAutoCompleteData(company)) }); 
    }

    onDateChange = (event) => {
        const { filters } = this.props;
        let { startDate, endDate } = event.selection;

        const date = {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
            manual: true,
        };

        if (startDate.getTime() === endDate.getTime() && !this.pendingDate && event.source !== "definedRange") {
            this.setState({ pendingDate: date });
            this.pendingDate = true;
        } else {
            this.pendingDate = false;
            this.raiseChange({
                ...filters,
                dateRange: date,
            }, { change: "dateRange" });
            this.setState({ pendingDate: false });
        }
    };

    onDateInputChange = (dateType, date) => {
        const { filters } = this.props;
        const { startDate, endDate } = filters.dateRange;

        if (dateType == "start") {
            this.raiseChange({
                ...filters,
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection",
                    manual: true,
                }
            });
        } else {
            this.raiseChange({
                ...filters,
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection",
                    manual: true,
                }
            });
        }
    };

    dateRangeClose = () => {
        if (this.state.pendingDate) {
            setTimeout(() => {
                this.setState({ pendingDate: null });
            }, 100);
        }
    }

    raiseChange = (filters, extra) => {
        if (this.props.loaded === false)
            return;

        if (_.isEqual(filters, this.props.filters)) {
            return;
        }

        filters.selectedQuery = 0;

        this.props.onChange && this.props.onChange(filters, extra);
    }

    replaceFilters = (filters, extra) => {
        if (this.props.loaded === false)
            return;

        if (_.isEqual(filters, this.props.filters)) {
            return;
        }

        this.props.onChange && this.props.onChange(filters, extra);
    }

    changeCompany = (event) => {
        const { filters, companies, changeCompany } = this.props;
        const { userObject, functions: { getFinancialYear } } = this.context;

        changeCompany(event.target.value);
    }

    changeCustomers = (value) => {
        const { filters } = this.props;

        this.raiseChange({
            ...filters,
            customers_ids: value ? value.map(x => x.value).filter(x => x !== '0') : [],
            projects_ids: [],
        }, { change: "customers_ids" });
    }

    changeValue = (name, value) => {
        const { filters } = this.props;

        this.raiseChange({
            ...filters,
            [name]: value ? value.map(x => x.value).filter(x => x !== '0') : [],
        }, { change: name });
    }

    changeValueSingle = (name, event) => {
        const { filters } = this.props;

        if (filters[name] == event.target.value)
            return;

        this.raiseChange({
            ...filters,
            [name]: event.target.value,
        }, { change: name });
    }

    changeValueCustom = (name, value) => {
        const { filters } = this.props;

        if (filters[name] == value)
            return;

        this.raiseChange({
            ...filters,
            [name]: value,
        }, { change: name });
    }

    loadSavedQuery = (evt) => {
        const { defaultFilters, filters: { company, selectedQuery: currentSelectedQuery } } = this.props;

        if (evt.nativeEvent?.target?.tagName == 'svg')
            return false;

        const val = evt.target.value;

        if (val === -1 && currentSelectedQuery !== 0) {
            const base = _.cloneDeep(defaultFilters);
            this.replaceFilters({ ...base, company, ...query, selectedQuery: 0, basekey: ++this.basekey });
            return;
        }

        const selectedQuery = this.props.queries.filter(item => item.id == val)[0];
        if (!selectedQuery)
            return;

        // Event fired twice on select & on blur, ignore second
        if (currentSelectedQuery == selectedQuery.id)
            return;

        const query = JSON.parse(selectedQuery.querystring);

        if (query.outsideFilters) {
            const { outsideFilters } = query;
            delete query.outsideFilters;

            this.props.onChangeOutsideFilters(outsideFilters);
        }

        const base = _.cloneDeep(defaultFilters);

        this.replaceFilters({ ...base, company, ...query, selectedQuery: selectedQuery.query_type == "empty" ? 0 : selectedQuery.id, basekey: ++this.basekey });
    }

    callSaveDialog = () => {
        this.setState({
            dialogData: {
                name: '',
                userId: '',
                saveFunc: 'saveSearchQuery'
            }
        });
        this.openDialog('saveSearch');
    }

    reset = () => {
        this.setState({
            resetCustomers: true, resetPipelines: true, resetUsers: true, resetProjects: true,
        })
    }

    saveSearchQuery = (data) => {
        const query = { ...this.props.filters };

        if (!query.dateRange.manual)
            delete query.dateRange;

        delete query.selectedQuery;

        if (this.props.outsideFiltersState) {
            query.outsideFilters = this.props.outsideFiltersState;
        }

        const params = {
            name: data.name,
            company: this.props.filters.company,
            is_private: data.isPrivate,
            querystring: query,
            querytype: this.props.savedQueryName
        }
        DataHandler.post({ url: `reports/save_query` }, params).done(
            response => {
                this.closeDialog();
                this.props.updateSavedQueries();
            });
    }

    callDeleteDialog = (evt, id) => {
        this.setState({
            dialogData: {
                id: id,
                saveFunc: 'deleteSavedQuery',
                text: this.dialogTexts.deleteSearch
            }
        });
        this.openDialog('deleteSearch');
    }

    deleteSavedQuery = (id) => {
        const query = this.props.queries.find(q => q.id == id);
        const squery = this.state.selectedQuery;

        DataHandler.get({ url: `reports/delete_query`, id: id }).done(
            response => {
                this.closeDialog();
                if (query.id == squery) {
                    const base = _.cloneDeep(this.props.defaultFilters);

                    this.raiseChange({ ...base, basekey: ++this.basekey });
                }
                this.props.updateSavedQueries();
            });
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false });
    }

    saveDialog = (saveFunc, data) => {
        this[saveFunc](data);
    }

    changeFinancialYear = (data) => {
        const { filters, companies } = this.props;

        this.raiseChange({
            ...filters,
            dateRange: {
                startDate: data.start,
                endDate: data.end,
                key: "selection",
                manual: true,
            }
        });
    }

    onInputListener = _.debounce(() => {
        this.refAdvancedSearch.current.triggerSearch();
    }, 200);

    renderFilter(commonProps, extraOptionsSources, filter) {
        // Skip false values, to allow conditional filters
        if (!filter)
            return null;

        // If it's element render directly
        if (React.isValidElement(filter)) {
            return filter;
        }

        const { name, type, options, optionsSource, filterOptions, conditionalProps, ...props } = filter;
        const { filters, autoCompleteData } = this.props;

        let optsArray = [];

        if (Array.isArray(options))
            optsArray = options;
        else if (optionsSource) {
            optsArray = extraOptionsSources[optionsSource] ?? autoCompleteData[optionsSource];
        }

        if (filterOptions && optsArray) {
            optsArray = filterOptions(optsArray, filters[name], filters);
        }

        const Element = knownFilters[type] ?? type;

        return <Element 
            key={name}
            {...commonProps}
            name={name}
            options={optsArray ?? []}
            value={filters[name]}
            onChange={this.changeValueCustom}
            {...props}
            {...(conditionalProps?.(filters[name], filters) ?? {})} />;
    }

    createCustomFields = () => {
        const { autoCompleteData: { account_custom_fields } } = this.props;

        if (!account_custom_fields)
            return [[], {}];

        const result = [];
        const options = {};

        _.forEach(account_custom_fields, (field, i) => {
            const key = `custom_account_${field.id}`;

            result.push({
                field: key,
                name: key,
                transl: field.name,
                tagTransl: field.name,
                fieldType: "multitext",
            });

            if (field.options) {
                options[key] = field.options;
            }

            // console.log(field)

            // field: "customership_groups",
            // name: "customership_groups",
            // transl: this.tr("Account Group"),
            // fieldType: "multitext",
            // entityMode: true
        });

        return [result, options];
    }

    render() {
        const {
            filters: {
                dateRange, customers_ids, projects_ids, project_categories_ids, users_ids, groups_ids, company, reporting_group_ids,
                basekey, selectedQuery, advanced_search, pipeline: pipelineId, pipeline_ids, status, account_manager, customership_group, project_type
            },
            autoCompleteData: {
                projects, customers, customership_groups, tags, project_categories, reporting_group, project_types,
                users, teams, minYear, customers_types, enterprise_groups
            },
            pipelines,
            pipelinesStages,
            savedQueryName,
            companies,
            loaded,
            queries,
            dropFilters,
            topOffset,
            timePeriodTitle,
            hideAdvancedSearch,
            disableAdvancedSearchDimensions,
            showPeriodSelector,
            showDefaultPipelines,
            filterProjectsByPipeline,
            showFiscalSelector,
            noProjectFilter,
            customFilters,
            allowMultipleCompanies,
            allowEmptyDateRange,
            advancedSearchProps,
        } = this.props;

        const {
            currentDialog, dialogData, resetCustomers, resetProjects, resetPipelines, resetUsers
        } = this.state;

        const { userObject, functions: { getFinancialYear } } = this.context;
        const { tr } = this;

        const companyData = companies[company];

        const selectedCustomers = (customers).filter(x => customers_ids.indexOf(x.value) !== -1);
        const selectedProjects = projects.filter(x => projects_ids.indexOf(x.value) !== -1);
        const selectedUsers = users.filter(x => users_ids.indexOf(x.value) !== -1);

        const years = _.range(minYear - 1, getYear(getFinancialYear().start) + 2).map(x => getFinancialYear(x, companyData ? companyData.financial_year_start : null)).reverse();

        let minDate = undefined;
        let maxDate = undefined;

        const financialYear = getFinancialYear(dateRange.startDate, companyData ? companyData.financial_year_start : null);

        if (showFiscalSelector) {
            minDate = financialYear.start;
            maxDate = financialYear.end;
        }

        const allPipelines = showDefaultPipelines ? [...this.defaultPipelines, ...pipelines] : pipelines;

        const selectedPipelines = pipeline_ids ? allPipelines.filter(x => pipeline_ids.indexOf(x.value) !== -1) : [];
        const pipeline = pipelines.length > 0 && pipelineId ? allPipelines.find(x => x.id == pipelineId) : { id: 0 };

        const pipelineIds = selectedPipelines.map(x => parseInt(x.id))
        const actualProjects = !filterProjectsByPipeline ? projects : projects.filter(x => (
            (customers_ids.length === 0 || customers_ids.includes(x.customers_id)) &&
            (pipelineIds.length === 0 || pipelineIds.includes(parseInt(x.pipeline)))
        ) || x.value === '0');

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        const searchButtons = (<>
            {savedQueryName && <div className="insight-save-search saveSearch" onClick={this.callSaveDialog}>
                <Save />
                {this.tr("Save this search")}
            </div>}
            {this.props.searchButton}
        </>);

        const allCompanies = _.values(companies);

        let companySelect = !this.props.hideCompanySelect && allCompanies.length > 1 && <OutlinedField className="listFilterOutlinedField" label={this.tr("Company")} name="company" value={company} select onChange={this.changeCompany}>
            {allCompanies.map(row => (
                <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
            ))}
        </OutlinedField>;

        if (allowMultipleCompanies) {
            companySelect = {
                name: 'company',
                type: 'multiselect',
                options: allCompanies,
            };
        }

        const filtersToDisplay = [
            companySelect,
            showFiscalSelector && <div key="financial_year" className="margin">
                <DataList label={this.tr("Financial Year")} options={years} value={financialYear} onChange={this.changeFinancialYear} />
            </div>,
            showPeriodSelector && <div key="daterange" className="margin date">
                <DateRangePicker
                    className="daterange"
                    financialYears={years}
                    ranges={[this.state.pendingDate ? this.state.pendingDate : dateRange]}
                    onChange={this.onDateChange}
                    onInputChange={this.onDateInputChange}
                    label={timePeriodTitle || tr("Time period")}
                    dateFormat={userObject.dateFormat}
                    onClose={this.dateRangeClose}
                    minDate={minDate}
                    maxDate={maxDate}
                    allowClear={allowEmptyDateRange}
                />
            </div>,
            ...customFilters,
        ];

        const extraOptionsSources = {
            allPipelines: [...this.defaultPipelines, ...pipelines],
            pipelines,
            pipelinesStages,
        };

        const [customFields, customAc] = this.createCustomFields();

        const asFields = [
            ...this.fields,
            ...(!disableAdvancedSearchDimensions && createAdvancedSearchFieldsForDimensions(this.state.dimensions) || []),
            ...customFields,
        ];

        return (<>
            <div style={{ top: topOffset || 0 }} className={cn("insights-filters", loaded === false && "insights-filters-disabled")}>
                {filtersToDisplay.map(this.renderFilter.bind(this, {
                    companyData, autoCompleteData: this.props.autoCompleteData
                }, extraOptionsSources))}

                {(dropFilters.includes("pipeline")) && <OutlinedField
                    select
                    className="listFilterOutlinedField"
                    label={this.tr("Pipeline")}
                    name="pipeline"
                    value={pipeline ? pipeline.id : 0}
                    onChange={this.changeValueSingle.bind(this, 'pipeline')}
                >

                    {allPipelines.map(option => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </OutlinedField>}

                {(dropFilters.includes("status")) && <OutlinedField
                    select
                    className="listFilterOutlinedField"
                    label={this.tr("Status")}
                    name="status"
                    value={status}
                    onChange={this.changeValueSingle.bind(this, 'status')}
                >

                    {this.statuses.map(option => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </OutlinedField>}

                {(dropFilters.includes("customers")) && <MultiSelect
                    className="margin"
                    options={customers}
                    defaultValue={selectedCustomers}
                    reset={resetCustomers}
                    resetFunction={() => { this.setState({ resetCustomers: false }) }}
                    resetValue={selectedCustomers}
                    label={this.tr("Account")}
                    onChange={this.changeCustomers}
                    skipInitialChange
                />}

                {(dropFilters.includes("pipelines")) && <MultiSelect
                    className="margin"
                    options={allPipelines}
                    defaultValue={selectedPipelines}
                    reset={resetPipelines}
                    resetFunction={() => { this.setState({ resetPipelines: false }) }}
                    resetValue={selectedPipelines}
                    label={this.tr("Pipeline")}
                    onChange={this.changeValue.bind(this, 'pipeline_ids')}
                    skipInitialChange
                    noAllOptions
                />}

                {(dropFilters.includes("customers")) && !noProjectFilter && <MultiSelect
                    className="margin"
                    options={actualProjects}
                    defaultValue={selectedProjects}
                    reset={resetProjects}
                    resetFunction={() => { this.setState({ resetProjects: false }) }}
                    resetValue={selectedProjects}
                    label={this.tr("Projects")}
                    onChange={this.changeValue.bind(this, 'projects_ids')}
                    skipInitialChange
                />}

                {(dropFilters.includes("users")) && <MultiSelect
                    className="margin"
                    options={users}
                    defaultValue={selectedUsers}
                    reset={resetUsers}
                    resetFunction={() => { this.setState({ resetUsers: false }) }}
                    resetValue={selectedUsers}
                    label={this.props.usersFilterName || this.tr("User")}
                    onChange={this.changeValue.bind(this, 'users_ids')}
                    skipInitialChange
                />}

                {(dropFilters.includes("account_manager")) && <OutlinedField
                    select
                    className="listFilterOutlinedField"
                    label={this.tr("Account Manager")}
                    name="account_manager"
                    value={account_manager}
                    onChange={this.changeValueSingle.bind(this, 'account_manager')}
                >

                    {[{ id: null, name: this.tr("Not selected") }, ...users].map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </OutlinedField>}

                {(dropFilters.includes("customership_group")) && <OutlinedField
                    select
                    className="listFilterOutlinedField"
                    label={this.tr("Account Group")}
                    name="customership_group"
                    value={customership_group}
                    onChange={this.changeValueSingle.bind(this, 'customership_group')}
                >

                    {[{ id: null, name: this.tr("Not selected") }, ...customership_groups].map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </OutlinedField>}

                {savedQueryName && <OutlinedField
                    select
                    className="listFilterOutlinedField"
                    label={this.tr("Saved searches")}
                    name="savedSearches"
                    SelectProps={this.outlinedFieldSelectProps}
                    value={selectedQuery}
                    onChange={evt => this.loadSavedQuery(evt)}>
                        <MenuItem className="googleTranslateHide" value={0}> </MenuItem>{/* Blocks Chrome from translating empty element, hidden with css from menu */}
                        <MenuItem classes={{ root: 'insightSavedSearch' }} className="query-option" value={-1}>
                            {this.tr("Empty search")}
                        </MenuItem>
                    {queries.filter(x => x.query_type !== "empty").map(option => {
                        return (
                            <MenuItem classes={{ root: 'insightSavedSearch' }} className="query-option" key={option.id} value={option.id}>
                                <div style={{ flex: 1 }}>
                                    {option.name}
                                </div>
                                <ListItemIcon className="hide-selected">
                                    <Clear onClick={(evt) => this.callDeleteDialog(evt, option.id)} />
                                </ListItemIcon>
                            </MenuItem>
                        )
                    })}
                </OutlinedField>}

                {!hideAdvancedSearch && asFields.length > 0 ? <AdvancedSearch
                    ref={this.refAdvancedSearch}
                    key={`as${basekey}`}
                    className={"margin"}
                    fields={asFields}
                    noRequests
                    initialFilters={advanced_search.currentFilters}
                    onSearchTrigger={(advanced_search) => {

                        //poistetaan kun advanced search alkaa käyttämään display value'ta
                        const advanced_search_strings_strip_map = {
                            project_manager: ' (',
                            account_manager: ' (',
                            sales_agent: ' (',
                            project_team: ' ('
                        };
                        if (advanced_search.advanced_search_criteria?.filters) {
                            let newFilters = {};
                            Object.entries(advanced_search.advanced_search_criteria.filters).map(([name, filterArray]) => {
                                if (advanced_search_strings_strip_map[name]) 
                                    newFilters[name] = filterArray.map(fa => ({...fa, value: fa.value.split(advanced_search_strings_strip_map[name])[0]}));
                                else
                                    newFilters[name] = filterArray;
                            });
                            advanced_search.advanced_search_criteria.filters = newFilters;
                        }

                        if (!advanced_search.freetextSearchTerm && advanced_search.mode === "freetext") {
                            this.raiseChange({
                                ...this.props.filters,
                                advanced_search: {},
                            });
                            return;
                        }

                        this.raiseChange({
                            ...this.props.filters,
                            advanced_search: JSON.parse(JSON.stringify(advanced_search)),
                        });
                    }}
                    onInputListener={this.onInputListener}
                    autoCompleteData={{
                        customers: [customers, "parent_id", "value"],
                        projects: [projects, "parent_id", "value"],
                        tags,
                        customership_groups,
                        customers_types,
                        project_type: project_types,
                        project_categories: [project_categories.filter(pc => pc.locked < 1), "parent_id", "value"],
                        reporting_group: [reporting_group, "parent_id", "value"],
                        account_manager: users,
                        sales_agent: users,
                        project_team: users,
                        project_manager: users,
                        team: teams,
                        enterprise_groups: enterprise_groups,
                        ...this.state.dimensionAutoCompleteData,
                        ...customAc,
                    }}
                    autoCompleteDataFilters={{
                        projects: {
                            project_type: (projects, project_types) => {
                                const projectTypes = project_types
                                    .filter(g => typeof(g) === "object" && g.hasOwnProperty("data"))
                                    .map(g => g.data.value);

                                return projects.filter(p => intersection(p.project_types, projectTypes).length > 0);
                            },
                            customers: "customers_id|customers.id",
                            customership_group: "customership_groups_id|customership_groups.id",
                            reporting_group:    "product_structures_id|reporting_group.id",
                            // branch_of_business: "branchofbusiness_id|branch_of_business.id"
                        },
                        reporting_group: "customers_id|customers.id",
                        ...this.state.dimensionAutoCompleteDataFilters
                    }}
                        {...advancedSearchProps}
                    >
                    {searchButtons}
                </AdvancedSearch> : searchButtons}

                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.saveDialog}
                    data={dialogData} />}
            </div>
        </>);
    }
}

export default InsightFilter;
