import React from "react";

import SimpleListCell from "./SimpleListCell";

class SimpleListRow extends React.Component {
    static defaultProps = {
        className: "",
        columnConfigs: {},
        columnOrder: [],
        data: {},
        flex: false,
        flip: undefined,
        multipleListRowTypes: false,
        noColorVariance: false,
        // Row height is controlled via CSS
        // and given as a prop to List in virtualized mode.
        rowHeight: 40,
        rowKey: "id",
        rowType: "default",
        top: 0,
        virtualized: false
    };
   
   
    constructor(props) {
        super(props);

        this.ref      = React.createRef();
        this.dataKeys = Object.keys(this.props.data);

        this.moveRow  = this.moveRow.bind(this);
    }


    componentDidMount() {
        this.moveRow(this.props.top);
    }


    shouldComponentUpdate(nextProps) {
        if(this.props.virtualized && nextProps.top !== this.props.top) {
            this.moveRow(nextProps.top);
        }

        return (
            this.props.data[this.props.rowKey] !== nextProps.data[this.props.rowKey] 
            || 
            this.props.flips[this.props.data[this.props.rowKey]] !== nextProps.flips[this.props.data[this.props.rowKey]]
            ||
            this.props.flip !== nextProps.flip
            ||
            this.props.flip === undefined
        );
    }


    moveRow(top) {
        this.ref.current.style.top = `${top}px`;
    }


    render() {
        const { 
            rowHeight,
            className,
            flex,
            noColorVariance,
            virtualized,
            columnOrder,
            columnWidthMap,
            columnConfigs,
            cellPresentations,
            rowType,
            data,
            listRef,
            sharedData
        } = this.props;
        const style = {
            height: `${rowHeight}px`,
            lineHeight: `${rowHeight}px`
        };

        return (
            <div 
                className={`listElement 
                    ${!noColorVariance ? "mainLevel" : ""} 
                    ${virtualized ? "virtualized" : ""} 
                    ${className}`} 
                style={style}
                ref={this.ref}>
				<div 
                    className={`listElement row 
                        ${className} 
                        ${flex ? "flex" : ""}`}
                    style={style}>
                    {columnOrder.map((columnName, index) => {
                        const columnConfig = columnConfigs[rowType][columnName];
                        const definition   = cellPresentations[rowType][columnName] || false;
                        const presentation = !definition 
                            ? data[columnName] 
                            : definition(data, this.props, listRef, sharedData);
                        const [value, props] = Array.isArray(presentation) 
                            ? presentation 
                            : [presentation, {}];

                        return (
                            <SimpleListCell 
                                name={columnName}
                                data-testid={`${columnName}_${this.props.rowIndex}`}
                                flex={flex}
                                width={columnWidthMap[columnName]}
                                key={index} 
                                value={value}
                                {...props}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default SimpleListRow;
