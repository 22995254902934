import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import List from "../../list/List";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import SettingsGrid from './SettingsGrid';
import "../Settings.css";
import OneColumnSettingList from "../../list/lists/OneColumnSettingList";
import ListRow from "../../list/ListRow";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import CheckboxCell from "../../list/cells/CheckboxCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { Switch, FormControlLabel } from '@mui/material';

import TrashCanIcon from "@mui/icons-material/Delete";

import PropsOnlyListRow from "../../list/PropsOnlyListRow";

import $ from "jquery";
import { AutoSizer } from 'react-virtualized';

import StatusTag from '../../general/StatusTag';

const rowHeight = 73;
const rowHeightPx = rowHeight + "px";

class ContactRelationOptionListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props);
    }

    editAndSave = (name, value) => {
        let data = this.props.data;
        data[name] = value;
        this.setDataAndUpdate(data);
    }
    changeDefault = (e, checked) => {
        this.editAndSave("is_default", checked ? "1" : "0");
    }
    defineClassName() {
        return "ContactRelationOptionListRow";
    }

    defineCells() {
        const { data } = this.props;

        let cells = {
            name:
                <ListCell alignCell onlyDisplay name="name" value={data['name']}>
                    <img src={data.icon} /> {data.name}
                </ListCell>,
            default:
                <ListCell className="" width={this.props.columnWidthMap['default']} onlyDisplay>
                    <Switch color="primary" checked={data.default} onChange={this.changeDefault} />
                </ListCell>

        };

        return cells;
    }
}
class Contact extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/pages/Contact");

        this.langList = React.createRef();
        this.positionList = React.createRef();
        this.contactRelationOptionList = React.createRef();

        this.state = {
            data: {
                positions: [],
                contactRelationOptions: [],
                langs: []
            },
            initialized:false,
            contactSettings: [],
        };
        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.setStateData = this.setStateData.bind(this);
        this.initData = this.initData.bind(this);
        this.fetchPositions = this.fetchPositions.bind(this);
        this.updateContactRelationOption = this.updateContactRelationOption.bind(this);

        this.initData();
    }

    setStateData(key, data) {
        let stateData = this.state.data;
        stateData[key] = data;
        this.setState({ data: stateData });
    }
    onChange = (data) => {
        this.setState({ data });

        // this.save(data);
    }

    initData() {
        this.fetchPositions();
        this.fetchCompanyLangs();
        //this.updateContactRelationOption();
        this.updateComponentData();
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.initData();
    }

    updateComponentData = async () => {
        const { company } = this.props;
        const contactSettings = await DataHandler.get({ url: `settings/company/${company}/contact_settings`});
        const additional = await DataHandler.get({ url: `settings/company/${company}/contact_relation_option` });
        this.setState({
            contactSettings: contactSettings, additional, initialized:true
        }, this.updateContactRelationOption);
    }

    updateContactRelationOption = () => {
        const { additional: { contact_relation_default_option } } = this.state;

        this.state.data.contactRelationOptions = [
            {
                id: 1,
                name: this.tr("Constrained to the corporation tree"),
                default: false
            }, {
                id: 2,
                name: this.tr("Can be added to any customer"),
                default: false
            }
        ];

        const crdop = this.state.data.contactRelationOptions.find(x => x.id == contact_relation_default_option);

        if (crdop) crdop.default = true;
        this.setStateData(this.state.data.contactRelationOptions);
    }

    fetchPositions = () => {
        DataHandler.get({ url: `settings/contacts/positions/${this.props.company}` }).done(data =>
            this.setStateData("positions", data)
        );
        
    }

    fetchCompanyLangs = async () => {
        const { company } = this.props;
        let response = await DataHandler.get({ url: `settings/company/${company}/langs` });
        this.setStateData("langs", response);
    }

    changeCreationSettings = (settings, setting, value) => {
        let ns = settings;
        ns[setting] = value;
        this.setState({contactSettings: ns}, () => {
            const params = {setting: setting, setting_value: value};
            DataHandler.post({ url: `settings/company/${this.props.company}/contact_settings`, ...params});
        })
    }

    render() {
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { tr } = this;
        const { company } = this.props;
        const { taimerAccount: { isMulticompany } } = this.context;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };
      
        const settings = [
            {
                type: "check",
                name: "company_contacts",
                label: tr("Show only account’s contacts on the project card"),
                className: "respSetting half"
            }
        ];
        const contactSettings = [  
            {
                type: "check",
                name: "contacts_force_email",
                label: tr("Email mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_position",
                label: tr("Position mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_title",
                label: tr("Title mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_language",
                label: tr("Language mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_phone",
                label: tr("Phone mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_owner",
                label: tr("Contact owner mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            //{
            //    type: "check",
            //    name: "contacts_force_corporation_tree",
            //    label: tr("Restrict contact relations to corporation tree whend adding or editing contact"),
            //    className: "respSetting half",
            //},
        ];
        return (
            <div id="settings-feature-contact">
           
                {!this.state.initialized && 
                    <div> 
                        <img style={loaderStyle} src={require("../img/loading.svg").default}/>
                    </div>
                }
                {this.state.initialized && 
                    <React.Fragment>
                            {/*    <h3>{tr("Define Contact Attributes")}</h3>
                                <p style={{ fontSize: "11px" }}>{tr("Create a more targeted contact list with your own attributes. Define attributes to segment your contacts. Each contact can have one attribute linked to them.")}</p>
                            
                            <h3>{tr("General")}</h3>
                        
                                <SettingsGrid settings={settings} item={"test"} onChange={this.onChange} />
                            
                        */} {/*
                                Odottaa jotain ratkaisua konsernin laajuisille kieli asetuksille
                                <div className="settings-feature-wrapper">
                                    <h3>{this.tr("Languages")}</h3>
                                    <p style={{ fontSize: "11px" }}>{tr("Define languages") + "."}</p>
                                    <Button className="green" onMouseUp={evt => this.langList.current.addNewRow()} size="large">{tr("ADD LANGUAGE")}</Button>
                                    <OneColumnSettingList
                                        id="langList"
                                        key={'id'}
                                        ref={this.langList}
                                        company={company}
                                        headerTitle={this.tr("Language")}
                                        rowProps={{
                                            onCreate: data => DataHandler.post({url: `settings/company/${company}/new_lang`}, {lang: data.name}).done(() => {
                                                this.langList.current.removeNewRow(data.id);
                                                this.fetchCompanyLangs();
                                            }),
                                            onUpdate: data => DataHandler.put({url: `settings/company/${company}/update_lang`}, {name: data.name, id: data.id }).done(() => {
                                                this.fetchCompanyLangs();
                                            }),
                                            onDelete: data => {
                                                this.setState({
                                                    dialogData: {
                                                        id: data.id,
                                                        saveFunc: (id) => DataHandler.delete({ url: `settings/company/${company}/delete_lang`}, {id: data.id}).done(() => {
                                                            this.langList.current.removeRow(data.id);
                                                            this.fetchCompanyLangs();
                                                        }),
                                                        text: tr("Do you want to delete the language") + ": " + data.name + "?"
                                                    }
                                                });
                                                this.openDialog('confirmation');
                                            }
                                        }}
                                        data={this.state.data.langs}
                                        />
                                </div>

                            */}    

                                <div className="settings-feature-firstwrapper">

                                    <h3>{this.tr("Positions")}
                                        {isMulticompany ? <StatusTag
                                                style={{ marginLeft: 16, display: "inline", padding: "5px 12px" }}
                                                color={"#ffaf0f"}
                                                text={this.tr("Multi-Company")}
                                            /> : ''
                                        }
                                    </h3>
                                    <p style={{ fontSize: "11px" }}>{tr("Positions text.")} {isMulticompany ? tr("(shared with corporation)") : ""}</p>
                                    <Button className="green" onMouseUp={evt => this.positionList.current.addNewRow()} size="large">{tr(" Add position")}</Button>
                                    <OneColumnSettingList
                                        id="positionList"
                                        key={this.state.data.positions.length}
                                        ref={this.positionList}
                                        company={company}
                                        height="auto"
                                        headerTitle={this.tr("Position")}
                                        rowProps={{
                                            onCreate: data => {

                                                let error = false;
                                                this.state.data.positions.filter(p => {
                                                    if(p.id != data.id && p.name.toLowerCase() === data.name.toLowerCase())
                                                        error = true
                                                })
                                                if(data.name == ""){
                                                    this.props.enqueueSnackbar((tr("Enter position") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;
                                                }
                                                if (!error) {
                                                    return DataHandler.post({url: `settings/contacts/positions/${company}`}, {name:data.name}).done(() => {
                                                        // this.fetchPositions();
                                                    });
                                                }
                                                else{
                                                    this.props.enqueueSnackbar(( data.name + tr(" Position already exists") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;

                                                }
                                            },
                                            onUpdate: data => DataHandler.put({url: `settings/contacts/positions/${company}`}, {name: data.name, id: data.id }).done(() => {
                                                // this.fetchPositions();
                                            }),
                                            onDelete: (data, _, list) => {
                                                if (Number(data.id) < 0) {
                                                    list.removeRow(data.id);
                                                    // this.positionList.current.removeNewRow(data.id);
                                                    return;
                                                }
                                                this.setState({
                                                    dialogData: {
                                                        id: data.id,
                                                        saveFunc: (id) => DataHandler.delete({ url: `settings/contacts/positions/${data.id}`}).done(() => {
                                                            list.removeRow(data.id);
                                                            // this.fetchPositions();
                                                        }),
                                                        text: tr("Do you want to delete the position") + ": " + data.name + "?"
                                                    }
                                                });
                                                this.openDialog('confirmation');
                                            }
                                        }}
                                        data={this.state.data.positions} />
                                </div>
                                <div className="settings-feature-firstwrapper">
                                    <h3>{this.tr("Contacts mandatory fields settings")}</h3>
                                    <p style={{ fontSize: "11px" }}>{tr("Contact adding definitions and Account linking rules")}</p>
                                    <SettingsGrid settings={contactSettings} item={this.state.contactSettings} onChange={this.changeCreationSettings} />
                                    
                                </div>

                                {/* Contacts/Partners settings */}

                                <div className="settings-feature-lastwrapper">
                                    <h3>{tr("Contact relation settings")}</h3>
                                    <p className="subtitle">{tr("Controls whether an existing contact can be added to any customer, or only to those customers that exist in the original customer's corporation tree.")}</p>

                                    <span>{this.tr("Restrict contact relations to corporation tree")}</span> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.additional ? String(this.state.additional.contact_relation_default_option) === "1" : false} 
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/company/${company}/contact_relation_option` }, {
                                                contact_relation_default_option: checked === true ? "1" : "2"
                                            }).done(response => {
                                                this.setState({
                                                    additional: { contact_relation_default_option: response.contact_relation_default_option }
                                                });
                                            });
                                        }}
                                    />
                                    
                                    {/*<List
                                        id="contactRelationOption"
                                        ref={this.contactRelationOptionList}
                                        className="settingsList"
                                        height="auto"
                                        row_key="id"
                                        rowHeight={70}
                                        listRowType={ContactRelationOptionListRow}
                                        emptyNewDataOnUpdate
                                        rowProps={{
                                            onUpdate: data => {
                                                DataHandler.post({ url: `settings/company/${company}/contact_relation_option` }, {
                                                    contact_relation_default_option: data.id,
                                                });
                                                this.setState({ additional: { ...this.state.additional, contact_relation_default_option: data.id } }, this.updateContactRelationOption);
                                            }  
                                        }} 
                                        noStateData
                                        columns={[
                                            { width: 352, name: "name", header: tr("Option"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                            { width: 130, name: "default", header: tr("Active"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },

                                        ]}
                                        newRow={{
                                            name: "",
                                            companies_id: company
                                        }}
                                        data={this.state.data.contactRelationOptions}
                                    />*/}
                                    </div>
                            

                                    {Dialog && <Dialog
                                        open
                                        company={company}
                                        onDialogClose={this.closeDialog}
                                        onDialogSave={this.confirmDialog}
                                        data={this.state.dialogData}

                                    />}
                    </React.Fragment>
                    }
            </div>
    
                        
                  
        );

    }
}

export default Contact;
