import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { SettingsContext } from '../SettingsContext';
import styles from './Link.module.css';

class Link extends React.PureComponent {
    static contextType = SettingsContext;

    _generateUrlObjectFromString = (url) => {
        let parts = url.split('?');
        if (!parts || parts.length < 2) return false;
        let paramParts = parts[1].split('&');
        if (!paramParts || !Array.isArray(paramParts)) return false;
        let updateViewConfig = {};
        paramParts.forEach((part) => {
            const param = part.split('=');
            if (!param || !param[0]) return;
            updateViewConfig[param[0]] = param[1] ? param[1] : undefined;
        });
        return updateViewConfig;
    };

    _urlToString = (url) => {
        if (!url) return '';
        const urlValue = typeof url == 'function' ? url() : url;
        if (typeof urlValue == 'string') {
            return urlValue;
        }
        try {
            const urlString = `index.php?${$.param(urlValue)}`;
            return urlString;
        } catch (e) {
            return '';
        }
    };

    _onClick = (e) => {
        const { url, href, onClick, onLinkClicked, onClickWithCtrl, allowHrefWithOnClick, setTitle } = this.props;
        if (onClick && (onClickWithCtrl || !e.ctrlKey && !e.metaKey && e.button !== 1)) {
            !allowHrefWithOnClick && e.preventDefault();
            onClick(e);
            return;
        }
        // url should be used – href in here just to make sure everything works if that "wrong" prop is used.
        let urlToUse = typeof url == 'function' ? url() : url || href;
        if (!urlToUse) return;
        onLinkClicked && onLinkClicked();
        if (typeof urlToUse == 'object') {
            e.preventDefault();
            const inNewTab = e.ctrlKey || e.metaKey || e.button === 1 || this.props.openInNewTab;
            this.context.functions.updateView(urlToUse, inNewTab, true, false, false, inNewTab ? undefined : setTitle);
            return;
        }
        if (urlToUse.includes('http') || urlToUse.includes('mailto') || urlToUse.includes('tel')) return;
        e.preventDefault();
        const urlObject = this._generateUrlObjectFromString(urlToUse);
        const inNewTab = e.ctrlKey || e.metaKey || e.button === 1 || this.props.openInNewTab;
        this.context.functions.updateView(urlObject, inNewTab, true, false, false, inNewTab ? undefined : setTitle);
    };

    render() {
        const { className = '', style, noColor, children, url, href, openInNewTab = false, ...rest } = this.props;
        // url should be used – href in here just to make sure everything works if that "wrong" prop is used.
        return (
            <a
                className={`${styles.link} ${noColor ? styles.noColor : ''} ${className}`}
                style={style}
                href={this._urlToString(url || href)}
                target={openInNewTab ? '_blank' : '_self'}
                rel={openInNewTab && 'noopener noreferrer'}
                {...rest}
                onClick={this._onClick}
            >
                {children}
            </a>
        );
    }
}

Link.propTypes = {
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.string, // url should be used but just in case accepts href also
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.element,
    openInNewTab: PropTypes.bool,
};

export default Link;
