import React, { Component } from 'react'

/* local components */
import List from '../list/List';
import TaimerComponent from '../TaimerComponent';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import AutoCompleteCell from "../list/cells/AutoCompleteCell";
import EditableStatusCell from "../list/cells/EditableStatusCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import ContextMenu from '../general/ContextMenu';
import DataHandler from '../general/DataHandler';
import ConfirmationDialog from '../list/dialogs/ConfirmationDialog';
import Utils from "./../general/Utils.js";

/* material ui */
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Button, MenuItem, TextField } from '@mui/material';
import { Switch } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import TrashCanIcon from '@mui/icons-material/Delete';

import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';

/* context */
import { SettingsContext } from '../SettingsContext';

/* other */
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';

import {ReactComponent as Loading} from '../dashboard/insights/img/loading.svg';

import './TabRelations.css';
import PageTopSection from '../general/PageTopSection';

class RelationRow extends PropsOnlyListRow {
    static contextType = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        showLockedUsersWithTag: true,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            onChange: () => { },
            editMode: false
        }
    };

    constructor(props, context) {
        super(props, { autoCompleteUsers: [] }, {}, "accounts/TabRelations");

        this.context = context;

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };        
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getAutoCompleteData(this.props.data.companies_id);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let update = isEqual(nextProps.rowProps.companies_id, this.props.rowProps.companies_id);
        return update || (this.props.flips[this.props.data.id] !== nextProps.flips[this.props.data.id] || (this.props.usesState && !isEqual(this.state, nextState)));
    }

    getAutoCompleteData = async (company) => {
        let autoCompleteUsers;
        await DataHandler.get({ url: `subjects/employees/${company}`, dontIgnoreCompany: this.context.taimerAccount.allowAccountManagerAndResponsibleFromAllCompanies ? false : true}).done(response => {
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };            
            response.forEach((p, i) => {
                response[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            });
            autoCompleteUsers = response.filter(user => user.companies_id > 0);
        });	
        if (this.context.taimerAccount.allowAccountManagerAndResponsibleFromAllCompanies) {
            autoCompleteUsers = autoCompleteUsers.map(u => {let user = {...u}; user.name = user.name + " (" + this.props.sharedData.companies.find(company => company.id == user.companies_id).name + ")"; return user;})
        }
        this.setState({ autoCompleteUsers });
    }

    saveNewRow = async () => {
        let { data } = this.props;
        const id = await this.create(data)
        if (id) {
            this.setData({
                id: String(id)
            });
        } else {
            return;
        }
    }

    lockCustomer = (data, selectNewAccount) => {
        this.deleteProductFromAccount(data, selectNewAccount);
        const target = this.context.taimerAccount.isMulticompany ? "r_locked" : "c_locked";
        this.setDataAndUpdate(target, data.newData.id);
        this.props.rowProps.closeAccountLockDialog();
    }

    deleteRelation = (data, selectNewAccount) => {
        this.deleteProductFromAccount(data, selectNewAccount);
        this.delete();
        this.props.rowProps.closeAccountLockDialog();
    }

    deleteProductFromAccount = (data, selectNewAccount) => {
        if (data.productAmount > 0 && selectNewAccount) {
			DataHandler.post({url: `products/supplier/assign_new/${data.companies_id}`}, {oldSupplier: data.customers_id, newSupplier: selectNewAccount.supplier, newAddress: selectNewAccount.address});
		}
		else if (data.productAmount > 0) {
			DataHandler.delete({url: `products/supplier/${data.orig_id || data.customers_id}`});
        }
    }

    defineClassName() {
        return "relationRow";
    }

    defineCells() {
        const { data, sharedData, rowProps: { statusLabels, openDialog, customer, companies_id, writeRightCompanies } } = this.props;
        const { account } = sharedData;
        const { taimerAccount, functions: { checkPrivilege, companyUsesAddOn } } = this.context;
        const crmEditable = data.is_crm_editable > 0 || data.id < 0;

        const mediaproAllowProjectCreation = checkPrivilege("admin", "admin") 
            || !companyUsesAddOn("mediapro", data.companies_id) 
            || Boolean(parseInt(account.main_unit));

        let cells = {
            context:
                this.props.data['id'] > 0 && crmEditable 
                    ?
                        <ContextMenu label={<MoreHoriz />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                            <MenuItem className="delete" onClick={() => this.props.rowProps.openAccountLockDialog(data, this.deleteRelation, true) }><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem>
                        </ContextMenu>
                    : ( this.props.data['id'] < 0 ?
                        <ListCell width={this.props.columnWidthMap.context} permanentEditMode={true} onlyDisplay={true}>
                            <Tooltip title={this.tr("Save")} placement="bottom">
                                <CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
                            </Tooltip> 
                        </ListCell>
                    : <div className="cell" style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} ></div> ),
            checked:
                this.props.data['id'] > 0
                    ?
                    <ListCell className="empty-cell" />
                    //<CheckboxCell checked={this.props.checked} onClick={() => this.props.listRef.check(this.props.data.id)} />
                    :
                    <ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
                        <Tooltip title={this.tr("Cancel")} placement="bottom">
                            <Cancel className="cancelIcon" onClick={() => this.delete(this.props.data)} />
                        </Tooltip>
                    </ListCell>,
            company:
                <AutoCompleteCell
                    value={data.companies_id || companies_id}
                    autoCompleteData={!crmEditable ? sharedData.companies : (writeRightCompanies && sharedData.companies ? sharedData.companies.filter(c => writeRightCompanies.find(w => c.id == w)) : [])}
                    editable={crmEditable}
                    onEdited={async newData => {
                        const company = sharedData.companies.find(c => c.id.indexOf(data.companies_id) === -1)
                        await this.getAutoCompleteData(newData.id);
                        if (data.id > 0) {
                            let promise = this.setDataAndUpdate("companies_id", newData.id);
                            promise.done(response => {
                                if (response.error) {
                                    this.props.rowProps.enqueueSnackbar(this.tr("This account type already exist for") + " " + company.name, {
                                        variant: "error"
                                    });
                                    this.setData("companies_id", data.companies_id)
                                    return;
                                }
                            });
                        } else {
                            this.setData("companies_id", newData.id);
                        }
                    }}
                />,
            account_type:
                <AutoCompleteCell
                    value={data.type_id}
                    autoCompleteData={sharedData.customer_types}
                    editable={crmEditable}
                    onEdited={newData => {
                        const company = sharedData.companies.find(c => c.id.indexOf(data.companies_id) !== -1)
                        if (data.id > 0) {
                            let promise = this.setDataAndUpdate("type_id", newData.id);
                            promise.done(response => {
                                if (response.error) {
                                    this.props.rowProps.enqueueSnackbar(this.tr("This account type already exist for") + " " + company.name, {
                                        variant: "error"
                                    });
                                    this.setData("type_id", data.type_id)
                                    return;
                                }
                            });
                        } else {
                            this.setData("type_id", newData.id);
                        }
                    }}
                />,
            locked:
                <EditableStatusCell
                    value={this.context.taimerAccount.isMulticompany ? { id: data.r_locked, label: statusLabels[Number(data.r_locked)] } : { id: data.c_locked, label: statusLabels[Number(data.c_locked)] }}
                    options={sharedData.statuses}
                    editable={crmEditable}
                    onEdited={newData => {
                        if (newData.id == 1) {
                            let account = {...data};
                            account.newData = newData;
                            if (taimerAccount.isMulticompany && data.r_locked == 1) 
                                return;
                            else if (data.c_locked == 1)
                                return;
                            
                            this.props.rowProps.openAccountLockDialog(account, this.lockCustomer);
                        } else {
                            if (taimerAccount.isMulticompany) {
                                this.setDataAndUpdate("r_locked", newData.id);
                            } else {
                                this.setDataAndUpdate("c_locked", newData.id);
                            }
                        }
                    }}
                />,
            account_manager:
                <AutoCompleteCell
                    value={data.account_manager_id}
                    autoCompleteData={this.state.autoCompleteUsers ? [{id: "0", users_id: "0", name: this.tr("No account manager"), label: this.tr("No account manager"), deleted: "0"}, ...sharedData.users_locked.filter(u => u.id == data.account_manager_id), ...this.state.autoCompleteUsers] : []}
                    editable={crmEditable}
                    onEdited={data => {
                        this.setDataAndUpdate("account_manager_id", data.id);
                    }}

                />,
            account_manager2:
                <AutoCompleteCell
                    value={data.account_manager2_id}
                    autoCompleteData={this.state.autoCompleteUsers ? [{id: "0", users_id: "0", name: this.tr("No account responsible"), label: this.tr("No account responsible"), deleted: "0"}, ...this.state.autoCompleteUsers] : []}
                    editable={crmEditable}
                    onEdited={data => {
                        this.setDataAndUpdate("account_manager2_id", data.id);
                    }}

                />,
            allow_project_creation:
                !mediaproAllowProjectCreation
                ? 
                <ListCell editable={false} onlyDisplay>
                    <Switch checked={parseInt(data.allow_project_creation) === 1} color="primary" disabled={true} />
                </ListCell>
                :
                <ListCell className="" editable={crmEditable} onlyDisplay>
                    <Switch
                        checked={data.allow_project_creation == 1}
                        color="primary"
                        disabled={!crmEditable}
                        onChange={e => {
                            let allowCreate = 0;
                            if (e.target.checked)
                                allowCreate = 1

                            this.setDataAndUpdate("allow_project_creation", allowCreate);
                        }}
                    />
                </ListCell>,

        }

        return cells;
    }
}

class TabRelations extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {showLockedUsersWithTag: true};

    constructor(props, context) {
        super(props, context, "accounts/TabRelations");

        this.state = {
            data: [],
            autoCompleteData: {},
            page: 1,
            perpage: 30,
            relationCount: 0,
            showDeleteConfirmation: false,
            confirmationData: {},
            accountType: null,
            writeRightCompanies: [],
            readRightCompanies: []
        }

        this.userTypeAutocompleteClasses = [
            'users',
            'users_locked'
        ];

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };

        this.list = React.createRef();
    }

    updateComponentData = (override = {}, updateAfter = false) => {
        this.setState({ loading: true }, async () => {
            const { account, company } = this.props;
            const { userObject } = this.context
            let params = { page: 1, perpage: this.state.perpage, user: userObject.usersId, lang: userObject.language, company };
    
            for (let oi in override)
                params[oi] = override[oi];
    
            const responses = await Promise.all([DataHandler.get({ url: `accounts/relations/autocompletedata`, ...params }), DataHandler.get({ url: `accounts/relations/data/${account.id}`, ...params })]);
            const autoCompleteData = responses[0] || {};
            const response = responses[1] || {};
            for (const status of autoCompleteData.statuses) {
                status.label = this.tr(status.label);
            }
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };            
            Object.keys(autoCompleteData).forEach(k => {
                if (this.userTypeAutocompleteClasses.includes(k)) {
                    autoCompleteData[k] = autoCompleteData[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                }
            })
            this.setState({ loading: false, data: response.data, relationCount: response.relationCount, pageCount: response.pageCount, autoCompleteData: autoCompleteData, writeRightCompanies: response.customer_write_right, readRightCompanies: response.customer_read_right }, () => {
                updateAfter && this.props.updateData();
            });
        })
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company !== this.props.company) {
            this.updateComponentData();
            //this.list.current.removeNewRows
        }
    }

    renderConfirmation = () => {
        if (!this.state.showDeleteConfirmation) return null;
        return (
            <ConfirmationDialog
                data={{ text: this.tr("Do you really want to lock") + " " + this.state.accountType + "?" }}
                onDialogSave={() => {
                    this.state.saveCallback();
                    this.setState({ showDeleteConfirmation: false });
                }}
                onDialogClose={() => {
                    this.setState({ showDeleteConfirmation: false });
                }}
            />
        );
    };

    openDialog = (f = null, type = null) => {
        this.setState({ showDeleteConfirmation: true, saveCallback: f, accountType: type });
    }

    openAccountLockDialog = (data, lockCustomer, deleteRelation = false) => {
		this.props.openAccountLockDialog(data, data.customers_id, lockCustomer, deleteRelation);
    }

    render() {
        const { tr } = this;
        const { functions: { checkPrivilege, companyUsesAddOn } } = this.context;
        const { data, autoCompleteData, writeRightCompanies } = this.state;
        const { account, company } = this.props;

        let fields = [
            { field: "context", name: "context", header: "", columnHeaderType: writeRightCompanies && writeRightCompanies.length > 0 && "roundButton", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "checked", name: "checked", header: "", columnHeaderType: "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "company", name: "company", header: tr("Company"), width: 300 },
            { field: "account_type", name: "account_type", header: tr("Account Type"), width: 250 },
            { field: "locked", name: "locked", header: tr("Status"), width: 150 },
            { field: "account_manager", name: "account_manager", header: tr("Account manager"), width: 250 },
            { field: "account_manager2", name: "account_manager2", header: tr("Responsible"), width: 250 },
            { field: "allow_project_creation", name: "allow_project_creation", header: tr("Project creation"), width: 175 },

        ];
        let defaultType = 1;
        if (autoCompleteData.hasOwnProperty('customer_types')) {
            _.forEach(autoCompleteData.customer_types, type => {
                if (type.is_default == 1) {
                    defaultType = type.id;
                    return;
                }
            })
        }
        const newRow = {
            companies_id: company,
            type_id: defaultType,
            customers_id: account.id,
            c_locked: "-1",
            r_locked: "-1",
            account_manager: 0,
            account_manager2: 0,
            allow_project_creation: 1
        }

        return (
            <div id="relation-list-container">
                {this.state.loading && <div id="loading-icon-container"><Loading className="loading-icon"/></div>}
                {this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
                <List
                    ref={this.list}
                    data={data}
                    rowKey={"id"}
                    columns={fields}
                    sharedData={{ 
                        ...autoCompleteData, 
                        account: account
                    }}
                    height="fitRemaining"
                    minHeight={448}
                    trimHeight={-10}
                    className="relationList"
                    listRowType={RelationRow}
                    newRow={newRow}
                    saveColumnConfig={true}
                    showPageSelector={true}
                    noStateData={true}
                    manualCreate={true}
                    pageCount={this.state.pageCount}
                    totalCount={this.state.relationCount}
                    perpage={this.state.perpage}
                    userListSettingsKey="relations-list"
                    onDataChange={({ data, list }) => {
                        const ids = data
                            .filter(d => {
                                return parseInt(d.id) < 0
                                    && companyUsesAddOn("mediapro", d.companies_id)
                                    && (!account.main_unit || parseInt(account.main_unit) === 0)
                                    && parseInt(d.allow_project_creation) === 1
                                    && !checkPrivilege("admin", "admin");
                            })
                            .map(d => d.id);

                        if(ids.length > 0) {
                            list.editData({
                                allow_project_creation: 0
                            }, ids);
                        }
                    }}
                    onAddNewRow={newRows => {
                        const [newRow] = newRows;
                        const { 
                            allow_project_creation 
                        } = newRow;

                        const allowProjectCreation = !companyUsesAddOn("mediapro", newRow.companies_id) 
                            || Boolean(parseInt(account.main_unit));

                        this.list.current.editData({
                            allow_project_creation: allowProjectCreation 
                                ? allow_project_creation 
                                : false
                        }, newRow.id);
                    }}
                    rowProps={{
                        openDialog: this.openDialog,
                        onUpdate: (rowData) => {
                            let resp = DataHandler.put({ url: `accounts/relations/${rowData.id}`, data: rowData });
                            setTimeout(() => {
                                this.updateComponentData(undefined, true);
                            }, 1000);
                            return resp;
                        },
                        onCreate: async (rowData) => {
                            if (rowData.id < 0) {
                                let newType = true;
                                let companyName = "";
                                //rowData.companies_id = company;
                                _.forEach(data, relation => {
                                    if (relation.type_id == rowData.type_id && relation.companies_id == rowData.companies_id) {
                                        newType = false;
                                        companyName = relation.company_name;
                                    }
                                })
                                if (newType) {
                                    const id = await DataHandler.post({ url: `accounts/relations/new`, data: rowData })
                                    this.list.current.removeRow(rowData.id);
                                    setTimeout(() => {
                                        this.updateComponentData(undefined, true);
                                    }, 1000);
                                    return;
                                } else {
                                    this.props.enqueueSnackbar(tr("This account type already exist for") + " " + companyName, {
                                        variant: "error"
                                    });
                                    return;
                                }
                            }
                        },
                        onDelete: async (rowData) => {
                            if (rowData.id > 0) {
                                if (data.length > 1) {
                                    const resp = await DataHandler.delete({ url: `accounts/relations/${rowData.id}`, data: rowData });
                                    this.list.current.removeRow(rowData.id);
                                    let newData = data.filter(d => d.id !== rowData.id);
                                    this.setState({ data: newData });
                                } else {
                                    this.props.enqueueSnackbar(tr("Account must have atleast one type") + ".", {
                                        variant: "error"
                                    });
                                    return;
                                }
                            } else if (rowData.id < 0) {
                                this.list.current.removeRow(rowData.id);
                            }

                        },
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        statusLabels: { "-1": tr("Active"), "1": tr("Locked") },
                        customer: account,
                        companies_id: company,
                        openAccountLockDialog: this.openAccountLockDialog,
                        closeAccountLockDialog: this.props.closeAccountLockDialog,
                        writeRightCompanies: writeRightCompanies
                    }}
                    onPerPageChange={perpage => {
                        this.setState({ perpage: perpage });
                        this.updateComponentData({ perpage: perpage });
                    }}
                    onPageChange={page => {
                        this.list.current.startPageChangeAnimation();
                        this.setState({ page: page })
                        this.updateComponentData({ page: page })
                    }}
                    onSortRows={(colName, asc) => {
                        this.sortTerms = { name: colName, asc: asc };
                        this.updateComponentData();
                    }}
                />
                {this.renderConfirmation()}

            </div>
        )
    }
}

export default withSnackbar(TabRelations);
