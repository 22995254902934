import React from 'react';
import PropTypes from "prop-types";
import InputAdornment from '@mui/material/InputAdornment';

/* local components */
import OutlinedField from "./OutlinedField";

import './LabelField.css';

class LabelField extends React.Component {
	static defaultProps = {
		datePicker: false,
		editorType: OutlinedField,
        onClick: () => {},
        className: ""
	};


    render () {
        const { label, innerRef, labelFieldClass, infoTooltip, ...rest } = this.props;
        const Editor = this.props.editorType;

        let endAdornment = undefined;
        if (infoTooltip) {
            endAdornment = <InputAdornment position="end"><div className="HasTooltip label-field" title={infoTooltip}></div></InputAdornment>
        }
        
        return (
            <div className={`label-field ${labelFieldClass || ''} ${this.props.className}`} >
                {label && <div className="label">{label + ":"}</div>}
                {this.props.editMode !== false && <Editor components={this.props.components} autoComplete = {"new-" + this.props.name} {...rest} ref={innerRef} InputProps={{endAdornment: endAdornment}} />}
                {this.props.editMode === false && <Editor components={this.props.components} {...rest} ref={innerRef} readonly />}
            </div>
        );
    }
}

export default LabelField;
