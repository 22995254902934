import React from 'react';

import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import DateCell from "../cells/DateCell";
import TextInputCell from "../cells/TextInputCell";
import AutoCompleteCell from '../cells/AutoCompleteCell';
import CurrencyListCell from "../CurrencyListCell";
import CheckboxCell from "../cells/CheckboxCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import ContextMenu from "../../general/ContextMenu";
import { ReactComponent as RemoveIcon } from './../../general/icons/remove.svg';
import { ReactComponent as AccountIcon } from '../../navigation/NavIcons/customers.svg';
import { ReactComponent as ProjectIcon } from '../../navigation/NavIcons/leads.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import moment from 'moment';
import { getWorktypesForProject, postWorkhourEntry } from '../../Data';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { CheckCircleOutline, AssignmentReturned } from '@mui/icons-material';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Tooltip } from "@mui/material";
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import colors from '../../colors';

class MyHoursListRow extends PropsOnlyListRow {
	constructor(props) {
		super(props, {
			editMode: false,
			showDeleteConfirmation: false,
		}, {}, 'list/rows/MyHoursListRow');

		this.starttime = "";
		this.endtime = "";
		this.cellEdited = this.cellEdited.bind(this);
	}

	async cellEdited(name, value) {
		let originalData = { ...this.props.data }
		let data = { ...this.props.data };

		if (data[name] == value)
			return;

		if (name === "description"){
			this.descriptionEdited(name, value);
			return;
		}

		if (name === "date") {
			const oldStart = moment(data.start);
			const oldEnd = moment(data.end);

			var start = moment(value);
			var end = moment(value);

			start.hour(oldStart.hour());
			start.minute(oldStart.minute());
			start.second(oldStart.second());

			end.hour(oldEnd.hour());
			end.minute(oldEnd.minute());
			end.second(oldEnd.second());

			data['start'] = start;
			data['end'] = end;
		}
		else if (name === "starttime" || name === "endtime") {
			const time = moment(value, "HH:mm:ss");
			if (!time._isValid) {
				this.showFieldError(name, value, this.tr("Invalid time!"));
				return;
			}

			const oldtime = name === "starttime" ?  moment(data.end).format("HH:mm:ss") : moment(data.start).format("HH:mm:ss");
			const newtime = time.format("HH:mm:ss")

			if ((name === "starttime" && newtime >= oldtime) || (name === "endtime" && newtime <= oldtime)) {
				this.showFieldError(name, value, this.tr("Starttime cannot be later than endtime!"));
				return;
			}
			
			data["_invalidField_" + name] = false;

			var base = moment(name === "starttime" ? data.start : data.end);
			base.hour(time.hour());
			base.minute(time.minute());
			base.second(time.second());
			data[name === "starttime" ? 'start' : 'end'] = base;
		}
		else {
			data[name] = value;
		}

		const res = await this.setDataAndUpdate(data);
		console.log(res);

		if (!res) {
			this.setData(originalData);
		}
	}

	descriptionEdited = (name, value) => {
		let data = { ...this.props.data };
		const { functions: { getTimeTrackerSettings } } = this.context;
		const timeTrackerSettings = getTimeTrackerSettings();


		if (timeTrackerSettings.hour_entry_description && (!value || value.toString().trim() === "")) {
			this.showFieldError(name, value, this.tr("Description cannot be empty."));
			return;
		}

		data[name] = value;
		this.setDataAndUpdate(data);
	}

	showFieldError(name, value, message) {
		this.props.enqueueSnackbar(message, {
			variant: "error",
		});
		let data = {};
		data["_invalidField_" + name] = true;
		this[name] = value;
		this.setData(data);
	}

	deleteClick = async (e) => {
		e.stopPropagation();

		this.delete();
	}

	cancel = (e) => {
		e.stopPropagation();

		this.setState({ editMode: false });
		this.setData(_.cloneDeep(this.props.data.original));
	}

	saveEdits = () => {
		this.update();
	}

	updateWorkTypes = async (item = false) => {
		const { data } = this.props;
		const { worktypesProject, worktypes } = this.state;

		if (!item)
			item = data;

		if (worktypesProject !== item.project.id) {
			const wt = await getWorktypesForProject(item.project.id);

			this.setState({
				worktypesProject: item.project.id,
				worktypes: wt,
			});

			this.setData(item);

			return wt;
		}

		return worktypes;
	}

	rowClick = () => {
		// this.updateWorkTypes();
	}

	async update(data = false) {
		const { enqueueSnackbar } = this.props;

		const item = data !== false ? data : this.props.data;

		// Validate
		const invalids = [];

		if (item.worktask.id == 0) {
			invalids.push('jobtype');
		}

		this.setInvalidFields(invalids);

		if (invalids.length > 0) {
			enqueueSnackbar("TODO", {
				variant: "error",
			});
			return;
		}

		this.setState({ editMode: false });

		try {
			const data = await postWorkhourEntry(item);

			if (data.status === "FAILED") {
                if (data.errorcode === 'PROJECT_NOT_MAIN') {
                    enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'INVALID_WORKTASK') {
                    enqueueSnackbar(this.tr("Selected worktask can't be used in current project."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'OVERLAPS_DISABLED') {
                    enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                      variant: "error",
                    });
                } else if (data.errorcode === 'INVALID_PROJECT') {
                    enqueueSnackbar(this.tr("This project can't be selected."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'NO_PERMISSION') {
                    enqueueSnackbar(this.tr("This project can't be selected (no permission)."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'ZERO_HOURS') {
                    enqueueSnackbar(this.tr("Start and end time must be different."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'INVALID_DATES' || data.errorcode === 'INVALID_DATE') {
                    enqueueSnackbar(this.tr("Start and/or end date are not valid."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'NOT_IN_TEAM') {
                    enqueueSnackbar(this.tr("Only project team members can add hours to this project."), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                    enqueueSnackbar(this.tr("Can't add hours to this month"), {
                        variant: "error",
                    });
                } else if (data.errorcode === 'DESCRIPTION_REQUIRED') {
					enqueueSnackbar(this.tr("Description is required."), {
					  variant: "error",
					});
				} else if (data.errorcode) {
                    enqueueSnackbar(this.tr("Saving failed!") + " " + this.tr("Error") + ": " + this.tr(data.errorcode), {
                        variant: "error",
                    });
                } else {
                    enqueueSnackbar(this.tr("Saving failed!"), {
                        variant: "error",
                    });
                }

				return false;
            } else {
				window.dispatchEvent(new Event("workhourSaved"));
			}
		} catch (error) {
			
		}

		this.props.rowProps.onUpdate();
		return true;
	}

	projectEdited = async (project) => {
		const { data, enqueueSnackbar } = this.props;

		// Skip if choosing same project
		if (project.id == data.project.id) {
			return;
		}
		const worktypesNew = await this.updateWorkTypes({ project });

		const currentWorktask = worktypesNew.find(x => x.id == data.worktask.id) || { id: 0 };

		const change = {
			project: { id: project.id, name: project.pro_name, url: project.pro_url },
			wh_projects_resource: { id: 0 },
		};

		const invalids = [];

		if (!currentWorktask.id) {
			enqueueSnackbar(this.tr("Worktype ${worktype} cannot be used on project ${project}. Please select another.", {
				worktype: data.worktask.name,
				project: project.label,
			}), {
				variant: "info",
			});

			this.setInvalidFields("jobtype");

			invalids.push('jobtype');

			this.setState({ editMode: true });

			change.worktask = currentWorktask;
		}

		this.setInvalidFields(invalids);

		if (invalids.length > 0) {
			this.setData(change);
		} else {
			this.setDataAndUpdate(change);
		}
	}

	createDisableableMenuItem = (text, disableInfoMsg = "", onClickFunc = () => {}, disabled = false, Icon = undefined, className = "", iconClass = "") => {
		return disabled ? (
			<Tooltip title={disableInfoMsg} placement="right">
				<div>
					<MenuItem disabled={true} className={className} onClick={() => {}}>
						<Icon className={iconClass} title="" />{text}
					</MenuItem>
				</div>
			</Tooltip>
		) : (			
			<MenuItem className={className} onClick={onClickFunc}>
				<Icon className={iconClass} title="" />{text}
			</MenuItem>
		)
	}

	defineCells() {
		const { data, columnWidthMap, sharedData: { projects_resources, accounts, allAccounts, allProjects, projects }, enqueueSnackbar } = this.props;
		const { editMode, worktypes, showDeleteConfirmation } = this.state;

		const start = moment(data.start);
		const end = moment(data.end);
        const diff = end.diff(start, 'hours', true);
		const { rowProps: { tr }} = this.props;
		
		const statusOptions = [
            {id: 1, name: "approved", color: colors.greenish_cyan, value: 1, label: this.tr("approved")},
            {id: 0, name: "waiting", color: "#ffb822", value: 0, label: this.tr("waiting")},
			{id: -1, name: "declined", color: "#ff5722", value: -1, label: this.tr("declined")},
			{id: 2, name: "Submitted", color: "#716ACA", value: 2, label: this.tr("Submitted")}
		];
		
        let type = "";
        
        if(data.project_type == "3") {
            type = tr("vacation");
        } else if(data.is_overtime !== "0") {
            type = tr("overtime");
        } else {
            type = tr("normal");
        }

		const editable = data.editable;

		const commonProps = {
			editable,
		};

		const resources = projects_resources.filter(el => 
			el.id == data.wh_projects_resource.id ||
			el.id == 0 ||
			(el.projects_id == data.project.id && el.done != 1 && 
				((el.user_ids && el.user_ids.includes(data.user.id)) || el.allow_all))
		);		

		const customerSource = data.unit.id ? 'unit' : 'customer';
		const customer = data[customerSource];
		const customersProjects = projects.filter(e => e.customers_id == customer.id);

		return {
			checked:
				<CheckboxCell
					width={columnWidthMap['checked']}
					checked={this.props.checked}
					onClick={() => this.props.listRef.check(data.id)}
				/>,
			actions:
				<ListCell
					width={columnWidthMap['actions']}
					editable={false}
					onlyDisplay>
					{editMode ? <>
						<Cancel className="cancelIcon" onClick={this.cancel} />
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={this.saveEdits} />
					</> : 
						<ContextMenu className="row-menu" label={<ContextMenuIcon />}
							buttonProps={{ className: 'action-menu' }} noExpandIcon>
							<MenuItem key={1} noClose={false} onClick={() => this.props.rowProps.openHourEntryDialog(data)} >
								<ViewIcon />{this.tr("View hour")}
							</MenuItem>
							<MenuItem key={1} noClose={false} onClick={() => this.context.functions.updateView({module: "customers", action: "view", id: customer.id})} >
								<AccountIcon />{this.tr("View account")}
							</MenuItem>
							<MenuItem key={2} noClose={false} onClick={() => this.context.functions.updateView({module: "projects", action: "view", id: data.project.id})} >
								<ProjectIcon />{this.tr("View project")}
							</MenuItem>
							{(data.submittable || data.revokeable) && data.approvable_hour &&
								<MenuItem key={3} noClose={false} onClick={() => this.props.rowProps.confirmHourSubmit(data.revokeable ? "revoke" : "submit", data.id)} >
									{data.revokeable ? <AssignmentReturned /> : <CheckCircleOutline />}{data.revokeable ? this.tr("Revoke") : this.tr("Send for approval")}
								</MenuItem>
							}
							{this.createDisableableMenuItem(
								this.tr("Delete"), 
								this.tr("Workhour cannot be deleted anymore"),
								this.deleteClick,
								data.deletable == 0,
								RemoveIcon,
								'delete',
								'delete'
							)}
				 		</ContextMenu>}
				</ListCell>,
			date:
				<DateCell
					name="date"
					width={columnWidthMap.date}
					value={data.start}
					onEdited={this.cellEdited}
					{...commonProps}
				/>,
			customer:
				<AutoCompleteCell
					name="customer"
					value={allAccounts.find(a => a.value == customer.id)}
					autoCompleteData={accounts}
					noTab={true}
					urlHandler={() => `index.php?module=customers&action=view&id=${customer.id}`}
					onEdited={value => {
						this.setData({[customerSource]: value, project: {id: 0}});
						this.setInvalidFields(["project"]);
					}}
					editable={editable} 
				/>,
			project:
				<AutoCompleteCell
					name="project"
					autoCompleteData={customersProjects}
					value={allProjects.find(p => p.value == data.project.id)}
					urlHandler={() => `index.php?module=projects&action=view&id=${data.project.id}`}
					noTab={true}
					onEdited={project => {
						this.projectEdited(project);
					}}
					editable={editable} 
				/>,
			workphase: // TODO
				<ListCell
					width={this.props.columnWidthMap['workphase']}
					name="workphase"
					value={data.workphase}
					{...commonProps.listCellProps}
					editable={false}
				/>,
			jobtype:
				<AutoCompleteCell
					name="worktask"
					value={data.worktask}
					editable={editable}
					autoCompleteData={worktypes}
					searchable={false}
					onEdited={value => {
						this.cellEdited("worktask", value)
					}}
					listCellProps={{
						editable: editable,
					}}
					selectProps={{
						onFocus: () => {
							this.updateWorkTypes();
						}
					}}
				/>,
			description:
				<TextInputCell
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_description'],
					}}
					name="description"
					value={this.props.data['_invalidField_description'] ? this.description : data.description}
					editable={data.editable}
					onEdited={this.cellEdited}
					{...commonProps}
				/>,
			task:
				<AutoCompleteCell
					name="resource"
					value={data.wh_projects_resource.id == 0 ? undefined : resources.find(r => r.id == data.wh_projects_resource.id)}
					editable={editable}
					autoCompleteData={resources}
					searchable={false}
					onEdited={value => {
						this.cellEdited("wh_projects_resource", value);
					}}
					listCellProps={{
						editable: editable,
					}}
				/>,
			starttime:
				<TextInputCell
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_starttime'],
					}}
					name="starttime"
					inputType="text"
					value={this.props.data['_invalidField_starttime'] ? this.starttime : start.format('LT')}
					editValue={this.props.data['_invalidField_starttime'] ? this.starttime : start.format('HH:mm')}
					onEdited={this.cellEdited}
					{...commonProps}

				/>,
			endtime:
				<TextInputCell
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_endtime'],
					}}
					name="endtime"
					inputType="text"
					value={this.props.data['_invalidField_endtime'] ? this.endtime : end.format('LT')}
					editValue={this.props.data['_invalidField_endtime'] ? this.endtime : end.format('HH:mm')}
					onEdited={this.cellEdited}
					{...commonProps}
				/>,
			hours: <ListCell
				name="hours"
				value={diff.toFixed(2)}
				textAlign="right"
                editable={false}  />,
            type:
                <ListCell
                    width={this.props.columnWidthMap['type']}
                    name="type"
                    value={type}
					editable={false} />,
			invoicing_type:
				<ListCell
					width={this.props.columnWidthMap['invoicing_type']}
					name="invoicing_type"
					value={this.props.rowProps.invoicingTypes.find(e => e.value == data.project_type)?.label}
					editable={false} />,
			selling_price:
				<CurrencyListCell
					width={this.props.columnWidthMap['selling_price']}
					name="selling_price"
					currency={this.props.rowProps.currency}				
					value={data.selling_price}
					textAlign="right"
					editable={false} />,
			status:
				<EditableStatusCell
					editable={false}
					options={statusOptions}
					value={parseInt(data.status)}
					onEdited={() => {}} 
				/>,
			decline_message:
				<ListCell
					editable={false}
					name="decline_message"
					width={this.props.columnWidthMap['decline_message']}
					value={data.status == "-1" ? data.decline_message : ""}
					showTooltipForOverflownText={true}
				/>,
		};
	}

	defineClassName() {
		return "myhoursListRow";
	}
}

export default withSnackbar(MyHoursListRow);
