import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import NotificationListRow from '../../list/rows/NotificationListRow';
import NotificationsDefinitions from '../../notifications/NotificationsDefinitions';
import OutlinedField from "../../general/OutlinedField";
import { MenuItem } from '@mui/material';

import _ from 'lodash';
import cloneDeep from "lodash/cloneDeep";

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class Notifications extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/Notifications");
        const { tr } = this;

        this.state = {
            loaded: false,
            timeTrackerSettings: [],
            invoiceSettings: {},
            projectSettings: {},
            purchaseorderSettings: {},
            disabledTypes: {},
            enabledIntegrationTypes: {},
            hasPoRights: false,
            company: this.context.userObject.companies_id,
            company_selector: "props",
        }

        this.timeTrackerList = React.createRef();
        this.invoiceRefs = {
            1: this.newList = React.createRef(),
            2: this.overdueList = React.createRef(),
            3: this.paidList = React.createRef(),
        }
        this.projectRefs = {
            1: this.roleAddedList = React.createRef(),
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getSettings();
        DataHandler.get({url: 'subjects/companies'}).done( companies => this.setState({companies}) );
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(this.props.company != prevProps.company) {
            this.setState({"company_selector": "props"}, () => {
                this.getSettings();
            });
        } else if (this.state.company != prevState.company) {
            this.setState({"company_selector": "state"}, () => {
                this.getSettings();
            });
        }
    }

    setCompany = (e) => {
        this.setState({"company": e.target.value, "company_selector": "state"});
    }

    getSettings = async () => {
        const definedNotifications = NotificationsDefinitions.getDefinitions();
        const company = this.state.company_selector === "props" && this.props.company ? this.props.company : this.state.company;
        const settings = await DataHandler.get({url: `settings/company/${company}/notifications/settings`, users_id: this.context.userObject.usersId});

        //i is the main category
        _.forEach(definedNotifications, (type, i) => {
            if (i == 1) {
                let t_settings = _.cloneDeep(type.settings);
                _.forEach(t_settings, (t_setting, n) => {
                    let disabled = t_setting.controls.some(subtype => {return settings.disabled.time_tracker.indexOf(String(subtype)) > -1});
                    if(disabled)
                        t_settings[n].active = false;
                }) 

                this.setState({timeTrackerSettings: t_settings});
            } else if (i == 2) {
                let { invoiceSettings } = this.state;
                const groups = _.cloneDeep(type.setting_groups);
                _.forEach(groups, (group, g) => {
                    let i_settings = group.settings;
                    _.forEach(i_settings, (i_setting, n) => {
                        let disabled = settings.disabled.invoicing.indexOf(String(i_setting.id));
                        if(disabled > -1)
                            i_settings[n].active = false;
                    })
                    invoiceSettings[g] = i_settings;
                })
                this.setState({invoiceSettings: invoiceSettings});
            } else if (i == 3) {
                let { projectSettings } = this.state;
                const groups = _.cloneDeep(type.setting_groups);
                _.forEach(groups, (group, g) => {
                    let p_settings = group.settings;
                    _.forEach(p_settings, (p_setting, n) => {
                        if (p_setting.id >= 10000) {
                            let enabled = settings.integrations.projects.indexOf(String(p_setting.id));
                            if(enabled > -1) {
                                p_settings[n].active = true;
                            } else {
                                settings.disabled.projects.push(String(p_setting.id));
                            }
                        } else {
                            let disabled = settings.disabled.projects.indexOf(String(p_setting.id));
                            if(disabled > -1)
                                p_settings[n].active = false;
                        }
                    })
                    projectSettings[g] = p_settings;
                })
                this.setState({projectSettings: projectSettings});
            }  else if (i == 4) {
                let { purchaseorderSettings } = this.state;
                const groups = _.cloneDeep(type.setting_groups);
                _.forEach(groups, (group, g) => {
                    let po_settings = group.settings;
                    _.forEach(po_settings, (po_setting, n) => {
                        let disabled = settings.disabled.purchase_orders.indexOf(String(po_setting.id));
                        if(disabled > -1)
                            po_settings[n].active = false;
                    })
                    purchaseorderSettings[g] = po_settings;
                })
                this.setState({purchaseorderSettings: purchaseorderSettings});
            }
        });
        this.setState({disabledTypes: settings.disabled, hasPoRights: settings.has_po_rights == 1});
    }
    render() {
        const definedNotifications = NotificationsDefinitions.getDefinitions();
        const { tr } = this;
        const company = this.state.company_selector === "props" && this.props.company ? this.props.company : this.state.company;
        const { timeTrackerSettings, invoiceSettings, projectSettings, purchaseorderSettings, hasPoRights, companies } = this.state;
        const { userObject, addons, functions: { checkPrivilege } } = this.context;
        return (
            <div id="settings-notifications">
                <React.Fragment>
                    <div className="top-container">
                        <div className="top-left">
                            <h3>{tr("Your notification preferences")}</h3>
                            <span>{tr("Here you can define how you want to be notified")}</span>
                        </div>
                        <div className="top-right">
                            {companies && companies.length > 1 && <OutlinedField className="company-selector-notifications" name="company" value={company} label={tr("Select company")} onChange={this.setCompany} select>
                                {companies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                            </OutlinedField>}
                        </div>
                    </div>
                </React.Fragment>
                {addons && addons.timetracker ? <React.Fragment>
                    <List 
                        id="timeTrackerSettings"
                        ref={this.timeTrackerList}
                        className="notificationsList settingsList"
                        height="auto"
                        row_key="id"
                        rowHeight={120}
                        height="fitRemaining"
                        fluid
                        listRowType={NotificationListRow}
                        emptyNewDataOnUpdate
                        rowProps={{
                            onUpdate: data => {
                                timeTrackerSettings.forEach((setting, i) => {
                                    if (setting.id == data.id)
                                        timeTrackerSettings[i] = data;
                                })
                                let params = { sub_types: data.controls, users_id: userObject.usersId }
                                if (data.active) {
                                    DataHandler.post({url: `settings/company/${company}/notifications/enable`, ...params});
                                } else if (!data.active) {
                                    DataHandler.post({url: `settings/company/${company}/notifications/disable`, ...params});
                                }

                                this.setState(timeTrackerSettings);
                            }
                        }}
                        noStateData
                        columns={[
                            { width: 352, name: "name", header: tr("Time Tracker"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                            { width: 30, name: "active", header: tr("Notification"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                        ]}
                        newRow={{
                            name: "",
                            companies_id: company
                        }}
                        data={timeTrackerSettings}
                    />
                </React.Fragment> : undefined}

                {(checkPrivilege("invoices", "write_simple", company) || checkPrivilege("invoices", "write_full", company)) &&
                _.map(invoiceSettings, (settings, group) => {
                    let listHeight = 450;
                    if (!checkPrivilege("invoices", "write_full", company)) {
                        settings = settings.filter(x => [5,6,9,10,13,14].indexOf(x.id) > -1);
                        listHeight = 180;
                    }
                    return (
                            <React.Fragment>
                                <List 
                                id={"invoiceSettings-group" + group}
                                ref={this.invoiceRefs[group]}
                                key={"invoiceSettings-list" + group}
                                className="notificationsList settingsList"
                                height={listHeight}
                                rowKey="id"
                                rowHeight={120}
                                fluid
                                listRowType={NotificationListRow}
                                emptyNewDataOnUpdate
                                rowProps={{
                                    onUpdate: data => {
                                        const newSettings = cloneDeep(invoiceSettings);

                                        newSettings[group].find(s => s.id === data.id).active = data.active;

                                        if(data.allSelector) {
                                            newSettings[group] = newSettings[group].map(s => ({ ...s, active: data.active }));
                                        } else {
                                            const allSelectorRef = newSettings[group].find(s => s.allSelector);

                                            allSelectorRef.active = newSettings[group]
                                                .filter(s => s !== allSelectorRef)
                                                .filter(s => s.active).length === newSettings[group].length - 1;
                                        }

                                        this.setState({invoiceSettings: newSettings});
                                        let { disabledTypes } = this.state
                                        //loop newSettings and update any changed notification settings
                                        newSettings[group].forEach(n_data => {
                                            let params = { setting_id: n_data.id,  sub_types: n_data.controls, users_id: userObject.usersId }
                                            //Only enable if currently disabled
                                            if (n_data.active && disabledTypes.invoicing.indexOf(String(n_data.id)) > -1) {
                                                DataHandler.post({url: `settings/company/${company}/notifications/enable`, ...params}).done(response => {
                                                    disabledTypes.invoicing.splice(String(n_data.id));
                                                });
                                            }
                                            //Only disable if currently enabled (removes duplicate disables from db 
                                            else if (!n_data.active && disabledTypes.invoicing.indexOf(String(n_data.id)) == -1) {
                                                DataHandler.post({url: `settings/company/${company}/notifications/disable`, ...params}).done(response => {
                                                    disabledTypes.invoicing.push(String(n_data.id));
                                                });
                                            }
                                        })
                                        this.setState({disabledTypes: disabledTypes});
                                    }
                                }}
                                noStateData
                                columns={[
                                    { width: 352, name: "name", header: definedNotifications[2].setting_groups[group].title, resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                    { width: 30, name: "active", header: tr("Notification"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                ]}
                                newRow={{
                                    name: "",
                                    companies_id: company
                                }}
                                data={settings}
                                />
                            </React.Fragment>
                        )
                    })
                }
                {_.map(projectSettings, (settings, group) => {
                    let listHeight = 450;
                    return (
                        <React.Fragment>
                            <List 
                            id={"projectSettings-group" + group}
                            ref={this.projectRefs[group]}
                            key={"projectSettings-list" + group}
                            className="notificationsList settingsList"
                            height={listHeight}
                            rowKey="id"
                            rowHeight={120}
                            fluid
                            listRowType={NotificationListRow}
                            emptyNewDataOnUpdate
                            rowProps={{
                                onUpdate: data => {
                                    const newSettings = cloneDeep(projectSettings);
                                    newSettings[group].find(s => s.id === data.id).active = data.active;

                                    this.setState({projectSettings: newSettings});
                                    let { disabledTypes } = this.state
                                    //loop newSettings and update any changed notification settings
                                    newSettings[group].forEach(n_data => {
                                        let params = { setting_id: n_data.id,  sub_types: n_data.controls, users_id: userObject.usersId }
                                        //Only enable if currently disabled
                                        if (n_data.active && disabledTypes.projects.indexOf(String(n_data.id)) > -1) {
                                            DataHandler.post({url: `settings/company/${company}/notifications/enable`, ...params}).done(response => {
                                                disabledTypes.projects.splice(String(n_data.id));
                                            });
                                        }
                                        //Only disable if currently enabled (removes duplicate disables from db 
                                        else if (!n_data.active && disabledTypes.invoicing.indexOf(String(n_data.id)) == -1) {
                                            DataHandler.post({url: `settings/company/${company}/notifications/disable`, ...params}).done(response => {
                                                disabledTypes.projects.push(String(n_data.id));
                                            });
                                        }
                                    })
                                    this.setState({disabledTypes: disabledTypes});
                                }
                            }}
                            noStateData
                            columns={[
                                { width: 352, name: "name", header: tr("Projects"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                { width: 30, name: "active", header: tr("Notification"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                            ]}
                            newRow={{
                                name: "",
                                companies_id: company
                            }}
                            data={settings}
                            />
                        </React.Fragment>
                        )
                    })
                }
                 {hasPoRights &&
                 _.map(purchaseorderSettings, (settings, group) => {
                    let listHeight = 450;
                    return (
                        <React.Fragment>
                            <List 
                            id={"purchaseorderSettings-group" + group}
                            ref={this.projectRefs[group]}
                            key={"purchaseorderSettings-list" + group}
                            className="notificationsList settingsList"
                            height={listHeight}
                            rowKey="id"
                            rowHeight={120}
                            fluid
                            listRowType={NotificationListRow}
                            emptyNewDataOnUpdate
                            rowProps={{
                                onUpdate: data => {
                                    const newSettings = cloneDeep(purchaseorderSettings);
                                    newSettings[group].find(s => s.id === data.id).active = data.active;

                                    this.setState({purchaseorderSettings: newSettings});
                                    let { disabledTypes } = this.state
                                    //loop newSettings and update any changed notification settings
                                    newSettings[group].forEach(n_data => {
                                        let params = { setting_id: n_data.id,  sub_types: n_data.controls, users_id: userObject.usersId }
                                        //Only enable if currently disabled
                                        if (n_data.active && disabledTypes.purchase_orders.indexOf(String(n_data.id)) > -1) {
                                            DataHandler.post({url: `settings/company/${company}/notifications/enable`, ...params}).done(response => {
                                                disabledTypes.purchase_orders.splice(String(n_data.id));
                                            });
                                        }
                                        //Only disable if currently enabled (removes duplicate disables from db 
                                        else if (!n_data.active && disabledTypes.purchase_orders.indexOf(String(n_data.id)) == -1) {
                                            DataHandler.post({url: `settings/company/${company}/notifications/disable`, ...params}).done(response => {
                                                disabledTypes.purchase_orders.push(String(n_data.id));
                                            });
                                        }
                                    })
                                    this.setState({disabledTypes: disabledTypes});
                                }
                            }}
                            noStateData
                            columns={[
                                { width: 352, name: "name", header: definedNotifications[4].setting_groups[group].title, resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                { width: 30, name: "active", header: tr("Notification"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                            ]}
                            newRow={{
                                name: "",
                                companies_id: company
                            }}
                            data={settings}
                            />
                        </React.Fragment>
                        )
                    })
                }

            </div>
        ) 
    }
}

export default withStyles(styles)(Notifications);
