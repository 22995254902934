import React, { Component } from 'react';
import InvoiceRowMileageAdditional from './InvoiceRowMileageAdditional';
import { CreateInvoiceRow, UpdateInvoiceRow } from './InvoiceRowsEdit';
import _ from 'lodash';
import { SettingsContext } from '../../../SettingsContext';
import OutlinedField from '../../../general/OutlinedField';
import FilterDropdown from '../../../general/FilterDropdown';
import TaimerComponent from "../../../TaimerComponent";
import { returnCurrencyList } from '../../../general/Currencies';

export default class InvoiceRowMileage extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
		mileage: [],
	}

	childIndex = 1;

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRowMileage");

		this.currencySymbol = returnCurrencyList().find(rc => rc.id == this.context.taimerAccount.currency).symbol;

		this.state = {
			valid: false,
			validation: this.validate(this.props.item),
		};

		this.childIndex = this.props.item.child.length;
	}

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	addChild = () => {
		var item = {
			...this.props.item,
		};

		let ma = _.values(this.props.mileageAdditional)[0];
		var extraData = { mileageAdditional: ma };

		var row = CreateInvoiceRow(this.childIndex++, 'mileage_additional', 0, extraData);

		item.child.push(row);

		this.props.onChange(item.invoiceRowIndex, item, "child");
	}

	validate = (row) => {
		var result = {
			valid: true,
		};

		if (isNaN(row.amount) || row.amount <= 0) {
			result.valid = false;
			result.amount = true;
		}

		return result;
	}

	onChange = (e) => {
		let { name, value } = e.target;

		if (e.target.name == 'amount') {
			value = e.target.value.replace(',', '.');
		}

		var item = {
			...this.props.item,
			[name]: value,
		};

		if (name === "subtype")
			item.unit_price = value.rate;

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}

	DeleteInvoiceRow = (row) => {
		var p = [...this.props.item.child];

		if (row.id < 0) {
			p.splice(p.indexOf(row), 1);
		} else {
			p[p.indexOf(row)] = { ...p[p.indexOf(row)], deleted: 1 }
		}

		const item = {
			...this.props.item,
			child: p,
		};

		this.props.onChange(item.invoiceRowIndex, item, "child");
	}

	ChangeInvoiceRow = (rowIndex, data, from) => {
		var p = [...this.props.item.child];

		var index = p.findIndex(x => x.invoiceRowIndex === rowIndex);

		var row = UpdateInvoiceRow(data);

		p[index] = row;

		const item = {
			...this.props.item,
			child: p,
		};

		this.props.onChange(item.invoiceRowIndex, item, "child");
	}

	render() {
		const { item, showValidationResult, disabled: propDisabled } = this.props;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment key={item.invoiceRowIndex}>
			<div className="third colspan">
				<div className="fullwidth">
					<FilterDropdown
						label={this.tr("Type")}
						name="subtype"
						items={this.props.mileage}
						onChange={this.onChange}
						value={item.subtype}
						error={validation.subtype}
						disabled={disabled} />
				</div>
			</div>

			<OutlinedField name="amount"
				label={taimerAccount.distanceUnit}
				value={item.amount}
				onChange={this.onChange}
				fullWidth
				error={validation.amount}
				disabled={disabled} />

			<OutlinedField name="unit_price"
				label={`${this.currencySymbol}/${taimerAccount.distanceUnit}`}
				value={item.unit_price}
				onChange={this.onChange}
				format="currency"
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				disabled={disabled} />

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				disabled={disabled} />

			<div className="invoiceRowDelete">
				{!disabled && <button className="addChildRow" onClick={this.addChild}>+</button>}
				{!disabled && <button className="delete" onClick={this.delete}>X</button>}
			</div>

			{item.child.filter(v => !v.deleted).map((v) => <InvoiceRowMileageAdditional
				key={v.invoiceRowIndex}
				item={v}
				mileageAdditional={this.props.mileageAdditional}
				onDelete={this.DeleteInvoiceRow}
				onChange={this.ChangeInvoiceRow}
				disabled={propDisabled} />)}
		</React.Fragment>)
	}
}