import React from 'react';
import Link from '../general/Link';

import TaimerComponent from "../TaimerComponent";

/* local components */
import { ReactComponent as PasswordRequestedImage } from './images/login_recover_password.svg';

class PasswordRequestedView extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "login/PasswordRequestedView");
    }; 
 
    changePage = (e, page) => {
        e.preventDefault();
        this.props.changePage(page);
    };

    render() {
        return (
            <React.Fragment>
                <div class="password-requested-page">
                    <PasswordRequestedImage className={"password-request-image"} />
                    <h1>{this.tr("Help is on the way")}</h1>
                    <p className={"password-request-text"}>
                        {this.tr("We're combing through our records to find the Taimer account for ")}
                        <br />
                        <b>{this.props.email}</b>.
                    </p>
                    <p className={"password-request-text"}>
                        {this.tr("If we find a match, you'll get an email with further instructions. If you don't hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder or contact ")}
                        <Link url="mailto: support@taimer.com">support@taimer.com</Link>.
                    </p>
                    <p className={"link-text"}>{this.tr("Go back to")} <Link onClick={(e) => this.changePage(e, "login")}>{this.tr("login page")}</Link></p>
                </div>
            </React.Fragment>
        );
    }
}

export default PasswordRequestedView;