import React, { Component } from 'react';
import _ from 'lodash';
import FilterDropdownServer from './FilterDropdownServer';
import { SettingsContext } from '../SettingsContext';

export default class FilterDropdownUser extends Component {
	static contextType = SettingsContext;

	render() {
		const { company, customer, parent, current } = this.props;
		const { userObject } = this.context;

		var params = {
			companies_id: company || userObject.companies_id,
			customers_id: customer,
			current
		};

		if (parent)
			params.parent = parent;

		if (customer === 0)
			params=false;

		return <FilterDropdownServer url="dialogs/users" params={params} {...this.props} />
	}
}

export class FilterDropdownActivityUser extends Component {
	render() {
		const { company, project, userid } = this.props;

		var params = {
			companies_id: company,
			project,
			userid: userid
		};

		return <FilterDropdownServer 
			url="dialogs/activityusers"
			params={params} {...this.props} />
	}
}