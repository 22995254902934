/* css */
import "./CPQList.css";

/* material-ui */
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { LocalOffer, Widgets, Book } from "@mui/icons-material";

/* others */
import React from 'react';
import List from '../List';
import FileSaver from 'file-saver';
import CPQListRow from '../rows/CPQListRow';
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import CPQListOverlay from '../overlays/CPQListOverlay';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import PageTopSection from "../../general/PageTopSection";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import _ from 'lodash';
import WithTabs from "../../navigation/WithTabs";
//import AdvancedSearch from '../../search/AdvancedSearch';

//Head class for all CPQ List related components
class CPQList extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        perpage: 30
    };

    constructor(props, context) {
        super(props, context, "list/lists/CPQList");
        this.checkedCPQs = [];
        this.state = {
            data: [],
            page: 1,
            pageCount: 1,
            groupscount: -1,
            cpqsCount: 0,
            perpage: this.props.perpage,
            totals: {
                value: 0,
                margin: 0,
                tracked: 0
            },
            openOneDelete: false,
            deleteData: [],
            DataDone: false,
            groupfilter: "0",
            searchTerms: [],
            addedRowsCount: 0,
            newParents: [],
            companies: [],
            company: context.functions.getCompany("products", "read", false, true),
            stickySearchInitialized: false,
        };

        this.filtersInitialValues = {
            company: context.functions.getCompany("products", "read", false, true),
            sortvalue: undefined,
            asc: undefined,
            page: 1,
            perpage: this.props.perpage
        };

        const columnConfigKey = "taimer_list_settings_for_cpq_list";
        let savedColumns = _.cloneDeep(JSON.parse(localStorage.getItem(columnConfigKey)));
        
        // Make sure that show_in_quote_print column goes to right place if column order is saved
        if (savedColumns) {
            const showQuoteColumnIndex = savedColumns.findIndex(s => s.name == "show_in_quote_print");

            if (showQuoteColumnIndex != 2) {
                savedColumns.splice(showQuoteColumnIndex, 1);
                savedColumns.splice(2, 0, { name: "show_in_quote_print", width: 50, visible: true });
                localStorage.setItem(columnConfigKey, JSON.stringify(savedColumns));
            }
        }

        this.searchTerms = undefined;
        this.sortTerms = undefined;
        this.groupsCount = -1;
        this.autoCompleteData = false;
        this.stickySearchKey = "CPQList";

        this.list = React.createRef();
        this.advancedSearch = React.createRef();
        this.updateRowData = this.updateRowData.bind(this);
        this.updateRowOrder = this.updateRowOrder.bind(this);
        this.getCPQData = this.getCPQData.bind(this);
        this.export = this.export.bind(this);
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.initializeStickySearch();
        this.listenReset();
        DataHandler.get({ url: `subjects/companies/products/read` }).done(companies => this.setState({ companies }));
    }

    componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.company !== this.state.company)
            this.getCPQData({}, true);
    }

    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                this.getCPQData();
                return;            
            }

            this.setState({ ...response }, this.getCPQData(response, false, false, true));
        }).fail(response => {
            this.getCPQData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(filters) {
        delete filters.newParents;

        if (this.filtersAreInInitialState()) return;
    
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: filters });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        const filters = {}

        for(let key in initial) {
            initial[key] = String(initial[key]);
            filters[key] = String(this.state[key]);
        }

        return _.isEqual(initial, filters);
    }

    _resetFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            this.resetCheckedRows();
            this.setState({
                ...this.filtersInitialValues, 
            }, this.getCPQData({}, true));
        }
    }

    listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
	}

    //Fetch CPQs data from backend
    getCPQData(override = {}, resetPage = false, onlyIds = false, stickySearch = false) {
        const { company } = this.state;
        let parameters = { page: this.state.page, perpage: this.state.perpage, sortvalue: this.state.sortvalue, asc: this.state.asc, newParents: this.state.newParents, company };

        //Overrides values if "override" is defined
        for (let oi in override)
            parameters[oi] = override[oi];

        if (onlyIds) {
            return DataHandler.get({ url: `cpq`, ...parameters, onlyIds: true });
        }

        if (!stickySearch) 
            this.saveStickySearch(parameters);

        DataHandler.get({ url: `cpq`, ...parameters }).done(response => {
            response.hasCPQs = response.has_cpqs > 0;
            this.setState(response);
            this.autoCompleteData = response;
            this.setState({ DataDone: true });

            if (resetPage && this.list.current)
                this.list.current.setPage(1);

            setTimeout(() => {
                try {
                    this.list.current.endPageChangeAnimation();
                } catch (e) { }
            });
        }).fail(response => { });
    }

    //Keeps track of new parents so they stay at top of the list
    newParentAdded = (id) => {//TAIM9-2085 -> koska listakompponentti muutunut (?) niin rivi lisäänty kahdesti. Korjattu järjestämällä data kyselyssä
        // let newParents = this.state.newParents;
        // newParents = [...newParents, String(id)];
        // this.setState({ newParents: newParents })
    }

    //Function for exporting
    export(target) {
        let parameters = { searchterms: this.state.searchTerms, page: this.state.page, perpage: this.state.perpage, sortvalue: this.state.sortvalue, asc: this.state.asc, checkedCPQs: this.checkedCPQs, groupfilter: this.state.groupfilter };
        DataHandler.getArrayBuffer({ url: `cpq/list_export`, ...parameters }).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `Productlist.${target}`);
        });
    }

    //Used to update list data without backend query
    updateRowData = () => {
        this.getCPQData();
    };

    //Updates childrens order inside parent
    updateRowOrder = (behind_id, moved_id, parent_id, order_id, position) => {
        DataHandler.post({ url: `cpq/order` }, { behind_id: behind_id, moved_id: moved_id, parent_id: parent_id, order_id: order_id, position, company: this.state.company }).done(response => {
            this.getCPQData();
        }).fail(response => { });
    };

    //Item child row
    addItemRow = (id) => {

        let data = {
            id: -1,
            type: 1,
            worktype: 3,
            name: "Item",
            description: "",
            quantity: 1,
            unit: "",
            vat: 0,
            unit_cost: 0,
            selling_price: 0,
            parentId: id,
            companies_id: this.state.company,
            hidden_for_print: 0
        };

        this.saveNewChild(data);
    };

    //Product child row
    addProductRow = (id) => {
        let data = {
            id: -1,
            type: 2,
            worktype: 4,
            name: "Product",
            //description: "",
            quantity: 1,
            unit: "",
            vat: 0,
            unit_cost: 0,
            selling_price: 0,
            parentId: id,
            rowType: "product",
            companies_id: this.state.company,
            hidden_for_print: 0
        };

        this.saveNewChild(data);
    };

    //Description child row
    addDescriptionRow = (id) => {
        let data = {
            id: -1,
            type: 3,
            worktype: 0,
            name: "Description",
            description: "",
            quantity: 0,
            unit: "",
            vat: 0,
            unit_cost: 0,
            selling_price: 0,
            parentId: id,
            companies_id: this.state.company,
            hidden_for_print: 0
        };

        this.saveNewChild(data);
    };

    //Saves new childs default data when created
    saveNewChild(data) {
        DataHandler.post({ url: `cpq/cpqnew` }, data).done(response => {
            this.setState({ invalids: [], freshRowId: response.id });


            if (this.state.data.id < 0)
                this.setData("id", response.id);

            this.getCPQData();
        }).fail(response => {
            if (response.status === 422) {
                this.setState({ invalids: Object.keys(response.responseJSON) });
            }
        });
    }

    handleClose = () => {
        this.setState({ anchorEl: null, anchorElDrive: null, anchorElLogout: null });
    };

    dialogDeleteOne = () => {
        const data = this.state.deleteData;
        DataHandler.delete({ url: `cpq/deletecpq` }, { id: data.id, company: this.state.company }).done(response => {
            this.getCPQData();
        }).fail(response => { });
        this.setState({ openOneDelete: false });
    }

    dialogDeleteMultiple = () => {
        DataHandler.post({ url: `cpq/deletecpqs` }, { cpqs: this.checkedCPQs, company: this.state.company }).done(response => {
            this.resetCheckedRows();
            this.getCPQData({}, true);
        }).fail(response => { });

        this.setState({ openMultipleDelete: false });
    }

    resetCheckedRows() {
        this.checkedCPQs = [];
        this.list.current.resetCheckedRows();
    }

    async getCheckedRows(multipleDelete = false) {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();
        let selected;

        if(allCheckedExcept) {
            selected = await this.getCPQData({}, false, true);
            selected = selected.ids.filter(s => {
                if (!allCheckedExcept[s.id])
                    this.checkedCPQs.push(s.id);
                return !allCheckedExcept[s.id]; 
            });
        } else {
            selected = this.list.current.getCheckedRows();
            this.checkedCPQs = selected;
        }

        if (multipleDelete && this.checkedCPQs.length > 0)
            this.setState({ openMultipleDelete: true });

        return selected;
    }

    dialogCancel = () => {
        this.checkedCPQs = [];
        this.setState({ openOneDelete: false, openMultipleDelete: false });
    }

    renderSummarySection = () => {
        const { company } = this.state;
        const { functions: { checkPrivilege }, userObject: { sidebarStyle } } = this.context;
        const writable = checkPrivilege("products", "write", company);
        return (
            <PageTopSection 
            summaries={[{ title: this.tr("CPQs"), value: this.state.cpqsCount || 0 }]}
            settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'products' }, true)
			}} mainButtons={[{
                title: this.tr("ADD CPQ"),
                action: () => { 
                    this.list.current.addNewRow(); 
                    this.setState({ hasCPQs: true }) 
                },
                isVisible: writable
            }]} settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
                href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'products' }),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'products' }, false)
			}} />
        );
    }

    selectTab = (e, module) => {
        if (!e.ctrlKey && !e.metaKey)
            e.preventDefault();
        else 
            return;

        this.context.functions.updateView(module);
    }

    showHidePrint = (data) => {
        DataHandler.post({ url: `cpq/cpqnew` }, data).done(response => {
            
        }).fail(response => {
            this.props.enqueueSnackbar(this.tr("Error in saving row!"), {
                variant: "error"
            });
        });
    }

    render() {
        if(!this.state.stickySearchInitialized) {
            return null;
        }

        const { company, companies } = this.state;
        const { functions: { checkPrivilege }, userObject: { sidebarStyle } } = this.context;

        if (!this.state.DataDone) {
            return null;
        }

        const writable = checkPrivilege("products", "write", company);

        const cpqfields = [
            { field: "expand", name: "expand", header: "", columnHeaderType: writable && "roundButton", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "show_in_quote_print", name: "show_in_quote_print", header: this.tr("Show in quote print"), width: 50, showResizeMarker: true, resizeable: false, sortable: false, showMenu: true, hideable: true, moveable: false },
            { field: "name", className: "namefield", name: "name", header: this.tr("Name"), width: 125, showMenu: true, resizeable: true },
            { field: "description", name: "description", header: this.tr("Description"), width: 125, showMenu: true, resizeable: true },
            { field: "quantity", name: "quantity", header: "", width: 50, showMenu: false, hideable: false, showResizeMarker: false },
            { field: "unit", name: "unit", header: "", width: 50, showMenu: false, hideable: false, showResizeMarker: false },
            { field: "unit_cost", name: "unit_cost", header: this.tr("Cost Total"), width: 125 },
            { field: "selling_price", name: "selling_price", header: this.tr("Selling Price Total"), width: 125, showMenu: true, resizeable: true },
            { field: "vat", name: "vat", header: "", width: 50, showMenu: false, hideable: false, showResizeMarker: false },
            { field: "sum", name: "sum", header: this.tr("Sum 0%"), width: 125, showMenu: true, resizeable: true },
            { field: "margin", name: "margin", header: this.tr("Margin 0%"), width: 125, showMenu: true, resizeable: true },
            { field: "worktype", name: "worktype", header: "", width: 70, showResizeMarker: false, showMenu: false, hideable: false },
        ];

        //Vanhemmille
        const newRowParents = {
            type: 0,
            name: "",
            description: "",
            parentId: 0,
            companies_id: company,
        };

        const deleteType = this.state.deleteData && Number(this.state.deleteData.type) === 0 ? "CPQ" : "row";
        const deleteInfo = deleteType === "CPQ" ? this.state.deleteData.name : this.state.deleteData.description;
        
        const overlayAddCPQ = () => { this.setState({ hasCPQs: true }, () => { this.list.current.addNewRow() }) };
        return (
            <div className="contentBlock" id="CPQList">
                <div className="listControlsContainer clearfix">
                    <div className="primary header-container">
                        {companies.length > 1 && 
                            <OutlinedField className="listFilterOutlinedField" label={this.tr("Company")} value={company} select onChange={e => {
                                this.resetCheckedRows(); 
                                this.setState({ company: e.target.value, page: 1 });
                                this.context.functions.setLastCompany(e.target.value);
                            }}>
                                {companies.map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                            </OutlinedField>
                        }
                        <OutlinedField select label={this.tr("Options")} className="listFilterOutlinedField" shrinkLabel={false}>
                            {
                                writable ?
                                    <MenuItem onClick={() => this.getCheckedRows(true)} >
                                        {this.tr("Delete")}
                                    </MenuItem> :
                                    <MenuItem disabled >
                                        {this.tr("Delete")}
                                    </MenuItem>
                            }
                        </OutlinedField>
                        {!this.filtersAreInInitialState() && (
                            <span onClick={() => this._resetFilters()} className="clear-search-button">{this.tr("Clear filters")}</span>
                        )}
                    </div>
                    {this.renderSummarySection()}
                </div>
                <div>

                    {(this.state.openOneDelete || this.state.openMultipleDelete) &&
                        <MassDialog
                            onDialogClose={this.dialogCancel}
                            onDialogSave={this.state.openMultipleDelete ? this.dialogDeleteMultiple : this.dialogDeleteOne}
                            dialogType={"delete"}
                            dialogProps={{
                                wider: true,
                                onCloseClick: this.dialogCancel,
                                open: this.state.openOneDelete || this.state.openMultipleDelete,
                                close: this.dialogCancel,
                                header: this.tr(`Delete ${this.state.openMultipleDelete ? "CPQs" : deleteType}`) + "?",
                                warning: () => {
                                    return (<p id="product-delete-dialog-warning">
                                        {!this.state.openMultipleDelete ?
                                            this.tr(`Are you sure you want to delete ${deleteType}`) + ": " + deleteInfo + "?" :
                                            this.tr(`Are you sure you want to delete`) + " " + this.checkedCPQs.length + " " + this.tr("CPQs") + "?"}
                                    </p>);
                                },
                                onConfirm: () => {
                                    this.state.openMultipleDelete ? this.dialogDeleteMultiple() : this.dialogDeleteOne();
                                }

                            }}
                        />
                    }

                    <div className="grid">
                        <div className="grid-container">
                            <List
                                ref={this.list}
                                data={this.state.cpqs}
                                columns={cpqfields}
                                sharedData={this.autoCompleteData}
                                height="fitRemaining"
                                trimHeight={-10}
                                className="cpqList"
                                ignoreRowPropsChange={false}
                                useAllCheckedExcept={true}
                                noStateData={true}
                                listRowType={
                                    CPQListRow
                                }
                                // showNoResultsMessage={true}
                                showOverlay={!this.state.hasCPQs}
                                overlayComponent={CPQListOverlay}
                                treeData={true}
                                overlayProps={{
                                    addCPQ: overlayAddCPQ
                                }
                                }
                                newRow={newRowParents}
                                rowProps={{
                                    rename: this.rename,
                                    addItemRow: this.addItemRow,
                                    getCPQData: this.getCPQData,
                                    addProductRow: this.addProductRow,
                                    newParentAdded: this.newParentAdded,
                                    updateRowOrder: this.updateRowOrder,
                                    addDescriptionRow: this.addDescriptionRow,
                                    showHidePrint: this.showHidePrint,
                                    newParents: this.state.newParents,
                                    write: writable,
                                    onCreate: (data) => {

                                    },
                                    onUpdate: (data) => {

                                    },
                                    onDelete: (data) => {
                                        if (data.id < 0) {
                                            this.list.current.removeNewRow(data.id);
                                        } else {
                                            this.setState({ openOneDelete: true, deleteData: data });
                                        }
                                    },
                                    freshId: this.state.freshRowId || null
                                }}
                                saveColumnConfig={true}
                                userListSettingsKey="cpq_list"
                                showPageSelector={true}
                                pageCount={this.state.pageCount}
                                totalCount={this.state.cpqsCount}
                                perpage={this.state.perpage}
                                page={this.state.page}
                                controlPage={true}
                                onPerPageChange={perpage => {
                                    this.list.current.startPageChangeAnimation();
                                    this.setState({ perpage: perpage, page: 1, newParents: [] });
                                    this.getCPQData({ perpage: perpage, page: 1, newParents: [] }, true);
                                }}
                                onPageChange={page => {
                                    this.list.current.startPageChangeAnimation();
                                    this.setState({ page: page, newParents: [] })
                                    this.getCPQData({ page: page, newParents: [] });
                                }}
                                onSortRows={(colName, asc) => {
                                    this.setState({ sortvalue: colName, asc: asc, page: 1, newParents: [] });
                                    this.getCPQData({ sortvalue: colName, asc: asc, page: 1, newParents: [] }, true);
                                }}
                                useHSRightPadding
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CPQList;
