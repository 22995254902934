import React from 'react';
import Button from '@mui/material/Button';

import DataHandler from "./DataHandler";
import FileSaver from 'file-saver';
import { exportContext } from '../Taimer.js';
import InvoiceTranslations from './backendTranslations/InvoiceTranslations';
import moment from 'moment';
import _ from 'lodash';

let InvoiceApi = {
    finvoice: (idObject) => {
        var nWin = window.open(`index.php?module=invoices&action=print_invoice&select=${idObject.ids}&lang=finvoice`,'_blank');
        nWin.onload = function() {
            nWin.document.title = `Invoice ${idObject.bill_ids}`;
        }
    },
    finvoice2: (idObject) => {
        DataHandler.get({url: `invoices/print_finvoice`, ids: idObject.ids}).done((response) => {
            var blob = new Blob([response.documentElement.outerHTML], {
                type: 'application/xml'
            });

            FileSaver.saveAs(blob, 'finvoice.xml');
        });
    },
    finvoice3: (idObject) => {
        DataHandler.getArrayBuffer({url: `invoices/print_finvoice3`, ids: idObject.ids}).done((response) => {
            var blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });

            FileSaver.saveAs(blob, 'finvoice3.xml');
        });
    },
    maventa: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;
        
        const chunks = _.chunk(idObject.ids, 3);
        const idCount = idObject.ids.length;
        let sent = 0;

        setSendingStatus(true);

        for (const chunk of chunks) {
            DataHandler.put({url: `invoices/maventa`}, {ids: chunk}).done(resp => {
                if (!resp)
                    return;
                
                resp.sent.length && resp.sent.forEach(e => enqueueSnackbar(tr('invoice nr. ${bill_id} sent to Maventa', { bill_id: e.bill_id }), { variant: "success" }));
                resp.failed.length && resp.failed.forEach(e => enqueueSnackbar(tr('invoice nr. ${bill_id} failed to send to Maventa', { bill_id: e.bill_id }), { variant: "error" }));
            
                sent += chunk.length;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar(tr('${idCount1} / ${idCount2} invoices exported.', { idCount1: idCount, idCount2: idCount }), {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                        variant: "success",
                    });
                }
            }).fail(() => {
                setSendingStatus(false);
                
                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                });
            });
        }
    },
    efina: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater, company, tr, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext(); 
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        let analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        let failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        if(idObject && idObject.ids.length > 0) stateUpdater({loading: true});

        DataHandler.put({url: `invoices/efina`}, {ids: idObject.ids}).done(resp => {

            stateUpdater({loading: false});

            if (!resp)
                return;

            if(resp.sent.length) {
                resp.sent.forEach((e) => {
                    analyticsData.invoices_sent++;
                    enqueueSnackbar(`invoice nr. ${e.bill_id} sent to Efina`, { variant: "success" });
                });
                context.functions.sendAnalytics("invoice_sent", analyticsData);  
            } 


            if (resp.failed.length) {
                resp.failed.forEach((e) => {
                    failedAnalyticsData.invoices_sent++;
                    enqueueSnackbar(`invoice nr. ${e.bill_id} failed to send to Efina`, { variant: "error" });
                    setTimeout(() => {
                        context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                    }, 600);
                });
            }
            resp.authfailed && enqueueSnackbar(`Connection to eFina has failed`, { variant: "error" });
            
            updateData();

        }).fail( (e) => {
            enqueueSnackbar("Failed to export invoices", {  variant: "error" });
            context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
        });
    },
    talenom: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater, company, tr, analyticsKey) => {
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        let analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }

        let failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }

        if(idObject && idObject.ids.length > 0) stateUpdater({loading: true});

        let talenomResponses = {
            "success": [
                tr("invoice nr"),
                tr("sent to Talenom")
            ],
            "error": {
                "default": tr("error in invoice nr"),
                "unknown": tr("unknown error"),
                "errorlogin": tr("check your talenom login credentials"),
                "errorinvoicenumber": tr("the invoice number is already in talenom"),
                "invalidinvoicedate": tr("the invoice date is in a locked period"),
                "invalidstreetaddress": tr("the street_address is invalid"),
                "invalindcountry": tr("the country name is invalid (e.g. fi)"),
                "notalenomid": tr("talenom id is not defined"),
                "wrongvatrate": tr("account number has been connected to wrong vat rate"),
                "postalcode": tr("postal code value is invalid")
            }
        };

        DataHandler.put({url: `invoices/talenom`}, {ids: idObject.ids}).done(resp => {

            stateUpdater({loading: false});

            if (!resp)
                return;

            resp.forEach((response) => {

                if(response.success) {
                    analyticsData.invoices_sent++;
                    enqueueSnackbar(`${talenomResponses.success[0]} ${response.bill_id} ${talenomResponses.success[1]}`, { variant: "success" })
                } else {
                    let errorMsg = talenomResponses.error[response.msg];
                    if (response.msg == "unknown" && response.status) {
                        const parser = new DOMParser();
                        const dom    = parser.parseFromString(response.status, "application/xml");
                        if (dom.children && dom.children[0] && dom.children[0].textContent) {
                            const err    = dom.children[0].textContent;
                            const errDom = parser.parseFromString(err, "application/xml");
                            const errElem = errDom.getElementsByTagName("invoice");
                            errorMsg      = errElem && errElem.length > 0 ? errElem[0].getAttribute("returnmessage") : talenomResponses.error[response.msg];
                        }
                    }
                    enqueueSnackbar(`${talenomResponses.error['default']} ${response.bill_id}: ${errorMsg}`, { variant: "error", autoHideDuration: 10000 })
                    failedAnalyticsData.invoices_sent++;
                }

            })
            if (analyticsData.invoices_sent > 0) {
                context.functions.sendAnalytics("invoice_sent", analyticsData);
            }
            if (failedAnalyticsData.invoices_sent > 0) {
                setTimeout(() => {
                    context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                }, 600);
            }

            updateData();

        }).fail((e) =>{
            enqueueSnackbar("Failed to export invoices", {  variant: "error" });
            stateUpdater({loading: false});
        });
    },
    quickbooks: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);

        DataHandler.post({url: `quickbooks/export/invoices`}, {ids :idObject.ids}).done(response =>{
            setSendingStatus(false);

            if(response.success > 0 && response.error == 0){
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                ;
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });
            } else if (response.success == 0 && response.error > 0) {
                context.functions.sendAnalytics("invoice_sent", analyticsData);    
                enqueueSnackbar("Failed to export invoices", {
                    variant: "error",
                });
            } else if (response.success > 0 && response.error > 0) {
                
                analyticsData.invoices_sent = response.success;
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                setTimeout(() => {
                    analyticsData.invoices_sent = response.error;
                    analyticsData.success = false;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar("Failed to export some invoices", {
                    variant: "error",
                });
            }
            updateData();
        }).fail(() => {
            setSendingStatus(false);    
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });
        });
    },
    procountor: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const idCount = ids.length;
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss'); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        let failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        const chunks = _.chunk(ids, 3);

        let sent = 0;

        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);

        for (const chunk of chunks) {
            const response = await DataHandler.put({url: `invoices/procountor`}, {company: company, ids: chunk, translations: transl});
            if(response.invalid.length == 0){
                sent += chunk.length;
                analyticsData.invoices_sent++;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar("Invoices exported successfully", {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(sent + " / " + idCount + " invoices exported successfully. Exporting now more invoices...", {
                        variant: "success",
                    });
                }  
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    failedAnalyticsData.invoices_sent++;
                    return el.bill_id + ": " + el.errors.map(c => c.message).join(", ") + "\n";
                });
                let errorMsg = errors.join("\n");

                setSendingStatus(false);

                enqueueSnackbar("Failed to export some invoices - \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });

                updateData();

                break;
            } else {
                setSendingStatus(false);
                failedAnalyticsData.invoices_sent += chunk.length;
                enqueueSnackbar("Failed to export invoices", {
                    variant: "error",
                });
                
                updateData();

                break;
            }
        }
        if (analyticsData.invoices_sent > 0) {
            context.functions.sendAnalytics("invoice_sent", analyticsData);          
        }
        if (failedAnalyticsData.invoices_sent > 0) {
            setTimeout(() => {
                context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
            }, 600);
        }
        updateData();
    },
    wintime: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext();
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);
    
        DataHandler.put({url: `invoices/wintime`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);
            if(response.status && response.status == "success"){
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else {
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar("Failed to export invoices", {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });
        });
    },
    wintime_demo: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;

        DataHandler.put({url: `invoices/wintime_demo`}, {company: company, ids: ids}).done(response =>{
            var blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'wintime_demo.txt');
        }).fail(() => {
            enqueueSnackbar("Failed to generate file", {
                variant: "error",
            });
        });
    },
    netvisor: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss')
        const ids = idObject.ids;
        const idCount = ids.length;
        const chunks = _.chunk(ids, 3);
        let sent = 0;

        setSendingStatus(true);
        let analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        let failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        for (const chunk of chunks) {
            const response = await DataHandler.put({url: `invoices/netvisor`}, {company: company, ids: chunk});
            if(response.success == 1) {
                sent += chunk.length;
                analyticsData.invoices_sent++;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar("Invoices exported successfully", {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(sent + " / " + idCount + " invoices exported successfully. Exporting now more invoices...", {
                        variant: "success",
                    });
                }  
            } else {
                setSendingStatus(false);

                var parser = new DOMParser();
                if (response.invalid.customers) {
                    let customerErrors = response.invalid.customers.map(el => {
                        let dom = parser.parseFromString(el.response, "application/xml");
                        var errorMessage = dom.children[0].textContent.substring(0, dom.children[0].textContent.length - 25);
                        return errorMessage;
                    });

                    enqueueSnackbar(customerErrors, {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                }
                if (response.invalid.bills) {
                    let billErrors = response.invalid.bills.map(el => {
                        failedAnalyticsData.invoices_sent++;
                        if (el.response == "country_not_found") {
                            return tr("Error in invoice ${invoice_nr}", {invoice_nr: el.bill_id}) + ": " + tr("Country not found");
                        }
                        let dom = parser.parseFromString(el.response, "application/xml");
                        var errorMessage = dom.children[0].textContent.substring(0, dom.children[0].textContent.length - 25);
                        return errorMessage;
                    });

                    enqueueSnackbar(billErrors, {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                }

                updateData();

                break;
            }
            if (analyticsData.invoices_sent > 0) {    
                context.functions.sendAnalytics("invoice_sent", analyticsData);
            }
            if (failedAnalyticsData.invoices_sent > 0) {
                setTimeout(() => {
                    context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                }, 600);
            }

        }
    },
    ropocapital: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/ropocapital`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                analyticsData.invoices_sent = response.invalid.length;
                let errors = response.invalid.map(el => {
                    return el.error + "\n";
                });
                let errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar("Failed to export some invoices: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            setSendingStatus(false);    
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });
        });
    },
    emce: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.put({url: `invoices/emce`}, {company: company, ids: ids}).done(response =>{
            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            var blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'emce.txt');
        }).fail(() => {
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to generate file", {
                variant: "error",
            });
        });
    },
    emce_invoice: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;

        DataHandler.getArrayBuffer({url: `invoices/emce_invoice`, company: company, ids: ids}).done(response =>{
            var blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'rsklasku.xml');
        }).fail(() => {
            enqueueSnackbar("Failed to generate file", {
                variant: "error",
            });
        });

        DataHandler.getArrayBuffer({url: `invoices/emce_address`, company: company, ids: ids}).done(response =>{
            var blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'osoite.xml');
        }).fail(() => {
            enqueueSnackbar("Failed to generate file", {
                variant: "error",
            });
        });
    },
    fennoa: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/fennoa`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.error + "\n";
                });
                let errorMsg = errors.join("\n");
                
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar("Failed to export some invoices: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });

            updateData();
        });
    },
    meritaktiva: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);
            
        DataHandler.put({url: `invoices/meritaktiva`}, {company: company, ids: ids, translations: transl}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    const error = el.translate == 1 ? tr(el.error) : el.error;
                    return el.bill_id + ": " + error + "\n";
                });
                let errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            context.functions.sendAnalytics("invoice_sent", analyticsData);      
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });

            updateData();
        });
    },
    xero: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/xero`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.error + "\n";
                });
                let errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {          
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar("Failed to export some invoices: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            context.functions.sendAnalytics("invoice_sent", analyticsData);

            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });

            updateData();
        });
    },
    fivaldi: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
         
        const context = exportContext();    
        let analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        DataHandler.put({url: `invoices/fivaldi`}, {company: company, ids: idObject.ids}).done(response =>{
            if(response.success == 1){
                analyticsData.invoices_sent = idObject.ids.length;
                analyticsData.success = true;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else {
                /*let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.error.constraintViolations.map(c => c.errorCode).join(", ") + "\n";
                });*/
                let errorMsg = tr("Failed to send invoices");
                analyticsData.invoices_sent = idObject.ids.length;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar(errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            analyticsData.invoices_sent = idObject.ids.length;
            analyticsData.success = false;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });
        });
    },
    fivaldi_file: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.getArrayBuffer({url: `invoices/fivaldi_file`, company: company, ids: ids}).done(response =>{
            var blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'fivaldi.xml');

            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();
        }).fail(() => {
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to generate file", {
                variant: "error",
            });
        });
    },
    nav: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext();
        const ids = idObject.ids;

        setSendingStatus(true);
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        DataHandler.put({url: `invoices/nav`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);

            /*if(response.status && response.status == "success"){
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else {
                enqueueSnackbar("Failed to export invoices", {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }*/

            /*var blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            FileSaver.saveAs(blob, 'nav.xlsx');*/

            var el = document.createElement("a");
            el.setAttribute("href", " data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
            el.setAttribute("download", 'nav.xlsx');
            el.style.display = "none";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });
        });
    },
    navDebug: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext();
        const ids = idObject.ids;

        DataHandler.put({url: `invoices/nav_debug`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);

            var el = document.createElement("a");
            el.setAttribute("href", " data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
            el.setAttribute("download", 'nav_debug.xlsx');
            el.style.display = "none";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
          
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            enqueueSnackbar("Failed to get debug data", {
                variant: "error",
            });
        });
    },
    fortnox: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        let failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);
            
        DataHandler.put({url: `invoices/fortnox`}, {company: company, ids: ids, translations: transl}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.invoices_sent = idObject.ids.length;
                context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.message + "\n";
                });
                let errorMsg = errors.join("\n");
                failedAnalyticsData.invoices_sent = response.invalid.length;
                setTimeout(() => {
                    context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                }, 600);
                enqueueSnackbar("Failed to export some invoices: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);

            failedAnalyticsData.invoices_sent = idObject.ids.length;
            context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
            
            enqueueSnackbar("Failed to export invoices", {
                variant: "error",
            });

            updateData();
        });
    },
    s3: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if (idObject.ids == "")
            return;

        setSendingStatus(true);

        DataHandler.put({ url: `invoices/s3` }, { ids: idObject.ids, companies_id: company, target: idObject.target }).done(resp => {
            if (!resp)
                return;

            setSendingStatus(false);

            if (resp.already_sent_bills?.length > 0) {
                const errorText = tr('${amount} invoices could not be sent', { amount: resp.already_sent_bills.length }) + ": " + tr('Invoices have already been sent');
                enqueueSnackbar(errorText, {
                    variant: "error",
                });
            }
            if (resp.sent_bills?.length > 0) {
                enqueueSnackbar(tr('${amount} invoices sent.', { amount: resp.sent_bills.length }), {
                    variant: "success",
                });
                updateData();
            }
        }).fail(() => {
            setSendingStatus(false);

            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    heeros: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const idCount = ids.length;

        const context = exportContext(); 
        let analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        let sent = 0;
        setSendingStatus(true);

        for (const id of ids) {
            const response = await DataHandler.put({url: `invoices/heeros`}, {company: company, ids: [id]});
            
            if(response.invalid.length == 0){
                sent++;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar(sent + " invoices exported successfully", {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(sent + " / " + idCount + " invoices exported successfully. Exporting next invoice...", {
                        variant: "success",
                    });
                }            
            } else if (response.invalid.length > 0) {
                let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.message + "\n";
                });
                let errorMsg = errors.join("\n");

                setSendingStatus(false);
                
                enqueueSnackbar("Failed to export invoice: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });

                updateData();

                break;
            } else {
                setSendingStatus(false);
            
                enqueueSnackbar("Failed to send invoices", {
                    variant: "error",
                });

                updateData();

                break;
            }
        }
    },
};

export default InvoiceApi;
