import React from 'react';
import ListCell from "./../ListCell";

class CheckboxCell extends React.Component {
	constructor(props) {
		super(props);

		this.isChecked 	 = this.isChecked.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}


	isChecked() {
		return this.props.checked;
	}


	handleClick() {
		this.props.onClick();

		this.props.onEdited(!this.props.checked);
	}


	render() {
		let className = this.props.checked ? "checkmarkContainer checked" : "checkmarkContainer";
		className     = this.props.className ? `${className} ${this.props.className}` : className;

		return (
			<ListCell
				width={this.props.width}
				permanentEditMode={true}
                {...this.props.listCellProps}>
				<div className="checkbox">
					<div onClick={this.handleClick} className={className}></div>
				</div>
			</ListCell>
		);
	}
}

CheckboxCell.defaultProps = {
	width: 100,
	checked: false,
	onClick: () => {},
	onEdited: () => {},
    listCellProps: {}
};

export default CheckboxCell;
