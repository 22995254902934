import React, { Component } from 'react';
import UserList from '../general/UserList';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactComponent as LeaveGroup } from './icons/leavegroup.svg';
import { ReactComponent as Popup } from './icons/popup.svg';
import ContextMenu from './../general/ContextMenu';
import { SettingsContext } from '../SettingsContext';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import DataHandler from './../general/DataHandler';
import _ from 'lodash';
import OutlinedField from '../general/OutlinedField';
import ToggleableLabelField from '../general/ToggleableLabelField';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import ConfirmationDialog from "./../settings/dialogs/ConfirmationDialog";

export default class TabHeader extends Component {
    static contextType = SettingsContext;

    static defaultProps = {
        allowRename: false,
        onRefresh: () => {},
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            loaded: false,
            groupsMembers: [],
            addableMembers: [],
            editable: false,
            canAdd: true,
            owned: false,
            editingName: false,
            editedName: '',
            currentDialog: false,
            dialogData: {},
        };

        this.refUserList = React.createRef();
        this.refNameEdit = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog,
        };
    }

    openDialog = (name, callback, data = {}) => {
        this.setState({ currentDialog: name, dialogData: {
            saveFunc: () => callback(),
            ...data,
        } });
    };

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    };

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    componentDidMount() {
        const { showMemberSelect } = this.props;
        showMemberSelect && this.refreshMembers();
    }

    componentDidUpdate(prevProps, prevState) {
        const { showMemberSelect, type, id } = this.props;

        if (showMemberSelect && (prevProps.type !== type || prevProps.id !== id)) {
            if (this.state.editingName)
                this.setState({editingName: false});

            this.refreshMembers();
        }
    }

    addMember = async (member) => {
        const { type, id, enqueueSnackbar } = this.props;

        const addableMembers = this.state.addableMembers.filter(x => x.id != member.id);
        const groupsMembers = [...this.state.groupsMembers, member];

        try {
            await DataHandler.post({ url: `teamchat/${type}/${id}/members` }, { 'user': member.id });

            this.setState({
                addableMembers, groupsMembers
            });
        } catch (error) {
            enqueueSnackbar("Failed to add member.", {
                variant: 'error'
            });
        }
    }

    removeMember = async (member) => {
        const { type, id, enqueueSnackbar } = this.props;
        
        const groupsMembers = this.state.groupsMembers.filter(x => x.id != member.id);
        const addableMembers = [...this.state.addableMembers, member];

        try {
            await DataHandler.delete({ url: `teamchat/${type}/${id}/members/${member.id}` });

            this.setState({
                addableMembers, groupsMembers
            });
        } catch (error) {
            enqueueSnackbar("Failed to remove member.", {
                variant: 'error'
            });
        }
    }

    refreshMembers = async (params = {}) => {
        const { type, id } = this.props;

        if (this.props.isNew) {
            params.refresh = 1;
        }

        this.setState({
            loaded: false,
            groupsMembers: [],
            addableMembers: [],
            editable: false,
            canAdd: true,
            owned: false,
        });

        if (!type || !(id > 0))
            return;

        const members = await DataHandler.get({ url: `teamchat/${type}/${id}/members`, ...params });

        const membersIn = _.filter(members.users, u => u.has_invite > 0);
        const membersOut = _.filter(members.users, u => u.has_invite <= 0);

        this.setState({
            loaded: true,
            groupsMembers: membersIn,
            addableMembers: membersOut,
            editable: members.editable,
            canAdd: members.canAdd,
            owned: members.is_own,
        });
    }

    deleteGroupClick = () => {
        this.openDialog("confirmation", () => { 
            console.log("a")
            this.deleteGroup(); 
        }, {
            text: this.props.tr("Delete current group?"),
        })
    }

    deleteGroup = async () => {
        const { type, id } = this.props;

        const data = await DataHandler.delete({ url: `teamchat/${type}/${id}` });
        if (data.status)
        {
            this.props.onLeaveGroup && this.props.onLeaveGroup();
        }
    }

    leaveGroup = async () => {
        const { type, id } = this.props;

        const data = await DataHandler.post({ url: `teamchat/${type}/${id}/leave` });

        if (data.success)
        {
            this.props.onLeaveGroup && this.props.onLeaveGroup();
        }
    }

    clickGroupName = () => {
        const { title } = this.props;
        this.setState({editingName: true, editedName: title});
    }

    clickSave = async () => {
        const { type, id, onRefresh, enqueueSnackbar } = this.props;
        const { editedName } = this.state;

        if (!editedName) {
            enqueueSnackbar("Name must not be empty.", {
                variant: 'error'
            });
            return;
        }

        const data = await DataHandler.patch({ url: `teamchat/${type}/${id}` }, {
            name: editedName,
        });

        onRefresh();

        this.setState({editingName: false});
    }

    clickCancel = () => {
        this.setState({editingName: false});
    }

    nameEdited = (e) => {
        this.setState({editedName: e.target.value});
    }

    render() {
        const { inPopup, openPopup, onClose, tr, classes, showMemberSelect, title, type, subtitle, allowRename, extraMenuItems, linkTarget } = this.props;
        const { loaded, groupsMembers, addableMembers, owned, editable, canAdd, editedName } = this.state;
        const { urlify, updateView } = this.context.functions;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (<div className="TeamChatGridTop TeamChatGridInfo">
            <div>
                {
                    loaded && showMemberSelect && <UserList
                        ref={this.refUserList}
                        editable={editable}
                        canAdd={canAdd}
                        users={groupsMembers}
                        addableUsers={addableMembers}
                        onAdd={this.addMember}
                        onRemove={this.removeMember} />
                }
            </div>
            <div className="TeamChatGridInfoName">
                {!this.state.editingName && <span className={cn("groupName", allowRename && "groupNameEditable")} onClick={allowRename && this.clickGroupName}>
                    {linkTarget ? (<a href={urlify(linkTarget)} onMouseUp={(evt) => evt.button === 0 && updateView(linkTarget)}>{title}</a>) : title}

                </span>}
                {this.state.editingName && <div className="groupName">
                    <div className="editContainer">
                        <ToggleableLabelField ref={this.refNameEdit} value={editedName} autoFocus onChange={this.nameEdited} useOnChange /> 
                        <CheckIcon className="saveIcon" onClick={this.clickSave} />
                        <CloseIcon className="cancelIcon" onClick={this.clickCancel} />
                    </div>
                </div>}
                {subtitle && <span className="groupSubtitle">
                    {subtitle}
                </span>}
            </div>
            <div>
                <ContextMenu classes={{ button: classes.button }} label={<MoreIcon />} noExpandIcon>
                    {!inPopup && <MenuItem classes={{ root: classes.item }} onClick={openPopup}>
                        <ListItemIcon classes={{ root: classes.icon }}>
                            <Popup className="contextSvg" />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={tr("Open Popup")} />
                    </MenuItem>}
                    {inPopup && <MenuItem classes={{ root: classes.item }} onClick={onClose}>
                        <ListItemIcon classes={{ root: classes.icon }}>
                            <Popup className="contextSvg" />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={tr("Close Popup")} />
                    </MenuItem>}
                    {allowRename && <MenuItem classes={{ root: classes.item }} onClick={this.clickGroupName}>
                        <ListItemIcon classes={{ root: classes.icon }}>
                            <EditIcon className="contextSvg" />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={tr("Rename Group")} />
                    </MenuItem>}
                    {extraMenuItems}
                    {type === "groups" && !owned &&
                        <MenuItem classes={{ root: classes.item }} onClick={this.leaveGroup}>
                            <ListItemIcon classes={{ root: classes.icon }}>
                                <LeaveGroup className="contextSvg" />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary={tr("Leave Group")} />
                        </MenuItem>}
                    {allowRename && <MenuItem classes={{ root: classes.item }} className="delete" onClick={this.deleteGroupClick}>
                        <ListItemIcon classes={{ root: classes.icon }}>
                            <RemoveIcon className="contextSvg" />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} inset primary={tr("Delete Group")} />
                    </MenuItem>}
                </ContextMenu>
            </div>
            {Dialog && (
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}
                    />
                )}
        </div>);
    }
}