import React, { useContext, useState } from 'react';
import { SettingsContext } from '../SettingsContext';
import { KeyboardArrowDown, KeyboardArrowRight, OpenInNew } from '@mui/icons-material';
import Link from '../general/Link';
import TabInfoButton from '../general/TabInfoButton';
import './DropdownTabs.css';

const DropdownItem = (props) => {
    const { id, name, items, label, icon, newTab, index, subDropdownIndex, setSubDropdownIndex, selectedSubTabChild, hideDropdown, onClick, active, checkItemPrivilege, url, generateUrlForItem } =
        props;
    const context = useContext(SettingsContext);

    const [subDropdownVisible, setSubDropdownVisible] = useState(false);
    let hideDropdownTimer;

    // hide dropdown quickly if hovering over another tab
    if (subDropdownVisible && subDropdownIndex != index) {
        setSubDropdownVisible(false);
    }

    const showSubDropdown = () => {
        cancelHidingSubDropdown();
        setSubDropdownIndex && setSubDropdownIndex(index);
        setSubDropdownVisible(true);
    };

    const hideSubDropdown = () => {
        hideDropdownTimer = setTimeout(() => {
            setSubDropdownVisible(false);
        }, 200);
    };

    const cancelHidingSubDropdown = () => {
        hideDropdownTimer && clearTimeout(hideDropdownTimer);
        hideDropdownTimer = null;
    };

    const filteredItems = (items || []).filter((item) => !isItemHidden(item, checkItemPrivilege));
    if (items && filteredItems.length == 0) return null;

    const content = (
        <>
            {icon && React.cloneElement(icon, { className: `${icon.props.className} icon` })}
            <h3>{label || name}</h3>
            {items && items.length > 0 && <KeyboardArrowRight className="indicator" />}
            {newTab && <OpenInNew className="indicator" />}
        </>
    );

    return (
        <div data-testid={`${props.id}-dropdown-item`} className="tabs-dropdown-item" onMouseEnter={showSubDropdown} onMouseLeave={hideSubDropdown}>
            {url ? (
                <Link
                    noColor
                    url={url}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    <div className={`dropdown-item-content ${active && 'active'}`}>{content}</div>
                </Link>
            ) : (
                <div onClick={() => onClick()} className={`dropdown-item-content ${active && 'active'}`}>
                    {content}
                </div>
            )}
            {subDropdownVisible && filteredItems && filteredItems.length > 0 && (
                <div onMouseEnter={cancelHidingSubDropdown} className="dropdown-container sub">
                    {filteredItems.map((i) => {
                        if (isItemHidden(i, checkItemPrivilege)) return null;
                        return (
                            <DropdownItem
                                key={i.id}
                                url={(i.destination || i.getDestination) ? (i.destination || i.getDestination()) : generateUrlForItem && generateUrlForItem({ id }, i)}
                                {...i}
                                onClick={
                                    i.onClick ||
                                    (() => {
                                        if (i.newTab) {
                                            (i.destination || i.getDestination) && context.functions.updateView(i.destination || i.getDestination(), true);
                                            hideDropdown && hideDropdown();
                                            return;
                                        }
                                        i.action && i.action(id, i.id);
                                        onClick(i.id);
                                    })
                                }
                                active={selectedSubTabChild == i.id}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const Tab = (props) => {
    const {
        id,
        label,
        name,
        active,
        items,
        setDropdownIndex,
        dropdownIndex,
        tabIndex,
        onClick,
        selectedSubTabChild,
        setSelectedSubTabChild,
        selectedSubItem,
        setSelectedTab,
        setSelectedSubTab,
        checkItemPrivilege,
        url,
        generateUrlForItem,
        disabled,
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [subDropdownIndex, setSubDropdownIndex] = useState(null);
    let hideDropdownTimer;

    const context = useContext(SettingsContext);

    // hide dropdown quickly if hovering over another tab
    if (dropdownVisible && dropdownIndex != tabIndex) {
        setDropdownVisible(false);
    }

    const showDropdown = () => {
        cancelHidingDropdown();
        setDropdownIndex(tabIndex);
        setDropdownVisible(true);
    };

    const hideDropdown = () => {
        hideDropdownTimer = setTimeout(() => {
            setDropdownVisible(false);
        }, 200);
    };

    const cancelHidingDropdown = () => {
        hideDropdownTimer && clearTimeout(hideDropdownTimer);
        hideDropdownTimer = null;
    };

    const filteredItems = (items || []).filter((item) => !isItemHidden(item, checkItemPrivilege));
    if (items && filteredItems.length == 0) return null;

    const content = (
        <>
            <h2>{label || name}</h2>
            {items && items.length > 0 && <KeyboardArrowDown />}
            {active && <div className="active-indicator" />}
        </>
    );

    return (
        <div onMouseEnter={showDropdown} onMouseLeave={hideDropdown} className={`tab ${active && 'active'} ${disabled && 'disabled'}`}>
            {url ? (
                <Link data-testid={`${id}-tab`} noColor url={url} onClick={onClick}>
                    <div className="main-content">{content}</div>
                </Link>
            ) : (
                <div data-testid={`${id}-tab`} onClick={onClick} className="main-content">
                    {content}
                </div>
            )}
            {dropdownVisible && filteredItems && filteredItems.length > 0 && (
                <div onMouseEnter={cancelHidingDropdown} className="dropdown-container">
                    {filteredItems.map((i, index) => {
                        if (isItemHidden(i, checkItemPrivilege)) return null;
                        return (
                            <DropdownItem
                                checkItemPrivilege={checkItemPrivilege}
                                key={i.id}
                                url={generateUrlForItem && generateUrlForItem({ id }, i)}
                                {...i}
                                index={index}
                                generateUrlForItem={generateUrlForItem && ((subItem, subItemChild) => generateUrlForItem({ id }, subItem, subItemChild))}
                                subDropdownIndex={subDropdownIndex}
                                setSubDropdownIndex={setSubDropdownIndex}
                                hideDropdown={() => setDropdownVisible(false)}
                                onClick={(subTabChildId) => {
                                    if (i.onClick) {
                                        i.onClick();
                                        return;
                                    }
                                    if (i.newTab) {
                                        (i.destination || i.getDestination) && context.functions.updateView(i.destination || i.getDestination(), true);
                                        setDropdownVisible(false);
                                        return;
                                    }
                                    if (i.items && i.items[0].newTab && !subTabChildId) return;
                                    setSelectedTab(id);
                                    setSelectedSubTab && setSelectedSubTab(id, i.id);
                                    setSelectedSubTabChild && subTabChildId && setSelectedSubTabChild(id, i.id, subTabChildId);
                                    i.action && i.action(id, i.id);
                                    setDropdownVisible(false);
                                }}
                                active={selectedSubItem == i.id}
                                selectedSubTabChild={selectedSubTabChild}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const isItemHidden = (item, checkItemPrivilege) => {
    return (checkItemPrivilege && !checkItemPrivilege(item.id)) || (typeof item.hidden == 'function' ? item.hidden(item.id) : item.hidden);
};

const DropdownTabs = (props) => {
    const {
        tabs = [],
        selected: selectedTab,
        selectedSubItem,
        selectedSubTabChild,
        onSubTabChildClick,
        onTabClick,
        onSubTabClick,
        containerPadding = 0,
        checkItemPrivilege,
        generateUrlForItem,
        tabInfoButtonProps,
        rightComponent,
        height = 40,
        subTabStyling,
        parentTabStyling,
        noInnerPadding
    } = props;
    const [dropdownIndex, setDropdownIndex] = useState(null);
    // const [selectedTab, setSelectedTab] = useState(selected);
    const _onTabClick = (tab, firstSubItemId, newTab) => {
        if (selectedTab == tab.id) return;
        // setSelectedTab(tabId);
        if (firstSubItemId && onSubTabClick) {
            onSubTabClick(tab.id, firstSubItemId, newTab);
        } else {
            onTabClick(tab, newTab);
        }
    };
    return (
        <div
            id="dropdown-tabs"
            className={`${noInnerPadding ? 'no-inner-padding' : ''} ${parentTabStyling ? 'dropdown-parenttabs' : ''} ${subTabStyling ? 'dropdown-subtabs' : ''}`}
            style={{ marginRight: -containerPadding, marginLeft: -containerPadding, height }}
        >
            <div className='dropdown-tabs-container'>
                {tabs.map((tab, i) => {
                    if (isItemHidden(tab, checkItemPrivilege)) return null;
                    return (
                        <Tab
                            url={generateUrlForItem && generateUrlForItem(tab)}
                            {...tab}
                            onClick={(e) => {
                                let firstClickableSubItem;
                                for (let i = 0; i < (tab.items || []).length; i++) {
                                    const item = tab.items[i];
                                    if (item.items) {
                                        for (let j = 0; j < (item.items || []).length; j++) {
                                            const sub = item.items[j];
                                            if (!sub.newTab && !sub.onClick) {
                                                firstClickableSubItem = item.id;
                                                break;
                                            }
                                        }
                                        if (firstClickableSubItem) break;
                                    } else {
                                        if (!item.newTab && !item.onClick) {
                                            firstClickableSubItem = item.id;
                                            break;
                                        }
                                    }
                                }
                                if ((tab.items || []).length > 0 && !firstClickableSubItem) return;
                                tab.action ? tab.action(e) : _onTabClick(tab, firstClickableSubItem, e.ctrlKey || e.metaKey);
                            }}
                            active={selectedTab == tab.id || (tab.items || []).find((st) => st.id == selectedTab)}
                            selectedSubItem={selectedSubItem}
                            selectedSubTabChild={selectedSubTabChild}
                            tabIndex={i}
                            dropdownIndex={dropdownIndex}
                            generateUrlForItem={generateUrlForItem}
                            checkItemPrivilege={checkItemPrivilege}
                            setSelectedTab={(tabId) => {}}
                            setSelectedSubTab={onSubTabClick}
                            setSelectedSubTabChild={onSubTabChildClick}
                            setDropdownIndex={setDropdownIndex}
                        />
                    );
                })}
            </div>
            <div className="dropdown-tabs-right">
                {tabInfoButtonProps &&
                    (Array.isArray(tabInfoButtonProps) ? tabInfoButtonProps.map((infoButtonProps, i) => <TabInfoButton key={i} {...infoButtonProps} />) : <TabInfoButton {...tabInfoButtonProps} />)}
                {rightComponent && rightComponent}
            </div>
        </div>
    );
};

export default DropdownTabs;
