import { format, parse } from "date-fns";
import moment from "moment";
import DataHandler from "../general/DataHandler";
import { ResourcingFilters } from "./ResourcingView";

export enum TaskType {
    Normal,
    Project,
    /**
     * @deprecated
     */
    MainPart,
    /**
     * @deprecated
     */
    SubPart,
}

enum EditPermission {
    None = "none",
    Own = "own",
    Full = "full",
}

export enum TaskStatus {
    Unknown,
    Ongoing,
    Overdue,
    Done,
}

export enum ProjectType {
    Unknown,
    Invoiceable,
    NonInvoiceable,
    Vacation,
}

export interface GridViewDataItem {
    hours?: number | null;
    percent?: number | null;
    available?: number | null;
    fully_resourced?: boolean;
}

export interface GridViewData {
    daily: Dictionary<GridViewDataItem>;
    weekly: Dictionary<GridViewDataItem>;
    monthly: Dictionary<GridViewDataItem>;
}

export interface TotalData extends HourTotalFields {
    projects_allocated: number;
    gridData: GridViewData;
}

export interface HourTotalFields {
    /**
     * Allocation (Task only)
     */
    allocated: number;
    /**
     * Resourced to user(s)
     */
    resourced: number;
    /**
     * Tracked
     */
    tracked: number;
    /**
     * Tracked (other users)
     */
     tracked_other?: number;
    /**
     * Remaining = resourced - tracked
     */
    remaining: number;
}

interface JsonUserHourPeriod {
    key: number;
    start: string;
    end: string;
    hours: number;
    days: number|null;
}

interface JsonUserHour {
    id: number;
    // companies_id: number;
    // color: string;
    days: number|null;
    done: boolean;
    hours: number;

    /**
     * Hours done
     * @deprecated Use allTotal/periodTotal
     */
    hours_done: number;

    /**
     * Hours done today
     */
    hours_done_today: number;

    /**
     * Hours done before today
     */
    hours_done_before_today: number;

    /**
     * Contains split portions
     */
    is_split: boolean;

    resources_id: number;
    // title, name
    users_id: number;
    periods: JsonUserHourPeriod[];

    /**
     * Totals for whole task
     */
    allTotal: HourTotalFields|null;
    /**
     * Totals within selected period
     */
    periodTotal: HourTotalFields|null;
    /**
     * Grid hours data
     */
    gridData: GridViewData|null;
}

/**
 * Task from API
 */
interface JsonTask {
    /**
     * ID with prefix
     */
    id: string;
    /**
     * ID without prefix
     */
    task_id: number;

    edit_type: EditPermission;
    editable: boolean;
    status: TaskStatus;

    has_subtasks: boolean;

    customers_id: number;
    customer_name: string;

    /**
     * Project ID with prefix
     */
    project: string;

    /**
     * Project ID without prefix
     */
    projects_id: number;

    /**
     * Project Number
     */
    project_number: string;
    /**
     * Project Name, including project number
     */
    project_name: string;

    /**
     * Companies ID of Project
     */
    companies_id: number;

    /**
     * Parent Project
     */
    projects_parent_id: number;

    /**
     * 
     */
    projects_type: ProjectType;

    /**
     * Name
     */
    description: string;

    /**
     * Additional Description
     */
    additional_description: string;

    /**
     * Timezone for dates
     */
    timezone: string;
    /**
     * Start Date of Task
     * Format YYYY-MM-DD HH:MM:SS
     */
    start_date: string;
    starttime: string|null;
    /**
     * End Date of Task
     * Format YYYY-MM-DD HH:MM:SS
     */
    end_date: string;
    endtime: string|null;

    task_type: TaskType,

    hours: number;

    /**
     * Hours done by all users
     */
    hours_done: number;

    /**
     * Hours done today by all users
     */
    hours_done_today: number;

    /**
     * Hours done before today by all users
     */
    hours_done_before_today: number;

    budgeted: number;

    done: boolean;

    is_own_task: boolean;
    is_full_vacation: boolean;
    is_one_day_task: boolean;
    is_subtask: boolean;

    matched: boolean;

    is_groupable: number;

    original_parent: string;
    parent: string;

    /**
     * Parent Task (0 if none)
     */
    parent_id: number;

    priorities_id: number;
    priority: string;

    progress: number;

    project_disabled: number;
    /**
     * @deprecated Get from project
     */
    projects_allocated: number;
    
    quote_rows_id: number;
    recurrence_parent_id: number;

    remaining: number;

    rrule: string;
    skill: string;
    skills_id: number;

    /**
     * Totals in selected period
     */
    allTotal: HourTotalFields|null;

    /**
     * Totals in selected period
     */
    periodTotal: HourTotalFields|null;

    users_hours: JsonUserHour[];

    unassignedHours?: {
        /**
         * How many hours left to be resourced in period
         */
        resourced: number;
        /**
         * How many hours left to be resourced in period (all time)
         */
        resourced_all_time: number;
        gridData: GridViewData|null
    };
}

/**
 * 
 */
interface JsonMilestone {
    /**
     * Prefixed ID
     */
    id: string;

    status: TaskStatus;

    description: string;
    text: string;

    companies_id: number;

    users_id: number;
    users_name: string;
    user_ids: number[];

    budgeted: false;
    budgeted_period: false;
    hours: false;
    hours_done: false;
    hours_done_period: false;

    done: boolean;

    customer_name: string;
    customers_id: number;

    projects_id: number;
    project_name: string;
    project_number: string;

    priority: string;

    start_date: string;
    end_date: string;

    time: string;
    timezone: string;

    edit_type: EditPermission;
    editable: boolean;

    parent: string;
    rrule: string;

    is_own_task: boolean;
    is_subtask: boolean;
    matched: boolean;

    priorities_id: number;
    projects_allocated: false;

    recurrence_parent_id: number;

    remaining: false;
    remaining_period: false;

    skill: string;
    skills_id: number;

    /**
     * Totals in selected period
     */
    allTotal?: undefined;

    /**
     * Totals in selected period
     */
    periodTotal?: undefined;
}

/**
 * 
 */
interface JsonProject {
    /**
     * Prefixed ID
     */
    id: string;
    projects_id: number;
    /**
     * Parent Project
     */
    parentid: number;
    project: string;
    text: string;
    project_number: string;
    start_date: string;
    end_date: string;
    is_root: true;
    is_subproject: boolean;
    hours_done: number;
    hours_done_period: number;
    hours: null|number; // ???
    editable: boolean;
    customer: string;
    customers_id: number;
    companies_id: number;
    can_add_task: boolean;
    status: number;
    users_id: number;
    users_name: string;
    viewable: boolean;
    skill: string;
    pipeline_name: string|null;
    parent: string;
    new_parentid: string;
    original_parent: string;
    remaining: number;
    remaining_period: number;
    resource_hours: number;
    budgeted: number;
    budgeted_period: number;
    /**
     * -1 = unlocked
     * 1 = locked
     */
    locked: number;
    matched: boolean;
    maxhours: number|null;
    projects_allocated: number|null;
    progress: number;
    projects_type: ProjectType;
    priority: string;

    users: number[];
}

interface dayColumns {
    mon: number|null;
    tue: number|null;
    wed: number|null;
    thu: number|null;
    fri: number|null;
    sat: number|null;
    sun: number|null;
}

export type DayColumn = keyof dayColumns;

interface JsonResourcingUserWorkweek extends dayColumns {
    start: string;
    end: string|null;
}

export interface ResourcingUserWorkweek extends dayColumns {
    start: Date;
    end: Date|null;
    mon: number|null;
    tue: number|null;
    wed: number|null;
    thu: number|null;
    fri: number|null;
    sat: number|null;
    sun: number|null;
}

interface JsonResourcingUser {
    id: number;
    ww: JsonResourcingUserWorkweek[];
}

export interface ResourcingUser {
    id: number;
    ww: ResourcingUserWorkweek[];
}

interface GetTasksResponse {
    can_create: boolean;
    tasks: JsonTask[];
    projects: JsonProject[];
    projectsForUser: Dictionary<number[]>;
    milestones: JsonMilestone[];
    links: Link[];
    matchedUsers: number[]|false;
    users: JsonResourcingUser[];
}

export interface Link {
    id: string;
    source: string;
    source_id: string;
    source_type: string;
    target: string;
    target_id: string;
    target_type: string;
    type: string;
}

export interface UserHourPeriod extends Omit<JsonUserHourPeriod, 'start'|'end'> {
    start: Date;
    end: Date;
}

export interface UserHour extends Omit<JsonUserHour, 'periods'> {
    periods: UserHourPeriod[];
}

export interface Task extends Omit<JsonTask, 'users_hours'|'start_date'|'end_date'> {
    type: "task";
    $new?: boolean;
    start_date: Date;
    end_date: Date;
    duration_ms: number;
    users_hours: UserHour[];
}

export interface Project extends Omit<JsonProject, 'start_date'|'end_date'> {
    type: "taimer-project";
    start_date: Date;
    end_date: Date;
    duration_ms: number;
}

export interface Milestone extends Omit<JsonMilestone, 'start_date'|'end_date'> {
    type: "milestone";
    start_date: Date;
    end_date: Date;
    duration_ms: number;
}

export type Resource = Task | Milestone;

interface TaskData {
    can_create: boolean;
    projects: Project[];
    projectsForUser: Dictionary<number[]>;
    resources: Resource[];
    links: Link[];
    users: ResourcingUser[];
    matchedUsers: number[]|false;
}

/**
 * 
 * @param date 
 * @returns 
 */
 function convertDate(date: string) {
    return parse(date, "YYYY-MM-DD", new Date());
}

/**
 * 
 * @param date 
 * @returns 
 */
 function convertDateTime(date: string) {
    return parse(date, "YYYY-MM-DD HH:mm:ss", new Date());
}

/**
 * 
 * @param date 
 * @param timezone 
 * @returns 
 */
function convertTimezoneDateTime(date: string, timezone: string) {
    return moment.tz(date, "YYYY-MM-DD HH:mm:ss", timezone).local().toDate();
}

/**
 * Converts dates to js objects
 * @param task 
 * @returns 
 */
export function formatTask(task: JsonTask): Task {
    const start = convertTimezoneDateTime(task.start_date, task.timezone);
    let end = convertTimezoneDateTime(task.end_date, task.timezone);
    
    // Validate against corrupt data
    if (end < start) {
        end = start;
    }

    return {
        ...task,
        start_date: start,
        end_date: end,
        duration_ms: end.getTime() - start.getTime(),
        type: 'task',
        users_hours: task.users_hours.map(user => ({
            ...user,
            hours: Number(user.hours.toFixed(2)),
            periods: user.periods.map(period => ({
                ...period,
                start: convertDate(period.start),
                end: convertDate(period.end),
                hours: Number(period.hours.toFixed(2)),
            })),
        })),
    };
}

/**
 * 
 * @param milestone 
 * @returns 
 */
 function formatMilestone(milestone: JsonMilestone): Milestone {
    const start = convertTimezoneDateTime(milestone.start_date + ' ' + milestone.time, milestone.timezone);
    let end = convertTimezoneDateTime(milestone.end_date + ' ' + milestone.time, milestone.timezone);

    // Validate against corrupt data
    if (end < start) {
        end = start;
    }

    return {
        ...milestone,
        start_date: start,
        end_date: end,
        duration_ms: end.getTime() - start.getTime(),
        type: 'milestone',
    };
}

/**
 * 
 * @param project 
 * @returns 
 */
 function formatProject(project: JsonProject): Project {
    const start = convertDate(project.start_date);
    let end = convertDateTime(project.end_date + " 23:59:59");

    // Validate against corrupt data
    if (end < start) {
        end = start;
    }

    return {
        ...project,
        start_date: start,
        end_date: end,
        duration_ms: end.getTime() - start.getTime(),
        type: 'taimer-project',
    };
}

interface GetTasksOptions {
    showMatchesAndChildren?: boolean;
    showWholeTrees?: boolean;
    projectsId?: number;
    singleProject?: boolean;
}

export async function getGetParameters(filters: ResourcingFilters) {
    const {
        company,
        dateRange,
        customer,
        teams = [],
        users = [],
        pipelines = [],
        query,
        showStatusBy,
    } = filters;

    const startdate = dateRange?.startDate && !isNaN(dateRange.startDate.getTime()) ? format(dateRange.startDate, "YYYY-MM-DD") : undefined;
    const enddate = dateRange?.endDate && !isNaN(dateRange.endDate.getTime()) ? format(dateRange.endDate, "YYYY-MM-DD") : undefined;
    const customersId = customer?.value ?? 0;

    return {
        startdate: startdate,
        enddate: enddate,
        customers_id: customersId,
        users_ids: users.map(x => x.value),
        team: teams.map(x => x.value),
        pipelines_ids: pipelines.map(x => x.value),
        company,
        split: 1,
        timezone: moment.tz.guess(),
    }
}
 
export async function getTasks(filters: ResourcingFilters, extra: GetTasksOptions = {}): Promise<TaskData> {
    const {
        company,
        dateRange,
        customer,
        teams = [],
        users = [],
        pipelines = [],
        query,
        showStatusBy,
    } = filters;
    const {
        showMatchesAndChildren, 
        showWholeTrees, 
        projectsId,
        singleProject,
    } = extra;

    const startdate = dateRange?.startDate && !isNaN(dateRange.startDate.getTime()) ? format(dateRange.startDate, "YYYY-MM-DD") : undefined;
    const enddate = dateRange?.endDate && !isNaN(dateRange.endDate.getTime()) ? format(dateRange.endDate, "YYYY-MM-DD") : undefined;

    const customersId = customer?.value ?? 0;
    const statusid = showStatusBy?.id;
    
    const postParams = {
        searchQuery: query,
        projects: projectsId !== undefined ? [projectsId] : (filters.projects || []).map(x => x.value),
    };

    const hasFilters = !!(postParams.searchQuery || customersId > 0 || users.length > 0 || pipelines.length > 0 || postParams.projects || statusid != -1);
    
    const data = await DataHandler.post({
        url: `resourcing`,
        startdate: startdate,
        enddate: enddate,
        customers_id: customersId,
        users_ids: users.map(x => x.value),
        team: teams.map(x => x.value),
        pipelines_ids: pipelines.map(x => x.value),
        status_id: statusid,
        company,
        singleProject: (singleProject && !showWholeTrees && !showMatchesAndChildren) ? 1 : 0,
        wholeTrees: showWholeTrees ? 1 : 0,
        matchesAndChildren: showMatchesAndChildren ? 1 : 0,
        markMatches: ((showWholeTrees || showMatchesAndChildren) && hasFilters) ? 1 : 0,
        split: 1,
        timezone: moment.tz.guess(),
        include_locked: singleProject ? 1 : 0,
    }, postParams) as GetTasksResponse;

    const resources = [
        ...data.tasks.map(formatTask),
        ...data.milestones.map(formatMilestone)
    ];

    return {
        can_create: data.can_create,
        resources,
        projects: data.projects.map(formatProject),
        links: data.links,
        projectsForUser: data.projectsForUser,
        matchedUsers: data.matchedUsers,
        users: data.users ? data.users.map(u => ({
            ...u, 
            ww: u.ww.map(w => ({
                ...w, 
                start: convertDate(w.start), 
                end: w.end ? convertDate(w.end) : null,
            })),
        })) : [],
    };
}

interface GetTaskTotalsResponse {
    all: TotalData;
    user: TotalData;
}

export async function getTaskTotals(filters: ResourcingFilters, extra: GetTasksOptions = {}): Promise<GetTaskTotalsResponse> {
    const {
        company,
        dateRange,
        customer,
        teams = [],
        users = [],
        pipelines = [],
        query,
        showStatusBy,
    } = filters;
    const {
        showMatchesAndChildren, 
        showWholeTrees, 
        projectsId,
        singleProject,
    } = extra;

    const startdate = dateRange?.startDate ? format(dateRange.startDate, "YYYY-MM-DD") : undefined;
    const enddate = dateRange?.endDate ? format(dateRange.endDate, "YYYY-MM-DD") : undefined;

    const customersId = customer?.value ?? 0;
    const statusid = showStatusBy?.id;
    
    const postParams = {
        searchQuery: query,
        projects: projectsId !== undefined ? [projectsId] : (filters.projects || []).map(x => x.value),
    };

    const hasFilters = !!(postParams.searchQuery || customersId > 0 || users.length > 0 || pipelines.length > 0 || postParams.projects || statusid != -1);
    
    const data = await DataHandler.post({
        url: `resourcing/totals`,
        startdate: startdate,
        enddate: enddate,
        customers_id: customersId,
        users_ids: users.map(x => x.value),
        team: teams.map(x => x.value),
        pipelines_ids: pipelines.map(x => x.value),
        status_id: statusid,
        company,
        singleProject: (singleProject && !showWholeTrees && !showMatchesAndChildren) ? 1 : 0,
        wholeTrees: showWholeTrees,
        matchesAndChildren: showMatchesAndChildren,
        markMatches: (showWholeTrees || showMatchesAndChildren) && hasFilters,
        split: 1,
        timezone: moment.tz.guess(),
        include_locked: singleProject ? 1 : 0,
    }, postParams) as GetTaskTotalsResponse;

    return data;
}

export interface UserAvailabilityData {
    companies_id: number;
    gridData: GridViewData;
    user: number;
}

export async function getAvailability(end: Date) {
    const availability = await DataHandler.post({
        url: `resourcing/availability`,
        end: format(end, 'YYYY-MM-DD'),
    }) as Dictionary<UserAvailabilityData>;

    return availability;
}