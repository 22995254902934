import React from "react";
import { Drawer, Button, Tooltip } from "@mui/material";
import { Close, Info } from "@mui/icons-material";

import TaimerComponent from "../../TaimerComponent";
import List from "../List";
import CatalogAccountListRow from "../rows/CatalogAccountListRow";
import DataHandler from "../../general/DataHandler";
import CatalogAccountSliderOverlay from "../overlays/CatalogAccountSliderOverlay";

import "./CatalogAccountSlider.css";
import { withSnackbar } from "notistack";

class CatalogAccountSlider extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/CatalogAccountSlider");
  }

  _getColumns = () => {
    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
        columnHeaderType: (this.props.catalog && this.context.functions.checkPrivilege("customers", "write", this.props.catalog.companies_id)) && "roundButton",
        width: 50,
        ...staticColumnConfig,
      },
      {
        field: "customer_id",
        name: "customer_id",
        header: this.tr("No."),
        width: 60,
        ...staticColumnConfig,
      },
      {
        field: "customer_name",
        name: "customer_name",
        header: this.tr("Account"),
        width: 200,
        ...staticColumnConfig,
      },
      {
        field: "edited_date",
        name: "edited_date",
        header: this.tr("Last Edited"),
        width: 150,
        ...staticColumnConfig,
      },
      {
        field: "edited_by_name",
        name: "edited_by_name",
        header: this.tr("Edited by"),
        width: 150,
        ...staticColumnConfig,
      },
      {
        field: "catalog_products_only",
        name: "catalog_products_only",
        header: this.tr("Catalog products only"),
        width: 200,
        additionalComponent: () => (
          <div className="catalog-switch-info">
            <Tooltip
              classes={{
                tooltip: "darkblue-tooltip catalog-account-slider-tooltip",
              }}
              interactive
              title={this.tr(
                "If activated you can only use products from this catalogue. This means that when someone wants to add a product to time tracker or quote or invoice... it will only show the products added to the selected catalogue."
              )}
              enterTouchDelay={10}
            >
              <Info className="icon" />
            </Tooltip>
          </div>
        ),
        ...staticColumnConfig,
      },
    ];
  }

  unlinkFromAccount = (customer) => {
    const { catalog, getData, enqueueSnackbar } = this.props;
    DataHandler.put(
      {
        url: `products/company/${catalog.companies_id}/catalog`,
      },
      {
        catalogids: [catalog.id],
        customerids: [customer.customer_id],
        archiverelations: 1,
      }
    ).done(() => {
      enqueueSnackbar(this.tr("Changes saved succesfully!"), {
        variant: "success",
      });
      setTimeout(() => {
        getData();
      }, 1000);
    }).fail(() => {
      enqueueSnackbar(this.tr("Saving changes failed!"), {
        variant: "error",
      });
    });
  };

  setCatalogAccountSettings = (e, account) => {
    const { catalog, enqueueSnackbar, getData } = this.props;
    DataHandler.put(
      {
        url: `accounts/${account.customer_id}/company/${catalog.companies_id}/catalogonly`,
      },
      { catalog_only: e.target.checked }
    )
      .done(() => {
        enqueueSnackbar(this.tr("Settings saved succesfully!"), {
          variant: "success",
        });
        setTimeout(() => {
          getData();
        }, 1000);
      })
      .fail((error) => {
        enqueueSnackbar(this.tr("Saving settings failed!"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  _renderContent = () => {
    const { onClose, data, catalog, openLinkToAccountDialog } = this.props;
    return (
      <div className="content">
        <div className="top-bar">
          <div className="left">
            <div>
              <h2>{catalog && catalog.name}</h2>
              <h3>{this.tr("Linked to Account(s)")}</h3>
            </div>
          </div>
          <div className="right">
            <Button onClick={onClose}>
              <Close />
            </Button>
          </div>
        </div>
        <div className="bottom">
          <List
            data={data || []}
            columns={this._getColumns()}
            height="fitRemaining"
            minHeight={448}
            trimHeight={-20}
            enforceMinimumWidths={false}
            rowProps={{
              unlinkFromAccount: this.unlinkFromAccount,
              setCatalogAccountSettings: this.setCatalogAccountSettings,
              company: catalog && catalog.companies_id
            }}
            rowKey="customer_id"
            className="catalogAccountList"
            listRowType={CatalogAccountListRow}
            noStateData={true}
            showOverlay={(data || []).length == 0}
            overlayProps={{
              linkAccounts: () => openLinkToAccountDialog(catalog),
            }}
            overlayComponent={CatalogAccountSliderOverlay}
            roundbutton={() => openLinkToAccountDialog(catalog)}
            showPageSelector={false}
          />
        </div>
      </div>
    );
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Drawer
        id="catalog-account-slider"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        {this._renderContent()}
      </Drawer>
    );
  }
}

export default withSnackbar(CatalogAccountSlider);
