import React from "react";
import { AccessTime, MoreHoriz, Stop } from "@mui/icons-material";
import { Popover, Tooltip } from "@mui/material";
import moment from "moment";

import PropsOnlyListRow from "../../../list/PropsOnlyListRow";
import colors from "../../../colors";
import TextInputCell from "../../../list/cells/TextInputCell";
import ListCell from "../../../list/ListCell";
import { formatInputNumber } from "../../../helpers";

export default class TasksListRow extends PropsOnlyListRow {
  constructor(props) {
    super(
      props,
      { anchorEl: null },
      {},
      "dashboard/my_day/components/TasksListRow"
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      super.shouldComponentUpdate(nextProps, nextState) ||
      this.props.sharedData.hoverId != nextProps.sharedData.hoverId ||
      this.props.sharedData.trackingId != nextProps.sharedData.trackingId ||
      this.props.sharedData.loadingId != nextProps.sharedData.loadingId ||
      this.props.sharedData.priorities != nextProps.sharedData.priorities ||
      this.props.sharedData.updatedValues !=
        nextProps.sharedData.updatedValues ||
      this.props.data != nextProps.data ||
      this.state.anchorEl != nextState.anchorEl ||
      (this.props.sharedData.trackingId == this.props.data.id &&
        this.props.sharedData.timerLabel != nextProps.timerLabel)
    );
  }

  defineClassName() {
    return "myDayListRow";
  }

  _getTrackedPercentage = (tracked, resourced) => {
    return (
      (Number(resourced) != 0
        ? Math.round((Number(tracked) / Number(resourced)) * 100)
        : 0) + " %"
    );
  };

  _getDeadlineStringAndColor = (deadline) => {
    const mDeadline = moment(deadline, "YYYY-MM-DD").startOf("day");
    const today = moment();
    const diff = today.startOf("day").diff(mDeadline, "days");
    let string;
    let color = "#aaa";
    if (diff > 0) {
      string =
        diff == 1 ? this.tr("Yesterday") : `${this.tr("${amount} days ago")}`;
      color = colors.carnation_pink;
    } else if (diff == 0) {
      string = `${this.tr("Today")}`;
      color = colors.dark_sky_blue;
    } else {
      string =
        diff == -1 ? this.tr("Tomorrow") : `${this.tr("In ${amount} days")}`;
      color = diff < -3 ? colors.greenish_cyan : colors.orangey_yellow;
    }
    return {
      string: string.replace("${amount}", Math.abs(diff)),
      color: color,
    };
  };

  // 1: In progress, 2: Overdue, 3: Done, 4: Milestone
  _getStatusStringAndColor = (status) => {
    switch (status) {
      case 1:
        return { color: colors.orangey_yellow, string: this.tr("In progress") };
      case 2:
        return { color: colors.carnation_pink, string: this.tr("Overdue") };
      case 3:
        return { color: colors.greenish_cyan, string: this.tr("Done") };
      case 4:
        return { color: colors.dark_sky_blue, string: this.tr("Milestone") };
      default:
        return { color: "#aaa", string: this.tr("-") };
    }
  };

  _formatHours = (value) => {
    if (Number(value) === 0) return value + " h";

    return formatInputNumber(value, "hours") + " h";
  };

  _getPriorityName = () => {
    const {
      data,
      sharedData: { priorities, updatedValues },
    } = this.props;
    const prio =
      priorities.find(
        (x) =>
          x.id ==
          (updatedValues[data.id] && updatedValues[data.id].priorities_id
            ? updatedValues[data.id].priorities_id
            : data.priorities_id)
      ) || {};
    return (
      <div className="prio-container">
        {prio.icon && <img src={prio.icon} />}
        {this.tr(prio.name)}
      </div>
    );
  };

  defineCells() {
    const {
      userObject: { dateFormat },
    } = this.context;
    const {
      data,
      sharedData: {
        openTaskDialog,
        hoverId,
        trackingId,
        hoverItems,
        timerLabel,
        endTimer,
        menuItems,
        showEditPopUp,
        updatedValues,
        loadingId
      },
    } = this.props;
    const { anchorEl } = this.state;

    let cells = {};

    Object.keys(data).forEach((key) => {
      cells[key] = (
        <TextInputCell onlyDisplay editable={false} value={data[key] || ""} />
      );
    });

    const statusData = this._getStatusStringAndColor(data.status);
    const deadlineData = this._getDeadlineStringAndColor(
      updatedValues[data.id] && updatedValues[data.id].enddate
        ? updatedValues[data.id].enddate
        : data.end_date
    );

    cells = {
      ...cells,
      actions: (
        <ListCell cellClassName="actions" onlyDisplay editable={false}>
          {trackingId == data.id ? (
            <button onClick={endTimer}>
              <div className="timer-icon-container">
                <AccessTime className="icon" />
                <div className="timer-indicator" />
              </div>
              <div className="label">{timerLabel}</div>
            </button>
          ) : (
            <React.Fragment>
              <button
                onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
              >
                <MoreHoriz className="icon" />
              </button>
              <Popover
                open={Boolean(anchorEl)}
                elevation={4}
                anchorEl={anchorEl}
                onClose={() => this.setState({ anchorEl: null })}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {menuItems
                  .filter((item) =>
                    item.checkRight ? item.checkRight(data) : true
                  )
                  .map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          color: item.hasOwnProperty("color") && item.color,
                        }}
                        className="my-day-task-list-menu-item"
                        onClick={() => {
                          this.setState({ anchorEl: null });
                          item.action(data);
                        }}
                      >
                        <item.icon
                          style={{
                            color: item.hasOwnProperty("color") && item.color,
                          }}
                          className="icon"
                        />
                        {item.label}
                      </div>
                    );
                  })}
              </Popover>
            </React.Fragment>
          )}
        </ListCell>
      ),
      project_account: (
        <ListCell onlyDisplay editable={false}>
          <div className="text-container">
            <a
              href={`index.php?module=projects&action=view&id=${data.projects_id}`}
              onClick={(e) => {
                      !e.ctrlKey && !e.metaKey && e.preventDefault();
                      this.context.functions.updateView({module: 'projects', action: 'view', id:data.projects_id});
              }}
              title={data.project_name || "–"}
              className="top blue-highlight"
            >
              {data.project_name || "–"}
            </a>
            <a
              href={`index.php?module=customers&action=view&id=${data.customers_id}`}
              onClick={(e) => {
                !e.ctrlKey && !e.metaKey && e.preventDefault();
                this.context.functions.updateView({module: 'customers', action: 'view', id:data.customers_id});
              }}
              title={data.customer_name || "-"}
              className="bottom blue-highlight"
            >
              {data.customer_name || "–"}
            </a>
          </div>
        </ListCell>
      ),
      description_type: (
        <ListCell onlyDisplay editable={false}>
          {loadingId == data.id ? <img className="spinner" src={require("../../insights/img/loading.svg").default} /> : hoverId == data.id ? (
            <div className="hover-action-container">
              {trackingId == data.id ? (
                <div onClick={endTimer} className="stop-container">
                  <Stop className="icon" />
                  {this.tr("Stop timer")}
                </div>
              ) : (
                hoverItems
                  .filter((item) =>
                    item.checkRight ? item.checkRight(data) : true
                  )
                  .map((item, i) => {
                    return (

                      (item.label == "View project resourcing") ?
                      <div >
                        <a
                          key={i}
                          href={`index.php?module=projects&action=view&id=${data.id}&tab=resourcing`}
                          style={{
                            color: item.hasOwnProperty("color") && item.color,
                          }}
                          onClick={(e) => {
                            !e.ctrlKey && !e.metaKey && e.preventDefault();
                            !e.ctrlKey && !e.metaKey && item.action(data);
                          }}
                        >
                          <Tooltip title={item.label} placement="top">
                            <item.icon className="icon" />
                          </Tooltip>
                        
                        </a>
                      </div>
                      :
                      <div key={i} onClick={() => item.action(data)}>
                        <Tooltip title={item.label} placement="top">
                          <item.icon className="icon" />
                        </Tooltip>
                      </div>
                    );
                  })
              )}
            </div>
          ) : (
            <div className="text-container">
              <div
                onClick={() => openTaskDialog(data)}
                className="top bold"
              >
                {data.description || "–"}
              </div>
              <div className="bottom">{data.skill || "–"}</div>
            </div>
          )}
        </ListCell>
      ),
      priority_status: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) =>
              showEditPopUp(e.currentTarget, "priority_status", data)
            }
          >
            <div className="text-container">
              <div className="top">{this._getPriorityName()}</div>
              <div style={{ color: statusData.color }} className="bottom">
                {[
                  statusData.string,
                  data.own_done ? "," : "",
                  data.own_done ? (
                    <span
                      key="own"
                      style={{ color: colors.greenish_cyan }}
                    >{` ${this.tr("own done")}`}</span>
                  ) : (
                    ""
                  ),
                ]}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      tracked_resourced: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) =>
              showEditPopUp(e.currentTarget, "tracked_resourced", data)
            }
          >
            <div className="text-container">
              <div className="top">{data.is_milestone == 1 ? "-" : `${ this._formatHours(
                data.hours_done || "0"
              )} / ${this._formatHours(data.resourced_hours || "0")}`}</div>
              <div className="bottom">
                {data.is_milestone == 1 ? "-" :this._getTrackedPercentage(
                  data.hours_done || "0",
                  data.resourced_hours || "0"
                )}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      deadline: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) => showEditPopUp(e.currentTarget, "deadline", data)}
          >
            <div className="text-container">
              <div className="top">
                {moment(
                  updatedValues[data.id] && updatedValues[data.id].enddate
                    ? updatedValues[data.id].enddate
                    : data.end_date,
                  "YYYY-MM-DD"
                ).format(dateFormat) || "–"}
              </div>
              <div style={{ color: deadlineData.color }} className="bottom">
                {deadlineData.string}
              </div>
            </div>
          </div>
        </ListCell>
      ),
    };

    return cells;
  }
}
