import ListCell from "./ListCell";
import { SettingsContext } from './../SettingsContext';


class CurrencyRateListCell extends ListCell {
	static contextType = SettingsContext;

	presentValue(value) {
		const {  functions } = this.context;
		return functions.presentCurrency(value, this.props.owner.props.currency);
	}

}

export default CurrencyRateListCell;