import React from 'react';
import ResourcingView from "../resourcing/ResourcingView";
import DataHandler from './../general/DataHandler';


class TabResourcing extends React.Component {

	constructor(props, context) {
        super(props, context, "projects/TabResourcing");
        this.state = {
		};
	}

	componentDidMount() {

    }

    countAllocatedHours = () => {
        DataHandler.get({url: 'resourcing/projects_allocated_hours', projects: this.props.id}).done((data) => {
            this.props.setMaxHours(data);
        });
    }


    render () {

        return (
            <div id="projects-resourcing">
                <ResourcingView
                    stickySearchKey={`resourcing-view-project-${this.props.project.id}`}
                    checkPrivilege={this.props.checkPrivilege}
                    singleProject
                    onProjectRangeExtended={this.props.onProjectRangeExtended}
                    onDialogSave={this.countAllocatedHours}
                    startDate={null}
                    endDate={null}
                    projectsId={Number(this.props.project.id)}
                    locked={this.props.project.locked == 1}
                    className={"project-tab"}
                    company={this.props.company}
                    selectedTab={this.props.selectedTab}
                    grouping={this.props.grouping}
                    zoom={this.props.zoom}
                    noTabs={this.props.noTabs}
                    header={this.props.header}
                    subheaders={this.props.subheaders}
                />
            </div>
        );
    }


};

export default TabResourcing;