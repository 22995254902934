import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { format } from 'date-fns';
import { cloneDeep, clone, isEqual } from 'lodash';
import { DatePicker } from '../general/react-date-range/src';
import { Checkbox, Popover, Tooltip } from '@mui/material';
import { ArrowDropDownSharp, PrintDisabled, Visibility } from '@mui/icons-material';

import colors from '../colors';
import List from '../list/List';
import QuoteRow from './QuoteRow';
import Logo from '../invoices/Logo';
import { quoteRowTypes } from './TabQuotes';
import StatusTag from '../general/StatusTag';
import navicons from '../navigation/NavIcons';
import TaimerComponent from '../TaimerComponent';
import DataHandler from '../general/DataHandler';
import TextAreaCell from '../list/cells/TextAreaCell';
import { makeMapOfPrimitives } from '../list/ListUtils';
import FieldEditSlider from '../general/FieldEditSlider';
import LabelFieldGroup from '../general/LabelFieldGroup';
import { AddContact } from '../general/no-options/AddItemComponents';

import styles from './TabQuotes.module.scss';

const ResourcingIcon = navicons.resourcing;
interface ListProps extends Props {
    tr: any;
    activeCell: any;
    listColumns: any[];
    setActiveCell: (cell: any) => void;
    createPurchaseOrder: (row: any) => void;
    onListMounted: () => void;
    onDraggedRowDrop: any;
    onColumnVisibilityChange: (columns: any) => void;
}

// Quote list is in its own component just to avoid all unnecessary re-renders
class QuoteList extends TaimerComponent<ListProps> {
    list: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/QuoteList');
    }

    shouldComponentUpdate = (newProps) => {
        if (
            !isEqual(newProps.quoteRows, this.props.quoteRows) ||
            !isEqual(newProps.listColumns, this.props.listColumns) ||
            !isEqual(newProps.products, this.props.products) ||
            !isEqual(newProps.CPQParents, this.props.CPQParents) ||
            !isEqual(newProps.jobtypes, this.props.jobtypes) ||
            newProps.editMode != this.props.editMode ||
            newProps.activeCell != this.props.activeCell ||
            newProps.currencyFormatter != this.props.currencyFormatter
        ) {
            return true;
        }
        return false;
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.listColumns, this.props.listColumns)) {
            this.list.current && this.list.current.saveColumnConfig(this.props.listColumns);
        }
    };

    onDeleteRow = (row) => {
        this.props.onRowEdit({ ...row, deleted: 1 });
    };

    quoteRowVisibilityFilter = (row) => {
        return row && (!row.deleted || row.deleted === '0');
    };

    onColumnVisibilityChange = (columns) => {
        this.props.onColumnVisibilityChange(columns);
    };

    renderIndicators = (row) => {
        const hasTask = Number(row.has_task) > 0;
        const hiddenFromPrint = row.hidden_for_print == 1;
        return (
            <div className={`${styles.rowIndicators} ${hasTask && hiddenFromPrint ? styles.multiple : ''}`}>
                {hasTask && (
                    <Tooltip title={this.tr('A task has been linked to this row')}>
                        <div className={styles.taskLinked}>
                            <ResourcingIcon />
                        </div>
                    </Tooltip>
                )}
                {hiddenFromPrint && (
                    <Tooltip title={this.tr('Hidden from print')}>
                        <div className={styles.hiddenFromPrint}>
                            <PrintDisabled />
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    render() {
        const {
            editMode,
            tr,
            renderField,
            activeCell,
            setActiveCell,
            createPurchaseOrder,
            getEditableFields,
            onListMounted,
            listColumns,
            quoteRows,
            onRowEdit,
            onAddRow,
            currencyFormatter,
            quantityTypes,
            jobtypes,
            workTypes,
            project,
            products,
            CPQParents,
            onDraggedRowDrop,
            useLazyLoad,
            checkPrivilege,
            openTaskDialog,
        } = this.props;
        return (
            <List
                ref={this.list}
                className={`${styles.list} ${editMode ? styles.editMode : ''}`}
                fluid
                manualCreate
                hideUndefinedCells
                useLazyLoad={useLazyLoad}
                showHiddenFromPrintIndicator
                renderIndicators={this.renderIndicators}
                saveColumnConfig
                userListSettingsKey="quote_column_settings"
                onListMounted={onListMounted}
                idType="string"
                visibilityFilter={this.quoteRowVisibilityFilter}
                height="auto"
                columns={listColumns}
                listRowType={QuoteRow}
                noColorVariance
                noStateData
                ignoreRowPropsChange={false}
                controlOrder={true}
                data={quoteRows}
                onEdit={onRowEdit}
                onColumnVisibilityChange={this.onColumnVisibilityChange}
                hideBottomMarker={false}
                rowProps={{
                    editMode,
                    tr,
                    onAddRow: onAddRow,
                    currencyFormatter,
                    jobtypes,
                    quantityTypes: quantityTypes,
                    workTypes: workTypes,
                    canCreatePurchaseOrder: project.rights.includes('purchase_order_write'),
                    products,
                    CPQParents,
                    renderField: renderField,
                    activeCell: activeCell,
                    setActiveCell: setActiveCell,
                    getEditableFields: getEditableFields,
                    createPurchaseOrder: createPurchaseOrder,
                    onDeleteRow: this.onDeleteRow,
                    createTask: openTaskDialog,
                    canCreateTask: checkPrivilege('projects', 'project_resourcing_write') || checkPrivilege('projects', 'own_resourcing_write'),
                    company: this.props.project.companies_id,
                    formatNumberInput: this.props.formatNumberInput,
                    canCreateInvoice: this.props.canCreateInvoice,
                }}
                rowDragging={true}
                onDraggedRowDrop={onDraggedRowDrop}
                dragDropIsAllowed={(rowToDrop, onRow) => {
                    if (rowToDrop.data.id == onRow.data.id) {
                        return false;
                    }

                    const r = rowToDrop.data;
                    const o = onRow.data;
                    const onNormalRow =
                        o.type == quoteRowTypes.quoteRow ||
                        o.type == quoteRowTypes.descriptionRow ||
                        o.type == quoteRowTypes.productRow ||
                        o.type == quoteRowTypes.cpqGroupedRow ||
                        o.type == quoteRowTypes.summaryRow;
                    const droppedIsNormalRow =
                        r.type == quoteRowTypes.quoteRow || r.type == quoteRowTypes.descriptionRow || r.type == quoteRowTypes.productRow || r.type == quoteRowTypes.cpqGroupedRow;
                    const droppedIsHeaderRow = r.type == quoteRowTypes.headerRow;
                    return (
                        (onRow.data.id == 'BOTTOM_MARKER' && droppedIsHeaderRow) ||
                        (onNormalRow && droppedIsNormalRow) ||
                        (o.type == quoteRowTypes.headerRow && droppedIsHeaderRow) ||
                        (onRow.lastInGroup && r.type != quoteRowTypes.headerRow)
                    );
                }}
            />
        );
    }
}

interface Props {
    editMode: boolean;
    onQuoteEdited: (e: any) => void;
    onAddressEdited: (e: any, additionalValues?: any) => void;
    updateHeaders: (headers: any) => void;
    quote?: any;
    project: any;
    visitingAddresses: any[];
    receiverContacts: any[];
    companyContacts: any[];
    allUsers: any[];
    jobtypes: any[];
    products: any[];
    CPQParents: any[];
    quoteRows: any[];
    quantityTypes: any[];
    workTypes: any[];
    totals: any;
    companyAddress: any;
    onCompanyAddressEdited: (e: any) => void;
    onRowEdit: (row: any) => void;
    onAddRow: (row: any, rowId?: any) => void;
    onAddressSelected: (addressId: string, data: any) => void;
    onContactSelected: (contact: any) => void;
    onSenderContactSelected: (contact: any) => void;
    getVisitingAddresses: () => void;
    currencyFormatter: any;
    printDateFormat: string;
    onMount?: () => void;
    innerRef?: any;
    loadingQuotes: boolean;
    useLazyLoad: boolean;
    printMode: boolean;
    checkPrivilege: (module: string, permission: string) => boolean;
    openTaskDialog: any;
    getEditableFields: any;
    renderField: any;
    printLanguage: string;
    formatNumberInput: (value: string) => any;
    canCreateInvoice: boolean;
    onGrandTotalVisibilityChange: (visible: boolean) => void;
    grandTotalHidden: boolean;
    setTargetingSectionY: (value: number) => void;
}

interface State {
    listColumns: any[];
    activeCell?: any;
    columnSettingsAnchor?: any;
    listMounted: boolean;
    logoLoaded: boolean;
    editSliderData?: any;
}

class Quote extends TaimerComponent<Props, State> {
    // For forcing column order even when column visibility changes
    listColumnOrderMap = {
        name: 1,
        quantity: 2,
        cost: 3,
        value: 4,
        vat: 5,
        sum: 6,
        margin: 7,
        total: 8,
        workType: 9,
        jobtype: 10,
        invoiced: 11,
    };
    addressSliderFields: any[];
    resizeTimeout: any;
    topSection: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/Quote');

        const {
            taimerAccount: { showState },
        } = this.context;

        this.addressSliderFields = [
            {
                title: this.tr('Company'),
                key: 'name',
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
            },
            {
                title: this.tr('Address'),
                key: 'address',
            },
            ...(showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
            },
        ];

        let listColumns = this.getInitialListColumns();
        listColumns = this.setColumnPrintHides(listColumns);

        this.state = {
            listColumns,
            listMounted: false,
            logoLoaded: false,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.setTargetingSectionY();
        window.addEventListener('resize', this.onResize);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
            this.setTargetingSectionY();
        }, 200);
    };

    setTargetingSectionY = () => {
        const topSectionDimensions = this.topSection.current?.getBoundingClientRect();
        this.props.setTargetingSectionY(topSectionDimensions?.height || 500);
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.quote?.print_exceptions, this.props.quote?.print_exceptions)) {
            this.setColumns(this.state.listColumns);
        }

        if (oldProps.editMode != this.props.editMode) {
            const listColumns = [...this.state.listColumns];
            // Showing drag indicator only in edit mode, need to tweak widths etc.
            if (this.props.editMode) {
                const dragIndex = listColumns.findIndex((c) => c.field == 'drag');
                if (dragIndex == -1) {
                    listColumns.unshift({
                        field: 'drag',
                        name: 'drag',
                        header: '',
                        width: 22,
                        showMenu: false,
                        resizeable: false,
                        moveable: false,
                        hideable: false,
                        staticIndex: 0,
                        alwaysHiddenFromPrint: true,
                        noFlex: true,
                        dontSaveConfig: true,
                        hideFromPrintOptions: true,
                    });
                }
                const menuIndex = listColumns.findIndex((c) => c.field == 'menu');
                if (menuIndex != -1) {
                    listColumns[menuIndex].width = 35;
                    listColumns[menuIndex].staticIndex = 1;
                }
            } else {
                const dragIndex = listColumns.findIndex((c) => c.field == 'drag');
                if (dragIndex != -1) {
                    listColumns.splice(dragIndex, 1);
                }
                const menuIndex = listColumns.findIndex((c) => c.field == 'menu');
                if (menuIndex != -1) {
                    listColumns[menuIndex].width = 40;
                    listColumns[menuIndex].staticIndex = 0;
                }
            }
            this.setColumns(listColumns);
        }

        if (this.props.jobtypes != oldProps.jobtypes) {
            const listColumns = cloneDeep(this.state.listColumns);
            const jobtypeColumnIndex = listColumns.findIndex((c) => c.field == 'jobtype');
            if (jobtypeColumnIndex != -1) {
                listColumns[jobtypeColumnIndex].hidden = (this.props.jobtypes || []).length == 0;
            }
            this.setState({ listColumns });
        }

        if (this.state.listMounted && (!this.showLogo() || this.state.logoLoaded)) {
            this.props.onMount && this.props.onMount();
        }
    };

    getInitialListColumns = () => {
        const {
            project: { charge_costest, type },
            canCreateInvoice,
        } = this.props;
        const commonColumnProps = {
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
        };
        const listColumns = [
            {
                field: 'menu',
                name: 'menu',
                header: '',
                width: 40,
                staticIndex: 0,
                alwaysHiddenFromPrint: true,
                noFlex: true,
                dontSaveConfig: true,
                hideFromPrintOptions: true,
                ...commonColumnProps,
            },
            {
                field: 'name',
                name: 'name',
                header: this.tr('Rows'),
                width: 90,
                ...commonColumnProps,
            },
            {
                field: 'quantity',
                name: 'quantity',
                header: this.tr('Amount'),
                width: 30,
                alignRight: true,
                ...commonColumnProps,
            },
            {
                field: 'cost',
                name: 'cost',
                header: this.tr('Unit cost'),
                width: 35,
                alignRight: true,
                currency: true,
                ...commonColumnProps,
            },
            {
                field: 'value',
                name: 'value',
                header: this.tr('Selling price'),
                width: 35,
                alignRight: true,
                currency: true,
                ...commonColumnProps,
            },
            {
                field: 'vat',
                name: 'vat',
                header: this.tr('Vat'),
                width: 30,
                alignRight: true,
                ...commonColumnProps,
            },
            {
                field: 'sum',
                name: 'sum',
                header: this.tr('Sum 0%'),
                width: 40,
                alignRight: true,
                currency: true,
                disabled: true,
                ...commonColumnProps,
            },
            {
                field: 'margin',
                name: 'margin',
                header: this.tr('Margin 0%'),
                width: 40,
                alignRight: true,
                currency: true,
                ...commonColumnProps,
            },
            {
                field: 'total',
                name: 'total',
                header: this.tr('Total'),
                width: 40,
                alignRight: true,
                currency: true,
                disabled: true,
                visible: false,
                ...commonColumnProps,
            },
            {
                field: 'workType',
                name: 'workType',
                header: this.tr('Type of work'),
                width: 40,
                alignRight: true,
                ...commonColumnProps,
            },
            ...(this.context.addons.nav
                ? [
                      {
                          field: 'jobtype',
                          name: 'jobtype',
                          header: this.tr('Jobtype'),
                          width: 40,
                          alignRight: true,
                          visible: false,
                          hiddenFromPrint: true,
                          hidden: (this.props.jobtypes || []).length == 0,
                          ...commonColumnProps,
                      },
                  ]
                : []),
            ...(type == '1' && charge_costest == '1' && canCreateInvoice
                ? [
                      {
                          field: 'invoiced',
                          name: 'invoiced',
                          header: this.tr('Invoiced'),
                          width: 30,
                          alignRight: true,
                          disabled: true,
                          visible: false,
                          alwaysHiddenFromPrint: true,
                          ...commonColumnProps,
                      },
                  ]
                : []),
        ];
        return listColumns;
    };

    onListMounted = () => this.setState({ listMounted: true });
    onLogoLoaded = () => this.setState({ logoLoaded: true });

    tr = (value) => {
        // overriding tr function to use selected print language when printing and Taimer language otherwise
        const { printMode, printLanguage } = this.props;
        return !printMode ? super.tr(value) : this.beTr(value, printLanguage || 'en', {}, 'general/backendTranslations/QuotePrint');
    };

    // Setting column print hides, could be checked in render but this should be more efficient.
    // Stupid because needs to be remembered to be called when listColumns change.
    setColumnPrintHides = (columns) => {
        const listColumns = cloneDeep(columns);
        listColumns.forEach((col, i) => {
            listColumns[i].hiddenFromPrint = col.alwaysHiddenFromPrint || (this.props.quote?.print_exceptions || []).includes(col.name);
        });
        return listColumns;
    };

    setActiveCell = (activeCell) => this.setState({ activeCell });

    openColumnSettings = (e) => this.setState({ columnSettingsAnchor: e.target });
    closeColumnSettings = () => this.setState({ columnSettingsAnchor: undefined });

    onColumnVisibilityChange = (field, visible) => {
        const listColumns = cloneDeep(this.state.listColumns);
        const index = listColumns.findIndex((c) => c.field == field);
        let alwaysHiddenFromPrint = false;
        if (index != -1) {
            listColumns[index].visible = visible;
            alwaysHiddenFromPrint = listColumns[index].alwaysHiddenFromPrint;
        }
        this.setState({ listColumns }, () => {
            if (!alwaysHiddenFromPrint) {
                this.onColumnPrintVisibilityChange(field, visible);
            }
        });
    };

    onColumnPrintVisibilityChange = (field, visible) => {
        const printExceptions = cloneDeep(this.props.quote?.print_exceptions || []);
        if (visible) {
            const foundIndex = printExceptions.findIndex((p) => p == field);
            if (foundIndex != -1) {
                printExceptions.splice(foundIndex, 1);
            }
        } else {
            printExceptions.push(field);
        }
        this.props.onQuoteEdited({ target: { name: 'print_exceptions', value: printExceptions } });
    };

    onDraggedRowDrop = (droppedRow, onRow, currentOrder, dataMap, listRef) => {
        let dId = Number(droppedRow.data.id);
        let onId = typeof onRow.data === 'object' ? onRow.data.id : null;
        const newOrder: any = [];
        const toMove = [dId];
        let toFlip = [dId];
        let after = false;
        const onRowParentId = onRow.data.id !== 'BOTTOM_MARKER' ? onRow.parentId || onRow.data.parentId : null;

        if (onRow.lastInGroup) {
            after = true;

            const inHeader = listRef.getData().filter((d) => d.parentId == onRowParentId);

            if (!inHeader || inHeader.length === 0) {
                onId = onRowParentId;
            } else {
                onId = inHeader[inHeader.length - 1].id;
            }
        }

        // If we're moving a group of rows.
        if (droppedRow.data.type == quoteRowTypes.headerRow) {
            listRef
                .getData()
                .filter((d) => d.parentId == dId)
                .forEach((d) => toMove.push(d.id));

            const treeMap = makeMapOfPrimitives(toMove, true);
            currentOrder = currentOrder.filter((id) => !treeMap[id]);
            toFlip = clone(toMove);
        } else if (droppedRow.data.parentId && onRowParentId && droppedRow.data.parentId != '0' && droppedRow.data.parentId != onRowParentId) {
            // If we're moving a row under a new parent (into a new group).
            toFlip.push(droppedRow.data.parentId);

            dataMap[dId].parentId = onRowParentId;
            currentOrder = currentOrder.filter((id) => id != dId);
        } else {
            currentOrder = currentOrder.filter((id) => id != dId);
        }

        let cur;

        for (let i in currentOrder) {
            cur = currentOrder[i];

            if (cur == onId && !after) {
                toMove.forEach((id) => newOrder.push(id));
            }

            newOrder.push(cur);

            if (cur == onId && after) {
                toMove.forEach((id) => newOrder.push(id));
            }
        }

        if (onRow.data.id === 'BOTTOM_MARKER') {
            toMove.forEach((id) => newOrder.push(id));
        }

        // Rows filtered out by List.props.visibilityFilter are not in currentOrder,
        // so we have to add them manually to the
        // data that will replace List's current data.
        const deletedRows = listRef.getData().filter((d) => newOrder.findIndex((n) => n == d.id) === -1);

        const rows = newOrder.map((id) => dataMap[id]).concat(deletedRows);
        let headers: any = [];
        rows.forEach((row) => {
            if (row.type == quoteRowTypes.headerRow) {
                headers.push({ ...row, rows: [] });
            } else if (row.type != quoteRowTypes.summaryRow) {
                const headerIndex = headers.findIndex((h) => h.id == row.parentId);
                if (headerIndex != -1) {
                    headers[headerIndex].rows.push(row);
                }
            }
        });
        return [rows, () => this.props.updateHeaders(headers)];
    };

    showEditSlider = (editSliderData) => this.setState({ editSliderData });
    closeEditSlider = () => this.setState({ editSliderData: undefined });

    showNewAddressSlider = (address) => {
        this.showEditSlider({
            onSave: this.onSaveNewVisitingAddress,
            fields: this.addressSliderFields,
            item: { name: this.props.project?.account?.name, address, vatid: this.props.project?.account?.vatid },
        });
    };

    onSaveNewVisitingAddress = async (address) => {
        const addressData = {
            id: -1,
            ...address,
        };
        this.closeEditSlider();
        let response = await DataHandler.put({ url: `accounts/${this.props.project.customers_id}/visiting_addresses/${this.props.project.companies_id}` }, { visiting_addresses: [addressData] });
        setTimeout(this.props.getVisitingAddresses, 1000);
        this.props.onAddressSelected(response[0].id, addressData);
    };

    renderToAddress = () => {
        const { quote, visitingAddresses, receiverContacts, editMode, onAddressEdited, onAddressSelected, onContactSelected } = this.props;
        const {
            taimerAccount: { showState },
        } = this.context;
        const address = quote?.editedAddress;
        return (
            <LabelFieldGroup
                title={this.tr('To')}
                editMode={editMode}
                values={quote?.address_id && Number(quote?.address_id) > 0 && visitingAddresses ? visitingAddresses.find((addr) => addr.id === quote?.address_id) : {}}
                fields={[
                    {
                        label: this.tr('Company'),
                        name: 'name',
                        value: address?.name,
                        onChange: onAddressEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                    {
                        label: this.tr('Address'),
                        name: 'address',
                        value: { label: address?.address, value: quote?.address_id },
                        editorType: CreatableSelect,
                        options: visitingAddresses,
                        onChange: async (address) => {
                            if (address.__isNew__) {
                                this.showNewAddressSlider(address.value);
                            } else if (address.id) {
                                onAddressSelected(address.id, address);
                            }
                        },
                    },
                    {
                        label: this.tr('Zip code'),
                        name: 'postalcode',
                        value: address?.postalcode,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('City'),
                        name: 'city',
                        value: address?.city,
                        onChange: onAddressEdited,
                    },
                    showState && {
                        label: this.tr('State'),
                        name: 'state',
                        value: address?.state,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('Country'),
                        name: 'country',
                        value: address?.country,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('Business ID'),
                        name: 'vatid',
                        value: address?.vatid,
                        onChange: onAddressEdited,
                    },
                    !address?.custom_contact || receiverContacts.findIndex((c) => c.label == address?.custom_contact) != -1
                        ? {
                              label: this.tr('Contact'),
                              name: 'receiver_contact',
                              value: (receiverContacts || []).find((contact) => contact.id == quote?.receiver_contact_id),
                              editorType: CreatableSelect,
                              noOptions: AddContact,
                              options: receiverContacts,
                              onItemCreated: (contact) => onContactSelected(contact),
                              onChange: (value) => {
                                  if (value.__isNew__ || !value.id) {
                                      onAddressEdited({ target: { name: 'custom_contact', value: value.label } }, { phone: '', email: '' });
                                  } else {
                                      onContactSelected(value);
                                  }
                              },
                          }
                        : {
                              label: this.tr('Contact'),
                              name: 'custom_contact',
                              value: address?.custom_contact,
                              onChange: (e) => onAddressEdited({ target: { name: 'custom_contact', value: !e.target.value ? undefined : e.target.value } }),
                          },
                    {
                        label: this.tr('Phone'),
                        name: 'phone',
                        value: address?.phone,
                        onKeyUp: onAddressEdited,
                    },
                    {
                        label: this.tr('Email address'),
                        name: 'email',
                        value: address?.email,
                        onKeyUp: onAddressEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                ]}
            />
        );
    };

    renderFromAddress = () => {
        const { quote, onQuoteEdited, companyAddress, onCompanyAddressEdited, editMode, companyContacts, allUsers } = this.props;
        const {
            taimerAccount: { showState },
        } = this.context;
        let senderContact = companyContacts.find((c) => c.id == quote?.sender_contact_id);
        if (!senderContact) {
            senderContact = allUsers && allUsers.find((u) => u.id == quote?.sender_contact_id);
        }
        if (senderContact) {
            senderContact.value = senderContact.id;
            senderContact.label = senderContact.name;
        }
        return (
            <LabelFieldGroup
                title={this.tr('From')}
                editMode={editMode}
                values={companyAddress}
                fields={[
                    { label: this.tr('From Company'), name: 'name', value: undefined, editable: false, className: 'quote-header-email-field', editorType: editMode ? undefined : TextAreaCell },
                    { label: this.tr('Address'), name: 'address', value: undefined, editable: false },
                    {
                        label: this.tr('Zip code'),
                        name: 'postalcode',
                        value: undefined,
                        onKeyUp: onCompanyAddressEdited,
                        editable: false,
                    },
                    { label: this.tr('City'), name: 'city', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    showState && {
                        label: this.tr('State'),
                        name: 'state',
                        value: undefined,
                        onKeyUp: onCompanyAddressEdited,
                        editable: false,
                    },
                    { label: this.tr('Country'), name: 'country', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    { label: this.tr('Business ID'), name: 'vatid', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    {
                        label: this.tr('Contact'),
                        name: 'sender_contact',
                        value: senderContact,
                        showStringValue: !senderContact?.id,
                        editorType: Select,
                        options: companyContacts || [],
                        onChange: (value) => {
                            this.props.onSenderContactSelected(value);
                        },
                    },
                    {
                        label: this.tr('Phone'),
                        name: 'company_phone',
                        value: quote?.company_phone,
                        onKeyUp: onQuoteEdited,
                    },
                    {
                        label: this.tr('Email address'),
                        name: 'company_email',
                        value: quote?.company_email,
                        onKeyUp: onQuoteEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                ]}
            />
        );
    };

    renderDates = () => {
        const { quote, companyAddress, onQuoteEdited, editMode, printMode, printDateFormat } = this.props;
        return (
            <LabelFieldGroup
                editMode={editMode}
                values={companyAddress}
                fields={[
                    {
                        label: this.tr('Sent'),
                        name: 'sent_date',
                        date: quote?.sent_date,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'sent_date', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'sent_date', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                    {
                        label: this.tr('Valid until'),
                        name: 'valid_until',
                        date: quote?.valid_until,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'valid_until', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'valid_until', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                    {
                        label: this.tr('Delivered'),
                        name: 'delivery_date',
                        date: quote?.delivery_date,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'delivery_date', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'delivery_date', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                ]}
            />
        );
    };

    createPurchaseOrder = (data) => {
        this.context.functions.updateView({
            module: 'purchaseorder',
            action: 'view',
            companies_id: this.props.project.companies_id,
            projects_id: this.props.project.id,
            quote: this.props.quote.id,
            quoteRow: data.id,
        });
    };

    sortByColumnOrder = (a, b) => this.listColumnOrderMap[a.field] - this.listColumnOrderMap[b.field];
    setColumns = (listColumns) => {
        const columns = this.setColumnPrintHides(listColumns);
        this.setState({ listColumns: columns.sort(this.sortByColumnOrder) }, () => {
            this.setTargetingSectionY();
        });
    };

    getInvoicedStatus = (quote) => {
        if (!quote) return { text: '', color: '' };

        if (quote.fully_invoiced == 1) {
            return { text: this.tr('Invoiced'), color: colors.greenish_cyan };
        }
        for (let i = 0; i < (quote.headers || []).length; i++) {
            const header = quote.headers[i];
            if ((header.rows || []).findIndex((r) => (r.bills_id || 0) != 0) != -1) {
                return { text: this.tr('Partially invoiced'), color: colors.orangey_yellow };
            }
        }
        return { text: this.tr('Not invoiced'), color: colors.bluey_purple };
    };

    onGrandTotalPrintVisibilityChange = (e) => this.onColumnPrintVisibilityChange('quoteTotal', e.target.checked);
    onGrandTotalVisibilityChange = (e) => this.props.onGrandTotalVisibilityChange(!e.target.checked);

    showLogo = () => {
        return this.props.project?.show_logo == 1;
    };

    render() {
        const {
            editMode,
            onQuoteEdited,
            quote,
            totals,
            project,
            currencyFormatter,
            loadingQuotes,
            printMode,
            getEditableFields,
            renderField,
            grandTotalHidden,
            project: { charge_costest, type },
            canCreateInvoice,
        } = this.props;
        const { listColumns, columnSettingsAnchor, activeCell, editSliderData } = this.state;

        let columnsToUse = listColumns;
        if (printMode) {
            columnsToUse = (listColumns || []).filter((c) => !c.hiddenFromPrint);
        }

        const grandTotalPrintHidden = (quote?.print_exceptions || []).includes('quoteTotal');

        const { text: invoicedStatusText, color: invoicedStatusColor } = this.getInvoicedStatus(quote);
        return (
            <div ref={this.props.innerRef} className={`${styles.quote} ${loadingQuotes ? styles.loading : ''}`}>
                {
                    // this whole table thing is just to get padding on printed blank pages' top and bottom
                    // (with an empty header and footer)
                }
                <table cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div ref={this.topSection} className={`${styles.topSection}`}>
                                    <div className={styles.row}>
                                        <div className={styles.names}>
                                            {!!quote ? (
                                                <>
                                                    <div className={styles.input}>
                                                        <input
                                                            className={editMode ? styles.edit : ''}
                                                            name="project_name"
                                                            placeholder={project.name}
                                                            onChange={onQuoteEdited}
                                                            value={editMode ? quote?.project_name : `${quote?.project_name || project.name} (${project.project_id})`}
                                                        />
                                                        {editMode && <h1>{`(${project.project_id})`}</h1>}
                                                    </div>
                                                    <div className={styles.subtitle}>
                                                        {editMode ? <input className={editMode ? styles.edit : ''} name="name" value={quote?.name == "New quote" ? this.tr("New quote header") : quote?.name} onChange={onQuoteEdited} data-testid="quote-input-name" /> : <h1>{quote?.name == "New quote" ? this.tr("New quote header") : quote?.name}</h1>}
                                                    </div>
                                                    <div className={styles.tags}>
                                                        <StatusTag
                                                            text={quote?.active == 1 ? this.tr('Active') : this.tr('Inactive')}
                                                            color={quote?.active == 1 ? colors.dark_sky_blue : colors.steel}
                                                        />
                                                        {quote?.type == 2 && <StatusTag text={this.tr('Refund quote')} color={colors.dark_sky_blue} />}
                                                        {type == '1' && charge_costest == '1' && canCreateInvoice && <StatusTag text={invoicedStatusText} color={invoicedStatusColor} />}
                                                    </div>
                                                </>
                                            ) : (
                                                loadingQuotes && <img src={require('../dashboard/insights/img/loading.svg').default} />
                                            )}
                                        </div>
                                        <div className={styles.logoDates}>
                                            {this.showLogo() && (
                                                <div className={`${styles.logoContainer} ${editMode ? styles.editMode : ''}`}>
                                                    <Logo
                                                        editMode={editMode}
                                                        hideWhenNotInEditMode
                                                        company={project.companies_id ? project.companies_id : 1}
                                                        base64Encode={true}
                                                        onLogoLoaded={this.onLogoLoaded}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${styles.addresses} ${!editMode ? styles.readMode : ''}`}>
                                        <div>{this.renderToAddress()}</div>
                                        <div>{this.renderFromAddress()}</div>
                                        <div className={styles.dates}>{this.renderDates()}</div>
                                    </div>
                                    <Tooltip title={this.tr('Column visibility settings')}>
                                        <button onClick={this.openColumnSettings} className={styles.viewOptionsButton}>
                                            <Visibility />
                                            {this.tr('Columns')}
                                            <ArrowDropDownSharp />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className={styles.listContainer}>
                                    <QuoteList
                                        {...this.props}
                                        createPurchaseOrder={this.createPurchaseOrder}
                                        listColumns={columnsToUse}
                                        getEditableFields={getEditableFields}
                                        renderField={renderField}
                                        setActiveCell={this.setActiveCell}
                                        activeCell={activeCell}
                                        onListMounted={this.onListMounted}
                                        onDraggedRowDrop={this.onDraggedRowDrop}
                                        tr={this.tr}
                                        onColumnVisibilityChange={this.setColumns}
                                    />
                                    {!grandTotalHidden && (
                                        <div className={`${styles.totalRow} ${grandTotalPrintHidden ? styles.hiddenFromPrint : 'lol'}`}>
                                            {grandTotalPrintHidden && (
                                                <div className={styles.rowIndicators}>
                                                    <Tooltip title={this.tr('Hidden from print')}>
                                                        <div className={styles.hiddenFromPrint}>
                                                            <PrintDisabled />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <h2>{this.tr('Grand total')}</h2>
                                            <div className={styles.totalValues}>
                                                <div className={styles.totalValue}>
                                                    <h3>{this.tr('Subtotal')}</h3>
                                                    <p>{currencyFormatter.format(totals?.subtotal || 0)}</p>
                                                </div>
                                                {Number(totals?.discount || 0) > 0 && (
                                                    <div className={styles.totalValue}>
                                                        <h3>{this.tr('Discount')}</h3>
                                                        <p>-{currencyFormatter.format(totals?.discount || 0)}</p>
                                                    </div>
                                                )}
                                                <div>
                                                    <div className={styles.totalValue}>
                                                        <h3>{this.tr('Vat')}</h3>
                                                        <p>{`${currencyFormatter.format(totals?.vatTotal || 0)}`}</p>
                                                    </div>
                                                    {Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length > 0 && (
                                                        <div
                                                            className={styles.vatTotals}
                                                            style={{ height: (printMode ? 14 : 17) * Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length + 17 }}
                                                        >
                                                            <hr />
                                                            {Object.keys(totals?.vatTotals || {})
                                                                .sort((a, b) => Number(a) - Number(b))
                                                                .map((vat) => {
                                                                    const vatValue = totals?.vatTotals[vat] || 0;
                                                                    return vatValue == 0 ? null : (
                                                                        <div key={vat} className={styles.vatTotalRow}>
                                                                            <p>{`${vat} %:`}</p>
                                                                            <p>{`${currencyFormatter.format(vatValue)}`}</p>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h3>{this.tr('Total')}</h3>
                                                    <p>{currencyFormatter.format(totals?.total || 0)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Popover
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    transformOrigin={{ horizontal: 'right', vertical: -8 }}
                                    open={!!columnSettingsAnchor}
                                    anchorEl={columnSettingsAnchor}
                                    onClose={this.closeColumnSettings}
                                >
                                    <div className={styles.viewOptions}>
                                        <div className={styles.viewOptionRow}>
                                            <div>
                                                <h2>{this.tr('Print')}</h2>
                                            </div>
                                            <div>
                                                <h2>{this.tr('View')}</h2>
                                            </div>
                                            <div>
                                                <h2>{this.tr('Name')}</h2>
                                            </div>
                                        </div>
                                        {listColumns
                                            .filter((c) => !c.hideFromPrintOptions && !c.hidden)
                                            .map((column) => {
                                                const hidden = column.visible == false;
                                                const printCheckbox = (
                                                    <Checkbox
                                                        className={`${column.alwaysHiddenFromPrint ? styles.alwaysHidden : ''} ${!column.hiddenFromPrint && !hidden ? styles.active : ''}`}
                                                        disabled={hidden || column.alwaysHiddenFromPrint}
                                                        onChange={(e) => this.onColumnPrintVisibilityChange(column.field, e.target.checked)}
                                                        checked={!column.hiddenFromPrint && !hidden}
                                                    />
                                                );
                                                return (
                                                    <div className={styles.viewOptionRow}>
                                                        <Tooltip title={hidden ? this.tr('The column needs to be visible in order to show it in print.') : ''}>
                                                            <div>{printCheckbox}</div>
                                                        </Tooltip>
                                                        <div>
                                                            <Checkbox
                                                                className={!hidden ? styles.active : ''}
                                                                onChange={(e) => this.onColumnVisibilityChange(column.field, e.target.checked)}
                                                                checked={!hidden}
                                                            />
                                                        </div>
                                                        <div>
                                                            <p>{column.header}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div className={styles.viewOptionRow}>
                                            <Tooltip title={grandTotalHidden ? this.tr('Grand total needs to be visible in order to show it in print.') : ''}>
                                                <div>
                                                    {
                                                        <Checkbox
                                                            className={`${!grandTotalPrintHidden && !grandTotalHidden ? styles.active : ''}`}
                                                            disabled={grandTotalHidden}
                                                            onChange={this.onGrandTotalPrintVisibilityChange}
                                                            checked={!grandTotalPrintHidden && !grandTotalHidden}
                                                        />
                                                    }
                                                </div>
                                            </Tooltip>
                                            <div>
                                                <Checkbox className={!grandTotalHidden ? styles.active : ''} onChange={this.onGrandTotalVisibilityChange} checked={!grandTotalHidden} />
                                            </div>
                                            <div>
                                                <p>{this.tr('Grand total')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Popover>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
                <FieldEditSlider
                    onSave={editSliderData?.onSave}
                    item={editSliderData?.item}
                    fields={editSliderData?.fields}
                    title={this.tr('New visiting address')}
                    open={!!editSliderData}
                    onClose={this.closeEditSlider}
                />
            </div>
        );
    }
}

export default Quote;
