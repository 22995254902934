import React from 'react';
import moment from 'moment';
import { Add, Edit, AccessTime, Chat, CheckBox, Delete, RemoveRedEye, CheckBoxOutlined } from '@mui/icons-material';
import { withSnackbar } from 'notistack';
import { Popover } from '@mui/material';

import TaimerComponent from '../../../TaimerComponent';
import BlockTopBar from './BlockTopBar';
import ResourceDialog from '../../../dialogs/ResourceDialog';
import DataHandler from '../../../general/DataHandler';
import colors from '../../../colors';
import ConfirmationPopUp from '../../../resourcing/views/ResourcingGrid/components/ConfirmationPopUp';
import MyDayDateRangeModal from './MyDayDateRangeModal';
import MyDayList from './MyDayList';
import TasksListRow from './TasksListRow';

import './TasksList.css';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import InsightDropDown from '../../insights/InsightDropDown';
import { getAutocompleteDataForDialog } from '../../../resourcing/helpers';

const emptyImage = require('../../images/ActivitiesGraphic.svg').default;

const COLUMNS_KEY = 'my_day_tasks_list_columns';
const MODE_KEY = 'my_day_tasks_list_mode';
class TasksList extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'dashboard/my_day/components/TasksList');
        this.myDayList = React.createRef();

        this._hoverExitTimeout = null;
        this._hourTimer = null;
        this._prioTimer = null;

        const {
            functions: { checkPrivilege },
        } = this.context;

        this.rowMenuItems = this._noHover()
            ? [
                  {
                      icon: Edit,
                      label: this.tr('Edit task'),
                      action: (data) => this.openTaskDialog(data),
                      checkRight: (item) => this._hasWriteRight(),
                  },
                  {
                      icon: AccessTime,
                      action: this.startTimer,
                      label: this.tr('Start timer'),
                      checkRight: (item) => item.is_milestone != 1 && (checkPrivilege('workhours', 'write') || checkPrivilege('workhours', 'write_full')),
                  },
                  {
                      icon: Chat,
                      label: this.tr('Open chat'),
                      action: (data) => this.openTeamchat(data.projects_id),
                      checkRight: (item) => item.in_project_team == 1 && checkPrivilege('newsfeed', 'newsfeed'),
                  },
                  {
                      icon: CheckBoxOutlined,
                      label: this.tr('Mark own part as done'),
                      action: (data) => this.markOwnHoursAsDone(data.id),
                      checkRight: (item) => item.is_milestone != 1 && this._hasWriteRight(),
                  },
                  {
                      icon: CheckBox,
                      label: this.tr('Mark task as done'),
                      action: (data) => this.markTaskAsDone(data.id),
                      checkRight: (item) => item.is_milestone != 1 && checkPrivilege('projects', 'project_resourcing_write'),
                  },
                  {
                      icon: RemoveRedEye,
                      label: this.tr('View project resourcing'),
                      action: (data) => this.showProject(data.projects_id),
                      checkRight: (item) => checkPrivilege('projects', 'project_resourcing_read') || checkPrivilege('projects', 'own_resourcing_read'),
                  },
                  {
                      icon: Delete,
                      label: this.tr('Delete'),
                      color: colors.faded_red,
                      action: (data) => this.showDeleteConfirmation(data),
                      checkRight: (item) => this._hasWriteRight(),
                  },
              ]
            : [
                  {
                      icon: CheckBox,
                      label: this.tr('Mark task as done'),
                      action: (data) => this.markTaskAsDone(data.id),
                      checkRight: (item) => item.is_milestone != 1 && checkPrivilege('projects', 'project_resourcing_write'),
                  },
                  {
                      icon: Chat,
                      label: this.tr('Open chat'),
                      action: (data) => this.openTeamchat(data.projects_id),
                      checkRight: (item) => item.in_project_team == 1 && checkPrivilege('newsfeed', 'newsfeed'),
                  },
                  {
                      icon: RemoveRedEye,
                      label: this.tr('View project resourcing'),
                      action: (data) => {
                          this.showProject(data.projects_id);
                      },
                      checkRight: (item) => checkPrivilege('projects', 'project_resourcing_read') || checkPrivilege('projects', 'own_resourcing_read'),
                  },
                  {
                      icon: Delete,
                      label: this.tr('Delete'),
                      color: colors.faded_red,
                      action: (data) => this.showDeleteConfirmation(data),
                      checkRight: (item) => this._hasWriteRight(),
                  },
              ];

        this.rowHoverItems = [
            {
                icon: Edit,
                label: this.tr('Edit task'),
                action: (data) => this.openTaskDialog(data),
                checkRight: (item) => this._hasWriteRight(),
            },
            {
                icon: AccessTime,
                action: this.startTimer,
                label: this.tr('Start timer'),
                checkRight: (item) => item.is_milestone != 1 && (checkPrivilege('workhours', 'write') || checkPrivilege('workhours', 'write_full')),
            },
            {
                icon: CheckBoxOutlined,
                label: this.tr('Mark own part as done'),
                action: (data) => this.markOwnHoursAsDone(data.id),
                checkRight: (item) => item.is_milestone != 1 && this._hasWriteRight(),
            },
        ];

        const { tr } = this;

        this.modeItems = [
            {
                key: 'all',
                label: this.tr('All'),
                action: () => this._setMode('all'),
            },
            {
                key: 'overdue',
                label: this.tr('Overdue'),
                action: () => this._setMode('overdue'),
            },
            {
                key: 'in_progress',
                label: this.tr('In Progress'),
                action: () => this._setMode('in_progress'),
            },
            {
                key: 'today',
                label: this.tr('Due Today'),
                action: () => this._setMode('today'),
            },
            {
                key: 'this_week',
                label: this.tr('Due This Week'),
                action: () => this._setMode('this_week'),
            },
            {
                key: 'last_week',
                label: this.tr('Due Last Week'),
                action: () => this._setMode('last_week'),
            },
            {
                key: 'this_month',
                label: this.tr('Due This Month'),
                action: () => this._setMode('this_month'),
            },
        ];

        this.columns = [
            {
                field: 'actions',
                name: 'actions',
                header: '',
                width: 50,
                content: this._hasWriteRight() ? this._renderAddButton() : null,
                resizeable: false,
                moveable: false,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'description_type',
                name: 'description_type',
                header: this.tr('Description/Type'),
                width: 200,
                resizeable: true,
                moveable: false,
                hideable: false,
                showMenu: true,
                hideColumnsFromMenu: true,
            },
            {
                field: 'priority_status',
                name: 'priority_status',
                header: this.tr('Priority/Status'),
                width: 160,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: true,
                hideColumnsFromMenu: true,
            },
            {
                field: 'tracked_resourced',
                name: 'tracked_resourced',
                header: this.tr('Tracked/Resourced'),
                width: 180,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: true,
                hideColumnsFromMenu: true,
            },
            {
                field: 'deadline',
                name: 'deadline',
                header: this.tr('Deadline'),
                width: 130,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: true,
                hideColumnsFromMenu: true,
            },
            {
                field: 'project_account',
                name: 'project_account',
                header: this.tr('Project'),
                width: 200,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: true,
                hideColumnsFromMenu: true,
            },
        ];

        const selectedColumns = [...new Set(this._getColumnsFromLS(this.columns.map((c) => c.field)))];

        const mode = this._getModeFromLS();

        this.state = {
            hoverId: null,
            loadingId: null,
            selectedColumns,
            mode,
            trackingId: null,
            timerLabel: '00:00',
            trackerStart: null,
            dataLoaded: false,
            data: [],
            employees: [],
            autoCompleteData: {
                customers: [],
                projects: [],
                pipelines: [],
                skills: [],
                priorities: [],
                project_drop: [],
                default_priority: 3,
                resourcing_disable_skill: false,
                resourcing_disable_priority: false,
            },
            companies: [],
            company: context.functions.getCompany('projects', 'project_resourcing_read'),
            pageData: {
                perPage: 30,
                totalCount: 0,
                pageCount: 1,
                page: 1,
                sortby: 'priority_status',
                sortasc: false,
            },
            updatedValues: {},
            selectedPriorities: [],
            hasReadRight: true,
            hasWriteRight: true,
            showMatchesAndChildren: false,
            showWholeTrees: true,
            filters: this._getFiltersForMode(mode),
            loading: true,
            searchQuery: '',
        };

        this.stickySearchKey = 'my_task_list';
    }

    _hasWriteRight = () => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        return checkPrivilege('projects', 'project_resourcing_write') || checkPrivilege('projects', 'own_resourcing_write');
    };

    _setTimerFromProps = () => {
        const { timers } = this.props;
        let trackerStart;
        let trackingId;
        if ((timers || []).length > 0) {
            (timers || []).forEach((timer) => {
                if (timer.wh_projects_resource) {
                    trackerStart = moment.utc(timer.start, 'YYYY-MM-DD HH:mm:ss').local();
                    trackingId = 't-' + timer.wh_projects_resource;
                }
            });
        }
        this.setState({ trackerStart, trackingId }, () => {
            if (trackingId) {
                this._hourTimer = setInterval(() => {
                    const { trackerStart } = this.state;
                    const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                    const timerLabel = this._toHHMMSS(secondsSinceStart);
                    this.setState({ timerLabel });
                }, 1000);
            }
        });
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.timers != this.props.timers) {
            this._setTimerFromProps();
        }
    };

    componentDidMount = () => {
        super.componentDidMount();
        this._setTimerFromProps();
        this.initializeStickySearch();
        window.addEventListener('taskSaved', this._getData);
        if (this.state.company !== false) {
            this._getAutoCompleteData();
        }

        DataHandler.get({
            url: `subjects/companies_with_project_right/project_resourcing_read`,
        }).done((companies) => {
            const state = {
                companies: companies,
                hasReadRight: companies.length > 0,
            };
            let callback = () => {};
            if (this.state.company === false) {
                state.company = companies.length > 0 ? companies[0].id : false;
                callback = () => {
                    this._getAutoCompleteData();
                    this._getData();
                };
            }
            this.setState(state, callback);
        });
        DataHandler.get({
            url: `subjects/companies_with_project_right/project_resourcing_write`,
        }).done((companies) => this.setState({ hasWriteRight: companies.length > 0 }));
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('taskSaved', this._getData);
    };

    initializeStickySearch = () => {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` })
            .done((response, _, request) => {
                if (request.status !== 200) {
                    this._getData();
                    return;
                }
                this.setState(
                    {
                        ...response,
                        pageData: { ...this.state.pageData, perPage: response.perpage || this.state.pageData.perPage, sortby: response.sort.name, sortasc: response.sort.asc },
                    },
                    () => this._getData()
                );
            })
            .fail(() => {
                this._getData();
            })
            .always(() => {
                this.setState({ stickySearchInitialized: true });
            });
    };

    saveStickySearch = () => {
        const filters = {
            sort: { name: this.state.pageData.sortby, asc: this.state.pageData.sortasc },
            perpage: this.state.pageData.perPage,
        };
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}` }, { search: filters });
    };

    _getEmployees = () => {
        DataHandler.get({ url: 'employees' }).done((employees) => {
            employees.forEach((e) => {
                if (e.companies_id < 1) {
                    e.label += ` (${this.tr('freelancer')})`;
                    e.employee += ` (${this.tr('freelancer')})`;
                }
            });
            employees.unshift({ id: '', label: this.tr('All') });
            employees.push({ id: 0, employee: this.tr('Unassigned') });
            employees = employees.map((el) => ({ label: el.employee, ...el }));
            this.setState({ employees });
        });
    };

    _getModeFromLS = () => {
        let mode = 'all';
        try {
            mode = localStorage.getItem(MODE_KEY) || 'all';
        } catch (e) {}
        return mode;
    };

    _getColumnsFromLS = (defaultValue) => {
        let item = defaultValue;
        try {
            item = localStorage.getItem(COLUMNS_KEY) || defaultValue;
        } catch (e) {}
        if (!Array.isArray(item)) {
            item = item.split(',');
        }
        return item;
    };

    _saveColumns = (selectedColumns) => {
        this.setState({ selectedColumns });
        try {
            localStorage.setItem(COLUMNS_KEY, selectedColumns.join(','));
        } catch (e) {}
    };

    _getDropdownColumns = () => {
        return this.columns.filter((col) => {
            if (!col.moveable) return false;
            if (col.checkRight) {
                return col.checkRight();
            }
            return true;
        });
    };

    _toggleColumn = (column) => {
        const selectedColumns = [...this.state.selectedColumns];
        const index = selectedColumns.indexOf(column.name);
        const newIndex = this.columns.findIndex((c) => c.field == column.field);
        index == -1 ? selectedColumns.splice(newIndex, 0, column.name) : selectedColumns.splice(index, 1);
        this._saveColumns(selectedColumns);
    };

    _getActiveColumns = () => {
        const { selectedColumns } = this.state;
        return selectedColumns.reduce((filtered, col) => {
            const found = this.columns.find((obj) => obj.name == col);
            if (found) {
                filtered.push(found);
            }
            return filtered;
        }, []);
    };

    _getFiltersForMode = (mode) => {
        let startdate = '2010-01-01';
        let enddate = '2030-01-01';
        let status = [1, 2, 4, 5];
        const today = new Date();
        switch (mode) {
            case 'overdue':
                status = 2;
                break;
            case 'in_progress':
                status = [1, 4];
                break;
            case 'last_week':
                {
                    const lastWeek = moment(today).subtract(1, 'week').toDate();
                    startdate = moment(
                        startOfWeek(lastWeek, {
                            weekStartsOn: this.context.calendar.startOfWeek,
                        })
                    ).format('YYYY-MM-DD');
                    enddate = moment(
                        endOfWeek(lastWeek, {
                            weekStartsOn: this.context.calendar.startOfWeek,
                        })
                    ).format('YYYY-MM-DD');
                }
                break;
            case 'this_week':
                startdate = moment(
                    startOfWeek(today, {
                        weekStartsOn: this.context.calendar.startOfWeek,
                    })
                ).format('YYYY-MM-DD');
                enddate = moment(
                    endOfWeek(today, {
                        weekStartsOn: this.context.calendar.startOfWeek,
                    })
                ).format('YYYY-MM-DD');
                break;
            case 'this_month':
                startdate = moment(startOfMonth(today)).format('YYYY-MM-DD');
                enddate = moment(endOfMonth(today)).format('YYYY-MM-DD');
                break;
            case 'today':
                startdate = moment(today).format('YYYY-MM-DD');
                enddate = moment(today).format('YYYY-MM-DD');
                break;
        }
        return { startdate, enddate, status };
    };

    _setMode = (mode) => {
        this.setState({ mode, filters: this._getFiltersForMode(mode) }, () => {
            this.setPage(1);
        });
        try {
            mode = localStorage.setItem(MODE_KEY, mode);
        } catch (e) {}
    };

    _getData = () => {
        this.setState({ loading: true }, () => {
            const {
                autoCompleteData: { priorities },
                filters,
                searchQuery,
                pageData,
                selectedPriorities,
                sort,
            } = this.state;

            return DataHandler.post(
                {
                    url: `resourcing/simple_tasks`,
                    ...filters, // startdate, enddate, status
                    freetext: searchQuery,
                    priorities: selectedPriorities.length == 5 ? [] : selectedPriorities,
                    search_enddate: 1, // 0 == ettii osuuko aikavälille eli niinku on resursointimoduulissaki || 1 == ettii loppupäivän mukaan
                    page: pageData.page,
                    perpage: pageData.perPage,
                },
                { sort: { name: pageData.sortby, asc: pageData.sortasc } }
            ).done((data) => {
                const totalCount = data.total_count || 0;
                data.data.forEach((el) => {
                    const prior = priorities.find((x) => x.id == el.priorities_id);
                    if (prior) {
                        el.priority = this.tr(prior.name);
                        el.priority_icon = prior.icon;
                    }
                });
                this.saveStickySearch();
                this.setState({
                    data: data.data,
                    updatedValues: {},
                    pageData: { ...this.state.pageData, totalCount, pageCount: Math.ceil(totalCount) },
                    dataLoaded: true,
                    loading: false,
                });
            });
        });
    };

    _getAutoCompleteData = async () => {
        const { tr } = this;
        const { company } = this.state;

        const data = await getAutocompleteDataForDialog(company);

        this.setState({
            autoCompleteData: data,
            employees: data.employees,
        });
    };

    _closePopup = () => {
        this.props.setPopUp(null);
    };

    _deleteTask = (row, deleteInstances = 'this') => {
        const { enqueueSnackbar } = this.props;
        DataHandler.delete({
            url: row.is_milestone == 1 ? `resourcing/delete_milestone/${row.id}` : `resourcing/delete_task/${row.id}`,
            delete_others: deleteInstances,
        })
            .done((response) => {
                this._getData();

                if (response.success) {
                    enqueueSnackbar(this.tr(`Task deleted successfully!`), {
                        variant: 'success',
                    });
                } else if (response.error === 'workhours') {
                    enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(this.tr(`Deleting task failed!`), {
                        variant: 'error',
                    });
                }
            })
            .catch(() => {
                enqueueSnackbar(this.tr(`Deleting task failed!`), {
                    variant: 'error',
                });
            });
    };

    shouldComponentUpdate = (newProps, newState) => {
        return newState != this.state || newProps.timers != this.props.timers;
    };

    _showDeleteSelections = (row) => {
        const popUpComponent = (
            <ConfirmationPopUp
                header={this.tr('Which instances of this task would you like to delete?')}
                desc={null}
                leftButtons={[
                    {
                        label: this.tr('Cancel'),
                        style: { backgroundColor: 'transparent', color: '#34495e' },
                        onClick: this._closePopup,
                    },
                ]}
                rightButtons={[
                    {
                        label: this.tr('All'),
                        style: { backgroundColor: '#2d9ff7', color: 'white' },
                        onClick: () => {
                            this._closePopup();
                            this._deleteTask(row, 'all');
                        },
                    },
                    {
                        label: this.tr('Future'),
                        style: { backgroundColor: '#2d9ff7', color: 'white' },
                        onClick: () => {
                            this._closePopup();
                            this._deleteTask(row, 'future');
                        },
                    },
                    {
                        label: this.tr('Only this'),
                        style: { backgroundColor: '#2d9ff7', color: 'white' },
                        onClick: () => {
                            this._closePopup();
                            this._deleteTask(row);
                        },
                    },
                ]}
            />
        );
        this.props.setPopUp(popUpComponent);
    };

    showDeleteConfirmation = (row) => {
        const task = row.description;
        const popUpComponent = (
            <ConfirmationPopUp
                header={this.tr('Are you sure?')}
                desc={this.tr('Are you sure you want to remove task "${task}"? Removing this deletes data for this task across all your accounts. This change cannot be undone.', { task })}
                leftButtons={[
                    {
                        label: this.tr('Cancel'),
                        style: { backgroundColor: 'transparent', color: '#34495e' },
                        onClick: this._closePopup,
                    },
                ]}
                rightButtons={[
                    {
                        label: this.tr('Delete'),
                        style: { backgroundColor: '#dc4054', color: 'white' },
                        onClick: () => {
                            if (row.recurrence_parent_id > 0) {
                                this._showDeleteSelections(row);
                            } else {
                                this._closePopup();
                                this._deleteTask(row);
                            }
                        },
                    },
                ]}
            />
        );
        this.props.setPopUp(popUpComponent);
    };

    markOwnHoursAsDone = (taskId) => {
        const { enqueueSnackbar } = this.props;
        const { userObject } = this.context;
        let data = {
            users_id: userObject.usersId,
        };
        DataHandler.post({ url: `resourcing/mark_hours_done/${taskId}` }, data)
            .done(() => {
                this._getData();
                enqueueSnackbar(this.tr(`Own hours marked as done!`), {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar(this.tr(`Saving task failed!`), {
                    variant: 'error',
                });
            });
    };

    markTaskAsDone = (taskId) => {
        const { enqueueSnackbar } = this.props;
        DataHandler.post({ url: `resourcing/mark_task_done/${taskId}` })
            .done(() => {
                this._getData();
                enqueueSnackbar(this.tr(`Task marked as done!`), {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar(this.tr(`Saving task failed!`), {
                    variant: 'error',
                });
            });
    };

    showProject = (projectId) => {
        this.context.functions.updateView({ module: 'projects', action: 'view', id: projectId, tab: 'resourcing' });
    };

    openTeamchat = (projectId) => {
        const {
            functions: { openTeamChat },
        } = this.context;

        openTeamChat({
            module: 'projects',
            action: 'view',
            id: projectId,
        });
    };

    _toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num / 60) % 60;
        var seconds = sec_num % 60;

        return [hours, minutes, seconds]
            .map((v) => (v < 10 ? '0' + v : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    };

    _showTimerConfirmation = (data) => {
        this.props.setPopUp(
            <ConfirmationPopUp
                header={this.tr('Are you sure you want to end your current timer?')}
                desc={this.tr("If you start this timer, your current running timer will stop and you'll be able to save that hour entry.")}
                rightButtons={[
                    {
                        label: this.tr('End current timer'),
                        style: { backgroundColor: '#dc4054', color: 'white' },
                        onClick: async () => {
                            this.props.setPopUp(null);

                            this.context.functions.naviStopTimeTracker(async () => {
                                if (this.context.functions.naviStartTimeTracker) {
                                    const startData = await this.context.functions.naviStartTimeTracker(
                                        {
                                            customers_id: data.customers_id,
                                            projects_id: data.projects_id,
                                            wh_projects_resource: data.id.replace(/[^0-9]/g, ''),
                                        },
                                        true
                                    );
                                    if (!startData) return;
                                }
                                this.setState({ trackerStart: moment(), trackingId: data.id }, () => {
                                    this._hourTimer = setInterval(() => {
                                        const { trackerStart } = this.state;
                                        const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                                        const timerLabel = this._toHHMMSS(secondsSinceStart);
                                        this.setState({ timerLabel });
                                    }, 1000);
                                });
                            });
                        },
                    },
                ]}
                leftButtons={[
                    {
                        style: { backgroundColor: 'transparent', color: '#34495e' },
                        label: this.tr('Cancel'),
                        onClick: () => {
                            this.props.setPopUp(null);
                        },
                    },
                ]}
            />
        );
    };

    startTimer = async (data) => {
        if (this.props.timers.length > 0) {
            this._showTimerConfirmation(data);
            return;
        } else {
            if (this.context.functions.naviStartTimeTracker) {
                const startData = await this.context.functions.naviStartTimeTracker({
                    customers_id: data.customers_id,
                    projects_id: data.projects_id,
                    wh_projects_resource: data.id.replace(/[^0-9]/g, ''),
                });
                if (!startData) return;
            }
            this.setState({ trackerStart: moment(), trackingId: data.id }, () => {
                this._hourTimer = setInterval(() => {
                    const { trackerStart } = this.state;
                    const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                    const timerLabel = this._toHHMMSS(secondsSinceStart);
                    this.setState({ timerLabel });
                }, 1000);
            });
        }
    };

    endTimer = async () => {
        return await this.context.functions.naviStopTimeTracker();
    };

    _renderAddButton = () => {
        return (
            <div className="add-button-container">
                <button onClick={this._addNewTask}>
                    <Add className="icon" />
                </button>
            </div>
        );
    };

    _addNewTask = async () => {
        const taskData = {
            users_hours: [
                {
                    id: -1,
                    users_id: this.context.userObject.usersId,
                    hours_done: 0,
                    hours: 1,
                },
            ],
        };

        try {
            const lastEntry = await DataHandler.get({ url: 'resourcing/lastEntry' });

            if (lastEntry.projects_id) {
                taskData.projects_id = lastEntry.projects_id;
            }
            if (lastEntry.companies_id) {
                taskData.companies_id = lastEntry.companies_id;
            }
        } catch (error) {}

        this.openTaskDialog(taskData);
    };

    _noHover = () => {
        return window.matchMedia('(hover: none)').matches;
    };

    onRowHoverEnter = (hoverId) => {
        if (this._noHover()) {
            return;
        }

        if (this._hoverExitTimeout) {
            clearTimeout(this._hoverExitTimeout);
            this._hoverExitTimeout = null;
        }
        this.setState({ hoverId });
    };

    onRowHoverExit = () => {
        if (this._noHover()) {
            return;
        }

        this._hoverExitTimeout = setTimeout(() => {
            this.setState({ hoverId: null });
        }, 200);
    };

    checkPrivilege = (group, perm, company) => {
        if (this.props.checkPrivilege) return this.props.checkPrivilege(group, perm);

        return this.context.functions.checkPrivilege(group, perm, company);
    };

    openTaskDialog = (data) => {
        if (!data.id) {
            this.context.functions.addResource(data);
        } else {
            this.setState({ loadingId: data.id }, () => {
                DataHandler.get({
                    url: 'resourcing/task/' + data.id.replace(/[^0-9]/g, ''),
                    is_milestone: data.is_milestone,
                })
                    .done((resource) => {
                        this.setState({ loadingId: null }, () => {
                            if (!resource.id) {
                                return;
                            } else {
                                this.context.functions.addResource(resource);
                            }
                        });
                    })
                    .fail(() => {
                        this.setState({ loadingId: null });
                    });
            });
        }
    };

    _renderRowEditPopUp = () => {
        const { rowEditData } = this.state;
        if (!rowEditData) return null;
        const { anchor, component } = rowEditData;
        return (
            <Popover
                open={Boolean(anchor)}
                elevation={4}
                anchorEl={anchor}
                onClose={() => this.setState({ rowEditData: null })}
                anchorOrigin={{
                    vertical: 48,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {component}
            </Popover>
        );
    };

    _showSnackbar = (message, success) => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    _stopLoading = () => {
        this.setState({ loading: false, updatedValues: {} });
    };

    _togglePriority = (prio) => {
        this._prioTimer && clearTimeout(this._prioTimer);
        const selectedPriorities = [...this.state.selectedPriorities];
        const index = selectedPriorities.indexOf(prio.id);
        index == -1 ? selectedPriorities.push(prio.id) : selectedPriorities.splice(index, 1);
        this.setState({ selectedPriorities }, () => {
            this._prioTimer = setTimeout(() => {
                this.setPage(1);
            }, 500);
        });
    };

    _renderViewOptionsButton = () => {
        const { selectedPriorities, autoCompleteData } = this.state;

        let selection = this.tr('All');
        const names = selectedPriorities.map((prioId) => this.tr(autoCompleteData.priorities.find((prio) => prio.id == prioId).name));

        if (names.length == 0) {
            selection = this.tr('All');
        } else if (names.length == 1) {
            selection = names[0];
        } else {
            selection = `${names.length} ${this.tr('selected')}`;
        }

        return (
            <InsightDropDown
                style={{ marginRight: 10 }}
                tabs={autoCompleteData.priorities}
                titleLabel={selection}
                selected={undefined}
                title={this.tr('Priority')}
                customComponent={(autoCompleteData.priorities || []).map((prio) => {
                    return (
                        <div key={prio.id} onClick={() => this._togglePriority(prio)} className="column-options-dropdown-item">
                            <div className="check-box">{selectedPriorities.indexOf(prio.id) != -1 && <CheckBox className="icon" />}</div>
                            {this.tr(prio.name)}
                        </div>
                    );
                })}
            />
        );
    };

    _setPriority = (priority, task) => {
        const updatedValues = {
            ...this.state.updatedValues,
            [task.id]: {
                priorities_id: priority.id,
            },
        };

        this.setState({ loading: true, updatedValues }, () => {
            DataHandler.post(
                { url: `resourcing/edit_task/${task.id}` },
                {
                    priorities_id: priority.id,
                    type: task.is_milestone == 1 ? 'milestone' : 'task',
                    parent_id: task.parent_id,
                }
            )
                .done(() => {
                    this._showSnackbar(this.tr('Changes saved successfully!'), true);
                    window.dispatchEvent(new Event('taskSaved'));
                    setTimeout(() => {
                        this._getData();
                    }, 1000);
                })
                .fail(() => this._stopLoading());
        });
    };

    _setDates = (dateRange, task) => {
        const updatedValues = {
            ...this.state.updatedValues,
            [task.id]: {
                startdate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                enddate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            },
        };

        this.setState({ loading: true, updatedValues, rowEditData: null }, () => {
            DataHandler.post(
                { url: `resourcing/edit_task/${task.id}` },
                {
                    startdate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                    enddate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                    type: task.is_milestone == 1 ? 'milestone' : 'task',
                    parent_id: task.parent_id,
                }
            )
                .done(() => {
                    this._showSnackbar(this.tr('Changes saved successfully!'), true);
                    window.dispatchEvent(new Event('taskSaved'));
                    setTimeout(() => {
                        this._getData();
                    }, 1000);
                })
                .fail(() => this._stopLoading());
        });
    };

    _getEditPopUpComponent = (column, data) => {
        switch (column) {
            case 'priority_status':
                return (this.state.autoCompleteData.priorities || []).map((priority) => {
                    return (
                        <div
                            onClick={() => {
                                this.setState({ rowEditData: null });
                                this._setPriority(priority, data);
                            }}
                            className="my-day-task-priority-row"
                        >
                            <img src={priority.icon} alt="" />
                            {this.tr(priority.name)}
                        </div>
                    );
                });
            case 'deadline': {
                const dateRange = {
                    startDate: data.start_date && moment(data.start_date, 'YYYY-MM-DD').toDate(), // TODO check one day tasks
                    endDate: data.end_date && moment(data.end_date, 'YYYY-MM-DD').toDate(),
                    key: 'selection',
                };
                return (
                    <MyDayDateRangeModal
                        initialDateRange={dateRange}
                        mode={data.is_one_day_task || data.is_milestone == 1 ? 'date' : 'daterange'}
                        saveChanges={(dateRange) => this._setDates(dateRange, data)}
                    />
                );
            }
            default:
                return null;
        }
    };

    showEditPopUp = (anchor, column, data) => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        if (column == 'tracked_resourced') {
            if (!(checkPrivilege('workhours', 'write') || checkPrivilege('workhours', 'write_full')) || data.is_milestone == 1) {
                return;
            }
            this.context.functions.addHours(
                {
                    customer: {
                        id: data.customers_id || 0,
                    },
                    project: {
                        id: data.projects_id || 0,
                    },
                    wh_projects_resource: {
                        id: (data.id || 0).replace(/[^0-9]/g, ''),
                    },
                },
                { parentComponent: 'MyDay - Task List', afterSaveCallback: this._onHourEntrySaved }
            );
            return;
        }

        if (!this._hasWriteRight()) return;

        this.setState({
            rowEditData: {
                anchor,
                data,
                component: this._getEditPopUpComponent(column, data),
            },
        });
    };

    _onHourEntrySaved = () => {
        setTimeout(this._getData, 1000);
    };

    _getEmptyOverlayContent = () => {
        const { hasWriteRight } = this.state;
        return hasWriteRight ? (
            <p>
                {this.tr('Nothing to do ?')} <a onClick={this._addNewTask}>{this.tr('Add a task')}</a>
            </p>
        ) : (
            <p>{this.tr('There are no tasks')}</p>
        );
    };

    _searchTimeout;

    _onSearchChanged = (searchQuery) => {
        this.setState({ loading: true });
        this._searchTimeout && clearTimeout(this._searchTimeout);
        this._searchTimeout = setTimeout(() => {
            this.setState({ searchQuery }, () => {
                this.setPage(1);
            });
        }, 1000);
    };

    onPageSettingsChange = (pageData) => {
        this.setState({ pageData: { ...this.state.pageData, ...pageData } }, () => this._getData());
    };

    onSortRows = (sortby, sortasc) => {
        this.setState({ pageData: { ...this.state.pageData, sortby, sortasc } }, () => this.setPage(1));
    };

    setPage = (page) => {
        this.onPageSettingsChange({ page });
    };

    render() {
        if (!this.state.stickySearchInitialized) {
            return null;
        }
        const { data, autoCompleteData, hoverId, loadingId, trackingId, timerLabel, dataLoaded, mode, loading, pageData, updatedValues } = this.state;

        return (
            <div className="my-day-tasks-list">
                <BlockTopBar
                    loading={loading}
                    selected={mode}
                    dropdownItems={this.modeItems}
                    dropdownTitle={this.tr('Status')}
                    title={this.tr('My Tasks')}
                    allowSearch={true}
                    onSearchChanged={this._onSearchChanged}
                    extraTopBarComponents={this._renderViewOptionsButton()}
                    viewButton={{
                        title: this.tr('Edit columns'),
                        items: this._getDropdownColumns(),
                        selectedItems: this.state.selectedColumns,
                        onItemClick: this._toggleColumn,
                    }}
                />
                <MyDayList
                    ref={this.myDayList}
                    data={data}
                    sharedData={{
                        hoverId,
                        loadingId,
                        trackingId,
                        hoverItems: this.rowHoverItems,
                        timerLabel,
                        endTimer: this.endTimer,
                        menuItems: this.rowMenuItems,
                        priorities: autoCompleteData.priorities,
                        showEditPopUp: this.showEditPopUp,
                        openTaskDialog: this.openTaskDialog,
                        updatedValues,
                    }}
                    onSortRows={this.onSortRows}
                    rowProps={{
                        onMouseEnter: this.onRowHoverEnter,
                        onMouseLeave: this.onRowHoverExit,
                    }}
                    showPageSelector={true}
                    pageCount={Math.ceil(pageData.totalCount / pageData.perPage)}
                    totalCount={pageData.totalCount}
                    perpage={pageData.perPage}
                    page={pageData.page}
                    controlPage={true}
                    onPerPageChange={(perPage) => {
                        this.onPageSettingsChange({ perPage, page: 1 });
                    }}
                    onPageChange={(page) => {
                        this.onPageSettingsChange({ page: page });
                    }}
                    columns={this._getActiveColumns()}
                    onColumnOrderChange={this._saveColumns}
                    emptyOverlayData={{
                        image: emptyImage,
                        content: this._getEmptyOverlayContent(),
                    }}
                    showEmptyOverlay={(data || []).length == 0 && dataLoaded}
                    listRowType={TasksListRow}
                />
                {this._renderRowEditPopUp()}
            </div>
        );
    }
}

export default withSnackbar(TasksList);
