import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "./../../general/OutlinedField";
import TimeOutlinedField from "./../../general/TimeOutlinedField";
import DataList from '../../general/DataList';
import UserSelect from '../../general/FilterDropdownUser'
import { formatInputNumber } from './../../helpers';
import { Switch, MenuItem, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Chip } from '@mui/material';
import { Clear } from "@mui/icons-material";
import _ from 'lodash';
import './SettingsGrid.css';
import cn from 'classnames';

const styles = {
    bigAvatar: {
        width: 105,
        height: 105,
    },
    formControlLabel: {
        flex: 1
    }
};

class DataListSetting extends Component {
	onChange = (value, { action }) => {
		if (action === "select-option")
		{
			const e = {
				target: {
					name: this.props.name,
					value: value.value,
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
		else if (action === "pop-value")
		{
			const e = {
				target: {
					name: this.props.name,
					value: '',
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
	}

	render() {
        const { options, value, hide } = this.props;
        
        const val = options.find(x => x.value === value);

		if (hide)
            return null;

		return <DataList {...this.props} 
			options={options}
            onChange={this.onChange}
            value={val} />
	}
}
class SettingsGrid extends Component {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            loaded: true,
        }
    }

    onChange = (e) => {
        const { item, settings } = this.props;

        let setting = null;
        let isRadioGroup = false;
        if (Array.isArray(settings) && settings[0].type === "radiogroup") {
            setting = settings[0].options.find(x => x.value == e.target.value);
            isRadioGroup = true;
        } else {    
            setting = settings.find(x => x.name === e.target.name);
        }
        if (setting.locked)
            return;

        if (isRadioGroup) {
            this.props.onChange(e.target.value);
        } else {
            const updated = {
                ...item,
                [e.target.name]: e.target.value,
            };
    
            this.props.onChange(updated);
        }


    }

    onChangeBool = (e) => {
        const { item, settings } = this.props;

        const setting = settings.find(x => x.name === e.target.name);

        if (setting.locked)
            return;

        const value = e.target.checked ? 1 : 0;

        const updated = {
            ...item,
            [e.target.name]: value,
        };

        this.props.onChange(updated, e.target.name, value);
    }

    isError = (field) => {
        const { errors = {} } = this.props;

        return errors[field];
    }

    onChipAdd = (e) => {
        if (Array.isArray(e.target.value))
            return;

        const { item, settings } = this.props;

        const field = settings.find(x => x.name == e.target.name);
        const chip = field.options.find(x => x.value == e.target.value);

        const newChips =  item[e.target.name] ? item[e.target.name].concat(chip) : [chip];

        const updated = {
            ...item,
            [e.target.name]: newChips,
        };

        this.props.onChange(updated);
    }

    onChipDelete = (name, value) => {
        const { item } = this.props;
        let chips = _.cloneDeep(item[name])

        if (chips.length > 1) {
            const chipIndex = chips.findIndex(x => x.value == value);
            if (chipIndex > -1)
                chips.splice(chipIndex, 1);
            else    
                return;
        }
        else {
            chips = [];
        }

        const updated = {
            ...item,
            [name]: chips,
        };

        this.props.onChange(updated);
    }

    renderSettings = (setting) => {
        const { item, company, classes } = this.props;

        if (!setting)
            return null;
        
        const { hideCond } = setting;

        if (hideCond && hideCond(item))
            return null;
            
        let textValue = "";
        if(setting.type === "text") {
            textValue = item[setting.name];
            if (setting.name === "defaultpenaltyinterest" || setting.name === "defaultvat")
                textValue = formatInputNumber(item[setting.name]);
            
            if (setting.name === "dailyworkinghours")
                textValue = formatInputNumber(item[setting.name], "hours");
        }
        
        //i dont know why this does not work in switch/case. React does not find render function in component and it just crashes
        if (setting.type === 'component' && setting.component) {
            const Component = setting.component;

            return <Component key={setting.key || setting.name} name={setting.name} value={setting.name && item[setting.name]} company={company} onChange={this.onChange} isError={this.isError} />
        }
        switch (setting.type)
        {
            case "title":
                return <div key={setting.key} className={cn("full settingstitle", setting.className)}><h3>{setting.label}</h3>{setting.subtext && <p>{setting.subtext}</p>}</div>
                case "time":
                return <div key={setting.key || setting.name} className={setting.className}>
                    <TimeOutlinedField disabled={setting.locked} label={setting.label} name={setting.name} value={item[setting.name]} fullWidth onChange={this.onChange} validation={setting.validation} error={this.isError(setting.name)} />
                </div>
            case "text":
                return <div key={setting.key || setting.name} className={setting.className}>
                    <OutlinedField disabled={setting.locked} label={setting.label} name={setting.name} value={textValue} fullWidth onChange={this.onChange} validation={setting.validation} error={this.isError(setting.name)} />
                </div>
            case "select":
              return <div key={setting.key || setting.name} className={setting.className}>
                    <OutlinedField select disabled={setting.locked} label={setting.label} name={setting.name} value={item[setting.name]} fullWidth onChange={this.onChange} validation={setting.validation} error={this.isError(setting.name)}>
                        {setting.options.map(({value, label}) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
                    </OutlinedField>
                  </div>              
            case "datalist":
                return  <div key={setting.key || setting.name} className={setting.className}>
                        <DataListSetting isDisabled={setting.locked} label={setting.label} value={item[setting.name]} name={setting.name} onChange={this.onChange} options={setting.options} />
                    </div>
            case "user":
                return  <div key={setting.key || setting.name} className={setting.className}>
                            <UserSelect isDisabled={setting.locked} label={setting.label} name={setting.name} value={item[setting.name]} onChange={this.onChange} />
                        </div>
            case "check":
                return  <div key={setting.key || setting.name} className={cn("SettingsCheck", setting.className)}>
                        <div>{setting.label}</div>
                        <Switch color="primary" disabled={setting.locked} name={setting.name} value={1} checked={item[setting.name] == 1} onChange={this.onChangeBool} /> 
                    </div>
            case "empty":
                return <div />
            case "radiogroup":
                return <div>
                    <FormControl>
                        <FormLabel>{setting.formLabel}</FormLabel>
                        <RadioGroup defaultValue={setting.defaultValue} value={setting.value} onChange={this.onChange}>
                            {setting.options.map(option => {
                                return (
                                    <FormControlLabel labelPlacement={option.labelPlacement} label={option.label} value={option.value} control={<Radio />} classes={{label: classes.formControlLabel}} />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>
            case "chipSelect":
                return <div key={setting.key || setting.name} className={setting.className}>
                      <OutlinedField select disabled={setting.locked} label={setting.label} name={setting.name} value={item[setting.name]} fullWidth onChange={this.onChipAdd} validation={setting.validation} error={this.isError(setting.name)}>
                          {setting.options.map(({value, label}) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
                      </OutlinedField>
                        <div className={setting.chips.length > 0 ? "settings-selected-chips" : ""}>
                            {setting.chips.map((row) => (
                            <Chip
                                id={row.value}
                                key={row.value}
                                label={row.label}
                                name={setting.name}
                                className="square chip"
                                onDelete={() => this.onChipDelete(setting.name, row.value)}
                                deleteIcon={<Clear />}
                            />
                            ))}
                        </div>
                    </div>   
            case "labeltext":
                return <div key={setting.key || setting.name} className={cn("SettingsLabeltext", setting.className)}>
                    <div>{setting.formLabel}</div>
                    <OutlinedField disabled={setting.locked} label={setting.label} name={setting.name} value={item[setting.name]} onChange={this.onChange} validation={setting.validation} error={this.isError(setting.name)} />
                </div>   
            default:
                return null;
        }
    }


    render() {
        const { settings } = this.props;
        
        return <React.Fragment>
            {settings.map(x => this.renderSettings(x))}
        </React.Fragment>
    }
}

export default withStyles(styles)(SettingsGrid);