import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import ResourcingView from '../../resourcing/ResourcingView';

class ResourcePlanning extends TaimerComponent<any, any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/ResourcePlanning');
        const {
            userObject,
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'grid',
                label: this.tr('Grid'),
            },
            {
                id: 'gantt',
                label: this.tr('Gantt'),
            },
            {
                id: 'list',
                label: this.tr('List'),
            },
            {
                id: 'usage',
                label: this.tr('Utilization'),
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} selectedSubTab={this.props.selectedSubTab} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'grid':
                        case 'gantt':
                        case 'list':
                        case 'usage':
                            return <ResourcingView {...this.props} stickySearchKey='resourcing-view' fullHeight selectedTab={selectedTab} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default ResourcePlanning;
