import React from "react";
import TaimerComponent from "../TaimerComponent";
import SearchTextInput from "../general/SearchTextInput";
import TreeOption from "./../list/TreeOption";
import { createBranchIndicators } from "../general/BranchIndicator";
import { 
	treeFormatDataForList,
	createTreeOptions,
	findMaxDepth,
	makeMap,
	makeMapOfPrimitives,
	gatherTree 
} from "../list/ListUtils";
import { List as VirtualizedList, AutoSizer } from "react-virtualized";
import "./AdvancedSearch.css";
import $ from "jquery";
import { Popper, ClickAwayListener, Tooltip } from "@mui/material";
import ClickAwayWrapper from "../general/ClickAwayWrapper";
import FreezableInput from "../general/FreezableInput";
import _ from 'lodash';
import isEqual from "lodash/isEqual"; 
import cloneDeep from "lodash/cloneDeep";
import HighlightedString from "./../general/HighlightedString";
import {
	gatherFromRoot,
	findRoot,
	createHierarchyDataMap
} from "./../general/TreeUtils";

// TODO:
// Properly separate an autocomplete option's display value and its "search value".
// E.g.: 
// { id: 212, value: 'Foobar', display_value: 'Foobar (212) (locked)' }
// props = { 
//   displayValueFields: { 
//     customers: { displayValue: 'display_value', searchValue: 'value' }
//   }
// }


function createAdvancedSearchTreeOptions(data, parentKey = "parentId", idKey = "id") {
	return createTreeOptions(treeFormatDataForList(data, parentKey, undefined, undefined, idKey), 0, undefined, idKey);
}

class AdvancedSearch extends TaimerComponent {
	constructor(props, context) {
		super(props, context, "search/AdvancedSearch");

		const { tr } = this;

		this.operatorTranslMap = {
			"eq": tr("is"),
			"neq": tr("is not"),
			"contains": tr("contains"),
			"doesnotcontain": tr("does not contain"),
			"gt": tr("greater than"),
			"lt": tr("less than"),
			"between": tr("between"),
			"before": tr("before"),
			"after": tr("after")
		};

		this.filterItems      = {}; // References to contained AdvancedSearchFilterItems.
		this.addedFilters     = {}; // Filters from "outside" the component that need to be sent to the server.
		this.autoCompleteData = {};

		this.previousSearch   = {};

		this.searchTextInput = React.createRef();
		this.searchTermWrapper = React.createRef();
		this.refRoot = React.createRef();
		let savingFilters = [];

		let filterIndex = 0;

		 if (
			 this.props.previousFilters && 
			 (this.props.previousFilters.advanced_search_criteria) && 
			 (this.props.previousFilters.advanced_search_criteria.filters)
			)		 
		_.forEach(this.props.previousFilters.advanced_search_criteria.filters,
			(o,filter) => _.forEach(o, f => {
				f.id = ++filterIndex;
				f.name = filter;	
				f.field = filter;
				f.operatorTransl= this.operatorTranslMap[f.operator];
				f.active = true;
				{
					this.props.fields.map((fl) => {
						if(fl.field == f.name){
							f.nameTransl = fl.transl;
							f.onRemoveClicked = () => {
								this.removeFilterById(f.name, f.id)
							};
							savingFilters.push(f);

						}
					})
				}
				
		}));

        this.prevValue = "";

        // If the mode has been given as a prop (is not undefined) and it is either advanced or freetext, init mode from this prop. 
        // Otherwise don't let it affect the decision of whether we're initing as advanced or freetext. 
        const modeFromProps = (this.props.mode === "advanced" || this.props.mode === "freetext") ? this.props.mode === "advanced" : undefined;

		this.state = {
			open: false,
            advanced: modeFromProps === undefined ? (( // If the mode hasn't been passed as a prop, use the "old" way of inferring the mode.
                this.props.previousFilters && 
                this.props.previousFilters.advanced_search_criteria && 
                (Object.values(this.props.previousFilters.advanced_search_criteria.filters)).length >= 1
            ) ? true : false) : modeFromProps,
            mainConfig: this.props.mainConfig === undefined ? { operator: "AND", filters: {}  } : this.props.mainConfig,
            currentFilters: this.props.initialFilters === undefined ? ((this.props.previousFilters && this.props.previousFilters.advanced_search_criteria && this.props.previousFilters.advanced_search_criteria.filters) ? savingFilters : []) : this.props.initialFilters,
            inputValues: {},
			clickAwayActive: false
		};

		if(this.props.initialFilters !== undefined) {
			const stateInputValues = {};

			this.props.initialFilters.forEach(f => {
				const { 
					name, 
					identifier, 
					entityMode,
					value,
					isBundle,
					bundledElements
				} = f;

				if(!stateInputValues.hasOwnProperty(name)) {
					stateInputValues[name] = [];
				}

				const inputValue = {
					frozen: entityMode || isBundle,
					value: value
				};

				if(entityMode) {
					inputValue.data = {
						name: value,
						id: identifier,
						value: identifier
					};
				}

				if(isBundle) {
					inputValue.isBundle        = true;
					inputValue.bundledElements = bundledElements;
				}

				stateInputValues[name].push(inputValue);
			});

			this.state.inputValues = stateInputValues;
		}

		this._advancedSearch                     = this._advancedSearch.bind(this);
		this.handleChangedAutoCompleteData       = this.handleChangedAutoCompleteData.bind(this);
		this.initSearchItemReferences            = this.initSearchItemReferences.bind(this);
		this.openWrapper                         = this.openWrapper.bind(this);
		this.closeWrapper                        = this.closeWrapper.bind(this);
		this.toggleWrapper                       = this.toggleWrapper.bind(this);
		this.setAddedFilters                     = this.setAddedFilters.bind(this);
		this.triggerSearch                       = this.triggerSearch.bind(this);
		this._triggerSearch                      = this._triggerSearch.bind(this);
		this._createStringAutoCompleteDataFilter = this._createStringAutoCompleteDataFilter.bind(this);
		this.handleClearSearchPress              = this.handleClearSearchPress.bind(this);
		this.clearSearch                         = this.clearSearch.bind(this);
		this.toggleMode                          = this.toggleMode.bind(this);
		this.getFilters                          = this.getFilters.bind(this);
		this.filterInputValueChanged             = this.filterInputValueChanged.bind(this);
		this.searchFieldKeyPressed               = this.searchFieldKeyPressed.bind(this);
		this.isInAdvancedMode                    = this.isInAdvancedMode.bind(this);
		this.removeFilterById                    = this.removeFilterById.bind(this);
		this.removeFiltersNotInFields            = this.removeFiltersNotInFields.bind(this);

		// Ensure that at least freetextSearchUrl is always set.
		if (!this.props.noRequests && !props.hasOwnProperty("freetextSearchUrl") && !props.hasOwnProperty("advancedSearchUrl"))
			throw new Error("Error in AdvancedSearch constructor: at least freetextSearchUrl must be defined.");

		this.initSearchItemReferences();
	}


	componentDidMount() {
		this.handleChangedAutoCompleteData();
	}


	shouldComponentUpdate() {
        return true;
	}


	initSearchItemReferences() {
		this.props.fields.forEach(field => {
			this.filterItems[field.field] = React.createRef();
		});
	}


	searchFieldKeyPressed(event) {
		if(event.keyCode === 27) {
			this.closeWrapper();
		} else if(event.keyCode === 13) {
			this._triggerSearch();
		} else if(event.keyCode === 8) {
			if(this.searchTextInput.current.getValue() === "")
				this.clearSearch();
		} else {
			let str = this.searchTextInput.current.getValue();
 			this.prevValue = str.substring(0, str.length);
		}
	}


	openWrapper() {
		this.setState({ open: true }, () => this.props.onMenuVisibilityChange(true))
	}


	toggleWrapper() {
		this.setState({ open: !this.state.open })
	}


	closeWrapper(e) {
		this.setState({ open: false }, () => this.props.onMenuVisibilityChange())
	}


	getFilters() {
		let filters = [];

		for(let i in this.filterItems) {
			if(!this.filterItems[i] || !this.filterItems[i].current) {
				continue;
			}

			filters.push(this.filterItems[i].current.getFilterData());
		}

		return filters;
	}


    // filterInputValueChanged(filterName, value, selectedData) {
	filterInputValueChanged(filterName, values) {	
		const inputValues       = cloneDeep(this.state.inputValues);
		inputValues[filterName] = values;

		this.setState({ inputValues: inputValues }, () => {
			this.handleAutoCompleteDataFiltering();
		});
    }


	_createStringAutoCompleteDataFilter(f) {
		return (autoCompleteData) => {
			const [childFieldName, parent]            = f.split("|");
			const [parentEntityType, parentFieldName] = parent.split(".");

			if(!this.state.inputValues.hasOwnProperty(parentEntityType)) {
				return autoCompleteData;
			}

			const parents = this.state.inputValues[parentEntityType]
				.filter(v => v && v.hasOwnProperty("data"))
				.map(p => {
					return !p.isBundle
						? p.data[parentFieldName]
						: p.bundledElements.map(e => e[parentFieldName])
				})
				.flat()
				.map(v => String(v));

			return (!parents || parents.length === 0) 
				? 
				autoCompleteData
				:
				autoCompleteData
				.filter(entity => parents.indexOf(String(entity[childFieldName])) > -1);
		};	
	}


	handleAutoCompleteDataFiltering() {
		Object.keys(this.filterItems).forEach(name => {
			if(!this.props.autoCompleteDataFilters.hasOwnProperty(name)) {
				return;
			}

			const f = this.props.autoCompleteDataFilters[name];

			let filterFunction;
 
			switch(typeof(f)) {
				case "function":
					filterFunction = (autoCompleteData) => {
						return f(autoCompleteData, this.state.inputValues);
					};

					break;

				case "object":
					const relevantKeys = Object.keys(f);
					const functions    = [];

					relevantKeys.forEach(k => {
						// If no filters k are active,
						// they don't affect the data; 
						// return it all.
						if(!this.state.inputValues.hasOwnProperty(k) 
							|| 
							!this.state.inputValues[k] 
							|| 
							(Array.isArray(this.state.inputValues[k]) && !this.state.inputValues[k].length)) {
							functions.push(data => data);

							return;
						}

						functions.push(data => {
							return typeof(f[k]) === "string"
								?
								this._createStringAutoCompleteDataFilter(f[k])(data)
								:
								f[k](data, this.state.inputValues[k]);
						});
					});

					filterFunction = (autoCompleteData) => {
						let currentData = autoCompleteData;

						functions.forEach(fn => currentData = fn(currentData));

						return currentData;
					};

					break;

				case "string":
					filterFunction = this._createStringAutoCompleteDataFilter(f);

					break;
			}

			this.filterItems[name].current.setAutoCompleteFilter(filterFunction);
		});
	}


	_triggerSearch(extraParams = undefined) {
		this.triggerSearch(extraParams);
	}


	setAddedFilters(filters) {
		this.addedFilters = filters;
	}


	removeAddedFilters() {
		this.addedFilters = {};
	}


	// TODO: Is overriddenAndAddedTerms used by anyone?
	//used in reports 
	triggerSearch(overriddenAndAddedTerms = {}) {
		if(this.state.advanced || overriddenAndAddedTerms.mode === "advanced") {
			this._advancedSearch(overriddenAndAddedTerms);
		} else {
			this._freetextSearch(overriddenAndAddedTerms);
		}

		setTimeout(this.closeWrapper, 0);
	}


	// TODO:
	_freetextSearch(overriddenAndAddedTerms = {}) {
		const freetextSearchTerm = this.searchTextInput.current.getValue();

		let mainConfig = this.state.mainConfig;

		for (let oi in overriddenAndAddedTerms)
			if (oi !== "filters")
				mainConfig[oi] = overriddenAndAddedTerms[oi];

		mainConfig.filters = this.addedFilters;

		const search = cloneDeep({ mode: "freetext", freetextSearchTerm: freetextSearchTerm, advanced_search_criteria: mainConfig });

		if(isEqual(search, this.previousSearch)) {
			return;
		}

		this.previousSearch = search;

		this.props.onSearchTrigger(search);
	}


	_advancedSearch() {
		const config = {
			mode: "advanced",
			advanced_search_criteria: {
				filters: {}	
			},
			currentFilters: []
		};

		const filterMap     = {};
		const inputValueMap = {};
		const keys          = Object.keys(this.filterItems)
			.filter(key => this.filterItems[key].current !== null);

		keys.forEach(k => {
			filterMap[k]     = this.filterItems[k].current;
			inputValueMap[k] = filterMap[k].getValue();
		});

		let inputValues = keys
			.filter(k => inputValueMap[k].value.length > 0)
			.map(k => [k, inputValueMap[k]])
			.map(([k, inputValues]) => {
				const lastAction = this.filterItems[k].current.getLastAction();

				inputValues.value = inputValues.value
					.filter((value, index, allValues) => {
						return !(index === (allValues.length - 1) 
							&& lastAction === "selection" 
							&& !value.frozen);
					});

				return [k, inputValues];
			});

		// Voi pojot.
		config.mode = this.state.advanced ? "advanced" : "freetext";

		inputValues.forEach(p => {
			const [k, inputValues] = p;

			config.advanced_search_criteria.filters[k] = inputValues.value.map(value => {
				let type         = filterMap[k].props.type;
				const entityMode = value.frozen 
					&& filterMap[k].props.entityMode 
					&& type === "text" 
					&& ["eq", "neq"].indexOf(inputValues.operator) > -1;

				let filterValue;

				if(entityMode && value.hasOwnProperty("data")) {
					// TODO: Make the field names customizable via props or something.
					type  = !filterMap[k].props?.nullEntity 
                        ? "entity"
                        : "nullEntity"; // Check AdvancedSearchUtils.php.
					filterValue = value.data.hasOwnProperty("id") 
						? 
						value.data.id 
						: 
						value.data.value; 
				} else {
					filterValue = value.value;
				}

				return {
					operator: inputValues.operator,
					type:     type,
					value:    filterValue
				};
			});
		});

		config.currentFilters = inputValues.map(p => {
			const [
				k, 
				inputValues
			] = p;

			const field = this.props.fields
				.find(f => f.field === k);

			inputValues.value = field.disableNonEntityValues
				?
				inputValues.value.filter(v => v.frozen)
				:
				inputValues.value;

			return inputValues.value.map(e => ({
				name: k,
				nameTransl: field.tagTransl /* Custom Field */ ?? this.tr(k),
				identifier: e.frozen ? e.data.id || e.data.value : e.value,
				isBundle: Boolean(e.isBundle),
				bundledElements: e?.bundledElements || [],
				entityMode: Boolean(e.frozen) && field.entityMode,
				operator: inputValues.operator,
				operatorTransl: this.operatorTranslMap[inputValues.operator],
				value: e.value
			}));
		}).reduce((acc, cur) => {
			return [...acc, ...cur];
		}, []).filter(v => v.value.trim());

		config.currentFilters
			.filter(f => f.isBundle)
			.map(f => {
				const { 
					entityMode, 
					operator,
					name
				} = f;

				return f.bundledElements
					.map(be => {
						return {
							name: name,
							filter: {
								value: entityMode
									? be.id 
									: be.name,
								type: entityMode
									? "entity" 
									: "text", 
								operator: operator
							}
						};
					});
			})
			.flat()
			.forEach(f => {
				config
					.advanced_search_criteria
					.filters[f.name]
					.push(f.filter);
			});

		this.setState({ currentFilters: config.currentFilters }, () => {
			if(isEqual(this.previousSearch, config)) {
				return;
			}

			this.props.onSearchTrigger(config);

			this.previousSearch = config;
		});
	}


	removeFilterById(name, identifier) {
		const inputValues = cloneDeep(this.state.inputValues);

		if(inputValues && inputValues.hasOwnProperty(name)) {
			inputValues[name] = inputValues[name].filter(v => {
				return v.frozen && !v.isBundle 
					? (v.data.id !== identifier && v.data.value !== identifier) 
					: v.value !== identifier;
			});
		}

		// No time to figure out why the setTimeout is needed
		this.setState({ 
			inputValues: inputValues, 
			advanced: typeof(inputValues) === "object" && Object.keys(inputValues).length > 0
		}, () => {
			setTimeout(this._advancedSearch, 0);
		});
	}


    handleClearSearchPress() {
		this.clearSearch();

		if(typeof(this.props.onClearSearch) === "function") {
			this.props.onClearSearch();
		}
    }


	clearSearch(evt, resetAll) {
		Object.keys(this.filterItems)
			.map(key => this.filterItems[key])
			.filter(ref => ref.current !== null)
			.map(ref => ref.current)
			.forEach(ref => ref.reset());

		if(this.state.advanced && this.state.currentFilters.length === 0) {
			return;
		} else if (!this.state.advanced && this.searchTextInput.current.getValue() === this.prevValue) {
			return;
		}

		let { keyOne } = this.state;

		if(resetAll) {
			keyOne = Math.floor(Math.random() * 10) + 1;
			keyOne = keyOne == this.state.keyOne ? keyOne + 1 : keyOne;
		}

		this.prevValue = "";

		this.setState({ 
			currentFilters: [], 
			advanced: false, 
			keyOne: keyOne,
			inputValues: {}
		}, () => {
			this.state.advanced ? this._advancedSearch() : this._freetextSearch();
		});
	}


	toggleMode() {
		const newMode = !this.state.advanced;

		this.setState({ advanced: newMode });
	}


	isInAdvancedMode() {
		return this.state.advanced;
	}


	componentDidUpdate(prevProps, prevState) {
		(prevState.currentFilters.length == 0 && this.state.currentFilters.length > 0) && this.setState({advanced: true});
		// TODO: Check if the below code was ever even really needed.
		(prevState.currentFilters.length > 0 && this.state.currentFilters.length == 0) && this.setState({advanced: false});

		if(!prevState.open && this.state.open) {
			this.setState({ advanced: true });

			const firstActiveField = Object.keys(this.filterItems)
				.map(k => this.filterItems[k].current)
				.filter(ref => ref !== null)
				.find(ref => {
					return ref.getValue().value.length > 0;
				});

			if(firstActiveField) {
				firstActiveField.focus();
			}
		}

		(prevState.open && !this.state.open && this.state.currentFilters.length == 0) && this.setState({advanced: false});
		(!prevState.advanced && this.state.advanced) && this.clearSearchTextInput();

		if (prevState.open != this.state.open) {
			setTimeout(() => {
				this.setState({ clickAwayActive: this.state.open })
			}, 500);
		}

		if(!isEqual(this.props.autoCompleteData, prevProps.autoCompleteData)) {
			this.handleChangedAutoCompleteData();
		}

		if(!isEqual(prevProps.fields, this.props.fields)) {
			this.removeFiltersNotInFields();
		}
	}


	// Remove filters that aren't in the current fields anymore
	removeFiltersNotInFields() {
		this.initSearchItemReferences();

		const fields      = this.props.fields.map(f => f.field);
		const fieldsMap   = makeMapOfPrimitives(fields);
		const nonExistent = makeMapOfPrimitives(this.state.currentFilters
			.map(f => f.name)
			.filter(name => !fieldsMap[name]));

		this.state.currentFilters
			.filter(f => nonExistent[f.name])
			.forEach(f => {
				this.removeFilterById(f.name, f.identifier);
			});
	}


	handleChangedAutoCompleteData() {
		const data             = cloneDeep(this.props.autoCompleteData);
		const autoCompleteData = {};
		const fieldMap         = makeMap(this.props.fields, "field");

		Object.keys(data).filter(key => fieldMap[key]).forEach(key => {
			if(!fieldMap[key].hasOwnProperty("visualizationType") || fieldMap[key].visualizationType !== "tree") {
				autoCompleteData[key] = data[key];
				return;
			}

			const params = data[key];

			if(!params[0]) {
				params[0] = [];
			}

			autoCompleteData[key] = params;
		});

		this.autoCompleteData = autoCompleteData;

		this.forceUpdate();
	}


	clearSearchTextInput = () => {
		// No need to do this if there's 
		// nothing in the input right now.
		const triggerSearchAfter = this.searchTextInput.current 
			&& this.searchTextInput.current.getValue().trim() !== "";

		this.searchTextInput.current && this.searchTextInput.current.clear();
		this.prevValue = "";

		if(!triggerSearchAfter) {
			return;
		}

		setTimeout(() => {
			this._freetextSearch();
		}, 10);
	}

	compareAutocompleteData(a, b) {
		const aN = a.name ? a.name : "";
		const bN = b.name ? b.name : "";
		return aN.localeCompare(bN)
	}


	render() {
		const { tr } = this;	
		const advSearchText = this.state.currentFilters.length > 0 ? tr("Edit filters") : tr("Add filters");
		const searchFieldPlaceholder = this.state.advanced ? tr("Not available") : tr("Search all fields");

		let inputValue = (this.props.freetextLabel || (this.props.previousFilters && this.props.previousFilters.freetextSearchTerm && this.props.previousFilters.freetextSearchTerm)) ? (this.props.freetextLabel || this.props.previousFilters.freetextSearchTerm) : '';
		const currentFilterNames = this.state.currentFilters.map(f => f.name);
		const { keyOne } = this.state;
		const container = this.props.searchMenuContainer || document.body;

		return (
			<React.Fragment>
				<div className={"advancedSearch " + (this.props.className ? this.props.className : '')}>
					{!this.props.hideTextInput && <div><SearchTextInput
						key={keyOne}
						disabled={this.state.advanced}
						initValue={inputValue}
						ref={this.searchTextInput}
						placeholder={searchFieldPlaceholder}
						// onFocus={this.openWrapper}
						onKeyUp={this.searchFieldKeyPressed}
						// onClick={this.openWrapper}
						onInputListener={this.props.onInputListener}
						onSearchPressed={() => this._triggerSearch()} /></div>}
					{!this.props.hideAdvanced && (<span data-testid="add-advanced-search-filter-button" ref={this.refRoot} className="advSearchSpan notSelectable" onClick={this.openWrapper}>{advSearchText}</span>)}
					{(this.state.currentFilters.length > 0 || this.props.alwaysShowClearFilters) && (<span className="advSearchSpan notSelectable" onClick={this.handleClearSearchPress}>{tr("Clear filters")}</span>)}
                    {this.refRoot.current && 
                        <Popper keepMounted placement="bottom-start" anchorEl={this.refRoot.current} container={container} open={this.state.open}>
                            <ClickAwayWrapper active={this.state.clickAwayActive} onClickAway={() => this.triggerSearch()}>
                                <div className="searchTermContainer" style={{display: !this.state.open && 'none'}}><div className={"searchTermWrapper " + (!this.state.open ? "closed" : "")} ref={this.searchTermWrapper} style={{display: this.state.open ? 'flex' : 'none'}}>
                                    {this.props.fields.map((f, index) => {
										if(!f)
											return null;

										const searchItemRef  = this.filterItems[f.field];
										let autoCompleteData = this.autoCompleteData.hasOwnProperty(f.field) && this.autoCompleteData[f.field] !== undefined ? this.autoCompleteData[f.field] : [];

										if(f.visualizationType !== "tree" && !f.noSort) {
											autoCompleteData.sort(this.compareAutocompleteData)
										}

										// TODO: This is a bit of a hack.
										if (searchItemRef && searchItemRef.current !== null && searchItemRef.current !== undefined) {
											const currentFilters = this.state.currentFilters.filter(filter => filter.name === f.field);

											if (currentFilters.length) {
												// TODO: Is this even needed?
												// searchItemRef.current.setValue(currentFilters.map(filter => filter.value).join(", "), {operator: currentFilters[0].operator});
											} else {
												searchItemRef.current.setValue();
											}
										}
										
										return (
											<AdvancedSearchFilterItem
												autoCompleteData={f.visualizationType === "tree" ? autoCompleteData[0] : autoCompleteData}
												parentIdKey={f.visualizationType === "tree" ? autoCompleteData[1] : undefined}
												key={index}
												transl={f.transl}
												name={f.field}
												data-testid={`advanced_search_field_${f.field}`}
												type={f.type}
												operator={this.state.currentFilters.find(cf => cf.name === f.field)?.operator}
												visualizationType={f.visualizationType}
												excludedOperators={f.excludedOperators}
												entityMode={f.entityMode}
                                                nullEntity={f.nullEntity}
												ref={searchItemRef}
												onEnter={this.triggerSearch}
												values={this.state.inputValues[f.field]}
												valueChanged={this.filterInputValueChanged} />
										);
                                    })}
                                </div>						
                                <div onClick={this.closeWrapper} style={{height: 220}} />{/* taustadropdownille */}
                            </div>
                        </ClickAwayWrapper>
                    </Popper>}
				</div>
				{this.props.children}
				<div className="tagsplitter" />
				{this.state.currentFilters.map((tag, index) => {
					const tooltip = tag.tooltip ? tag.tooltip : (tag.isBundle 
						? 
						(
							<React.Fragment>
								<div>{this.tr("This search includes")}</div>
								<ul style={{
									paddingLeft: 8,
									listStyleType: "none"
								}}>
									{tag.bundledElements.map(e => {
										return <li>-{e.name}</li>;
									})}
								</ul>
							</React.Fragment>
						)
						: false);

					return (
						<AdvancedSearchTag
							key={tag.identifier}
							name={tag.nameTransl}
							operator={tag.operatorTransl}
							value={tag.value}
							tooltip={tooltip}
							// filterData={tag}
                            onRemoveClicked={() => {
								this.removeFilterById(tag.name, !tag.isBundle 
									? tag.identifier
									: tag.value);
                            }} />
					);
				})}
			</React.Fragment>

		);
	}
}

AdvancedSearch.defaultProps = { 
    fields: [], 
    autoCompleteData: [], 
    autoCompleteDataFilters: {},
    initialFilters: undefined, // If this prop is not undefined, AdvancedSearch's state's currentFilters will be initialized with these values. 
    mode: undefined, // Same as above, but for mode.
    mainConfig: undefined, // Same as above, but for mainConfig.
    onSearchTrigger: () => { }, 
    onSearchResult: () => { }, 
    onClearSearch: () => {},
    onMenuVisibilityChange: () => {},
    alwaysShowClearFilters: false, // Overrides the check for showing the clear filters text/button.
    noRequests: false, 
    freetextSearchUrl: "", 
    advancedSearchUrl: "", 
    perpage: 30
};



// A list item in the search wrapper, which has the inputs, etc. Tag is different.
class AdvancedSearchFilterItem extends TaimerComponent {
	constructor(props, context) {
        super(props, context, "search/AdvancedSearch");

        const { tr } = this;

		this.typeOperatorMap = {
			// The text and entity types can be used
			// in the same filter, depending on what 
			// kind of a value the user inputs.
			// So take "text" meaning "text/entity".
			"text": this.props.entityMode ? ["eq", "neq", "contains", "doesnotcontain"] : ["contains", "doesnotcontain", "eq", "neq"],
			"number": ["eq", "neq", "gt", "lt"],
			//"date": ["between", "before", "after", "eq"]
			"date": ["before", "after", "eq"]
		};

		this.operatorTranslMap = {
			"eq": tr("is"),
			"neq": tr("is not"),
			"contains": tr("contains"),
			"doesnotcontain": tr("does not contain"),
			"gt": tr("greater than"),
			"lt": tr("less than"),
			"between": tr("between"),
			"before": tr("before"),
			"after": tr("after")
		};

		this.keyCodeCallbacks = {
			188: props.onComma,
			13: props.onEnter
		};

		this.autoCompleteData         = this.props.autoCompleteData;

		this.virtualizedList          = React.createRef();
		this.input                    = React.createRef();
		this.freezableInput           = React.createRef();
		this.autoCompleteContainer    = React.createRef();
		this.autoCompleteRowRefs      = [];
		this.autoCompleteTimeout      = undefined;

        this.focus                    = this.focus.bind(this)
		this.setAutoCompleteFilter    = this.setAutoCompleteFilter.bind(this);
		this.valueChanged             = this.valueChanged.bind(this);
		this.onKeyUp                  = this.onKeyUp.bind(this);
		this.onBlur                   = this.onBlur.bind(this);
		this.getFilterData            = this.getFilterData.bind(this)
		this.operatorChanged          = this.operatorChanged.bind(this);
		this.reset                    = this.reset.bind(this);
		this.setValue                 = this.setValue.bind(this);
		this.useActiveAutoCompleteRow = this.useActiveAutoCompleteRow.bind(this);
		this.handleAutoComplete       = this.handleAutoComplete.bind(this);
		this.filterAutoCompleteData   = this.filterAutoCompleteData.bind(this);
		this.showAutoComplete         = this.showAutoComplete.bind(this);
		this.hideAutoComplete         = this.hideAutoComplete.bind(this);
		this._rowRenderer             = this._rowRenderer.bind(this);
		this.getLastAction            = this.getLastAction.bind(this);

		this.operators = this.typeOperatorMap[this.props.type] ? this.typeOperatorMap[this.props.type].filter(operator => {
			return this.props.excludedOperators.indexOf(operator) === -1;
		}) : this.typeOperatorMap.text;

		// By default the filter's state is non-active.
		// Pass a prop active=true to the item to make it active at initialization.
		this.state = {
			value: props.value || "",
			active: false, // TODO: This needs to be conditional when FilterItems are loaded from a saved search.
			operator: props.operator || (this.operators && this.operators[0]), // By default the first operator is selected.
			autoCompleteDataFilter: (d) => d,
			autoCompleteOpen: false,
			autoCompleteData: this.autoCompleteData,
			activeAutoCompleteRowIndex: -1,
			elementAmount: 0,
			visualizedAutoCompleteData: [],
			lastAction: null
		};

		this.dataMap = {};

		if(this.props.visualizationType === "tree") {
			this.dataMap = createHierarchyDataMap(this.props.autoCompleteData, 
				this.props.parentIdKey);
		}
	}


	componentDidMount() {
		super.componentDidMount();

		// Prevent pressing up or down from moving the cursor to the beginning or end of the text input.
		this.input.current.addEventListener("keydown", (event) => {
			if (!([38, 40].indexOf(event.keyCode) > -1))
				return;

			event.preventDefault();
			return false;
		});
	}


	getLastAction() {
		return this.state.lastAction;
	}


	shouldComponentUpdate() {
        return true;
	}


	componentDidUpdate(prevProps, prevState) {
		if(!isEqual(this.props.autoCompleteData, prevProps.autoCompleteData)) {
			this.autoCompleteData = this.props.autoCompleteData;

			if(this.props.visualizationType === "tree") {
				this.dataMap = createHierarchyDataMap(this.props.autoCompleteData, 
					this.props.parentIdKey);
			}

			this.setState({ autoCompleteData: this.props.autoCompleteData });
		}

        if(!isEqual(this.props.entityMode, prevProps.entityMode)) {
            //correct operator order if entityMode changes
            this.typeOperatorMap.text = this.props.entityMode ? ["eq", "neq", "contains", "doesnotcontain"] : ["contains", "doesnotcontain", "eq", "neq"];
            this.operators = this.typeOperatorMap[this.props.type] ? this.typeOperatorMap[this.props.type].filter(operator => {
                return this.props.excludedOperators.indexOf(operator) === -1;
            }) : this.typeOperatorMap.text;
        }

        if(!isEqual(this.props.name, prevProps.name)) {
            //correct operator if filterItems change
            this.setState({ operator: this.props.operator ? this.props.operator : (this.operators ? this.operators[0] : undefined)});
        }

		if(prevState.activeAutoCompleteRowIndex === this.state.activeAutoCompleteRowIndex) {
			return;
		}

		// When the active row index has changed, calculate new scroll position on the y-axis to always show the current selection.
		// h = height of one row, i = index, a = amount of max visible rows.
		// h·i - (a/2 - 0.5)·h, simplified: h(i - (a/2 - 0.5)): h(i - a/2 + 0.5).
		this.autoCompleteContainer.current.scrollTop = 22 * (this.state.activeAutoCompleteRowIndex - 10 / 2 + 0.5);
		// (The height of one row is 22px: this should be somehow defined in a constant.)
	}


	focus() {
		if(!this.freezableInput.current) {
			return;
		}

		this.freezableInput.current.focus();
	}


    // This sets the filter function for later, but also filters the autocomplete data already, 
    // so as to reflect the change immediately, should the dropdown already be open for any reason.
    setAutoCompleteFilter(filterFunction) {
        this.setState({ autoCompleteDataFilter: filterFunction, autoCompleteData: filterFunction(this.autoCompleteData) });
    }


	inputClicked(event) {
		event.preventDefault();
		return true;
	}


	reset() {
		this.setState({ lastAction: null }, () => this.setValue());
	}


	setValue(value, extra = {}) {
		this.setState({ value: value || "", active: !(value === undefined || value.trim() === ""), ...extra });
	}


	getValue() {
		return {
			value: this.freezableInput.current.getValue(),
			operator: this.state.operator
		};
	}


	valueChanged(values) {
		this.props.valueChanged(this.props.name, values);
	}


	onKeyUp(event) {
		event.persist();

		if(event.keyCode === 27) {
			this.hideAutoComplete();
			return;
		}

		if(event.keyCode === 13) { // Enter.
			const values    = this.freezableInput.current.getValue()
			const lastValue = values.length > 0 ? values.pop() : false;

			if(this.state.autoCompleteOpen && this.state.activeAutoCompleteRowIndex >= 0 && (!lastValue || lastValue && !lastValue.frozen)) {
				// Voi että.
				this.useActiveAutoCompleteRow(false, undefined, event);
				return;
			}

			this.props.onEnter();
			this.hideAutoComplete();

			return;
		}

		// 38 - up arrow
		// 40 - down arrow
		if([38, 40].indexOf(event.keyCode) > -1) { // On up or down arrow, change the active autocomplete row.
			const newIndex = event.keyCode === 38 ? (this.state.activeAutoCompleteRowIndex - 1) < 0 ? this.state.autoCompleteData.length - 1 : this.state.activeAutoCompleteRowIndex - 1 : (this.state.activeAutoCompleteRowIndex + 1) % this.state.autoCompleteData.length;
			this.setState({ activeAutoCompleteRowIndex: newIndex });
			return;
		} else if([37, 39].indexOf(event.keyCode) > -1) { // 37 - left arrow, 39 - right arrow
			return;
		} else if([9, 16, 17].indexOf(event.keyCode) === -1) { // Don't automatically show the autocomplete container when moving into the input with tab or shift+tab.
			setTimeout(() => {
				const v = this.freezableInput.current.getRightMostValue();
				const callValue = v.frozen ? "" : v.value;

				this.handleAutoComplete(callValue);
			}, 0);
		}

		this.setState({
			lastAction: "typing"
		});
	}


	onBlur(event) {
		event.persist();

		if(event && event.relatedTarget && event.relatedTarget.offsetParent === this.autoCompleteContainer.current) {
			return;
		}

		setTimeout(() => {
			this.hideAutoComplete();
		}, 50);
	}


	// TODO: Get rid of the frequent .toLowerCase() calls.
	handleAutoComplete(value) {
		if(this.autoCompleteTimeout !== undefined && this.autoCompleteTimeout !== null) {
			clearTimeout(this.autoCompleteTimeout);
		}

		setTimeout(() => {
			this.filterAutoCompleteData(value);
		}, 50);	
	}


	filterAutoCompleteData(value) {
		value = value.split(",").map(v => v.trim()).pop().toLowerCase();
		value = value.split(" ").filter(v => v.length > 1);

		const autoCompleteData = cloneDeep(this.state.autoCompleteDataFilter(this.autoCompleteData));

		// Hold refs to autoCompleteData.
		const map   = makeMap(autoCompleteData, "id"); 
		let matches = autoCompleteData.map(d => {
			d.rowValue = d.hasOwnProperty("name") 
				? 
				(d.name || "").toLowerCase() 
				: 
				(d || "").toLowerCase();

			return d;
		}).filter(d => {
			if(!value || value.length === 0) {
				return true;
			}

			// Because of OR:
			for(const v of value) {
				if(d.rowValue.indexOf(v) === -1) {
					continue;
				}

				return true;
			}
			
			return false;
		}).sort((a, b) => {
			if(a.rowValue === b.rowValue) {
				return 0;
			}

			return (a.rowValue < b.rowValue)
				? -1
				: +1;
		}).map(d => {
			let matchedStrings = [];

			for(const v of value) {
				if(d.rowValue.indexOf(v) > -1) {
					matchedStrings.push(v);
				}
			}

			const allMatchesLength = matchedStrings.join("").length;
			const wholeLength      = d.rowValue.replaceAll(" ", "").length;

			d.matchPercentage = (allMatchesLength 
				+ (allMatchesLength / wholeLength))
				.toPrecision(2);

			return d;
		});

		// matches = matches
			// .sort()
			// .reverse();

		// REMINDER: This is the correct order regarding
		// match percentage or whatever.
		matches = matches.sort((a, b) => {
			if(a.matchPercentage === b.matchPercentage) {
				return 0;
			}

			// "Reversed" since higher values should come first.
			return (a.matchPercentage < b.matchPercentage)
				? +1
				: -1;
		});

		if(this.props.visualizationType === "tree") {
			const orderByRootMap = {};

			matches.forEach(m => {
				const root = findRoot(this.dataMap[m.id]);

				if(!orderByRootMap.hasOwnProperty(m.matchPercentage)) {
					orderByRootMap[m.matchPercentage] = [];
				}

				orderByRootMap[m.matchPercentage].push(root);	
			});

			matches = Object.keys(orderByRootMap)
				.map(matchPercentage => {
					return orderByRootMap[matchPercentage].map(id => {
						return gatherFromRoot(this.dataMap[id]);
					}).flat();
				})
				.flat();

			// Make unique.
			const uniqueMap = {};

			matches = matches.filter(id => {
				if(uniqueMap[id]) {
					return false;
				}

				uniqueMap[id] = true;

				return true;
			});

			const autoCompleteMap = makeMap(autoCompleteData, "id");

			// Reversed because from the object made earlier the order 
			// is from least to greatest, which is not what we want in this case.
			matches = matches.map(id => autoCompleteMap[id]);
		}

		const finalData = matches.filter(e => e);

		this.setState({ 
			autoCompleteData: finalData, 
			visualizedAutoCompleteData: this.props.visualizationType === "tree" 
			? 
			createAdvancedSearchTreeOptions(finalData, this.props.parentIdKey) 
			: 
			finalData, 
			activeAutoCompleteRowIndex: -1 
		}, () => {
			this.virtualizedList.current.forceUpdateGrid();

			(matches.length > 0) 
				? 
				this.showAutoComplete(matches.length) 
				: 
				this.hideAutoComplete();
		});
	}


	useActiveAutoCompleteRow(index = false, data = undefined, event = undefined) {
		const selectAllChildren = event?.ctrlKey === true;

		const el = cloneDeep(index === false 
			? 
			this.state.visualizedAutoCompleteData[this.state.activeAutoCompleteRowIndex] 
			: 
			this.state.visualizedAutoCompleteData[index]);

		const treeEl = this.dataMap[el.id];
		let children = selectAllChildren && treeEl.childRefs.length > 0 
			? 
			cloneDeep(gatherFromRoot(treeEl).map(id => this.dataMap[id]))
			: 
			null;

		let isBundle        = selectAllChildren && children?.length > 0;
		let bundledElements = [];
		let value           = el.hasOwnProperty("name") ? el.name : el;
		let currentValues   = this.state.value.split(",").map(v => v.trim());

		// If the clicked element's children have
		// also been selected by ctrl-clicking, we
		// don't want to show all the selected values,
		// but rather only the clicked value and an 
		// indicator that all its children also are 
		// in the search.
		// E.g.: "Customer X and its sub-units"
		if(isBundle) {
			value = `${value} + ${children.length - 1} ${this.tr("other(s)")}`;
			bundledElements = children;

			// The references have to be
			// severed here, because they keep causing
			// cyclic reference exceptions later on.
			for(let ref of bundledElements) {
				delete ref.childRefs;
				delete ref.parentRef;
			}
		}

		if(currentValues.length > 0) {
			currentValues[currentValues.length - 1] = value;
		} else {
			currentValues = [value];
		}

		this.setState({ 
			value: currentValues.join(", "), 
			active: true,
			lastAction: "selection"
		});

		this.input.current.focus();

		const values    = this.freezableInput.current.getValue();
		const lastValue = values.pop();

		this.freezableInput.current.setValue({
			frozen: true,
			value: value,
			isBundle: isBundle,
			bundledElements: bundledElements,
			data: el
		}, lastValue && !lastValue.frozen ? values.length : undefined);
	}


	// The amount of elements must always be passed, so we can determine the height of the auto complete element.
	showAutoComplete(elementAmount) {
		this.setState({
			autoCompleteOpen: true,
			elementAmount: elementAmount
		});
	}


	hideAutoComplete() {
		this.setState({ autoCompleteOpen: false });
	}


	operatorChanged(event) {
		this.setState({ operator: event.target.value });
	}


	getFilterData() {
		return {
			name: this.props.name,
			nameTransl: this.props.transl,
			value: this.state.value,
			type: this.props.type,
			operator: this.state.operator,
			operatorTransl: this.operatorTranslMap[this.state.operator],
			active: this.state.active
		};
	}


	_rowRenderer({ key, index, style }) {
		const { visualizationType } = this.props;
		const d                     = this.state.visualizedAutoCompleteData[index];
		const className             = this.state.activeAutoCompleteRowIndex >= 0 
			&& this.state.activeAutoCompleteRowIndex === index 
			? "row active" 
			: "row";
		const value                 = (d?.display_value == "") ? "" : (d?.name == "") ? "" : (d?.display_value || d?.name || d); //tulipas ruma. Pitää sallia tyhjä stringi tai saattaa tulla hupsista-errori.
		const { values }            = this.props;
		const highlightValues       = values !== undefined 
			&& Array.isArray(values) 
			&& values.length > 0
			&& !values[values.length - 1].frozen
			? values[values.length - 1].value
			.split(" ")
			.filter(s => s)
			: false;

		let row;

		if(visualizationType === "tree") {
			row = <TreeOption 
				key={index} 
				index={index}
				className={className} 
				style={style}
				{...d} 
				displayValue={value}
				floatLeft={true} 
				data={d} 
				level={d.level} 
				isLastChild={d.isLastChild} 
				inList={false} 
				highlight={highlightValues}
				onSelect={(data, event) => {
					this.useActiveAutoCompleteRow(index, d, event);
				}}
			/>
		} else {
			row = <div 
				key={index} 
				className={className} 
				style={style}
				onMouseDown={(event) => { 
					event.preventDefault(); 
					event.stopPropagation(); 

					this.useActiveAutoCompleteRow(index, d, event) 
				}}>
				<HighlightedString 
					string={value} 
					highlighters={highlightValues ? highlightValues : []}
					highlightClassName={"highlight"}
				/>
			</div>
		}

		return row;
	}


	render() {
		const autoCompleteClassName       = "autoCompleteContainer " + ((this.state.autoCompleteOpen) ? "open" : "");
		const autoCompleteContainerHeight = this.state.autoCompleteOpen ? (this.state.elementAmount * 22 + 4) + "px" : "0px";

		return (
			<div className="advancedSearchFilterItem notSelectable">
				<span className={this.state.active ? "active" : ""}>{this.props.transl}</span>
				<select className={this.state.active ? "active" : ""} onChange={this.operatorChanged} value={this.state.operator} tabIndex="-1">
                    {this.operators && this.operators.map((operator, index) => {
						return <option key={index} value={operator}>{this.operatorTranslMap[operator]}</option>
					})}
				</select>
				{<FreezableInput 
					ref={this.freezableInput}
					disableFreezing={!this.props.entityMode}
					values={this.props.values}
					innerRef={this.input}
					onChange={values => {
						if(!values) {
							return;
						}

						this.valueChanged(values);
					}}
					onKeyUp={this.onKeyUp}
					onFocus={values => {
						if(!values || values.length === 0) {
							this.handleAutoComplete("");

							return;
						}

						const value = values[values.length - 1];

						if(!value.frozen) {
							this.handleAutoComplete(value.value);
						} else {
							this.handleAutoComplete("");
						}
					}}
					inputProps={{
						name: this.props.name,
						'data-testid': this.props['data-testid'],
						placeholder: this.props.placeholder,
						tabIndex: this.props.key,
						onClick: this.inputClicked,
						onBlur: this.onBlur,
						autocomplete: "off",
					}}
				/>}
                <div ref={this.autoCompleteContainer} className={autoCompleteClassName} style={{ height: autoCompleteContainerHeight }}>
					<AutoSizer>
						{
							({height, width}) => (
								<VirtualizedList
									ref={this.virtualizedList}
									rowHeight={this.props.visualizationType === "tree" ? 20 : 22}
									auto
									width={width}
									height={height}
									rowCount={this.state.visualizedAutoCompleteData.length}
									scrollToIndex={this.state.activeAutoCompleteRowIndex}
									rowRenderer={this._rowRenderer} 
									overscanRowCount={20}
									data={this.state.visualizedAutoCompleteData}
								/>
							)
						}
					</AutoSizer>
				</div>
			</div>
		);
	}
}

AdvancedSearchFilterItem.defaultProps = {
	entityMode: false,
    nullEntity: false,
	type: "text",
	name: undefined,
	value: "",
	values: undefined,
	valueChanged: () => {},
	autoCompleteData: [],
	placeholder: "",
	operator: undefined,
	visualizationType: "list",
	parentIdKey: undefined,
	excludedOperators: [],
	onComma: () => { },
	onEnter: () => { }
};



class AdvancedSearchTag extends React.Component {
	constructor(props) {
		super(props);

		this.remove = this.remove.bind(this);
	}


	shouldComponentUpdate() {
        return true;
	}


	remove() {
		this.props.onRemoveClicked(this, this.props);
	}


	render() {
		const tooltip = this.props.tooltip || false;
		const Wrapper = tooltip !== false
			? Tooltip
			: React.Fragment;
		const tooltipProps = tooltip !== false
			? { title: tooltip, placement: "bottom-start" }
			: {};

		return (
			<Wrapper {...tooltipProps}>
				<div className="tag-wrapper">
					<div className="tag">
						<p>
							<span className="name">{this.props.name}</span>
							<span className="operator">{this.props.operator}</span>
							<span className="value">{this.props.value}</span>
							<span className="x" onClick={this.props.onRemoveClicked}>X</span>
						</p>
					</div>
				</div>
			</Wrapper>
		);
	}
}

AdvancedSearchTag.defaultProps = {
	name: "",
	operator: "",
	value: "",
	filterData: {},
	hideAdvanced: false,
	onRemoveClicked: () => {},
	tooltip: false
};

export default AdvancedSearch;
