import React, { Component, PureComponent } from 'react';
import TextBoxSuggest from '../general/TextBoxSuggest';
import DataHandler from '../general/DataHandler';
import TaimerAvatar from '../general/TaimerAvatar';
import throttle from 'lodash/throttle';
import { getComboSearch } from '../Data';
import './TextBoxSuggestUser.css';
import { getColorClass } from './time-tracker/Colors';
import cn from 'classnames';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from '../SettingsContext';

class PopupListItem extends TaimerComponent {
    static contextType = SettingsContext;

    
	constructor(props) {
		super(props);

		this.element = React.createRef();
	}

	onClick = () => {
		this.props.onClick(this.props.item);
	}

	componentDidUpdate = () => {
		if (this.props.selected && this.element.current.scrollIntoView)
			this.element.current.scrollIntoView({block: "center", inline: "center"});
	}

	render() {
        const { item } = this.props;
        const { tr } = this;

        return (<li ref={this.element}
            className={cn(this.props.selected && "selected", getColorClass(item, 'project'))}
            onClick={this.onClick}>
                <div className="rbc-ball-cont">
                    <TaimerAvatar
                        id={item.id}
                        name={item.label}
                        color={item.color}
                    />
                </div>
                <div className="titleContainer">
                    <div className="title">{item.label} {item.user_ids ? 
											(item.type == 0 ? this.tr(" (Groups)") : (item.type == 2 
												? this.tr(" (Teams)") : '')) : ''}</div>
                    <div className="subtitle">{item.title}</div>
                </div>
            </li>)
	}
}

export default class TextBoxSuggestUser extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {

    }

	constructor(props, context) {
		super(props, context, "workhours/TextBoxSuggestUser");

        this.state = {
            suggestItems: [],
            search: '',
        }
        this.allItems = [];
    }

    componentDidMount() {
        super.componentDidMount();
        const url = this.props.onlySubjects ? 'dialogs/subjects' : 'dialogs/active_users_and_groups';
        DataHandler.get({url: url}).done(resp => {
            resp.users = resp.users.map(u => {
                if (u.companies_id < 1) {
                    u.label += ` (${this.tr('freelancer')})`;
                    u.name += ` (${this.tr('freelancer')})`;
                }
                return u;
            });
            let allItems = resp.users.map(el => ({...el, id: 'u-' + el.id}));
            if(resp.groups) {
                resp.groups.forEach(el => {
                    if(el.type == 2) {
                        allItems.push({...el, id: 'g-' + el.id});
                    }
                })
            }
            this.allItems = allItems;
        });
    }

    search = (text) => {
        const regExp = new RegExp(text, 'i');
        let suggestItems = this.allItems.filter(el => regExp.test(el.label) && this.props.currentUsers.indexOf(el.id) < 0);

        this.setState({suggestItems});
    }

    onFocus = () => {
        const { search } = this.state;

        this.search(search);

        if (this.props.onFocus)
			this.props.onFocus();
    }

    onChange = (e) => {
        this.search(e.target.value);
        this.setState({search: e.target.value});

        this.props.onChange && this.props.onChange(e);
    }

	render() {
        const { search } = this.state;
        const { tr } = this;

		return (
            <TextBoxSuggest
                popupListClass="noWrap TextBoxSuggestUserPopUp"
                {...this.props}
                value={search}
                onFocus={this.onFocus}
                onChange={this.onChange}
                suggestItems={this.state.suggestItems}
                component={PopupListItem} />)
	}
}
