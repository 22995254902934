import React from "react";
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

import TaimerComponent from "../../TaimerComponent";
import List from "../List";
import DataHandler from "../../general/DataHandler";
import CatalogListSliderOverlay from "../overlays/CatalogListSliderOverlay";
import "./ProductDetailsInfo.css";
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import LinkListCell from "../LinkListCell";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { ReactComponent as InvoiceIcon } from '../../navigation/NavIcons/received_invoices.svg';
import {ReactComponent as ProjectManagement} from '../../navigation/NavIcons/Project_management.svg'
import {
  MoreHoriz,
  RemoveRedEye,
  Edit,
  Archive,
  Link,
  LinkOff,
  ToggleOn,
} from "@mui/icons-material";
import ContextMenu from "../../general/ContextMenu";
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Tooltip } from "@mui/material";



class ProductProjectSalesSliderRow extends PropsOnlyListRow {
    constructor(props) {
      super(props, null, {}, "list/rows/ProductProjectSalesSliderRow");
    }
  
    defineClassName() {
      return "ProductProjectSalesSliderRow";
    }
  
  
    defineCells() {
      const {columnWidthMap, data} = this.props;
      let cells = {};
    
      cells = {
       
      context:
            data.id > 0 ? (<ListCell className="nobg alignLeft pricelistcontextcell" onlyDisplay>
            <ContextMenu className="row-menu" label={<ContextMenuIcon />}
              buttonProps={{ className: 'action-menu' }} noExpandIcon>
              <MenuItem key={1} noClose={false} onClick={() => this.context.functions.updateView({ module: "projects", action: "view", id: data.id }, false)} >
                  <ProjectManagement className="Project_management" />
                  {this.tr("View Project")}
              </MenuItem>
            </ContextMenu>
          </ListCell>) : 
            <ListCell width={this.props.columnWidthMap.delete} permanentEditMode={true} onlyDisplay={true}>
              <Cancel className="cancelIcon"  />

              <CheckCircle className="saveNewRowCheckCircleIcon"  />
            </ListCell>,
    project: (
        <LinkListCell
          urlHandler={value => `index.php?module=projects&action=view&id=${data.id}`}
          width={columnWidthMap.project}
          noTab={true}
          value={data['project'] + ' (' + data['project_id'] + ')'}
          editable={false}
          noTab={true}
        />
      ),
    account: (
      <LinkListCell 
          urlHandler={value => `index.php?module=customers&action=view&id=${data.customer_id}`}
          width={columnWidthMap.nr} 
          value={data['customer']} 
          editable={false} 
          value={data.customer}
          noTab={true}
      />
      ),
    invoiced: (
        <ListCell width={columnWidthMap.Invoiced} value={data['bills'] || "0"} editable={false} >
          {data.bills}
        </ListCell>
      ),
    invoiceable: (
        <ListCell width={columnWidthMap.invoiceable} value={data['projectentries'] || "0"} editable={false} >
         {data['projectentries']}
        </ListCell>
      )
    };
  
      return cells;
    }
  }

class ProductProjectSalesSlider extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/ProductProjectSalesSlider");

    this.state={ 
      data: [],
      reservedBalance: 0,
    }
  }

  componentDidMount() {
    super.componentDidMount();

    DataHandler.get({url: `products/${this.props.productId}/project_sales`}).done(response => {
        this.setState({ data: response.project_balance});
    });
  }

  _getColumns = () => {

    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
        // columnHeaderType:"roundButton",
        width: 80,
        ...staticColumnConfig,
      },
      {
        field: "project",
        name: "project",
        header: this.tr("Project"),
        width: 200,
        ...staticColumnConfig,
      },
      {
        field: "account",
        name: "account",
        header: this.tr("Account"),
        width: 200,
        ...staticColumnConfig,
      },
      
      {
        field: "invoiceable",
        name: "invoiceable",
        header: this.tr("invoiceable"),
        width: 100,
        ...staticColumnConfig,
      },
      {
        field: "invoiced",
        name: "invoiced",
        header: this.tr("Invoiced"),
        width: 100,
        ...staticColumnConfig,
      }
    ];
  }

 
  render() {
    const { open, onClose, height } = this.props;
    const {data} = this.state;

    return (
      
      <div className="product-card-content">
        <div className="product-card-bottom">
          <List
            fluid
            columns={this._getColumns()}
            height={height}
            listRowType={ProductProjectSalesSliderRow}
            noStateData={true}
            data={data}
          />
        </div>
      </div>
     
    );
  }
}

export default ProductProjectSalesSlider;
