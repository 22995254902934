import React from 'react';
import OutlinedField from '../general/OutlinedField';
import TaimerComponent from '../TaimerComponent';
import GoogleLogin from 'react-google-login';
import DataList from '../general/DataList';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Lottie from 'react-lottie';
import DataHandler from '../general/DataHandler';
import { cloneDeep } from 'lodash';
import validator from 'validator';

import styles from './OnboardingView.module.scss';

import GoogleLogo from './img/google.png';
import { AddCircleOutline, Check, Close } from '@mui/icons-material';
import moment from 'moment';
const animationData = require('./logoanimation.json');
const currencyMap = require('./currencyMap.json');

interface Props extends WithSnackbarProps {}

interface State {
    lang: string;
    error?: string;
    signupData: any;
    versionData: any;
    step: number;
    currencies: any;
    countries: any;
    companyLanguages: any;
    industries: any;
    employeeCounts: any;
    googleUserData?: any;
    invitees: any;
    fieldErrors: any;
}

class OnboardingView extends TaimerComponent<Props, State> {
    fields: any;
    inviteeFields: any;
    fieldRefs: any = {};
    inviteeMailFieldRefs: any = {};
    firstInviteeField: any = React.createRef();
    onBlurACFields = ['firstName', 'lastName', 'companyName', 'phone'];
    onChangeACFields = ['countryCode'];
    mailProviderDomainBlackList = ['gmail', 'yahoo', 'mail', 'hotmail', 'live', 'aol', 'msn'];
    analyticsEventKeys = {
        stepChanged: 'onboarding_step_changed',
    };
    constructor(props, context) {
        super(props, context, 'onboarding/OnboardingView');

        this.inviteeFields = [
            [
                {
                    name: 'firstName',
                    label: this.tr('First name'),
                    required: true,
                    autoFocus: true,
                    ref: this.firstInviteeField,
                },
                {
                    name: 'lastName',
                    label: this.tr('Last name'),
                    required: true,
                },
            ],
            {
                name: 'email',
                label: this.tr('Email'),
                required: true,
                type: 'email',
                fullWidth: true,
                validation: ['email'],
                onBlur: (invitee, index) => this.setInviteeMailErrorIfNeeded(invitee, `invitee_${index}_email`),
            },
        ];

        this.fields = {
            1: [
                {
                    name: 'email',
                    label: this.tr('Enter your work email'),
                    required: true,
                    type: 'email',
                    fullWidth: true,
                    validation: ['email'],
                    onBlur: () => this.setFieldErrorIfNeeded('email'),
                },
            ],
            2: [
                [
                    {
                        name: 'firstName',
                        label: this.tr('First name'),
                        required: true,
                        getAutoFocus: () => !this.state.googleUserData,
                    },
                    {
                        name: 'lastName',
                        label: this.tr('Last name'),
                        required: true,
                    },
                ],
                {
                    name: 'companyName',
                    label: this.tr('Company name'),
                    required: true,
                    fullWidth: true,
                    getAutoFocus: () => !!this.state.googleUserData,
                },
                {
                    name: 'phone',
                    label: this.tr('Phone number'),
                    fullWidth: true,
                    type: 'tel',
                    validation: ['phone'],
                    onBlur: () => this.setFieldErrorIfNeeded('phone'),
                },
                {
                    name: 'password',
                    label: this.tr('Password'),
                    type: 'password',
                    autoComplete: 'new-password',
                    required: true,
                    fullWidth: true,
                },
            ],
            3: [
                {
                    name: 'domain',
                    label: this.tr('Company website'),
                    fullWidth: true,
                    type: 'url',
                    getAutoFocus: () => !this.state.signupData?.domain,
                    validation: ['link'],
                    onBlur: () => this.setFieldErrorIfNeeded('domain'),
                },
                {
                    name: 'industry',
                    label: this.tr('Industry'),
                    fullWidth: true,
                    type: 'select',
                    dataKey: 'industries',
                },
                {
                    name: 'employeeCount',
                    label: this.tr('Company size'),
                    fullWidth: true,
                    type: 'select',
                    dataKey: 'employeeCounts',
                },
                {
                    name: 'countryCode',
                    label: this.tr('Country'),
                    required: true,
                    fullWidth: true,
                    type: 'select',
                    dataKey: 'countries',
                },
                [
                    {
                        name: 'companyLanguage',
                        label: this.tr('Language'),
                        required: true,
                        type: 'select',
                        dataKey: 'companyLanguages',
                    },
                    {
                        name: 'currency',
                        label: this.tr('Currency'),
                        required: true,
                        type: 'select',
                        dataKey: 'currencies',
                    },
                ],
            ],
        };

        const { lang, signupData, versionData, step } = this.parseURLParams();

        const companyLanguages = [
            {
                key: 'en',
                label: this.tr('English'),
            },
            {
                key: 'fi',
                label: this.tr('Finnish'),
            },
            {
                key: 'se',
                label: this.tr('Swedish'),
            },
        ];

        const industries = [
            { label: this.tr('Accounting Firms') },
            { label: this.tr('Architecture & Planning') },
            { label: this.tr('Business Consulting') },
            { label: this.tr('Civil & Industrial Engineering') },
            { label: this.tr('IT Services') },
            { label: this.tr('Marketing & Creative Agencies') },
            { label: this.tr('Other') },
        ];

        const employeeCounts = [{ label: '1' }, { label: '2-4' }, { label: '5-10' }, { label: '11-50' }, { label: '51-100' }, { label: '101-200' }, { label: '200+' }];

        this.state = {
            lang,
            signupData,
            versionData,
            step,
            currencies: [],
            countries: [],
            companyLanguages,
            industries,
            employeeCounts,
            invitees: [],
            fieldErrors: {},
        };
    }

    parseURLParams = () => {
        const params = new URLSearchParams(window.location.search);
        let lang = 'fi';
        let step = 1;
        const signupData: any = {};
        const versionData = {
            version: 'erp',
            installation_type: '4',
        };
        for (let [key, value] of params.entries()) {
            switch (key) {
                case 'lang': {
                    if (value == 'sv') lang = 'se';
                    if (value != 'fi' && value != 'se') lang = 'en';
                    localStorage.setItem('taimerLang', value);
                    break;
                }
                case 'email':
                    signupData.email = value;
                    step = 2;
                    break;
                case 'version':
                    if (['free', 'starter', 'free-crm'].includes(value)) {
                        versionData.version = 'free-crm';
                        versionData.installation_type = '1';
                    } else if (['crm', 'team', 'sales-crm'].includes(value)) {
                        versionData.version = 'sales-crm';
                        versionData.installation_type = '2';
                    } else if (value == 'project-management') {
                        versionData.version = 'project-management';
                        versionData.installation_type = '3';
                    } else if (value == 'erp') {
                        versionData.version = 'erp';
                        versionData.installation_type = '4';
                    }
                    break;
            }
        }

        return { lang, signupData, versionData, step };
    };

    componentDidMount = () => {
        super.componentDidMount();
        document.body.style.paddingTop = '0px';
        this.getAutocompleteData();

        //email is in the url, first page skipped
        if (this.state.step == 2 && this.state.signupData.email) {
            if (this.emailIsValid(this.state.signupData.email)) {
                this.setStepParam(this.state.step);
                this.onEmailSubmitted();
            } else {
                this.setState({ step: 1 }, () => {
                    this.setStepParam(this.state.step);
                    this.setFieldErrorIfNeeded('email');
                });
            }
        }
    };

    emailIsValid = (email) => {
        return validator.isEmail(email);
    };

    componentDidUpdate = (_, prevState) => {
        if (prevState.signupData.countryCode != this.state.signupData.countryCode) {
            const currency = this.getLinkedCurrency(this.state.signupData.countryCode?.id);
            const companyLanguage = this.getLinkedLanguage(this.state.signupData.countryCode?.id);
            this.setState({
                signupData: {
                    ...this.state.signupData,
                    currency,
                    companyLanguage,
                },
            });
        }

        if (prevState.step == 1 && this.state.step == 2) {
            this.onEmailSubmitted();
        }
    };

    componentWillUnmount = () => {
        document.body.style.paddingTop = '70px';
    };

    getAutocompleteData = async () => {
        try {
            const response = await DataHandler.get({ url: 'onboarding/locales/' });
            const { currencies, countries } = response;
            this.setState({ currencies, countries });
        } catch (err) {
            console.error(err);
        }
    };

    getLinkedCurrency = (countryCode) => {
        const linkedCurrency = currencyMap[countryCode.toUpperCase()]?.currency || this.state?.signupData?.currency?.id || 'eur';
        return this.state.currencies.find((c) => c.id == linkedCurrency.toLowerCase()) || { id: 'eur', label: 'EUR' };
    };

    getLinkedLanguage = (countryCode) => {
        let language = 'en';
        if (countryCode == 'fi') {
            language = 'fi';
        } else if (countryCode == 'se') {
            language = 'se';
        }
        return this.state.companyLanguages.find((l) => l.key == language);
    };

    onEmailSubmitted = () => {
        this.setCompanyDomainFromEmail();
        // making sure the event is sent (the sendAnalytics inside Taimer.js is throttled)
        setTimeout(() => {
            this.trackGtag('signup_bc');
        }, 1000);
        this.createActiveCampaignContact();
    };

    setCompanyDomainFromEmail = () => {
        const email = this.state.signupData?.email || '';
        const mailParts = email.split('@') || [];
        let domainSuggestion = '';
        if (mailParts.length > 1) domainSuggestion = mailParts[1];
        let domain;
        if (this.mailProviderDomainBlackList.findIndex((excludedDomain) => domainSuggestion.startsWith(`${excludedDomain}.`)) == -1) {
            domain = domainSuggestion;
        }
        this.setState({
            signupData: {
                ...this.state.signupData,
                domain,
            },
        });
    };

    // old onboarding events, used to use old gtag system – now uses the new tag manager
    trackGtag = (event) => {
        if (this.isTestSignup()) {
            return;
        }
        const {
            lang,
            versionData: { version },
        } = this.state;
        const eventData = {
            taimer_version_name: version,
            taimer_language: lang,
        };
        this.context.functions.sendAnalytics(event, eventData);
    };

    createActiveCampaignContact = () => {
        const {
            lang,
            versionData: { version },
            signupData,
            googleUserData,
        } = this.state;

        const data = {
            email: signupData.email,
            is_onboarding: 'true',
            lang,
            is_free_version: version == 'free-crm' ? 1 : 0,
            version,
            tags: !!googleUserData ? `CHANNEL: Google, GPROFILEPICTURE_URL: ${googleUserData.imageUrl}, LANG: ${lang}` : `CHANNEL: manual, LANG: ${lang}`,
        };

        DataHandler.post({ url: 'onboarding/add_ac_contact/' }, { ...data })
            .done((response) => {
                const countryCode = this.state.countries.find((c) => c.id.toLowerCase() == (response.country_code || '').toLowerCase()) || this.state.countries.find((c) => c.id == 'fi');
                this.setState({
                    signupData: {
                        ...this.state.signupData,
                        id: response.subscriber_id,
                        countryCode,
                    },
                });
            })
            .fail((err) => {
                console.error(err);
                const countryCode = this.state.countries.find((c) => c.id == 'fi');
                this.setState({
                    signupData: {
                        ...this.state.signupData,
                        countryCode,
                    },
                });
            });
    };

    editActiveCampaignContact = (name, value) => {
        const {
            lang,
            versionData: { version },
            signupData,
        } = this.state;

        const data = {
            id: signupData.id,
            lang: lang,
            version: version,
            tags: `LANG: ${lang}`,
            [name]: value,
        };

        DataHandler.post({ url: 'onboarding/edit_ac_contact/' }, { ...data })
            .done((resp) => {})
            .fail((err) => console.error(err));
    };

    onGoogleLogin = (response) => {
        if (!response || response.error) {
            if (response?.error != 'idpiframe_initialization_failed') {
                this.setState({ error: this.tr('Sign up with Google failed. Please try again.') });
            }
            console.error(response?.error);
            return;
        }
        const { profileObj } = response;
        const { email = '', givenName = '', familyName = '' } = profileObj || {};
        this.setState(
            {
                googleUserData: profileObj,
                signupData: {
                    ...this.state.signupData,
                    email,
                    firstName: givenName,
                    lastName: familyName,
                },
            },
            () => {
                this.moveToNextStep();
            }
        );
    };

    finishOnboarding = (href, response) => {
        const time = Math.floor(Date.now() / 1000);
        const expireTime = 7200;
        localStorage.setItem('taimerToken', response.token);
        localStorage.setItem('taimerPrivileges', JSON.stringify(response.privileges));
        localStorage.setItem('taimerVersion', response.version);
        localStorage.setItem('taimerVersionId', response.version_id);
        localStorage.setItem('taimerAddons', JSON.stringify(response.addons));
        localStorage.setItem('taimerTokenIssued', String(time));
        localStorage.setItem('taimerTokenExpires', String(time + expireTime));
        localStorage.setItem('taimerTokenRefresh', String(time + expireTime / 2));
        localStorage.setItem('lastActivityTime', String(time));
        this.context.functions.setTokenExpiration();
        window.location.href = href;
    };

    onStartUsingTaimer = () => {
        const {
            signupData,
            versionData: { version, installation_type },
            invitees,
            lang,
        } = this.state;

        const finalSignupData = {
            ...signupData,
            industry: signupData.industry?.label,
            employeeCount: signupData.employeeCount?.label,
            countryCode: signupData.countryCode?.id || 'fi',
            companyLanguage: signupData.companyLanguage?.key || 'en',
            currency: signupData.currency?.id || 'eur',
        };

        DataHandler.post({ url: 'onboarding/set_profile/' }, { ...finalSignupData, version, lang, installation_type, invitees })
            .fail((err) => console.error(err))
            .done((resp) => {
                this.editActiveCampaignContact('finalRegister', true);
                const href = `/${resp.folder}/index.php?module=dashboard&action=main&selectedTab=my-day&utm_source=taimer.com&utm_medium=onboardingForm&utm_campaign=firstSignup`;

                const poll = (timeout, interval) => {
                    var endTime = Number(new Date()) + (timeout || 2000);
                    interval = interval || 100;
                    var request = new XMLHttpRequest();
                    var checkCondition = (resolve, reject) => {
                        request.open('GET', `/react/api/onboarding_queue/${resp.id}`, true);
                        request.onreadystatechange = function () {
                            if (request.readyState === 4) {
                                if (request.status === 200 && request.responseText === '0') {
                                    resolve();
                                } else if (Number(new Date()) < endTime) {
                                    setTimeout(checkCondition, interval, resolve, reject);
                                } else {
                                    reject();
                                }
                            }
                        };
                        request.send();
                    };
                    return new Promise(checkCondition);
                };

                poll(60000, 500)
                    .then(() => {
                        this.trackGtag('login_cp');
                        DataHandler.post({ url: 'auth/onboarding' }, { authcode: resp.auth, account: resp.folder })
                            .fail((err) => console.error(err))
                            .done((response) => {
                                if (!('$FPROM' in window)) {
                                    this.finishOnboarding(href, response);
                                    return;
                                }
                                window.$FPROM.trackSignup(
                                    {
                                        email: signupData?.email,
                                    },
                                    () => {
                                        this.finishOnboarding(href, response);
                                    }
                                );
                            });
                    })
                    .catch(() => {
                        this.props.enqueueSnackbar(
                            this.tr(
                                'Thanks for choosing Taimer! Oops! Unfortunately, we have detected that your onboarding has failed. Possible causes might be reusing existing email or bad internet connections. Please, contact our support team via support@taimer.com and we try our best to solve the issue.'
                            ),
                            {
                                variant: 'error',
                                persist: true,
                            }
                        );
                        this.trackGtag('onboarding_cp');
                    });
            });
    };

    isTestSignup = () => {
        const { signupData } = this.state;
        return (signupData?.email || '').toLowerCase().includes('@taimer.com') || (signupData?.companyName || '').toLowerCase().includes('hb_test');
    };

    sendAnalyticsEvent = (event, data) => {
        const {
            lang,
            versionData: { installation_type },
        } = this.state;
        if (this.isTestSignup()) {
            return;
        }
        const eventData = {
            taimer_version: installation_type,
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            taimer_language: lang,
            ...data,
        };
        this.context.functions.sendAnalytics(event, eventData);
    };

    moveToStep = (step) => {
        if (step <= 4) {
            const from_step = this.state.step;
            const to_step = step;
            this.sendAnalyticsEvent(this.analyticsEventKeys.stepChanged, { from_step, to_step });
            this.setState({ step, error: undefined, fieldErrors: {} }, () => {
                this.setStepParam(step);
                if (step == 4) {
                    this.onStartUsingTaimer();
                }
            });
        }
    };

    setStepParam = (step) => {
        this.context.functions.updateView({ step: step == 1 ? undefined : step }, false, false, false, true /* no whoami */);
    };

    moveToNextStep = () => {
        this.moveToStep(this.state.step + 1);
    };

    moveToPreviousStep = () => this.setState({ step: this.state.step - 1 });

    onSignupDataChanged = (e) => {
        const { name, value } = e.target;
        const signupData = {
            ...this.state.signupData,
            [name]: value,
        };
        this.setState(
            {
                fieldErrors: {
                    ...this.state.fieldErrors,
                    [name]: false,
                },
                error: undefined,
                signupData,
            },
            () => {
                if (this.onChangeACFields.includes(name)) {
                    this.editActiveCampaignContact(name, typeof value == 'object' ? value?.id : value);
                }
            }
        );
    };

    onKeyDown = (e) => {
        const { step } = this.state;
        const key = e.key;
        const { name } = e.target;
        if (key == 'Enter' && this.requiredFieldsFilled(step)) {
            switch (name) {
                case 'email':
                case 'password':
                    this.moveToNextStep();
            }
        }
    };

    onBlur = (e) => {
        const { signupData } = this.state;
        const { name, value } = e.target;
        const notValid = this.fieldRefs[name]?.isValid && !this.fieldRefs[name].isValid(signupData[name], false);
        if (this.onBlurACFields.includes(name) && !notValid) {
            this.editActiveCampaignContact(name, value);
        }
    };

    fieldNotValid = (field, invitee?, signupData = this.state.signupData) => {
        if (invitee) {
            return (
                (field.required && !invitee[field.name]) ||
                (field.name == 'email' && this.inviteeMailFieldRefs[invitee.id]?.isValid && !this.inviteeMailFieldRefs[invitee.id].isValid(invitee[field.name], false))
            );
        }
        return (field.required && !signupData[field.name]) || (this.fieldRefs[field.name]?.isValid && !this.fieldRefs[field.name].isValid(signupData[field.name], false));
    };

    fieldHasIncorrectValues = (fieldName) => {
        return this.fieldRefs[fieldName]?.isValid && !this.fieldRefs[fieldName].isValid(this.state.signupData[fieldName], false);
    };

    setFieldErrorIfNeeded = (fieldName) => {
        if (this.fieldHasIncorrectValues(fieldName)) {
            this.setState({
                fieldErrors: {
                    ...this.state.fieldErrors,
                    [fieldName]: true,
                },
            });
        }
    };

    setInviteeMailErrorIfNeeded = (invitee, fieldName) => {
        if (this.inviteeMailFieldRefs[invitee.id]?.isValid && !this.inviteeMailFieldRefs[invitee.id].isValid(invitee['email'], false)) {
            this.setState({
                fieldErrors: {
                    ...this.state.fieldErrors,
                    [fieldName]: true,
                },
            });
        }
    };

    getInvalidFields = (step, signupData?, invitees = this.state.invitees) => {
        const fields = this.fields[step];
        let invalidFields = {};
        fields.forEach((field) => {
            if (Array.isArray(field)) {
                field.forEach((halfField) => {
                    if (this.fieldNotValid(halfField, undefined, signupData)) {
                        invalidFields = {
                            ...invalidFields,
                            [halfField.name]: true,
                        };
                    }
                });
            } else {
                if (this.fieldNotValid(field, undefined, signupData)) {
                    invalidFields = {
                        ...invalidFields,
                        [field.name]: true,
                    };
                }
            }
        });
        if (invitees?.length > 0) {
            invitees.forEach((invitee, i) => {
                this.inviteeFields.forEach((field) => {
                    if (Array.isArray(field)) {
                        field.forEach((halfField) => {
                            if (this.fieldNotValid(halfField, invitee, signupData)) {
                                invalidFields = {
                                    ...invalidFields,
                                    [`invitee_${i}_${halfField.name}`]: true,
                                };
                            }
                        });
                    } else {
                        if (this.fieldNotValid(field, invitee, signupData)) {
                            invalidFields = {
                                ...invalidFields,
                                [`invitee_${i}_${field.name}`]: true,
                            };
                        }
                    }
                });
            });
        }
        return invalidFields;
    };

    requiredFieldsFilled = (step, signupData?, invitees = this.state.invitees) => {
        return (Object.values(this.getInvalidFields(step, signupData, invitees)).filter((v) => !!v) || []).length == 0;
    };

    renderStepIndicator = () => {
        const { step: currentStep } = this.state;
        const steps = [
            {
                label: this.tr('Email'),
            },
            {
                label: this.tr('Your details'),
            },
            {
                label: this.tr('Company details'),
            },
        ];
        return (
            <div className={styles.stepIndicator}>
                {steps.map((step, i) => (
                    <div className={styles.stepContainer}>
                        <div>
                            <p className={currentStep == i + 1 ? styles.current : ''}>{step.label}</p>
                            <div className={styles.bottom}>
                                <span className={`${styles.progressLine} ${currentStep == i + 1 && currentStep == 1 ? styles.blue : currentStep >= i + 1 ? styles.green : ''}`} />
                                <div
                                    onClick={currentStep > i + 1 ? () => this.moveToStep(i + 1) : undefined}
                                    className={`${styles.stepNumber} ${currentStep > i + 1 ? styles.green : ''} ${currentStep > i + 1 ? styles.clickable : ''}`}
                                >
                                    {currentStep > i + 1 ? <Check /> : i + 1}
                                </div>
                                <span className={`${styles.progressLine} ${currentStep > i + 1 ? styles.green : ''}`} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    renderField = (field) => {
        const { signupData, fieldErrors } = this.state;
        switch (field.type) {
            case 'select':
                return (
                    <DataList
                        key={field.name}
                        {...field}
                        autoFocus={field.getAutoFocus ? field.getAutoFocus() : field.autoFocus}
                        label={`${field.label}${field.required ? ' *' : ''}`}
                        ref={(ref) => (this.fieldRefs[field.name] = ref)}
                        menuPosition="fixed"
                        error={!!fieldErrors[field.name]}
                        value={field.getValue ? field.getValue() : signupData[field.name] || ''}
                        options={field.getOptions ? field.getOptions() : this.state[field.dataKey]}
                        onChange={(option) => this.onSignupDataChanged({ target: { name: field.name, value: option } })}
                        onBlur={this.onBlur}
                        openMenuOnFocus
                        shownCount={20}
                        isClearable={false}
                        isDisabled={false}
                    />
                );
            default:
                return (
                    <OutlinedField
                        key={field.name}
                        {...field}
                        autoFocus={field.getAutoFocus ? field.getAutoFocus() : field.autoFocus}
                        ref={(ref) => (this.fieldRefs[field.name] = ref)}
                        value={signupData[field.name] || ''}
                        fullWidth
                        error={!!fieldErrors[field.name]}
                        noOnchangeValidation
                        callOnChangeOnKeyUp
                        onKeyDown={this.onKeyDown}
                        onBlur={(e) => {
                            this.onBlur(e);
                            field.onBlur && field.onBlur(e);
                        }}
                        onChange={this.onSignupDataChanged}
                    />
                );
        }
    };

    renderFields = (step) => {
        return this.fields[step].map((field) => {
            if (Array.isArray(field)) {
                return (
                    <div className={styles.halfFieldContainer}>
                        {field.map((halfField) => (
                            <div>{this.renderField(halfField)}</div>
                        ))}
                    </div>
                );
            }
            return this.renderField(field);
        });
    };

    addInvitee = () => {
        const invitees = cloneDeep(this.state.invitees);
        invitees.push({ id: this.state.invitees.length, firstName: '', lastName: '', email: '' });
        this.setState({ invitees }, () => {
            this.firstInviteeField.current && this.firstInviteeField.current.focus();
        });
        this.trackGtag('teammates_bc');
    };

    removeInvitee = (id) => {
        const invitees = cloneDeep(this.state.invitees);
        const foundIndex = invitees.findIndex((i) => i.id == id);
        if (foundIndex != -1) {
            invitees.splice(foundIndex, 1);
            this.setState({ invitees });
        }
    };

    onInviteeChanged = (id, e) => {
        const { name, value } = e.target;
        const index = this.state.invitees.findIndex((i) => i.id == id);
        if (index != -1) {
            const invitees = cloneDeep(this.state.invitees);
            invitees[index][name] = value;
            this.setState({ invitees, fieldErrors: { ...this.state.fieldErrors, [`invitee_${index}_${name}`]: false } });
        }
    };

    renderInviteSection = () => {
        const { invitees, fieldErrors } = this.state;
        return (
            <>
                <div className={styles.addInviteeButton} onClick={this.addInvitee}>
                    <AddCircleOutline />
                    {invitees.length > 0 ? this.tr('Invite another teammate') : this.tr('Invite teammate')}
                </div>
                {invitees.map((invitee, i) => (
                    <div className={styles.invitee}>
                        {this.inviteeFields.map((field) => {
                            if (Array.isArray(field)) {
                                return (
                                    <div className={styles.halfFieldContainer}>
                                        {field.map((halfField) => (
                                            <div>
                                                {
                                                    <OutlinedField
                                                        key={halfField.name}
                                                        {...halfField}
                                                        value={invitee[halfField.name] || ''}
                                                        fullWidth
                                                        noOnchangeValidation
                                                        callOnChangeOnKeyUp
                                                        error={!!fieldErrors[`invitee_${i}_${halfField.name}`]}
                                                        onKeyDown={this.onKeyDown}
                                                        onChange={(e) => this.onInviteeChanged(invitee.id, e)}
                                                        onBlur={() => {
                                                            halfField.onBlur && halfField.onBlur(invitee, i);
                                                        }}
                                                    />
                                                }
                                            </div>
                                        ))}
                                    </div>
                                );
                            }
                            return (
                                <OutlinedField
                                    key={field.name}
                                    {...field}
                                    ref={(ref) => (this.inviteeMailFieldRefs[invitee.id] = ref)}
                                    value={invitee[field.name] || ''}
                                    fullWidth
                                    error={!!fieldErrors[`invitee_${i}_${field.name}`]}
                                    noOnchangeValidation
                                    callOnChangeOnKeyUp
                                    onKeyDown={this.onKeyDown}
                                    onChange={(e) => this.onInviteeChanged(invitee.id, e)}
                                    onBlur={() => {
                                        field.onBlur && field.onBlur(invitee, i);
                                    }}
                                />
                            );
                        })}
                        <button className={styles.removeInviteeButton} onClick={() => this.removeInvitee(invitee.id)}>
                            <Close />
                        </button>
                    </div>
                ))}
            </>
        );
    };

    showRequiredErrorIfNeeded = () => {
        const { step } = this.state;
        const fieldErrors = this.getInvalidFields(step);
        this.setState({
            fieldErrors,
        });
    };

    getError = () => {
        const { step, fieldErrors } = this.state;
        let error;
        if (Object.values(fieldErrors).includes(true)) {
            error = step == 1 ? this.tr('Please check the validity of your email address.') : this.tr('Please fill all the required fields with valid data before continuing.');
        }
        return error || this.state.error;
    };

    renderDialogContent = () => {
        const { lang, step, signupData } = this.state;
        const error = this.getError();
        switch (step) {
            case 1: {
                const agreementBase = this.tr('By registering, you agree to our ${terms} and ${privacyPolicy}');
                const agreementParts = agreementBase.split(' ');
                const agreement = agreementParts.map((word, i) => {
                    const postfix = i == agreementParts.length - 1 ? '' : ' ';
                    switch (word) {
                        case '${terms}':
                            return (
                                <a
                                    href={`https://help.taimer.com/${lang != 'fi' && lang != 'se' ? 'en' : lang}/articles/1734133-terms-of-service-taimer-com`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {this.tr('Terms of Service')}{' '}
                                </a>
                            );
                        case '${privacyPolicy}':
                            return (
                                <a href={`https://help.taimer.com/${lang != 'fi' && lang != 'se' ? 'en' : lang}/articles/4341582-privacy-policy-taimer-com`} target="_blank" rel="noopener noreferrer">
                                    {this.tr('Privacy Policy')}{' '}
                                </a>
                            );
                        default:
                            return `${word}${postfix}`;
                    }
                });
                return (
                    <>
                        <div className={styles.titleContainer}>
                            <h1>{this.tr('Try Taimer for 14 days')}</h1>
                            <p>{this.tr('Instant access, no credit card needed!')}</p>
                        </div>
                        <div className={styles.fieldContainer}>
                            {error && <p className={styles.error}>{error}</p>}
                            {this.renderFields(step)}
                            <div className={styles.nextStepButtonContainer} onClick={this.showRequiredErrorIfNeeded}>
                                <button disabled={!this.requiredFieldsFilled(step)} className={styles.firstContinueButton} onClick={this.moveToNextStep}>
                                    {this.tr('Continue')}
                                </button>
                            </div>
                            <div className={styles.dividerContainer}>
                                <span className={styles.line} />
                                <p>{this.tr('Or sign up with')}</p>
                                <span className={styles.line} />
                            </div>
                            <GoogleLogin
                                clientId="610531248917-qc9navhqlivfa26vpgn6hhqjfeha3nna.apps.googleusercontent.com"
                                render={(renderProps) => (
                                    <button className={styles.googleButton} onClick={renderProps.onClick}>
                                        <img src={GoogleLogo} />
                                        <h2>{this.tr('Google')}</h2>
                                    </button>
                                )}
                                onSuccess={this.onGoogleLogin}
                                onFailure={this.onGoogleLogin}
                                cookiePolicy="single_host_origin"
                            />
                            <div className={styles.footerTexts}>
                                <p>{agreement}</p>
                                <p>
                                    {this.tr('Already have an account?')} <a href={`https://account.taimer.com`}>{this.tr('Log in')}</a>
                                </p>
                            </div>
                        </div>
                    </>
                );
            }
            case 2:
                return (
                    <>
                        {this.renderStepIndicator()}
                        <div className={styles.titleContainer}>
                            <h1>{this.tr('Setup your account')}</h1>
                            <p>
                                {this.tr("You're signing up as ${email}", { email: signupData?.email })} <button onClick={this.moveToPreviousStep}>({this.tr('Edit email')})</button>
                            </p>
                        </div>
                        <div className={styles.fieldContainer}>
                            {this.renderFields(step)}
                            {error && <p className={`${styles.error} ${styles.bottom}`}>{error}</p>}
                            <div className={styles.nextStepButtonContainer} onClick={this.showRequiredErrorIfNeeded}>
                                <button disabled={!this.requiredFieldsFilled(step)} onClick={this.moveToNextStep}>
                                    {this.tr('Continue')}
                                </button>
                            </div>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        {this.renderStepIndicator()}
                        <div className={styles.titleContainer}>
                            <h1>{this.tr('Yay, almost there!')}</h1>
                            <p>{this.tr('We just need a few more details')}</p>
                        </div>
                        <div className={styles.fieldContainer}>
                            {this.renderFields(step)}
                            {this.renderInviteSection()}
                            {error && <p className={`${styles.error} ${styles.bottom}`}>{error}</p>}
                            <div className={styles.nextStepButtonContainer} onClick={this.showRequiredErrorIfNeeded}>
                                <button disabled={!this.requiredFieldsFilled(step)} onClick={this.moveToNextStep}>
                                    {this.tr('Start using Taimer')}
                                </button>
                            </div>
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className={styles.titleContainer}>
                            <h1>{this.tr('Configuring your account...')}</h1>
                            <p>{this.tr("Hang on tight, you'll be using Taimer in no time!")}</p>
                        </div>
                        <div className="logo-animation">
                            <Lottie
                                options={{
                                    renderer: 'svg',
                                    loop: true,
                                    autoplay: true,
                                    animationData,
                                }}
                                height={400}
                                width={400}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <div id={styles.onboarding}>
                <div className={styles.dialog}>{this.renderDialogContent()}</div>
            </div>
        );
    }
}

export default withSnackbar(OnboardingView);
