import React from "react";
import ListRow from "../ListRow";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

class SettingRow extends ListRow {
    static rowHeight = 63;
    static rowHeightPx = SettingRow.rowHeight + "px";

    constructor(props, context) {
        super(props);

        this._create       = this._create.bind(this);
        this.editAndSave   = this.editAndSave.bind(this);
        this.editAndSaveV2 = this.editAndSaveV2.bind(this);
    }


    editAndSave(name, value) {
        const data = cloneDeep(this.state.data);
        data[name] = value;

        if(isEqual(data, this.state.data) && data[this.props.rowKey] > 0) {
            return;
        }

        if(data[this.props.rowKey] < 0) {
            this._create(data);

            return;
        }

        this.props.listRef.editData(data);

        this.update(data);
    }


    _create(data) {
        const promise = this.create(data);

        if(!promise || !promise.hasOwnProperty("done")) {
            return;
        }

        promise.done(response => {
            const newData = {
                ...data,
                [this.props.rowKey]: Object.prototype.hasOwnProperty.call(response, this.props.rowKey) 
                ? 
                response[this.props.rowKey] 
                : 
                response 
            };

            this.props.listRef.editData(newData, data[this.props.rowKey]);
        });
    }


    /**
     * If value did not change this function
     * does not trigger update
     *
     */
    editAndSaveV2(name, value) {
        let data = this.state.data;
        if (data[name] !== value || data.id < 0) {
            data[name] = value;
            data.id < 0 ? this.create(data) : this.update(data);
            this.setState({ data: data });
        }
    }
}

export default SettingRow;
