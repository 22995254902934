import React from 'react';
import Utils from "../general/Utils";
import { SettingsContext } from './../SettingsContext';
import ListRow from "../list/ListRow";
import ListCell from "../list/ListCell";
import TextInputCell from "../list/cells/TextInputCell";
import SummaryActionButton from "./SummaryActionButton";


class BillSummaryRow extends ListRow {
    static rowHeight = 35;
    static contextType = SettingsContext;


    constructor(props, context) {
        super(props, undefined, undefined, "bills/BillSummaryRow");

        this.context = context;
        this.widths  = Utils.mergeArrayElements(this.props.columnWidths.map(cw => cw.width), [[0, 1, 2], [3], [4], [5, 6]]);
    }
    
    
    defineCells() {
        const { currencyRate } = this.props.rowProps;
        const noCurrencySums = !currencyRate || currencyRate == "1.000000";
        const { taimerAccount } = this.context;
        const numberFormat = new Intl.NumberFormat(taimerAccount.numberFormat, { style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2, currency: this.props.rowProps.currency });
        const data = this.props.rowProps.rows;
        const total      = numberFormat.format(Utils.truncateDecimals((data.length > 0) ? data.map(d => parseFloat(String(noCurrencySums ? d.total : d.currency_total).replace(/\,/g, "."))).reduce((a, c) => a + c) : 0, 2, true));
        const totalNoVat = numberFormat.format(Utils.truncateDecimals((data.length > 0) ? data.map(d => parseFloat(String(noCurrencySums ? d.total_no_vat : d.currency_total_no_vat).replace(/\,/g, "."))).reduce((a, c) => a + c) : 0, 2, true));
        const totalUnitPrice = numberFormat.format(Utils.truncateDecimals((data.length > 0) ? data.map(d => parseFloat(String(noCurrencySums ? d.unit_price : d.currency_unit_price).replace(/\,/g, "."))).reduce((a, c) => a + c) : 0, 2, true));

        const commonStyle = { lineHeight: `${BillSummaryRow.rowHeight}px` };
        const commonProps = { alignCell: true, textAlign: "right", onlyDisplay: true };

        return [
            <ListCell onlyDisplay alignCell textAlign="left" width={this.widths[0]}>
                {this.props.rowProps.editMode && <SummaryActionButton onClick={() => this.props.listRef.addNewRow()} height={BillSummaryRow.rowHeight} text={this.tr("Item")} />}
            </ListCell>,
            <ListCell {...commonProps} style={commonStyle} width={this.widths[1]}>{totalUnitPrice}</ListCell>, 
            <ListCell {...commonProps} style={commonStyle} width={this.widths[2]}>{totalNoVat}</ListCell>,
            <ListCell {...commonProps} style={commonStyle} width={this.widths[3]}>{total}</ListCell>,
        ];
    }


    render() {
        const cells     = this.defineCells();
        const className = ["summaryRow listElement row flex billSummaryRow"].join(" ");

        return (
            <div className={className} style={{ height: `${BillSummaryRow.rowHeight}px` }}>
                {cells}
            </div>
        );
    }
}

export default BillSummaryRow;
