import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react';

import { Tooltip } from '@mui/material';
import { TimerOutlined } from '@mui/icons-material';
import customStyles from './TaimerNavi.module.scss';

interface WorkhourData {
    start: Date,
}

interface Props {
    timer?: WorkhourData | null | false;
    onClick?: () => void,
    tooltip: string;
}

/**
 * https://usehooks-ts.com/react-hook/use-interval
 */
function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

function formatDiff(whStartTime: Date) {
    let diff = Math.round(((new Date().getTime()) - whStartTime.getTime()) / 1000);
    const hours = Math.floor(diff / 3600);
    diff -= hours * 3600;
    const mins = Math.floor(diff / 60);
    const seconds = Math.round(diff - mins * 60).toString().padStart(2, "0");

    if (hours > 0)
        return hours.toString().padStart(2, "0") + ':' + String(mins).padStart(2, "0") + ':' + seconds;
    else
        return String(mins).padStart(2, "0") + ':' + seconds;
}

const WorkhourTimer = (props: Props) => {
    const {
        timer: workhourTimer,
        onClick,
        tooltip
    } = props;

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    useInterval( () => {
        forceUpdate();
    }, workhourTimer ? 1000 : null);

    return (
        <div className={customStyles.actionButton} onClick={onClick}>
            <div className={customStyles.withInfoLabel}>
                <Tooltip title={tooltip}>
                    <TimerOutlined />
                </Tooltip>
                {workhourTimer && <p>{workhourTimer ? formatDiff(workhourTimer.start) : ""}</p>}
            </div>
            {workhourTimer && <div className={`marker ${workhourTimer && "red"}`}></div>}
        </div>
        // <div className="action-button" onClick={onClick}>
        //     <div className="icon-text">
        //         <WorkhourIcon title="" />
        //         {workhourTimer && <div className='info'>{workhourTimer ? formatDiff(workhourTimer.start) : ""}</div>}
        //     </div>
        //     {workhourTimer && <div className={`marker ${workhourTimer && "red"}`}></div>}
        // </div>
    );
};

export default WorkhourTimer;