import React, { ReactNode } from 'react';
import { MenuItem, Radio, Tooltip } from '@mui/material';
import { Cancel, ChangeCircle, CheckCircle, Delete, Edit, InfoOutlined, KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@mui/icons-material';
import TaimerComponent from '../TaimerComponent';
import styles from './SliderFieldGroup.module.scss';
import FieldEditSlider from './FieldEditSlider';
import Link from './Link';
import ContextMenu from './ContextMenu';
import DataList from './DataList';
import { format } from 'date-fns';
import { isValid } from 'date-fns/esm';
import { prefixLink } from '../helpers';

interface SliderFieldGroupProps {
    onItemSaved: (item: any) => void;
    onItemAdded: (item: any) => void;
    onItemChanged: (item: any) => void;
    onItemDeleted: (item: any) => void;
    onDefaultChanged: (e: any, itemId: number | string) => void;
    items: any[];
    defaultSelectionKey?: string;
    newItemDefaultValues?: any;
    editingDisabled?: boolean;
    fields: any[];
    canDeleteItem?: (item: any, fromPreview?: boolean) => boolean;
    alwaysShowDelete?: boolean;
    deleteDisabledTooltip?: string;
    editButtonLocation?: string;
    renderRightComponentForItem?: (item: any) => ReactNode;
    getOptions: (item: any) => { key: string; title: string; onClick?: (item: any) => void; isChange?: boolean; isEdit?: boolean; isDelete?: boolean; icon: ReactNode }[];
    showAll?: boolean;
    allowAdd?: boolean;
    allowChange?: boolean;
    addFromDropdownItems?: any[];
    addDropdownNoOptions?: any;
    addDropdownNoOptionsProps?: any;
    changeFromDropdownItems: any[];
    changeButtonTooltip?: string;
    allowEditButton?: boolean;
    sliderEditTitle?: string;
    sliderAddTitle?: string;
    additionalFields?: (item: any) => any;
    additionalPreviewFields?: any;
    overrideOnItemSaved?: boolean;
}

const MINIMIZED_ADDRESS_LIST_SECTION_AMOUNT = 2;

interface SliderFieldGroupState {
    showAll: boolean;
    sliderData?: any;
    addFromDropdownMode?: boolean;
    changeFromDropdownMode?: boolean;
}

export default class SliderFieldGroup extends TaimerComponent<SliderFieldGroupProps, SliderFieldGroupState> {
    constructor(props, context) {
        super(props, context, 'general/SliderFieldGroup');
        this.state = {
            showAll: this.props.showAll || false,
            addFromDropdownMode: false,
            changeFromDropdownMode: false,
        };
    }

    showAll = () => this.setState({ showAll: true });

    showLess = () => this.setState({ showAll: false });

    onEditItem = (item) => {
        const sliderData = { item, title: this.props.sliderEditTitle || this.tr('Edit item') };
        this.setState({ sliderData });
    };

    onAddItem = () => {
        const { newItemDefaultValues } = this.props;
        const sliderData = { item: { id: -1, ...newItemDefaultValues }, title: this.props.sliderAddTitle || this.tr('Add item') };
        this.setState({ sliderData });
    };

    onItemAdded = (item) => {
        this.setState({ addFromDropdownMode: false });
        this.props.onItemAdded && this.props.onItemAdded(item);
    };

    onItemChanged = (item) => {
        this.setState({ changeFromDropdownMode: false });
        this.props.onItemChanged && this.props.onItemChanged(item);
    };

    onItemSaved = (item) => {
        this.onCloseSlider();
        this.props.onItemSaved && this.props.onItemSaved(item);
    };

    onItemDeleted = (item) => {
        this.onCloseSlider();
        this.props.onItemDeleted && this.props.onItemDeleted(item);
    };

    onCloseSlider = () => this.setState({ sliderData: undefined });

    findChild = (item, id) => {
        if (item.id == id) return item;
        return item.children.find((child) => child.id == id);
    };

    getFieldValueKey = (field) => {
        return field?.overrideValueKey || field?.key;
    };

    getValue = (item, field) => {
        if (field.customPreviewComponent) return field.customPreviewComponent(item);
        const fieldValueKey = this.getFieldValueKey(field);
        switch (field.type) {
            case 'treeselect': {
                const id = item[fieldValueKey];
                let value = '';
                for (let i = 0; i < field?.options.length; i++) {
                    const option = field.options[i];
                    const foundValue = this.findChild(option, id);
                    if (foundValue) {
                        value = foundValue.label || foundValue.name;
                        break;
                    }
                }
                return value;
            }
            case 'switch':
            case 'dateswitch':
                if (item[fieldValueKey] == '1' || item[fieldValueKey] == true) {
                    return item[fieldValueKey];
                }
                return '';
            case 'data_select':
            case 'select':
                if (field.isMulti) {
                    const multiValueArr = Array.isArray(item[fieldValueKey]) ? item[fieldValueKey] : item[fieldValueKey]?.split ? item[fieldValueKey]?.split(',') : item[fieldValueKey];
                    return (multiValueArr || [])
                        .map((v) => {
                            if (field.formatMultiValue) {
                                const formatted = field.formatMultiValue(v, field.options);
                                return formatted?.label || formatted?.name || (field.strictMultiValue ? undefined : v);
                            }
                            return typeof v == 'object' ? v.label || v.name : v;
                        })
                        .filter((i) => !!i)
                        .join(', ');
                }
                const foundOption =
                    field.type == 'select'
                        ? item[fieldValueKey]
                        : field?.options?.find((o) => o.id == (item[fieldValueKey] !== undefined && item[fieldValueKey] !== '' ? item[fieldValueKey] : field?.defaultValue));
                return foundOption?.label || foundOption?.name;
            case 'daterange': {
                const startDate = new Date((item[field.startKey || 'startDate']) || undefined);
                const endDate = new Date((item[field.endKey || 'endDate']) || undefined);
                return !isValid(startDate) && !isValid(endDate)
                    ? ''
                    : `${isValid(startDate) ? format(startDate, this.context.userObject.dateFormat) : ''} - ${isValid(endDate) ? format(endDate, this.context.userObject.dateFormat) : ''}`;
            }
            case 'date': {
                const date = new Date(item[fieldValueKey]);
                return !!item[fieldValueKey] && isValid(date) ? format(date, this.context.userObject.dateFormat) : '';
            }
            default: {
                if (field.additionalProps?.format == 'currency') {
                    const currencyFormatter = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, {
                        style: 'currency',
                        currency: field.additionalProps?.currency,
                        maximumFractionDigits: 2,
                    }).format;
                    return currencyFormatter(item[fieldValueKey]);
                }
                return item[fieldValueKey];
            }
        }
    };

    renderValue = (field, value) => {
        if (field.customPreviewComponent) return value;
        if (field.formURL) {
            return (
                <Link url={field.formURL(value || '')} openInNewTab={field.openInNewTab}>
                    <h3>{value}</h3>
                </Link>
            );
        }
        switch (field.type) {
            case 'switch':
            case 'dateswitch':
                return value == '1' || value == true ? (
                    <div className={styles.switchValueRow}>
                        <CheckCircle />
                        <h3>{field.valuePostfix && field.valuePostfix()}</h3>
                    </div>
                ) : (
                    ''
                );
                // if we want to show also false values
                // return (
                //     <div className={styles.switchValueRow}>
                //         {value == '1' || value == true ? <CheckCircle /> : <Cancel className={styles.false} />}
                //         <h3>{field.valuePostfix && field.valuePostfix()}</h3>
                //     </div>
                // );
            case 'link':
                return (
                    <Link target="_blank" href={prefixLink(value)} rel="noopener">
                        <h3>{value}</h3>
                    </Link>
                );
            case 'textarea':
                return <h3 className={styles.multiline}>{value}</h3>;
        }
        switch (field.validation) {
            case 'phone':
                return (
                    <Link url={`tel:${value}`}>
                        <h3>{value}</h3>
                    </Link>
                );
            case 'email':
                return (
                    <Link url={`mailto:${value}`}>
                        <h3>{value}</h3>
                    </Link>
                );
            default:
                return <h3>{value}</h3>;
        }
    };

    addFromDropdown = () => this.setState({ addFromDropdownMode: true });
    closeAddDropdown = () => this.setState({ addFromDropdownMode: false });

    changeFromDropdown = () => this.setState({ changeFromDropdownMode: true });
    closeChangeDropdown = () => this.setState({ changeFromDropdownMode: false });

    render() {
        const {
            items = [],
            fields,
            defaultSelectionKey,
            canDeleteItem,
            onDefaultChanged,
            editingDisabled,
            alwaysShowDelete,
            deleteDisabledTooltip,
            editButtonLocation,
            renderRightComponentForItem,
            getOptions,
            allowAdd,
            allowChange,
            addFromDropdownItems,
            changeFromDropdownItems,
            additionalFields,
            addDropdownNoOptions,
            addDropdownNoOptionsProps,
            changeButtonTooltip,
            overrideOnItemSaved
        } = this.props;
        const { showAll, sliderData, addFromDropdownMode, changeFromDropdownMode } = this.state;
        let filteredItems = items.filter((item) => item.deleted != 1);
        if (defaultSelectionKey) {
            filteredItems = filteredItems.sort((a, b) => Number(b[defaultSelectionKey]) - Number(a[defaultSelectionKey]));
        }
        return (
            <div className={styles.addressListSection}>
                <div className={styles.toolbar}>
                    {allowChange &&
                        (changeFromDropdownItems || []).length > 1 &&
                        !addFromDropdownMode &&
                        (changeFromDropdownMode ? (
                            <div className={styles.addDropdownContainer}>
                                <DataList
                                    fullWidth
                                    label={this.tr('Select item')}
                                    autoFocus
                                    openMenuOnFocus
                                    options={changeFromDropdownItems}
                                    onChange={this.onItemChanged}
                                    menuListMaxHeight={200}
                                    menuWidth={300}
                                    virtualized
                                    shownCount={20}
                                />
                                <button onClick={this.closeChangeDropdown}>{this.tr('Cancel')}</button>
                            </div>
                        ) : (
                            <Tooltip title={changeButtonTooltip || ''} classes={{ tooltip: 'darkblue-tooltip' }} placement="right" arrow>
                                <button className={styles.addButton} onClick={this.changeFromDropdown}>
                                    {this.tr('Change')}
                                </button>
                            </Tooltip>
                        ))}
                    {allowAdd &&
                        !changeFromDropdownMode &&
                        (addFromDropdownMode ? (
                            <div className={styles.addDropdownContainer}>
                                <DataList
                                    fullWidth
                                    label={this.tr('Select item to add')}
                                    autoFocus
                                    openMenuOnFocus
                                    options={addFromDropdownItems}
                                    onChange={this.onItemAdded}
                                    menuListMaxHeight={200}
                                    menuWidth={300}
                                    shownCount={20}
                                    noOptions={addDropdownNoOptions}
                                    onItemCreated={this.onItemAdded}
                                    {...addDropdownNoOptionsProps}
                                />
                                <button onClick={this.closeAddDropdown}>{this.tr('Cancel')}</button>
                            </div>
                        ) : (
                            <button className={styles.addButton} onClick={addFromDropdownItems ? this.addFromDropdown : this.onAddItem}>
                                {this.tr('Add')}
                            </button>
                        ))}
                </div>
                {filteredItems.length == 0 ? (
                    <p>{this.tr('No items here.')}</p>
                ) : (
                    <>
                        <ul className={styles.items}>
                            {(showAll ? filteredItems : filteredItems.slice(0, MINIMIZED_ADDRESS_LIST_SECTION_AMOUNT)).map((item) => {
                                const canDelete = canDeleteItem && canDeleteItem(item, true);
                                const options = ((getOptions && getOptions(item)) || []).filter(
                                    (o) => (!o.isChange || (o.isChange && allowChange)) && (!o.isEdit || (o.isEdit && !editingDisabled)) && (!o.isDelete || (o.isDelete && canDelete))
                                );
                                return (
                                    <div key={item.id} className={`${styles.itemContainer} ${!editingDisabled ? styles.editable : ''}`}>
                                        <li key={item.id}>
                                            {editButtonLocation == 'top' && !editingDisabled && (
                                                <button style={{ marginTop: -15, marginBottom: 5 }} className={styles.addButton} onClick={() => this.onEditItem(item)}>
                                                    {this.tr('Edit')}
                                                </button>
                                            )}
                                            {fields.map((field, i) => {
                                                if (field.isHidden && field.isHidden(item, true)) return null;
                                                if (field.type == 'header') {
                                                    return (
                                                        <div key={field.title} className={styles.subheader}>
                                                            {field.tooltip ? (
                                                                <Tooltip title={field.tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="right" arrow>
                                                                    <div>
                                                                        <h3>{field.title}</h3>
                                                                        <InfoOutlined className={styles.tooltip} />
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div>
                                                                    <h3>{field.title}</h3>
                                                                </div>
                                                            )}
                                                            {field.noItemsText && <p>{field.noItemsText}</p>}
                                                        </div>
                                                    );
                                                }
                                                let value = this.getValue(item, field) || field.defaultValue || (field.getPlaceholder ? field.getPlaceholder(item) : field.placeholder);
                                                if (!value && !field.forceVisible) return null;
                                                if (field.formatValue) {
                                                    value = field.formatValue(value);
                                                }
                                                return (
                                                    <div
                                                        key={`${field.key}_${i}`}
                                                        className={`${styles.fieldRow} ${
                                                            field.verticalAlignment == 'center' || field.type == 'switch' || field.type == 'dateswitch' ? styles.center : ''
                                                        }`}
                                                    >
                                                        {field.tooltip ? (
                                                            <Tooltip title={field.tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="right" arrow>
                                                                <div className={styles.title}>
                                                                    <p>{field.title}</p>
                                                                    <InfoOutlined className={styles.tooltip} />
                                                                </div>
                                                            </Tooltip>
                                                        ) : (
                                                            <div className={styles.title}>{<p>{field.title}</p>}</div>
                                                        )}
                                                        {this.renderValue(field, value)}
                                                        {editButtonLocation == field.key && !editingDisabled && (
                                                            <button className={styles.addButton} onClick={() => this.onEditItem(item)}>
                                                                {this.tr('Edit')}
                                                            </button>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        {
                                            <div className={styles.rightSide}>
                                                <div>
                                                    {renderRightComponentForItem && renderRightComponentForItem(item)}
                                                    {!!defaultSelectionKey && (
                                                        <Tooltip placement="top" title={this.tr('Set as default')}>
                                                            <Radio
                                                                disabled={editingDisabled}
                                                                onChange={(e) => onDefaultChanged(e, item.id)}
                                                                color="primary"
                                                                checked={Number(item[defaultSelectionKey]) > 0}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {options.length > 0 && (
                                                        <ContextMenu label={<MoreVert />} noExpandIcon>
                                                            {options.map((option) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={option.key}
                                                                        className={option.isDelete ? 'delete' : ''}
                                                                        onClick={() =>
                                                                            option.isEdit
                                                                                ? this.onEditItem(item)
                                                                                : option.isDelete
                                                                                ? this.onItemDeleted(item)
                                                                                : option.isChange
                                                                                ? this.changeFromDropdown()
                                                                                : option.onClick && option.onClick(item)
                                                                        }
                                                                    >
                                                                        {option.icon || (option.isEdit ? <Edit /> : option.isChange ? <ChangeCircle /> : option.isDelete ? <Delete /> : undefined)}
                                                                        {option.title}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </ContextMenu>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                );
                            })}
                        </ul>
                        {filteredItems.length > MINIMIZED_ADDRESS_LIST_SECTION_AMOUNT && !this.props.showAll && (
                            <button onClick={showAll ? this.showLess : this.showAll}>
                                {showAll ? this.tr('Show less') : `${this.tr('Show more')} (${filteredItems.length - MINIMIZED_ADDRESS_LIST_SECTION_AMOUNT})`}
                                {showAll ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </button>
                        )}
                    </>
                )}
                <FieldEditSlider
                    onSave={overrideOnItemSaved ? this.props.onItemSaved : this.onItemSaved}
                    onDeleteItem={this.onItemDeleted}
                    canDeleteItem={canDeleteItem}
                    alwaysShowDelete={alwaysShowDelete}
                    deleteDisabledTooltip={deleteDisabledTooltip}
                    item={sliderData?.item}
                    fields={[
                        ...(fields || []),
                        ...(defaultSelectionKey
                            ? [
                                  {
                                      key: defaultSelectionKey,
                                      title: this.tr('Set this item as default'),
                                      type: 'radio',
                                  },
                              ]
                            : []),
                    ]}
                    title={sliderData?.title || ''}
                    additionalFields={additionalFields}
                    open={!!sliderData}
                    onClose={this.onCloseSlider}
                />
            </div>
        );
    }
}
