import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import DataList from './../../general/DataList';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';

export default class AddToMailingListDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context)
	{
		super(props, context, "list/dialogs/AddToMailingListDialog");
        this.addToMailingLists	= this.addToMailingLists.bind(this);
        this.loadMailingList = this.loadMailingList.bind(this);
        this.mailingLists  = this.props.mailing_lists;

        var mailingLists = []

        this.props.mailing_lists.forEach(element => {
            mailingLists.push({
                value: element.id,
                label: element.name
            })
        });


        this.state = {
            mailingLists,
            values: [],
        };
    }
    //siivoa mailinglistit ja contactit arrayna
    addToMailingLists(name, value){ 
        let contacts            = _.map(this.props.contacts, 'id');
        let maillists           = _.map(this.state.values, 'value');
        
        DataHandler.post({url: `mail_list_add`}, {contacts, maillists}).done(()=> this.props.onClose());
        
        //this.props.onClose();
    }

    loadMailingList (value, { action }) {     
        this.setState({values: value})
    }

	render() {
        return <DialogBorder className="AddToMailingListDialog" id="dialog" title={this.tr("Add selected to mailing list")} textOk="Add" onClose={this.props.onClose} onSave={this.addToMailingLists}>
			<div id="container" className="AddToMailingListDialog">
				
                <DataList options={this.state.mailingLists} value={this.state.values} isMulti onChange={this.loadMailingList}  />    
			</div>
		</DialogBorder>
	}
}