import React from 'react';

/* css */
import '../styles.css';
import '../theme/default.css';

/* material-ui */
import CalendarIcon from '@mui/icons-material/DateRange';

/* other */
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DateRange from './DateRange';
import { format } from 'date-fns';
import classnames from 'classnames';
import coreStyles from '../styles';
import MaskedInput from 'react-text-mask';
import DefinedRange from './DefinedRange';
import TaimerComponent from '../../../../TaimerComponent';
import ClickAwayWrapper from '../../../ClickAwayWrapper';
import { SettingsContext } from './../../../../SettingsContext';
import { getDefaultStaticRanges } from '../defaultRanges';
import { InputLabel, FormControl, Popper, Tooltip } from '@mui/material';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { findNextRangeIndex, generateStyles } from '../utils.js';
import Calendar from './Calendar';
import { Close } from '@mui/icons-material';
import { createStaticRanges } from '../defaultRanges';

export class DateRangePopper extends TaimerComponent {

  static defaultProps = {
      months: 2,
      direction: "horizontal",  
      disabled: false,
      rangeOptions: {
          useFinancialYear: false,
      },
      mode: 'daterange',
      disablePortal: false,
  };

  constructor(props) {
      super(props, null, 'general/DateRangePopper');

      this.calendar = React.createRef();
      this.state = {
          focusedRange: [findNextRangeIndex(this.props.ranges), 0],
          showAllFinancialYears: false,
      };
  }

  componentDidUpdate = (oldProps) => {
      if (oldProps.date !== this.props.date) {
          this.calendar.current && this.calendar.current.focusToDate(this.props.date);
      };
  }

  render() {
      const { focusedRange, showAllFinancialYears } = this.state;
      const { rangeOptions, additionalBottomComponent, customDateRanges = [], disablePortal, date  } = this.props;

      const dateOptions = {
          weekStartsOn: this.context.calendar.startOfWeek,
      };
      const staticRanges = [...getDefaultStaticRanges(dateOptions, rangeOptions).map((e) => {
          e.label = this.tr(e.label);
          return e;
      }), ...(customDateRanges ? createStaticRanges(customDateRanges) : [])];
      const financialYears = showAllFinancialYears ? this.props.financialYears : (this.props.financialYears || []).slice(0, 3);
      return (
          <Popper disablePortal={disablePortal} open={this.props.open} keepMounted placement="bottom-start" anchorEl={this.props.anchorEl}>
              <ClickAwayWrapper mouseEvent="onMouseDown" onClickAway={this.props.handleBlur} active={this.props.open}>
                  <div className={`dateRangePickerPopperCalendarView ${this.props.className}` + (this.props.open && !this.props.disabled ? '' : ' hidden')}>
                      {this.props.mode == 'date' ? (
                          <Calendar
                              date={(this.props.date)}
                              shownDate={(this.props.date)}
                              ref={this.calendar}
                              onChange={(e) => {
                                this.props.onChange(e);
                                this.props.handleBlur();
                              }}
                              locale={this.context.calendar.locale}
                              showDateDisplay={false}
                          />
                      ) : (
                          <>
                              <DefinedRange
                                  staticRanges={staticRanges}
                                  focusedRange={focusedRange}
                                  onPreviewChange={(value) => this.dateRange.updatePreview(value)}
                                  {...this.props}
                                  range={this.props.ranges[focusedRange[0]]}
                                  className={undefined}
                                  onRangeSelected={this.props.handleBlur}
                              />
                              {this.props.financialYears && (
                                  <div className="financial-years">
                                      <p>{this.tr('Financial years')}</p>
                                      <div className="btns">
                                          {financialYears.map((fy) => (
                                              <button
                                                  onClick={() => {
                                                      const range = { startDate: fy.start, endDate: fy.end };
                                                      this.props.onChange({ selection: range });
                                                      this.dateRange.updatePreview(range);
                                                      this.props.handleBlur();
                                                  }}
                                              >
                                                  {fy.label}
                                              </button>
                                          ))}
                                          {this.props.financialYears.length > 3 && (
                                              <button onClick={() => this.setState({ showAllFinancialYears: !this.state.showAllFinancialYears })} className="show-more">
                                                  {showAllFinancialYears ? `- ${this.tr('Show less')}` : `+ ${this.tr('Show more')}`}
                                              </button>
                                          )}
                                      </div>
                                  </div>
                              )}
                              <DateRange
                                  onRangeFocusChange={(focusedRange) => this.setState({ focusedRange })}
                                  focusedRange={focusedRange}
                                  {...this.props}
                                  ref={(t) => (this.dateRange = t)}
                                  className={undefined}
                                  onRangeSelected={this.props.handleBlur}
                              />
                          </>
                      )}
                      {additionalBottomComponent}
                  </div>
              </ClickAwayWrapper>
          </Popper>
      );
  }
}

class DateRangePicker extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    rangeOptions: {
      useFinancialYear: false,
    },
    allowClear: false,
  }

  constructor(props) {
    super(props, null, 'general/dateRangePicker');
    this.state = {
      focusedRange: [findNextRangeIndex(props.ranges), 0],
      open: false,
      showAllFinancialYears: false
    };
    this.inputContainer = React.createRef();
    this.firstInput = React.createRef();
    this.secondInput = React.createRef();
    this.styles = generateStyles([coreStyles, props.classNames]);

    let regExp = new RegExp("([a-zA-Z])");
    this.props.dateFormat.split("").map(char => {
      if(regExp.exec(char) === null) {
        this.delimeter = char;
      }
      return null;
    });

      this.formatArray = this.props.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
      this.handleBlur = this.handleBlur.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
      return true;
  }

  onDateChange(dateType, event) {
    if(event.target.value.indexOf("_") == -1 && (event.target.value != "" || this.props.allowClear)) {
      let date = undefined;

      if (event.target.value !== "") {
        const dateParts = event.target.value.split(this.delimeter);
        date = new Date(dateParts[this.formatArray.indexOf('yyyy')], dateParts[this.formatArray.indexOf('mm')]-1, dateParts[this.formatArray.indexOf('dd')]);
      }

      const range = this.props.onInputChange(dateType, date);
      if(this.state.open) {
        this.dateRange && this.dateRange.updatePreview(range)
        this.handleBlur();
      }
    }
  }

  handleToggle = () => {
    this.setState({open: !this.state.open});
  }

  handleFocus() {
    this.setState({open: true});
  }

  handleBlur() {
    if(!this.state.open)
      return;
    this.firstInput.current && this.firstInput.current.inputElement.blur();
    this.secondInput.current && this.secondInput.current.inputElement.blur();
    this.setState({open: false});
    this.props.onClose && this.props.onClose();
  }

  handleChange(date) {
    this.props.onChange(date);
    this.handleBlur();
  }

  clearSelection = () => {
      this.props.onChange({selection: { startDate: undefined, endDate: undefined }});
      this.handleBlur();
  }

  render() {
    const { className, disabled, dateFormat, showClearButton } = this.props;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(this.props.dateFormat.toLowerCase());

    const regExp = new RegExp("([a-zA-Z])");
    const mask = dateFormat.split("").map(char => {
      if(regExp.exec(char) === null)
        return char;

      return /\d/;
    });

    const {startDate, endDate} = this.props.ranges[0] ?? {};

    return (
      <div ref={this.inputContainer} className={classnames(this.styles.dateRangePickerWrapper, `daterange ${className}`, disabled && "disabled", this.props.error && 'error')}>
        <div>
        {this.props.mode != "list" ? (<FormControl className={"formControl"} variant="filled">
          <InputLabel
            ref={ref => {
              this.labelRef = ReactDOM.findDOMNode(ref);
            }}
            shrink={true}>
            {this.props.label}
          </InputLabel>
          <div className="inputWrapper">
            <MaskedInput
              ref={this.firstInput}
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput first"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(startDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("start", e)}
              onFocus={(e) => this.handleFocus(e)} 
              data-testid={"daterangepicker_start_" + this.props.label} />
            {!startDate < 1 && !endDate < 1 && <span className="date-separator">-</span>}
            <MaskedInput
              ref={this.secondInput}
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput second"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(endDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("end", e)}
              onFocus={(e) => this.handleFocus(e)}
              data-testid={"daterangepicker_end_" + this.props.label} />
           {showClearButton && startDate && endDate ? <Tooltip placement="top" title={this.tr("Clear selection")}><Close onClick={this.clearSelection}/></Tooltip> : <CalendarIcon
                alt="Date picker"
                onClick={(e) => this.handleToggle(e)}
            />}
          </div>
        </FormControl>
        ) : (
          <div className="inputWrapper">
            <MaskedInput
              ref={this.firstInput}
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(startDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("start", e)}
              onFocus={(e) => this.handleFocus(e)} />
            {!startDate < 1 && !endDate < 1 && <span>-</span>}
            <MaskedInput
              ref={this.secondInput}
              disabled={this.props.disabled}
              mask={mask}
              className="datePickerInput"
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(endDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("end", e)}
              onFocus={(e) => this.handleFocus(e)} />
           {showClearButton && startDate && endDate ? <Tooltip placement="top" title={this.tr("Clear selection")}><Close onClick={this.clearSelection}/></Tooltip> : <CalendarIcon
                alt="Date picker"
                onClick={(e) => this.handleToggle(e)}
            />}
          </div>
        )}
        </div>
            <DateRangePopper open={this.state.open} handleBlur={this.handleBlur} anchorEl={this.inputContainer.current} {...this.props} />
        </div>
      );

      // return editor;
    // this.state.open ? (
    //     // TODO: rikkoo kalenterin doubledatafield
    //     // <ClickAwayListener onClickAway={() => this.handleBlur()}>
    //         editor
    //     // </ClickAwayListener>
    // ) : editor;
  }
}

DateRangePicker.defaultProps = {
  months: 2,
  direction: "horizontal",
  dateFormat: "dd/mm/yyyy",
  mode: "input",
  disabled: false
};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  className: PropTypes.string,
};

export default DateRangePicker;
