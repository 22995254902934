import React from "react";

import ListCell from "../../list/ListCell";
import DateCell from "../../list/cells/DateCell";
import SettingRow from "../../list/rows/SettingRow";
import TrashCanIcon from "@mui/icons-material/Delete";
import LinkListCell from "../../list/LinkListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import TextAreaCell from "../../list/cells/TextAreaCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { IconButton } from "@mui/material";
import { minBy } from "lodash";
import ConfirmationDialog from "../../settings/dialogs/ConfirmationDialog";

export default class TimeManagementOvertimeMultiplierRow extends SettingRow {
    editAndSave(name, value) {
        const { sharedData: { enqueueSnackbar, tr } } = this.props;

        const data = cloneDeep(this.state.data);

        if (name === 'start' || name === 'multiplier') {
            const num = Number(Number(value.replace(",", ".")).toFixed(2));

            if (!isNaN(num))
                data[name] = num;
            else
                data[name] = value;
        } else {
            data[name] = value;
        }

        if (isEqual(data, this.state.data) && data[this.props.rowKey] > 0) {
            return;
        }

        if (data[this.props.rowKey] < 0) {
            this.setData(data);

            return;
        }

        const [valid, error] = this.isValid(data);

        if (!valid) {
            if (error === 'duplicate') {
                enqueueSnackbar(tr('Multiplier with same start time already exists.'), {
                    variant: 'error',
                });
            } else if (error === 'negative_start' || error === 'start') {
                enqueueSnackbar(tr('Start time must be positive number.'), {
                    variant: 'error',
                });
            } else if (error === 'start_24') {
                enqueueSnackbar(tr('Start time must be less than 24h.'), {
                    variant: 'error',
                });
            } else if (error === 'negative_multiplier' || error === 'multiplier') {
                enqueueSnackbar(tr('Multiplier must be positive number.'), {
                    variant: 'error',
                });
            } else if (error === 'multiplier_large') {
                enqueueSnackbar(tr('Multiplier must be less than 100.'), {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(tr('Both start hours and multiplier is required.'), {
                    variant: 'error',
                });
            }
            return;
        }

        this.setData(data);

        this.props.listRef.editData(data);

        this.update(data);
    }

    isValid = (data) => {
        const { sharedData: { multipliers } } = this.props;

        if (typeof data.start !== "number") {
            return [false, 'start'];
        }
        if (typeof data.multiplier !== "number") {
            return [false, 'multiplier'];
        }

        if (data.start < 0)
            return [false, 'negative_start'];

        if (data.start >= 24)
            return [false, 'start_24'];

        if (data.multiplier < 1)
            return [false, 'negative_multiplier'];

        if (data.multiplier >= 100)
            return [false, 'multiplier_large'];

        const multiplier = multipliers.find(x => x.start === data.start && x.id !== data.id);
        
        if (multiplier) {
            return [false, 'duplicate'];
        }

        return [true];
    }

    saveNewRow = () => {
        const { data } = this.state;
        const { sharedData: { enqueueSnackbar, tr } } = this.props;

        const [valid, error] = this.isValid(data);

        if (!valid) {
            if (error === 'duplicate') {
                enqueueSnackbar(tr('Multiplier with same start time already exists.'), {
                    variant: 'error',
                });
            } else if (error === 'negative_start' || error === 'start') {
                enqueueSnackbar(tr('Start time must be positive number.'), {
                    variant: 'error',
                });
            } else if (error === 'negative_multiplier' || error === 'multiplier') {
                enqueueSnackbar(tr('Multiplier must be positive number.'), {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(tr('Both start hours and multiplier is required.'), {
                    variant: 'error',
                });
            }
            return;
        }

        this._create(data);
    }

    deleteClick = () => {
        this.setState({showConfirmationDialog: true});
    }

    render() {
        const { data } = this.state;
        const { sharedData: { multipliers, tr } } = this.props;

        const nextMultiplier = minBy(multipliers.filter(x => x.start > data.start), 'start');

        const cells = {
            delete: (
                <ListCell
                    className="noBg"
                    permanentEditMode={true}
                    width={this.props.columnWidthMap["delete"]}
                >

                    {data.id < 0 ? <>
                        <IconButton onClick={() => this.delete(data)}>
                            <Cancel className="cancelIcon"  />
                        </IconButton>

                        <IconButton onClick={this.saveNewRow}>
                            <CheckCircle className="saveNewRowCheckCircleIcon"  />
                        </IconButton>
                    </> : <>
                        <IconButton onClick={() => this.deleteClick()}>                      
                            <TrashCanIcon />
                        </IconButton>
                    </>}
                </ListCell>
            ),
            start: (
                <TextInputCell
                    width={this.props.columnWidthMap["start"]}
                    value={this.state.data["start"] !== undefined ? String(this.state.data["start"]) : ''}
                    name="start"
                    onEdited={(name, value) => {
                        this.editAndSave(name, value);
                    }}
                />
            ),
            end: (
                <TextInputCell
                    width={this.props.columnWidthMap["end"]}
                    value={ nextMultiplier ? `${nextMultiplier.start}` : tr('end of day') }
                    name="end"
                    editable={false}
                />
            ),
            multiplier: (
                <TextInputCell
                    width={this.props.columnWidthMap["multiplier"]}
                    value={String(this.state.data["multiplier"])}
                    name="multiplier"
                    onEdited={(name, value) => {
                        this.editAndSave(name, value);
                    }}
                />
            ),
        };

        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.state.showConfirmationDialog && <ConfirmationDialog
                    data={{
                        text: tr("Delete multiplier?"),
                    }}
                    onDialogClose={() => this.setState({showConfirmationDialog: false})}
                    onDialogSave={() => this.delete(data)}
                />}
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}
