import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import InvoiceList from '../../list/lists/InvoiceList';
import MassInvoicingList from '../../list/lists/MassInvoicingList';
import InvoiceLogListWithContext from '../../list/lists/InvoiceLogList';
import EmbedVideoOverlay from '../../general/EmbedVideoOverlay';

class Invoices extends TaimerComponent<any, any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Invoices');
        const {
            userObject,
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'invoices',
                label: this.tr('Invoices'),
                hidden: !hasPrivilege('invoices'),
            },
            {
                id: 'mass-invoicing',
                label: this.tr('Mass Invoicing'),
                hidden: !this.context.addons.mass_invoicing || userObject.mass_invoicing_companies.length == 0,
            },
            {
                id: 'invoices-log',
                label: this.tr('Invoices Log'),
                hidden: !hasPrivilege('invoices'),
            },
        ];
    }

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            // case 'mass-invoicing':
            //     return {
            //         title: this.tr('How mass invoicing works'),
            //         methods: {
            //             article: this.context.userObject.language == 'fi' ? 'https://support.taimer.com/mass-invoicing' : 'https://support.taimer.com/fi/massalaskutus',
            //             // video: 'https://player.vimeo.com/video/470207594?h=0a58f7548d&badge=0&autoplay=1&autopause=0&player_id=0&app_id=58479',
            //         },
            //     };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} getTabInfoButtonProps={this.getTabInfoButtonProps} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'invoices':
                            return <InvoiceList {...this.props} />;
                        case 'mass-invoicing':
                            return <MassInvoicingList {...this.props} />;
                        case 'invoices-log':
                            return <InvoiceLogListWithContext {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Invoices;
