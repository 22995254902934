import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import TimeTracker from '../../workhours/time-tracker/TimeTracker';
import TaimerCalendarLogin from '../../taimercalendar/TaimerCalendarLogin';

class TimeManagement extends TaimerComponent<any, any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/TimeManagement');
        this.tabs = [
            {
                id: 'time-tracker',
                label: this.tr('Time Tracker'),
                items: [
                    {
                        id: 'calendarView',
                        label: this.tr('Calendar View'),
                    },
                    {
                        id: 'bulkEntry',
                        label: this.tr('Bulk Entry'),
                    },
                    {
                        id: 'myHours',
                        label: this.tr('My Hours Listed'),
                    },
                ],
            },
            {
                id: 'calendar',
                label: this.tr('Calendar'),
                hidden: this.context.userObject.show_calendar != 1,
            },
            {
                id: 'approvals',
                label: this.tr('Hours Approval'),
                hidden: () => !this.context.timeTracker?.show_approval,
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} selectedSubTab={this.props.selectedSubTab} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'calendarView':
                        case 'bulkEntry':
                        case 'myHours':
                        case 'approvals':
                            return <TimeTracker {...this.props} tab={selectedTab} />;
                        case 'calendar':
                            return <TaimerCalendarLogin {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default TimeManagement;
