import React from 'react';
import TaimerComponent from "../../TaimerComponent";


class TravelExpenseTranslations extends TaimerComponent {

    constructor(props, context) {

        super(props, context, "general/backendTranslations/TravelExpensePrint");

    }

    returnTranslations = (langs) => {
        return langs.reduce((acc, lang) => ({...acc, [lang]: {
            invoice_number: this.beTr('Invoice number', lang),
            travel_expenses: this.beTr('Travel expenses', lang),
            company: this.beTr('Company', lang),
            project: this.beTr('Project', lang),
            from: this.beTr('From', lang),
            to: this.beTr('To', lang),
            duration: this.beTr('Duration', lang),
            route: this.beTr('Route', lang),
            purpose: this.beTr('Purpose', lang),
            subtotal: this.beTr('Subtotal', lang),
            total_all: this.beTr('Total all expenses', lang),
            kilometer_allowance: this.beTr('Kilometer allowance', lang),
            description: this.beTr('Description', lang),
            trip: this.beTr('Trip', lang),
            amount: this.beTr('Amount', lang),
            daily_allowance: this.beTr('Daily allowance', lang),
            days: this.beTr('Days', lang),
            other_expenses: this.beTr('Other expenses', lang),
            tax: this.beTr('Tax', lang),
            total: this.beTr('Total', lang),
            nav_id: this.beTr('Nav id', lang),
            customership_group_id: this.beTr('Customership group id', lang),
            pv: this.beTr('PV', lang),
        }}), {})
    };

    render() {
        return null;  
   }
};

export default TravelExpenseTranslations;