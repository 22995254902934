import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import TextInputCell from '../../list/cells/TextInputCell';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';

class SuperiorsMembers extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/components/SuperiorsMembers");

        this.state = {
            superiors: [],
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.refreshSuperiors();
    }

    refreshSuperiors = () => {
        const company = this.props.company;

        DataHandler.get({ url: `settings/company/${company}/superiors`, members: 1 }).then(superiors => {
            this.setState({ superiors });
        });
    }

    generateOptions = (row, all) => {
        const { users } = this.props;

        if (!row.users)
            row.users = [];

        const filteredUsers = users.filter(e => row.users.find(ee => ee === e.id) === undefined);

        return filteredUsers.map(el => {
            el.label = el.label ? el.label : (el.name || "");
            el.value = el.id;
            return el;
        });
    }

    setSuperior(user, superior) {
        DataHandler.post({url: `users/${user}/superiors`}, {superiors_id: superior}).done(this.refreshSuperiors);
    }

    removeFromSuperior(user, superior) {
        DataHandler.post({url: `users/${user}/superiors`}, {superiors_id: superior, delete: 1}).done(this.refreshSuperiors);
    }

    removeSuperior(superior) {
        const company = this.props.company;

        DataHandler.delete({url: `settings/company/${company}/superiors/${superior}`}).then( () => {
            this.refreshSuperiors();
        } );
    }

    setAsSuperior(oldSuperior, newSuperior) {
        const company = this.props.company;

        DataHandler.put({url: `settings/company/${company}/superiors/${oldSuperior}`}, {new_superiors_id: newSuperior}).then( () => {
            this.refreshSuperiors();
        } );
    }

    addNewSuperior = () => {
		const company = this.props.company;

        DataHandler.post({url: `settings/company/${company}/superiors/`}).then( () => {
            this.refreshSuperiors();
        } );
    }

    render() {
        const { users } = this.props;
        const { superiors } = this.state;
        const { tr } = this;

        const allUsers = superiors.map(a => a.users).flat(1);


        return <div id="teamgroup-settings">
            <h3>{tr("SuperiorsHEADER")}</h3>

            <Button className="green btn add" size="large" onClick={() => {this.addNewSuperior()}}>{tr("Add superior")}</Button>

            <div className="user-count">
                {superiors.length} {this.tr('superiors')}
            </div>

            <div id="teamgroup-wrapper">
                <div className="header">
                    <div className="column delete"></div>
                    <div className="column group">{tr("Superior")}</div>
                    <div className="column users">{tr("Subordinates")}</div>
                </div>
                <div className="content">
                    {superiors.map(row => (
                        <div className="row">
                            <div className="column delete">
                                {!row.dynamic && <DeleteIcon
                                    fontSize="small"
                                    onClick={() => this.removeSuperior(row.id)}
                                />}
                            </div>
                            <div className="column group">
                            	<AutoCompleteCell
                                    onEdited={(value) => {
                                        this.setAsSuperior(row.id, value.id);
                                    }}
                                    listCellProps={{
                                        permanentEditMode: false
                                    }}
                                    placeholderOnEmpty={true}
                                    value={{label: row.name, id: row.id}}
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder={this.tr("Select superior...")}
                                    autoCompleteData={this.generateOptions(row)}
                                    className=""
                                />
                            </div>
                            <div className="column users">
                                {row.users && row.users.map(u => {
                                    let found = users.find(elem => { return elem.id == u });
                                    if (found)
                                        return <Chip id={"g_" + found.id} key={"g_" + found.id} label={found.label} className="square" onDelete={() => this.removeFromSuperior(found.id, row.id)} />
                                })}

                                <Select
                                    onChange={(value) => {
                                        this.setSuperior(value.id, row.id);
                                    }}
                                    value={null}
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder={this.tr("Select...")}
                                    options={this.generateOptions(row)}
                                    className="drop"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    }
}

export default SuperiorsMembers;
