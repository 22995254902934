import React from 'react';
import TaimerComponent from "../../TaimerComponent";


class InvoiceTranslations extends TaimerComponent {

    constructor(props, context) {

        super(props, context, "general/backendTranslations/InvoiceTranslations");

    }

    returnTranslations = (langs) => {
        //this is a fallback if any language fails to manifest itself. At least zip fails then cuz we hardcode them langs:)
        if (langs[0] == undefined)
            langs = ['fi', 'se', 'en', 'ee'];
        return langs.reduce((acc, lang) => ({...acc, [lang]: {
            draft: this.beTr('Draft', lang),
            invoice: this.beTr('Invoice', lang),
            billed_to: this.beTr('Billed to', lang),
            invoice_no: this.beTr('Invoice no.', lang),
            delivery_date: this.beTr('Delivery date', lang),
            our_reference: this.beTr('Payment reference', lang),
            date: this.beTr('Date', lang),
            terms_of_payment: this.beTr('Terms of payment', lang),
            your_reference: this.beTr('Your reference', lang),
            due_date: this.beTr('Due date', lang),
            penalty_interest: this.beTr('Interest rate', lang),
            notice_period: this.beTr('Notice period', lang),
            description: this.beTr('Description', lang),
            qty: this.beTr('Qty', lang),
            price: this.beTr('Unit Price', lang),
            tot_vat: this.beTr('Tot. 0%', lang),
            vatpercentage: this.beTr('Vat %', lang),
            vat: this.beTr('Vat', lang),
            vat_excluded_total: this.beTr('Vat excluded total', lang),
            vat_total: this.beTr('Vat total', lang),
            total: this.beTr('Total', lang),
            reference_number: this.beTr('Reference number', lang),
            payment_request: this.beTr('Payment request', lang),
            credit_note: this.beTr('Credit note', lang),
            iban: this.beTr('IBAN', lang),
            swift: this.beTr('SWIFT/BIC', lang),
            days: this.beTr('days', lang),
            user: this.beTr('User', lang),
            worktype: this.beTr('Worktype', lang),
            task: this.beTr('Task', lang),
            hours: this.beTr('Hours', lang),
            account_total: this.beTr('Account Total', lang),
            project_total: this.beTr('Project Total', lang),
            professional_title: this.beTr('Professional Title', lang),
            average_price: this.beTr('Average Price', lang),
            summary: this.beTr('Summary', lang),
            invoicing_history: this.beTr('Invoicing History', lang),
            invoice_nr: this.beTr('Invoice Nr.', lang),
            invoice_date: this.beTr('Invoice Date', lang),
            total_no_vat: this.beTr('Total excl. VAT', lang),
            hours_report: this.beTr('Hours report', lang),
            entries_specified: this.beTr('Entries specified', lang),
            grand_total: this.beTr('Grand total', lang),
            agreement_identifier: this.beTr('Agreement_identifier', lang),
            order_identifier: this.beTr('Order_identifier', lang),
            reverse_charge: this.beTr('Reverse charge text', lang),
            payment_reminder: this.beTr('Payment reminder to invoice nr.', lang),
            refund_invoice: this.beTr('Refund invoice for bill no.', lang),
            /* for generateRows */
            workhours: this.beTr('Workhours', lang),
            purchase_expense: this.beTr('Purchase expence', lang),
            travel_expense: this.beTr('Travel expence', lang),
            daily_allowange: this.beTr('Daily allowance and mileage', lang),
            discount: this.beTr('This row has a discount of', lang),
            payment_reminder: this.beTr('Payment reminder to invoice nr.', lang),
            notes: this.beTr('Notes', lang),
            already_invoiced: this.beTr('Already invoiced', lang),
            including_vat: this.beTr('including VAT', lang),
            excluding_vat: this.beTr('excluding VAT', lang),
        }}), {})
    };

    render() {
        return null;  
   }
};

export default InvoiceTranslations;