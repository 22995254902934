import React from 'react';

/* css */
import './Expense.css';

/* materil-ui */
import {Button, Tab, Tabs, Switch, MenuItem } from '@mui/material';
import TrashCanIcon from "@mui/icons-material/Delete";
import withStyles from '@mui/styles/withStyles';
import MoreHoriz from "@mui/icons-material/MoreHoriz";


/* others */
import _ from "lodash";
import moment from 'moment';
// import List from "../../list/List";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from './../../general/DataHandler';
import TextInputCell from "../../list/cells/TextInputCell";
import UserAvatarCell from "../../list/cells/UserAvatarCell";
import DateCell from "../../list/cells/DateCell"
import TaimerComponent from "../../TaimerComponent";
import { withSnackbar } from 'notistack';
import CurrencyListCell from '../../list/CurrencyListCell';
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { SettingsContext } from '../../SettingsContext';
import OneColumnSettingList from "../../list/lists/OneColumnSettingList";
import { SettingsList as List } from "../../list/lists/SettingsList";
import { SettingsList } from "../../list/lists/SettingsList";
import { formatInputNumber } from '../../helpers';
import DataList from '../../general/DataList';
import { ReactComponent as CopyIcon } from '../../general/icons/copy.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import ContextMenu from '../../general/ContextMenu';
import { ReactComponent as InfoIcon } from './../../notifications/icons/info.svg';

import validator from 'validator';

import { format } from "date-fns";


const styles = theme => ({

});

const settings = [

];

const travelExpenseTabs = (props) => {
    const { active, archived } = props.selectedTabs; 
    return (
        <Tabs
            color="primary"
            value={active && archived ? 'all' : (active && !archived ? 'active' : 'archived')}
            className="header-buttons-tabs"
            onChange={(e, val) => props.onTabChange(val)} >
            <Tab
                value="all"
                label={props.labels.all}
                className={`header-buttons-tabs-tab ${active && archived ? 'selected' : ''}`} />            
            <Tab
                value="active"
                label={props.labels.active}
                className={`header-buttons-tabs-tab tab-left ${active && !archived ? 'selected' : ''}`} />
            <Tab
                value="archived"
                label={props.labels.archived}
                className={`header-buttons-tabs-tab tab-left ${archived && !active ? 'selected' : ''}`} />
            
        </Tabs>
    );
};

class rateCurrencyRows extends SettingRow {

    render() {
        const cells = {
            delete: <ListCell
                        className="noBg pointer"
                        permanentEditMode={true}
                        onClick={() => this.delete(this.state.data)}
                        width={this.props.columnWidthMap['delete']}>
                        <TrashCanIcon />
                    </ListCell>,
            name: <AutoCompleteCell
                        onEdited={value => this.editAndSave("name", value.name)}
                        value={{id: -1, name: this.state.data['name']}}
                        autoCompleteData={this.props.rowProps.currency_options}
                        width={this.props.columnWidthMap['name']}
                        menuIsOpen={this.state.data.id < 0}
                        listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true}
                    />,
            rate: <TextInputCell 
                        onEdited={value => this.editAndSave("rate", value)}
                        editable={this.state.data['id'] > 0}
                        name="name"
                        width={this.props.columnWidthMap['rate']}
                        value={this.state.data['rate']}
                    />, 
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class RestrictedPaymentTypeRow extends SettingRow {
    typeChanged(obj) {
        let newState = this.state.data; 
        newState.type = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {

        const options = [
            { "id" : "1", "name": this.props.rowProps.translations.ownMoney},
            { "id" : "2", "name": this.props.rowProps.translations.companyMoney}
        ];
        const type = options.find(t => t.id == this.state.data['type']);

        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} onClick={() => this.delete(this.state.data)} width={this.props.columnWidthMap['delete']}><TrashCanIcon /></ListCell>,
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            users: <UserAvatarCell
                width={this.props.columnWidthMap['users']}
                heightFix={70}
                users={this.state.data['users']}
                options={this.props.sharedData.users}
                editable={true}
                value={this.state.data['users']}
                showPlusWhenDisabled={true}
                editable={Number(this.state.data.id) > 0}
                onEdited={users => {
                    this.editAndSave("users", users);
                }} 
            />,
            type: <AutoCompleteCell
                listCellProps={{
                    className: "type"
                }}
                editable={true}
                autoCompleteData={options}
                name="type"
                width={this.props.columnWidthMap['type']}
                value={type}
                onEdited={(obj) => { this.editAndSave("type", obj.id) }}
            />
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}
class RegularPaymentTypeRow extends SettingRow {
    typeChanged(obj) {
        let newState = this.state.data; 
        newState.type = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const options = [
            { "id" : "1", "name": this.props.rowProps.translations.ownMoney},
            { "id" : "2", "name": this.props.rowProps.translations.companyMoney}
        ];
        const type = options.find(t => t.id == this.state.data['type']);

        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} onClick={() => this.delete(this.state.data)} width={this.props.columnWidthMap['delete']}><TrashCanIcon /></ListCell>,
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            type: <AutoCompleteCell
                listCellProps={{
                    className: "type"
                }}
                editable={true}
                autoCompleteData={options}
                name="type"
                width={this.props.columnWidthMap['type']}
                value={type}
                onEdited={(obj) => { this.editAndSave("type", obj.id) }}
            />,
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class ExpenseCustomTypeRow extends SettingRow {
    render() {
        const data = this.state.data;
    if(this.state.data["vat"]  == null){
        this.state.data["vat"] = "";
    }

        const cells = {
            delete: (
                <ListCell
                    onClick={() => this.delete()}
                    className="noBg pointer"
                    permanentEditMode={true}
                    width={this.props.columnWidthMap["delete"]}
                >
                    <TrashCanIcon />
                </ListCell>
            ),
            name: (
                <TextInputCell
                    onEdited={this.editAndSave}
                    name="name"
                    validation ={['empty']}
                    width={this.props.columnWidthMap["name"]}
                    value={this.state.data["name"]}
                    listCellProps={{
                        inEditMode:this.props.data.id < 0,
                    }}
                    focusOnMount={true}
                />
            ),
            vat: (
                <TextInputCell
                    validation={['percent100']}
                    onEdited={(name, value) => this.editAndSave(name, value.replace(",", "."))}
                    name="vat"
                    width={this.props.columnWidthMap["vat"]}
                    value={this.state.data["vat"] && formatInputNumber(this.state.data["vat"])}
                />
            ),
        };
        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}






class MileageAllowanceRow extends SettingRow {
    typeChanged(obj) {
        let newState = this.state.data; 
        newState.type = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const { integrationProducts } = this.props.rowProps;
        const type = this.props.rowProps.netvisorTypes.find(t => t.id == this.state.data['type']);
        const integration_product = integrationProducts && integrationProducts.find(p => p.id == this.props.data.integration_product);
        const cells = {
            context:   
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell edit row-menu"
                    width={this.props.columnWidthMap["context"]}
                    style={{
                        width: this.props.columnWidthMap["context"] + "px",
                        flex: this.props.columnWidthMap["context"] + " 1 0px",
                    }}
                    noExpandIcon >

                   <MenuItem className="edit" onClick={() => {this.props.rowProps.openCopyDialog(this.state.data)}}><CopyIcon />{this.props.rowProps.tr.copy}</MenuItem>
                   <MenuItem className="delete" onClick={() => this.delete(this.state.data)}><RemoveIcon className="Delete"/>{this.props.rowProps.tr.remove}</MenuItem>
                
            </ContextMenu>,            
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            startdate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="startdate"
                width={this.props.columnWidthMap['startdate']}
                value={this.state.data.startdate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,
            enddate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="enddate"
                width={this.props.columnWidthMap['enddate']}
                value={this.state.data.enddate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,            
            rate: <TextInputCell
                listCellType={CurrencyListCell}
                width={this.props.columnWidthMap['rate']}
                name="rate"
                value={this.state.data['rate']}
                onEdited={this.editAndSave}
            />,
            billing_price: <TextInputCell
                listCellType={CurrencyListCell}
                width={this.props.columnWidthMap['billing_price']}
                name="billing_price"
                value={this.state.data['billing_price']}
                onEdited={this.editAndSave}
            />,
            status:
                <ListCell className="" width={this.props.columnWidthMap['status']} onlyDisplay >
                    <Switch disabled color="primary" checked={this.state.data['status'] == 'active'} />
                </ListCell>,            
            edited: <ListCell 
                onlyDisplay={true} 
                editable={false}
                className="settings-allowances-logcell" 
                width={this.props.columnWidthMap['edited']} >
                    <h5 >{`${this.tr('Last edited')}: `} <span> {`${this.state.data['log_edited'] == '-' ? this.state.data['log_edited'] : moment(this.state.data['log_edited'])?.format(this.context.userObject.dateFormat)}` }</span></h5>
                    <h5 >{`${this.tr('By')}: `} <span > {`${this.state.data['user'] || ''}` }</span></h5>
            </ListCell>,
            type: <AutoCompleteCell
                listCellProps={{
                    className: "type"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.netvisorTypes}
                name="type"
                width={this.props.columnWidthMap['type']}
                value={type}
                onEdited={obj => this.typeChanged(obj)}
            />,
            integration_product: <AutoCompleteCell 
                listCellProps={{
                    className: "type"
                }}
                editable
                autoCompleteData={this.props.rowProps.integrationProducts}
                name="procountor_product"
                width={this.props.columnWidthMap['integration_product']}
                value={integration_product}
                onEdited={(obj) => { this.editAndSave("integration_product", obj.id) }}
            />
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DailyAllowanceRow extends SettingRow {
    typeChanged(obj) {
        let newState = this.state.data; 
        newState.type = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }
    
    render() {
        const options = [
            {id: 1, name: "Kotimaa"},
            {id: 2, name: "Ulkomaa"}
        ];
        const { integrationProducts } = this.props.rowProps;
        const type = options.find(t => t.id == this.state.data['type']);
        const integration_product = integrationProducts && integrationProducts.find(p => p.id == this.props.data.integration_product);

        const cells = {
            context:   
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell edit row-menu"
                    width={this.props.columnWidthMap["context"]}
                    style={{
                        width: this.props.columnWidthMap["context"] + "px",
                        flex: this.props.columnWidthMap["context"] + " 1 0px",
                    }}
                    noExpandIcon >

                   <MenuItem className="edit" onClick={() => {this.props.rowProps.openCopyDialog(this.state.data)}}><CopyIcon />{this.props.rowProps.tr.copy}</MenuItem>
                   <MenuItem className="delete" onClick={() => this.delete(this.state.data)}><RemoveIcon className="Delete"/>{this.props.rowProps.tr.remove}</MenuItem>
                
            </ContextMenu>,
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            startdate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="startdate"
                width={this.props.columnWidthMap['startdate']}
                value={this.state.data.startdate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,
            enddate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="enddate"
                width={this.props.columnWidthMap['enddate']}
                value={this.state.data.enddate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,
            rate: <TextInputCell
                listCellType={CurrencyListCell}                
                width={this.props.columnWidthMap['rate']}
                name="rate"
                value={this.state.data['rate']}
                onEdited={this.editAndSave}
            />,
            part_rate: <TextInputCell
                listCellType={CurrencyListCell}                
                width={this.props.columnWidthMap['part_rate']}
                name="part_rate"
                value={this.state.data['part_rate']}
                onEdited={this.editAndSave}
            />,
            status:
                <ListCell className="" width={this.props.columnWidthMap['status']} onlyDisplay >
                    <Switch disabled color="primary" checked={this.state.data['status'] == 'active'} />
                </ListCell>,
            edited: <ListCell 
                onlyDisplay={true} 
                editable={false}
                className="settings-allowances-logcell" 
                width={this.props.columnWidthMap['edited']} >
                    <h5 >{`${this.tr('Last edited')}: `} <span> {`${this.state.data['log_edited'] == '-' ? this.state.data['log_edited'] : moment(this.state.data['log_edited'])?.format(this.context.userObject.dateFormat)}` }</span></h5>
                    <h5 >{`${this.tr('By')}: `} <span > {`${this.state.data['user'] || ''}` }</span></h5>
            </ListCell>,
            type: <AutoCompleteCell
                listCellProps={{
                    className: "type"
                }}
                editable={true}
                autoCompleteData={options}
                name="type"
                width={this.props.columnWidthMap['type']}
                value={type}
                onEdited={obj => this.typeChanged(obj)}
            />,
            integration_product: <AutoCompleteCell 
                listCellProps={{
                    className: "type"
                }}
                editable
                autoCompleteData={this.props.rowProps.integrationProducts}
                name="procountor_product"
                width={this.props.columnWidthMap['integration_product']}
                value={integration_product}
                onEdited={(obj) => { this.editAndSave("integration_product", obj.id) }}
            />
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class AdditionalAllowanceRow extends SettingRow {
    typeChanged(obj) {
        let newState = this.state.data; 
        newState.type = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const { integrationProducts } = this.props.rowProps;
        const type = this.props.rowProps.netvisorTypes.find(t => t.id == this.state.data['type']);
        const integration_product = integrationProducts && integrationProducts.find(p => p.id == this.props.data.integration_product);

        const cells = {
            context:   
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell edit row-menu"
                    width={this.props.columnWidthMap["context"]}
                    style={{
                        width: this.props.columnWidthMap["context"] + "px",
                        flex: this.props.columnWidthMap["context"] + " 1 0px",
                    }}
                    noExpandIcon >

                   <MenuItem className="edit" onClick={() => {this.props.rowProps.openCopyDialog(this.state.data)}}><CopyIcon />{this.props.rowProps.tr.copy}</MenuItem>
                   <MenuItem className="delete" onClick={() => this.delete(this.state.data)}><RemoveIcon className="Delete"/>{this.props.rowProps.tr.remove}</MenuItem>
                
            </ContextMenu>,
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            startdate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="startdate"
                width={this.props.columnWidthMap['startdate']}
                value={this.state.data.startdate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,
            enddate: <DateCell
                editable={this.state.data['status'] == 'active'}
                name="enddate"
                width={this.props.columnWidthMap['enddate']}
                value={this.state.data.enddate}
                listCellProps={{
                    className: "editAlignLeft"
                }}
                onEdited={this.editAndSave}
            />,            
            rate: <TextInputCell
                listCellType={CurrencyListCell}    
                width={this.props.columnWidthMap['rate']}
                name="rate"
                value={this.state.data['rate']}
                onEdited={this.editAndSave}
            />,
            billing_price: <TextInputCell
                listCellType={CurrencyListCell}    
                width={this.props.columnWidthMap['billing_price']}
                name="billing_price"
                value={this.state.data['billing_price']}
                onEdited={this.editAndSave}
            />,
            status:
                <ListCell className="" width={this.props.columnWidthMap['status']} onlyDisplay >
                    <Switch disabled color="primary" checked={this.state.data['status'] == 'active'} />
                </ListCell>,
            edited: <ListCell 
                onlyDisplay={true} 
                editable={false}
                className="settings-allowances-logcell" 
                width={this.props.columnWidthMap['edited']} >
                    <h5 >{`${this.tr('Last edited')}: `} <span> {`${this.state.data['log_edited'] == '-' ? this.state.data['log_edited'] : moment(this.state.data['log_edited'])?.format(this.context.userObject.dateFormat)}` }</span></h5>
                    <h5 >{`${this.tr('By')}: `} <span > {`${this.state.data['user'] || ''}` }</span></h5>
            </ListCell>,
            type: <AutoCompleteCell
                listCellProps={{
                    className: "type"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.netvisorTypes}
                name="type"
                width={this.props.columnWidthMap['type']}
                value={type}
                onEdited={obj => this.typeChanged(obj)}
            />,
            integration_product: <AutoCompleteCell 
                listCellProps={{
                    className: "type"
                }}
                editable
                autoCompleteData={this.props.rowProps.integrationProducts}
                name="procountor_product"
                width={this.props.columnWidthMap['integration_product']}
                value={integration_product}
                onEdited={(obj) => { this.editAndSave("integration_product", obj.id) }}
            />
        };    

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Expense extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/Expense");

        this.regularPaymentTypeList    = React.createRef();
        this.restrictedPaymentTypeList = React.createRef();
        this.customTypeList            = React.createRef();
        this.mileageAllowanceList      = React.createRef();
        this.dailyAllowanceList        = React.createRef();
        this.additionalAllowanceList   = React.createRef();
        this.rateCurrencyList          = React.createRef();

        this.state = {
            currentDialog: false,
            dialogData: false,
            regularPaymentTypes: [],
            restrictedPaymentTypes: [],
            customTypes: [],
            autocompleteData: {},
            currency_data: [],
            accountingProducts: [],
            mileageHeaderVisible:false,
            dailyAllowanceHeaderVisible:false,
            additionalAllowanceHeaderVisible:false,
            userPaymentTypesHeaderVisible:false,
            initialized:false,
            allowanceTabValues: {
                mileageAllowances: {active:true, archived:false},
                dailyAllowances: {active:true, archived:false},
                additionalAllowances: {active:true, archived:false}
            },
            additionalDialogProps: {}
        };

        this.netvisorMileageTypes = [
            { "id" : "0", "name" : "Muu kuin auto" },
            { "id" : "1", "name" : "Auto" },
            { "id" : "2", "name" : "Auto peräkärryllä" },
            { "id" : "3", "name" : "Auto asuntovaunulla" },
            { "id" : "4", "name" : "Auto raskaalla kuormalla" },
            { "id" : "5", "name" : "Auto isolla koneella" },
            { "id" : "6", "name" : "Auto koiran kanssa" },
            { "id" : "7", "name" : "Auto karkeassa maastossa" },
            { "id" : "8", "name" : "Moottorivene, max 50Hv" },
            { "id" : "9", "name" : "Moottorivene, yli 50Hv" },
            { "id" : "10", "name" : "Moottorikelkka" },
            { "id" : "11", "name" : "Mönkijä" },
            { "id" : "12", "name" : "Moottoripyörä" },
            { "id" : "13", "name" : "Mopo" },
            { "id" : "14", "name" : "Muu" }
        ];

        this.netvisorAdditionalTypes = [
            { "id" : "0", "name": "Muu kuin Netvisor" },
            { "id" : "1", "name": "Netvisor lisämatkustaja" }
        ];

        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        this.mileageAllowanceColumns = [
            { width: 80, name: "context", header: "", ...dColConf },
            { width: 350, name: "name", header: this.tr("Name"), ...dColConf },
            { width: 100, name: "startdate", header: this.tr("Start date"), ...dColConf },
            { width: 100, name: "enddate", header: this.tr("End date"), ...dColConf },
            { width: 100, name: "rate", header: this.tr("Rate"), ...dColConf },
            { width: 100, name: "billing_price", header: this.tr("Billing price"), ...dColConf },
            { width: 60, name: "status", header: this.tr("Active"), ...dColConf },
            { width: 160, name: "edited", header: this.tr("Last edited"), ...dColConf },
        ]
        this.additionalAllowanceListColumns = [
            { width: 80, name: "context", header: "", ...dColConf },
            { width: 350, name: "name", header: this.tr("Name"), ...dColConf },
            { width: 100, name: "startdate", header: this.tr("Start date"), ...dColConf },
            { width: 100, name: "enddate", header: this.tr("End date"), ...dColConf },            
            { width: 100, name: "rate", header: this.tr("Rate"), ...dColConf },
            { width: 100, name: "billing_price", header: this.tr("Billing price"), ...dColConf },
            { width: 60, name: "status", header: this.tr("Active"), ...dColConf },
            { width: 160, name: "edited", header: this.tr("Last edited"), ...dColConf },
        ];
        this.dailyAllowanceListColumns = [
            { width: 80, name: "context", header: "", ...dColConf },
            { width: 350, name: "name", header: this.tr("Country"), ...dColConf },
            { width: 100, name: "startdate", header: this.tr("Start date"), ...dColConf },
            { width: 100, name: "enddate", header: this.tr("End date"), ...dColConf },            
            { width: 100, name: "rate", header: this.tr("Full day rate"), ...dColConf },
            { width: 100, name: "part_rate", header: this.tr("Part rate"), ...dColConf },
            { width: 60, name: "status", header: this.tr("Active"), ...dColConf },
            { width: 160, name: "edited", header: this.tr("Last edited"), ...dColConf },
        ];
        this.regularPaymentTypeColumns = [
            { width: 55, name: "delete", header: "", ...dColConf },
            { width: 350, name: "name", header: this.tr("Name"), ...dColConf },
        ];
        this.restrictedPaymentTypeColumns = [
            { width: 55, name: "delete", header: "", ...dColConf },
            { width: 350, name: "name", header: this.tr("Name"), ...dColConf },
            { width: 350, name: "users", header: this.tr("Users"), ...dColConf },
        ];

        if (this.context.addons && this.context.addons.netvisor) {
            const row = { width: 200, name: "type", header: this.tr("Netvisor type"), ...dColConf };
            this.mileageAllowanceColumns.push(row);
            this.additionalAllowanceListColumns.push(row);
            this.dailyAllowanceListColumns.push(row);

            const paymentTypeRow = { width: 200, name: "type", header: this.tr("Type"), ...dColConf };
            this.regularPaymentTypeColumns.push(paymentTypeRow);
            this.restrictedPaymentTypeColumns.push(paymentTypeRow);
        }

        if (this.context.addons && (this.context.addons.procountor)) {
            const header = this.tr("Procountor product");
            const IPRow = { width: 200, name: "integration_product", header: header, ...dColConf };
            this.mileageAllowanceColumns.push(IPRow);
            this.additionalAllowanceListColumns.push(IPRow);
            this.dailyAllowanceListColumns.push(IPRow);
        }

        this.dialogs = {
            confirmation: ConfirmationDialog
        }; 

        this.dialogTexts = {
            deleteCustomType: this.tr("Are you sure you want to delete the expense type? If you choose 'yes', the type becomes unusable for further expenses, but is still visible in reports."),
            copyAllowance: this.tr("Copy row and archive existing row.")
        }

        this.fetchRegularPaymentTypes    = this.fetchRegularPaymentTypes.bind(this);
        this.fetchRestrictedPaymentTypes = this.fetchRestrictedPaymentTypes.bind(this);
        this.fetchCustomTypes            = this.fetchCustomTypes.bind(this);
        this.fetchAllowances             = this.fetchAllowances.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    updateComponentData = () => {
        let currency_options, currency_data, regPT, resPT, cusPT, milAl, dayAl, addAl, accountingProducts;
        const { company } = this.props;
        const { addons: { procountor } } = this.context;
        return DataHandler.get({url: `settings/expenses/currencies/` + company + '/' + this.context.taimerAccount.currency}).then(data => {

            currency_data = data.currency_data;
            currency_options = data.currency_options

            return DataHandler.get({url: `expenses/regular_payment_types/` + company})
        })
        .then(data => {
            regPT = data.regular_payment_types;

            return DataHandler.get({url: `expenses/restricted_payment_types/` + company});
        })
        .then(data => {
            resPT = data.restricted_payment_types;

            return DataHandler.get({url: `expenses/custom_types/` + company});
        })
        .then(data => {
            cusPT = data.custom_types;

            return DataHandler.get({url: `expenses/rates/` + company, show_all: true});
        })
        .then(data => {
            milAl = data.mileage_rates;
            dayAl = data.daily_rates;
            addAl = data.additional_rates;
            
            if (procountor && procountor.used_by_companies.indexOf(company) > -1) {
                const currentTime = new Date();
                return DataHandler.get({url: `expenses/products/${company}/${currentTime.getFullYear()}`})
            } else {
                return data;
            }
        }).then(data => {
            if (procountor && procountor.used_by_companies.indexOf(company) > -1) {
                accountingProducts = data;
                return data;
            } else {
                return data;                
            }
        }).then(data => {
            return DataHandler.get({url: `subjects/employees/${company}`});
        }) 
        .then(users => {
            users = users.map(user => {
                user.value = user.id;
                return user;
            });
            this.setState({
                regularPaymentTypes: regPT,
                restrictedPaymentTypes: resPT,
                customTypes: cusPT,
                autocompleteData: {users},
                mileageAllowances: milAl,
                dailyAllowances: dayAl,
                additionalAllowances: addAl,
                accountingProducts,
                currency_data,
                currency_options,
                initialized:true
            });
        });
    }

    fetchExpensesRates = (origId = false) => {
        const { company } = this.props;
        if(origId) this.rateCurrencyList.current.removeRow(origId);
        DataHandler.get({url: `settings/expenses/currencies/` + company + '/' + this.context.taimerAccount.currency}).then(data => {
            const currency_data = data.currency_data;
            const currency_options = data.currency_options;
            this.setState({currency_data, currency_options});
        });
    }

    fetchRegularPaymentTypes(origId = false) {
        const { company } = this.props;
        
        DataHandler.get({url: `expenses/regular_payment_types/` + company}).done(data => {
            this.setState({ regularPaymentTypes: data.regular_payment_types });

            if(origId)
                this.regularPaymentTypeList.current.removeRow(origId);
        });
    }

    fetchRestrictedPaymentTypes(origId = false) {
        const { company } = this.props;
        
        DataHandler.get({url: `expenses/restricted_payment_types/` + company}).done(data => {
            this.setState({ restrictedPaymentTypes: data.restricted_payment_types });

            if(data.restricted_payment_types.length == 0){
                this.setState({userPaymentTypesHeaderVisible:false});
            }

            if(origId)
                this.restrictedPaymentTypeList.current.removeRow(origId);
        });
    }

    fetchCustomTypes(origId = false) {
        const { company } = this.props;
        
        DataHandler.get({url: `expenses/custom_types/` + company}).done(data => {
            this.setState({ customTypes: data.custom_types });

            if(origId)
                this.customTypeList.current.removeRow(origId);
        });
    }

    fetchAllowances(origId = false, type = false) {
        const { company } = this.props;

        DataHandler.get({url: `expenses/rates/`+company, show_all: true}).done(data => {
            this.setState({
                mileageAllowances: data.mileage_rates,
                dailyAllowances: data.daily_rates,
                additionalAllowances: data.additional_rates
            });
            if(data.mileage_rates.length == 0){
                
                this.setState({mileageHeaderVisible:false});
            }
             if(data.daily_rates.length == 0){
                this.setState({dailyAllowanceHeaderVisible:false});
            }
             if(data.additional_rates.length == 0){
                this.setState({additionalAllowanceHeaderVisible:false});
            }

            (origId&&type)&&(this[type+"AllowanceList"].current.removeRow(origId));
        });
    }


    close = () => {
        this.props.onClose();
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined, additionalDialogProps: {}});
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }
    _changeAllowanceVisibility = (type, tabValue) => {
        this.setState({allowanceTabValues: {
            ...this.state.allowanceTabValues, 
            [type]: tabValue == 'all' ? {active: true, archived: true} : {...{active: false, archived: false}, [tabValue]: true}
        }})
    }

    _copyAllowance = (allowanceType, data, toArchive) => {

        this.closeDialog();
        DataHandler.post({url: `expenses/allowance/${allowanceType}`}, {data: {...data, company_id: this.props.company, enddate: null}, toArchive: toArchive}).done(response => {
            this.fetchAllowances();
            this.setState({allowanceTabValues: {...this.state.allowanceTabValues, [`${allowanceType}Allowances`]: {active:true, archived: false}}});
        });
    }

    render() {
        const { currentDialog, dialogData,initialized, accountingProducts, additionalAllowanceHeaderVisible, userPaymentTypesHeaderVisible , dailyAllowanceHeaderVisible, mileageHeaderVisible } = this.state;
        const { company,  enqueueSnackbar  } = this.props;
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { versionId } = this.context;
        const { tr } = this;

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const allowanceTabsProps = (type) => ({
            onTabChange: (tabValue) => this._changeAllowanceVisibility(type, tabValue),
            selectedTabs: this.state.allowanceTabValues[type],
            labels: {all: this.tr('All'), active: this.tr('Active'), archived: this.tr('Archived')}
        });

        const filterAllowances = (dataClass) => {
            const allSelected = this.state.allowanceTabValues[dataClass]['archived'] && this.state.allowanceTabValues[dataClass]['active'];
            return this.state[dataClass].filter(d => allSelected || this.state.allowanceTabValues[dataClass][d.status]);
        }; 

        return (
            <div id="settings-feature-expenses">
                {!initialized && 
                    <div> 
                        <img style={loaderStyle} src={require("../img/loading.svg").default}/>
                    </div>
                }
                {initialized && 
                    <React.Fragment>
                        <div className="settings-feature-expenses-firstwrapper">
                            <h3>{tr("Payment Types for Expenses")}</h3>
                            <p className="subtitle">{tr("Manage all payment types for your expenses. All users will see these payment types. We have set default types as Own Money and Company Money")}</p>
                            <Button className="green" onMouseUp={() => this.regularPaymentTypeList.current.addNewRow()} size="large">{tr("Add payment type")}</Button>
                            <List
                                innerRef={this.regularPaymentTypeList}
                                id="regPaymentTypeList"
                                className="settingsList"
                                height="auto"
                                ignoreRowPropsChange={true}
                                rowHeight={SettingRow.rowHeight}
                                emptyNewDataOnUpdate={false}
                                newRow={{
                                    id: -1,
                                    name: "",
                                    type: 0
                                }}
                                listRowType={RegularPaymentTypeRow}
                                columns={this.regularPaymentTypeColumns}
                                rowProps={{
                                    translations: {
                                    	ownMoney: this.tr('Own money'),
                                    	companyMoney: this.tr('Company money'),
                                    },
                                    onCreate: data => {
                                        let error = false;
                                        this.state.regularPaymentTypes.filter(pt => {
                                            if(pt.id != data.id && pt.name.toLowerCase() === data.name.toLowerCase())
                                                error = true
                                        })
                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter payment type") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({url: `expenses/payment_type`}, { data: {...data, company: company} });
                                           
                                            // .done(response => {
                                                // this.fetchRegularPaymentTypes(data.id);
                                            // });
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.name + tr(" Payment type already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    onUpdate: data => DataHandler.post({url: `expenses/payment_type`}, { data: {...data, company: company} }),
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            this.regularPaymentTypeList.current.removeRow(data.id);
                                            return;
                                        }
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => DataHandler.delete({url: `expenses/payment_type`}, {id: data.id}).done(r => {
                                                    // this.fetchRegularPaymentTypes();
                                                    
                                                    list.removeRow(data.id);
                                                }),
                                                text: tr("Do you want to delete expense payment type") + ": " + data.name + "?" 
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    }
                                }}
                                data={this.state.regularPaymentTypes}
                            />
                        </div>
                        <div className="settings-feature-expenses-wrapper">
                            <h3>{tr("User Specific Payment Types")}</h3>
                            <p className="subtitle">{tr("Do you have different payment cards in your company? Here you can assign specific payment types for users, they will see the payment types assigned to them")}</p>
                            <Button className="green" onMouseUp={() =>{ this.restrictedPaymentTypeList.current.addNewRow(); this.setState({userPaymentTypesHeaderVisible:true})}} size="large">{tr("Add user payment type")}</Button>
                            <br />
                            <List
                                innerRef={this.restrictedPaymentTypeList}
                                id="resPaymentTypeList"
                                className="settingsList"
                                height="auto"
                                ignoreRowPropsChange={true}
                                rowHeight={SettingRow.rowHeight}
                                sharedData={this.state.autocompleteData}
                                emptyNewDataOnUpdate={false}
                                newRow={{
                                    id: -1,
                                    name: "",
                                    users: [],
                                    type: 0
                                }}
                                listRowType={RestrictedPaymentTypeRow}
                                columns={this.restrictedPaymentTypeColumns}
                                rowProps={{
                                    translations: {
                                    	ownMoney: this.tr('Own money'),
                                    	companyMoney: this.tr('Company money'),
                                    },
                                    onCreate: data => {
                                        data['restricted'] = 1;
                                        let error = false;
                                        this.state.restrictedPaymentTypes.filter(upt => {
                                            if(upt.id != data.id && upt.name.toLowerCase() === data.name.toLowerCase())
                                                error = true
                                        })
                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter user specific payment type") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({url: `expenses/payment_type`}, { data: {...data, company: company} });
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.name + tr(" User payment type already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    onUpdate: data => {
                                        data['restricted'] = 1;

                                        return DataHandler.post({url: `expenses/payment_type`}, { data: {...data, company: company} })
                                        .then(response => DataHandler.post({url: `expenses/restricted_payment_type`}, {data: {...data, company: company, id: response.id}}));
                                    },
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            this.restrictedPaymentTypeList.current.removeRow(data.id);
                                            if(this.state.restrictedPaymentTypes.length == 0){
                                                this.setState({userPaymentTypesHeaderVisible:false});
                                            }
                                            return;
                                        }
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => DataHandler.delete({url: `expenses/payment_type`}, {id: data.id}).done(r => {
                                                    list.removeRow(data.id);

                                                    // this.restrictedPaymentTypeList.current.removeRow(data.id);
                                                    // this.fetchRestrictedPaymentTypes();
                                                }),
                                                text: tr("Do you want to delete user specific expense payment type") + ": " + data.name + "?" 
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    }
                                }}
                                data={this.state.restrictedPaymentTypes} />
                        </div>
                        <div className="settings-feature-expenses-wrapper">
                            <h3>{tr("Custom Expenses types")}</h3>
                            <p className="subtitle">{tr("You can categorize your expenses by custom purposes, so the expenses can be allocated better")}</p>
                            <Button className="green" onMouseUp={() => this.customTypeList.current.addNewRow()} size="large">{tr("Add custom type")}</Button>
                            <List
                                innerRef={this.customTypeList}
                                id="customTypeList"
                                key={this.state.customTypes.length}
                                headerTitle={this.tr("Custom Type")}
                                className="settingsList"
                                height="auto"
                                rowHeight={SettingRow.rowHeight}
                                listRowType={ExpenseCustomTypeRow}
                                data={this.state.customTypes}
                                emptyNewDataOnUpdate={false}
                                
                                rowProps={{
                                    onCreate: data =>{
                                        let error = false;
                                        this.state.customTypes.filter(ct => {
                                            if(ct.id != data.id && ct.name.toLowerCase() === data.name.toLowerCase())
                                                error = true
                                        })
                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter custom expense payment type") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({url: `settings/add_custom_expense_type/${company}`}, { data: JSON.stringify(data) });

                                            // .done(response => {
                                            // this.fetchCustomTypes(data.id);
                                            // });
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.name + tr(" Custom expenses payment type already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    
                                    onUpdate: data => {
                                        if(!validator.isNumeric(data.vat) && !isNaN(data.vat) && data.vat != ""){
                                            this.props.enqueueSnackbar((tr("Enter valid vat data") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        else if(data.vat > 100){
                                            this.props.enqueueSnackbar((tr("The entered vat % is more then 100") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        else {
                                            DataHandler.post({url: `settings/update_custom_expense_type/${data.id}`}, { data: JSON.stringify({...data, company: company}) });
                                        }
                                       
                                    },
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            this.customTypeList.current.removeRow(data.id);
                                            return;
                                        }
                                        data.company = company
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => DataHandler.delete({url: `settings/delete_custom_expense_type/${id}`}, data).done(resp => {
                                                    list.removeRow(data.id);

                                                    // this.customTypeList.current.removeRow(data.id);
                                                }),
                                                text: this.dialogTexts['deleteCustomType']
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    }
                                }}
                                newRow={{
                                    id: -1,
                                    name:"",
                                    vat:null
                                }}
                                columns={[
                                    { width: 20, name: "delete", header: "", ...dColConf },
                                    { width: 300, name: "name", header: tr("Custom Type"), ...dColConf },
                                    { width: 100, name: "vat", header: tr("Vat % "), ...dColConf },
                                ]} />
                        </div>
                        <div className="settings-feature-expenses-wrapper">
                            <h3>{tr("Mileage allowances")}</h3>
                            {travelExpenseTabs(allowanceTabsProps('mileageAllowances'))}
                            <p className="subtitle"></p>
                            <div className="allowance-settings-buttons" >
                                <Button className="green" onMouseUp={() => {
                                    this.setState({ 
                                        allowanceTabValues: { 
                                            ...this.state.allowanceTabValues, 
                                            mileageAllowances: { active: true, archived: false }
                                        }, 
                                        mileageHeaderVisible: true }, () => {
                                            setTimeout(() => {
                                                this.mileageAllowanceList.current.addNewRow(); 
                                            }, 100);
                                    });
                                    }} size="large">{tr("Add mileage allowance")}</Button>
                            </div>                                                        
                            <br />
                            <List
                                innerRef={this.mileageAllowanceList}
                                id="mileageAllowanceList"
                                className="settingsList"
                                height="auto"
                                ignoreRowPropsChange={true}
                                rowHeight={SettingRow.rowHeight}
                                sharedData={this.state.autocompleteData}
                                emptyNewDataOnUpdate={false}
                                newRow={{
                                    id: -1,
                                    name: "",
                                    billing_price:"",
                                    rate:"",
                                    users: []
                                }}
                                listRowType={MileageAllowanceRow}
                                ignoreRowPropsChange={true}
                                columns={this.mileageAllowanceColumns}
                                rowProps={{
                                    netvisorTypes: this.netvisorMileageTypes,
                                    integrationProducts: accountingProducts,
                                    onCreate: data => {
                                        if(data.rate) data.rate = data.rate.replace(',', '.');
                                        if(data.billing_price) data.billing_price = data.billing_price.replace(',', '.');
                                        data.company_id = company;

                                        let error = false;
                                        this.state.mileageAllowances.filter(m => {
                                            if(m.status == 'active' && m.id != data.id && m.name.toLowerCase() === data.name.toLowerCase())
                                                error = true
                                        });
                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter mileage allowance") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({url: 'expenses/allowance/mileage'}, { data: data });
                                        }
                                        if(error){
                                            this.props.enqueueSnackbar(( data.name + tr(" Mileage already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    onUpdate: data => {
                                        if(data.rate) data.rate = data.rate.replace(',', '.');
                                        if(data.billing_price) data.billing_price = data.billing_price.replace(',', '.');
                                        data.company_id = company;
                                        DataHandler.put({url: 'expenses/allowance/mileage/' + data.id}, {data: data}).done(response => {
                                            this.fetchAllowances();
                                        });
                                    },
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            this.mileageAllowanceList.current.removeRow(data.id);

                                            if(this.state.mileageAllowances.length == 0){
                                                this.setState({mileageHeaderVisible:false});
                                            }

                                            return;
                                        }
                                        data.company_id = company;
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => DataHandler.delete({url: 'expenses/allowance/mileage/' + data.id}, {data: data}).done(response => {
                                                    list.removeRow(data.id);

                                                    // this.fetchAllowances();
                                                }),
                                                text: tr("Do you want to delete mileage allowance") + ": " + data.name + "?" 
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    },
                                    openCopyDialog: (data) => {
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                noText: this.tr("No, just copy"),
                                                yesText: this.tr("Yes, continue"),
                                                text: this.dialogTexts.copyAllowance,
                                                maxWidth: 'md',
                                                textIcon: InfoIcon,
                                                hasXCloseButton: true,
                                                noCancelButton: true
                                            },
                                            additionalDialogProps: {
                                                yesNo: true,
                                                onDialogYes: () => this._copyAllowance('mileage', data, true),
                                                onDialogNo: () => this._copyAllowance('mileage', data)
                                            }
                                        }, () => this.openDialog('confirmation'));
                                    },
                                    tr: {copy: this.tr("Copy"), remove: this.tr("Remove")}
                                }}
                                data={filterAllowances('mileageAllowances')} />
                        </div>
                        <div className="settings-feature-expenses-wrapper">
                            <h3>{tr("Daily allowances")}</h3>
                            {travelExpenseTabs(allowanceTabsProps('dailyAllowances'))}
                            <p className="subtitle"></p>
                            <div className="allowance-settings-buttons" >
                                <Button className="green" onMouseUp={() => {
                                    this.setState({ 
                                        allowanceTabValues: { 
                                            ...this.state.allowanceTabValues, 
                                            dailyAllowances: { active: true, archived: false }
                                        }, 
                                        dailyAllowanceHeaderVisible: true }, () => {
                                            setTimeout(() => {
                                                this.dailyAllowanceList.current.addNewRow(); 
                                            }, 100);
                                    });
                                    }} size="large">{tr("Add daily allowance")}</Button>
                            </div>
                            <br />
                            <List
                            innerRef={this.dailyAllowanceList}
                            id="dailyAllowanceList"
                            className="settingsList"
                            height="auto"
                            ignoreRowPropsChange={true}
                            rowHeight={SettingRow.rowHeight}
                            sharedData={this.state.autocompleteData}
                            emptyNewDataOnUpdate={false}
                            newRow={{
                                id: -1,
                                name: "",
                                part_rate:"",
                                rate:"",
                            // users: []
                            }}
                            listRowType={DailyAllowanceRow}
                            columns={this.dailyAllowanceListColumns}
                            rowProps={{
                                integrationProducts: accountingProducts,
                                onCreate: data => {
                                    if(data.rate) data.rate = data.rate.replace(',', '.');
                                    if(data.part_rate) data.part_rate = data.part_rate.replace(',', '.');
                                    data.company_id = company;

                                    let error = false;
                                    this.state.dailyAllowances.filter(da => {
                                        if(da.status == 'active' && da.id != data.id && da.name.toLowerCase() === data.name.toLowerCase())
                                            error = true
                                    })
                                    if(data.name == ""){
                                        this.props.enqueueSnackbar((tr("Enter daily allowance") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                    if (!error) {
                                        return DataHandler.post({url: 'expenses/allowance/daily'}, { data: data });

                                        // .done(response => {
                                        // this.fetchAllowances(data.id, "daily");
                                        // });
                                    }
                                    if(error) {
                                        this.props.enqueueSnackbar(( data.name + tr(" Daily allowances already exists") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                },
                                onUpdate: data => {
                                    if(data.rate) data.rate = data.rate.replace(',', '.');
                                    if(data.part_rate) data.part_rate = data.part_rate.replace(',', '.');
                                    data.company_id = company;
                                    DataHandler.put({url: 'expenses/allowance/daily/' + data.id}, {data: data}).done(response => {
                                        this.fetchAllowances();
                                    });
                                },
                                onDelete: (data, _, list) => {
                                    if (Number(data.id) < 0) {
                                        this.dailyAllowanceList.current.removeRow(data.id);
                                        if(this.state.dailyAllowances.length == 0){
                                            this.setState({dailyAllowanceHeaderVisible:false});
                                        }
                                        return;
                                    }
                                    data.company_id = company;
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            saveFunc: (id) => DataHandler.delete({url: 'expenses/allowance/daily/' + data.id}, {data: data}).done(response => {
                                                list.removeRow(data.id);
                                                // this.fetchAllowances();
                                            }),
                                            text: tr("Do you want to delete daily allowance") + ": " + data.name + "?" 
                                        }
                                    });
                                    this.openDialog('confirmation');
                                },
                                openCopyDialog: (data) => {
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            noText: this.tr("No, just copy"),
                                            yesText: this.tr("Yes, continue"),
                                            text: this.dialogTexts.copyAllowance,
                                            maxWidth: 'md',
                                            textIcon: InfoIcon,
                                            hasXCloseButton: true,
                                            noCancelButton: true
                                        },
                                        additionalDialogProps: {
                                            yesNo: true,
                                            onDialogYes: () => this._copyAllowance('daily', data, true),
                                            onDialogNo: () => this._copyAllowance('daily', data)
                                        }
                                    }, () => this.openDialog('confirmation'));
                                },
                                tr: {copy: this.tr("Copy"), remove: this.tr("Remove")}

                            }}
                            data={filterAllowances('dailyAllowances')} />
                        </div> 
                        <div className="settings-feature-expenses-wrapper">
                            <h3>{tr("Additional allowances")}</h3>
                            {travelExpenseTabs(allowanceTabsProps('additionalAllowances'))}
                            <p className="subtitle"></p>
                            <div className="allowance-settings-buttons" >
                                <Button className="green" onMouseUp={() => {
                                    this.setState({ 
                                        allowanceTabValues: { 
                                            ...this.state.allowanceTabValues, 
                                            additionalAllowances: { active: true, archived: false }
                                        }, 
                                        additionalAllowanceHeaderVisible: true }, () => {
                                            setTimeout(() => {
                                                this.additionalAllowanceList.current.addNewRow(); 
                                            }, 100);
                                    });
                                }} size="large">{tr("Add additional allowance")}</Button>
                            </div> 
                            <br />
                            <List
                            innerRef={this.additionalAllowanceList}
                            id="additionalAllowanceList"
                            className="settingsList"
                            height="auto"
                            ignoreRowPropsChange={true}
                            rowHeight={SettingRow.rowHeight}
                            sharedData={this.state.autocompleteData}
                            emptyNewDataOnUpdate={false}
                            newRow={{
                                id: -1,
                                name: "",
                                rate:"",
                                billing_price:""
                                //users: []
                                
                            }}
                            listRowType={AdditionalAllowanceRow}
                            columns={this.additionalAllowanceListColumns}
                            rowProps={{
                                netvisorTypes: this.netvisorAdditionalTypes,
                                integrationProducts: accountingProducts,
                                onCreate: data => {
                                    if(data.rate) data.rate = data.rate.replace(',', '.');
                                    if(data.billing_price) data.billing_price = data.billing_price.replace(',', '.');
                                    data.company_id = company;
                                    let error = false;
                                    this.state.additionalAllowances.filter(ad => {
                                        if(ad.status == 'active' && ad.id != data.id && ad.name.toLowerCase() === data.name.toLowerCase())
                                            error = true
                                    })
                                    if(data.name == ""){
                                        this.props.enqueueSnackbar((tr("Enter additional allowance") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                    if (!error) {
                                        return DataHandler.post({url: 'expenses/allowance/additional'}, { data: data });

                                        // .done(response => {
                                        // this.fetchAllowances(data.id, "additional");
                                        // });
                                    }
                                    if (error) {
                                        this.props.enqueueSnackbar(( data.name + tr(" Additional allowances already exists") + "."), {
                                            variant: "error",
                                        });
                                        return;

                                    }
                                },
                                onUpdate: data => {
                                    if(data.rate) data.rate = data.rate.replace(',', '.');
                                    if(data.billing_price) data.billing_price = data.billing_price.replace(',', '.');
                                    data.company_id = company;
                                    DataHandler.put({url: 'expenses/allowance/additional/' + data.id}, {data: data}).done(response => {
                                        this.fetchAllowances();
                                    });
                                },
                                onDelete: (data, _, list) => {
                                    if (Number(data.id) < 0) {
                                        this.additionalAllowanceList.current.removeRow(data.id);
                                        if(this.state.additionalAllowances.length == 0){
                                            this.setState({additionalAllowanceHeaderVisible:false});
                                        }
                                        return;
                                    }
                                    data.company_id = company;
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            saveFunc: (id) => DataHandler.delete({url: 'expenses/allowance/additional/' + data.id}, {data: data}).done(response => {
                                                list.removeRow(data.id);

                                                // this.fetchAllowances()
                                            }),
                                            text: tr("Do you want to delete additional allowance")+ ": " + data.name + "?" 
                                        }
                                    });
                                    this.openDialog('confirmation');
                                },
                                openCopyDialog: (data) => {
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            noText: this.tr("No, just copy"),
                                            yesText: this.tr("Yes, continue"),
                                            text: this.dialogTexts.copyAllowance,
                                            maxWidth: 'md',
                                            textIcon: InfoIcon,
                                            hasXCloseButton: true,
                                            noCancelButton: true
                                        },
                                        additionalDialogProps: {
                                            yesNo: true,
                                            onDialogYes: () => this._copyAllowance('additional', data, true),
                                            onDialogNo: () => this._copyAllowance('additional', data)
                                        }
                                    }, () => this.openDialog('confirmation'));
                                },
                                tr: {copy: this.tr("Copy"), remove: this.tr("Remove")}
                            }}
                            data={filterAllowances('additionalAllowances')} />
                        </div>
                        <div className="settings-feature-expenses-lastwrapper">
                            <h3>{tr("Currency rates for expenses")}</h3>
                            <p className="subtitle">{tr("Define currency rates for expenses")}</p>
                            <Button className="green" onMouseUp={() => {this.rateCurrencyList.current.addNewRow()}} size="large">{tr("Add currency rate")}</Button>
                            <List
                                innerRef={this.rateCurrencyList}
                                id="rateCurrencyList"
                                className="settingsList"
                                data={this.state.currency_data}
                                listRowType={rateCurrencyRows}
                                height="auto"
                                ignoreRowPropsChange={true}
                                emptyNewDataOnUpdate={false}
                                rowHeight={SettingRow.rowHeight}
                                columns={[
                                    { width: 55, name: "delete", header: "", ...dColConf },
                                    { width: 300, name: "name", header: tr("Currency"), ...dColConf },
                                    { width: 150, name: "rate", header: tr("Currency rate"), ...dColConf }
                                ]}
                                newRow={{
                                    id: -1,
                                    name: "",
                                    rate: "1,000000"
                                }}
                                rowProps={{
                                    enqueueSnackbar: enqueueSnackbar,
                                    currency_options: this.state.currency_options,
                                    removeRow: this.rateCurrencyList.removeRow,
                                    onCreate: data => {
                                        
                                        let name = data.name;
                                        let rate = data.rate;

                                        //If name is empty
                                        if(name === "") {
                                            enqueueSnackbar(this.tr("Currency can't be empty"), {
                                                variant: "error",
                                            });
                                            return;
                                        }

                                        //Replace commas with dots
                                        rate = rate.replace(',', '.');
                                        rate = parseFloat(rate);

                                        //If rates value is not a number
                                        if(isNaN(rate)) {
                                            enqueueSnackbar(this.tr("The currency rate can only be a number"), {
                                                variant: "error",
                                            });
                                            return;
                                        }

                                        //If rates value is smaller than 0.000001
                                        if(rate < 0.000001) {
                                            enqueueSnackbar(this.tr("The currency rate must be greater than or equal to 0.000001"), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        
                                        //Save new currency, shout it out in Snackbar and remove old -id value
                                        return DataHandler.post({url: `settings/expenses/rate/${company}`},{name: name, rate: rate});

                                            // .done(response => {
                                            // this.fetchExpensesRates(data.id);
                                            // enqueueSnackbar(this.tr('A new currency rate "') + name + this.tr('" has been created'), {
                                                // variant: "success",
                                            // });
                                        // });
                                    },
                                    onUpdate: inData => {


                                        const id = inData.id;
                                        const name = inData.name;
                                        let rate = inData.rate;

                                        //If name is empty
                                        if(name === "") {
                                            enqueueSnackbar(this.tr("Currency can't be empty"), {
                                                variant: "error",
                                            });
                                            return;
                                        }

                                        //Replace commas with dots
                                        rate = rate.replace(',', '.');
                                        rate = parseFloat(rate);

                                        //If rates value is not a number
                                        if(isNaN(rate)) {
                                            enqueueSnackbar(this.tr("The currency rate can only be a number"), {
                                                variant: "error",
                                            });
                                            return;
                                        }

                                        //If rates value is smaller than 0.000001
                                        if(rate < 0.000001) {
                                            enqueueSnackbar(this.tr("The currency rate must be greater than or equal to 0.000001"), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        
                                        //Update currency data 
                                        DataHandler.put({url: `settings/expenses/rate/${company}/${id}`},{name, rate}).done(response => {
                                            // this.fetchExpensesRates();
                                            enqueueSnackbar(this.tr('The currency rate "') + name + this.tr('" has been updated'), {
                                                variant: "success",
                                            });
                                        });
                                        
                                    },
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            this.rateCurrencyList.current.removeRow(data.id);
                                            if(this.state.currency_data.length == 0){
                                            }
                                            return;
                                        }
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => 
                                                    { 
                                                        if(id > 0){
                                                            DataHandler.delete({url: `settings/expenses/rate/${company}/${data.id}`}).done(response => {
                                                                // this.fetchExpensesRates(); 
                                                                list.removeRow(data.id);

                                                                enqueueSnackbar(this.tr('The currency rate "') + data.name + this.tr('" has been deleted'), {
                                                                    variant: "success",
                                                                });
                                                            });
                                                        } else {
                                                            this.rateCurrencyList.current.removeRow(id);
                                                        }
                                                    },
                                                text: tr("Do you want to delete currency rate ") + '"' + data.name +'"?'
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    }
                                }} />
                        </div>
                        
                        {Dialog && <Dialog
                            open
                            onDialogClose={this.closeDialog}
                            onDialogSave={this.confirmDialog}
                            data={dialogData}
                            {...this.state.additionalDialogProps} /> }  
                    </React.Fragment>  
                }                               
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(Expense));
