import { startOfDay } from "date-fns";
import { Employee } from "../../helpers";
import { Project, Resource, TaskStatus } from "../../resourcing";
import { ViewOptions } from "../../ResourcingView";

interface GanttFormatingOptions {
    unassignedLabel: string;
    employees: Dictionary<Employee>;
    viewOptions: ViewOptions;
}

interface GanttUser {
    id: number;
    name: string;
    color: string;
}

export interface ResourcingListRow {
    id: string;
    text: string;

    hours: false | number;
    hours_done: false | number;
    resourced: false | number;
    remaining: false | number;

    projectInfo?: Project;
    resource?: Resource;

    progress: false | number;

    customer_name: string;
    project_name: string;

    start_date: Date;
    end_date: Date;
    is_one_day_task: boolean;
    starttime: string|null;
    endtime: string|null;

    users: GanttUser[];

    status: TaskStatus;
    done: boolean;
    priorities_id: number;
    skills_id: number;

    type: string;

}

export function formatDataForList(options: GanttFormatingOptions, projects: Project[], resources: Resource[]): ResourcingListRow[] {
    const { employees, viewOptions } = options;

    const today = startOfDay(new Date());

    const rows: ResourcingListRow[] = [];

    for (const r of resources) {
        const users: GanttUser[] = [];

        if (!viewOptions.showOnGoing && r.type === 'task' && r.status === TaskStatus.Ongoing) {
            continue;
        }

        if (!viewOptions.showOverdue && r.type === 'task' && r.status === TaskStatus.Overdue) {
            continue;
        }

        if (!viewOptions.showDone && r.type === 'task' && r.status === TaskStatus.Done) {
            continue;
        }

        if (!viewOptions.showMilestones && r.type === 'milestone') {
            continue;
        }

        if (r.type === 'milestone') {
            const user = employees[r.users_id];
            if (user) {
                users.push({
                    id: user.id,
                    name: user.name,
                    color: user.color || '',
                });
            } else {
                console.error('missing user', r);
            }
        } else if (r.type === 'task') {
            for (const uh of r.users_hours) {
                const user = employees[uh.users_id];
        
                if (!user) {
                    console.error('missing user', uh);
                    continue;
                }
        
                users.push({
                    id: user.id,
                    name: user.name,
                    color: user.color || '',
                });
            }
        }

        const row: ResourcingListRow = {
            id: r.id,
            text: r.description,
            hours: r.allTotal?.allocated ?? false,
            hours_done: r.allTotal?.tracked ?? false,
            resourced: r.allTotal?.resourced ?? false,
            remaining: r.allTotal?.remaining ?? false,
            progress: r.type === 'task' ? r.progress : 0,
            resource: r,
            customer_name: r.customer_name,
            project_name: r.project_name,
            users,
            done: r.done,
            status: r.status,
            start_date: r.start_date,
            end_date: r.end_date,
            is_one_day_task: r.type === 'task' ? r.is_one_day_task : false,
            starttime: r.type === 'task' ? r.starttime : null,
            endtime: r.type === 'task' ? r.endtime : null,
            priorities_id: r.priorities_id,
            skills_id: r.skills_id,
            type: r.type,
        }

        rows.push(row);
    }

    return rows;
}