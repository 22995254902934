import React from 'react';
import InfoSelect from '../../../list/cells/InfoSelect';
import DataHandler from '../../../general/DataHandler';
import OutlinedField from '../../../general/OutlinedField';
import TaimerComponent from '../../../TaimerComponent';
import { SettingsContext } from '../../../SettingsContext';
import { returnCurrencyList } from '../../../general/Currencies';

export default class InvoiceRowProduct extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
		products: {},
		item: {},
	}

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRowProduct");

		this.currencySymbol = returnCurrencyList().find(rc => rc.id == this.context.taimerAccount.currency).symbol;

		this.state = {
			valid: false,
            validation: this.validate(this.props.item),
            products: []
		};
    }
    
    componentDidMount = () => {
        this.getProductsData();
    }

    getProductsData = () => {

        DataHandler.get({ url: `products/array/${this.props.company}`, include_deleted: 0, account: this.props.invoice.customerId || this.props.invoice.customer.id || "0"})
			.done(response => {
                if(response.products) {
                    this.setState({ products: response.products})
                }
			})
			.fail(err => {
				console.log(err);
			});
    }

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	onChangeProduct = (product) => {

        var item = {
            ...this.props.item,
            product: product,
            product_register_id: product.id,
            unit: product.unit,
            name: product.name,
            vat: product.vat,
            unit_price: parseFloat(product.income_price).toFixed(2),
        };

        const validation = this.validate(item, product);
        item.valid = validation.valid;
        this.setState({ validation });

        this.props.onChange(item.invoiceRowIndex, item, "product");
	}

	validate = (row, product) => {
		var result = {
			valid: true,
		};

		if (!row.product_register_id) {
			result.valid = false;
			result.name = true;
		}

		if (isNaN(row.amount) || row.amount <= 0) {
			result.valid = false;
			result.amount = true;
		}

		if (isNaN(row.unit_price) || row.unit_price < 0) {
			result.valid = false;
			result.unit_price = true;
		}

		return result;
	}

	onChange = (e) => {
		let value = e.target.value;

		if (e.target.name == 'amount' || e.target.name == 'unit_price') {
			value = e.target.value.replace(',', '.');
		}

		var item = {
			...this.props.item,
			[e.target.name]: value,
		};

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}

	render() {
		const { tr } = this;
        const { item, showValidationResult, disabled: propDisabled } = this.props;
        const { products } = this.state;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment key={item.invoiceRowIndex}>
            <InfoSelect
                height={230}
                tableWidth={550}
                headerHeight={35}
                rowHeight={49}
                options={products}
                placeholder={this.tr("Product")}
                editable={true}
				disabled={disabled}
                className={"is_workhour_product"}
                columns={[
                    { name: "code", header: this.tr("Code"), width: 75 },
                    { name: "name", header: this.tr("Name"), width: 200 },
                    { name: "path", header: this.tr("Category"), width: 200 },
                    { name: "unit", header: this.tr("Unit"), width: 75 },
                ]}
                value={item.name}
                onChange={this.onChangeProduct}
            />
			<OutlinedField name="amount"
				className="amount-field"
				InputLabelProps={{ shrink: true }}
				label={this.tr("Amount")}
				value={item.amount}
				onChange={this.onChange}
				adornment={!item.unit || item.unit == 0 ? '' : item.unit}
				adornmentPos="end"
				fullWidth
				error={validation.amount}
				disabled={disabled}
			/>

			<OutlinedField name="unit_price"
				label={`${this.currencySymbol}/${this.tr("unit")}`}
				value={item.unit_price}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				error={validation.unit_price}
				disabled={disabled}
			/>

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				InputProps={{
					readOnly: true,
				}}
				disabled={disabled}
				error={validation.total} />

			<div className="invoiceRowDelete">{!disabled && <button onClick={this.delete}>X</button>}</div>
		</React.Fragment>)
	}
}