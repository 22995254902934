import React, { useState } from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Close, KeyboardArrowDown, KeyboardArrowUp, Print } from '@mui/icons-material';
import { Drawer, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import DataHandler from '../general/DataHandler';
import TaimerComponent from '../TaimerComponent';
import PageTopSection from '../general/PageTopSection';
import styles from './HoursReportSlider.module.css';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { createExport } from '../dashboard/insights/InsightExport';
import _ from 'lodash';

const CloseableSection = (props) => {
    const { title, children, datasets, dateColumns = [], printMode, dateFormat, childOpen, onSetOpen, showFooter, dateColumnKey, showTotal, totalTitle } = props;
    const [open, setOpen] = useState(false);
    let content = children;
    const formatNumberValue = (value) => {
        if (!Number.isFinite(Number(value)) || value == '') return value;
        return Math.round(Number(value) * 100) / 100;
    };
    const renderTable = (columns, data, index) => {
        return (
            <div key={index}>
                <Table>
                    <TableRow className={styles.tableHead}>
                        {columns.map((c, i) => {
                            return (
                                <TableCell className={c.className} align="left">
                                    {c.title}
                                </TableCell>
                            );
                        })}
                        {dateColumns.map((d, i) => {
                            return (
                                <TableCell className={`${styles.dateCell} ${i == dateColumns.length - 1 ? styles.last : ''}`} align="center">
                                    {d}
                                </TableCell>
                            );
                        })}
                        {showTotal && <TableCell align="right">{totalTitle}</TableCell>}
                    </TableRow>
                    <TableBody>
                        {Object.values(data).map((row) => {
                            return (
                                <TableRow>
                                    {columns.map((c) => {
                                        let value = c.getValue ? c.getValue(row) : c.key == 'date' ? moment(row[c.key], 'YYYY-MM-DD').format(dateFormat || 'DD.MM.YYYY') : row[c.key];
                                        if (c.key == 'percentage') {
                                            const rowTotal = Number(row[c.totalKey || 'total'] || 0);
                                            const total = Object.values(data).reduce((prev, row) => {
                                                let newValue = 0;
                                                if (Number.isFinite(Number(row[c.totalKey || 'total']))) {
                                                    newValue = Number(row[c.totalKey || 'total']);
                                                }
                                                const val = (prev += newValue);
                                                return val;
                                            }, 0);
                                            value = total != 0 ? Math.round((rowTotal / total) * 100) : 0;
                                        }
                                        return <TableCell align="left">{c.formatValue ? c.formatValue(formatNumberValue(value)) : formatNumberValue(value)}</TableCell>;
                                    })}
                                    {row[dateColumnKey] &&
                                        dateColumns.map((m, i) => {
                                            return (
                                                <TableCell className={`${styles.dateCell} ${i == dateColumns.length - 1 ? styles.last : ''}`} align="center">
                                                    {formatNumberValue(row[dateColumnKey][i])}
                                                </TableCell>
                                            );
                                        })}
                                    {showTotal && <TableCell align="right">{formatNumberValue(row.total)}</TableCell>}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    {showFooter && (
                        <TableRow className={styles.tableFooter}>
                            {columns.map((c, i) => {
                                let value = c.key == 'percentage' ? '100 %' : i == 0 ? totalTitle : '';
                                if (value == '') {
                                    value = Object.values(data).reduce((prev, row) => {
                                        let newValue = 0;
                                        const rowVal = c.getValue ? c.getValue(row) : row[c.key];
                                        if (Number.isFinite(Number(rowVal))) {
                                            newValue = Number(rowVal);
                                        }
                                        const val = (prev += newValue);
                                        return val;
                                    }, 0);
                                }
                                return <TableCell align="left">{c.noFooter ? '' : c.formatTotal ? c.formatTotal(formatNumberValue(value)) : formatNumberValue(value)}</TableCell>;
                            })}
                        </TableRow>
                    )}
                </Table>
            </div>
        );
    };
    if (!!datasets) {
        content = (
            <div className={styles.sectionContent}>
                {datasets.map(({ columns, data }, i) => {
                    return renderTable(columns, data, i);
                })}
            </div>
        );
    }
    return (
        <div className={`${styles.section} ${open ? styles.open : ''} ${!open || childOpen === false ? styles.hiddenInPrint : ''}`}>
            <div
                onClick={() => {
                    onSetOpen && onSetOpen(!open);
                    setOpen(!open);
                }}
                className={`${styles.sectionTop} ${!datasets ? styles.hasChildren : ''}`}
            >
                <h2>{title}</h2>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {open && content}
        </div>
    );
};

class HoursReportSlider extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/HoursReportSlider');

        this._reportContent = React.createRef();

        this.yearlyMonthlyColumns = [
            {
                title: this.tr('User'),
                className: styles.nameCell,
                getValue: (row) => {
                    return (this.state.data.users || []).find((u) => u.id == row.users_id)?.name;
                },
            },
        ];

        this.entryColumns = [
            {
                title: this.tr('User'),
                className: styles.nameCell,
                getValue: (row) => {
                    return (this.state.data.users || []).find((u) => u.id == row.user)?.name;
                },
            },
            {
                title: this.tr('Date'),
                key: 'date',
                className: styles.fullDateCell,
            },
            {
                title: this.tr('Hours'),
                key: 'hours',
                className: styles.hourCell,
            },
            {
                title: this.tr('Jobtype'),
                key: 'worktask',
                className: styles.nameCell,
            },
            {
                title: this.tr('Description'),
                key: 'description',
            },
        ];

        this.userColums = [
            {
                title: this.tr('Per Person'),
                key: 'name',
            },
            {
                title: this.tr('Hours'),
                className: styles.hourCell,
                key: 'total',
            },
            {
                title: '%',
                className: styles.percentageCell,
                key: 'percentage',
            },
        ];

        this.jobtypeColums = [
            {
                title: this.tr('Per Jobtype'),
                key: 'name',
            },
            {
                title: this.tr('Hours'),
                className: styles.hourCell,
                key: 'total',
            },
            {
                title: '%',
                className: styles.percentageCell,
                key: 'percentage',
            },
        ];

        this.taskColums = [
            {
                title: this.tr('User'),
                getValue: (row) => {
                    return (this.state.data.users || []).find((u) => u.id == row.users_id)?.name;
                },
            },
            {
                title: this.tr('Jobtype'),
                className: styles.nameCell,
                key: 'worktask',
                noFooter: true,
            },
            {
                title: this.tr('Task'),
                className: styles.nameCell,
                key: 'name',
                noFooter: true,
            },
            {
                title: this.tr('Hours'),
                className: styles.hourCell,
                key: 'total',
            },
            {
                title: this.tr('Resourced'),
                className: styles.hourCell,
                key: 'resourced',
            },
            {
                title: this.tr('Cost'),
                className: styles.costCell,
                getValue: (row) => {
                    const totalHours = Number(row.total || 0);
                    let total = 0;
                    if (totalHours != 0) {
                        total = Number(row.cost || 0) / totalHours;
                    }
                    return total;
                },
                formatValue: (value) => {
                    const {
                        functions: { presentCurrency },
                    } = this.context;
                    return presentCurrency(value);
                },
                noFooter: true,
            },
            {
                title: this.tr('Total'),
                className: styles.costCell,
                key: 'cost',
                formatValue: (value) => {
                    const {
                        functions: { presentCurrency },
                    } = this.context;
                    return presentCurrency(value);
                },
                formatTotal: (value) => {
                    const {
                        functions: { presentCurrency },
                    } = this.context;
                    return presentCurrency(value);
                },
            },
        ];

        this.months = [
            this.tr('Jan'),
            this.tr('Feb'),
            this.tr('Mar'),
            this.tr('Apr'),
            this.tr('May'),
            this.tr('Jun'),
            this.tr('Jul'),
            this.tr('Aug'),
            this.tr('Sep'),
            this.tr('Oct'),
            this.tr('Nov'),
            this.tr('Dec'),
        ];

        this.state = {
            data: {},
            openSections: {},
            loading: false,
        };
    }

    _getData = () => {
        const { project } = this.props;
        if (!project) return;
        this.setState({ loading: true }, async () => {
            try {
                const data = await DataHandler.autoRetryGet({ url: `projects/${project.id}/hours`, freelancerTranslation: this.tr('Freelancer') });
                this.setState({ data, loading: false });

            } catch (err) {
                this.setState({ data: {}, loading: false });
                console.error(err);
            }
        });
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.open != this.props.open) {
            if (this.props.open) {
                this.setState({ data: {}, openSections: {} }, () => {
                    this._getData();
                });
            }
        }
    };

    _renderYearlySection = () => {
        const { data, openSections, generatingPDF } = this.state;
        if (!data.years || data.years.length == 0) return null;
        return (
            <CloseableSection onSetOpen={(open) => this.setParentSectionOpen('yearly', open)} childOpen={Object.values(openSections['yearly'] || {}).indexOf(true) != -1} title={this.tr('Yearly')}>
                {data.years.map((yearData, i) => {
                    const datasets = [
                        {
                            data: yearData.users,
                            columns: this.yearlyMonthlyColumns,
                        },
                    ];
                    return (
                        <CloseableSection
                            printMode={generatingPDF}
                            onSetOpen={(open) => this.setSectionOpen('yearly', i, open)}
                            users={data.users}
                            totalTitle={this.tr('Total')}
                            showTotal
                            title={yearData.year}
                            datasets={datasets}
                            dateColumns={this.months}
                            dateColumnKey="months"
                        />
                    );
                })}
            </CloseableSection>
        );
    };

    setSectionOpen = (key, index, open) => {
        const openSections = {
            ...this.state.openSections,
            [key]: {
                ...this.state.openSections[key],
                [index]: open,
            },
        };
        this.setState({
            openSections,
        });
    };

    setParentSectionOpen = (key, open) => {
        if (!!open) return; // only setting subitems to false when closing
        const openSections = {
            ...this.state.openSections,
        };
        Object.keys(openSections[key] || {}).forEach((subkey) => {
            openSections[key][subkey] = false;
        });
        this.setState({
            openSections,
        });
    };

    _renderMonthlySection = () => {
        const { data, openSections, generatingPDF } = this.state;
        if (!data.months || data.months.length == 0) return null;

        return (
            <CloseableSection onSetOpen={(open) => this.setParentSectionOpen('monthly', open)} childOpen={Object.values(openSections['monthly'] || {}).indexOf(true) != -1} title={this.tr('Monthly')}>
                {data.months.map((monthData, i) => {
                    const dates = [];
                    let date = moment(monthData.start, 'YYYY-MM-DD');
                    const endDate = moment(monthData.end, 'YYYY-MM-DD');
                    while (date.isSameOrBefore(endDate, 'date')) {
                        dates.push(date.date());
                        date.add(1, 'day');
                    }
                    const datasets = [
                        {
                            data: monthData.users,
                            columns: this.yearlyMonthlyColumns,
                        },
                    ];
                    return (
                        <CloseableSection
                            printMode={generatingPDF}
                            onSetOpen={(open) => this.setSectionOpen('monthly', i, open)}
                            users={data.users}
                            totalTitle={this.tr('Total')}
                            showTotal
                            title={`${this.months[endDate.month()]} ${endDate.format('YYYY')}`}
                            datasets={datasets}
                            dateColumns={dates}
                            dateColumnKey="days"
                        />
                    );
                })}
            </CloseableSection>
        );
    };

    _renderEntrySection = () => {
        const { data, openSections, generatingPDF } = this.state;
        if (!data.entries || Object.keys(data.entries).length == 0) return null;

        return (
            <CloseableSection
                onSetOpen={(open) => this.setParentSectionOpen('entry', open)}
                childOpen={Object.values(openSections['entry'] || {}).indexOf(true) != -1}
                title={this.tr('Breakdown of Time Entries')}
            >
                {Object.keys(data.entries).map((month, i) => {
                    const date = moment(month, 'YYYY-MM');
                    const entryData = data.entries[month];
                    const datasets = [
                        {
                            data: entryData,
                            columns: this.entryColumns,
                        },
                    ];
                    return (
                        <CloseableSection
                            dateFormat={this.context.userObject.dateFormat}
                            printMode={generatingPDF}
                            onSetOpen={(open) => this.setSectionOpen('entry', i, open)}
                            users={data.users}
                            title={`${this.months[date.month()]} ${date.format('YYYY')}`}
                            datasets={datasets}
                            dateColumnKey="days"
                        />
                    );
                })}
            </CloseableSection>
        );
    };

    _renderOverviewSection = () => {
        const { data, generatingPDF } = this.state;
        if (!data.users || !data.worktasks || ((data.users || []).length == 0 && (data.worktasks || []).length == 0)) return null;

        const datasets = [
            {
                data: data.users,
                columns: this.userColums,
            },
            {
                data: data.worktasks,
                columns: this.jobtypeColums,
            },
        ];

        return (
            <CloseableSection
                onSetOpen={(open) => this.setSectionOpen('overview', 0, open)}
                printMode={generatingPDF}
                totalTitle={this.tr('Total')}
                showFooter={true}
                title={this.tr('Together')}
                datasets={datasets}
            />
        );
    };

    _renderTasksSection = () => {
        const { data, generatingPDF } = this.state;
        if (!data.resources || data.resources.length == 0) return null;

        const datasets = [
            {
                data: data.resources,
                columns: this.taskColums,
            },
        ];

        return (
            <CloseableSection
                onSetOpen={(open) => this.setSectionOpen('task', 0, open)}
                printMode={generatingPDF}
                totalTitle={this.tr('Total')}
                showFooter={true}
                title={this.tr('By Task')}
                datasets={datasets}
            />
        );
    };

    _export = () => {
        const { data } = this.state;
        const { userObject: { dateFormat } } = this.context;

        this.setState({ generatingXLSX: true }, () => {
            const users = _.keyBy(data.users, 'id');

            const yearEntries = [];

            for (const year of data.years) {
                for (const row of _.values(year.users)) {
                    const user = users[row.users_id];

                    const months = {};

                    _.each(row.months, (v, k) => months[`month_${k}`] = v);

                    yearEntries.push({
                        ...row,
                        year: year.year,
                        user: user?.name,
                        ...months,
                    });
                }
            }

            const dayEntries = [];
            for (const month of data.months) {

                const date = moment(month.start, 'YYYY-MM-DD');

                const period = {
                    year: date.year(),
                    month: this.months[date.month()],
                }

                for (const row of _.values(month.users)) {
                    const user = users[row.users_id];

                    const days = {};

                    _.each(row.days, (v, k) => days[`day_${k}`] = v);

                    dayEntries.push({
                        ...row,
                        ...period,
                        user: user?.name,
                        ...days,
                    });
                }
            }


            const perTask = [];

            for (const row of data.resources) {
                const user = users[row.users_id];

                perTask.push({
                    ...row,
                    user: user?.name,
                });
            }

            const entryData = [];
            for (const row of _.flatten(_.values(data.entries))) {
                const user = users[row.user];

                entryData.push({
                    ...row,
                    user: user?.name,
                })
            }

            const totalUsers = _.sumBy(data.users, 'total');
            const totalTasks = _.sumBy(data.worktasks, 'total');

            createExport({
                name: `project_hours_report_${this.props.project?.name.replace(' ', '_').toLowerCase()}_${moment().format('YYYY-MM-DD')}`,
            }, [
                {
                    title: this.tr('Yearly'),
                    data: yearEntries,
                    columns: [
                        {
                            title: this.tr('User'),
                            column: 'user',
                        },
                        {
                            title: this.tr('Year'),
                            column: 'year',
                            type: 'number',
                        },
                        ...(_.range(0, 12).map(x => ({
                            title: this.months[x],
                            column: `month_${x}`,
                            type: 'number',
                            decimals: 2,
                        }))),
                        {
                            title: this.tr('Total'),
                            column: `total`,
                            type: 'number',
                            decimals: 2,
                        }
                    ],
                },
                {
                    title: this.tr('Monthly'),
                    data: dayEntries,
                    columns: [
                        {
                            title: this.tr('User'),
                            column: 'user',
                        },
                        {
                            title: this.tr('Year'),
                            column: 'year',
                            type: 'number',
                        },
                        {
                            title: this.tr('Month'),
                            column: 'month',
                        },
                        ...(_.range(0, 31).map(x => ({
                            title: x + 1,
                            column: `day_${x}`,
                            type: 'number',
                            decimals: 2,
                        }))),
                        {
                            title: this.tr('Total'),
                            column: `total`,
                            type: 'number',
                            decimals: 2,
                        }
                    ],
                },
                {
                    title: this.tr('Breakdown of Time Entries'),
                    data: entryData,
                    columns: [
                        {
                            title: this.tr('User'),
                            column: 'user',
                        },
                        {
                            title: this.tr('Date'),
                            column: 'date',
                            type: 'date',
                            format: 'YYYY-MM-DD',
                            displayFormat: dateFormat,
                        },
                        {
                            title: this.tr('Hours'),
                            column: 'hours',
                            type: 'number',
                            decimals: 2,
                        },
                        {
                            title: this.tr('Worktask'),
                            column: 'worktask',
                        },
                        {
                            title: this.tr('Description'),
                            column: 'description',
                        },
                    ],
                },
                {
                    title: this.tr('Per Person'),
                    data: data.users,
                    columns: [
                        {
                            title: this.tr('User'),
                            column: 'name',
                        },
                        {
                            title: this.tr('Hours'),
                            column: 'total',
                            type: 'number',
                            decimals: 2,
                        },
                        {
                            title: this.tr('% Share'),
                            value: (r) => r.total / totalUsers,
                            type: 'percent',
                            // decimals: 2,
                        },
                    ],
                },
                {
                    title: this.tr('Per Jobtype'),
                    data: data.worktasks,
                    columns: [
                        {
                            title: this.tr('Jobtype'),
                            column: 'name',
                        },
                        {
                            title: this.tr('Hours'),
                            column: 'total',
                            type: 'number',
                            decimals: 2,
                        },
                        {
                            title: this.tr('% Share'),
                            value: (r) => r.total / totalTasks,
                            type: 'percent',
                            // decimals: 2,
                        },
                    ],
                },
                {
                    title: this.tr('By Task'),
                    data: perTask,
                    columns: [
                        {
                            title: this.tr('User'),
                            column: 'user',
                        },
                        {
                            title: this.tr('Jobtype'),
                            column: 'worktask',
                        },
                        {
                            title: this.tr('Task'),
                            column: 'name',
                        },
                        {
                            title: this.tr('Hours'),
                            column: 'total',
                            type: 'number',
                            decimals: 2,
                        },
                        {
                            title: this.tr('Resourced'),
                            column: 'resourced',
                            type: 'number',
                            decimals: 2,
                        },
                        {
                            title: this.tr('Cost'),
                            value: (e) => e.cost / (e.total || 1), 
                            type: 'currency',
                            currency: data.currency,
                            decimals: 2,
                        },
                        {
                            title: this.tr('Total'),
                            type: 'currency',
                            column: 'cost',
                            currency: data.currency,
                            decimals: 2,
                        },
                    ],
                },
            ]);

            this.setState({ generatingXLSX: false });
        });
    }

    _print = () => {
        this.setState({ generatingPDF: true }, () => {
            let styleTags = document.head.getElementsByTagName('style');
            let styleString = '';
            for (let style in styleTags) {
                if (styleTags[style].innerText != '' && styleTags[style].innerText != undefined && styleTags[style].innerText != 'undefined')
                    if (!styleTags[style].innerText.startsWith('iframe')) styleString += styleTags[style].innerText;
            }

            let linkTags = document.head.getElementsByTagName('link');
            let links = [];
            for (let link in linkTags) {
                if (linkTags[link].rel == 'stylesheet' && (linkTags[link].href.indexOf('taimer.com') > 0 || linkTags[link].href.indexOf('taimer-stack:1025') > 0)) {
                    links.push(linkTags[link].href);
                }
            }
            let html =
                '<html><head><meta charset="UTF-8">' +
                `<style> 
                * {
                    font-family: 'Open Sans', sans-serif;
                    -webkit-print-color-adjust: exact;
                    margin: 0;
                    padding: 0;
                }
            </style>` +
                '<style>@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600"</style><style>' +
                styleString +
                '</style>';
            for (let link in links) {
                html += "<link rel='stylesheet' href='" + links[link] + "'>";
            }
            const id = styles.hoursReportPrint;
            html += `</head><body style="margin:0px"><div id="${id}">`;
            let reportHtml = this._reportContent.current?.outerHTML;
            html += reportHtml + '</div></body></html>';

            if (process.env.NODE_ENV !== 'production') {
                const printWindow = window.open('', 'PRINT');
                if (printWindow && reportHtml) printWindow.document.body.innerHTML = html;
                printWindow.focus();
                this.setState({ generatingPDF: false });
            } else {
                const params = {
                    html: html,
                    styles: styleString,
                    links: links,
                };
                DataHandler.post({ url: 'print/project_hours_report' }, params).done((response) => {
                    this.setState({ generatingPDF: false });
                    if (response.error) {
                        return;
                    }
                    DataHandler.getArrayBuffer({ url: 'file', filename: response.filename }).done((response) => {
                        if (response == false) {
                            return;
                        }
                        const blob = new Blob([response], {
                            type: 'application/pdf',
                        });
                        FileSaver.saveAs(blob, `project_hours_report_${this.props.project?.name.replace(' ', '_').toLowerCase()}_${moment().format('YYYY-MM-DD')}.pdf`);
                    });
                });
            }
        });
    };

    _nothingOpened = () => {
        const { openSections } = this.state;
        let nothingOpened = true;
        Object.values(openSections).forEach((val) => {
            if (Object.values(val || {}).includes(true)) {
                if (!!nothingOpened) {
                    nothingOpened = false;
                }
            }
        });
        return nothingOpened;
    };

    render() {
        const { open, project, onClose } = this.props;
        const { generatingPDF, generatingXLSX, loading } = this.state;

        const yearly = this._renderYearlySection();
        const monthly = this._renderMonthlySection();
        const entry = this._renderEntrySection();
        const overview = this._renderOverviewSection();
        const tasks = this._renderTasksSection();

        const noData = !loading && !yearly && !monthly && !entry && !overview && !tasks;

        return (
            <Drawer classes={{ paper: styles.hoursReportSlider }} anchor="right" open={open} onClose={onClose}>
                <div className={styles.top}>
                    <PageTopSection
                        header={this.tr('Project hour report')}
                        subtitle={this.tr('To make a PDF: make a preferred selection from the menus below. Only this selection will be shown in the PDF.')}
                        additionalButtons={[
                            {
                                title: this.tr('Print PDF'),
                                loading: generatingPDF,
                                icon: <Print />,
                                action: this._print,
                                disabled: this._nothingOpened(),
                            },
                            {
                                title: this.tr("Export XLSX"),
                                loading: generatingXLSX,
                                icon: <CloudDownload />,
                                action: () => this._export(),
                                disabled: loading || noData,
                            }
                        ]}
                    />
                    <button onClick={onClose}>
                        <Close />
                    </button>
                </div>
                {!!project && (
                    <div className={styles.content} ref={this._reportContent}>
                        <div className={styles.defaultTitles}>
                            <h1>{project.name}</h1>
                            <p>{project.customer}</p>
                        </div>
                        <div className={styles.printTitles}>
                            <h1>{this.tr('Project hour report')}</h1>
                            <p>{`${project.customer} - ${project.name}`}</p>
                        </div>
                        {loading && <img src={require('../dashboard/insights/img/loading.svg').default} />}
                        {yearly}
                        {monthly}
                        {entry}
                        {overview}
                        {tasks}
                        {noData && (
                            <div className={styles.noDataView}>
                                <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                                <h1>{this.tr('No data to show.')}</h1>
                                <p>{this.tr('Start creating hour entries to see them here.')}</p>
                            </div>
                        )}
                    </div>
                )}
            </Drawer>
        );
    }
}

export default HoursReportSlider;
