import React from 'react';
import isEqual from "lodash/isEqual"; 
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

class ListCell extends React.Component {
	static defaultProps = {
		additionalContent: undefined,
		owner: undefined,
		alignCell: false,
		width: 200,
		textualPresentationKey: undefined,
		className: "",
        cellClassName: "",
		editable: true,
		editMode: "one_cell",
		permanentEditMode: false, // Must ensure that the cell never goes out of editMode.
        controlEditMode: false,
        inEditMode: undefined, // Must ensure that the cell's inEditMode state reflects this prop. A good use case is setting a whole row editable by showing all cells as editable at once.
        inCreateMode: false,
		initInEditMode: false, // Must ensure that the first time the cell is displayed, it is in editMode.
        innerStyle: {},
		listRef: undefined,
        noInitFocus: false, // Must ensure that the cell/editor isn't focused when the cell is first displayed. // TODO: What is this prop's actual purpose? It's used in TextInputCell, but here there's no reference to it.
		openEditOnFocus: true, // Must ensure that the cell transitions to editMode when focused.
		onlyDisplay: false, // Must ensure that only the editor is ever displayed.
        useIndenting: false,
        indent: 0,
		showTooltipForOverflownText: false,
        showErrorBorder: false,
		// tabIndex: "-1",
        hideOverflow: false, // This is done in a hurry for release-w36. If it causes problems or doesn't work well, refactor please.
        textAlign: "left",
        fontWeight: "normal",
        noBorder: false,
		style: {},
		onShiftTab: () => {},
		onClick: () => {},
		onFocus: () => {},
		onCtrlS: () => {},
		onEnterEditMode: () => {}, // Implies edit should become visible and editing should be possible.
		onQuitEditMode: () => {}, // Implies saving.
		onExitEditMode: () => {}, // Implies saving.
        onMouseEnter: () => {},
        onMouseLeave: () => {},
		value: undefined
	};


	constructor(props) {
		super(props);

		this.cell      = React.createRef();
        this.inner     = React.createRef();
		this.cellValue = React.createRef();

		const { 
			editable, 
			permanentEditMode, 
			inEditMode, 
			initInEditMode 
		} = props;

		this.state = {
			// If the cell is not editable, prevent constructing in edit mode.
			inEditMode: (editable && (inEditMode || initInEditMode || permanentEditMode)),
			showOverflowTooltip: false
		};

		this.focus           = this.focus.bind(this);
		this.blurCell        = this.blurCell.bind(this);
		this.handleFocus     = this.handleFocus.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp 	 = this.handleMouseUp.bind(this);
		this.handleClick 	 = this.handleClick.bind(this);
		this.handleKeyDown 	 = this.handleKeyDown.bind(this);
		this.cleanValue   	 = this.cleanValue.bind(this);
		this.presentValue 	 = this.presentValue.bind(this);
		this.openEdit  	  	 = this.openEdit.bind(this);
		this.closeEdit 	  	 = this.closeEdit.bind(this);
		this.isEditable 	 = this.isEditable.bind(this);
        this.isInEditMode 	 = this.isInEditMode.bind(this);
        this.isInCreateMode  = this.isInCreateMode.bind(this);
		this.getDiv          = this.getDiv.bind(this);

		this.textAlignClasses = {
			left: "alignLeft",
			right: "alignRight",
			center: "alignCenter"
        };

        this.fontWeightClasses = {
            bold: "bold",
            heavy: "heavy",
            normal: "normal"
        };

		this.mouseIsDown   = false;
		this.globalMouseUp = (e) => {
			this.handleMouseUp(e);
		};
	}


	componentDidMount() {
		if(this.props.owner !== undefined) {
			this.props.owner.__getListCell = () => this;
		}

        if(this.props.showTooltipForOverflownText
			&& !this.state.inEditMode 
			&& (this.cellValue.current.scrollWidth > this.inner.current.scrollWidth || this.props.forceToolTip)) {
            this.setState({ showOverflowTooltip: true });
		}
    }


    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.value !== nextProps.value 
            || this.state.inEditMode !== nextState.inEditMode 
            || this.state.showOverflowTooltip !== nextState.showOverflowTooltip
        ) {
            return true;
		}

        // TODO: Benchmark whether this is faster
        // than always returning true when virtualized.
        // In virtualized mode the list itself knows
        // which cells need to be updated, so a simple
        // on whether we're virtualized or not could 
        // save a lot of cpu time.
        return !isEqual(this.props, nextProps);
    }


    focus() {
		this.cell.current?.focus();
		this.props.innerRef?.current?.focus();
	}


	// Used to "save" the cell editor's data. This should trigger the editor's onEdited prop function.
	blurCell() {
		this.focus();

		this.cell.current?.blur();
		this.props.innerRef?.current?.blur();
	}


    handleFocus() {
		const {
			editMode,
			openEditOnFocus,
			editable,
			inEditMode,
			owner,
			onFocus
		} = this.props;

		if(this.mouseIsDown) {
			return;
        }

		onFocus();

		if(!openEditOnFocus) {
			return;
        }

		this.openEdit(() => {
			if(!editable 
				|| (editMode === "row" && !inEditMode)
				|| !owner 
				|| !owner.hasOwnProperty("focusOnEditor") 
				|| typeof owner.focusOnEditor !== "function") {
				return;
			}

			owner.focusOnEditor();
		});
	}


	// Disable enabling edit mode when List is scrolled by grabbing it with the middle mouse button.
	handleMouseDown(e) {
		if(e.button !== 1) {
			return false;
        }

		this.mouseIsDown = true;

		window.addEventListener("mouseup", this.globalMouseUp);
	}


	// Don't let other possible mouseup events stop this prevention.
	handleMouseUp(e) {
		if(e.button !== 1) {
			return false;
        }

		this.mouseIsDown = false;

		window.removeEventListener("mouseup", this.globalMouseUp);
	}


	handleKeyDown(e) {
		if(e.ctrlKey && e.key === "s") {
			e.preventDefault();
			e.stopPropagation();

			this.blurCell();
			this.props.onCtrlS(this);
		}

		if(e.shiftKey && e.key === "Tab") {
			this.props.onShiftTab(this);
		}
	}


	handleClick(e) {
		if(this.props.permanentEditMode || this.props.onlyDisplay) {
			this.props.onClick(e);
		}
	}


	isEditable() {
		return this.props.editable;
	}


	isInEditMode() {
		return this.state.inEditMode;
    }


    isInCreateMode() {
        return this.props.inCreateMode;
    }


	// Used internally to communicate to the wrapped element that edit mode is on.
	openEdit(after = () => {}) {
        if(this.props.controlEditMode || !this.props.editable) {
            return;
        }

		if(this.state.inEditMode || !this.props.editable) {
			if(typeof after === "function")
				after();

			return;
		}

		const { 
			editMode,
			rowRef
		} = this.props;

		({
			one_cell: () => {
				this.setState({ inEditMode: true }, after);		
			},
			row: () => {
				rowRef.enterEditMode(this.props.owner.focusOnEditor);
			}
		})[editMode]();
	}


	// Used by the wrapped element to close edit mode.
	closeEdit() {
		if(this.props.inEditMode || this.props.permanentEditMode || this.props.onlyDisplay) {
			return;
		}

		this.setState({ inEditMode: false });
	}


	// When edit mode changes, call these callbacks to let the wrapped component know of our state.
	componentDidUpdate(prevProps, prevState) {
		const { 
			editable,
			inEditMode,
			width,
			showTooltipForOverflownText,
			onEnterEditMode,
			onQuitEditMode,
			onExitEditMode
		} = this.props;
		
		if(editable) {
			if(prevState.inEditMode === false && this.state.inEditMode === true) {
				onEnterEditMode();
			} else if(prevState.inEditMode === true && this.state.inEditMode === false) {
				onQuitEditMode();
				onExitEditMode()
			}

			if(prevProps.inEditMode !== inEditMode) {
				this.setState({ inEditMode: inEditMode });
			}
		} else if(prevProps.inEditMode && !inEditMode) {
			this.setState({ inEditMode: inEditMode });	
		}

		// If the new value is wide enough show the tip
		if(showTooltipForOverflownText && prevState.inEditMode !== this.state.inEditMode && !this.state.inEditMode) {
            const valueWidth = this.cellValue.current.scrollWidth;
            const innerWidth = this.inner.current.scrollWidth;

			this.setState({ 
                showOverflowTooltip: valueWidth > innerWidth 
            });
		}
	}


	cleanValue(presentedValue) {
		// placeholder function
		// TODO: See if any sanitation etc. needs to be done to this value ever.
		return presentedValue;
	}


	presentValue(value) {
		if(Array.isArray(value)) // Hahaa.
			return value.map(val => this.presentValue(val)).join(", ");

		if(value === undefined || value === null)
			return "";
		else if(typeof value === "string" || typeof value === "number")
			return value;
		else if(typeof value === "object" && (this.props.textualPresentationKey !== undefined || value.hasOwnProperty("name") || value.hasOwnProperty("label")))
			return value[this.props.textualPresentationKey !== undefined ? this.props.textualPresentationKey : value.hasOwnProperty("name") ? "name" : "label"];
	}


	getDiv() {
		return this.props.innerRef || this.cell;
	}


    render() {
        const editMode           = !this.props.controlEditMode ? this.state.inEditMode : this.props.inEditMode;
		const propClassName      = this.props.className.trim() == "undefined" ? "" : this.props.className; // ??????????
        const className          = `cell ${propClassName} ${this.props.showErrorBorder ? "error" : ""} ${this.props.showWarningBorder ? "warning" : ""} ${this.state.inEditMode || this.props.permanentEditMode ? "editMode" : ""} ${this.props.permanentEditMode ? "permanentEditMode" : ""} ${this.props.alignCell === true ? this.textAlignClasses[this.props.textAlign] : ""} ${this.props.noBorder ? "noBorder" : ""}`;
        const indentClassName    = this.props.useIndenting ? " indent-" + this.props.indent : "";
        const cellValueClassName = `cellValue ${this.textAlignClasses[this.props.textAlign]} ${this.fontWeightClasses[this.props.fontWeight]}`;
		const cellValueStyle     = this.props.disablePadding ? { padding: 0 } : {};

        const useFlex = !(typeof(this.props.style) === "object" && this.props.style.hasOwnProperty("flex"));
        const style   = { 
            width: this.props.width + "px", 
            flex: this.props.width + " 1 0px",
            ...this.props.style 
        };

		const { 
            additionalContent,
            onMouseEnter,
            onMouseLeave
        } = this.props;

		const presentedValue    = this.cleanValue(this.presentValue(this.props.value)) || (this.props.placeholderOnEmpty && this.props.placeholder);
		const ValueWrapper      = this.state.showOverflowTooltip ? Tooltip : React.Fragment;
        const valueWrapperProps = this.state.showOverflowTooltip ? { title: presentedValue, placement: "right" } : {};

		return (
			!this.props.onlyDisplay
			?
			<div
				ref={this.props.innerRef || this.cell}
				data-testid={this.props['data-testid']}
				className={`${className}${indentClassName}`}
				style={style}
				onMouseDown={this.handleMouseDown}
				onMouseUp={this.handleMouseUp}
				onKeyDown={this.handleKeyDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
				onClick={e => this.props.permanentEditMode || this.props.onlyDisplay ? this.props.onClick(e) : null}
				tabIndex={this.props.editable || this.props.permanentEditMode ? "0" : false} // TODO: need a prop to control whether this rule should be applied at all
				onFocus={this.handleFocus}
				title={!this.state.inEditMode && this.props.title ? this.props.title : ""}>
				{(!editMode && !this.props.permanentEditMode)
				&&
				(<ValueWrapper {...valueWrapperProps}>
					<div className="inner" style={this.props.innerStyle} ref={this.inner}>
						{this.props.openChildren ? 
							<div data-testid={`${this.props['data-testid']}-value`} style={cellValueStyle} onClick={this.props.openChildren} ref={this.cellValue} className={cellValueClassName + " " + this.props.cellClassName + " parentnamecell"}>{this.props.isChildrenOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp /> }{presentedValue}</div> :
							<div data-testid={`${this.props['data-testid']}-value`} style={cellValueStyle} ref={this.cellValue} className={`${cellValueClassName} ${this.props.cellClassName}`}>{presentedValue}</div> 
						}
					</div>
				</ValueWrapper>)}
				{((editMode || this.props.permanentEditMode) && this.props.editable)
				&&
				(this.props.children ? (
					<div className={"inner"} style={this.props.innerStyle} ref={this.inner}>
						{this.props.children}
					</div>
				) : (
					<ValueWrapper {...valueWrapperProps}>
						<div data-testid={`${this.props['data-testid']}-value`} ref={this.cellValue} style={cellValueStyle} className={cellValueClassName}>{presentedValue}</div>
					</ValueWrapper>
				))}
				{additionalContent !== undefined && (additionalContent(this))}
			</div>
			:
			<div ref={this.props.innerRef || this.cell} data-testid={this.props['data-testid']} title={this.props.title ? this.props.title : undefined} className={className} style={style}>
                <div className={`inner ${this.props.hideOverflow ? "hideOverflow" : ""} ${this.props.cellClassName}`} style={this.props.innerStyle}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export const ListCellWithRef = React.forwardRef((props, ref) => <ListCell {...props} innerRef={ref} />);
export default ListCell;
