import React from "react";
import SettingRow from "../../list/rows/SettingRow";
import ListCell from "../../list/ListCell";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";

/**
 * Efina settings - TalenomTaxRow
 *
 */
export default class TalenomTaxRow extends SettingRow {
	/**
	 * TalenomTaxRow renderer
	 *
	 */
	render() {
		const cells = {
			delete: (
				<ListCell
					className="noBg pointer"
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap["delete"]}
				>
					<TrashCanIcon />
				</ListCell>
			),
			value: (
				<TextInputCell
					width={this.props.columnWidthMap["value"]}
					name="value"
					value={this.state.data["value"]}
					onEdited={this.editAndSave}
				/>
			),
		};

		return (
			<div
				className="listElement row flex"
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}
