import React from 'react';
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import styles from './ToggleableContainer.module.scss';
import { Tooltip } from '@mui/material';
import WithTabs from '../navigation/WithTabs';

class ToggleableContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: props.section?.initiallyOpen,
            selectedTab: (props.section?.tabs || [])[0]?.id || props.section?.key,
        };
    }

    toggleOpenState = () => {
        const { open } = this.state;
        this.setState({ open: !open }, () => {
            this.props.onOpenStateChanged(this.props.section.key, this.state.open);
        });
    };

    render() {
        const { title, children, hidden, section, nextOpen } = this.props;
        const { open, selectedTab } = this.state;
        const { rightComponent, tooltip, tabs } = section;
        const style = hidden ? { display: 'none' } : undefined;
        return (
            <div key={section.key} id={styles.toggleableContainer} className={(open || nextOpen) && !section.onlyContent ? styles.open : ''} style={style}>
                {!section.onlyContent && (
                    <div className={styles.titleContainer} onClick={this.toggleOpenState}>
                        <div className={styles.left}>
                            <h2 className={styles.h2}>{title}</h2>
                            {open && tooltip && (
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={tooltip} arrow placement="right">
                                    <InfoOutlined className={styles.tooltip} />
                                </Tooltip>
                            )}
                        </div>
                        <div className={styles.right}>
                            {open && <div className={styles.custom}>{rightComponent && <div className={styles.rightComponent}>{rightComponent()}</div>}</div>}
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </div>
                    </div>
                )}
                {open && (
                    <div className={`${styles.container} ${open ? styles.open : ''}`}>
                        {tabs && tabs.length > 1 ? (
                            <WithTabs tabs={tabs} selectedTab={selectedTab} noUpdateView noInnerPadding onTabClick={({ id: selectedTab }) => this.setState({ selectedTab })}>
                                {(selected) => children && children(selected)}
                            </WithTabs>
                        ) : (
                            children && children(selectedTab)
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ToggleableContainer;
