import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import DataHandler from '../../general/DataHandler';
import DialogBorder from '../DialogBorder';
import DialogSection from '../DialogSection';
import TaimerComponent from  '../../TaimerComponent';
import ImportReview from './ImportReview';
/* dialog parts */
import ImportTool from '../../general/ImportTool';
import LoadingScreen from '../../general/LoadingScreen';
import FileUpload from './FileUpload';
import ResultsScreen from './ResultsScreen';
import ErrorScreen from './ErrorScreen';

/* material ui */
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import withStyles from '@mui/styles/withStyles';

/* other */
import { withSnackbar } from 'notistack';
import _ from 'lodash';

/* css */
import './ImportDialog.css';
import { DialogContent } from '@mui/material';

const initialState = {
    file: [],
    fileData: false,
    importObjects: [],
    importData: [],
    accountTypes: false,
    noMemory: false,
    onlyIntStr: false,
    error: false,
    importStage: "upload",
    accountsById:{},
    accountsByName:{},
    accountsByVatid:{},
    fileAttributes: {
        totals: {
            rowTotal: 0,
            taimerTotal: 0,
            fileErrorTotal: 0,
        },
        duplicateNameDiffVatid: [],
        duplicateVatidDiffName: [],
        noNameVatid: [],
        vatidExistsDiffName: [],
        nameExistsDiffVatid: [],
        vatidExists: [],
        nameExists: [],
        skip: [],
    },
    errorHandling: {
        skip: true,
        override: false,
    },
    errorRows: {
        inFile: [],
        inTaimer: [],
    },
    importResults: {
        total: 0,
        success: 0,
        skip: 0,
        errors: {
            totals: {
                totalErrors: 0,
                rowErrors: 0,
            },
            account: {
                account: [],
                tags: [],
                type: [],
                customer_group: [],
            },
            address: {
                visiting: [],
                invoicing: [],
                delivery: [],
            },
            contact: {
                contact: [],
                tags: [],
            }
        }
    },
    importStep: 0,
    integration: {value: 0, label: ""},
    useCustomCustomerId: false,
    back: false,
}

class ImportAccountDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportAccountDialog")
        this.accountFields = [
            { id: 0,  name: "" },
            { id: 2,  name: this.tr("Account Vat-ID")},
            { id: 1,  name: this.tr("Account number")},
            { id: 3,  name: this.tr("Account name")},
            { id: 47, name: this.tr("Subunit")},
            { id: 6,  name: this.tr("Account branch of business" )},
            { id: 5,  name: this.tr("Account type" )},
            { id: 8,  name: this.tr("Account group")},
            { id: 9,  name: this.tr("Account Email")},
            { id: 11, name: this.tr("Account Phone" )},
            { id: 10, name: this.tr("Account Website")},
            { id: 7,  name: this.tr("Account manager" )},
            { id: 48, name: this.tr("Account team" )},
            { id: 12, name: this.tr("Account Tags" )},
            { id: 13, name: this.tr("Visiting address" )},
            { id: 14, name: this.tr("Visiting post number" )},
            { id: 16, name: this.tr("Visiting city" )},
            context.taimerAccount.countryCode === "US" && { id: 15, name: this.tr("Visiting state" )},
            { id: 17, name: this.tr("Visiting country" )},
            { id: 32, name: this.tr("Contact first name")},
            { id: 33, name: this.tr("Contact last name")},
            { id: 36, name: this.tr("Contact title")},
            { id: 49, name: this.tr("Contact position")},
            { id: 34, name: this.tr("Contact email")},
            { id: 35, name: this.tr("Contact phone")},
            //{ id: 37, name: this.tr("Contact language")},
            { id: 38, name: this.tr("Contact tags")},
            { id: 18, name: this.tr("Invoice Address" )},
            { id: 19, name: this.tr("Invoice post number")},
            { id: 21, name: this.tr("Invoice city")},
            context.taimerAccount.countryCode === "US" && { id: 20, name: this.tr("Invoice state")},
            { id: 22, name: this.tr("Invoice country")},
            { id: 46, name: this.tr("Payment Term")},
            { id: 50, name: this.tr("Invoice email")},
            { id: 23, name: this.tr("Invoice contact person")},
            { id: 25, name: this.tr("E-invoice address")},
            { id: 24, name: this.tr("E-invoice operator")},
            //{ id: 26, name: this.tr("Delivery address")},
            //context.taimerAccount.countryCode === "US" && { id: 27, name: this.tr("Delivery state")},
            //{ id: 28, name: this.tr("Delivery post number")},
            //{ id: 29, name: this.tr("Delivery city")},
            //{ id: 30, name: this.tr("Delivery country")},
            //{ id: 31, name: this.tr("Delivery contact person")},
            //{ id: 39, name: this.tr("Contact address")},
            //{ id: 40, name: this.tr("Contact post number")},
            //{ id: 41, name: this.tr("Contact city")},
            //{ id: 42, name: this.tr("Contact country")},
            //{ id: 43, name: this.tr("Contact maillist")},
            { id: 4,  name: this.tr("Integration ID")},

        ].filter(x => x); //null filtering

        this.integrations = [
            {value: 0,  label: ""},
            {value: 1,  label: this.tr("Talenom")},
            {value: 2,  label: this.tr("Netvisor")},
            //{value: 3,  label: this.tr("Maventa")},
            {value: 4,  label: this.tr("Quickbooks")},
            {value: 5,  label: this.tr("eFina")},
            //{value: 6,  label: this.tr("Ropo Capital")},
            //{value: 7,  label: this.tr("EmCe")},
            {value: 8,  label: this.tr("Fennoa")},
            {value: 9,  label: this.tr("Fivaldi")},
            //{value: 10, label: this.tr("Mepco")},
            {value: 11,  label: this.tr("Heeros")},
        ];

        if (this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(props.company) > -1) {
            this.accountFields.splice(2, 0, { id: 1,  name: this.tr("Account Number" )})
        }

        this.state = _.cloneDeep(initialState);
        this.dialog = React.createRef();
        this.upload = React.createRef();

        ["setImportObjects", 
        "setFileData", 
        "startLoadingAnimation", 
        "reviewImport", 
        "handleNext", 
        "handleBack", 
        "stageFile", 
        "uploadFile"].forEach(e => this[e] = this[e].bind(this));

    }
    
    componentDidMount() {
        super.componentDidMount();
        this.fetchInitialData();
    }

    fetchInitialData = () => {
        DataHandler.get({url: "imports/accounts_by_name"}).done(response => {
            this.setState({accountsByName: response});
        });
        DataHandler.get({url: "imports/accounts_by_vatid"}).done(response => {
            this.setState({accountsByVatid: response});
        });
        DataHandler.get({url: "imports/accounts_by_id"}).done(response => {
            this.setState({accountsById: response});
        });
    }

    changeIntegration = (integration) => {
        this.setState({integration: integration});
    }

    handleCustomCustomerIdChange = (value) => {
        this.setState({useCustomCustomerId: value});
    }

    close = () => {
        this.props.onDialogClose();
    }

    handleNext() {
        let { importStep, importObjects, integration } = this.state;
        let nextError = false;
        if (importStep == 1) {
            if (Object.keys(importObjects).length > 0 && _.find(importObjects, x => x.taimerField.id === 4 && x.importable) && integration.value == 0) {
                this.props.enqueueSnackbar(this.tr(`Target integration not selected!`), {
                    variant: "error",
                });
                nextError = true;
                return nextError;
            }
        }
        importStep++;
        this.setState({importStep: importStep, back: false});
        return nextError;
    }

    handleBack() {
        let component = "";
        let { importStep, importObjects } = this.state;
        let stage = this.state.importStage;
        if(stage === "config" || stage === "error") {
            component = "upload";
            this.setState(_.cloneDeep(initialState));
            this.fetchInitialData();
        } else if(stage === "review") {
            component = "config";
            this.setState({
                fileAttributes: {
                    totals: {
                        rowTotal: 0,
                        taimerTotal: 0,
                        fileErrorTotal: 0,
                    },
                    duplicateNameDiffVatid: [],
                    duplicateVatidDiffName: [],
                    noNameVatid: [],
                    vatidExistsDiffName: [],
                    nameExistsDiffVatid: [],
                    //idExists: [],
                    vatidExists: [],
                    nameExists: [],
                    skip: [],
                },
                errorRows: {
                    inFile: [],
                    inTaimer: [],
                },
            });
        }
        if(importStep > 0)
            importStep--;
        this.setState({importStep: importStep, importStage: component});
    }

    save = async () => {
        let { importData, errorHandling, errorRows, importResults, importStep, integration, useCustomCustomerId } = this.state;
        this.setState({importStage: "import"})
        let chunks = _.chunk(importData, 100);
        let i = 0;

        for (const chunk of chunks) {
            const response = await DataHandler.post({ url: `imports/accounts`}, 
                { details: { 
                    companies_id: this.props.company, 
                    accounts: chunk, 
                    errorHandle: errorHandling,
                    errorRows: errorRows,
                    chunk: i,
                    integration: integration.value,
                    use_custom_customer_id: useCustomCustomerId,
                } 
            });
            importResults.total += response.processed_rows;
            importResults.success += response.success_rows;
            importResults.skip += response.skipped_rows;
            _.forEach(response.errors, (category, i) => {
                _.forEach(category, (error, n) => {
                    if(i !== "totals") {
                        importResults.errors[i][n] = [...importResults.errors[i][n], ...error];
                    } else {
                        importResults.errors[i][n] += error; 
                    }
                })    
            }) 
            i++;   
        }            
        this.handleNext();
        this.setState({importResults: importResults, importStage: "results"});
    };

    setImportObjects(importObjects) {
        this.setState({ importObjects: importObjects });
    };

    stageFile(file) {
        this.setState({file: file});
    }

    uploadFile() {
        const { enqueueSnackbar } = this.props;
        const { file } = this.state;
        if(file.length == 0) {
            enqueueSnackbar(this.tr(`Upload a file to continue.`), {
                variant: "error",
            });
            return;
        }
        if(file.length > 0 && !file[0].name.endsWith('xlsx')) {
            enqueueSnackbar(this.tr(`File format is not XLSX.`), {
                variant: "error",
            });
            return;
        }
        if(file.length > 0 && file[0].size > (1048576 * 5)) {
            enqueueSnackbar(this.tr(`File is too large`, "."), {
                variant: "error",
            });
            return;
        }
        this.upload.current.uploadFiles(file);
    }

    setFileData(data) {
        if (data.errors.out_of_memory) {
            this.setState({importStage: "error", noMemory: true});
        } else if (data.errors.only_int_with_str) {
            this.setState({importStage: "error", onlyIntStr: true});
        } else if (data.errors.error) {
            this.setState({importStage: "error", error: true});
        } else {
            let objects = [];
            _.forEach(data.data, d => {
                let obj = JSON.parse(d)
                objects.push(obj)
            })
            data.data = objects;
            this.setState({fileData: data, importStage: "config", file: []});
            this.handleNext();
        }
    }

    startLoadingAnimation(e) {
        if(e.target.name === "upload")
            this.setState({importStage: "loading"});
    }
    
    handleConflict = (e) => {
        let { errorHandling } = this.state;
        errorHandling[e.target.value] = !errorHandling[e.target.value]; 
        this.setState({ errorHandling: errorHandling});
    }

    reviewImport(e) {
        let { fileAttributes, fileData, importObjects, accountsByName, accountsByVatid, accountsById, errorRows } = this.state;
        let { enqueueSnackbar } = this.props
        let duplicates = 0;
        let duplicateNames = [];
        let hasname, hascontact = false;
        let fieldsToSave = [];
        let accountsToSave = [];
        let seenVatids = {};
        let seenVatidsRows = {};
        let seenNames = {};
        let seenNamesRows = {};

        this.setState({importStage: "loading"}, () => {

            _.forEach(importObjects, object => {
                if (object.taimerField.id == 3 && object.importable) {
                    hasname = true;
                }
                if (object.taimerField.id >= 32 && object.taimerField.id <= 43 && object.importable) {
                    hascontact = true;
                }
                if (object.importable) {
                    if (fieldsToSave.find(x => x.taimerField.id == object.taimerField.id)) {
                        duplicateNames.push(fieldsToSave.find(x => x.taimerField.id == object.taimerField.id).taimerField.name)
                        duplicates++;
                    }
                    fieldsToSave.push(object);
                }
    
            })
            if (duplicates > 0) { 
                duplicateNames = _.uniq(duplicateNames);
                let endStr = duplicateNames.length > 1 ? this.tr(`are duplicated`) : this.tr(`is duplicated`);
                enqueueSnackbar(this.tr(`You have`) + " " + duplicates + " " + this.tr(`duplicate fields.`) + " " + duplicateNames.join(", ") + " " + endStr, {
                    variant: "error",
                });
                this.setState({importStage: "config", importObjects: importObjects});
                return;
            }
            if (!hasname) {
                enqueueSnackbar(this.tr(`Account name is mandatory`), {
                    variant: "error",
                });
                this.setState({importStage: "config", importObjects: importObjects});
                return;
            }
            if (hascontact) {
                let firstname, lastname, email = false;
                fieldsToSave.forEach(field => {
                    if(field.taimerField.id == 32)
                        firstname = true;
                    if(field.taimerField.id == 33)
                        lastname = true;
                    //not needed anymore
                    //if(field.taimerField.id == 33)
                    //    email = true;
                })
                if (!firstname || !lastname) {
                    enqueueSnackbar(this.tr(`Contacts firstname and lastname are mandatory`), {
                        variant: "error",
                    });
                    this.setState({importStage: "config", importObjects: importObjects});
                    return;
                }
            }

            fileData.data.forEach((account, i) => {
                if (!account){
                    return;
                }

                let accountFields = {};
                let currentName = "";
                let currentVatid = "";
                let currentId = 0;
                let inTaimer = false;

                _.forEach(fieldsToSave, (field, n) => {
                    accountFields[field.taimerField.id] = account[field.id] && account[field.id].name;
                    //if(field.taimerField.id == 1)
                    //    currentId = account[field.id].name;
                    if(field.taimerField.id == 2)
                        currentVatid = account[field.id].name;
                    if(field.taimerField.id == 3)
                        currentName = String(account[field.id].name).toLowerCase();
                })
                if(currentName === "" && currentVatid === "") {
                    fileAttributes.skip.push(i+2);
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }
                if ((currentName && seenNames[currentName] && seenNames[currentName] !== currentVatid)) {
                    fileAttributes.duplicateNameDiffVatid.push((seenNamesRows[currentName] + " & " + (i+2)));             
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                } 
                if((currentVatid && seenVatids[currentVatid] && seenVatids[currentVatid] !== currentName)) {
                    fileAttributes.duplicateVatidDiffName.push((seenVatidsRows[currentVatid] + " & " + (i+2)));
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }
                if (currentName === "" && currentVatid != 0){
                    fileAttributes.noNameVatid.push((i+2));
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }
                if(currentVatid && accountsByVatid[currentVatid] && String(accountsByVatid[currentVatid]).toLowerCase() !== currentName) {
                    fileAttributes.vatidExistsDiffName.push(i+2);
                    if(!inTaimer) {
                        fileAttributes.totals.taimerTotal++;
                        errorRows.inTaimer.push(i);
                        inTaimer = true;
                    }
                }
                if (currentName && accountsByName[currentName] && accountsByName[currentName] !== currentVatid) {
                    fileAttributes.nameExistsDiffVatid.push(i+2);
                    if(!inTaimer) {
                        fileAttributes.totals.taimerTotal++;
                        errorRows.inTaimer.push(i);
                        inTaimer = true;
                    }
                }
                if(currentVatid && accountsByVatid.hasOwnProperty(currentVatid)) {
                    if(!inTaimer) {
                        fileAttributes.vatidExists.push(i+2);
                        fileAttributes.totals.taimerTotal++;
                        errorRows.inTaimer.push(i);
                        inTaimer = true;
                    }
                }
                if (accountsByName.hasOwnProperty(currentName)) {
                    if(!inTaimer) {
                        fileAttributes.nameExists.push(i+2);
                        fileAttributes.totals.taimerTotal++;
                        errorRows.inTaimer.push(i);
                        inTaimer = true;
                    }
                }
                
                fileAttributes.totals.rowTotal++;
                seenVatids[currentVatid] = currentName;
                if (!seenVatidsRows[currentVatid])
                    seenVatidsRows[currentVatid] = i+2; 
                seenNames[currentName] = currentVatid;
                if (!seenNamesRows[currentName])
                    seenNamesRows[currentName] = i+2;
                accountsToSave.push(accountFields);
            });
            let nextError = this.handleNext();
            if (!nextError)
                this.setState({importData: accountsToSave, fileAttributes: fileAttributes, errorRows: errorRows, importStage: "review"});
            else {
                this.setState({importStage: "config", importObjects: importObjects});
            }
        });
    }

    render() {
        const { company } = this.props;
        const { fileData, importStage, importObjects, fileAttributes, importStep, importResults, error, noMemory, onlyIntStr } = this.state;
        const { tr } = this;
        const importSteps = [tr("Upload File"), tr("Map Attributes"), tr("Review Import"), tr("Results")];
        const errorHeaders = {
            duplicateNameDiffVatid: tr("Account with same name and different vat-id on row"),
            duplicateVatidDiffName: tr("Account with same vat-id and different name on row"),
            noNameVatid: tr("Account with vat-id and no name"),
            vatidExistsDiffName: tr("Vat-ID already exist for a different account in taimer"),
            nameExistsDiffVatid: tr("Name already exist for different vat-id in taimer"),
            vatidExists: tr("Account with vat-id exists in taimer"),
            nameExists: tr("Account with name exists in taimer"),
            skip: tr("Account has no name or vatid and will be skipped")
        };
        const totalHeaders = {
            taimerTotal: tr("Existing accounts"),
            fileErrorTotal: tr("File errors"),
            rowTotal: tr("Rows validated"),
        }
        const resultHeaders = {
            totals: {
                success: tr("Accounts imported"),
                total: tr("Rows processed"),
                skip: tr("Rows skipped"),
                errors: tr("Errors")
            },
            errors: {
                account: {
                    account: tr("Failed to import account on row"),
                    tags: tr("Failed to import account tags on row"),
                    type: tr("Failed to set or import account type on row"),
                    customer_group: tr("Failed to import account group on row"),
                },
                address: {
                    visiting: tr("Failed to import visiting address on row"),
                    invoicing: tr("Failed to import invoicing address on row"),
                    delivery: tr("Failed to import delivery address on row"),
                },
                contact: {
                    contact: tr("Failed to import contact on row"),
                    tags: tr("Failed to import contact tags on row"),
                },

            }
        }
        const Components = {
            error: 
                <ErrorScreen 
                    errorParams={{
                        error: error,
                        onlyIntStr: onlyIntStr,
                        noMemory: noMemory,
                    }}
                />,
            upload: 
                <FileUpload
                    ref={this.upload}
                    startLoadingAnimation={this.startLoadingAnimation} 
                    setFileData={this.setFileData}
                    stageFile={this.stageFile}
                    company={company}
                    uploadHeader={tr("Import accounts by dragging a file here or by")}
                    suggestedFilesize={tr("Max file size: 5.0MiB")}
                    fileSizeWarningThreshold={(1048576 * 5)}
                />,
            loading:
                <LoadingScreen loadingMessage={tr("Processing data...")} />, 
            config: 
                <ImportTool 
                    importFields={Object.keys(importObjects).length > 0 ? importObjects : fileData.field_names}
                    exampleData={fileData.example_data}
                    taimerFields={this.accountFields}
                    onBack={(importStage !== "results" && importStage !== "upload" && importStage !== "loading" && importStage !== "import") ? this.state.back : undefined}
                    setData={this.setImportObjects}
                    dialogRef={this.dialog}
                    extraSettings={[
                        {
                            type: "dropdown",
                            show: Object.keys(importObjects).length > 0 ? _.find(importObjects, x => x.taimerField.id === 4 && x.importable) : false, 
                            content: this.integrations,
                            value: this.state.integration,
                            label: tr("Integration"),
                            onChange: this.changeIntegration,
                            header: tr("Integration selection"),
                        }, 
                        {
                            type: "switch",
                            show: Object.keys(importObjects).length > 0 ? _.find(importObjects, x => x.taimerField.id === 44 && x.importable) : false,
                            value: this.state.useCustomCustomerId,
                            onChange: this.handleCustomCustomerIdChange,
                            label: tr("Use old Account ID's") + ".",
                            subtext: tr("Using this changes how Account Nr's work so that new Account Nr's won't be generated automatically and the user will have to input them manually") + "."
                        }
                    ]}
                />,   
            import: 
                <LoadingScreen loadingMessage={tr("Importing...")} />,
            review: 
                <ImportReview 
                    fileAttributes={fileAttributes} 
                    errorHeaders={errorHeaders} 
                    totalHeaders={totalHeaders} 
                    buttonLabels={{
                        skip: tr("Skip errors"),
                        override: tr("Update existing Accounts"),
                    }}
                    handleConflict={this.handleConflict}
                    conflictOptions={this.state.errorHandling}
                />,
            results: <ResultsScreen results={importResults} headers={resultHeaders} />
        }

        const Component = Components[importStage];
        
        return (
            <DialogBorder
                title={importStage !== "review" ? tr("Import accounts from file") : tr("Review & import")}
                onClose={this.close}
                className="Account"
                id="ImportDialog"
                saveText={importStage === "results" ? tr("Close") : importStage === "review" ? tr("Import") : tr("Continue")}
                onSave={
                    importStage === "upload" ? this.uploadFile : 
                    importStage === "review" ? this.save : 
                    importStage === "config" ? this.reviewImport : 
                    importStage === "results" ? this.close : 
                    undefined
                }
                onBack={(importStage !== "results" && importStage !== "upload" && importStage !== "loading" && importStage !== "import") ? this.handleBack : undefined}
                dialogRef={this.dialog}>
                <DialogSection>
                    <Stepper activeStep={importStep} alternativeLabel className="stepper">
                      {importSteps.map(label => (
                        <Step key={label} className="step">
                          <StepLabel className="step-label">{label}</StepLabel>
                        </Step>
                        
                      ))}
                    </Stepper>
                    {Component}
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(ImportAccountDialog);
