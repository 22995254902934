import React from 'react';
import TaimerComponent from "../TaimerComponent";
import './Settings.css';
import { withStyles } from '@mui/styles';
import { List, ListItem, ListItemText, Collapse, MenuItem, Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import OverlayList from './../list/List';
import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import PersonalDetails from './pages/PersonalDetails';
import ActivateMobileApp from './pages/ActivateMobileApp';
import ChangePassword from './pages/ChangePassword';
import Notifications from './pages/Notifications';
import Permissions from './pages/Permissions';
import AccountDefaultSettings from './pages/AccountDefaultSettings';
import CompanyAddress from './pages/CompanyAddress';
import CompanyBankAccounts from './pages/CompanyBankAccounts';
import CompanyLogo from './pages/CompanyLogo';
import UserList from './pages/UserList';
import UserGroups from './pages/UserGroups';
import Account from "./pages/Account";
import Contact from "./pages/Contact";
import Activity from "./pages/Activity";
import Project from "./pages/Project";
import Product from "./pages/Product";
import Invoice from "./pages/Invoice";
import Expense from "./pages/Expense";
import TimeManagement  from "./pages/TimeManagement";
import Pipeline  from "./pages/Pipeline";
import SubscriptionInfo from "./pages/SubscriptionInfo";
import BillingList from "./pages/BillingList";
import Resourcing from "./pages/Resourcing";
import Tags from "./pages/Tags";
import cn from 'classnames';
import { withSnackbar } from 'notistack';

import { ReactComponent as HoursOverlay } from '../projects/images/time_tracker.svg';
import ReferralProgram from './pages/ReferralProgram';
import Accounting from './pages/Accounting';
import TermsAndConditions from './pages/TermsAndConditions';
import OutlinedField from "../general/OutlinedField";

import GoogleDrive from "./api-settings/GoogleDrive";

/* overlay */
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';
import Goals from './pages/Goals';
import colors from '../colors';
import Link from '../general/Link';

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    listRoot: {
        paddingLeft: 0,
    },
    listItem: {
        paddingLeft: '16px',
    },
    listItemSelected: {
        backgroundColor: 'transparent !important',
        color: colors.dark_sky_blue,
        fontWeight: 'bold'
    },
    listItemText: {
        font: "'Open Sans', sans-serif",
        fontSize: '16px',
    },
    listItemNested: {
        color: 'black',
        padding: '4px 22px',
        '&$selected, &$selected:hover': {
            backgroundColor: "transparent !important",
            color: colors.dark_sky_blue,
            fontWeight: 'bold'
        },
    },
    listItemNestedSelected: {
        //'&$selected, &$selected:hover': {
            backgroundColor: "transparent !important",
            color: colors.dark_sky_blue,
            fontWeight: 'bold'
        //},
    },
    listItemNestedText: {
        font: "'Open Sans', sans-serif",
        fontSize: '12px',
    },
});

class Settings extends TaimerComponent {
    static contextType = SettingsContext;
    headerTitle = this.tr("Settings");

    static defaultProps = {
        group: 'my-profile',
        page: 'default',
    }

    constructor(props, context) {
        super(props, context, "settings/Settings");
        const { tr } = this;
        
        this.settingsSections = [
            {
                id: 'my-profile',
                name: tr("My Profile"),
                rights: 'admin user',
                subsections: [
                    {
                        id: 'default',
                        name: tr("Personal Details"),
                        component: PersonalDetails,
                        rights: 'admin user',
                    },
                    {
                        id: 'mobile',
                        name: tr("Activate Mobile Application"),
                        component: ActivateMobileApp,
                        rights: 'admin user',
                    },
                    {
                        id: 'notifications',
                        name: tr("Notifications"),
                        component: Notifications,
                        rights: 'admin user',
                    },
                    {
                        id: 'password',
                        name: tr("Password"),
                        component: ChangePassword,
                        rights: 'admin user',
                    },
                    {
                        id: 'referral',
                        name: tr("Referral Program"),
                        component: ReferralProgram,
                        rights: 'admin user',
                        className: 'SettingsNoPadding SettingsNoScroll SettingsReferral',
                    },
                    (this.context.addons?.googledrive?.used_by_companies.indexOf(context.userObject.companies_id) > -1) && {
                        id: 'personal-integrations',
                        name: tr("Personal integration settings"),
                        component: GoogleDrive,
                        rights: 'admin user',
                    }
                ]
            },
            {
                id: 'company',
                name: tr("Company Details"),
                rights: 'admin',
                subsections: [
                    {
                        id: 'default',
                        name: tr("Account default settings"),
                        component: AccountDefaultSettings,
                        rights: 'admin',
                    },
                    {
                        id: 'address',
                        name: tr("Address"),
                        component: CompanyAddress,
                        rights: 'admin',
                    },
                    {
                        id: 'bankaccounts',
                        name: tr("Bank Account & invoicing"),
                        component: CompanyBankAccounts,
                        rights: 'admin',
                    },
                    {
                        id: 'logo',
                        name: tr("Logo"),
                        component: CompanyLogo,
                        rights: 'admin',
                    }
                ]
            },
            {
                id: 'users',
                name: tr("User Management"),
                rights: 'admin',
                subsections: [
                    {
                        id: 'default',
                        name: tr("Users"),
                        component: UserList,
                        rights: 'admin',
                        className: "settings-userlist"
                    },
                    /*{
                        id: 'teamleaders',
                        name: tr("Team Leaders"),
                        rights: 'admin',
                    },
                    {
                        id: 'roles',
                        name: tr("Roles"),
                        rights: 'admin',
                    },*/
                    {
                        id: 'upg',
                        name: tr("User Permission Groups"),
                        component: UserGroups,
                        rights: 'admin',
                    },
                    {
                        id: 'permissions',
                        name: tr("Permissions"),
                        component: Permissions,
                        rights: 'admin',
                    },
                ]
            },
            {
                id: 'billing',
                name: tr("Billing"),
                rights: 'admin',
                hiddenOrigins: ['ingram'],
                subsections: [
                    {
                        id: 'default',
                        name: tr("Subscription"),
                        component: SubscriptionInfo,
                        rights: 'admin',
                    },
                    context.taimerAccount.stripeCustomerId && {
                        id: 'billing_history',
                        name: tr("Billing history"),
                        component: BillingList,
                        rights: 'admin',
                    },
                    /*{
                        id: 'manage_plan',
                        name: tr("Manage plan"),
                        component: SubscriptionManager,
                        rights: 'admin',
                    }*/
                ]
            },
            {
                id: 'features',
                name: tr("Feature Settings"),
                rights: 'admin',
                subsections: [
                    {
                        id: 'default',
                        name: tr("Account"),
                        component: Account,
                        rights: 'admin',
                    },
                    {
                        id: 'activity',
                        name: tr("Activity"),
                        component: Activity,
                        rights: 'admin',
                    },
                    {
                        id: 'contact',
                        name: tr("Contact"),
                        component: Contact,
                        rights: 'admin',
                    },
                    {
                        id: 'expense',
                        name: tr("Expenses"),
                        component: Expense,
                        rights: 'admin',
                    },
                    {
                        id: 'invoicing',
                        name: tr("invoicing bills"),
                        component: Invoice,
                        rights: 'admin',
                    },
                    {
                        id: 'projects',
                        name: tr("Projects"),
                        component: Project,
                        rights: 'admin',
                    },
                    {
                        id: 'products',
                        name: tr("Products"),
                        component: Product,
                        rights: 'admin',
                    },
                    {
                        id: 'pipeline',
                        name: tr("Pipeline"),
                        component: Pipeline,
                        rights: 'admin',
                    },
                    {
                        id: 'time-management',
                        name: tr("Time Management"),
                        component: TimeManagement,
                        rights: 'admin',
                        addon: 'timetracker',
                    },

                    {
                        id: 'resourcing',
                        name: tr("Resourcing"),
                        component: Resourcing,
                        rights: 'admin',
                        addon: 'resourcing'
                    },
                    {
                        id: 'goals',
                        name: tr("Goals"),
                        component: Goals,
                        rights: 'admin',
                    },
                    {
                        id: 'tags',
                        name: tr("Tags"),
                        component: Tags,
                        rights: 'admin',
                    },
                ]
            },
            {
                id: 'integrations',
                name: tr("Integrations"),
                rights: 'admin',
                subsections: [
                    {
                        id: 'default',
                        name: tr("Accounting"),
                        component: Accounting,
                        rights: 'admin',
                    },
                    /*{
                        id: 'e-invoice',
                        name: tr("E-Invoice"),
                        rights: 'admin'
                    },
                    {
                        id: 'banks',
                        name: tr("Banks"),
                        rights: 'admin'
                    },
                    {
                        id: 'o365',
                        name: tr("O365"),
                        rights: 'admin'
                    },
                    {
                        id: 'sales-automation',
                        name: tr("Sales Automation"),
                        rights: 'admin'
                    },
                    {
                        id: 'marketing-automation',
                        name: tr("Marketing Automation"),
                        rights: 'admin'
                    }*/
                ]
            },
            {
                id: 'terms',
                name: tr("Terms & Conditions"),
                rights: 'admin user',
                component: TermsAndConditions,
                /*subsections: [
                    {
                        id: 'tos',
                        name: tr("Terms of Services"),
                        href: 'https://help.taimer.com/english-articles-on-how-to-use-taimer/terms-of-agreement-taimercom',
                        rights: 'admin user',
                    },{

                        id: 'privacy',
                        name: tr("Privacy Policy"),
                        href: 'https://help.taimer.com/english-articles-on-how-to-use-taimer/data-processing-agreement-taimercom',
                        rights: 'admin user',
                    },
                ]*/
            },
        ]



        this.state = {
            groupExpanded: true,
            groupsExpanded: [],
            companies: [],
            company: this.props.company ? this.props.company : context.functions.getCompany("admin", "admin", context.userObject.companies_id, true)
        }
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }
    updateComponentData = () => {
        DataHandler.get({url: 'subjects/companies/admin/admin'}).done( companies => this.setState({companies}) );
    }

    setActiveGroup = (group) => {
        const { currentGroup, groupExpanded } = this.props;
        const { groupsExpanded } = this.state;

        if (group === currentGroup)
        {
            this.setState({groupExpanded: !groupExpanded})
        }
        else
        {
            const groupData = this.settingsSections.find(x => x.id === group);

            if (!groupData)
                return;

            const pageData = groupData.subsections && groupData.subsections.find(x => x.id === 'default');

            if (pageData || groupData.component) {
                this.setActivatePage(group, 'default');
            } else {
                const newGroups = groupsExpanded.filter((v) => v !== group);

                !groupsExpanded.includes(group) && newGroups.push(group);

                this.setState({groupsExpanded: newGroups});
            }
        }
    }

    setActivatePage = (group, page) => {
        this.setState({ groupExpanded: true })
        this.context.functions.updateView({group: group, page});
    }

    renderSection = (section) => {
        const { classes, group: currentGroup, page: currentPage } = this.props;
        const { groupExpanded, groupsExpanded } = this.state;
        const { functions: { checkPrivilege }, taimerAccount, addons } = this.context; 

        let rights = checkPrivilege("admin", "admin", this.state.company) ? 'admin' : 'user';

        const expanded = groupsExpanded.includes(section.id) || groupExpanded && currentGroup === section.id;

        if (section.hiddenOrigins && section.hiddenOrigins.indexOf(taimerAccount.origin) !== -1)
            return;

        if(section.rights.includes(rights))
        {
            return <React.Fragment key={section.id}>
                        <ListItem data-testid={"settings_" + section.id} selected={section.id === currentGroup} classes={{ root: section.id === currentGroup ? classes.listItemSelected : classes.listItem }} button onClick={() => this.setActiveGroup(section.id)}>
                            <ListItemText disableTypography classes={{primary: classes.listItemText}} primary={section.name} />
                            {section.subsections && (expanded ? <ExpandLess /> : <ExpandMore />)}
                        </ListItem>
                        {section.subsections && <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {section.subsections.map(v => {
                                    if (!v)
                                        return;
                                    
                                    const hasAddon = !v.addon || !!addons[v.addon];
                                  
                                    if (!hasAddon && taimerAccount.origin === "ingram")
                                        return undefined;

                                    return (v.rights.includes(rights) && (!v.versionId || (v.versionId && v.versionId == this.context.versionId) )) ?
                                    <ListItem selected={v.id === currentPage} button classes={{ root: classes.listItemNested, selected: classes.listItemNestedSelected }} onClick={() => this.setActivatePage(section.id, v.id)}>
                                        <Link data-testid={"settings_" + section.id + "_" + v.id} noColor url={v.href || { group: section.id, page: v.id }}>
                                            <ListItemText disableTypography classes={{primary: classes.listItemNestedText}} primary={v.name} />
                                        </Link>
                                    </ListItem> : undefined
                                })}
                            </List>
                        </Collapse>}
                    </React.Fragment>
        }
        else
        {
            return undefined
        }
    }

    setCompany = (e) => {
        const { setLastCompany } = this.context.functions;

        this.props.updateView({company: e.target.value});
        this.setState({[e.target.name]: e.target.value});
        setLastCompany(e.target.value);
    }

    render() {
        const { group: currentGroup, page: currentPage } = this.props;
        const { companies, company } = this.state;
        const { props } = this;
        const { addons, functions: { checkPrivilege }, taimerAccount } = this.context;
        const { tr } = this;

        const group = this.settingsSections.find(x => x.id === currentGroup);
        const page = (group && group.subsections && group.subsections.find(x => x && x.id === currentPage)) || group;

        const hidden = group && group.hiddenOrigins && group.hiddenOrigins.indexOf(taimerAccount.origin) !== -1;

        const Component = !hidden ? page.component : undefined;

        const hasAddon = !page.addon || !!addons[page.addon];

        let OverlayHeader;
        switch(page.addon) {
            case 'resourcing':
                OverlayHeader = (
                        <div>
                            <h2>{tr("Resource your work.")}</h2> 
                            <h4>{tr("Resource text")}</h4>
                           {/*  <p>{tr("Free trial for add-ons is applicable for 14 Days")}</p> */}
                        </div>
                    )
                break;
            default:
                OverlayHeader =(
                    <div>
                        <h2>{tr("Time tracker")}</h2> 
                        <h4>{tr("Track hours with an automatic built-in time tracker. Get in-depth insights on hourly rates, attendance, billable and non-billable hours ")}</h4>
                        {/* <p>{tr("Free trial for add-ons is applicable for 14 Days")}</p> */}
                    </div>
                )
                break;
        }

        const rights = checkPrivilege("admin", "admin", this.state.company) ? 'admin' : 'user';
        const perm = group.rights.includes(rights) && page.rights.includes(rights);

        return <div className="SettingsPage">
            <div className="SettingsTopRow">
                {/* {rights === 'admin' ? <h2>{tr("Settings")}</h2> : <h2>{tr("My Profile")}</h2>} */}
                {companies.length > 1 && <OutlinedField className="company-selector" name="company" value={company} label={tr("Select company")} onChange={this.setCompany} select>
                    {companies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                </OutlinedField>}
            </div>
            
            <div className="SettingsSplit">
                <div className="SettingsNav">
                    <List component="nav" disablePadding>
                        {this.settingsSections.map(this.renderSection)}
                    </List>
                </div>
                <div className={cn("settingsArea SettingsInnerGrid", !hasAddon && "SettingsLocked", page.className)}>
                    {!hasAddon && <div className="SettingsDisabledOverlay">
                        <HoursOverlay />
                        <div className="text-container">
                            {
                                OverlayHeader
                                
                            }
                            <Button
                                onClick={() => this.props.toggleBuyDialog(page.addon)}>
                                {this.tr("Buy Addon")}
                            </Button>
                        </div>
                    </div>}
                    <div>{perm ? Component && <Component key={`page_${company}`} {...props} company={company} /> : <OverlayList showOverlay={true} overlayComponent={NoPermissionOverlay}/> }</div>
                </div>
            </div>
        </div >
    }
}

export default withStyles(styles)(withSnackbar(Settings));
