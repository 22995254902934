import React, { PureComponent } from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { isEqual } from 'lodash'; 
import { format } from 'date-fns';

// Header Component, to add number of hours to day columns
export default class HeaderWrapper extends PureComponent
{
	static contextTypes = {
		timetracker: PropTypes.object
	};

	constructor(props)
	{
		super(props)
		this.state = {
			events: this.props.events || []
		}
	}

	componentDidUpdate = (oldProps) => {
		if (!isEqual(oldProps.events, this.props.events)) {
			this.setState({ events: this.props.events || [] });
		}
	}

	setEvents = (events) => {
		this.setState({ events: events || [] });
	}

	getEventsTotalHours = (events, start) => {
		const time = _.reduce(events, (sum, e) =>
			sum + (moment(start).isSame(moment(e.start), 'day') ? e.end - e.start : 0), 0);

		return time;
	}

	countWorktime = (totalTime, travelTime, dailyworkinghours) => {
		let workTime = 0;
		const normalTime = totalTime - travelTime;

		if (travelTime === 0 || totalTime <= dailyworkinghours)
			workTime = totalTime;
		else if (normalTime <= dailyworkinghours && totalTime >= dailyworkinghours)
			workTime = dailyworkinghours;
		else 
			workTime = normalTime;

		return workTime;
	}

	render() {
		const { date, label } = this.props;
		const { events } = this.state;
		const { timetracker: { workdays } } = this.context;

		const start = moment(date);
		const rangeEvents = events.filter(e => moment(e.start).isSame(start, 'date'));
		const dateCompare = format(date, "YYYY-MM-DD");

		const dayInfo = workdays[dateCompare] ?? {type: 0, hours: null};

		const holiday = dayInfo.type === 3;

		const travelEvents = [];
		const normalEvents = [];
		
		rangeEvents.forEach(event => {
			if (event.isWorktripProject && !event.is_task) 
				travelEvents.push(event);
			else if (!event.is_task)
				normalEvents.push(event);
		});

		const allEvents = normalEvents.concat(travelEvents);
		let travelTimeMS = 0;
		const totalTimeMS = this.getEventsTotalHours(allEvents, start);	
		
		if (travelEvents.length > 0) {
			travelTimeMS = this.getEventsTotalHours(travelEvents, start);
		}		

		const totalTime = (totalTimeMS / 3600000);
		const travelTime = (travelTimeMS / 3600000);
		const workTime = this.countWorktime(totalTime, travelTime, dayInfo.hours || 0);
		
		let timeView = "";

		if (travelTime === 0) 
			timeView = totalTime.toFixed(2);
		else
			timeView = totalTime.toFixed(2) + " / " + workTime.toFixed(2);

		return (
			<React.Fragment>
				<span className={holiday ? "holiday" : "workday"}>{label}</span>
				<br/>
				<span className={cn("dayHours", holiday ? "holiday" : "workday")}>
					{timeView} h
				</span>
			</React.Fragment>);
	}
}