import React from 'react';
import TaimerComponent from "../TaimerComponent";
import moment from 'moment';

/*context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import { ReactComponent as LoginLogo } from './images/login_logo.svg';
import LoginView from "./LoginView";
import ForgotPasswordView from "./ForgotPasswordView";
import PasswordRequestedView from "./PasswordRequestedView";
import DataHandler from './../general/DataHandler';
import Link from '../general/Link';

/*material ui */
import Button from '@mui/material/Button';

/* css */
import './Login.css';

class Login extends TaimerComponent {
    static contextType = SettingsContext;
    
    state = {
        account: window.location.href.indexOf(':8080')  > -1 ? null : window.location.href.split("/")[3], 
        page: "login",
        email: "",
    }

    
    constructor(props, context) {
        super(props, context, "login/Login" /* translation namespace */);
        if(this.state.account && this.state.account.startsWith("?")) {
            this.state.account = null;
        }
        this.showSSO = window.location.pathname.match(/\/(laakarikes8431)/gi) || window.location.host == "saml.taimer.com";
        this.state.isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

        this.loginViewRef = React.createRef();
        this.forgotPassvordViewRef = React.createRef();
    }

    componentDidMount() {
        let code;
        
        if (code = window.location.href.match(/onboarding_auth=([^&]+)/))
            this.loginViewRef.current.onboardingAuth(code[1]);
        
        if (code = window.location.href.match(/jwt=([^&]+)/))
            this.loginViewRef.current.igAuth(code[1]);

    }

    changePage = (page, email) => {
        this.setState({ page, email });
    }

    handleKeyPress = (event) => {
        if(event.key == 'Enter'){
            event.preventDefault();
            if (this.state.page === "login")
                this.loginViewRef.current.getToken();
            if (this.state.page === "forgotPassword")
                this.forgotPassvordViewRef.current.sendPasswordRequest();
        }   
    }

    sendMobileDownloadAnalyticsEvent = (platform) => {
        const eventData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            mobile_platform: platform,
        };
        this.context.functions.sendAnalytics('mobile_download_clicked', eventData);
    }

    render () {
        const { state } = this;
        const { account, page, email, taimerVersion, lang } = state;
        var lng = window.navigator.userLanguage || window.navigator.language;

        return (
            <div id="taimer-login" onKeyPress={this.handleKeyPress} >
                {
                    this.state.isMobile && (
                        <div class="mobile">
                            <span class="app-info">
                                {this.tr("Download our native mobile app")}
                            </span>
                            <Link 
                                class="app-store"
                                href="https://apps.apple.com/fi/app/taimer-sales-crm-projects/id1453159925"
                                onClick={() => this.sendMobileDownloadAnalyticsEvent('ios')}
                                onClickWithCtrl
                                allowHrefWithOnClick
                            >
                                <img 
                                    src="https://taimer.com/wp-content/uploads/2019/03/App-Store-Badge-US-White.png"
                                    alt="Taimer app store - taimer.com"
                                    width="157"
                                    height="52"
                                />
                            </Link>
                            <Link
                                class="play-store"
                                href="https://play.google.com/store/apps/developer?id=Taimer+Ltd"
                                onClick={() => this.sendMobileDownloadAnalyticsEvent('android')}
                                onClickWithCtrl
                                allowHrefWithOnClick
                            >
                                <img 
                                    src="https://taimer.com/wp-content/uploads/2019/03/Google-Play-Badge-US.png"
                                    alt="Taimer Google Play - taimer.com"
                                    width="157"
                                    height="51"
                                />
                            </Link>
                        <span class="or">{this.tr("or")}</span>                            
                        <Button color="primary" className="login-now" onClick={() => this.setState({isMobile: false})}>{this.tr("Login now")}</Button>
                        </div>
                    )
                }
                {
                    !this.state.isMobile && (
                        <React.Fragment>
                            <LoginLogo />

                            {page === "login" && (
                                <LoginView ref={this.loginViewRef} account={account} changePage={this.changePage} showSSO={this.showSSO} />
                            )}
                            {page === "forgotPassword" && (
                                <ForgotPasswordView ref={this.forgotPassvordViewRef} changePage={this.changePage} />
                            )}
                            {page === "passwordRequested" && (
                                <PasswordRequestedView changePage={this.changePage} email={email} />
                            )}

                            <div class="footer">
                                <p>&copy; 2021 {this.tr("Taimer Ltd")}. {this.tr("All Rights Reserved")}.</p>
                                <Link url={`https://help.taimer.com/${lng == "fi" ? "fi" : "en"}/articles/4341582-privacy-policy-taimer-com`} openInNewTab>{this.tr("Privacy Policy")}</Link>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    };
}

export default Login;