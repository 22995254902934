import React from 'react';

import TaimerComponent from "../TaimerComponent";

/*context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import OutlinedField from "./../general/OutlinedField";
import DataHandler from './../general/DataHandler';

/*material ui */
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

/* jquery */
import $ from 'jquery';
import Link from '../general/Link';

class LoginView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "login/LoginView");
        this.getToken = this.getToken.bind(this);

        this.state = {
            username: '',
            password: '',
            error: false,
            loading: false
        };

        this.validation = {
            account: React.createRef(),
            username: React.createRef(),
            password: React.createRef(),
        }
    };

    getToken() {
        const account = this.props.account || this.account.value;
        const username = this.username.value.trim();
        const password = this.password.value;
        let hasErrors = false;
        this.setState({ loading: true });

        let fields = ["username", "password"];
        if (!this.props.account)
            fields.push("account");

        fields.forEach(e => {
            if (!this.validation[e].current.isValid())
                hasErrors = true;
        });

        if (hasErrors) {
            this.setState({ error: false, loading: false });
            return;
        }

        DataHandler.post({ url: "auth/login" }, { username, password, account})
            .fail((response) => {
                console.log(response);
                this.setState({ error: response?.responseJSON?.error || true, loading: false }); 
            })
            .done((e) => {
                this.setState({ error: false });
                this.setSession(e);
            });
    };

    onboardingAuth(authcode) {
        const { account } = this.state;
        
        DataHandler.post({url: "auth/onboarding"}, {authcode, account})
            .fail(() => this.setState({error: "onBoardingError"}))
            .done(e => this.setSession(e));
    }

    igAuth(authcode) {
        DataHandler.post({url: "auth/ig"}, {authcode})
            .fail(() => this.setState({error: "onBoardingError"}))
            .done(e => this.setSession(e));
    }

    setSession = (response) => {
        this.clearIntercomLocalStorage();

        localStorage.setItem('taimerToken', response.token);
        localStorage.setItem('taimerPrivileges', JSON.stringify(response.privileges) );
        localStorage.setItem('taimerVersion',response.version);
        localStorage.setItem('taimerVersionId',response.version_id);
        localStorage.setItem('taimerAddons', JSON.stringify(response.addons) );
        localStorage.setItem('onboardingEnded',response.onboarding_ended);
        this.context.functions.setTokenExpiration();
        
        let location = `/${response.account}/index.php`;
        
        if (window.location.href.indexOf('odin.') > -1) 
            location = 'index.php';
        
        if (response.onboarding_ended == true)
            window.location = location + "?module=onboarding&action=order";
        else if (localStorage.lastView)
            window.location = location + `?${$.param(JSON.parse(localStorage.lastView))}`
        else
            window.location = location + "?module=dashboard&action=main&selectedTab=my-day";
    }

    loginSAML = () => {
        const account = this.props.account || this.account.value;
        window.location = "/react/api/auth/login_saml?saml=1&account=" + account;
    };

    changePage = (e, page) => {
        e.preventDefault();
        this.props.changePage(page);
    };

    clearIntercomLocalStorage = () => {
        Object.keys(localStorage).forEach(e => e.indexOf("intercom-state") > -1 && localStorage.removeItem(e));
    }

    render() {
        const { state, tr } = this;
        const { username, password, error, loading } = state;
        const { account } = this.props;

        const isSamlTaimer = window.location.host == "saml.taimer.com";
        let errorMessage;
        if (error === 'RC_INSTALLATION')
            errorMessage = tr('This installation is a test installation. Please use https://rc.taimer.com to sign in.');
        else if(error === 'PRODUCTION_INSTALLATION')
            errorMessage = tr('This installation is a production installation. Please use https://accounts.taimer.com to sign in.');
        else
            errorMessage = error === "onBoardingError" ? tr("Log in failed") : (account ? tr("Incorrect username or password") : tr("Incorrect company ID, username or password"));

        return (
            <React.Fragment>
                <h1 className="login-page-header">{tr("Login to Taimer")}</h1>
                {error &&
                    <div className="error-alert">
                        <p>{errorMessage}</p>
                    </div>
                }
                {!account && <OutlinedField data-testid="company" InputProps={{pswdManagerProps: {ignore_last_pass: true}}} noOnchangeValidation={true} className={"login-field"} ref={this.validation.account} validation={["empty"]} inputRef={el => this.account = el} label={tr("Company ID")} autoFocus={!account} />}
                {
                    !isSamlTaimer && <OutlinedField autoComplete="email" data-testid="username" noOnchangeValidation={true} className={"login-field"} value={username} onChange={(e) => this.setState({ username: e.target.value.trim() })} ref={this.validation.username} validation={["empty"]} inputRef={el => this.username = el} type="text" name="username" id="username" label={tr("Username")} autoFocus={account} />
                }
                {
                    !isSamlTaimer && <OutlinedField autoComplete="password" data-testid="password" noOnchangeValidation={true} className={"login-field"} value={password} onChange={(e) => this.setState({ password: e.target.value })} ref={this.validation.password} validation={["empty"]} inputRef={el => this.password = el} type="password" label={tr("Password")} />
                }
                {this.props.showSSO && <Button className="login-button sso-button" color="primary" onClick={() => this.loginSAML()} size="large">{tr("Single Sign-On")}</Button>}
                {
                    isSamlTaimer && <Button className={"login-button"} color="primary" onClick={() => window.location = 'https://account.taimer.com/' + account} size="large">{tr("Use normal login").toUpperCase()}</Button>
                }
                {!isSamlTaimer && !loading &&
                    <Button data-testid="login-button" className={"login-button"} color="primary" onClick={() => this.getToken()} size="large">{tr("Login").toUpperCase()}</Button>
                }
                { !isSamlTaimer && loading &&
                    <Button className={"login-button"} color="primary" size="large" disabled><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>
                }
                { !isSamlTaimer && <div className={"additional"}>
                        <span className={"forgot-password"}>
                            {tr("Forgot password")}? <Link onClick={(e) => this.changePage(e, "forgotPassword")}>{tr("Click here")}</Link>
                        </span>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default LoginView;
