import React, { Component } from 'react';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* data backend */
import DataHandler from './../../general/DataHandler';
import { format, endOfMonth, startOfMonth, isValid, addMonths } from "date-fns";

/* local components */
import TaimerComponent from '../../TaimerComponent';
import DataList from './../../general/DataList';
import { DateRangePicker } from './../../general/react-date-range/src';
import AdvancedSearch from './../../search/AdvancedSearch';
import { parseHexColor, textColorOnBG, colorToHex } from '../../helpers';


/* material ui */
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

/* material icons */
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRight from '@mui/icons-material/ChevronRight';

/* chart */
import * as d3 from 'd3';
import D3Funnel from 'd3-funnel';

/* css */
import './Pipeline.css'

/* lodash */
import _ from 'lodash';
import debounce from 'lodash/debounce';

/* Images */
import { ReactComponent as PipelineGraphic } from '../images/PipelineGraphic.svg';

class Pipeline extends TaimerComponent{

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/overview/Pipeline");
        
        window.d3 = d3;
        
        const thisMonth = {start: startOfMonth(new Date()), end: endOfMonth(new Date())};

        const dateRange = {
            start: format(startOfMonth(new Date()), "YYYY-MM-DD"), 
            end: format(endOfMonth(new Date()), "YYYY-MM-DD")
        };

        this.searchTerms = {};
        this.pipelinePercentages = [];
        this.chartColors = [];

        this.state = {
            startColor: "#2d9ff7",
            endColor: "#033155",
            data: [],
            open: true,
            elementWidth: 10000,
            elementWidthSet: false,
            emptyDataOverlay: false,
            autoCompleteData: false,
            selectedPipeline: {},
            sortedPipelines: {},
            dateRange: {
                selection: {
                    startDate: dateRange.start,
                    endDate: dateRange.end,
                    key: 'selection',
                }
            },

        }

        this.chart          = React.createRef();
        this.refChart       = React.createRef();
        this.advancedSearch = React.createRef();
        this.element        = React.createRef();
        this.handleToggle   = this.handleToggle.bind(this);
        this.sortData       = this.sortData.bind(this);
        this.switchPipeline = this.switchPipeline.bind(this);
        this.generateColors = this.generateColors.bind(this);

    }

    componentDidMount(){
        super.componentDidMount();
       this.updateComponentData();
       window.addEventListener('resize', this.handleResize);
       window.addEventListener('projectCreated', this.updateComponentData);
    }
    
    updateComponentData = () => {
        const { company } = this.props;
        DataHandler.post({url: `autocomplete_data/pipeline/${company}`}).done(data => {
            this.setState({autoCompleteData: data, selectedPipeline: data.pipelines[0]});
            this.handleData();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateChart();
        
        if (prevProps.company !== this.props.company)
            this.updateComponentData();
        else if (this.props.customersId !== prevProps.customersId)
            this.handleData();
        
        if(this.element.current && this.element.current.clientWidth != null && this.state.elementWidthSet == false)
            this.setState({elementWidth: this.element.current.clientWidth, elementWidthSet: true})
         
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('projectCreated', this.updateComponentData);
    }

    handleResize = debounce(() => {
        this.updateView();
        this.updateChart();
    }, 100)

    updateView = () => {
        let element = document.getElementById("overview-pipeline");
        if(element) {
            this.setState({elementWidth: element.clientWidth});
        }
        this.forceUpdate();
    }

    updateChart = () => {
        const node = this.refChart.current;

        if (!node)
        return

        const { open, data, loaded, sortedPipelines: sortedPipelines, selectedPipeline, autoCompleteData } = this.state;
        const { functions: { updateView } } = this.context;
        const { customersId , company} = this.props;
        let pipelineData = sortedPipelines[selectedPipeline.value] && sortedPipelines[selectedPipeline.value].states;
        const chartData = [];

        let i = 0;
        _.forEach(pipelineData, pd => {
            chartData.push({
                value: pd.percentage,
                label: pd.label,
                backgroundColor: this.chartColors[i % this.chartColors.length],
                pipeline_id: pd.pipeline_id,
                stage_id: pd.stage_id
            })
            i++;
        });

        if (chartData.length === 0)
            return;

        let options = {
            chart: {
                height: 345,
                bottomWidth: 187/1048,
                bottomPinch: chartData.length == 1 ? 0 : 1,
            },
            events: {
                click: {
                    block(d) {
                        let parameters = {};
                        _.forEach(chartData, stage => {

                            if (d.value == stage.value){
                                parameters = {view: "kanban" , pipeline_id: stage.pipeline_id, customerId:customersId,  companies_id:company}
                            }
                        })

                        updateView({module: 'projects', action: 'list', ...parameters}, false);
                    }
                }
            },
            block: {
                dynamicHeight: true,
                minHeight: 22,
                fill:{
                    type: 'solid'
                },
            },
            label: {
                format: (l,f) => `${l} ${f}%`,
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '11px',
                fill: "#ffffff"
            },
            tooltip: {
                enabled: false,
                format: (l,f) => `${l} ${f}%`,
            }
        };

        let chart = new D3Funnel(node);

        chart.draw(chartData, options);
    }

    async handleData(){
        const { company } = this.props;

        if(this.searchTerms !== undefined && this.searchTerms.mode === "advanced") {
            const advParams = { 
                advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria), 
                mode: "advanced", 
                customersId: this.props.customersId || 0 
            };
            
            DataHandler.post({url: 'data/pipeline_overview', company}, advParams).done(pipelineData => {this.setState({data: pipelineData.pipelines, empty: pipelineData.empty })}).done(() => this.sortData());
        } else if(this.searchTerms != undefined && this.searchTerms.mode === "freetext"){
            DataHandler.post({url: 'data/pipeline_overview', mode: "freetext", customersId: this.props.customersId || 0, company}, {...this.searchTerms}).done(pipelineData => {this.setState({data: pipelineData.pipelines, empty: pipelineData.empty})}).done(() => this.sortData());
        } else {
            DataHandler.post({url: 'data/pipeline_overview', mode:  "none", customersId: this.props.customersId || 0, company}).done(pipelineData => {this.setState({data: pipelineData.pipelines, empty: pipelineData.empty})}).done(() => this.sortData());
        }

    }

    sortData(){
        const { data, autoCompleteData } = this.state;

        let sortedPipelines = {}
        _.forEach(autoCompleteData.sales_states, state => {

            if (!sortedPipelines[state.pipeline])
                sortedPipelines[state.pipeline] = {
                    states: {}
                }

            sortedPipelines[state.pipeline].states[state.id] = {
                label: state.name,
                stage_id: state.id,
                revenue: 0,
                margin: 0,
                count: 0,
                percentage: 0.00,
                stateorder: state.stateorder,
                pipeline_id: state.pipeline
            }

        });

        data.forEach( pipeline => {

            const totalRevenue = _.sumBy(pipeline, x => Number(x.revenue));

            pipeline.forEach(project => {

                let saleStatesId = project.sales_state_id;

                // If sale state id is invalid, ignore
                if (!saleStatesId || !sortedPipelines[project.pipeline_id] || !sortedPipelines[project.pipeline_id].states[saleStatesId])
                    return;

                sortedPipelines[project.pipeline_id].states[saleStatesId].label = project.sales_state_label;
                sortedPipelines[project.pipeline_id].states[saleStatesId].count++;
                sortedPipelines[project.pipeline_id].states[saleStatesId].revenue += Number(project.revenue);
                sortedPipelines[project.pipeline_id].states[saleStatesId].margin += Number(project.salesmargin);
                let percentage = Number((sortedPipelines[project.pipeline_id].states[saleStatesId].revenue / totalRevenue) * 100);
                if (isNaN(percentage))
                    percentage = 0;
                sortedPipelines[project.pipeline_id].states[saleStatesId].percentage = percentage;
            })
        });

        _.forEach(sortedPipelines, (pipeline) => {
            let percentages = 0;
            _.forEach(pipeline.states, state => {
                percentages += state.percentage;
            })
            if (percentages == 0) {
                let leveler = 100/Object.keys(pipeline.states).length;
                _.forEach(pipeline.states, state => {
                    state.percentage = leveler;
                });
            }
            pipeline.states = _.orderBy(this.roundTo(pipeline.states, 100, 'percentage'), 'stateorder');
        });

        this.setState({ sortedPipelines: sortedPipelines });
    }

    roundTo(l, target, param) {
        var off = target - _.reduce(l, function(acc, x) { return acc + Math.round(x[param]) }, 0);
        return _.chain(l).
                sortBy(function(x) { return Math.round(x[param]) - x[param] }).
                map(function(x, i) { return {...x, [param]: Math.round(x[param]) + (off > i) - (i >= (l.length + off)) }}).
                value();
    }


    handleToggle(){
        if (this.state.open == true){
            this.setState({open: false});
        } else if (this.state.open == false) {
            this.setState({open: true});
        }
    }

    switchPipeline(value){
        this.setState({selectedPipeline: value})
    }

    addLead(e){
        this.context.functions.addProject({ customers_id: this.props.customersId, companies_id: this.props.company });   
    }

    generateColors(startColor, endColor, lenght){

        /*
            chartData[0] = alku väri
            chartdata[last] = loppuväri
            Gradient toimintaperiaate:
            uusi väri = alku rgb arvo - ((alku rgb arvo - loppu rgb arvo) / lenght)
            uusi väri to hex
            alkuväri: rgb(45,159,247)
            loppuväri: rgb(3,49,85)
            erotus: rgb(42,110, 162) / 6 = rgb(7,18,27)
        */

        const sC = parseHexColor(startColor);
        const eC = parseHexColor(endColor);

        let difR = (sC.r - eC.r ) / lenght;
        let difG = (sC.g - eC.g ) / lenght;
        let difB = (sC.b - eC.b ) / lenght;

        let colors = [];

        for(let i=0; i < lenght -1; i++){
            let nC = colorToHex({r: Math.round(sC.r - i * difR), g: Math.round(sC.g - i * difG), b: Math.round(sC.b - i * difB)});
            colors.push(nC);
        }
        colors.push(endColor);

        return (colors);
    }

    render(){

        const { open, data, loaded, sortedPipelines: sortedPipelines, selectedPipeline, autoCompleteData } = this.state;
        const { taimerAccount, privileges, userObject, functions: { updateView } } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency || "EUR"
        }).format;
        const advancedSearchFields = [
            (!this.props.customersId || this.props.customersId < 1) && { field: "customer", transl: "Customer", type: undefined},
            { field: "sales_agent", transl: "Sales agent", type: undefined},
            { field: "project_name", transl: "Project name", type: undefined},
            { field: "project_manager", transl: "Project manager", type: undefined},
            { field: "customership_leader", transl: "Customership leader", type: undefined},
            { field: "customership_group", transl: "Customership group", type: undefined},
            { field: "branchofbusiness", transl: "Branch of Business", type: undefined},
            { field: "startdate", transl: "Start date", type: undefined},
            { field: "enddate", transl: "End date", type: undefined}
        ];

        let i = 0;
        let empty = true;
      
        if(autoCompleteData == false || !selectedPipeline){
            return <div><img className='main-page-loading-indicator' src={require('../insights/img/loading.svg').default} /></div>
        }
        let pipelineData = sortedPipelines[selectedPipeline.value] && sortedPipelines[selectedPipeline.value].states;
        //_.filter(saleStates, ss => ss.pipeline == selectedPipeline.value);

        if(pipelineData) {
            pipelineData.some(function(el) {
                if(el.count > 0) {
                    empty = false;
                    return true;
                }
            });
        }

        this.chartColors = this.generateColors(this.state.startColor, this.state.endColor, _.keys(pipelineData).length)

        return (
            
            <div className={"grid-container content-block " + (!open && "closed")} id="overview-pipeline">
                { (empty && open) ? <div className="grid-item-second overlayDiv">
                    <div className = {"overlay"} >
                        <PipelineGraphic className="svg"/>
                        {
                            privileges.projects && privileges.projects.write ? 
                                <p>{this.tr("Your pipeline is empty.")} <a 
                                    onClick={e => (e.button !== 1) && this.addLead(e)} 
                                    onMouseUp={e => (e.button === 1) && this.addLead(e)}>
                                    {this.tr("Add lead")}
                                </a></p>
                            : <p>{this.tr("There are no leads")}</p>
                        }
                    </div>
                </div> : undefined }

                <div className="header grid-item">
                        <Button className="button" size="small" variant="text" onClick={this.handleToggle}>{this.tr("Pipeline Overview")} {!open ? <ExpandMore /> : <ExpandLess />}</Button>
                        <DataList
                            className="button datalist"
                            options={autoCompleteData.pipelines}
                            value={selectedPipeline}
                            label={this.tr("Pipeline")}
                            onChange={this.switchPipeline}
                            
                        />

                       {/* <AdvancedSearch
                            ref = {this.advancedSearch}
                            fields = {advancedSearchFields}
                            noRequests = {true}
                            onSearchTrigger={(searchTerms) => {
                                this.searchTerms = searchTerms;
                                this.handleData(searchTerms);
                            }}
                            onSearchResult={this.setData}
                        />
                       */}
                {
                     //   <Button className="button" size="small" variant="text" id="rb">Sales insights {<ChevronRight />}</Button>
                }
                </div>
                <br/>
                <div className="grid-item-second">
                {open && (
                    <React.Fragment>
                        {
                            // <div ref={this.element} className={`wrapper ${this.state.elementWidth <= 1230 ? "divider" : ""}`}  >
                        }
                        <div ref={this.element} className={`wrapper`}>
                            <div className="chart-area">
                                <div key={"chart"} className="pipeline-chart" ref={this.refChart}></div>
                            </div>
                            <div className="summary-area">
                                <Table className="summary">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="indicator"></TableCell>
                                            <TableCell className="title">{this.tr("Pipeline")}</TableCell>
                                            <TableCell className="title">{this.tr("Revenue")}</TableCell>
                                            <TableCell className="title">{this.tr("Margin")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(pipelineData, ss => {
                                                return (
                                                <TableRow className="row">
                                                    <TableCell className="indicator">
                                                        <div className="ball-indicator" style={{backgroundColor: this.chartColors[i++]}}></div>
                                                    </TableCell>
                                                    <TableCell className="label" >
                                                        {ss.label}<div className="sub-label">{ss.count} {this.tr("Projects")}</div>
                                                    </TableCell>
                                                    <TableCell className="number-value" >{currencyFormatter(ss.revenue)}</TableCell>
                                                    <TableCell className="number-value" >{currencyFormatter(ss.margin)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        <TableRow className="row">
                                            <TableCell className="indicator">
                                                <div className="ball-indicator" style={{backgroundColor: "#000000" }}></div>
                                            </TableCell>
                                            <TableCell className="label">{this.tr("Total")}
                                                <div className="sub-label">{_.sumBy(pipelineData, 'count')} {this.tr("Projects")}</div> </TableCell>
                                            <TableCell className="number-value">{currencyFormatter(_.sumBy(pipelineData, 'revenue'))}</TableCell>
                                            <TableCell className="number-value">{currencyFormatter(_.sumBy(pipelineData, 'margin'))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                </div>
            </div>
        )
    }
}

export default Pipeline;