import React from 'react';
import ListRow from "../list/ListRow";
import ListCell from "../list/ListCell";
import TextInputCell from "../list/cells/TextInputCell";
import Utils from "../general/Utils";
import { formatInputNumber } from '../helpers';
import { SettingsContext } from './../SettingsContext';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import ContextMenu from '../general/ContextMenu';
import { Button, MenuItem, TextField } from '@mui/material';
import "./BillRow.css";

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';

class BillRow extends ListRow {
    static contextType  = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            onChange: () => {},
            editMode: false
        }
    };


    constructor(props, context) {
        super(props, {},{}, "bills/BillRow");

        this.context = context;
        this.editRow = this.editRow.bind(this);
    }


    // TODO: abstract
    defineClassName() {
        return "billRow";
    }


    defineCells() {
        const { currencyRate } = this.props.rowProps;
        const noCurrencySums = !currencyRate || currencyRate == "1.000000";

        const commonProps = {
            textAlign: "right",
            onEdited: this.editRow,
            // runOnEditedOnInput: true
        };

        const editModeProps = {
            listCellProps: {
                editable: this.props.rowProps.editMode || this.state.data.id < 0,
                inEditMode: this.props.rowProps.editMode || this.state.data.id < 0
            }
        };

        const vatEditModeProps = {
            listCellProps: {
                editable: (this.props.rowProps.editMode || this.state.data.id < 0) && (this.context.addons && (this.context.addons?.heeros?.used_by_companies || []).indexOf(this.props.rowProps.company) == -1),
                inEditMode: (this.props.rowProps.editMode || this.state.data.id < 0) && (this.context.addons && (this.context.addons?.heeros?.used_by_companies || []).indexOf(this.props.rowProps.company) == -1)
            }
        };

        // const totalNoVat = this.numberFormat

        let cells = {
            context: 
                !this.props.rowProps.editMode ? <ListCell width={this.props.columnWidthMap.context} editable={false} /> : <ContextMenu className="cell row-menu" label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} style={{width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                    <MenuItem  className="delete" onClick={() => {this.delete()}}><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem>
                </ContextMenu>,
            description:
                <TextInputCell
                    {...editModeProps}
                    value={this.state.data.description}
                    runOnEditedOnInput={true}
                    onEdited={value => {
                        this.setData("description", value);
                    }} />,
            quantity:
                <TextInputCell
                    {...editModeProps}
                    {...commonProps}                   
                    name="quantity"
                    validation={["numeric"]}
                    value={formatInputNumber(this.state.data.quantity)} />,
            unit_price: 
                <TextInputCell
                    {...editModeProps}
                    {...commonProps}
                    name="unit_price"
                    validation={["numeric"]}
                    value={formatInputNumber(noCurrencySums ? this.state.data.unit_price : this.state.data.currency_unit_price )} />,
            total_no_vat: 
                <TextInputCell
                    {...commonProps}
                    name="total_no_vat"
                    editable={false}
                    value={formatInputNumber(noCurrencySums ? this.state.data.total_no_vat : this.state.data.currency_total_no_vat)} />,
            vat: 
                <TextInputCell 
                    {...vatEditModeProps}
                    {...commonProps}
                    name="vat"
                    validation={["numeric"]}
                    value={formatInputNumber(this.state.data.vat)} />,
            total: 
                <ListCell 
                    {...commonProps}
                    name="total"
                    editable={false}
                    value={formatInputNumber(noCurrencySums ? this.state.data.total : this.state.data.currency_total)} />,
        };

        return cells;
    }


    editRow(name, value) {
        const noCurrencySums = !this.props.rowProps.currencyRate || this.props.rowProps.currencyRate == "1.000000";
        let currencyRate = Number(this.props.rowProps.currencyRate);
        if (!currencyRate)
            currencyRate = 1;

        if (name == "unit_price" && !noCurrencySums) {
            name = "currency_unit_price";
        }    

        this.setData(name, value, () => {
            let data = this.state.data;
            if (!noCurrencySums) {
                data.unit_price = data.currency_unit_price / currencyRate;
            }

            const vatMultip   = 1 + parseFloat(String(data.vat).replace(/\,/g, ".")) / 100;

            data.total_no_vat = Utils.truncateDecimals((parseFloat(String(data.quantity).replace(/\,/g, ".")) * parseFloat(String(data.unit_price).replace(/\,/g, "."))));
            data.total = Utils.truncateDecimals((vatMultip * String(data.total_no_vat).replace(/\,/g, ".")));

            if (!noCurrencySums) {
                data.currency_total_no_vat = Utils.truncateDecimals((parseFloat(String(data.quantity).replace(/\,/g, ".")) * parseFloat(String(data.currency_unit_price).replace(/\,/g, "."))));
                data.currency_total = Utils.truncateDecimals((vatMultip * String(data.currency_total_no_vat).replace(/\,/g, ".")));
            }
        
            this.setData(data);
            this.props.rowProps.onChange(data);
        });
    }
}

export default BillRow;
