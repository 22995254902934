import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { isEqual } from 'lodash';
import TaimerComponent from '../../../TaimerComponent';
import { ProposalContentSection } from '../types';
import $ from 'jquery';
import styles from '../ProposalEditor.module.css';

interface PDFSectionProps {
    section: ProposalContentSection;
    sectionIndex: number;
    loadingPDF: boolean;
}

export default class PDFSection extends TaimerComponent<PDFSectionProps> {
    state = {
        pageCounts: {},
        pdfImages: {},
    };

    shouldComponentUpdate = (oldProps: PDFSectionProps, oldState) => {
        return !isEqual(oldProps.section.content, this.props.section.content) || !isEqual(oldState.pageCounts, this.state.pageCounts) || !isEqual(oldState.pdfImages, this.state.pdfImages);
    };

    // Copied from inside react-pdf to divide our wanted scale with this value (a prop for Page).
    // Canvas is scaled dynamically inside react-pdf and we don't want that – we want our pdf pages to have the same size regardless of the device.
    _getPixelRatio = () => {
        return window?.devicePixelRatio || 1;
    };

    _onPDFLoadSuccess = (pageCount, key) => {
        const pageCounts = {
            ...this.state.pageCounts,
            [key]: pageCount,
        };
        this.setState({
            pageCounts,
        });
    };

    _onPDFRenderSuccess = (props) => {
        const { sectionIndex } = this.props;
        const { _pageIndex } = props;
        const parent: Element | null = document.querySelectorAll('.' + styles.contentSection)[sectionIndex];
        const importPDFCanvas: NodeListOf<HTMLCanvasElement> | null = parent?.querySelectorAll('.react-pdf__Page__canvas');
        const pdfAsImageSrc = importPDFCanvas[_pageIndex]?.toDataURL('image/jpeg', 0.8);
        const pdfImages = {
            ...this.state.pdfImages,
            [_pageIndex]: pdfAsImageSrc,
        };
        this.setState({ pdfImages });
    };

    render() {
        const { pageCounts, pdfImages } = this.state;
        const {
            section: { content, key },
            loadingPDF,
        } = this.props;
        let pages: any = [];
        for (let i = 1; i <= (pageCounts[key] || 0); i++) {
            const image = pdfImages[i - 1];
            pages.push(
                <div key={`${key}_${i}`} className={`${[styles.page, styles.pdf].join(' ')} ${loadingPDF ? styles.loading : ''}`}>
                    <Page width={800} scale={1 / this._getPixelRatio()} onRenderSuccess={this._onPDFRenderSuccess} pageNumber={i} renderTextLayer={false} renderAnnotationLayer={false} />
                    {image && <img src={image} />}
                    {loadingPDF && <img className={styles.loader} src={require('../../../dashboard/insights/img/loading.svg').default} />}
                </div>
            );
        }

        let imageUrl: any = undefined;
        if (!!content?.filename) {
            const params = {
                id: content?.filename,
                attachment: 'attachment',
                _auth: this.context.functions.getStorage().taimerToken,
            };
            imageUrl = this.context.functions.getDownloadPath() + `${$.param(params)}`;
        }

        return (
            <Document key={key} file={imageUrl || content} onLoadSuccess={({ numPages }) => this._onPDFLoadSuccess(numPages, key)}>
                {pages}
            </Document>
        );
    }
}
