import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import ProductList from '../../list/lists/ProductList';
import CPQList from '../../list/lists/CPQList';
import ProductCatalogList from '../../list/lists/ProductCatalogList';

class Products extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Products');
        const {
            privileges
        } = this.context;
        this.tabs = [
            {
                id: 'products',
                label: this.tr('Products'),
                hidden: !privileges.products,
            },
            {
                id: 'cpq',
                label: this.tr('CPQ'),
                hidden: !privileges.products,
            },
            {
                id: 'catalogs',
                label: this.tr('Catalogs'),
                hidden: !privileges.products,
            },
        ];
    }

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} hideTabs={!!this.props.catalog_id} additionalUpdateViewValues={{ catalog_id: undefined, catalog_name: undefined, catalog_company: undefined }} tabsAlwaysVisible>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'products':
                            return <ProductList {...this.props} />;
                        case 'cpq':
                            return <CPQList {...this.props} />;
                        case 'catalogs':
                            return <ProductCatalogList {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Products;
