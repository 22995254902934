import React from 'react';
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import './ProductSliderTabs.css';
import ProductDetailsInfo  from './ProductDetailsInfo';
import ProductSupplierDetails  from './ProductSupplierDetails';
import ProductInvoiceHistory  from './ProductInvoiceHistory';
import ProductProjectSalesSlider from './ProductProjectSalesSlider'; 
import ProductAttachmentsSlider from './ProductAttachmentsSlider';
import ProductEventLogSlider from './ProductEventLogSlider'


const ProductTabButton = props => {
  const { onClick, label, selected } = props;
  return (
    <div className="mode-button-container">
      <Button
        className="mode-button"
        style={{
          fontWeight: selected ? "bold" : "normal",
          opacity: selected ? 1 : 0.5
        }}
        onClick={onClick}
      >
        {label}
      </Button>
      {selected && <div className="selected-indicator" />}
     
    </div>
   
  );
};

const ProductSliderTabs = props => {
    const {tabs, selected,productSliderData, productSliderTabsData, enqueueSnackbar, saveProduct, getProductData, productCodes, tr} = props;
    if (!productSliderData)
      return <div></div>;

    const { product, open, onClose, currency, autoCompleteData } = productSliderData;

    return (
          <Drawer id="product-list-slider" anchor="right" open={open} onClose={onClose}>
            <div className="top-bar">
              <div className="left">
                <div>
                  <h2>{product.name}</h2>
                  <h3>{tr("Product Card")}</h3>
                </div>
              </div>
              <div className="right">
                <Button onClick={onClose}>
                  <Close />
                </Button>
              </div>
            </div>
            <div className="product-tabs-container">
                 {tabs.map(tab => {
                  return (
                    <ProductTabButton
                      selected={tab.key == selected}
                      label={tab.label}
                      onClick={tab.action}
                    />
                  )
                })} 

            </div> 
            <div className="product-slider-div">
              {selected == "product_info"    &&  <ProductDetailsInfo product={product} productCodes={productCodes} autoCompleteData={autoCompleteData} currency={currency} getProductData={getProductData} saveProduct={saveProduct} enqueueSnackbar={enqueueSnackbar} /> }
              {selected == "supplier_details" &&  <ProductSupplierDetails product={product} enqueueSnackbar={enqueueSnackbar} getProductData={getProductData} /> }
              {selected == "invoice_history"  &&  <ProductInvoiceHistory productId={product.id} height={450} /> }
              {selected == "project_sales"    &&  <ProductProjectSalesSlider productId={product.id} height={450}  />}
              {selected == "attachment"       &&  <ProductAttachmentsSlider product={product} height={750} enqueueSnackbar={enqueueSnackbar} />}
              {selected == "event_log"       &&  <ProductEventLogSlider productId={product.id} height={450} />}
            </div>
        </Drawer>
    );
}

export default ProductSliderTabs;