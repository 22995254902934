import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './ContactList.svg';

class ContactListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/ContactListOverlay');
    }

    render () {

        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.tr("Add your first Contact") }
                </div>
                <Button
                    color="primary"
                    onClick={() => this.context.functions.addContact({ companies_id: this.props.company })}
                >
                {this.tr("ADD CONTACT") }
                </Button>
             </div>
        );
    }

}

export default ContactListOverlay;