import React, { Component } from 'react';

/* css */

/* material-ui */

/* others */
import _ from 'lodash';
import DataList from './DataList';

/*
FilterDropdown

*/
export default class FilterDropdown extends Component {
	static defaultProps = {
		hideIfEmpty: false,
	}

	constructor(props) {
		super(props);
		this.refField = React.createRef();

		this.state = {
			inputValue: {}
		}

	}


	onChange = (value, { action }) => {
		const { items } = this.props;

		this.itemCreate = false;

		if (action === "select-option") {
			const selectedVal = items.find(x => x.id === value.value) || { id: 0, name: '' };

			const e = {
				target: {
					name: this.props.name,
					value: selectedVal,
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
		else if (action === "pop-value") {
			const e = {
				target: {
					name: this.props.name,
					value: { id: 0, name: '' },
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
		else if (action === "clear") {
			const e = {
				target: {
					name: this.props.name,
					value: { id: 0, name: '' },
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
		else if (action === "create-option") {
			const e = {
				target: {
					name: this.props.name,
					value: { id: 0, name: value.label },
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
	}

	itemCreate = false

	onInputChange = (value) => {
		const { allowCreate } = this.props;

		if (allowCreate && value) {
			this.itemCreate = { id: 0, name: value }
		}
	}

	onBlur = (value) => {
		const { allowCreate } = this.props;
		if (allowCreate && this.itemCreate) {
			const e = {
				target: {
					name: this.props.name,
					value: this.itemCreate,
				}
			}
			if (this.props.onChange)
				this.props.onChange(e);
		}

		this.props.onBlur && this.props.onBlur();
	}

	focus = () => {
		if (this.refField.current)
			this.refField.current.focus();
	}

	onFocus = () => {
		this.itemCreate = false;
		this.props.onFocus && this.props.onFocus();
	}

	render() {
		const { items = [], hideIfEmpty, hide, value, allowCreate } = this.props;

		const val = (value && (value.id || (allowCreate && value.name)) && {
			value: value.id,
			label: value.name,
		}) || { value: '', label: '' }

		// Use label from list when possible
		const it = items.find(i => i.id == val.value);

		if (it) {
			val.label = it.name;
		}

		if (hide || (hideIfEmpty && !val.value && !val.label && items.length === 0))
			return null;

		var options = [];

		items && items.forEach((v, i) => options.push({ value: v.id, label: v.name }));

		const { label, error, ...rest } = this.props;

		const textFieldProps = {
			label,
			error,
			InputLabelProps: {
				shrink: val ? true : undefined,
			}
		};

		return (<DataList {...rest} isDisabled={this.props.disabled} onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} options={options} onChange={this.onChange} onInputChange={this.onInputChange} value={val} textFieldProps={textFieldProps} shownCount={20} />)
	}
}