import React, { ReactNode } from 'react';
import TaimerComponent from '../../../TaimerComponent';
import ContextMenu from '../../../general/ContextMenu';
import { isEqual } from 'lodash';
import { Person, ProposalContentSection, ProposalContentSectionType, ProposalSectionOptions } from '../types';
import AddContentButton from '../AddContentButton';
import { Cached, Clear, Delete, InsertPhotoOutlined, MoreHoriz, Save } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import styles from '../ProposalEditor.module.css';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import DataHandler from '../../../general/DataHandler';

interface ProposalSectionProps extends WithSnackbarProps {
    section: ProposalContentSection;
    quoteId: string;
    setPageRef: (ref: any) => void;
    children: ReactNode;
    sectionIndex: number;
    sectionOptions: ProposalSectionOptions;
    sectionIcons: any;
    employees: Person[];
    contacts: Person[];
    templateMode?: boolean;
    onAddNewSection: (index: number, type: string) => void;
    onDeleteSection: (sectionIndex: number) => void;
    onAddTemplate: (sectionIndex: number, swapContent?: boolean, templateType?: ProposalContentSectionType) => void;
    onAddBackground: (sectionIndex: number, image: any) => void;
    onRemoveBackground: (sectionIndex: number) => void;
    onSaveAsTemplate: (sectionIndex: number) => void;
    setLoadingBGImage: (loading: boolean) => void;
    readMode: boolean;
}

class ProposalSection extends TaimerComponent<ProposalSectionProps> {
    _upload: React.RefObject<HTMLInputElement> = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/ProposalSection');
    }

    _getMenuItems = () => {
        const { section, sectionIndex, onDeleteSection, onRemoveBackground, onSaveAsTemplate, onAddTemplate, templateMode } = this.props;
        const menuItems: { icon: ReactNode; title: string; action: () => void; className?: string }[] =
            templateMode || section.type == 'quote'
                ? []
                : [
                      {
                          icon: <Cached />,
                          title: this.tr('Get template'),
                          action: () => onAddTemplate(sectionIndex, true, section.type),
                      },
                      {
                          icon: <Save />,
                          title: this.tr('Save as template'),
                          action: () => onSaveAsTemplate(sectionIndex),
                      },
                  ];
        if (section.type == 'cover') {
            menuItems.unshift({
                icon: <InsertPhotoOutlined />,
                title: section.backgroundImage ? this.tr('Change background') : this.tr('Add background'),
                action: () => this._addBackgroundImage(),
            });
            if (section.backgroundImage) {
                menuItems.unshift({
                    icon: <Clear />,
                    title: this.tr('Remove background'),
                    action: () => onRemoveBackground(sectionIndex),
                });
            }
        }

        if (!templateMode && section.type != 'quote') {
            menuItems.push({
                icon: <Delete />,
                className: styles.delete,
                title: this.tr('Delete section'),
                action: () => onDeleteSection(sectionIndex),
            });
        }

        return menuItems;
    };

    shouldComponentUpdate = (oldProps: ProposalSectionProps) => {
        return (
            oldProps.section.title !== this.props.section.title ||
            oldProps.sectionIndex != this.props.sectionIndex ||
            !isEqual(oldProps.section.content, this.props.section.content) ||
            !isEqual(oldProps.employees, this.props.employees) ||
            !isEqual(oldProps.contacts, this.props.contacts) ||
            oldProps.section.backgroundImage != this.props.section.backgroundImage ||
            !isEqual(oldProps.section.hiddenBlocks, this.props.section.hiddenBlocks) ||
            oldProps.section.hidden != this.props.section.hidden
        );
    };

    _addBackgroundImage = () => {
        this._upload.current && this._upload.current.click();
    };

    _uploadImage = (e) => {
        const { sectionIndex, onAddBackground, enqueueSnackbar, quoteId, setLoadingBGImage } = this.props;
        const {
            taimerAccount: { attachmentMaxSize },
        } = this.context;
        e.stopPropagation();
        e.preventDefault();
        const file = e.target.files[0];
        if (file.size > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: 'error',
            });
            return;
        }
        setLoadingBGImage(true);
        onAddBackground(sectionIndex, URL.createObjectURL(file));
        DataHandler.file({ url: `projects/proposals/${quoteId}/attachments` }, file, undefined, (e) => {
            if (e.responseJSON === false) {
                enqueueSnackbar(this.tr('Uploading file failed'), {
                    variant: 'error',
                });
                onAddBackground(sectionIndex, undefined);
                setLoadingBGImage(false);
                return;
            }
            setTimeout(() => {
                const filename = e.responseJSON.filename;
                onAddBackground(sectionIndex, { filename, localFile: URL.createObjectURL(file) });
            }, 1000);
        });
    };

    render() {
        const { setPageRef, section, children, sectionIndex, sectionOptions, sectionIcons, onAddNewSection, onAddTemplate, templateMode, readMode } = this.props;
        const menuItems = this._getMenuItems();
        return (
            <div ref={setPageRef} key={section.key} className={`${styles.contentSection} ${section.type == 'cover' ? styles.cover : ''}`}>
                <div className={section.hidden ? styles.hidden : ''}>
                    <div className={styles.header}>
                        <h3>{section.title}</h3>
                        <input ref={this._upload} onChange={this._uploadImage} type="file" accept="image/png,image/jpeg" />
                        {menuItems.length > 0 && !readMode && (
                            <ContextMenu
                                disablePortal={false}
                                //@ts-ignore
                                buttonProps={{ className: styles.optionMenuButton }}
                                noExpandIcon
                                customGrowId={styles.optionMenuGrow}
                                className={styles.optionMenu}
                                label={<MoreHoriz />}
                                size="medium"
                                placement="bottom-end"
                            >
                                {menuItems.map((item) => {
                                    return (
                                        <MenuItem className={item.className} onClick={item.action}>
                                            {item.icon}
                                            {item.title}
                                        </MenuItem>
                                    );
                                })}
                            </ContextMenu>
                        )}
                    </div>
                    {children}
                </div>
                {!templateMode && !readMode && (
                    <AddContentButton
                        templateTitle={this.tr('Template')}
                        onAddTemplate={onAddTemplate}
                        sectionIndex={sectionIndex}
                        showSeparatorLines={true}
                        index={sectionIndex}
                        items={Object.values(sectionOptions)}
                        icons={sectionIcons}
                        onItemClick={onAddNewSection}
                        title={this.tr('Add new section')}
                    />
                )}
            </div>
        );
    }
}

export default withSnackbar<ProposalSectionProps>(ProposalSection);
