import React from 'react';

import ListRow from "../ListRow";
import ListCell from "../ListCell";
import DateCell from "../cells/DateCell";
import LinkListCell from "../LinkListCell";
import TextInputCell from "../cells/TextInputCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import AutoCompleteCell from '../cells/AutoCompleteCell';
import moment from 'moment';
import CheckboxCell from "../cells/CheckboxCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import { getWorktypesForProject } from '../../Data';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import ContextMenu from "../../general/ContextMenu";
import { ReactComponent as AccountIcon } from '../../navigation/NavIcons/customers.svg';
import { ReactComponent as ProjectIcon } from '../../navigation/NavIcons/leads.svg';
import { ReactComponent as RemoveIcon } from './../../general/icons/remove.svg';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Tooltip } from "@mui/material";
import _ from 'lodash';
import colors from '../../colors';

class ApprovalsListRow extends PropsOnlyListRow {
    
    constructor(props) {
		super(props, { checked: false }, {}, 'list/rows/ApprovalsListRow');
    }
    
    defineClassName() {
		return "approvalsListRow";
	}

	cellEdited(name, value) {
		let data = _.cloneDeep(this.props.data);
		if (data[name] == value)
			return;

		if (name === "description" || name === "overtime_description"){
			this.descriptionEdited(name, value);
			return;
		}

		if (name === "starttime" || name === "endtime") {
			const starttime = moment(data.timestart).format("HH:mm:ss");
			const endtime = moment(data.timeend).format("HH:mm:ss");
			const time = moment(value, "HH:mm:ss");

			// Return if not changed
			if ((name === "starttime" && starttime == time.format("HH:mm:ss")) || (name === "endtime" && endtime == time.format("HH:mm:ss")))
				return;

			if (!time._isValid) {
				this.showFieldError(name, value, this.tr("Invalid time!"));
				return;
			}

			const oldtime = name === "starttime" ? endtime : starttime;
			const newtime = time.format("HH:mm:ss")

			if ((name === "starttime" && newtime >= oldtime) || (name === "endtime" && newtime <= oldtime)) {
				this.showFieldError(name, value, this.tr("Starttime cannot be later than endtime!"));
				return;
			}

			data[name] = time.format('HH:mm:ss');
		}
		else if (name === "date") {
			data[name] = moment(value).format('YYYY-MM-DD');
		}

		this.props.rowProps.onEdited(data.id, name, data[name]);
	}

	showFieldError(name, value, message) {
		this.props.rowProps.enqueueSnackbar(message, {
			variant: "error",
		});
		let data = {};
		data["_invalidField_" + name] = true;
		this[name] = value;
		this.setData(data);
	}

	updateWorkTypes = async (newProjectId = false) => {
		const { data } = this.props;
		let worktypes = [];
		const projectId = newProjectId ? newProjectId : data.project_id;
		let workTypeFound = true;

		try {
			worktypes = await getWorktypesForProject(projectId);
		} catch (e) {
			console.log(e)
		}

		if (!worktypes.find(w => w.id == data.worktasks_id)) {
			workTypeFound = false;
			!newProjectId && worktypes.unshift({id: data.worktasks_id, name: data.worktask_name, label: data.worktask_name });
		}

		this.setData({worktypes});
		return workTypeFound;
	}

	projectEdited = async (project = {}) => {
		const { data, sharedData: { allProjects } } = this.props;
		let worktypeFound = false;
		if (project.value == data.project_id) {
			return;
		}

		try {
			worktypeFound = await this.updateWorkTypes(project.value);
		} catch (e) {
			console.log(e)
		}
		const invalids = [];

		if (!worktypeFound) {
			this.props.rowProps.enqueueSnackbar(this.tr("Worktype ${worktype} cannot be used on project ${project}. Please select another.", {
				worktype: data.worktask_name,
				project: project.label,
			}), {
				variant: "info",
			});

			this.setInvalidFields("jobtype");
			invalids.push('jobtype');
			const change = {
				project_id: project.value,
				worktask_name: "",
				wh_projects_resource_id: 0,
				projectNotSaved: true
			};

			this.setData(change);
		}
		else {
			this.props.rowProps.onEdited(data.id, "project", project.value);
			this.setInvalidFields(invalids);
			this.setData({projectNotSaved: false, wh_projects_resource_id: 0});
		}
	}

	descriptionEdited = (name, value) => {
		const { overtimeSettings, enqueueSnackbar } = this.props.rowProps;
		const { functions: { getTimeTrackerSettings } } = this.context;
		const timeTrackerSettings = getTimeTrackerSettings();
		
		if (name === "description" &&  timeTrackerSettings.hour_entry_description && (!value || value.toString().trim() === "")) {
			this.showFieldError(name, value, this.tr("Description cannot be empty."));
			return;
		}
		else if (name === "overtime_description" &&  
				overtimeSettings.activate_overtime_tracking == "1" && 
				overtimeSettings.overtime_description_mandatory == "1" &&
				(!value || value.toString().trim() === "")) {

				this.showFieldError(name, value, this.tr("overtime description is mandatory."));
			return;
		}

		this.props.rowProps.onEdited(this.props.data.id, name, value);
	}

	deleteClick = async (e) => {
		e.stopPropagation();

		this.delete();
	}

	createDisableableMenuItem = (text, disableInfoMsg = "", onClickFunc = () => {}, disabled = false, Icon = undefined, className = "", iconClass = "") => {
		return disabled ? (
			<Tooltip title={disableInfoMsg} placement="right">
				<div>
					<MenuItem disabled={true} className={className} onClick={() => {}}>
						<Icon className={iconClass} title="" />{text}
					</MenuItem>
				</div>
			</Tooltip>
		) : (			
			<MenuItem className={className} onClick={onClickFunc}>
				<Icon className={iconClass} title="" />{text}
			</MenuItem>
		)
	}

	defineCells() {
		const { rowProps: { tr }, sharedData: { projects_resources, accounts, allAccounts, allProjects, projects } } = this.props;
		const { addons } = this.context;
		const { data } = this.props;
		const start = moment(data.timestart);
        const end = moment(data.timeend);
		const status_date = moment(data.status_date);

		const userId = Number(data.users_id);

		const resources = projects_resources.filter(el =>
			el.id == 0 ||
			el.id == data.wh_projects_resource_id || 
			(
				(el.user_ids?.includes(userId) || el.allow_all) && 
				(el.projects_id == data.project_id && el.done != 1)
			)
		);
		
		const customersProjects = projects.filter(e => e.customers_id == data.customer_id);
		const editable = data.editable == "1";
		const approvable = data.approvable == "1";
		const deleteAllowed = data.delete_permission == "1" && data.delete_allowed == "1";

		const statusOptions = [
            {id: 1, name: "approved", color: colors.greenish_cyan, value: 1, label: tr("approved")},
            {id: 0, name: "waiting", color: "#ffb822", value: 0, label: tr("waiting")},
			{id: -1, name: "declined", color: "#ff5722", value: -1, label: tr("declined")}
		];
		
		const submittedStatusOption = {id: 2, name: "Submitted", color: "#716ACA", value: 2, label: tr("Submitted")};

        let type = "";
        let is_overtime = false;

        if(data.project_type === "3") {
            type = tr("vacation");
        } else if(data.is_overtime !== "0") {
            type = tr("overtime");
            is_overtime = true;
        } else {
            type = tr("normal");
		}

 		const cells = {
			actions:
				<ListCell
					editable={false}
					onlyDisplay>
						<ContextMenu className="row-menu" label={<ContextMenuIcon />}
							buttonProps={{ className: 'action-menu' }} noExpandIcon>
							<MenuItem key={1} noClose={false} onClick={() => this.context.functions.updateView({module: "customers", action: "view", id: data.customer_id})} >
								<AccountIcon />{this.tr("View account")}
							</MenuItem>
							<MenuItem key={2} noClose={false} onClick={() => this.context.functions.updateView({module: "projects", action: "view", id: data.project_id})} >
								<ProjectIcon />{this.tr("View project")}
							</MenuItem>
							{addons.delete_user_workhours && this.createDisableableMenuItem(
								this.tr("Delete"), 
								data.delete_allowed == "0" && data.delete_permission == "1" ? this.tr("Workhour cannot be deleted anymore") : this.tr("Workhour cannot be deleted: No permission"),
								this.deleteClick,
								!deleteAllowed,
								RemoveIcon,
								'delete',
								'delete'
							)}
				 		</ContextMenu>
				</ListCell>,
			checked:
                <CheckboxCell checked={this.props.checked} onClick={() => this.props.listRef.check(this.props.data.id)} />,
            user:
                <ListCell
                    name="user"
                    value={data.user_name || ''}
					editable={false} />,
			team:
				<ListCell
					name="team"
					value={data.team_name || ''}
					editable={false} />,
            date:
				<DateCell
					name="date"
					value={start  || ''}
					onEdited={this.cellEdited} 
					editable={editable}/>,
			customer:
				<AutoCompleteCell
					name="customer"
					value={allAccounts.find(a => a.value == data.customer_id)}
					autoCompleteData={accounts}
					noTab={true}
					urlHandler={() => `index.php?module=customers&action=view&id=${data.customer_id}`}
					onEdited={value => {
						this.setData({customer_id: value.value, project_id: 0});
						this.setInvalidFields(["project"]);
					}}
					editable={editable} />,
			project:
				<AutoCompleteCell
					name="project"
					autoCompleteData={customersProjects}
					value={allProjects.find(p => p.value == data.project_id)}
					urlHandler={() => `index.php?module=projects&action=view&id=${data.project_id}`}
					noTab={true}
					onEdited={project => {
						this.projectEdited(project);
					}}
					editable={editable} />,
			subproject:
				<ListCell
					name="subproject"
					value={data.subproject ? data.subproject : ''}
					editable={false} />,
			workphase: // TODO
				<ListCell
					name="workphase"
					value={data.workphase  || ''}
					editable={false} />,
			task:
				<AutoCompleteCell
					name="resource"
					value={data.wh_projects_resource_id == 0 ? undefined : resources.find(r => r.id == data.wh_projects_resource_id)}
					editable={editable}
					autoCompleteData={resources}
					searchable={false}
					onEdited={value => {
                        this.props.rowProps.onEdited(this.props.data.id, "task", value.id);
					}}
				/>,
			jobtype:
				<AutoCompleteCell
					name="jobtype"
					autoCompleteData={data.worktypes || []}
					disabled={data.project_id == 0 || !editable}
					value={data.worktypes ? data.worktypes.find(w => w.id == data.worktasks_id) : data.worktask_name}
					onEdited={value => {
						if (data.projectNotSaved) {
							this.props.rowProps.onEdited(this.props.data.id, "jobtype_project", {project: data.project_id, jobtype: value.id});
							this.setData({projectNotSaved: false});
						}
						else {
							this.props.rowProps.onEdited(this.props.data.id, "jobtype", value.id);
						}
						this.setInvalidFields([]);
					}}
					selectProps={{
						onFocus: () => {
							const projectId = data.projectNotSaved ? data.project_id : false;
							this.updateWorkTypes(projectId);
						}
					}}
					editable={data.project_id != 0 && editable} />,
			description:
				<TextInputCell
					name="description"
					value={this.props.data['_invalidField_description'] ? this.description : data.description}
					onEdited={this.cellEdited}
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_description'],
					}}
                    editable={editable} />,
            overtime_description:
				<TextInputCell
					name="overtime_description"
					value={data.overtime_description || ''}
					onEdited={this.cellEdited}
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_overtime_description'],
					}}
                    editable={editable && data.is_overtime == "1"} />,
            type:
                <ListCell
                    name="type"
                    value={type}
                    editable={false} />,
            status:
                <EditableStatusCell
                    editable={approvable}
                    options={statusOptions}
					value={parseInt(data.status)}
					displayValue={data.status == 2 ? submittedStatusOption : null}
                    onEdited={status => {
                        this.props.rowProps.onEdited(this.props.data.id, "status", status.value + "");
                    }} />,
			starttime:
				<TextInputCell
					name="starttime"
					inputType="text"
					value={this.props.data['_invalidField_starttime'] ? this.starttime : start.format('LT')}
					editValue={this.props.data['_invalidField_starttime'] ? this.starttime : start.format('HH:mm')}
					onEdited={this.cellEdited}
					editable={editable}
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_starttime'],
					}}
					/>,
			endtime:
				<TextInputCell
					name="endtime"
					inputType="text"
					value={this.props.data['_invalidField_endtime'] ? this.endtime : end.format('LT')}
					editValue={this.props.data['_invalidField_endtime'] ? this.endtime : end.format('HH:mm')}
					onEdited={this.cellEdited}
					editable={editable}
					listCellProps={{
						showErrorBorder: this.props.data['_invalidField_endtime'],
					}}
					/>,
			hours: <ListCell
				name="hours"
				value={data.hours.toFixed(2)}
				textAlign="right"
                editable={false}  />,
            overtime_hours: <ListCell
				name="overtime_hours"
				value={is_overtime ?  data.overtime_hours.toFixed(2) : ""}
				editable={false}  />,
            overtime_multiplier: <ListCell
				name="overtime_multiplier"
				value={is_overtime ? data.overtime_multiplier : ""}
				editable={false}  />,
            status_date: <DateCell
				name="status_date"
				value={status_date}
                editable={false} />,
            status_changed_by: <ListCell
				name="status_changed_by"
				value={data.status_changed_by}
				editable={false}  />
		};

		return cells;
	}
}

export default ApprovalsListRow;
