import React from 'react';
import moment from 'moment';
import { cloneDeep, sumBy } from 'lodash';
import { addDays, parse, format } from 'date-fns';
import { DateRangePicker } from './react-date-range/src';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Refresh, Repeat } from '@mui/icons-material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import DataHandler from './DataHandler';
import TreeDropdown from './TreeDropdown';
import WithTabs from '../navigation/WithTabs';
import TaimerComponent from '../TaimerComponent';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';
import ResourceDialogUsers from '../dialogs/ResourceDialogUsers';
import ProjectTreeDropdown from '../projects/ProjectTreeDropdown';
import SplitTaskDialog from '../resourcing/dialogs/SplitTaskDialog';
import { getAutocompleteDataForDialog } from '../resourcing/helpers';
import ConfirmationDialog from '../settings/dialogs/ConfirmationDialog';
import ResourceRecurrenceDialog from '../dialogs/ResourceRecurrenceDialog';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import { CustomOptionPriority, SingleValueInfo, SingleValuePriority } from '../dialogs/ResourceDialog';

import styles from './AddResourceSlider.module.scss';
import { getTasks } from '../resourcing/resourcing';
import { AddProject } from './no-options/AddItemComponents';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    singleProject?: boolean;
    massEditMode?: boolean;
    massEditProjectIds?: any;
    onProjectRangeExtended?: (start: Date, end: Date) => void;
    mode?: 'task' | 'milestone';
    afterSaveCallback?: () => void;
    showLockedUsersWithTag?: boolean;
}

interface State {
    resource: any;
    open: boolean;
    companies: any[];
    selectedProjects: any[];
    projectInvalid?: boolean;
    timeInvalid?: boolean;
    userHoursInvalid?: boolean;
    maxhoursInvalid?: boolean;
    skills: any[];
    priorities: any[];
    autoCompleteData: any;
    tasks: any[];
    mode: 'task' | 'milestone';
    usersHours: any[];
    employees: any[];
    teams: any[];
    showRecurrenceDialog?: boolean;
    moveSubtasks?: boolean;
    showConfirmationDialog?: boolean;
    confirmationRecurringTask?: boolean;
    confirmationDialogText?: string;
    confirmationDialogSaveFunc?: () => void;
    confirmationDialogRadio?: any;
    confirmationDialogExtra?: any;
    showSplitDialog: boolean;
}

class AddResourceSlider extends TaimerComponent<Props, State> {
    lastHours: any;
    lastUsersHours: any;
    assignedAmount = 0;
    assignedNoPermissionAmount = 0;
    allTasks: any = [];
    tabs = [
        {
            id: 'task',
            label: this.tr('Task'),
        },
        {
            id: 'milestone',
            label: this.tr('Milestone'),
        },
    ];

    constructor(props, context) {
        super(props, context, 'general/AddResourceSlider');
        const resource = {
            id: -1,
            starttime: new Date().getHours() + ':00',
            endtime: new Date().getHours() + 1 + ':00',
            time: moment().startOf('hour').format('HH:mm'),
            projects_id: 0,
            hours: 0,
            users_id: props.mode == 'milestone' ? this.context.userObject.usersId : undefined,
            ...this.props.initialSelectionProps,
            companies_id: this.props.initialSelectionProps?.companies_id || this.context.userObject.companies_id,
            start_date: this.props.initialSelectionProps?.start_date ? moment(this.props.initialSelectionProps?.start_date).toDate() : new Date(),
            end_date: this.props.initialSelectionProps?.end_date ? moment(this.props.initialSelectionProps?.end_date).toDate() : addDays(new Date(), 7),
        };
        this.lastHours = resource.hours;
        this.lastUsersHours = [];
        this.state = {
            resource,
            open: false,
            companies: [],
            selectedProjects: [],
            skills: [],
            priorities: [],
            autoCompleteData: {},
            tasks: [],
            mode: this.props.initialSelectionProps?.type || this.props.mode || 'task',
            usersHours: [],
            employees: [],
            teams: [],
            showSplitDialog: false,
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getCompanies();
            this.getAutoCompleteData();
            this.getTeams();
            if (this.state.resource.projects_id) {
                this.getTasks();
            }
        }

        if (oldState.resource.projects_id != this.state.resource.projects_id) {
            this.getTasks();
        }

        if (oldState.resource.companies_id != this.state.resource.companies_id) {
            this.setState(
                {
                    resource: {
                        ...this.state.resource,
                        projects_id: 0,
                        skills_id: 0,
                        priorities_id: 0,
                    },
                    selectedProjects: [],
                    usersHours: [],
                    userHoursInvalid: false,
                    maxhoursInvalid: false,
                },
                () => {
                    this.getAutoCompleteData();
                    this.getTeams();
                }
            );
        }
    };

    getCompanies = async () => {
        const companies = await DataHandler.get({ url: `subjects/companies_with_project_right/project_resourcing_write+own_resourcing_write` });
        this.setState({ companies: companies.map((c) => ({ ...c, label: c.name, value: c.id })) });
    };

    onProjectsFetched = (projects) => {
        if (this.state.resource?.projects_id && this.state.selectedProjects.length == 0) {
            const foundProject = projects.find((p) => p.id == this.state.resource?.projects_id);
            if (foundProject) {
                const selectedProjects = cloneDeep(this.state.selectedProjects);
                selectedProjects.push(foundProject);
                this.setState({ selectedProjects });
            }
        }
    };

    getTeams = async () => {
        const teams = await DataHandler.get({ url: 'settings/usergroups', type: 'teams', companyId: this.state.resource.companies_id });
        this.setState({
            teams: teams.map((x) => ({
                id: Number(x.id),
                name: x.name,
                type: Number(x.type),
                companies_id: Number(x.companies_id),
                users: x.users ? x.users.map((y) => Number(y)) : [],
            })),
        });
    };

    getTasks = async () => {
        const { resource, selectedProjects } = this.state;
        if (!resource.projects_id || resource.projects_id == 0 || selectedProjects.length > 1) {
            this.setState({ tasks: [] });
            return;
        }
        const taskData = await getTasks({ company: resource.companies_id }, { projectsId: resource.projects_id });
        const { resources } = taskData;
        let disabledMap = {};
        const tasks: any[] = (resources || [])
            .filter((el) => {
                return el.type === 'task' && el.task_type == 0 && el.id != resource.id;
            })
            .sort((a: any, b: any) => {
                const aP = a.original_parent ? a.original_parent : '';
                const bP = b.original_parent ? b.original_parent : '';
                return aP.localeCompare(bP);
            })
            .map((el: any) => {
                el.id = el.id.substr(2);
                el.name = el.label = el.description;
                if (typeof el.original_parent == 'string') {
                    if (el.original_parent.substr(0, 1) === 'p') {
                        el.original_parent = undefined;
                    } else {
                        el.original_parent = el.original_parent.substr(2);
                    }
                }
                const isDisabled = disabledMap[el.id] || disabledMap[el.original_parent];

                if (isDisabled) {
                    disabledMap[el.id] = true;
                    if (el.original_parent) {
                        disabledMap[el.original_parent] = true;
                    }
                    return false;
                }

                return { data: el, id: el.id, name: el.name, label: el.name, original_parent: el.original_parent, projects_id: el.projects_id };
            })
            .filter((el) => {
                return el !== false;
            })
            .sort((a: any, b: any) => {
                const aN = a.name ? a.name : '';
                const bN = b.name ? b.name : '';
                return aN.localeCompare(bN);
            });
        tasks.unshift({ data: {}, id: 0, name: this.tr('Not selected'), label: this.tr('Not selected'), original_parent: 0 });
        this.setState({ tasks });
    };

    getAutoCompleteData = async () => {
        const { resource } = this.state;
        const autoCompleteData = await getAutocompleteDataForDialog(resource.companies_id, false, {freelancer: this.tr("freelancer"), locked: this.tr("locked")});
        const skills = autoCompleteData.skills.map((el) => ({ ...el, value: el.id, label: el.name, info: el.description }));
        const priorities = autoCompleteData.priorities.map((el) => ({ ...el, value: el.id, label: this.tr(el.name) }));
        const employees = autoCompleteData.employees;
        const defaultSkill = (resource.skills_id ? skills.find((el) => el.id == resource.skills_id) : skills.find((el) => el.is_default))?.id;
        const defaultPriority = (resource.priorities_id ? priorities.find((el) => el.id == resource.priorities_id) : priorities.find((el) => el.is_default))?.id;
        this.setState(
            {
                autoCompleteData,
                skills,
                priorities,
                employees,
                resource: {
                    ...this.state.resource,
                    skills_id: defaultSkill,
                    priorities_id: defaultPriority,
                },
            },
            this.setUsersHours
        );
    };

    setUsersHours = () => {
        const { resource, employees } = this.state;
        let usersHours;
        if (resource.users_hours) {
            usersHours = resource.users_hours
                .filter((el) => !(el.deleted > 0))
                .map((el) =>
                    Object.assign(
                        el,
                        employees.find((e) => el.users_id == e.id),
                        { id: el.id }
                    )
                );
        } else {
            usersHours = [];
        }
        this.setState({ usersHours });
        this.lastUsersHours = usersHours;
    };

    setValue = (key, value) => {
        if (key == 'hours') {
            this.lastHours = value;
        }
        this.setState({
            resource: {
                ...this.state.resource,
                [key]: value,
            },
        });
    };

    setMultipleValues = (values, callback) => {
        const resource = {
            ...this.state.resource,
            ...values,
        };
        this.setState(
            {
                resource,
            },
            callback
        );
    };

    changeTime = (startString, endString) => {
        const resource = cloneDeep(this.state.resource);
        let usersHours = this.state.usersHours;
        let start: any = moment(startString, 'HH:mm');
        let end: any = moment(endString, 'HH:mm');
        start = start.isValid() ? start : undefined;
        end = end.isValid() ? end : undefined;
        const timeInvalid = !start || !end || end < start;
        if (!timeInvalid) {
            const hours = (end - start) / (1000 * 60 * 60);
            usersHours = usersHours.map((el) => ({ ...el, hours: hours, invalid: false }));
            const count = usersHours.filter((el) => !el.deleted || el.deleted < 1).length;
            resource.hours = hours * count;
        }
        resource.starttime = timeInvalid ? false : start.format('HH:mm');
        resource.endtime = timeInvalid ? false : end.format('HH:mm');
        this.setState({ resource, usersHours: usersHours, timeInvalid, userHoursInvalid: false, maxhoursInvalid: false });
    };

    changeDateRange = (range) => {
        const { resource, usersHours } = this.state;

        const start = range.range1.startDate;
        const cmpStart = new Date(start);
        cmpStart.setHours(0, 0, 0, 0);

        const end = range.range1.endDate;
        const cmpEnd = new Date(end);
        cmpEnd.setHours(0, 0, 0, 0);
        //@ts-ignore
        const isOneDayTask = this.isOneDayTask({ start_date: cmpStart, end_date: cmpEnd });
        let callback;

        if (isOneDayTask) {
            callback = () => this.changeTime(resource.starttime, resource.endtime);
        } else {
            callback = () => {
                this.setState({
                    resource: {
                        ...this.state.resource,
                        hours: this.lastHours,
                    },
                    usersHours: usersHours.map((x) => ({
                        ...x,
                        hours: x.lastHours !== undefined ? x.lastHours : x.hours,
                    })),
                    timeInvalid: false,
                });
            };
        }

        this.setMultipleValues({ start_date: start, end_date: end }, callback);
    };

    hoursFromEntries = () => {
        const { usersHours } = this.state;

        const hours = sumBy(
            usersHours.filter((x) => !x.deleted),
            (x) => Number(x.hours) || 0
        );

        this.setValue('hours', hours);
        this.checkMaxHours(usersHours, undefined, hours);
    };

    rruleToHumanReadable = (rrule) => {
        const { userObject } = this.context;
        let defaulMonth = { value: 1, label: this.tr('Jan') };
        let data: any = {
            freq: 'yearly',
            interval: 1,
            ends: 'never',
            after: 1,
            monthday: 1,
            setPos: { value: 1, label: this.tr('First') },
            month: defaulMonth,
            endDate: new Date(),
            selectedFormat: 'monthday',
            selectedWeekDays: {
                su: true,
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                'su,mo,tu,we,th,fr,sa': false,
                'mo,tu,we,th,fr': false,
                'su,sa': false,
            },
        };
        let rrules = rrule.split(';');
        let format = 'monthday';
        let weekdays = [
            { value: 'su', label: this.tr('Sun') },
            { value: 'mo', label: this.tr('Mon') },
            { value: 'tu', label: this.tr('Tue') },
            { value: 'we', label: this.tr('Wed') },
            { value: 'th', label: this.tr('Thu') },
            { value: 'fr', label: this.tr('Fri') },
            { value: 'sa', label: this.tr('Sat') },
            { value: 'su,mo,tu,we,th,fr,sa', label: this.tr('Day'), isComposite: true },
            { value: 'mo,tu,we,th,fr', label: this.tr('Weekday'), isComposite: true },
            { value: 'su,sa', label: this.tr('Weekend day'), isComposite: true },
        ];
        let months = [
            { value: 1, label: this.tr('Jan') },
            { value: 2, label: this.tr('Feb') },
            { value: 3, label: this.tr('Mar') },
            { value: 4, label: this.tr('Apr') },
            { value: 5, label: this.tr('May') },
            { value: 6, label: this.tr('Jun') },
            { value: 7, label: this.tr('Jul') },
            { value: 8, label: this.tr('Aug') },
            { value: 9, label: this.tr('Sep') },
            { value: 10, label: this.tr('Oct') },
            { value: 11, label: this.tr('Nov') },
            { value: 12, label: this.tr('Dec') },
        ];
        let onTheDays = [
            { value: 1, label: this.tr('First') },
            { value: 2, label: this.tr('Second') },
            { value: 3, label: this.tr('Third') },
            { value: 4, label: this.tr('Fourth') },
            { value: -1, label: this.tr('Last') },
        ];
        let endFormat = 'never';
        for (let i = 0; i < rrules.length; i++) {
            let split = rrules[i].split('=');
            let name = split[0].toLowerCase();
            let value = split[1].toLowerCase();
            switch (name) {
                case 'freq':
                    data.freq = value;
                    break;
                case 'interval':
                    data.interval = value;
                    break;
                case 'bysetpos':
                    format = 'countday';
                    data.setPos = onTheDays.find((el) => el.value == value);
                    break;
                case 'bymonth':
                    data.month = months.find((el) => el.value == value);
                    break;
                case 'bymonthday':
                    data.monthday = value;
                    break;
                case 'byday':
                    for (let j in data.selectedWeekDays) {
                        data.selectedWeekDays[j] = false;
                    }
                    if (data.selectedWeekDays[value] !== undefined) {
                        data.selectedWeekDays[value] = true;
                    } else {
                        let splittedDays = value.split(',');
                        for (let k = 0; k < splittedDays.length; k++) {
                            if (splittedDays[k]) data.selectedWeekDays[splittedDays[k]] = true;
                        }
                    }
                    break;
                case 'count':
                    endFormat = 'after';
                    data.after = value;
                    break;
                case 'until':
                    endFormat = 'onDate';
                    data.endDate = moment(value.toUpperCase()).toDate();
                    break;
            }
        }
        data.selectedFormat = format;
        data.ends = endFormat;
        let humanReadable = '';
        let weekdayTranslations: string[] = [];
        const freq = data.freq;

        if (freq != 'yearly') {
            humanReadable += 'interval=1';
        }
        humanReadable += 'freq=1';

        if (freq == 'yearly' || freq == 'monthly') {
            if (data.selectedFormat == 'monthday') {
                if (freq == 'yearly') {
                    humanReadable += 'bymonth=1';
                }
                humanReadable += 'bymonthday=1';
            }
            if (data.selectedFormat == 'countday') {
                if (data.selectedFormat == 'countday') {
                    for (let i in data.selectedWeekDays) {
                        if (data.selectedWeekDays[i]) {
                            weekdayTranslations.push(weekdays.find((el) => el.value == i)?.label || '');
                            break;
                        }
                    }
                }
                humanReadable += 'bysetpos=1';
                humanReadable += 'byday=1';
                if (freq == 'yearly') {
                    humanReadable += 'bymonth=1';
                }
            }
        }
        if (freq == 'weekly') {
            humanReadable += 'byday=1';
            let selected: any = [];
            for (let i in data.selectedWeekDays) {
                if (data.selectedWeekDays[i] && i.indexOf(',') == -1) {
                    selected.push(i);
                    weekdayTranslations.push(weekdays.find((el) => el.value == i)?.label || '');
                }
            }
        }

        if (data.ends == 'after') {
            humanReadable += 'count=1';
        }
        if (data.ends == 'onDate') {
            humanReadable += 'until=1';
        }

        let freqTranslations = {
            yearly: this.tr('year'),
            monthly: data.interval > 1 ? this.tr('months') : this.tr('month'),
            weekly: data.interval > 1 ? this.tr('weeks') : this.tr('week'),
            daily: data.interval > 1 ? this.tr('days') : this.tr('day'),
        };

        humanReadable = this.tr('Every') + ' ' + humanReadable;
        if (data.interval > 1) {
            humanReadable = humanReadable.replace(/interval=1/, data.interval);
        } else {
            humanReadable = humanReadable.replace(/interval=1/, '');
        }
        humanReadable = humanReadable.replace(/freq=1/, ' ' + freqTranslations[freq] + ' ');
        humanReadable = humanReadable.replace(/bymonth=1/, (data.selectedFormat == 'countday' ? ' ' + this.tr('of') + ' ' : '') + data.month.label + ' ');
        humanReadable = humanReadable.replace(/bymonthday=1/, data.monthday + '. ');
        humanReadable = humanReadable.replace(/bysetpos=1/, data.setPos.label.toLowerCase() + ' ');
        humanReadable = humanReadable.replace(/count=1/, ', ' + data.after + ' ' + this.tr('times'));
        humanReadable = humanReadable.replace(/until=1/, ', ' + this.tr('until') + ' ' + moment(data.endDate).format(userObject.dateFormat));
        humanReadable = humanReadable.replace(/byday=1/, weekdayTranslations.join(', ').toLowerCase() + ' ');
        humanReadable = humanReadable.replace(' , ', ', ');
        humanReadable = this.tr('Repeats') + ' ' + humanReadable;
        return humanReadable;
    };

    openRecurrenceDialog = () => this.setState({ showRecurrenceDialog: true });

    compareTeams = (t1, t2) => {
        const label1 = t1.label.toLowerCase();
        const label2 = t2.label.toLowerCase();

        if (label1 > label2) return 1;
        if (label2 > label1) return -1;

        return 0;
    };

    getSelectedProjects = () => {
        const { userObject } = this.context;
        const { resource, selectedProjects } = this.state;
        return resource.id == -1 && selectedProjects.length >= 1
            ? selectedProjects
            : resource.projects_id > 0
            ? [
                  {
                      id: Number(resource.projects_id),
                      value: Number(resource.projects_id),
                      name: resource.project_name,
                      label: resource.project_name,
                      companies_id: Number(resource.companies_id) || Number(userObject.companies_id),
                  },
              ]
            : [];
    };

    usersChanged = (usersHours, index) => {
        this.checkMaxHours(usersHours, index);
    };

    showVacationCheck = () => {
        return this.state.resource?.projects_type == 3 || ((this.state.selectedProjects || []).length > 0 && this.state.selectedProjects.every((x) => x.type == 3));
    };

    onProjectSelected = (e) => {
        const { resource, mode } = this.state;
        const { data = e } = e;

        const selectedProjects = cloneDeep(this.state.selectedProjects);

        if (resource.id == -1 && data.id > 0 && !selectedProjects.find((el) => el && el.id == data.id)) {
            selectedProjects.push(data.customers_name ? { ...data, label: `${data.customers_name} / ${data.name} (${data.project_id})` } : data);
        }

        const newResource = {
            ...this.state.resource,
            projects_id: data.id,
        };

        this.setState({
            projectInvalid: false,
            resource: newResource,
            selectedProjects,
        });

        if (this.context.taimerAccount.useSubresources && resource.id != -1 && mode === 'task') {
            DataHandler.get({ url: `resourcing/task/${resource.task_id}/subtasks_count` }).then((response) => {
                if (response.count > 0) {
                    this.setState({
                        projectInvalid: false,
                        showConfirmationDialog: true,
                        confirmationRecurringTask: false,
                        moveSubtasks: false,
                        confirmationDialogText: this.tr('Do you want to also move subtasks to new project?'),
                        confirmationDialogSaveFunc: () => {
                            this.setState({ moveSubtasks: true, showConfirmationDialog: false });
                        },
                        confirmationDialogExtra: {
                            cancelText: this.tr('No'),
                        },
                    });
                }
            });
        }
    };

    isProjectSelected = () => {
        if (this.props.massEditMode) return true;
        const projectSelected = this.state.selectedProjects.length > 0;
        this.setState({
            projectInvalid: !projectSelected,
        });
        return projectSelected;
    };

    renderUsers = (item) => {
        const canWrite = item.id == -1 || item.editable;
        const disableEdit = item.done || !canWrite;
        return (
            <ResourceDialogUsers
                className="full"
                teams={this.state.teams}
                projects={this.getSelectedProjects()}
                employees={this.state.employees}
                task={item}
                disableEdit={disableEdit}
                showVacationCheck={this.showVacationCheck()}
                userHours={this.state.usersHours}
                isOneDayTask={this.isOneDayTask(item)}
                onChange={this.usersChanged}
            />
        );
    };

    renderProjectTreeDrop = (item) => {
        const canWrite = item.id == -1 || item.editable;
        const disableEdit = item.done || !canWrite;
        return (
            <>
                <ProjectTreeDropdown
                    label={this.tr('Project') + ' *'}
                    required
                    value={item.projects_id}
                    treeDropdownProps={{
                        resetOnClickaway: true,
                        highlightMatches: true,
                        usePopper: true,
                    }}
                    onProjectsFetched={this.onProjectsFetched}
                    noOptionsMessage={AddProject}
                    noOptionsMessageProps={{
                        selectProps: {
                            companies_id: item.companies_id,
                            onItemCreated: this.onProjectSelected,
                            sliderWidth: 550,
                        },
                    }}
                    disabled={this.props.singleProject || disableEdit}
                    disableAutoSelect
                    error={this.state.projectInvalid}
                    customer={item.customer_name && { name: item.customer_name }}
                    project={item.project_name && { name: item.project_name }}
                    queryParameters={{
                        resource_note: 1,
                        right: 'project_resourcing_write own_resourcing_write',
                        includeCurrent: (item && item.projects_id) ?? -1,
                        company: item.companies_id,
                    }}
                    onSelect={this.onProjectSelected}
                />
                {item.id == -1 && !this.props.singleProject && this.state.selectedProjects.length > 0 && (
                    <div className={styles.selectedProjects}>
                        {this.state.selectedProjects.map((el) => {
                            return (
                                el && (
                                    <span key={el.id}>
                                        <span>{el.label}</span>
                                        <span
                                            className={styles.remove}
                                            onClick={() => {
                                                const newSelectedProjects = this.state.selectedProjects.filter((p) => p.id !== el.id);
                                                const projects_id = newSelectedProjects.length == 0 ? undefined : item.projects_id;
                                                this.setState({
                                                    selectedProjects: newSelectedProjects,
                                                    resource: {
                                                        ...this.state.resource,
                                                        projects_id,
                                                    },
                                                });
                                            }}
                                        >
                                            X
                                        </span>
                                    </span>
                                )
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    renderParentTaskDrop = (item) => {
        const canWrite = item.id == -1 || item.editable;
        const disableEdit = item.done || !canWrite;
        return (
            <TreeDropdown
                label={this.tr('Parent task')}
                value={typeof item.original_parent == 'string' ? item.original_parent.substr(2) : false}
                data={this.state.tasks}
                parentKey={'original_parent'}
                resetOnClickaway
                disabled={disableEdit}
                onSelect={(e) => {
                    const { data } = e;
                    if (data) {
                        this.setValue('original_parent', 'r-' + e.id);
                    }
                }}
                onErase={() => {
                    this.setValue('original_parent', undefined);
                }}
            />
        );
    };

    renderDateRange = (item) => {
        const canWrite = item.id == -1 || item.editable;
        const disableEdit = item.done || !canWrite;
        const isOneDayTask = this.isOneDayTask(item);
        const dateRange = {
            startDate: item.start_date,
            endDate: item.end_date,
        };
        return (
            <div className={styles.dateRange}>
                <DateRangePicker
                    disabled={disableEdit}
                    className={isOneDayTask ? 'is-one-day' : ''}
                    label={this.tr('Date range')}
                    name="daterange"
                    ranges={[dateRange]}
                    onChange={this.changeDateRange}
                    onInputChange={(type, dd) => {
                        const startDate = type === 'start' ? dd : item.start_date;
                        const endDate = type === 'end' ? dd : item.end_date;
                        this.changeDateRange({ range1: { startDate, endDate } });
                    }}
                    dateFormat={this.context.userObject.dateFormat}
                />
                {!disableEdit && (
                    <div className={styles.bookHours}>
                        <span>{this.tr('or')}</span>
                        {isOneDayTask ? (
                            <div
                                onClick={() => {
                                    const startDate = new Date();
                                    const endDate = new Date();
                                    endDate.setDate(endDate.getDate() + 7);
                                    this.changeDateRange({ range1: { startDate, endDate } });
                                }}
                            >
                                {this.tr('Book Specific Time')}
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    const startDate = new Date();
                                    const endDate = new Date();
                                    this.changeDateRange({ range1: { startDate, endDate } });
                                }}
                            >
                                {this.tr('Book Specific Hours')}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    renderButtons = (item) => {
        const canWrite = item.id == -1 || item.editable;
        const disableEdit = item.done || !canWrite;
        const isOneDayTask = this.isOneDayTask(item);
        return (
            <div className={styles.resourceButtons}>
                {this.state.mode === 'task' && !isOneDayTask && !disableEdit && (
                    <div className={styles.updateHoursButton} onClick={this.hoursFromEntries}>
                        <Refresh /> {this.tr('Update resourced h')}
                    </div>
                )}
                {!disableEdit && (
                    <div className={styles.repeatButton} onClick={this.openRecurrenceDialog}>
                        <Repeat />
                        {item.rrule ? this.rruleToHumanReadable(item.rrule) : this.tr('Repeat')}
                    </div>
                )}
            </div>
        );
    };

    getFields = () => {
        const { resource, skills, priorities, employees, companies } = this.state;
        const { taimerAccount } = this.context;
        const canWrite = resource.id == -1 || resource.editable;
        const disableEdit = resource.done || !canWrite;

        const fields: EditableField[] = [
            {
                key: 'companies_id',
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
                isHidden: () => !taimerAccount.isMulticompany,
                required: true,
                disabled: resource.id != -1,
            },
            {
                key: 'projects_id',
                title: this.tr('Project'),
                isHidden: () => !!this.props.massEditMode,
                customComponent: this.renderProjectTreeDrop,
            },
            {
                key: 'skills_id',
                title: this.tr('Skill'),
                type: 'data_select',
                options: skills,
                disabled: disableEdit,
                isHidden: () => this.state.autoCompleteData.resourcing_disable_skill,
                hideIfEmpty: true,
                additionalProps: {
                    customSingleValue: SingleValueInfo,
                },
            },
            {
                key: 'original_parent',
                isHidden: (item) => {
                    const canHaveSubtasks =
                        this.state.selectedProjects.length > 0 &&
                        this.state.selectedProjects.length < 2 &&
                        taimerAccount.useSubresources &&
                        (item.task_type == '0' || item.id == -1) &&
                        this.state.mode == 'task';
                    return !canHaveSubtasks || this.state.tasks.length < 2;
                },
                title: this.tr('Parent task'),
                customComponent: this.renderParentTaskDrop,
            },
            {
                key: 'description',
                title: this.tr('Description'),
                disabled: disableEdit,
                required: true,
            },
            {
                key: 'additional_description',
                title: this.tr('Note'),
                disabled: disableEdit,
                additionalProps: {
                    multiline: true,
                },
                isHidden: () => this.state.mode != 'task',
            },
            {
                key: 'priorities_id',
                title: this.tr('Priority'),
                type: 'data_select',
                options: priorities,
                disabled: disableEdit,
                isHidden: () => this.state.autoCompleteData.resourcing_disable_priority,
                additionalProps: {
                    customSingleValue: SingleValuePriority,
                    customOption: CustomOptionPriority,
                },
            },
            {
                key: 'daterange',
                title: this.tr('Date range'),
                isHidden: () => this.state.mode === 'milestone',
                customComponent: this.renderDateRange,
            },
            {
                key: 'hours',
                title: this.tr('Allocated hours'),
                validation: 'numeric',
                disabled: disableEdit || (this.showVacationCheck() && resource.is_full_vacation > 0),
                error: this.state.maxhoursInvalid,
                isHidden: (item) => this.state.mode !== 'task' || this.isOneDayTask(item),
                afterEdit: (item) => {
                    this.lastHours = item.hours;
                },
                additionalProps: {
                    onInput: (evt) => this.checkMaxHours(this.state.usersHours, undefined, Number(evt.target.value.replace(',', '.'))),
                },
            },
            {
                key: 'timerange',
                startKey: 'starttime',
                endKey: 'endtime',
                type: 'timerange',
                title: this.tr('Time'),
                disabled: disableEdit || (this.showVacationCheck() && resource.is_full_vacation > 0),
                error: this.state.timeInvalid,
                isHidden: (item) => this.state.mode != 'task' || !this.isOneDayTask(item),
                afterEdit: (item) => this.changeTime(item.starttime, item.endtime),
            },
            {
                key: 'start_date',
                type: 'date',
                title: this.tr('Date'),
                disabled: disableEdit,
                isHidden: () => this.state.mode != 'milestone',
            },
            {
                key: 'time',
                type: 'time',
                title: this.tr('Time'),
                disabled: disableEdit,
                isHidden: () => this.state.mode != 'milestone',
            },
            {
                key: 'is_full_vacation',
                type: 'switch',
                title: this.tr('Is full vacation'),
                disabled: disableEdit,
                isHidden: (item) => this.state.mode != 'task' || !(this.showVacationCheck() || item.is_full_vacation > 0),
            },
            {
                key: 'buttons',
                customComponent: this.renderButtons,
            },
            {
                key: 'users_id',
                title: this.tr('Assigned to'),
                type: 'data_select',
                options: employees,
                isHidden: () => this.state.mode != 'milestone',
            },
            {
                key: 'assigned_to',
                isHidden: () => this.state.mode != 'task',
                customComponent: this.renderUsers,
            },
        ];

        console.log({fields})

        return fields;
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    checkMaxHours = (usersHours: any[], index: any = undefined, maxhours: any = undefined) => {
        const maxHours = maxhours ? maxhours : Number(this.state.resource.hours);
        const hours = sumBy(
            usersHours.filter((x) => !x.deleted),
            (x) => x.hours
        );
        if (hours > maxHours && !this.isOneDayTask(this.state.resource)) {
            if (index !== undefined && index > -1) {
                usersHours[index].invalid = true;
            }
            this.setState({ usersHours: usersHours, maxhoursInvalid: true, userHoursInvalid: true });
            return false;
        }
        usersHours = usersHours.map((el) => ({ ...el, invalid: false }));
        this.setState({ usersHours: usersHours, maxhoursInvalid: false, userHoursInvalid: false });
        this.lastUsersHours = [...usersHours];
    };

    recurrenceSave = (rrule) => {
        this.setState({
            resource: {
                ...this.state.resource,
                rrule,
            },
            showRecurrenceDialog: false,
        });
    };

    delete = () => {
        const { resource, mode } = this.state;
        let confirmText = this.tr('Delete task "${task}"?', { task: resource.description });
        let isRecurring = false;
        if (mode === 'milestone') {
            confirmText = this.tr('Delete milestone "${task}"?', { task: resource.description });
        }
        if (resource.recurrence_parent_id > 0) {
            confirmText = this.tr('Do you want to delete recurring elements also?');
            isRecurring = true;
        }

        this.setState({
            showConfirmationDialog: true,
            confirmationRecurringTask: isRecurring,
            confirmationDialogText: confirmText,
            confirmationDialogSaveFunc: () => {
                this.deleteQuery();
            },
            confirmationDialogExtra: {},
        });
    };

    deleteQuery = () => {
        const { resource, mode } = this.state;
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const key = enqueueSnackbar(this.tr('Deleting...'), {
            variant: 'info',
            persist: true,
        });
        this.setState({
            showConfirmationDialog: false,
        });
        const url = mode === 'task' ? 'resourcing/delete_task/' : 'resourcing/delete_milestone/';
        this.onClose();
        DataHandler.delete({ url: url + resource.id, delete_others: this.state.confirmationDialogRadio })
            .done((response) => {
                closeSnackbar(key);

                if (response.success && mode === 'task') {
                    enqueueSnackbar(this.tr(`Task deleted successfully!`), {
                        variant: 'success',
                    });
                } else if (response.success) {
                    enqueueSnackbar(this.tr(`Milestone deleted successfully!`), {
                        variant: 'success',
                    });
                } else if (response.error === 'subtask') {
                    enqueueSnackbar(this.tr(`Tasks with subtasks can't be removed until you remove or move subtasks!`), {
                        variant: 'error',
                    });
                } else if (response.error === 'workhours') {
                    enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(this.tr(`Error while deleting.`), {
                        variant: 'error',
                    });
                }

                window.dispatchEvent(new Event('taskSaved'));
            })
            .fail(() => {
                closeSnackbar(key);
                enqueueSnackbar(this.tr('Error while deleting.'), {
                    variant: 'error',
                });
            });
    }

    isOneDayTask = (resource) => !resource.end_date || (resource.end_date - resource.start_date) / (1000 * 60 * 60 * 24) < 1;

    onSave = async (resource, recurrenceConfirmed = false) => {
        const isNew = resource.id == -1;
        if (!isNew && !recurrenceConfirmed && Number(resource.recurrence_parent_id) > 0) {
            this.setState({
                showConfirmationDialog: true,
                confirmationRecurringTask: true,
                confirmationDialogText: this.tr("Do you want to edit recurring elements also? Edited elements are separated from original elements and can't be edited at the same time anymore."),
                confirmationDialogSaveFunc: () => {
                    this.setState({ showConfirmationDialog: false }, () => {
                        this.onSave(resource, true);
                    });
                },
                confirmationDialogExtra: {},
            });
            return;
        }
        const { usersHours, selectedProjects, mode } = this.state;
        const { enqueueSnackbar, closeSnackbar, onProjectRangeExtended, afterSaveCallback } = this.props;
        const isOneDayTask = this.isOneDayTask(resource);
        const projectsId = resource.id == -1 && selectedProjects.length > 1 ? selectedProjects.map((el) => el.id) : resource.projects_id;
        const data = {
            description: resource.description,
            startdate: moment(resource.start_date).format('YYYY-MM-DD'),
            enddate: moment(resource.end_date).format('YYYY-MM-DD'),
            parent_id: selectedProjects.length > 0 && selectedProjects.length < 2 ? resource.original_parent : 0,
            hours: resource.hours,
            projects_id: !this.props.massEditMode ? projectsId : this.props.massEditProjectIds,
            skills_id: resource.skills_id,
            priorities_id: resource.priorities_id,
            type: mode,
            users_id: resource.users_id,
            time: resource.time,
            starttime: moment(resource.starttime, 'LT') ? moment(resource.starttime, 'LT').format('HH:mm') : null,
            endtime: moment(resource.endtime, 'LT') ? moment(resource.endtime, 'LT').format('HH:mm') : null,
            quote_rows_id: resource.quote_rows_id,
            users_hours: usersHours.map((el) => ({
                id: el.id,
                users_id: el.users_id,
                hours: el.hours,
                periods: el.periods?.map((p) => ({
                    start: format(p.start, 'YYYY-MM-DD'),
                    end: format(p.end, 'YYYY-MM-DD'),
                    hours: p.hours,
                })),
                deleted: el.deleted,
            })),
            timezone: moment.tz.guess(),
            rrule: resource.rrule ? resource.rrule : '',
            is_full_vacation: !this.showVacationCheck() ? 0 : resource.is_full_vacation,
            additional_description: resource.additional_description,
            moveSubtasks: this.state.moveSubtasks ? 1 : 0,
        };

        if (isOneDayTask) {
            const hours = usersHours.reduce((a, b, i) => {
                if (b.deleted > 0) {
                    return Number(a);
                }
                return Number(a) + Number(b.hours);
            }, 0);
            data.hours = hours;
        }

        const creatingSnackbar: any = enqueueSnackbar(this.tr('Saving resource...'), {
            variant: 'info',
            persist: true,
        });

        const start = moment(resource.start_date).format('YYYY-MM-DD');
        const end = moment(resource.end_date).format('YYYY-MM-DD');

        this.onClose();

        try {
            const dates = await DataHandler.get({ url: 'resourcing/extend_projects', id: this.getSelectedProjects().map((x) => x.id), start, end });

            if (dates.extended) {
                setTimeout(() => {
                    onProjectRangeExtended && onProjectRangeExtended(parse(start, 'YYYY-MM-DD', new Date()), parse(end, 'YYYY-MM-DD', new Date()));
                }, 1000);
                enqueueSnackbar(this.tr('Projects date range was extended, because task was created outside.'), {
                    variant: 'info',
                });
            }
        } catch (error) {
            closeSnackbar(creatingSnackbar);
            enqueueSnackbar(this.tr('Creating tasks outside projects current dates requires permission to update project.'), {
                variant: 'error',
            });
            return;
        }

        let url = '';

        if (!this.props.massEditMode && !(isNew && selectedProjects.length > 1)) url = isNew ? 'resourcing/add_task' : `resourcing/edit_task/${resource.id}`;
        else url = 'resourcing/add_task_multiple';

        DataHandler.post({ url: url, edit_others: this.state.confirmationDialogRadio }, data)
            .done((response) => {
                closeSnackbar(creatingSnackbar);
                if (response.error) {
                    enqueueSnackbar(this.tr("Can't create task to locked project."), {
                        variant: 'error',
                    });
                } else {
                    const id = isNew ? response : resource.id;
                    const project = selectedProjects[0];
                    const createdSnackbar = enqueueSnackbar(
                        <EntityCreatedSnackbar
                            type={data.type}
                            id={id}
                            onClose={() => closeSnackbar(createdSnackbar)}
                            actions={
                                !!project
                                    ? [
                                          {
                                              key: 'activity',
                                              onClick: () => {
                                                  this.context.functions.openActivitySlider({
                                                      customers_id: project.cus_id,
                                                      projects_id: project.id,
                                                  });
                                              },
                                              label: 'Add activity',
                                          },
                                          {
                                              key: 'hours',
                                              onClick: () => {
                                                  this.context.functions.addHours({
                                                      customer: {
                                                          id: project.cus_id,
                                                      },
                                                      unit: {
                                                          id: 0,
                                                      },
                                                      project: {
                                                          name: project.name,
                                                          label: project.name,
                                                          id: project.id,
                                                          value: project.id,
                                                      },
                                                      wh_projects_resource: {
                                                          label: resource.description,
                                                          name: resource.description,
                                                          id,
                                                          value: id,
                                                      },
                                                  });
                                              },
                                              label: 'Add hour entry',
                                          },
                                          {
                                              key: 'view_project',
                                              onClick: () => {
                                                  this.context.functions.updateView({ module: 'projects', action: 'view', id: project.id });
                                              },
                                              label: 'View project',
                                          },
                                      ]
                                    : []
                            }
                        />,
                        {
                            variant: 'default',
                            autoHideDuration: 5000,
                            className: 'entityCreatedSnackbar',
                        }
                    );
                    afterSaveCallback && afterSaveCallback();
                    setTimeout(() => {
                        window.dispatchEvent(new Event('taskSaved'));
                    }, 1000);
                }
            })
            .fail(() => {
                closeSnackbar(creatingSnackbar);
                enqueueSnackbar(this.tr('Creating resource failed.'), {
                    variant: 'error',
                });
            });
    };

    onItemChanged = (resource) => this.setState({ resource });

    handleConfirmationRadio = (evt) => {
        this.setState({ confirmationDialogRadio: evt.target.value });
    };

    onTabClick = (tab) => {
        this.setState({ mode: tab.id });
    };

    markOwnAsDone = () => {
        const { resource } = this.state;
        const { userObject } = this.context;
        const data = {
            users_id: userObject.usersId,
        };
        DataHandler.post({ url: 'resourcing/mark_task_done/' + resource.id }, data);
        this.setState(
            {
                resource: {
                    ...this.state.resource,
                    done: true,
                },
                usersHours: this.state.usersHours.map((el) => {
                    if (el.users_id == userObject.usersId) {
                        return { ...el, done: true };
                    }
                    return el;
                }),
            },
            () => {
                this.onSave(this.state.resource);
            }
        );
    };

    markOwnAsUndone = () => {
        const { userObject } = this.context;
        const { resource } = this.state;
        const data = {
            users_id: userObject.usersId,
        };
        DataHandler.post({ url: 'resourcing/mark_hours_undone/' + resource.id }, data);
        this.setState(
            {
                resource: {
                    ...this.state.resource,
                    done: false,
                },
                usersHours: this.state.usersHours.map((el) => ({ ...el, done: false })),
            },
            () => {
                this.onSave(this.state.resource);
            }
        );
    };

    markAsDone = () => {
        const { resource } = this.state;
        DataHandler.post({ url: 'resourcing/mark_task_done/' + resource.id });
        this.setState(
            {
                resource: {
                    ...this.state.resource,
                    done: true,
                },
                usersHours: this.state.usersHours.map((el) => ({ ...el, done: true })),
            },
            () => {
                this.onSave(this.state.resource);
            }
        );
    };

    unlockTask = () => {
        const { resource } = this.state;
        DataHandler.post({ url: 'resourcing/unlock_task/' + resource.id });
        this.setState(
            {
                resource: {
                    ...this.state.resource,
                    done: false,
                },
                usersHours: this.state.usersHours.map((el) => ({ ...el, done: false })),
            },
            () => {
                this.onSave(this.state.resource);
            }
        );
    }

    addHours = () => {
        const { resource } = this.state;
        const selectedProjects = this.getSelectedProjects();
        const id = resource.id.replace(/[^0-9]/g, '');
        this.context.functions.closeSlider(() =>
            this.context.functions.addHours({
                start: resource.start_date,
                end: resource.end_date,
                customer: { id: resource.customers_id, value: resource.customers_id, name: resource.customer_name, label: resource.customer_name },
                project: selectedProjects.length ? selectedProjects[selectedProjects.length - 1] : undefined,
                wh_projects_resource: { id, value: id, name: resource.description, label: resource.description },
            })
        );
    };

    getOptions = () => {
        const { resource, mode, usersHours } = this.state;
        if (resource.id == -1) return undefined;
        const {
            userObject,
            functions: { checkPrivilege },
        } = this.context;
        const canWrite = resource.id == -1 || resource.editable;
        const showAsDoneOptions = resource.id && !resource.done;
        const showAsUndoneOptions = resource.id && resource.done && canWrite;
        const ownHour = usersHours.find((el) => el.users_id == userObject.usersId && el.deleted != 1);
        const hasOwnHours = usersHours.find((el) => el.users_id == userObject.usersId && el.deleted != 1 && el.done != 1) != null;
        const hasDoneOwnHours = ownHour && usersHours.find((el) => el.users_id == userObject.usersId && el.deleted != 1 && el.done > 0) != null;
        const hasSavedOwnHours = usersHours.find((el) => el.users_id == userObject.usersId && el.deleted != 1 && el.done != 1 && el.id > 0) != null;
        const disableEdit = resource.done || !canWrite;
        const options: any = [];
        if (showAsDoneOptions) {
            if (hasOwnHours) {
                options.push({
                    key: 'own_done',
                    label: this.tr('Mark own hours as done'),
                    onClick: this.markOwnAsDone,
                });
            }
            if (hasDoneOwnHours) {
                options.push({
                    key: 'own_undone',
                    label: this.tr('Mark own hours as undone'),
                    onClick: this.markOwnAsUndone,
                });
            }
            if (!disableEdit) {
                options.push({
                    key: 'done',
                    label: mode == 'task' ? this.tr('Mark whole task as done') : this.tr('Mark milestone as done'),
                    onClick: this.markAsDone,
                });
            }
        }
        if (showAsUndoneOptions) {
            options.push({
                key: 'unlock',
                label: mode == 'task' ? this.tr('Activate task') : this.tr('Mark milestone as undone'),
                onClick: this.unlockTask,
            });
        }

        if (mode == 'task' && !disableEdit && resource.id && resource.rrule === '' && !resource.recurrence_parent_id) {
            options.push({
                key: 'split',
                label: this.tr('Split'),
                onClick: this.split,
            });
        }
        if (checkPrivilege('workhours', 'write') && resource.id != -1 && this.isOneDayTask(resource) && hasSavedOwnHours && mode == 'task') {
            options.push({
                key: 'add_hours',
                label: this.tr('Add hours'),
                onClick: this.addHours,
            });
        }
        return options;
    };

    split = () => {
        this.setState({
            showSplitDialog: true,
        });
    };

    cancelSplit = () => {
        this.setState({ showSplitDialog: false });
    };

    onSaveSplit = () => {
        this.setState({ showSplitDialog: false });
        this.onClose();
    };

    render() {
        const { resource, open, showRecurrenceDialog, showConfirmationDialog, mode, showSplitDialog } = this.state;
        const canWrite = resource.id == -1 || resource.editable;
        const disableEdit = resource.done || !canWrite;
        return (
            <>
                <FieldEditSlider
                    open={open}
                    width={550}
                    onClose={this.onClose}
                    title={resource.id != -1 ? (disableEdit ? this.tr('View resource') : this.tr('Edit resource')) : this.tr('Add resource')}
                    onSave={this.onSave}
                    onItemChanged={this.onItemChanged}
                    item={resource}
                    fields={this.getFields()}
                    options={this.getOptions()}
                    canDeleteItem={() => !disableEdit && resource.id != -1}
                    onDeleteItem={this.delete}
                    additionalValidations={this.isProjectSelected}
                    saveHidden={disableEdit}
                >
                    {(sliderContent) => (
                        <WithTabs
                            hideTabs={resource.id != -1}
                            style={{ overflow: 'hidden' }}
                            offsetTop={resource.id != -1 ? 0 : 10}
                            tabs={this.tabs}
                            selectedTab={mode}
                            noUpdateView
                            onTabClick={this.onTabClick}
                        >
                            <div className={styles.container}>{sliderContent}</div>
                        </WithTabs>
                    )}
                </FieldEditSlider>
                {showRecurrenceDialog && (
                    <ResourceRecurrenceDialog
                        onSave={(rule) => this.recurrenceSave(rule)}
                        rrule={resource.rrule ? resource.rrule : ''}
                        onClose={() => this.setState({ showRecurrenceDialog: false })}
                    />
                )}
                <SplitTaskDialog open={showSplitDialog} onCancel={this.cancelSplit} onSave={this.onSaveSplit} task={showSplitDialog ? resource : undefined} />
                {showConfirmationDialog && (
                    <ConfirmationDialog
                        data={{
                            text: this.state.confirmationDialogText,
                            cancelText: this.state.confirmationDialogExtra?.cancelText,
                        }}
                        onDialogClose={() => this.setState({ showConfirmationDialog: false })}
                        onDialogSave={this.state.confirmationDialogSaveFunc}
                    >
                        {this.state.confirmationRecurringTask && (
                            <RadioGroup name="resource-edit-recurrence" value={this.state.confirmationDialogRadio} onChange={this.handleConfirmationRadio}>
                                <FormControlLabel value="this" label={this.tr('Only this')} labelPlacement="start" control={<Radio />} />
                                <FormControlLabel value="future" label={this.tr('Future')} labelPlacement="start" control={<Radio />} />
                                <FormControlLabel value="all" label={this.tr('All')} labelPlacement="start" control={<Radio />} />
                            </RadioGroup>
                        )}
                    </ConfirmationDialog>
                )}
            </>
        );
    }
}

export default withSnackbar(AddResourceSlider);
