import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { format } from "date-fns";
import { withSnackbar } from 'notistack';

import { Tabs, Tab, Switch, Button, MenuItem, Typography, Tooltip } from '@mui/material';
import { withStyles } from "@mui/styles";
import { MoreHoriz, CheckCircle, Cancel } from "@mui/icons-material";

import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import TaimerComponent from '../../TaimerComponent';
import DataHandler from '../../general/DataHandler';
import SettingsGrid from './SettingsGrid';
import List from "../../list/List";
import ConfirmationDialog from './../dialogs/ConfirmationDialog';
import RateChanceForInvoicesDialog from './../dialogs/RateChanceForInvoicesDialog';
import { SettingsContext } from '../../SettingsContext';
import SettingRow from "../../list/rows/SettingRow";
import ListCell from '../../list/ListCell';
import TextInputCell from '../../list/cells/TextInputCell';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';
import CurrencyRatePresentCell from '../../list/cells/CurrencyRatePresentCell';
import DateCell from "../../list/cells/DateCell";
import ContextMenu from "../../general/ContextMenu";
import { returnCurrencyList } from "../../general/Currencies";

import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';

/* css */
import './InvoiceSettings.css';
import colors from '../../colors';

const StyledTab = withStyles({
	    label: {
	        fontSize: "14px",
	        letterSpacing: "1px"
	    }
	})(Tab);

const StyledTabs = withStyles({
    indicator: {
        display: 'none',
        "&.with-indicator": {
            display: 'block',
            backgroundColor: "#43A7F5",
            height: "3px",
        },
        "&.rounded-indicator": {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
    }
})(Tabs);

class CurrencyRateListRow extends SettingRow {

	componentDidMount() {
		super.componentDidMount();
	}

    editAndSave(name, value) {
        const data = cloneDeep(this.state.data);
        data[name] = value;
        if(isEqual(data, this.state.data) && data.id > 0) {
            return;
        }

    	if (name == 'valid_from') {
//NB! HUOM! tämä poistetaan kun on tietyd asiakkaat luonneet tarpeeksi kursseja mennesyydeen!!! khm, Miltton.
		 //    if (new Date(data.valid_from) < new Date().setHours(0,0,0,0) ) {
			//     this.props.rowProps.enqueueSnackbar(this.props.rowProps.translations.validFromWarning, {
			//         variant: "error",
			//         preventDublicate: true
			//     });
			//     return false;
			// }
			if (this.props.rowProps.allRates.some(cr => cr.currency_label == data.currency_label && cr.valid_from == data.valid_from)) {
			    this.props.rowProps.enqueueSnackbar(this.props.rowProps.translations.existingDateWarning, {
			        variant: "error",
			        preventDublicate: true
			    });
			    return false;												
			}
    	}


        this.setData(data);

        if(data.id < 0)
            return;

        this.props.listRef.editData(data);     
        this.update({...data, checkForInvoices: name == 'currency_rate', refresh: true});
    }

    createAndSave(data) {

	 //    if (new Date(data.valid_from) < new Date().setHours(0,0,0,0) ) {
		//     this.props.rowProps.enqueueSnackbar(this.props.rowProps.translations.validFromWarning, {
		//         variant: "error",
		//         preventDublicate: true
		//     });
		//     return false;
		// }
		if (this.props.rowProps.allRates.some(cr => cr.currency_label == data.currency_label && cr.valid_from == data.valid_from)) {
		    this.props.rowProps.enqueueSnackbar(this.props.rowProps.translations.existingDateWarning, {
		        variant: "error",
		        preventDublicate: true
		    });
		    return false;												
		}
		if (!data.currency_label || data.currency_label == '' || data.currency_label == -1) {
		    this.props.rowProps.enqueueSnackbar(this.props.rowProps.translations.noLabelWarning, {
		        variant: "error",
		        preventDublicate: true
		    });
		    return false;			
		}
    	
    	this._create(data);
    }

    render() {

    	const { columnWidthMap, sharedData, data, rowProps } = this.props;
    	let stateData = cloneDeep(this.state.data);

    	const statusColorMap = {active: colors.greenish_cyan, pending: "#716aca", archived: "#979797"}

        const cells = {
            context: data.parentId > 0 ? <div style={{width: columnWidthMap.context + "px", flex: columnWidthMap.context + " 1 0px"}}> </div>: (data.id > 0 ? 
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell row-menu"
                    width={columnWidthMap.context}
                    style={{
                        width: columnWidthMap.context + "px",
                        flex: columnWidthMap.context + " 1 0px",
                    }}
                    noExpandIcon >

                        <MenuItem id="menu-item-remove" onClick={() => this.delete(data.id)}>
                            <RemoveIcon className="delete" />
                            <Typography className="delete" variant="inherit">{this.props.rowProps.translations.delete}</Typography>
                        </MenuItem>                        

                </ContextMenu> : 
					<ListCell width={columnWidthMap.context} permanentEditMode={true} onlyDisplay={true}>
						<Tooltip title={this.props.rowProps.translations.save} placement="bottom">
                        	<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.createAndSave(stateData)} />
						</Tooltip>
                        <Cancel className="cancelIcon" onClick={() => this.delete(data.id)} />
					</ListCell>
            ),        	
            currency_label: (
                <AutoCompleteCell
                	ref={(cell) => this.currencyLabelCell = cell }
                	editable={data.id < 1 && !data.parentId}
                	disabled={data.id > 0 || data.parentId > 0}
                	width={columnWidthMap.currency_label}
                    value={data.currency_label > 0 ? data.currency_label : stateData.currency_label}
                    autoCompleteData={returnCurrencyList().filter(rc => rc.id != rowProps.defaultCurrency)}
                    menuIsOpen={data.id < 0}
                    onEdited={val => {
                        this.editAndSave("currency_label", val.id);
                    }}
                />
            ),
            // country: (
            // 	<ListCell
            // 		editable={false}
            //         width={columnWidthMap.country}
            //         name="country"
            //         value={`${displayCountry(data.currency_label).icon} ${displayCountry(data.currency_label).name}`} />
            // ),
            currency_rate: (
                <TextInputCell
                	listCellType={data.id > 0 ? CurrencyRatePresentCell : ListCell}
                	editable={!data.parentId}
                    width={columnWidthMap.currency_rate}
                    companyCurrency={data.base_currency}
                    rateCurrency={data.currency_label}
                    name="currency_rate"
                    value={String(stateData.currency_rate).replace('.', ',')}
                    onEdited={(name, value) => this.editAndSave(name, String(value).replace(',', '.'))} />
            ),
            currency_rate_reverse: (
                <TextInputCell
                	listCellType={data.id > 0 ? CurrencyRatePresentCell : ListCell}
                    companyCurrency={data.base_currency}
                    rateCurrency={data.currency_label}                	
                	editable={!data.parentId}
                    width={columnWidthMap.currency_rate_reverse}
                    name="currency_rate_reverse"
                    value={String(stateData.currency_rate_reverse).replace('.', ',')}
                    onEdited={(name, value) => this.editAndSave(name, String(value).replace(',', '.'))} />
            ),
            valid_from: (!data.parentId ? 
                <DateCell
                	editable
                	name="valid_from" 
                	width={columnWidthMap.valid_from} 
                	value={stateData.valid_from}
                	onEdited={(name, value) => this.editAndSave(name, value)} />
               	:
            	<ListCell
            		editable={false}
                    width={columnWidthMap.valid_from}
                    name="valid_from"
                    value={data.valid_from?.reduce((acc, curr, i) => acc = `${format(acc, this.context.userObject.dateFormat)}${i > 0 ? ' - ' : ''}${format(curr, this.context.userObject.dateFormat)}`)} />               	
            ),
            modified_by: (
				<ListCell
                    editable={false}
                    width={columnWidthMap.modified_by}
                    name="user"
                    value={data.modified_by} />
            ),
            is_active: (
				<ListCell
                    editable={false}
                    width={columnWidthMap.is_active}
                    name="is_active"
                    value={this.props.rowProps.translations[data.active_status]} />            	
            	/*<StatusCell
            		name="is_active" 
					width={columnWidthMap.is_active} 
					displayData={{"name": this.props.rowProps.translations[data.active_status], "color": statusColorMap[data.active_status]}} />*/
            ),            

        };

        return (
            <div
                className={`listElement row flex ${data.parentId > 0 ? 'child-row' : ''}`}
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }} >

                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

/**
 * Invoice & Bills settings
 *
 */
class Invoice extends TaimerComponent {
	static contextType = SettingsContext;
	static propTypes = {
		enqueueSnackbar: PropTypes.func.isRequired,
	};

	constructor(props, context) {
		super(props, context, 'settings/pages/Invoice');

		this.tabs = {
			'details': {},
			'currency_settings': {}
		};

		this.tabNames = Object.keys(this.tabs).map(key => key);

		this.state = {
			initialized: false,
			settings: undefined,
			invoiceNumber: undefined,
			draftInvoiceNumber: undefined,
			data: {},
			dialogData: undefined,
			selectedTab: props.selectedTab && this.tabNames.includes(props.selectedTab) ? props.selectedTab : this.tabNames[0],
			currencyRates: [],
			hideLogData: true,
			currencyRateTypes: {
				active: true,
				pending: false,
				archived: false,
				all: false
			}
		};

		this.dialogs = {
			confirmation: ConfirmationDialog,
			rateChanceForInvoicesDialog: RateChanceForInvoicesDialog 
		};

		// this.invoiceLanguages = [
  //           {value: "fi", label: this.tr("Finnish")},
  //           {value: "en", label: this.tr("English")}
  //       ];
		this.automaticInvoiceStates = [
            {value: 0, label: this.tr("Waiting")},
            {value: 1, label: this.tr("Draft")}
        ];
        this.listColumnCommons = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

		this.invoicingSettings = [
			{
				type: 'text',
				name: 'defaultvat',
				label: this.tr('Default VAT%'),
				className: 'respSetting third marginTop',
				validation: ["numeric"],
			},
			{
				type: 'text',
				name: 'defaultpenaltyinterest',
				label: this.tr('Default Penalty Interest'),
				className: 'respSetting third',
				validation: ["numeric"],
			},
			{
				type: 'text',
				name: 'defaultannotation',
				label: this.tr('Default Notice Period (days)'),
				className: 'respSetting third',
			},
			{
				type: 'text',
				name: 'defaulttermsofpayment',
				label: this.tr('Default Terms of Payment (days)'),
				className: 'respSetting third',
			},
			{
				type: 'text',
				name: 'billing_zones',
				label: this.tr('Billing Area (separate with a comma)'),
				className: 'respSetting third',
			},
			{
				type: 'text',
				name: 'invoice_start_number',
				label: this.tr('Invoice starting number'),
				className: 'respSetting third',
			},
			// ... context.addons.invoice_language ? [{
			// 	type: 'select',
			// 	name: 'invoice_language',
			// 	label: this.tr('Invoice language'),
			// 	className: 'respSetting third',
			// 	options: this.invoiceLanguages
			// }] : [],
			{
				type: 'check',
				name: 'show_late_invoices_frontpage',
				label: this.tr('Show invoices which are late'),
				className: 'respSetting third',
			},
			{
				type: 'check',
				name: 'use_manual_ownwork_invoicing',
				label: this.tr('Use manual own work calculation in invoicing'),
				className: 'respSetting third',
			},
			{
				type: 'check',
				name: 'invoice_header_name_type',
				label: this.tr('Show parent project name in invoice headers'),
				className: 'respSetting third',
			},
			{
				type: 'check',
				name: 'invoice_only_material',
				label: this.tr('Allow blank invoice creation'),
				className: 'respSetting third',
			},
            {
				type: 'check',
				name: 'invoice_barcode',
				label: this.tr('Show barcode on invoice'),
				className: 'respSetting third',
			},
            {
				type: 'check',
				name: 'already_invoiced_total_row',
				label: this.tr('Define already invoiced total row active by default'),
				className: 'respSetting third',
			},
		];

		if (!this.isCompanyUsingMeritAktiva(context.addons, props.company)) {
			this.invoicingSettings.push(
				{
					type: 'check',
					name: 'show_agreement_order_identifier_on_invoice',
					label: this.tr('Show agreement identifier and order identifier on invoice'),
					className: 'respSetting third',
				}
			);
		}

		if (this.context.addons.mass_invoicing) {
			this.invoicingSettings.push(
				{
					type: 'check',
					name: 'allow_mass_invoicing',
					label: this.tr('Allow mass invoice creation'),
					className: 'respSetting third',
				}
			);
		}

        this.newCurrencyListRateRow = {
        	id: -1,
            currency_label: -1,
            country: -1,
            currency_rate: 1,
            currency_rate_reverse: 1,
            valid_from: format(new Date(), 'YYYY-MM-DD'),
            modified_by: context.userObject.fullname,
            is_active: 1,
            companies_id: props.company,
            visible: true
        }

		this.currencyRatesList = React.createRef();

	}

	componentDidMount() {
		super.componentDidMount();
		this.fetchInvoicingSettings();
	}

	isCompanyUsingMeritAktiva= (addons, company) => {
        return (addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(company) > -1);
    }

    createTab(name, label, className) {

        return (
            <StyledTab 
                className={`settings-styled-tab ${className} ${this.state.selectedTab !== name ? "not-selected" : ""}`} 
                value={name}
                label={this.tr(label)} 
                href={this.context.functions.urlify({...this.props.viewProps, selectedTab: name})} 
                onClick={(evt) => !evt.ctrlKey && evt.preventDefault()}
            />
        )
    }


	openDialog = name => {
		this.setState({ currentDialog: name });
	};

	closeDialog = () => {
		this.currencyRatesList.current && this.currencyRatesList.current.resetStateData();
		this.setState({ currentDialog: false, dialogData: undefined });
	};

	confirmDialog = (saveFunc, id) => {
		saveFunc(id);
		this.closeDialog();
	};

    switchTab = (tabName) => {
        this.context.functions.updateView({ selectedTab: tabName });
        this.setState({ selectedTab: tabName });
    }	

	/**
	 * Saves invoicing settings details
	 *
	 */
	onChangeDetails = (settings) => {
		const { enqueueSnackbar, company } = this.props;
		DataHandler.post({ url: `settings/invoicing/${company}` }, { settings })
			.done(response => {
				this.fetchInvoicingSettings();
				enqueueSnackbar(this.tr('Invoice settings saved'), {
					variant: 'success',
					preventDublicate: true,
				});
			})
			.fail(response => {
				this.fetchInvoicingSettings();
				enqueueSnackbar(this.tr('Something went wrong while saving your invoice settings'), {
					variant: 'error',
					preventDublicate: true,
				});
			});
	}

	/**
	 * Saves invoicing default invoice receiver
	 *
	 */
	onChangeDefaultReceiver = userid => {
		const { enqueueSnackbar, company } = this.props;
		DataHandler.post({ url: `settings/invoicing/${company}/default_receiver` }, { userid: userid })
			.done(response => {
				this.fetchInvoicingSettings();
			})
			.fail(response => {
				this.fetchInvoicingSettings();
				enqueueSnackbar(this.tr('Something went wrong while saving default invoice receiver'), {
					variant: 'error',
					preventDublicate: true,
				});
			});
	};

	/**
	 * Gets invoicing settings
	 *
	 */
	fetchInvoicingSettings = (options) => {
		const { company } = this.props;
		DataHandler.get({ url: `settings/invoicing/${company}` }).done(response => {
			if (response.error) return;
			const {recipients, currency_rates, default_currencies, ...rest} = response;
			this.setState({
				defaultCurrency: default_currencies.find(df => df.id == company)?.currency,
				settings: rest, 
				recipients: recipients, 
				currencyRates: currency_rates, 
				initialized: true 
			});
		});
	};

	/**
	 * Reverts invoice with its number
	 *
	 */
	revertSelectedInvoice = () => {
		DataHandler.get({ url: 'settings/return_bill', bill_id: this.state.invoiceNumber, company: this.props.company })
			.done(response => {
				if (response == 'error')
					this.props.enqueueSnackbar(this.tr('There was a problem reverting the invoice.'), {
						variant: 'error',
					});
				else {
					this.props.enqueueSnackbar(`${this.state.invoiceNumber} ${this.tr('has been reverted')}`, {
						variant: 'success',
					});

					this.setState({ invoiceNumber: '' });
				}
			})
			.fail(response => {
				this.props.enqueueSnackbar(this.tr('There was a problem reverting the invoice.'), {
					variant: 'error',
				});
			});
	}

	/**
	 * Deletes invoice with its number
	 *
	 */
	deleteSelectedInvoice = (isDraft) => {
		const { invoiceNumber, draftInvoiceNumber } = this.state;
		const billId = invoiceNumber ? invoiceNumber : draftInvoiceNumber;
		const stateProperty = invoiceNumber ? 'invoiceNumber ' : 'draftInvoiceNumber';

		let params = { bill_id: billId };
		params.company = this.props.company;

		if (isDraft) {
			params.type = 'pre_bill';
		}

		DataHandler.get({ url: 'settings/delete_bill', ...params })
			.done(response => {
				if (response == 'error')
					this.props.enqueueSnackbar(this.tr('There was a problem deleting the invoice.'), {
						variant: 'error',
					});
				else {
					this.props.enqueueSnackbar(`${billId} ${this.tr('has been deleted')}`, {
						variant: 'success',
					});

					this.setState({ [stateProperty]: '' });
				}
			})
			.fail(response => {
				this.props.enqueueSnackbar(this.tr('There was a problem deleting the invoice.'), {
					variant: 'error',
				});
			});
	}

	changeUsersHourInvoiceability = (evt) => {
		const {company} = this.props;
		let {settings} = this.state;
		DataHandler.post({url: `settings/company/${company}/change_hour_invoiceability`, value: evt }).done(response => {
			settings.corporate_hour_invoicing_mode = Number(evt);
			this.setState({settings: settings});
		});
	}

	close = () => {
		this.props.onClose();
	};

	checkForExistingBills = (id) => {
		return DataHandler.get({url: `settings/company/${this.props.company}/${id}/check_for_currency_invoices`});
	}

	updateCurrencyRate = (data, unbilledInvoices) => {

		const {refresh, ...rest} = data;

		DataHandler.put({url: `settings/company/${this.props.company}/currency_rate`}, {...rest})
		.done(response => {
			this.state.currentDialog && this.closeDialog();
			refresh && this.fetchInvoicingSettings();
		}).fail(err => {
			console.log(err)
		})

		const invoiceIds = unbilledInvoices?.invoices.map(u => u.id);
		const billIds = unbilledInvoices?.bills.map(u => u.id);

		if (invoiceIds)	{
			DataHandler.put({url: `settings/company/${this.props.company}/invoice_currency_rate`}, {...data, invoice_ids: invoiceIds, bill_ids: billIds})
			.done(response => {
				this.closeDialog();
			}).fail(err => {
				console.log(err)
			})			
		}	
	}

	/**
	 * Invoice (settings) renderer
	 *
	 */
	render() {
		const invoiceNumberItem = { invoice_number: this.state.invoiceNumber };
		const draftInvoiceNumberItem = { invoice_number: this.state.draftInvoiceNumber };
		const { tr } = this;
		const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
		const { taimerAccount: {isMulticompany} } = this.context;
		const { currencyRates } = this.state;
		const { company, enqueueSnackbar } = this.props;

		const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
        };

		const hour_invoicing_settings = [
			{
				type: "radiogroup",
				formLabel: "",
				defaultValue: 'invoice_corporation_hours',
				value: this.state.initialized && this.state.settings.corporate_hour_invoicing_mode,
				onChange: this.changeUsersHourInvoiceability,
				options: isMulticompany ? [
					{
						name: 'invoice_corporation_hours',
						value: 1,
						label: tr('Invoice corporate hours'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
					{
						name: 'invoice_all_hours',
						value: 2,
						label: tr('Invoice all hours'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
					{
						name: 'invoice_company_hours',
						value: 3,
						label: tr('Invoice companies hours'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
				] 
				:
				[
					{
						name: 'invoice_all_hours',
						value: 2,
						label: tr('Invoice all hours'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
					{
						name: 'invoice_company_hours',
						value: 3,
						label: tr('Invoice companies hours'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
				]	
			}
		];


		const currencyRateListColumns = [
			{ width: 1, name: "context", header: '', ...this.listColumnCommons },
            { width: 2, name: "currency_label", header: this.tr("Currency"), ...this.listColumnCommons },
            // { width: 4, name: "country", header: this.tr("Country"), ...this.listColumnCommons },
            { width: 4, name: "currency_rate", header: `${this.tr("selling price")}`, ...this.listColumnCommons },
            { width: 4, name: "currency_rate_reverse", header: `${this.tr("buying price")}`, ...this.listColumnCommons },
            { width: 4, name: "valid_from", header: this.tr("Valid from"), ...this.listColumnCommons },
            { width: 4, name: "modified_by", header: this.tr("Edited by"), ...this.listColumnCommons },
            { width: 2, name: "is_active", header: this.tr("Status"), ...this.listColumnCommons },
        ];		

		const filteredCurrencyRates = () => {
			let rates = currencyRates;
			if (this.state.hideLogData)
				rates = currencyRates.filter(cr => !cr.parentId);
			if (this.state.currencyRateTypes.all)
				return rates;
			else 
				return rates.filter(c => (this.state.currencyRateTypes[c.active_status] || !c.active_status) && (!c.parentId || this.state.currencyRateTypes[rates.find(rr => rr.id == c.parentId)?.active_status] ));
		}

		return (
			<div id='settings-invoice'>
				{!this.state.initialized && 
					<div> 
						<img style={loaderStyle} src={require("../img/loading.svg").default}/>
					</div>
				}		
				{this.state.initialized && (
					<React.Fragment>

                        <StyledTabs 
                        	classes={{indicator: 'with-indicator rounded-indicator'}} 
                        	value={this.state.selectedTab} 
                        	variant="standard" 
                        	onChange={(evt, tab) => !evt.ctrlKey && this.switchTab(tab)}
                        >
	                        {this.createTab("details", "Details")}
	                        {this.context.addons.invoice_currency && this.createTab("currency_settings", "Currency settings")}
                        </StyledTabs>
                        <hr style={{border: 0, margin: "0 -25px 42px -25px", borderTop: "1px solid #dde3e8"}}/>

						{this.state.selectedTab == 'details' && <div>
							<h3>{tr('Invoicing details')}</h3>
							<p className='subtitle'>
								{tr(
									'Define default invoicing settings, editable on invoice. Payment details can also be defined per account.'
								)}
							</p>
							<SettingsGrid 
								settings={this.invoicingSettings} 
								item={this.state.settings} 
								onChange={this.onChangeDetails} />

							<div className='settings-feature-wrapper'>
								<div className='respSetting half clearfix'>
									<h3>{tr('Corporate invoicing of hours')}</h3>
									<p className='subtitle'>
										{tr('Define if the work of employees from other companies can be invoiced by your company') + "."}
									</p>
									<SettingsGrid settings={hour_invoicing_settings} item={this.state.settings.hour_invoicing} onChange={this.changeUsersHourInvoiceability} />
									{/*<div>
										<Switch
	                    				    checked={this.state.settings.invoice_other_company_users_hours == 1}
	                    				    color="primary"
	                    				    onChange={e => {
												let allowInvoice = 0;
	                    				        if (e.target.checked)
												allowInvoice = 1
	
	                    				        this.changeUsersHourInvoiceability("corporation", allowInvoice);
	                    				    }}
										/>
										</div>*/}
								</div>
							</div>
							<div className='settings-feature-wrapper'>
								<h3>{tr('Delete and reset Invoices')}</h3>
								<p className='subtitle'>
									{tr(
										"Need to edit an already sent or approved invoice? Reset the invoice status to 'waiting', select the company to which the invoice has been created, and then use the invoice number of the invoice you want to edit. Follow the same steps to delete an invoice. You cannot reset or get back an invoice that has been deleted. The invoice number will be deleted as well."
									)}
								</p>
								<div className='respSetting third clearfix marginBottom'>
									<h4>{tr('Invoice')}</h4>
									<SettingsGrid
										settings={[
											{
												type: 'text',
												name: 'invoice_number',
												label: tr('Invoice Number'),
											},
										]}
										item={invoiceNumberItem}
										onChange={d => {
											let match = d.invoice_number.match(/[0-9]/g);
	
											this.setState({ invoiceNumber: match !== null ? match.join('') : '' });
										}}
									/>
									<Button
										onMouseUp={() => {
											this.setState({
												dialogData: {
													saveFunc: () => this.revertSelectedInvoice(),
													text:
														tr('Do you want to revert invoice number') +
														': ' +
														this.state.invoiceNumber +
														'?',
												},
											});
											this.openDialog('confirmation');
										}}
										className='blue'
										style={{ marginTop: 24 }}
										size='large'
									>
										{this.tr('REVERT')}
									</Button>
									<Button
										onMouseUp={() => {
											this.setState({
												dialogData: {
													saveFunc: () => this.deleteSelectedInvoice(),
													text:
														tr('Do you want to delete invoice number') +
														': ' +
														this.state.invoiceNumber +
														'?',
												},
											});
											this.openDialog('confirmation');
										}}
										className='darkred'
										style={{ float: 'right', marginTop: 24 }}
										size='large'
									>
										{tr('DELETE')}
									</Button>
								</div>
							</div>
							<div className='settings-feature-wrapper'>
								<div className='respSetting third clearfix'>
									<h4>{tr('Draft')}</h4>
									<SettingsGrid
										item={draftInvoiceNumberItem}
										settings={[
											{
												type: 'text',
												name: 'invoice_number',
												label: tr('Draft Number'),
											},
										]}
										onChange={d => {
											let match = d.invoice_number.match(/[0-9]/g);
	
											this.setState({ draftInvoiceNumber: match !== null ? match.join('') : '' });
										}}
									/>
									<Button
										onMouseUp={() => {
											this.setState({
												dialogData: {
													saveFunc: () => this.deleteSelectedInvoice(true),
													text:
														tr('Do you want to delete draft invoice number') +
														': ' +	
														this.state.draftInvoiceNumber +
														'?',
												},
											});
											this.openDialog('confirmation');
										}}
										className='darkred'
										style={{ float: 'right', marginTop: 24 }}
										size='large'
									>
										{tr('DELETE')}
									</Button>
								</div>
								<div className='respSetting third clearfix marginBottom marginTop'>
									<h4>{tr('Default recipient of purchase invoices')}</h4>
									<p className='subtitle'>
										{tr(
											'Define default recipient of purchase invoices to target invoices without recipient'
										)}
									</p>
									<Select
										placeholder={this.tr('Select default user')}
										onChange={data => this.onChangeDefaultReceiver(data.value)}
										value={this.state.settings.defaultrecipient}
										isSearchable={true}
										options={this.state.recipients}
										className='default_purchase_user'
										classNamePrefix='default-purchase_user'
									/>
								</div>
							</div>
						</div>}

						{this.state.selectedTab == 'currency_settings' && <div>
							<h3>{tr('Currency settings')}</h3>
							<p className='subtitle'>
								{tr('Define invoiceable currency settings.')}
							</p>
							<div className="currency-settings-buttons" >
								<Button 
									className="green" 
									onMouseUp={() => !filteredCurrencyRates().find(f => f.id < 0) && this.setState({currencyRates: [this.newCurrencyListRateRow, ...this.state.currencyRates]}, () =>
										setTimeout(() => this.currencyRatesList.current.listElementReferences[0].current.currencyLabelCell.listCell.current.openEdit(), 200))} 
									size="large">
										{tr("Add currency")}
								</Button>

								<div className="settings-button-container">
									<p>{this.tr("View log")}</p>
									<Switch
	                				    checked={!this.state.hideLogData}
	                				    color="primary"
	                				    onChange={(e, val) => this.setState({ hideLogData: !this.state.hideLogData })}
									/>

									<Tabs
	                                    color="primary"
	                                    className="header-buttons-tabs"
	                                    onChange={(e, val) => {this.setState({currencyRateTypes: {[val]: !this.state.currencyRateTypes[val]}})}}>
	                                    <Tab
	                                        value="active"
	                                        label={this.tr("Active")}
	                                        className={`header-buttons-tabs-tab tab-left ${this.state.currencyRateTypes.active ? 'selected' : ''}`} />
	                                    <Tab
	                                        value="pending"
	                                        label={this.tr("Pending")}
	                                        className={`header-buttons-tabs-tab tab-left ${this.state.currencyRateTypes.pending ? 'selected' : ''}`} />
	                                    <Tab
	                                        value="archived"
	                                        label={this.tr("Archived")}
	                                        className={`header-buttons-tabs-tab tab-left ${this.state.currencyRateTypes.archived ? 'selected' : ''}`} />
	                                    <Tab
	                                        value="all"
	                                        label={this.tr("All")}
	                                        className={`header-buttons-tabs-tab ${this.state.currencyRateTypes.all ? 'selected' : ''}`}
	                                    />
	                                    
	                                </Tabs>
									
								</div>
																
							</div>
							<List
                                id="currencyRatesList"
                                className="settingsList"
                                ref={this.currencyRatesList}
                                hideHeader={currencyRates?.length > 0 ? false: true}
                                fluid
                                rowKey="rowid"
                                parentsExpandedOnInit={false}
                                columns={currencyRateListColumns}
                                newRow={this.newCurrencyListRateRow}
                                sharedData={{users: 0}}
                                listRowType={CurrencyRateListRow}
                                rowProps={{
                                    onCreate: (data) => {

                                        DataHandler.post({url: `settings/company/${company}/currency_rate`}, {...data, base_currency: this.state.defaultCurrency})
                                        .done(response => {
                                        	console.log(response);
                                        	this.fetchInvoicingSettings();
                                        }).fail(err => {
                                        	console.log(err)
                                        })
                                    },
                                    
                                    onUpdate: async (data) => {

                                    	const {checkForInvoices, ...rest} = data;
                                    	
                                    	if (!checkForInvoices) {
                                    		this.updateCurrencyRate(rest);
                                    		return;
                                    	}

                                    	const unbilledInvoices = await this.checkForExistingBills(data.id);

                                    	if (unbilledInvoices?.invoices.length > 0 || unbilledInvoices?.bills.length > 0) {
                                    		this.setState({
												dialogData: {
													onSave: () => this.updateCurrencyRate(rest),
													onSaveAndEdit: () => this.updateCurrencyRate(rest, unbilledInvoices),
													invoices: unbilledInvoices,
													newRate: rest.currency_rate,
													currencySymbol: returnCurrencyList().find(rc => rc.id == rest.currency_label).symbol
												},
											});
											this.openDialog('rateChanceForInvoicesDialog');

                                    	} else {
	                                    	this.updateCurrencyRate(rest);                                  		
                                    	}

                                    },
                                    onDelete: async (data) => {

								        if (data.id < 0) {
								        	this.setState({currencyRates: cloneDeep(currencyRates).filter(r => r.id !== data.id)});
								            return;
								        }

								        const unbilledInvoices = await this.checkForExistingBills(data.id);
								        if (unbilledInvoices.length > 0) {
								            enqueueSnackbar(`${this.tr('The rate is currently in use on the following waiting invoices')} - ${unbilledInvoices.map(u => u.bill_id).join(', ')}`, {
								                variant: "error",
								                preventDublicate: true
								            });
								            return; 								        	
								        }

                                        data.deleted = 1;
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: () =>
                                                	DataHandler.delete({url: `settings/company/${company}/currency_rate`}, {...data})
                                                .done(respData => {
                                                    this.fetchInvoicingSettings();
                                                }).fail(err => {
                                                	console.log(err)
                                                }),
                                                text:tr("If you delete ${label} you will not be able to use it on invoices. Proceed?", {label: data.currency_label}) ,
                                            },
                                        });
                                        this.openDialog("confirmation");
                                    },
                                    returnTab: this.state.selectedTab,
                                    allRates: currencyRates,
                                    enqueueSnackbar: enqueueSnackbar,
                                    defaultCurrency: this.state.defaultCurrency,
                                    translations: {
                                    	validFromWarning: this.tr('New currency rate must start in the future'),
                                    	existingDateWarning: this.tr('This currency already has a rate starting from this date'),
                                    	noLabelWarning: this.tr('You must choose a currency!'),
                                    	delete: this.tr("DELETE"),
                                    	save: this.tr("Save"),
                                    	active :this.tr("Active"),
                                    	pending: this.tr("Pending"),
                                    	archived: this.tr("Archived")
                                    }
                                }}
                                data={filteredCurrencyRates()}
                                />
						</div>}						
					</React.Fragment>
				)}
				{Dialog && (
					<Dialog
						open
						onDialogClose={this.closeDialog}
						onDialogSave={this.confirmDialog}
						data={this.state.dialogData}
					/>
				)}
			</div>
		);
	}
}

Invoice.defaultProps = {
    enqueueSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(Invoice);
