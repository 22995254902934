import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "../../general/OutlinedField";
import Button from '@mui/material/Button';
import DataHandler from '../../general/DataHandler';
import { withSnackbar } from 'notistack';

const styles = theme => ({
});

class TermsAndConditions extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/TermsAndConditions");

        this.state = {
            loaded: false,
            canJoin: true,
        }
    }
 
    render() {
        const { tr } = this;
        return <React.Fragment>
            <div style={{textAlign: 'center'}}>
                <a href={this.context.userObject.language == "fi" ? "https://support.taimer.com/fi/terms-of-service-taimer.com-1" : "https://support.taimer.com/terms-of-service-taimer.com"} target="_blank" rel="noopener noreferrer">{tr("Terms of Services")}</a>
                 |
                <a href={this.context.userObject.language == "fi" ? "https://support.taimer.com/fi/privacy-policy-taimer.com-1" : "https://support.taimer.com/privacy-policy-taimer.com"} target="_blank" rel="noopener noreferrer">{tr("Privacy Policy")}</a>
            </div>
        </React.Fragment>
    }
}

export default withSnackbar(withStyles(styles)(TermsAndConditions));