import React from 'react';
import TaimerComponent from '../TaimerComponent';
import EmbedVideoOverlay from './EmbedVideoOverlay';
import styles from './TabInfoButton.module.scss';
import { Button, MenuItem } from '@mui/material';
import ContextMenu from './ContextMenu';

const articleIcon = require('./icons/tab_explainers_article.svg').default;
const videoIcon = require('./icons/tab_explainers_video.svg').default;

class TabInfoButton extends TaimerComponent {
    icons = {
        article: articleIcon,
        video: videoIcon,
    };
    labels = {
        article: this.tr('Read article'),
        video: this.tr('Watch video'),
    };
    render() {
        const { title, methods } = this.props;
        const methodKeys = Object.keys(methods);
        const hasSingleMethod = methodKeys.length == 1;
        return (
            <div className={styles.tabInfoButton}>
                {hasSingleMethod ? (
                    <Button onClick={() => window.open(methods[methodKeys[0]], '_blank')}>
                        <img src={this.icons[methodKeys[0]]} />
                        {title}
                    </Button>
                ) : (
                    <ContextMenu
                        label={title}
                        buttonProps={{
                            stickyIcon: true,
                            size: 'large',
                        }}
                        className={styles.contextMenu}
                        noExpandIcon={hasSingleMethod}
                        variant="outlined"
                        size="medium"
                        placement={'bottom-end'}
                    >
                        {methodKeys.map((method) => {
                            return (
                                <MenuItem
                                    key={method}
                                    onClick={() =>
                                        method == 'video'
                                            ? this.context.functions.setOverlayComponent(<EmbedVideoOverlay title={title} url={methods[method]} />)
                                            : window.open(methods[method], '_blank')
                                    }
                                >
                                    <img src={this.icons[method]} />
                                    <p>{this.labels[method]}</p>
                                </MenuItem>
                            );
                        })}
                    </ContextMenu>
                )}
            </div>
        );
    }
}

export default TabInfoButton;
