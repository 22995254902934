import { GridViewData, GridViewDataItem, Project, Resource, Task, TaskStatus, TaskType, UserAvailabilityData, UserHour, UserHourPeriod } from "../../resourcing";
import _ from 'lodash';
import { Employee } from "../../helpers";
import { ProjectsShowMode, ResourcingFilters, ViewOptions } from "../../ResourcingView";

interface HoursFields {
    allocated: number;
    resourced: number;
    tracked: number;
    remaining: number;
    allocated_all_time: number;
    resourced_all_time: number;
    tracked_all_time: number;
    remaining_all_time: number;
}

interface GridRowCommon {
    /**
     * Edit Type
     */
    type: 'project' | 'task' | 'total' | 'task_new';
    /**
     * 
     */
    display_type: 'user' | 'project' | 'task',
    /**
     * Unique ID (for building tree)
     */
    row_id: string;
    /**
     * Row ID of root level
     */
    root_row?: string | null;
    /**
     * Row ID of parent
     */
    parent_row: string;
    /**
     * Level (starting from 0) for styling
     */
    level: number;
    /**
     * Project for row
     */
    project?: Project;
    /**
     * User for row
     */
    user?: Employee;
    /**
     * Task for row
     */
    task?: Task;
    task_type?: 'project' | 'task';

    gridData?: GridViewData | null;
    gridDataAvailability?: GridViewData | null;
    editable: boolean;
    hoursEditable?: boolean;

    allocated?: number | null;
    allocated_all_time?: number | null;
    resourced?: number;
    resourced_all_time?: number;
    tracked?: number;
    tracked_all_time?: number;
    remaining?: number;
    remaining_all_time?: number;

    // Filled in Grid.jsx / _getParsedDataLevel
    isSub?: boolean,
    open?: boolean;
    hasSubItems?: boolean;
}

interface GridRowTotal extends GridRowCommon {
    type: 'total';
    editable: false;
    hoursEditable: false;
}
export interface GridRowProject extends GridRowCommon {
    type: 'project';
}
interface GridRowTaskExisting extends GridRowCommon {
    type: 'task';
    task: Task,
    project: Project;
    user: Employee;
}
interface GridRowTaskNew extends GridRowCommon {
    type: 'task_new';
    task: undefined;
    task_type: 'project' | 'task';
    project: Project;
    user: Employee;
}

interface GridBuildOptions {
    filters: ResourcingFilters;
    unassignedLabel: string;
    grouping: string;
    employees: Dictionary<Employee>;
    projects: Project[];
    resources: Resource[];
    useProjectTasks?: boolean;
    showUsersWithoutTasks?: boolean;
    showUsersWithoutProjects?: boolean;
    containsPartialProjects?: boolean;
    viewOptions: ViewOptions;
    availability: Dictionary<UserAvailabilityData>;
    usersAllowedToShow: false|Dictionary<number>;
}

export type GridRowTask = GridRowTaskExisting | GridRowTaskNew;

export type GridRow = GridRowProject | GridRowTask | GridRowTotal;

type UserTaskPeriod = Omit<UserHourPeriod, 'key'>;

interface UserTask extends HoursFields {
    user?: Employee;
    userHours?: UserHour;
    task: Task;
    gridData?: GridViewData | null;
    periods: UserTaskPeriod[];
}


export function formatDataForGrid(options: GridBuildOptions): GridRow[] {
    const { availability, grouping, employees, projects, resources, viewOptions, usersAllowedToShow } = options;
    
    const tasks: UserTask[] = [];

    const unassginedUser: Employee = {
        id: 0,
        companies_id: 0,
        deleted: false,
        locked: 0,
        name: options.unassignedLabel,
        projects: [],
        read_companies: [],
        read_companies_all: [],
        read_companies_projects_only: [],
    }

    const allUsers: Dictionary<boolean> = {};
    const allProjects: Dictionary<boolean> = {};
    const projectsById = _.keyBy(projects, x => x.projects_id);

    const projectsForUser: Dictionary<Dictionary<boolean>> = {};
    const usersForProject: Dictionary<Dictionary<boolean>> = {};

    for (const p of projects) {
        for (const u of p.users) {
            if (!projectsForUser[u]) {
                projectsForUser[u] = {};
            }
            if (!usersForProject[p.projects_id]) {
                usersForProject[p.projects_id] = {};
            }

            projectsForUser[u][p.projects_id] = true;
            usersForProject[p.projects_id][u] = true;
        }
    }

    for (const r of resources) {
        if (!viewOptions.showOnGoing && r.type === 'task' && r.status === TaskStatus.Ongoing) {
            continue;
        }

        if (!viewOptions.showOverdue && r.type === 'task' && r.status === TaskStatus.Overdue) {
            continue;
        }

        if (!viewOptions.showDone && r.type === 'task' && r.status === TaskStatus.Done) {
            continue;
        }

        if (r.type === 'task') {
            allProjects[r.projects_id] = true;

            if ((usersAllowedToShow === false || usersAllowedToShow[0]) && (r.users_hours.length === 0 || r.unassignedHours)) {
                // Unassigned
                tasks.push({
                    user: unassginedUser,
                    task: r,
                    allocated: r.unassignedHours?.resourced ?? 0,
                    resourced: 0,
                    remaining: 0,
                    tracked: 0,
                    allocated_all_time: r.unassignedHours?.resourced_all_time ?? 0,
                    resourced_all_time: 0,
                    remaining_all_time: 0,
                    tracked_all_time: 0,
                    gridData: r.unassignedHours?.gridData,
                    periods: [
                        {
                            days: 0,
                            start: r.start_date,
                            end: r.end_date,
                            hours: r.hours,
                        }
                    ]
                });

                allUsers[0] = true;
                if (!projectsForUser[0]) {
                    projectsForUser[0] = {};
                }
                if (!usersForProject[r.projects_id]) {
                    usersForProject[r.projects_id] = {};
                }

                projectsForUser[0][r.projects_id] = true;
                usersForProject[r.projects_id][0] = true;
            }

            for (const uh of r.users_hours) {
                const user = uh.users_id === 0 ? unassginedUser : employees[uh.users_id];

                if (uh.done && !viewOptions.showDone)
                    continue;

                if (usersAllowedToShow !== false && !usersAllowedToShow[uh.users_id])
                    continue;

                if (!user) {
                    console.error('missing user', uh)
                    continue;
                }

                allUsers[user.id] = true;

                if (!projectsForUser[user.id]) {
                    projectsForUser[user.id] = {};
                }
                if (!usersForProject[r.projects_id]) {
                    usersForProject[r.projects_id] = {};
                }

                projectsForUser[user.id][r.projects_id] = true;
                usersForProject[r.projects_id][user.id] = true;

                tasks.push({
                    user,
                    userHours: uh,
                    task: r,
                    gridData: uh.gridData,
                    allocated: uh.periodTotal?.resourced || 0,
                    tracked: uh.periodTotal?.tracked || 0,
                    remaining: uh.periodTotal?.remaining ?? 0,
                    resourced: uh.periodTotal?.resourced || 0,
                    allocated_all_time: uh.allTotal?.resourced ?? 0,
                    tracked_all_time: uh.allTotal?.tracked ?? 0,
                    remaining_all_time: uh.allTotal?.remaining ?? 0,
                    resourced_all_time: uh.allTotal?.resourced ?? 0,
                    periods: uh.is_split ? uh.periods : [
                        {
                            days: uh.days,
                            hours: uh.hours,
                            start: r.start_date,
                            end: r.end_date,
                        }
                    ],
                });
            }
        }
    }

    if (grouping === "user") {
        const tasksByUser = _.groupBy(tasks, x => x.user?.id ?? 0);

        const rows = _.chain(allUsers)
            .map((v, k) => ({ id: k, user: Number(k) === 0 ? unassginedUser : employees[k], tasks: tasksByUser[k] || [] }))
            .sortBy((v) => v.user?.name ?? "")
            .map(({ id, user, tasks }) => {
                if (!user) {
                    console.error('missing user', id);
                    return [];
                }

                const rowSet: GridRow[] = [];
                const [levelSum, userTotals, numberOfTasks] = sumOfTasks(tasks, user && availability[user.id]?.gridData);

                const userGroup: GridRowTotal = {
                    type: 'total',
                    level: 0,
                    display_type: 'user',
                    row_id: `u${id}`,
                    parent_row: '',
                    gridData: levelSum,
                    user,
                    project: undefined,
                    editable: false,
                    hoursEditable: false,
                    ...userTotals,
                    // allocated: null,
                };
                rowSet.push(userGroup);

                const tasksByProject = _.chain(tasks)
                    .groupBy(x => x.task.projects_id)
                    .value();

                const projectsToShow = _.chain(projectsForUser[id])
                    .map((v, k) => ({ id: k, project: projectsById[k], tasks: tasksByProject[k] || [] }))
                    .sortBy((v) => v.project?.text ?? "")
                    .value();

                for (const { id, project, tasks } of projectsToShow) {
                    if (!project) {
                        console.error('missing project', id);
                        continue;
                    }

                    const hasNonProjectTask = !!tasks.find(x => x.task.task_type !== TaskType.Project);
                    const projectTask = tasks.find(x => x.task.task_type === TaskType.Project);

                    if (viewOptions.projectsShowMode === ProjectsShowMode.WithTasks && tasks.length === 0) {
                        continue;
                    } else if (viewOptions.projectsShowMode === ProjectsShowMode.WithoutTasks && tasks.length > 0) {
                        continue;
                    }

                    // Special Case: Show project resourcing on top level
                    // if there's no tasks
                    if (options.useProjectTasks && !hasNonProjectTask) {
                        if (projectTask) {
                            const pt: GridRowTask = {
                                ...buildTaskRow(userGroup, projectTask, user, project),
                                display_type: 'project',
                            }
                            rowSet.push(pt);
                        } else if (!options.containsPartialProjects) {
                            const pt: GridRowTask = {
                                ...emptyProjectTaskRow(userGroup, user, project),
                                display_type: 'project',
                            };
                            rowSet.push(pt);
                        }
                    }
                    else {
                        const [projectSum, projectTotal] = sumOfTasks(tasks);
                        const projectTotalRow: GridRowTotal = {
                            type: 'total',
                            level: userGroup.level + 1,
                            display_type: 'project',
                            row_id: `${userGroup.row_id}_p${project.projects_id}`,
                            parent_row: userGroup.row_id,
                            root_row: userGroup.row_id,
                            project,
                            user,
                            gridData: projectSum,
                            editable: false,
                            hoursEditable: false,
                            ...projectTotal,
                        };
                        rowSet.push(projectTotalRow);

                        if (options.useProjectTasks && !projectTask && user.id && !options.containsPartialProjects) {
                            rowSet.push(emptyProjectTaskRow(projectTotalRow, user, project));
                        }

                        const formattedTasks = _.map(tasks, task => buildTaskRow(projectTotalRow, task, user, project));
                        for (const task of formattedTasks) {
                            rowSet.push(task);
                        }
                    }
                }

                return rowSet;
            })
            .flatten()
            .value();

        return rows;
    } else {
        const tasksByProject = _.groupBy(tasks, x => x.task.projects_id);

        const rows = _.chain(allProjects)
            .map((v, k) => ({ id: k, project: projectsById[k], tasks: tasksByProject[k] || [] }))
            .sortBy((v) => v.project?.text ?? "")
            .map(({ id, project, tasks }) => {
                if (!project) {
                    console.error('missing project', id);
                    return [];
                }

                const rowSet: GridRow[] = [];
                const [levelSum, levelTotal, numberOfTasks] = sumOfTasks(tasks, null);

                if (viewOptions.projectsShowMode === ProjectsShowMode.WithTasks && numberOfTasks === 0) {
                    return rowSet;
                } else if (viewOptions.projectsShowMode === ProjectsShowMode.WithoutTasks && numberOfTasks > 0) {
                    return rowSet;
                }

                const projectGroup: GridRowTotal = {
                    type: 'total',
                    display_type: 'project',
                    row_id: `p${project.projects_id}`,
                    parent_row: '',
                    level: 0,
                    gridData: levelSum,
                    project,
                    user: undefined,
                    editable: false,
                    hoursEditable: false,
                    ...levelTotal,
                    // allocated: project.budgeted,
                };
                rowSet.push(projectGroup);

                const tasksByUser = _.chain(tasks)
                    .groupBy(x => x.user?.id ?? 0)
                    .value();

                const usersToShow = _.chain(usersForProject[project.projects_id])
                    .map((v, k) => ({ id: k, user: employees[k] ?? unassginedUser, tasks: tasksByUser[k] || [] }))
                    .sortBy((v) => v.user?.name ?? "")
                    .value();

                for (const { id, user, tasks } of usersToShow) {
                    if (!user && id) {
                        console.error('missing user', id);
                    }

                    const hasNonProjectTask = !!tasks.find(x => x.task.task_type !== TaskType.Project);
                    const projectTask = tasks.find(x => x.task.task_type === TaskType.Project);
                    const [sum, sumTotals, numberOfTasks] = sumOfTasks(tasks, user ? availability?.[user.id]?.gridData : null);

                    if (!options.showUsersWithoutTasks && numberOfTasks === 0) {
                        continue;
                    }

                    // Special Case: Show project resourcing on top level
                    // if there's no tasks
                    if (options.useProjectTasks && !hasNonProjectTask) {
                        if (projectTask) {
                            const pt: GridRowTask = {
                                ...buildTaskRow(projectGroup, projectTask, user, project),
                                display_type: 'user',
                            }
                            rowSet.push(pt);
                        } else if (!options.containsPartialProjects) {
                            const pt: GridRowTask = {
                                ...emptyProjectTaskRow(projectGroup, user, project),
                                display_type: 'user',
                            };
                            rowSet.push(pt);
                        }
                    }
                    else {
                        const userTotalRow: GridRowTotal = {
                            type: 'total',
                            display_type: 'user',
                            row_id: `${projectGroup.row_id}_u${user.id}`,
                            parent_row: projectGroup.row_id,
                            level: projectGroup.level + 1,
                            root_row: projectGroup.root_row,
                            project,
                            user,
                            gridData: sum,
                            editable: false,
                            hoursEditable: false,
                            ...sumTotals,
                            allocated: null,
                        };
                        rowSet.push(userTotalRow);

                        if (options.useProjectTasks && !projectTask && user.id && !options.containsPartialProjects) {
                            rowSet.push(emptyProjectTaskRow(userTotalRow, user, project));
                        }

                        const formattedTasks = _.map(tasks, task => buildTaskRow(userTotalRow, task, user, project));
                        for (const task of formattedTasks) {
                            rowSet.push(task);
                        }
                    }
                }

                return rowSet;
            })
            .flatten()
            .value();

        return rows;
    }
}

function emptyProjectTaskRow(parent: GridRow, user: Employee, project: Project): GridRowTask {
    return {
        type: 'task_new',
        task: undefined,
        display_type: 'task',
        task_type: 'project',
        row_id: `${parent.row_id}_pt_${user.id}`,
        root_row: parent.root_row,
        parent_row: parent.row_id,
        editable: true,
        hoursEditable: true,
        gridData: {
            daily: {},
            monthly: {},
            weekly: {},
        },
        level: parent.level + 1,
        project,
        user,
        allocated: 0,
        resourced: 0,
        tracked: 0,
        remaining: 0,
    };
}

function sumOfTasks(tasks: UserTask[], availability?: GridViewData | null | undefined): [GridViewData, HoursFields, number] {
    const sum: GridViewData = {
        daily: {},
        weekly: {},
        monthly: {},
    };
    const hours: HoursFields = {
        allocated: 0,
        resourced: 0,
        tracked: 0,
        remaining: 0,
        allocated_all_time: 0,
        resourced_all_time: 0,
        tracked_all_time: 0,
        remaining_all_time: 0,
    }
    let numOfTasks = 0;

    for (const task of tasks) {
        if (task.gridData) {
            addGridViewDataItem(sum.daily, task.gridData.daily);
            addGridViewDataItem(sum.weekly, task.gridData.weekly);
            addGridViewDataItem(sum.monthly, task.gridData.monthly);
        }

        hours.allocated += task.allocated;
        hours.resourced += task.resourced;
        hours.tracked += task.tracked;
        hours.remaining += Math.max(0, task.resourced - task.tracked);

        hours.allocated_all_time += task.allocated_all_time;
        hours.resourced_all_time += task.resourced_all_time;
        hours.tracked_all_time += task.tracked_all_time;
        hours.remaining_all_time += Math.max(0, task.resourced_all_time - task.tracked_all_time); 

        ++numOfTasks;
    }

    if (availability) {
        addGridViewDataItem(sum.daily, availability.daily);
        addGridViewDataItem(sum.weekly, availability.weekly);
        addGridViewDataItem(sum.monthly, availability.monthly);
    }

    return [sum, hours, numOfTasks];
}

function buildTaskRow(parent: GridRow, userTask: UserTask, user: Employee, project: Project): GridRowTask {
    const { task, gridData, allocated, allocated_all_time, resourced, resourced_all_time, tracked, tracked_all_time, remaining, remaining_all_time } = userTask;

    return {
        display_type: 'task',
        type: 'task',
        row_id: `${parent.row_id}_t${task.task_id}`,
        parent_row: parent.row_id,
        level: parent.level + 1,
        task,
        gridData,
        project,
        user,
        editable: task.editable,
        hoursEditable: task.editable && userTask.userHours && !userTask.userHours.done,
        allocated,
        allocated_all_time,
        resourced,
        resourced_all_time,
        tracked,
        tracked_all_time,
        remaining,
        remaining_all_time,
    };
}

export function addGridViewDataItem(target: Dictionary<GridViewDataItem>, source: Dictionary<GridViewDataItem>|null|undefined) {
    if (!source)
        return;

    _.forEach(source, (v, k) => {
        if (!target[k]) {
            target[k] = {
                hours: 0,
                percent: null,
            };
        }

        const t = target[k];

        if (v.hours !== undefined && v.hours !== null && t.hours !== undefined && t.hours !== null) {
            t.hours += v.hours;
        }

        if (v.percent !== undefined && v.percent !== null && t.percent !== undefined) {
            if (t.percent !== null)
                t.percent += v.percent;
            else
                t.percent = v.percent;
        }

        if (v.available !== undefined && v.available !== null) {
            if (t.available === undefined || t.available === null) {
                t.available = 0;
            }
            t.available += v.available;
        }
    });
}