import React from 'react';

import $ from 'jquery';

import FileSaver from 'file-saver';
import { format, isValid, addDays, parse } from "date-fns";

/* context */
import { SettingsContext } from './../../SettingsContext';

import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import LinkListCell from "../LinkListCell";
import MultiLinkListCell from "../cells/MultiLinkListCell";
import CurrencyListCell from "../CurrencyListCell";
import CheckboxCell from "../cells/CheckboxCell";
import TextInputCell from "../cells/TextInputCell";
import AutoCompleteCell from "../cells/AutoCompleteCell";
import StatusCell from "../cells/StatusCell";
import DateCell from "../cells/DateCell";
import AttachmentCell from "../cells/AttachmentCell";
import ContextMenu from '../../general/ContextMenu';
import DataHandler from "./../../general/DataHandler";

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';

import rightArrow from './icons/right-arrow.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import {ReactComponent as Print} from '../../general/icons/Print.svg';
import {ReactComponent as MaskAsPaid} from '../../general/icons/MarkAsPaid.svg';
import {ReactComponent as Note} from '../../general/icons/Note.svg';
import {ReactComponent as Notice} from '../../general/icons/Notice.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as MarkAsSendIcon } from '../../general/icons/MarkAsSend.svg';
import {ReactComponent as FormalizePreInvoice} from '../../general/icons/FormalizePre-invoice.svg'
import InvoiceTranslations from './../../general/backendTranslations/InvoiceTranslations';

import PrintIcon from '@mui/icons-material/Print';

import "./InvoiceListRow.css";
import { History } from '@mui/icons-material';
import colors from '../../colors';

class InvoiceListRow extends PropsOnlyListRow {

	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, undefined, undefined, "list/rows/InvoiceListRow");

		let functionBinds = ['cellEdited', 'getDisplayData', 'openAttachment', 'printPdf'];
		[...functionBinds].forEach(f => this[f] = this[f].bind(this));

		for (let field of this.props.columnWidths)
			if (field.type == 'date')
				this[field.name] = React.createRef();

	}

	cellEdited(name, value) {
		const params = { id: this.props.data.id, name: name, value: value };

        DataHandler.post({url: `invoices/modify`}, params).done(

	        response => {
	        	let obj = {};
	        	obj[name] = value;
				this.setData(obj);
	        }
        );
	}

	openAttachment() {
		this.props.rowProps.attachmentHandler(this.props.data.id);
	}

	getDisplayData() {

		const statuses = {
			"0": {"name": this.tr("all"), "color": "#dde3e8"},
			"5": {"name": this.tr("draft"), "color": "#979797", "trigger_id": 0},
			"1": {"name": this.tr("waiting"), "color": "#ffb822", "trigger_id": 1},
			"2": {"name": this.tr("sent"), "color": "#2d9ff7", "trigger_id": 2},
			"4": {"name": this.tr("paid"), "color": colors.greenish_cyan, "trigger_id": 3},
			"3": {"name": this.tr("credited"), "color": "#716aca", "trigger_id": 4},
			"6": {"name": this.tr("overdue"), "color": "#f52b2b", "trigger_id": 5}
		};

		let pr = this.props.data,
			data = statuses[pr['state']];
 

		if (pr['bill_type'] == "2")
			data = statuses['5'];

		if (pr.duedate < format(new Date(), 'YYYY-MM-DD') && pr.state == "2" && (pr.type == '1' || pr.type == '3'))
			data = statuses['6'];
		return data;
	}

	/* showInvoice() {
		window.location.href = `index.php?module=invoices&action=view&id=${this.props.data.id}&billid=${this.props.data.bill_id}`
	} */

	async printPdf(lang) {
        
        let langs = [lang];
		if (!langs || langs[0] == undefined || langs.length < 1)
			langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: [this.props.data.id]});

		const transl = new InvoiceTranslations().returnTranslations(langs);
      
		DataHandler.postArrayBuffer({url: 'invoices/print_pdf', ids: this.props.data.id, lang: lang}, {translations: transl})
        .done((response) => {
            var blob = new Blob([response], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob, 'invoice_' + this.props.data.bill_id +"_" + this.props.data.projects + ".pdf");

        });
	}

	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setState({ data: nextProps.data });
			return false;
		}

		return true;
	}

	//creates link data for cell
 	createLinkData = (name, data) => {
		const modules = {
			customer: 'customers',
			projects: 'projects',
			edited: 'persons',
			bill_id: 'invoices'
		},
			pickId = (n) => ({
			  "customer": `${modules[name]}_id`,
			  "projects": 'all_project_ids',
			  "edited": 'users_id',
			  "bill_id": 'id'

		})[n],
			id = data[pickId(name)].split(',');

		return {pathname: 'index.php',
			module: modules[name],
			action: `view${name == 'bill_id' ? '_new' : ''}`,
			id: (id.length > 1 ? id : id[0])
		}
	}

	defineCells() {

		const { taimerAddons, taimerAccount } = this.context;
		const { rowProps } = this.props;

		const className = `invoiceListRow listElement row mainLevel`,
			{checked } = this.props,
			statusDisplay = this.getDisplayData();
		const data = this.props.data;
		const rowStyle = { height: "44px", lineHeight: "44px" , display: this.props.rowProps.rowDisplay};

        const cells   = {};
        const cellArr = this.props.columnOrder.map(columnName => {

					const params = this.props.columnWidths.filter(col=>col.name == columnName)[0];
					const width = params.width;
					let fieldType = "";
					if (columnName === "duedate" || columnName === "deliverydate") {
						if ((data.state == 1 && data.bill_type == 1) || (data.state == 10 && data.bill_type == 2))
							fieldType = "date_editable";
						else
							fieldType = "date";
					} else {
						fieldType = this.props.columnConfig[columnName].fieldType;
					}
					const value = columnName == 'customer' ? data.company : data[columnName];
					const { id, state, bill_type, type } = data;

					const commonProps = {
						width: width,
						value: value,
						name: columnName
					};

					const isCurrencyRatedInvoice = !['', rowProps.currency].includes(data.currency_code) && (!['0', 0, '', 1, '1', '1.000000'].includes(data.currency_rate))

					switch (fieldType) {
						case 'rowmenu':
							return <ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: width, flex: `${width} 1 0`}} noExpandIcon>
			                    <MenuItem
			                    	key="show"
			                    	onClick={(evt) => rowProps.updateView({ module: 'invoices', action: 'view', id: data.id, billid: data.bill_id, editMode: false }, evt.button === 1)} >
			                    		<ViewIcon title="" /> {this.tr('Show')}
			                    </MenuItem>
			                    {(state == '1' && bill_type != '2') && <MenuItem
			                    	key="send"
			                    	onClick={()=>rowProps.sentHandler([id])} >
			                    		<MarkAsSendIcon />{this.tr('Mark as sent')}
			                    </MenuItem>}
			                    {(state == "2" && bill_type != '2') && <MenuItem
			                    	key="pay"
			                    	onClick={()=>rowProps.markPaidHandler(id)} >
			                    		<MaskAsPaid />{this.tr('Mark as paid')}
			                    </MenuItem>}
			                    {(!new Date(this.props.data.duedate) < new Date() && state == "2" && bill_type != '2' && type == '1') && <MenuItem
			                    	key="notice"
			                    	onClick={()=>rowProps.createNoticeHandler('createNotice', id)} >
			                    		<Notice />{this.tr('Create payment notice')}
			                    </MenuItem>}
			                    {(['2', '4'].includes(state) && bill_type != '2' && type == '1' && this.props.data.credited_status == '') && <MenuItem
			                    	key="note"
			                    	onClick={()=>rowProps.createCreditHandler('createCredit', id)} >
			                    		<Note />{this.tr('Create credit note')}
			                    </MenuItem>}
			                    { bill_type == '2' && <MenuItem
			                    	key="formalize"
			                    	onClick={()=>rowProps.formalizeHandler('formalize', id)} >
			                    		<FormalizePreInvoice />{this.tr('Formalize pre-invoice')}
			                    </MenuItem>}
								<MenuItem key={'print'} onClick={() => rowProps.print(undefined, id)}> <PrintIcon />{this.tr('Print')}</MenuItem>
								{rowProps.printLanguage != 'en' && <MenuItem key={'print_en'} onClick={() => rowProps.print('en', id)}><PrintIcon />{this.tr('Print in english')}</MenuItem>}			                    
								{ bill_type == '2' && <MenuItem
									key="delete"
									className="delete"
			                    	onClick={()=>rowProps.deleteHandler('delete', id)} >
			                    		<RemoveIcon className="Delete" />{this.tr('Remove pre-invoice')}
			                    </MenuItem>}
								<MenuItem
			                    	key="showLog"
			                    	onClick={() => rowProps.showInvoiceLogSlider({ id: data.id, bill_id: data.bill_id, company: data.companies_id })}>
			                    		<History title="" /> {this.tr('Show Invoice Log')}
			                    </MenuItem>
			                </ContextMenu>;
						break;
						case 'check':
							return <CheckboxCell
								{...commonProps} 
								checked={checked}
								onClick={() => this.props.listRef.check(this.props.data.id)} />;
						break;
						case 'attachment':
							return <AttachmentCell 
								{...commonProps} 
								onClick={() => this.openAttachment()} />;
						break;
						case 'special':
							return <StatusCell 
								{...commonProps} 
								displayData={statusDisplay}/>;
						break;
						case 'date_editable':
							return <DateCell 
								{...commonProps} 
								onEdited={this.cellEdited} editable="true" listCellProps={{className: "date"}}/>;
						break;
						case 'date':
							return <DateCell 
								{...commonProps}
								value={(isValid(value) && value)}
								editable={false} />;
						break;
						case 'linktext':
							if(columnName == 'customer') {
								return <LinkListCell
									urlHandler={value => `index.php?module=customers&action=view&id=${this.props.data.customers_id}`}
									asText={this.props.data.can_see_account == 0}
									style={{width: this.props.columnWidthMap.customer + 'px'}}
									width={width}
									value={this.props.data.company}
									editable={false}
									noTab={true} />		
							}
							return <LinkListCell
								urlHandler={value => `index.php?module=users&action=view&id=${this.props.data.users_id}`}
								style={{width: this.props.columnWidthMap.edited + 'px'}}
								width={width}
								value={this.props.data.users_company !== null && Number(this.props.data.users_company) < 1 ? `${this.props.data.edited} (${this.tr("freelancer")})` : this.props.data.edited}
								editable={false}
								noTab={true} />		
						break;
						case 'bill_link':
							return <TextInputCell
							listCellType={LinkListCell}
							listCellProps={{
								urlHandler: value => `index.php?module=invoices&action=view&id=${data.parent_id > 0 && columnName == 'parentid' ? data.parent_id : data.id}&billid=${data.parentid > 0 && columnName == 'parentid' ? data.parentid : data.bill_id}&editMode=false`,
								valueHandler: value => value,
								editable: false,
								noTab: true,
							}}
							name={columnName}
							value={columnName == 'parentid' ? 
								(data.parentid > 0 ? `${data.type == 2 ? this.tr('Refund invoice') : this.tr('Payment notice')}: ${value}` : '') :
								value}
							width={width} />;
						break;
						case 'multitext':
							return <MultiLinkListCell
								{...commonProps}
								editable={false}
								noTab={true}
								urlHandler={value => {
									let urls = [],
										ld = this.createLinkData(columnName, data),
										url = `${ld.pathname}?`,
										ids = Array.isArray(ld.id) ? ld.id : [ld.id];

									Object.keys(ld).map(function(k, i) {
										url = `${url}${i > 0 && i < 3 ? `${k}=${ld[k]}&` : ''}`;
									});

									for (let id of ids) {
										urls.push(`${url}id=${id}`);
									}
									return urls;
								}} />;
						break;
						case 'currency':
							return <CurrencyListCell 
								{...commonProps}
								currency={rowProps.currency}
								editable={false} />;
							break;
						case 'paid':
							return <CurrencyListCell 
								{...commonProps}
								value={isCurrencyRatedInvoice ? (data.has_old_currency_convert > 0 ? value : (value / parseFloat(data.currency_rate)).toFixed(2)) : value}
								currency={rowProps.currency}
								editable={false} />;
							break;							
						case 'rate_currency':
							return <ListCell 
								{...commonProps}
								value={isCurrencyRatedInvoice ? (data.has_old_currency_convert > 0 ? `${data.total_in_currency} ${data.currency_symbol}` : `${(Number(data.net_total) * parseFloat(data.currency_rate)).toFixed(2)} ${data.currency_symbol}`) : ''}
								textAlign="right"
								editable={false} />;
							break;
						case 'rate_text':
							return <ListCell 
								{...commonProps}
								value={isCurrencyRatedInvoice ? value : ''}
								editable={false}
							/>;														
						default:
							return <ListCell 
								{...commonProps}
								editable={false}
							/>;
						break;
					}
				});

        cellArr.forEach((c, i) => {
            cells[this.props.columnOrder[i]] = c;
        });

        return cells;
	}
}

InvoiceListRow.defaultProps = {
	children: []
};

export default InvoiceListRow;
