import React from 'react';
import { Grid as VirtualizedGrid, List as VirtualizedList, AutoSizer } from 'react-virtualized';

import TaimerComponent from '../TaimerComponent';

import './RevenueRecognitionTable.css';

class RevenueRecognitionTableFooter extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/RevenueRecognitionTableFooter');
        this.grid = React.createRef();
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.data != this.props.data) {
            this.grid.current.forceUpdate();
        }

        if (oldProps.editMode != this.props.editMode) {
            this.grid.current.forceUpdate();
        }
    };

    _renderValue = (value, columnTotal, isTotal = false, month) => {
        return (
            <div className="text-container">
                <input disabled={true} value={this.props.formatInputValue(value, { columnTotal, isTotal, nonEditable: true, isFooter: true, month })} />
            </div>
        );
    };

    _gridCellRenderer = ({ columnIndex, key, style }) => {
        const { months, rowHeight, dateFormat, getUnallocatedValueForMonth, data } = this.props;
        const value = getUnallocatedValueForMonth(months[columnIndex]);
        const month = months[columnIndex].format(dateFormat);
        const columnTotal = data.totals[month] || 0;
        return (
            <div key={key} className={`cell ${columnIndex == months.length - 1 ? 'no-border' : ''}`} style={style}>
                <div style={{ height: rowHeight }} className="grid-cell main">
                    {this._renderValue(value, columnTotal, false, months[columnIndex])}
                </div>
            </div>
        );
    };

    _leftCellRenderer = ({ key, style }) => {
        const { rowHeight } = this.props;
        return (
            <div key={key} className="cell" style={style}>
                <div style={{ height: rowHeight }} className="left-cell main">
                    {this.tr('User unallocated')}
                </div>
            </div>
        );
    };

    _rightCellRenderer = ({ key, style }) => {
        const { rowHeight, getUnallocatedValueForMonth, months } = this.props;
        const rowTotal = months.reduce((prev, month) => (prev += getUnallocatedValueForMonth(month)), 0);
        return (
            <div key={key} className="cell" style={style}>
                <div style={{ height: rowHeight }} className="right-cell main">
                    {this._renderValue(rowTotal, 0, true)}
                </div>
            </div>
        );
    };

    _renderLeftTable = () => {
        const { leftTableWidth, rowHeight } = this.props;
        return (
            <div className="static-table-container">
                <VirtualizedList
                    className="no-outline no-scroll-bar left-table"
                    style={{
                        borderLeft: '1px solid #eee',
                    }}
                    width={leftTableWidth}
                    height={rowHeight}
                    rowCount={1}
                    rowHeight={rowHeight}
                    rowRenderer={this._leftCellRenderer}
                />
            </div>
        );
    };

    _renderRightTable = () => {
        const { verticalScrollBarWidth, rightTableWidth, rowHeight } = this.props;
        return (
            <div className="static-table-container">
                <VirtualizedList
                    className="no-outline no-scroll-bar right-table"
                    style={{
                        borderLeft: '1px solid #eee',
                        marginLeft: -verticalScrollBarWidth,
                    }}
                    width={rightTableWidth}
                    height={rowHeight}
                    rowCount={1}
                    rowHeight={rowHeight}
                    rowRenderer={this._rightCellRenderer}
                />
            </div>
        );
    };

    _renderGrid = () => {
        const { months } = this.props;
        const { verticalScrollBarWidth, horizontalScrollBarWidth, columnWidth, rowHeight, onScroll, scrollLeft } = this.props;
        return (
            <div className="grid">
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <div>
                            <VirtualizedGrid
                                ref={this.grid}
                                overscanColumnCount={0}
                                cellRenderer={this._gridCellRenderer}
                                columnCount={months.length}
                                className={`no-outline main-grid`}
                                scrollLeft={scrollLeft + 0.00001} //hack: force initial scrollLeft for footer when opening the user grid
                                onScroll={(e) => onScroll({ ...e, scrollTop: null })}
                                columnWidth={Math.max(columnWidth, (width - verticalScrollBarWidth) / months.length)}
                                height={rowHeight + horizontalScrollBarWidth}
                                rowCount={1}
                                rowHeight={rowHeight}
                                width={width}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
        );
    };

    render() {
        return (
            <div className="content">
                {this._renderLeftTable()}
                {this._renderGrid()}
                {this._renderRightTable()}
            </div>
        );
    }
}

export default RevenueRecognitionTableFooter;
