import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import { exportContext } from '../Taimer.js';

import { ReactComponent as PlusIcon } from './icons/add.svg';
import { ReactComponent as BillsIcon } from './icons/Bills.svg';
import { ReactComponent as BoardIcon } from './icons/Board.png';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as InvoiceIcon } from './icons/Invoice.svg';
import { ReactComponent as ProjectsIcon } from './icons/Leads_Deals.svg';
import { ReactComponent as ResourceIcon } from './icons/Resource-sidebar.svg';
import { ReactComponent as TimeTrackerIcon } from './icons/time tracker.svg';
import { ShoppingCart as PurchaseOrderIcon } from '@mui/icons-material';

import _ from 'lodash';

//HELLO FELLOW DEVS!
//If you add new notifications please update the latest runnning sub_type below so we don't have to keep searching for it.
//Current largest sub_type id: 30
//PS: PLEASE UPDATE CONFLUENCE DOCUMENTATION OR CREATE IT IF IT'S MISSING.


let NotificationsDefinitions = {
    getDefinitions: () => {
        var taimercomponent = new TaimerComponent({}, {}, "notifications/NotificationsDefinitions");

        const { tr } = taimercomponent;
        const context = exportContext();
        const definitions = {
            //timetracker
            1: {
                icon: <TimeTrackerIcon />,
                header: tr("Time Tracker"),
                notifications: [
                    {
                        sub_type: 1,
                        header: tr("Hourbalance limit exceeded"),
                        msg: tr("You have exceeded your minimum hour balance limit to ${hours}") + " " + tr("h"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read")},
                    }, {
                        sub_type: 2,
                        header: tr("Hourbalance limit exceeded"),
                        msg: tr("You have exceeded your maximum hour balance limit to ${hours}") + " " + tr("h"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read")},
                    }, {
                        sub_type: 3,
                        header: tr("Hourbalance limit exceeded"),
                        msg: tr("${user} has exceeded their minumum hour balance limit to ${hours}") + " " + tr("h"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 4,
                        header: tr("Hourbalance limit exceeded"),
                        msg: /* ${user} */ tr("${user} has exceeded their maximum hour balance limit to ${hours}") + " " + tr("h"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 5,
                        header: tr("New approvable overtime entry"),
                        msg: tr("${user} has made a new overtime entry for the day ${date}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), approve_overtime: tr("Approve"), view_overtime: tr("View approvable overtime")},
                    }, {
                        sub_type: 6,
                        header: tr("Your overtime entry has been approved"),
                        msg: tr("${user} has approved your ${date} overtime entry"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read")},
                    }, {
                        sub_type: 7,
                        header: tr("Your overtime entry has been declined"),
                        msg: tr("${user} has declined your ${date} overtime entry. ${message}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read")},
                    }, {
                        sub_type: 8,
                        header: tr("New approvable overtime entry"),
                        msg: tr("${user} has modified an overtime entry for the day ${date}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_overtime: tr("View approvable overtime")},
                    }, {
                        sub_type: 22,
                        header: tr("Your workhour entries has been approved"),
                        msg: tr("${user} has approved ${hour_entries} workhour entries."),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_workhours: tr("View hours"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 23,
                        header: tr("Your workhour entries has been declined"),
                        msg: tr("${user} has declined ${hour_entries} workhour entries. ${message}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_workhours: tr("View hours"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 24,
                        header: tr("Hours for approval"),
                        msg: tr("${user} has sent ${hours} h for approval"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_approval_hours: tr("View hours"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 30,
                        header: tr("Your workhour entries has been deleted"),
                        msg: tr("${user} has deleted ${hour_entries} workhour entries. ${message}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), openchat: tr("Open private chat")},
                    }, {
                        sub_type: 31,
                        header: tr("Hours for approval"),
                        msg: tr("${user} has sent ${hours} h for approval for projects you're managing."),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_approval_hours: tr("View hours"), openchat: tr("Open private chat")},
                    }
                ],
                settings: [
                    {
                        //controls is an array containing the subtypes this setting affects
                        id: 1,
                        controls: [1, 2],
                        header: tr("Notify when my hour balance exceeds companylimit +/-"),
                        active: true,
                    }, {
                        id: 2,
                        controls: [3, 4],
                        header: tr("Notify when my team members hour balance exceeds company limit +/-"),
                        active: true,
                    }, {
                        id: 3,
                        controls: [5],
                        header: tr("Notify when my subordinates make overtime entries"),
                        active: true,
                    }, {
                        id: 4,
                        controls: [6, 7],
                        header: tr("Notify when my overtime entry is approved"),
                        active: true,
                    }, {
                        id: 35,
                        controls: [30],
                        header: tr("Get notified about deleted hours"),
                        active: true,
                    }, {
                        id: 36,
                        controls: [22, 23],
                        header: tr("Notify when my workhour entry is approved/declined"),
                        active: true,
                    }, {
                        id: 37,
                        controls: [24],
                        header: tr("Get notified about hours sent for approval"),
                        active: true,
                    }, {
                        id: 38,
                        controls: [31],
                        header: tr("Get notified about hours sent for approval for my projects"),
                        active: true,
                    }
                ]
            },
            2: {
                icon: <InvoiceIcon />,
                header: tr("Invoicing"),
                notifications: [
                    {
                        sub_type: 9,
                        header: tr("Invoice created"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} has been created for ${account}'s project ${projects}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 10,
                        header: tr("Invoice overdue"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} is overdue for ${account}'s project ${projects}."),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 11,
                        header: tr("Invoice paid"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} has been paid for ${account}'s project ${projects}."),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 12,
                        header: tr("Invoice created"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} has been created for ${account}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 13,
                        header: tr("Invoice overdue"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} is overdue for ${account}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 14,
                        header: tr("Invoice paid"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoice Nr: ${invoice_nr}, sum: ${invoice_total} has been paid for ${account}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_invoice: tr("View invoice")},
                    },
                    {
                        sub_type: 15,
                        header: tr("Invoices created: ${invoice_count}"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoices with a total sum of: ${invoice_total} have been created to ${account_count} accounts and ${project_count} projects"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), list_invoices: tr("List invoices")},
                    },
                    {
                        sub_type: 16,
                        header: tr("Invoices overdue: ${invoice_count}"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoices with a total sum of: ${invoice_total} are overdue for ${account_count} account and ${project_count} projects"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), list_invoices: tr("List invoices")},
                    },
                    {
                        sub_type: 17,
                        header: tr("Invoices paid: ${invoice_count}"),
                        //sub_header: tr("Your role(s): ${roles}"),
                        msg: tr("Invoices with a total sum of: ${invoice_total} have been paid for ${account_count} accounts and ${project_count} projects"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), list_invoices: tr("List invoices")},
                    }
                ],
                setting_groups: {
                    1: {
                        title: tr("When invoice is created"),
                        settings: [
                            {
                                //controls is an array containing the subtypes this setting affects
                                id: 5,
                                controls: [9, 15],
                                header: tr("Notify me for all new Invoices for projects I'm involved in."),
                                active: true,
                                allSelector: true
                            }, {
                                id: 6,
                                controls: [9, 15],
                                header: tr("Notify me when an invoice is created for a project where I'm the project manager"),
                                active: true,
                            }, {
                                id: 7,
                                controls: [9, 15],
                                header: tr("Notify me when an invoice is created for a project where I'm the sales agent"),
                                active: true,
                            }, {
                                id: 8,
                                controls: [9, 15],
                                header: tr("Notify me when an invoice is created for a project where I'm a team member"),
                                active: true,
                            },
                            {
                                id: 17,
                                controls: [12, 15],
                                header: tr("Notify me when an invoice is created for Accounts where I'm the Account manager and invoice is not for a project"),
                                active: true,
                            },
                            {
                                id: 18,
                                controls: [12, 15],
                                header: tr("Notify me when an invoice is created for Accounts where I'm a team member and invoice is not for a project"),
                                active: true,
                            }
                        ]
                    },
                    2: {
                        title: tr("When invoice status changes to overdue"),
                        settings: [
                            {
                                id: 9,
                                controls: [10, 16],
                                header: tr("Notify me for all overdue Invoices for projects I'm involved in."),
                                active: true,
                                allSelector: true
                            }, {
                                id: 10,
                                controls: [10, 16],
                                header: tr("Notify me when an invoice is overdue for a project where I'm the project manager"),
                                active: true,
                            }, {
                                id: 11,
                                controls: [10, 16],
                                header: tr("Notify me when an invoice is overdue for a project where I'm the sales agent"),
                                active: true,
                            }, {
                                id: 12,
                                controls: [10, 16],
                                header: tr("Notify me when an invoice is overdue for a project where I'm a team member"),
                                active: true,
                            },
                            {
                                id: 19,
                                controls: [13, 16],
                                header: tr("Notify me when an invoice is overdue for Accounts where I'm the Account manager and invoice is not for a project"),
                                active: true,
                            },
                            {
                                id: 20,
                                controls: [13, 16],
                                header: tr("Notify me when an invoice is overdue for Accounts where I'm a team member and invoice is not for a project"),
                                active: true,
                            }
                        ]
                    },
                    3: {
                        title: tr("When invoice status changes to paid"),
                        settings: [
                            {
                                id: 13,
                                controls: [11, 17],
                                header: tr("Notify me for all paid Invoices for projects I'm involved in."),
                                active: true,
                                allSelector: true
                            }, {
                                id: 14,
                                controls: [11, 17],
                                header: tr("Notify me when an invoice is paid for a project where I'm the project manager"),
                                active: true,
                            }, {
                                id: 15,
                                controls: [11, 17],
                                header: tr("Notify me when an invoice is paid for a project where I'm the sales agent"),
                                active: true,
                            }, {
                                id: 16,
                                controls: [11, 17],
                                header: tr("Notify me when an invoice is paid for a project where I'm a team member"),
                                active: true,
                            },
                            {
                                id: 21,
                                controls: [14, 17],
                                header: tr("Notify me when an invoice is paid for Accounts where I'm the Account manager and invoice is not for a project"),
                                active: true,
                            },
                            {
                                id: 22,
                                controls: [14, 17],
                                header: tr("Notify me when an invoice is paid for Accounts where I'm a team member and invoice is not for a project"),
                                active: true,
                            }
                        ]
                    }
                }
            },
            3: {
                icon: <ProjectsIcon />,
                header: tr("Projects"),
                notifications: [
                    context.functions.checkPrivilege('projects', 'read') ? {
                        sub_type: 18,
                        header: tr("New project created"),
                        msg: tr("${user} created a new project ${account} | ${project}"),
                        icon: <PlusIcon />,
                        actions: {markasseen: tr("Mark as read"), view_project: tr("View project")},
                    } : undefined,
                    {
                        sub_type: 19,
                        header: tr("Added to project"),
                        msg: tr("${user} added you as the Project Manager to project ${account} | ${project}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_project: tr("View project")},
                    },
                    {
                        sub_type: 20,
                        header: tr("Added to project"),
                        msg: tr("${user} added you as the Sales Agent to project ${account} | ${project}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_project: tr("View project")},
                    },
                    {
                        sub_type: 21,
                        header: tr("Added to project"),
                        msg: tr("${user} added as a Team Member to project ${account} | ${project}"),
                        icon: <InfoIcon />,
                        actions: {markasseen: tr("Mark as read"), view_project: tr("View project")},
                    },
                    context.addons.hubspot && context.functions.checkPrivilege('projects', 'read') ? {
                        sub_type: 10000,
                        header: tr("New project added from HubSpot"),
                        msg: "${account} | ${project} added from HubSpot to Taimer",
                        icon: <PlusIcon />,
                        actions: {markasseen: tr("Mark as read"), view_project: tr("View project")},
                    } : undefined,
                ],
                setting_groups: {
                    1: {
                        title: tr("When given a project role"),
                        settings: [
                            context.functions.checkPrivilege('projects', 'read') ? {
                                id: 23,
                                controls: [18],
                                header: tr("Notify me for all new projects created"),
                                active: true,
                            } : undefined,
                            {
                                id: 24,
                                controls: [19],
                                header: tr("Notify me when I'm added as the project manager"),
                                active: true,
                            },
                            {
                                id: 25,
                                controls: [20],
                                header: tr("Notify me when I'm added as the sales agent"),
                                active: true,
                            },
                            {
                                id: 26,
                                controls: [21],
                                header: tr("Notify me when I'm added as a team member"),
                                active: true,
                            },
                            context.addons.hubspot && context.functions.checkPrivilege('projects', 'read') ? {
                                id: 10000,
                                controls: [10000],
                                header: tr("Notify me when a project is added from HubSpot"),
                                active: false,
                            } : undefined
                        ]
                    }
                }
            },
            4: {
                icon: <PurchaseOrderIcon />,
                header: tr("Purchase orders"),
                notifications: [
                    {
                        sub_type: 26,
                        header: tr("New purchase order created"),
                        msg: tr("${user} created a new purchase order for project ${project}"),
                        icon: <PlusIcon />,
                        actions: {markasseen: tr("Mark as read"), view_po: tr("View purchase_order")},
                    },
                    {
                        sub_type: 27,
                        header: tr("Purchase order was synced"),
                        msg: tr("${user} synced purchase order PO${po_id} to bill ${bill_nr}"),
                        icon: <PlusIcon />,
                        actions: {markasseen: tr("Mark as read"), view_po: tr("View purchase order")},
                    },
                    {
                        sub_type: 28,
                        header: tr("Purchase order was synced"),
                        msg: tr("Purchase order PO${po_id} was automatically synced to bill"),
                        icon: <PlusIcon />,
                        actions: {markasseen: tr("Mark as read"), view_po: tr("View purchase order")},
                    },
                    {
                        sub_type: 29,
                        header: tr("Purchase orders were synced"),
                        msg: tr("Purchase orders ${po_ids} were automatically synced to bills"),
                        icon: <PlusIcon />,
                        actions: context.privileges.purchaseorders?.read ? {markasseen: tr("Mark as read"), view_po: tr("View purchase orders")} : {markasseen: tr("Mark as read")},
                    },
                ],
                setting_groups: {
                    1: {
                        title: tr("When a new purchase order is created"),
                        settings: [
                            {
                                id: 27,
                                controls: [26],
                                header: tr("Send notification from all"),
                                active: true,
                            },
                            {
                                id: 28,
                                controls: [26],
                                header: tr("For projects where I'm project manager"),
                                active: true,
                            },
                            {
                                id: 29,
                                controls: [26],
                                header: tr("For projects where I'm sales agent"),
                                active: true,
                            },
                            {
                                id: 30,
                                controls: [26],
                                header: tr("For projects where I'm team member"),
                                active: true,
                            }
                        ]
                    },
                    2: {
                        title: tr("When a purchase order is synced"),
                        settings: [
                            {
                                id: 31,
                                controls: [27, 28, 29],
                                header: tr("Send notification from all"),
                                active: true,
                            },
                            {
                                id: 32,
                                controls: [27, 28, 29],
                                header: tr("For projects where I'm project manager"),
                                active: true,
                            },
                            {
                                id: 33,
                                controls: [27, 28, 29],
                                header: tr("For projects where I'm sales agent"),
                                active: true,
                            },
                            {
                                id: 34,
                                controls: [27, 28, 29],
                                header: tr("For projects where I'm team member"),
                                active: true,
                            }
                        ]
                    }

                }
            }
        }
        _.forEach(definitions, type => {
            //filtering undefined integration notifications
            type.notifications = type.notifications.filter(n => n);
            if (type.setting_groups) {
                _.forEach(type.setting_groups, setting_group => {
                    //filtering undefined integration notification settings
                    setting_group.settings = setting_group.settings.filter(sg => sg);
                }); 
            }
        }) 
        return definitions;
    }
}

export default NotificationsDefinitions;
