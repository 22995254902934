import React, { Component } from 'react';

/* context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import DataHandler from './DataHandler';
import OutlinedField from './OutlinedField';
import FilterDropdown from './FilterDropdown';
import TaimerComponent from './../TaimerComponent';
import DataList from './DataList';
import { ReactComponent as ActiveArrow } from './icons/arrow active.svg';
import { ReactComponent as InActiveArrow } from './icons/arrow not active.svg';

/* material ui */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { FormControlLabel, Switch } from '@mui/material';

import _ from 'lodash';
import $ from 'jquery';

/* css */
import './ImportTool.css';

class ImportRow extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "general/ImportRow");

        this.state = {
            menuDirection: "bottom"
        }
        this.importRow = React.createRef();
    }

    getVisibleArea = (e) => {
        let $el = $(this.importRow.current),
        scrollTop = $($el).scrollTop(),
        elTop = $el.offset().top,
        visibleTop = elTop < scrollTop ? scrollTop : elTop,
        distance = this.props.distanceToBottom - visibleTop;
        if(distance < -375 && distance > -575 && this.state.menuDirection === "top") {
            this.setState({menuDirection: "bottom"})
        } else if (distance < -625 && this.state.menuDirection === "bottom") {
            this.setState({menuDirection: "top"});
        }
        
    }

    render(){
        const { rowData, options, dialogRef, exampleData } = this.props;
        return (            
            <div className="row-container" ref={this.importRow}>
                <div className="field left">
                    <span className="field-name" title={rowData.importField}>{rowData.importField + ": "}</span>
                    <span className="field-example">{exampleData ? exampleData : this.tr("No data found.")}</span>
                </div>
                {/* <OutlinedField className="import field left" value={rowData.importField + ":    " + (exampleData ? <span>{exampleData}</span> : <span>{this.tr("No data found.")}</span>)} disabled fullwidth /> */}
                <Button 
                    className={"import-button " + (!rowData.importable ? "middle" : "middle importable")} 
                    variant="text" onClick={() => {
                        this.props.saveChange({...rowData, importable: !rowData.importable})
                    }} >{rowData.importable ? <ActiveArrow /> : <InActiveArrow /> }</Button>
                <FilterDropdown 
                    className="taimer field right" 
                    value={rowData.taimerField} 
                    items={options} 
                    onFocus={e => { this.getVisibleArea()}}
                    onChange={e => {
                        this.props.saveChange({...rowData, taimerField: e.target.value,  importable: !!e.target.value.id})
                    }}
                    menuPortalTarget={dialogRef.current.firstChild}
                    menuPlacement={this.state.menuDirection}
					styles={{ menuPortal: base => ({ ...base, zIndex: 10000000 }) }}
                />
            </div>
        );
    }
}

class ImportTool extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        showDrop: false,
        dropContent: [],
        dropValue: 0,
        setDropValue: () => {},
    }

    constructor(props, context) {
        super(props, context, "general/ImportTool")
        
        let importObjects = {};
        let i = 1;
        _.forEach(this.props.importFields, (element => {
            if(element.taimerField && element.taimerField.id > 0 || element.importable) {
                importObjects[i] = element;
            } else {
                if (this.props.taimerFields[i]) {
                    importObjects[i] = {
                        importable: true,
                        importField: typeof(element) === "object" ? element.importField : element,
                        taimerField: {id: this.props.taimerFields[i].id, name: this.props.taimerFields[i].name},
                        id: i
                    }
                } else {
                    importObjects[i] = {
                        importable: false,
                        importField: typeof(element) === "object" ? element.importField : element,
                        taimerField: {id: this.props.taimerFields[0].id, name: this.props.taimerFields[0].name},
                        id: i
                    }
                }
            }    
            i++;
        }).bind(this));
        if(!this.props.back)
        this.props.setData(importObjects);

        this.state = {
            matchedFields: {},
            importFields: this.props.importFields || [],
            taimerFields: this.props.taimerFields || [],
            importObjects: importObjects,
            distanceToBottom: 0,
        }

        this.setImportObjects = this.setImportObjects.bind(this);
        this.importArea = React.createRef();
    }

    componentDidMount() {
        this.importArea.current.addEventListener("scroll", this.getVisibleArea);
    }

    componentWillUnmount() {
        this.importArea.current.removeEventListener("scroll", this.getVisibleArea);
    }

    getVisibleArea = (e) => {
        let $el = $(this.importArea.current),
        scrollTop = $($el).scrollTop(),
        elTop = $el.offset().top,
        visibleTop = elTop < scrollTop ? scrollTop : elTop;

        this.setState({distanceToBottom: visibleTop});
    }
    
    setImportObjects(importObject) {
        const holder = {...this.state.importObjects};
        holder[importObject.id] = importObject;    
        this.setState({importObjects: holder}, () => this.props.setData(this.state.importObjects))
    }

    render() {
        
        const {taimerFields, importObjects, distanceToBottom} = this.state;
        const { exampleData, extraSettings, } = this.props;
        const {tr} = this;
        
        return (
            <div className="field-wrapper" id="importArea" ref={this.importArea}>
                <div className="import-header">
                    {this.props.importHeader}
                </div>
                {
                    _.map(extraSettings, setting => {
                        const {label, content, header, value, type, show, subtext } = setting;
                        if (show) {
                            switch(type) {
                                case "dropdown": 
                                    return (
                                        <div className="optional-drop">
                                            {header != "" ? <h4>{header}</h4> : undefined}
                                            <div className="drop-container">
                                                <DataList label={label} options={content} value={value} onChange={(integration) => setting.onChange(integration)} />
                                            </div>
                                            {subtext != "" ? <span className="subtext">{subtext}</span> : undefined}
                                        </div>
                                    )
                                break;
                                case "switch":
                                    return (
                                        <div className="optional-switch">
                                            <FormControlLabel 
                                                control={
                                                    <Switch 
                                                    checked={value}
                                                    onChange={() => setting.onChange(!value)}
                                                    color="primary"
                                                    />
                                                }
                                                label={label}
                                            />
                                            {subtext != "" ? <span className="subtext">{subtext}</span> : undefined}
                                        </div>
                                    )
                                break;
                            }
                        }
                    }) 
                }
                <div className="headers">
                    <div className="header-container">
                        <h4>{tr("Importable Fields")}</h4>
                    </div>
                    <div className="header-spacer"></div>
                    <div className="header-container">
                        <h4>{tr("Taimer Fields")}</h4>
                    </div>
                </div>    
                {_.map(importObjects, (object, i) => {
                    return(
                        <ImportRow 
                            rowData={object}
                            options={taimerFields}
                            saveChange={this.setImportObjects} 
                            dialogRef={this.props.dialogRef}
                            areaRef={this.importArea}
                            distanceToBottom={distanceToBottom}
                            exampleData={exampleData && exampleData[i]}
                        />
                    )
                })}
            </div>
        );
    }
}

export default ImportTool;