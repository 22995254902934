import React from 'react';
import ListCell from "./../ListCell";
import Select, { components, createFilter } from 'react-select';
import isEqual from "lodash/isEqual"; 
import Creatable from 'react-select/creatable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { List, AutoSizer } from 'react-virtualized';
import TaimerComponent from '../../TaimerComponent';


const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

function createMenuList(addedProps) {
    return props => {
        if (!props.children.length) {
            return props.children;
        }

        const rowHeight = addedProps.rowHeight;
        const rows = props.children;

		let menuAutoWidth = addedProps.menuWidth || 'auto';
		if (rows && rows.length > 0 && !addedProps.menuWidth) {
			const len = Math.max(...rows.map(x => String(x.props.label || "").length));
			menuAutoWidth = (len + 4) + "ch";
		}
        const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
            <div data-testid={`autoCompleteCell-option_${index}`} key={key} style={style}>{rows[index]}</div>
        );

        return (
            <List
				style={{ width: menuAutoWidth, minWidth: "100%" }}
                width={1000}
                height={Math.min(rows.length * rowHeight, 300)}
                rowHeight={rowHeight}
                rowCount={rows.length}
                rowRenderer={rowRenderer}
            />
        )
    };
}

class AutoCompleteCell extends TaimerComponent {
	static defaultProps = {
		editable: true,
		listCellType: ListCell,
		listCellProps: {},
		selectProps: {},
		multiple: false,
		searchable: true,
        openMenuOnEdit: true,
        openMenuOnFocus: true,
		className: "",
		closeMenuOnSelect: true,
		tabSelectsValue: true,
		menuPosition: "absolute",
		menuPortalTarget: false,
		menuContainerStyle: {},
		allowCreate: false,
		value: {},
		autoCompleteData: [],
		autoCompleteLabelKey: "name",
		onEdited: () => {},
        onEdit: () => {},
		onEnter: () => {},
		width: 200,
		menuPlacement: 'auto',
		addNoneOption: false,
        rowHeight: 46,
		hideOptions: {},
	};


	constructor(props, context) {
		super(props, context, "list/cells/AutoCompleteCell");

		this.listCell 	  = React.createRef();
		this.autoComplete = React.createRef();

        this.focusOnEditor         = this.focusOnEditor.bind(this);
        this.handleOnEnterEditMode = this.handleOnEnterEditMode.bind(this);
    }


    // Avoid React Select's full rerender when nothing's changed.
    // Or something. I can't remember what was the point here.
    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props.value, nextProps.value) || !isEqual(this.props, nextProps);
    }


    focusOnEditor() {
        if(this.autoComplete.current === null) {
            return;
        }

	this.autoComplete.current.focus();
    }


    handleOnEnterEditMode() {
        if(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) {
            this.focusOnEditor();
        }
    }


    render() {
		// If a non-object value is given as a prop, but autoCompleteData has objects, find the object with that id.
		// TODO: add a prop that lets you define the relevant key.
		let value        = (typeof this.props.value !== "object" && this.props.autoCompleteData.length > 0 && typeof this.props.autoCompleteData[0] === "object") ? this.props.autoCompleteData.find(o => String(o.id) === String(this.props.value)) : this.props.value;
		if (this.props.showStringValue) value = this.props.value;
		let addedProps   = {};
        const SelectType = !this.props.allowCreate ? Select : Creatable;
        let styles;

		if(!value || value === undefined || value === null)
			value = {};
		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: this, 
			ref: this.listCell, 
			width: this.props.width, 
			value: value,
			placeholderOnEmpty: this.props.placeholderOnEmpty || false,
			placeholder: this.props.placeholder || "",
			editable: this.props.editable,
			className: this.props.cellClassName,
			onEnterEditMode: this.handleOnEnterEditMode,
            editable: this.props.editable
		}, this.props.listCellProps);

		if(!this.props.listCellProps.inEditMode)
			addedProps.menuIsOpen = this.props.openMenuOnEdit;

		if(this.props.addScrollX) {
			styles = {menuPortal: styles => ({...styles, zIndex: 9999, ...this.props.menuContainerStyle, left: styles.left + window.scrollX})}
		} else {
			styles = {menuPortal: styles => ({...styles, zIndex: 9999, ...this.props.menuContainerStyle})}
		}

		styles = {
			...styles,
			menu: (provided) => {
			  return {
				...provided,
				zIndex: 9999,
				minWidth: 'fit-content'
			  }
			}
		  };

		const prepareOptions = () => {
			const { hideOptions } = this.props;
			let optionArray = this.props.autoCompleteData.filter(f => f.deleted != 1)
				.map(el => {
					el['label'] = el.hasOwnProperty(this.props.autoCompleteLabelKey) ? el[this.props.autoCompleteLabelKey] : "(no label)";
					return el;
				});
			if (typeof hideOptions === "function") {
				optionArray = hideOptions(optionArray);
			}
			if (this.props.addNoneOption)
				optionArray.unshift({id: this.props.noneOptionId || 0, value: this.props.noneOptionId || 0, label: this.props.noneOptionLabel || '', name: ''});

			return optionArray;
		}

		return (
			<ListCellType {...listCellProps}>
				<SelectType
					ref={this.autoComplete}
                    components={{ MenuList: createMenuList({ rowHeight: this.props.rowHeight }),  DropdownIndicator: DropdownIndicator, IndicatorsContainer: IndicatorsContainer, ...this.props.components }}
					filterOption={createFilter({ignoreAccents: false})}
					// autoFocus={listCellProps.hasOwnProperty("initInEditMode") && listCellProps.initInEditMode && !listCellProps.noInitFocus}
					isSearchable={this.props.searchable}
					isMulti={this.props.multiple}
					value={value}
					tabSelectsValue={this.props.tabSelectsValue}
					closeMenuOnSelect={this.props.closeMenuOnSelect}
					options={prepareOptions()}
					menuIsOpen = {this.props.menuIsOpen}
					formatCreateLabel={this.props.allowCreate ? (val) => this.tr("Create") + " \"" + val + "\""  : undefined}
					menuPlacement={this.props.menuPlacement}
					menuPosition={this.props.menuPosition}
					menuPortalTarget={this.props.menuPortalTarget}
					styles={styles}
					loadingMessage={() => `${this.tr("Loading")}...`}
					noOptionsMessage={this.props.noOptionsMessage}
					loadingMessage={() => `${this.tr("Loading")}...`}
					className={`${this.props.className} autoCompleteInCell`}
					classNamePrefix={`${this.props.className} autoCompleteInCell`}
					openMenuOnFocus={this.props.openMenuOnFocus}
					placeholder={this.props.placeholder}
					{...addedProps}
					onBlur={() => this.listCell.current.closeEdit()}
					{...this.props.selectProps}
					onKeyDown={e => {
						if(e.key !== "Enter")
							return;

						this.props.onEnter(e);
					}}
					onChange={value => {
						if(this.props.allowCreate)
							value = this.props.multiple ? value.map(v => { return {...v, new: v.__isNew__ } }) : {...value, new: value.__isNew__ };

						this.listCell.current.closeEdit();
						this.props.onEdited(value);
                        this.props.onEdit(value);
					}} />
			</ListCellType>
		);
	}
}

export { AutoCompleteCell as default, DropdownIndicator, IndicatorsContainer };
