import exceljs from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';
import currencies from './currencies.json';

export async function createExport(file, sheets = []) {

    const workbook = new exceljs.Workbook();

    workbook.creator = 'Taimer';

    for (const options of sheets) {
        const sheet = workbook.addWorksheet(options.title);
        sheet.properties.defaultColWidth = 35;

        if (options.header) {
            sheet.addRow([options.header]);
        }

        sheet.addRow(options.columns.map(x => x.title));

        for (const row of options.data) {
            const vals = [];
            for (const col of options.columns) {
                let val = col.value ? col.value(row) : row[col.column];

                switch (col.type) {
                    case 'number':
                    case 'percent':
                    case 'currency':
                        if (val !== undefined)
                            val = Number(val);
                        break;
                    case 'date':
                        if (col.format)
                            val = moment.utc(val, col.format).toDate();
                        break;
                    case 'custom':
                        val = col.formatter(val, row);
                        break;
                }

                if (col.formatter)
                    vals.push(col.formatter(val, row));
                else
                    vals.push(val);
            }

            sheet.addRow(vals);

            let index = 1;

            for (const col of options.columns) {
                const cell = sheet.lastRow.getCell(index);

                switch (col.type) {
                    case 'currency':
                        cell.numFmt = currencies[col.currency] ?? currencies.USD;
                        break;
                    case 'date':
                        if (col.displayFormat)
                            cell.numFmt = col.displayFormat;
                        break;
                    case 'number':
                    case 'currency':
                        if (col.decimals !== undefined)
                            cell.numFmt = `0.${'0'.repeat(col.decimals)}`;
                        break;   
                    case 'percent':
                        if (col.decimals !== undefined)
                            cell.numFmt = `0.${'0'.repeat(col.decimals)}%`;
                        else
                            cell.numFmt = '0%';
                        break;         
                }

                index++;
            }

        }
    }

    const buffer = await workbook.xlsx.writeBuffer();

    var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

    FileSaver.saveAs(blob, `${file.name}.xlsx`);
}