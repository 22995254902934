import React from 'react';
import ListCell from "./../ListCell";
import BranchIndicator, { 
    createBranchIndicators, 
    hasFurtherSiblings, 
    determineBranchIndicators 
} from "../../general/BranchIndicator";
import { ReactComponent as DownArrow } from './icons/down-arrow.svg';


class TreeStructureIndicatorCell extends React.Component {
    static defaultProps = {
        listCellType: ListCell,
        listCellProps: {},
        width: 100,
        showExpander: true,
        oneIndent: 25,
        indentDeepestLevel: false,
        treeMetaData: null
    };


    constructor(props) {
        super(props);

        this.listCell = React.createRef();
        this.child    = React.createRef();

		this.focusOnEditor = this.focusOnEditor.bind(this);
    }


    focusOnEditor() {
        // Since the actual editor of this cell is the TextInputCell, 
        // we need to call its focusOnEditor when this cell's focusOnEditor is called.
        if(!this.child.current.__getListCell().isInEditMode())
            return false;

        this.child.current.focusOnEditor();
	}


    render() {
        const {
            children,
            treeMetaData,
            recursionLevel,
            rowData,
            indentDeepestLevel,
            valueDisplayer,
            oneIndent,
            childrenVisible,
            onExpanderClick
        } = this.props;

        let depth       = false; 
        let hasChildren = false;

		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: 	  this,
			ref: 	  this.listCell,
			width: 	  this.props.width,
            editable: true,
            permanentEditMode: true,
            onExpanderClick: () => {}
        }, this.props.listCellProps);

        let branchIndicators;

        // TODO: The height and indent/width params should be customizable.

        if(treeMetaData === null) {
            branchIndicators = createBranchIndicators({
                height: 44,
                indent: 25
            }, { 
                lineType: "solid", 
                lineColor: "#eaecf0", lineWidth: 2,
                extendHorizontally: this.props.indentDeepestLevel && this.props.rowData.children.length === 0
            })(this.props.rowData, this.props.rowData.recursionLevel, this.props.rowData.isLastChild);
        } else {
            depth       = treeMetaData.depth;
            hasChildren = treeMetaData.hasChildren;

            branchIndicators = determineBranchIndicators(treeMetaData, { width: oneIndent, height: 44 });
        }

        let marginMultiplier = depth || rowData?.recursionLevel || 0;
        const showExpander   = this.props.showExpander 
            && (rowData?.children?.length > 0 || hasChildren);

        if(showExpander) {
            marginMultiplier += 1;
        }

        if(indentDeepestLevel 
            && (rowData?.children?.length === 0 || !hasChildren)) {
            marginMultiplier += 0.7;
        }

        const ValueDisplayer  = valueDisplayer;
        const innerMarginLeft = marginMultiplier * oneIndent;
        const level           = depth || recursionLevel;

        return (
            <ListCellType {...listCellProps}>
                {branchIndicators.map(Indicator => Indicator)}
                {showExpander && <DownArrow 
                    style={{ left: (oneIndent / 4 + (level * oneIndent)) + "px" }} 
                    className={`childExpander ${childrenVisible ? "expanded" : "closed"}`} 
                    onClick={onExpanderClick} />}
                <div style={{ marginLeft: `${innerMarginLeft}px`}}>
                    {React.Children.map(children, (child, index) => {
                        return React.cloneElement(child, {
                            ref: this.child,
                        });
                    })}
                </div>
            </ListCellType>
        );
    }
}

export default TreeStructureIndicatorCell;
