import React from 'react';
import PropsOnlyListRow from '../../list/PropsOnlyListRow';
import ListCell from '../../list/ListCell';
import TextInputCell from '../../list/cells/TextInputCell';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';
import LinkListCell from '../../list/LinkListCell';
import ContextMenu from '../../general/ContextMenu';
import { FileCopy, MoreHoriz, Star, VisibilityOff } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { isEqual } from 'lodash';
import styles from './BulkEntry.module.scss';
import DataHandler from '../../general/DataHandler';

export default class BulkEntryListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, { jobtypes: [], isLoading: false }, {}, 'workhours/time-tracker/BulkEntryListRow');
    }

    defineClassName() {
        return 'bulkEntryListRow';
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (!isEqual(this.state.jobtypes, nextState.jobtypes) || this.state.isLoading !== nextState.isLoading) {
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState) || !isEqual(this.props.sharedData, nextProps.sharedData) || this.props.rowProps.jobtypeErrorKey != nextProps.rowProps.jobtypeErrorKey;
    };

    getJobtypes = () => {
        this.setState({ isLoading: true }, () => {
            DataHandler.get({ url: `dialogs/worktypes/`, id: this.props.data.id }).done((jobtypes) => {
                this.setState({ jobtypes, isLoading: false });
            }).fail(err => {
                console.err(err);
                this.setState({ jobtypes: [], isLoading: false });
            });
        });
    };

    defineCells() {
        const {
            data,
            columnWidthMap,
            sharedData: { tasks },
            rowProps: { onRemoveProject, onCopyRow, validateRowUpdate, jobtypeErrorKey },
        } = this.props;

        const { isLoading, jobtypes } = this.state;

        let cells = {};

        const filteredTasks = tasks.filter((t) => t.projects_id == data.id);
        let customer = data.unit && data.unit.id != 0 ? data.unit : data.customer;
        Object.keys(data).forEach((key) => {
            cells[key] = <ListCell width={columnWidthMap[key]} value={data[key] || '-'} textAlign="left" editable={false} />;
        });

        cells = {
            ...cells,
            menu: (
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: 'action-menu' }}
                    className="cell row-menu"
                    style={{
                        width: this.props.columnWidthMap['menu'] + 'px',
                        flex: this.props.columnWidthMap['menu'] + ' 1 0px',
                    }}
                    noExpandIcon
                >
                    {[
                        <MenuItem key={1} noClose={false} onClick={() => onCopyRow(data)}>
                            <FileCopy />
                            {this.tr('Copy row')}
                        </MenuItem>,
                        <MenuItem key={2} noClose={false} onClick={() => onRemoveProject(data)}>
                            <VisibilityOff />
                            {this.tr('Hide row')}
                        </MenuItem>,
                    ]}
                </ContextMenu>
            ),
            favorite: (
                <ListCell className={`bulkEntryFavoriteCell ${data.pinned == '1' ? 'selected' : ''}`} width={columnWidthMap.favorite} onlyDisplay={true}>
                    <button onClick={() => this.setDataAndUpdate('pinned', data.pinned != '1' ? '1' : '0')}>
                        <Star />
                    </button>
                </ListCell>
            ),
            jobtype: (
                <AutoCompleteCell
                    width={columnWidthMap.jobtype}
                    value={data.jobtype}
                    selectProps={{ onFocus: this.getJobtypes, isLoading }}
                    autoCompleteData={jobtypes}
                    showStringValue
                    searchable
                    listCellProps={jobtypeErrorKey == data.key ? { className: `bulkEntryJobtypeCell ${styles.error}` } : { className: 'bulkEntryJobtypeCell' }}
                    onEdited={(jobtype) => {
                        const simpleJobtype = {
                            id: jobtype.id,
                            name: jobtype.name,
                        };
                        if (validateRowUpdate('jobtype', simpleJobtype, data)) {
                            this.setDataAndUpdate('jobtype', simpleJobtype);
                        }
                    }}
                    textAlign="left"
                />
            ),
            task: (
                <AutoCompleteCell
                    width={columnWidthMap.task}
                    value={data.task}
                    addNoneOption
                    noneOptionLabel={this.tr('Not selected')}
                    autoCompleteData={filteredTasks}
                    onEdited={(task) => {
                        const simpleTask = {
                            id: task.id,
                            name: task.name,
                        };
                        if (validateRowUpdate('task', simpleTask, data)) {
                            this.setDataAndUpdate('task', simpleTask);
                        }
                    }}
                    textAlign="left"
                />
            ),
            name: <LinkListCell urlHandler={() => `index.php?module=projects&action=view&id=${data.id}`} width={columnWidthMap.name} value={data.name} textAlign="left" editable={false} />,
            customer: (
                <LinkListCell urlHandler={() => `index.php?module=customers&action=view&id=${customer?.id}`} width={columnWidthMap.customer} value={customer?.name} textAlign="left" editable={false} />
            ),
            description: (
                <TextInputCell
                    width={columnWidthMap.description}
                    name="description"
                    value={data.description}
                    listCellProps={{ className: 'bulkEntryDescriptionCell' }}
                    textAlign="left"
                    onEdited={(name, value) => {
                        if (validateRowUpdate(name, value, data)) {
                            this.setDataAndUpdate(name, value);
                        }
                    }}
                    editable={true}
                />
            ),
        };

        return cells;
    }
}
