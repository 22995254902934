import React from 'react';

/* css */
import './ExpenseList.css';

/* material-ui */
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';

/* others */
import $ from 'jquery';
import List from "../list/List";
import FileSaver from 'file-saver';
import IconButton from '@mui/material/IconButton';
import DataHandler from "../general/DataHandler";
import SettingsIcon from '@mui/icons-material/Settings';
import CloudDownload from '@mui/icons-material/CloudDownload';
import OutlinedField from "./../general/OutlinedField";
import AdvancedSearch from "../search/AdvancedSearch";
import ExpenseListRow from "./ExpenseListRow";
import TaimerComponent from "../TaimerComponent";
import AttachmentDialog from "../list/dialogs/HandleRowAttachments";
import ExpenseListOverlay from "../list/overlays/ExpenseListOverlay";
import TravelExpenseListRow from "./TravelExpenseListRow";
import TravelExpenseListOverlay from "../list/overlays/TravelExpenseListOverlay";
import { withSnackbar } from 'notistack';
import { format, endOfMonth, startOfMonth } from 'date-fns';
import cloneDeep from "lodash/cloneDeep";
import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions,
	getInitialDimensionState
} from "../dimensions/helpers"

/* Context */
import { SettingsContext } from './../SettingsContext';
import { DateRangePicker } from '../general/react-date-range/src';

import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import _ from 'lodash';
import PageTopSection from '../general/PageTopSection';
import { AirplanemodeActive, Payment } from '@mui/icons-material';
import PurchaseExpenseTranslations from '../general/backendTranslations/PurchaseExpenseTranslations';
import TravelExpenseTranslations from '../general/backendTranslations/TravelExpenseTranslations';
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import WithTabs from '../navigation/WithTabs';
import Utils from "./../general/Utils.js";
import colors from '../colors';

const styles = theme => ({
	buttonContainer: {
		display: 'flex',
		height: "47px",
		alignItems: "center"
	},
	button: {
		marginRight: "16px",
		textTransform: 'uppercase',
	},
	exportButton: {
		cursor: "pointer",
		color: "#6b7897",
		fontSize: "14px",
		minWidth: "180px",
		display: 'flex',
		alignItems: 'center',
		minWidth: '180px',
	},
	cloudDownload: {
		margin: "0 6px 0 24px"
	}
});

class ExpenseList extends TaimerComponent {
    static contextType = SettingsContext;
	static propTypes   = {
		enqueueSnackbar: PropTypes.func.isRequired,
	};

	constructor(props, context) {
		super(props, context, 'expenses/ExpenseList');
		this.stickySearchKey = "expense_list"

		let expenseColumnMap = [
			{ name: "context", hide: false },
			{ name: "attachment" , hide: false },
			{ name: "checked" , hide: false},
			{ name: "id", header: this.tr("Nr."), hide: false},
			{ name: "fullname", header: this.tr("User"), hide: false },
			{ name: "account", header: this.tr("Account"), hide: false },
			{ name: "project", header: this.tr("Project"), hide: false},
			{ name: "state", header: this.tr("Status"), hide: false },
			{ name: "description", header: this.tr("Purpose of expense"), hide: false },
			{ name: "expense_date", header: this.tr("Date"), hide: false},
			{ name: "expense_types", header: this.tr("Expense types"), hide: false },
			{ name: "team_groups", header: this.tr("Team"), hide: false },
			{ name: "payment_type", header: this.tr("Payment type"), hide: false },
			{ name: "total", header: this.tr("Sum (inc. VAT)"), hide: false },
		];

		 let travelColumnMap = [
			{ name: "context", header: "",  hide: false  },
			{ name: "attachment", header: "",  hide: false  },
			{ name: "checked",  header: "",  hide: false  },
			{ name: "id", header: this.tr("Nr."), hide: false },
			{ name: "fullname", header: this.tr("User"), hide: false },
			{ name: "account", header: this.tr("Account"),  hide: false  },
			{ name: "project", header: this.tr("Project"),  hide: false  },
			{ name: "state", header: this.tr("Status"),  hide: false  },
			{ name: "description", header: this.tr("Description"),  hide: false  },
			{ name: "team_groups", header: this.tr("Team"),  hide: false  },
			{ name: "route", header: this.tr("Route"),  hide: false  },
			{ name: "startdate", header: this.tr("Start Date"), hide: false  },
			{ name: "starttime", header: this.tr("Start Time"),  hide: false  },
			{ name: "enddate", header: this.tr("End Date"),  hide: false  },
			{ name: "endtime", header: this.tr("End Time"),  hide: false  },
			{ name: "total", header: this.tr("Sum (inc. VAT)"),  hide: false  }
		]; 

		const localStorageConfig = localStorage.getItem("worktrips") ? JSON.parse(localStorage.getItem("worktrips")) : [];
		
        let useLocalStorage = false;

        if (!Array.isArray(localStorageConfig) || localStorageConfig.length === 0 || typeof localStorageConfig[0] !== "object" || localStorageConfig[0] === null) {
            localStorage.removeItem("worktrips");
        } else {
			localStorageConfig.forEach((col, index) => travelColumnMap[index].hide = col.hide);
			localStorageConfig.forEach((col, index) => expenseColumnMap[index].hide = col.hide);
            useLocalStorage = true;
		}

		const date = this.props.date ? new Date(this.props.date) : new Date();
	
		const start =  this.props.start ? new Date(this.props.start) :  null;
		const end = this.props.end ? new Date(this.props.end) : null;
		this.states = [];
		this.states[0] = { name: this.tr("Draft"), color: "#bbbbbb" };
		this.states[1] = { name: this.tr("Waiting"), color: "#ffb822" };
		this.states[2] = { name: this.tr("Approved"), color: colors.greenish_cyan };
		this.states[3] = { name: this.tr("Rejected"), color: "#f7548f" };
		this.states[4] = { name: this.tr("Archived"), color: "#bbbbbb" };
		this.getParams = {};
		this.getParams['start_date'] = start ? format(start, "YYYY-MM-DD") : null;
		this.getParams['end_date'] = end ? format(end, "YYYY-MM-DD") : null;

        this.initialFetchDone = {
            expenses: false,
            travel: false
		};
		
		const initialCompany = this.props.companies_id ? this.props.companies_id : (context.functions.getCompany("worktrips", "approve", false, true) || context.functions.getCompany("worktrips", "approve_superior", false, true) || context.userObject.companies_id);

		this.defaultValues = {
			billing_type: 0,
			payment_type: 0,
			date_range: {
				startDate: null,
				endDate: null,
				key: "selection"
			},
			listType: 'expenses',
			tabValue: 5,
			perpage: this.props.perpage,
			sort: {},
			page: 1,
			company: initialCompany
		};

        const { userObject: { sidebarStyle }} = this.context;

		this.filtersInitialValues = {
			billing_type: this.defaultValues.billing_type,
			payment_type: this.defaultValues.payment_type,
			date_range: {
				startDate: start ? format(start, "YYYY-MM-DD") : this.defaultValues.date_range.startDate,
				endDate: end ? format(end, "YYYY-MM-DD") : this.defaultValues.date_range.endDate,
				key: "selection"
			},
			tabValue: this.props.type || this.defaultValues.tabValue,
			listType: (sidebarStyle == "1" ? localStorage.getItem('expenseListType') : this.props.listType) || this.defaultValues.listType,
			perpage: this.defaultValues.perpage,
			sort: this.defaultValues.sort,
			page: this.defaultValues.page,
			company: initialCompany
		}

		this.getParams['state'] = this.filtersInitialValues.tabValue == 5 ? -1 : this.filtersInitialValues.tabValue;

		this.state = {
			...this.filtersInitialValues,
			data: [],
			'tabColor': '#2d9ff7',
			checkedRows: [],
			currentDialog: false,
			paymentTypes: [],
			hasExpenses: true,
			companyCurrency: "EUR",
			companies: [],
			arhciveDialogOpen: false,
			isLoadingList: true,
			sendingInProgress: false,
			snackBarKey: undefined,
			stickySearchInitialized: false,
			employees: [],
			printLanguageOptions: [{value: "en", label: this.tr("English")}],
			...getInitialDimensionState()
		};

		this.autoCompleteData = {};
		this.savedParams = {};
		this.searchTerms = {};
		this.stateToSave = {
			expenses: {},
			travel_expenses: {}
		}

		this.checkedRows = [];
		this.allExpenseIds = [];

        this.userTypeAutocompleteClasses = [
            'all_users',
            'dynamic_right_employees',
            'employees',
            'modified_by',
            'privileged_employees',
            'project_team_member_pool',
            'users'
        ];

		this.translations = {
			locked: this.tr("locked"),
			freelancer: this.tr("freelancer")
		};

        this.userTypeDataHeaders = {users_id: 'fullname'};		

		this.list = React.createRef();
		this.advancedSearch = React.createRef();

		this.addRow = this.addRow.bind(this);
		this.setData = this.setData.bind(this);
		this.sortRows = this.sortRows.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.switchTabs = this.switchTabs.bind(this);
		this.createNewExpense = this.createNewExpense.bind(this);
		this.callAttachmentDialog = this.callAttachmentDialog.bind(this);
		this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
		this.saveStickySearch         = this.saveStickySearch.bind(this);
		this.initDimensions           = this.initDimensions.bind(this);
		
		const accountingAddons = [
			"procountor"
		];
		const addons = this.context.addons ? this.context.addons : {};
		this.usesAccounting = Object.keys(addons).find((a) => accountingAddons.indexOf(a) > -1) ? true : false;
	}

	componentDidMount() {
		super.componentDidMount();
		this.initializeStickySearch();
		this.listenReset();
		this.initDimensions(this.state.company);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.listType != this.props.listType) {
			this.setState({ listType: this.props.listType, stickySearchInitialized: false }, () => {
				localStorage.setItem('expenseListType', this.state.listType);
				this.initializeStickySearch();
			});
		}
		if(prevState.company !== this.state.company) {
			this.initDimensions(this.state.company);	
		}
	}

	getAutoCompleteData(fetchData, sticky) {
		DataHandler.get({ url: `projects/autoCompleteData/${this.state.company}`}).done(
			data => {
                const userTagProps = {
                    fields: {name: 'name'},
                    showLocked: this.props.showLockedUsersWithTag,
                    transl: this.translations
                };				
	            Object.keys(data).forEach(k => {
	                if (this.userTypeAutocompleteClasses.includes(k)) {
	                    data[k] = data[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
	                }
	            })

				for (var i in data) {
					this.autoCompleteData[i] = data[i];
				}

				if (this.list.current)
            		this.list.current.resetCheckedRows();
				this.fetchData(fetchData, false, undefined, sticky)
			}
		);

		DataHandler.get({ url: `expenses/payment_types`, company: this.state.company }).done(
			response => {
				this.autoCompleteData.payment_types = response.payment_types;
				this.setState({ paymentTypes: response.payment_types });
				this.setState({ currenciesDefined: response.currenciesDefined, isLoadingList: false });
			}
		);

		DataHandler.get({ url: `subjects/companies/worktrips/write+approve+approve_superior+approve_projectmanager+modify_all`, currency: 1, print_lang: 1, country_lang: 1, print_options: 1  }).done(companies => {
			let c = false;
            companies.forEach(company => {
                if(company.id == this.state.company) {
					const printLanguageOptions = company.print_languages.map(pl => {return {...pl, label: this.tr(pl.label)}})
					this.setState({companyCurrency: company.currency, printLanguageOptions});
                    c = true;
                }
            })
            if (!c) {
                this.setState({companyCurrency: companies[0].currency})
            }
			this.setState({ companies })
		});

		DataHandler.get({ url: `subjects/employees/worktrips/write/${this.state.company}`, dontIgnoreCompany: true }).done(employees => {
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };			
	        employees.forEach((p, i) => {
	            employees[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
	        })

			this.setState({ employees })
		});

	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.perpage !== nextState.perpage || this.state.page !== nextState.page)
			return false;

		return true;
	}

	hasInitialFilterProps = () => {
		const initialProps = ["type", "expense_types", "start", "end", "customerId"];
		
		let i;
		for (i = 0; i < initialProps.length; i++) {
			if (this.props[initialProps[i]]) 
				return true;
		};

		return false;
	}

	initializeStickySearch() {		
		if (this.hasInitialFilterProps()) {
			this.setState({ stickySearchInitialized: true }, () => this.getInitialData({}, true));
			return;
		}
		
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, __, request) => {
            if(request.status !== 200) {
				this.getInitialData({}, true)
                return;            
			}
			this.stateToSave = response;
			let tab = "expenses";
			if (this.state.listType !== "expenses") 
				tab = "travel_expenses";

			if (Array.isArray(response[tab])) {
				response[tab] = this.filtersInitialValues;
				this.stateToSave[tab] = this.filtersInitialValues;
			}
			
			if (response[tab].searchTerms)
				this.searchTerms = response[tab].searchTerms;

			const approvePrivileges = this.context.privileges.worktrips.approve;
			const approve_projectmanagerPrivileges = this.context.privileges.worktrips.approve_projectmanager;
			const modify_allPrivileges = this.context.privileges.worktrips.modify_all;
			const writePrivileges = this.context.privileges.worktrips.write;

			const hasApprovePrivileges = approvePrivileges && approvePrivileges.find(el => el == response[tab].company);
			const hasApprove_projectmanagerPrivileges = approve_projectmanagerPrivileges && approve_projectmanagerPrivileges.find(el => el == response[tab].company);
			const hasModify_allPrivileges = modify_allPrivileges && modify_allPrivileges.find(el => el == response[tab].company);
			const hasWritePrivileges = writePrivileges && writePrivileges.find(el => el == response[tab].company);

			if (!hasApprovePrivileges && !hasApprove_projectmanagerPrivileges && !hasModify_allPrivileges && !hasWritePrivileges) {
				response[tab].company = this.filtersInitialValues.company;
			}
			
			if (this.props.companies_id)
				response[tab].company = this.props.companies_id;

			const start = response[tab].date_range ? response[tab].date_range.startDate : this.filtersInitialValues.date_range.startDate;
			const end = response[tab].date_range ? response[tab].date_range.endDate : this.filtersInitialValues.date_range.endDate;
			
			this.getParams['state'] = response[tab].tabValue == 5 ? -1 : response[tab].tabValue;
			this.getParams['start_date'] = format(start, "YYYY-MM-DD");
			this.getParams['end_date'] = format(end, "YYYY-MM-DD");
			this.getParams['sort'] = response[tab].sort || this.filtersInitialValues.sort;

            this.setState({ ...response[tab], listType: this.state.listType, tabColor: this.states[response[tab].tabValue] ? this.states[response[tab].tabValue].color : '' }, () => this.getInitialData({ ...response[tab] }, true));
        }).fail(response => {
			this.getInitialData({}, true)

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
	}
	
	getInitialData(data, sticky = false) {
		this.getAutoCompleteData(data, sticky);
	}

    saveStickySearch() {
		let tab = "expenses";
		if (this.state.listType !== "expenses") 
			tab = "travel_expenses";

		this.stateToSave[tab].searchTerms = _.cloneDeep(this.searchTerms);
		
		for(let key in this.filtersInitialValues) {
            this.stateToSave[tab][key] = _.cloneDeep(this.state[key]);
		}
		
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: this.stateToSave });
    }

    async initDimensions(company) {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
			this.setState(getInitialDimensionState());
            return;
        }

        this.setState({ ...(await getDimensionAutoCompleteData(company)) }); 
    }

    filtersAreInInitialState() {
		const initial = _.cloneDeep(this.filtersInitialValues);
		["perpage", "sort", "page"].forEach(e => {
            delete initial[e];
        });
		
        const filters = {};

        for(let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext;
    }

	_resetFilters = (evt) => {
		if (!evt || evt.keyCode == '27') {
			const filters = _.cloneDeep(this.filtersInitialValues);
			delete filters.page;
		
			this.advancedSearch.current.clearSearch(evt, true);
			this.advancedSearch.current.clearSearchTextInput();

			this.searchTerms = undefined;
			this.getParams = {};

			this.getParams['start_date'] = format(filters.date_range.startDate, "YYYY-MM-DD");
			this.getParams['end_date'] = format(filters.date_range.endDate, "YYYY-MM-DD");
			this.getParams['state'] = this.filtersInitialValues.tabValue == 5 ? -1 : this.filtersInitialValues.tabValue;

			this.setState({
				...filters,
			}, () => this.getAutoCompleteData({}, false));
		}
	}

	listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
	}

	fetchData = (override = {}, useSavedParams = false, callback = undefined, stickySearch) => {
		const { company, perpage, billing_type, payment_type, date_range, tabValue, listType } = this.state;
		const { customerId, expense_types, user, team, projects_id, companies_id } = this.props;

		let parameters = { page: 1, perpage, projectId: projects_id, customers_id: customerId, expense_types, user_filter_data: user, team_filter_data: team, billing_type, payment_type, date_range, tabValue, listType };
		
		let postParams = {};

        this.list.current && this.list.current.setState({ isLoading: true});

		if (useSavedParams) {
			parameters = this.savedParams;
		} else {
			for (let gp in this.getParams)
				parameters[gp] = this.getParams[gp];
			if (this.searchTerms !== undefined) {
				parameters.mode = this.searchTerms.mode;
				if (this.searchTerms.mode == 'advanced') {
					postParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
				} else {
					postParams.freetext = this.searchTerms.freetextSearchTerm;
				}
			}
		}


		for (let oi in override)
			parameters[oi] = override[oi];

		this.savedParams = cloneDeep(parameters);

		if (this.savedParams.get_all_ids)
			delete this.savedParams.get_all_ids;

		const params  = { ...parameters, company };
		
		this.saveStickySearch();

        return DataHandler.post({ url: `expenses/list_new`, ...params }, postParams).done(data => {
            if(callback)
                callback(data);
			else
                this.setData(data);

            this.list.current && this.list.current.setState({ isLoading: false});

            this.initialFetchDone.expenses = true;
            this.initialFetchDone.travel   = true;

            return data;
        });
    }

    showError = (modifiedMsg = undefined) => {
        this.props.enqueueSnackbar(modifiedMsg === undefined ? this.tr("An error occured while executing the requested action.") : modifiedMsg, {
            variant: "error"
        });
	}
	
	fetchAllIds = async () => {
		const expenses = await this.fetchData({ get_all_ids: 1 }, false, () => {});
		return expenses[this.state.listType == "expenses" ? 'expenses' : 'travel_expenses']
			.all_expense_ids
			.map(exp => exp.id);	
	}

    // Async because we might need to fetch data from the backend.
    getChecked = async () => {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();

        if(allCheckedExcept) {
            try {
                const ids = await this.fetchAllIds();
                return ids.filter(id => !allCheckedExcept[id]);
            } catch(exception) {
                this.showError();
                return;
            }
        } else {
            return this.list.current.getCheckedRows();
        }

    }

	export = async (target, fields) => {
        let ids = await this.getChecked();

		if (!ids || ids.length < 1)
			ids = await this.fetchAllIds(); 
			
		if (ids.length < 1) {
			this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            this.list.current && this.list.current.setState({ isLoading: false});
            return;
		}

		let idParams = {};
		idParams = { ids: JSON.stringify(ids) };

		const params = { ...this.savedParams, columnNames: [], currency: this.state.companyCurrency, time: this.context.calendar.clock, states: [], company: this.state.company };
		let columnOrder = this.list.current.visibleColumnsOrder;
		columnOrder = columnOrder.filter(co => !["context", "attachment", "checked"].includes(co));

		_.forEach(columnOrder, c => {
			let field = null;
			if (field = fields.find(f => f.name == c))
				params.columnNames.push(field.header);		
		})

		this.states.forEach(state => {
			params.states.push(state.name);
		})
		params.file_name = this.state.listType == "expenses" ? this.tr("expenses_list_export") : this.tr("travel_expenses_list_export");

		let url = this.state.listType == "expenses" ? "expenses/export/expense" : "expenses/export/traveling";
		DataHandler.postArrayBuffer({ url: url, ...params, order: columnOrder, export: target }, {...idParams}, true)
			.done((response) => {

				var blob = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});

				FileSaver.saveAs(blob, `${params.file_name}.${target}`);
                this.list.current && this.list.current.setState({ isLoading: false});
			});

	}

	setSendingStatus = (status) => {
		if (status == true) {
			const key = this.props.enqueueSnackbar(this.tr("Sending invoices. This may take a while."), {
				variant: "info",
				persist: true
			});
			this.setState({sendingInProgress: status, snackBarKey: key});
		} else {
			this.props.closeSnackbar(this.state.snackBarKey);
			this.setState({sendingInProgress: status, snackBarKey: undefined});
		}
	}

	sendProcountor = async () => {
        const { company } = this.state;
		let ids           = await this.getChecked();
		
		if (ids.length == 0 || this.state.sendingInProgress == true)
			return;

		this.setSendingStatus(true);

		DataHandler.put({ url: `expenses/procountor` }, { company: company, ids: ids, type: this.state.listType }).done(response => {
			this.setSendingStatus(false);

			if (response.invalid.length == 0) {
				this.fetchData();

				this.props.enqueueSnackbar(this.tr("Expenses exported successfully!"), {
					variant: "success",
				});

				this.setState({ arhciveDialogOpen: true });
			} else if (response.invalid.length > 0) {
				let errors = response.invalid.map(el => {
					return el.expense_id + ": " + el.errors.map(c => c.message).join(", ") + "\n";
				});
				let errorMsg = errors.join(",\n");

				this.fetchData();

				this.props.enqueueSnackbar(this.tr("Failed to export some expenses") + ": " + errorMsg, {
					variant: "error",
					autoHideDuration: 10000
				});
			}
		});
	}

	sendNetvisor = async (version = null) => {
		//let ids = this.state.checkedRows.map(el => el.id);
		let ids = await this.getChecked();

        if (ids.length == 0)
			return;
			
		DataHandler.put({url: `expenses/netvisor`}, {company: this.state.company, ids: ids, type: version != null ? version : this.state.listType}).done(response => {
            if(response.success == 1){
                this.props.enqueueSnackbar(this.tr("Expenses exported successfully!"), {
                    variant: "success",
                });

                this.fetchData();
                this.setState({arhciveDialogOpen: true});        
            } else {
                var parser = new DOMParser();
                if (response.invalid.customers) {
                    let customerErrors = response.invalid.customers.map(el => {
                        let dom = parser.parseFromString(el.response, "application/xml");
                        var errorMessage = dom.children[0].textContent.substring(0, dom.children[0].textContent.length - 24);
                        return errorMessage;
                    });

                    this.props.enqueueSnackbar(customerErrors, {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                }
                if (response.invalid.expenses) {
                    let billErrors = response.invalid.expenses.map(el => {
                        let dom = parser.parseFromString(el.response, "application/xml");
                        var errorMessage = dom.children[0].textContent.substring(0, dom.children[0].textContent.length - 24);
                        return errorMessage;
                    });

                    this.fetchData();

                    this.props.enqueueSnackbar(billErrors, {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                }
            }   
        });
    }

	sendFortnox = async () => {
		//let ids = this.state.checkedRows.map(el => el.id);
		let ids = await this.getChecked();

        if (ids.length == 0)
            return;

		const key = this.props.enqueueSnackbar(this.tr("Sending expenses to Fortnox..."), {
			variant: "info",
			persist: true
		});
        
		DataHandler.put({url: `expenses/fortnox`}, {company: this.state.company, ids: ids, type: this.state.listType}).done(response => {
			this.props.closeSnackbar(key);

            if(response.success == 1){
                this.props.enqueueSnackbar(this.tr("Expenses exported successfully!"), {
                    variant: "success",
                });

                this.fetchData();
                this.setState({arhciveDialogOpen: true});        
            } else {
                let errors = response.invalid.map(el => {
                    return el.id + ": " + el.message + " \n";
                });
                let errorMsg = errors.join("\n");

                this.props.enqueueSnackbar("Failed to export some expenses: \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });

            }   
        });
    }

	setData = resp => {

		const { listType } = this.state;
		const { expenses, travel_expenses } = resp;

		const data = expenses || travel_expenses || [];

		const pageCount = data.page_count;

		const expenseCount = data.expense_count;

		(data.all_expense_ids && data.all_expense_ids.length > 0) && localStorage.setItem((listType == 'expenses' ? "expense_ids" : "travel_expense_ids"), JSON.stringify(data.all_expense_ids.map(e => e.id)));
        
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.autoCompleteData.users
        };
        data.expenses.forEach((p, i) => {
            if (Object.keys(p).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {
          
                data.expenses[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            }
        })

		this.setState(state => {
			state.data = {
				[listType]: data.expenses,
			};
			state.pageCount = pageCount || 1;
			state.expenseCount = expenseCount || 0;
			state.hasExpenses = data.has_expenses > 0;
			state.sum = this.calculateSum(data.expenses);
			state.totalSum = data.total;
			return state;
		}, () => this.list.current && this.list.current.endPageChangeAnimation());
	}

	calculateSum = data => {
		let sum = 0;

		if (!data || data.length == 0)
			return sum;

		if (this.state.listType == "expenses") {
			data.map(e => {
				sum += e.total_with_vat - 0;
			});
		} else {
			data.map(e => {
				sum += (e.mileage_allowance_total_vat - 0) + (e.daily_allowance_total_vat - 0) + (e.other_allowance_total_vat - 0) + (e.additional_allowance_total_vat - 0);
			});
		}

		return sum;
	}

	addRow() {
		this.list.current.addNewRow();
	}

	createNewExpense(e) {
		this.props.updateView({
			"module": "worktrips",
			"action": "modify",
			"expenseType": 1,
			projects_id: this.props.projects_id
		}, e.ctrlKey || e.metaKey || e.button === 1);
	}

	switchTabs(e, value) {
		this.getParams.state = value == 5 ? -1 : value;
		this.list.current.setPage(1);
		this.setState({ tabColor: this.states[value] ? this.states[value].color : '', tabValue: value }, () => this.resetListAndFetchData());
	}

	dropChanged = event => {
		const { state } = this;
		const { name, value } = event.target;

		state[name] = value;
		this.getParams[name] = value;
		this.list.current.setPage(1);
		this.setState({ state }, () => this.resetListAndFetchData());
	}

	handleRangeSelect = (event) => {
		const { startDate, endDate } = event.selection;
		this.getParams['start_date'] = format(startDate, "YYYY-MM-DD");
		this.getParams['end_date'] = format(endDate, "YYYY-MM-DD");
		this.list.current.setPage(1);

		this.setState({
			date_range: {
				startDate: format(startDate, "YYYY-MM-DD"),
				endDate: format(endDate, "YYYY-MM-DD"),
				key: "selection"
			}
		}, () => this.resetListAndFetchData());	
	}

	handleRangeInputChange = (dateType, date) => {

		const { endDate, startDate } = this.state.date_range;
		date = format(date, "YYYY-MM-DD");
		this.getParams['start_date'] = startDate;
		this.getParams['end_date'] = date;

		if (dateType == "start") {
			this.setState({
				date_range: {
					startDate: date,
					endDate: endDate,
					key: "selection"
				}
			}, () => this.resetListAndFetchData());

			this.getParams['start_date'] = date;
			this.getParams['end_date'] = endDate;
		} else {
			this.setState({
				date_range: {
					startDate: startDate,
					endDate: date,
					key: "selection"
				}
			}, () => this.resetListAndFetchData());
		}
		this.list.current.setPage(1);
	}

	resetListAndFetchData(update) {
        if (this.list.current)
            this.list.current.resetCheckedRows();

        this.fetchData({ ...update });
    }

	print = async (lang, id = false) => {
		let ids = id ? id : this.list.current.getCheckedRows().join();

		if (!ids) {
			this.props.enqueueSnackbar(this.tr("No expenses selected."), {
				variant: "warning",
			});
			return;
		}

		const key = this.props.enqueueSnackbar(this.tr("Generating expenses PDF-file..."), {
			variant: "info",
			persist: true
		});

		//let { checkedRows } = this.state;
		//let ids = checkedRows.map(el => el.id);
		const transl = new PurchaseExpenseTranslations().returnTranslations(['en']);
		const travelTransl = new TravelExpenseTranslations().returnTranslations(['en']);

		const parameters = {
			use_netvisor: this.context.addons.netvisor ? 1 : 0,
			module: this.state.listType == "expenses" ? "expenses" : "travel_expenses",
			purchase_expenses_id: ids,
			traveling_expenses_id: ids,
			showattachments: 1,
			company_id: this.state.company,
			pdf: 1,
			//tablet: 1,
			rtlang: lang,
			auth: this.context.functions.getStorage().taimerToken,
			dateFormat: this.convertPrintDateFormat(this.context.userObject.dateFormat),
		};

		DataHandler.postArrayBuffer({ url: 'expenses/print_pdf', ...parameters })
			.done((response) => {
				this.props.closeSnackbar(key);
				
				if (!response.error) {
					var blob = new Blob([response], {
						type: 'application/pdf'
					});
					FileSaver.saveAs(blob, parameters.module + '_' + format(new Date(), "YYYY-MM-DD") + '.pdf');
				}
				else {
					this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
						variant: "error",
					});
				}
			})
			.fail(err => {
				this.props.closeSnackbar(key);

				this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
					variant: "error",
				});
			})
	}

	convertPrintDateFormat(format) {
        return format.replace('DD','%d')
            .replace('MM', '%m')
            .replace('YYYY', '%Y')
    }

	setExpenseStates = state => {
		const checkedRows = this.list.current.getCheckedRows();

		const action = this.state.listType == "expenses" ? "set_multiple_expense_statuses" : "set_multiple_travel_expense_statuses"

		let params = { module: "purchase_expenses", action: action };

		const data = {
			state: state,
			ids: checkedRows,
		}
		DataHandler.post(params, data).done(response => {
			this.setState({ checkedRows: [] })
			this.resetListAndFetchData({ page: this.state.page });
		});
	}

	sortRows(columnName, isAscending) {
		let sortObj = { name: columnName, asc: isAscending };
		this.state.sort = sortObj;
		this.getParams.sort = sortObj;
		this.list.current.setPage(1);
		this.setState({ sort: sortObj }, () => this.fetchData({ expenseType: 1 }));
	}

	sortTravelRows = (columnName, isAscending) => {
		let sortObj = { name: columnName, asc: isAscending };
		this.state.sort = sortObj;
		this.getParams.sort = sortObj;
		this.list.current.setPage(1);
		this.fetchData({ expenseType: 2 });
	}

	openDialog = (name) => {
		this.setState({ currentDialog: name });
	}

	closeDialog = () => {
		this.setState({ currentDialog: false });
	}

	callAttachmentDialog(id) {
		this.setState({
			dialogData: {
				id: id,
				targetModule: this.state.listType == "expenses" ? "purchase_expenses" : "traveling_expenses"
			}
		}, () => this.openDialog('attachments'));
	}

	showExpenses = () => {
		this.context.functions.updateView({ module: 'costs', action: 'main', selectedTab: 'expenses' })
	}

	showTravelExpenses = () => {
		this.context.functions.updateView({ module: 'costs', action: 'main', selectedTab: 'travel-expenses' })
	}

	handleTabChange = (e, value) => {
		localStorage.setItem('expenseListType', value);
		value === "expenses" ? this.showExpenses() : this.showTravelExpenses();
		this.setState({ stickySearchInitialized: false }, () => this.initializeStickySearch());
	};

	updateExpense = (type, data) => {
		const dataType = type === "expense" ? "expenses" : "travelExpenses";
		const expense = this.state.data[dataType].find(e => e.id == data.id);
		const currentState = expense ? expense.state : undefined;
		const { userObject } = this.context;
		let url = `expenses/${data.id}/${type}`;
		if(data.state !== undefined && data.state != currentState) {
			url += "/state";
		}
		DataHandler.put({ url: url }, { company: userObject.companies_id, ...data }).done(response => {}).fail(resp => this.showError());
	}

	renderSummarySection = () => {
		const { userObject: { sidebarStyle }, functions: { checkPrivilege } } = this.context;

		const addExpense = (e) => {
			const { addons } = this.context;

			if (addons.expenses && addons.expenses.limit && addons.expenses.used >= addons.expenses.limit) {
				this.props.toggleBuyDialog("expenses");
			}
			else {
				this.createNewExpense(e);
			}
		}

		const addWorktrip = (e) => {
			const { addons } = this.context;

			if (addons.expenses && addons.expenses.limit && addons.expenses.used >= addons.expenses.limit) {
				this.props.toggleBuyDialog("expenses");
			}
			else {
				this.props.updateView({
					"module": "worktrips",
					"action": "modify",
					"expenseType": 2,
					projects_id: this.props.projects_id
				}, e.ctrlKey || e.metaKey || e.button === 1);
			}
		}

		const { expenseFields, travelFields } = this.getFields();
		const fields = this.state.listType == "expenses" ? expenseFields : travelFields;

		return (
			<PageTopSection settingsButton={{
				isVisible: checkPrivilege("admin", "admin"),
				title: this.tr("Settings"),
				href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'expense' }),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'expense' }, false)
			}} mainButtons={[
				{
					title: this.tr("NEW EXPENSE"),
					action: addExpense,
					isVisible: !this.props.projects_id && checkPrivilege("worktrips", "write") && this.state.listType == "expenses",
					"data-testid": 'add-purchase-expense-button'
				},
				{
					title: this.tr("NEW TRAVEL EXPENSE"),
					action: addWorktrip,
					isVisible: !this.props.projects_id && checkPrivilege("worktrips", "write") && this.state.listType == "travelExpenses",
					"data-testid": 'add-travel-expense-button'
				},
			]} additionalButtons={[
				{
					title: this.tr("EXPORT"),
					action: () => this.export('xlsx', fields),
					icon: <CloudDownload />
				}
			]} summaries={[
				{
					title: this.tr("Sum"),
					value: new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.state.companyCurrency }).format(this.state.sum || 0)
				}
			]} />
		);
	}

	getFields = () => {
		let expenseFields = [
			{ field: "context", name: "context", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "attachment", name: "attachment", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "checked", name: "checked", columnHeaderType: "checkbox", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "id", name: "id", header: this.tr("Nr."), width: 61, resizeable: false, moveable: false },
			{ field: "fullname", name: "fullname", header: this.tr("User"), width: 150, entityMode: true },
			{ field: "account", name: "account", header: this.tr("Account"), width: 150, visualizationType: "tree", entityMode: true },
			{ field: "project", name: "project", header: this.tr("Project"), width: 150, visualizationType: "tree", entityMode: true },
			{ field: "state", name: "state", header: this.tr("Status"), width: 120 },
			{ field: "description", name: "description", header: this.tr("Purpose of expense"), width: 180 },
			{ field: "expense_date", name: "expense_date", header: this.tr("Date"), width: 125, type: "date" },
			{ field: "expense_types", name: "expense_types", header: this.tr("Expense types"), width: 200 },
			{ field: "team_groups", name: "team_groups", header: this.tr("Team"), width: 200 },
			{ field: "reporting_groups", name: "reporting_groups", header: this.tr("Reporting group"), width: 200, visualizationType: "tree", entityMode: true },
			{ field: "payment_type", name: "payment_type", header: this.tr("Payment type"), width: 200, entityMode: true },
			{ field: "total_without_vat", name: "total_without_vat", header: this.tr("Sum (excl vat)"), width: 200, type: "number" },
			{ field: "total", name: "total", header: this.tr("Sum (inc. VAT)"), width: 200, type: "number" },			
			{ field: "vat_sum", name: "vat_sum", header: this.tr("Vat sum"), width: 200, type: "number",},
		];

		let travelFields = [
			{ field: "context", name: "context", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "attachment", name: "attachment", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "checked", name: "checked", columnHeaderType: "checkbox", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
			{ field: "id", name: "id", header: this.tr("Nr."), width: 61, resizeable: false, moveable: false },
			{ field: "fullname", name: "fullname", header: this.tr("User"), width: 150, entityMode: true },
			{ field: "account", name: "account", header: this.tr("Account"), width: 150, visualizationType: "tree", entityMode: true },
			{ field: "project", name: "project", header: this.tr("Project"), width: 150, visualizationType: "tree", entityMode: true },
			{ field: "state", name: "state", header: this.tr("Status"), width: 120 },
			{ field: "description", name: "description", header: this.tr("Description"), width: 180 },
			{ field: "team_groups", name: "team_groups", header: this.tr("Team"), width: 200 },
			{ field: "reporting_groups", name: "reporting_groups", header: this.tr("Reporting group"), width: 200, visualizationType: "tree", entityMode: true },
			{ field: "route", name: "route", header: this.tr("Route"), width: 180 },
			{ field: "startdate", name: "startdate", header: this.tr("Start Date"), width: 125, type: "date" },
			{ field: "starttime", name: "starttime", header: this.tr("Start Time"), width: 125, type: "number" },
			{ field: "enddate", name: "enddate", header: this.tr("End Date"), width: 125, type: "date" },
			{ field: "endtime", name: "endtime", header: this.tr("End Time"), width: 125, type: "number" },
			{ field: "total", name: "total", header: this.tr("Sum (inc. VAT)"), width: 200, type: "number" },
			{ field: "mileage_distance", name: "mileage_distance", header: this.tr("Distance (count)"), width: 20, type: "number" },
			{ field: "daily_allowance_days", name: "daily_allowance_days", header: this.tr("Daily allowance days (count)"), width: 200, type: "number" },
            { field: "country", name: "country", header: this.tr("Country"), width: 180, sortable: false },
        ];

        expenseFields.push({ field: "currency_total", name: "currency_total", header: this.tr("Original Sum (inc. VAT)"), width: 200, type: "number" })

		if (this.context.addons && this.context.addons.procountor) {
            expenseFields.push({ field: "in_procountor", name: "in_procountor", header: this.tr("In Procountor"), width: 125 })
            travelFields.push({ field: "in_procountor", name: "in_procountor", header: this.tr("In Procountor"), width: 125 })
		}

		return { expenseFields, travelFields };
	}

	render() {
		if(!this.state.stickySearchInitialized) {
            return null;
		}

		const { userObject, userObject: { sidebarStyle }, functions: { checkPrivilege, checkPrivilegeAny } } = this.context;
		const { companies, company, printLanguageOptions, listType } = this.state;
		if (!checkPrivilegeAny("worktrips", undefined, company)) {
			return <NoPermissionOverlay />
		}

		const { expenseFields, travelFields } = this.getFields();

		const StyledTabs = withStyles({
			indicator: {
				display: "none"
			}
		})(Tabs)

		const StyledTab = withStyles({
			root: {
				backgroundColor: "#f9f9f9",
				minWidth: '50px',
				minHeight: '35px;'
			},
			selected: {
				color: "#ffffff",
				backgroundColor: this.state.tabColor
			}
		})(Tab)

        const rowProps = {
			updateView: this.props.updateView,
			attachmentHandler: this.callAttachmentDialog,
			fetchData: this.fetchData,
			currency: this.state.companyCurrency,
			print: this.print,
			printLanguageOptions: this.state.printLanguageOptions
		}

		const button = {
			className: 'list-menu',
			stickyIcon: true,
		}

		const SelectProps = {
			MenuProps: {
				onEntering: () => this.unListenReset(),
				onExited: () => this.listenReset()
			}
		}

		const showDialog = this.state.currentDialog ? true : false;
		
		const expenseFieldsColumns = expenseFields.filter(e => e.name != 'reporting_groups').map(e => {
			return e;
		});
		const travelFieldsColumns = travelFields.filter(t => t.name != 'reporting_groups').map(t => {
			return t;
		});	

        const fields = listType == "expenses" ? expenseFields : travelFields;

		return (
			<div className="contentBlock expense-list-container" id="expenseandtravelexpenselist">
				<WithTabs selectedTab={listType} selectedTabKey="listType">
					{(selectedTab) => (
						<>
							<div className="listControlsContainer clearfix">
								<div className="filterContainer">
									<div className="header-container primary">
										{companies.length > 1 && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Company")} value={company} select onChange={e => {
												companies.forEach(company => {
													if(company.id == e.target.value)
														this.setState({companyCurrency: company.currency});
												})
												this.setState({ company: e.target.value }, () => this.getAutoCompleteData({}));
												this.list.current.setPage(1);
												this.context.functions.setLastCompany(e.target.value);

												} }>
												{companies.map(row => (
													<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
												))}
											</OutlinedField>
										}
										{checkPrivilege("worktrips", "approve", this.state.company) && (
											<OutlinedField
												className="listFilterOutlinedField"
												SelectProps={SelectProps}
												select
												label={this.tr("Options")}
												name="options"
												shrinkLabel={false}>
												<div className="expenseOptionWrapper">
													<MenuItem onMouseDown={() => this.setExpenseStates(2)}>{this.tr("Approve")}</MenuItem>
													<MenuItem onMouseDown={() => this.setExpenseStates(3)}>{this.tr("Reject")}</MenuItem>
													<MenuItem onMouseDown={() => this.setExpenseStates(4)}>{this.tr("Archive")}</MenuItem>
												</div>
											</OutlinedField>
										)}
										<ContextMenu 
											buttonClassName="listFilterContextmenu"
											dropdownMenu
											name="exports"
											label={this.tr("Print & export")}>
												{printLanguageOptions.length > 1 ?
													<ContextSubMenu title={this.tr("Print")}>										
														{printLanguageOptions.map(pl => 
															<MenuItem onClick={() => this.print(pl.value)}>{pl.label}</MenuItem>
														)}
													</ContextSubMenu>
													:
													<MenuItem onClick={() => this.print(printLanguageOptions[0]?.value)}>{this.tr("Print")}</MenuItem>
												}
												{this.context.addons.procountor && <MenuItem key={'procountor'} onClick={this.sendProcountor}>{this.tr('Send Procountor')}</MenuItem>}
												{this.context.addons.netvisor && <MenuItem key={'netvisor'} onClick={() => this.sendNetvisor()}>{this.tr('Send Netvisor')}</MenuItem>}
												{this.context.addons.netvisor && listType == "expenses" && <MenuItem key={'netvisor'} onClick={() => this.sendNetvisor("escan")}>{this.tr('Send Netvisor via eScan')}</MenuItem>}
												{this.context.addons.fortnox && <MenuItem key={'fortnox'} onClick={this.sendFortnox}>{this.tr('Send Fortnox')}</MenuItem>}
										</ContextMenu>

										{/*<OutlinedField select label={this.tr("View")} className="listFilterOutlinedField" value={this.state.listType} onChange={e => {
											this.setState({ view: e.target.value });

											e.target.value === "expenses" ? this.showExpenses() : this.showTravelExpenses();
										}}>
											<MenuItem value="expenses">{this.tr("Expenses")}</MenuItem>
											<MenuItem value="travelExpenses">{this.tr("Traveling expenses")}</MenuItem>
										</OutlinedField>*/}


									
										<OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Status")} value={this.state.tabValue} onChange={e => this.switchTabs(e, e.target.value)}>
											<MenuItem value={5}>{this.tr("All")}</MenuItem>
											{this.states.map((state, index) => <MenuItem value={index}>{state.name}</MenuItem>)}
										</OutlinedField>

										{this.state.listType == "expenses" && <OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Payment type")} name="payment_type" value={this.state.payment_type} onChange={this.dropChanged}>
											<MenuItem key={0} value={0}>{this.tr("All")}</MenuItem>
											{this.state.paymentTypes.map(row => (
												<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
											))}
										</OutlinedField>}

										{this.state.listType == "expenses" && false && <OutlinedField className="listFilterOutlinedField" select SelectProps={SelectProps} label={this.tr("Billing type")} name="billing_type" value={this.state.billing_type} onChange={this.dropChanged}>
											<MenuItem key={0} value={0}>{this.tr("All")}</MenuItem>
											<MenuItem key={1} value={1}>{this.tr("Billed")}</MenuItem>
											<MenuItem key={2} value={2}>{this.tr("Unbilled")}</MenuItem>
											<MenuItem key={3} value={3}>{this.tr("Non Billable")}</MenuItem>
										</OutlinedField>}

										<div className="drop-container date-range">
											<DateRangePicker
												className="basic-info daterange"
												ranges={[this.state.date_range]}
												onChange={this.handleRangeSelect}
												onInputChange={this.handleRangeInputChange}
												label={this.tr("Date range")}
												dateFormat={userObject.dateFormat} />
										</div>

										<AdvancedSearch
											key={this.state.listType}
											mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
											initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
											mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
											freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
											alwaysShowClearFilters={!this.filtersAreInInitialState()}
											onClearSearch={this._resetFilters}
											ref={this.advancedSearch}
											fields={[
												...fields
												.filter(f => ["context", "date", "checked", "state",  "attachment", "currency_total", this.state.listType === "travelExpenses" ? "total" : undefined].indexOf(f.field) === -1)
												.map(f => ({ field: f.field, transl: f.header, type: f.type, visualizationType: f.visualizationType || "list", entityMode: f.entityMode || false })), 
												...createAdvancedSearchFieldsForDimensions(this.state.dimensions)
											]}
											onSearchResult={this.setData}
											autoCompleteData={{
												account: [this.autoCompleteData['customers'], "parent_id"],
												project: [this.autoCompleteData['projects'], "parent_id"],
												team_groups: this.autoCompleteData['team_groups'],
												fullname: this.autoCompleteData['project_managers'],
												payment_type: this.autoCompleteData['payment_types'],
												reporting_groups: [this.autoCompleteData['reporting_groups'], "parent_id"],
												fullname: this.state.employees,
												...this.state.dimensionAutoCompleteData
											}}
											autoCompleteDataFilters={{
												project: "customers_id|account.id",
												reporting_groups: "customers_id|account.id",
												...this.state.dimensionAutoCompleteDataFilters
											}}
											perpage={this.state.perpage}
											noRequests={true}
											onSearchTrigger={(searchTerms) => {
												this.searchTerms = searchTerms;
												if (this.list.current) {
													this.list.current.setPage(1);
													this.list.current.resetCheckedRows();
												}

												this.fetchData({}, false, response => {
													this.setData(response);
												});
											}}
										/>
									</div>

									<div className="type-tabs">
										<div className="tab-container">
											<StyledTabs
												tabItemContainerStyle={{ width: '100px' }}
												onChange={this.switchTabs}
												value={this.state.tabValue}>
												{Object.values(this.states).map((entry, i) => {
													return <StyledTab key={i} label={entry.name} selected={this.state.tabValue == i} />;
												})}
											</StyledTabs>
										</div>
									</div>
								</div>
								{this.renderSummarySection()}
							</div>
							{selectedTab == 'expenses' && !this.state.isLoadingList && <List
								minWidth="1500px"
								handler={this}
								ref={this.list}
								data={this.state.data.expenses}
								columns={expenseFieldsColumns}
								sharedData={this.autoCompleteData}
								noStateData={true}
								height="fitRemaining"
								className="expense-list"
								listRowType={ExpenseListRow}
								saveColumnConfig={true}
								userListSettingsKey="expense_list"
								ignoreRowPropsChange={false}
								rowProps={{ 
									...rowProps, 
									onUpdate: data => {
										this.updateExpense("expense", data);
									}
								}}
								showNoResultsMessage={this.initialFetchDone.expenses}
								showOverlay={!this.state.hasExpenses}
								overlayComponent={ExpenseListOverlay}
								useAllCheckedExcept={true}
								perpage={this.state.perpage}
								controlPage={true}
								page={this.state.page}
								showPageSelector={true}
								pageCount={this.state.pageCount}
								totalCount={this.state.expenseCount}
								onSortRows={this.sortRows}
								onPageChange={page => {					
									this.list.current.startPageChangeAnimation();
									this.setState({ page }, () => this.fetchData({ page }));
								}}
								onPerPageChange={perpage => {
									this.list.current.setPage(1);
									this.setState({ perpage }, () => this.fetchData({ perpage, page: 1 }));
								}} 
								useHSRightPadding
								/>}

							{selectedTab == 'travelExpenses' && <List
								minWidth="1500px"
								handler={this}
								ref={this.list}
								data={this.state.data.travelExpenses}
								columns={travelFieldsColumns}
								sharedData={this.autoCompleteData}
								height="fitRemaining"
								className="travel-list"
								listRowType={TravelExpenseListRow}
								showNoResultsMessage={this.initialFetchDone.travel}
								showOverlay={!this.state.hasExpenses}
								overlayComponent={TravelExpenseListOverlay}
								saveColumnConfig={true}
								userListSettingsKey="travel_list"
								ignoreRowPropsChange={false}
								rowProps={{ 
									...rowProps, 
									onUpdate: data => {
										this.updateExpense("travel_expense", data);
									}
								}}
								noStateData={true}
								useAllCheckedExcept={true}
								perpage={this.state.perpage}
								page={this.state.page}
								controlPage={true}
								showPageSelector={true}
								pageCount={this.state.pageCount}
								totalCount={this.state.expenseCount}
								onSortRows={this.sortTravelRows}
								onPageChange={page => {
									this.list.current.startPageChangeAnimation();
									this.setState({ page }, () => this.fetchData({ page }));
								}}
								onPerPageChange={perpage => {
									this.list.current.setPage(1);
									this.setState({ perpage: perpage }, () => this.fetchData({ perpage: perpage, page: 1 }));					
								}} 
								useHSRightPadding
								/>}
						</>
					)}
				</WithTabs>
				{showDialog &&
					<AttachmentDialog
						open
						onDialogClose={this.closeDialog}
						data={this.state.dialogData} />
				}

				{this.state.arhciveDialogOpen && <Dialog
                    open={this.state.arhciveDialogOpen}
                    onClose={() => this.setState({ arhciveDialogOpen: false })}
                    maxWidth="xs"
                    className="archive-dialog"
                    fullWidth={true}>
					<div className="dialog-topbar" />
					<DialogTitle>{this.tr("Archive sent invoices")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{this.tr("Do you want to archive sent invoices?")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button size="large" variant="outlined" onClick={() => this.setState({ arhciveDialogOpen: false })}>
							{this.tr("Cancel")}
						</Button>
						<Button size="large" variant="outlined" color="primary" onClick={() => {
							this.setExpenseStates(4);
							this.setState({ arhciveDialogOpen: false });
						}}>
							{this.tr("Archive invoices")}
						</Button>
					</DialogActions>
				</Dialog>}
			</div>
        );
	}
}

ExpenseList.defaultProps = {
	perpage: 30,
	showLockedUsersWithTag: true,
}

export default withSnackbar(withStyles(styles)(ExpenseList));
