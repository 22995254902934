import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';
import './TimeRangeOutlinedField.css';
import { FilledInput } from '@mui/material';

class TimeRangeOutlinedField extends React.Component {
    static defaultProps = {
        label: 'Time',
        disabled: false,
        disabledReason: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    parseTextToRange = (text) => {
        let [start, end] = text.split('-');

        if (!start || !end)
        {
            start = undefined;
            end = undefined;
        }
        else
        {
            start = moment(start, "LT");
            end = moment(end, "LT");

            if (!start.isValid() || !end.isValid())
            {
                start = undefined;
                end = undefined;
            }
        }

        return [start, end]
    }

    onBlur = (e) => {
        if (!this.props.useState) return;
        const [start, end] = this.parseTextToRange(e.target.value);
        this.props.onChange(e.target.value, start, end);
    }

    onChange = (e) => {
        const [start, end] = this.parseTextToRange(e.target.value);
        if (this.props.useState) {
            this.setState({ value: e.target.value });
        } else {
            this.props.onChange(e.target.value, start, end);
        }
    }

    render() {
        const { name, rootClass, value, label = "Time", disabled, disabledReason, useState } = this.props;

        const [start, end] = this.parseTextToRange(value);

        const len = (start && end ? end.diff(start, "hours", true) : 0).toFixed(2);
        
        return (
            <FormControl className={rootClass} variant="filled">
                <InputLabel
                    shrink
                    ref={ref => {
                        this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor={`${name}-date`} >

                    {label} {len > 0 && <span className="timeRangeHours">{len} h</span>}
                    {disabledReason && <span className="timeRangeDisabledReason">{disabledReason}</span>}
                </InputLabel>

                <FilledInput
                    autoComplete="off" 
                    type="text"
                    // name={`${name}`}
                    id={`${name}-date`}
                    value={useState ? this.state.value : value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    error={this.props.error}
                    inputProps={{
                        'data-testid': this.props['data-testid'] || 'time-range'
                    }}
                    disabled={disabled} />
            </FormControl>
        );
    }
}

TimeRangeOutlinedField.defaultProps = {
    value: "07:00",
}

export default TimeRangeOutlinedField;
