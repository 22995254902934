import React, { Component, PureComponent } from 'react';
import cn from 'classnames';
import $ from 'jquery';
import './SortableList.css';
import { filterByText } from '../helpers';

class DropTarget extends PureComponent {
	static defaultProps = {
		newPosition: 0,
		dragType: '',
	}

	constructor(props)
	{
		super(props)

		this.dragEnter = this.dragEnter.bind(this);
		this.dragLeave = this.dragLeave.bind(this);
		this.dragOver  = this.dragOver.bind(this);
		this.drop      = this.drop.bind(this);

		this.state = {over: false};
	}

	dragEnter(e)
	{
		if (e.dataTransfer.types.indexOf(this.props.dragType) === -1)
			return;

		this.setState({over: true});
	}

	dragLeave(e)
	{
		if (e.dataTransfer.types.indexOf(this.props.dragType) === -1)
			return;

		this.setState({over: false});
	}

	dragOver(e)
	{
		if (e.dataTransfer.types.indexOf(this.props.dragType) === -1)
			return;

		e.preventDefault();
	}

	drop(e)
	{
		if (e.dataTransfer.types.indexOf(this.props.dragType) === -1)
			return;

		var data = parseInt(e.dataTransfer.getData(this.props.dragType), 10);

		this.props.onDrop(data, this.props.newPosition);

		this.setState({over: false});
	}

	render() {
		return <div 
			className={cn("drop_target", this.state.over && "active")} 
			onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDrop={this.drop} onDragOver={this.dragOver} />
	}
}

export default class SortableList extends Component {
	static defaultProps = {
		items: [],
		keyfield: "id",
		dragType: "default",
	}

	state = {
		overIndex: undefined
	}

	changeOrder = (newIndex) => {
		if (this.dragIndex === newIndex) return;
		const itemToMove = this.props.items[this.dragIndex];
		var otherItems = [...this.props.items];
		otherItems.splice(this.dragIndex, 1);
		if (newIndex > this.dragIndex)
			newIndex--;
		otherItems.splice(newIndex, 0, itemToMove);
		this.props.onOrderChanged(otherItems);
	}

	onDragStart = (e, index) => {
		const dragItem = this.props.items[index];
		this.props.onDragStart(e, dragItem);
		e.dataTransfer.setData(this.props.dragType, index);
		this.dragIndex = index;
		e.dataTransfer.effectAllowed = "copyMove";
		$('body').addClass('dragging');
	}

	onDragEnd = () => {
		$('body').removeClass('dragging');
		this.setState({ overIndex: undefined });
	}

	onDragEnter = (e, overIndex) =>
	{
		if (e.dataTransfer.types.indexOf(this.props.dragType) === -1)
			return;
		if (overIndex != this.state.overIndex) {
			this.setState({ overIndex: overIndex == this.dragIndex ? undefined: overIndex });
		}
	}

	onDragOver = (e) => {
		e.preventDefault();
	}

	render() {
		const Item = this.props.component;

		const { overIndex } = this.state;
		const { items, filter, dragType, keyfield, onChangeColor, disableDrag } = this.props;

        return <div className={cn("TMRSortableList", this.props.className)}>
			{filterByText(items, filter).map((item, index) =>
				<div key={item[keyfield]} draggable={!disableDrag} onDragOver={this.onDragOver} onDragEnter={e => this.onDragEnter(e, index)} onDragStart={(e) => this.onDragStart(e, index)} onDragEnd={this.onDragEnd} onDrop={() => this.changeOrder(index + 1)}>
					<Item item={item} {...this.props} onChangeColor={onChangeColor} />
					{overIndex == index && <div className="dragOverIndicator" />}
				</div>
			)}
		</div>
	}
}