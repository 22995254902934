import { returnCurrencyList } from "./Currencies";
import cloneDeep from 'lodash/cloneDeep';

const currencySymbolsMap = returnCurrencyList()?.reduce((acc, cl) => ({...acc, [cl.id]: cl.symbol}), {});

interface currencyObject {
    currency_label: string;
	id: string;
    active_status: string;
    currency_rate: string;
    currency_rate_reverse: string;
}

interface basicDropData {
    name: string;
    label: string;
    id: string;
}

interface currencyMappedObject extends basicDropData {
    rateId: string;
    currency_code: string;
    currency_rates_id: string;
    status: string;
    rate: number;
    currency_rate: number;
    rate_reverse: number;
    reverse: number;
}

interface currencyDetailsObject {
    currency_code: string;
    currency_rate: number;
    currency_rates_id: string;
}

interface params {
    defaultValue: currencyDetailsObject;
    field: string;
    searchValue: string;
}

interface rowObject {
    deleted: string;
}

export class CurrencyUtils {


    static getSymbol(currencyCode: string): string {
        return currencySymbolsMap[currencyCode];
    }

    static mapCurrenciesForAutocomplete(rawCurrencyArray: currencyObject[]): currencyMappedObject[] {
    	const mappedCurrencies = rawCurrencyArray.map(c => ({
    		rateId: c.id, 
    		id: c.currency_label,
            currency_rates_id: c.id,
    		name: c.currency_label,
            currency_code: c.currency_label, 
    		status: c.active_status, 
    		label: c.currency_label, 
    		rate: Number(c.currency_rate),
            currency_rate: Number(c.currency_rate), 
    		symbol: this.getSymbol(c.currency_label), 
    		rate_reverse: Number(c.currency_rate_reverse), 
    		reverse: Number(c.currency_rate_reverse)
    	}));
        return mappedCurrencies;
    }

    static returnActiveCurrencyAutocompleteValue(currencies: currencyMappedObject[], id: string, params: params): basicDropData {
        
        const {defaultValue: {currency_code}} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const activeCurrency = activeCurrencies.find(ac => ac.id == id)

        const autocompleteValue = ['id', 'name', 'label'].reduce((acc, r) => ({...acc, [r]: activeCurrency?.id || currency_code}), {} as basicDropData);
        return autocompleteValue;
    }

    static sumRowValues(rows, params: {fieldName: string, rate: number, convertable: boolean, oldCurrencyConvert: boolean}): number {

        let sum = 0;
        const {fieldName, rate, convertable, oldCurrencyConvert} = params;

        rows.map(r => {
            let rowValue = !isNaN(r[fieldName]) ? Number(r[fieldName]) : 0;
            let preciseValue = !isNaN(r[`precise_${fieldName}`]) ? Number(r[`precise_${fieldName}`]) : 0;
            if (rate && rate != 0 && convertable)
                rowValue = oldCurrencyConvert ? preciseValue : rowValue * rate;

            sum += (!r.deleted || r.deleted < 1) && r.row_type != 13 ? rowValue : 0
        });

        return Number(sum.toFixed(2));       
    }    

    static returnFirstActiveCurrencyDetails(currencies: currencyMappedObject[], params: params): currencyDetailsObject {
        const {defaultValue} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const {currency_code = defaultValue.currency_code, currency_rate = defaultValue.currency_rate, currency_rates_id = defaultValue.currency_rates_id} = activeCurrencies?.[0] || {};
        return {currency_code, currency_rate, currency_rates_id};
    }

    static returnSpecificActiveCurrencyDetails(currencies: currencyMappedObject[], params: params): currencyDetailsObject {
        const {defaultValue, field, searchValue} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const currency = activeCurrencies.find(c => c[field] == searchValue);
        const {currency_code = defaultValue?.currency_code, currency_rate = defaultValue?.currency_rate, currency_rates_id = defaultValue?.currency_rates_id} = currency || {};
        return {currency_code, currency_rate, currency_rates_id};
    }

    static returnMatchingActiveCurrencyDetail(currencies: currencyMappedObject[], field: string, searchValueProgression: string[]): any {
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        let currency: currencyDetailsObject|undefined = undefined;
        searchValueProgression.some(v => {
            currency = activeCurrencies.find(c => c.id == v);
            return currency;
        });
        return currency?.[field];
    }
}

//chekka et kui teed uue arve vana editoinnist siis tulebikka company data