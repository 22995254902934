import React from 'react';
import PropTypes from "prop-types";
import TaimerComponent from "../TaimerComponent";


/* material ui */
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import ToolTip from '@mui/material/Tooltip';

/* local components */
import List from '../list/List'
import ContextMenu from '../general/ContextMenu';
import FileSize from '../general/FileSize';
import TaimerAvatar from '../general/TaimerAvatar';
import OnedriveLogin from '../onedrive/OnedriveLogin'

/* data backend */
import DataHandler from './../general/DataHandler';

/* context */
import { SettingsContext } from './../SettingsContext';

/* material icons */
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import OnedriveIcon from '@mui/icons-material/CloudQueue'
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from './../general/icons/view.svg';
import { ReactComponent as DocuSignIcon } from './../general/icons/docusignIcon.svg';
import { ReactComponent as GoogleDriveLogo } from './../general/icons/logo_google_drive_small.svg';
import { ReactComponent as GoogleDocsLogo } from '../general/icons/logo_google_docs.svg';
import { ReactComponent as GoogleSheetsLogo } from '../general/icons/logo_google_sheets.svg';
import { ReactComponent as GoogleSlidesLogo } from '../general/icons/logo_google_slides.svg';

import { withSnackbar } from 'notistack';
import FileSaver from 'file-saver';

/* css */
import './TabAttachments.css';
import PageTopSection from './PageTopSection';
import Link from './Link';

const styles = theme => ({
    icon: {
        color: "#ffffff",
        "font-size": "33px",
        "margin-left": "-14px",
    }
});

class DriveIcon extends TaimerComponent {

    render() {
        const { fileType } = this.props;

        return (
            <>
                {fileType === 'application/vnd.google-apps.document' && <GoogleDocsLogo />}
                {fileType === 'application/vnd.google-apps.spreadsheet' && <GoogleSheetsLogo />}
                {fileType === 'application/vnd.google-apps.presentation' && <GoogleSlidesLogo />}
                {!fileType.includes('application/vnd.google-apps') && <GoogleDriveLogo />}
            </>
        );
    }
}

class AttachmentsRow extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "general/TabAttachments");
    }
    handleView = event => {
        const { rowProps, data } = this.props;
        rowProps.handleView(data);
    };
    handleDelete = event => {
        const { rowProps, data } = this.props;
        rowProps.handleDelete(data);
    };

    render() {
        const { columnOrder, columnWidthMap, data } = this.props;

        const props = new Proxy({}, {
            get: (obj, prop) => { return { key: prop, "className": prop, style: { width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" } } }
        });
        const { tr } = this;

        let docusignStatus = "";
        switch (data.docusign_status) {
            case '1':
                docusignStatus = tr("Sent");
                break;

        }

        let showDocusign = !!this.context.addons.docusign;
        let docusignExtensions = [];
        let fileExtension = /\.([A-Za-z]+?)$/.exec(data.filename);

        if (!fileExtension) {
            showDocusign = false;
        } else {
            let supportedTypes = ["doc", "docm", "docx", "dot",
                "dotm", "dotx", "htm", "html", "msg", "pdf", "rtf",
                "txt", "wpd", "xps", "bmp", "gif", "jpg", "jpeg", "png",
                "tif", "tiff", "pot", "potx", "pps", "ppt", "pptm",
                "pptx", "csv", "xls", "xlsm", "xlsx"
            ];
            showDocusign &= supportedTypes.indexOf(fileExtension[1].toLowerCase()) > -1;
        }

        const createdByWithAllTags = `${data.created_by} ${data.created_by_company < 1 ? ` (${this.tr("freelancer")})` : ''}${data.created_by_locked > 0 ? ` (${this.tr("locked")})` : ''}`;
        
        const cells = {
            "context": (
                <ContextMenu className="cell row-menu" label={<MoreHoriz />} {...props.context} noExpandIcon>
                    <MenuItem className="menuIcon view" onClick={this.handleView}><ViewIcon />{tr("View")}</MenuItem>
                    {this.props.rowProps.editable && (
                        <MenuItem className="menuIcon delete" onClick={this.handleDelete}><RemoveIcon className="Delete" />{tr("Delete")}</MenuItem>
                    )
                    }
                    {
                        this.props.rowProps.editable && showDocusign && (<MenuItem className="menuIcon docuSign" onClick={() => this.props.rowProps.sendWithDocusign(data.attachments_id)}><DocuSignIcon />{tr("Send with DocuSign")}</MenuItem>)
                    }
                </ContextMenu>
            ),
            "locationtype": (
                <div class="location-type">{data.locationtype === 'googledrive' && <DriveIcon fileType={data.type} />}</div>
            ),
            "filename": (
                <Link url="#" onClick={this.handleView} {...props.filename}>{data.filename}</Link>
            ),
            "size": (
                <div {...props.size}>
                    <FileSize>{data.filesize - 0}</FileSize>
                </div>
            ),
            "createdby": (
                <div {...props.createdby}>
                    <TaimerAvatar
                        id={data.created_by_id}
                        name={data.created_by}
                        color={data.color}
                    />
                    <div className="person">
                        <span>{createdByWithAllTags}</span><br/>
                        {data.role}
                    </div>
                </div>
            ),
            "docusign_status": (
                <div {...props.docusign_status}>
                    <span>{docusignStatus}</span>
                </div>
            ),
        };

        return (
            <div className="list-element">
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class TabAttachments extends TaimerComponent {
    static contextType = SettingsContext;
    state = {
        attachments: [],
        onedrive: false,
        googledrive: (this.context.addons.googledrive && this.context.addons?.googledrive?.used_by_companies.indexOf(this.context.userObject.companies_id) > -1),
        googleDriveAuthorized: false,
        googledriveFolder: ''
    }

    constructor(props, context) {
        super(props, context, "general/TabAttachments");
        this.upload = React.createRef();
    }

    updateComponentData = () => {
        const { id, entity } = this.props;

        DataHandler.get({ url: `${entity}/${id}/attachments` }).done(data => {
            let filtered_data = data.filter(item => {
                if (item.filesize == null || item.id == null || item.filesize == "0") return false;
                else return true;
            });

            this.setState({ attachments: filtered_data }, () => {
                if (this.state.googledrive && this.state.googleDriveAuthorized) {
                    if(entity === 'projects' || entity === 'accounts') this.checkIfDriveFolderExists();
                    if(entity === 'invoices') this.checkIfHasAttachments('invoices'); //Let's not straight just pass entity itself
                }
            });
        });
    }
    componentDidMount() {
        super.componentDidMount();
        if(this.state.googledrive) this.checkIfGoogleDriveAuthorized(); //Calls updateCompnent data. This way we set the state.googleDriveAuthorized before calling updateComponentData
        else this.updateComponentData();

    }
    deleteEntity = (row) => {
        const { attachments } = this.state;
        const { id, entity, enqueueSnackbar } = this.props;

        DataHandler.delete({ url: `attachments/${row.id}`, entity: entity, entity_id: id }).done(e => {
            enqueueSnackbar(this.tr('Attachment removed succesfully!'), {
                variant: "success"
            });
            this.updateComponentData();
        });
    };
    onDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    };
    uploadFiles = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        const { userObject } = this.context;
        const { attachments, googledrive, googledriveFolder } = this.state;
        const { id, entity, enqueueSnackbar } = this.props;
        const { taimerAccount: { attachmentMaxSize } } = this.context;

        const files = Array.from(evt.target.files || evt.dataTransfer.files);

        let totalSize = 0;

        files.forEach(function (file) {
            totalSize += file.size;
        });

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        const url = googledrive && googledriveFolder !== '' ? `drive/google/uploadfile/${googledriveFolder}` : `${entity}/${id}/attachments`;

        files.forEach(e => {
            DataHandler.file({ url: url }, e, undefined, e => {
                if (e.responseJSON === false) {
                    enqueueSnackbar(this.tr('Uploading file failed'), {
                        variant: "error"
                    });
                    return;
                } else {
                    enqueueSnackbar(this.tr('File uploaded'), {
                        variant: "success"
                    });
                    this.updateComponentData();
                    return;
                }
            });
        });
    }
    sendWithDocusign = id => {
        const { enqueueSnackbar, closeSnackbar } = this.props;
        let key = enqueueSnackbar(this.tr("Sending to DocuSign. Please wait..."), {
            variant: "info",
            persist: true,
        });
        DataHandler.post({ url: 'docusign/send_file' }, { attachments_id: id, currentUrl: window.location.href }).done(data => {
            closeSnackbar(key);
            if (data.error) {
                enqueueSnackbar(data.error, {
                    variant: "error",
                });
            } else {
                window.open(data.url);
                this.updateComponentData();
            }
        });
    }

    checkIfGoogleDriveAuthorized = () => {

        DataHandler.post({ url: `drive/google/connect` }, { company: this.props.company })
            .done(response => {
                if (response.authenticated) {
                    this.setState({
                        googleDriveAuthorized: response.authenticated === 1
                    }, () => { 
                        this.updateComponentData(); 
                });
                    
                } else {
                    //Eeven if we did not pass the auth, we still want to update
                    this.updateComponentData();
                }
            })
            .fail(response => {
                this.setState({ googleDriveAuthorized: false });
            });
    }

    checkIfDriveFolderExists = async () => {
        const { id, entity } = this.props;
        const { googledriveFolder } = this.state;

        let folderId = googledriveFolder;

        if (folderId === '') {
            if (entity === 'accounts') {
                const folder = await this.getGoogleDriveClientFolder(id,true).catch(err => {
                    console.log(err);
                });

                /*if (folder && Object.keys(folder).length > 0 && folder.id !== '') {
                    folderId = folder.id;
                }*/
            }
            if (entity === 'projects') {
                const folder = await this.getGoogleDriveProjectFolder(id,true).catch(err => {
                    console.log(err);
                });

                /*if (folder && Object.keys(folder).length > 0 && folder.id !== '') {
                    folderId = folder.id;
                }*/
            }
        }

        //Folder id could still be empty
        if (folderId !== '') {
            let attachments = await this.getGoogleDriveFilesFromFolder(folderId);
            this.setState({ attachments: [...this.state.attachments, ...attachments] });
        }

    }

    getGoogleDriveProjectFolder = async (projectId,getAttachments = false) => {
        let response = DataHandler.post({ url: 'drive/google/getprojectfolder' }, { project_id: projectId })
            .done(response => {
                if (!response.error) {
                    if (Object.keys(response.folder).length > 0) this.setState({ googledriveFolder: response.folder.id });
                    if(getAttachments && response.folder.id !== '' && typeof response.folder.id !== 'undefined'){
                        this.getGoogleDriveFilesFromFolder(response.folder.id, true);
                    }
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });

        return response.folder;
    };

    getGoogleDriveClientFolder = async (clientId,getAttachments = false) => {
        const { enqueueSnackbar } = this.props;

        let response = await DataHandler.post({ url: 'drive/google/getclientfolder' }, { client_id: clientId })
            .done(response => {
                if (!response.error) {
                    if (Object.keys(response.folder).length > 0) this.setState({ googledriveFolder: response.folder.id });
                    if(getAttachments && response.folder.id !== '' && typeof response.folder.id !== 'undefined'){
                        this.getGoogleDriveFilesFromFolder(response.folder.id, true);
                    }
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                enqueueSnackbar(this.tr('Client folder unavailable'), {
                    variant: "error"
                });
                console.log(err);
            });
        if (response.folder) return response.folder;
    };

    //This is used to set the Google Drive's ROOT folder for customer / account
    setGoogleDriveClientFolder = (clientId, folderId) => {
        DataHandler.post({ url: 'drive/google/setclientfolder' }, { client_id: clientId, folder_id: folderId })
            .done(response => {
                if (!response.error) {
                    if (response.satus === 'ok') this.setState({ googledriveFolder: folderId });
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    //This is used to set the Google Drive's ROOT folder for customer / account
    setGoogleDriveProjectFolder = (projectId, folderId) => {
        DataHandler.post({ url: 'drive/google/setprojectfolder' }, { project_id: projectId, folder_id: folderId })
            .done(response => {
                if (!response.error) {
                    if (response.satus === 'ok') this.setState({ googledriveFolder: folderId });
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    createGoogleDriveFolder = async (folderParent, folderName) => {
        const { enqueueSnackbar } = this.props;

        let response = await DataHandler.post({ url: 'drive/google/createfolder' }, { folder_parent: folderParent, folder_name: folderName })
            .done(response => {
                if (!response.error) {

                    this.setState({ googledriveFolder: response.folder.id });

                    enqueueSnackbar(this.tr('Folder created succesfully!'), {
                        variant: "success"
                    });

                    return;
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
        return response.folder;
    };

    createGoogleDriveFile = (filetype) => {

        const { googledriveFolder } = this.state;

        DataHandler.post({ url: 'drive/google/createfile' }, { folder_parent: googledriveFolder, file_type: filetype })
            .done(response => {
                if (!response.error) {
                    window.open(response.file_url, "_blank");
                    this.updateComponentData();
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    getGoogleDriveFilesFromFolder = async (folderId, setAttachments = false) => {

        if(folderId === '' || typeof folderId === 'undefined') return [];
        
        let response = await DataHandler.post({ url: 'drive/google/getfilesfromfolder' }, { folder_id: folderId })
            .done(response => {
                if (!response.error) {
                    if(setAttachments){
                        this.setState({ attachments: [...this.state.attachments, ...response.files] });
                    } 
                    return;
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
        return response.files;
    };

    checkIfHasAttachments = (type) => {
        DataHandler.post({ url: 'drive/google/getattachmentsforinvoioces' }, { id: this.props.id, type: type })
            .done(response => {
                if (response.status !== 'no-attachments' && Object.keys(response.files).length > 0) {
                        this.setState({ attachments: [...this.state.attachments, ...response.files] });
                } else {
                    console.log(response.error); 
                }
            })
            .fail(err => {
                console.log(err);
            });
    }

    deleteDriveFile = (fileId) => {
        const { enqueueSnackbar } = this.props;

        DataHandler.post({ url: 'drive/google/deletefile' }, { file_id: fileId })
            .done(response => {
                if (!response.error) {

                    enqueueSnackbar(this.tr('Attachment removed succesfully!'), {
                        variant: "success"
                    });

                    this.updateComponentData();

                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    }


    //This is used when no folder has been found and we offer the create fold
    initiateGoogleDrive = async () => {
        const { id, project, account, entity, enqueueSnackbar } = this.props;

        if (entity === 'accounts') {
            let folder = await this.createGoogleDriveFolder('', account.name + ' (' + id + ')').catch(err => {
                console.log(err);
            });

            if (folder && Object.keys(folder).length > 0) {
                this.setGoogleDriveClientFolder(id, folder.id);
            } else {
                enqueueSnackbar(this.tr('Creating folder has failed. Contact the administrator.'), {
                    variant: "error"
                });
            }

        }
        else {
            let clientFolder = await this.getGoogleDriveClientFolder(project.account.id).catch(err => {
                console.log(err);
            });

            if (clientFolder && Object.keys(clientFolder).length > 0) {
                let folder = await this.createGoogleDriveFolder(clientFolder.id, project.name + ' (' + id + ')');
                this.setGoogleDriveProjectFolder(id, folder.id);
            } else {
                clientFolder = await this.createGoogleDriveFolder('', project.account.name + ' (' + project.account.id + ')').catch(err => {
                    console.log(err);
                });
                //Since google Drive does not give proper error when the user does not have sufficient rights, we might end up here and no clientFolder was actually created
                if (clientFolder && Object.keys(clientFolder).length > 0) {
                    this.setGoogleDriveClientFolder(project.account.id, clientFolder.id);
                    let folder = await this.createGoogleDriveFolder(clientFolder.id, project.name + ' (' + id + ')');
                    this.setGoogleDriveProjectFolder(id, folder.id);
                } else {
                    enqueueSnackbar(this.tr('Creating folder has failed. Contact the administrator.'), {
                        variant: "error"
                    });
                }
            }
        }

    };

    render() {
        const { classes, editable, entity } = this.props;
        const { googledrive, googledriveFolder, googleDriveAuthorized } = this.state;
        const { tr } = this;

        const rowProps = {
            handleDelete: row => {
                if (row.locationtype === 'googledrive') this.deleteDriveFile(row.id);
                else this.deleteEntity(row);
            },
            handleView: row => {
                const { id, file_url, locationtype } = row;

                if (locationtype === 'googledrive') {
                    window.open(file_url, "_blank");
                } else {
                    DataHandler.getArrayBuffer({ url: `attachment/${id}` }).done((response, status, xhr) => {
                        const type = xhr.getResponseHeader("content-type") || "";
                        const filename = xhr.getResponseHeader("Content-Disposition").match(/filename=(.*)/)[1] || "file";
                        const blob = new Blob([response], { type });
                        FileSaver.saveAs(blob, filename);
                    });
                }

            },
            sendWithDocusign: this.sendWithDocusign,
            editable: this.props.editable
        };

        let columns = [
            { name: "context", header: "", width: 48, showMenu: false },
            /*{ name: "filename", header: this.tr("Filename"), width: 500 },
            { name: "createdby", header: this.tr("Added by"), width: 250 },
            { name: "size", header: this.tr("Size"), width: 100 },*/
        ];

        //easiest way to get this to its right position is to just push it here in between
        if (this.state.googledrive) {
            columns.push({ name: "locationtype", header: "", width: 35, showMenu: false });
        }

        //And then push the rest of them
        columns.push(
            { name: "filename", header: this.tr("Filename"), width: 500 },
            { name: "createdby", header: this.tr("Added by"), width: 250 },
            { name: "size", header: this.tr("Size"), width: 100 },
        );

        if (this.context.addons.docusign) {
            columns.push({ name: "docusign_status", header: this.tr("Docusign status"), width: 100 });
        }

        if (this.state.onedrive) {
            return (
                <>
                    <OnedriveLogin className="attachments" />
                    {this.props.additionalContent}
                </>
            );
        } else {

            return (
                <div id="projects-attachments" className="projects-attachments-container">
                    {this.props.header && (
                        <PageTopSection
                            header={this.props.header}
                            subheaders={this.props.subheaders}
                        />
                    )}
                    <div className="column">
                        <List
                            columns={columns}
                            //trimHeight={-62} 
                            height="fitRemaining"
                            fluid data={this.state.attachments}
                            listRowType={AttachmentsRow}
                            rowProps={rowProps}
                            saveColumnConfig={true}
                            userListSettingsKey="tabAttachments" />
                    </div>
                    <div className="column attachment-invoice-resize">
                        {editable &&
                            <React.Fragment>
                                <h3>{tr("Add Attachment")}</h3>
                                <div className="add-file" onDragOver={this.onDragOver} onDrop={this.uploadFiles}>
                                    <div>
                                        <CloudDownloadOutlined /><br />
                                        <span onClick={() => this.upload.current.click()}>{tr("Choose a file")}</span> {tr("or drag it here")}
                                        <input ref={this.upload} onChange={this.uploadFiles} type="file" multiple />
                                    </div>
                                </div>
                                {
                                    (this.context.userObject.show_onedrive == 1 || googledrive) && (
                                        <React.Fragment>

                                            {(entity === 'projects' || entity === 'accounts') && googleDriveAuthorized &&
                                            <>
                                                <h3>{tr("Add Online Document")}</h3>

                                                <div className="googledrive-selection">
                                                    {googledrive && googledriveFolder === '' &&
                                                        <Button className="center googledrive-selection-button" onClick={() => { this.initiateGoogleDrive() }}>
                                                            <div className="googledrive-text">{tr("Create folder to Google Drive")}</div>
                                                        </Button>
                                                    }
                                                    {googledrive && googledriveFolder !== '' &&
                                                        <div className="googledrive-add-files">
                                                            <div onClick={() => this.createGoogleDriveFile('document')} className="create-drive-file docs"><ToolTip title={tr("Add Google Doc")}><GoogleDocsLogo /></ToolTip></div>
                                                            <div onClick={() => this.createGoogleDriveFile('spreadsheet')} className="create-drive-file sheets"><ToolTip title={tr("Add Google Sheets")}><GoogleSheetsLogo /></ToolTip></div>
                                                            <div onClick={() => this.createGoogleDriveFile('presentation')} className="create-drive-file slides"><ToolTip title={tr("Add Google Slides")}><GoogleSlidesLogo /></ToolTip></div>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            }
                                            {(entity === 'projects' || entity === 'accounts') && !googleDriveAuthorized &&
                                                <React.Fragment>
                                                    {tr("In order to use Google Drive for attachments, you have to do personal authentication from My Profile settings.")}
                                                </React.Fragment>
                                            }

                                            {!googledrive && <React.Fragment>
                                                <div className="connect-options onedrive-selection-hide">
                                                    <div>{tr("Connect files to the cloud")}</div><br />
                                                    {tr("Allow your Taimer account to access your cloud storage. Click on icon to connect")}
                                                </div>
                                                <div className="onedrive-selection-hide">
                                                    <div className="onedrive-selection">
                                                        <Button className="center onedrive-selection-button" onClick={() => { this.setState({ onedrive: true }) }}>
                                                            <OnedriveIcon className={classes.icon} />
                                                            <div className="onedrive-text">{tr("OneDrive")}</div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )}
                            </React.Fragment>}
                        {this.props.additionalContent}
                    </div>
                </div>
            );
        }


    }
};
TabAttachments.propTypes = {
    id: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
};

export default withSnackbar(withStyles(styles)(TabAttachments));
