import React from "react";

const SIMPLE_LIST_CELL_ALIGNMENT_CLASSES = {
    left: "alignLeft",
    right: "alignRight"
};

class SimpleListCell extends React.Component {
    static defaultProps = {
        align: "left",
        cellClassName: "",
        cellStyle: {},
        cellValueClassName: "",
        cellValueStyle: {},
        flex: false,
        value: undefined,
        width: 140
    };


    constructor(props) {
        super(props);
    }


    render() {
        const cellClassName      = `cell ${this.props.cellClassName}`;
        const cellValueClassName = `cellValue ${SIMPLE_LIST_CELL_ALIGNMENT_CLASSES[this.props.align]} ${this.props.cellValueClassName}`;

        return (
            <div 
                className={cellClassName}
                data-testid={this.props['data-testid']}
                title={this.props.title}
                style={{
                    width: this.props.width,
                    flex: this.props.flex ? `${this.props.width} 1 0px` : undefined,
                    ...this.props.cellStyle
                }}>
                <div 
                    className={cellValueClassName}
                    data-testid={`${this.props['data-testid']}-value`}
                    style={this.props.cellValueStyle}>
                    {this.props.value} 
                </div>
            </div>
        );
    }
}

export default SimpleListCell;
