import React from "react";

import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../SettingsContext";
import OverviewBlock from "./OverviewBlock";
import colors from "../../../colors";
import DataHandler from "../../../general/DataHandler";

import "./HoursOverview.css";
import OverviewBlockCell from "./OverviewBlockCell";
import JobtypeTable from "./JobtypeTable";
import InsightDropDown from "../../insights/InsightDropDown";
import { formatInputNumber } from '../../../helpers';
import moment from "moment";

const MODE_KEY = "my_day_hours_overview_mode";
const JOBTYPE_MODE_KEY = "my_day_hours_overview_jobtype_mode";
export default class HoursOverview extends TaimerComponent {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/HoursOverview");

    this.modes = [
      {
        key: "summary",
        label: this.tr("Summary"),
        action: () => this._setMode("summary"),
        icon: require("../assets/summary.svg").default
      },
      {
        key: "by_jobtype",
        label: this.tr("By Jobtype"),
        action: () => this._setMode("by_jobtype"),
        icon: require("../assets/jobtype.svg").default
      }
    ];

    this.jobtypeModes = [
      {
        key: "today",
        label: this.tr("Today"),
        action: () => this._setJobtypeMode("today")
      },
      {
        key: "this_week",
        label: this.tr("This Week"),
        action: () => this._setJobtypeMode("this_week")
      },
      {
        key: "this_month",
        label: this.tr("This Month"),
        action: () => this._setJobtypeMode("this_month")
      }
    ];


    const mode = this._getModeFromLS(MODE_KEY, "summary");
    const jobtypeMode = this._getModeFromLS(JOBTYPE_MODE_KEY, "this_month");

    this.state = {
      mode,
      jobtypeMode,
      data: initialData,
      hoverIndex: null,
      loading: true
    };
  }

  _getItems = () => {
    const {
      functions: { checkPrivilege },
      userObject: { companies_id, disable_user_balance }
    } = this.context;
    const { data } = this.state;

    const usesOvertime = (data.summary.overtime || {}).value != -1;

    let fields = [
      {
        header: this.tr("Average Hours/Day"),
        key: "average_hours",
        formatValue: this._formatHours,
        right: true
      },
      {
        header: usesOvertime
          ? this.tr("Hour Balance / Overtime")
          : this.tr("Hour Balance"),
        dual: usesOvertime,
        keys: ["hour_balance", "overtime"],
        key: "hour_balance",
        formatValue: this._formatHours,
        right: !disable_user_balance,
      },
      {
        header: this.tr("Worked Today"),
        key: "worked_today",
        formatValue: this._formatHours,
        showHover: true,
        showDifference: true,
        formatSubValue: this._formatDifference,
        'data-testid': 'worked_today',
        right: true
      },
      {
        header: this.tr("Billable Rate Today"),
        key: "billable_rate_today",
        subKey: "subValue",
        formatValue: this._formatPercentage,
        formatSubValue: this._formatCurrency,
        right: checkPrivilege("invoices", "write_full", companies_id)
      },
      {
        header: this.tr("Worked This Week"),
        key: "worked_this_week",
        formatValue: this._formatHours,
        showHover: true,
        showDifference: true,
        formatSubValue: this._formatDifference,
        'data-testid': 'worked_this_week',
        right: true
      },
      {
        header: this.tr("Billable Rate This Week"),
        key: "billable_rate_this_week",
        subKey: "subValue",
        formatValue: this._formatPercentage,
        formatSubValue: this._formatCurrency,
        right: checkPrivilege("invoices", "write_full", companies_id)
      },
      {
        header: this.tr("Worked This Month"),
        key: "worked_this_month",
        formatValue: this._formatHours,
        showHover: true,
        showDifference: true,
        formatSubValue: this._formatDifference,
        'data-testid': 'worked_this_month',
        right: true
      },
      {
        header: this.tr("Billable Rate This Month"),
        key: "billable_rate_this_month",
        subKey: "subValue",
        formatValue: this._formatPercentage,
        formatSubValue: this._formatCurrency,
        right: checkPrivilege("invoices", "write_full", companies_id)
      },
      {
        header: this.tr("Client Hours This Month"),
        key: "client_hours",
        subKey: "subValue",
        formatValue: this._formatHours,
        formatSubValue: this._formatPercentage,
        right: true,
      },
      {
        header: this.tr("Internal Hours This Month"),
        key: "internal_hours",
        subKey: "subValue",
        formatValue: this._formatHours,
        formatSubValue: this._formatPercentage,
        right: true,
      },
    ];
    return fields.filter(f => f.right);
  };

  componentDidMount() {
    super.componentDidMount();
    this._getData();
  }

  shouldComponentUpdate = (newProps, newState) => {
    return newState != this.state || newProps.currency != this.props.currency;
  };

  _getData = () => {
    this.setState({ loading: true }, async () => {
      const { userObject: { usersId, language, companies_id } } = this.context
      let { data } = this.state;
      try {
        const newData = await DataHandler.get({url: `myday/hour_stats/${usersId}`, lang: language, company: companies_id});
        data.summary = newData.summary;
        data.graphData = newData.graphData;
        data.by_jobtype = newData.by_jobtype;
        this.setState({ data, loading: false });
      } catch (e) {
        console.log(e);
      }
    })
  }

  _getModeFromLS = (key, defaultValue) => {
    let mode = defaultValue;
    try {
      mode = localStorage.getItem(key) || defaultValue;
    } catch (e) {}
    return mode;
  };

  _setMode = mode => {
    this.setState({ mode });
    try {
      mode = localStorage.setItem(MODE_KEY, mode);
    } catch (e) {}
  };

  _setJobtypeMode = jobtypeMode => {
    this.setState({ jobtypeMode });
    try {
      localStorage.setItem(JOBTYPE_MODE_KEY, jobtypeMode);
    } catch (e) {}
  };

  _formatNumber = (number) => {
    return Math.round(Number(number) * 100) / 100;
  };

  _formatHours = value => {
    if (Number(value) === 0)
      return value + " h"

    return formatInputNumber(value, "hours") + " h";
  };

  _formatDifference = value => {
    return value > 0
      ? `+${formatInputNumber(value, "hours")} h`
      : value < 0
      ? `${formatInputNumber(value, "hours")} h`
      : `±${0} h`;
  };

  _formatPercentage = value => {
    if (Number(value) === 0 || Number(value) === 100)
      return value + " %"

    return formatInputNumber(value) + " %";
  };

  _formatCurrency = (value, currency) =>
    this.context.functions.presentCurrency(value, currency);

  _getSliderColumns = itemKey => {
    switch (itemKey) {
      case "worked_today":
      case "worked_this_week":
      case "worked_this_month":
      case "internal_hours":
      case "client_hours":
        return [
          "invoicing_type",
          "project",
          "account",
          "date",
          "jobtype",
          "hours"
        ];
      case "billable_rate_today":
      case "billable_rate_this_week":
      case "billable_rate_this_month":
        return ["project", "account", "date", "jobtype", "hours", "amount"];
      default:
        return [];
    }
  };

  _clearHoverIndexTimer = null;

  _setHoverIndex = (index) => {
    this._clearHoverIndexTimer && clearTimeout(this._clearHoverIndexTimer);
    const hoverIndex = index;
    this.setState({ hoverIndex });
  };

  _clearHoverIndex = () => {
    this._clearHoverIndexTimer = setTimeout(() => {
      this.setState({ hoverIndex: null });
    }, 200);
  };

  _renderHover = (item, itemHoverIndex) => {
    const { data, hoverIndex } = this.state;
    if (!hoverIndex || hoverIndex != itemHoverIndex || !item.showHover)
      return null;
    const itemData = data.summary[item.key] || {};
    const goal = Number(itemData.goal || 0);
    const tracked = Number(itemData.value || 0);
    const difference = tracked - goal;
    return (
      <div className="hover-content">
        <h3>{item.header}</h3>
        <div className="row">
          <p className="hover-row-title">{this.tr("Goal")}:</p>
          <p className="bold">{this._formatHours(goal)}</p>
        </div>
        <div className="row">
          <p className="hover-row-title">{this.tr("Tracked")}:</p>
          <p className="bold">{this._formatHours(tracked)}</p>
        </div>
        <div className="row">
          <p className="hover-row-title">{this.tr("Difference")}:</p>
          <p
            className="bold"
            style={{
              color:
                (difference || 0) > 0
                  ? colors.greenish_cyan
                  : colors.carnation_pink
            }}
          >
            {(difference || 0) > 0
              ? "+ " + this._formatHours(difference)
              : this._formatHours(difference)}
          </p>
        </div>
      </div>
    );
  };

  _getSubValue = item => {
    const { data } = this.state;
    if (item.showDifference) {
      const difference =
        Number(data.summary[item.key].value || 0) -
        Number((data.summary[item.key].goal_today ?? data.summary[item.key].goal) || 0);
      return difference;
    }
    return item.subKey && data.summary[item.key][item.subKey];
  };

  _getCellValue = item => {
    const { data, mode } = this.state;

    if (item.getValue) {
      return item.getValue(data);
    }

    if (item.dual) {
      const string = [];
      (item.keys || []).forEach((key, i) => {
        string.push(
          item.formatValue
            ? item.formatValue(data.summary[key].value)
            : data.summary[key].value
        );
        if (i == 0) string.push(" / ");
      });
      return string;
    }

    return item.formatValue
      ? item.formatValue(data.summary[item.key].value)
      : data.summary[mode][item.key].value;
  };

  _renderCells = (items) => {
    const { data } = this.state;
    const { currency } = this.props;
    return items.map((item, i) => {
      const subValue = this._getSubValue(item);
      const formattedSubValue =
        subValue !== NaN &&
        subValue !== null &&
        item.formatSubValue &&
        item.formatSubValue(subValue, currency);
      return (
        <OverviewBlockCell
          key={i}
          onMouseEnter={() => this._setHoverIndex(i)}
          onMouseLeave={() => this._clearHoverIndex()}
          renderHover={this._renderHover(item, i)}
          header={item.header}
          data-testid={item['data-testid']}
          sliderData={
            data.summary[item.key] && data.summary[item.key].sliderData
          }
          sliderColumns={this._getSliderColumns(item.key)}
          value={this._getCellValue(item)}
          subValue={formattedSubValue}
          subValueStyles={
            item.showDifference
              ? {
                  color:
                    subValue > 0
                      ? colors.greenish_cyan
                      : subValue < 0
                      ? colors.carnation_pink
                      : colors.steels,
                  opacity: 1,
                  fontWeight: "bold"
                }
              : {
                  color: colors.steels,
                  opacity: 1,
                  fontWeight: "bold"
                }
          }
        />
      );
    });
  };

  _renderContent = () => {
    const { data, jobtypeMode, mode } = this.state;

    if (mode == "by_jobtype") {
      return <JobtypeTable tr={this.tr} data={data.by_jobtype[jobtypeMode]} />;
    }

    return (
      <div className="details-container">
          {this._renderCells(this._getItems())}
      </div>
    );
  };

  _getGraphData = () => {
    const { data } = this.state;
    const { currency } = this.props;
    const {
      functions: { checkPrivilege },
      userObject: { companies_id }
    } = this.context;

    let datasets = [
      {
        label: this.tr("Tracked"),
        values: data.graphData.tracked,
        formatTooltipLabel: label => this._formatHours(label),
        rights: true
      },
      {
        label: this.tr("Billable rate"),
        values: data.graphData.billable_rate,
        hidden: true,
        formatTooltipLabel: label => this._formatPercentage(label),
        rights: checkPrivilege("invoices", "write_full", companies_id)
      },
      {
        label: this.tr("Billable"),
        values: data.graphData.billable,
        hidden: true,
        formatTooltipLabel: label => this._formatCurrency(label, currency),
        rights: checkPrivilege("invoices", "write_full", companies_id)
      }
    ];

    datasets = datasets.filter(ds => ds.rights);

    return {
      months: data.graphData.months,
      color: colors.dark_sky_blue,
      datasets: datasets
    };
  };

  _renderJobtypeDropdown = () => {
    const { jobtypeMode, mode } = this.state;
    if (mode != "by_jobtype") return null;
    return (
      <InsightDropDown
        style={{ marginRight: 10 }}
        tabs={this.jobtypeModes}
        selected={jobtypeMode}
      />
    );
  };

  render() {
    const { mode, loading } = this.state;
    return (
      <OverviewBlock
        className="my-day-hours-overview"
        loading={loading}
        dropdownItems={this.modes}
        selected={mode}
        extraTopBarComponents={this._renderJobtypeDropdown()}
        hideGraph={mode == "by_jobtype"}
        graphData={this._getGraphData()}
        title={this.tr("Hours Overview")}
        content={this._renderContent()}
      />
    );
  }
}

const getMonths = () => {
  let months = [];
  const now = moment();
  for (let i = 5; i >= 0; i--) {
    months.push(moment(now).subtract(i, 'month').format("MM-YYYY"));
  }
  return months;
}

const initialData = {
  graphData: {
    tracked: [],
    billable_rate: [],
    billable: [],
    months: getMonths()
  },
  summary: {
    average_hours: { value: 0 },
    worked_today: {
      value: 0,
      goal: 0,
      sliderData: []
    },
    worked_this_week: {
      value: 0,
      goal: 0,
      sliderData: []
    },
    worked_this_month: {
      value: 0,
      goal: 0,
      sliderData: []
    },
    hour_balance: { value: 0 },
    overtime: { value: -1 },
    billable_rate_today: {
      value: 0,
      subValue: 0,
      sliderData: []
    },
    billable_rate_this_week: {
      value: 0,
      subValue: 0,
      sliderData: []
    },
    billable_rate_this_month: {
      value: 0,
      subValue: 0,
      sliderData: []
    },
    internal_hours: {
      value: 0,
      subValue: 0,
      sliderData: []
    },
    client_hours: {
      value: 0,
      subValue: 0,
      sliderData: []
    }
  },
  by_jobtype: {
    today: [],
    this_week: [],
    this_month: []
  }
};
