import React from 'react';
/*context*/
import { SettingsContext } from '../SettingsContext';

/* local components */
import TaimerComponent from '../TaimerComponent';
import Tooltip from '@mui/material/Tooltip';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import List from '../list/List';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import ListCell from '../list/ListCell';
import LinkListCell from '../list/LinkListCell';
import ContextMenu from '../general/ContextMenu';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';

import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';

/*material-ui*/
import { MenuItem } from '@mui/material';

/*datahandler*/
import DataHandler from '../general/DataHandler';

/*extras*/
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import validator from 'validator';
import { Business, CopyAll, Email, Phone } from '@mui/icons-material';
import { languages } from 'country-data';
import styles from './TabPersonalDetails.module.scss';
import cardStyles from '../general/styles/CardStyles.module.scss';

import cloneDeep from 'lodash/cloneDeep';
import ToggleableContainerList from '../general/ToggleableContainerList';
import Skeleton from 'react-loading-skeleton';
import TaimerAvatar from '../general/TaimerAvatar';
import Link from '../general/Link';
import SliderFieldGroup from '../general/SliderFieldGroup';
import AddTag from '../general/no-options/AddTag';

export const validators = {
    email: (e) => !e.length || validator.isEmail(e),
};

const customertype = [
    { id: '1', name: 'Customer', value: '1', label: 'Customer' },
    { id: '2', name: 'Sub Contractor', value: '2', label: 'Sub Contractor' },
];

//Partners list cells starts
class PartnersHistoryRow extends PropsOnlyListRow {
    constructor(props, subtypeState = {}, rowTypeConfig = {}) {
        super(props, {}, {}, 'contacts/PartnersHistoryRow');

        this.cellEdited = this.cellEdited.bind(this);
        this.saveNew = this.saveNew.bind(this);

        this.contactRelationTypes = [
            { id: null, name: '', value: null, label: '' },
            { id: '1', name: this.tr('Contact'), value: '1', label: this.tr('Contact') },
            { id: '2', name: this.tr('Partner'), value: '2', label: this.tr('Partner') },
        ];
    }

    shouldComponentUpdate(nextProps, nextState) {
        return super.shouldComponentUpdate(nextProps, nextState) || this.props.sharedData !== nextProps.sharedData;
    }

    saveNew() {
        this.create(this.props.data);
    }

    defineClassName() {
        return ['partners-history-row', this.props.data['id'] < 0 ? 'new' : ''].join(' ');
    }

    cellEdited(name, value) {
        this.setDataAndUpdate(name, value);
    }

    defineCells() {
        const data = this.props.data;
        const contact = this.props.sharedData.contact;
        const companies = this.props.sharedData.companies;

        const contactRelationTypes = data.id < 0 ? this.contactRelationTypes : this.contactRelationTypes.filter((t) => t.id !== null);

        const menuPlacement = 'bottom';

        const cells = {
            context:
                data.id > 0 ? (
                    <ListCell onlyDisplay={true}>
                        {contact > 0 && data.companies_id == this.context.userObject.companies_id && (
                            <ContextMenu
                                className="cell row-menu"
                                label={<MoreHoriz />}
                                buttonProps={{ className: 'action-menu' }}
                                width={this.props.columnWidthMap['context']}
                                style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + ' 1 0px' }}
                                noExpandIcon
                            >
                                <MenuItem
                                    className="delete"
                                    onClick={() => {
                                        this.delete();
                                    }}
                                >
                                    <RemoveIcon className="Delete" />
                                    {this.tr('Delete')}
                                </MenuItem>
                            </ContextMenu>
                        )}
                    </ListCell>
                ) : (
                    <ListCell width={this.props.columnWidthMap.context} onlyDisplay={true}>
                        {contact > 0 && <CheckCircle titleAccess="Save" className="saveNewRowCheckCircleIcon" onClick={() => this.saveNew()} />}
                        {contact > 0 && <Cancel titleAccess="Cancel" className={contact > 0 && 'cancelIcon'} onClick={() => this.delete(this.state.data)} />}
                    </ListCell>
                ),

            companies_id: (
                <AutoCompleteCell
                    autoCompleteData={companies}
                    menuPlacement={menuPlacement}
                    name="companies_id"
                    value={data.companies_id || this.context.functions.getCompany('persons', 'read')}
                    editable={companies.length > 1}
                    listCellProps={
                        data.initialRow != undefined && {
                            inEditMode: !!data.initialRow,
                        }
                    }
                    width={this.props.columnWidthMap['companies_id']}
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    onEdited={(value) => {
                        this.cellEdited('companies_id', value.id);
                    }}
                />
            ),

            customers_id: (
                <AutoCompleteCell
                    autoCompleteData={(this.props.sharedData.customersMap ? this.props.sharedData.customersMap[data.companies_id] : []).map((c) => ({ ...c, value: c.id }))}
                    menuPlacement={menuPlacement}
                    name="customers_id"
                    value={data.customers_id}
                    editable={true}
                    listCellProps={
                        data.initialRow != undefined && {
                            inEditMode: !!data.initialRow,
                        }
                    }
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    width={this.props.columnWidthMap['customers_id']}
                    onEdited={(value) => {
                        this.cellEdited('customers_id', value.id);
                    }}
                />
            ),

            contact_type: (
                <AutoCompleteCell
                    autoCompleteData={contactRelationTypes}
                    menuPlacement={menuPlacement}
                    name="customer_partner"
                    value={data.customer_partner}
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    editable={true}
                    listCellProps={
                        data.initialRow != undefined && {
                            inEditMode: !!data.initialRow,
                        }
                    }
                    width={this.props.columnWidthMap['customer_partner']}
                    onEdited={(value) => {
                        this.cellEdited('customer_partner', value.id);
                    }}
                />
            ),
        };
        return cells;
    }
}

class ContactsProjectRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, 'contacts/ContactsProjectRow');
    }

    defineClassName() {
        return 'contacts-project-row';
    }

    defineCells() {
        const data = this.props.data;

        const cells = {
            project: (
                <LinkListCell
                    name="project"
                    urlHandler={(value) => `index.php?module=projects&action=view&id=${this.props.data.projects_id}`}
                    value={data['name'] + ' (' + data['project_id'] + ')'}
                    editable={false}
                    width={this.props.columnWidthMap['project']}
                    noTab={true}
                />
            ),
            tags: <ListCell name="tags" value={data.tags} editable={false} showTooltipForOverflownText={true} width={this.props.columnWidthMap['tags']} />,
        };
        return cells;
    }
}

class TabPersonalDetails extends TaimerComponent {
    static contextType = SettingsContext;

    state = {
        autoCompleteDataDone: false,
        data: [],
        errors: {},
        companies: [],
        contactRelationData: [],
        customerlist: [],
        customerTreeList: [],
        positionOptions: [],
        contactRelationOption: [],
        langs: [],
        projects: [],
        tagPoolSettingsData: {
            use_separate_tag_pools: 0,
            create_tags_only_from_settings: 1,
        },
        allContacts: [],
    };

    constructor(props, context) {
        super(props, context, 'contacts/TabPersonalDetails');

        this.outerContainerRef = React.createRef();
        this.partnersHistoryList = React.createRef();

        this.showContactNameInHeader = false;
        this.autoCompleteData = {};

        this.detailsSections = [
            {
                key: 'details',
                title: this.tr('Contact details'),
                initiallyOpen: true,
            },
            {
                key: 'address',
                title: this.tr('Address'),
                initiallyOpen: true,
            },
        ];

        this.newContact = {
            id: -1,
            lastname: '',
            firstname: '',
            on_mailing_lists: [],
            vacance: {
                id: 0,
            },
            branch_of_business: '',
            title: '',
            customer_types: '',
            customersMap: {},
            email: '',
            phone: '',
            modified_by: '',
            contact_owner: {
                id: context.userObject.usersId,
            },
            lang: '',
            other: '',
            tags: [],
            street_address: '',
            postal_code: '',
            city: '',
            state: '',
            country: '',
            contact_history: [
                {
                    id: 1,
                    initialRow: true,
                    companies_id: props.company || context.userObject.companies_id,
                    customers_id: '',
                    customer_partner: null,
                },
            ],
            contactSettings: {},
        };

        this.state.data.companies_id = this.state.data.companies_id ? this.state.data.companies_id : context.userObject.companies_id;
    }
    componentDidMount = async () => {
        super.componentDidMount();
        let mode = 'none';
        let contact = this.props.id;
        let company = this.props.company;
        const contactSettings = await DataHandler.get({ url: `settings/company/${company}/contact_settings` });
        this.fetchAutoCompleteData().done(() => {
            DataHandler.get({ url: 'get_contacts', mode: mode, contact: contact, company: this.props.company ? this.props.company : this.state.data.companies_id }).done((data) => {
                this.setState({ contactSettings: contactSettings, data: data.contacts[0] ? data.contacts[0] : {}, autoCompleteDataDone: true });
            });
            this.fetchContactHistoryData();
            this.fetchProjects();
        });
        DataHandler.get({ url: `subjects/companies/persons/read` }).done((companies) => this.setState({ companies }));
    };

    fetchAutoCompleteData = () => {
        const company = this.props.company || this.context.userObject.companies_id;

        return DataHandler.get({ url: `autocomplete_data/contacts/${company}` }).done((autoCompleteData) => {
            autoCompleteData.modified_by = autoCompleteData.modified_by.map((ac) => {
                if (ac.companies_id < 1) ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });
            autoCompleteData.users = autoCompleteData.users.map((ac) => {
                if (ac.companies_id < 1) ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });
            this.autoCompleteData = autoCompleteData;
            DataHandler.get({ url: `settings/company/${company}/langs` }).done((languages) => {
                this.setState({ langs: languages, positionOptions: autoCompleteData['positions'], allContacts: autoCompleteData['users'] });
                this.forceUpdate();
            });

            this.getTagSettings(company);
        });
    };

    getTagSettings = (company) => {
        DataHandler.get({ url: `settings/company/${company}/tagSettings` })
            .done((response) => {
                if (response.tagPoolSettingsData) this.setState({ tagPoolSettingsData: response.tagPoolSettingsData });
            })
            .fail((response) => {
                console.log('Error', response);
            });
    };

    onContactEdited = (data) => {
        const formattedData = {
            ...data,
            on_mailing_lists: data.on_mailing_lists.map((ml) => ml.id || ml),
        };
        this.setState({ data: formattedData, companies_id: data.companies_id });
        DataHandler.post({ url: `contacts_save/${data.id}` }, formattedData).done(() => {
            this.fetchData().done(() => this.fetchAutoCompleteData());
        });
    };

    fetchData = () => {
        let mode = 'none';
        let contact = this.state.data.id;
        this.fetchContactHistoryData();
        return DataHandler.get({ url: 'get_contacts', mode: mode, contact: contact, company: this.props.company ? this.props.company : this.state.data.companies_id }).done((data) => {
            this.setState({ data: data.contacts[0], autoCompleteDataDone: true });
        });
    };

    fetchContactHistoryData = () => {
        let contact = this.state.data.id ? this.state.data.id : this.props.id;
        DataHandler.get({ url: 'get_ContactCustomerRelation', contact: contact ? contact : -1 }).done((data) => {
            this.setState({ contactRelationData: data.contact_history, customerlist: data.customers_list });
        });

        DataHandler.get({ url: `get_CompanyContactRelationOptions` }).done((data) => {
            this.setState({ contactRelationOption: data.crosettings });
        });

        this.fetchContactCustomerTreeList(this.props.company ? this.props.company : this.state.data.companies_id);
    };

    fetchProjects() {
        const contactId = this.state.data.id ? this.state.data.id : this.props.id;

        DataHandler.get({ url: `contacts/${contactId}/projects` }).then((projects) => {
            this.setState({ projects });
        });
    }

    fetchContactCustomerTreeList = (c) => {
        let contact = this.state.data.id ? this.state.data.id : this.props.id;

        DataHandler.get({ url: `get_corporationTreeCustomers/${contact}` }).done((data) => {
            this.setState({
                customersMap: data.customers_map,
            });
        });
    };

    contactHistory = (data) => {
        const contact_history = cloneDeep(this.state.data.contact_history);
        const foundIndex = contact_history.findIndex((x) => x.id == data.id);
        if (foundIndex != -1) {
            contact_history[foundIndex] = data;
        }
        this.setState({ data: { ...this.state.data, contact_history } });
    };

    checkRelationExistance = (data) => {
        let err = false;
        const currentRelations = cloneDeep(this.partnersHistoryList.current.props.data);
        currentRelations.forEach((relation) => {
            let obj = relation;
            delete obj.id;
            let newRelation = cloneDeep(data);
            delete newRelation.id;
            delete newRelation.__originalCreationKey;
            if (_.isEqual(obj, newRelation)) {
                err = true;
                return;
            }
        });
        return err;
    };

    renderContentForDetailsSection = (section) => {
        const { data, allContacts, positionOptions, contactSettings = {} } = this.state;
        const {
            functions: { checkPrivilege, hasPrivilege },
        } = this.context;
        const writePrivileges = checkPrivilege('persons', 'read');
        data.tags = data.tags || []; /* WHY I HAVE TO DO THIS?? */
        const tn = _.uniqBy(_.map(data.tags, (t) => t.name));

        const taimerLangs = [
            'fin',
            'swe',
            'nor',
            'dan',
            'icl',
            'est',
            'lit',
            'lav',
            'eng',
            'deu',
            'ita',
            'fra',
            'spa',
            'por',
            'rus',
            'pol',
            'bul',
            'hrv',
            'ces',
            'ell',
            'hun',
            'gle',
            'mlt',
            'ron',
            'slk',
            'slv',
            'zho',
            'jpn',
            'nld',
            'ara',
            'hin',
            'pan',
            'ind',
        ];
        const languageOptions = languages.all
            .filter((l) => taimerLangs.indexOf(l.alpha3) > -1)
            .filter((ln) => ['Panjabi', 'Flemish', 'Castilian', 'Moldavian'].indexOf(ln.name) == -1)
            .map((language) => {
                if (language.alpha3 === 'ell') return { id: language.alpha3, value: language.alpha3, name: this.tr('Greek'), label: this.tr('Greek') };
                else return { id: language.alpha3, value: language.alpha3, name: this.tr(language.name), label: this.tr(language.name) };
            });

        let mlOptions = [];
        let currentMl = [];
        let ctOptions = [];
        let currentCt = [];
        let mailList = this.autoCompleteData['mailing_list']?.filter((ml) => data['on_mailing_lists'] && data['on_mailing_lists'].indexOf(ml.id) > -1) || [];
        let contactOwner = this.autoCompleteData['users']?.find((co) => data['contact_owner'] === co.id) || {};
        let customerType = customertype.filter((ct) => data['customer_types'] && data['customer_types'].indexOf(ct.id) > -1);
        let tagOptions = this.autoCompleteData['tags']?.filter((t) => Number(t.disabled) < 1 && tn.indexOf(t.name) === -1);
        if (this.newContact['contact_owner']['id'] == 0 && (!this.props.id || this.props.id == -1) && !contactOwner['id']) {
            contactOwner['id'] = this.context.userObject.usersId;
        }

        currentMl = _.map(mailList, 'id');
        (this.autoCompleteData['mailing_list'] || []).forEach((element) => {
            if (currentMl.indexOf(element.id) === -1) mlOptions.push(element);
        });

        currentCt = _.map(customerType, 'id');
        customertype.forEach((element) => {
            if (currentCt.indexOf(element.id) === -1) ctOptions.push(element);
        });

        const {
            taimerAccount: { showState },
        } = this.context;

        const usMode = /* (data['state']) || */ showState;

        switch (section) {
            case 'details':
                return (
                    <SliderFieldGroup
                        items={[data]}
                        editingDisabled={!writePrivileges}
                        editButtonLocation="top"
                        sliderEditTitle={this.tr('Edit contact')}
                        onItemSaved={this.onContactEdited}
                        fields={[
                            {
                                key: 'firstname',
                                title: this.tr('First Name'),
                                disabled: !writePrivileges,
                                required: true,
                            },
                            {
                                key: 'lastname',
                                title: this.tr('Surname'),
                                disabled: !writePrivileges,
                                required: true,
                            },
                            {
                                key: 'email',
                                title: this.tr('Email'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_email === '1',
                                validation: 'email',
                            },
                            {
                                key: 'phone',
                                title: this.tr('Phone'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_phone === '1',
                                validation: 'phone',
                            },
                            {
                                key: 'title',
                                title: this.tr('Title'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_title === '1',
                            },
                            {
                                key: 'vacance',
                                title: this.tr('Position'),
                                type: 'data_select',
                                options: positionOptions,
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_position === '1',
                                addNoneOption: true,
                            },
                            {
                                key: 'lang',
                                title: this.tr('Language'),
                                type: 'data_select',
                                options: languageOptions,
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_language === '1',
                            },
                            {
                                key: 'contact_owner',
                                title: this.tr('Contact owner'),
                                type: 'data_select',
                                options: allContacts,
                                required: contactSettings.contacts_force_owner === '1',
                                disabled: !writePrivileges,
                                isHidden: () => !hasPrivilege('persons', 'contact_owner_read'),
                            },
                            {
                                key: 'other',
                                title: this.tr('Other'),
                                disabled: !writePrivileges,
                            },
                            {
                                key: 'tags',
                                title: this.tr('Tags'),
                                type: 'select',
                                isMulti: true,
                                options: tagOptions,
                                disabled: !writePrivileges,
                                noOptions: AddTag,
                                additionalProps: {
                                    company: data.companies_id,
                                    tagType: 3,
                                },
                            },
                            {
                                key: 'on_mailing_lists',
                                title: this.tr('Email lists'),
                                type: 'select',
                                isMulti: true,
                                options: (this.autoCompleteData['mailing_list'] || []).map((ml) => ({ ...ml, label: ml.name, value: ml.id })),
                                formatMultiValue: (value, options) => options.find((o) => (value.id ? value.id == o.id : o.id == value)),
                                strictMultiValue: true,
                                disabled: !writePrivileges,
                            },
                            {
                                key: 'created',
                                title: this.tr('Created'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'modified',
                                title: this.tr('Modified'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'modified_by',
                                title: this.tr('Modified By'),
                                type: 'data_select',
                                options: this.autoCompleteData['modified_by'],
                                disabled: true,
                            },
                        ]}
                    />
                );
            case 'address':
                return (
                    <SliderFieldGroup
                        items={[data]}
                        editButtonLocation="top"
                        onItemSaved={this.onContactEdited}
                        editingDisabled={!writePrivileges}
                        fields={[
                            {
                                key: 'street_address',
                                title: this.tr('Street Address'),
                                forceVisible: true,
                            },
                            ...(usMode
                                ? [
                                      {
                                          key: 'city',
                                          title: this.tr('City'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'state',
                                          title: this.tr('State'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'postal_code',
                                          title: this.tr('Zip code'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'country',
                                          title: this.tr('Country'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                  ]
                                : [
                                      {
                                          key: 'postal_code',
                                          title: this.tr('Zip code'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'city',
                                          title: this.tr('City'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'country',
                                          title: this.tr('Country'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                  ]),
                        ]}
                    />
                );
            default:
                return null;
        }
    };

    renderDetailsTopSection = () => {
        const { data: contact, contactRelationData } = this.state;
        const validContactRelations = (contactRelationData || []).filter((c) => c.customers_id != 0);
        return (
            <div className={`${cardStyles.detailsTopSection} ${styles.detailsTopSection}`}>
                <div className={cardStyles.titleRow}>
                    {contact.id > -1 && <TaimerAvatar noavatar name={`${contact.firstname} ${contact.lastname}`} />}
                    <div className={`${cardStyles.titles} ${!this.props.id ? cardStyles.noTopMargin : ''}`}>
                        <h1>{contact.id == -1 ? this.tr('New contact') : contact.firstname ? `${contact.firstname} ${contact.lastname}` : <Skeleton />}</h1>
                        {contact.id != -1 && contact.title && <p>{contact.title}</p>}
                    </div>
                </div>
                {contact.id > -1 && (
                    <div className={`${cardStyles.keyInfo} ${cardStyles.iconSection}`}>
                        {validContactRelations.length > 0 && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Business />
                                </div>
                                <p>
                                    {_.uniqBy(validContactRelations, (val) => val.customers_id)
                                        .map((r) => r.customers_name)
                                        .join(', ')}
                                </p>
                            </div>
                        )}
                        {contact.phone && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Phone />
                                </div>
                                <Link url={`tel:${contact.phone}`}>{contact.phone}</Link>
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                    <button
                                        onClick={() => {
                                            this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                            navigator.clipboard.writeText(contact.phone);
                                        }}
                                    >
                                        <CopyAll />
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                        <div className={cardStyles.iconRow}>
                            <div className={cardStyles.icon}>
                                <Email />
                            </div>
                            <Link url={`mailto:${contact.email}`}>{contact.email}</Link>
                            <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                <button
                                    onClick={() => {
                                        this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                        navigator.clipboard.writeText(contact.email);
                                    }}
                                >
                                    <CopyAll />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    onDetailsScroll = (e) => {
        if (!this.props.id) return;
        if (e.currentTarget.scrollTop >= 100 && !this.showContactNameInHeader) {
            this.showContactNameInHeader = true;
            this.props.setShowContactNameInHeader(true);
        } else if (e.currentTarget.scrollTop < 100 && this.showContactNameInHeader) {
            this.showContactNameInHeader = false;
            this.props.setShowContactNameInHeader(false);
        }
    };

    render() {
        const { data, companies, contactRelationData, contactRelationOption } = this.state;
        const columnConfig = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

        const contact_history = {
            id: -1,
            companies_id: this.props.company || this.context.userObject.companies_id,
            customers_id: '',
            customer_partner: null,
        };

        return (
            <div id={styles.personalDetails} ref={this.outerContainerRef}>
                {(!this.state.autoCompleteDataDone || !this.state.data) && (
                    <div className={styles.savingOverlay}>
                        <img src={require('../dashboard/insights/img/loading.svg').default} />
                    </div>
                )}
                <div className={cardStyles.detailsBox} onScroll={this.onDetailsScroll}>
                    {this.renderDetailsTopSection()}
                    <div className={styles.detailsSections}>
                        <ToggleableContainerList sections={this.detailsSections} renderContentForSection={this.renderContentForDetailsSection} />
                    </div>
                </div>
                <div className={cardStyles.mainContent}>
                    <div className={styles.listContainer}>
                        <h3>{this.tr('Contact relations')}</h3>
                        <div>
                            <List
                                ref={this.partnersHistoryList}
                                height="fitRemaining"
                                disableInitialFocusOnRow={true}
                                userListSettingsKey="partners_history"
                                saveColumnConfig={true}
                                fluid={true}
                                noStateData={true}
                                manualCreate={true}
                                sharedData={{
                                    companies,
                                    contactRelationOption,
                                    contact: this.state.data.id,
                                    company: this.props.company || companies.companies_id,
                                    customersMap: this.state.customersMap,
                                }}
                                columns={[
                                    { field: 'context', name: 'context', header: '', columnHeaderType: this.props.id > 0 ? 'roundButton' : null, width: 80, ...columnConfig },
                                    { field: 'companies_id', name: 'companies_id', header: this.tr('Company'), width: 300, resizeable: true, moveable: true },
                                    { field: 'customers_id', name: 'customers_id', header: this.tr('Account'), width: 300, resizeable: true, moveable: true },
                                    { field: 'contact_type', name: 'contact_type', header: this.tr('Contact type'), width: 300, resizeable: true, moveable: true },
                                ]}
                                data={this.state.data.id > 0 ? contactRelationData : data.contact_history}
                                newRow={contact_history}
                                listRowType={PartnersHistoryRow}
                                rowProps={{
                                    outerContainerRef: this.outerContainerRef,
                                    onDelete: (data) => {
                                        if (data.id > 0) {
                                            return DataHandler.delete({ url: 'delete_relation' }, { id: data.id, company: this.state.data.companies_id }, data).then(() =>
                                                setTimeout(() => {
                                                    this.fetchContactHistoryData();
                                                }, 1000)
                                            );
                                        } else {
                                            this.partnersHistoryList.current.removeRow(data.id);
                                        }
                                    },
                                    onCreate: (data) => {
                                        const err = this.checkRelationExistance(data);
                                        if (err) {
                                            this.props.enqueueSnackbar(this.tr('This type of relation already exists') + '.', {
                                                variant: 'error',
                                            });
                                            return;
                                        }
                                        let contact = this.props.id;
                                        return DataHandler.post({ url: 'contacts/' + contact + '/update_relation' }, { data, company: this.state.data.companies_id }).done((data) => {
                                            setTimeout(() => {
                                                this.fetchContactHistoryData();
                                            }, 1000);
                                        });
                                    },
                                    onUpdate: (data) => {
                                        const err = this.checkRelationExistance(data);

                                        if (err) {
                                            this.props.enqueueSnackbar(this.tr('This type of relation already exists') + '.', {
                                                variant: 'error',
                                            });
                                            if (this.state.data.id > 0) {
                                                this.fetchContactHistoryData();
                                            }
                                            return;
                                        }
                                        if (this.state.data.id < 0) {
                                            this.contactHistory(data);
                                            return;
                                        }
                                        let contact = this.props.id;
                                        return DataHandler.post({ url: 'contacts/' + contact + '/update_relation' }, { data, company: data.companies_id }).done((data) => {
                                            // setTimeout(() => this.fetchContactCustomerTreeList(data.companies_id), 1000); is already done inside fetchContactHistoryData
                                            setTimeout(() => {
                                                this.fetchContactHistoryData();
                                            }, 1000);
                                        });
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {this.state.projects.length > 0 && (
                        <div className={styles.listContainer}>
                            <h3>{this.tr('Projects')}</h3>
                            <div>
                                <List
                                    userListSettingsKey="contacts_projects"
                                    saveColumnConfig={true}
                                    noStateData={true}
                                    fluid={true}
                                    height="fitRemaining"
                                    columns={[
                                        { field: 'project', name: 'project', header: this.tr('Project'), width: 1, resizeable: true, moveable: true, showMenu: false, showResizeMarker: true },
                                        { field: 'tags', name: 'tags', header: this.tr('Tags'), width: 1, resizeable: true, moveable: true, showMenu: false, showResizeMarker: true },
                                    ]}
                                    data={this.state.projects}
                                    listRowType={ContactsProjectRow}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withSnackbar(TabPersonalDetails);
