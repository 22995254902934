import { countries, currencies } from 'country-data';


const mainCurrenciesMap = [
    {currency: "EUR", name: "EU"},
    {code: 'US', currency: "USD"},
    {code: 'GB', currency: "GBP"},
    {code: 'SE', currency: "SEK"}
];

const selectableCountries = countries.all.filter(f => f.alpha3 != '' && f.currencies.length > 0);
const symbols = currencies.all.reduce((acc, c) => ({...acc, [c.code]: c.symbol}), {});

const displayCountry = (currencyCode) => {
    const mainCurrencyResult = mainCurrenciesMap.find(sf => sf.currency == currencyCode);
    if (mainCurrencyResult) {
        if (currencyCode == 'EUR') {
            const res = selectableCountries.find(sc => sc.alpha2 == "EU");
            return {name: mainCurrencyResult?.name, icon: res?.emoji};
        } else {
            const res = selectableCountries.find(sc => sc.alpha2 == mainCurrencyResult.code);
            return {name: res?.name, icon: res?.emoji};
        }
    } else {
        const res = selectableCountries.filter(sc => sc.currencies.includes(currencyCode))[0];
        return {name: res?.name || '', icon: res?.emoji || ''};
    }
}

const returnCurrencyList = () => {

    return selectableCountries.map(c => ({
        id: c.currencies[0], 
        name: c.currencies[0], 
        label: c.currencies[0],
        symbol: symbols[c.currencies[0]]
        })).
        //remove dublicates
        filter((entry, index, self) =>
            index === self.findIndex((t) => (
                t.id === entry.id
            ))
        ).
        //reorder
        reduce((acc, current) => { 
            if (mainCurrenciesMap.map(c => c.currency).includes(current.id)) {
                acc.unshift(current);
                return acc;
            } else {
                acc.push(current);
                return acc;
            }
        }, []);
}

export {
    displayCountry,
    returnCurrencyList
};