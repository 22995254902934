import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import { Tooltip } from "@mui/material";
import cn from 'classnames';

export default class GridCell extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "resourcing/views/ResourcingGrid/components/GridCell");
}

  shouldComponentUpdate = oldProps => {
    return (
      oldProps.selected != this.props.selected ||
      oldProps.viewMode != this.props.viewMode ||
      (this.props.selected && oldProps.manualValue != this.props.manualValue) ||
      oldProps.item != this.props.item ||
      oldProps.column != this.props.column ||
      oldProps.holidays != this.props.holidays
    );
  };

  render() {
    const {
      selected,
      parentItem,
      item,
      column,
      manualValue,
      manualValueMode,
      getValue,
      isMainRow,
      onClick,
      getColor,
      viewMode,
      getHoursFromPercent,
      getPercentFromHours,
      rowIndex,
      style,
    } = this.props;

    let value;
    let fgColor;
    let bgColor;

    const { user } = item;

    let view = viewMode;
    let hasValue = false;

    if (selected && manualValue != null) {
      let realValue = null;

      if (manualValue) {
        hasValue = true;

        if (manualValueMode === "hours") {
          realValue = getPercentFromHours(user, manualValue, column, rowIndex);
        } else {
          realValue = getHoursFromPercent(user, manualValue, column, rowIndex);
        }

        if (realValue) {
          if (view === 'percent') {
            value = realValue.percent;
          } else if (view === 'available' && realValue.available !== undefined) {
            value = realValue.available;
          } else {
            value = realValue.hours;
          }
        }
      }

      const { color, backgroundColor } = getColor(realValue && realValue.percent, column);
      fgColor = !Number(value) ? "#2d9ff7" : color;
      bgColor = !Number(value) ? "white" : backgroundColor;
    } else {
      const valueObj = getValue(column, rowIndex);

      if (view === 'percent' && valueObj.percent === null) {
        view = 'hours';
      }

      if (view === 'available' && valueObj.available === undefined && user && item.display_type !== 'user') {
        view = 'hours';
      }

      hasValue = view === 'available' ? valueObj.available !== undefined : valueObj.hours > 0;

      // If percent = null, but there's hours -> no daily hours set
      // 0.1 is minimum, so it always has color, if there's hours
      const valuePercent = user?.id && valueObj.percent === null && valueObj.hours > 0 ? null : (valueObj.hours > 0 ? Math.max(valueObj.percent, 0.1) : 0)

      const { color, backgroundColor } = getColor(
        view === 'available' ? valueObj.available : valuePercent,
        column,
        !isMainRow,
        view === 'available',
      );

      value = view === 'percent' ? valueObj.percent : (valueObj[view] !== undefined ? Number(valueObj[view]).toFixed(2) : '');
      fgColor = selected && value === 0 ? "#2d9ff7" : color;
      bgColor = selected && value === 0 ? "white" : backgroundColor;
      if (!selected && Number(value) === 0 && view !== "available" && !hasValue) value = ""
    }

    const postfix = view === 'percent' ? "%" : this.tr('h');

    return (
      <Tooltip classes={{tooltip: 'darkblue-tooltip tooltip-big'}} title={item.splitDisabled ? this.tr("Parent and Sub-tasks spiliting and editing is not possible in Grid view") : ""} arrow  enterDelay={1} leaveDelay={1} placement="top"><div
        onClick={onClick}
        style={{
          ...style,
          backgroundColor: hasValue && bgColor,
          color: hasValue && fgColor,
        }}
        className={cn(
          'grid-cell', 
          `grid-cell-level-${item.level}`,
          item.hasSubItems && 'grid-has-subitems', 
          item.hoursEditable && "task-editable", 
          selected && "selected"
        )}>
        <div className="grid-cell-container">
          {hasValue && `${value} ${postfix}`}
        </div>
      </div></Tooltip>
    );
  }
}
