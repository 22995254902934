import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { CloudDownload } from '@mui/icons-material';
import { SettingsContext } from './../SettingsContext';
import classNames from 'classnames';
import DataHandler from "./../general/DataHandler";
import FileSaver from 'file-saver';

class ProcountorExporter extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "reports/HoursIntegrationExporter");
    }

    componentDidMount() {
        super.componentDidMount();
    }

    exportWorkHours = () => {
        const {startDate, endDate, company} = this.props;

        DataHandler.getArrayBuffer({url: `reports/export/procountor_workhours`, start: startDate, end: endDate, company: company}).done((response) => {
            var blob = new Blob([response], {
                type: 'text/csv;charset=utf-8'
            });

            FileSaver.saveAs(blob, 'procountor_workhours.csv');
        });
    }

    render() {
        return (
            <div className="mepco-reports">
                <div className="slot">
                    <span className={classNames(this.props.classes.exportButton, this.props.classes.button)} onClick={() => this.exportWorkHours()} >
                        <CloudDownload /><span>{this.tr("Work hours")}</span>
                    </span>
                </div>
            </div>
        )
    }
}

export default ProcountorExporter;