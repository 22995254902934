import React from 'react';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import AttachIcon from '@mui/icons-material/AttachFile';
import { SettingsContext } from './../SettingsContext';
import { IconButton, FilledInput } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmojiPickerButton from './EmojiPickerButton';
import CloseIcon from '@mui/icons-material/Close';
import './SendMessage.css';
import TaimerAvatar from '../general/TaimerAvatar';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    barButton: {
        padding: 4
    },
    input: {
        padding: '6px 10px',
    },
    icon: {
        width: '16px',
        height: '16px'
    },
    avatar: {
        width: 30,
        height: 30,
    }
});


class SendMessage extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "team-chat/SendMessage");

        this.state = {
            message: '',
            attachments: [],
        };

        this.refFileInput = React.createRef();
    }

    addAttachment = () => {
        this.refFileInput.current.click();
    }

    addFiles = (files) => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;
        const { taimerAccount: { attachmentMaxSize } } = this.context;
        var attachments = [];

        var totalSize = 0;

        for (var i = 0; i < files.length; i++) {
            const file = files[i];
            attachments.push({
                index: this.index++,
                name: file.name,
                size: file.size,
                type: file.type,
                file
            });
            totalSize += file.size;
        }

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        this.setState({ attachments });
    }

    delete = (row) => {
        var attachments = [...this.state.attachments];

        attachments.splice(attachments.indexOf(row), 1);

        this.setState({ attachments });
    }

    onChangeFileInput = (e) => {
        this.addFiles(e.target.files);
        this.refFileInput.current.value = '';
    }

    onChange = (e) => {
        this.setState({ message: e.target.value });
    }

    onKeyPress = async (e) => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;
        const { message } = this.state;

        switch (e.key) {
            case 'Enter':
                if (message === '' && this.state.attachments.length === 0)
                    return;

                this.send();

                break;
        }

    }

    send = async () => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;
        const { message, attachments } = this.state;

        if (message === '' && attachments.length === 0)
            return;

        this.setState({ message: '', attachments: [] });

        try {
            const res = await this.props.onSendMessage(message, attachments);

            if (res === false) {
                enqueueSnackbar(tr("Failed to send message!"), {
                    variant: "error",
                });
                this.setState({ message, attachments });
            }
        } catch (err) {
            enqueueSnackbar(tr("Failed to send message!"), {
                variant: "error",
            });
            this.setState({ message, attachments });
        }
    }

    onPaste = (evt) => {
        if (evt.clipboardData) {
            if (evt.clipboardData.files.length) {
                evt.preventDefault();
                this.addFiles(evt.clipboardData.files);
            }
        }
    }

    showEmoji = () => {
        this.setState({ emojiPickerVisible: true });
    }

    addEmoji = (emoji) => {
        let { message } = this.state;

        message += ` ${emoji.colons} `;

        this.setState({ message, emojiPickerVisible: false });
    }

    render() {
        const { classes } = this.props;
        const { userObject } = this.context;
        const { attachments } = this.state;

        const endAdornment = (
            <InputAdornment position="end">
                <IconButton
                    classes={{ root: classes.barButton }}
                    onClick={this.addAttachment}
                    size="large">
                    <AttachIcon classes={{ root: classes.icon }} />
                </IconButton>
                <EmojiPickerButton classes={{ button: classes.barButton, icon: classes.icon }} onSelect={this.addEmoji} />
            </InputAdornment>);

        return (
            <div className="SendMessageBox">
                {attachments.length > 0 && <div className="AttachmentBar">
                    <div>{attachments[0].name}</div>
                    <IconButton onClick={this.delete} size="large"><CloseIcon /></IconButton>
                </div>}
                <div className="SendMessageMain">

                    <TaimerAvatar
                        id={userObject.usersId}
                        name={userObject.fullname}
                        color={userObject.color}
                        size="small" />

                    <input style={{ display: 'none' }} ref={this.refFileInput} type="file" onChange={this.onChangeFileInput} />

                    <div className="MsgBox">
                        <FilledInput
                            autoComplete="off"
                            classes={{ input: classes.input }}
                            name="message"
                            value={this.state.message}
                            onChange={this.onChange}
                            fullWidth
                            onKeyPress={this.onKeyPress}
                            placeholder={this.tr("write a comment…")}
                            onPaste={this.onPaste}
                            endAdornment={endAdornment} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(SendMessage));