import React from "react";
import { AccessTime, MoreHoriz, Stop } from "@mui/icons-material";
import { Popover, Tooltip } from "@mui/material";
import moment from "moment";

import PropsOnlyListRow from "../../../list/PropsOnlyListRow";
import colors from "../../../colors";
import TextInputCell from "../../../list/cells/TextInputCell";
import ListCell from "../../../list/ListCell";
import { hexColorWithOpacity, formatInputNumber } from "../../../helpers";

export default class ProjectsListRow extends PropsOnlyListRow {
  constructor(props) {
    super(
      props,
      { anchorEl: null },
      {},
      "dashboard/my_day/components/ProjectsListRow"
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      super.shouldComponentUpdate(nextProps, nextState) ||
      this.props.sharedData.hoverId != nextProps.sharedData.hoverId ||
      this.state.anchorEl != nextState.anchorEl ||
      this.props.data != nextProps.data ||
      this.props.sharedData.updatedValues != nextProps.sharedData.updatedValues ||
      (this.props.sharedData.trackingId == this.props.data.id &&
        this.props.sharedData.timerLabel != nextProps.timerLabel)
    );
  }

  defineClassName() {
    return "myDayListRow";
  }

  _getDateStringAndColor = (date) => {
    if (!date || date == "0000-00-00") return { string: "-", color: "#aaa" };
    const mDate = moment(date, "YYYY-MM-DD").startOf("day");
    const today = moment();
    const diff = today.startOf("day").diff(mDate, "days");
    let string;
    let color = "#aaa";
    if (diff > 0) {
      string =
        diff == 1 ? this.tr("Yesterday") : `${this.tr("${amount} days ago")}`;
      color = colors.carnation_pink;
    } else if (diff == 0) {
      string = `${this.tr("Today")}`;
      color = colors.dark_sky_blue;
    } else {
      string =
        diff == -1 ? this.tr("Tomorrow") : `${this.tr("In ${amount} days")}`;
      color = diff < -3 ? colors.greenish_cyan : colors.orangey_yellow;
    }
    return {
      string: string.replace("${amount}", Math.abs(diff)),
      color: color,
    };
  };

  _getValueFromUpdatedValues = (key) => {
    const {
      data,
      sharedData: { updatedValues },
    } = this.props;

    const hasUpdatedValues = updatedValues[data.id] && updatedValues[data.id][key];

    let returnTranslated = false;
    if (hasUpdatedValues && (key === 'funnel' && (updatedValues[data.id]['funnel_id'] == -1 || updatedValues[data.id]['funnel_id'] == -5)))
      returnTranslated = true;
    else if (key === 'funnel' && (data['funnel_id'] == -1 || data['funnel_id'] == -5))
      returnTranslated = true;
  
    return hasUpdatedValues
      ? (returnTranslated ? this.tr(updatedValues[data.id][key]) : updatedValues[data.id][key])
      : (returnTranslated ? this.tr(data[key]) : data[key]);
  };

  _formatHours = (value) => {
    if (Number(value) === 0) return value + " h";

    return formatInputNumber(value, "hours") + " h";
  };

  _formatPercentage = (value) => {
    if (Number(value) === 0 || Number(value) === 100) return value + " %";

    return formatInputNumber(value) + " %";
  };

  defineCells() {
    const {
      userObject: { dateFormat },
      functions: { presentCurrency, checkPrivilege },
    } = this.context;
    const {
      data,
      sharedData: {
        menuItems,
        showEditPopUp,
        trackingId,
        currency,
        endTimer,
        timerLabel,
        hoverItems,
        hoverId,
      },
    } = this.props;
    const { anchorEl } = this.state;

    let cells = {};

    Object.keys(data).forEach((key) => {
      cells[key] = (
        <ListCell onlyDisplay editable={false}>
          {data[key] || ""}
        </ListCell>
      );
    });

    const closeDateString = this._getValueFromUpdatedValues("close_date");
    const closeDateData = this._getDateStringAndColor(closeDateString);
    const activityDueData = this._getDateStringAndColor(
      (data.next_activity || {}).due_date
    );

    const presentCurrencyOrFalse = (value, currency) => value === false ? '-' : presentCurrency(value, currency);

    cells = {
      ...cells,
      actions: (
        <ListCell cellClassName="actions" onlyDisplay editable={false}>
          {trackingId == data.id ? (
            <button onClick={endTimer}>
              <div className="timer-icon-container">
                <AccessTime className="icon" />
                <div className="timer-indicator" />
              </div>
              <div className="label">{timerLabel}</div>
            </button>
          ) : (
            <React.Fragment>
              <button
                onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
              >
                <MoreHoriz className="icon" />
              </button>
              <Popover
                open={Boolean(anchorEl)}
                elevation={4}
                anchorEl={anchorEl}
                onClose={() => this.setState({ anchorEl: null })}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {(menuItems || [])
                  .filter((item) =>
                    item.checkRight ? item.checkRight(data) : true
                  )
                  .map((item, i) => {
                    return (
                      (item.label == "View Account") ?
                          <div
                            key={i}
                            style={{
                              color: item.hasOwnProperty("color") && item.color,
                            }}
                            className="my-day-list-menu-item ViewAccount"
                            >
                            <a
                              className="my-day-list-menu-item"
                              href={`index.php?module=customers&action=view&id=${data.account_id}`}
                              style={{
                                color: item.hasOwnProperty("color") && item.color,
                              }}
                              onClick={(e) => {
                                !e.ctrlKey && !e.metaKey && e.preventDefault();
                                !e.ctrlKey && !e.metaKey && item.action(data);
                              }}>
                                <item.icon
                                  style={{
                                    color: item.hasOwnProperty("color") && item.color,
                                  }}
                                  className="icon"
                                />
                                {item.label}
                            </a>
                          </div>   
                      
                      :
                      (<div
                            key={i}
                            style={{
                              color: item.hasOwnProperty("color") && item.color,
                            }}
                            className="my-day-list-menu-item"
                            onClick={() => {
                              this.setState({ anchorEl: null });
                              item.action(data);
                            }}
                          >
                            <item.icon
                              style={{
                                color: item.hasOwnProperty("color") && item.color,
                              }}
                              className="icon"
                            />
                            {item.label}
                          </div>
                      )
                    );
                  })}
              </Popover>
            </React.Fragment>
          )}
        </ListCell>
      ),
      project_account: (
        <ListCell onlyDisplay editable={false}>
          {hoverId == data.id ? (
            <div className="hover-action-container">
              {trackingId == data.id ? (
                <div onClick={endTimer} className="stop-container">
                  <Stop className="icon" />
                  {this.tr("Stop timer")}
                </div>
              ) : (
                hoverItems
                  .filter((item) =>
                    item.checkRight ? item.checkRight(data) : true
                  )
                  .map((item, i) => {
                    return (
                      (item.label == "Edit project") ?
                      <div >
                        <a
                          key={i}
                          href={`index.php?module=projects&action=view&id=${data.id}`}
                          style={{
                            color: item.hasOwnProperty("color") && item.color,
                          }}
                          onClick={(e) => {
                            !e.ctrlKey && !e.metaKey && e.preventDefault();
                            !e.ctrlKey && !e.metaKey && item.action(data);
                          
                          }}>
                              <Tooltip title={item.label} placement="top">
                                <item.icon className="icon" />
                              </Tooltip>
                        
                        </a>
                      </div>
                      :
                      <div key={i} onClick={() => item.action(data)}>
                        <Tooltip title={item.label} placement="top">
                          <item.icon className="icon" />
                        </Tooltip>
                      </div>
                    );
                  })
              )}
            </div>
          ) : (
            <div className="text-container">
              <div
                title={data.project_name || "-"}
                onClick={() =>
                  window.open(
                    `index.php?module=projects&action=view&id=${data.id}`,
                    "_blank"
                  )
                }
                className="top bold"
              >
                {data.project_name || "–"}
              </div>
              <div
                onClick={() =>
                  window.open(
                    `index.php?module=customers&action=view&id=${data.account_id}`,
                    "_blank"
                  )
                }
                title={data.account_name || "-"}
                className="bottom clickable"
              >
                {data.account_name || "–"}
              </div>
            </div>
          )}
        </ListCell>
      ),
      next_activity: (
        <ListCell onlyDisplay editable={false}>
          <div className="text-container">
            {!data.next_activity ? (
              checkPrivilege("projects", "project_crm_write") ? (
                <div
                  className="click-container blue-highlight"
                  onClick={(e) => this.props.rowProps.onCreateActivity(data)}
                >
                  {this.tr("Add activity")}
                </div>
              ) : (
                <div>-</div>
              )
            ) : (
              <>
                <div className="top">
                  {(data.next_activity || {}).description || "-"}
                </div>
                <div
                  style={{ color: activityDueData.color }}
                  className="bottom"
                >
                  {activityDueData.string}
                </div>
              </>
            )}
          </div>
        </ListCell>
      ),
      close_date: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) => showEditPopUp(e.currentTarget, "close_date", data)}
          >
            <div className="text-container">
              <div className="top">
                {closeDateString && closeDateString != "0000-00-00"
                  ? moment(closeDateString, "YYYY-MM-DD").format(dateFormat)
                  : "–"}
              </div>
              <div style={{ color: closeDateData.color }} className="bottom">
                {closeDateData.string}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      funnel_stage: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) =>
              showEditPopUp(e.currentTarget, "funnel_stage", data)
            }
          >
            <div className="text-container">
              <div className="top">
                {this._getValueFromUpdatedValues("funnel") || "-"}
              </div>
              <div className="bottom">
                {this._getValueFromUpdatedValues("stage") || "-"}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      value_margin: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) =>
              showEditPopUp(e.currentTarget, "value_margin", data)
            }
          >
            <div className="text-container">
              <div className="top">
                {presentCurrencyOrFalse(
                  this._getValueFromUpdatedValues("value") ?? 0,
                  currency
                )}
              </div>
              <div className="bottom">
                {presentCurrencyOrFalse(
                  this._getValueFromUpdatedValues("margin") ?? 0,
                  currency
                )}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      invoiced_billable: (
        <ListCell onlyDisplay editable={false}>
          <div className="text-container">
            <div className="top">
              {presentCurrency(data.invoiced || 0, currency)}
            </div>
            <div className="bottom">
              {presentCurrency(data.billable || 0, currency)}
            </div>
          </div>
        </ListCell>
      ),
      budgeted_actual: (
        <ListCell onlyDisplay editable={false}>
          <div className="text-container">
            <div className="top">
              {presentCurrency(data.budgeted_costs || 0, currency)}
            </div>
            <div className="bottom">
              {presentCurrency(data.actual_costs || 0, currency)}
            </div>
          </div>
        </ListCell>
      ),
      net_gross: (
        <ListCell onlyDisplay editable={false}>
          <div className="text-container">
            <div className="top">
              {presentCurrency(data.net_profits || 0, currency)}
            </div>
            <div className="bottom">
              {presentCurrency(data.gross_profits || 0, currency)}
            </div>
          </div>
        </ListCell>
      ),
      tracked_resourced: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) =>
              showEditPopUp(e.currentTarget, "tracked_resourced", data)
            }
          >
            <div className="text-container">
              <div className="top">{`${this._formatHours(
                data.tracked_h || "-"
              )} / ${this._formatHours(data.resourced_h || "-")}`}</div>
              <div className="bottom">
                {this._formatPercentage(
                  Number(data.resourced_h || 0) != 0
                    ? Math.round(
                        (Number(data.tracked_h || 0) /
                          Number(data.resourced_h || 0)) *
                          100
                      )
                    : 0
                )}
              </div>
            </div>
          </div>
        </ListCell>
      ),
    };

    return cells;
  }
}
