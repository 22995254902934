import React from 'react';
import TaimerComponent from '../../TaimerComponent';

import { SettingsContext } from '../../SettingsContext';

import './AccountingAccounts.scss';

import DataHandler from "../../general/DataHandler";

import SettingRow from "../../list/rows/SettingRow";
//import List from "../../list/List";
import { SettingsList as List } from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";

import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';

import TrashCanIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class VatCodeRow extends SettingRow {
    accountChanged(obj) {
        let newState = this.state.data; 
        newState.accounting_accounts_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const accounting_accounts_id = this.props.rowProps.accountingAccounts.find(d => d.id == this.state.data['accounting_accounts_id']);

        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            vat_code: <TextInputCell
                width={this.props.columnWidthMap['vat_code']}
                name="vat_code"
                value={this.state.data['vat_code']}
                onEdited={this.editAndSave}
            />,
            value: <TextInputCell
                width={this.props.columnWidthMap['value']}
                name="value"
                value={this.state.data['value']}
                onEdited={this.editAndSave}
            />,
            accounting_accounts_id: <AutoCompleteCell
                listCellProps={{
                    className: "accounting_accounts_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.accountingAccounts}
                name="accounting_accounts_id"
                width={this.props.columnWidthMap['accounting_accounts_id']}
                value={accounting_accounts_id}
                onEdited={obj => this.accountChanged(obj)}
            />,
            integration_id: <TextInputCell
                width={this.props.columnWidthMap['integration_id']}
                name="integration_id"
                value={this.state.data['integration_id']}
                onEdited={this.editAndSave}
            />,
            is_default: <RadioButtonCell 
                onEdited={(event, checked) => {
                    this.editAndSave("is_default", checked);
                }}
                width={this.props.columnWidthMap['is_default']}
                checked={this.state.data['is_default'] == 1} 
            />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName]) }</div>;
    }
}

class AccountingVatCodes extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        showIntegrationID: false,
        showVatCode: true,
        showVatAccount: true
    };
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingVatCodes");

        this.state = {
            vatCodes: [],
            accountingAccounts: []
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.list = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount () {
        super.componentDidMount();
        this.getData();
        this.getVatAccounts();
    }

    getData = () => {
        DataHandler.get({url: `accounting/vatcodes/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState({vatCodes: response[Object.keys(response)[0]]});
        });
    }

    getVatAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/${this.props.integration}/4`}).done(response => {
            this.setState({accountingAccounts: response[Object.keys(response)[0]]});
        });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className="accounting-list">
                <Button className="green" onMouseUp={() => this.list.current.addNewRow()} size="large">{this.tr("Add VAT code")}</Button>
            
                <List
                    innerRef={this.list}
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    newRow={{
                        id: -1,
                        name: "",
                        type: "",
                        value: "",
                        accounting_accounts_id: 0,
                    }}
                    listRowType={VatCodeRow}
                    columns={[
                        {width: 55, name: "delete", header: "", ...this.columnConfig},
                        ...(this.props.showVatCode ? [{width: 200, name: "vat_code", header: this.tr("VAT code"), ...this.columnConfig}] : []),
                        {width: 300, name: "name", header: this.tr("VAT code name"), ...this.columnConfig},
                        {width: 200, name: "value", header: this.tr("VAT %"), ...this.columnConfig},
                        ...(this.props.showVatAccount ? [{width: 200, name: "accounting_accounts_id", header: this.tr("VAT account"), ...this.columnConfig}] : []),
                        ...(this.props.showIntegrationID ? [{width: 300, name: "integration_id", header: this.tr("Integration ID"), ...this.columnConfig}] : []),
                        {width: 100, name: "is_default", header: this.tr("Default"), ...this.columnConfig},
                    ]}
                    rowProps={{
                        accountingAccounts: this.state.accountingAccounts ? this.state.accountingAccounts : [],
                        onCreate: data => {
                            data.companies_id = this.props.company;
                            DataHandler.post({url: `accounting/vatcodes/${this.props.integration}`}, { data: JSON.stringify(data) }).done(response => {
                                this.getData();
                            });
                        },
                        onUpdate: data => {
                            data.integration = this.props.integration;
                            data.companies_id = this.props.company;
                            DataHandler.put({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
                                this.getData();
                            });
                        },
                        onDelete: data => {
                            if (Number(data.id) < 0) {
                                this.list.current.removeRow(data.id);
                                return;
                            }
                            if (data.is_default == true) {
                                this.props.enqueueSnackbar(this.tr("Default VAT code can't be removed"), {
                                    variant: "error",
                                });	
                                return;
                            }
                            data.deleted = "1";
                            data.integration = this.props.integration;
                            data.companies_id = this.props.company;
                            this.setState({
                                dialogData: {
                                    saveFunc: () => DataHandler.delete({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getData();
                                    }),
                                    text: this.tr("Do you want to delete VAT code") + ": " + data.name + "?" 
                                }
                            });
                            this.openDialog('confirmation');
                        }
                    }}
                    data={this.state.vatCodes}
                />

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        );
    }
}

export default withSnackbar(AccountingVatCodes);
