import React from 'react';
import DataHandler from "./general/DataHandler";

/* context */
import { SettingsContext } from './SettingsContext';

/* jquery */
import $ from 'jquery';
import _ from 'lodash';

import replace from "string-replace-to-array";

const DEV_LOCATIONS = [
    //"taimer-stack:3000",
    ":8080",
    //"localhost:3000",
];
const DEV_LOCATION = DEV_LOCATIONS.some(e => window.location.href.indexOf(e) !== -1) ? true : false;

let translations = {};
let backendTranslations = {};

const storage = sessionStorage.taimerToken ? sessionStorage : localStorage;

if (!storage.taimerLang)
    storage.taimerLang = "en";

const prefix = window.location.href.indexOf("odin") > -1 ? "" : "/";

let lang = storage.taimerLang;

// in onboarding the language has to come from url parameters, hence this override possibility
const params = new URLSearchParams(window.location.search);
for (let [key, value] of params.entries()) {
    if (key == "lang") {
        lang = value;
        break;
    }
}

const version = process.env.REACT_APP_TAIMER_VERSION || "";

if (process.env.NODE_ENV !== "test") {
    if (DEV_LOCATION)
        $.ajax({
            url: prefix + 'react/api/translations/all_keys/' + lang,
            type: 'GET',
            async: false,
            cache: false,
            timeout: 30000,
            success: resp => translations = resp
        });
    else
        $.ajax({
            url: `${prefix}react/json/translations.${lang}.json?${version}`,
            type: 'GET',
            async: false,
            success: resp => translations = resp
        });

    if (DEV_LOCATION)
        $.ajax({
            url: prefix + 'react/api/translations/backend',
            type: 'GET',
            async: false,
            cache: false,
            timeout: 30000,
            success: resp => backendTranslations = resp
        });
    else
        $.ajax({
            url: `${prefix}react/json/backend_translations.json?${version}`,
            type: 'GET',
            async: false,
            success: resp => backendTranslations = resp
        });
}

declare global {
    interface Window {
        loadedComponents: any;
        Intercom: any;
        intercomSettings: any;
        Appcues: any;
        gtag: any;
        dataLayer: any;
        LeadDyno: any;
        $FPROM: any;
    }
}

class TaimerComponent<P = {}, S = {}> extends React.Component<P, S> {
    static contextType = SettingsContext;

    _keys: any;
    lang: string;
    namespace: string;
    delayUnload: boolean = false;

    constructor(props, context, namespace) {
        super(props, context);
        this.namespace = namespace;

        if (!translations[namespace])
            translations[namespace] = {};
        this._keys = translations[namespace];

        this.tr = this.tr.bind(this);
        this.getText = this.getText.bind(this);
        this.htmlTr = this.htmlTr.bind(this);
        this.lang = lang;
    }

    componentDidMount() {
        if (!window.loadedComponents)
            window.loadedComponents = [];

        window.loadedComponents.push(this.namespace);
    }
    componentWillUnmount() {
        if (!this.delayUnload)
            this._keys = null;
        window.loadedComponents = window.loadedComponents ? window.loadedComponents.filter(lc => lc !== this.namespace) : [];
    }

    getText(transl) {
        const keys = this._keys;

        let text = transl;

        if (DEV_LOCATION && keys !== undefined && (!keys || !keys[transl])) {
            if (keys) keys[transl] = transl;
            DataHandler.post({ url: 'translations/addkey' }, { namespace: this.namespace, key: transl });
            // return transl;
        } else if (keys && keys[transl]) {
            text = keys[transl];
        }
        else if (process.env.NODE_ENV !== "test") {
            //console.error("TRANSLATION KEYS NOT DEFINED!!!");
        }

        return text;
    }

    tr(transl, replacers = {}) {
        let text = this.getText(transl);

        _.forEach(replacers, (v, k) => text = text.replace(`\$\{${k}\}`, v))

        return text;
    }

    htmlTr(transl, replacers = {}) {
        let text = this.getText(transl);

        function replaceHTML(match, key) {
            return replacers[key] ? replacers[key] : match;
        }

        return replace(text, /\$\{([a-zA-Z0-9]+)\}/g, replaceHTML);
    }

    getBackendText = (transl, lang, namespace = this.namespace) => {

        const translation = backendTranslations?.[namespace]?.[transl]?.[lang.toLowerCase()];

        if (DEV_LOCATION && !translation) {
            if (!backendTranslations[namespace])
                backendTranslations[namespace] = { [transl]: { [lang]: transl } };
            backendTranslations[namespace][transl] = { ...backendTranslations[namespace][transl], [lang]: transl };
            DataHandler.post({ url: 'translations/addkey' }, { namespace, key: transl });
            return transl;

        } else if (translation) {
            return translation;
        }
        else if (process.env.NODE_ENV !== "test") {
            //console.error("TRANSLATION KEYS NOT DEFINED!!!");
        }

        return transl;
    }

    beTr = (transl, lang, replacers = {}, namespace = this.namespace) => {
        let text = this.getBackendText(transl, lang, namespace);

        _.forEach(replacers, (v, k) => text = text.replace(`\$\{${k}\}`, v))

        return text;
    }

}

export default TaimerComponent;
