import React from "react";
import { withSnackbar } from "notistack";
import { LocalOffer, Widgets, Book } from "@mui/icons-material";

import TaimerComponent from "../../TaimerComponent";
import PageTopSection from "../../general/PageTopSection";
import List from "../List";
import ProductCatalogListRow from "../rows/ProductCatalogListRow";
import DataList from "../../general/DataList";
import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from "../../general/DataHandler";
import ProductCatalogModalProvider from "../dialogs/ProductCatalogModalProvider";
import ProductCatalogListOverlay from "../overlays/ProductCatalogListOverlay";
import { SettingsContext } from '../../SettingsContext';
import _ from 'lodash';

import "./ProductCatalogList.css";
import WithTabs from "../../navigation/WithTabs";

class ProductCatalogList extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, "../list/lists/ProductCatalogList");

    this.list = React.createRef();
		this.advancedSearch = React.createRef();

    this._statuses = [
      {
        id: -1,
        name: "",
        label: "",
      },
      {
        id: 0,
        name: this.tr("Archived"),
        label: this.tr("Archived"),
      },
      {
        id: 1,
        name: this.tr("Active"),
        label: this.tr("Active"),
      },
    ];

    const initialCompany = { id: this.context.functions.getCompany("products", "read", false, true) };
		this.stickySearchKey = "product_catalog_list"
    
    this.state = {
      loading: true,
      loaded: false,
      data: [],
      companies: [],
      accounts: [],
      pageData: {
        page: 1,
        pageCount: 1,
        totalCount: 0,
        perpage: 30,
        sortby: false,
        sortasc: true,
      },
      sliderData: {
        open: false,
        data: [],
        title: "",
      },
      filters: {
        company: initialCompany,
        freetext: "",
        account: undefined,
        status: undefined
      },
      addDialogData: {
        open: false,
      },
      linkToAccountDialogData: {
        open: false,
      },
      archiveCatalogDialogData: {
        open: false,
      },
      stickySearchInitialized: false,
      hasCatalogs: false
    };

    this.filtersInitialValues = {
      filters: {
        company: initialCompany,
        freetext: "",
        account: undefined,
        status: undefined
      },			
      pageData: {
        page: 1,
        pageCount: 1,
        totalCount: 0,
        perpage: 30,
        sortby: false,
        sortasc: true,
      },
		};

    this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
    this.initializeStickySearch   = this.initializeStickySearch.bind(this);
    this.saveStickySearch         = this.saveStickySearch.bind(this);
  }

  componentDidMount = () => {
    super.componentDidMount();
    this.initializeStickySearch();
  };

  initialFetch(stickySearch = false) {
    this._getFilterData();
    this._getData(stickySearch);
  }

  componentDidUpdate = (_, oldState) => {
    if (oldState.data != this.state.data && this.state.sliderData.open) {
      const foundCatalog = this.state.data.find(
        (c) => c.id == this.state.sliderData.catalog.id
      );
      if (foundCatalog) {
        this.setState({
          sliderData: {
            ...this.state.sliderData,
            data: foundCatalog.customers,
            catalog: foundCatalog,
          },
        });
      }
    }
  };

  initializeStickySearch() {
    DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
      if (request.status !== 200) {
        this.initialFetch(true);
        return;
      }

      response.filters.company = this.state.filters.company;
      this.setState({ ...response }, () => this.initialFetch(true));
    }).fail(response => {
      this.initialFetch(true);

    }).always((response, _, request) => {
      this.setState({ stickySearchInitialized: true });
    });
  }

  saveStickySearch(filters) {
    let stateToSave = _.cloneDeep(filters);
    DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
  }

  filtersAreInInitialState() {
    const initial = _.cloneDeep(this.filtersInitialValues);
    delete initial.pageData;
    const initialFilters = {};
    const filters = {};

    for (let key in initial.filters) {
      if (key !== "company") {
        initialFilters[key] = JSON.stringify(initial.filters[key]);
        filters[key] = JSON.stringify(this.state.filters[key]);
      }
    }

    return _.isEqual(initialFilters, filters) && this.state.filters.company.id === initial.filters.company.id;
  }

  _resetFilters = (evt) => {
    if (!evt || evt.keyCode == '27') {
      this.advancedSearch.current.clearSearchTextInput();

      this.setState({
        ...this.filtersInitialValues,
      }, () => this.initialFetch());
    }
  }

  _setFilter = (key, value) => {
    this.setState(
      {
        pageData: {
          ...this.state.pageData,
          page: 1
        },
        filters: {
          ...this.state.filters,
          [key]: value.id && value.id == -1 ? null : value,
        },
      },
      () => {
        if (key == "company") { 
          this._getFilterData();
          this.context.functions.setLastCompany(value.id);
        }
        this._getData();
      }
    );
  };

  _getData = (stickySearch = false) => {
    this.setState({ loading: true }, () => {
      const {
        filters: { company, account, status, freetext },
        pageData,
      } = this.state;

      if (!stickySearch)
        this.saveStickySearch({filters: { account, status, freetext }, pageData});

      DataHandler.post(
        { url: `/products/company/${company.id}/catalog` },
        {
          pagedata: pageData,
          freetext,
          account: account && account.id,
          status: status && status.id,
        }
      )
        .done((res) => {
          this.setState({
            data: res.catalogs || [],
            pageData: {
              ...this.state.pageData,
              totalCount: res.total || 0,
              pageCount: res.pages || 1,
            },
            loading: false,
            loaded: true,
            hasCatalogs: res.has_catalogs == 1
          });
          try {
            this.list.current.endPageChangeAnimation();
          } catch (e) {}
        })
        .fail(() => {
          this.setState({
            loading: false,
            loaded: true,
          });
          try {
            this.list.current.endPageChangeAnimation();
          } catch (e) {}
        });
    });
  };

  _getFilterData = () => {
    Promise.all([
      DataHandler.get({
        url: `subjects/companies/products/read`,
        currency: 1,
      }),
      DataHandler.get({
        url: `subjects/accounts/${this.state.filters.company.id}`,
      }),
    ]).then((responses) => {
      const { filters } = this.state;
      const companies = responses[0] || [];
      const accounts = responses[1] || [];
      accounts.unshift({ id: -1, name: "" });
      const company = companies.find((c) => c.id == (filters.company || {}).id);
      const account = accounts.find((c) => c.id == (filters.account || {}).id);
      this.setState({
        companies,
        accounts,
        filters: {
          ...this.state.filters,
          company: {
            ...company,
            label: (company || {}).name,
          },
          account,
        },
      });
    });
  };

  _getColumns = () => {
    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    const dynamicColumnConfig = {
      showMenu: true,
      resizeable: true,
      showResizeMarker: true,
      moveable: true,
      hideable: true,
      visibleInToolbar: false,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
        columnHeaderType:
          this.context.functions.checkPrivilege(
            "products",
            "write",
            this.state.filters.company.id
          ) && "roundButton",
        width: 50,
        ...staticColumnConfig,
      },
      {
        field: "name",
        name: "name",
        header: this.tr("Catalog Name"),
        width: 200,
        ...dynamicColumnConfig,
      },
      {
        field: "linked_to_account",
        name: "linked_to_account",
        header: this.tr("Linked to Account(s)"),
        width: 200,
        ...dynamicColumnConfig,
        sortable: false,
      },
      {
        field: "status",
        name: "status",
        header: this.tr("Status"),
        width: 150,
        ...dynamicColumnConfig,
      },
      {
        field: "created",
        name: "created",
        header: this.tr("Creation Date"),
        width: 150,
        ...dynamicColumnConfig,
      },
      {
        field: "edited_date",
        name: "edited_date",
        header: this.tr("Last Edited"),
        width: 150,
        ...dynamicColumnConfig,
      },
      {
        field: "edited_by_name",
        name: "edited_by_name",
        header: this.tr("Edited by"),
        width: 150,
        ...dynamicColumnConfig,
      },
      {
        field: "products_amount",
        name: "products_amount",
        header: this.tr("Products in Catalog"),
        width: 200,
        ...dynamicColumnConfig,
      },
    ];
  };

  onPageSettingsChange = (pageData) => {
    this.setState({ pageData: { ...this.state.pageData, ...pageData } }, () =>
      this._getData()
    );
  };

  onSortRows = (sortby, sortasc) => {
    this.setState(
      { pageData: { ...this.state.pageData, sortby, sortasc } },
      () => this._getData()
    );
  };

  _saveCatalog = (catalog, callback = null, newCatalog) => {
    this.setState({ loading: true }, () => {
      const { enqueueSnackbar } = this.props;
      this._closeAddDialog();
      const switchParams = newCatalog
        ? {
            catalogids: [newCatalog.id],
            customerids: (catalog.customers || []).map((c) => c.customer_id),
          }
        : {};
      DataHandler.put(
        {
          url: `products/company/${
            catalog.companies_id || this.state.filters.company.id
          }/catalog`,
        },
        { catalogs: [catalog], ...switchParams }
      )
        .done((res) => {
          enqueueSnackbar(this.tr("Catalog saved succesfully!"), {
            variant: "success",
          });
          setTimeout(() => {
            callback ? callback(res) : this._getData();
          }, 1000);
        })
        .fail((error) => {
          enqueueSnackbar(this.tr("Saving catalog failed!"), {
            variant: "error",
          });
          console.log(error);
          this.setState({ loading: false });
        });
    });
  };

  _editCatalog = (catalog) => {
    this._saveCatalog(
      catalog,
      catalog.id
        ? null
        : (res) => {
            if ((res.catalog_ids || []).length) {
              this.context.functions.updateView({
                module: "products",
                action: "view",
                catalog_id: res.catalog_ids[0],
                catalog_name: catalog.name,
                catalog_company:
                  catalog.companies_id || this.state.filters.company.id,
              });
            }
          }
    );
  };

  openAddDialog = (catalog) => {
    this.setState({
      addDialogData: {
        open: true,
        catalog,
        onClose: this._closeAddDialog,
        onSave: this._editCatalog,
      },
    });
  };

  _closeAddDialog = () => {
    this.setState({
      addDialogData: { ...this.state.addDialogData, open: false },
    });
  };

  openLinkToAccountDialog = (catalog) => {
    this.setState({
      sliderData: { ...this.state.sliderData, open: false },
      linkToAccountDialogData: {
        open: true,
        catalog,
        onClose: this._closeLinkToAccountDialog,
        onSave: this._linkCatalogToAccount,
      },
    });
  };

  _closeLinkToAccountDialog = () => {
    this.setState({ linkToAccountDialogData: { open: false } });
  };

  _linkCatalogToAccount = (accounts, catalog) => {
    const { enqueueSnackbar } = this.props;
    this._closeLinkToAccountDialog();
    DataHandler.put(
      {
        url: `products/company/${catalog.companies_id}/catalog`,
      },
      { catalogids: [catalog.id], customerids: accounts.map((a) => a.id) }
    )
      .done(() => {
        enqueueSnackbar(this.tr("Changes saved succesfully!"), {
          variant: "success",
        });
        setTimeout(() => {
          this._getData();
        }, 1000);
      })
      .fail(() => {
        enqueueSnackbar(this.tr("Saving changes failed!"), {
          variant: "error",
        });
      });
  };

  openSlider = (catalog) => {
    this.setState({
      sliderData: {
        open: true,
        data: catalog.customers,
        catalog,
        getData: this._getData,
        openLinkToAccountDialog: this.openLinkToAccountDialog,
        onClose: this.closeSlider,
      },
    });
  };

  closeSlider = () => {
    this.setState({ sliderData: { ...this.state.sliderData, open: false } });
  };

  _closeArchiveDialog = () => {
    this.setState({
      archiveCatalogDialogData: {
        ...this.state.archiveCatalogDialogData,
        open: false,
      },
    });
  };

  openArchiveDialog = (catalog) => {
    this.setState({
      archiveCatalogDialogData: {
        open: true,
        catalog,
        onClose: this._closeArchiveDialog,
        onSave: this._archiveCatalog,
      },
    });
  };

  activateCatalog = (catalog) => {
    const activated = { ...catalog, status: "1" };
    this._saveCatalog(activated);
  };

  _archiveCatalog = (catalog, newCatalog) => {
    this._closeArchiveDialog();
    const archived = { ...catalog, status: "0" };
    this._saveCatalog(archived, null, newCatalog);
  };

  renameCatalog = (catalog) => {
    this.openAddDialog(catalog);
  };

  selectTab = (e, module) => {
    if (!e.ctrlKey && !e.metaKey)
        e.preventDefault();
    else 
        return;

    this.context.functions.updateView(module);
  }

  render() {
    if(!this.state.stickySearchInitialized) {
      return null;
    }

    const {
      pageData,
      data,
      loading,
      accounts,
      companies,
      filters,
      loaded,
      hasCatalogs
    } = this.state;

    const {
      functions: { checkPrivilege },
    } = this.context;

    return (
      <div className="contentBlock" id="product-catalog-list">
         <div className="listControlsContainer clearfix">
          <div className="filter-container">
            <div className="filters">
              {(companies || []).length > 1 && (
                <DataList
                  dropLabel={this.tr("Company")}
                  options={(companies || []).map((c) => ({
                    ...c,
                    label: c.name,
                  }))}
                  onChange={(obj) => this._setFilter("company", obj)}
                  value={filters.company}
                  fullWidth={true}
                  className="listFilterOutlinedField"
                  shownCount={20}
                />
              )}
              <DataList
                dropLabel={this.tr("Account")}
                options={accounts}
                onChange={(obj) => this._setFilter("account", obj)}
                value={filters.account}
                fullWidth={true}
                className="listFilterOutlinedField"
                shownCount={20}
              />
              <DataList
                dropLabel={this.tr("Status")}
                options={this._statuses}
                onChange={(obj) => this._setFilter("status", obj)}
                value={filters.status}
                fullWidth={true}
                className="listFilterOutlinedField"
              />
              <AdvancedSearch
                ref={this.advancedSearch}
                alwaysShowClearFilters={!this.filtersAreInInitialState()}
                onClearSearch={this._resetFilters}
                freetextLabel={filters.freetext}
                onSearchTrigger={(searchTerms) => {
                  this._setFilter("freetext", searchTerms.freetextSearchTerm);
                }}
                fields={this._getColumns()
                  .filter(
                    (c) => ["created", "edited_date"].indexOf(c.field) != -1
                  )
                  .map((f) => ({
                    field: f.field,
                    transl: f.header,
                    type: f.type,
                }))}
                hideAdvanced
                noRequests
              />
            </div>
          </div>
          <PageTopSection
          summaries={[{ title: this.tr("Catalogs"), value: this.state.pageData?.totalCount || 0 }]}
            settingsButton={{
              isVisible: !(null == this.context.privileges.admin),
              title: this.tr("Settings"),
              href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'products' }),
              action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'products' }, false)
            }}
            mainButtons={[
              {
                title: this.tr("Add Catalog"),
                isVisible: checkPrivilege(
                  "products",
                  "write",
                  filters.company.id
                ),
                action: () => this.openAddDialog(null),
              },
            ]}
          />
        </div>
        <div className="list-container">
          <List
            ref={this.list}
            data={data}
            columns={this._getColumns()}
            height="fitRemaining"
            minHeight={448}
            trimHeight={-20}
            rowKey="id"
            className="catalogList"
            listRowType={ProductCatalogListRow}
            noStateData={true}
            showNoResultsMessage={loaded && data.length == 0 && hasCatalogs}
            showOverlay={loaded && !hasCatalogs}
            overlayComponent={ProductCatalogListOverlay}
            overlayProps={{
              addCatalog: this.openAddDialog,
            }}
            rowProps={{
              openLinkToAccountDialog: this.openLinkToAccountDialog,
              openSlider: this.openSlider,
              archiveCatalog: this.openArchiveDialog,
              activateCatalog: this.activateCatalog,
              renameCatalog: this.renameCatalog,
              company: this.state.filters.company.id,
            }}
            roundbutton={() => this.openAddDialog()}
            saveColumnConfig={true}
            userListSettingsKey="product_catalog_list"
            showPageSelector={true}
            controlPage={true}
            pageCount={pageData.pageCount}
            page={pageData.page}
            totalCount={pageData.totalCount}
            perpage={pageData.perpage}
            onPerPageChange={(perpage) =>
              this.onPageSettingsChange({ perpage, page: 1 })
            }
            onPageChange={(page) => {
              this.list.current && this.list.current.startPageChangeAnimation();
              this.onPageSettingsChange({ page });
            }}
            onSortRows={this.onSortRows}
            useHSRightPadding
          />
        </div>
        <ProductCatalogModalProvider
          editDialogData={this.state.addDialogData}
          linkToAccountDialogData={this.state.linkToAccountDialogData}
          archiveCatalogDialogData={this.state.archiveCatalogDialogData}
          catalogAccountSliderData={this.state.sliderData}
        />
      </div>
    );
  }
}

export default withSnackbar(ProductCatalogList);
