import React from "react";
import List from "../List";
import InsightDropDown from "../../dashboard/insights/InsightDropDown";
import WorkWeekTemplateRow from "../rows/WorkWeekTemplateRow";
import EyeMenu from "../../general/EyeMenu";
import TaimerComponent from "../../TaimerComponent";
import CoreDialog from "../../dialogs/mass_operations/CoreDialog";
import moment from 'moment';

import {
    isPercentualHourAmount,
    isConcreteHourAmount,
    countPercentualsAndConcretes,
    decimalNumber
} from "../../general/WorkWeekUtils";
import { 
    makeCheckMap, 
    makeMap 
} from "../ListUtils";
import DataHandler from "../../general/DataHandler";
import Utils from "../../general/Utils";
import { Button, Checkbox } from '@mui/material';

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import "./WorkWeekTemplateList.css";
import "../../settings/Settings.css";

function collectFields(obj, fields) {
    const collected = {};

    fields.forEach(f => {
        if(!obj.hasOwnProperty(f)) {
            return;
        }

        collected[f] = obj[f];
    });

    return collected;
}

class WorkWeekTemplateList extends TaimerComponent {
    static newRow = {
        template_name: "",
        template_id: null,
        start_date: "",
        end_date: "",
        description: "",
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
        total: "",
        count_balance: true,
        is_default: false,
    };
    static defaultProps = {
        afterRequests: () => {},
        companyId: null,
        enqueueSnackbar: () => {},
        excludeFields: [],
        mode: "time_management_settings", // time_management_settings or user_view
        newUserMode: false,
        style: {},
        userId: null,
    };
    static days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

    constructor(props, context) {
        super(props, context, "list/lists/WorkWeekTemplateList");

        const {
            mode
        } = props;
            
        this.state = {
            currentTab: localStorage.getItem(`${mode}_initial_tab`) || "active",
            templates: [],
            usersEmployment: [],
            fields: [],
            dayVisibilities: [],
            dialogOpen: false,
            dialogProps: {}
        };

        this.list       = React.createRef();
        this.tempEntity = null;

        this.visibilityFilters = {
            all: v => v,
            active: v => !v.deleted,
            archived: v => v.deleted || v.id < 0
        };

        [
            "init",
            "initTimeManagement",
            "initUserView",
            "initFields",
            "getList",
            "getData",
            "getSharedData",
            "handleEyeMenuItemClick",
            "rowHasErrors",
            "showErrorSnackbar",
            "prepareRowData",
            "fetchTemplates",
            "fetchUsersEmployment",
            "getPreviousRow",
            "getNextRow",
            "openDialog",
            "closeDialog",
            "handleTabChange",
            "showEditingExistingTemplateDialogForTemplate",
            "showEditingBaseRowDialog",
            "setStartDateInNewUserMode",
            "setEndDateInNewUserMode",
            "switchTab"
        ].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
        const { newUserMode } = this.props;

        this.init()
            .then(this.initFields)
            .then(() => {
                if(!newUserMode) {
                    return;
                }

                const { templates }   = this.state;
                const defaultTemplate = templates.find(t => t.is_default);

                const newRow = collectFields(defaultTemplate, [
                    "mon",
                    "tue",
                    "wed",
                    "thu",
                    "fri",
                    "sat",
                    "sun",
                    "is_percentage",
                    "count_balance"
                ]);

                newRow.template_id = defaultTemplate.id;
                newRow.start_date  = Utils.today();

                this.list.current.addNewRow(newRow);
            });
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.excludeFields, this.props.excludeFields)) {
            this.initFields();
        }
    }

    init() {
        const { mode } = this.props;

        const map = {
            time_management_settings: this.initTimeManagement,
            user_view: this.initUserView
        };

        return map[mode]();
    }

    async initTimeManagement() {
        const { companyId } = this.props;

        const templates = await this.fetchTemplates(companyId);

        return new Promise((resolve, reject) => {
            this.setState({ 
                templates:templates
            }, resolve);
        });

    }

    initUserView() {
        const { 
            companyId,
            userId,
            mode
        } = this.props;

        return new Promise((resolve, reject) => {
            Promise.all([
                this.fetchTemplates(companyId),
                this.fetchUsersEmployment(userId)
            ]).then(([weeks, employment]) => {
                this.setState({ 
                    templates: weeks, 
                    usersEmployment: employment
                }, resolve);
            })
        });
    }

    fetchTemplates(companyId) {
        return DataHandler
            .get({ url: `settings/${companyId}/workweeks` });
    }

    fetchUsersEmployment(userId) {
        return DataHandler
            .get({ url: `users/workweeks/${userId}` });
    }

    getList() {
        return this.list.current;
    }

    getData() {
        const { 
            mode
        } = this.props;

        const {
            templates,
            usersEmployment
        } = this.state;
        
        return mode === "time_management_settings" 
            ? templates
            : usersEmployment;
    }

    getSharedData() {
        const { 
            mode
        } = this.props;

        const {
            templates
        } = this.state;

        return mode === "user_view"
            ? {
                templates: templates
            }
            : {};
    }

    handleEyeMenuItemClick(day) {
        const { value } = day;
        const { 
            mode 
        } = this.props;
        const { 
            dayVisibilities,
            fields
        } = this.state;

        const settings        = JSON.parse(localStorage.getItem(`workweek_template_list_${mode}`)) || {};
        const newVisibilities = cloneDeep(dayVisibilities)
            .map(dayV => {
                if(dayV.value === value) {
                    dayV.checked = !day.checked;
                }

                return dayV;
            });

        settings[value] = !day.checked;

        localStorage.setItem(`workweek_template_list_${mode}`, JSON.stringify(settings));

        const map = makeMap(newVisibilities, "value");

        this.setState({ 
            dayVisibilities: newVisibilities,
            fields: cloneDeep(fields).map(f => {
                const { name } = f;

                f.hidden = map.hasOwnProperty(name)
                    ? !map[name].checked
                    : false;

                return f;
            })
        });
    }

    initFields() {
        const { tr } = this;
        const { 
            excludeFields,
            mode,
        } = this.props;

        const settings      = JSON.parse(localStorage.getItem(`workweek_template_list_${mode}`)) || {};
        let dayVisibilities = [
            { value: "mon", text: tr("Monday" ) },
            { value: "tue", text: tr("Tueday" ) },
            { value: "wed", text: tr("Wednesday" ) },
            { value: "thu", text: tr("Thursday" ) },
            { value: "fri", text: tr("Friday" ) },
            { value: "sat", text: tr("Saturday" ) },
        ];

        const sunday = { value: "sun", text: tr("Sunday" ) };
       
        this.context.calendar.startOfWeek === 0 
            ? dayVisibilities.unshift(sunday)
            : dayVisibilities.push(sunday);

        dayVisibilities = dayVisibilities.map(day => {
            day.checked = settings.hasOwnProperty(day.value) 
                ? settings[day.value]
                : true;

            return day;
        });

        const commonConf = {
            sortable: false,
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: false 
        };

        const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat"];

        // 0 = sunday
        this.context.calendar.startOfWeek === 0 
            ? dayOrder.unshift("sun")
            : dayOrder.push("sun");

        const fields = [
            { name: "context", width: 60, ...commonConf, showMenu: false, alignment: "center" },
            { name: "status", width: 80, ...commonConf },
            { name: "template_id", header: tr("Template"), width: 140, ...commonConf },
            { name: "template_name", header: tr("Template name"), width: 140, ...commonConf },
            { name: "start_date", header: tr("Start date"), width: 140, ...commonConf },
            { name: "end_date", header: tr("End date"), width: 140, ...commonConf },
            { name: "description", header: tr("Description"), width: 140, ...commonConf },
            ...dayOrder.map(d => {
                return { name: d, header: tr(Utils.capitalize(d)), width: 60, ...commonConf, alignment: "center" }
            }),
            { name: "total", header: tr("Total"), width: 140, ...commonConf, alignment: "right" },
            // { name: "help", header: tr(""), width: 70, ...commonConf, alignment: "center" },
            { name: "count_balance", header: tr("Count balance"), width: 150, ...commonConf, alignment: "center" },
            { name: "is_default", header: tr("Default template"), width: 150, ...commonConf, alignment: "center" },
        ].filter(f => f)
         .filter(f => excludeFields.indexOf(f.name) === -1)
         .map(f => {
             const { name } = f;

             if(["mon", "tue", "wed", "thu", "fri", "sat", "sun"].indexOf(name) > -1) {
                 f.hidden = settings.hasOwnProperty(name)
                     ? !settings[name]
                     : false;
             }

             return f;
         });

        return new Promise((resolve, reject) => {
            this.setState({ 
                fields: fields,
                dayVisibilities: dayVisibilities
            }, resolve); 
        });
    }

    rowHasErrors(id) {
        const attributeMap = this.list.current.getAttributes()
        const attributes   = attributeMap[id] || {};

        const { 
            errorsIn
        } = attributes;
        const {
            start_date
        } = this.list.current.getItem(id);

        const errors = Object
            .keys(errorsIn)
            .filter(f => errorsIn[f]);

        return errors.length > 0 || (this.props.mode === "user_view"
            && (!start_date 
            || start_date === "0000-00-00"
            || start_date === "Invalid Date"));
    }

    showErrorSnackbar() {
        this.props.enqueueSnackbar(this.tr("You must resolve the work week's errors before it can be saved"), {
            variant: "error"
        });
    }

    prepareRowData(data) {
        const { 
            mode,
            companyId
        } = this.props;

        const map = {
            "user_view": [
                "id", "template_id", "start_date", "end_date", "description", 
                "mon", "tue", "wed", "thu", "fri", "sat", "sun", 
                "total", "count_balance", "is_default", 
            ],
            "time_management_settings": [
                "id", "name", "mon", "tue", "wed", "thu", "fri", "sat", "sun", 
                "total", "count_balance", "is_default"
            ]
        };

        data = collectFields(data, map[mode]);
        data = Utils.translateFields(data, {
            template_id: "templates_id"
        });

        if(mode === "time_management_settings") {
            data.companies_id = companyId;
        }

        return data;
    }

    getPreviousRow(id) {
        const data   = this.list.current.getData();
        const index  = data.findIndex(d => d.id === id);
    
        return index > 0 
            ? data[index - 1]
            : false;
    }

    getNextRow(id) {
        const data   = this.list.current.getData();
        const index  = data.findIndex(d => d.id === id);

        return index >= 0 && index < (data.length - 1)
            ? data[index + 1]
            : false;
    }

    openDialog(dialogProps) {
        this.setState({ 
            dialogOpen: true,
            dialogProps: dialogProps
        }, () => this.list.current.flipAllRows());
    }

    closeDialog() {
        this.setState({ 
            dialogOpen: false,
            dialogProps: {}
        }, () => this.list.current.flipAllRows());
    }
   
    handleTabChange(event, value) {
        this.setState({ 
            currentTab: value 
        });
    }

    async showEditingExistingTemplateDialogForTemplate(templateId) {
        const { tr } = this;
        const inUse  = Boolean(await DataHandler.get({ url: `settings/workweek/in_use/${templateId}` }));

        return new Promise((resolve, reject) => {
            if(!inUse || localStorage.getItem("workweek_template_edit_existing") === "true") {
                resolve();

                return;
            }

            this.openDialog({
                header: tr("Editing template"),
                confirmButtonClass: "blue",
                onConfirm: () => {
                    resolve();
                    this.closeDialog();
                },
                onCancel: () => {
                    reject();
                    this.closeDialog();
                },
                onCloseClick: () => {
                    reject();
                    this.closeDialog();
                },
                warning: () => {
                    return (
                        <React.Fragment>
                            <p>{tr("Editing this template will not affect the users that \
                                use this template; any changes you've made will not be \
                                reflected in any user's work week definition.")}
                            </p>

                            <Checkbox id="edit-existing-workweek-checkbox" onChange={(e, checked) => {
                                localStorage.setItem("workweek_template_edit_existing", checked);
                            }} /> <span id="edit-existing-workweek-checkbox-span" onClick={() => {
                                document.querySelector("#edit-existing-workweek-checkbox").click();
                            }}>{tr("Do not show this warning in the future")}</span>
                        </React.Fragment>
                    );
                },
                confirmButtonText: tr("Save"),
                cancelButtonText: tr("Discard changes"),
            });
        });
    }

    showEditingCurrentOrPassedRow() {
        const { tr } = this;

        return new Promise((resolve, reject) => {
            if(localStorage.getItem("users_workweek_edit_existing_or_current") === "true") {
                resolve();

                return;
            }

            this.openDialog({
                header: tr("Editing a passed or current definition"),
                confirmButtonClass: "blue",
                onConfirm: () => {
                    resolve();
                    this.closeDialog();
                },
                onCancel: () => {
                    reject();
                    this.closeDialog();
                },
                onCloseClick: () => {
                    this.closeDialog();
                },
                warning: () => {
                    return (
                        <React.Fragment>
                            <p>{tr("Editing a workweek definition that has already passed, or is the currently active \
                                workweek for the user, will affect their hour balance.")}</p>
                            <p>{tr("Are you sure you want to apply these changes?")}</p>

                            <Checkbox id="edit-existing-or-current-checkbox" onChange={(e, checked) => {
                                localStorage.setItem("users_workweek_edit_existing_or_current", checked);
                            }} /> <span id="edit-existing-or-current-checkbox-span" onClick={() => {
                                document.querySelector("#edit-existing-or-current-checkbox").click();
                            }}>{tr("Do not show this warning in the future")}</span>
                        </React.Fragment>
                    );
                },
                confirmButtonText: tr("Yes"),
                cancelButtonText: tr("No")
            });
        });
    }

    showEditingBaseRowDialog() {
        const { tr } = this;

        return new Promise((resolve, reject) => {
            this.openDialog({
                header: tr("Editing workweek definition"),
                confirmButtonClass: "blue",
                onConfirm: () => {
                    resolve();
                    this.closeDialog();
                },
                onCancel: () => {
                    reject();
                    this.closeDialog();
                },
                onCloseClick: () => {
                    this.closeDialog();
                },
                warning: () => {
                    return (
                        <React.Fragment>
                            <p>{tr("There are upcoming workweek definitions that are \
                                based on the definition you are editing.")}
                            </p>
                            <p>{tr("Do you want to automatically refresh the upcoming \
                                definitions?")}
                            </p>
                        </React.Fragment>
                    );
                },
                confirmButtonText: tr("Yes"),
                cancelButtonText: tr("No")
            });
        });
    }

    setStartDateInNewUserMode(date) {
        // There can only be one row on the list in new user mode.
        const [week] = this.list.current.getData();

        this.list.current.editData({ start_date: date }, week.id);
    }

    setEndDateInNewUserMode(date) {
        const [week] = this.list.current.getData();

        this.list.current.editData({ end_date: date }, week.id);
    }

    switchTab(tab) {
        const {
            mode
        } = this.props;

        this.setState({ currentTab: tab }, () => {
            localStorage.setItem(`${mode}_initial_tab`, tab);
        });
    }

    render() {
        const { tr } = this;
        const { 
            userId,
            mode,
            style,
            newUserMode
        } = this.props;
        const {
            fields,
            dayVisibilities,
            dialogOpen,
            dialogProps,
            currentTab
        } = this.state;

        const sharedData = this.getSharedData();

        return (
            <div id="workweek-template-list" className={mode} style={style}>
                <Button
                    disabled={currentTab === "archived" || newUserMode}
                    className="green"
                    onMouseUp={() => {this.list.current.addNewRow()}}
                    style={{ 
                        marginBottom: 12,
                        marginLeft: mode === "user_view" ? 24 : 0
                    }}
                    size="large">
                    {mode === "user_view" 
                        ? tr("Add a definition") 
                        : tr("Add an employment template")}
                </Button>

                <EyeMenu 
                    id="eye-menu"
                    items={dayVisibilities}
                    onItemClick={this.handleEyeMenuItemClick}
                />

                {!newUserMode && <InsightDropDown 
                    title={tr("Filter")} 
                    tabs={[
                        { key: "all", label: tr("All"), action: () => this.switchTab("all") },
                        { key: "active", label: tr("Active" ), action: () => this.switchTab("active") },
                        { key: "archived", label: tr("Archived" ), action: () => this.switchTab("archived" ) },
                    ]}
                    selected={currentTab} 
                    style={{ 
                        display: "inline-block", 
                        float: "right" 
                    }} />}

                <List 
                    alternativeRows={true}
                    visibilityFilter={this.visibilityFilters[currentTab]}
                    className="workWeekTemplateList"
                    // Handled manually.
                    // saveColumnConfig={true}
                    // userListSettingsKey={`workweek_template_list_${mode}`}
                    ref={this.list}
                    data={this.getData()}
                    editMode="row"
                    fluid={true}
                    fluidFix={true}
                    listRowType={WorkWeekTemplateRow}
                    noStateData={true}
                    columns={fields}
                    height="auto"
                    rowHeight={44}
                    reverseNewData={mode === "user_view"}
                    renderNewDataAtEnd={mode === "user_view"}
                    sharedData={sharedData}
                    onEnterEditMode={(row, data, list) => {
                        this.tempEntity = cloneDeep(data);
                    }}
                    rowProps={{
                        mode: mode,
                        getPreviousRow: this.getPreviousRow,
                        getNextRow: this.getNextRow,
                        dialogOpen: dialogOpen,
                        newUserMode: newUserMode
                    }}
                    onAddNewRow={(rows, data, list) => {
                        if(!data || data.length === 0) {
                            return;
                        }

                        const prev = data[data.length - 1];

                        if(prev.end_date === "0000-00-00" 
                            || !prev.end_date
                            || prev.end_date === "Invalid Date") {
                            return;
                        }

                        const nextDate = Utils.dateAdd(prev.end_date, 1);

                        list.editData({ start_date: Utils.dateToString(nextDate) }, 
                            rows[rows.length - 1].id);
                    }}
                    rowCallbacks={{
                        showEditingExistingTemplateDialogForTemplate: this.showEditingExistingTemplateDialogForTemplate,
                        setDefault: (id) => {
                            DataHandler.put({ url: `settings/workweek/default/${id}` }); 
                        },
                        archiveWorkWeek: async (data) => {
                            const inUse     = Boolean(await DataHandler.get({ url: `settings/workweek/in_use/${data.id}` }));
                            const inUseText = tr("This employment template is in use. Archiving the template will not affect the users to whom it has currently been assigned, but after archiving the template can not be assigned to users.")
                            const otherText = tr("After the template has been archived, it can not be assigned to users.");

                            const text = inUse 
                                ? inUseText
                                : otherText;

                            this.openDialog({
                                header: tr("Archive employment template?"),
                                confirmButtonClass: "blue",
                                onCancel: () => {
                                    this.closeDialog();
                                },
                                onConfirm: () => {
                                    DataHandler.delete({ url: `settings/workweek/${data.id}` })
                                    .then(() => {
                                        this.list.current.editData({ deleted: true }, data.id);
                                    })
                                    .then(() => this.closeDialog());
                                },
                                warning: () => {
                                    return (
                                        <React.Fragment>
                                            <p>{text}</p>
                                            <p>
                                                {tr("Are you sure you want to archive the template")}
                                                <strong>{` ${data.name}`}</strong>?
                                            </p>
                                        </React.Fragment>
                                    );
                                },
                                confirmButtonText: tr("Archive"),
                                onCloseClick: () => {
                                    this.closeDialog();
                                } 
                            });
                        },
                        reactivateWorkWeek: async (data) => {
                            const base = mode === "user_view"
                                ? "users"
                                : "settings";

                            await DataHandler.put({ url: `${base}/workweek/reactivate/${data.id}` });

                            this.list.current.editData({ deleted: false }, data.id);
                        },
                        deleteUsersWorkWeek: (data, deleteOrArchive) => {
                            const hasBasedOnRows = this.list.current.getData()
                                .filter(d => parseInt(d.based_on) === parseInt(data.id))
                                .length > 0;
                            const header = deleteOrArchive === "delete"
                                ? tr("Delete workweek definition?")
                                : tr("Archive workweek definition?");

                            const passed        = Boolean(Utils.today() > data.start_date);
                            const inFuture      = Boolean(Utils.today() < data.start_date);
                            const current       = Boolean(data.start_date <= Utils.today() 
                                && (Utils.today() <= data.end_date || !data.end_date));

                            const passedText    = tr("This workweek definition has already passed. Archiving it will only hide it.")
                            const passedPrompt  = tr("Are you sure you want to archive this workweek definition?");
                            const futureText    = tr("This workweek definition has not yet been in use. Deleting it will delete it permanently.");
                            const futurePrompt  = tr("Are you sure you want to delete this workweek definition?");
                            const currentText   = tr("This is the workweek definition currently in use for this user. \
                                Deleting or archiving it will result in this user having no work time expectancy for this definition's time range.");
                            const currentTextWithBasedOnRows = `${currentText} ` + tr("All percentual definitions that refer to this definition will also be deleted.");
                            const currentPrompt = tr("Are you sure you want to permanently delete this user's current workweek definition?");
                            // const currentTextArchive = tr("This is the workweek definition currently in use for this user. \
                                // Archiving it might result in changes in this user's hour balance.");
                            // const currentPromptArchive = tr("Are you sure you want to archive this user's current workweek definition? \
                                // Doing this will set the workweek's end date to yesterday.");

                            // Note the presedence after evaluation.
                            const [prompt, text] = ({
                                [passed]: [passedPrompt, passedText],
                                [current]: [currentPrompt, currentText],
                                [current && hasBasedOnRows]: [currentPrompt, currentTextWithBasedOnRows],
                                // [current && deleteOrArchive === "archive"]: [currentPromptArchive, currentTextArchive],
                                [inFuture]: [futurePrompt, futureText],
                            })[true];

                            const confirmText = deleteOrArchive === "archive" 
                                ? tr("Archive") 
                                : tr("Delete");

                            this.openDialog({
                                header: header,
                                confirmButtonClass: deleteOrArchive === "archive" 
                                    ? "blue"
                                    : "red",
                                onCancel: () => {
                                    this.closeDialog();
                                },
                                onConfirm: () => {
                                    const {
                                        afterRequests
                                    } = this.props;

                                    const action = deleteOrArchive === "delete" 
                                        ? "delete" 
                                        : "put";
                                    
                                    const params = {
                                        ...data,
                                        deleted: deleteOrArchive === "archive" ? 1 : 0
                                    };

                                    DataHandler[action]({ url: `users/workweek/${data.id}` }, params)
                                    .then(() => {
                                        if(deleteOrArchive === "archive") {
                                            this.list.current.editData({ deleted: true }, data.id);
                                        } else {
                                            this.list.current.removeRow(data.id);
                                        }

                                        afterRequests();
                                    })
                                    .then(() => this.closeDialog());
                                },
                                warning: () => {
                                    const { 
                                        description, 
                                        start_date, 
                                        end_date 
                                    } = data;

                                    let templateName = sharedData.templates
                                        .find(t => t.id === data.template_id)?.name || false;

                                    if(data.modified && data.template_id !== null) {
                                        templateName += ` (${tr("modified")})`;
                                    }

                                    let start = moment(start_date).format(this.context.userObject.dateFormat);
                                    start     = `${tr("starting on")} ${start}`;
                                    let end   = moment(end_date).format(this.context.userObject.dateFormat);
                                    end       = end !== "Invalid date" 
                                        ? `${tr("ending on")} ${end}`
                                        : false;

                                    const descText = description.trim() === "" 
                                        ? false
                                        : `${description}`

                                    return (
                                        <React.Fragment>
                                            <p>{text}</p>
                                            <ul>
                                                {[
                                                    [templateName, descText]
                                                    .filter(t => t)
                                                    .join(", "), 
                                                    start,
                                                    end
                                                ].filter(t => t)
                                                 .filter(str => str.trim() !== "")
                                                 .map(l => {
                                                    return (
                                                        <li><strong>{l}</strong></li>
                                                    );
                                                })}
                                            </ul>
                                            <p>{prompt}</p>
                                        </React.Fragment>
                                    );
                                },
                                confirmButtonText: confirmText,
                                onCloseClick: () => {
                                    this.closeDialog();
                                } 
                            });
                            
                        },
                        startDateEdited: (id, date, list, row) => {
                            const previous = this.getPreviousRow(id);
                            let editor     = list.batchEdit({ start_date: date }, id);

                            if(!previous) {
                                editor.commit();

                                return;
                            }

                            if(previous.end_date === null 
                                || previous.end_date === "0000-00-00"
                                || previous.end_date.trim() === "") {
                                let prevDate = new Date(date);

                                prevDate.setDate(prevDate.getDate() - 1);

                                prevDate = prevDate.toISOString().substr(0, 10);
                                editor   = editor.edit({ end_date: prevDate }, previous.id)
                            }

                            editor
                                .commit()
                                .then(() => {
                                    list.batchEditAttributes()
                                        .edit({ alsoSave: previous.id }, id)
                                        .commit();
                                })
                        },
                        onCreate: async (data, row, list, attributes) => {
                            if(this.rowHasErrors(data.id)) {
                                list.editAttributes({ atLeastOneSaveAttempt: true }, data.id);

                                this.showErrorSnackbar();
                                return;
                            }

                            const {
                                afterRequests
                            } = this.props;

                            data = this.prepareRowData(data);

                            try {
                                const url = mode === "time_management_settings"
                                    ? `settings/workweek`
                                    : `users/workweek/${userId}`;

                                await list.editAttributes({ creating: true }, data.id);

                                const response = await DataHandler.post({ url: url }, data);

                                let id;
                                let createdData = {};

                                if(mode === "user_view") {
                                    createdData = response;
                                    id          = response.id;
                                } else {
                                    id = response;
                                }

                                if(attributes?.alsoSave || false) {
                                    list.getRow(attributes.alsoSave).update();
                                }

                                const newData = { ...createdData, id: id };

                                await list.editAttributes({ creating: false }, data.id);

                                return list
                                    .editData(newData, data.id)
                                    .then(() => afterRequests());
                            } catch(exception) {
                                // TODO
                            }
                        },
                        onUpdate: async (data, row, list) => {
                            if(this.rowHasErrors(data.id)) {
                                this.showErrorSnackbar();
                                return;
                            }

                            // Only show the prompts if we're 
                            // actively editing this row,
                            // not when adding a new row
                            // changes the data of this row.
                            if(list.getAttributes()?.[data.id]?.rowInEditMode 
                                && data.start_date < Utils.today()) {
                                try {
                                    await this.showEditingCurrentOrPassedRow();
                                } catch(e) {
                                    return;
                                }
                            }

                            const saveFn = async (params = {}) => {
                                data = { 
                                    ...this.prepareRowData(data),
                                    users_id: userId
                                };

                                const {
                                    afterRequests
                                } = this.props;

                                try {
                                    const url = mode === "time_management_settings"
                                        ? `settings/workweek/${data.id}`
                                        : `users/workweek/${data.id}`;

                                    const { 
                                        updated, 
                                        based 
                                    } = await DataHandler.put({ url: url, ...params }, data);

                                    if(mode !== "user_view") {
                                        return;
                                    }

                                    let editor = list.batchEditData(updated, data.id);

                                    if(based) {
                                        based.forEach(b => {
                                            editor = editor.edit(b, b.id);
                                        });
                                    }

                                    return editor
                                        .commit()
                                        .then(() => afterRequests());
                                } catch(exception) {
                                    // TODO
                                }
                            };

                            const a      = {};
                            const b      = {};
                            const params = {};

                            WorkWeekTemplateList.days.forEach(day => {
                                a[day] = this.tempEntity?.[day];
                                b[day] = data[day];
                            });

                            this.tempEntity = null;

                            if(list.getAttributes()?.[data.id]?.rowInEditMode 
                                && list.getData().filter(d => parseInt(d.based_on) === parseInt(data.id))
                                .length > 0 && !isEqual(a, b)) {
                                try {
                                    await this.showEditingBaseRowDialog();

                                    params.refresh_based_rows = 1;
                                } catch(e) {

                                }
                            }

                            return row.exitEditModeSilently(() => saveFn(params));
                        }
                    }}
                    onDataChange={({ data, newData, origin, list }) => {
                        if(origin !== "stateDataChange") {
                            return;
                        }

                        const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
                        let editor = list.batchEditAttributes();
                        editor     = editor.edit({ 
                            errorsIn: {}, 
                            errorMsgs: {}
                        }, data.map(d => d.id));

                        const firstPercentualIndex = data.findIndex(d => {
                            return days.filter(day => isPercentualHourAmount(d[day])).length > 0;
                        });

                        if(firstPercentualIndex === 0 && mode === "user_view") {
                            const [first]    = data;
                            const pDays      = days.filter(d => isPercentualHourAmount(first[d]));
                            const [firstDay] = pDays;

                            editor = editor.edit({
                                errorsIn: {
                                    ...makeCheckMap(pDays)
                                },
                                errorMsgs: {
                                    [firstDay]: tr("A user's first workweek definition can not be percentual.")
                                }
                            }, first.id);
                        }

                        // Rows with no start date -->
                        const noStartDateRows = data.filter(d => {
                            const { start_date } = d;

                            return mode === "user_view" 
                                && (!start_date 
                                    || start_date === "0000-00-00"
                                    || start_date === "Invalid Date");
                        }).map(d => d.id);

                        noStartDateRows.forEach(id => {
                            editor = editor.edit({ 
                                errorsIn: {
                                    ...(editor.getData().find(r => r.id === id)?.errorsIn || {}),
                                    start_date: true
                                },
                                errorMsgs: {
                                    ...(editor.getData().find(r => r.id === id)?.errorMsgs || {}),
                                    start_date: tr("A start date is required for each work week")
                                }
                            }, id);
                        }); // <--

                        // Check that the start date isn't after the end date. -->
                        const dateConflictRows = data.filter(d => {
                            const start = new Date(d.start_date).getTime();
                            const end   = d.end_date !== "Invalid Date" 
                                && d.end_date !== "" 
                                && d.end_date !== null
                                    ? new Date(d.end_date).getTime() 
                                    : false;

                            return end !== false && end <= start;
                        }).map(d => d.id);

                        dateConflictRows.forEach(id => {
                            editor = editor.edit({ 
                                errorsIn: { 
                                    ...(editor.getData().find(r => r.id === id)?.errorsIn || {}), 
                                    ...makeCheckMap(["start_date", "end_date"])
                                }
                            }, id);

                            editor = editor.edit({ 
                                errorMsgs: {
                                    ...(editor.getData().find(r => r.id === id)?.errorMsgs || {}),
                                    end_date: tr("The end date can not be before the start date")
                                } 
                            }, dateConflictRows);
                        }); // <--

                        // Check that the default row doesn't include
                        // any percentual days -->
                        const defaultPercentualConflictRow = data.filter(d => {
                            const p = days.filter(day => isPercentualHourAmount(d[day]));

                            return d.is_default && p.length > 0;
                        }).map(d => d.id);

                        if(defaultPercentualConflictRow.length > 0) {
                            const id     = defaultPercentualConflictRow[0];
                            const week   = list.getItem(id);
                            const errors = days
                                .filter(d => isPercentualHourAmount(week[d]));
                            const firstDay = errors[0];

                            editor = editor.edit({ errorsIn: { 
                                ...makeCheckMap(errors),
                                ...editor.getData().errorsIn 
                            }}, id);

                            editor = editor.edit({ errorMsgs: 
                                { [firstDay]: tr("You can't define the default workweek in percentages") } 
                            }, id);
                        }
                        // <-- default percentual checks.

                        // Check that no row contains 
                        // both concrete and percentual days. -->
                        const conflictRows = data.filter(d => {
                            const p = days
                                .filter(day => String(d[day]).trim() !== "")
                                .filter(day => isPercentualHourAmount(d[day]));
                            const c = days
                                .filter(day => String(d[day]).trim() !== "")
                                .filter(day => isConcreteHourAmount(d[day]));

                            return p.length > 0 && c.length > 0 && !d.is_default;
                        });

                        conflictRows.forEach(week => {
                            const pc = countPercentualsAndConcretes(week);
                            let fn   = (v) => v;

                            if(pc.p > pc.c) {
                                fn = (v) => isConcreteHourAmount(v) && String(v) !== "";
                            } else if(pc.p < pc.c) {
                                fn = (v) => isPercentualHourAmount(v) && String(v) !== "";
                            }
                            
                            const errors   = days.filter(day => fn(week[day]));
                            const firstDay = errors[0];
                            editor         = editor.edit({ errorsIn: { 
                                ...(editor.getData().find(r => r.id === week.id)?.errorsIn || {}), 
                                ...makeCheckMap(errors) 
                            } }, week.id);
                            editor = editor.edit({ errorMsgs: {
                                ...(editor.getData().find(r => r.id === week.id)?.errorMsgs || {}),
                                [firstDay]: tr("A workweek should be defined entirely in either hours or percentages")
                            } }, week.id);
                        }); // <--

                        // Check that no row has a value of over 24 hours -->
                        const overOneDayRows = data.filter(d => {
                            return days.filter(day => {
                                const h = decimalNumber(d[day]);

                                return !isPercentualHourAmount(h) 
                                    && parseFloat(h) > 24;
                            }).length > 0;
                        });

                        overOneDayRows.forEach(week => {
                            const errorDays = days.filter(day => {
                                return !isPercentualHourAmount(week[day])
                                    && parseFloat(decimalNumber(week[day])) > 24;
                            });

                            const firstDay = errorDays[0];

                            editor = editor.edit({ 
                                errorsIn: { 
                                    ...(editor.getData().find(r => r.id === week.id)?.errorsIn || {}), 
                                    ...makeCheckMap(errorDays)
                                }
                            }, week.id);

                            editor = editor.edit({ 
                                errorMsgs: {
                                    ...(editor.getData().find(r => r.id === week.id)?.errorMsgs || {}),
                                    [firstDay]: tr("The entered hour amount is greater than 24 hours. \
                                        If you want to input percentages, add a percentage sign (%) after the number")
                                } 
                            }, week.id);
                        });
                        // <--

                        // Check number formatting -->
                        const nrFormatFn = (val) => {
                            const strVal = String(val);

                            return strVal.trim() !== "" 
                                && strVal.search(/^[0-9]+[\.,]{0,1}[0-9]*[%]*$/g);
                        };

                        const badlyFormedNumbers = data.filter(week => {
                            return days.filter(d => nrFormatFn(week[d])).length > 0;
                        });

                        badlyFormedNumbers.forEach(week => {
                            const errorDays = days.filter(d => nrFormatFn(week[d]));
                            const firstDay  = errorDays[0];

                            editor = editor.edit({ 
                                errorsIn: { 
                                    ...(editor.getData().find(r => r.id === week.id)?.errorsIn || {}), 
                                    ...makeCheckMap(errorDays)
                                }
                            }, week.id);

                            editor = editor.edit({ 
                                errorMsgs: {
                                    ...(editor.getData().find(r => r.id === week.id)?.errorMsgs || {}),
                                    [firstDay]: tr("The number you've given is malformed; \
                                        the value must start with a number, include \
                                        at most one decimal sign (. or ,), and at most one percentage sign (%)")
                                } 
                            }, week.id);

                        });
                        // <--

                        editor.commit();
                    }}
                    newRowLimit={1}
                    newRow={WorkWeekTemplateList.newRow}
                />
                {dialogOpen && <CoreDialog 
                    dialogType="delete" 
                    dialogProps={dialogProps}
                    open={dialogOpen}
                />}
            </div>
        );
    }
}

export default WorkWeekTemplateList;
