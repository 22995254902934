export function validate(data, rules = []) {
    if (!data)
        return { valid: false };

    var validation = { valid: true };

    rules.forEach( (rule) => {
        if (!rule)
            return;

        const [name, validator, ...params] = rule;

        const value = data[name];

        if (!validator(value, ...params))
        {
            validation.valid = false;
            validation[name] = true;
        }

    }); 

    return validation;
}

export function notEmpty(value)
{
    return value !== undefined && value !== "";
}

export function validDate(value, txtkey, datekey, optional = false)
{
    if (value[txtkey] == "")
        return optional;

    return !isNaN(value[datekey]);
}

export function isValidType(value, type)
{
    // Allow empty always
    if (value === "")
        return true;

    switch (type)
    {
        default:
            return false;
        case "number":
            return !isNaN(parseFloat(value));

    }
}


const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export function validEmail(value, optional = false)
{
    const hasValue = value !== "" && value;

    if (!hasValue)
        return optional;

    if (value.length>254)
		return false;

	const valid = emailRegex.test(value);
	if (!valid)
		return false;

        const parts = value.split("@");
	if (parts[0].length>64)
		return false;

        const domainParts = parts[1].split(".");
	if (domainParts.some(function(part) { return part.length>63; }))
		return false;

	return true;
}

export function validEmailMultiple(value, optional = false, separator = ",") {
    const emails = value.split(separator);
    let valid = true;

    emails.forEach(e => {
        if (value.length > 0 && e.trim().length == 0) {
            valid = false;
        }
        else if (!validEmail(e.trim(), optional)) {
            valid = false;
        }
    });

    return valid;
}

export function selectHasValue(value, options, optional = false, idfield = 'id')
{
    if (typeof value !== "object")
    {
        value = {id: value};
    }

    var hasValue = value.id && (value.id > 0);

    if (optional === "new" && value.id === 0 && value.name)
        return true;

    if (!hasValue && optional === true)
        return true;

    const val = parseInt(value.id, 10);

    if (options && typeof options === "object" && !Array.isArray(options))
        return value.id in options;
    else if (options && Array.isArray(options))
    {
        if (!hasValue && options.length === 0 && optional === 'empty')
            return true;

        return options.findIndex( (x) => x[idfield] == val) > -1;
    }
    else if (options)
        return false;

    return value.id !== 0;
}