/* material-ui */
import Button from '@mui/material/Button';
import TrashCanIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

/* others */
import React from 'react';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import DataHandler from '../../general/DataHandler';
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import { withSnackbar } from 'notistack';
import SettingsGrid from "./SettingsGrid";
import "../Settings.css";
import "./Tags.css";

class tagsRow extends PropsOnlyListRow {
    render() { 
        const data = this.props.data;

        const cells = {
            delete: (
                data.id > 0 ? 
                <ListCell width={this.props.columnWidthMap.delete} className="noBg pointer" onClick={() => this.props.rowProps.onDelete(data)} permanentEditMode>
                    {<TrashCanIcon />}
                </ListCell> : 
                <ListCell width={this.props.columnWidthMap.delete} permanentEditMode={true} onlyDisplay={true}>
					<Cancel className="cancelIcon" onClick={() => this.props.rowProps.onDelete(data)} />
					<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.props.rowProps.onCreate(data)} />
				</ListCell>),
            tag: (
                <TextInputCell 
                    validation={['empty']} 
                    onEdited={(name, value) => this.setDataAndUpdate(name, value)} 
                    name="tag" 
                    width={this.props.columnWidthMap['tag']} 
                    value={data['tag']} 
                    listCellProps={{ inEditMode: Number(data.id) < 0 }} 
                    focusOnMount={true} 
                />),
            type: (
                <AutoCompleteCell
                    listCellProps={{
                        inEditMode: Number(data.id) < 0
                    }}
                    editable={true}
                    autoCompleteData={this.props.rowProps.sharedData.tagTypes}
                    name="type"
                    placeholder={this.tr("Select type...")}
                    width={this.props.columnWidthMap['type']}
                    value={this.state.type ? this.state.type : data['type']}
                    onEdited={obj => this.setDataAndUpdate("type", obj.value)}
                />),
            active: (
                <ListCell className="" width={this.props.columnWidthMap["active"]} onlyDisplay>
                    <Switch
                        color="primary"
                        disabled={Number(data.id) < 0}
                        checked={data.disabled == "0"}
                        onChange={event => {
                            this.setDataAndUpdate("disabled", event.target.checked ? "0" : "1");
                        }}
                    />
                </ListCell>)
        };    
        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Tags extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        perpage: 30,
    }
    constructor(props, context) {
        super(props, context, "settings/pages/Tags");

        this.state = {
            initialized: false,
            selectedTagsTab: "active",
            tHeaderVisible:false,
            tags: [],
            tagPoolSettingsData: {
                "use_separate_tag_pools": 0,
                "create_tags_only_from_settings": 0
            },
            deleteDialogOpen: false,
            dialogData: {},
            tagTypes: []
        };

        this.tagPoolSettings = [
            {
                type: "check",
                name: "use_separate_tag_pools",
                label: this.tr("Use separate tag pool for contacts, accounts and projects. By activating tags only visible in feature by pool."),
                className: "respSetting half",
                locked: false
            },
            {
                type: "check",
                name: "create_tags_only_from_settings",
                label: this.tr("Tag creation only in settings"),
                className: "respSetting half",
                locked: false
            },
        ];

        this.tagsList = React.createRef();
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }
    
    updateComponentData = () => {
        this.getTagSettings();
        this.getData();
    }

    getTagSettings = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/company/${company}/tagSettings`}).done(response => {
            if(response.tagPoolSettingsData)
                this.setState({tagPoolSettingsData: response.tagPoolSettingsData, initialized: true});
        })
        .fail(response => {
            console.log("Error", response);
        });
    }

    saveTagSettings = (data) => {
        const { company } = this.props;
        DataHandler.put({ url: `settings/company/${company}/tagSettings` }, data).then(response => {
            if (response.tagPoolSettingsData) {
                this.setState({ tagPoolSettingsData: response.tagPoolSettingsData });
                this.getData(response.tagPoolSettingsData.use_separate_tag_pools);
            }
        })
        .fail(error => {
            console.log(error)
        })
    }

    getData = (use_separate_tag_pools = undefined) => {
        const { company } = this.props;

        DataHandler.get({url: `tags/${company}`, use_separate_tag_pools, show_disabled: true}).done(data => {
            const tagTypes = data.tag_types.map(e => {
                const name = this.tr(e.name);
                return {...e, name, label: name}
            });
            this.setState({ tags: data.tags, tagTypes });
        })
        .fail(response => {
            console.log("Error", response);
        });
    }

    createTag = (data) => {        
        const { company } = this.props;   
           
        if (data.tag.trim() === "") {
            this.props.enqueueSnackbar(this.tr("Tag cannot be empty!"), {
                variant: "error",
            });
            return;
        }        
        else if (data.type == "0" && this.state.tagPoolSettingsData.use_separate_tag_pools == '1') {
            this.props.enqueueSnackbar(this.tr("Select tagpool!"), {
                variant: "error",
            });
            return;
        }

        DataHandler.post({url: `tags/save/${company}`}, {data}).done(t => {
            setTimeout(() => {
                this.props.enqueueSnackbar(this.tr("Tag ${tag} created!", {tag: data.tag}), {
                    variant: "success",
                })
                this.tagsList.current.removeRow(data.id);
                this.getData();
            }, 1000);
        })
        .fail(response => {
            this.showSaveError(response);
        });
    }

    saveTag = (data) => {        
        const { company } = this.props;   
           
        if (Number(data.id) < 1)
            return;

        if (data.tag.trim() === "") {
            this.props.enqueueSnackbar(this.tr("Tag cannot be empty!"), {
                variant: "error",
            });
            return;
        }        

        DataHandler.post({url: `tags/save/${company}`}, {data}).done(t => {
            setTimeout(() => {
                this.getData();
            }, 1000);
        })
        .fail(response => {
            this.tagsList.current.resetStateData();
            this.showSaveError(response);
        });
    }

    deleteTag = (id) => {
        const { company } = this.props;          
        DataHandler.post({url: `tags/delete/${company}`}, {id: id}).done(t => {
            setTimeout(() => {
                this.props.enqueueSnackbar(this.tr("Tag deleted successfully!"), {
                    variant: "success",
                });
                this.getData();
            }, 1000);
        })
        .fail(response => {
            this.props.enqueueSnackbar(this.tr("Error in deleting tag!"), {
                variant: "error",
            });
        });

        this.closeDialog();
    }

    showSaveError = (response) => {
        let msg = this.tr("Error in saving tag!");
        if (response && response.responseJSON && response.responseJSON.error === "TAG_EXISTS") 
            msg = this.tr("Tag already exists!");
        else if (response && response.responseJSON && response.responseJSON.error === "TAG_EXISTS_AS_DISABLED") 
            msg = this.tr("Tag already exists, but is disabled!");

        this.props.enqueueSnackbar(msg, {
            variant: "error",
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    closeDialog = () => {
        this.setState({deleteDialogOpen: false, dialogData: undefined});
    }

    render() {
        const { tr } = this;
        const { tHeaderVisible, initialized, tagPoolSettingsData, selectedTagsTab, tags } = this.state;
        const { company } = this.props; 
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

        const tagsToShow = selectedTagsTab === "active" ? tags.filter(t => t.disabled == "0") : tags.filter(t => t.disabled == "1"); 

        return (
            <div id="settings-tags">
            <React.Fragment>
                {!initialized &&
                    <div>
                        <img style={loaderStyle} src={require("../img/loading.svg").default} />
                    </div>
                }
                {initialized &&
                        <React.Fragment>
                            <div className="settings-feature-firstwrapper">
                                <h3>{tr("Tags")}</h3>
                                <p className="subtitle">
                                    {tr("You can tag accounts, projects and contacts. Use tags to filter and manage lists.")}
                                </p>
                                <h3>{tr("Tag settings")}</h3>
                                <SettingsGrid
                                    settings={this.tagPoolSettings}
                                    item={tagPoolSettingsData}
                                    onChange={data => {
                                       this.saveTagSettings(data);
                                    }}
                                />
                            </div>
                            <div className="settings-feature-wrapper">
                                <div className="tags-header-buttons">
                                    {selectedTagsTab === "active" && <Button
                                        className="green"
                                        onMouseUp={() => { this.tagsList.current.addNewRow(); this.setState({ tHeaderVisible: true }) }}
                                        size="large"
                                    >
                                        {tr("Add tag")}
                                    </Button>}
                                    <Tabs
                                        value={selectedTagsTab}
                                        color="primary"
                                        className="tags-header-buttons-tabs"
                                        onChange={(event, val) => {
                                            if (val !== "active")
                                                this.tagsList.current.emptyNewData();
                                            this.setState({ selectedTagsTab: val });
                                        }}
                                    >
                                        <Tab
                                            value="active"
                                            label={this.tr("active")}
                                            className={
                                                "tags-header-buttons-tabs-tab tab-left" +
                                                (selectedTagsTab === "active" ? " selected" : "")
                                            }
                                        />
                                        <Tab
                                            value="disabled"
                                            label={this.tr("disabled")}
                                            className={
                                                "tags-header-buttons-tabs-tab" +
                                                (selectedTagsTab === "disabled" ? " selected" : "")
                                            }
                                        />
                                    </Tabs>
                                </div>
                                <List
                                    id="tagsList"
                                    className={"settingsList"}
                                    ref={this.tagsList}
                                    height="auto"
                                    noStateData={true}
                                    fluid
                                    ignoreRowPropsChange={false}
                                    rowHeight={SettingRow.rowHeight}
                                    hideHeader={(tHeaderVisible || (this.state.tags && this.state.tags.length > 0)) ? false : true}
                                    listRowType={tagsRow}
                                    showPageSelector={false}
                                    emptyNewDataOnUpdate={false}
                                    manualCreate
                                    rowProps={{
                                        tr: this.tr,
                                        sharedData: {tagTypes: this.state.tagTypes},
                                        onCreate: data => {
                                            this.createTag(data);
                                        },
                                        onUpdate: data => {
                                            this.saveTag(data);
                                        },
                                        onDelete: data => {
                                            if (Number(data.id) < 1)
                                                this.tagsList.current.removeRow(data.id);
                                            else
                                                this.setState({ deleteDialogOpen: true, dialogData: data });
                                        },
                                    }}
                                    // parentKey="parentId"
                                    newRow={{
                                        id: -1,
                                        tag: "",
                                        type: "0",
                                        companies_id: company,
                                        disabled: "0",
                                        deleted: "0",
                                    }}
                                    columns={[
                                        { width: 2, name: "delete", header: "", ...dColConf },
                                        { width: 20, name: "tag", header: tr("Name"), ...dColConf },
                                        ...(tagPoolSettingsData.use_separate_tag_pools == 1
                                            ? [
                                                { width: 5, name: "type", header: tr("Tag pool"), ...dColConf },
                                            ]
                                            : []
                                        ),                                        
                                        { width: 2, name: "active", showTitle: true, header: tr(" Active"), ...dColConf },
                                    ]}
                                    data={tagsToShow}
                                />
                            </div>
                        </React.Fragment>}
            </React.Fragment>
            {this.state.deleteDialogOpen &&
                        <MassDialog
                            onDialogClose={this.closeDialog}
                            onDialogSave={() => this.deleteTag(this.state.dialogData.id)}
                            dialogType={"delete"}
                            dialogProps={{
                                wider: true,
                                onCloseClick: this.closeDialog,
                                open: this.state.deleteDialogOpen,
                                close: this.closeDialog,
                                header: this.tr("Delete tag?"),
                                warning: () => {
                                    return this.tr("Are you sure you want to delete tag: ${tag}?", {tag: this.state.dialogData.tag});
                                },
                                onConfirm: () => {
                                    this.deleteTag(this.state.dialogData.id);
                                }

                            }}
                        />
                    }
            </div>
        );
    }
}

export default withSnackbar(Tags);
