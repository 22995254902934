import React from "react";

/* css */

/* material-ui */
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import MailIcon from "@mui/icons-material/MailOutlined";
import InputBase from "@mui/material/InputBase";
import ReplyIcon from "@mui/icons-material/Reply";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import ForwardIcon from "@mui/icons-material/Forward";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

/* others */
import $ from "jquery";
import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import base64js from "base64-js";
import Mailframe from "./Mailframe";
import ColorHash from "color-hash";
import TopButton from "./TopButton";
import DataHandler from "./../../general/DataHandler";
import MailHeaders from "./MailHeaders";
import ContactHeaders from "./ContactHeaders";
import MailFolderLink from "./MailFolderLink";
import MailProjectList from "./MailProjectList";
import TaimerComponent from "../../TaimerComponent";
import RichEditorExample from "./RichEditorExample";
import { SettingsContext } from "../../SettingsContext";

const colorHash = new ColorHash();

export default class MailView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "mail/MailComponents/MailView");
        this.setFilesState = this.setFilesState.bind(this);
        const { userObject } = context;
        this.state = {
            files: [],
            top: 1,
            open: false,
            dialogOpen: false,
            anchorEl: null,
            reply: false,
            replyall: false,
            forward: false,
            Transition: null,
            toRecipients: "",
            content: "",
            cleancontent: "",
            snackbarmessage: "",
            anchorEl: null,
            attachments: [],
            savedattachments: [],
            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
            icontext: "",
            fieldVal: "",
            headertext: "",
            showmessage: false,
            tokenExpired: false,
            selectedIndex: "",
            selectedMailId: "",
            projectselection: false,
            mailaddressesplaceholder: this.tr("To"),
            inputValueAddress: "",
            onUpdateContent: "",
            inputValueSubject: "",
            searchplaceholder: this.tr("Search contacts..."),
            selectedFolderName: this.tr("Contacts"),
            sendmailaddresses: [],
            senderemailAddress: { name: "", address: "" },
        };
    }

    //Handles sending message
    sendMessage = content => {
        let mailfiles = this.state.files;

        //Reply & ReplyAll & Forward
        if (this.state.reply || this.state.forward || this.state.replyall) {
            //Reply
            if (this.state.reply) {
                let mailid = this.state.selectedMailId;
                let sendcontent = content;

                DataHandler.post({ url: `mails/replymsg` }, { mailid, sendcontent, mailfiles })
                    .done(response => {
                        this.setState({
                            showmessage: true,
                            searchplaceholder: "",
                            selectedFolderName: "",
                            mailaddressesplaceholder: this.tr("To"),
                            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
                            reply: false,
                            forward: false,
                            replyall: false,
                        });
                        this.onClickMailFolder("inbox", this.tr("Inbox"));
                        this.setState({ snackbarmessage: this.tr("Message sent!"), open: true });
                    })
                    .fail(response => {
                        console.log("Error");
                    });
            }

            //ReplyAll
            if (this.state.replyall) {
                let mailid = this.state.selectedMailId;
                let sendcontent = content;

                DataHandler.post({ url: `mails/replyallmsg` }, { mailid, sendcontent, mailfiles })
                    .done(response => {
                        this.setState({
                            showmessage: true,
                            searchplaceholder: "",
                            selectedFolderName: "",
                            mailaddressesplaceholder: this.tr("To"),
                            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
                            reply: false,
                            forward: false,
                            replyall: false,
                        });
                        this.onClickMailFolder("inbox", this.tr("Inbox"));
                        this.setState({ snackbarmessage: this.tr("Message sent!"), open: true });
                    })
                    .fail(response => {
                        console.log("Error");
                    });
            }

            //Forward
            if (this.state.forward) {
                this.emailvalidator();
                let mailid = this.state.selectedMailId;
                let mailaddress = this.state.sendmailaddresses;

                if (mailaddress.length <= 0) {
                    this.setState({ snackbarmessage: this.tr("Address field is empty!"), open: true });
                    return;
                }

                let sendcontent = content;
                DataHandler.post({ url: `mails/forwardmsg` }, { mailid, mailaddress, sendcontent, mailfiles })
                    .done(response => {
                        this.setState({
                            showmessage: true,
                            searchplaceholder: "",
                            selectedFolderName: "",
                            mailaddressesplaceholder: this.tr("To"),
                            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
                            reply: false,
                            forward: false,
                            replyall: false,
                        });
                        this.onClickMailFolder("inbox", this.tr("Inbox"));
                        this.setState({ snackbarmessage: this.tr("Message sent!"), open: true });
                    })
                    .fail(response => {
                        console.log("Error");
                    });
            }

            //Send
        } else {
            this.emailvalidator();
            let mailsubject = document.getElementById("subjectsubject").value;
            let mailaddress = this.state.sendmailaddresses;

            if (mailaddress.length <= 0) {
                this.setState({ snackbarmessage: this.tr("Address field is empty!"), open: true });
                return;
            }

            let sendcontent = content;
            DataHandler.post({ url: `mails/sendmsg` }, { mailsubject, mailaddress, sendcontent, mailfiles })
                .done(response => {
                    this.setState({
                        showmessage: true,
                        searchplaceholder: "",
                        selectedFolderName: "",
                        mailaddressesplaceholder: this.tr("To"),
                    });
                    this.onClickMailFolder("inbox", this.tr("Inbox"));
                    this.setState({ snackbarmessage: this.tr("Message sent!"), open: true });
                })
                .fail(response => {
                    console.log("Error");
                });
        }
    };

    //Moves message to deleted folder
    deleteMessage = () => {
        let id = this.state.selectedMailId;
        DataHandler.get({ url: `mails/deletemsg/${id}` })
            .done(response => {
                this.onClickMailFolder(this.state.selectedIndex, this.state.selectedFolderName);
                this.setState({ headertext: "", attachments: [] });
                this.setState({ snackbarmessage: this.tr("Message moved to 'deleted mails' folder!"), open: true });
            })
            .fail(response => {
                console.log("Error");
            });
    };

    //Enables "Reply" state
    onClickReply = () => {
        if (!(this.state.selectedMailId === "")) {
            if (this.state.reply) this.setState({ reply: false });
            else this.setState({ forward: false, reply: true, replyall: false });
        }
    };

    //Enables "ReplyAll" state
    onClickReplyAll = () => {
        if (!(this.state.selectedMailId === "")) {
            if (this.state.replyall) this.setState({ replyall: false });
            else this.setState({ forward: false, reply: false, replyall: true });
        }
    };

    //Enables "Forward" state
    onClickForward = () => {
        if (!(this.state.selectedMailId === "")) {
            if (this.state.forward) this.setState({ forward: false });
            else this.setState({ reply: false, forward: true, replyall: false });
        }
    };

    //Moves selected message to archive folder
    archiveMessage = () => {
        const id = this.state.message.id;
        if (id == null) {
            this.setState({ snackbarmessage: this.tr("No selected message"), open: true });
        } else {
            this.setState({ snackbarmessage: this.tr("Selected message archived"), open: true });
        }
        DataHandler.post({ url: `mails/archivemsg` }, { id: id })
            .done(response => {
                this.onClickMailFolder(this.state.selectedIndex, this.state.selectedFolderName);
                this.setState({ headertext: "", attachments: [] });
            })
            .fail(response => {
                console.log("Error");
            });
    };

    //Triggers when search boxs input value is changed
    searchBox = e => {
        this.setState({ search: e.target.value });
    };

    //Function to remove users token
    emptyTokens = () => {
        DataHandler.get({ url: `mails/deletetoken` });
        window.location.href = `index.php?module=mails&action=login`;
    };

    //Updates send, reply, replyall and forward messages content value
    onUpdateContent = val => {
        this.setState({ onUpdateContent: val });
    };

    //"New message" buttons function
    newMessage = () => {
        this.setState({
            showmessage: false,
            selectedFolderName: this.tr("Contacts"),
            selectedIndex: "",
            selectedMailId: "",
            searchplaceholder: this.tr("Search contacts..."),
            messages: "",
            headertext: "",
            sendmailaddresses: [],
            reply: false,
            forward: false,
            replyall: false,
        });
    };

    Base64Decode(str, encoding = "utf-8") {
        return base64js.toByteArray(str);
    }

    //Downloads attachment
    downloadData = (data, fileName) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var data = this.Base64Decode(data);
        var blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    //Shows inline attachment
    showData = (data, id) => {
        var data = this.Base64Decode(data);
        var blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        let content = this.state.content.replace(`src="cid:${id}"`, `src="${url}"`);
        this.setState({ content });
    };

    //Shows selected mail or saved project message
    onClickMessage = (id, color, icontxt, isRead) => {
        this.setState({ attachments: [], reply: false, forward: false });

        //If message is normal
        if (!id.startsWith("project_")) {
            let new_messages_state = [];
            this.state.messages.forEach(message => {
                if (message.id === id) {
                    let read_message = message;
                    read_message.isRead = true;
                    new_messages_state.push(read_message);
                } else {
                    new_messages_state.push(message);
                }
            });

            //Check if message is read or unread
            if (!(isRead === undefined || isRead == true)) {
                let new_folder_state = [];
                this.state.folderheaders.forEach(folder => {
                    if (folder.id === this.state.selectedIndex) {
                        let read_folder = folder;
                        if (folder.unreadItemCount > 0) read_folder.unreadItemCount = folder.unreadItemCount - 1;
                        new_folder_state.push(read_folder);
                    } else {
                        new_folder_state.push(folder);
                    }
                });
                this.setState({ folderheaders: new_folder_state });
            }

            this.setState({ messages: new_messages_state });

            //Get message
            DataHandler.get({ url: `mails/message/${id}` })
                .done(response => {
                    this.setState(response);
                    let new_content = response.message.body.content;
                    let new_toRecipients = " ";

                    response.message.toRecipients.forEach(to => {
                        new_toRecipients += to.emailAddress.address + ", ";
                    });

                    response.message.ccRecipients.forEach(to => {
                        new_toRecipients += to.emailAddress.address + ", ";
                    });

                    new_toRecipients = new_toRecipients.slice(0, -2);

                    this.setState({ content: new_content, savedattachments: [], toRecipients: new_toRecipients });

                    //Get messages attachments
                    this.state.attachments.forEach(attachment => {
                        if (attachment.isInline) {
                            let attachmentid = attachment.id;
                            DataHandler.get({ url: `mails/attachment/${this.state.message.id}/${attachmentid}` })
                                .done(response => {
                                    var data = response.content;
                                    var id = response.contentid;
                                    this.showData(data, id);
                                })
                                .fail(response => {
                                    console.log("Error");
                                });
                        }
                    });

                    this.setState({
                        cleancontent: "",
                        selectedMailId: id,
                        showmessage: true,
                        iconcolor: color,
                        icontext: icontxt,
                    });
                })
                .fail(response => {
                    console.log("Error");
                });

            //If message is saved project message
        } else {
            let id_1 = Number(id.split("_").pop());
            let msgid = this.state.messages[id_1].messageid;
            let newcontent = this.state.messages[id_1].content;
            this.setState({ message: this.state.messages[id_1] });
            this.setState({ content: newcontent });
            this.setState({
                cleancontent: "",
                selectedMailId: id,
                showmessage: true,
                iconcolor: color,
                icontext: icontxt,
            });
            let msg_attachments = [];
            let array = this.state.projectattachments;

            //Handles project message attachments
            Object.keys(array).forEach(key => {
                if (msgid === array[key].mails_id) {
                    msg_attachments.push(array[key]);
                    if (!(array[key].inline_id === null)) {
                        let cidid = array[key].inline_id;

                        const params = {
                            id: array[key].id,
                            attachment: "attachment",
                            _auth: this.context.functions.getStorage().taimerToken,
                        };

                        let url = this.context.functions.getDownloadPath() + $.param(params);
                        let content = newcontent.replace(`src="cid:${cidid}"`, `src="${url}"`);
                        this.setState({ content });
                    }
                }
            });
            this.setState({ savedattachments: msg_attachments, attachments: [] });
        }
    };

    //Adds clicked contact to "sendmailaddresses" list
    onClickContact = contactaddress => {
        this.setState({ mailaddressesplaceholder: this.tr("To") });
        let email = contactaddress;
        if (this.validate(email)) {
            if (this.state.sendmailaddresses.length >= 1) {
                let oldaddresses = this.state.sendmailaddresses;
                let newaddress = { address: email };
                let newaddresses = [...oldaddresses, newaddress];
                this.setState({ sendmailaddresses: newaddresses });
            } else {
                this.setState({ sendmailaddresses: [{ address: email }] });
            }

            document.getElementById("mailmail").value = "";
            this.setState({ mailaddressesplaceholder: "" });
        }
    };

    //Adds more values to be searched from selected folder
    onClickMore = () => {
        let top = this.state.top;

        if (isNaN(top)) top = 100;
        if (top < 100) top = 100;
        let newtop = top + 100;

        this.setState({ top: newtop });
        this.onClickMailFolder(this.state.selectedIndex, this.state.selectedFolderName, newtop);
    };

    //Triggers when some mailfolder is clicked. Inbox, Sent items etc.
    onClickMailFolder = (id, displayName, top = undefined) => {
        this.setState({
            savedattachments: [],
            projectattachments: [],
            attachments: [],
            reply: false,
            replyall: false,
            forward: false,
            files: [],
            mailfiles: [],
        });
        if (typeof top === "undefined") {
            this.setState({ top: 100 });
            top = 100;
        }
        DataHandler.get({ url: `mails/mailfolder/${id}/${top}` })
            .done(response => {
                if (response.url) {
                    sessionStorage.setItem("nextAction", "mail-office-login");
                    sessionStorage.setItem("previousUrl", window.location);
                    window.location = response.url;
                } else {
                    this.setState(response);
                }
            })
            .fail(response => {
                console.log("Error");
            });

        //Get mailfolders info (to update read & unread)
        DataHandler.get({ url: `mails/mailfolders` })
            .done(response => {
                this.setState(response);
            })
            .fail(response => {
                console.log("Error");
            });

        this.setState({
            selectedIndex: id,
            selectedFolderName: displayName,
            showmessage: true,
            content: "",
            cleancontent: "",
            selectedMailId: "",
            searchplaceholder: this.tr("Search mails..."),
            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
        });
    };

    //Triggers when project folder is selected
    onClickProjectHeader = (id, name) => {
        this.setState({ savedattachments: [], attachments: [], files: [], mailfiles: [] });

        //Gets selected projects messages
        DataHandler.post({ url: `mails/projectmsg` }, { id: id, name: name })
            .done(response => {
                let messages = [];
                let attachments = [];
                this.setState({ projectattachments: response.attachments });
                response.messages.forEach(function(element, i) {
                    messages.push({
                        id: "project_" + i,
                        messageid: element.id,
                        content: element.content,
                        subject: element.subject,
                        sentDateTime: element.time,
                        from: { emailAddress: { address: element.from_address, name: element.from_name } },
                        hasAttachments: element.has_attachments,
                    });
                });
                this.setState({ messages: messages });
            })
            .fail(response => {
                console.log("Error");
            });

        this.setState({
            selectedIndex: id,
            selectedFolderName: name,
            showmessage: true,
            content: "",
            cleancontent: "",
            selectedMailId: "",
            reply: false,
            replyall: false,
            forward: false,
            searchplaceholder: this.tr("Search mails..."),
            message: { subject: "", from: { emailAddress: { name: "", address: "" } } },
        });
    };

    //Triggers when unsaved messages attachment is selected
    onClickAttachment = attachmentid => {
        //Gets clicked attachment and triggers downloading
        DataHandler.get({ url: `mails/attachment/${this.state.message.id}/${attachmentid}` })
            .done(response => {
                let data = response.content;
                let fileName = response.name;
                this.downloadData(data, fileName);
            })
            .fail(response => {
                console.log("Error");
            });
    };

    //Triggers when saved messages attachment is selected
    onClickSavedAttachment = i => {
        const params = {
            id: this.state.projectattachments[i].id,
            attachment: "attachment",
            auth: this.context.functions.getStorage().taimerToken,
        };

        let url = this.context.functions.getDownloadPath() + $.param(params);

        window.open(url);
    };

    //Validates entered emailaddress and adds address to receiver address list (sendmailaddresses)
    emailvalidator = () => {
        //Changes placeholder to "" if sendmailaddresses is not empty
        if (this.state.sendmailaddresses.length > 0) {
            this.setState({ mailaddressesplaceholder: "" });
        } else {
            this.setState({ mailaddressesplaceholder: this.tr("To") });
        }

        let email = document.getElementById("mailmail").value;
        if (this.validate(email)) {
            if (this.state.sendmailaddresses.length >= 1) {
                let oldaddresses = this.state.sendmailaddresses;
                let newaddress = { address: email };
                let newaddresses = [...oldaddresses, newaddress];
                this.setState({ sendmailaddresses: newaddresses });
            } else {
                this.setState({ sendmailaddresses: [{ address: email }] });
            }

            document.getElementById("mailmail").value = "";
            this.setState({ mailaddressesplaceholder: "" });
        }
    };

    //Emailvalidators helper function
    validate = email => {
        let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

        if (!email) return false;

        if (email.length > 254) return false;

        let valid = tester.test(email);
        if (!valid) return false;

        let parts = email.split("@");
        if (parts[0].length > 64) return false;

        let domainParts = parts[1].split(".");
        if (
            domainParts.some(function(part) {
                return part.length > 63;
            })
        )
            return false;

        return true;
    };

    //Triggers when emailaddress delete button is clicked
    removeAddress = i => {
        if (i > -1) {
            let newarr = this.state.sendmailaddresses;
            newarr.splice(i, 1);
            this.setState({ sendmailaddresses: newarr });
        }

        if (this.state.sendmailaddresses.length > 0) this.setState({ mailaddressesplaceholder: "" });
        else this.setState({ mailaddressesplaceholder: this.tr("To") });
    };

    //Triggers when delete is selected on saved message
    deleteSavedMessage = () => {
        const message = this.state.message;

        DataHandler.delete({ url: `mails/deletemsg/${message.messageid}` }).done(e => {
            /* console.log(e); */
            this.setState({ snackbarmessage: this.tr("Projects message deleted!"), open: true });
        });

        this.onClickProjectHeader(this.state.selectedIndex, this.state.selectedFolderName);
    };

    //Triggers when "save to project" button is selected
    saveProjectOpen = event => {
        this.setState({ projectselection: true });
    };

    saveProjectClose = event => {
        this.setState({ projectselection: false });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    //Triggers to close snackbar
    handleSnackbarClose = () => {
        this.setState({ open: false });
    };

    handleSaveToProject = projects => project => {
        if (!project) return;

        if (!project["id"]) return;

        this.setState({ saveToProjectId: project["id"] });
        this.setState({ saveToProjectName: project["label"] });
        this.setState({ dialogopen: true });
        /* this.saveProjectClose(); */
    };

    //Save to project dialogs disagree function
    dialogHandleDisagree = () => {
        this.setState({ dialogopen: false });
    };

    //Save to project dialogs agree function
    dialogHandleAgree = () => {
        let parameters = {
            projectid: this.state.saveToProjectId,
            mail: this.state.message,
        };

        //Saves selected message to selected project
        DataHandler.post({ url: `mails/savemsg` }, { projectid: parameters.projectid, mail: parameters.mail })
            .done(response => {})
            .fail(response => {
                console.log("Error");
            });

        this.setState({ dialogopen: false });
    };

    setFilesState(files) {
        let helpfiles;
        let mailfiles;
        let mfiles;
        let uniqueArray = [];

        files.map(function(item) {
            delete item.file;
            item["contentBytes"] = item.base64.split("base64,").pop();
            item["@odata.type"] = "#microsoft.graph.fileAttachment";
            delete item.base64;
        });

        helpfiles = this.state.files;

        if (!(this.state.files == [] && this.state.files)) {
            files.forEach(function(file) {
                let found = false;

                helpfiles.forEach(function(sandwich) {
                    if (file.base64 == sandwich.base64 && file.name == sandwich.name) {
                        found = true;
                        return;
                    }
                });

                if (!found) uniqueArray.push(file);
            });

            helpfiles = this.state.files.concat(uniqueArray);

            this.setState({ files: helpfiles });
        } else {
            this.setState({ files: files });
        }
    }

    attachmentFilesDelete(i) {
        if (this.state.files.length > 0) {
            let files = this.state.files;
            files.splice(i, 1);
            this.setState({ files });
        }
    }

    //Triggers when page is loaded
    componentDidMount() {
        super.componentDidMount();

        //Get mailfolders info
        DataHandler.get({ url: `mails/mailfolders` })
            .done(response => {
                this.setState(response);
            })
            .fail(response => {
                console.log("Error");
            });

        //Get users contact from microsoft
        DataHandler.get({ url: `mails/contacts` })
            .done(response => {
                this.setState(response);
            })
            .fail(response => {
                console.log("Error");
            });

        //Get projects
        DataHandler.get({ url: `mails/projects` })
            .done(response => {
                this.setState({ projects: response });

                if (!(typeof this.props.projectid === "undefined")) {
                    response.map(({ id, name }, i) => {
                        if (id == this.props.projectid) this.onClickProjectHeader(id, name);
                    });
                }
            })
            .fail(response => {
                console.log("Error");
            });

        //Get own mail address (to remove from replyall etc.)
        DataHandler.get({ url: `mails/me` })
            .done(response => {
                if (response.mymail && response.mymail.mail) {
                    this.setState({ mymail: response.mymail.mail });
                }
            })
            .fail(response => {
                console.log("Error");
            });
    }

    onClickLogin = () => {
        DataHandler.get({ url: `mails/login` }).done(response => {
            if (response.url) {
                sessionStorage.setItem("previousUrl", window.location);
                sessionStorage.setItem("nextAction", "mail-office-login");
                window.location = response.url;
            }
        });
    };

    //Renders mailview component
    render() {
        const { userObject } = this.context;
        const { anchorEl } = this.state;

        let messagearea;
        let headerlist;
        let headerarea;

        if (this.state.showmessage) {
            messagearea = (
                <div className="mailreseived">
                    <div className="mailreseived_div">
                        <div className="attachments_div">
                            {this.state.attachments.map(({ id, name, size, isInline }) =>
                                !isInline ? (
                                    <div className="attachment_chip">
                                        <Chip
                                            className="attachment_chip_item"
                                            color="primary"
                                            variant="outlined"
                                            label={<div>{name}</div>}
                                            onClick={() => this.onClickAttachment(id)}
                                        />
                                    </div>
                                ) : (
                                    undefined
                                )
                            )}
                            {this.state.savedattachments.map(({ inline_id, filename, filesize }, i) =>
                                inline_id === null ? (
                                    <div className="attachment_chip">
                                        <Chip
                                            className="attachment_chip_item"
                                            color="primary"
                                            variant="outlined"
                                            label={<div>{filename}</div>}
                                            onClick={() => this.onClickSavedAttachment(i)}
                                        />
                                    </div>
                                ) : (
                                    undefined
                                )
                            )}
                        </div>
                        <Mailframe content={this.state.content} />
                    </div>
                    {this.state.reply ? (
                        <div className="mailsendcontainer" style={{ borderTop: "solid 1px #dde3e8" }}>
                            <div className="sendToRecipients">
                                <span>
                                    {this.tr("To: ")}
                                    {this.state.message.from.emailAddress.address}
                                </span>
                            </div>
                            {this.state.files.length != 0 ? (
                                <div className="files-chips-div">
                                    {this.state.files.map((file, i) => (
                                        <Chip
                                            className="files-chips"
                                            label={file.name + " " + file.size}
                                            onDelete={() => this.attachmentFilesDelete(i)}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            <RichEditorExample
                                onUpdateFile={this.setFilesState}
                                onUpdateContent={this.onUpdateContent}
                                onClickSend={this.sendMessage}
                            />
                        </div>
                    ) : (
                        undefined
                    )}
                    {this.state.replyall ? (
                        <div className="mailsendcontainer" style={{ borderTop: "solid 1px #dde3e8" }}>
                            <Tooltip
                                placement="bottom"
                                title={
                                    this.state.selectedFolderName === this.tr("Sent mails")
                                        ? this.state.toRecipients
                                        : this.state.message.from.emailAddress.name +
                                          " " +
                                          this.state.message.from.emailAddress.address
                                }
                            >
                                <span className="sendToRecipients">
                                    {this.tr("To: ")}
                                    {(this.state.toRecipients + " " + this.state.message.from.emailAddress.address)
                                        .replace(this.state.mymail + ",", "")
                                        .replace(this.state.mymail, "")}
                                </span>
                            </Tooltip>
                            {this.state.files.length != 0 ? (
                                <div className="files-chips-div">
                                    {this.state.files.map((file, i) => (
                                        <Chip
                                            className="files-chips"
                                            label={file.name + " " + file.size}
                                            onDelete={() => this.attachmentFilesDelete(i)}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            <RichEditorExample
                                onUpdateFile={this.setFilesState}
                                onUpdateContent={this.onUpdateContent}
                                onClickSend={this.sendMessage}
                            />
                        </div>
                    ) : (
                        undefined
                    )}
                    {this.state.forward ? (
                        <div className="mailsendcontainer" style={{ borderTop: "solid 1px #dde3e8" }}>
                            <Grid container justifyContent="left" alignItems="center" className="addressinfo forwardinfo">
                                {this.state.sendmailaddresses.map(({ address }, i) => (
                                    <React.Fragment>
                                        <Chip
                                            label={address}
                                            onDelete={() => this.removeAddress(i)}
                                            className="mailaddressdiv"
                                            color="primary"
                                        />
                                    </React.Fragment>
                                ))}
                                <TextField
                                    placeholder={this.state.mailaddressesplaceholder}
                                    className="addresstext"
                                    id="mailmail"
                                    type="email"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    onBlur={this.emailvalidator}
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            this.emailvalidator();
                                        }
                                    }}
                                />
                            </Grid>
                            {this.state.files.length != 0 ? (
                                <div className="files-chips-div">
                                    {this.state.files.map((file, i) => (
                                        <Chip
                                            className="files-chips"
                                            label={file.name + " " + file.size}
                                            onDelete={() => this.attachmentFilesDelete(i)}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            <RichEditorExample
                                onUpdateFile={this.setFilesState}
                                onUpdateContent={this.onUpdateContent}
                                onClickSend={this.sendMessage}
                            />
                        </div>
                    ) : (
                        undefined
                    )}
                </div>
            );

            headerlist = (
                <MailHeaders
                    onClickMessage={this.onClickMessage}
                    onClickMore={this.onClickMore}
                    selectedFolder={this.state.selectedFolderName}
                    messages={this.state.messages}
                    search={this.state.search}
                    selectedMailIndex={this.state.selectedMailId}
                    dateFormat={userObject.dateFormat}
                />
            );

            if (this.state.message.from.emailAddress.name.length > 0) {
                headerarea = (
                    <Grid container className="sendmailinfo">
                        <Grid className="sendmailinfoavatar" container justifyContent="center" alignItems="center">
                            <Avatar style={{ background: this.state.iconcolor, color: "#fff", fontWeight: "bold" }}>
                                {this.state.icontext}
                            </Avatar>
                        </Grid>
                        <Grid className="sendmailinfoheaders">
                            <Grid container justifyContent="left" alignItems="center" className="receivedaddressinfo">
                                <Tooltip
                                    placement="bottom-start"
                                    title={this.state.message.subject}
                                    aria-label={this.state.message.subject}
                                >
                                    <span>
                                        <p className="receivedmailsubject">{this.state.message.subject}</p>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid container justifyContent="left" alignItems="center" className="receivedsubjectinfo">
                                <span className="receivedaddressaddress">
                                    {this.tr("From:")}
                                    {this.state.message.from.emailAddress.name +
                                        " (" +
                                        this.state.message.from.emailAddress.address +
                                        ")"}
                                </span>
                                <Tooltip
                                    placement="bottom-start"
                                    title={
                                        this.state.selectedFolderName === this.tr("Sent mails")
                                            ? this.state.toRecipients
                                            : this.state.message.from.emailAddress.name +
                                              " " +
                                              this.state.message.from.emailAddress.address
                                    }
                                >
                                    <span className="receivedaddressaddress">
                                        {this.tr("To:")}
                                        {this.state.toRecipients}
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {!this.state.message.id.startsWith("project_") ? (
                            <Grid
                                className="sendmailinfoprojectbutton"
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                {!this.state.projectselection ? (
                                    <Button
                                        className="button"
                                        onClick={this.saveProjectOpen}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {this.tr("Save to project")}
                                    </Button>
                                ) : (
                                    <Select
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                        }}
                                        autoFocus
                                        className="selection"
                                        options={this.state.projects.map(suggestion => ({
                                            id: suggestion.id,
                                            label: suggestion.name,
                                        }))}
                                        onChange={this.handleSaveToProject()}
                                        placeholder=""
                                        onBlur={() => this.saveProjectClose()}
                                        menuIsOpen={true}
                                        onMenuClose={() => this.saveProjectClose()}
                                    />
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                className="sendmailinfoprojectbutton"
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Button variant="contained" color="primary" onClick={this.deleteSavedMessage}>
                                    {this.tr("Delete from project")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                );
            } else {
                headerarea = (
                    <Grid container className="sendmailinfo">
                        <Grid>
                            <Grid container justifyContent="left" alignItems="center" className="addressinfo">
                                {this.state.headertext}
                            </Grid>
                            <Grid container justifyContent="left" alignItems="center" className="subjectinfo">
                                {this.state.senderemailAddress.name + this.state.senderemailAddress.address}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
        } else {
            messagearea = (
                <div className="mailsendcontainer">
                    {this.state.files.length != 0 ? (
                        <div className="files-chips-div">
                            {this.state.files.map((file, i) => (
                                <Chip
                                    className="files-chips"
                                    label={file.name + " " + file.size}
                                    onDelete={() => this.attachmentFilesDelete(i)}
                                />
                            ))}
                        </div>
                    ) : null}
                    <RichEditorExample
                        onUpdateFile={this.setFilesState}
                        onUpdateContent={this.onUpdateContent}
                        onClickSend={this.sendMessage}
                    />
                </div>
            );

            headerlist = (
                <ContactHeaders
                    onClick={this.onClickContact}
                    messages={this.state.contacts}
                    search={this.state.search}
                />
            );

            headerarea = undefined;
        }

        if (this.state.tokenExpired) {
            return (
                <div className="mail-login-expired">
                    <Button onClick={this.emptyTokens}>{this.tr("TRY AGAIN")}</Button>
                    <div className="mail-login-expired-text">{this.tr("If the login does not work, make sure your email address works with Microsofts service")}</div>
                </div>
            );
        } else {
            return (
                <Grid container spacing={6} justifyContent="center" className="top-part" id="mail-container">
                    <Grid container justifyContent="center" alignItems="center" xs={2} className="top-part-left">
                        <Button variant="contained" size="small" onClick={this.newMessage}>
                            <MailIcon />
                            {this.tr("New message")}
                        </Button>
                    </Grid>
                    <Grid container justifyContent="left" alignItems="center" xs={3} className="top-part-middle">
                        <div className="top-part-middle-foldername">{this.state.selectedFolderName}</div>
                        <div className="top-part-middle-mymail">{this.state.mymail}</div>
                    </Grid>
                    <Grid container justifyContent="left" alignItems="center" xs={7} className="top-part-right">
                        {this.state.selectedMailId.length > 0 && !this.state.selectedMailId.includes("project") ? (
                            <div className="topbuttonsdiv">
                                <TopButton
                                    icon={<ReplyIcon color="action" />}
                                    cname="firstbutton"
                                    onClick={this.onClickReply}
                                    tooltip="Reply"
                                />
                                <TopButton
                                    icon={<ReplyAllIcon color="action" />}
                                    cname="topbutton"
                                    onClick={this.onClickReplyAll}
                                    tooltip="ReplyAll"
                                />
                                <TopButton
                                    icon={<ForwardIcon color="action" />}
                                    cname="topbutton"
                                    onClick={this.onClickForward}
                                    tooltip="Forward"
                                />
                                <TopButton
                                    icon={<FolderOutlinedIcon color="action" />}
                                    cname="topbutton"
                                    onClick={this.archiveMessage}
                                    tooltip="Archive"
                                />
                                <TopButton
                                    icon={<DeleteOutlinedIcon color="action" />}
                                    cname="topbutton"
                                    onClick={this.deleteMessage}
                                    tooltip="Delete"
                                />
                                <div className="inlineflex">
                                    <IconButton
                                        className="topbutton"
                                        aria-label="More"
                                        aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleClick}
                                        size="large">
                                        <MoreIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.emptyTokens}>{this.tr("Logout")}</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        ) : (
                            undefined
                        )}
                        {this.state.selectedMailId.length > 0 && this.state.selectedMailId.includes("project") ? (
                            <Grid container>
                                <div className="top-part-right-div">
                                    <div className="top-part-right-text">
                                        <div>{this.tr("Selected message")}</div>
                                    </div>
                                    <div className="top-part-right-button">
                                        <IconButton
                                            className="topbutton"
                                            aria-label="More"
                                            aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleClick}
                                            size="large">
                                            <MoreIcon />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.emptyTokens}>{this.tr("Logout")}</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            undefined
                        )}
                        {this.state.selectedMailId.length < 1 && !this.state.showmessage ? (
                            <Grid container className="sendmailinfo">
                                <Grid container justifyContent="left" alignItems="center" className="addressinfospecial">
                                    <span className="specialspan">{this.tr("To")}</span>
                                    <div className="specialwrapper">
                                        {this.state.sendmailaddresses.map(({ address }, i) => (
                                            <Chip
                                                label={address}
                                                onDelete={() => this.removeAddress(i)}
                                                className="mailaddressdiv"
                                                color="primary"
                                            />
                                        ))}
                                        <TextField
                                            className="addresstext"
                                            id="mailmail"
                                            type="email"
                                            autoCapitalize="off"
                                            autoComplete="off"
                                            onBlur={this.emailvalidator}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.emailvalidator();
                                                }
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid container justifyContent="left" alignItems="center" className="subjectinfospecial">
                                    <span className="specialspan">{this.tr("Subject")}</span>
                                    <TextField
                                        id="subjectsubject"
                                        className="subjecttext"
                                        type="text"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            undefined
                        )}
                        {this.state.selectedMailId.length < 1 && this.state.showmessage ? (
                            <Grid container>
                                <div className="top-part-right-div">
                                    <div className="top-part-right-text">
                                        <div></div>
                                    </div>
                                    <div className="top-part-right-button">
                                        <IconButton
                                            className="topbutton"
                                            aria-label={this.tr("More")}
                                            aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleClick}
                                            size="large">
                                            <MoreIcon />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.emptyTokens}>{this.tr("Logout")}</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            undefined
                        )}
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            open={this.state.open}
                            autoHideDuration={3000}
                            onClose={this.handleSnackbarClose}
                            message={<span id="message-id">{this.state.snackbarmessage}</span>}
                            action={
                                <Button color="primary" size="small" onClick={this.handleSnackbarClose}>
                                    {this.tr("OK")}
                                </Button>
                            }
                        />
                        <Dialog
                            className="mail-project-dialog"
                            open={this.state.dialogopen}
                            onClose={this.dialogHandleDisagree}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {this.tr("Save selected message to project") +
                                    ' "' +
                                    this.state.saveToProjectName +
                                    '"'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {this.tr("Do you want to save the selected message to project") +
                                        ' "' +
                                        this.state.saveToProjectName +
                                        '"' +
                                        this.tr(" and let other project members see the message?")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <div className="project-dialog-div-left">
                                    <Button
                                        className="project-dialog-button-left"
                                        onClick={this.dialogHandleAgree}
                                        color="primary"
                                        autoFocus
                                    >
                                        {this.tr("Agree")}
                                    </Button>
                                </div>
                                <div className="project-dialog-div-right">
                                    <Button className="project-dialog-button-right" onClick={this.dialogHandleDisagree}>
                                        {this.tr("Disagree")}
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        {/* <button onClick={this.tokenExpire} className="token-expire-test">Test token expiring</button>  */}
                    </Grid>
                    <Grid container justifyContent="left" alignItems="top" xs={2} className="bottom-part-left">
                        <div className="ListItemLinks">
                            <div>
                                <div className="folders">
                                    <MailFolderLink
                                        className="listheaders"
                                        onClick={this.onClickMailFolder}
                                        selectedIndex={this.state.selectedIndex}
                                        mailfolderinfo={this.state.folderheaders}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="projects">
                                    <MailProjectList
                                        onClick={this.onClickProjectHeader}
                                        projects={this.state.projects}
                                        className="listheaders"
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid container alignItems="top" xs={3} className="bottom-part-middle">
                        <InputBase
                            placeholder={this.state.searchplaceholder}
                            className="search"
                            onChange={this.searchBox}
                        />
                        <div className="headerlist">{headerlist}</div>
                    </Grid>
                    <Grid container alignItems="top" xs={7} className="bottom-part-right">
                        {headerarea}
                        {this.state.selectedMailId.length < 1 && !this.state.showmessage ? (
                            <div className="mailcontentspecial mailcontent">{messagearea}</div>
                        ) : (
                            <div className="mailcontent">{messagearea}</div>
                        )}
                    </Grid>
                </Grid>
            );
        }
    }
}
