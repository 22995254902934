import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import cn from 'classnames';
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import TaimerAvatar from "../../../../general/TaimerAvatar";
import { GridRow } from "../helpers";

interface Props {
	item: GridRow;
	toggleRowExpand: any;
}

interface State {
}

export default class GridLeftUserCell extends TaimerComponent<Props, State> {
	constructor(props, context) {
		super(props, context, "resourcing/views/ResourcingGrid/components/GridLeftUserCell");
	}

	shouldComponentUpdate = oldProps => oldProps.item !== this.props.item;

	render() {
		const { item, toggleRowExpand } = this.props;
		const { user } = item;

		return (<div className={cn("grid-table-cell", "grid-table-cell-first", "grid-table-user", !item.editable && "row-not-editable")}>
			<div className="expandOrClose">
				{item.hasSubItems && <div className="expand-icon-container-sub" onClick={() => toggleRowExpand(item)}>
					{item.open ? (
						<Arrow className="expand-icon up" />
					) : (
						<Arrow className="expand-icon down" />
					)}
				</div>}
			</div>
			<TaimerAvatar
				id={user?.id}
				name={user && user?.id > 0 ? user.name : this.tr("Unassigned")}
				color={user?.color}
			/>
			<div className="user-cell-labels">
				<h3>
					{user && user.id > 0 ? user.name : this.tr("Unassigned")}
				</h3>
				{user?.role && <p>{user.role}</p>}
			</div>
		</div>);
	}
}