import React, { Component } from 'react';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* data backend */
import DataHandler from './../../general/DataHandler';
import { format, endOfMonth, startOfMonth, startOfYear, isValid, addMonths } from "date-fns";

/* local components */
import TaimerComponent from '../../TaimerComponent';
import DataList from './../../general/DataList';
import { DateRangePicker } from './../../general/react-date-range/src';

/* material ui */
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

/* material icons */
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRight from '@mui/icons-material/ChevronRight';

/* chart-js */
import { Bar } from 'react-chartjs-2';
import ChartOptions from './../../general/ChartOptions';

/* extras */
import _ from 'lodash';
import debounce from 'lodash/debounce';
import moment from 'moment';

/* css */
import './Invoicing.css'
import { LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

/* Images */
import { ReactComponent as InvoicingOverview } from '../images/InvoicingOverview.svg';
import colors from '../../colors';

const styles = theme => ({
    dstyle: {
        color: "#575962"
    },
    wstyle: {
        color: "#f5a623"
    },
    sstyle: {
        color: "#2d9ff7"
    },
    pstyle: {
        color: colors.greenish_cyan
    },
    cstyle: {
        color: "#6f42c1"
    },
    odstyle: {
        color: "#ff5722"
    }
    });

class InvoicingOverView extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context){
        super(props, context, "dashboard/overview/Invoicing");

        const { functions: { getFinancialYear } } = context;
        const dateRange = getFinancialYear();
        const { tr } = this;

        this.state = {
            data: [],
            open: true,
            elementWidth: 10000,
            elementWidthSet: false,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                key: "selection"
            },
        }

        this.monthlyLabels = [
			tr("Jan"),
			tr("Feb"),
			tr("Mar"),
			tr("Apr"),
			tr("May"),
			tr("Jun"),
			tr("Jul"),
			tr("Aug"),
			tr("Sep"),
			tr("Oct"),
			tr("Nov"),
			tr("Dec")
        ];
        
        this.element = React.createRef();
        this.chart = React.createRef();
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.insightFilters !== prevProps.insightFilters) {
            this.handleData();
        }
        if (this.props.customersId !== prevProps.customersId || prevProps.company !== this.props.company)
            this.handleData();    
        if(this.element.current && this.state.elementWidthSet == false)
            this.setState({elementWidth: this.element.current.clientWidth, elementWidthSet: true})
    }

    componentDidMount(){
        super.componentDidMount();
        this.handleData();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = debounce(() => {
        this.updateView();
    }, 10)

    updateView = () => {
        let element = document.getElementById("overview-invoicing");
        if(element) {
            this.setState({elementWidth: element.clientWidth});
        }
    }

    async handleData(){


        if (this.props.insightFilters) {
            if (!this.props.insightFilters.dateRange)
                return;

            const {
                dateRange: { startDate, endDate },
                customers_ids,
                projects_ids,
                customer_tags,
                project_tags,
                customership_group_ids,
                project_categories_ids,
                reporting_group_ids,
                project_types_ids,
                groups_ids,
                users_ids,
                company,
                advanced_search,
            } = this.props.insightFilters;

            const data = await DataHandler.post({url: `data/invoicing_overview/${format(startDate, "YYYY-MM-DD") }/${format(endDate, "YYYY-MM-DD")}`, insightFiltering: 1}, {
                ...advanced_search,
                company,
                projects: projects_ids,
                project_tags: project_tags,
                customers: customers_ids,
                customer_tags: customer_tags,
                customership_groups: customership_group_ids,
                project_type: project_types_ids,
                project_category: project_categories_ids,
                reporting_group: reporting_group_ids,
                users: users_ids,
                groups: groups_ids,
            });
            this.setState({...data, loaded: true});
        } else {
            const {dateRange} = this.state;
            const { company } = this.props;
    
    
            const data = await DataHandler.get({url: 'data/invoicing_overview/' + format(dateRange.startDate, "YYYY-MM-DD") + '/' + format(dateRange.endDate, "YYYY-MM-DD"), customersId: this.props.customersId || 0, company});
            //const labels = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};
            //_.map(data, year => _.map(year.months, month => month.real_month_name = labels[month.real_month]))
            this.setState({...data, loaded: true});
        }
    }

    onDateChange = (event) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.handleData());
    }

    onDateInputChange = (dateType, date) => {

        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.handleData());
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.handleData());
        }
    }

    handleToggle(){
        if (this.state.open == true){
            this.setState({open: false});
        } else if (this.state.open == false) {
            this.setState({open: true});
        }
    }

    createInvoice(e){
        this.context.functions.updateView({module: 'invoices', action: 'view'}, e.ctrlKey || e.metaKey || e.button === 1);
    }


    render(){
        const {year, summary, loaded, open, dateRange} = this.state;
        const { taimerAccount, privileges, userObject, functions: { updateView } } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency || "EUR"
        }).format;
        if (!loaded) {
            return <div><img className='main-page-loading-indicator' src={require('../insights/img/loading.svg').default} /></div>
        }
        
        let element = document.getElementById("overview-invoicing");

//        let data = _.last(data);
        //tunge datasetit funktiion joskus
        const datasetDraft = {
            data: [],
            label: this.tr('Draft'),
            backgroundColor: ChartOptions.colorPalette[10],
        }

        const datasetWaiting = {
            data: [],
            label: this.tr('Waiting'),
            backgroundColor: ChartOptions.colorPalette[11],
        }

        const datasetSent = {
            data: [],
            label: this.tr('Sent'),
            backgroundColor: ChartOptions.colorPalette[12],
        }

        const datasetPaid = {
            data: [],
            label: this.tr('Paid'),
            backgroundColor: ChartOptions.colorPalette[13],
        }

        const datasetCredited = {
            data: [],
            label: this.tr('Credited'),
            backgroundColor: ChartOptions.colorPalette[14],
        }

        const datasetOverDue = {
            data: [],
            label: this.tr('Overdue'),
            backgroundColor: ChartOptions.colorPalette[15],
        }

        _.map(year, month => {
            datasetDraft.data.push(month.data.draft);
            datasetWaiting.data.push(month.data.waiting);
            datasetSent.data.push(month.data.sent);
            datasetPaid.data.push(month.data.paid);
            datasetCredited.data.push(month.data.credited * -1);
            datasetOverDue.data.push(month.data.overdue);
        });
        
        const labels = [];

        _.map(year, label => {
            const momentMonth = moment(label.date, "MM-YYYY");
            labels.push(`${this.monthlyLabels[momentMonth.month()]} ${momentMonth.format("YYYY")}`)
        })

        const invoicingData = {
            labels: labels,
            dates: _.map(year, 'date'),
            datasets: [
                datasetDraft,
                datasetWaiting,
                datasetSent,
                datasetPaid,
                datasetCredited,
                datasetOverDue
            ]
        }
        return(
            <div className={"grid-container content-block " + (!open && "closed")} id="overview-invoicing">
                { this.state.empty ? <div className="grid-item overlayDiv">
                    <div className = {"overlay"} >
                        <InvoicingOverview className="svg"/>
                        {
                            privileges.invoices && privileges.invoices.write_full ?
                            <p>{this.tr("No invoices here.")} <a 
                                onClick={e => (e.button !== 1) && this.createInvoice(e)} 
                                onMouseUp={e => (e.button === 1) && this.createInvoice(e)}>
                                {this.tr("Create invoice")}</a></p>
                            : <p>{this.tr("There are no invoices")}</p>
                        }               
                    </div>
                </div> : undefined }
                <div className="grid-item">
                {this.props.insightFilters && <div className="header">
                    <div className="header-group">
                        <Button className="button" size="small" variant="text" onClick={this.handleToggle}>{this.tr("Invoicing Overview")} {!open ? <ExpandMore /> : <ExpandLess />}</Button>
                    </div>
                </div>}
                {!this.props.insightFilters && <div className="header">
                        <Button className="button" size="small" variant="text" onClick={this.handleToggle}>{this.tr("Invoicing Overview")} {!open ? <ExpandMore /> : <ExpandLess />}</Button>
                        <DateRangePicker
                            className="daterange"
                            ranges={[dateRange]}
                            onChange={this.onDateChange}
                            onInputChange={this.onDateInputChange}
                            label={this.tr("Time span")}
                            dateFormat={userObject.dateFormat} />
                       {
                       // <DataList className="button" />
                       }
                    {
                    //<Button className="button" size="small" variant="text" id="rb">Invoicing insights {<ChevronRight />}</Button>
                    }
                </div>}
                <br />
                {open && (
                    <React.Fragment>
                        {
                            // <div ref={this.element} className={`wrapper ${this.state.elementWidth <= 1230 ? "divider" : ""}`} >
                        }
                        <div ref={this.element} className={`wrapper`} >
                            <div className="chart-area">
                                <Bar key={"chart"} id ="bars" ref={this.chart}
                                    data={invoicingData}
                                    height={400}
                                    options={{
                                        maintainAspectRatio: false,
                                        plugins: {
                                            tooltip: {
                                                enabled: true,
                                                callbacks: {
                                                    label: function(tooltipItem) {
                                                        const dataset = tooltipItem.dataset;
                                                        return dataset.label + ": " + currencyFormatter(tooltipItem.raw);
                                                    }
                                                }
                                            },
                                            legend: {
                                                display: true,
                                                position: 'bottom',
                                                labels: {
                                                    boxWidth: 14,
                                                    padding: 20,
                                                    usePointStyle: true
                                                }
                                            },
                                        },
                                        onClick: (bar, chart) => {
                                            let b = this.chart.current.chartInstance.getElementAtEvent(bar);
                                            if (b.length == 0){
                                                return
                                            }
                                          
                                            let index = b[0]._index;
                                            let parameters = { 
                                                pipeline_id: -1, 
                                                customerId:this.props.customersId,
                                                type:b[0]._datasetIndex,
                                                companies_id: this.props.company,
                                                date: (b[0]._chart.config.data && b[0]._chart.config.data.dates[index].split('-').reverse().join('-')) + "-01" }
                                            updateView({module: 'invoices', action: 'main', selectedTab: 'invoices', ...parameters}, bar.ctrlKey || bar.metaKey);
                                        },
                                        scales: {
                                            y: {
                                                stacked: true,
                                                ticks: {
                                                    callback: function(value, index, values) {
                                                        return currencyFormatter(value);
                                                    },
                                                }
                                            },
                                            x: {
                                                stacked: true,
                                                grid: {display: false}
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="summary-area">
                                <Table className="summary">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="title" id="title">{this.tr("Invoice Summary")}</TableCell>
                                            <TableCell id="title"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="title">{this.tr("Draft")}</TableCell>
                                            <TableCell className="content" classes={{root: this.props.classes.dstyle}}>{currencyFormatter(summary.draft)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="title">{this.tr("Waiting")}</TableCell>
                                            <TableCell className="content" classes={{root: this.props.classes.wstyle}}>{currencyFormatter(summary.waiting)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="title">{this.tr("Sent")}</TableCell>
                                            <TableCell className="content" classes={{root: this.props.classes.sstyle}}>{currencyFormatter(summary.sent)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="title">{this.tr("Paid")}</TableCell>
                                            <TableCell className="content" classes={{root: this.props.classes.pstyle}}>{currencyFormatter(summary.paid)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="title">{this.tr("Credited")}</TableCell>
                                            <TableCell className="content" classes={{root: this.props.classes.cstyle}} >{currencyFormatter(summary.credited)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="title" id="last-row" >{this.tr("Overdue")}</TableCell>
                                            <TableCell className="content" id="last-row" classes={{root: this.props.classes.odstyle}} >{currencyFormatter(summary.overdue)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                </div>
            </div>
        )
    }

}

export default withStyles(styles, { withTheme: true })(InvoicingOverView);