import React from "react";
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import "./LoaderButton.css";

class LoaderButton extends React.Component {

    constructor(props) {
		super(props);
    }

    render() {
        const {text, className, progressClassName, loading, notAllowed, buttonProps, progressProps, setProggressHeight, progressPadding, ...other} = this.props;

        const buttonStyle = {
            opacity: loading || notAllowed ? '.7' : "1",
        };
        const progressStyle = {
            minWidth: text.length + 'ch',
            height: setProggressHeight ? "100%" : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: progressPadding ? progressPadding + "px" : 0,
            paddingBottom: progressPadding ? progressPadding + "px" : 0
        };

        return (
            <Button data-testid={this.props['data-testid']} className={`loader-button ${className}`} disabled={loading || notAllowed} style={buttonStyle} onClick={!loading && this.props.onClick} onMouseUp={!loading && this.props.onMouseUp} {...buttonProps} {...other} >
                <span>{!loading && text.toUpperCase()}</span>
                {loading && (
                    <div style={progressStyle}>
                        <CircularProgress color="inherit" disableShrink size={18} className={`TMRDialogProgress ${progressClassName}`} {...progressProps} />
                    </div>
                )}
            </Button>);
    }	
}

Button.defaultProps = {
    text: "",
    className: "",
    progressClassName: "",
    loading: false,
    notAllowed: false,
    buttonProps: {},
    progressProps: {},
    onMouseUp: () => {},
    onClick: () => {}
};

export default LoaderButton;