import React, { useState } from 'react';
import TaimerComponent from '../TaimerComponent';
import PropTypes from "prop-types";
import classNames from 'classnames';
import moment from 'moment';
import $ from 'jquery';

/* context */
import { SettingsContext } from './../SettingsContext';

/* material ui */
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Popover from "@mui/material/Popover";

import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from "@mui/material/Switch";
import MUIList from "@mui/material/List";
import MUIListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

/* icons */
import { NotificationsActive, ExpandMore, ExpandLess, Save, Clear, CloudDownload, RemoveRedEye } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import HourReportListOverlay from "../list/overlays/HourReportListOverlay";

/* local components */
import AdvancedSearch from "./../search/AdvancedSearch";
import OutlinedField from "./../general/OutlinedField";
import LoaderButton from './../general/LoaderButton';
import MultiSelect from "./../general/MultiSelect";
import { DatePicker, DateRangePicker } from './../general/react-date-range/src';
import DataList from './../general/DataList';
import List from '../list/List';
import ReportListRow from './ReportListRow';
import ReportsDefinitions from "./ReportsDefinitions";
import {ReactComponent as Loading} from '../dashboard/insights/img/loading.svg';
import HoursIntegrationExporter from "./HoursIntegrationExporter";
import Utils from "./../general/Utils.js";

/*dialogs*/
import SaveSearchQuery from "./dialogs/SaveSearchQuery";
import DeleteSearchQuery from "./dialogs/DeleteSearchQuery";

/* data backend */
import DataHandler from "./../general/DataHandler";

import { format, endOfMonth, startOfMonth, isValid } from "date-fns";
import { treeFormatDataForList, createTreeOptions } from "./../list/ListUtils";

import FileSaver from 'file-saver';

import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions
} from "../dimensions/helpers"

import SimpleList from "../list/SimpleList";
import SimpleListRow from "../list/SimpleListRow";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { intersection } from "../general/Set";

import "./../list/List.css";
import { Tooltip } from '@mui/material';

import _ from 'lodash';
import { withSnackbar } from 'notistack';

const defineOptions = ReportsDefinitions.getDefinitions();
const currentReports = (selected) => Object.entries(defineOptions.reports[selected]).map(([k, r]) => {return {...r, id: k}});
const allFields = (withKeys) => withKeys ? Object.entries(defineOptions.fields) : Object.values(defineOptions.fields);
const tryParseJSONObject = (jsonString) => {
    try {
        var o = JSON.parse(jsonString);
        if (o && (typeof o === "object" || typeof o === "boolean"))
            return o;
    }
    catch (e) {}
    return false;
}

/* date consts */
const thisMonth = {start: startOfMonth(new Date()), end: endOfMonth(new Date())};
const today = format(new Date(), 'YYYY-MM-DD');
                    
const styles = theme => ({
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'       
    },
    filterButton: {
        padding: '0',
        margin: '0 10px 0 0',
        minWidth: '200px',
        maxWidth: "200px",
        height: "47px"    
    },
    halfFilterButton: {
        padding: '0',
        margin: '0 10px 0 0',        
        minWidth: '125px',
        maxWidth: '125px',
        height: "47px"    
    },
    deleteIcon: {
        position: 'absolute',
        right: '0px',
        marginRight: '4px'
    },
    saveSearch: {
        width: '250px',
        marginTop: '-4px',
        paddingBottom: '6px',
        fontSize: '12px',
        cursor: 'pointer',
        color: '#2d9ff7',
        float: 'left',
        fontFamily: '"Open Sans", sans-serif',
        maxWidth: "fit-content",
        height: '59px',
        display: 'flex',
        alignItems: 'center'
    },
    saveIcon: {
        color: "#2d9ff7",
        height: "16px",
        verticalAlign: "sub"
    }
});

const GroupByFilter = (props) => {
  return (
    <OutlinedField
        className={props.filterButton}
        name="groupByFilter"
        select
        label={props.labels.groupByFilter}
        value={props.values.groupByFilter}
        onChange={ (e) => e.target.value !== props.values.groupByFilter && props.onFilterChange('groupByFilter', e.target.value)} >

            {props.options.groups?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.label}
                </MenuItem>
            ))}
    </OutlinedField>
  );
};

const NameTypeFilter = (props) => {
  return (
    <OutlinedField
        className={props.filterButton}
        name="nameTypeFilter"
        select
        label={props.labels.nameType}
        value={props.values.nameTypeFilter}
        onChange={ (e) => e.target.value !== props.values.nameTypeFilter && props.onFilterChange('nameTypeFilter', e.target.value)} >

            {props.options.customerNameTargets?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
    </OutlinedField>
  );
};

const UserFilter = (props) => {
  const userFilterValue = Array.isArray(props.values.userFilter) ? props.values.userFilter : [props.values.userFilter];
  return (
    <MultiSelect
        key={`${props.selectedType}-userFilter-${props.selectedQuery}-${userFilterValue?.join()}`}   
        className={props.filterButton}
        options={props.options.users?.map(u => ({value: u.id, label: u.name}))}
        name="userFilter"
        onOpenMenu={() => props.onMultiselectFilterMenuChange(true)}
        onCloseMenu={() => props.onMultiselectFilterMenuChange()}
        defaultValue={props.options.users?.map(u => (userFilterValue?.includes(u.id) && {value: u.id, label: u.name})).filter(x => x) || []}
        label={props.labels.user}
        onChange={(data) => {!_.isEqual(data.map(d => d.value), userFilterValue) &&
            props.onFilterChange('userFilter', data?.reduce((arr, d) => [...arr, d.value], []));
        }}
    />
  );
};

const ApprovedFilter = (props) => {
  return (
    <OutlinedField
        className={props.halfFilterButton}
        name="approvedFilter"
        select
        label={props.labels.approve}
        value={props.values.approvedFilter}
        onChange={ (e) => e.target.value !== props.values.approve && props.onFilterChange('approvedFilter', e.target.value)} >

            {props.options.approvedOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
    </OutlinedField>
  );
};

const StartDateFilter = (props) => {
  return (
    <DatePicker
        className="date date-picker"
        label={props.labels.startDate}
        name="startDay"
        date={props.values.startDay}
        onChange={(date) => props.onDateChange(date, 'startDay')}
        onInputChange={(dateType, date) => props.onDateInputChange(dateType, date, 'startDay')}
        dateFormat={props.dateFormat}/>
  );
};

const DateFilter = (props) => {
  return (
    <DatePicker
        className="date date-picker"
        label={props.labels.date}
        name="day"
        date={props.values.day}
        onChange={(date) => props.onDateChange(date, 'day')}
        onInputChange={(dateType, date) => props.onDateInputChange(dateType, date, 'day')}
        dateFormat={props.dateFormat}/>
  );
};

const InvoicedFilter = (props) => {
  return (
    <OutlinedField
        className={props.filterButton}
        name="invoicedFilter"
        select
        label={props.labels.invoicedFilter}
        value={props.values.invoicedFilter}
        onChange={(e) => e.target.value != props.values.invoicedFilter && props.onFilterChange('invoicedFilter', e.target.value) } >

        {props.options.productInvoiceStatusOptions?.map(option => (
            option.included.includes(props.selectedType) &&
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </OutlinedField>
  );
};

const CustomerFilter = (props) => {
  return (
    <MultiSelect
        key={`${props.selectedType}-customerFilter-${props.selectedQuery}-${props.values.customerFilter?.join()}`} 
        className={props.filterButton}
        options={props.options.customers?.map(u => ({value: u.id, label: u.name}))}
        name="customerFilter"
        onOpenMenu={() => props.onMultiselectFilterMenuChange(true)}
        onCloseMenu={() => props.onMultiselectFilterMenuChange()}        
        defaultValue={props.options.customers?.map(u => (props.values.customerFilter?.includes(u.id) && {value: u.id, label: u.name})).filter(x => x) || []}
        label={props.labels.customerFilter}
        onChange={(data) => {!_.isEqual(data.map(d => d.value), props.values.customerFilter) &&
            props.onFilterChange('customerFilter', data?.reduce((arr, d) => [...arr, d.value], []));
        }}
    />
  );
};

const ProjectFilter = (props) => {
  return (
    <MultiSelect
        key={`${props.selectedType}-projectFilter-${props.selectedQuery}-${props.values.projectFilter?.join()}`}  
        className={props.filterButton}
        options={props.options.projects?.filter(p => props.values.customerFilter[0] == '0' || props.values.customerFilter.includes(p.customers_id)).map(u => ({value: u.id, label: u.name}))}
        name="projectFilter"
        onOpenMenu={() => props.onMultiselectFilterMenuChange(true)}
        onCloseMenu={() => props.onMultiselectFilterMenuChange()}        
        defaultValue={props.options.projects?.map(u => (props.values.projectFilter?.includes(u.id) && {value: u.id, label: u.name})).filter(x => x) || []}
        label={props.labels.projectFilter}
        onChange={(data) => {!_.isEqual(data.map(d => d.value), props.values.projectFilter) &&
            props.onFilterChange('projectFilter', data?.reduce((arr, d) => [...arr, d.value], []));
        }}
    />
  );
};

const FinancialYearFilter = (props) => {
  return (
    <OutlinedField
        className={props.filterButton}
        name="financialYearFilter"
        select
        label={props.labels.financialYearFilter}
        value={props.values.financialYearFilter}
        onChange={ (e) => e.target.value !== props.values.financialYearFilter && props.onFilterChange('financialYearFilter', e.target.value)} >

            {props.options.financial_years?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
    </OutlinedField>
  );
};

const FunnelFilter = (props) => {
   const funnelFilterValue = Array.isArray(props.values.funnelFilter) ? props.values.funnelFilter : [props.values.funnelFilter];
  return (
    <MultiSelect
        key={`${props.selectedType}-funnelFilter-${props.selectedQuery}-${funnelFilterValue?.join()}`} 
        className={props.filterButton}
        options={props.options.funnels?.map(f => ({value: f.id, label: f.name}))}
        name="funnelFilter"
        defaultValue={props.options.funnels?.map(f => ({value: f.id, label: f.name})).filter(f => funnelFilterValue?.includes(f.value)) || []}
        onOpenMenu={() => props.onMultiselectFilterMenuChange(true)}
        onCloseMenu={() => props.onMultiselectFilterMenuChange()}  
        label={props.labels.funnelFilter}
        onChange={(data) => {
            const funnels = data?.reduce((arr, d) => [...arr, d.value], []);
            !funnels.every(f => funnelFilterValue.includes(f)) &&
            props.onFilterChange('funnelFilter',funnels);
        }}
    />
  );
};

const ScheduledTypeFilter = (props) => {
  return (
    <OutlinedField
        className={props.filterButton}
        name="ScheduledTypeFilter"
        select
        label={props.labels.scheduledType}
        value={props.values.scheduledTypeFilter}
        onChange={ (e) => e.target.value !== props.values.scheduledTypeFilter && props.onFilterChange('scheduledTypeFilter', e.target.value)} >
            {props.options.scheduledTypes?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
    </OutlinedField>
  );
};

export const SwitchesGroup = (props) => {

    const [showMenu, toggleMenu] = useState(0);
    const [anchor, setAnchor] = useState(null);

    const content = (
        <div
            className="report-switch-group"
            onClick={(e) => {
                toggleMenu(!showMenu);
                setAnchor(e.target);
            }}
        >
            <RemoveRedEye className="filter-group-icon" />
            {!props.noLabel && <p>{props.groupLabel}</p>}

            {showMenu ? (
                <Popover
                    id="report-switch-group"
                    open
                    anchorEl={props.anchorEl || anchor}
                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <MUIList className="report-switch-list" component="div" onClick={(e) => e.stopPropagation()}>
                        {props.options.map((sw, i) => (
                            <MUIListItem button className="muiListItem" disabled={sw.disabled} onClick={() => props.onChange(sw.id)}>
                                <ListItemText style= {{textIndent: `${sw.indent}px`}} primary={sw.name} className="report-switch-label" />
                                <ListItemSecondaryAction>
                                    <Switch color="primary" disabled={sw.disabled} onChange={() => {props.onChange(sw.id, !props.values[sw.id])}} checked={props.values[sw.id]} />
                                </ListItemSecondaryAction>
                            </MUIListItem>
                        ))}
                    </MUIList>
                </Popover>
            ) : undefined}
        </div>
    );

    if (props.showTooltip) {
        return <Tooltip title={props.groupLabel} classes={{ tooltip: "darkblue-tooltip" }} placement="top-start" >{content}</Tooltip>;
    }

    return content;
};

class ReportsTab extends TaimerComponent {
    static contextType = SettingsContext;

    static getDerivedStateFromProps(props, state) {
        if (props.tabContent !== state.tabContent) {
            return { tabContent: props.tabContent, selectedType: props.selectedReport};
        }
        return null;
    }

    constructor(props, context) {
        super(props, context, "reports/ReportsTab");

        this.financialYearRange = [format(context.functions.getFinancialYear().start, "YYYY-MM-DD"), format(context.functions.getFinancialYear().end, "YYYY-MM-DD")];
        this.savedSearchSelectProps = { 
            MenuProps: { 
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
            } 
        };

        this.filtersInitialValues = {
            dateRange: {
                startDate: format(thisMonth.start, "YYYY-MM-DD"),
                endDate: format(thisMonth.end, "YYYY-MM-DD"),
                key: "selection"
            },
            selectedQuery: undefined,
            currentpage: 1,
            switches: {
                show_unlocked: true,
                show_locked: true,
                hide_projects: false,
                show_projects: false,
                show_creditnotes: true,
                show_subunits: false,
                show_projects_grouped: false,
                show_hold: true,
                show_project_tree: false,
                show_invoiceable: true,
                show_uninvoiceable: true,
                show_internal: true,
                show_invoicedScheduled: false,
                show_scheduled: true,
                show_automatic: true,
                show_purchase_orders: true,
                show_waiting_invoices: true,
                show_draft_invoices: true,
                show_waiting_bills: true,
                show_probability: false,
                show_grouped: true,
                show_account_subtotal: true,
                show_grouped_by_company: true,
                show_singlecompany_hours: true,
                show_export_hierarhia: true,
                show_grouped_by_main_customer: false,
                show_division: (this.context.addons && this.context.addons.division) ? true : false,
                view_quantity: false,
            },
            filterValues: {
                nameTypeFilter: 0,
                userFilter: ['0'],
                approvedFilter: -2,
                startDay: undefined,
                day: format(today, "YYYY-MM-DD"),
                dateTarget: 0,
                invoicedFilter: 1,
                customerFilter: [this.props.accounts_id || '0'],
                projectFilter: [this.props.projects_id || '0'],
                funnelFilter: ['0'],
                financialYearRange: this.financialYearRange,
                financialYearFilter: format(context.functions.getFinancialYear().start, "YYYY"),
                scheduledTypeFilter: '0',
                groupByFilter: undefined,
                pageLimitDifference: 0,
                groupByFilter: 'customers'
            },
            autoCompleteDataFilters: {},
            companySettings: {},            

        }

        this.state = {
            pageCount: 1,
            reportCount: props.fetchButton ? 1 : 0,
            perpage: props.perpage,
            tabContent: props.tabContent,
            selectedType: props.selectedReport,
            autoCompleteData: {},
            autoCompleteDataFilters: {},
            dimensions: [],
            dimensionItems: {},
            dimensionAutoCompleteData: {},
            dimensionAutoCompleteDataFilters: {},
            savedQueries: [],
            currentDialog: false,
            dialogData: false,            
            data: [],
            pageTotal: 0,
            queryTotal: 0,
            checked: true,
            dataIsFetching: !props.fetchButton,
            pageIsAppending: false,
            searchKey: 0,   
            ...this.filtersInitialValues,
            companySettings: {}
        }   

//kaikki raporttityyppikohtaiset suodattimet 
        this.individualFilters = {
            nameTypeFilter: NameTypeFilter,
            userFilter: UserFilter,
            approvedFilter: ApprovedFilter,
            startDateFilter: StartDateFilter,
            dateFilter: DateFilter,
            invoicedFilter: InvoicedFilter,
            customerFilter: CustomerFilter,
            projectFilter: ProjectFilter,
            financialYearFilter:FinancialYearFilter,
            funnelFilter: FunnelFilter,
            scheduledTypeFilter: ScheduledTypeFilter,
            groupByFilter: GroupByFilter,
        };        

        this.productInvoiceStatusOptions = [
            {value: "1", label: this.tr("Invoiced"), included: ['products_by_product', 'products_by_customer', 'cpq_by_cpq', 'cpq_by_customer']},
            {value: "2", label: this.tr("Active Quotes"), included: ['products_by_product', 'products_by_customer', 'cpq_by_cpq', 'cpq_by_customer']},
            {value: "3", label: this.tr("Open Invoices"), included: ['products_by_customer', 'cpq_by_cpq', 'cpq_by_customer']}
        ];

        this.userTypes = [
            // {id: "-1", name: this.tr("all")},
            {id: "1", name: this.tr("users")},
            {id: "0", name: this.tr("freelancers")}
        ];

        this.dateTargets = [
            {id: '0', name: this.tr("By invoice date")},
            {id: '1', name: this.tr("By event end date")}
        ]

        this.customerNameTargets = [
            {id: '0', name: this.tr("By customer invoice address")},
            {id: '1', name: this.tr("By project customer")}
        ]

        this.approvedOptions = [
            {value: "-2", label: this.tr('All')},
            {value: "1", label: this.tr('Approved')},
            {value: "0", label: this.tr('Not approved')},
            {value: "-1", label: this.tr('Rejected')}  
        ];

        this.scheduledTypes = [
            {value: "0", label: this.tr('All scheduled invoicing')},
            {value: "1", label: this.tr('Scheduled invoices')},
            {value: "2", label: this.tr('Automatic invoicing')},
            {value: "3", label: this.tr('Without any scheduled invoicing')}
        ];

        this.userTypeAutocompleteClasses = [
            'users',
            'account_managers',
            'project_managers',
            'sales_agents'
        ];

        this.userTypeDataHeaders = {
            users_id: 'users'
        };        

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer"),
            total: this.tr("total"),
            without_customer_groups: this.tr("Without customer groups"),
            without_project_managers: this.tr("Without project managers"),
            deleted: this.tr("deleted"),
            no_category_defined: this.tr("No project category defined"),
            bill: this.tr("Bill")
        };

        this.getParams = {};
        this.lastGetParams = {};
        this.lastPostParams = {};
        this.appendableSort = {};

        this.dialogs = {
            saveSearch: SaveSearchQuery,
            deleteSearch: DeleteSearchQuery
        };
        this.dialogTexts = {
            saveSearch: this.tr("Save current search"),
            deleteSearch: this.tr("Are you sure you want to delete this saved search?")
        }       

        this.checkbox           = React.createRef();
        this.list               = React.createRef();
        this.advancedSearch     = React.createRef();
        this.DateOutlinedField  = React.createRef();
        this.switchesGroup      = React.createRef();
        this.companySelectRef  = React.createRef();

        this.listenReset = this.listenReset.bind(this);
        this.unlistenReset = this.unListenReset.bind(this);

        this.initDimensions = this.initDimensions.bind(this);

        this.hourExportIntegrations = [
            {value: "mepco", label: "Mepco"},
            {value: "procountor", label: "Procountor"},
        ];

        this._infiniteScrollTotals = {};
        this.searchTerms = this.getInitialSearchTerms();
    }


    getInitialSearchTerms = () => {
        if (this.props.projects_id) {
            if (!defineOptions.fields['projects'].search.includes(this.state.selectedType)) return undefined;
            return {
                advanced_search_criteria: {
                    filters: {
                        projects: [
                            {
                                operator: 'eq',
                                type: 'entity',
                                value: this.props.projects_id
                            }
                        ]
                    }
                },
                currentFilters: [
                    {
                        bundledElements: [],
                        entityMode: true,
                        identifier: this.props.projects_id,
                        isBundle: false,
                        name: 'projects',
                        nameTransl: 'projects',
                        operator: 'eq',
                        operatorTransl: 'is',
                        value: `${decodeURIComponent(this.props.projects_name)} (${this.props.projects_project_id})`
                    }
                ],
                mode: 'advanced'
            }
        } else if (this.props.accounts_id) {
            if (!defineOptions.fields['customers'].search.includes(this.state.selectedType)) return undefined;
            return {
                advanced_search_criteria: {
                    filters: {
                        customers: [
                            {
                                operator: 'eq',
                                type: 'entity',
                                value: this.props.accounts_id
                            }
                        ]
                    }
                },
                currentFilters: [
                    {
                        bundledElements: [],
                        entityMode: true,
                        identifier: this.props.accounts_id,
                        isBundle: false,
                        name: 'customers',
                        nameTransl: 'customers',
                        operator: 'eq',
                        operatorTransl: 'is',
                        value: `${decodeURIComponent(this.props.accounts_name)}`
                    }
                ],
                mode: 'advanced'
            }
        }

        return undefined
    }

    componentDidMount() {
        super.componentDidMount();

        this.initDimensions(this.props.company);
        this.loadSettingsForCompanies(this.props.company);

        // this.updateAutoCompleteData(false, false, false, true);
        this.updateSavedQueries(this.props.company);
        this.listenReset();
    }

    componentWillUnmount(){
        this.unlistenReset();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.perpage !== nextState.perpage || this.state.page !== nextState.page)
            return false;

        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(prevState.filterValues, this.state.filterValues))
            this.setState({searchKey: this.state.searchKey + 1});

        if(prevProps.company !== this.props.company) {
            this.initDimensions(this.props.company);
        }
        if(prevProps.company !== this.props.company || prevProps.companyArray !== this.props.companyArray) {
            this.loadSettingsForCompanies(this.props.companyArray?.length > 0 ? this.props.companyArray : this.props.company);
        }        
    }    

    async initDimensions(company) {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
            this.setState({ 
                dimensions: [],
                dimensionItems: {},
                dimensionAutoCompleteData: {},
                dimensionAutoCompleteDataFilters: {},
            });

            return;
        }

        const data = await getDimensionAutoCompleteData(company);

        this.setState({ ...data }); 
    }

    async loadSettingsForCompanies(companies) {
        if (!companies)
            return false;

        companies = Array.isArray(companies) ? companies : [companies];

        const resp = await DataHandler.get({url: 'reports/companies_settings', ids: companies});

        this.setState(resp); 
    }    

    updateSavedQueries = () => {

        const {company, companyArray} = this.props;

        DataHandler.get({url: `reports/get_saved_queries`, querytype: this.state.selectedType, company: (companyArray?.length > 0 ? companyArray : company)}).done(
            response => {
                this.setState({savedQueries: [{id: '0', name: this.tr("Empty search")}, ...response] });
        });

    }

    createDefaultFilterValues = (tabContent, selectedType) => {

        let { defaultFilterValues } = defineOptions.reports[tabContent][selectedType];

        if (!defaultFilterValues || defaultFilterValues?.length < 1)
            return false;

        let defaults = {};
        defaultFilterValues.forEach((df, i)=> {
            if (df.value == "financial_year") {
                defaults[df.name] = {
                    startDate: this.financialYearRange[0],
                    endDate: this.financialYearRange[1],
                    key: "selection"                    
                }
            } else {
                if (df.type && df.name && df.value) {
                    defaults[df.type] = defaults[df.type] || {};
                    defaults[df.type][df.name] = df.value;
                }
            }
        });
        return defaults;       
    }

    loadSavedQuery = (evt) => {
        if (evt.nativeEvent?.target.tagName == 'svg' || this.state.selectedQuery == evt.target.value)
            return false;     

        const val = evt.target.value;
        if (val < 1) {
            this.resetFilters();
        } else {

            const selectedQuery = {...this.state.savedQueries.filter(item => item.id == val)[0]};
            if (!selectedQuery)
                return false;

            let query = JSON.parse(selectedQuery.querystring);

            //vanhoja hakuja varten, ettei haku ei kaatuisi backendissa
            if (!query.company)
                query.company = this.props[this.props.companyArray?.length > 0 ? 'companyArray' : 'company'];

            //koska voi olla vanhasta viallisesta koodista tallennettu tuplasti json.stringified dataa.
            let criteria;
            try {
                criteria = JSON.parse(query.advanced_search_criteria);
            } catch (e) {
                criteria = query.advanced_search_criteria;
            }
            this.searchTerms = {
                advanced_search_criteria: typeof query.advanced_search_criteria == 'object' ? query.advanced_search_criteria : criteria,
                freetextSearchTerm: query.freetext,
                mode: query.mode,
                currentFilters: query.currentFilters
            };

            let switches = {};
            let filterValues = {};

            Object.entries(this.filtersInitialValues.switches).map(([name, value]) => {
                if (query[name] !== 'undefined')
                    switches[name] = query[name];
            })
            Object.entries(this.filtersInitialValues.filterValues).map(([name, value]) => {
                if (query[name] !== 'undefined')
                    filterValues[name] = query[name];
            }) 

            this.setState(state => {
                state.selectedQuery = val;
                state.switches = {...this.filtersInitialValues.switches, ...switches};
                state.filterValues = {...this.filtersInitialValues.filterValues, ...filterValues}
                state.perpage = query.perpage || state.perpage;
                state.sort = query.sort;
                return state
            }, () => {
                this.props.onSwitchChange(this.state.switches);
                this.setState({searchKey: this.state.searchKey + 1});
                this.updateAutoCompleteData({company: query.company});
            });            
        }
    } 

    callSaveDialog = () => {
        this.setState({
            dialogData: {
                name: '',
                userId: '',
                saveFunc: 'saveSearchQuery'
            }
        });
        this.openDialog('saveSearch');
    }

    saveSearchQuery = (data) => {

        let queryString = this.collectParams({});

        queryString.startDate = queryString.endDate = undefined;
        if (queryString.advanced_search_criteria) {
            queryString.advanced_search_criteria = JSON.parse(queryString.advanced_search_criteria);
            queryString.currentFilters = this.searchTerms.currentFilters
        }

        const params = {
            name: data.name,
            is_private: data.isPrivate,
            querystring: queryString,
            querytype: this.state.selectedType,
            company: this.props.companyArray,
        }
        DataHandler.post({url: `reports/save_query`}, params).done(
            response => {
                this.closeDialog();
                this.updateSavedQueries();
        });            
    }

    callDeleteDialog = (evt, id) => {
        this.setState({
            dialogData: {
                id: id,
                saveFunc: 'deleteSavedQuery',
                text: this.dialogTexts.deleteSearch
            }
        });
        this.openDialog('deleteSearch');
    }

    deleteSavedQuery = (id) => {
        const query = this.state.savedQueries.find(q => q.id == id);
        const squery = this.state.selectedQuery;
        DataHandler.get({url: `reports/delete_query`, id: id}).done(
            response => {
                this.closeDialog();
                if(query.id == squery){
                    this.resetFilters(null);
                }
                this.updateSavedQueries();
        });        
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false});
    }

    saveDialog = (saveFunc, data) => {
        this[saveFunc](data);
    }
    
    handleCheck = (check) => {
        let checked = !check
        this.setState({checked: checked}, () => {
            this.updateAutoCompleteData();
        });
    }

    collectParams = (override, useLastParams) => {
        const { company, companyArray, companies } = this.props;
        const { tabContent, selectedType, filterValues, companySettings } = this.state;
        const currentReportOptions = defineOptions.reports[tabContent][selectedType];

        let parameters = { 
            page: 1, 
            perpage: this.state.perpage, 
            company: companyArray.length > 0 ? companyArray : company
        };
        let postParams = {};
        if (useLastParams) {
            parameters = this.lastGetParams;
            postParams = this.lastPostParams;
        } else {
            for (let gp in this.getParams)
                parameters[gp] = this.getParams[gp];
            if (this.searchTerms !== undefined && (this.searchTerms?.currentFilters?.length > 0 || (this.searchTerms?.freetextSearchTerm && this.searchTerms?.freetextSearchTerm != ''))) {
                parameters.mode = this.searchTerms.mode;
                if (this.searchTerms.mode == 'advanced') {
                    postParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
                } else {
                    parameters.freetext =this.searchTerms.freetextSearchTerm;
                }
            }
        }

        //tämä on tässä koska advancedsearch on hieman puutteellinen eikä osaa oikein käsitellä kun stringejä...poistetaan kun saataan muutos
        if (this.searchTerms && this.searchTerms.advanced_search_criteria?.filters?.user_type) {
            parameters['freelancerTerms'] = this.userTypes.find(t => t.id === this.searchTerms.advanced_search_criteria.filters.user_type[0].value).id;
        }

        for(let oi in override)
            parameters[oi] = override[oi];

        const {startDate, endDate} = this.state.dateRange;

        parameters['translations'] = this.translations;
        
//for autocomplete
        parameters['name'] = selectedType;
            
//for report data
        parameters['startDate'] = format(startDate, 'YYYY-MM-DD');
        parameters['endDate'] = format(endDate, 'YYYY-MM-DD');
        parameters['includeDescription'] = 0;

//virtual list scrolling
        if (currentReportOptions.useScrollPaging) {
            parameters['pagelimit'] = currentReportOptions.pageLimit;
            parameters['currentpage'] = parameters['currentpage'] || this.state.currentpage;
        }
//switch type parameters
        parameters = {...parameters, ...this.state.switches};

//account custom fields
        if (this.state.companySettings.account_custom_fields?.length > 0 && currentReportOptions.useVirtualized) {
            parameters['account_custom_fields'] = companySettings.account_custom_fields.map(f => ({id: f.id, name: f.name.toLowerCase()}));
        }

//raportin valuutta
        parameters['report_currency'] = this.props.currency;
        if (this.context.addons.invoice_currency) {
            const currencyCompanies = companyArray.length > 0 ? companyArray : [company];
            let currency_rates = currencyCompanies.reduce((acc, cc) => ({
                ...acc, ...{[cc]: this.props.currencies?.find(pc => pc.companies_id == cc)?.rates.find(cr => cr.label == this.props.currency)?.rate} 
            }), {});
            currency_rates = Object.entries(currency_rates).reduce((acc, [k, v]) => ({...acc, ...(v !== 1 ? {[k]: v} : {})}), {});
            parameters['currency_rates'] = Object.keys(currency_rates).length > 0 ? currency_rates : undefined;
            parameters['company_currency'] = this.context.taimerAccount.currency; 
        }

        if(selectedType == "customers_daily" && this.state.checked == true){
            parameters['includeDescription'] = 1;
        }

        this.lastGetParams = parameters;
        this.lastPostParams = postParams;

        return {...parameters, ...postParams, ...filterValues};
      
    }

    //jos päivityksessä pysyy sama raporttityypi niin initial == false ohittaa autocomplete datan haun
    updateAutoCompleteData = (override = {}, useLastParams = false, savedParams = false, initial = false) => {

        let params = savedParams || this.collectParams(override, useLastParams);
        const {tabContent, selectedType} = this.state;

        if (initial) {
            let in_search = allFields().reduce( (acc, e) => {
                if (e.search.includes(selectedType))
                    acc.push(e.name);
                return acc;
            }, []);

            const currentReportOptions = defineOptions.reports[tabContent][selectedType];

            //jos on raporttikohtaisia suodattimia jotka tarttee dataa niin ne lisätään erikseen
            const dataForFilters = currentReportOptions.filters?.reduce((acc, f) => {f.dataClass && acc.push(f.dataClass); return acc}, []) || [];

            //ja pakko on alkaa hakeaa data erikseen suodattimiin ja advanced searchiin kun alkaa olla jo sekunnitolkulla haettavaa
            const filters = [...dataForFilters, ...(currentReportOptions.dynamicColumnsDefinitions ? [...Object.values(currentReportOptions.dynamicColumnsDefinitions)] : [])];
            //TODO! refine what autocomplete should have deleted stuff?
            const deleted = {branch_of_business: selectedType == 'bills_by_category', users: true};
            DataHandler.post({url: `reports/autocomplete_data`}, {...params, in_search: filters, deleted})
                .done(response => {
                    if (response.funnels)
                        response.funnels = [...response.funnels, {id: "-1", name: this.tr("Won deal")}, {id: "-5", name: this.tr("Internal")}];
                        const userTagProps = {
                            fields: {name: 'name'},
                            showLocked: this.props.showLockedUsersWithTag,
                            transl: this.translations
                        };                        
                        Object.keys(response).forEach(k => {
                            if (this.userTypeAutocompleteClasses.includes(k)) {
                                response[k] = response[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                            }
                        })
                    this.setState({autoCompleteData: response}, () => {

                        deleted.users = false;
                        DataHandler.post({url: `reports/autocomplete_data`}, {...params, in_search, deleted})
                            .done(response => {
                                if (response.hasFreelancers)
                                    response.user_type = this.userTypes;
                                if (response.funnels){
                                    response.funnels = [...response.funnels, {id: "-1", name: this.tr("Won deal")}, {id: "-5", name: this.tr("Internal")}];
                                }

                                Object.keys(response).forEach(k => {
                                    if (this.userTypeAutocompleteClasses.includes(k)) {
                                        // const userTagProps = {
                                        //     fields: {name: 'name'},
                                        //     showLocked: this.props.showLockedUsersWithTag,
                                        //     transl: this.translations
                                        // };
                                        response[k] = response[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                                    }
                                })                                

                                this.setState({autoCompleteData: {...this.state.autoCompleteData, ...response}});
                            });                        
                    });
                });             
        }

        this.fetchData(override, useLastParams, savedParams, params);

        this.updateSavedQueries();

    }    

    fetchData = (override = {}, useLastParams = false, savedParams = false, params = false, buttonFetch) => {

        if (this.props.fetchButton && !buttonFetch)
            return;

        if (!params)
            params = savedParams || this.collectParams(override, useLastParams);

        params.user = this.context.userObject;

        if(this.state.selectedType == "hours_export" || this.state.selectedType === 'chief_reports') {
            return;
        }

        DataHandler.post({url: `reports/${this.state.selectedType}`}, params)
            .done(response => {
                this.setData(response);
                this.setState({dataIsFetching: false, dataIsSet: true});
            });
        
    }

    fetchAppendableData = (override = {}, useLastParams = false, savedParams = false, params = false,) => {

        if (!params)
            params = savedParams || this.collectParams(override, useLastParams);

        params.user = this.context.userObject;
        params.sort = this.appendableSort;

        return DataHandler.post({url: `reports/${this.state.selectedType}`}, params);
        
    }    

    setData = (resp) => {
        if(this.list.current && (resp === undefined || resp.length === 0)) {
            this.list.current.setState({data: []})
            return;
        }

        let { data, pageCount, reportCount, pageTotal, grandTotal, pageLimitDifference } = resp;
        pageTotal = Number(pageTotal);
        grandTotal = Number(grandTotal);
        this.getParams.sort = {};
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.state.autoCompleteData?.users
        };
        data?.length > 0 && data.forEach((p, i) => {
            if (Object.keys(p).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {         
                data[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            }
        })

        this.setState({
            data: data === undefined || data === 0 ? [] : data,
            sort: {},
            pageCount: pageCount || 1,
            reportCount: reportCount || 0,
            pageTotal: pageTotal,
            queryTotal: grandTotal,
            filterValues: {...this.state.filterValues, pageLimitDifference: this.state.filterValues.pageLimitDifference + pageLimitDifference},
            // sort: {}
        }, () => this.props.updateView(this.state.pageTotal, this.state.queryTotal, this.state.selectedType, resp.summaries ?? {}, this.state.reportCount));
    }

    sortRows = (columnName, isAscending) => {
        let sortObj = {name: columnName, asc: isAscending};
        if (this.state.sort == sortObj)
            return;
        this.setState({
                selectedQuery: undefined, /*data: [],*/ 
                dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true, 
                sort: sortObj, 
                currentpage: 1,
                filterValues: {...this.state.filterValues, pageLimitDifference: 0}
            }, () => {
            // this.state.sort = sortObj;
            this.getParams.sort = this.appendableSort = sortObj;
            this.fetchData({}, false, false, false, true);
        });
    }

//selects the report type
    changeReportType = (selectedType) => {

        this.setState({selectedQuery: undefined, data: [], dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true}, () => {

            if (this.state.selectedType == selectedType)
                return;

            const { tabContent } = this.state;
            const defaultFilterValues = this.createDefaultFilterValues(tabContent, selectedType);

            //we must empty the multicompanies prop each time we change report type because that's the priority query companyids source
            this.props.onCompanyChange([]);

            let newState = cloneDeep(this.state);

            //jos liikkuu kannattavuusraporttien välissä niin filtterit pysyy 
            if (tabContent !== 'profitabilityReports') {
                newState.filterValues = {...this.filtersInitialValues.filterValues, ...defaultFilterValues.filterValues};
                newState.switches = {...this.filtersInitialValues.switches, ...defaultFilterValues.switches};
            }
            // if (!['profitabilityReports', 'recognitionReports', 'forecastReports'].includes(tabContent))
            //     this.searchTerms = undefined;

            let x = this.list?.current?.setPage(1);



            this.setState({
                ...newState,
                selectedType,
            }, () => {
                this.props.onSwitchChange(this.state.switches);
                this.updateAutoCompleteData(false, false, false, true);
            });

        });

    }

    changeFilter = (name, value) => {
        if (this.state[name] == value)
            return;
        this.setState({[name]: value, selectedQuery: undefined}, () => this.fetchData());
    }

    changeIndividualFilter = (name, value) => {
        if (this.state.filterValues[name] == value)
            return;
        let additionalFilterValues = {};

        //jos tarttee muuttaa suodattimissa muutakin kun itseensä, eli poikkeudet
        if (name == 'financialYearFilter')
            additionalFilterValues = {financialYearRange: this.state.autoCompleteData.financial_years?.find(fy => fy.id == value).range};

        let data = this.state.data;
        if (['groupByFilter'].includes(name))
            data = [];

        this.setState({
            data: data,
            filterValues: {...this.state.filterValues, [name]: value, ...additionalFilterValues}, 
            selectedQuery: undefined,
            dataIsSet: false,
            /*data: [],*/ 
            dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true
        }, () => this.fetchData());
    }

    onSwitchChange = (name, checked) => {
        const autocompleteFilterConditions = defineOptions.reports[this.state.tabContent][this.state.selectedType].switches.find(sw => sw.id == name)?.filterConditions;
        let filterValues = {...this.state.filterValues};
        let autoCompleteDataFilters = {...this.state.autoCompleteDataFilters};
//todo - array for multiple filterconditions under one switch
        if (autocompleteFilterConditions) {    
            const filterFunction = (autoCompleteData) => ({
                ...autoCompleteData, 
                ...(checked ? {[autocompleteFilterConditions.dataClass]: autoCompleteData[autocompleteFilterConditions.dataClass]?.filter(f => autocompleteFilterConditions?.operator(f))} : {})
            });
            //this check currently recognizes only multiselect filters (for those will be most likely needed only)
            let filterValue = this.state.filterValues[autocompleteFilterConditions?.filter];
            if (checked) {
                if (filterValue?.[0] !== '0') {
                    let newFilterValue = filterValue.filter(n => autocompleteFilterConditions?.operator(this.state.autoCompleteData[autocompleteFilterConditions.dataClass].find(d => d.id == n)));
                    if (newFilterValue.length !== filterValue.length) {
                        filterValue = newFilterValue;
                        this.props.enqueueSnackbar(this.tr(autocompleteFilterConditions.alertText), {
                            variant: "warning"
                        });
                    }
                }

                let relevantTerms = this.searchTerms?.advanced_search_criteria?.filters[autocompleteFilterConditions.dataClass];
                let relevantFilters = this.searchTerms?.currentFilters;
                if (relevantTerms) {
                    relevantTerms = relevantTerms.filter(t => (t.operator == 'eq' && autocompleteFilterConditions?.operator(this.state.autoCompleteData[autocompleteFilterConditions.dataClass].find(d => d.id == t.value)) || t.operator != 'eq'));
                    relevantFilters = relevantFilters.filter(c => c.name == autocompleteFilterConditions.dataClass && autocompleteFilterConditions?.operator(this.state.autoCompleteData[autocompleteFilterConditions.dataClass].find(d => d.id == c.identifier)));
                    this.searchTerms.advanced_search_criteria.filters[autocompleteFilterConditions.dataClass] = relevantTerms;
                    this.searchTerms.currentFilters = relevantFilters;
                    this.setState({searchKey:this.state.searchKey + 1});
                }                
            }
            filterValues = {...this.state.filterValues, [autocompleteFilterConditions.filter]: filterValue};
            autoCompleteDataFilters = {...this.state.autoCompleteDataFilters, [`switches_filter_${name}`]: filterFunction};
        }

        const dataResetSwitchTypes = ['view_quantity', 'show_projects', 'hide_projects', 'show_subunits', 'show_projects_grouped', 'show_division', 'show_grouped_by_main_customer'];
        let {data} = this.state;
        if (dataResetSwitchTypes.includes(name))
            data = [];

        this.setState({
            data: data,
            switches: {...this.state.switches, [name]: !this.state.switches[name]},
            dataIsSet: false, 
            dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true, 
            selectedQuery: undefined,
            filterValues: filterValues,
            autoCompleteDataFilters: autoCompleteDataFilters
        }, ()=> {
            this.props.onSwitchChange(this.state.switches); 
            this.fetchData();           
        });
    }

    onDateRangeChange = (event) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            },
        }, () => this.fetchData());

    }

    onDateRangeInputChange = (dateType, date) => {

        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                },
            }, () => this.fetchData());
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                },
            }, () => this.fetchData());
        }
    }


    onDateChange = (date, name = 'day') => {

        date = format(date, 'YYYY-MM-DD');

        this.setState({
            filterValues: {...this.state.filterValues, [name]: date}, 
            /*data: [],*/ 
            dataIsSet: false,
            dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true,
            selectedQuery: undefined
        }, () => this.fetchData());

    }

    onDateInputChange = (dateType, date, name = 'day') => {

        date = format(date, 'YYYY-MM-DD');

        this.setState({
            filterValues: {...this.state.filterValues, [name]: date}, 
            /*data: [],*/
            dataIsSet: false,
            dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true,
            selectedQuery: undefined
        }, () => this.fetchData());

    }

    listenReset() {
        document.body.addEventListener("keyup", this.resetFilters);
    }
 
    unListenReset() {
        document.body.removeEventListener("keyup", this.resetFilters);
    }
 

    resetFilters = (evt, resetDates = true) => {
		if (evt == null || evt.keyCode == '27') {
			this.getParams = {};
			this.searchTerms = this.getInitialSearchTerms();

            let { dateRange } = resetDates ? this.filtersInitialValues : this.state;

            //jos raportille on määritelty omia default suodatinarvoja
            const { tabContent, selectedType } = this.state;
            const defaultFilterValues = this.createDefaultFilterValues(tabContent, selectedType);

            let newState = cloneDeep(this.state);
            newState.currentpage = 1;
            newState.filterValues = {...this.filtersInitialValues.filterValues, ...defaultFilterValues.filterValues};
            newState.switches = {...this.filtersInitialValues.switches, ...defaultFilterValues.switches};
            newState.autoCompleteDataFilters = {};
            newState.dateRange = {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                key: "selection"                    
            };
            newState.selectedQuery = undefined;

            //tuoteraportteja varten vielä...
            let x = this.list?.current?.setPage(1);

            this.setState({...newState}, () => {
                this.props.onSwitchChange(this.state.switches);
                if (!this.props.projects_id && !this.props.accounts_id) {
                    this.advancedSearch.current && this.advancedSearch.current.clearSearch(evt, true);
                }
                this.updateAutoCompleteData(false, false, false, true)
            });
		}
    }

    exportHoursUsers = (target) => {
        this.setState({ exportingHoursUsers: true }, () => {
            const {startDate, endDate} = this.state.dateRange;
            const dateRange = `${moment(startDate).format(this.context.userObject.dateFormat)} - ${moment(endDate).format(this.context.userObject.dateFormat)}`;
            const title = this.tr("Hours users");
            const filename = `${title}_${dateRange}.${target}`.toLowerCase().replace(/ /g, "_").replace(/ä/g, "a").replace(/ö/g, "o").replace(/å/g, "a");
            DataHandler.getArrayBuffer({url: `reports/hours_users_export`, filename, title, start: startDate, end: endDate, dateRange, company: this.props.company, target}).done((response) => {
                let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
                if (target == 'csv') {
                    type = 'text/csv';
                }
                const blob = new Blob([response], {
                    type
                });
                FileSaver.saveAs(blob, filename);
                this.setState({ exportingHoursUsers: false });
            }).catch(() => {
                this.setState({ exportingHoursUsers: false });
            });
        })
    }

    getHourExportIntegrations = (company) => {
        let response = [];

        if (!this.context.addons)
            return response;
        
        const allHourIntegrations = this.hourExportIntegrations;
        const addons = allHourIntegrations.map(e => e.value);
        if (addons.length == 0)
            return response;

        _.each(this.context.addons, function(e, i) {
            if (!addons.includes(i))
                return;
            
            if (e.used_by_companies.indexOf(company) > -1) {
                response.push({value: i, label: allHourIntegrations.find(e => e.value == i).label});
            }
        });

        return response;
    }

    countHourExportIntegrations = (company) => {
        let count = 0;

        if (!this.context.addons)
            return count;
        
        const addons = this.hourExportIntegrations.map(e => e.value);
        if (addons.length == 0)
            return count;

        _.each(this.context.addons, function(e, i) {
            if (!addons.includes(i))
                return;
            
            if (e.used_by_companies.indexOf(company) > -1)
                count++;
        });

        return count;
    }

    filterOptions = (options) => {
        if (!options)
            return undefined;
        return options.filter(o => {
            if (!o.optionConditions)
                return true;
            let conds = [];o.optionConditions.forEach(oc => console.log(oc, this.state[oc.type]?.[oc.name]));
            o.optionConditions.forEach(oc => conds.push(this.state[oc.type]?.[oc.name] > 0 || this.props[oc.type]?.[oc.name] > 0));
            return conds.every(e =>  e);
        });
    } 

    render () {
        
        if (!this.state.tabContent)
            return null;

        const { tabContent, selectedType, dateRange, autoCompleteData, currentDialog, dialogData, savedQueries, selectedQuery, 
            pageCount, reportCount, perpage, checked, dataIsFetching, filterValues, exportingHoursUsers, companySettings: {account_custom_fields} } = this.state;
        let {data} = this.state

        const { userObject, privileges, taimerAccount: { hasEnterpriseGroups }, functions: { getFinancialYear } } = this.context;
        const { hourSymbol } = userObject;
        const { classes, companies, company, companyArray, multiCompany, currency, currencySymbol } = this.props;
        const { tr } = this;

	//raporttilajin yksittäiset raportit 
        const reportTypes = currentReports(tabContent).map( r => {
                return {value: r.id, label: this.tr(r.name), tableWidth: r.table_width};
        });

//aktiivisen raportin ominaisuudet
        const currentReportOptions = defineOptions.reports[tabContent][selectedType];

        // Column save key
        const keyParts = [];
        
        if (currentReportOptions?.getColumnSaveKey) {
            const parts = currentReportOptions?.getColumnSaveKey?.(filterValues);

            if (parts) {
                for (const iterator of parts) {
                    keyParts.push(iterator);
                }
            }
        }

//raportin sarakkeet.        
        const columnOrder = [...currentReportOptions.column_order];

        const fields = allFields().map(f => ({ ...f, field: f.name, header: this.tr(f.header) }));

        let accountColumnIndex = null;
        let accountCustomColumns = [];

        let columns = columnOrder.map( (column, j) => {
            if (selectedType === 'hour_balance' && Date.parse(filterValues.startDay) && column === 'previous_balance')
                return false;

            if (column === "enterprise_groups" && !hasEnterpriseGroups)
                return false;

            const field = fields.find( field => field.name == column);
            if (!field)
                return false;

            //sarakemäärittelyn columnfilterin avulla voitaan määritellä dynaamisesti, jos joku sarake näkyy raporttilajissa vaan tietyllä filtterilla
            const conditions = field.filterConditions?.filter(f =>  f.type == selectedType)?.reduce((acc, v) => {v.filters.forEach(e => acc[e.name] = e.value); return acc}, {});

            //tarksitetaan state juuressa olevat suodattimet + switches & filterValues + props
            let conditionsCheck = false;
            if (Object.entries(conditions || {}).every(([name, value]) => Array.isArray(value) ? 
                value.includes(this.state[name]) || value.includes(this.state.filterValues[name]) || value.includes(this.state.switches[name])
                :
                this.state[name] == value || filterValues[name] == value || this.state.switches[name] == value || this.props[name] == (tryParseJSONObject(value) || value))
                )
                conditionsCheck = true;

            if (!conditionsCheck)
                return false;

            if (field.tooltipText)
                field.tooltipContent = this.tr(field.tooltipText);

            //report type specific tooltip, overrides default if needed
            if (currentReportOptions.tooltipTexts?.length > 0) {
                const tContent = currentReportOptions.tooltipTexts.find(tt => tt.column == column)?.text;
                field.tooltipContent = tContent && this.tr(tContent);
            }

            //Oletuksena on kaikki otsikkoominaisuudet päällä, mut ne saa otsikkokohtaisesti pois
            if (field.removedHeaderProperties)
                field.removedHeaderProperties.forEach(hprop => field[hprop] = false);

            if (currentReportOptions.isNotSortable)
                field.sortable = false;

            //virtualisoitut listat, tulee jatkossa olemaan kaikilla
            if (currentReportOptions.useVirtualized) {
                field.presentation = currentReportOptions.presentation[field.name];
                //create custom columns
                let customColumnName = selectedType == 'bills_by_supplier' ? 'supplier' : 'customers';
                if (column === customColumnName && account_custom_fields?.length > 0) {
                    accountColumnIndex = j+1;
                    accountCustomColumns = account_custom_fields.map(acf => {
                        const nameString = acf.name?.replaceAll(' ', '_').toLowerCase();
                        return {
                            field: nameString,
                            fieldType: 'text',
                            header: this.props.companyArray?.length > 1 ? `${acf.name} (${this.props.companies.find(c => c.id == acf.companies_id)?.name})` : acf.name,
                            name: nameString,
                            presentation: (data, props, listRef, sharedData) => data[nameString],
                            width: 160                        
                        }
                    });
                }
            }

            return field;
        
        }).filter(col => col);

        accountCustomColumns?.length > 0 && accountColumnIndex > 0 && accountCustomColumns.forEach((ac, i) => {
            columns.splice(accountColumnIndex, 0, ac);
            accountColumnIndex ++;
        });


//jos dynaamisia sarakkeita niin pistetään placeholderin tilalle autocomplete vastaava data (huom! toistaiseksi presentation hardkoodattu currency)
        if (currentReportOptions.dynamicColumnsDefinitions) {
            Object.entries(currentReportOptions.dynamicColumnsDefinitions).forEach(([originalColumn, dataClass]) => {
                const index = columns.findIndex(c => c.name == originalColumn);
                if (index < 0)
                    return;
                const baseColumn = cloneDeep(columns[index]);
                let dc = this.state.autoCompleteData[dataClass]?.length > 0 ? cloneDeep(this.state.autoCompleteData[dataClass]) : undefined;
                let insertColumns = undefined, headers = undefined;
                let op;
                if ((op = currentReportOptions.dynamicColumnHeaderOperators?.find(o => o.definition == originalColumn)) && dc) {
                    let reorderedDataClass = (op.reorderToTreeStructure ? Utils.treeFormatDataForList(dc).map(dc => dc.data) : dc).map(r => ({...r, name: `${r.name} (${r.id})`}));

                    if (op.filterByDataValues) {
                        const existingDataColumns = [...new Set(data.reduce((acc, d) => [...acc, ...Object.keys(d)], []))];
                        reorderedDataClass = reorderedDataClass.filter(r => existingDataColumns.includes(r.name));
                    }

                    const {processedHeaders, reorderedIds} = op.operator(reorderedDataClass, this.translations);
                    headers = processedHeaders?.reverse();
                    reorderedDataClass = reorderedIds.map(id => reorderedDataClass.find(dc => dc.id == id));
                    dc = reorderedDataClass;
                }
                if (dc === null)
                    return false;

                if (typeof dc?.[0] === 'string') {
                    insertColumns = dc?.reverse();
                }
                else {
                    insertColumns = dc?.map(c => c.name).reverse();
                }

                insertColumns && insertColumns.forEach((nc, i) => {
                    const newColumn = {...baseColumn, name: nc, header: (headers ? headers[i] : this.tr(nc)) || this.tr(nc), presentation: (data, props, listRef, sharedData) => [sharedData[sharedData.getOptionalPresentation(data, {field: 'free_summary_text', fieldValue: 'win rate', trueFunctionName: 'presentPercentage', falseFunctionName: 'presentCurrency'})](data[nc]), { align: "right"}]};
                    columns.splice(index, (i==0 ? 1 : 0), newColumn);

                    keyParts.push(nc);
                })
            })

        }

//hakukentät advanced searchia varten
        const searchColumns = allFields().reduce( (searchFields, e) => {
            if (e.name == 'stages' && ([['-1'], ['-5'], ['-1', '-5'], ['-5', '-1']].some(a => isEqual(filterValues.funnelFilter, a))))
                return searchFields;
            if (e.search.includes(selectedType) && 
                    (((e.name !== "user_type" && e.name !== "enterprise_groups" && e.name !== "division") || (autoCompleteData.hasFreelancers && e.name == "user_type")) || (e.name == "enterprise_groups" && hasEnterpriseGroups) || (e.name == "division" && this.state.switches.show_division))) {    

                const treeString = ["reporting_group", "branch_of_business"].includes(e.name) && companyArray.length > 1 ? 'list' : 'tree';

                searchFields.push({
                    field: e.name,
                    transl: this.tr(e.header),
                    type: e.searchFieldType || 'text',
                    visualizationType: ["projects", "customers", "reporting_group", "branch_of_business"].includes(e.name) ? treeString : "list",
                    entityMode: e.hasOwnProperty("entityMode") && e.entityMode, 
                    filterConditions: e.isFilteredBy,
                    noSort: e.noSort
                });
            } 
            return searchFields;
        }, []);

        createAdvancedSearchFieldsForDimensions(this.state.dimensions)
            .forEach(f => searchColumns.push(f));

        if (account_custom_fields?.length > 0 && currentReportOptions.useVirtualized) {
            accountCustomColumns.forEach(ac => {
                searchColumns.push({
                    field: ac.field?.replaceAll('_', ' '),
                    transl: ac.header,
                    type: 'text',
                    visualizationType: "list",
                    entityMode: false,
                    noSort: true
                })
            });
        }            

//advanced search autocomplete data, mahdolliset suodatukset jnejne. Toistaiseksi katsoo vaan filtterit ei switcheja
        const returnSearchAutocompleteData = (forFilters) => {
            const filterableFields = searchColumns.filter(sf => sf.filterConditions).map(s => s.field);

            let overwrite = {};
            Object.entries(autoCompleteData).map(([dataClass, data]) => {
                if (filterableFields.includes(dataClass)) {
                    const conditions = searchColumns.find(f => f.field == dataClass).filterConditions;
                    const test =  conditions.reduce((acc, c) => {
                        const filterValue = filterValues[c.filterName];
                        if (Array.isArray(filterValue)) {
                            if (filterValue[0] == '0')
                                return acc;
                            else
                                return acc.filter(d => filterValue.includes(d[c.filterBy]));
                        } else
                            return acc.filter(d => d[c.filterBy] == filterValues[c.filterName]);
                    }, data);
                    overwrite[dataClass] = test;
                }
            });
            let filteredAutocomplete = {...autoCompleteData, ...overwrite};

            if (this.state.autoCompleteDataFilters)
                Object.values(this.state.autoCompleteDataFilters).forEach(f => filteredAutocomplete = f(filteredAutocomplete));

            if (forFilters)
                return {...filteredAutocomplete};

            filteredAutocomplete.branch_of_business = filteredAutocomplete.branch_of_business ? filteredAutocomplete.branch_of_business.filter(bob => ((!bob?.deleted || (bob?.deleted && bob.deleted < 1)) && bob?.locked < 1 )) : filteredAutocomplete.branch_of_business;

            return {...filteredAutocomplete,
                projects:           [filteredAutocomplete.projects, "parent_id"],
                branch_of_business: companyArray.length > 1 ? filteredAutocomplete.branch_of_business : [filteredAutocomplete.branch_of_business, "parent_id"],
                customers:          [filteredAutocomplete.customers, "parent_id"],
                reporting_group:    companyArray.length > 1 ? filteredAutocomplete.reporting_group : [filteredAutocomplete.reporting_group, "parent_id"],
                ...this.state.dimensionAutoCompleteData
            }

        }

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        const sumText = this.tr(currentReports(tabContent).find(c => c.id == selectedType).sumText);

        const rowProps = {
            rowDisplay: 'flex',
            //nämä lähtee helvettiin kun saataan kaikki raportit virtualisoituksi:)
            symbols: {
                total: {
                    prefix: 'Total', color: "#2c3547"
                },
                hour: {
                    suffix: (hourSymbol ? hourSymbol : "h"),
                    customerTotal: {
                        prefix: tr('Customer total') + ":", color: "#2c3547"
                    },
                    projectTotal: {
                        prefix: tr('Project total') + ":", color: "#2c3547"
                    },
                    employeeTotal: {
                        prefix: tr('Employee total') + ":", color: "#2c3547"
                    },
                    total: {
                        prefix: sumText + ":", color: "#2c3547"
                    },
                    Total: {
                        prefix: tr("Total") + ":", color: "#2c3547"
                    },                    
                    hourbalancetotal: {
                        prefix: sumText + ":", color: "#2c3547", textAlign:"right"
                    }
                },
                percentage: {
                    suffix: "%",
                    "Total": {
                        prefix: tr("Total") + ":", color: "#2c3547"
                    },
                    "Total_different": {
                        prefix: tr("Total vat different") + ":", color: "#2c3547"
                    },
                    "Total_vat": {
                        prefix: tr("Total vat") + "%:", color: "#2c3547"
                    },                                       
                }
            },
            showDescription: this.state.checked,
            currentReport: selectedType,
            currency: this.props.currency,
            sumText: sumText
        };

        const filterProps = {
            filterButton: classNames(classes.filterButton),
            halfFilterButton: classNames(classes.halfFilterButton),
            labels: {
                user: this.tr("By user"), 
                nameType: this.tr("Customer's name"),
                approve: this.tr("By approvement"), 
                startDate: this.tr("Start date (optional)"),
                date: this.tr("Date"),
                invoicedFilter: this.tr("Choose by invoiced status"),
                customerFilter: this.tr("By customer"),
                projectFilter: this.tr("By project"),
                financialYearFilter: this.tr("Financial year"),
                funnelFilter: this.tr("By funnel"),
                scheduledType: this.tr("By type"),
                groupByFilter: this.tr(currentReportOptions.filters?.find(f => f.name == 'groupByFilter')?.label || this.tr("Group by")),
            },
            values: {...filterValues},
            options: {
                ...returnSearchAutocompleteData(true), 
                customerNameTargets: this.customerNameTargets, 
                productInvoiceStatusOptions: this.productInvoiceStatusOptions, 
                approvedOptions: this.approvedOptions, 
                scheduledTypes: this.scheduledTypes,
                groups: this.filterOptions(currentReportOptions.groupByOptions)?.map(fo => ({...fo, label: this.tr(fo.label)})),
            },
            onFilterChange: this.changeIndividualFilter,
            onDateChange: this.onDateChange,
            onDateInputChange: this.onDateInputChange,
            dateFormat: userObject.dateFormat,
            selectedType: selectedType,
            selectedQuery: selectedQuery,
            onMultiselectFilterMenuChange: (openStatus) => this.setState({filterSearchOpen: openStatus})
        };
        const currentIndividualFilters = currentReportOptions.filters?.filter(f => 
            f.filterConditions ? this.state[f.filterConditions.type][f.filterConditions.name] == f.filterConditions.value : true
        );

        const tableWidth = reportTypes.filter(t => t.value == selectedType)[0]?.tableWidth || 0;
        //this is here because Multiselect's defaultValue is set before we even get companies responce. So we must render it again. 
        const multiCompanyDefaults = companyArray.length > 0 ? companies.filter(c => companyArray.includes(c.id)) : (company ? [companies.find(cc => cc.id == company)] : []);
        const hourExportIntegrations = this.getHourExportIntegrations(company);
        const exportIntegration = !this.state.exportIntegration ? (hourExportIntegrations[0] ? hourExportIntegrations[0].value : null) : this.state.exportIntegration
//todo - some settings?

        let orderedColumns = columns;

        const addTotalsData = (data) => {
            return data?.map(row => {

                if (selectedType === "projects_backlog" && row.id !== "total") {
                    // row[]

                    // filterValues.groupBy
                    if (filterValues.groupBy === "pipeline") {
                        if (row.pipeline_id == -1) {
                            row.groupTitle = this.tr('won deals');
                        } else if (row.pipeline_id == -5) {
                            row.groupTitle = this.tr('Internal');
                        } else {
                            row.groupTitle = row.pipeline_name;
                        }
                    } else if (filterValues.groupBy === "pipeline_stage") {
                        if (row.pipeline_id == -1) {
                            row.groupTitle = `${this.tr('won deals')} - ${row.stage_name || '-'}`;
                        } else if (row.pipeline_id == -5) {
                            row.groupTitle = `${this.tr('Internal')} - ${row.stage_name || '-'}`;
                        } else {
                            row.groupTitle = `${row.pipeline_name || '-'} - ${row.stage_name || '-'}`;
                        }
                    }
                }

                //jos rivityyppi on 'total' (ensimmäinen ja viimeinen rivi), niin ensimmäinen sarake saa raporttikohtaisen arvon)
                if(row._type == 'total') {
                    row.totalTitle = row.totalTitle || currentReportOptions.sumText;
                    row[orderedColumns[0].name] = `${this.tr(row.totalTitle)}:`;
                }

                if(["sub-total_1", "sub-total_2"].includes(row._type)) {

                    //poikkeus (huono ratkaisu mutta en mitenkään haluaa alkaa simplelistin renderöintiin noita poikkeuksia laittamaan...)
                    if (selectedType === "employees_daily" && row._type == 'sub-total_1') {
                        row[orderedColumns[0].name] = `
                            ${this.tr('estimated days')}: ${Number(row.estimated_days || '0').toFixed(2)} ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}
                            ${this.tr('estimated hours')}: ${Number(row.estimated_hours || '0').toFixed(2)}  ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}
                            ${this.tr('total hours')}: ${Number(row.total_hours || '0').toFixed(2)}  ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}   
                            ${this.tr('total days')}: ${Number(row.total_days || '0').toFixed(2)}  ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}   
                            ${this.tr('Overtime hours')}: ${Number(row.overtime || '0').toFixed(2)}  ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}   
                            ${this.tr('flexitime')}: ${Number(row.flexitime || '0').toFixed(2)}  ${'\u00A0\u00A0\u00A0\u00A0\u00A0'}
                        `
                    } else {
                        const i = orderedColumns.findIndex(c => c.name == row.titleField);
                        if (orderedColumns[i-1])
                            row[orderedColumns[i-1].name] = `${this.tr(row.totalTitle)}:`;                                      
                    }
                }

                if(selectedType === "invoices_summary" && (row._type === "total" || row._type === "vat_total")) {
                    row.totalTitle = this.tr(row.totalTitle);
                    row.bill_id    = row.totalTitle;
                }

                return row;
            });        
        }

        const infiniteScrollStatus = currentReportOptions.useScrollPaging && (!this.searchTerms || (this.searchTerms?.currentFilters?.length < 1 && !this.searchTerms?.freetextSearchTerm)) && !currentReportOptions.filters?.some(o => this.state.filterValues[o.name][0] > 0);

        if (infiniteScrollStatus)
            columns = columns.map(c => ({...c, sortable: false}));
           
        const subreportKey = keyParts.join('|');

        return (
            <div id="reports-tab">
                <div className="reports-filters-container">
                <div className={`${classNames(classes.actionContainer)} actionContainer header-container primary`} >
                    {this.state.selectedType == "projects_backlog" && <OutlinedField
                        className={classNames(classes.filterButton)}
                        name="groupBy"
                        select
                        label={this.tr("Group by")}
                        value={filterValues.groupBy}
                        onChange={(e) => this.changeIndividualFilter("groupBy", e.target.value)}>
                        {currentReportOptions.groupByOptions.filter(option => option.condition === undefined || option.condition(this.state.autoCompleteData)).map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {this.tr(option.label)}
                            </MenuItem>
                        ))}
                    </OutlinedField>}

                        {multiCompany && 
                            (currentReportOptions.multiCompanyQueries ?
                        <MultiSelect
                                ref={this.companySelectRef}
                                className={classNames(classes.filterButton)}
                                key={`${selectedType}-companyFilter-${multiCompanyDefaults.join()}`}  
                                disabled={companies.length < 2}
                                options={companies?.map(c => ({value: c.id, label: c.name}))}
                                name="companyFilter"
                                defaultValue={multiCompanyDefaults}
                                label={this.tr("Choose company")}
                                showSingleValueInCaseOfOnlyOneOption={true}
                                onChange={(data) => {
                                    const ids = data[0].value == 0 ? companies.map(cm => cm.id) : data?.reduce((arr, d) => [...arr, d.value], []);
                                    if ((companyArray.length < 1 && data.length == 1 && data[0].id == company) || (ids.length == companyArray.length && ids.every(d => companyArray.includes(d))))
                                        return false;
                                        this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true}, () => this.props.onCompanyChange(ids, selectedType));
                                }}
                            />
                        :
                        <OutlinedField
                            className={classNames(classes.filterButton)}
                            name="companyFilter"
                            select
                            disabled={companies.length < 2}
                            label={this.tr("Choose company")}
                            value={company}
                            onChange={ (e) => e.target.value != company && this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true}, () => this.props.onCompanyChange(e.target.value, selectedType)) } >

                            {companies.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </OutlinedField>)}

                        {this.state.selectedType == "hours_export" && <OutlinedField
                            className={classNames(classes.filterButton)}
                            name="companyFilter"
                            select
                            disabled={this.countHourExportIntegrations(company) < 2}
                            label={this.tr("Export hours to")}
                            value={exportIntegration}
                            onChange={(e) => this.setState({exportIntegration: e.target.value})} >
                            {hourExportIntegrations.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </OutlinedField>}

                        {!currentReportOptions.hideDateRange &&
                            <DateRangePicker
                                className="daterange"
                                ranges={[dateRange]}
                                onChange={(e) => this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true}, () => this.onDateRangeChange(e))}
                                onInputChange={(dateType, date) => this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true}, () => this.onDateRangeInputChange(dateType, date))}
                                label={this.tr("Time span")}
                                dateFormat={userObject.dateFormat} />
                        }

                        {currentReportOptions.filters?.map(f => this.individualFilters[f.name]({...filterProps, ...f.options}))}

                        {this.state.selectedType != "hours_export" && tabContent != "chiefReports" && !currentReportOptions.hideSavedSearch && <OutlinedField 
                            className={`${classNames(classes.filterButton)} select`}
                            select
                            label={this.tr("Saved searches")}
                            name="savedSearches"
                            value={selectedQuery}
                            onChange={evt => this.loadSavedQuery(evt)} 
                            SelectProps={this.savedSearchSelectProps}>
                            {savedQueries.map(option => {
                                return(    
                                <MenuItem className="reports-tab-saved-query-option" key={option.id} value={option.id}>
                                    <span className="title">{option.query_type === "empty" ? this.tr("Empty search") : option.name}</span>
                                    {option.id > 0 ? <Clear className="hide-selected" onClick={(evt) => this.callDeleteDialog(evt, option.id)}/> : undefined}
                                </MenuItem>
                                )
                            })}
                        </OutlinedField>}
                        {this.props.fetchButton && this.state.selectedType == "wintime_unfinished_stock" && <div className="reports-search-button">
                            <LoaderButton 
                                className="blue"
                                setProggressHeight={true}
                                progressPadding="1"
                                notAllowed={this.state.dataIsFetching || this.state.pageIsAppending || this.state.filterSearchOpen}
                                loading={this.state.dataIsFetching || this.state.pageIsAppending} 
                                onClick={() => !this.state.dataIsFetching && !this.state.pageIsAppending && !this.state.filterSearchOpen &&
                                    this.setState({
                                        dataIsFetching: true, 
                                        currentpage: 1, 
                                        data: [],
                                        infiniteScrollEnded: false, 
                                        filterValues: {...this.state.filterValues, pageLimitDifference: 0
                                    }}, () => {
                                    let companyOverride = {};
                                    if (multiCompany && this.companySelectRef && this.companySelectRef.current) {
                                        if (!(_.isEqual(_.sortBy(this.companySelectRef.current.refSelect.current.props.value.map(v => v.value)), _.sortBy(this.props.companyArray))))
                                            companyOverride = {company: this.companySelectRef.current.refSelect.current.props.value.map(v => v.value)};
                                    }
                                    this.fetchData(companyOverride, false, false, false, true);
                                    this.appendableSort = {};
                                })}
                                text={this.tr("Search")} />
                        </div>}                                    
                        {this.state.selectedType != "hours_export" && tabContent != "chiefReports" && !currentReportOptions.hideAdvancedSearch && <AdvancedSearch
                            key={`${tabContent}-${this.state.selectedType}- ${this.state.searchKey}${Object.keys(this.state.autoCompleteData).length}`}
                            ref={this.advancedSearch}
                            fields={searchColumns}
                            onSearchResult={this.setData}
                            mode={this.searchTerms?.mode}
                            initialFilters={this.searchTerms?.currentFilters}
                            mainConfig={this.searchTerms?.advanced_search_criteria}
                            freetextLabel={this.searchTerms?.freetextSearchTerm}
                            autoCompleteData={returnSearchAutocompleteData()}
                            perpage={perpage}
                            noRequests={true}
                            onInputListener={(string) => this.searchTerms = {...this.searchTerms, freetextSearchTerm: string, mode: 'freetext'}}
                            autoCompleteDataFilters={{
                                projects: {
                                    project_types: (projects, project_types) => {
                                        const projectTypes = project_types
                                            .filter(g => typeof(g) === "object" && g.hasOwnProperty("data"))
                                            .map(g => g.data.id);

                                        return projects.filter(p => intersection(p.project_types, projectTypes).length > 0);
                                    },
                                    customers: "customers_id|customers.id",
                                    customership_group: "customership_groups_id|customership_group.id",
                                    reporting_group:    "product_structures_id|reporting_group.id",
                                    branch_of_business: "branchofbusiness_id|branch_of_business.id",
                                    ...this.state.dimensionItemFilters
                                },
                                reporting_group: "customers_id|customers.id",
                                customers: (customers, currentValues) => {
                                    if(!currentValues.customership_group) {
                                        return customers;
                                    }

                                    const customershipGroups = currentValues.customership_group
                                        .filter(g => typeof(g) === "object" && g.hasOwnProperty("data"))
                                        .map(g => g.data.id);

                                    return customers.filter(c => c.cship_groups !== null && customershipGroups.indexOf(c.cship_groups[this.props.company]) > -1);
                                },
                                ...this.state.dimensionAutoCompleteDataFilters
                            }}
                            onSearchTrigger={(searchTerms) => {
                                const {mode, freetextSearchTerm} = searchTerms;

                                this.searchTerms = searchTerms;
                                //tämä lähtee tästä heti kun saataan tuntiraportit virtualisoitulle pohjalle!!!
                                const advanced_search_strings_strip_map = {
                                    users: ' (',
                                    project_managers: ' (',
                                    account_managers: ' (',
                                    sales_agents: ' (',
                                    project_team: ' (',
                                    team: ' (',
                                    stages: ' | ',
                                };
                                if (searchTerms.advanced_search_criteria?.filters) {
                                    let newFilters = {};
                                    Object.entries(searchTerms.advanced_search_criteria.filters).map(([name, filterArray]) => {
                                        if (advanced_search_strings_strip_map[name]) 
                                            newFilters[name] = filterArray.map(fa => ({...fa, value: fa.value.split(advanced_search_strings_strip_map[name])[0]}));
                                        else
                                            newFilters[name] = filterArray;
                                    });
                                    searchTerms.advanced_search_criteria.filters = newFilters;
                                }

                                // this.list.current.setPage(1);
                                this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true, selectedQuery: undefined}, () => this.updateAutoCompleteData());
                            }}
                            onMenuVisibilityChange={(openStatus) => this.setState({filterSearchOpen: openStatus})} >

                            <span className={classNames(classes.saveSearch)} onClick={this.callSaveDialog}>
                                <Save className={classNames(classes.saveIcon)}/>
                                {this.tr("Save this search")}
                            </span>
                            {this.props.fetchButton && <div data-testid="search-button" className="reports-search-button">
                                <LoaderButton 
                                    className="blue"
                                    setProggressHeight={true}
                                    progressPadding="1"
                                    notAllowed={this.state.dataIsFetching || this.state.pageIsAppending || this.state.filterSearchOpen}
                                    loading={this.state.dataIsFetching || this.state.pageIsAppending} 
                                    onClick={() => !this.state.dataIsFetching && !this.state.pageIsAppending && !this.state.filterSearchOpen &&
                                        this.setState({
                                            dataIsFetching: true, 
                                            currentpage: 1, 
                                            data: [],
                                            infiniteScrollEnded: false, 
                                            filterValues: {...this.state.filterValues, pageLimitDifference: 0
                                        }}, () => {
                                        let companyOverride = {};
                                        if (multiCompany && this.companySelectRef && this.companySelectRef.current) {
                                            if (!(_.isEqual(_.sortBy(this.companySelectRef.current.refSelect.current.props.value.map(v => v.value)), _.sortBy(this.props.companyArray))))
                                                companyOverride = {company: this.companySelectRef.current.refSelect.current.props.value.map(v => v.value)};
                                        }
                                        this.fetchData(companyOverride, false, false, false, true);
                                        this.appendableSort = {};
                                    })}
                                    text={this.tr("Search")} />
                            </div>}                        
                        </AdvancedSearch>}              
                                                                               
                    </div>
                    {this.props.renderSummarySection && this.props.renderSummarySection()}
                </div>
                {/* {currentReportOptions.switches && <div className="report-switches-container"> //this is shown in top bar now 
                    <div ref={this.switchesGroup} >
                        {currentReportOptions.switches?.length > 0 && <SwitchesGroup
                            groupLabel={this.tr('Additional options')}
                            options={currentReportOptions.switches.map(sw => ({id: sw.id, name: this.tr(sw.name)}))}
                            values={this.state.switches}
                            onChange={this.onSwitchChange}
                            anchorEl={this.switchesGroup.current} />}
                    </div>
                </div>} */}
                
                {dataIsFetching && (this.state.selectedType != "hours_export" && this.state.selectedType !==  "chief_reports") && <div id="loading-icon-container"><Loading className="loading-icon"/></div>}

                {(!currentReportOptions.useVirtualized && this.state.selectedType != "hours_export" && this.props.tabContent != "chiefReports" ) && ( <div id="report-list">
                    <List
                        // hideFluidFix={this.props.selectedTab === "hourReports" || this.state.selectedTab === "hourReports" || selectedType === "company_report"}
                        ref={this.list}
                        rowKey="id"
                        data={data}
                        columns={columns}
                        sharedData={autoCompleteData}
                        height="fitRemaining"
                        trimHeight={-25}
                        className={`report-list_${selectedType}`}
                        listRowType={ReportListRow}
                        showOverlay={!dataIsFetching && (this.state.dataIsSet && data.length === 0)}
					    overlayComponent={HourReportListOverlay}
                        noStateData={true}
                        idType="string"
                        // minWidth={tableWidth}
                        rowProps={rowProps}
                        showPageSelector={true}
                        pageCount={pageCount}
                        totalCount={reportCount}
                        perpage={perpage}
                        saveColumnConfig={true}
                        userListSettingsKey={`reports_${selectedType}_${subreportKey}`}
                        onSortRows={this.sortRows}
                        onPageChange={page => 
                            this.setState({/*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true }, () => {
                                this.fetchData({ page: page });
                            })
                        }
                        onPerPageChange={perpage => 
                            this.setState({ perpage: perpage, /*data: [],*/ dataIsSet: false, dataIsFetching: this.props.fetchButton ? this.state.dataIsFetching : true }, () => {
                                this.fetchData({ perpage: perpage });
                            })
                        } />
                </div> )}
                {
                    this.state.selectedType == "hours_export" && (
                        <HoursIntegrationExporter
                            classes={classes}
                            company={company}
                            startDate={this.state.dateRange.startDate}
                            endDate={this.state.dateRange.endDate}
                            integration={exportIntegration}
                        />
                    )
                }
                {
                    this.props.tabContent == "chiefReports" && (
                    <div className="chief-reports">
                        {
                            exportingHoursUsers ? 
                            <div className="loadingContainer">
                                <img src={require('../dashboard/insights/img/loading.svg').default} />
                                {this.tr("Generating report...")}
                            </div>
                            :
                            <>
                                <div className="slot">
                                    <span className={classNames(classes.exportButton, classes.button)} onClick={() => this.exportHoursUsers('xlsx')} >
                                        <CloudDownload /><span>{`${tr("Hours users")} (.xlsx)`}</span>
                                    </span>
                                </div>
                                <div className="slot">
                                    <span className={classNames(classes.exportButton, classes.button)} onClick={() => this.exportHoursUsers('csv')} >
                                        <CloudDownload /><span>{`${tr("Hours users")} (.csv)`}</span>
                                    </span>
                                </div>
                            </>
                        }
                    </div>
                    )
                }

                {currentReportOptions.useVirtualized && ( 
                    <div id="report-list">
                        <SimpleList 
                            className="reports-list-invoicing"
                            data={addTotalsData(data)}
                            // fluid={true}
                            rowHeight={30}
                            listProps={{
                                useInfiniteScroll: infiniteScrollStatus,
                                // How "close" to the list's bottom-most row
                                // we have to scroll for the onInfiniteScroll event
                                // to be fired.
                                infiniteScrollTriggerLimit: currentReportOptions.scrollTriggerLimit || 400,
                                infiniteScrollDynamicPageNumbering: true,
                                onInfiniteScroll: async (currentPageNumber, appendData) => { 
                                    if (this.state.dataIsFetching || this.state.data.length < 1)
                                        return false;
                                    if (this.state.infiniteScrollEnded) {
                                        this.setState({showAppendingHourglass: false});
                                        return false;
                                    }
                                    this.setState({pageIsAppending: true, showAppendingHourglass: true});
                                    const nextPageNumber = currentPageNumber + 1;
                                    const resp = await this.fetchAppendableData({ currentpage: nextPageNumber }, false, false, false, true);
                                    let pageData = resp.data; 
                                    let totalRow = {};
                                    let lastId = this.list.current.state.data[this.list.current.state.data.length -1].id + 1;
                                    pageData.forEach((p, i) => {
                                        pageData[i] = {...pageData[i], id: lastId};
                                        lastId ++;
                                    });                                    
                                    if (pageData?.length == 0 && data.length > 0 && this.list.current.state.data[this.list.current.state.data.length -1]?._type != 'total') {
                                        this.setState({filterValues: {...this.state.filterValues, pageLimitDifference: 0}});
                                        currentReportOptions.totalFields.names.map(n => {this._infiniteScrollTotals[n] = 0;});
                                        this.list.current.state.data.map((d, i) => {
                                            if (d._type == 'base')
                                                currentReportOptions.totalFields.names.map(n => {this._infiniteScrollTotals[n] += Number(d[n])});
                                            if (i+1 === this.list.current.state.data.length) {
                                                totalRow = {
                                                   ...currentReportOptions.totalFields, 
                                                   names: null, 
                                                   ...this._infiniteScrollTotals
                                                };
                                                this.setState({infiniteScrollEnded: true, showAppendingHourglass: false});
                                            }
                                        });
                                        pageData.push(totalRow);
                                        this._infiniteScrollTotals = {};

                                    }

                                    appendData(addTotalsData(pageData));
                                    this.setState({pageIsAppending: false, showAppendingHourglass: false, filterValues: {...this.state.filterValues, pageLimitDifference: this.state.filterValues.pageLimitDifference + resp.pageLimitDifference},});
                                    if (pageData.length > 0)
                                        this.setState({currentpage: nextPageNumber});

                                    // Fetch more data using the given pageNumber parameter.
                                    // Use the passed appendData function to append the 
                                    // new page's data to List's existing data.
                                    
                                    // For example: 
                                    // const pageData = await this.fetchData({ page: pageNumber });

                                    // appendData(pageData);
                                    //
                                    // When you change List's data via props, its "infinite scroll"
                                    // state should reset, and this process starts from the beginning,
                                    // meaning from page 1.
                                },
                                onInfiniteScrollPageEnd: () => {},
                                ref:this.list,
                                saveColumnConfig: true,
                                userListSettingsKey: `reports_${selectedType}_${subreportKey}`,
                                height: "fitRemaining",
                                minWidth: tableWidth,
                                showPageSelector: false,
                                trimHeight: -25,
                                pageCount: pageCount,
                                totalCount: reportCount,
                                showOverlay: !dataIsFetching && (this.state.dataIsSet && data.length === 0),
                                overlayComponent: HourReportListOverlay,
                                perpage: currentReportOptions.pageLimit,
                                sharedData: { 
                                    translate: this.tr, 
                                    presentCurrency: (sum) => {

                                        if (sum == '-')
                                            return false;

                                        sum = isNaN(sum) ? 0 : Math.round(sum * 100) / 100;

                                        return this.context.functions.presentCurrency(sum, currency);
                                    },
                                    presentHours: (amount) => {
                                        if (amount == null)
                                            return false;

                                        amount = isNaN(amount) ? 0 : Math.round(amount * 100) / 100;

                                        return `${amount.toFixed(2)} ${this.tr("h")}`;
                                    },
                                    presentPercentage: (percentage) => {
                                        if (percentage == '-')
                                            return false;                                        
                                        return isNaN(percentage) || (typeof(percentage) === "string" && percentage.trim() === "") ? "-" : `${Number(percentage).toFixed(2)} %`;
                                    },
                                    presentDate: (date) => {
                                        return date;
                                        // return !date ? "" : window.moment(date).format("L");
                                    },
                                    presentDates: (dates) => {
                                        if (!dates)
                                            return "";
                                        // let datesArray = dates.split(',');
                                        // datesArray = datesArray.map(da => window.moment(da).format("L"));
                                        // return datesArray.join(', ');
                                        return dates;
                                    },
                                    presentTime: (time) => {
                                        return time;
                                    },
                                    presentQuantity: (q) => {

                                        if (q == '-')
                                            return false;

                                        q = isNaN(q) ? 0 : parseFloat(q).toFixed(2);

                                        return q;
                                    },
                                    getCellTitleString: (id) => {
                                        return this.tr(currentReportOptions.cellTooltips[id]);
                                    },
                                    getOptionalPresentation: (data, options = {}) => {

                                        if (data[options?.field] == options?.fieldValue)
                                            return options?.trueFunctionName;
                                        else
                                            if (this.state.switches.view_quantity)
                                                return 'presentQuantity';
                                            else
                                                return options?.falseFunctionName;
                                    },
                                },
                                onSortRows: (colName, asc) => {
                                    this.sortRows( colName, asc );
                                }                                
                            }}
                            rowConfiguration={{
                                project: {
                                    definesHeader: currentReportOptions.headerDefiningRowType == 'project',
                                    className: "base-row",
                                    cells: columns
                                },
                                customer: {
                                    className: selectedType === "invoices_summary" ? "customer-row-alt" : "subheader-row",
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },
                                section_subtotal: {
                                    className: "section-subtotal-row",
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column.name];

                                            return column;
                                        });
                                    }
                                },                                
                                subtotal: {
                                    className: "subtotal-row",
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },                                
                                total: {
                                    className: "grand-total-row",
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },
                                "sub-total_1": {
                                    className: `sub-total level_1 ${selectedType === "employees_daily" ? 'summary-row' : ''}`,
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },
                                "sub-total_2": {
                                    className: "sub-total level_2",
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },                                
                                "vat-total": {
                                    className: "vat-total-row",
                                    cells: (mainOrder, widthMap) => {
                                        let cols  = cloneDeep(columns);
                                        let order = cloneDeep(mainOrder);

                                        let firstCol  = { name: "totalTitle", width: widthMap[order[0]] };
                                        let secondCol = { name: "totalValue", width: widthMap[order[1]] };

                                        cols = cols.filter(c => c.name !== order[0] && c.name !== order[1]);
                                        cols =  order.filter(c => [order[0], order[1]].indexOf(c) === -1).map(c => cols.find(col => col.name === c));

                                        return [firstCol, secondCol, ...cols];
                                    }
                                },
                                base: {
                                    definesHeader: currentReportOptions.headerDefiningRowType == 'base',
                                    className: `base-row ${currentReportOptions.rowClassNames?.parent}`,
                                    cells: columns
                                },
                                child: {
                                    definesHeader: currentReportOptions.headerDefiningRowType == 'child',
                                    className: `child-row ${currentReportOptions.rowClassNames?.child}`,
                                    cells: (mainOrder, widthMap) => {
                                        return mainOrder.map(c => {
                                            let column = columns.find(col => col.name === c);

                                            column.width = widthMap[column?.name];

                                            return column;
                                        });
                                    }
                                },
                                emptyRow: {
                                    className: "emptyRow",
                                    cells: columns
                                },                                
                            }}                            
                        />

                    </div>
                )}
                {this.state.showAppendingHourglass && <div id="loading-icon-container" className="appending-page"><Loading className="loading-icon appending-page"/></div>}
                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.saveDialog}
                    data={dialogData} />}
            </div>  
            
        );
    }   
};

ReportsTab.propTypes = {

};
ReportsTab.defaultProps = {
    perpage: 30,
    hideSavedSearch: false,
    hideAdvancedSearch: false,
    showLockedUsersWithTag: true,
}

export default withStyles(styles)(withSnackbar(ReportsTab));
