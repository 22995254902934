import React from 'react';
import TaimerComponent from "../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import { SettingsContext } from './../SettingsContext';

import { ReactComponent as OverlayImage } from '../list/overlays/ExpenseList.svg';

class ExpenseViewOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'expenses/ExpenseViewOverlay');
    }

    render () {

        return (
            <div id="expense-view-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.tr("Add mileage and daily allowance details in settings to get started") }
                </div>
                <Button
                    color="primary"
                    onClick={() => this.context.functions.updateView({module: 'settings', action: 'index', group: 'features', page: 'expense'})}
                >
                {this.tr("GO TO SETTINGS") }
                </Button>
             </div>
        );
    }

}

export default ExpenseViewOverlay;