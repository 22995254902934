import React, { useContext, useEffect, useState } from 'react';
import { Payment, NoteAdd } from '@mui/icons-material';
import moment from 'moment';
import DataHandler from '../general/DataHandler';
import StatusTag from '../general/StatusTag';
import TaimerAvatar from '../general/TaimerAvatar';
import { SettingsContext } from '../SettingsContext';
import TaimerComponent from '../TaimerComponent';
import InvoiceLogProvider, { InvoiceLogContext } from './InvoiceLogProvider';
import styles from './InvoiceLogView.module.scss';

interface Props {
    invoiceId: string | number;
    company: string;
    currency: string;
}
interface InvoiceLogItem {
    id: string;
    users_id: string;
    users_name: string;
    users_color: string;
    stamp: string;
    message: string;
    eventcode: string;
}

const InvoiceLogViewRow = (props) => {
    const { item, currency } = props;
    const context = useContext(SettingsContext);
    const logContext = useContext(InvoiceLogContext);
    const { getEventDescription, getStatusData, eventTypes, formatMessage, getMessage, getStampFormat } = logContext;
    const {
        userObject: { dateFormat },
    } = context;
    const stampDate = moment.utc(item.stamp);
    const statusData = getStatusData(item);
    return (
        <li className={styles.invoiceLogViewRow}>
            {item.users_id ? (
                <TaimerAvatar size="medium" id={item.users_id} name={item.users_name} color={item.users_color} />
            ) : (
                <div className={styles.iconContainer}>{item.eventcode == '101' ? <NoteAdd /> : <Payment />}</div>
            )}
            <div className={styles.mainText}>
                <p>{stampDate.isValid() && stampDate.local().format(getStampFormat(item))}</p>
                <h2>{getEventDescription(item)}</h2>
                <p>{item.users_name}</p>
                {item.message && item.eventcode != eventTypes.sentToS3SweFinance && (
                    <p
                        className={`${styles.message} ${
                            item.eventcode != eventTypes.invoiceReverted &&
                            item.eventcode != eventTypes.invoiceDeleted &&
                            item.eventcode != eventTypes.invoiceCreated &&
                            item.eventcode != eventTypes.paymentReceived &&
                            item.eventcode != eventTypes.sellingPriceDiffers &&
                            styles.breakAll
                        }`}
                    >
                        {formatMessage(getMessage(item, currency))}
                    </p>
                )}
            </div>
            <div className={styles.right}>
                <StatusTag color={statusData.color} text={statusData.name} />
            </div>
        </li>
    );
};

class NoDataView extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'invoices/InvoiceLogView/NoDataView');
    }

    render() {
        return (
            <div className={styles.noDataView}>
                <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                <h1>{this.tr('No data to show for this invoice!')}</h1>
                <p>{this.tr("There's nothing to see here.")}</p>
            </div>
        );
    }
}

const InvoiceLogView = (props: Props) => {
    const { invoiceId, company, currency } = props;
    const [data, setData] = useState<InvoiceLogItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await DataHandler.post({ url: `invoices/invoice_log/${company}` }, { invoiceId });
            setLoading(false);
            setDataLoaded(true);
            setData(response?.data || []);
        } catch (err) {
            setLoading(false);
            setDataLoaded(true);
            setData([]);
            console.error(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <InvoiceLogProvider>
            <div id={styles.invoiceLogView}>
                {loading ? (
                    <img src={require('../dashboard/insights/img/loading.svg').default} />
                ) : dataLoaded && !data.length ? (
                    <NoDataView />
                ) : (
                    <ul>
                        {data.map((item) => (
                            <InvoiceLogViewRow key={item.id} item={item} currency={currency} />
                        ))}
                    </ul>
                )}
            </div>
        </InvoiceLogProvider>
    );
};

export default InvoiceLogView;
