import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SettingsContext } from "../SettingsContext";
import TaimerComponent from '../TaimerComponent';
import DataHandler from './DataHandler';
import JoditEditor from "jodit-react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';

import $ from 'jquery';
import _ from 'lodash';

import './NoteDrawer.css';

export class Note extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        mainHeader: "Project",
        subHeader: "Account",
        creator: null,
        actions: [],
        note: "",
    }
    
    constructor (props, context) {
        super(props, context, 'general/Note');

        const editorButtons = [
            "paragraph",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "ul",
            "ol",
            "|",
            "link",
            "|",
            "undo",
            "redo"
        ];
          
        this.editorConfig = {
            buttons: editorButtons,
            buttonsMD: editorButtons,
            buttonsSM: editorButtons,
            buttonsXS: editorButtons,
            showPlaceholder: false,
            showTooltip: true,
            // uploader: {
            // TODO check this to upload images
            //   insertImageAsBase64URI: false
            // },
            enableDragAndDropFileToEditor: false
        };
		this.handleKeyDown = this.handleKeyDown.bind(this);
        this.editor = React.createRef();
    }

    handleKeyDown(e) {
		if(e.ctrlKey && e.key === "s") {
			e.preventDefault();
			e.stopPropagation();
            const { value } = this.editor.current;
            const { project_id } = this.props;
            this.props.onCtrlS(value, project_id);
		}
	}

    openCreatorChat = () => {
        const { creator, creator_id } = this.props;
        this.context.functions.openTeamChat({module: 'projects', action: 'open-chat', id: creator_id, target_name: creator});
        this.props.close();
    }

    openProjectChat = () => {
        const { project_id, creator } = this.props;
        this.context.functions.openTeamChat({module: 'projects', action: 'open-chat', id: project_id, target_name: creator});
        this.props.close();
    }

    render() {

        let { mainHeader, subHeader, note, creator_id, creator, created_date, editor_id, editor, edited_date, actions, unEditable } = this.props;
        const { tr, htmlTr } = this;
        const functions = {
            creatorChat: this.openCreatorChat,
            projectChat: this.openProjectChat,
        }
        const functionHeaders = {
            creatorChat: tr("Chat with") + " " + creator,
            projectChat: tr("Project chat"),
        }
                            
        const replacers = {
            'creator': <span className="bold-text">{creator}</span>,
            'creationDate': <span className="bold-text">{created_date}</span>
        } 

        return (
            <div className="main-container note">
                <div className="header-container">
                    <span className={"main-header " + this.props.mainHeaderAction && "link"} onClick={this.props.mainHeaderAction && this.props.mainHeaderAction}>{mainHeader}</span>
                    <span className="sub-header" >{subHeader}</span>
                    {creator_id > 0 ? 
                    <p className="created">
                        <span className="light-text">{htmlTr("Message created by ${creator} on ${creationDate}", replacers)}</span>
                    </p>
                    : undefined}
                    {actions && <div className="actions">
                        {creator_id > 0 ? 
                            actions.map(a => {
                                if (a === "creatorChat" && creator_id == this.context.userObject.usersId)
                                    return;
                                else 
                                    return (
                                        <span onClick={functions[a]}>{functionHeaders[a]}</span>
                                    )
                            })
                        : undefined}
                    </div>}
                </div>
                <div className="text-editor" onKeyDown={this.handleKeyDown} >
                    {!unEditable ? <JoditEditor
                        ref={this.editor}
                        tabIndex={1}
                        config={this.editorConfig}
                        value={note || ""}
                        //onBlur={this.onEditorBlur}
                    /> : <div className="note-substitute" dangerouslySetInnerHTML={{__html: note}} ></div>}
                </div>
                <div className="separator"></div>
            </div>
        )
    }
}



export class NoteDrawer extends TaimerComponent {
    static contextType = SettingsContext;
    static propTypes = {

    }
    static defaultProps = {
        noteData: []
    }
    constructor (props, context) {
        super(props, context, 'general/NoteDrawer');

        this.noteRefs = {};

        this.state = {
            open: false
        } 
    }

    componentDidMount() {
        super.componentDidMount();
        this.createRefs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.noteData.length != this.props.noteData.length) {
             this.createRefs();
        }
    }

    createRefs = () => {
        const { noteData } = this.props;
        noteData.forEach((note, i) => {
            const noteRef = "note" + i;
            this.noteRefs[noteRef] = React.createRef();
        });
    }

    open = () => {
        if (!this.state.open) {
            $('body').addClass('notes-open');
            this.setState({ open: true });
        }
    }

    close = () => {
        this.props.noteData.forEach((note, i) => {
            _.forEach(this.noteRefs, ref => {
                //looppaa reffit läpi täällä
                if (ref.current?.editor.current && note.project_id === ref.current.props.project_id && note.note !== ref.current.editor.current?.value) {
                    this.props.noteProps.onCtrlS(ref.current.editor.current?.value, note.project_id, note.creator)
                }
            })
        })
        $('body').removeClass('notes-open');
        this.setState({ open: false });     
    }

    render() {
        const { tr } = this;
        const { open } = this.state;
        const { title, noteData, noteProps } = this.props
        
        return (
            <Drawer anchor="right" open={open} classes={{paper: "noteDrawer"}} onClose={this.close}>
                <div className="main-container notes">
                    <div className="header">
                        <h4>{title}</h4>
                        <Close onClick={this.close} />
                    </div>
                    {noteData.map((note, i) => {
                        const noteRef = "note" + i;
                        return (
                            <Note {...note} {...noteProps} ref={this.noteRefs[noteRef]} close={this.close}/>
                        )
                    })}
                </div>
            </Drawer>
        )
    }
}

export default NoteDrawer
