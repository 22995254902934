import React, { Component } from 'react'

/* local components */
import TaimerComponent from '../TaimerComponent';
import DataHandler from '../general/DataHandler';
import Category from './Category';
import { format } from 'date-fns';
import moment from 'moment';

/* material ui */
import Drawer from '@mui/material/Drawer';
import SettingsIcon from '@mui/icons-material/Settings';

/* context */
import { SettingsContext } from '../SettingsContext';
import NotificationsDefinitions from './NotificationsDefinitions';
import _ from 'lodash';
import $ from 'jquery';

import './Notifications.css';


export class Notifications extends TaimerComponent {
    static contextType = SettingsContext;
    
    //static defaultProps = {
    //    updateFrequency: 300000,
    //}
    
    constructor (props, context) {
        super (props, context, "notifications/Notifications");
        
        this.state = {
            open: false,
            data: {},
            newCount: 0,
            hasPoRights: false
        }
    }

    //interval = false;
    isRefreshing = false;
    isPaused = false;


    componentDidMount() {
        super.componentDidMount();
        this.refresh(true);
        //this.interval = setInterval(this.refresh, this.props.updateFrequency);
        const categoryVisibility = localStorage.getItem("openNotifications");
        if (!categoryVisibility){
            localStorage.setItem("openNotifications", JSON.stringify({1: "closed", 2: "closed"}));
        }
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
        //clearInterval(this.interval);
    }

    open = (currentState) => {
        this.unreadNotificationShown = 0;

        if (!this.state.open) {
            $('body').addClass('notifications-open');
            this.setState({ open: true });
        }
        this.refresh(true, true);
    }

    close = () => {
        const { full = false } = this.props;
        $('body').removeClass('notifications-open');
        this.setState({ open: false });     
    }
    
    refresh = async (forceRefresh = false) => {
        let newCount = 0;
        if (this.isPaused || (this.isRefreshing && !forceRefresh))
            return;

        this.isRefreshing = true;

        try {
            const { userObject: {usersId} } = this.context;
            const data = await DataHandler.get({url: `notifications/${usersId}/data`});
            const hasPoRights = data.has_po_rights == 1;
            delete data.has_po_rights;

            _.forEach(data, (type, key) => {
                type.forEach((notification, nkey) => {
                    if (notification.seen == 0)
                        newCount++;

                    if(key == 1 && (['5', '8'].includes(notification.sub_type))) {
                        data[key][nkey].value = format(notification.value, this.context.userObject.dateFormat.toUpperCase());
                    }

                });
                
            })
            this.props.onUnseenCount && this.props.onUnseenCount(newCount);
            this.setState({data: data, newCount: newCount, hasPoRights});
        } catch (error) {
            console.log("notifications error: ", error);
        }

        this.isRefreshing = false;
    }
    
    render() {
        const definedNotifications = NotificationsDefinitions.getDefinitions();
        const { tr } = this;
        const { openTeamChat } = this.props;
        const { open, data, hasPoRights } = this.state;
        const { functions: {checkPrivilege}, addons, userObject: {companies_id} } = this.context;

        return (
            <Drawer anchor="right" open={open} onClose={this.close} classes={{paper: "notificationDrawer"}}>
                <div className="mainContainer notifications" id="taimer-notifications">
                    <div className="headerContainer">
                        <span className="title">{tr("Notifications")}</span>
                        <SettingsIcon onClick={() => {
                            this.context.functions.updateView({module: 'settings', action: 'index', group: 'my-profile', page: 'notifications', subpage: ''});
                            this.close();
                        }
                    } />
                    </div>
                    <div className="contentContainer">
                        {_.map(definedNotifications, (category, type) => {
                            const element = <div className="categoryContainer">
                                                <Category 
                                                    icon={category.icon}
                                                    header={category.header}
                                                    type={type}
                                                    data={data[type]}
                                                    notificationProps={{
                                                        refresh: this.refresh,
                                                        openTeamChat: openTeamChat,
                                                        close: this.close,
                                                        tr: this.tr
                                                    }}
                                                />
                                            </div>
                            switch (Number(type)) {
                            case 1:
                                if (checkPrivilege("workhours", "write"))
                                    return (element);
                            break;
                            case 2: 
                                if (checkPrivilege("invoices", "write_simple", companies_id) || checkPrivilege("invoices", "write_full", companies_id)) 
                                    return (element);
                            break;
                            case 3: 
                                    return (element);
                            break;
                            case 4: 
                                 if (addons.notifications && hasPoRights)
                                    return (element);
                            break;
                            default:
                                return (element);
                            }
                        })}
                    </div>
                </div>
            </Drawer>
        )
    }
}

export default Notifications
