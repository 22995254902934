import React from 'react';

import MenuItem from './MenuItem';
import _ from 'lodash';

class SidebarGroup extends React.Component {
   
    onClickGroup = (event) => {
        const { group, isVisibleMenuItem } = this.props;

        const subitems = group.subitems && group.subitems.filter(x => isVisibleMenuItem(x));

        if (subitems && subitems.length > 0) {
            this.props.onClick && this.props.onClick(subitems[0], event.ctrlKey || event.metaKey || event.button === 1, group.label);
            return;
        }

        if (!subitems || subitems.length === 0)
            this.props.onHeaderClick && this.props.onHeaderClick(group, event.ctrlKey || event.metaKey || event.button === 1);
    }

    onClickItem = (event, item) => {
        this.props.onClick && this.props.onClick(item, event.ctrlKey || event.metaKey || event.button === 1);
    }

    render() {
        const {
            isVisibleMenuItem, group, showLocked,
            allAddons, addonsInfo, headingComponnent: Heading = MenuItem,
            onClick, openDialog, selected, level, tree, tabletMode,
        } = this.props;

        const subitems = group.subitems && group.subitems.filter(x => isVisibleMenuItem(x));

        // All subitems hidden
        if (!subitems && !group.target)
            return null;

        const groupAddon = group.addonkey && allAddons[group.addonkey];
        const groupAddonInfo = group.addonkey && addonsInfo[group.addonkey];

        const locked = groupAddon && groupAddon.can_buy && !groupAddon.limit;

        // For old menu, hide locked
        if (locked && !showLocked)
            return null;

        return (<div>
            <Heading
                selected={selected}
                item={group}
                data-testid={`navigation-${group.name}`}
                icon={group.icon}
                name={group.label}
                onClick={this.onClickGroup}
                onSubItemClick={onClick}
                openDialog={openDialog}
                buy={groupAddon}
                addon={groupAddonInfo}
                addonanme={group.addonkey}
                tabletMode={tabletMode}
                subitems={subitems}
                mainItem
                allAddons={allAddons} 
                addonsInfo={addonsInfo}
            />
        </div>)
    }
}

export default SidebarGroup;