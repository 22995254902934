/* react */
import React from 'react';

/* css */
/*import './Efina.css';*/
import './Maventa.css';
import './GoogleDrive.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import DataList from '../../general/DataList';
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class GoogleDrive extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/GoogleDrive");

        this.state = {
            activated: false,
            authcode: "",
            authorized: false,
            googleDriveFolders: [],
            selectedFolder: [],
            dialogData: undefined
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
    }

    getAuthData = () => {

        const company = this.props.company ? this.props.company :  this.context.userObject.companies_id;

        DataHandler.get({ url: `settings/company/${company}/googledrive` }).done(response => {
            this.setState({ activated: response.activated === "1" });
        });

        DataHandler.post({ url: `drive/google/connect` }, { company: company })
            .done(response => {
                if (response.authenticated) {
                    this.setState({
                        authorized: response.authenticated === 1,
                        googleDriveFolders: response.folders !== null ? response.folders : [],
                        selectedFolder: response.selected_folder.status === 'ok' ? response.selected_folder : [],
                    });
                } else {
                    this.setState({
                        authorized: false,
                    });
                }
            })
            .fail(response => {
                this.setState({ authorized: false });
            });
    }

    back = () => {
        const { functions: { updateView } } = this.context;
        const { viewProps: { page } } = this.props;

        if(page === 'personal-integrations') updateView({ module: 'settings', action: 'index', group: 'my-profile', page: 'default', subpage: '' });
        else updateView({ module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: '' });
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    //There is two levels of swiching google on and off. First is the activation of Google Drive feature on Taimer, second is the personal authentication
    disconnectGoogle = () => {
        DataHandler.post({ url: "drive/google/logout" }, { company: this.props.company }).done(this.setState({ authorized: false }));
    }

    activateGoogle = () => {
        DataHandler.put({ url: `settings/company/${this.props.company}/googledrive` }).done(response => {
            this.setState({ activated: true });
        });
    }


    //This is unauthorising google 
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => {
                    DataHandler.delete({ url: `settings/company/${this.props.company}/googledrive` }).done(response => {
                        this.setState({ activated: false });
                        this.props.fetchData();
                        this.back();
                    });
                },
                text: this.tr("Are you sure you want to deactivate your Google Drive and unset the root folder?")
            }
        }, () => this.openDialog('confirmation'));
    }

    connectGoogle = () => {
        DataHandler.post({ url: `drive/google/connect` }, { company: this.props.company })
            .done(response => {
                if (response.url) {
                    sessionStorage.setItem("previousUrl", window.location);
                    sessionStorage.setItem("nextAction", "google-login");
                    window.open(response.url, "_blank");
                }
                this.setState({ getauth: false });
            })
            .fail(err => {
                console.log(err);
            });
    };

    authenticateGoogle = () => {

        const { enqueueSnackbar } = this.props;
        const company = this.props.company ? this.props.company :  this.context.userObject.companies_id;


        if(this.state.authcode == ''){
            enqueueSnackbar(this.tr('Fill in authentication code'), {
                variant: "error"
            });
            return;
        } 

        DataHandler.post({ url: "drive/google/authenticate" }, { authcode: this.state.authcode, company: company })
            .done(response => {
                if (!response.error) {
                    setTimeout(() => {
                        this.setState({ authorized: true, googleDriveFolders: response.folders, selectedFolder: response.selected_folder });
                    }, 1000);
                    enqueueSnackbar(this.tr('Google Drive authentiacated'), {
                        variant: "success"
                    });
                } else {
                    enqueueSnackbar(this.tr(response.error_description), {
                        variant: "error"
                    });
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    onFolderChanged = (value) => {
        DataHandler.post({ url: "drive/google/setrootfolder" }, { folderid: value.id, company: this.props.company })
            .done(response => {
                if (!response.error) {
                    value.status = 'ok'; //we need to add status to the selectedFolder
                    this.setState({ selectedFolder: value });
                } else {
                    console.log(response.error); //there should be a prompt for the user too
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    render() {
        const { activated, authorized, googleDriveFolders, selectedFolder } = this.state;
        const { tr } = this;
        const { functions: { checkPrivilege } } = this.context;
        const { viewProps: { page, group } } = this.props;

        //const selectedFolder  = null;

        const rights = checkPrivilege("admin", "admin", this.state.company) ? 'admin' : 'user';

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const folderOptions = (googleDriveFolders || []).map(el => ({ id: el.id, label: el.name !== '' ? el.name : '/' }));

        return (
            <div id="integration-google-drive" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Google Drive Integration")}</h3>
                </div>
                {!activated && rights === 'admin' && group === 'integrations' &&
                    <div className='content-text'>
                        <div className='connect-google'>
                            <h4>{tr("Activate Google Drive")}</h4>
                            <Button size="large" className="blue connect-button" onClick={() => this.activateGoogle()} disabled={activated}>
                                {tr("Activate here")}
                            </Button>
                        </div>
                    </div>
                }
                {activated && !authorized &&
                    <div>
                        <div className='content-text'>
                            <div>{tr("Previously added attachments will not be automatically transferred to Google Drive. However, they will be visible and editable normally on Taimer.")}</div>
                            <div>
                                <ul>
                                    <li> {tr("Customer")}
                                        <ul>
                                            <li> {tr("Project")} </li>
                                            <li> {tr("Sub project")}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div>{tr("When adding a first attachment, Taimer will create folders needed to Google Drive. It might take a couple of minutes. ")}</div>

                            <div>{tr("Please note: every user has to privately connect and authorize Google Drive in order to use it for the attachments. ")}</div>
                        </div>
                        <div className="buttons google">
                            <div className='connect-google'>
                                <h4>{tr("Authenticate Your Google Drive Account")}</h4>
                                <div>{tr("Authenticate on Google and paste the authentication code below")}</div>
                                <Button size="large" className="blue connect-button" onClick={() => this.connectGoogle()} disabled={authorized}>
                                    {tr("Authenticate here")}
                                </Button>
                            </div>

                            <div className='authenticate-google'>
                                <h4>{tr("Add Authentication Code")}</h4>
                                <div className="buttons-authentication">
                                    <OutlinedField
                                        className="buttons-authentication-textfield"
                                        value={""}
                                        onChange={evt => this.setState({ authcode: evt.target.value })}
                                        label={tr("Authentication code")}
                                    />
                                    <Button
                                        size="large"
                                        color="primary"
                                        className="buttons-authentication-button"
                                        onClick={() => this.authenticateGoogle()}
                                    >
                                        {tr("Activate")}
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                }

                {activated && authorized &&
                    <div>
                        {rights === 'admin' && group === 'integrations' &&
                            <div className='sync-folder'>
                                <h4>{tr("Select Google Drive Root Folder")}</h4>
                                <div>{tr("Select one folder from your Google Drice account to sync files from Taimer Accounts and Projects.")}</div>
                                {googleDriveFolders === null &&
                                    <div className='error folder-not-found'>
                                        {tr("Folders could not be fetched from Google Drive. Please check sharing on Google Drive or try refreshing the page.")}
                                    </div>
                                }                                
                                {folderOptions && googleDriveFolders !== null && selectedFolder !== null &&
                                    <DataList
                                        style={{ display: 'block' }}
                                        addNoneOption={false}
                                        label={tr("Google Drive Root Folder")}
                                        options={folderOptions}
                                        value={selectedFolder || []}
                                        onChange={(value) => this.onFolderChanged(value)}
                                    />
                                }
                                {selectedFolder !== null && selectedFolder?.status !== 'ok' && selectedFolder.hasOwnProperty('id') &&
                                    <div className='error folder-not-found'>
                                        {tr("Selected folder not found on Google Drive")}
                                    </div>
                                }
                            </div>
                        }
                        {page === 'personal-integrations' &&
                            <div className='sync-folder'>
                                <h4>{tr("Selected Google Drive Root Folder")}</h4>

                                { selectedFolder &&
                                    <div>
                                        { selectedFolder.name ?  selectedFolder.name : tr("Selected folder not found. Please check the sharing on Google Drive.")}
                                    </div>
                                }

                            </div>
                        }
                        <div className='deactivate'>
                            <h4>{tr("Disconnect Personal Google Drive from Taimer")}</h4>
                            <div>{tr("Please note! If you disconnect Google Drive you cannot see or edit Google Drive attachments on Taimer.")}</div>
                            <Button
                                size="large"
                                className="blue deactivate"
                                onClick={this.disconnectGoogle}
                            >
                                {tr("Disconnect")}
                            </Button>
                        </div>
                    </div>
                }

                {activated && rights === 'admin' && group === 'integrations' &&
                    <div className='deactivate'>
                        <h4>{tr("Deactivate your Company Google Drive from Taimer")}</h4>
                        <div>{tr("Deactivate Google Drive")}</div>
                        <Button
                            size="large"
                            className="red deactivate"
                            onClick={this.deactivate}
                        >
                            {tr("Deactivate")}
                        </Button>
                    </div>
                }

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />
                }

            </div >
        )
    }
}

export default GoogleDrive;