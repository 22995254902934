import replace from "string-replace-to-array";
import { Emoji } from "emoji-mart";
import React from "react";
import _ from "lodash";

import ColorHash from 'color-hash';

import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

export const colorHash = new ColorHash();

export const toTitleCase = (string) => {
  if (!string) return "";
  const formatted = string.toLowerCase();
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

export function formatHoursAndMinutes(h, m, allowEmpty = false) {
  return (
    (h > 0 ? `${h} h ` : "") +
    (m > 0 || (!h && !allowEmpty) ? `${m} m` : "").trim()
  );
}

export function formatHours(hours, allowEmpty = false) {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);

  return (
    (h > 0 ? `${h} h ` : "") +
    (m > 0 || (!h && !allowEmpty) ? `${m} m` : "").trim()
  );
}

export function prefixLink(link) {
  if (!link || !link.startsWith)
      return link;
    
  if (link.startsWith('http://') || link.startsWith('https://'))
    return link;

  return `http://${link}`;
}

const emojiRegex = /(:([+-\d\w]+(::skin-tone-\d)?):)/g;
const linkEmojiRegex = /((:([+-\d\w]+(::skin-tone-\d)?):)|((http|https):\/\/([^\s'"]+)))/g;

export function renderWithEmoji(text, size = 32, enableEmbeds = true) {
  let i = 0;
  function replaceEmoji(match, emoji) {
    if (match.startsWith("http://") || match.startsWith("https://")) {
      var url = new URL(match);

      if (
        enableEmbeds &&
        (url.host === "www.youtube.com" ||
          url.host === "youtube.com" ||
          url.host === "www.youtu.be" ||
          url.host === "youtu.be")
      ) {
        let id = false;
        let time = url.searchParams.get("t") || 0;

        if (url.pathname.startsWith("/watch")) {
          id = url.searchParams.get("v");
        } else {
          id = url.pathname;
        }

        if (id) {
          return (
            <iframe
              className="youtube-embed"
              src={`https://www.youtube-nocookie.com/embed/${id}?${
                time ? `start=${time}` : ""
              }`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          );
        }
      }

      return (
        <a href={match} target="_blank">
          {match}
        </a>
      );
    }

    return (
      <Emoji
        key={`${i++}-${emoji}`}
        title={emoji}
        tooltip
        emoji={emoji}
        set="twitter"
        size={size}
      />
    );
  }

  return replace(text, linkEmojiRegex, replaceEmoji);
}

export function filterByText(data = [], search = "", fields = []) {
  if (!search) return data;

  var filtered = [];

  // By default search all fields
  if (fields.length === 0) {
    const first = _.first(data);
    fields = _.keys(first);
  }

  search = search.toLowerCase();

  _.forEach(data, value => {
    var found = false;

    fields.forEach(f => {
      const val = String(value[f]).toLowerCase();

      if (val.indexOf(search) > -1) found = true;
    });

    if (found) filtered.push(value);
  });

  return filtered;
}

export function objectMap(object, mapFn) {
  if (!object) return [];

  return Object.keys(object).reduce(function(result, key) {
    result.push(mapFn(object[key]));
    return result;
  }, []);
}

export function convertToObject(array, func, numeric) {
  var obj = {};

  if (array)
    array.forEach(function(v) {
      if (func) func(v);

      obj[v.id] = v;
    });

  return obj;
}

export function getElementOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function timeOfDay(date) {
  var midnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );

  return date.getTime() - midnight.getTime();
}

export function shadeColor(color, percent) {
  var c = typeof color === "object" ? color : parseHexColor(color);

  if (c.r === 255 && c.g === 255 && c.b === 255) percent = -Math.abs(percent);

  var R = parseInt((c.r * (100 + percent)) / 100, 10);
  var G = parseInt((c.g * (100 + percent)) / 100, 10);
  var B = parseInt((c.b * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  return colorToHex({ r: R, g: G, b: B });
}

export const stringToColour = str => {
  var hash = 0;
  if (!str)
    str = "";
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const hexColorWithOpacity = (hex, opacity = 0.6) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  return hex;
};

export function textColorOnBG(color) {
  var c = parseHexColor(color);

  if (0.213 * c.r + 0.715 * c.g + 0.072 * c.b < 0.5) return "text-light";
  else return "text-dark";
}

export function parseHexColor(color) {
  if (typeof color === "object") return color;

  color = color.replace(/#/g, "");
  var r = parseInt(color.substring(0, 2), 16);
  var g = parseInt(color.substring(2, 4), 16);
  var b = parseInt(color.substring(4, 6), 16);
  return { r, g, b };
}

export function colorToHex(color) {
  if (typeof color === "string") return color;

  var r = color.r.toString(16).padStart(2, "0");
  var g = color.g.toString(16).padStart(2, "0");
  var b = color.b.toString(16).padStart(2, "0");

  return "#" + r + g + b;
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}

export function getTextWidthInElement(text, el) {
  return getTextWidth(text, window.getComputedStyle(el).font);
}

export function randomColor() {
  const colorArray = [
    red["900"],
    red["700"],
    red["500"],
    red["300"],
    red["100"],
    pink["900"],
    pink["700"],
    pink["500"],
    pink["300"],
    pink["100"],
    purple["900"],
    purple["700"],
    purple["500"],
    purple["300"],
    purple["100"],
    deepPurple["900"],
    deepPurple["700"],
    deepPurple["500"],
    deepPurple["300"],
    deepPurple["100"],
    indigo["900"],
    indigo["700"],
    indigo["500"],
    indigo["300"],
    indigo["100"],
    blue["900"],
    blue["700"],
    blue["500"],
    blue["300"],
    blue["100"],
    lightBlue["900"],
    lightBlue["700"],
    lightBlue["500"],
    lightBlue["300"],
    lightBlue["100"],
    cyan["900"],
    cyan["700"],
    cyan["500"],
    cyan["300"],
    cyan["100"],
    teal["900"],
    teal["700"],
    teal["500"],
    teal["300"],
    teal["100"],
    "#194D33",
    green["700"],
    green["500"],
    green["300"],
    green["100"],
    lightGreen["900"],
    lightGreen["700"],
    lightGreen["500"],
    lightGreen["300"],
    lightGreen["100"],
    lime["900"],
    lime["700"],
    lime["500"],
    lime["300"],
    lime["100"],
    yellow["900"],
    yellow["700"],
    yellow["500"],
    yellow["300"],
    yellow["100"],
    amber["900"],
    amber["700"],
    amber["500"],
    amber["300"],
    amber["100"],
    orange["900"],
    orange["700"],
    orange["500"],
    orange["300"],
    orange["100"],
    deepOrange["900"],
    deepOrange["700"],
    deepOrange["500"],
    deepOrange["300"],
    deepOrange["100"],
    brown["900"],
    brown["700"],
    brown["500"],
    brown["300"],
    brown["100"],
    blueGrey["900"],
    blueGrey["700"],
    blueGrey["500"],
    blueGrey["300"],
    blueGrey["100"],
    "#525252",
    "#969696",
    "#D9D9D9"
  ];

  return colorArray[Math.floor(Math.random() * colorArray.length)];
}

export function formatInputNumber(value, type) {
  if (value == undefined) return value;
  const num = Number(String(value).replace(",",".").replace(/\s+/g, ''));
  if (!num && num !== 0)
    return value;

  if (type === "hours") {
    return String(num.toFixed(2)).replace(",",".");
  } else if (type == 'no-zero-decimals') {
    return String(Math.round((num || 0) * 100) / 100).replace(".",",");
  } else
    return String(num.toFixed(2)).replace(".",",");
}

export function getInvoiceFieldLimits(context, company) {
  const { addons }      = context;
  const usedLimitAddons = [];
  let showNotes         = false;
  let hasNoNotesAddons  = false;
  let hasNotesAddons    = false;
  let restrictedChars   = "";
  const noNotesAddons   = ["efina", "talenom", "fivaldi", "fennoa", "netvisor", "ropocapital", "emce", "quickbooks", "meritaktiva", "xero"];
  const notesAddons     = ["fortnox", "procountor", "maventa", "heeros"];
  const limitAddons     = ["fortnox", "procountor", "maventa", "meritaktiva", "heeros"];

  const fieldLimits = {
    reference: 255, // 255 is our database limit
    customerreference: 255, // 255 is our database limit
    notes: 0,
    postalcode: 0,
    city: 0,
    address: 0, 
    customerName: 0,
    vatid: 0,
    customer_email: 0,
    country: 0,
    vatid_2: 0,
    contact: 0,
    rowItemDescription: 0
  }

  /**
    * reference: Payment reference or Our reference
    * customerreference: Your reference
    */
  const integrationLimits = {
    fortnox: {
      reference: 50,
      customerreference: 50,
      notes: 990 // 1024 - 34. Reverse vat info goes to same field (max length added from that is 34) 
    },
    procountor: {
      reference: 255, // Procountor limit is 10000 but our database limit is 255.
      customerreference: 70,
      notes: 9600 // 10000 - 255 - 144 = 9600 Limit is 100000 but reference goes to same field so that is 255. Info text is added When sending to Procountor (that can be translated) so lets put 144 extra.
    },
    maventa: {
      reference: 35,
      customerreference: 35,
      notes: 512
    },
    meritaktiva: {
      reference: 255, // Merit Aktiva limit is 4000 but our database limit is 255. Goes to same field with customerreference in Merit Aktiva.
      customerreference: 255, // Merit Aktiva limit is 4000 but our database limit is 255. Goes to same field with reference in Merit Aktiva.
      postalcode: 15,
      city: 30,
      address: 100, 
      customerName: 150,
      vatid: 30,
      customer_email: 80,
      country: 2,
      vatid_2: 30,
      contact: 35,
      rowItemDescription: 100
    },
    heeros: {
      notes: 512,
      country: 2,
    },
  }

  noNotesAddons.forEach(addon => {
    if (addons[addon] && addons[addon].used_by_companies.indexOf(company) > -1) {
      hasNoNotesAddons = true;
    }
  });
  notesAddons.forEach(addon => {
    if (addons[addon] && addons[addon].used_by_companies.indexOf(company) > -1) {
      hasNotesAddons = true;
    }
  });


  limitAddons.forEach(limitAddon => {
    if (addons[limitAddon] && addons[limitAddon].used_by_companies.indexOf(company) > -1) {
      usedLimitAddons.push(limitAddon);
      if (limitAddon == "fortnox") {
        restrictedChars = "<>|\"";
      }
    }
  });

  if (usedLimitAddons.length > 0) {
    Object.keys(integrationLimits).forEach(integration => {
      if (usedLimitAddons.includes(integration)) {
        Object.keys(fieldLimits).forEach(limit => {
          const value = fieldLimits[limit];
          const integrationLimit = integrationLimits[integration][limit];
          if (!value || (integrationLimit && integrationLimit < value)) {
            fieldLimits[limit] = integrationLimits[integration][limit];
          }
        });
      }
    });
  }

  showNotes = hasNotesAddons || !hasNoNotesAddons;
  return {fieldLimits, restrictedChars, showNotes};
}

export function checkStringRestrictedChars(chekcString, restrictedChars) {
  var matcher = new RegExp("[" + restrictedChars + "+]");

  if (matcher.test(chekcString)) {
      return true;
  } else {
      return false;
  }
}

