import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import MyHoursList from '../../list/lists/MyHoursList';
import ApprovalsList from "../../list/lists/ApprovalsList";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import ProgressNotification from "./../../general/ProgressNotification";
import ErrorNotification from "./../../general/ErrorNotification";
import NotificationContent from "./../../general/NotificationContent";
import DataHandler from "../../general/DataHandler";
import { formatInputNumber } from '../../helpers';
import { getWorktypesForProject, getDefaultPricelistWorktypes } from '../../Data';
import { format } from "date-fns";
import Button from "@mui/material/Button";
import moment from "moment";
import { withSnackbar } from 'notistack';
import _ from 'lodash';

// My Hours tab
class MyHours extends TaimerComponent {

	constructor(props, context)
	{
        super(props, context, "workhours/MyHours");
		this.state = {

		};

		this.autoCompleteData = undefined;

		this.dialogs = {
            editSelections: MassDialog,
            propertyEditor: MassDialog,
            promptBatchUpdate: MassDialog
		};
		
		this.basicDialogControls = () => ({
            cancelText: this.tr("Cancel"),
            confirmText: this.tr("Confirm"),
            onCancel: this.closeDialog,
            onCloseClick: this.closeDialog
		});

        this.propsStatusFilterValues = [
            { value: 3, label: this.tr("approved") },
            { value: 1, label: this.tr("declined") } 
        ];
		
		this.propertyEditorDialogFields = {
            date: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("date"),
                    date: {
                        _type: "date",
                        label: this.tr("Choose a date")
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Date"), value: format(data.date, this.context.userObject.dateFormat) }, { date: format(data.date, "YYYY-MM-DD") });
                }
            },
            description: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("description"),
                        description: {
                            _type: "text",
                            label: this.tr("Write a description"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Description"), value: data.description }, { description: data.description }) 
                }
			},
			overtime_description: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("overtime_description"),
                        overtime_description: {
                            _type: "text",
                            label: this.tr("Write an overtime description"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Overtime description"), value: data.overtime_description }, { overtime_description: data.overtime_description }) 
                }
            },
            project: {
                fields: (dialogData, sharedData) => {
                    return {
                        ...this.propertyPickerFields("project"),
                        customer: {
                            _type: "datalist",
                            label: this.tr("Choose an account"),
							options: sharedData.accounts,
							onChange: (val) => {
								setTimeout(() => {
									this.massEditDialog.current && this.massEditDialog.current.emptyValues(["project", "jobtype", "task"]);
								}, 500)
							}
                        },
                        project: {
                            _type: "datalist",
                            label: this.tr("Choose a project"),
							options: sharedData.projects.filter(p => p.customers_id == dialogData.customer),
                            onChange: async (val) => {
                                const resources = sharedData.projects_resources.filter(el => el.id == 0 || (el.projects_id == val && el.done != 1));

                                let worktypes = await getWorktypesForProject(val);
                                worktypes = worktypes.map(el => {
                                    el.value = el.id;
                                    return el;
								})

								this.massEditDialog.current && this.massEditDialog.current.emptyValues(["jobtype", "task"]);
                                this.setState({editDialogData: {worktypes, resources}})
                            }
                        },
                        jobtype: {
                            _type: "treeDrop",
                            label: this.tr("Choose a jobtype"),
                            options: dialogData.project && this.state.editDialogData ? this.state.editDialogData.worktypes : [],
                        },
                        task: {
                            _type: "treeDrop",
                            label: this.tr("Choose a task"),
                            options: dialogData.project && this.state.editDialogData ? this.state.editDialogData.resources : [],
                        }
                    }
                },
                onConfirm: (data) => {
                    const name = this.autoCompleteData.projects.find(el => el.id == data.project).name;

                    this.promptBatchUpdate({ name: this.tr("Project"), value: name }, { project: data.project, jobtype: data.jobtype, task: data.task }) 
                },
                sharedData: () => this.getAutoCompleteData()
            },
            jobtype: {
                fields: (dialogData, sharedData) => {
                    return {
                        ...this.propertyPickerFields("jobtype"),
                        jobtype: {
                            _type: "treeDrop",
                            label: this.tr("Choose a jobtype"),
                            options: this.state.dialogData?.checkedData?.allowedJobtypes
                        },
                    }
                },
                onConfirm: (data) => {
                    const name = this.state.dialogData?.checkedData?.allowedJobtypes?.find(e => e.id == data.jobtype).name;

                    this.promptBatchUpdate({ name: this.tr("Jobtype"), value: name }, { jobtype: data.jobtype }) 
                }
            },
            task: {
                fields: (dialogData, sharedData) => {
                    const {projectId, userId} = this.state.dialogData.checkedData.fieldsData;
                    const resources = sharedData.projects_resources.filter(el => el.id == 0 || (el.projects_id == projectId && el.done != 1 && (el.allow_all || el.user_ids?.includes(Number(userId)))) );                  

                    return {
                        ...this.propertyPickerFields("task"),
                        task: {
                            _type: "treeDrop",
                            label: this.tr("Choose a task"),
                            options: resources,
                        }
                    }
                },
                onConfirm: (data) => {
                    const name = this.autoCompleteData.projects_resources.find(e => e.id == data.task).name;
                    this.promptBatchUpdate({ name: this.tr("Task"), value: name }, { task: data.task }) 
                },
                sharedData: () => this.getAutoCompleteData()
            },
        };

        this.propertyPickerFields = (value = undefined) => ({
            propertyToEdit: {
                _type: "drop",
                label: this.tr("Choose a property to edit"),
                onChange: async (propertyToEdit) => {
                    if (!propertyToEdit) return;

                    const rows = this.state.dialogData.hours;
                    let dialogProps = this.propertyEditorDialogFields[propertyToEdit];

                    if(dialogProps.hasOwnProperty("sharedData") && typeof(dialogProps.sharedData) === "function") {
                        dialogProps.sharedData = await dialogProps.sharedData();
                    }

                    this.dialogProps.propertyEditor.dialogProps = {
                        ...this.basicDialogControls(),
                        additionalContent: () => this.renderConfirmationContent(),
                        ...dialogProps
                    };

                    this.openDialog("propertyEditor", {
						hours: this.state.dialogData.hours,
                        checkedData: this.state.dialogData.checkedData,
                        title: this.tr('Edit'),
                        subtitle: `(${rows.length} ${this.tr("row(s) selected")})`
                    });
                },
                value: value,
				options:
                    [
                        { value: "date", label: this.tr("Date") },
                        { value: "description", label: this.tr("Description") },
                        ... (this.props.selectedTab != "myHours" ? [{ value: "overtime_description", label: this.tr("Overtime description"), disabled: this.state.dialogData ? this.state.dialogData.checkedData?.fieldsData?.overtimeAllowed == 0 : true, tooltip: this.tr("No overtime hours selected.") }] : []),
                        { value: "project", label: this.tr("Project") },
                        { value: "task", label: this.tr("Task"), disabled: this.state.dialogData ? this.state.dialogData.checkedData?.fieldsData?.taskAllowed == 0 : true, tooltip: this.tr("Task can be edited only if all selected tasks have same project and same user.") },
                        { value: "jobtype", label: this.tr("Jobtype"), disabled: this.state.dialogData ? this.state.dialogData.checkedData?.allowedJobtypes?.length < 1 : true, tooltip: this.tr("Selected workhours don't have projects with common jobtypes.") }
                    ].filter(x => x)
            }
        });

        this.originalDialogProps = () => ({
            editSelections: {
                dialogType: "edit",
                dialogProps: {
                    ...this.basicDialogControls(),
                    fields: this.propertyPickerFields(),
                    disableConfirm: true
                }
            },
            propertyEditor: {
                dialogType: "edit",
                dialogProps: {
                    // The rest of this will be filled in from 
                    // this.propertyEditorDialogFields according to which 
                    // property the user wants to edit.
                }
            },
            promptBatchUpdate: {
                dialogType: "confirm",
                open: this.state.currentDialog === "promptBatchUpdate",
                dialogProps: {
                    ...this.basicDialogControls(),
                    title: this.tr("Edit"),
                    confirmText: this.tr("Confirm"),
                    cancelText: this.tr("Cancel")
                },
            },
        });

        this.dialogProps    = this.originalDialogProps();
		this.myHours        = React.createRef();
		this.approvals      = React.createRef();
		this.massEditDialog = React.createRef();
	}

	componentDidMount() {
        super.componentDidMount();
    }

	getAutoCompleteData = async (company = false) => {
        const { userObject } = this.context;
        company = company ? company : this.context.userObject.companies_id;

        let projects_resources = [];
        try {
            projects_resources = await DataHandler.get({ url: 'dialogs/resources', view: 'approvals' });
        } catch(e) {}

        projects_resources = projects_resources.map(el => {
            el.value = el.id;
			let startTime = moment(el.startdate);
			let endTime = moment(el.enddate);
			if (el.startdate == el.enddate) {
				el.name += " (" + startTime.format(userObject.dateFormat) + ")";
			} else {
				el.name += " (" + startTime.format(userObject.dateFormat) + " - " + endTime.format(userObject.dateFormat) + ")";
			}

            if (el.user_ids) {
                el.user_ids = el.user_ids.map(x => Number(x));
            } else {
                el.user_ids = [];
            }

			return el;
		});

		projects_resources.unshift({ id: 0, value: 0, name: this.tr('Not chosen'), label: this.tr('Not chosen') });

        let autoCompleteData = await DataHandler.get({ url: "timetracker/workhours/approval/autocomplete", view: this.props.selectedTab });
		autoCompleteData.status_chanced_by = _.cloneDeep(autoCompleteData.approveUsers);
		autoCompleteData.types = [
			{ value: 0, label: this.tr("all") },
			{ value: 1, label: this.tr("normal") },
			{ value: 2, label: this.tr("overtime") },
			{ value: 3, label: this.tr("vacation") },
		];
		autoCompleteData.statuses = [
            { value: 0, label: this.tr("all") },
			{ value: 3, label: this.tr("approved") },
			{ value: 2, label: this.tr("waiting") },
            { value: 1, label: this.tr("declined") },
            { value: 4, label: this.tr("Submitted") }
		];

        this.autoCompleteData = { ...this.autoCompleteData, ...autoCompleteData, projects_resources };

        return this.autoCompleteData;
    }
	
	sortTest = (items) => {
		this.setState({items})
	}

	export = () => {
		this.myHours.current && this.myHours.current.onToolbarExportClick();
	}

	openEditDialog = async (hours) => {
		let checkedData = {};
		try {
            checkedData = await DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: {ids: hours}, module: this.props.selectedTab });
		} catch (e) {}
		
		this.setState({ dialogData: { ...this.state.dialogData, hours, checkedData }}, () => this.openEditSelections(hours, checkedData));
	}

	openEditSelections = (hours, checkedData) => {
        this.dialogProps = this.originalDialogProps();
        const updateableAmount = checkedData.hoursData.updateHours.length;
        
        checkedData.warningInfo = updateableAmount > 0 
            ? (updateableAmount < hours.length ? this.tr("${amount} of the selected hours can be edited", {amount: updateableAmount}) : "")
            : this.tr("No updateable hours!");

        this.dialogProps.editSelections.dialogProps = {
            ...this.dialogProps.editSelections.dialogProps,
            additionalContent: () => this.renderConfirmationContent(),
            disableConfirm: updateableAmount < 1,
            hideEdit: updateableAmount < 1,
        };
        
        this.openDialog('editSelections', {
			checkedData,
			hours,
            title: this.tr("Edit"),
            subtitle: this.tr("Do you want to edit ${hour_count} workhours?", {hour_count: hours.length}),
        });
	}
	
	 /* Mass edit */
	 promptBatchUpdate(config = {}) {
        if (arguments.length > 1) {
            this._promptBatchUpdate(...arguments);
            return;
        }

        const defConf = {
            confirmationSummary: [],
            data: {},
            uncheckAfter: false,
            onConfirm: undefined
        };

        const fConfig = { ...defConf, ...config };

        this._promptBatchUpdate(...Object.keys(defConf).map(f => fConfig[f]));
    }


    async _promptBatchUpdate(confirmationSummary = [], data, uncheckAfter = true, onConfirm = undefined) {
        const updateableHours = this.state.dialogData.checkedData.hoursData.updateHours;
        const name = Object.keys(data)[0];
        let fieldCheckData = {};
        const checkData = {
            ids: updateableHours,
            name: name,
            value: data[name]
        }

        try {
            fieldCheckData = await DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: checkData, module: this.props.selectedTab, type: "fieldEdit" });
        } catch (e) {}

        let checkedData = _.cloneDeep(this.state.dialogData.checkedData);
        checkedData.hoursData = {
            ...checkedData.hoursData,
            updateHours: updateableHours.filter(h => !fieldCheckData.alreadyUpdated.includes(h) && !fieldCheckData.notOvertime.includes(h)),
            alreadyUpdated: fieldCheckData.alreadyUpdated,
            notOvertime: fieldCheckData.notOvertime
        }

        const newUpdateableAmount = checkedData.hoursData.updateHours.length;
        checkedData.warningInfo = newUpdateableAmount > 0 
            ? (newUpdateableAmount < this.state.dialogData.hours.length 
                ? this.tr("${amount} of the selected hours can be edited, are you sure you want to apply the changes to hours? Once applied, the changes can not be canceled or undone.", {amount: newUpdateableAmount}) 
                : this.tr("Are you sure you want to apply the changes to ${amount} hours? Once applied, the changes can not be canceled or undone.", {amount: newUpdateableAmount}))
            : this.tr("No updateable hours!");

        this.setState({dialogData: {...this.state.dialogData, type: "massEdit", selectedRows: this.state.dialogData.hours, checkedData}}, () => this.openBatchUpdateDialog(confirmationSummary, data, uncheckAfter, onConfirm, checkedData));
    }

    openBatchUpdateDialog = (confirmationSummary, data, uncheckAfter, onConfirm, checkedData) => {
        const rows = this.state.dialogData.selectedRows;

        this.openDialog("promptBatchUpdate", {
            checkedData: checkedData,
            disableConfirm: checkedData.hoursData.updateHours.length < 1,
            hideEdit: false,
            title: `${this.tr('Edit')}`,
            subtitle: `(${rows.length} ${this.tr("row(s) selected")})`,
            text: this.renderConfirmationContent(checkedData),
            onConfirm: onConfirm === undefined ? () => {
                this.batchUpdate(data, uncheckAfter);
            } : onConfirm,
            confirmationSummary: Array.isArray(confirmationSummary) ? confirmationSummary : [confirmationSummary]
        });
    }

    batchUpdate(config = {}) {
        if (arguments.length > 1)
            return this._batchUpdate(...arguments);

        const defConf = {
            data: {},
            uncheckAfter: false,
            showNotification: true,
            excludeIds: []
        };

        const fConfig = { ...defConf, ...config };

        return this._batchUpdate(...Object.keys(defConf).map(f => fConfig[f]));
    }


    // uncheckAfter === true will uncheck all rows on the list after changes have been applied.
    // Used when an update leads to the selected rows no longer being visible in the current set.
    async _batchUpdate(data, uncheckAfter = false, showNotification = true, excludeIds = []) {
        // https://i.imgflip.com/3ncsld.jpg
        const selectedRows = this.state.dialogData.checkedData.hoursData.updateHours;
        const name = Object.keys(data)[0];
        let notificationKey = this.showProgressNotification();
        this.closeDialog();

		let update = {};
		if (name == "project") {
			update = { name: "project_mass", value: data.project, jobtype: data.jobtype, task: data.task };
		}
		else {
			update = { name: name, value: data[name] }
		}

        DataHandler.put({ url: `timetracker/workhours/update_hours` }, { data: update, ids: selectedRows.join(), module: this.props.selectedTab })
            .done(response => {
                this.massEditUpdate(response, true);
            })
            .fail(err => {
                this.props.enqueueSnackbar(this.tr("Error in saving workhours"), {
                    variant: "error",
                });
            })
            .always(() => {
                setTimeout(() => {
                    this.props.closeSnackbar(notificationKey);
                }, 1000);
            });
    }

    massEditUpdate = (data, uncheckAll = false, totalHours = 0) => {
        setTimeout(() => {
            this.myHours.current.fetchData();

            if (uncheckAll)
                this.myHours.current.unCheckAll();

            if (Number(totalHours) > 0) {
                this.props.enqueueSnackbar(this.tr("${amount} hours updated", { amount: formatInputNumber(totalHours, "hours") }), {
                    variant: "success",
                });
            }
            else if (data.notUpdated > 0) {
                this.showErrorNotification({
                    message: this.tr("${amount} rows not updated", { amount: data.notUpdated }),
                    buttonText: this.tr("Dismiss"),
                    key: "batchUpdateFieldError"
                });
            }
            else if (data.updated > 0) {
                this.props.enqueueSnackbar(this.tr("${amount} rows updated", { amount: data.updated }), {
                    variant: "success",
                });
            }
        }, 1000);
    }

    showProgressNotification() {
        return this.props.enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: key => {
                return (
                    <ProgressNotification
                        message={this.tr("Applying changes")} />
                );
            }
        });
    }

    showErrorNotification({ message, buttonText, onClick, key, preventDuplicate }) {
        const { closeSnackbar } = this.props;

        this.props.enqueueSnackbar("", {
            persist: true,
            key: key || "errorNotification",
            preventDuplicate: preventDuplicate || false,
            content: () => {
                return (
                    <ErrorNotification message={message} closeSnackbar={closeSnackbar} key={key} onClick={onClick} buttonText={buttonText} />
                );
            }
        });
    }

    openDialog = (name, data = {}) => {
        this.setState({
            currentDialog: name,
            dialogData: data,
            currentDialogProps: {
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}),
                ...(typeof (data) === "object" ? data : {})
            }
        });
	}
	
	closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }
    
    getSelectedDateRange = () => {
        if (this.props.selectedTab == "myHours")
            return this.myHours.current.getSelectedDateRange();
        else 
            return {}; 
    }

    updateMyhoursData = () => {
        if (this.props.selectedTab == "myHours") {
            this.myHours.current.fetchData();
        }
    }

    unCheckMyhoursList = () => {
        this.myHours.current.unCheckAll();
    }

	renderConfirmationContent = (data= null) => {
        if (!data) {
            data = this.state.dialogData.checkedData;
        }

        const alreadyUpdated = data.hoursData.alreadyUpdated?.length;
        const notAllowed     = data.hoursData.notAllowed?.length;
        const notEditable    = data.hoursData.notEditable?.length;
        const incorrectData  = data.hoursData.incorrectData?.length;
        const notOvertime    = data.hoursData.notOvertime?.length;

        let infoPart = "";

        if (data.type === "status") {
            switch(data.value) {
                case 1:
                    infoPart = "approved";
                    break;
                case 0:
                    infoPart = "set to waiting";
                    break;
                case -1:
                    infoPart = "declined";
                    break;
            }
        }
        else {
            infoPart = "updated";
        }

        return (
            <>
                <p>{data.warningInfo}</p>
                <ul>
                {alreadyUpdated > 0 && 
                    <li>{alreadyUpdated + " " + this.tr("hours are already " + infoPart + ".")}</li>
                }
                 {notOvertime > 0 && 
                    <li>{notOvertime + " " + this.tr("hours are not overtime hours.")}</li>
                }
                 {notEditable > 0 && 
                    <li>{notEditable + " " + this.tr("hours cannot be " + infoPart) + ": " +  this.tr("Not editable anymore.")}</li>
                }
                 {incorrectData > 0 && 
                    <li>{incorrectData + " " + this.tr("hours cannot be " + infoPart) + ": " +  this.tr("Has incorrect data.")}</li>
                }
                {notAllowed > 0 && 
                    <li>{notAllowed + " " + this.tr("hours cannot be " + infoPart) + ": " +  this.tr("No permission.")}</li>
                }
                </ul>
            </>
        );
    }
	
	render() {
		const commonProps = {
			openEditDialog: this.openEditDialog, 
            massEditUpdate: this.massEditUpdate,
            getAutoCompleteData: this.getAutoCompleteData,
			autoCompleteData: this.autoCompleteData,
			...this.props
		}
		const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

		return (
			<>
				{Dialog &&
					<Dialog
						open
						ref={this.massEditDialog}
						onDialogClose={this.closeDialog}
						onClose={this.closeDialog} // for workhour entry
						onDialogSave={this.confirmDialog}
						data={this.state.dialogData}
						dialogType={this.dialogProps[this.state.currentDialog].dialogType}
						dialogProps={{ ...this.state.currentDialogProps }}
						item={this.state.dialogData.item} // for workhour entry
					/>}


				{this.props.selectedTab == "myHours" ? (
					<div className="MyHours">
						<MyHoursList ref={this.myHours} propsStatusFilterValues={this.propsStatusFilterValues} {...commonProps} />
					</div>
				) : (
						<ApprovalsList ref={this.myHours} {...commonProps} />
					)}
			</>
		);
	}
}

MyHours.defaultProps = {
    selectedTab: "myHours",
};

export default withSnackbar(MyHours);
