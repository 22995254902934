/* material-ui */
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { MenuItem } from '@mui/material';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import EyeIcon from '@mui/icons-material/RemoveRedEye';

/* others */
import React from 'react';
import List from '../list/List';
import Paper from "../general/Paper";
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import ListRow from '../list/ListRow';
import ListCell from '../list/ListCell';
import navicons from '../navigation/NavIcons';
import ContextMenu from '../general/ContextMenu';
import DataHandler from '../general/DataHandler';
import TextInputCell from '../list/cells/TextInputCell';
import TaimerComponent from "../TaimerComponent";
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import CurrencyListCell from "../list/CurrencyListCell";
import { SettingsContext } from './../SettingsContext';
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import Utils from "./../general/Utils";
import { makeMapOfPrimitives } from "../list/ListUtils";
import { formatInputNumber } from '../helpers';
import InfoSelect from '../list/cells/InfoSelect';
import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import PurchaseOrderHeader from '../bills/PurchaseOrderHeader';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { TaimerTheme } from './../TaimerTheme';
import { TextField } from '@mui/material';
import { JssProvider } from 'react-jss';
import _ from 'lodash';

const ResourcingIcon = navicons.resourcing;

function Header(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";
    return <Wrapper className="header">{props.children}</Wrapper>;
}

function ContentArea(props) {
    return <div className="content">{props.children}</div>;
}

function Footer(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";
    return <Wrapper className="footer">{props.children}</Wrapper>;
}

class Totals extends ListRow {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);
        this.context = context;
    }
    
    render() {
        const { className = "" } = this.props;
        const numberFormat = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.props.currency });
        return (
            <div className={`sum-info ${className}`}>
                {this.props.rows.map(row => {
                    return (<div className={`row ${row.grey ? "grey" : row.black ? "black" : row.greyvat ? "greyvat" : ""}`}>
                            <div className="name">{row.name}</div>
                            <div className="sum">{numberFormat.format(row.sum)}</div>
                        </div>);
                })}
            </div>
        );
    }
}

class QuoteRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteRow");
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow hidePrint";
        else 
            return "quote-listrow quoteRow";
    }


    defineCells() {
        const { data, rowProps, columnConfig } = this.props;
        const { taimerAccount }        = rowProps.context;
        const { CPQParents, editMode } = rowProps;

        const textInputCellProps = { 
            useClickAwayListener: true, 
            runOnEditedOnInput: false 
        };

        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true,
        };

        const { products } = rowProps;

        const calcValue        = data.cost;
        const sum              = data.quantity * calcValue;
        const vatTotal         = sum + (data.vat / 100 * (calcValue * data.quantity));
        const type             = Number(data.type);
        let rowText = "";
        if((type === 3 || (data.id < 0 && type === 2))) {
            let product = products.find(el => el.id == data.product_id);
            if(product) {
                rowText = product.name;
            }
        } else if(type == 4) {
            let parent = CPQParents.find(el => el.id == data.product_id);
            if(parent) {
                rowText = parent.name;
            }
        } else {
            rowText = data.name;
        }

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true} className={!editMode ? "transparent" : ""}>
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem className="menuItem" onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                        {/*<MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "row" })}>{this.tr('Add child')}</MenuItem>*/}
                    </ContextMenu>
                </ListCell>,
            move: 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            name:
                /*
                 * types:
                 * 1: normal quote row (text)
                 * 2: description row
                 * 3: product row
                 * 4: cpq row
                 * 5: ???
                 *
                 */


                // If this row is a product row on the "main level" (outside CPQ) or it's a cpq product row as a child of a main level row,
                // show products and use the cpq product row's type_id
                //
                // This could've been written better, but lack of time and all the usual excuses.
                // (data["_type"] === "productRow" || (data["_type"] === "cpqRow" && (type == 2 || data.id > 0 && type == 4))) && workType === 4 ?
            
                // workType has no bearing over wether the row can have a product or not.  
          
                (data.id < 0 && type === 2) ? 
                <AutoCompleteCell
                    // {...editModeProps} // TODO
                    className="onQuoteRow"
                    name="name"
                    allowCreate={false}
                    openMenuOnFocus={false}
                    value={data.product_id}
                    noOptionsMessage={() => this.tr("No options")}
                    autoCompleteData={products.map(e => ({ ...e, value: e.id }))}
                    searchable={true}
                    menuPortalTarget={document.body}
                    listCellProps={{...listCellEditModeProps, forceToolTip: true, showTooltipForOverflownText:true, className: columnConfig.name.className }}
                    onEdited={value => {
                        this.setData(data => {
                            const key = data["_type"] === "productRow" || data["_type"] === "cpqRow" && type == 4 ?  "product_id" : "type_id";
                            data[key] = value.id;
                            
                            data = {
                                ...data,
                                ...{
                                    name: value.name,
                                    cost: value.cost_price,
                                    vat: value.vat,
                                    quantity: data.quantity == 0.00 ? 1 : data.quantity
                                }
                            };

                            return data;
                        });
                    }}
                /> : type === 3 ?
                <ListCell editable={false} onlyDisplay={true}>
                    <InfoSelect
                        height={230}
                        tableWidth={1000}
                        headerHeight={35}
                        rowHeight={49}
                        className={columnConfig.name.className + " InfoSelect__infoselect_product infoSelect__hideChildBorder"}
                        placeholder={rowText ? rowText : this.tr("Product")}
                        editable={rowProps.editMode}
                        inEditMode={rowProps.editMode}
                        noOptionsMessage={this.tr("No options")}
                        options={products.filter(e => Number(e.deleted) === 0).map(e => ({ ...e, value: e.id }))}
                        columns={[
                            { name: "code", header: this.tr("Code"), width: 75 },
                            { name: "name", header: this.tr("Name"), width: 500 },
                            { name: "path", header: this.tr("Category"), width: 350 },
                            { name: "unit", header: this.tr("Unit"), width: 75 },
                        ]}
                        value={rowText}
                        onChange={value => {
                            this.setData(data => {
                                const key = data["_type"] === "productRow" || data["_type"] === "cpqRow" && type == 4 ?  "product_id" : "type_id";
                                data[key] = value.id;
                                data = {
                                    ...data,
                                    ...{
                                        cost: value.cost_price,
                                        vat: value.vat,
                                        quantity: data.quantity == 0.00 ? 1 : data.quantity
                                    }
                                };

                                return data;
                            });
                        }}
                    /> 
                </ListCell>
                    : (type === 4) ?
                <AutoCompleteCell
                    // {...editModeProps} // TODO
                    className="onQuoteRow"
                    // style={{width: columnWidthMap['name'] + 'px'}}
                    name="name"
                    allowCreate={false}
                    openMenuOnFocus={false}
                    value={data.product_id}
                    autoCompleteData={CPQParents.map(e => ({ ...e, value: e.id }))}
                    noOptionsMessage={() => this.tr("No options")}
                    searchable={true}
                    menuPortalTarget={document.body}
                    listCellProps={{...listCellEditModeProps, forceToolTip: true, showTooltipForOverflownText:true, className: columnConfig.name.className}}
                    onEdited={async (value) => {
                        this.setData("product_id", value.id);

                        try {
                            const { cpqs } = await DataHandler.get({ url: `cpq/childrens`, parentId: value.id, company: this.props.rowProps.company }); 
                            const sums     = { cost: 0, quantity: 1, vat: 0 };

                            cpqs.forEach(row => {
                                sums.cost  += Number(row.quantity) * Number(row.unit_cost); 
                            });

                            this.setData(sums);
                        } catch(e) {
                            
                        }

                        // this.setData("cpqId", value.id);
                        // rowDataChanged("cpqSingleRow", value, quoteId, headerId, data.id);
                    }}
                    /> :
                <TextInputCell
                    // {...editModeProps} TODO
                    // style={{width: columnWidthMap['name'] + 'px'}}
                    name="name"
                    placeholder={this.tr("Item")}
                    // value={data["_type"] !== "cpqRow" ? data.name : data.description}
                    value={(data.description == "Row" ? this.tr(data.description): data.description) || (data.name == "Row" ? this.tr(data.name): data.name)} 
                    {...textInputCellProps}
                    listCellProps={{...listCellEditModeProps, className: columnConfig.name.className}}
                    onEdited={value => {
                        this.setData("name", value);
                        this.setData("description", value); // En tiijjä
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/> ,
            quantity:
                <TextInputCell
                    // {...editModeProps} TODO
                    name="quantity"
                    value={formatInputNumber(data.quantity)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.quantity.className}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("quantity", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            cost:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['cost'] + 'px'}}
                    name="cost"
                    value={formatInputNumber(data.cost)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.cost.className, currency: this.props.rowProps.currency}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("cost", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            vat:
                <TextInputCell
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['vat'] + 'px'}}
                    name="vat"
                    value={formatInputNumber(data.vat)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.vat.className}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("vat", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            vatTotal:
                <ListCell
                    // style={{width: columnWidthMap['vatTotal'] + 'px'}}
                    name="vatTotal"
                    textAlign="right"
                    value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(vatTotal)}
                    editable={false}
                    listCellProps={{className: columnConfig.vatTotal.className, currency: this.props.rowProps.currency}}
                    className={columnConfig.vatTotal.className}
                    />,
            sum:
                <ListCell
                    // style={{width: columnWidthMap['sum'] + 'px'}}
                    name="sum"
                    value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(sum)} // TODO
                    textAlign="right"
                    editable={false}
                    className={columnConfig.sum.className}
                    listCellProps={{currency: this.props.rowProps.currency}}
            />

        };
    }
}

// Also acts as a CPQ header row.
class QuoteHeaderRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteHeaderRow");

        // TODO: What are these for?
        this.typeMap = {
            // 0: parent,
            // 1: item,
            // 2: product,
            // 3: description
        };

        this.selectConfiguration = this.selectConfiguration.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.data.parentChosen && this.props.data.parentChosen && this.props.data.cpqParentId !== undefined)
            this.selectConfiguration(this.props.data.cpqParentId);
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow header useEllipsis hidePrint";
        else 
            return "quote-listrow quoteRow header useEllipsis";
    }


    async selectConfiguration(parentId) {
        const typeMap = {
            1: "quoteRow",
            2: "productRow", 
            3: "descriptionRow"
        };

        const cpqResponse = await DataHandler.get({ 
            url: `cpq/childrens`, 
            parentId: parentId,
            company: this.props.rowProps.company
        });

        this.props.listRef.addNewRow(cpqResponse.cpqs.map(row => {
            let rowType = Number(row.type);
            let rRow    = {
                parentId: this.props.data.id, 
                _type: typeMap[rowType],

                cost: row.unit_cost,
                description: row.description,
                quantity: row.quantity,
                type: row.type,

                // TODO: type_id/product_id requires more scrutiny; can always having type_id in product_id produce bugs?
                type_id: row.type_id,
                product_id: rowType === 2 ? row.type_id : undefined,

                vat: row.vat,
            };

            return rRow;
        }));
     }


    defineCells() {
		// TODO:
        const { data, rowProps } = this.props;
        const { CPQParents }     = rowProps;
        const editMode           = rowProps.editMode;
        const { units, hidden, rowDataChanged, quoteId, headerId } = rowProps;
        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true
        };

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true} className={!editMode ? "transparent" : ""}>
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                    </ContextMenu>
                </ListCell>,
            move: 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            text: data["_type"] !== "cpqHeaderRow" || (data["_type"] === "cpqHeaderRow" && data.parentChosen)
            ? 
            <TextInputCell 
                value={(data.text == "Header" ? this.tr(data.text) : data.text) ||(data.name == "Header" ? this.tr(data.name) : data.name)} 
                listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.text.className}}
                onEdited={value => {
                    this.setData("text", value);
                }} />
            :
            <AutoCompleteCell
                className="onQuoteRow"
                listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.text.className}}
                menuPortalTarget={document.body}
                noOptionsMessage={() => this.tr("No options")}
                autoCompleteData={CPQParents.map(e => ({ ...e, value: e.id }))}
                onEdited={value => {
                    this.setData(data => {
                        data.cpqParentId  = value.id;
                        data.parentChosen = true;
                        data.text         = value.name;

                        return data;
                    });
                }}
                value={data.cpqParentId ? data.cpqParentId : (CPQParents[0] ? CPQParents[0].id : 0)} 
            />
        };
    }


    render() {
        let columnOrder = ["button", "quantity", "cost", "vat", "vatTotal",  "sum"];
        
        return (
            <React.Fragment>
                {super.render()}
                <QuoteTopicSummaryRow 
                    key={"quoteTopicSummaryRow"}
                    fluid 
                    data={this.props.listRef.getData().filter(d => Number(d.parentId) === Number(this.props.data.id)).filter(d => !d.deleted || d.deleted === "0")}
                    formatCurrency={this.props.rowProps.formatCurrency}
                    parentId={this.props.data.id}
                    lastInGroup={true}
                    rowProps={this.props.rowProps}
                    listRef={this.props.listRef}
                    noColorVariance={true}
                    columnOrder={columnOrder} 
                    columnConfig={{ 
                        button: { width: 190 },
                        cost: { width: 50, className: this.props.columnConfigs.quoteRow.cost.className },
                        quantity: { width: 30, className: this.props.columnConfigs.quoteRow.quantity.className },
                        vat: { width: 30, className: this.props.columnConfigs.quoteRow.vat.className },
                        vatTotal: { width: 50, className: this.props.columnConfigs.quoteRow.vatTotal.className },
                        sum: { width: 50, className: this.props.columnConfigs.quoteRow.sum.className },
                    }} />
            </React.Fragment>
        );
    }
}


class QuoteDescriptionRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteDescriptionRow");
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow useEllipsis hidePrint";
        else 
            return "quote-listrow quoteRow useEllipsis";
    }


    defineCells() {
		// TODO:
        const { data, rowProps } = this.props;
        const editMode           = rowProps.editMode;
        const { units, hidden, rowDataChanged, quoteId } = rowProps;
        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true
        };

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true} className={!editMode ? "transparent" : ""}>
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                        {/*<MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "row" })}>{this.tr("Add child")}</MenuItem>*/}
                    </ContextMenu>
                </ListCell>,
            move: 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            description: 
                <TextInputCell 
                    listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.description.className}}
                    value={data.description || data.name || data.text} 
                    runOnEditedOnInput={true}
                    onEdited={value => {
                        this.setData("description", value);
                    }} />
        };
    }
}



class QuoteTopicSummaryRow extends ListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteTopicSummaryRow");

        this.menu = React.createRef();

        this.formSummedData = this.formSummedData.bind(this);
    }


    defineClassName() {
        return "quote-listrow quoteRow summary";
    }


    formSummedData() {
        const summed = { cost: 0, vatTotal: 0, sum: 0, margin: 0 }; 

        for(let data of this.props.data) {
            const calcValue        = data.cost;
            const sum              = data.quantity * calcValue;
            const vatTotal         = sum + (data.vat / 100 * (calcValue * data.quantity));

            // || 0 for when the value itself is NaN (description row, etc.)
            summed.cost     += (parseFloat(data.cost) * parseFloat(data.quantity)) || 0;
            summed.vatTotal += vatTotal || 0;
            summed.sum      += sum || 0;
        }

        Object.keys(summed).forEach(k => {
            summed[k] = !summed[k] ? 0 : summed[k];
        });

        return summed;
    }

    defineCells() {
        const commonProps   = { noBorder: true, editable: false, textAlign: "right" };
        const { columnConfig, rowProps }  = this.props;
        const { taimerAccount } = rowProps.context;
        const summed        = this.formSummedData();
        return {
            button: 
                rowProps.editMode ? <ListCell noBorder={true} onlyDisplay={true} className="action-cell">
                    <ContextMenu label={
                        <Button variant="text" className={"action-button"}>
                            <AddIcon className="add-icon" />
                            {this.tr('Add new')}
                        </Button>
                    } buttonProps={{className: 'action-menu'}} className="cell" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ _type: "headerRow" })}>{this.tr("Header")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "descriptionRow" })}>{this.tr("Description")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "quoteRow" })}>{this.tr("Row")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "productRow", workType: 4 })}>{this.tr("Product")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "cpqRow" })}>{this.tr("CPQ row (grouped)")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "cpqHeaderRow" })}>{this.tr("CPQ row (itemized)")}</MenuItem>
                    </ContextMenu>
                </ListCell> : <ListCell noBorder={true} editable={false} className="start-filler"/>,
            quantity: <ListCell {...commonProps} className={columnConfig.quantity.className} />,
            cost: <ListCell {...commonProps} className={columnConfig.cost.className} value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(summed.cost)} />,
            vat: <ListCell {...commonProps} className={columnConfig.vat.className} />,
            vatTotal: <ListCell {...commonProps} className={columnConfig.vatTotal.className} value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(summed.vatTotal)} />,
            sum: <ListCell {...commonProps} value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(summed.sum)} className={"sum " + columnConfig.sum.className} />,
        };
    }
}

//Main class (Quote)
class PurchaseOrder extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/Quote");

        this.list   = React.createRef();
        this.paper  = React.createRef();
        this.header = React.createRef(); 

        this.typeMap = {
            "0": "headerRow",
            "1": "quoteRow",
            "2": "descriptionRow",
            "3": "productRow",
            "4": "cpqRow"
        };

        this.hideableColumns = [
            {field: this.tr("Quantity"), key: "quantity"},
            {field: this.tr("Unit cost"), key: "cost"},
            {field: this.tr("VAT %"), key: "vat"},
            {field: this.tr("VAT Total"), key: "vatTotal"},
            {field: this.tr("Sum 0%"), key: "sum"},
            {field: this.tr("Quote Total"), key: "quoteTotal"},
        ];

        this.typeMapReversed = Utils.flipObject(this.typeMap);

        this.reformatHeaders          = this.reformatHeaders.bind(this);
        this.formatCurrency           = Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.props.rowProps.currency });
        this.markRowDeleted           = this.markRowDeleted.bind(this);
        this.addFirstRows             = this.addFirstRows.bind(this);
        this.emptyNewData             = this.emptyNewData.bind(this);
        this.getRowTypeMap            = this.getRowTypeMap.bind(this);
        this.getQuoteData             = this.getQuoteData.bind(this);
        this.countSums                = this.countSums.bind(this);
        this.updateTotals             = this.updateTotals.bind(this);
        this.quoteRowVisibilityFilter = this.quoteRowVisibilityFilter.bind(this);
        this.onListDataChange         = this.onListDataChange.bind(this);
        this.onDraggedRowDrop         = this.onDraggedRowDrop.bind(this);
        this.editData                 = this.editData.bind(this); 
        this.resetData                = this.resetData.bind(this);

        this.state = {
            anchorEl: null,
            quoteDataRows: this.reformatHeaders(this.props.data.headers),
            totals: this.countSums(),
            rowOrder: undefined,
            details: props.data.details,
            misc: props.data.details.misc
        };
    }


    componentDidMount() {
        super.componentDidMount();

        if(Number(this.props.id) > 0 || this.props.fromQuote)
            return;

        setTimeout(this.addFirstRows);
    }


    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.data.id < 0 && this.props.data.id !== prevProps.data.id && !this.props.fromQuote) {
            this.setState({ quoteDataRows: [], details: {} }, () => {
                setTimeout(this.addFirstRows, 100); // Ei hyvä.
            });

            return;
        }

        const { active: prevActive, ...prevDataRest } = prevProps.data;
        const { active: curActive, ...curDataRest }   = this.props.data;

        if(!isEqual(prevDataRest, curDataRest)) {
            const { headers, details } = this.props.data;

            this.setState({
                quoteDataRows: this.reformatHeaders(headers),
                totals: this.countSums(headers),
                details,
                misc: details.misc
            }); 
        }
    }

    getHeaderData = () => {
        return this.header.current.getComponentData();
    }

    getDeliveryAddress = () => {
        return this.header.current.getDeliveryAddress();
    }

    setAddressError = (valid) => {
        this.header.current.setAddressError(valid);
    }

    companyChanged = (company) => {
        this.header.current.supplierChanged({id: 0}, company);
    }

    accountChanged = (account) => {
        this.header.current.supplierChanged(account);
    }

    supplierCreated = (supplier) => {
        this.header.current.supplierCreated(supplier);
    }

    getDataRows = () => {
        return this.list.current.getVisibleRows().map(row => row.getData());
    }

    getMisc = () => {
        return _.cloneDeep(this.state.misc);
    }

    emptyNewData = () => {
        this.list.current.emptyNewData();
    }

    reformatHeaders(headers) {
        const quoteDataRows = [];

        (headers || []).forEach(header => {
            header["_type"] = "headerRow";

            quoteDataRows.push(header);

            header.rows.forEach(row => {
                row["_type"] = this.typeMap[row.type];

                quoteDataRows.push(cloneDeep(row));
            });
        });

        return quoteDataRows;
    }


    addFirstRows() {
        const ids = this.list.current.addNewRow({ _type: "headerRow" });

        setTimeout(() => this.list.current.addNewRow({ _type: "quoteRow", parentId: ids[0] }));
    }


    emptyNewData(cb = () => {}, origin = undefined) {
        this.list.current.emptyNewData(cb, origin);
    }


    getRowTypeMap(reversed = false) {
        return !reversed ? this.typeMap : this.typeMapReversed;
    }


    getQuoteData() {
        return this.list.current.getData();
    }


    markRowDeleted(id) {
        id = Number(id);

        const data = this.list.current.getData();
        const ids  = [id, ...data.filter(d => Number(d.parentId) === id).map(d => d.id)]

        this.list.current.editData({ deleted: 1 }, ids);
    }

    editData(...args) {
        this.list.current.editData(...args); 
    }


    resetData() {
        this.list.current.resetStateData();     
    }


    /*selectQuote = (id) => {
        const { quotes } = this.props;
        let foundQuote = null;

        if (id == -1) {
            foundQuote = quotes.find(c => c.active == 1);
            if (!foundQuote)
                foundQuote = quotes[0];
        }
        else
            foundQuote = quotes.find(c => c.id == id);

        if (foundQuote)
            return foundQuote;
        else
            return false;
    }*/

    countSums(headers) {
        const totals = {
            vat: 0,
            subtotal: 0,
            discount: 0
        };

        if (!headers) {
            return totals;
        }

        headers.map(h => h.rows.filter(row => row.deleted == 0).map(row => {
            if (row.type == 2)
                return;
            totals.vat += (row.quantity * row.cost) * (row.vat / 100);
            totals.subtotal += row.quantity * row.cost;
        }));

        return totals;
    }


    updateTotals(listData) {
        const totals = {
            vat: 0,
            subtotal: 0,
            discount: 0,
            vatsTotals: {}
        };

        listData.filter(r => r.parentId && r.parentId !== "0").forEach(row => {
            totals.vat      += (row.quantity * row.cost) * (row.vat / 100) || 0;
            totals.subtotal += Number(row.quantity * row.cost) || 0;

            //TODO: Some US states have vats with 3 decimals. Supporting 3 digits needs changes elsewhere too.
            totals.vatsTotals[Number(row.vat).toFixed(2)] = !totals.vatsTotals[Number(row.vat).toFixed(2)] ? (row.quantity * row.cost) * (row.vat / 100) || 0 : totals.vatsTotals[Number(row.vat).toFixed(2)] + (row.quantity * row.cost) * (row.vat / 100) || 0;
            totals.vatsTotals = Object.fromEntries(Object.entries(totals.vatsTotals).sort()); //sort vats percentages ascending
        });

        this.setState({ totals: totals });
    }


    quoteRowVisibilityFilter(row) {
        return row && (!row.deleted || row.deleted === "0");
    }


    onListDataChange(e) {
        const data = e.data.filter(r => !r.hasOwnProperty("deleted") || Number(r.deleted) === 0);

        this.updateTotals(data);
        this.props.onListDataChange(data);

        if(data.length === 0 && e.origin === "editData") {
            this.addFirstRows();
        }
    }


    onDraggedRowDrop(droppedRow, onRow, currentOrder, dataMap, listRef) {
        let dId        = Number(droppedRow.data.id);
        let strOnId    = typeof(onRow.data) === "object" ? String(onRow.data.id) : null;
        let onId       = typeof(onRow.data) === "object" ? Number(onRow.data.id) : null;
        const newOrder = [];
        const toMove   = [dId];
        let toFlip     = [dId];
        let after      = false;

        const onRowParentId = onRow.data.id !== "BOTTOM_MARKER" ? onRow.parentId || onRow.data.parentId : null;

        if(onRow.lastInGroup) {
            after = true; 

            const inHeader = listRef.getData()
                .filter(d => Number(d.parentId) === Number(onRowParentId));

            if(!inHeader || inHeader.length === 0) {
                onId = onRowParentId;
            } else {
                onId = Number(inHeader[inHeader.length - 1].id); 
            }
        }

        // If we're moving a group of rows.
        if(droppedRow.data['_type'] === "headerRow" || droppedRow.data['_type'] === "cpqHeaderRow") {
            listRef.getData()
                .filter(d => Number(d.parentId) === dId)
                .forEach(d => toMove.push(Number(d.id)));

            const treeMap = makeMapOfPrimitives(toMove, true);
            currentOrder  = currentOrder.filter(id => !treeMap[id]);
            toFlip        = clone(toMove);
        } else if((droppedRow.data.parentId && onRowParentId) && droppedRow.data.parentId !== "0" && Number(droppedRow.data.parentId) !== Number(onRowParentId)) { // If we're moving a row under a new parent (into a new group).
            toFlip.push(droppedRow.data.parentId);

            dataMap[dId].parentId = onRowParentId;
            currentOrder          = currentOrder.filter(id => id !== dId);
        } else {
            currentOrder = currentOrder.filter(id => id !== dId);
        }

        let cur;

        for(let i in currentOrder) {
            cur = currentOrder[i];

            if(cur === onId && !after) {
                toMove.forEach(id => newOrder.push(id))
            }

            newOrder.push(cur);

            if(cur === onId && after) {
                toMove.forEach(id => newOrder.push(id))            
            }
        }

        if(onRow.data.id === "BOTTOM_MARKER") {
            toMove.forEach(id => newOrder.push(id));
        }

        // Rows filtered out by List.props.visibilityFilter are not in currentOrder, 
        // so we have to add them manually to the 
        // data that will replace List's current data.
        const deletedRows = listRef.getData().filter(d => newOrder.indexOf(Number(d.id)) === -1);

        return [newOrder.map(id => dataMap[id]).concat(deletedRows), () => listRef.flipRows(toFlip)];
    }

    getClassName = (columnName) => {
        if (!this.props.data.print_exceptions)
            return "";
            
        return this.props.data.print_exceptions.includes(columnName) ? "hidePrint" : "";
    }

    togglHiddenColumns = (columnName) => {
        let exceptions = this.props.data.print_exceptions ? this.props.data.print_exceptions : [];
        const index = exceptions.indexOf(columnName);

        if (index > -1) {
            exceptions.splice(index, 1);
        } else {
            exceptions.push(columnName);
        }  

        this.props.quoteDataChanged(this.props.data.id, 'print_exceptions', exceptions);

    }

    getTranslation = (value) => {
        const { renderPrintPo, printLanguage } = this.props;
        return !renderPrintPo ? this.tr(value) : this.beTr(value, printLanguage || "en", {}, "general/backendTranslations/PurchaseOrderPrint");
    }

    render() {
        const { paperProps, editMode, id} = this.props;

        const totals        = this.state.totals;
        const quoteDataRows = this.state.quoteDataRows;
        const headerWidth   = 370;

        const rowProps = {
            ...this.props.rowProps, 
            markRowDeleted: this.markRowDeleted,
            formatCurrency: this.formatCurrency,
            context: this.context
        };

        const printProps = {
            renderPrintPo: this.props.renderPrintPo,
            printLanguage: this.props.printLanguage,
            printDateFormat: this.props.printDateFormat
        };

        const { company, currency} = rowProps;

        let totalRows = [];
        totalRows.push({grey: true, name: this.getTranslation("Subtotal"), sum: (totals.subtotal).toFixed(2)});

        if (totals.vatsTotals) {
            for (const [key, value] of Object.entries(totals.vatsTotals)) {
                if (Object.keys(totals.vatsTotals).length == 1) {
                    totalRows.push({grey: true, name: this.getTranslation("Vat") + ` ${key}%` , sum: (value).toFixed(2)});
                } else {
                    totalRows.push({greyvat: true, name: this.getTranslation("Vat") + ` ${key}%` , sum: (value).toFixed(2)});
                }
            } 
        }

        //add vat totals only if more than 1 different vat %
        if (totals.vatsTotals && Object.keys(totals.vatsTotals).length !== 1) {
            totalRows.push({grey: true, name: this.getTranslation("VAT Total"), sum: (totals.vat).toFixed(2)});
        }

        totalRows.push({black: true, name: this.getTranslation("Total"), sum: (totals.vat + totals.subtotal - totals.discount).toFixed(2)});

        const newPaperProps = Object.assign(paperProps, {paperMounted: this.paperMounted});

        return (
            <Paper ref={this.paper} {...newPaperProps} style={{ paddingBottom: "200px" }}>
                <PurchaseOrderHeader {...printProps} ref={this.header} company={company} id={id} data={this.state.details} editMode={editMode} onPrintRenderReady={this.props.onPrintRenderReady} poDataLoaded={this.props.poDataLoaded} {...rowProps} />
                <ContentArea>
                    <div id="purchaseorder-list-wrapper" className={`${editMode ? " editMode" : ""}`}>
                        <List
                            fluid
                            ref={this.list}
                            data={quoteDataRows}
                            rowHeight={26}
                            noStateData={true}
                            visibilityFilter={this.quoteRowVisibilityFilter}
                            hideBottomMarker={false}
                            controlOrder={true}
                            parentKey="parentId"
                            treeData={true}
                            noColorVariance={true}
                            reverseNewData={true}
                            renderNewDataAtEnd={true}
                            emptyNewDataOnUpdate={true}
                            minimizeRerendersInTrees={true}
                            onDataChange={this.onListDataChange}
                            rowDragging={true}
                            onDraggedRowDrop={this.onDraggedRowDrop}
                            dragDropIsAllowed={(rowToDrop, onRow) => {
                                if(rowToDrop.data.id === onRow.data.id) {
                                    return false;
                                }

                                const r                  = rowToDrop.data['_type'];
                                const o                  = onRow.data['_type'];
                                const onNormalRow        = o === "quoteRow" || o === "descriptionRow" || o === "productRow" || o === "cpqRow";
                                const droppedIsNormalRow = r === "quoteRow" || r === "descriptionRow" || r === "productRow" || r === "cpqRow";
                                const droppedIsHeaderRow = r === "headerRow" || r === "cpqHeaderRow";

                                return (
                                    (onRow.data.id === "BOTTOM_MARKER" && droppedIsHeaderRow)
                                    ||
                                    (onNormalRow && droppedIsNormalRow) 
                                    || 
                                    ((o === "headerRow" || o === "cpqHeaderRow") && droppedIsHeaderRow)
                                    ||
                                    (onRow.lastInGroup && r !== "headerRow" && r !== "cpqHeaderRow")
                                );
                            }} 
                            columnHeaderConfigKey="quoteRow"
                            className="quote-list"
                            userListSettingsKey="purchaseorder_rows_list"
                            rowProps={rowProps}
                            ignoreRowPropsChange={false}
                            showPageSelector={false}
                            newRowMap={{
                                headerRow: {
                                    text: this.tr("Header"),
                                    hidden_for_print: 0
                                },
                                descriptionRow: {
                                    description: this.tr("Description"),
                                    hidden_for_print: 0
                                },
                                quoteRow: {
                                    name: this.tr("Row"),
                                    quantity: 0.00,
                                    cost: 0.00,
                                    vat: 0,
                                    vatTotal: 0,
                                    sum: 0,
                                    type: 1,
                                    hidden_for_print: 0
                                },
                                productRow: {
                                    product_id: undefined,
                                    quantity: 0.00,
                                    cost: 0.00,
                                    vat: 0,
                                    vatTotal: 0,
                                    sum: 0,
                                    type: 3,
                                    hidden_for_print: 0
                                },
                                cpqRow: {
                                    cpqId: undefined,
                                    quantity: 0.00,
                                    cost: 0.00,
                                    vat: 0,
                                    vatTotal: 0,
                                    sum: 0,
                                    type: 4,
                                    hidden_for_print: 0
                                },
                                cpqHeaderRow: {
                                    cpqParentId: undefined,
                                    parentChosen: false,
                                    hidden_for_print: 0
                                }
                            }}
                            listRowTypeKey="_type"
                            listRowTypeMap={{
                                headerRow: QuoteHeaderRow,
                                descriptionRow: QuoteDescriptionRow,
                                quoteRow: QuoteRow,
                                productRow: QuoteRow, // Exactly the same component as QuoteRow, but with Product chosen as the initial worktype.
                                cpqRow: QuoteRow,
                                cpqHeaderRow: QuoteHeaderRow
                            }}
                            columns={{
                                headerRow:
                                    [
                                        { name: "context", width: 10 },
                                        { name: "move", width: 10 },
                                        { name: "text", width: headerWidth, className: this.getClassName("name") },
                                    ],
                                descriptionRow: 
                                    [
                                        { name: "context", width: 10 },
                                        { name: "move",  width: 10 },
                                        { name: "description", width: headerWidth, className: this.getClassName("name") },
                                    ],
                                quoteRow:
                                    [
                                        { field: "context", name: "context", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { name: "move", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { field: "name", name: "name", header: this.getTranslation("Rows"), width: 170, showMenu: false, resizeable: false, moveable: false, hideable: false, className: this.getClassName("name") },
                                        { field: "quantity", name: "quantity", header: this.getTranslation("Quantity"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("quantity") },
                                        { field: "cost", name: "cost", header: this.getTranslation("Unit cost"), width: 50, showMenu: false,resizeable: false,  moveable: false, hideable: false, alignRight: true, className: this.getClassName("cost") },
                                        { field: "vat", name: "vat", header: this.getTranslation("VAT %"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vat") },
                                        { field: "vatTotal", name: "vatTotal", header: this.getTranslation("VAT Total"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vatTotal") },
                                        { field: "sum", name: "sum", header: this.getTranslation("Sum 0%"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("sum") },
                                    ],
                                productRow:
                                    [
                                        { field: "context", name: "context", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { name: "move", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { field: "name", name: "name", header: this.getTranslation("Rows"), width: 170, showMenu: false, resizeable: false, moveable: false, hideable: false, className: this.getClassName("name") },
                                        { field: "quantity", name: "quantity", header: this.getTranslation("Quantity"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("quantity") },
                                        { field: "cost", name: "cost", header: this.getTranslation("Unit cost"), width: 50, showMenu: false,resizeable: false,  moveable: false, hideable: false, alignRight: true, className: this.getClassName("cost") },
                                        { field: "vat", name: "vat", header: this.getTranslation("VAT %"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vat") },
                                        { field: "vatTotal", name: "vatTotal", header: this.getTranslation("VAT Total"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vatTotal") },
                                        { field: "sum", name: "sum", header: this.getTranslation("Sum 0%"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("sum") },
                                    ],
                                cpqRow:
                                    [
                                        { field: "context", name: "context", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { name: "move", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                        { field: "name", name: "name", header: this.getTranslation("Rows"), width: 170, showMenu: false, resizeable: false, moveable: false, hideable: false, className: this.getClassName("name") },
                                        { field: "quantity", name: "quantity", header: this.getTranslation("Quantity"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("quantity") },
                                        { field: "cost", name: "cost", header: this.getTranslation("Unit cost"), width: 50, showMenu: false,resizeable: false,  moveable: false, hideable: false, alignRight: true, className: this.getClassName("cost") },
                                        { field: "vat", name: "vat", header: this.getTranslation("VAT %"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vat") },
                                        { field: "vatTotal", name: "vatTotal", header: this.getTranslation("VAT Total"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vatTotal") },
                                        { field: "sum", name: "sum", header: this.getTranslation("Sum 0%"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("sum") },
                                    ],
                                cpqHeaderRow:
                                    [
                                        { name: "context",  width: 10 },
                                        { name: "move",  width: 10 },
                                        { name: "text", width: headerWidth, className: this.getClassName("name") },
                                    ]
                            }}
                        />
                    </div>
                </ContentArea>
                <div class="footer-area">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={TaimerTheme}>
                                <JssProvider generateClassName={this.generateClassName}>
                                    <Footer>
                                        <FlexChild weight={5} style={{ paddingRight: "16px" }}>
                                            {this.state.misc && this.state.misc.length > 0 &&
                                                <div className={`notes-header hide-normal`}>{this.getTranslation("Notes")}</div>
                                            }
                                            <div className={`textarea hide-normal`}>{this.state.misc}</div>
                                            <TextField
                                                multiline
                                                disabled={!this.props.editMode}
                                                className={`textarea hide-print`}
                                                value={this.state.misc}
                                                placeholder={this.tr("Notes...")}
                                                inputProps={{
                                                    onFocus: (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }
                                                }}
                                                onChange={e => this.setState({ misc: e.target.value })} />
                                        </FlexChild> 
                                        <FlexChild weight={3}>
                                            <Totals className={this.getClassName("quoteTotal")} currency={currency} rows={totalRows} />
                                        </FlexChild>
                                    </Footer>
                                </JssProvider>
                            </ThemeProvider>
                    </StyledEngineProvider>
                </div>                      
            </Paper>
        );
    }
}

PurchaseOrder.defaultProps = {
	printMode: false,
    onListDataChange: () => {}
};

export default PurchaseOrder;