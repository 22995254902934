import React from 'react';
import TaimerComponent from '../../../TaimerComponent';
import BlockTopBar from './BlockTopBar';
import MyDayList from './MyDayList';
import { Add, Edit, Delete, AccessTime, CheckBox, RemoveRedEye, Chat, AddAlert, AddBox } from '@mui/icons-material';
import DataHandler from '../../../general/DataHandler';

import './ProjectsList.css';
import moment from 'moment';
import { Popover, MenuItem } from '@mui/material';
import colors from '../../../colors';
import ConfirmationPopUp from '../../../resourcing/views/ResourcingGrid/components/ConfirmationPopUp';
import { withSnackbar } from 'notistack';
import ProjectsListRow from './ProjectsListRow';
import MyDayDateRangeModal from './MyDayDateRangeModal';
import OutlinedField from '../../../general/OutlinedField';
import ResourceDialog from '../../../dialogs/ResourceDialog';

import HighestPriority from '../../../resourcing/images/priority/highest.svg';
import HighPriority from '../../../resourcing/images/priority/high.svg';
import NormalPriority from '../../../resourcing/images/priority/normal.svg';
import LowPriority from '../../../resourcing/images/priority/low.svg';
import LowestPriority from '../../../resourcing/images/priority/lowest.svg';
import InsightDropDown from '../../insights/InsightDropDown';
import { addDays } from 'date-fns';
import { getAutocompleteDataForDialog } from '../../../resourcing/helpers';
const emptyImage = require('../../images/ActivitiesGraphic.svg').default;

const MODE_KEY = 'my_day_projects_mode';
const COLUMNS_KEY = 'my_day_project_list_columns';

class ProjectsList extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'dashboard/my_day/components/ProjectsList');

        this._resourcingDataLoaded = false;
        this._hoverExitTimeout = null;

        const {
            functions: { checkPrivilege },
        } = this.context;

        this.rowHoverItems = [
            {
                icon: Edit,
                label: this.tr('Edit project'),
                action: (data) => this.showProject(data.id),
                checkRight: () => this._hasWriteRight(),
            },
            {
                icon: AccessTime,
                label: this.tr('Track hours'),
                action: (data) => this.startTimer(data),
                checkRight: () => checkPrivilege('workhours', 'write') || checkPrivilege('workhours', 'write_full'),
            },
            {
                icon: Chat,
                label: this.tr('Open chat'),
                action: (data) => this.openTeamchat(data.id),
                checkRight: () => checkPrivilege('newsfeed', 'newsfeed'),
            },
        ];

        this.rowMenuItems = this._noHover()
            ? [
                  {
                      icon: Edit,
                      label: this.tr('Edit project'),
                      action: (data) => this.showProject(data.id),
                      checkRight: () => this._hasWriteRight(),
                  },
                  {
                      icon: AccessTime,
                      label: this.tr('Track hours'),
                      action: (data) => this.startTimer(data),
                      checkRight: () => checkPrivilege('workhours', 'write') || checkPrivilege('workhours', 'write_full'),
                  },
                  {
                      icon: Chat,
                      label: this.tr('Open chat'),
                      action: (data) => this.openTeamchat(data.id),
                      checkRight: () => checkPrivilege('newsfeed', 'newsfeed'),
                  },
                  {
                      icon: AddAlert,
                      label: this.tr('Add Activity'),
                      checkRight: () => checkPrivilege('projects', 'project_crm_write'),
                      action: (data) =>
                          this._openActivityDialog({
                              customers_id: data.account_id,
                              projects_id: data.id,
                          }),
                  },
                  {
                      icon: AddBox,
                      label: this.tr('Add Task'),
                      checkRight: () => checkPrivilege('projects', 'project_resourcing_write'),
                      action: (data) =>
                          this.context.functions.addResource({
                              projects_id: data.id,
                          }),
                  },
                  {
                      icon: RemoveRedEye,
                      label: this.tr('View Account'),
                      checkRight: () => checkPrivilege('customers', 'read'),
                      action: (data) => this.showAccount(data.account_id),
                  },
              ]
            : [
                  {
                      icon: AddAlert,
                      label: this.tr('Add Activity'),
                      checkRight: () => checkPrivilege('projects', 'project_crm_write'),
                      action: (data) =>
                          this._openActivityDialog({
                              customers_id: data.account_id,
                              projects_id: data.id,
                          }),
                  },
                  {
                      icon: AddBox,
                      label: this.tr('Add Task'),
                      checkRight: () => checkPrivilege('projects', 'project_resourcing_write'),
                      action: (data) =>
                          this.context.functions.addResource({
                              projects_id: data.id,
                          }),
                  },
                  {
                      icon: RemoveRedEye,
                      label: this.tr('View Account'),
                      checkRight: () => checkPrivilege('customers', 'read'),
                      action: (data) => this.showAccount(data.account_id),
                  },
              ];

        this.defaultModes = [
            {
                key: 'all',
                label: this.tr('All'),
                action: () => this._setMode('all'),
            },
            {
                key: 'won_deals',
                label: this.tr('Won Deals'),
                action: () => this._setMode('won_deals'),
            },
            {
                key: 'internal',
                label: this.tr('Internal'),
                action: () => this._setMode('internal'),
            },
        ];

        this.roles = [
            {
                key: 'any',
                label: this.tr('Any'),
                action: () => this._setRole('any'),
            },
            {
                key: 'sales_agent',
                label: this.tr('Sales Agent'),
                action: () => this._setRole('sales_agent'),
            },
            {
                key: 'project_manager',
                label: this.tr('Project Manager'),
                action: () => this._setRole('project_manager'),
            },
            {
                key: 'team_member',
                label: this.tr('Team Member'),
                action: () => this._setRole('team_member'),
            },
        ];

        this._allColumns = [
            {
                field: 'actions',
                name: 'actions',
                header: '',
                width: 50,
                content: this._hasWriteRight() ? this._renderAddButton() : null,
                resizeable: false,
                moveable: false,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'project_account',
                name: 'project_account',
                header: this.tr('Project/Account'),
                width: 160,
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: false,
                hideable: false,
                showMenu: true,
            },
            {
                field: 'funnel_stage',
                name: 'funnel_stage',
                header: this.tr('Funnel/Stage'),
                width: 150,
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: true,
                hideable: false,
                showMenu: true,
            },
            {
                field: 'value_margin',
                name: 'value_margin',
                header: this.tr('Value/Margin'),
                width: 150,
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: true,
                hideable: false,
                showMenu: true,
            },
            {
                field: 'close_date',
                name: 'close_date',
                header: this.tr('Exp. Close Date'),
                width: 180,
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: true,
                hideable: false,
                showMenu: true,
            },
            {
                field: 'next_activity',
                name: 'next_activity',
                header: this.tr('Next Activity'),
                width: 180,
                checkRight: () => checkPrivilege('projects', 'project_crm_read'),
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: true,
                hideable: false,
                showMenu: true,
            },
            {
                field: 'tracked_resourced',
                name: 'tracked_resourced',
                header: this.tr('Tracked/Resourced h'),
                width: 160,
                resizeable: true,
                hideColumnsFromMenu: true,
                moveable: true,
                hideable: false,
                showMenu: true,
            },
            // {
            //   field: "invoiced_billable",
            //   name: "invoiced_billable",
            //   header: this.tr("Invoiced/Billable Materials"),
            //   width: 200,
            //   resizeable: false,
            //   moveable: true,
            //   hideable: false,
            //   showMenu: false
            // },
            // {
            //   field: "budgeted_actual",
            //   name: "budgeted_actual",
            //   header: this.tr("Budgeted/Actual Costs"),
            //   width: 180,
            //   resizeable: false,
            //   moveable: true,
            //   hideable: false,
            //   showMenu: false
            // },
            // {
            //   field: "net_gross",
            //   name: "net_gross",
            //   header: this.tr("Net/Gross Profit"),
            //   width: 160,
            //   resizeable: false,
            //   moveable: true,
            //   hideable: false,
            //   showMenu: false
            // }
        ];

        this._defaultColumns = ['actions', 'project_account', 'funnel_stage', 'value_margin', 'close_date', 'next_activity', 'tracked_resourced'];

        const selectedColumns = [...new Set(this._getColumnsFromLS(this._defaultColumns))];

        this.stickySearchKey = 'my_projects_list';

        const mode = 'won_deals';
        const role = 'team_member';

        this.state = {
            mode,
            role,
            modes: this.defaultModes,
            hoverId: null,
            trackingId: null,
            timerLabel: '00:00',
            trackerStart: null,
            data: [],
            searchText: '',
            pipelines: [],
            stages: [],
            loading: true,
            resourcingData: {
                employees: [],
                all_employees: [],
                autoCompleteData: {},
            },
            dataLoaded: false,
            updatedValues: {},
            selectedColumns,
            currency: 'USD',
            pageData: {
                perPage: 30,
                totalCount: 0,
                pageCount: 1,
                page: 1,
                sortby: 'funnel_stage',
                sortasc: false,
            },
        };
    }

    _noHover = () => {
        return window.matchMedia('(hover: none)').matches;
    };

    _getDropdownColumns = () => {
        return this._allColumns.filter((col) => {
            if (!col.moveable) return false;
            if (col.checkRight) {
                return col.checkRight();
            }
            return true;
        });
    };

    _checkIfAllowedColumn = (col) => {
        if (col.checkRight) {
            return col.checkRight();
        }
        return true;
    };

    _getActiveColumns = () => {
        const { selectedColumns } = this.state;
        return selectedColumns.reduce((filtered, col) => {
            const found = this._allColumns.find((obj) => obj.name == col);
            if (found && this._checkIfAllowedColumn(found)) {
                filtered.push(found);
            }
            return filtered;
        }, []);
    };

    componentDidMount = () => {
        super.componentDidMount();
        this._setTimerFromProps();
        this._getCurrency();
        this.initializeStickySearch();
    };

    initializeStickySearch = () => {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` })
            .done((response, _, request) => {
                if (request.status !== 200) {
                    this._getData();
                    return;
                }
                this.setState({ ...response, pageData: { ...this.state.pageData, perPage: response.perpage || this.state.pageData.perPage } }, () => this._getData());
            })
            .fail(() => {
                this._getData();
            });
    };

    saveStickySearch = () => {
        const filters = {
            perpage: this.state.pageData.perPage,
        };
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}` }, { search: filters });
    };

    shouldComponentUpdate = (newProps, newState) => {
        return newState != this.state || newProps.timers != this.props.timers;
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.timers != this.props.timers) {
            this._setTimerFromProps();
        }
    };

    _setTimerFromProps = () => {
        const { timers } = this.props;
        let trackerStart;
        let trackingId;
        if ((timers || []).length > 0) {
            (timers || []).forEach((timer) => {
                if (timer.projects_id) {
                    trackerStart = moment.utc(timer.start, 'YYYY-MM-DD HH:mm:ss').local();
                    trackingId = timer.projects_id;
                }
            });
        }
        this.setState({ trackerStart, trackingId }, () => {
            if (trackingId) {
                this._hourTimer = setInterval(() => {
                    const { trackerStart } = this.state;
                    const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                    const timerLabel = this._toHHMMSS(secondsSinceStart);
                    this.setState({ timerLabel });
                }, 1000);
            }
        });
    };

    _getResourcingEmployees = () => {
        const { tr } = this;
        return new Promise((resolve) => {
            DataHandler.get({
                url: `resourcing/get_data`,
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().format('YYYY-MM-DD'),
                company: this.context.userObject.companies_id,
            }).done((data) => {
                if (!data.employees) {
                    data.employees = [];
                }
                data.employees.unshift({ id: '', label: tr('All') });
                data.employees.push({ id: 0, employee: tr('Unassigned') });
                data.employees = data.employees.map((el) => ({
                    label: el.employee,
                    ...el,
                }));
                resolve(data);
            });
        });
    };

    _getResourcingAutoCompleteData = () => {
        const { tr } = this;
        const {
            userObject: { companies_id },
        } = this.context;

        return getAutocompleteDataForDialog(companies_id);
    };

    _openActivityDialog = (activityDialogData) => {
        this.context.functions.openActivitySlider(activityDialogData, { afterSave: this._getData });
    };

    _checkPrivilege = (group, perm, company) => {
        if (this.props.checkPrivilege) return this.props.checkPrivilege(group, perm);
        return this.context.functions.checkPrivilege(group, perm, company);
    };

    _openHourEntryDialog = (data) => {
        this.context.functions.addHours(
            {
                customer: {
                    id: data.account_id || 0,
                },
                project: {
                    id: data.id || 0,
                },
            },
            { parentComponent: 'MyDay - Project List', afterSaveCallback: this._onHourEntrySaved }
        );
    };

    _toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num / 60) % 60;
        var seconds = sec_num % 60;

        return [hours, minutes, seconds]
            .map((v) => (v < 10 ? '0' + v : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    };

    showProject = (projectId) => {
        this.context.functions.updateView({ module: 'projects', action: 'view', id: projectId });
    };

    showAccount = (accountId) => {
        this.context.functions.updateView({ module: 'customers', action: 'view', id: accountId });
    };

    openTeamchat = (projectId) => {
        const {
            functions: { openTeamChat },
        } = this.context;

        openTeamChat({
            module: 'projects',
            action: 'view',
            id: projectId,
        });
    };

    _showTimerConfirmation = (data) => {
        this.props.setPopUp(
            <ConfirmationPopUp
                header={this.tr('Are you sure you want to end your current timer?')}
                desc={this.tr("If you start this timer, your current running timer will stop and you'll be able to save that hour entry.")}
                rightButtons={[
                    {
                        label: this.tr('End current timer'),
                        style: { backgroundColor: '#dc4054', color: 'white' },
                        onClick: async () => {
                            this.props.setPopUp(null);

                            this.context.functions.naviStopTimeTracker(async () => {
                                if (this.context.functions.naviStartTimeTracker) {
                                    const startData = await this.context.functions.naviStartTimeTracker(
                                        {
                                            customers_id: data.account_id,
                                            projects_id: data.id,
                                        },
                                        true
                                    );
                                    if (!startData) return;
                                }
                                this.setState({ trackerStart: moment(), trackingId: data.id }, () => {
                                    this._hourTimer = setInterval(() => {
                                        const { trackerStart } = this.state;
                                        const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                                        const timerLabel = this._toHHMMSS(secondsSinceStart);
                                        this.setState({ timerLabel });
                                    }, 1000);
                                });
                            });
                        },
                    },
                ]}
                leftButtons={[
                    {
                        style: { backgroundColor: 'transparent', color: '#34495e' },
                        label: this.tr('Cancel'),
                        onClick: () => {
                            this.props.setPopUp(null);
                        },
                    },
                ]}
            />
        );
    };

    startTimer = async (data) => {
        if (this.props.timers.length > 0) {
            this._showTimerConfirmation(data);
            return;
        } else {
            if (this.context.functions.naviStartTimeTracker) {
                const startData = await this.context.functions.naviStartTimeTracker({
                    customers_id: data.account_id,
                    projects_id: data.id,
                });
                if (!startData) return;
            }
            this.setState({ trackerStart: moment(), trackingId: data.id }, () => {
                this._hourTimer = setInterval(() => {
                    const { trackerStart } = this.state;
                    const secondsSinceStart = moment().diff(trackerStart, 'seconds');
                    const timerLabel = this._toHHMMSS(secondsSinceStart);
                    this.setState({ timerLabel });
                }, 1000);
            });
        }
    };

    endTimer = async () => {
        return await this.context.functions.naviStopTimeTracker();
    };

    _onHourEntrySaved = () => {
        setTimeout(this._getData, 1000);
    };

    onPageSettingsChange = (pageData) => {
        this.setState({ pageData: { ...this.state.pageData, ...pageData } }, () => {
            this._getData();
        });
    };

    _funnelsWithPipelines = (pipelines) => {
        const modes = [...this.defaultModes];
        modes.splice(
            1,
            0,
            ...(pipelines || []).map((p) => ({
                ...p,
                label: p.name,
                key: p.id,
                action: () => this._setMode(p.id),
            }))
        );
        return modes;
    };

    _getIdFromMode = (mode) => {
        switch (mode) {
            case 'all':
                return 0;
            case 'won_deals':
                return -1;
            case 'internal':
                return -5;
            default:
                return mode;
        }
    };

    _getCurrency = () => {
        const {
            userObject: { companies_id },
        } = this.context;

        DataHandler.get({
            url: `subjects/companies/projects/read`,
            currency: 1,
        }).done((companies) => {
            let currency = 'USD';
            (companies || []).forEach((company) => {
                if (company.id == companies_id) {
                    currency = company.currency;
                }
            });
            if (!currency && (companies || []).length > 0) {
                currency = companies[0].currency;
            }
            this.setState({ currency });
        });
    };

    _getData = () => {
        const { mode, role, searchText, pageData } = this.state;
        const newPageData = { ...pageData };

        const {
            userObject: { usersId, companies_id },
        } = this.context;
        this.setState({ loading: true }, () => {
            const params = {
                funnel: this._getIdFromMode(mode),
                freetext: searchText,
                role: role,
                user: usersId,
                company: companies_id,
                sort: {
                    name: pageData.sortby,
                    asc: pageData.sortasc,
                },
                ...newPageData,
            };
            this.saveStickySearch();
            //mode -1 = won deals, -5 = internal, > 0 = pipeline_id
            DataHandler.get({ url: `projects/myday/list`, ...params }).done((data) => {
                const modes = this._funnelsWithPipelines(data.pipelines);

                this.setState({
                    data,
                    dataLoaded: true,
                    currency: data.currency,
                    pageData: {
                        ...newPageData,
                        pageCount: data.pageData.pageCount || 1,
                        totalCount: data.pageData.totalCount || 0,
                    },
                    mode,
                    modes,
                    loading: false,
                    updatedValues: {},
                });
            });
        });
    };

    onRowHoverEnter = (hoverId) => {
        if (this._noHover()) {
            return;
        }
        if (this._hoverExitTimeout) {
            clearTimeout(this._hoverExitTimeout);
            this._hoverExitTimeout = null;
        }
        this.setState({ hoverId });
    };

    onRowHoverExit = () => {
        if (this._noHover()) {
            return;
        }
        this._hoverExitTimeout = setTimeout(() => {
            this.setState({ hoverId: null });
        }, 200);
    };

    _getColumnsFromLS = (defaultValue) => {
        let item = defaultValue;
        try {
            item = localStorage.getItem(COLUMNS_KEY) || defaultValue;
        } catch (e) {}
        if (!Array.isArray(item)) {
            item = item.split(',');
        }
        return item;
    };

    _renderAddButton = () => {
        return (
            <div className="add-button-container">
                <button onClick={this._addNewProject}>
                    <Add className="icon" />
                </button>
            </div>
        );
    };

    _closePopup = () => {
        this.props.setPopUp(null);
    };

    _updateProjectWithObject = (object, saveObject, project) => {
        const { id } = project;
        const updatedValues = {
            ...this.state.updatedValues,
            [id]: {
                ...(this.state.updatedValues[id] ?? {}),
                ...object,
            },
        };

        this.setState({ loading: true, updatedValues, rowEditData: null }, () => {
            DataHandler.put({ url: `projects/${id}` }, saveObject).done(() => {
                this._showSnackbar(this.tr('Changes saved successfully!'), true);
                window.dispatchEvent(new Event('projectSaved'));
                setTimeout(() => {
                    this._getData();
                }, 1000);
            });
        });
    };

    _updateProject = (key, value, project) => {
        const { id } = project;
        const updatedValues = {
            ...this.state.updatedValues,
            [id]: {
                ...(this.state.updatedValues[id] ?? {}),
                [key]: value,
            },
        };

        const updated = updatedValues[id];

        let data = {};
        if (key == 'value')
            data = {
                costest_sum: value,
                costest_margin: updated.margin ?? project.margin,
            };
        if (key == 'margin')
            data = {
                costest_sum: updated.value ?? project.value,
                costest_margin: value,
            };
        if (key == 'close_date') data = { closing_date: value };

        this.setState({ loading: true, updatedValues }, () => {
            DataHandler.put({ url: `projects/${id}` }, data).done(() => {
                this._showSnackbar(this.tr('Changes saved successfully!'), true);
                window.dispatchEvent(new Event('projectSaved'));
            });
        });
    };

    _showSavingSnackbar = () => {
        const { enqueueSnackbar } = this.props;
        return enqueueSnackbar(this.tr('Saving...'), {
            variant: 'info',
            persist: true,
        });
    };

    _closeSnackbar = (key) => {
        const { closeSnackbar } = this.props;
        closeSnackbar(key);
    };

    _showSnackbar = (message, success) => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    _addNewProject = () => {
        this.context.functions.addProject();
    };

    _setMode = (mode) => {
        this.setState({ mode }, () => this.onPageSettingsChange({ page: 1 }));
        try {
            localStorage.setItem(MODE_KEY, mode);
        } catch (e) {}
    };

    _setRole = (role) => {
        this.setState({ role }, () => this.onPageSettingsChange({ page: 1 }));
    };

    _hasWriteRight = () => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        return checkPrivilege('projects', 'write');
    };

    _getEmptyOverlayContent = () => {
        return this._hasWriteRight() ? (
            <p>
                {this.tr('Nothing to do?')} <a onClick={this._addNewProject}>{this.tr('Add a project')}</a>
            </p>
        ) : (
            <p>{this.tr('There are no projects.')}</p>
        );
    };

    _getStatusData = (funnelId) => {
        if (Number(funnelId) > 0) {
            return {
                projects_pipelines_id: funnelId,
                status: '0',
                status_date: moment().format('YYYY-MM-DD'),
            };
        } else if (Number(funnelId) == -1) {
            return {
                projects_pipelines_id: '0',
                status: '1',
                status_date: moment().format('YYYY-MM-DD'),
            };
        } else if (Number(funnelId) == -5) {
            return {
                projects_pipelines_id: '0',
                status: '5',
                status_date: moment().format('YYYY-MM-DD'),
            };
        }
    };

    _setCloseDate = (dateRange, data) => {
        if (!dateRange.endDate) return;
        this._updateProject('close_date', moment(dateRange.endDate).format('YYYY-MM-DD'), data);
    };

    _textFieldChanged = (evt, key, project) => {
        const { fromBlur } = evt;
        if (key == 'stage') {
            if (fromBlur) return;
            const {
                data: { stages },
            } = this.state;
            const value = evt.target.value;
            const stage = stages.find((s) => s.id == value) || {};
            this._updateProjectWithObject({ stages_id: stage.id, stage: stage.name }, { projects_sales_states_id: stage.id }, project);
            return;
        } else if (key == 'funnel') {
            if (fromBlur) return;
            const {
                data: { pipelines },
            } = this.state;
            const value = evt.target.value;
            const funnel = this._getAllFunnels(pipelines).find((s) => s.id == value) || {};
            const stages = this._getStagesForFunnel(project, funnel);
            let stage = {};
            if (stages.findIndex((s) => s.id == project.stages_id) == -1) {
                const stageObj = stages[0] || {};
                stage = { stages_id: stageObj.id || '-1', stage: stageObj.name || '' };
            }
            this._updateProjectWithObject({ funnel_id: funnel.id, funnel: funnel.name, ...stage }, this._getStatusData(funnel.id), project);
            return;
        }
        if (!fromBlur) return;
        const value = evt.currentTarget.value;
        this._updateProject(key, value, project);
    };

    _getStagesForFunnel = (data, funnel = {}) => {
        const {
            updatedValues,
            data: { stages },
        } = this.state;
        const funnelId = funnel.id || (updatedValues[data.id] && updatedValues[data.id].funnel_id ? updatedValues[data.id].funnel_id : data.funnel_id || '');
        return (stages || []).filter((s) => s.projects_pipelines_id == funnelId);
    };

    _getAllFunnels = (pipelines) => {
        const funnels = [...pipelines];
        funnels.push({ id: '-1', name: this.tr('Won Deal') });
        funnels.push({ id: '-5', name: this.tr('Internal Project') });
        return funnels;
    };

    _getEditPopUpComponent = (column, data) => {
        const {
            currency,
            data: { pipelines },
            updatedValues,
        } = this.state;
        switch (column) {
            case 'close_date': {
                let date = moment();
                if (data.close_date && data.close_date != '0000-00-00') {
                    date = moment(data.close_date, 'YYYY-MM-DD');
                }
                const dateRange = {
                    startDate: date.toDate(),
                    endDate: date.toDate(),
                    key: 'selection',
                };
                return (
                    <MyDayDateRangeModal
                        initialDateRange={dateRange}
                        mode={'date'}
                        saveChanges={(dateRange) => {
                            this.setState({ rowEditData: null });
                            this._setCloseDate(dateRange, data);
                        }}
                    />
                );
            }
            case 'value_margin':
                return (
                    <div style={{ padding: 15 }}>
                        <OutlinedField
                            label={this.tr('Deal Value')}
                            name="costest_sum"
                            value={Number(data.value || 0).toFixed(2)}
                            currency={currency}
                            format="currency"
                            validation={['numeric']}
                            fullWidth={true}
                            onChange={(evt) => this._textFieldChanged(evt, 'value', data)}
                        />
                        <OutlinedField
                            label={this.tr('Deal Margin')}
                            name="costest_margin"
                            value={Number(data.margin || 0).toFixed(2)}
                            currency={currency}
                            format="currency"
                            validation={['numeric']}
                            fullWidth={true}
                            onChange={(evt) => this._textFieldChanged(evt, 'margin', data)}
                        />
                    </div>
                );
            case 'funnel_stage':
                return (
                    <div style={{ padding: 15 }}>
                        <OutlinedField
                            label={this.tr('Funnel')}
                            name="funnel"
                            value={updatedValues[data.id] && updatedValues[data.id].funnel_id ? updatedValues[data.id].funnel_id : data.funnel_id || ''}
                            select
                            fullWidth={true}
                            onChange={(evt) => this._textFieldChanged(evt, 'funnel', data)}
                        >
                            {this._getAllFunnels(pipelines).map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </OutlinedField>
                        <OutlinedField
                            label={this.tr('Stage')}
                            name="stage"
                            select
                            value={updatedValues[data.id] && updatedValues[data.id].stages_id ? updatedValues[data.id].stages_id : data.stages_id || ''}
                            fullWidth={true}
                            onChange={(evt) => this._textFieldChanged(evt, 'stage', data)}
                        >
                            {this._getStagesForFunnel(data).map((row) => (
                                <MenuItem key={row.id} value={row.id}>{`${row.name} ${Math.round(Number(row.probability_percent || 0) * 100)}%`}</MenuItem>
                            ))}
                        </OutlinedField>
                    </div>
                );
            default:
                return null;
        }
    };

    _stopLoading = () => {
        this.setState({ loading: false });
    };

    showEditPopUp = (anchor, column, data) => {
        const {
            functions: { hasPrivilege },
        } = this.context;
        if (column == 'tracked_resourced') {
            if (!hasPrivilege('workhours', 'write_full') && !hasPrivilege('workhours', 'write')) {
                return;
            }
            this._openHourEntryDialog(data);
            return;
        }
        if (!this._hasWriteRight()) {
            return;
        }
        this.setState({
            rowEditData: {
                anchor,
                data,
                column,
            },
        });
    };

    _renderRowEditPopUp = () => {
        const { rowEditData } = this.state;
        if (!rowEditData) return null;
        const { anchor, column, data } = rowEditData;
        return (
            <Popover
                open={Boolean(anchor)}
                elevation={4}
                anchorEl={anchor}
                onClose={() =>
                    this.setState({ rowEditData: null }, () => {
                        setTimeout(() => {
                            this._getData();
                        }, 1000);
                    })
                }
                anchorOrigin={{
                    vertical: 48,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {this._getEditPopUpComponent(column, data)}
            </Popover>
        );
    };

    _saveColumns = (selectedColumns) => {
        this.setState({ selectedColumns });
        try {
            localStorage.setItem(COLUMNS_KEY, selectedColumns.join(','));
        } catch (e) {}
    };

    _toggleColumn = (column) => {
        const selectedColumns = [...this.state.selectedColumns];
        const index = selectedColumns.indexOf(column.name);
        const newIndex = this._allColumns.findIndex((c) => c.field == column.field);
        index == -1 ? selectedColumns.splice(newIndex, 0, column.name) : selectedColumns.splice(index, 1);
        this._saveColumns(selectedColumns);
    };

    _renderViewOptionsButton = () => {
        const { role } = this.state;

        return (
            <div className="extra-top-items">
                <InsightDropDown style={{ marginRight: 10 }} title={this.tr('Role')} selected={role} tabs={this.roles} />
            </div>
        );
    };

    _renderResourceLoadingIndicator = () => {
        if (!this.state.loadingResourcingData) return null;
        return (
            <div className="loading-view">
                <div>
                    <img src={require('../../insights/img/loading.svg').default} />
                    {this.tr('Loading resourcing data...')}
                </div>
            </div>
        );
    };

    onSearchChanged = (searchText) => {
        this.setState({ loading: true });
        this._searchTimeout && clearTimeout(this._searchTimeout);
        this._searchTimeout = setTimeout(() => {
            this.setState({ searchText }, () => {
                this.onPageSettingsChange({ page: 1 });
            });
        }, 1000);
    };

    onSortRows = (sortby, sortasc) => {
        this.setState({ pageData: { ...this.state.pageData, page: 1, sortby, sortasc } }, () => this._getData());
    };

    render() {
        const { mode, data, dataLoaded, pageData, loading, trackingId, timerLabel, updatedValues, currency, hoverId, modes } = this.state;

        return (
            <div className="my-day-projects-list">
                <BlockTopBar
                    title={this.tr('My Projects')}
                    dropdownTitle={this.tr('Funnels')}
                    dropdownItems={modes}
                    selected={mode}
                    loading={loading}
                    allowSearch={true}
                    onSearchChanged={this.onSearchChanged}
                    viewButton={{
                        title: this.tr('Edit columns'),
                        items: this._getDropdownColumns(),
                        selectedItems: this.state.selectedColumns,
                        onItemClick: this._toggleColumn,
                    }}
                    extraTopBarComponents={this._renderViewOptionsButton()}
                />
                <MyDayList
                    data={data.projects}
                    columns={this._getActiveColumns()}
                    onColumnOrderChange={this._saveColumns}
                    emptyOverlayData={{
                        image: emptyImage,
                        content: this._getEmptyOverlayContent(),
                    }}
                    rowProps={{
                        onMouseEnter: this.onRowHoverEnter,
                        onMouseLeave: this.onRowHoverExit,
                        onCreateActivity: (data) =>
                            this._openActivityDialog({
                                customers_id: data.account_id,
                                projects_id: data.id,
                            }),
                    }}
                    showPageSelector={true}
                    totalCount={pageData.totalCount}
                    perpage={pageData.perPage}
                    page={pageData.page}
                    pageCount={pageData.pageCount}
                    controlPage={true}
                    onPerPageChange={(perPage) => {
                        this.onPageSettingsChange({ perPage, page: 1 });
                    }}
                    onPageChange={(page) => {
                        this.onPageSettingsChange({ page: page });
                    }}
                    onSortRows={this.onSortRows}
                    sharedData={{
                        menuItems: this.rowMenuItems,
                        hoverItems: this.rowHoverItems,
                        hoverId,
                        trackingId,
                        timerLabel,
                        endTimer: this.endTimer,
                        showEditPopUp: this.showEditPopUp,
                        updatedValues,
                        currency,
                    }}
                    showEmptyOverlay={(data.projects || []).length == 0 && dataLoaded}
                    listRowType={ProjectsListRow}
                />
                {this._renderRowEditPopUp()}
                {this._renderResourceLoadingIndicator()}
            </div>
        );
    }
}

export default withSnackbar(ProjectsList);
