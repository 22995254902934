import React from "react";

import _, { conforms } from "lodash";
import cn from "classnames";
import moment from "moment";
import { Calendar } from "react-big-calendar";
import localizer from "react-big-calendar/lib/localizers/moment";
import PropTypes from "prop-types";
import DataHandler from "../../general/DataHandler";
import TimeSlotWrapper from "../Wrappers/TimeSlotWrapper";
import TaimerComponent from "../../TaimerComponent";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import GoogleEventDialog from "../../dialogs/GoogleEventDialog";
import GoogleEventWrapper from "../Wrappers/EventWrapper";
import PersonalMeetingLinkDialog from "../../dialogs/PersonalMeetingLinkDialog";
import TaimerCalendarToolbar from "./TaimerCalendarToolbar";
import TaimerCalendarSidebar from "./TaimerCalendarSidebar";
import { withSnackbar } from "notistack";
import { SettingsContext } from "../../SettingsContext";
import { getColorClass, getColorMarking } from "./Colors.js";
import { Timer, Delete, Edit,CalendarToday, RadioButtonUnchecked, GroupAdd, LocationOn, Videocam } from '@mui/icons-material';
import { ReactComponent as Activity } from '../../navigation/ActionIcons/activities.svg';
import ContentSlider from '../../general/ContentSlider';
import PopoverMenu from './PopoverMenu';

const momentLocalizer = localizer(moment);
const DnDCalendar = withDragAndDrop(Calendar, { backend: false });

/**
 * TaimerCalendarView
 *
 */
class TaimerCalendarView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "taimercalendar/CalendarComponents/TaimerCalendarView");

        this.components = {
            toolbar: TaimerCalendarToolbar,
            event: GoogleEventWrapper,
            timeSlotWrapper: TimeSlotWrapper,
        };

        this.state = {
            selectable: true,
            resizable: true,
            events: [],
            googlecalendars: [],
            officecalendars: [],
            dialogEvent: undefined,
            currentView: "week",
            nextCurrentView: "week",
            currentDate: moment().toDate(),
            nextCurrentDate: moment().toDate(),
            currentMonth: new Date().getMonth(),
            dialogcalendars: [],
            contacts: [],
            collapses: [],
            sliderEventFields: undefined,
            sliderVisible: false,
            editPersonalMeetingLink: false,
            personalMeetingLink: '',
            companyActivities: []
        };

        this.accurary = 15;
        this.doubleClick = false;

        this.messages = {
            date: this.tr("Date"),
            time: this.tr("Time"),
            event: this.tr("Event"),
            allDay: this.tr("All day"),
            week: this.tr("Week"),
            work_week: this.tr("Work week"),
            day: this.tr("Day"),
            month: this.tr("Month"),
            previous: this.tr("Back"),
            next: this.tr("Next"),
            yesterday: this.tr("Yesterday"),
            tomorrow: this.tr("Tomorrow"),
            today: this.tr("Today"),
            agenda: this.tr("Agenda"),
            noEventsInRange: this.tr("there are no events in this range"),
            showMore: total => `+${total} ${this.tr("more")}`,
        };

        const { userObject } = context;
        const prefix = "TaimerCalendar_" + userObject.usersId;
        this.localStorageGoogleCalendars = prefix + "_Google_Calendars";
        this.localStorageGoogleCalendarsCollapses = prefix + "_Google_Calendars_Collapse";
        this.localStorageOfficeCalendars = prefix + "_Office_Calendars";
        this.localStorageOfficeCalendarsCollapses = prefix + "_Office_Calendars_Collapse";
        this.fullname = userObject.fullname;
    }

    /**
     * TaimerCalendarView componentDidMount
     *
     */
    componentDidMount = () => {
        super.componentDidMount();
        this.setCollapses();
        this.getCalendars();
        //this.getCalendars();
        //this.getOfficeCalendars();
        this.getCompanyActivities();
        this.getContactsAndEmployees();
        this.setState({personalMeetingLink: this.context.userObject.personal_meeting_link});
    };

    /**
     * Sets sidebars calendars list open or closed
     *
     */
    setCollapses = () => {
        if (this.localStorageGoogleCalendarsCollapses in localStorage) {
            try {
                const collapses = JSON.parse(localStorage[this.localStorageGoogleCalendarsCollapses]);

                if (Array.isArray(collapses)) {
                    this.setState({ collapses });
                } else {
                    this.setState({ collapses: [] });
                }
            } catch (error) {
                this.setState({ collapses: [] });
            }
        } else if (this.localStorageOfficeCalendarsCollapses in localStorage) {  //Currently we do not allow both google and office so else if does the trick
            try {
                const collapses = JSON.parse(localStorage[this.localStorageOfficeCalendarsCollapses]);

                if (Array.isArray(collapses)) {
                    this.setState({ collapses });
                } else {
                    this.setState({ collapses: [] });
                }
            } catch (error) {
                this.setState({ collapses: [] });
            }
        } else {
            this.setState({ collapses: [] });
        }
    };

    /**
     * Logout from google client
     *
     */
    googleLogout = () => {
        const { changeAuthStatus } = this.props;
        DataHandler.post({ url: "calendar/google/logout" })
            .done(response => {
                changeAuthStatus();
            })
            .fail(err => {
                changeAuthStatus();
            });
    };

    /**
     * Gets all Outlook calendars and their events
     *
     */
    getContactsAndEmployees = () => {
        let contacts  = [];
        let employees = [];

        //Get the contacts for events 
        DataHandler.get({ url: `subjects/contacts` }).done((response) => {
            
            contacts = response;
            
            DataHandler.get({ url: `subjects/employees/${this.context.userObject.companies_id}` }).done((res) => {
                employees = res.filter(employee => employee.deleted === "0");
                this.setState({ contacts: _.unionBy(employees,contacts,'email') });
            });
        });
    }
    /**
     * Gets all Google or Office calendars and their events
     *
     */

    getCalendars = (date = this.state.currentDate, view = this.state.currentView) => {

        this.props.setLoading(true);
        let start, end;
        let selected = [];
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (view == "week") {
            start = moment(date)
                .startOf("week")
                .subtract(1, "days")
                .toISOString();
            end = moment(date)
                .endOf("week")
                .add(1, "days")
                .toISOString();
        } else if (view == "day") {
            start = moment(date)
                .subtract(1, "days")
                .toISOString();
            end = moment(date)
                .add(1, "days")
                .toISOString();
        } else {
            start = moment(date)
                .startOf("month")
                .subtract(6, "days")
                .toISOString();
            end = moment(date)
                .endOf("month")
                .add(6, "days")
                .toISOString();
        }

        let setDefaultCalendarsVisible = false;

        if (this.props.selectedCalendar === "google" && this.localStorageGoogleCalendars in localStorage) {
            selected = JSON.parse(localStorage[this.localStorageGoogleCalendars]);
        } else if (this.props.selectedCalendar === "outlook" && this.localStorageOfficeCalendars in localStorage) {
            selected = JSON.parse(localStorage[this.localStorageOfficeCalendars]);
        } else {
            setDefaultCalendarsVisible =true;
            selected = [];
        }

        if (this.props.selectedCalendar === "outlook") {
            DataHandler.post({ url: "calendar/office/calendars" }, { selected: selected, start: start, end: end, timezone: timeZone })
                .done(response => {
                    let dialogcalendars = [];
                    let officecalendars = [];

                    let visibleCalendars = [];

                    //Checks that we got calendars.
                    if (!(response.status === "error") && response.calendars) {

                        //this.setState({ contacts: response.contacts });

                        response.calendars.forEach((calendar, i) => {
                            
                            let calendarmolder = calendar[0];
                            calendarmolder.summary = calendarmolder.name;
                            calendarmolder.events = Array.isArray(calendar.events) ? calendar.events : [];
                            calendarmolder.backgroundColor = calendarmolder.hexColor === "" ? "#0CFF00" : calendarmolder.hexColor;
                            const ownerName = calendarmolder.owner.name?.split(' ').reduceRight((acc, r) => `${acc} ${r}`);
                            //HUOM! pitää olla sama nimi Outlook kalenterissa ja Taimerissa! Muuten tulee kaikki kalenterit 'muut' dropin alle
                            calendarmolder.accessRole = this.context.userObject.fullname == ownerName ? "owner" : "";

                            if(setDefaultCalendarsVisible && calendarmolder.isDefaultCalendar){
                                visibleCalendars.push(calendarmolder.id);
                            }

                            if (calendarmolder.isDefaultCalendar) {
                                //calendarmolder.summary = this.fullname;
                                this.primaryCalendar = { value: calendarmolder.id, label: this.fullname }; //Should we use calendar.owner.address instead, like google?
                                officecalendars.unshift(calendarmolder);
                            } else {
                                officecalendars.push(calendarmolder);
                            }
                        });

                        if(setDefaultCalendarsVisible){
                            localStorage.setItem(this.localStorageOfficeCalendars, JSON.stringify(visibleCalendars));
                        }

                        //Go through all calendars. We currently get only one, but let's keep the foreaching for the future
                        officecalendars.forEach((calendar, key) => {
                            //Gives calendar show atribute
                            officecalendars[key].show = 0;

                            if (this.localStorageOfficeCalendars in localStorage) {
                                try {
                                    const calendars = JSON.parse(localStorage[this.localStorageOfficeCalendars]);

                                    if (Array.isArray(calendars) && calendars.length > 0) {
                                        if (calendars.includes(calendar.id)) {
                                            officecalendars[key].show = 1;
                                        }
                                    }
                                } catch (error) {
                                    officecalendars[key].show = 1;
                                }
                            } else {
                                // officecalendars[key].show = 0; //this is useless? as it sets 0 to 0
                            }


                            getColorClass(calendar);

                            //Change events "start" and "end" time to right format
                            if(Array.isArray(calendar.events)){
                                calendar.events.forEach((event, i) => {

                                    officecalendars[key].events[i].allDay = event.isAllDay;
                                    officecalendars[key].events[i].start = new Date(event.start.dateTime);
                                    officecalendars[key].events[i].end = new Date(event.end.dateTime);

                                    officecalendars[key].events[i].calendar_role = event.isOrganizer ? "owner" : ""; //Let's use isOrganizer. We have only default calendar currently, always owner?
                                    officecalendars[key].events[i].calendarid = calendar.id;
                                    officecalendars[key].events[i].calendarsummary = calendar.name;
                                    officecalendars[key].events[i].backgroundColor = calendar.backgroundColor;
                                    officecalendars[key].events[i].description = event.body.contentType == "html" ? event.body.content.replace(/(<([^>]+)>)/gi, "") : event.body.content;
                                    officecalendars[key].events[i].summary = event.subject;
                                    officecalendars[key].events[i].location = event.location.displayName;
                                    officecalendars[key].events[i].locationdata = event.location;
                                    officecalendars[key].events[i].isonlinemeeting = event.isOnlineMeeting;
                                    officecalendars[key].events[i].onlinemeetingtype = event.isOnlineMeeting !== false ? 'default' : '';
                                    officecalendars[key].events[i].onlinemeetingurl = event.isOnlineMeeting !== false ? event.onlineMeeting.joinUrl : event.isOnlineMeeting;
                                    officecalendars[key].events[i].type = event.type;
                                    officecalendars[key].events[i].attendees = event.attendees.map((attendee) => {
                                        return {name: attendee.emailAddress.name, email: attendee.emailAddress.address}
                                    });
                                    
                                    if (calendar.isDefaultCalendar) officecalendars[key].events[i].from_primary = true;

                                });
                            }

                            let dialogcalendar = {
                                value: calendar.id,
                                label: calendar.name,
                                backgroundColor: calendar.backgroundColor,
                                foregroundColor: calendar.backgroundColor,
                                show: 1,
                            };

                            if (calendar.isDefaultCalendar) {
                                if (calendar.accessRole == "owner") dialogcalendars.unshift(dialogcalendar);
                            } else {
                                if (calendar.accessRole == "owner") dialogcalendars.push(dialogcalendar);
                            }
                        });
                        
                        this.setState({ officecalendars, dialogcalendars, currentDate: date, currentView: view }, () => {
                            this.props.setLoading(false);

                            if (this.state.nextCurrentDate != date || this.state.nextCurrentView != view)
                                this.getCalendars(this.state.nextCurrentDate, this.state.nextCurrentView);
                        });
                    }

                })
                .fail(err => {
                    console.error("O365: Taimer calendar error");
                });
        }

        if (this.props.selectedCalendar === "google") {
            DataHandler.post({ url: "calendar/google/calendars" }, { selected: selected, start: start, end: end })
                .done(response => {
                    let dialogcalendars = [];
                    let googlecalendars = [];

                    let visibleCalendars = [];

                    //console.log('google response', response);
                    //if (window.navigator.geolocation) window.navigator.geolocation.getCurrentPosition(console.log, console.log);


                    //Checks that we got calendars
                    if (!(response.status === "error") && response.googlecalendars) {

                        //this.setState({contacts: response.contacts}); We can activate this when we get people api available

                        response.googlecalendars.forEach((calendar, i) => {
                            if (calendar.primary) {

                                //First time in, setting primaries visible
                                if(setDefaultCalendarsVisible) visibleCalendars.push(calendar.id);

                                //calendar.summary = this.tr("Primary");
                                calendar.summary = this.fullname;
                                this.primaryCalendar = { value: calendar.id, label: this.fullname };
                                googlecalendars.unshift(calendar);
                            } else {
                                googlecalendars.push(calendar);
                            }
                        });

                        if(setDefaultCalendarsVisible){
                            localStorage.setItem(this.localStorageGoogleCalendars, JSON.stringify(visibleCalendars));
                        }

                        //Go through all calendars
                        googlecalendars.forEach((calendar, key) => {
                            //Gives calendar show atribute
                            googlecalendars[key].show = 0;

                            //We will set the first allowed conference type as default. Should be hangoutsMeet pretty much always nowadays.
                            //Futhermore this is not used anywhere yet as we always create hangoutsMeet 
                            googlecalendars[key].defaultConferenceType = calendar.conferenceProperties.allowedConferenceSolutionTypes[0];

                            if (this.localStorageGoogleCalendars in localStorage) {
                                try {
                                    const calendars = JSON.parse(localStorage[this.localStorageGoogleCalendars]);

                                    if (Array.isArray(calendars) && calendars.length > 0) {
                                        if (calendars.includes(calendar.id)) {
                                            googlecalendars[key].show = 1;
                                        }
                                    }
                                } catch (error) {
                                    googlecalendars[key].show = 1;
                                }
                            } else {
                                googlecalendars[key].show = 0;
                            }

                            getColorClass(calendar);

                            //Change events "start" and "end" time to right format
                            if(Array.isArray(calendar.events)){
                                calendar.events.forEach((event, i) => {

                                    googlecalendars[key].events[i].type = event.recurringEventId !== null ? 'occurrence' : '';

                                    if (event.alldayevent) {
                                        googlecalendars[key].events[i].start = new Date(
                                            new Date(event.start).setHours(0, 0, 0, 0)
                                        );
                                        googlecalendars[key].events[i].end = new Date(new Date(event.end).setHours(0, 0, 0, 0));
                                        googlecalendars[key].events[i].allDay = true;
                                    } else {
                                        googlecalendars[key].events[i].start = new Date(event.start);
                                        googlecalendars[key].events[i].end = new Date(event.end);
                                    }

                                googlecalendars[key].events[i].onlinemeetingurl = '';
                                googlecalendars[key].events[i].onlinemeetingtype = '';
                                
                                if(event.hangoutLink !== null){
                                    googlecalendars[key].events[i].onlinemeetingurl = event.hangoutLink;
                                    googlecalendars[key].events[i].onlinemeetingtype = 'default';
                                } 

                                googlecalendars[key].events[i].onlinemeetingurl = '';
                                googlecalendars[key].events[i].onlinemeetingtype = '';
                                
                                if(event.hangoutLink !== null){
                                    googlecalendars[key].events[i].onlinemeetingurl = event.hangoutLink;
                                    googlecalendars[key].events[i].onlinemeetingtype = 'default';
                                } 

                                    googlecalendars[key].events[i].calendar_role = calendar.accessRole
                                        ? calendar.accessRole
                                        : "";
                                    googlecalendars[key].events[i].calendarid = calendar.id;
                                    googlecalendars[key].events[i].calendarsummary = calendar.summary;
                                    googlecalendars[key].events[i].backgroundColor = calendar.backgroundColor;

                                    googlecalendars[key].events[i].attendees = event.attendees.map((attendee) => {
                                        return {name: attendee.displayName, email: attendee.email}
                                    });

                                    if (calendar.primary) googlecalendars[key].events[i].from_primary = true;
                                });
                            }

                            let dialogcalendar = {
                                value: calendar.id,
                                label: calendar.summary,
                                backgroundColor: calendar.backgroundColor,
                                foregroundColor: calendar.foregroundColor,
                                show: 1,
                            };

                            if (calendar.primary) {
                                if (calendar.accessRole == "owner") dialogcalendars.unshift(dialogcalendar);
                            } else {
                                if (calendar.accessRole == "owner") dialogcalendars.push(dialogcalendar);
                            }
                        });

                        this.setState({ googlecalendars, dialogcalendars, currentDate: date, currentView: view }, () => {
                            this.props.setLoading(false);

                            if (this.state.nextCurrentDate != date || this.state.nextCurrentView != view)
                                this.getCalendars(this.state.nextCurrentDate, this.state.nextCurrentView);
                        });
                    }
                })
                .fail(err => {
                    console.error("Taimer calendar error");
                });
        }
    };

    onEventResizeDrop = ({ event, start, end/*, allDay*/ }) => {

        let calendarid = "primary";
        let dialogEvent = event;

        delete dialogEvent.timerange;
        if (dialogEvent.calendar) {
            calendarid = dialogEvent.calendar.value;
            delete dialogEvent.calendar;
        }

        if (dialogEvent.calendarid) calendarid = dialogEvent.calendarid;

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startTime = moment(start).format("YYYY-MM-DD") + "T" + moment(start).format("HH:mm") + ":00";
        const endTime = moment(end).format("YYYY-MM-DD") + "T" + moment(end).format("HH:mm") + ":00";


        dialogEvent.start = {};
        dialogEvent.start.dateTime = startTime;
        dialogEvent.start.timeZone = timeZone;

        dialogEvent.end = {};
        dialogEvent.end.dateTime = endTime;
        dialogEvent.end.timeZone = timeZone;

        delete dialogEvent.startdate;
        delete dialogEvent.starttime;
        delete dialogEvent.enddate;
        delete dialogEvent.endtime;
        delete dialogEvent.hours;
        delete dialogEvent.from_primary;
        delete dialogEvent.calendar_role;

        const url = this.props.selectedCalendar === "google" ? "calendar/google/event" : "calendar/office/event";

        if (dialogEvent.new) {
            delete dialogEvent.new;

            DataHandler.post({ url: url }, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.closeEventDialog();
                    this.getCalendars();
                })
                .fail(err => {
                    this.closeEventDialog();
                    this.getCalendars();
                });
        } else {
            DataHandler.put({ url: url }, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.closeEventDialog();
                    this.getCalendars();
                })
                .fail(err => {
                    this.closeEventDialog();
                    this.getCalendars();
                });
        }
    };

    getEventProps = (event, start, end, isSelected) => {
        const len = end.getTime() - start.getTime();
        return {
            style: {},
            className: cn(getColorMarking(event), len <= this.accurary * 60000 * 2 && " event-short"),
        };
    };

    /**
     * Handles navigation in calendar
     *
     */
    onNavigate = (date, view) => {
        let nextCurrentDate = null;

        if (!view) {
            view = this.state.currentView;
            nextCurrentDate = new Date(date.setHours(23, 59, 59, 0));
        } else {
            nextCurrentDate = new Date(date);
        }

        this.setState({ nextCurrentDate, nextCurrentView: view }, () => {
            this.getCalendars(nextCurrentDate, view);
        });
    };

    /**
     * Changes calendar view: day, week, month
     *
     */
    onViewChange = view => {
        this.setState({ selectable: view !== "month", resizable: view !== "month", nextCurrentView: view }, () => {
            this.getCalendars(undefined, view);
        });
    };

    /**
     * Opens selected event in googles website
     *
     */
    onDoubleClickEvent = event => {

        //We just block the functionality for Outlook as it does not offer such
        if (this.props.selectedCalendar === "google") {
            var link = event.htmlLink;
            var split = link.split("eid=").pop();
            window.open("https://calendar.google.com/calendar/r/eventedit/" + split, "_blank");
        }
        setTimeout(() => {
            this.doubleClick = false;
        }, 500);
    };

    onSelectEvent = event => {

        let attendees = event.attendees.map((attendee) => {
            return { name: attendee.name ?? attendee.email, value: attendee.email };
        });

        const dialogevent = {
            id: event.id,
            type: event.type,
            summary: event.summary,
            description: event.description,
            location: event.location,
            status: event.status,
            start: event.start,
            end: event.end,
            startdate: moment(event.start).format("YYYY-MM-DD"),
            starttime: moment(event.start).format("HH:mm"),
            enddate: moment(event.end).format("YYYY-MM-DD"),
            endtime: moment(event.end).format("HH:mm"),
            timerange: `${moment(event.start).format("LT")} - ${moment(event.end).format("LT")}`,
            calendar: { value: event.calendarid, label: event.calendarsummary },
            allday: event.allDay,
            recurrence: event.recurrence,
            attendees: attendees,
            from_primary: event.from_primary,
            calendar_role: event.calendar_role,
            htmlLink: event.htmlLink,
            onlinemeetingurl: event.onlinemeetingurl,
            edit: false,
            notification: 'Add notification',
            onlinemeetingtype: event.onlinemeetingtype,
            personal_meeting_link: this.state.personalMeetingLink
        };

        this.initializeSliderFields(false,dialogevent);
        
        this.setState({ dialogEvent: dialogevent, eventdialogopen: true, sliderVisible: true });

        setTimeout(() => {
            this.doubleClick = false;
        }, 500);
    };

    /**
     * Event creation by dragging mouse on calendar
     *
     */
    onSelectSlot = slotInfo => {

        const event = {
            new: true,
            edit: true,
            startdate: moment(slotInfo.start).format("YYYY-MM-DD"),
            starttime: moment(slotInfo.start).format("HH:mm"),
            enddate: moment(slotInfo.end).format("YYYY-MM-DD"),
            endtime: moment(slotInfo.end).format("HH:mm"),
            hours: (slotInfo.end - slotInfo.start) / (1000 * 60 * 60),
            attendees: [],
            calendar: this.primaryCalendar,
            onlinemeetingtype: '',
            onlinemeetingurl: '',
            allday: false,
            recurrence: '',
            personal_meeting_link: this.state.personalMeetingLink
        };

        this.initializeSliderFields(true,event);

        event.timerange = `${moment(slotInfo.start).format("LT")} - ${moment(slotInfo.end).format("LT")}`;

        this.setState({ dialogEvent: event, sliderVisible: true });
    };

    initializeSliderFields = (create,event) => {

        const slidereventfields = {
            title: create ? this.tr("Add event") : (event.type === 'occurrence' ? this.tr("Edit event - occurrence of a repeating event") : this.tr("Edit event")),
            key: "eventslide",
            items: [],
            canDeleteItem: true,
            listOpen: true,
            fields: [
                {
                    title: this.tr("title"),
                    key: 'summary',
                    type: 'title',
                    preview: true,
                    icon: <RadioButtonUnchecked />
                },
                /*{
                    title: this.tr("Meeting type"),
                    key: 'meetingtype',
                    type: 'select',
                    options: this.state.companyActivities
                },*/
                /*{
                    title: this.tr("dates"),
                    key: 'date',
                    type: 'daterange',
                    startKey: 'startdate',
                    endKey: 'enddate'
                },*/
                {
                    title: this.tr("Date"),
                    key: 'startdate',
                    type: 'date',
                    disabled: false,
                    preview: true, 
                    icon: false

                },
                {
                    title: this.tr("Time"),
                    key: 'timerange',
                    type: 'timerange',
                    startKey: 'starttime',
                    endKey: 'endtime',
                    disabled: (typeof event.allday === "undefined" ) ? false : event.allday,
                    preview: true,
                    icon: false
                },
                /*{
                    nested: [
                        {
                            title: this.tr("Date"),
                            key: 'startdate',
                            type: 'date',
                            disabled: (typeof event.allDay !== "undefined") ? false : event.allDay /* these are disabled if allDay event */
                /*      },              
                        {
                            title: this.tr("Starts"),
                            key: 'starttime',
                            type: 'time',
                            disabled: (typeof event.allDay !== "undefined") ? false : event.allDay/* these are disabled if allDay event */
                /*      },
                        {
                            title: this.tr("Ends"),
                            key: 'endtime',
                            type: 'time',
                            disabled: (typeof event.allDay !== "undefined") ? false : event.allDay /* these are disabled if allDay event */
                /*     }
                    ]
                },*/
                {
                    nested: [
                        {
                            title: this.tr("All day"),
                            key: 'allday',
                            type: 'checkbox',
                            toggleDisableKeys: ['timerange'],
                            setOtherKeys: [
                                {key: 'starttime', value: '00:00'},
                                {key: 'endtime', value: '00:00'},
                                {key: 'timerange', value: '00.00 - 00.00'},
                            ]
                        },
                        {
                            title: this.tr("Repeat"),
                            key: 'recurrence',
                            type: 'repeatrule',
                        },                        
                    ]
                },
                {
                    title: this.tr("location"),
                    key: 'location',
                    preview: true,
                    icon: <LocationOn />
                },
                {
                    title: this.tr("Add Meeting Link"),
                    key: 'onlinemeetingtype',
                    type: 'context_menu',
                    preview: false,
                    icon: false,
                    options: [
                        {
                            label: this.props.selectedCalendar === "google" ? this.tr("Google Meet - Default") : this.tr("Microsoft Teams - Default"),
                            value: 'default',
                            function: null
                        },                        
                        {
                            label: this.tr("Personal Meeting Link"),
                            value: 'personal',
                            function: null
                        },
                        {
                            label: this.tr("Edit Personal Link"),
                            value: 'edit',
                            function: this.editPersonalMeetingLink
                        },
                        {
                            label: this.tr("Not An Online Meeting"),
                            value: 'none',
                            function: null
                        },
                    ]
                },
                {
                    title: this.tr("Online Meeting URL"),
                    key: 'onlinemeetingurl',
                    type: 'link',
                    preview: true,
                    icon: <Videocam />
                },                    
                {
                    title: this.tr("attendees"),
                    label: this.tr("Add participants"),
                    key: 'attendees',
                    type: 'contact-list',
                    preview: true,
                    icon: <GroupAdd />,
                    options: this.state.contacts
                },
                /*{
                    title: this.tr("Remind me"),
                    key: 'notification',
                    type: 'dialogdropdown',
                    options: [
                        {
                            title: 'Never',
                            function: ''
                        },
                        {
                            title: 'At time of event',
                            function: ''
                        },
                        {
                            title: '5 minutes before',
                            function: ''
                        },
                        {
                            title: '15 minutes before',
                            function: ''
                        },
                        {
                            title: '30 minutes before',
                            function: ''
                        },
                        {
                            title: '1 hour before',
                            function: ''
                        },
                        {
                            title: '2 hours before',
                            function: ''
                        },
                        {
                            title: '12 hours before',
                            function: ''
                        },
                        {
                            title: 'One day before',
                            function: ''
                        },
                        {
                            title: 'One week before',
                            function: ''
                        },
                    ]
                },*/
                {
                    title: this.tr("description"),
                    key: 'description',
                    type: 'textarea',
                    rows: 3,
                    rowsMax: 5,
                    preview:false,
                    icon: false
                },
                /*{
                    title: this.tr("Customer"),
                    key: 'customer'
                },*/
                /*{
                    title: this.tr("Project"),
                    key: 'project'
                },*/
                {
                    title: this.tr("calendar"),
                    key: 'calendar',
                    type: 'select',
                    preview: true,
                    icon: <CalendarToday />,
                    options: this.state.dialogcalendars
                },
            ]
        };

        this.setState({sliderEventFields: slidereventfields });
    }

    editPersonalMeetingLink = (event,item) => {
        this.setState({ editPersonalMeetingLink: true, dialogEvent: item});
    }

    /**
     * Closes event dialog
     *
     */
    closeEventDialog = () => {
        this.setState({ dialogEvent: false });
    };
    closeEventSlider = () => {
        this.setState({ dialogEvent: [],  /*sliderEventFields: [],*/ sliderVisible: false });
    };

    /**
     * Returns all calendars events those show = 1
     * Jos onlyallday = true niin palauttaa vain koko päivän eventit
     * Jos onlyallday = false niin palauttaa vain eventit joska ei oo koko päivän
     *
     */
    getEvents = () => {
        let events = [];
        const calendars =   (this.state.googlecalendars.length > 0 && this.props.selectedCalendar === "google")
                            ? this.state.googlecalendars
                            : (this.state.officecalendars.length > 0 && this.props.selectedCalendar === "outlook")
                            ? this.state.officecalendars
                            : [];   

        if (!Array.isArray(calendars))
            return events;

        calendars.forEach((calendar, i) => {
            if (calendar.show) {
                if (Array.isArray(calendar.events)) {
                    calendar.events.forEach((event, ii) => {
                        events.push(event);
                    });
                }
            }
        });
       
        return events;
    };

    getCompanyActivities() {
        const company = this.context.userObject.companies_id;
        DataHandler.get({ url: `settings/company/${company}/activities` })
            .done(response => {
                let activities = response.map(activity => ({ value: activity.id, label: activity.name }));
                this.setState({ companyActivities: activities });
            });
    }

    onCreateActivityFromSlider = () => {
        const newEvent = {
            description: this.state.dialogEvent.summary || "",
            date: this.state.dialogEvent.startdate,
        };
        this.setState({ sliderVisible: false }, () => {
            this.context.functions.openActivitySlider(newEvent);
        });
    };

    onCreateActivity = event => {
        const newEvent = {
            description: event.summary || "",
            date: event.startdate,
        };
        this.setState({ dialogEvent: false }, () => {
            this.context.functions.openActivitySlider(newEvent);
        });
    };

    onCreateTimeEntry = event => {
        const new_event = {
            start: event.start,
            end: event.end,
            description: event.summary || ""
        };
        this.context.functions.addHours(new_event, { preventMulticompanyHourentries: this.props.preventMulticompanyHourentries, parentComponent: "Google Calendar" });
    };

    onCreateTimeEntryFromSlider = () => {
        const new_event = {
            start: this.state.dialogEvent.start,
            end: this.state.dialogEvent.end,
            description: this.state.dialogEvent.summary || ""
        };
        this.context.functions.addHours(new_event, { preventMulticompanyHourentries: this.props.preventMulticompanyHourentries, parentComponent: "Google Calendar" });
    };

    toggleSlider = () => {
        this.setState(prevState => ({
            sliderVisible: !prevState.sliderVisible
          }));
    }

    onOpenInGoogle = event => {
        var link = event.htmlLink;
        var split = link.split("eid=").pop();
        window.open("https://calendar.google.com/calendar/r/eventedit/" + split, "_blank");

        this.setState({ dialogEvent: false });
    };

    onDelete = (event = this.state.dialogEvent) => {
        if (event && ((event.calendar && event.calendar.value) || event.calendarid) && event.id) {
            const calendarid = typeof event.calendar !== "undefined" ? event.calendar.value : event.calendarid;
            const eventid = event.id;

            const url = this.props.selectedCalendar === "google" ? "calendar/google/event/delete" : "calendar/office/event/delete";

            DataHandler.post({ url: url }, { calendarid: calendarid, eventid: eventid })
                .done(response => {
                    //For the addon
                    const company = this.context.userObject.companies_id;
                    const useSlider = true;
                    if(useSlider) this.setState({ dialogEvent: [], sliderEventFields: [], sliderVisible: false });
                    else this.setState({ dialogEvent: false });
                    this.getCalendars();
                })
                .fail(err => {
                    this.getCalendars();
                });
        }
    }

    onSave = (event) => {
        //let dialogEvent = this.state.dialogEvent;
        let dialogEvent = event;
        let calendarid = "";

        delete dialogEvent.timerange;
        if (dialogEvent.calendar) {
            calendarid = dialogEvent.calendar.value;
            delete dialogEvent.calendar;
        }

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startTime = dialogEvent.startdate + "T" + ( event.allday ? "00:00" : dialogEvent.starttime )+ ":00";
        if(event.allday) dialogEvent.startdate = moment(dialogEvent.startdate).add(1, 'days').format('YYYY-MM-DD')
        const endTime = /*dialogEvent.enddate on current config only one date */ dialogEvent.startdate + "T" + ( event.allday ? "00:00" : dialogEvent.endtime )+ ":00";

        if(this.props.selectedCalendar !== "google" && typeof event.recurrence !== "undefined" && event.recurrence !== null ){
            let startOfWeek = moment().startOf("week");
            if(event.recurrence.includes('WEEKLY')) dialogEvent.recurrence = event.recurrence+';WEEKSTART='+startOfWeek._locale._week.dow;
        }

        dialogEvent.start = {};
        dialogEvent.start.dateTime = startTime;
        dialogEvent.start.timeZone = timeZone;

        dialogEvent.end = {};
        dialogEvent.end.dateTime = endTime;
        dialogEvent.end.timeZone = timeZone;
       
        let attendees = event.attendees.map((attendee) => {
            return { name: attendee.name, email: attendee.value };
        });
        event.attendees = attendees;

        delete dialogEvent.startdate;
        delete dialogEvent.starttime;
        delete dialogEvent.enddate;
        delete dialogEvent.endtime;
        delete dialogEvent.hours;
        delete dialogEvent.from_primary;
        delete dialogEvent.calendar_role;
        delete dialogEvent.htmlLink;

        const url = this.props.selectedCalendar === "google" ? "calendar/google/event" : "calendar/office/event";

        if (dialogEvent.new) {
            delete dialogEvent.new;
            DataHandler.post({ url: url }, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.closeEventSlider();
                    this.getCalendars();
                })
                .fail(err => {
                    this.closeEventSlider();
                    this.getCalendars();
                });
        } else {
            DataHandler.put({ url: url }, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.closeEventSlider();
                    this.getCalendars();
                })
                .fail(err => {
                    this.closeEventSlider();
                    this.getCalendars();
                });
        }
    };
    closePopover = () => {
        this.setState({ anchorEl: null, eventPopover: false, popoverEvent: []} );
    }
    eventMenuDialog = (calendarEvent,eventTarget) => {
        this.setState({ anchorEl: eventTarget, eventPopover: true, popoverEvent: calendarEvent});
    }


    /**
     * TaimerCalendarView renderer
     *
     */
    render() {
        const { dialogcalendars, dialogEvent, sliderEventFields, sliderVisible, eventPopover, anchorEl, popoverEvent, editPersonalMeetingLink } = this.state;
        const slotsInHour = 60 / this.accurary;
        const slots = Math.min(Math.ceil(slotsInHour), 12);
        const isExact = slotsInHour * this.accurary === 60;
        let events = this.getEvents(true);
        const date = "0000-00-00";

        //For the addon
        const company = this.context.userObject.companies_id;
        const useSlider = true;
        
        const { userObject, functions: { hasPrivilege } } = this.context;
        const custombutton = [{
            title: this.tr("Options"),
            key: "addnew",
            type: 'dialogdropdown',
            options: [
                {
                    title: this.tr("Create a time entry"),
                    function: this.onCreateTimeEntryFromSlider,
                    show: (hasPrivilege("workhours", "write") || hasPrivilege("workhours", "write_full")) ? true : false
                },
                {
                    title: this.tr("Create activity"),
                    function: this.onCreateActivityFromSlider,
                    show: true
                },
                {
                    title: this.tr("Delete"),
                    function: this.onDelete,
                    show: true
                },
            ]
        }]

        const popOverOptions = [
            {
                title: this.tr("Create a time entry"),
                class: 'time-entry',
                function: this.onCreateTimeEntry,
                show: (hasPrivilege("workhours", "write") || hasPrivilege("workhours", "write_full")) ? true : false,
                icon: <Timer />
            },
            {
                title: this.tr("Create activity"),
                class: 'activity',
                function: this.onCreateActivity,
                show: true,
                icon: <Activity />
            },
            {
                title: this.tr("View / Edit"),
                class: 'edit',
                function: this.onSelectEvent,
                show: true,
                icon: <Edit />
            },            
            {
                title: this.tr("Delete"),
                class: 'delete',
                function: this.onDelete,
                show: true,
                icon: <Delete className="delete-icon" />
            },
        ]

        //const usedCalendar = this.state.googlecalendars.length > 0 ? this.state.googlecalendars : this.state.officecalendars; 
        return (
            <div className="TaimerCalendarWrapper">
                {sliderEventFields && useSlider && (
                    <ContentSlider 
                        onSave={(event) => {
                            this.onSave(event);
                        }}
                        onDeleteItem={(event) => this.onDelete(event)}
                        canDeleteItem={sliderEventFields.canDeleteItem}
                        item={dialogEvent}
                        fields={[
                            ...(sliderEventFields.fields || []),
                        ]}
                        title={sliderEventFields.title || ''}
                        open={!!sliderVisible}
                        onClose={() => this.closeEventSlider()}
                        toggleMe = {() => this.toggleSlider()}
                        custombuttonsbottom={ dialogEvent && !dialogEvent.new ?  custombutton : null }
                    />
                )}
                {dialogEvent && !useSlider && (
                    <GoogleEventDialog
                        onSave={() => {
                            this.closeEventDialog();
                            this.getCalendars();
                        }}
                        onClose={() => {
                            this.closeEventDialog();
                        }}
                        onDelete={(event) => this.onDelete(event)}
                        onChangeDialogEvent={this.onChangeDialogEvent}
                        dialogEvent={dialogEvent}
                        dialogcalendars={dialogcalendars}
                        onOpenInGoogle={event => this.onOpenInGoogle(event)}
                        onCreateActivity={event => this.onCreateActivity(event)}
                        onCreateTimeEntry={event => this.onCreateTimeEntry(event)}
                        selectedCalendar={this.props.selectedCalendar}
                    />
                )}
                {editPersonalMeetingLink && (
                    <PersonalMeetingLinkDialog
                        onSave={(link) => { 
                            var devent = {...this.state.dialogEvent}
                            devent.personal_meeting_link = link;

                            this.setState({ editPersonalMeetingLink: false, sliderVisible: true, dialogEvent: devent, personalMeetingLink: link }); 
                        }}
                        onClose={() => {
                            this.setState({ editPersonalMeetingLink: false, sliderVisible: true  });
                        }}
                        value={this.state.personalMeetingLink}
                    />
                )}
                {eventPopover && (
                    <PopoverMenu
                        calendarEvent={popoverEvent}
                        onClose={this.closePopover}
                        options={popOverOptions}
                        anchorEl={anchorEl}
                    />
                )}
                <div className="TaimerCalendarBody">
                    <TaimerCalendarSidebar
                        createNewEvent={() => { }}
                        calendars={this.props.selectedCalendar === "google" ? this.state.googlecalendars : this.state.officecalendars}
                        updateCalendars={(calendars, show) => {
                            let calendarArray = [];
                            calendars.forEach((calendar, i) => {
                                if (calendar.show) {
                                    calendarArray.push(calendar.id);
                                }
                            });

                            if (this.props.selectedCalendar === "google") {
                                localStorage.setItem(this.localStorageGoogleCalendars, JSON.stringify(calendarArray));
                                this.setState({ "googlecalendars": calendars }, () => {
                                    if (show) this.getCalendars();
                                });
                            }
                            if (this.props.selectedCalendar === "outlook") {
                                localStorage.setItem(this.localStorageOfficeCalendars, JSON.stringify(calendarArray));
                                this.setState({ "officecalendars": calendars }, () => {
                                    if (show) this.getCalendars();
                                });
                            }
                        }}
                        updateCalendarsCollapse={collapses => {
                            this.setState({ collapses });
                            localStorage.setItem(this.localStorageGoogleCalendarsCollapses, JSON.stringify(collapses));
                            localStorage.setItem(this.localStorageOfficeCalendarsCollapses, JSON.stringify(collapses));
                        }}
                        collapses={this.state.collapses}
                        date={date}
                        changeDate={this.onNavigate}
                        currentDate={this.state.currentDate}
                        tr={this.tr}
                        handleClick={this.handleClick}
                    />
                    <div className="TaimerCalendarMain">
                        <DnDCalendar
                            formats={{
                                timeGutterFormat: isExact
                                    ? moment.localeData()._longDateFormat.LT.replace(/[^A-Za-z][mM]{1,2}/, "")
                                    : moment.localeData()._longDateFormat.LT,
                            }}
                            min={this.props.startHour}
                            max={this.props.endHour}
                            resizable={this.state.resizable}
                            selectable={this.state.selectable}
                            draggableAccessor={event =>
                                event.calendar_role && event.calendar_role == "owner" && this.state.resizable
                            }
                            defaultDate={this.state.currentDate}
                            defaultView={this.state.currentView}
                            events={events}
                            onEventResize={e => this.onEventResizeDrop(e)}
                            onEventDrop={e => this.onEventResizeDrop(e)}
                            localizer={momentLocalizer}
                            components={this.components}
                            eventPropGetter={this.getEventProps}
                            onNavigate={this.onNavigate}
                            onView={this.onViewChange}
                            onSelectSlot={this.onSelectSlot}
                            onSelectEvent={(calendarEvent,event) => {
                                const eventTarget = event.currentTarget;
                                setTimeout(() => {
                                    if (!this.doubleClick) {
                                        this.eventMenuDialog(calendarEvent,eventTarget);
                                    }
                                }, 300);
                            }}
                            onDoubleClickEvent={event => {
                                this.doubleClick = true;
                                this.onSelectEvent(event);
                            }}
                            showMultiDayTimes
                            views={["month", "week", "day"]}
                            step={this.accurary}
                            timeslots={slots}
                            messages={this.messages}
                            date={this.state.nextCurrentDate}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getChildContext() {
        return {
            timetracker: {
                events: this.getEvents(true),
            },
        };
    }
}

TaimerCalendarView.childContextTypes = {
    timetracker: PropTypes.object,
};

export default withSnackbar(TaimerCalendarView);
